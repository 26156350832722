import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import TableBody from '@material-ui/core/TableBody';

import GenericTableHead from './components/GenericTableHead';

import {
  StyledTableContainer,
  StyledTable,
  TableRowCustom,
  TableCellCustom,
  TableCellText,
  EmptyRowContainer
} from './style'

const GenericTable = ({
  headerList,
  itemList,
  rowItemProps = {},
  onTableRowClick,
  pageNumber = 0,
  rowsPerPage = 15,
  hover = false,
  stickyHeader = true,
  pagination = false,
  cellWithModifiedData = [], // for cell that are wrapped with div, the original data should reside in key+original
  className, // to pass styled component className to mainContainer
}) => {
  const [_page,] = useState(pageNumber);
  const [_rowsPerPage,] = useState(rowsPerPage);
  const [orderBy, setOrderBy] = useState('titre');
  const [order, setOrder] = useState('asc');
  const [sortedList, setSortedList] = useState([]);

  useEffect(() => {
    let newList = stableSort(itemList, getComparator(order, orderBy));
    if (pagination)
      newList = newList.slice(_page * _rowsPerPage, _page * _rowsPerPage + _rowsPerPage);
    setSortedList(newList);
  }, [itemList, order, orderBy])

  const descendingComparator = (a, b, orderBy) => {
    const hasOriginalVersion = _.includes(cellWithModifiedData, orderBy);
    const valueA = hasOriginalVersion ? a[`${orderBy}Original`] : a[orderBy];
    const valueB = hasOriginalVersion ? b[`${orderBy}Original`] : b[orderBy];

    // console.log(orderBy, hasOriginalVersion);
    // console.log('valueA', valueA);
    // console.log('valueB', valueB);
    // console.log('a', a);

    // if (valueB === 0) {
    //   return 0;
    // } 

    if (valueB < valueA) {
      return -1;
    }
    if (valueB > valueA) {
      return 1;
    }
    return 0;
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleOnClick = (item) => {
    if (onTableRowClick) onTableRowClick(item);
    return;
  }

  return (
    <StyledTableContainer className={`${className ? className : ''} genericTable`}>
      <StyledTable
        aria-labelledby="tableTitle"
        aria-label="sticky table"
        stickyHeader={stickyHeader}
        className={'genericMaterialTable'}
      >
        <GenericTableHead
          headerList={headerList}
          orderBy={orderBy}
          order={order}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {sortedList.map((item, index) => {
            return (
              <TableRowCustom hover={hover} key={index} onClick={() => handleOnClick(item)}>
                {_.compact(_.map(item, (value, key) => {
                  // console.log('tableRow', key, typeof value);
                  if (rowItemProps[key]?.display !== 'hidden') {
                    return (
                      <TableCellCustom key={key} {...rowItemProps[key]}>
                        {typeof value === String ?
                          <TableCellText>{value}</TableCellText> :
                          value
                        }
                      </TableCellCustom>
                    )
                  }
                }))}
              </TableRowCustom>
            )
          })
          }
        </TableBody>
      </StyledTable>
      {itemList.length === 0 &&
        <EmptyRowContainer>
          Aucune donnée à afficher
        </EmptyRowContainer>
      }
    </StyledTableContainer>
  )
}

GenericTable.propTypes = {
  headerList: PropTypes.array,
  itemList: PropTypes.array,
  rowItemProps: PropTypes.array,
  onTableRowClick: PropTypes.func,
  pageNumber: PropTypes.number,
  rowsPerPage: PropTypes.number,
  hover: PropTypes.bool,
  stickyHeader: PropTypes.bool,
  pagination: PropTypes.bool
}

export default GenericTable


// USAGE //

/*
  headerList contains the label, id and settings for the headerList
  the id itself is the key of the object inside itemList (For the table sorting function to work)
  example:

  const itemList = [
    {title: 'abc', creation_date:'123', publication_date: '234', type: 'aaa', site: 'hey', status: 'hello'},
    {title: 'bca', creation_date:'231', publication_date: '134', type: 'baa', site: 'iey', status: 'bello'},
  ]

  All the keys inside this object will correspon to the id inside the object of headerList

  const headerList = [
    { id: "title", numeric: false, disablePadding: false, label: "Titre du contenu" },
    { id: "creation_date", numeric: false, disablePadding: false, label: "Date de création" },
    { id: "publication_date", numeric: false, disablePadding: false, label: "Date de publication" },
    { id: "type", numeric: false, disablePadding: false, label: "Type de contenu" },
    { id: "site", numeric: false, disablePadding: false, label: "Site(s) d'entreprises" },
    { id: "status", numeric: false, disablePadding: false, label: "Status" },
  ];


  Numeric and disablePadding is not mandatory, it is a props for material UI to style

  const headerList = [
    { id: "providers", label: "Prestataires" },
    { id: "manager", label: "Responsable" },
    { id: "contact", label: "Contact" },
    { id: "action", label: "Action" },
  ];

  We can put an component as an item instead of a string, and it will render it as we want

  const itemListWithComponentExample = [
    { providers: 'Microsoft', manager: 'Ronan Bouziane', contact: 'ronan.bouziane@epitech.eu', action: <Test /> },
    { providers: 'Microsoft', manager: 'Ronan Bouziane', contact: 'ronan.bouziane@epitech.eu', action: <Test /> },
    { providers: 'Microsoft', manager: 'Ronan Bouziane', contact: 'ronan.bouziane@epitech.eu', action: <Test /> },
  ]

  We can pass props into the rowItemProps in this following example,
  The rowItem of 'creation_date' will have the className of 'hover-deep-blue' which make this item to have background color of deepblue when hover over,
  'displayBackgroundColor' is a props for styledComponent which control if the item have a background color,

  In this example, we need to use the rowItem object key to apply the correct props,
  and we can see that the key 'title' is missing, this is because the rowItem 'title' does not need additional props and will follow the default behaviour,

  In short, if we need to pass additional props to the rowItem, we can do it with this structure:

  'key': {
    props
  }

  if the rowItem does not need additional props, we can leave the key out.
  rowItemProps is not a mandatory props for genericTable

  const rowItemProps = {
    creation_date: {
      className: 'hover-deep-blue',
      displayBackgroundColor: true
    },
    publication_date: {
      className: 'hover-deep-blue',
      displayBackgroundColor: true
    },
    type: {
      className: 'hover-green',
      displayBackgroundColor: true
    },
    site: {
      className: 'hover-yellow',
      displayBackgroundColor: true
    },
    status: {
      className: 'hover-blue',
    },
  }

  Example:
  <GenericTable
    headerList={headerList}
    itemList={itemList}
    rowItemProps={rowItemProps}
    hover={true}
    onTableRowClick={(item) => console.log('this will give you the data of the rowItem to be process as needed', item)}
  />
*/

// cellWithModifiedData is an array for list of cell that are wrapped with other element thus the sorting will not work anymore,
// This also include date cell where normally date is formated to suit the display needs, but sorting of date is better be done with timestamp instead,
// so the cell 'date' will contains formated date for display purpose, while 'dateOriginal' will contains the timestamp version of the data for sorting purpose
// the 'original' version of the data should be hidden with rowItemProps { dateOriginal: { display: 'hidden' }} as this data is only for calculation purpose and not intended to be displayed