import React, { useEffect, useState } from 'react';
import { useRouteMatch } from "react-router-dom";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { useSelector, shallowEqual } from 'react-redux';
import { getConciergeConciergesData } from '../../../../../store/concierge/selectors';
import { getUser } from '../../../../../store/collaborator/selectors'

import EmailIcon from '@material-ui/icons/Email';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';

// Styles
import {
  SkillsDomains,
  SkillsDomainsHead,
  SkillsDomainsHeadCircle,
  SkillsDomainsHeadTitle,
  SkillsDomainsList,
  SkillsDomainsItem,
  SkillsDomainsItemCircle,
  SkillsDomainsItemContent,
  SkillsDomainsItemTitle,
  SkillsDomainsItemText,
  DesktopMessageIconWrapper,
  ArrowIconWrapper,
  IconAndText,
  StyledAccordion,
  StyledAccordionSummary,
  CircleDiv,
  StyledAccordionTitle,
  ConciergerieDescription,
  ContainerItemText,
  ConciergerieDescriptionSpan,
  StyledEmailIcon,
  StyledPhoneIcon
} from "./style";

import ArrowRightIcon from '../../../components/atoms/icons/arrowRight';
import DesktopMessageIcon from '../../../components/atoms/icons/desktopMessage';

// Hooks
import useTheme from "../../../../../theme-style/useThemeCustomHook";

const SkillDomains = ({ title, skills, type = null }) => {
  const theme = useTheme();
  const { path } = useRouteMatch();
  const [curentConciergerie, setCurentConciergerie] = useState(null)

  const {
    conciergesData,
    user
  } = useSelector(
    state => ({
      conciergesData: getConciergeConciergesData(state),
      user: getUser(state)
    }),
    shallowEqual
  );

  useEffect(() => {
    if (conciergesData && user) {
      const curent = conciergesData.find((item) => String(item.company_site.id) === String(user.company_site.id))
      setCurentConciergerie(curent);
    }
  }, [user, conciergesData])

  return (
    <SkillsDomains>
      {type === 'Conciergerie' ?

        curentConciergerie && (
          <StyledAccordion>
            <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
              <SkillsDomainsHeadCircle>
                <DesktopMessageIconWrapper>
                  <DesktopMessageIcon color={theme.colors.white} />
                </DesktopMessageIconWrapper>
              </SkillsDomainsHeadCircle>
              <StyledAccordionTitle>{`${title} ${curentConciergerie?.name || ''}`}</StyledAccordionTitle>
            </StyledAccordionSummary>
            <AccordionDetails>
              <ConciergerieDescription>
                <ConciergerieDescriptionSpan>{curentConciergerie?.presentation}</ConciergerieDescriptionSpan >
              </ConciergerieDescription>
              <ContainerItemText>
                <IconAndText> <StyledEmailIcon style={{ width: 13, height: 13, marginRight: 5 }} /> {curentConciergerie?.mail || "-"}</IconAndText>
                <IconAndText> <StyledPhoneIcon style={{ width: 13, height: 13, marginRight: 5 }} /> {curentConciergerie?.phone || "-"}</IconAndText>
              </ContainerItemText>
            </AccordionDetails>
          </StyledAccordion>
        )
        :
        <SkillsDomainsHead>
          <SkillsDomainsHeadCircle>
            <DesktopMessageIconWrapper>
              <DesktopMessageIcon color={theme.colors.white} />
            </DesktopMessageIconWrapper>
          </SkillsDomainsHeadCircle>
          <SkillsDomainsHeadTitle>
            {title}
          </SkillsDomainsHeadTitle>
        </SkillsDomainsHead>
      }

      {/* FOR DELIVERY 3 */}
      {title !== 'Conciergerie' &&
        <SkillsDomainsList>
          {skills && skills.map((skill, idx) => {
            return (
              <SkillsDomainsItem key={idx} to={`${path}/thematique/${skill.id}`}>
                <SkillsDomainsItemCircle>
                  {skill.title.charAt(0)}
                </SkillsDomainsItemCircle>
                <SkillsDomainsItemContent>
                  <SkillsDomainsItemTitle>
                    {skill.title}
                  </SkillsDomainsItemTitle>
                  {skill.text &&
                    <SkillsDomainsItemText>
                      {skill.text}
                    </SkillsDomainsItemText>
                  }
                </SkillsDomainsItemContent>
                <ArrowIconWrapper>
                  <ArrowRightIcon color={theme.colors.grey4} />
                </ArrowIconWrapper>
              </SkillsDomainsItem>
            );
          })}
        </SkillsDomainsList>
      }
      {/* FOR DELIVERY 3 */}

    </SkillsDomains>
  );
};

export default SkillDomains;