import { call, put, select } from 'redux-saga/effects'
import {
  getServiceMessagesSuccess,
  getServiceMessagesError,
  saveServiceMessageSuccess,
  saveServiceMessageError
} from '../actions';
import endpoint from '../../../helpers/api';
import apiCall from '../../apiCall';
import * as authenticationSelectors from '../../authentication/selectors';
import { getUser } from '../../collaborator/selectors';
import { saveTicketMessageService } from '../../../services/ticketMessageServices';
import { toaster } from '../../../helpers/toaster';

export function* getServiceMessagesHandler(data) {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const getServicesOrdersListData = yield call(apiCall, endpoint.getServiceMesaggesList(data.ticketId), userToken);
    yield put(getServiceMessagesSuccess(getServicesOrdersListData));
  } catch (error) {
    yield put(getServiceMessagesError(error));
  }
}

export function* saveServiceMessageHandler(action) {
  const { formData } = action.data;
  try {
    const user = yield select(getUser);
    const userToken = yield select(authenticationSelectors.getUserToken);
    const response = yield call(saveTicketMessageService, endpoint.saveTicketMessage, userToken, user, formData);
    yield put(saveServiceMessageSuccess(response.data));
    if (formData.type !== 'system') toaster('Votre message est envoyé', 'success');
  } catch (err) {
    yield put(saveServiceMessageError(err));
    toaster(`Une erreur s'est produite. Veuillez réessayer`, 'error');
  }
}