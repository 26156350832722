import React from 'react';
import styled, { css } from "styled-components";
// Styled Material UI componenets
import Tabs from '@material-ui/core/Tabs';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';

export const StatusFeeback = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 100px;
  background-color: #ff5252;
`;

export const StyledTabs = styled(Tabs)`
  &&{
    &.MuiTabs-root{
      color: white;
      background-color: #2768B3;
      min-width: 200px;
      width: 100%;
      max-width: 230px;
      height: 100vh;
      .MuiTabScrollButton-vertical{
        height: 25px;
      }
    }
    .MuiTabs-scroller{
      .MuiTabs-indicator{
        display: none;
      }
    }
  }
`;

export const StyledListItemText = styled(({
  isnested,
  ...rest
}) => (<span {...rest}></span>))`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;


export const StyledListItem = styled(({
  isnested,
  ...rest
}) => (<ListItem {...rest} />))`
    color: white;
    background-color: #2768B3;
    background-color: ${props => props.isActiveTab && "#2160A9 !important"};
    padding-left: ${props => props.isnested ? "30px" : "20px"};
    ${StyledListItemText}{
      font-size: ${props => props.isnested ? "12px" : "11px"};
      font-weight: ${props => props.isnested ? "500" : "700"};
      padding-left: ${props => props.isnested ? "15px" : "0"};
    }
    svg{
      display: none;
    }
    &:hover {
      background-color: #2160A9;
    }
`;

export const CollapseCustom = styled(Collapse)`
  &&{
    .MuiList-root{
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      .MuiListItem-root {
        width: auto;
        display: inline-flex;
      }
      .MuiCollapse-container {
        padding-bottom: 30px;
      }
    }
  }
`;

export const LoaderWrapper = styled.div`
  padding: 20px 20px;
  display: flex;
  justifyContent: center;
`;

export const ListNavCustom = styled(List)`
  &&{
    &.MuiList-root{
      .MuiListItem-root{
        padding: 10px;
        margin: 0;
        border-radius: 5px;
        span{
          padding-left: 0;
        }
      }
    }
  }
`;

export const ListCustom = styled(List)`
  &&{
    &.MuiList-root{
      padding: 20px;
      & > .MuiListItem-root{
        padding: 0 0 15px 0;
      }
    }
  }
`;
