import styled from "styled-components";
import breakpoints from "../../../theme-style/breakpoints";

export const MainContainer = styled.div`
  width: 100%;
  max-width: 600px;
  height: 100%;
  box-sizing: border-box;
  background-color: white;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 4px;
  position: relative;
  max-height: calc(100vh - 1.4rem);
      
  @media ${breakpoints.mobileXL} {
    max-height: calc(100vh - 2.4rem);
  }
`;

export const Banner = styled.img`
  background-color: black;
  top: 0;
  width: 100%;
  max-height: 200px;
  /* background-image: url(${props => props.image}), url(${props => props.defaultImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; */
  object-fit: cover;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  /* align-items: center; */
  padding: 40px;
  font-size: 1rem;
  line-height: 1.4;
  word-break: keep-all;
  width: 100%;
  ol, ul {
    padding-left: 2rem;
    list-style-position: inside;
  }
  ul {
    list-style: disc;
  }
  ol {
    list-style: decimal;
  }
  em {
    font-style: revert;
  }
  strong {
    font-weight: revert;
  }
  @media screen and (max-width: 769px){
    padding: 25px;
    ol, ul {
      padding-left: 1rem;
    }
  }
  img {
    max-width: 100%;
    height: auto !important;
  }
`;

export const ContentHeader = styled.div`
  margin-bottom: 20px;
  text-align: center;
  font-weight: 700;
  color: #5A5A5A;
`;

export const GoodDealContainer = styled.div`
  margin: 30px 0px;
  padding: 20px 0px;
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #E5E5E5;
  font-size: 1rem;
  text-align: center;
  span{
    margin-left: 10px;
    color: #EC6B87;
    font-weight: 700;
    text-transform: uppercase;
  }
`;

export const GoodDealLink = styled.a`
  background-color: #CDCDCD;
  padding: 15px 35px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 3px;
`;