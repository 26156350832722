import React from 'react';

// Styles Theme Hooks
import useTheme from "../../../../theme-style/useThemeCustomHook";

// Icons
import NotificationIcon from '../../../../components/atoms/icons/notification';

// Styles
import {
  Container,
  WrapperContent,
  Title,
  Text,
  Toolbar,
  ButtonCptNotification,
  NotificationIconWrapper,
  NotificationIndicator
} from "./style";

const Header = () => {
  const theme = useTheme();
  const notificationStatus = true;
  const notificationValue = 30;

  return (
    <Container>
      <WrapperContent>
        <Title>
            Tableau de bord de mon espace d’administration
        </Title>
        <Text>
            Retrouvez un aperçu de vos différents éléments d’administration et des dernières activités à leur sujet.
        </Text>
      </WrapperContent>
      <Toolbar>
        {/* <ButtonCptNotification
          appearence="secondary"
          startIcon={
            <NotificationIconWrapper>
              <NotificationIcon color={theme.colors ? theme.colors.grey7 : ""} />
            </NotificationIconWrapper>
          }
          endIcon={notificationStatus && (
            <NotificationIndicator>
              <span>{notificationValue}</span>
            </NotificationIndicator>
          )}
        /> */}
      </Toolbar>
    </Container>
  );
}

export default Header;
