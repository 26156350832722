import React, {useEffect, useState} from 'react';
import {
  shallowEqual,
  useDispatch,
  useSelector,
} from "react-redux";

// Import services
// import getTreatedFbBySkillStats from '../../services/getTreatedFbBySkillStats.service';
import {
  getTreatedFbBySkillStatsData,
} from '../../../../../../store/adminFront/selectors';
import {
  getTreatedFbBySkillStats,
} from '../../../../../../store/adminFront/actions';

// Styles
import {
  Container,
  Title,
  Content,
  OldFeedbackCompetenceList,
  OldFeedbackCompetenceItem
} from "./style";

const OldFeedbackTreatedCompetenceStats = () => {
  const dispatch = useDispatch();
  // const [oldTreatedFbBySkillStat, setOldTreatedFbBySkillStat] = useState([]);

  // useEffect(() => {
  //     getTreatedFbBySkillStats().then(data => setOldTreatedFbBySkillStat(data));
  // }, []);

  const {
    oldTreatedFbBySkillStats,
  } = useSelector(
    state => ({
      oldTreatedFbBySkillStats: getTreatedFbBySkillStatsData(state),
    }),
    shallowEqual
  );

  useEffect(() => {
      dispatch(getTreatedFbBySkillStats());
  }, []);

  // console.log(oldTreatedFbBySkillStats);
  return (
    <Container>
      <Title>
        Ancienne(s) remarque(s) déjà traitée(s) par compétence
      </Title>
      <Content>
        <OldFeedbackCompetenceList>
          {oldTreatedFbBySkillStats && Object.keys(oldTreatedFbBySkillStats).slice(0, 9).map((item, index) => {
            const { feedbacks } = oldTreatedFbBySkillStats[item];

            return (
              <OldFeedbackCompetenceItem key={`key-oldTreatedFbBySkillStat-${index}`}>
                <span>{item || '-'}</span>
                <strong>{feedbacks || '-'}</strong>
                <p>au total</p>
              </OldFeedbackCompetenceItem>
            )
          })}
          {/*<OldFeedbackCompetenceItem>
            <span>RTT</span>
            <strong>140</strong>
            <p>au total</p>
          </OldFeedbackCompetenceItem>
          <OldFeedbackCompetenceItem>
            <span>Mutuel</span>
            <strong>20</strong>
            <p>au total</p>
          </OldFeedbackCompetenceItem>
          <OldFeedbackCompetenceItem>
            <span>Paie</span>
            <strong>37</strong>
            <p>au total</p>
          </OldFeedbackCompetenceItem>
          <OldFeedbackCompetenceItem>
            <span>Congés</span>
            <strong>1</strong>
            <p>au total</p>
          </OldFeedbackCompetenceItem>
          <OldFeedbackCompetenceItem>
            <span>Congés Maladie</span>
            <strong>10</strong>
            <p>au total</p>
          </OldFeedbackCompetenceItem>
          <OldFeedbackCompetenceItem>
            <span>Congés Maternité</span>
            <strong>11</strong>
            <p>au total</p>
          </OldFeedbackCompetenceItem>
          <OldFeedbackCompetenceItem>
            <span>Contrat Employeur</span>
            <strong>90</strong>
            <p>au total</p>
          </OldFeedbackCompetenceItem>
          <OldFeedbackCompetenceItem>
            <span>Note de Frais</span>
            <strong>29</strong>
            <p>au total</p>
          </OldFeedbackCompetenceItem>*/}
        </OldFeedbackCompetenceList>
      </Content>
    </Container>
  );
}

export default OldFeedbackTreatedCompetenceStats;
