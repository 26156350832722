import styled from "styled-components";
import Button from '@material-ui/core/Button';

import { COLORS } from '../../../../../../../../constants/design'

export const MainContainer = styled.div`
  border-left: 1px solid ${COLORS.LIGHT_GREY_1};
  overflow-y: scroll;
  width: 420px;
  padding: 15px;
  >div:not(:last-child) {
    margin-bottom: 15px;
  };
`;

export const StyledTabContainer = styled.div`
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .genericTitleWithIcon {
    >div:not(:last-child) {
      margin-right: 10px;
    };
  }
  && {
    .MuiTab-root {
      /* border: 1px solid red; */
      font-size: 0.8rem;
      min-width: auto;
      text-transform: none;
    }
    .PrivateTabIndicator-colorSecondary-6 {
      background-color: ${COLORS.PRIMARY_BLUE};
    }
  }
`;

export const StyledButton = styled(Button)`
  && {
    &.MuiButton-root {
      position: relative;
      padding: 5px 15px;
      font-size: 0.8rem;
      background-color: ${COLORS.WHITE};
      color: ${COLORS.DARK_GREY};
      border: 1px solid !important;
      border-color: ${COLORS.DARK_GREY} !important;
      transition: all ease-in .15s;
      border: transparent;
      border-radius: 5px;
      text-transform: none;
    }
  }
`;
