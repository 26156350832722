import React from "react";

const WeatherRainIcon = ({
  width = "100%",
  height = "100%",
  colorCloud1 = "#F7F7F7",
  colorCloud2 = "#BBB",
  colorRain = "#2768B3",
}) => {

  return (
    <svg
      className="icon weatherRainIcon"
      width={width}
      height={height}
      viewBox="0 0 600 436"
      fill="none"
    >
      <g fillRule="nonzero" fill="none">
        <path
          d="M552.7 150.1c-.2 0-.4.1-.6.1 3.7-8.6 5.8-18.2 5.8-28.2 0-39.3-31.8-71.1-71.1-71.1-13.9 0-26.8 4-37.7 10.9C434.3 25.8 399 .5 357.7.5c-54.6 0-98.9 44.3-98.9 98.9 0 4.1.3 8.2.8 12.2h-.8c-36.6 0-66.3 29.7-66.3 66.3 0 36.6 29.7 66.3 66.3 66.3h293.9c26 0 47-21.1 47-47 0-26-21.1-47.1-47-47.1z"
          fill={colorCloud2}
        />
        <path
          d="M434.9 212c-.2 0-.5.1-.7.1 4.5-10.4 7-21.9 7-34 0-47.3-38.4-85.7-85.7-85.7-16.7 0-32.3 4.9-45.5 13.2-17.8-43.4-60.5-73.9-110.3-73.9-65.8 0-119.2 53.4-119.2 119.2 0 5 .4 9.9 1 14.7h-1C36.4 165.6.6 201.4.6 245.5c0 44.1 35.8 79.9 79.9 79.9h354.3c31.3 0 56.7-25.4 56.7-56.7 0-31.3-25.3-56.7-56.6-56.7z"
          fill={colorCloud1}
        />
        <path
          d="M121.5 350.4c3.1 3.1 3.1 8.2 0 11.3l-72 72c-1.5 1.5-3.6 2.3-5.6 2.3-2 0-4.1-.8-5.7-2.3-3.1-3.1-3.1-8.2 0-11.3l72-72c3.1-3.1 8.2-3.1 11.3 0zm120.5 0c3.1 3.1 3.1 8.2 0 11.3l-72 72c-1.5 1.5-3.6 2.3-5.6 2.3-2 0-4.1-.8-5.7-2.3-3.1-3.1-3.1-8.2 0-11.3l72-72c3.1-3.1 8.2-3.1 11.3 0zm119.5 0c3.1 3.1 3.1 8.2 0 11.3l-72 72c-1.5 1.5-3.6 2.3-5.6 2.3-2 0-4.1-.8-5.7-2.3-3.1-3.1-3.1-8.2 0-11.3l72-72c3.1-3.1 8.2-3.1 11.3 0z"
          fill={colorRain}
        />
      </g>
    </svg>
  );
};

export default WeatherRainIcon;
