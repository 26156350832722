import React from 'react';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';
import _ from 'lodash';

import {
  MainContainer,
  StyledTabItem,
  StyledDateBlock,
  StyledDateOnly,
  StyledDateInfo,
  StyledDateText,
  StyledDateTitle,
} from './style';


// TODO scroll to todays date

const TicketEventTab = ({ calendarObject, handleEventSelect }) => {
  return (
    <MainContainer>
      {_.map(calendarObject, (object, index) => {
        const date = new Date(object.start);
        const dateString = format(date, "EEEE dd MMMM yyyy", { locale: fr });
        const dateOnly = format(date, "dd", { locale: fr });
        const monthOnly = format(date, "MMM", { locale: fr });
        return (
          <StyledTabItem key={index}>
            <StyledDateBlock type={object?.resource?.type}>
              <StyledDateOnly>{dateOnly}</StyledDateOnly>
              {monthOnly}
            </StyledDateBlock>
            <StyledDateInfo onClick={(target) => handleEventSelect(object, target)} className={'acceptableCalenderObject'}>
              <StyledDateText className={'acceptableCalenderObject'}>
                {dateString}
              </StyledDateText>
              <StyledDateTitle className={'acceptableCalenderObject'}>
                {object.title}
              </StyledDateTitle>
            </StyledDateInfo>
          </StyledTabItem>
        )
      })}
    </MainContainer>
  )
}

export default TicketEventTab;