import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  MainContainer,
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetail
} from './style';

const GenericAccordion = ({
  className,
  headerComponent,
  detailComponent,
  ...rest
}) => {
  return (
    <MainContainer className={`${className ? className : ''} genericAccordion`}>
      <StyledAccordion {...rest}>
        <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
          {headerComponent}
        </StyledAccordionSummary>
        <StyledAccordionDetail>
          {detailComponent}
        </StyledAccordionDetail>
      </StyledAccordion>
    </MainContainer>
  );
};

export default GenericAccordion;