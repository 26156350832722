import * as a from "./actions";
import _ from 'lodash';

const initGetValue = {
  loading: false,
  success: false,
  error: null,
}

const modifyGet = (type, error = null) => {
  return {
    loading: type === 'request' ? true : false,
    success: type === 'success' ? true : false,
    error: type === 'error' ? error : null,
  }
}

const INITIAL_STATE = {
  expressing: {
    login: {
      loading: false,
      success: false,
      error: null,
    },
    logout: {
      loading: false,
      success: false,
      error: null,
    },
    amount: {
      data: null,
      loading: false,
      success: false,
      error: null,
    },
    pastOrders: {
      data: null,
      loading: false,
      success: false,
      error: null,
    },
    onGoingOrders: {
      data: null,
      loading: false,
      success: false,
      error: null,
    },
    selectedPrestation: {
      data: null,
      get: {
        loading: false,
        success: false,
        error: null,
      }
    },
  },
  weather: {
    data: null,
    get: {
      loading: false,
      success: false,
      error: null,
    }
  },
  traffic: {
    data: null,
    get: {
      loading: false,
      success: false,
      error: null,
    }
  },
  paroleCyconia: {
    data: null,
    get: {
      loading: false,
      success: false,
      error: null,
    }
  },
  expertiseAreas: {
    data: null,
    get: {
      loading: false,
      success: false,
      error: null,
    }
  },
  expertiseAreasTheme: {
    data: null,
    get: {
      loading: false,
      success: false,
      error: null,
    }
  },
  serviceCategoryList: {
    data: null,
    get: {
      loading: false,
      success: false,
      error: null,
    }
  },
  servicesOrdersList: {
    data: null,
    get: {
      loading: false,
      success: false,
      error: null,
    }
  },
  servicesCategoryById: {
    data: null,
    get: {
      loading: false,
      success: false,
      error: null,
    }
  },
  selectedParole: {
    data: null,
    get: {
      loading: false,
      success: false,
      error: null,
    }
  },
  selectedServiceTicket: {
    data: null,
    get: initGetValue
  },
  serviceMessages: {
    data: [],
    get: initGetValue
  },
};

export function sidebar(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    // LOGIN EXPRESSING
    case a.LOGIN_EXPRESSING.REQUEST:
      return {
        ...state,
        expressing: {
          ...state.expressing,
          login: {
            loading: true,
            success: false,
            error: null,
          },
        }
      };
    case a.LOGIN_EXPRESSING.SUCCESS:
      return {
        ...state,
        expressing: {
          ...state.expressing,
          login: {
            loading: false,
            success: true,
            error: null,
          }
        }
      };
    case a.LOGIN_EXPRESSING.FAILURE:
      return {
        ...state,
        expressing: {
          ...state.expressing,
          login: {
            loading: true,
            success: false,
            error: action.error,
          }
        }
      };
    case a.LOGIN_EXPRESSING.RESET:
      return {
        ...state,
        expressing: INITIAL_STATE.expressing,
      };
    // LOGOUT EXPRESSING
    case a.LOGOUT_EXPRESSING.REQUEST:
      return {
        ...state,
        expressing: {
          ...state.expressing,
          logout: {
            loading: true,
            success: false,
            error: null,
          },
        }
      };
    case a.LOGOUT_EXPRESSING.SUCCESS:
      return {
        ...state,
        expressing: {
          ...state.expressing,
          logout: {
            loading: false,
            success: true,
            error: null,
          }
        }
      };
    case a.LOGOUT_EXPRESSING.FAILURE:
      return {
        ...state,
        expressing: {
          ...state.expressing,
          logout: {
            loading: true,
            success: false,
            error: action.error,
          }
        }
      };
    // GET AMOUNT EXPRESSING
    case a.EXPRESSING_GET_AMOUNT.REQUEST:
      return {
        ...state,
        expressing: {
          ...state.expressing,
          amount: {
            data: null,
            loading: true,
            success: false,
            error: null,
          },
        }
      };
    case a.EXPRESSING_GET_AMOUNT.SUCCESS:
      return {
        ...state,
        expressing: {
          ...state.expressing,
          amount: {
            data: action.amount,
            loading: false,
            success: true,
            error: null,
          }
        }
      };
    case a.EXPRESSING_GET_AMOUNT.FAILURE:
      return {
        ...state,
        expressing: {
          ...state.expressing,
          amount: {
            data: null,
            loading: true,
            success: false,
            error: action.error,
          }
        }
      };
    // GET EXPRESSING PAST ORDERS
    case a.EXPRESSING_GET_PAST_ORDERS.REQUEST:
      return {
        ...state,
        expressing: {
          ...state.expressing,
          pastOrders: {
            data: null,
            loading: true,
            success: false,
            error: null,
          },
        }
      };
    case a.EXPRESSING_GET_PAST_ORDERS.SUCCESS:
      return {
        ...state,
        expressing: {
          ...state.expressing,
          pastOrders: {
            data: action.pastOrders,
            loading: false,
            success: true,
            error: null,
          }
        }
      };
    case a.EXPRESSING_GET_PAST_ORDERS.FAILURE:
      return {
        ...state,
        expressing: {
          ...state.expressing,
          pastOrders: {
            data: null,
            loading: true,
            success: false,
            error: action.error,
          }
        }
      };
    // GET EXPRESSING ON GOING ORDERS
    case a.EXPRESSING_GET_ONGOING_ORDERS.REQUEST:
      return {
        ...state,
        expressing: {
          ...state.expressing,
          onGoingOrders: {
            data: null,
            loading: true,
            success: false,
            error: null,
          },
        }
      };
    case a.EXPRESSING_GET_ONGOING_ORDERS.SUCCESS:
      return {
        ...state,
        expressing: {
          ...state.expressing,
          onGoingOrders: {
            data: action.onGoingOrders,
            loading: false,
            success: true,
            error: null,
          }
        }
      };
    case a.EXPRESSING_GET_ONGOING_ORDERS.FAILURE:
      return {
        ...state,
        expressing: {
          ...state.expressing,
          onGoingOrders: {
            data: null,
            loading: true,
            success: false,
            error: action.error,
          }
        }
      };
    // DASHBOARD - WEATHER
    case a.GET_WEATHER.REQUEST:
      return {
        ...state,
        weather: {
          data: null,
          loading: true,
          success: false,
          error: null,
        }
      };
    case a.GET_WEATHER.SUCCESS:
      return {
        ...state,
        weather: {
          data: action.weather,
          loading: false,
          success: true,
          error: null,
        }
      };
    case a.GET_WEATHER.FAILURE:
      return {
        ...state,
        weather: {
          data: null,
          loading: false,
          success: false,
          error: action.error,
        }
      };
    // DASHBOARD - TRAFFIC
    case a.GET_TRAFFIC.REQUEST:
      return {
        ...state,
        traffic: {
          data: null,
          loading: true,
          success: false,
          error: null,
        }
      };
    case a.GET_TRAFFIC.SUCCESS:
      return {
        ...state,
        traffic: {
          data: action.traffic,
          loading: false,
          success: true,
          error: null,
        }
      };
    case a.GET_TRAFFIC.FAILURE:
      return {
        ...state,
        traffic: {
          data: null,
          loading: false,
          success: false,
          error: action.error,
        }
      };
    // DASHBOARD - PAROLE CYCONIA
    case a.GET_PAROLE_CYCONIA.REQUEST:
      return {
        ...state,
        paroleCyconia: {
          data: null,
          loading: true,
          success: false,
          error: null,
        }
      };
    case a.GET_PAROLE_CYCONIA.SUCCESS:
      return {
        ...state,
        paroleCyconia: {
          data: action.paroleCyconia,
          loading: false,
          success: true,
          error: null,
        }
      };
    case a.GET_PAROLE_CYCONIA.FAILURE:
      return {
        ...state,
        paroleCyconia: {
          data: null,
          loading: false,
          success: false,
          error: action.error,
        }
      };
    // MES COMPETENCES - SKILLS LIST
    case a.GET_EXPERTISE_AREAS.REQUEST:
      return {
        ...state,
        expertiseAreas: {
          data: null,
          loading: true,
          success: false,
          error: null,
        }
      };
    case a.GET_EXPERTISE_AREAS.SUCCESS:
      return {
        ...state,
        expertiseAreas: {
          data: action.expertiseAreas,
          loading: false,
          success: true,
          error: null,
        }
      };
    case a.GET_EXPERTISE_AREAS.FAILURE:
      return {
        ...state,
        expertiseAreas: {
          data: null,
          loading: false,
          success: false,
          error: action.error,
        }
      };
    // MES COMPETENCES - SKILLS THEMATIQUE LIST
    case a.GET_EXPERTISE_AREAS_THEME.REQUEST:
      return {
        ...state,
        expertiseAreasTheme: {
          data: null,
          loading: true,
          success: false,
          error: null,
        }
      };
    case a.GET_EXPERTISE_AREAS_THEME.SUCCESS:
      return {
        ...state,
        expertiseAreasTheme: {
          data: action.expertiseAreasTheme,
          loading: false,
          success: true,
          error: null,
        }
      };
    case a.GET_EXPERTISE_AREAS_THEME.FAILURE:
      return {
        ...state,
        expertiseAreasTheme: {
          data: null,
          loading: false,
          success: false,
          error: action.error,
        }
      };
    // CONCIERGERIE - SERVICES LIST
    case a.GET_SERVICES_LIST.REQUEST:
      return {
        ...state,
        serviceCategoryList: {
          data: null,
          loading: true,
          success: false,
          error: null,
        }
      };
    case a.GET_SERVICES_LIST.SUCCESS:
      return {
        ...state,
        serviceCategoryList: {
          data: action.serviceCategoryList,
          loading: false,
          success: true,
          error: null,
        }
      };
    case a.GET_SERVICES_LIST.FAILURE:
      return {
        ...state,
        serviceCategoryList: {
          data: null,
          loading: false,
          success: false,
          error: action.error,
        }
      };
    // CONCIERGERIE - SERVICES ORDERS LIST
    case a.GET_SERVICES_ORDERS_LIST.REQUEST:
      return {
        ...state,
        servicesOrdersList: {
          data: null,
          loading: true,
          success: false,
          error: null,
        }
      };
    case a.GET_SERVICES_ORDERS_LIST.SUCCESS:
      return {
        ...state,
        servicesOrdersList: {
          data: action.servicesOrdersList,
          loading: false,
          success: true,
          error: null,
        }
      };
    case a.GET_SERVICES_ORDERS_LIST.FAILURE:
      return {
        ...state,
        servicesOrdersList: {
          data: null,
          loading: false,
          success: false,
          error: action.error,
        }
      };
    // CONCIERGERIE - SERVICES CATEGORY BY ID
    case a.GET_SERVICES_CATEGORY_BY_ID.REQUEST:
      return {
        ...state,
        servicesCategoryById: {
          data: null,
          loading: true,
          success: false,
          error: null,
        }
      };
    case a.GET_SERVICES_CATEGORY_BY_ID.SUCCESS:
      return {
        ...state,
        servicesCategoryById: {
          data: action.servicesCategoryById,
          loading: false,
          success: true,
          error: null,
        }
      };
    case a.GET_SERVICES_CATEGORY_BY_ID.FAILURE:
      return {
        ...state,
        servicesCategoryById: {
          data: null,
          loading: false,
          success: false,
          error: action.error,
        }
      };

    // DASHBOARD - PAROLE CYCONIA - MODAL
    case a.SET_SELECTED_PAROLE.REQUEST:
      return {
        ...state,
        selectedParole: {
          data: null,
          loading: true,
          success: false,
          error: null,
        }
      };
    case a.SET_SELECTED_PAROLE.SUCCESS:
      return {
        ...state,
        selectedParole: {
          data: action.data,
          loading: false,
          success: true,
          error: null,
        }
      };
    case a.SET_SELECTED_PAROLE.FAILURE:
      return {
        ...state,
        selectedParole: {
          data: null,
          loading: true,
          success: false,
          error: action.error,
        }
      };
    // DASHBOARD - EXPRESSING PRESTATION - MODAL
    case a.SET_EXPRESSING_PUBLICATION.REQUEST:
      return {
        ...state,
        expressing: {
          ...state.expressing,
          selectedPrestation: {
            data: null,
            loading: true,
            success: false,
            error: null,
          }
        }
      };
    case a.SET_EXPRESSING_PUBLICATION.SUCCESS:
      return {
        ...state,
        expressing: {
          ...state.expressing,
          selectedPrestation: {
            data: action.data,
            loading: false,
            success: true,
            error: null,
          }
        }
      };
    case a.SET_EXPRESSING_PUBLICATION.FAILURE:
      return {
        ...state,
        expressing: {
          ...state.expressing,
          selectedPrestation: {
            data: null,
            loading: false,
            success: false,
            error: action.error,
          }
        }
      };
    // SIDEBAR - SERVICE TICKET
    case a.GET_SERVICE_TICKET.REQUEST:
      return {
        ...state,
        selectedServiceTicket: {
          data: null,
          get: modifyGet('request')
        },
      };
    case a.GET_SERVICE_TICKET.SUCCESS:
      return {
        ...state,
        selectedServiceTicket: {
          data: action.data,
          get: modifyGet('success')
        },
      };
    case a.GET_SERVICE_TICKET.FAILURE:
      return {
        ...state,
        selectedServiceTicket: {
          data: state.selectedServiceTicket.data,
          get: modifyGet('error', action.error)
        },
      };
    // SIDEBAR - SERVICE MESSAGES
    case a.GET_SERVICE_MESSAGES.REQUEST:
      return {
        ...state,
        serviceMessages: {
          data: [],
          get: modifyGet('request')
        },
      };
    case a.GET_SERVICE_MESSAGES.SUCCESS:
      return {
        ...state,
        serviceMessages: {
          data: action.data,
          get: modifyGet('success')
        },
      };
    case a.GET_SERVICE_MESSAGES.FAILURE:
      return {
        ...state,
        serviceMessages: {
          data: state.serviceMessages.data,
          get: modifyGet('error', action.error)
        },
      };
    case a.SAVE_SERVICE_MESSAGE.REQUEST:
      return {
        ...state,
        serviceMessages: {
          data: state.serviceMessages.data,
          get: modifyGet('request')
        },
      };
    case a.SAVE_SERVICE_MESSAGE.SUCCESS:
      return {
        ...state,
        serviceMessages: {
          // data: _.unionBy([action.data], state.serviceMessages.data, "id"),
          data: [...state.serviceMessages.data, action.data],
          get: modifyGet('success')
        },
      };
    case a.SAVE_SERVICE_MESSAGE.FAILURE:
      return {
        ...state,
        serviceMessages: {
          data: state.serviceMessages.data,
          get: modifyGet('error', action.error)
        },
      };
    default:
      return state;
  }
}

export default sidebar;
