import styled from "styled-components";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import { Link } from 'react-router-dom';

export const TableCellCustom = styled(TableCell)`
  &&{
    padding: 0;
    /* padding: 16px 20px; */
    /* border-bottom: 1px solid transparent; */
    border-bottom: 0;
    transition: all .2s ease-out;
    span{
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 12px;
      line-height: 8px;
      font-weight: 500;
      color: #5A5A5A;
      text-align: left;
      /* padding: 9px 10px; */
      /* max-height: 26px; */
      /* background-color: #ededed;
      border-radius: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; */
      transition: all .2s ease-out;
    }
  }
`;

export const TablePaginationCustom = styled(TablePagination)`
  &&{
    .MuiTablePagination-toolbar{
      display: flex;
      justify-content: flex-start;
      padding: 0 20px;
      .MuiTablePagination-spacer{
        display: none;
        & + p.MuiTablePagination-caption{
          margin: 0;
        }
      }
      .MuiTablePagination-caption{
        font-size: 13px;
        margin: 0 auto;
      }
      .MuiTablePagination-input{
        font-size: 13px;
        margin-right: 0;
      }
      .MuiTablePagination-actions{
        font-size: 13px;
        margin-left: 0;
      }
    }
  }
`;

export const RowTitle = styled.tr`

`;

export const RowContentRowTable = styled.table`
  width: 100%;
`;

export const RowContentRowTd = styled.td`

`;

export const Divider2 = styled.tr`
  /* height: 25px; */
`;

export const RowContentRow = styled.tr`

`;

export const RowContentRowSubRow = styled.tr`
  background-color: #f4f8ff;
  /* background-color: #FFFFFF; */
  transition: all .2s ease-out;
`;

export const RowContentRowTbody = styled.tbody`
  cursor: pointer;
  ${RowContentRowSubRow}{
    &:focus,
    &:hover{
      background-color: #e7effe !important;
    }
    ${TableCellCustom}{
      padding: 0;
      border-bottom: 1px solid #e7effe;
      a{
        padding: 15px 20px;
        height: 100%;
        width: 100%;
        display: flex;
      }
      span{
        transition: all .2s ease-out;
        font-size: 12px;
        font-weight: 500;
        line-height: 13px;
        color: #2B2B2B;
        letter-spacing: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }
`;

export const RowContentRowThead = styled.thead`
  ${RowContentRowSubRow}{
    background-color: #FFFFFF;
    ${RowContentRowTd}{

    }
    ${TableCellCustom}{
      padding: 15px 20px;
      /* background-color: #357ed3; */
      background-color: #40b176;
      border-radius: 0 !important;
      &.cell-content-headLabel{
        span{
          font-size: 12px;
          font-weight: 400;
        }
      }
      span{
        font-size: 14px;
        font-weight: 600;
        line-height: 10px;
        color: #FFFFFF;
        /* color: #5A5A5A; */
        letter-spacing: 0;
      }
    }
  }
`;

export const TableRowContentContainer = styled.table`
  width: 100%;
`;

export const CellContentContainer = styled.td`

`;

export const RowContent = styled.tr`
  &:last-child{
    ${RowContentRowSubRow}{
      &:last-child{
        ${TableCellCustom}{
          border-bottom: 0;
          &:first-child{
            border-radius: 0 0 0 10px;
            &.cell-content-subtitle{
              border-radius: 0;
            }
          }
          &:last-child{
            border-radius: 0 0 10px 0;
            &.cell-content-subtitle{
              border-radius: 0;
            }
          }
        }
      }
    }
  }
`;

export const TheadRowTitle = styled.thead`
  ${RowTitle}{
    ${TableCellCustom}{
      padding: 20px;
      /* background-color: #1c5596; */
      background-color: #357ed3;
      /* background-color: #E8EFFE; */
      border-radius: 10px 10px 0 0;
      span{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: transparent;
        padding: 0;
        border-radius: 0;
        overflow: visible;
        text-overflow: visible;
        white-space: initial;
        font-size: 25px;
        font-weight: 600;
        /* color: #5A5A5A; */
        color: #FFFFFF;
        letter-spacing: 0;
        line-height: 18px;
        strong{
          font-size: 11px;
          line-height: 8px;
          font-weight: 600;
          color: #ffffff;
          text-align: left;
          padding: 7px 8px;
          max-height: 22px;
          margin: 0 0 0 10px;
          background-color: #4A90E2;
          border-radius: 4px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          transition: all .2s ease-out;
        }
      }
      p{
        font-size: 12px;
        font-weight: 500;
        color: #999999;
        letter-spacing: 0;
        line-height: 8px;
        margin: 10px 0 0 0;
      }
    }
  }
`;

export const TbodyRowContent = styled.tbody`
  ${RowContent}{
    ${CellContentContainer}{
      ${TableRowContentContainer}{

      }
    }
  }
`;

export const TheadRowContentRow = styled.thead`
  ${RowContentRow}{
    display: table-row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0;
    background-color: #2768b3;
    ${TableCellCustom}{
      padding: 25px 20px;
      /* border-bottom: 1px solid #F7F7F7; */
      border-bottom: 0;
      &.cell-content-title{
        span{
          position: relative;
          padding-left: 20px;
          &:before{
            content: '';
            position: absolute;
            left: 0;
            /* background-color: #357ED3; */
            background-color: #ffffff;
            border-radius: 50px;
            width: 6px;
            height: 16px;
          }
        }
      }
      &.cell-content-text{
        span{
          font-size: 14px;
          font-weight: 600;
          line-height: 16px;
          text-align: right;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
      span{
        font-size: 16px;
        font-weight: 600;
        line-height: 11px;
        /* color: #5A5A5A; */
        color: #ffffff;
        letter-spacing: 0;
      }
    }
  }
`;

export const TbodyRowContentRow = styled.tbody`
  ${RowContentRow}{
    transition: all .2s ease-out;
    &:hover,
    &:focus{
      /* background-color: #e7effe; */
      /* background-color: #e4ecf5; */
      /* tr,td{
        background-color: transparent;
      } */
      ${RowContentRowSubRow}{
        /* background-color: transparent; */
        ${TableCellCustom}{
          &.cell-content-subtitle{
            span{
              /* color: #357ED3; */
            }
          }
        }
      }
    }
    ${RowContentRowTd}{

    }
  }
`;

export const TableRowContainer = styled.table`
  width: 100%;
`;

export const TableCellContainer = styled.td`

`;

export const Divider = styled(TableRow)`
  &&{
    &.MuiTableRow-root{
      height: 60px;
    }
  }
`;

export const TableRowCustom = styled(TableRow)`
  &&{
    &.MuiTableRow-root{
    }
    &.MuiTableRow-hover{
      &:first-child{
        ${TheadRowTitle}{
          ${TableCellCustom}{
            border-radius: 10px 10px 0 0;
          }
        }
      }
      &:hover,
      &:focus{
        background-color: transparent;
        ${TableCellCustom}{
          /* border-bottom: 1px solid transparent; */
        }
      }
    }
  }
`;

export const TableCustom = styled(Table)`
  &&{
    min-width: 750px;
  }
`;

export const PaperCustom = styled(Paper)`
  &&{
    width: 100%;
    margin-bottom: 16px;
    box-shadow: none;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  padding: 0 20px;
`;

export const Container = styled.div`
  table, thead, tbody, tfoot, td, th{
    border-collapse: collapse;
    border: 0;
    padding: 0;
  }
`;
