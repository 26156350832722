import axios from 'axios'

export const addNewServiceProvider = async (providerData, endpoint, userToken) => {
  const { chooseFile, society, phoneNumber, name, email, note, services, sites, website, address } = providerData
  let servicesList = {}
  let sitesList = {}
  services.map((service, index) => {
    servicesList[index] = JSON.stringify(service.value)
  })
  sites.map((site, index) => {
    sitesList[index] = JSON.stringify(site.value)
  })

  const newProvider = new FormData();
  newProvider.append("company", society)
  newProvider.append("phoneNumber", phoneNumber)
  newProvider.append("manager", name)
  newProvider.append("email", email)
  newProvider.append("note", note)
  newProvider.append("services", JSON.stringify(servicesList))
  newProvider.append("companySites", JSON.stringify(sitesList))
  chooseFile?.website !== "" && newProvider.append("website", website);
  chooseFile?.address !== "" && newProvider.append("address", address);
  chooseFile?.name != null && newProvider.append("file", chooseFile);

  const headers = {};
  if (userToken) {
    headers.Authorization = `Bearer ${userToken}`;
  }

  return axios.request({
    method: 'post',
    url: endpoint,
    data: newProvider,
    headers: headers
  });
}

export const editServiceProvider = async (providerData, endpoint, userToken) => {
  const { chooseFile, society, phoneNumber, name, email, note, services, sites, website, address } = providerData
  let servicesList = {}
  let sitesList = {}
  services.map((service, index) => {
    servicesList[index] = JSON.stringify(service.value)
  })
  sites.map((site, index) => {
    sitesList[index] = JSON.stringify(site.value)
  })
  const newProvider = new FormData();
  newProvider.append("company", society)
  newProvider.append("phoneNumber", phoneNumber)
  newProvider.append("manager", name)
  newProvider.append("email", email)
  newProvider.append("note", note)
  newProvider.append("services", JSON.stringify(servicesList))
  newProvider.append("companySites", JSON.stringify(sitesList))
  chooseFile?.website !== "" && newProvider.append("website", website);
  chooseFile?.address !== "" && newProvider.append("address", address);
  chooseFile?.name != null && newProvider.append("file", chooseFile);

  const headers = {};
  if (userToken) {
    headers.Authorization = `Bearer ${userToken}`;
  }

  return axios.request({
    method: 'post',
    url: endpoint,
    data: newProvider,
    headers: headers
  });
}
export const deleteServiceProvider = async (endpoint, userToken, providerId) => {
  let url = `${endpoint}${providerId}`;

  const headers = {};
  if (userToken) {
    headers.Authorization = `Bearer ${userToken}`;
  }

  return axios.request({
    method: 'delete',
    url: url,
    headers: headers
  });
}