import React from "react";

const DesktopMessageIcon = ({
  color = "#FFFFFF",
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon desktopMessageIcon"
      width={width}
      height={height}
      viewBox="0 0 15 15"
      fill="none"
    >
      <path
        d="M12.477 10.064a.508.508 0 01-.508-.507v-.711a.508.508 0 111.015 0v.71c0 .281-.227.508-.507.508zM3.03 9.557V5.824c0-1.12.911-2.031 2.031-2.031h.94a.508.508 0 100-1.016h-.94a3.05 3.05 0 00-3.046 3.047v3.733a.508.508 0 101.015 0zM14 12.477c0-.84-.683-1.524-1.523-1.524H2.523c-.84 0-1.523.684-1.523 1.524S1.683 14 2.523 14h9.954c.84 0 1.523-.683 1.523-1.523zm-1.523-.508a.508.508 0 010 1.015H2.523a.508.508 0 010-1.015h9.954zM8.15 7.554l.046-.046a.81.81 0 01.935-.153c.464.231.965.348 1.49.348h.002a3.35 3.35 0 002.365-.966c.635-.626.99-1.461.998-2.353a3.333 3.333 0 00-.969-2.395A3.327 3.327 0 0010.636 1c-.899 0-1.744.353-2.38.995a3.327 3.327 0 00-.972 2.387v2.813a.508.508 0 00.867.36h0zM8.3 6.316V4.38v-.005a2.319 2.319 0 01.678-1.665 2.318 2.318 0 011.658-.694 2.325 2.325 0 012.336 2.359 2.348 2.348 0 01-2.348 2.313h-.002c-.366 0-.715-.082-1.038-.242a1.822 1.822 0 00-1.284-.13z"
        fillRule="nonzero"
        fill={color}
        stroke={color}
        strokeWidth={0.5}
      />
    </svg>
  );
};

export default DesktopMessageIcon;
