import React from "react";

import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useHistory
} from "react-router-dom";

import RoomBooking from '../../../RoomBooking';

const Routes = () => {
  const history = useHistory();
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/roomBooking/:date?`} render={() => {
        return (
          <RoomBooking
            onClose={() => history.push(`${path}`)}
          />
        )
      }}
      />
      <Redirect to={`${path}`} />
    </Switch>
  );
}

export default Routes;
