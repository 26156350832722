import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';

export const Text = styled.p`
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
  line-height: 18px;
  max-width: 420px;
  margin: 0 auto 10px auto;
`;

export const Title = styled.h2`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
`;

export const ModalCustom = styled(Dialog)`
  &&{
    .MuiDialog-container{
      .MuiDialog-paper{
        padding: 30px;
        box-sizing: border-box;
        text-align: center;
        form{
          .input-group{
            position: relative;
            margin-top: 30px;
            margin-bottom: 15px;
            & > label{
              font-size: 12px;
              font-weight: 900;
              color: #838383;
              margin-bottom: 10px;
              display: block;
            }
            & > div{
              position: relative;
              input{
                height: 60px;
                font-size: 14px;
                color: #5a5a5a;
                border-radius: 6px;
                display: block;
                width: 100%;
                padding: 14px 20px;
                box-sizing: border-box;
                border: none;
                background-color: #f5f5f5;
                outline: none;
                font-weight: 800;
              }
              svg{
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
              }
            }
          }
        }
        button{
          display: block;
          width: 100%;
          padding: 14px;
          text-align: center;
          font-weight: 700;
          border: none;
          text-decoration: none;
          border-radius: 6px;
          cursor: pointer;
          font-size: 14px;
          height: 60px;
          margin-top: auto;
          background-color: #463e96;
          color: #fff;
          text-transform: uppercase;
        }
      }
    }
  }
`;