import React from 'react'
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

import {
  Wrapper,
  LabelStyle,
  RequiredLabel
} from './style'

const GenericTitleWithToolTip = ({
  title = "",
  toolTipText = null,
  ToolTipIcon = InfoIcon,
  toolTipProps = {},
  isRequired = false,
  className, // to pass styled component className to wrapper
}) => {
  return (
    <Wrapper className={`${className ? className : ''} textTitleWrapper`}>
      {title &&
        <LabelStyle className={'textTitle'}>
          {title}
          {isRequired &&
            <RequiredLabel>
              *
            </RequiredLabel>
          }
        </LabelStyle>
      }
      {toolTipText &&
        <Tooltip className={'textTooltip'} title={toolTipText} {...toolTipProps}>
          <ToolTipIcon className={'textTooltip'} style={{ fontSize: 18 }} />
        </Tooltip>
      }
    </Wrapper>
  )
}

GenericTitleWithToolTip.propTypes = {
  title: PropTypes.string,
  toolTipText: PropTypes.any,
  ToolTipIcon: PropTypes.any,
  toolTipProps: PropTypes.any,
  className: PropTypes.string,
}

export default GenericTitleWithToolTip

// The provided className of individual tag can be used to be styled when wrapped by styled component
// For the styling of material ui component please refer to the style.js