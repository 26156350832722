export const INIT = {
  REQUEST: 'COMPANY/INIT/REQUEST',
  SUCCESS: 'COMPANY/INIT/SUCCESS',
  FAILURE: 'COMPANY/INIT/FAILURE'
};

export const GET_MODULES = {
  REQUEST: 'COMPANY/GET_MODULES/REQUEST',
  SUCCESS: 'COMPANY/GET_MODULES/SUCCESS',
  FAILURE: 'COMPANY/GET_MODULES/FAILURE'
};

export const SAVE_COMPANY_SETTINGS = {
  REQUEST: 'COMPANY/SAVE_COMPANY_SETTINGS/REQUEST',
  SUCCESS: 'COMPANY/SAVE_COMPANY_SETTINGS/SUCCESS',
  FAILURE: 'COMPANY/SAVE_COMPANY_SETTINGS/FAILURE'
};

export const GET_COMPANY_CONTEXT = {
  REQUEST: 'COMPANY/GET_COMPANY_CONTEXT/REQUEST',
  SUCCESS: 'COMPANY/GET_COMPANY_CONTEXT/SUCCESS',
  FAILURE: 'COMPANY/GET_COMPANY_CONTEXT/FAILURE'
};

/**
 * COMPANY - INIT
 */
 export const companyInit = () => ({
  type: INIT.REQUEST,
});

export const companyInitSuccess = (data) => ({
  type: INIT.SUCCESS,
  data,
});

export const companyInitError = (error) => ({
  type: INIT.FAILURE,
  error,
});

/**
 * COMPANY - GET MODULES
 */
 export const companyGetModules = () => ({
  type: GET_MODULES.REQUEST,
});

export const companyGetModulesSuccess = (data) => ({
  type: GET_MODULES.SUCCESS,
  data,
});

export const companyGetModulesError = (error) => ({
  type: GET_MODULES.FAILURE,
  error,
});

export const saveCompanySettings = (data) => ({
  type: SAVE_COMPANY_SETTINGS.REQUEST,
  data
});

export const saveCompanySettingsSuccess = (data) => ({
  type: SAVE_COMPANY_SETTINGS.SUCCESS,
  data,
});

export const saveCompanySettingsError = (error) => ({
  type: SAVE_COMPANY_SETTINGS.FAILURE,
  error,
});

export const getCompanyContext = () => ({
  type: GET_COMPANY_CONTEXT.REQUEST,
});

export const getCompanyContextSuccess = (data) => ({
  type: GET_COMPANY_CONTEXT.SUCCESS,
  data,
});

export const getCompanyContextError = (error) => ({
  type: GET_COMPANY_CONTEXT.FAILURE,
  error,
});
