import styled from "styled-components";

import { COLORS } from '../../../../../../constants/design';
import DrawerCpt from '../../../../components/organisms/drawerCpt';
import GenericDrawerFormContainer from "../../../../../../components/molecules/GenericDrawerFormContainer";

export const Container = styled.div`
`;

export const DrawerCptCustom = styled(DrawerCpt)`
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 700px;
  height: 100%;
  position: relative;
  box-sizing: border-box;
`;

export const StyledFormContainer = styled(GenericDrawerFormContainer)`
  .genericMaterialMenu {
    .textFieldTitle {
      margin-bottom: 10px;
    }
  }
  .MuiCheckbox-root {
    padding: 0px;
    margin-right: 10px;
  }
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: ${COLORS.PRIMARY_BLUE};
  }
  .MuiCheckbox-colorSecondary.Mui-disabled {
    color: ${COLORS.LIGHT_GREY_4};
  }
`;

export const SettingContainer = styled.div`
  color: ${props => props.active ? COLORS.DARK_GREY : COLORS.LIGHT_GREY_4};
  .genericCheckBox {
    .textTitle {
      /* font-weight: 700; */
      font-size: 0.9rem !important;
      color: ${COLORS.DARK_GREY};
      margin: 14px 5px 14px 0px;
    }
  }
  >div:not(:last-child) {
    margin-bottom: 10px;
  };
`;