import React from 'react';

// Import third party packages
import Linkify from 'react-linkify';
import Parser from 'html-react-parser';

// Import services 
import botFormService from '../../../../../../services/bot_form';
import botPositiveFeedService from '../../../../../../services/bot_positive_feedback';
import botApiFormService from '../../../../../../services/bot_api_form';
import declineBotReplyProposal from '../../../../../../services/bot_declined_reply';
import customSmalltalkIntentService from '../../../../../../services/customSmalltalkIntentService.js';
// import getArticleNewsService from '../../../services/getArticleNews';
// import updateIntroStatusService from '../../../services/finishIntroService';

// Import icons
import IconEditBotMsg from '../../../../../../assets/icons/IconEdit.js';

let countBtn = 0;
let btnCnt = 0;

async function transmitData(e, ids, props, dataIn) {
  e.preventDefault();

  // Format for news
  if (dataIn.indexOf("news") > -1) {
    dataIn = dataIn.replace(/:"/g, '":"');
    dataIn = dataIn.replace(/{/g, '{"');
    dataIn = dataIn.replace(/",/g, '","');
  }

  let data = JSON.parse(dataIn);
  let response, elem1, elem2;

  switch (data.type) {
    // Handle RGOD
    // case "rgpd":
    //   try {
    //     let elem = document.getElementById("rgpd")
    //     if (elem) {
    //       elem.classList.add("disabled");
    //       elem.innerHTML = "Vos préférences ont été sauvegardées";
    //     }
    //       await updateIntroStatusService();
    //   } catch (err) {
    //     console.log(err);
    //   }
    //   break;
    // TODO disable second click
    case "bot_negative_feedback": // [BOTMAN DONE CHECK]
      elem1 = document.getElementById(ids.id2);
      elem2 = document.getElementById(ids.id1);
      if (elem1 && elem2) {
        elem2.style.visibility = "visible";
        elem2.style.backgroundColor = "#EC6B87";
        // elem1.style.visibility = "hidden";
      }
      // response = await botFormService(data);
      // props.fireNegativeFeedback(data, response);
      props.onBotmanFeedbackClick(data, props.messageIndex);
      break;
    case "bot_positive_feedback":  // [BOTMAN DONE CHECK]
      elem1 = document.getElementById(ids.id2);
      elem2 = document.getElementById(ids.id1);
      if (elem1 && elem2) {
        elem1.style.visibility = "visible";
        elem1.style.backgroundColor = "#69B86A";
        // elem2.style.visibility = "hidden";
      }
      props.onBotmanFeedbackClick(data, props.messageIndex);
      // await botPositiveFeedService(data);
      break;
    case "api": // [BOTMAN DONE CHECK]
      // response = await botApiFormService(data);
      // props.apiFormcall(data, response);
      const element = {
        type: 'button-form',
        transmitData: {
          ...data.payload,
          type: 'form',
        }
      }
      props.onMessageButtonClick(null, element);
      break;

    case "url_blank":
      // Handled
      window.open(data.payload, '_blank');
      break;
    default:
      break;
  }
}

// [BOTMAN DONE CHECK]
async function customSmalltalkTextType(e, props, userReply1, userReply2, botReply, thisIn, data) { // using chatbotV3 function for button-text
  e.preventDefault();
  countBtn = 0;

  // props.userOrBotInMsg(userReply1.replace(/"/g, ""), 'me');
  // setTimeout(() => {
  //   props.isBotLoading(true);
  // })

  let jsonType = "";
  if (data)
    jsonType = JSON.parse(data);
  const element = {
    type: 'customSmalltalkTextType',
    userReply1: userReply1.replace(/"/g, ""),
    userReply2: userReply2,
    botReply: botReply,
    jsonType: jsonType
  }
  props.onMessageButtonClick(null, element);
  // let response = await declineBotReplyProposal(userReply2, botReply, jsonType);
  // console.log('declineBotReplyProposal', response);

  // let resFormat = "";
  // if (response.messages[0].text[0] === '"')
  //   resFormat = response.messages[0].text.replace(/"/, "");
  // else resFormat = response.messages[0].text;

  // if (resFormat[resFormat.length - 1] === '"')
  //   resFormat = resFormat.slice(0, -1);

  // resFormat = resFormat.replace(/\\"/g, "'");
  // resFormat = resFormat.replace(/\\'/g, "'");

  // resFormat = resFormat.replace(/€/g, "&euro;");

  // const formatedResponse = {
  //   ...response,
  // }
  // formatedResponse.messages[0].text = resFormat;

  // props.isBotLoading(false);

  

  // props.userOrBotInMsg(resFormat, 'them')
}

function cleanJsonData(dataIn, funcName) {
  let start = dataIn.indexOf(funcName + "(")
  let end = dataIn.indexOf('})');
  let data = dataIn.substring(start + funcName.length + 1, end + 1);
  data = data.replace(/\'/g, '"');
  return data;
}

function customSmalltalkTextTypeFomatData(funuctionName, dataIn) {
  let start = dataIn.indexOf(funuctionName + "(")
  let end = dataIn.indexOf('})');
  if (end === -1)
    end = dataIn.indexOf('this)');

  let data = dataIn.substring(start + funuctionName.length + 1, end + 4);

  data = data.replace(/\'/g, '"');

  if (data.indexOf('{"type":') !== -1) {
    let startType = data.indexOf('{"type":')
    let endType = data.indexOf('"}');
    let params = data.substring(0, startType);
    let typeData = data.substring(startType, endType + 2);
    let paramsArr = params.split(',');
    return ({
      paramsArr,
      typeData
    })
  } else {
    let paramsArr = data.split(',');
    return ({
      paramsArr
    })
  }
}

// Leave a feedback
function leaveFeddback(e, props) { // [BOTMAN DONE CHECK]
  e.preventDefault();
  countBtn = 0;
  // props.toggleFeedbackModal();
  const element = {
    type: 'button-form',
    transmitData: {
      type: 'feedback',
    }
  }
  props.onMessageButtonClick(null, element);
}

// Chauffagiste
function getIndicesOf(searchStr, str, caseSensitive) {
  var searchStrLen = searchStr.length;
  if (searchStrLen == 0) {
    return [];
  }
  var startIndex = 0, index, indices = [];
  if (!caseSensitive) {
    str = str.toLowerCase();
    searchStr = searchStr.toLowerCase();
  }
  while ((index = str.indexOf(searchStr, startIndex)) > -1) {
    indices.push(index);
    startIndex = index + searchStrLen;
  }
  return indices;
}


function getTextContent(pos, data, text) {

  // console.log("text =>", text);
  if (text !== "") {
    return text.replace(/"/g, "");
  } else if (data.text.search("span>") !== -1) {
    let startIndices = getIndicesOf('<span style="margin-left: 15px">', data.text)
    let endIndices = getIndicesOf('</span>', data.text)

    // Handle multi buttons bot response
    if (countBtn === 0) {
      countBtn = data.text.match(/<\/button>/g).length;
      if (data.text.indexOf("J’ai une remarque") !== -1) countBtn--;
      btnCnt = 0;
    } else countBtn--;

    if (countBtn > 0 && endIndices[btnCnt] !== undefined) {
      if (countBtn === 1) countBtn = 0;
      btnCnt++;
      return data.text.substring(startIndices[btnCnt - 1] + 32, endIndices[btnCnt - 1]);
    } else if (pos === 1 || pos === 2)
      return data.text.substring(startIndices[pos - 1] + 32, endIndices[pos - 1]);
    else if (pos === 0) {
      // TODO MORE TEST FOR A NEEDED GERALE TEXT
      return "Non, je n'ai pas l'information";
    }
    else
      return data.text.substring(data.text.search('<span style="margin-left: 15px">') + 32, data.text.search("</span>"));
  }
  return text.replace(/"/g, "");
}

// Handle customSmalltalkIntentType
async function customSmalltalkIntentType(e, props, title, userReply, thisIn) { // using chatbotV3 function for button-intent
  e.preventDefault();
  const element = {
    type: 'customSmalltalkIntentType',
    title: title,
    userReply: userReply.replace(/"/g, ""),
  }
  props.onMessageButtonClick(null, element);
  // countBtn = 0;

  // props.userOrBotInMsg(userReply.replace(/"/g, ""), 'me');

  // setTimeout(() => {
  //   props.isBotLoading(true);
  // })


  // let response = await customSmalltalkIntentService(title, userReply);
  // props.isBotLoading(false);

  // if (response.messages.length > 0) {
  //   response.messages.map(msg => {
  //     if (msg.text.indexOf("button") !== -1)
  //       props.userOrBotInMsg(msg.text, 'them');
  //     else props.userOrBotInMsg(msg.text.replace(/"/g, ""), 'them');
  //   })
  // }
}

function checkUserPermission() {
  let user = JSON.parse(localStorage.getItem('user'));

  let objKeys;

  if (user && user.permissions)
    objKeys = Object.keys(user.permissions);
  if (objKeys && objKeys.length > 0) {
    if (objKeys.includes("Modifier une réponse"))
      return true;
    else return false;
  } else return false;
}


function checkEiidExist(data) { // [BOTMAN DONE CHECK]
  return data.indexOf("informationElementId") === -1
    ? false : true;
}

function dataParserAll(data, props) {
  let ids = { id1: 0, id2: 0 };
  let counts = 0;
  let isAllowed = checkUserPermission();

  try {
    // FIX URL EMBEDED
    props.data.text = props.data.text.replace("data-payload", "href");
    props.data.text = props.data.text.replace('href="#"', "");
    props.data.text = props.data.text.replace('data-type="url_blank"', 'data-type="url_blank" target="__blank"');
  } catch (err) { return; };

  // return <div dangerouslySetInnerHTML={{__html: props.data.text}} ></div>

  return Parser(props.data.text, {
    replace: function (domNode) {
      // if (domNode.name === "br")
      //   return <span style={{ position: "absolute", height: '0px', width: '0px' }}></span>;

      // console.log("domNode :", domNode);

      // Handle rgpd button [AVAILABLE IN NEW RESPONSE]
      if (domNode.name === "button" && domNode.attribs.class === "btn_rgpd") {

        let data = '{"type": "rgpd"}';

        return <button id="rgpd" class="btn" onClick={(e) => transmitData(e, "", props, data)}>
          <span style={{ marginLeft: '15px' }}>
          C’est noté, merci de ne plus m’afficher ces informations concernant le RGPD.
          <em>(Vous pouvez toujours accéder à notre politique de traitement ou contacter nos équipes)</em>
          </span></button>;
      }

      // btn_negative_feedback and Edit response [BOTMAN DONE CHECK]
      else if (domNode.name === "button" && domNode.attribs.onclick && domNode.attribs.class === "btn_negative_feedback") {
        let data = cleanJsonData(domNode.attribs.onclick, "transmitData");
        let action = null;
        let companyResponseElement = null;
        let informationElementId = null;

        try {
          let dataValues = JSON.parse(data);
          informationElementId = dataValues.payload.defaultValues.informationElementId;
          action = dataValues.payload.defaultValues.action;
          companyResponseElement = dataValues.payload.defaultValues.companyResponseElement
        } catch (err) { action = null };


        if (checkEiidExist(data)) {
          if (isAllowed && companyResponseElement !== null) {
            let pathUrl = window.location.href.substr(0, window.location.href.indexOf("/dashboard"))
            return <span><button id={ids.id1} className="btn_negative_feedback"
              onClick={(e) => transmitData(e, ids, props, data)}>👎</button>
              <button id={ids.id1} className="btn_edit_msg"
                // onClick={(e) => { window.location.href = pathUrl + "/admin/dashboard?opendrawerbyid=" + informationElementId }}>
                onClick={() => {
                  window.open(pathUrl + "/admin/dashboard?opendrawerbyid=" + informationElementId, '_blank');
                }}
              >
                <IconEditBotMsg /> V1
              </button></span>;
          } else return <button id={ids.id1} className="btn_negative_feedback" onClick={(e) => transmitData(e, ids, props, data)}>👎</button>;
        } else return <span id={ids.id1}></span>

        // btn_positive_feedback [BOTMAN DONE CHECK]
      } else if (domNode.name === "button"
        && domNode.attribs.class === "btn_positive_feedback") {
        let data = cleanJsonData(domNode.attribs.onclick, "transmitData");
        let id1 = Math.floor(Math.random() * 999999);
        let id2 = Math.floor(Math.random() * 999999);
        ids.id1 = id1;
        ids.id2 = id2;

        if (checkEiidExist(data)) {
          return <button id={ids.id2} className="btn_positive_feedback"
            onClick={(e) => transmitData(e, ids, props, data)}>👍</button>;
        } else return <span id={ids.id2}></span>;;

        // AskHelpType btn1
      } else if (domNode.name === "button"
        && domNode.attribs.class === "btn"
        && domNode.attribs
          .onclick.indexOf("CollaboratorsBundle/Form/Bot/AskHelpType") !== -1) {

        let data = cleanJsonData(domNode.attribs.onclick, "transmitData");

        return <button class="btn" onClick={(e) => transmitData(e, "", props, data)}>
          <i class="fab fa-wpforms fa-lg"></i>
          <span style={{ marginLeft: '15px' }}>
            {getTextContent(1, props.data, "")}
          </span></button>;

        // AskHelpType btn2
      } else if (domNode.name === "button"
        && domNode.attribs.class === "btn"
        && domNode.attribs.onclick.indexOf("CollaboratorsBundle/Form/Bot/AskSmalltalkReplyType") !== -1) {

        let data = cleanJsonData(domNode.attribs.onclick, "transmitData")

        return <button class="btn"
          onClick={(e) => transmitData(e, "", props, data)}><i class="fab fa-wpforms fa-lg"></i>
          <span style={{ marginLeft: '15px' }}>{getTextContent(2, props.data, "")}</span></button>;
      }

      // Declined reply btn3 [BOTMAN DONE CHECK]
      else if (domNode.name === "button"
        && domNode.attribs.type === "button"
        && domNode.attribs["data-type"] === "text"
        && domNode.attribs.class === "btn btn-full-width") {

        let results = customSmalltalkTextTypeFomatData("customSmalltalkTextType", domNode.attribs.onclick);

        // console.log("results:" ,results)
        if (results !== false) {
          let paramsArr = results.paramsArr;
          let typeData = results.typeData;
          let className = domNode.attribs.onclick.indexOf("decline_reply_proposal") === -1 ? "disableBtn" : "declined";

          let botReply = "";
          let i = 2;
          while (i < paramsArr.length) {
            if (paramsArr[i] !== " this")
              botReply += paramsArr[i];
            i++;
          }

          return <button type="button" data-type="text" className={className + " btn btn-full-width"}
            onClick={(e) => customSmalltalkTextType(e, props, paramsArr[1], paramsArr[0], botReply, " this", typeData)}>
            {getTextContent(0, props.data, paramsArr[0])}</button>
        }

        return;
      }

      // customSmalltalkIntentType reply [BOTMAN DONE CHECK]
      else if (domNode.name === "button"
        && domNode.attribs.type === "button"
        && domNode.attribs["data-type"] === "intent"
        && domNode.attribs.class === "btn btn-full-width") {

        let results = customSmalltalkTextTypeFomatData("customSmalltalkIntentType", domNode.attribs.onclick);

        if (results !== false) {
          let paramsArr = results.paramsArr;
          let className2 = domNode.attribs.onclick.indexOf("decline_reply_proposal") === -1 ? "disableBtn" : "declined";

          return <button type="button" data-type="intent" className={className2 + " btn btn-full-width"}
            onClick={(e) => customSmalltalkIntentType(e, props, paramsArr[0], paramsArr[1], paramsArr[3])}>
            {getTextContent(12, props.data, paramsArr[0])}</button>
        }
        return;
      }

      // Partners / services btn [BOTMAN DONE CHECK]
      else if (domNode.name === "button"
        && domNode.attribs.class === "btn" &&
        domNode.attribs.onclick.indexOf("transmitData") !== -1
        && domNode.attribs.onclick.indexOf("api") !== -1
        && domNode.attribs.onclick.indexOf("feedback") === -1
      ) {

        let data = cleanJsonData(domNode.attribs.onclick, "transmitData");

        return <React.Fragment><button style={{ marginTop: '10px' }} class="btn" onClick={(e) => transmitData(e, "", props, data)}>
          <i className="fab fa-wpforms fa-lg"></i>
          <span style={{ marginLeft: '15px' }}>{getTextContent(1, props.data, "")}</span></button></React.Fragment>
      }

      // Handle leave a feedback [BOTMAN DONE CHECK]
      else if (domNode.name === "button" &&
        domNode.attribs.class === "btn" &&
        domNode.attribs.onclick.indexOf("transmitData") !== -1
        && domNode.attribs.onclick.indexOf("transmitData({'type': 'feedback'})") !== -1
      ) {

        return <button class="btn" onClick={(e) => leaveFeddback(e, props)}><i class="fab fa-wpforms fa-lg"></i>
          <span style={{ marginLeft: "15px" }}>J’ai une remarque</span></button>
      }

      //Handle form [BOTMAN DONE CHECK]
      else if (domNode.name === "button"
        && domNode.attribs["data-type"] === "form"
        && domNode.attribs.onclick.indexOf("transmitData") !== -1
        && domNode.attribs.onclick.indexOf("api") !== -1) {

        let data = cleanJsonData(domNode.attribs.onclick, "transmitData");

        return <React.Fragment>
          <button data-type="form" className="btn btn-full-width"
            onClick={(e) => transmitData(e, "", props, data)}>
            <i class="fab fa-wpforms fa-lg"></i>
            <span style={{ marginLeft: "15px" }}>{getTextContent(1, props.data, "")}</span>
          </button></React.Fragment>
      }

      // Handle news modals [AVAILABLE IN NEW RESPONSE]
      else if (domNode.name === "button"
        && domNode.attribs.class === "btn bot-event-news"
        && domNode.attribs.onclick.indexOf("transmitData") !== -1
        && domNode.attribs.onclick.indexOf("news") !== -1) {

        let data = cleanJsonData(domNode.attribs.onclick, "transmitData");

        return <React.Fragment>
          <button
            class="btn btn-full-width"
            onClick={(e) => transmitData(e, "", props, data)}>
            {domNode.children ?
              domNode.children[0].data : 'NONE'}</button></React.Fragment>
      }

      // [BOTMAN DONE CHECK]
      else if (domNode.attribs === undefined && domNode.next !== null) {
        try {
          if (domNode.next.name === "a" && domNode.next.attribs["data-payload"] !== undefined && domNode.next.attribs.href !== undefined) {
            domNode.next.attribs.href = domNode.next.attribs["data-payload"];
            domNode.next.attribs.target = "_blank";
            domNode.next.attribs.style = "text-decoration: underline; color: #0000EE;"
          } return { domNode }
        } catch (err) {
          return { domNode }
        }
      }
    }
  })
}

const BotmanTextMessage = (props) => {
  // console.log('botman', props);
  return <div className="chatbot-sc-message--text">{
    <Linkify properties={{ target: '_blank' }}>{dataParserAll(props.data, props)}</Linkify>
  }</div>;
}

export default BotmanTextMessage;