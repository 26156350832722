import axios from 'axios'

export function editConciergeService(endpoint, userToken, formData) {
  const newForm = new FormData();
  newForm.append("name", String(formData.name))
  newForm.append("presentation", String(formData.presentation))
  newForm.append("mail", String(formData.email))
  newForm.append("phone", String(formData.phoneNumber))
  newForm.append("serviceHighlighted", String(formData.service1))
  newForm.append("serviceHighlighted2", String(formData.service2))
  newForm.append("serviceHighlighted3", String(formData.service3))
  newForm.append("companySite", String(formData.site))

  // console.log('id', id);
  // console.log('newForm', ...newForm);
  // console.log('formData', formData);

  const headers = {};
  if (userToken) {
    headers.Authorization = `Bearer ${userToken}`;
  }

  return axios.request({
    method: 'post',
    url: endpoint,
    data: newForm,
    headers: headers
  });

}
