import React from "react";

const ArchiveFolderIllustration = ({
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon archiveFolderIllustration"
      width={width}
      height={height}
      viewBox="0 0 140 113"
      fill="none"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="archiveFolderIllustration"
          fillRule="nonzero"
        >
            <path d="M45.6213254,51.8785424 L42.2928136,51.8785424 L42.2928136,50.5490644 L45.6213254,50.5490644 C46.5364781,50.5490644 47.2783559,49.8071866 47.2783559,48.8920339 C47.2783559,47.9768812 46.5364781,47.2350034 45.6213254,47.2350034 L42.2928136,47.2350034 L42.2928136,45.9055254 L45.6213254,45.9055254 C47.2707285,45.9055254 48.6078339,47.2426308 48.6078339,48.8920339 C48.6078339,50.541437 47.2707285,51.8785424 45.6213254,51.8785424 L45.6213254,51.8785424 Z" id="Path" fill="#357ED3"></path>
            <path d="M45.6213254,51.8785424 L42.2928136,51.8785424 L42.2928136,50.5490644 L45.6213254,50.5490644 C46.5364781,50.5490644 47.2783559,49.8071866 47.2783559,48.8920339 C47.2783559,47.9768812 46.5364781,47.2350034 45.6213254,47.2350034 L42.2928136,47.2350034 L42.2928136,45.9055254 L45.6213254,45.9055254 C47.2707285,45.9055254 48.6078339,47.2426308 48.6078339,48.8920339 C48.6078339,50.541437 47.2707285,51.8785424 45.6213254,51.8785424 L45.6213254,51.8785424 Z" id="Path" fill="#FFFFFF" opacity="0.61"></path>
            <ellipse id="Oval" fill="#E6E6E6" opacity="0.45" cx="70.1155119" cy="104.52298" rx="69.8794814" ry="9.72060339"></ellipse>
            <path d="M113.559576,26.7918712 C112.904471,36.9411831 108.05862,62.2494339 75.2985492,48.0153492 L76.4257153,44.6723864 C76.4257153,44.6723864 92.6154814,47.5288373 96.8977492,41.5221017 C100.520095,36.4354034 100.462292,19.7543085 108.458427,20.5491051 C111.557118,20.9265699 113.807113,23.6801047 113.559576,26.7918712 Z" id="Path" fill="#357ED3"></path>
            <path d="M113.559576,26.7918712 C112.904471,36.9411831 108.05862,62.2494339 75.2985492,48.0153492 L76.4257153,44.6723864 C76.4257153,44.6723864 92.6154814,47.5288373 96.8977492,41.5221017 C100.520095,36.4354034 100.462292,19.7543085 108.458427,20.5491051 C111.557118,20.9265699 113.807113,23.6801047 113.559576,26.7918712 Z" id="Path" fill="#000000" opacity="0.08"></path>
            <path d="M83.4054746,7.88052881 C83.4054746,7.88052881 84.0702136,11.5365932 85.3322542,13.7475729 C85.576759,14.1802468 85.9839107,14.4973769 86.4632747,14.6285237 C86.9426386,14.7596704 87.4545255,14.6939754 87.8852373,14.4460305 C88.9931356,13.8005593 90.2937119,12.6107729 90.1781051,10.5924712 L90.3948678,7.16762034 C90.3375597,5.75391693 89.3927937,4.53093771 88.0393797,4.11849153 C85.6068203,3.23217288 82.890061,5.6743661 83.4054746,7.88052881 Z" id="Path" fill="#F4A28C"></path>
            <polygon id="Path" fill="#F4A28C" points="90.4237695 8.29478644 97.6347424 15.5731966 93.3283898 20.2504542 88.8052746 11.5510441"></polygon>
            <path d="M88.0538305,9.91328136 C88.0538305,9.91328136 87.7021932,8.526 88.766739,8.34777288 C89.8312847,8.16954576 90.3515153,10.1733966 88.9931356,10.7562475 L88.0538305,9.91328136 Z" id="Path" fill="#F4A28C"></path>
            <path d="M83.9449729,10.1637627 L83.3332203,11.8159763 C83.2622349,12.0063963 83.2929368,12.219844 83.4147134,12.3825375 C83.53649,12.545231 83.7326318,12.6348465 83.935339,12.6204068 L85.3804237,12.5048 L83.9449729,10.1637627 Z" id="Path" fill="#F4A28C"></path>
            <path d="M102.220478,100.573081 C102.220478,100.573081 101.257088,102.331268 99.5567051,102.215661 C97.856322,102.100054 97.1482305,103.660746 98.5933153,104.339936 C100.0384,105.019125 104.248414,103.655929 104.248414,103.655929 L104.513346,101.208919 L102.220478,100.573081 Z" id="Path" fill="#357ED3"></path>
            <path d="M122.519102,98.3332 C122.519102,98.3332 123.602915,100.023949 122.673244,101.4594 C121.743573,102.894851 122.798485,104.267681 124.074976,103.318742 C125.351468,102.369803 126.208885,98.0200983 126.208885,98.0200983 L124.190583,96.6087322 L122.519102,98.3332 Z" id="Path" fill="#357ED3"></path>
            <path d="M89.5326339,12.9479593 C89.8698038,12.2040738 90.0829609,11.4100229 90.1636542,10.5972881 C90.1636542,10.5972881 92.9671186,13.1888068 91.0885085,15.9441017 L89.5326339,12.9479593 Z" id="Path" fill="#CE8172" opacity="0.31"></path>
            <polygon id="Path" fill="#24285B" points="101.377512 62.7889322 101.170383 101.367878 106.160742 101.367878 112.548017 62.2927864"></polygon>
            <path d="M94.1280034,17.5625966 C96.6378421,15.5540505 99.7344808,14.4192364 102.947837,14.3304237 C108.150142,14.2774373 122.658793,15.1685729 121.666502,32.9142136 C120.929508,46.0644847 120.553786,60.6116712 120.553786,60.6116712 L101.449766,63.7956746 C101.449766,63.7956746 78.631878,31.5076644 94.1280034,17.5625966 Z" id="Path" fill="#357ED3"></path>
            <path d="M106.753227,22.0230915 C106.753227,22.0230915 102.750342,26.8400407 105.226254,35.6454237 C107.702166,44.4508068 111.180003,55.0721797 99.2532373,60.4093593 L101.449766,63.7812237 L120.553786,60.5972203 L120.804268,52.4662102 L123.872664,37.4084271 L106.753227,22.0230915 Z" id="Path" fill="#000000" opacity="0.08"></path>
            <rect id="Rectangle" fill="#A5A5A5" opacity="0.79" x="27.095339" y="53.1357661" width="32.2591085" height="51.3872136"></rect>
            <rect id="Rectangle" fill="#E6E6E6" x="41.1752814" y="53.1357661" width="27.9045864" height="51.3872136"></rect>
            <polygon id="Path" fill="#FFD200" points="61.0789153 56.1415424 60.5683186 59.0846983 46.8689153 59.0846983 46.8689153 71.5027932 69.6530847 71.5027932 69.6530847 61.3872 69.6530847 59.0846983 69.6530847 56.1415424"></polygon>
            <polygon id="Path" fill="#000000" opacity="0.08" points="61.0789153 56.1415424 60.5683186 59.0846983 46.8689153 59.0846983 46.8689153 71.5027932 69.6530847 71.5027932 69.6530847 61.3872 69.6530847 59.0846983 69.6530847 56.1415424"></polygon>
            <polygon id="Path" fill="#FFD200" points="64.4074271 56.1415424 63.8968305 59.0846983 50.1974271 59.0846983 50.1974271 71.5027932 72.9815966 71.5027932 72.9815966 61.3872 72.9815966 59.0846983 72.9815966 56.1415424"></polygon>
            <polygon id="Path" fill="#FFD200" points="101.146298 39.0510068 102.080786 42.5240271 89.4892814 47.9238271 95.4093119 61.7243864 116.353407 52.7407763 111.531641 41.5028339 110.433376 38.9402169 109.026827 35.6695085"></polygon>
            <polygon id="Path" fill="#000000" opacity="0.08" points="101.146298 39.0510068 102.080786 42.5240271 89.4892814 47.9238271 95.4093119 61.7243864 116.353407 52.7407763 111.531641 41.5028339 110.433376 38.9402169 109.026827 35.6695085"></polygon>
            <polygon id="Path" fill="#FFD200" points="104.205061 37.7359797 105.139549 41.2138169 92.5480441 46.6088 98.4680746 60.4093593 119.407353 51.4305661 114.590403 40.1878068 113.492139 37.6300068 112.08559 34.3592983"></polygon>
            <polygon id="Path" fill="#FFD200" points="67.1579051 56.1415424 66.6473085 59.0846983 52.952722 59.0846983 52.952722 71.5027932 75.7368915 71.5027932 75.7368915 61.3872 75.7368915 59.0846983 75.7368915 56.1415424"></polygon>
            <polygon id="Path" fill="#000000" opacity="0.08" points="67.1579051 56.1415424 66.6473085 59.0846983 52.952722 59.0846983 52.952722 71.5027932 75.7368915 71.5027932 75.7368915 61.3872 75.7368915 59.0846983 75.7368915 56.1415424"></polygon>
            <rect id="Rectangle" fill="#24285B" transform="translate(64.338161, 61.101613) rotate(-8.280000) translate(-64.338161, -61.101613) " x="53.5217016" y="53.7437229" width="21.6329186" height="14.7157797"></rect>
            <rect id="Rectangle" fill="#FFFFFF" opacity="0.12" transform="translate(64.338161, 61.101613) rotate(-8.280000) translate(-64.338161, -61.101613) " x="53.5217016" y="53.7437229" width="21.6329186" height="14.7157797"></rect>
            <rect id="Rectangle" fill="#24285B" transform="translate(65.461544, 59.015676) rotate(-8.280000) translate(-65.461544, -59.015676) " x="54.6450843" y="51.6577865" width="21.6329186" height="14.7157797"></rect>
            <polygon id="Path" fill="#FFD200" points="70.4864169 56.1415424 69.9758203 59.0846983 56.2812339 59.0846983 56.2812339 71.5027932 79.0654034 71.5027932 79.0654034 61.3872 79.0654034 59.0846983 79.0654034 56.1415424"></polygon>
            <rect id="Rectangle" fill="#A5A5A5" x="45.5924237" y="62.7985661" width="35.905539" height="14.017322"></rect>
            <path d="M114.70601,40.4623729 C112.591722,43.1868333 110.340935,45.8025695 107.962281,48.2995492 C106.137817,50.1436259 104.087142,51.7492057 101.859207,53.0779627 C100.563447,53.8727593 99.2002508,55.2889424 100.115471,57.504739 L107.249373,57.504739 L116.570169,44.8602475 L114.70601,40.4623729 Z" id="Path" fill="#000000" opacity="0.08"></path>
            <rect id="Rectangle" fill="#F2F2F2" x="56.9170712" y="62.7985661" width="24.5760746" height="14.017322"></rect>
            <rect id="Rectangle" fill="#F2F2F2" x="45.5924237" y="83.2946847" width="28.4296339" height="14.017322"></rect>
            <rect id="Rectangle" fill="#357ED3" x="36.3101627" y="44.4604407" width="8.13582712" height="8.86318644"></rect>
            <circle id="Oval" fill="#A5A5A5" cx="69.6386339" cy="69.2436441" r="1.9701322"></circle>
            <circle id="Oval" fill="#A5A5A5" cx="60.2311322" cy="89.7445797" r="1.9701322"></circle>
            <path d="M76.2185864,45.279322 C74.8661403,45.2259591 73.5158845,45.4302956 72.2397864,45.8814407 C70.105878,46.6328847 68.1309288,48.5355797 69.6530847,50.7610102 C70.8284203,52.4758441 72.4950847,51.5895254 73.1164712,50.2166949 C73.7378576,48.8438644 74.1135797,47.5143864 75.2985492,48.0153492 L76.2185864,45.279322 Z" id="Path" fill="#F4A28C"></path>
            <path d="M113.429519,16.6329254 C119.63434,18.9518957 124.660762,23.6455335 127.398671,29.6772237 C129.809677,34.7830651 129.614242,40.7375541 126.873624,45.6743119 C124.046075,50.6165017 118.251285,56.1608102 106.367871,58.5596508 L104.985407,55.0866305 L116.334139,45.1203627 C117.557114,44.0501457 118.281404,42.5206071 118.33436,40.8963445 C118.387315,39.2720819 117.764191,37.6986142 116.613522,36.5510102 C113.241658,33.1791458 108.954573,28.2176881 107.022976,22.9816644 C106.356422,21.1603232 106.812255,19.1175716 108.189875,17.7523818 C109.567494,16.3871919 111.614293,15.9498908 113.429519,16.6329254 Z" id="Path" fill="#357ED3"></path>
            <path d="M113.429519,16.6329254 C119.63434,18.9518957 124.660762,23.6455335 127.398671,29.6772237 C129.809677,34.7830651 129.614242,40.7375541 126.873624,45.6743119 C124.046075,50.6165017 118.251285,56.1608102 106.367871,58.5596508 L104.985407,55.0866305 L116.334139,45.1203627 C117.557114,44.0501457 118.281404,42.5206071 118.33436,40.8963445 C118.387315,39.2720819 117.764191,37.6986142 116.613522,36.5510102 C113.241658,33.1791458 108.954573,28.2176881 107.022976,22.9816644 C106.356422,21.1603232 106.812255,19.1175716 108.189875,17.7523818 C109.567494,16.3871919 111.614293,15.9498908 113.429519,16.6329254 Z" id="Path" fill="#FFFFFF" opacity="0.2"></path>
            <path d="M105.226254,55.7321017 C105.226254,55.7321017 99.9276102,55.1540678 99.4459153,57.1771864 C98.9642203,59.2003051 98.6366678,60.4238102 98.6366678,60.4238102 C101.317435,60.3623159 103.928635,59.5586131 106.18001,58.1020407 L105.226254,55.7321017 Z" id="Path" fill="#F4A28C"></path>
            <path d="M120.553786,60.6116712 C120.764147,63.0757015 120.793126,65.5518604 120.640492,68.020139 C120.510434,71.0596339 119.720454,76.1222475 119.123153,79.6241695 C118.697571,82.157274 119.156818,84.7596723 120.423729,86.9941017 L125.240678,95.5634542 L121.743573,99.0509254 L116.006586,91.5942881 C113.077666,87.7685168 111.072987,83.317339 110.149176,78.5885254 L108.323553,69.2725458 L103.723366,63.4247695 L120.553786,60.6116712 Z" id="Path" fill="#24285B"></path>
            <path d="M81.2860169,3.53082373 C81.2860169,3.53082373 79.7975797,6.97012542 83.3524881,7.13871864 C86.9073966,7.30731186 86.8640441,6.58476949 86.8640441,6.58476949 C86.8640441,6.58476949 87.0856237,11.180139 90.1636542,10.606922 L91.4401458,9.31597966 C91.4401458,9.31597966 92.8852305,3.45375254 90.3804169,2.16762712 C90.3804169,2.16762712 85.3804237,-2.97205763 81.2860169,3.53082373 Z" id="Path" fill="#24285B"></path>
            <path d="M87.9960271,10.4672305 C87.9960271,10.4672305 88.1838881,7.47108814 89.8698203,8.21771525 C91.5557525,8.96434237 90.4430373,12.2302339 87.9960271,10.4672305 Z" id="Path" fill="#F4A28C"></path>
            <path d="M42.2928136,44.4604407 C42.2928136,44.4604407 48.0201661,40.7851085 42.2928136,35.6454237 C36.565461,30.505739 34.3207627,42.3891525 30.1444678,34.3592983 C25.9681729,26.3294441 36.565461,17.6637525 24.8458237,17.0182814 C13.1261864,16.3728102 20.4575831,8.96915932 10.3660746,8.09729153 C0.274566102,7.22542373 -2.45182712,25.101122 5.25529153,26.2812746 C12.9624102,27.4614271 20.3516102,27.9383051 18.2610542,35.3226881 C16.1704983,42.7070712 24.0413932,44.715739 27.7359932,42.9093831 C31.4305932,41.1030271 36.3101627,44.4604407 36.3101627,44.4604407 L42.2928136,44.4604407 Z" id="Path" fill="#E6E6E6" opacity="0.45"></path>
            <path d="M21.6762712,97.3023729 C21.6762712,97.3023729 15.9681864,95.7705831 14.7061458,90.471939 C14.7061458,90.471939 23.5307966,88.6511322 23.8198136,97.7648 L21.6762712,97.3023729 Z" id="Path" fill="#357ED3" opacity="0.58"></path>
            <path d="M22.3795458,96.7147051 C22.3795458,96.7147051 18.3718441,90.4526712 21.8496814,84.5374576 C21.8496814,84.5374576 28.5934102,88.7715559 25.6358034,96.7147051 L22.3795458,96.7147051 Z" id="Path" fill="#357ED3" opacity="0.73"></path>
            <path d="M23.4055559,96.7147051 C23.4055559,96.7147051 25.481661,90.0576814 31.8352169,88.7715559 C31.8352169,88.7715559 33.0490881,93.1068102 27.7504441,96.7147051 L23.4055559,96.7147051 Z" id="Path" fill="#357ED3"></path>
            <polygon id="Path" fill="#24285B" points="19.2726136 96.5846475 20.4624 104.484444 27.7359932 104.484444 28.7764542 96.5846475"></polygon>
        </g>
      </g>
    </svg>
  );
};

export default ArchiveFolderIllustration;
