import React from "react";
import Parser from "html-react-parser";

import endpoint from '../../../helpers/api';

import bannerImage from '../../../assets/images/news-1.png';

//Import Style
import {
  MainContainer,
  Banner,
  ContentContainer,
  ContentHeader,
  GoodDealContainer,
  GoodDealLink,
} from './style'

const NewsContainer = ({ newsData, isPreview = false }) => {
  const {
    banner,
    title,
    link,
    description,
    deal_value,
    content,
  } = newsData;


  const getImage = () => {
    if (isPreview) {
      return banner;
    }
    else {
      let contentType = 'news';
      if (description && deal_value && link) contentType = 'good_deals'; // This means the content type is good deal, there is no other better way to differentiate them for now
      return banner ? `${endpoint.base_url}/uploads/${contentType}/${banner}` : bannerImage;
    }
  }

  const CustomParser = (content) => {
    return Parser(content, {
      replace: function (domNode) {
        if (domNode.name === "a") {
          const link = domNode.attribs.href;
          return (
            <a target="_blank" rel="noreferrer" href={link.includes("http") ? link : `//${link}`}>
              {domNode.children[0].data}
            </a>
          )
        }
      }
    });
  }

  return (
    <MainContainer>
      <Banner
        // image={getImage()}
        // defaultImage={bannerImage}
        src={getImage()}
      />
      <ContentContainer>
        <ContentHeader>
          {title}
        </ContentHeader>
        {link && (
          <>
            {description && CustomParser(description)}
            <GoodDealContainer>
              Code : <span> {deal_value} </span>
            </GoodDealContainer>
            <GoodDealLink target="_blank" rel="noreferrer" href={link.includes("http") ? link : `//${link}`}>
              {`Accédez au site du bon plan`}
            </GoodDealLink>
          </>
        )}
        {!!content?.length && CustomParser(content)}
      </ContentContainer>
    </MainContainer>
  )
}

export default NewsContainer;