import Dialog from '@material-ui/core/Dialog';
import styled from "styled-components";

import { COLORS } from '../../../constants/design';

export const CustomDialog = styled(Dialog)`
  &&{
    &.MuiDialog-root {
      /* background-color: red; */
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;
      box-sizing: border-box;
      .MuiDialog-container {
        width: 100%;
        height: 100%;
      }
      .MuiDialog-paper {
        width: 100%;
        height: 100%;
        margin: 0;
      }
    }
  }
`;

export const StyledCloseContainer = styled.div`
  position: absolute;
  z-index: 9999;
  color: ${COLORS.DARK_GREY};
  background-color: ${COLORS.LIGHT_GREY_1};
  border-radius: 50%;
  padding: 1px;
  display: flex;
  opacity: 0.8;
  top: 10px;
  right: 10px;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;