import React from "react";

// Styles
import { Container, WrapperTarget, PopoverCustom } from "./style";

const PopoverCpt = ({
  children,
  messagePopover,
  className,
  anchorOriginVertical = 'center',
  anchorOriginHorizontal = 'right',
  transformOriginVertical = 'center',
  transformOriginHorizontal = 'left'
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Container className={className || "popoverCpt"}>
      <WrapperTarget
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {children}
      </WrapperTarget>
      <PopoverCustom
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: anchorOriginVertical,
          horizontal: anchorOriginHorizontal,
        }}
        transformOrigin={{
          vertical: transformOriginVertical,
          horizontal: transformOriginHorizontal,
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <span>{messagePopover}</span>
      </PopoverCustom>
    </Container>
  );
};

export default PopoverCpt;
