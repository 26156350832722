import React from "react";
import GoogleSuggest from "../Input/GoogleSuggest";
import Parser from 'html-react-parser';
import moment from 'moment';


class CustomAddressInput extends React.Component {
    constructor(props) {
        super(props);
        let label = props.schema.title.replace(/\n/ig, '<br>');
        if (props.formData !== undefined) {
            this.state = {
                address: props.formData,
                label
            }
        } else {
            this.state = {
                label
            }
        }

        moment.locale('fr')
        this.setAddress = this.setAddress.bind(this);
    }

    onChange(name) {
        return (event) => {
            this.setState({
                [name]: event.target.value
            }, () => { this.props.onChange(this.state) });
        }
    }

    setAddress() {

    }
    // Près de quelle endroit souhaites-tu consulter ? ↵Ex : 28 rue du Chemin Vert, 75011 Paris
    render() {

        return (
            <React.Fragment>
                <label className="control-label" htmlFor={this.props.field_name}>
                    {Parser(this.state.label)} {this.props.required ? <span className="required">*</span> : null}
                </label>
                <div className="chatbot-m-custom-search-location" style={{ width: '100%' }}>
                    <GoogleSuggest
                        placeholder="Adresse"
                        setAddress={this.props.onChange}
                        defaultAddress={this.state.address}
                        getCoordinates={false}
                    />
                </div>
                {this.props.errors}

            </React.Fragment>
        )
    }
}

export { CustomAddressInput };