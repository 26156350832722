import React, { useState } from "react";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

// components
import ButtonCpt from "../../molecules/buttonCpt";

// Style
import {
  Container,
  DialogCustom,
  DialogTitleCustom,
  DialogPicture,
  DialogIllustration,
  CustomButtons,
} from "./style";

const ModalCpt = ({
  startIcon,
  endIcon,
  textButton,
  disabled,
  dialogTitle,
  dialogContent,
  dialogBtnCloseText,
  dialogBtnActionText,
  actionLink,
  actionClick,
  appearence,
  appearenceBtnClose,
  appearenceBtnAction,
  modalHeadPicture,
  modalHeadIllustration,
  className,
  openModal,
  closeModal,
  ...rest
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(openModal);
  };

  const handleClose = () => {
    setOpen(closeModal);
  };

  return (
    <Container className={className}>
      {textButton && (
        <CustomButtons
          type="button"
          appearence={appearence}
          startIcon={startIcon}
          endIcon={endIcon}
          onClick={handleClickOpen}
          label={textButton}
          disabled={disabled}
        />
      )}
      <DialogCustom
        open={openModal || open}
        onClose={closeModal ? handleClose : handleClose}
        aria-labelledby="premiumAlert-dialog-title"
        aria-describedby="premiumAlert-dialog-description"
        {...rest}
      >
        {modalHeadPicture &&
          <DialogPicture bgPicture={modalHeadPicture} />
        }
        {modalHeadIllustration &&
          <DialogIllustration>
            {modalHeadIllustration}
          </DialogIllustration>
        }

        <DialogTitleCustom
          id="premiumAlert-dialog-title"
          noPaddingTop={modalHeadPicture ? true : false}
        >
          {dialogTitle}
        </DialogTitleCustom>
        <DialogContent id="premiumAlert-dialog-description">
          {dialogContent}
        </DialogContent>
        <DialogActions>
          <ButtonCpt
            appearence={appearenceBtnClose}
            onClick={closeModal || handleClose}
            label={dialogBtnCloseText}
          />
          <ButtonCpt
            appearence={appearenceBtnAction}
            to={actionLink}
            onClick={actionClick}
            label={dialogBtnActionText}
          />
        </DialogActions>
      </DialogCustom>
    </Container>
  );
};

export default ModalCpt;
