import React from "react";

const AnswerIllustration = ({
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="illustration answerIllustration"
      width={width}
      height={height}
      viewBox="0 0 109 81"
    >
      <g
        fill="none"
        fillRule="nonzero"
      >
        <path
          d="M75 29.566s-1.264 5.763-7.955 6.414C60.354 36.632 51 21 51 21s8.074.82 11.016 4.769c2.942 3.949 5.816 5.792 10.423 2.721L75 29.566z"
          fill="#2768B3"
        />
        <path
          d="M75 29.566s-1.264 5.763-7.955 6.414C60.354 36.632 51 21 51 21s8.074.82 11.016 4.769c2.942 3.949 5.816 5.792 10.423 2.721L75 29.566z"
          fill="#FFF"
          opacity={0.2}
        />
        <ellipse
          fill="#E6E6E6"
          opacity={0.45}
          cx={55.5}
          cy={76.5}
          rx={33.5}
          ry={4.5}
        />
        <path
          d="M50.165 72.917s-.343 1.474.422 2.333c.765.858.391 2.092-.497 1.661-.888-.43-2.09-3.437-2.09-3.437L48.928 72l1.237.917zM62.564 72.592s-.236 1.286.801 1.906c1.037.62.734 1.706-.402 1.469C61.827 75.729 60 73.362 60 73.362L60.945 72l1.62.592z"
          fill="#2768B3"
        />
        <path
          d="M42.99 10.272s-.133 2.424-.813 3.964a1.277 1.277 0 01-.72.67 1.429 1.429 0 01-1.025-.003c-.833-.315-1.861-.991-1.96-2.298l-.464-2.204c-.08-.923.481-1.792 1.4-2.167 1.612-.793 3.756.564 3.582 2.038z"
          fill="#F4A28C"
        />
        <path fill="#F4A28C" d="M38 12l1.19 6.787L44 19l-2.797-5.303z" />
        <path
          d="M42.51 10.577a9.423 9.423 0 01-2.228-.5c.35.769.224 1.68-.319 2.316-.25.32-.605.534-.996.602a.854.854 0 01-.728-.278.93.93 0 01-.227-.77l.347-2.4c.108-.954.714-1.77 1.576-2.12a8.55 8.55 0 011.114-.38c.955-.245 2.184.57 3.075.053a.573.573 0 01.583.01.62.62 0 01.293.523c0 .992-.366 2.54-1.726 2.894-.25.061-.508.078-.764.05z"
          fill="#24285B"
        />
        <path
          d="M39.995 12.2s.093-1.176-.502-1.2c-.594-.024-.735 1.668.048 2l.454-.8zM42.55 12l.415 1.246a.727.727 0 01-.002.453c-.049.14-.139.232-.24.243L42 14l.55-2z"
          fill="#F4A28C"
        />
        <path
          d="M41.349 15.348A2.121 2.121 0 0140 15s.112 1.923 2 2l-.651-1.652z"
          fill="#CE8172"
          opacity={0.31}
        />
        <path
          d="M60.621 50l3.572 5.365a10.645 10.645 0 011.151 9.608L62.758 72l-2.682-.562.754-6.99c.23-2.157-.669-4.282-2.398-5.667L53 54.404 57.11 50h3.511z"
          fill="#24285B"
        />
        <path
          d="M30.01 25.938a7.237 7.237 0 017.427-6.935c4.836.117 11.968.745 16.438 3.226 7.276 4.028 6.047 27.102 6.047 27.102L44.312 53S29.566 38.432 30.01 25.938z"
          fill="#2768B3"
        />
        <path
          d="M37.758 23s6.526 2.906 9.285 14.529C49.77 49.043 60 42.19 60 42.19l-.16 7.336L45.038 53l-3.21-3.598s-9.7-10.883-4.07-26.402z"
          fill="#000"
          opacity={0.08}
        />
        <path
          d="M45 52.37l3.588 5.595a9.26 9.26 0 011.152 7.344l-1.852 7.017 2.889.674 3.899-7.243a11.509 11.509 0 00.531-9.774l-.829-2.045L59 49l-14 3.37z"
          fill="#24285B"
        />
        <path
          d="M70.251 41.26l5.516-4.528-5.622-6.945a4.873 4.873 0 005.151-2.278 4.946 4.946 0 00-.395-5.651 4.866 4.866 0 00-5.417-1.53 4.917 4.917 0 00-3.256 4.62L60.602 18 42 33.271 57.165 52l5.515-4.527a4.94 4.94 0 01.7-6.918 4.861 4.861 0 016.871.704z"
          fill="#FFD200"
        />
        <path
          d="M21.638 41.633l-3.503-5.318-6.717 4.35a4.338 4.338 0 00-1.547-4.78 4.447 4.447 0 00-5.07-.176 4.343 4.343 0 00-1.882 4.662A4.394 4.394 0 006.717 43.7L0 48.054 11.865 66 30 54.258l-3.516-5.317c-1.32.854-3 .946-4.407.241a4.365 4.365 0 01-2.414-3.655 4.354 4.354 0 011.992-3.898l-.017.004z"
          fill="#E6E6E6"
        />
        <path
          d="M102.026 37.845c1.803-.359 3.193-1.841 3.471-3.705.279-1.863-.613-3.705-2.228-4.602a4.263 4.263 0 00-4.974.602c-1.368 1.258-1.818 3.263-1.124 5.008l-6.968-3.883-3.032 5.73c1.443.707 2.388 2.172 2.457 3.81.07 1.64-.747 3.183-2.124 4.016a4.229 4.229 0 01-4.452-.039L80 50.55 98.797 61l3.025-5.73c-1.411-.737-2.322-2.201-2.378-3.825-.056-1.624.752-3.15 2.11-3.987a4.257 4.257 0 014.42-.036l3.026-5.73-6.974-3.847z"
          fill="#C9C9C9"
        />
        <path
          d="M49 42l13.534.3s-1.699 2.458.466 5.335L57.45 52 49 42z"
          fill="#000"
          opacity={0.08}
        />
        <path
          d="M30.5 24.33c-.706 3.45-1.302 10.162 2.93 20.12 1.146 2.669 3.736 4.4 6.605 4.412L59.85 49l.15-3.081-18.27-3.085s.731-15.705-4.39-20.707c-2.296-2.237-6.195-.958-6.84 2.204z"
          fill="#2768B3"
        />
        <path
          d="M30.5 24.33c-.706 3.45-1.302 10.162 2.93 20.12 1.146 2.669 3.736 4.4 6.605 4.412L59.85 49l.15-3.081-18.27-3.085s.731-15.705-4.39-20.707c-2.296-2.237-6.195-.958-6.84 2.204z"
          fill="#FFF"
          opacity={0.2}
        />
        <path
          d="M60 49.392s2.298 1.33 3.468.055c1.17-1.276.054-2.188 0-3.525.025-.393-.188-.755-.516-.875-.43-.148-.89.064-1.11.51-.318.59-.914 1.506-1.72 1.353L60 49.392zM76 27.744s-1.666-1.674-3.508 0 1.97 4.105 3.508 0z"
          fill="#F4A28C"
        />
        <path
          d="M41 43a21.82 21.82 0 00-3 0c.945-.48 1.956-.816 3-1v1z"
          fill="#000"
          opacity={0.08}
        />
        <path fill="#C9C9C9" d="M32.085 2.7l-1.503-.946.943-1.509 1.502.947z" />
        <path
          fill="#E6E6E6"
          d="M65.28 4.018L63.969 2.7l1.313-1.318L66.594 2.7zM14.106 25.894l-.673-2.96 2.948-.675.673 2.96z"
        />
        <path fill="#C9C9C9" d="M87 21v-3h3v3z" />
      </g>
    </svg>
  );
};

export default AnswerIllustration;
