import styled from "styled-components";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { COLORS } from '../../../../../../constants/design'


export const Container = styled.div`
  width: 100%;
`;

export const TicketCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px;
  >div:not(:last-child) {
    margin-right: 10px;
  };
`;

export const CustomDragDropContext = styled(DragDropContext)`
`;

export const CustomColumn = styled.div`
  flex: 1;
`;

export const TableColumn = styled.div`
  width: 100%;
  padding: 10px ;
  border-radius: 10px;
  background-color:  ${COLORS.SLIGHT_GREY_2};
  >div:not(:last-child) {
    margin-bottom: 10px;
  };
`;