import React, {useEffect, useState} from 'react';
import {
  shallowEqual,
  useDispatch,
  useSelector,
} from "react-redux";
import moment from 'moment';

// Import services
// import getUntreatedFbBySkillStats from '../../services/getUntreatedFbBySkillStats.service';
import {
  getUntreatedFbBySkillStatsData,
} from '../../../../../../store/adminFront/selectors';
import {
  getUntreatedFbBySkillStats,
} from '../../../../../../store/adminFront/actions';

// Styles
import {
  Container,
  Title,
  Content,
  FeedbackCompetenceList,
  FeedbackCompetenceItem
} from "./style";

const FeedbackUntreatedCompetenceStats = () => {
  const dispatch = useDispatch();
  // const [untreatedFbBySkillStat, setUntreatedFbBySkillStat] = useState([]);

  // useEffect(() => {
  //     getUntreatedFbBySkillStat().then(data => setUntreatedFbBySkillStat(data));
  // }, []);

  const {
    untreatedFbBySkillStats,
  } = useSelector(
    state => ({
      untreatedFbBySkillStats: getUntreatedFbBySkillStatsData(state),
    }),
    shallowEqual
  );

  useEffect(() => {
      dispatch(getUntreatedFbBySkillStats());
  }, []);

  // console.log(untreatedFbBySkillStat);

  return (
    <Container>
      <Title>
        Remarque(s) non traitée(s) par compétence
      </Title>
      <Content>
        <FeedbackCompetenceList>
          {untreatedFbBySkillStats && Object.keys(untreatedFbBySkillStats).slice(0, 8).map((item, index) => {
            const {
              feedbacks,
              date
            } = untreatedFbBySkillStats[item];

            return (
              <FeedbackCompetenceItem key={`key-untreatedFbBySkillStat-${index}`}>
                <span>{item || '-'}</span>
                <strong>{feedbacks || '-'}</strong>
                <p>remarque(s) depuis le {moment(date).format("DD/MM/YYYY") || '-'}</p>
              </FeedbackCompetenceItem>
              /*<>
                { untreatedFbBySkillStat[item] ?
                  <FeedbackCompetenceItem key={`key-untreatedFbBySkillStat-${index}`}>
                    <span>{item || '-'}</span>
                    <strong>{feedbacks || '-'}</strong>
                    <p>remarques depuis le {date || '-'}</p>
                  </FeedbackCompetenceItem>
                  :
                  <FeedbackCompetenceItem>
                    <p>Toutes les remarques ont été traité.</p>
                  </FeedbackCompetenceItem>
                }
              </>*/
            )
          })}
        </FeedbackCompetenceList>
      </Content>
    </Container>
  );
}

export default FeedbackUntreatedCompetenceStats;
