import styled from "styled-components";
import Button from '@material-ui/core/Button';
import SettingsIcon from '@material-ui/icons/Settings';

import DrawerCpt from '../../../../components/organisms/drawerCpt'
import GenericAdminFrontHeader from '../../../../../../components/molecules/GenericAdminFrontHeader';
import GenericMaterialMenu from '../../../../../../components/molecules/GenericMaterialMenu';
import { COLORS } from '../../../../../../constants/design'
import breakpoints from "../../../../../../theme-style/breakpoints";


export const DrawerCptCustom = styled(DrawerCpt)`
`;

export const DrawerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 700px;
  /* height: calc(100% - 89px); */
  height: 100%;
  position: relative;
  .textTitle {
    font-weight: 600;
    font-size: 0.9rem;
    color: ${COLORS.DARK_GREY};
    margin: 14px 5px 14px 0px;
  };
  .textTooltip {
    color: ${COLORS.GREY_1};
  }
  .textFieldInput {
    input {
      font-size: 0.9rem;
    }
  };
  /* border: 10px solid green; */
`;

export const StyledHeader = styled(GenericAdminFrontHeader)`
  .genericHeaderWrapper {
    /* width: 700px; */
  }
`;

export const StyledHeaderDescription = styled.div`
  display: flex;
  >div:not(:last-child) {
    margin-right: 15px;
  };
`;

export const HeaderButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ContentContainer = styled.div`
  /* border: 1px solid red; */
  display: flex;
  height: calc(100% - 74px);

  @media ${breakpoints.mobileXL} {
    height: calc(100% - 89px);
  }
`;

export const StyledButton = styled(Button)`
  && {
    &.MuiButton-root {
      position: relative;
      padding: 5px 15px;
      font-size: 0.8rem;
      background-color: ${COLORS.PRIMARY_BLUE};
      color: ${COLORS.WHITE};
      border: 1px solid !important;
      border-color: ${COLORS.PRIMARY_BLUE} !important;
      transition: all ease-in .15s;
      border: transparent;
      border-radius: 5px;
      text-transform: none;
      width: 160px;
      :hover {
        background-color: ${COLORS.PRIMARY_DEEP_BLUE};
      }
    }
  }
`;

export const StyledMenu = styled(GenericMaterialMenu)`
  .MuiButton-root {
    position: relative;
    padding: 5px 15px !important;
    font-size: 0.8rem !important;
    background-color: ${COLORS.WHITE};
    color: ${COLORS.DARK_GREY};
    border: 1px solid !important;
    border-color: ${COLORS.DARK_GREY} !important;
    transition: all ease-in .15s;
    border: transparent;
    border-radius: 5px !important;
    text-transform: none;
    width: 160px !important;
    margin-right: 15px;
    box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%) !important;
  }
`;

export const StyledSuggestionIconContainer = styled.div`
  position: relative;
`;

export const StyledSuggestionIndicator = styled.div`
  visibility: ${props => props.isIndicatorVisible ? 'visible' : 'hidden'};
  margin: 0px 10px;
  width: 190px;
  position: absolute;
  bottom: -60px;
  right: -10px;
  padding: 4px 8px 6px 6px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: ${COLORS.WHITE};
  background-color: rgba(0, 0, 0, 0.7);
  animation: infobulleAnimationReverse 1s infinite;
  ::after {
    content: '';
    position: absolute;
    top: -6px;
    right: 24px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0px 6px 6px 6px;
    border-radius: 1px;
    border-color: transparent transparent rgba(0, 0, 0, 0.7) transparent;
  }
  @keyframes infobulleAnimationReverse {
    0% {
      bottom: -60px;
    }
    50% {
      bottom: -72px;
    }
    100% {
      bottom: -60px;
    }
  }
`;

