import styled from 'styled-components';
/* import { COLORS } from '../../../../constants/design'; */
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';

export const Container = styled.div`
  width: 100%;
`

export const IconContainer = styled(IconButton)`
  &&{
    height: fit-content;
    .MuiSvgIcon-root {
      font-size: 1.2rem;
    }
  }
`;

export const VienwIcon = styled(VisibilityIcon)`
  &&{
    height: fit-content;
    .MuiSvgIcon-root {
      font-size: 1.2rem;
    }
  }
`;