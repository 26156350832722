// CollaboratorList
export const getCollaboratorListData = state => state.userManagement.collaboratorList.data;
export const getCollaboratorListIsLoading = state => state.userManagement.collaboratorList.get.loading;

// CollaboratorRoles
export const getCollaboratorRolesData = state => state.userManagement.collaboratorRoles.data;
export const getCollaboratorRolesIsLoading = state => state.userManagement.collaboratorRoles.get.loading;

// SelectedCollaborator
export const getSelectedCollaboratorData = state => state.userManagement.selectedCollaborator.data;
export const getSelectedCollaboratorIsLoading = state => state.userManagement.selectedCollaborator.get.loading;

// EdiCollaboartor
export const getEditCollaboratorIsLoading = state => state.userManagement.editCollaborator.get.loading;
export const getEditCollaboratorIsSuccess = state => state.userManagement.editCollaborator.get.success;

// SwitchCollaboratorSite
export const getSwitchCollaboratorSiteIsLoading = state => state.userManagement.switchCollaboratorSite.get.loading;
export const getSwitchCollaboratorSiteIsSuccess = state => state.userManagement.switchCollaboratorSite.get.success;