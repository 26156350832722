import React, { useState, useEffect } from 'react';
import AddIcon from '@material-ui/icons/Add';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { MESSAGE_ELEMENT_TYPE } from '../../../../../../constants/chatBotConstants';
import { FORM_SOURCE } from '../../../../../../../../constants/serviceConstants';

/* import plumberImage from '../../../../../../../../assets/images/plumberService.jpg'; */
import panierBioImage from '../../../../../../../../assets/images/panierBioService.png';
import defaultImage from '../../../../../../../../assets/images/default-service-background.png'
// import lavageImage from '../../../../../../../../assets/images/lavegeService.png';

import {
  StyledMainContainer,
  ImagePreviewContainer,
  ContentContainer,
  ContentTitle,
  ContentDescription,
  StyledButton,
} from './style';

const ServiceCard = ({
  itemId,  // This props is important for Horizontal scroll view to work!
  card,
  onMessageButtonClick
}) => {
  const [content, setContent] = useState(null);
  const [serviceForm, setServiceForm] = useState(null);

  useEffect(() => {
    if (card) {
      if (card.content_source === 'custom') {
        setContent(JSON.parse(card.content_custom));
      } else {

      }
      if (card.form_source === FORM_SOURCE.EXTERNAL) {
        setServiceForm(JSON.parse(card.form.url));
      } else {

      }
    }
  }, [card]);

  // console.log('card', card);
  // console.log('serviceForm', serviceForm);

  const handleFormClick = () => {
    let element = {}
    if (card.form_source === FORM_SOURCE.INTERNAL) {
      element = {
        type: MESSAGE_ELEMENT_TYPE.BUTTON_FORM,
        transmitData: card.form.payload,
      }
    }
    else if (card.form_source === FORM_SOURCE.EXTERNAL) {
      element = {
        type: MESSAGE_ELEMENT_TYPE.BUTTON_LINK,
        ...serviceForm,
      }
    }
    else {
      return;
    }
    onMessageButtonClick({}, element, null, null); // reuse chatbot button form/link mechanism, let first arg be empty object as we don't need to inherit the previous message
  }

  const handleShowMoreClick = () => {
    const element = {
      type: MESSAGE_ELEMENT_TYPE.BUTTON_SIMPLE,
      userMessage: `Plus d'informations sur ${content?.serviceName.toLowerCase()}`,
      messageElements: content?.messageElements
    }
    onMessageButtonClick({}, element, null, null); // reuse chatbot button simple mechanism, let first arg be empty object as we don't need to inherit the previous message
  }

  return (
    <StyledMainContainer>
      <ImagePreviewContainer
        image={card.image ? card.image : defaultImage}
        defaultImage={content?.serviceName === 'Panier Bio' ? panierBioImage : defaultImage}
      />
      <ContentContainer>
        <ContentTitle>{content?.serviceName || ''}</ContentTitle>
        <ContentDescription>{content?.description || ''}</ContentDescription>
        {card.form_source !== FORM_SOURCE.NONE &&
          <StyledButton
            startIcon={<AddIcon style={{ fontSize: '1rem' }} />}
            onClick={handleFormClick}
          >
            {`Faire une demande`}
          </StyledButton>
        }
        <StyledButton
          startIcon={<VisibilityIcon style={{ fontSize: '1rem' }} />}
          onClick={handleShowMoreClick}
        >
          {`Plus d'informations`}
        </StyledButton>
      </ContentContainer>
    </StyledMainContainer>
  );
}

export default ServiceCard;
