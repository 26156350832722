import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import GenericTextField from '../../../../../../../components/molecules/GenericTextField';

import {
  HELPER_TEXT
} from '../../../../../../../constants/helperConstants';

import {
  MainContainer,
  StyledAccordion,
  StyledTitle,
  DetailContainer,
} from './style';

const HelperEdit = ({
  helperForm,
  currentTemplateActive,
  handleHelperFormChange
}) => {

  const renderHelperField = () => {
    let index = 0;

    return _.map(helperForm, (form, helperKey) => {
      index++;
      return (
        <GenericTextField
          key={helperKey}
          name={helperKey}
          title={`Helper nº${index}`}
          placeholder={HELPER_TEXT[helperKey]}
          onChange={handleHelperFormChange}
          value={form}
          disabled={!currentTemplateActive}
        />
      );
    });
  };

  return (
    <MainContainer>
      <StyledAccordion
        defaultExpanded={true}
        headerComponent={
          <StyledTitle>
            {`Helper settings ${currentTemplateActive ? '' : '(pas actif)'}`}
          </StyledTitle>
        }
        detailComponent={
          <DetailContainer>
            {renderHelperField()}
          </DetailContainer>
        }
      />
    </MainContainer>
  );
};

export default HelperEdit;