import React, { useState, useEffect } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import _ from 'lodash';

import { priceDisplay } from '../../../../../helpers/roomBookingPriceHelper';
import GenericTextArea from "../../../../../components/molecules/GenericTextArea";

import {
  MainContainer,
  OptionsContainer,
  OptionsCheckBoxContainer,
  OptionsInfoContainer,
  OptionsTitle,
  OptionsDetails,
} from './style';

const OptionListInput = ({
  options,
  values,
  onChange
}) => {
  const [form, setForm] = useState(null);

  useEffect(() => {
    if (options) {
      const newValue = _.map(options, (option) => {
        const selectedValue = _.find(values, (value) => value.id === option.id);
        return ({
          id: option.id,
          price: option.price,
          title: option.title,
          cancel_deadline: option.cancel_deadline,
          cancel_fees: option.cancel_fees,
          type: option.type,
          checked: selectedValue?.checked || false,
          note: selectedValue?.note || '',
        })
      })
      setForm(newValue);
    }
  }, [values, options]);

  // console.log('options', options);
  // console.log('form', form);

  const handleOnChange = (id, event, type) => {
    const newForm = _.map(form, (data) => {
      return ({
        ...data,
        checked: type === 'checkBox' && String(data.id) === String(id) ? event.target.checked : data.checked,
        note: type === 'note' && String(data.id) === String(id) ? event.target.value : data.note,
      })
    });
    setForm(newForm);
    onChange(newForm);
  }

  const generateOptions = () => {
    if (options && options.length !== 0) {
      return _.map(options, (option, index) => {
        const formValue = _.find(form, (data) => String(data.id) === String(option.id));
        if (formValue) {
          return (
            <OptionsContainer key={index}>
              <OptionsCheckBoxContainer>
                <Checkbox
                  checked={formValue.checked}
                  onChange={(event) => handleOnChange(formValue.id, event, 'checkBox')}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </OptionsCheckBoxContainer>
              <OptionsInfoContainer>
                <OptionsTitle>
                  {option.title}
                </OptionsTitle>
                <OptionsDetails>
                  {`${option.description}`}
                </OptionsDetails>
                <OptionsDetails>
                  {`Prix: ${priceDisplay(option.price)}`}
                </OptionsDetails>
                {formValue.checked &&
                  <GenericTextArea
                    variant="outlined"
                    placeholder="Ajouter une note"
                    title={`Vous pouvez apporter des précisions`}
                    minRows={2}
                    value={formValue.note}
                    onChange={(event) => handleOnChange(formValue.id, event, 'note')}
                  />
                }
              </OptionsInfoContainer>
            </OptionsContainer>
          )
        }
      });
    }
    else {
      return (
        <OptionsContainer>
          {`Pas d'option disponible`}
        </OptionsContainer>
      )
    }
  }

  return (
    <MainContainer>
      {generateOptions()}
    </MainContainer>
  )
};

export default OptionListInput;