import React from 'react';
import { Link } from "react-router-dom";
import { IconCycobot } from "../../helpers/Icon";
import sm_logo from "../../assets/images/small-logo.svg";

const NotFoundCompany = () => {
    return (
        <div className="OnboardPage o-page">
            <div className="header-page__header">
                <Link to="/" className="remove-dec">
                    <img src={sm_logo} alt="Logo Cyconia" className="header-page__small-logo" />
                    <span className="header-page__logo-text">Cyconia</span>
                </Link>
                <div className="o-onboarding-boxdialog_notfound">
                    <div class="error">
                        <div style={{ fontSize: '29px', lineHeight: "40px" }}
                            className="error-code m-b-10 m-t-20"> Oups, cet espace n'est pas actif.
                      </div>

                        <div style={{ fontSize: '15px', lineHeight: "20px" }}
                            className="error-code m-b-10 m-t-20"> 
                            N'hésitez pas à nous contacter si vous penser que c'est un erreur !
                      </div>

                        <img src={IconCycobot} alt="" />

                        <a href="mailto:onvousaimeonvousaide@cyconia.io" target="_blank"
                            className="remove-dec">
                            <button className="ladda-button a-button a-button">
                                Contactez-nous
                    </button>
                        </a>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default NotFoundCompany;
