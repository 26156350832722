import React from "react";

import {
  MainContainer,
  StyledLoader
} from './style';

const Loader = props => {
  return (
    <MainContainer className='genericLoader'>
      <StyledLoader className='genericLoaderStyle'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </StyledLoader>
    </MainContainer>
  )
}

export default Loader;
