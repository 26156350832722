import React, { useEffect, useState } from 'react';
import EventIcon from '@material-ui/icons/Event';
import EuroIcon from '@material-ui/icons/Euro';
import AddIcon from '@material-ui/icons/Add';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import TicketUserInfo from './components/TicketUserInfo';
import TicketProviderInfo from './components/TicketProviderInfo';
import TicketSummaryInfo from './components/TicketSummaryInfo';
import TicketEventTab from './components/TicketEventTab';
import TicketPaymentTab from './components/TicketPaymentTab';
import GenericTitleWithIcon from '../../../../../../../../components/molecules/GenericTitleWithIcon';
import CalendarObjectModal from '../../../../../../components/organisms/CalandarObjectModal';
import CreateEventModal from '../../../../../../components/organisms/CreateEventModal';
import { getConciergeCalendarObjectsData, getConciergeProvidersData } from '../../../../../../../../store/concierge/selectors';
import { getSubDomain } from '../../../../../../../../store/config/selectors'

import { ticketStatusArray, finalTicketStatus } from '../../../../../../../../constants/ticketConstant';

import { saveCalendarObject, deleteCalendarObject } from '../../../../../../../../store/concierge/actions';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams } from "react-router-dom";
import _ from 'lodash'

import {
  MainContainer,
  StyledTabContainer,
  StyledButton,
} from './style';

// const testcalendarObject = [
//   {
//     id: 0,
//     title: 'Test 123 longgggggggg titleeeeeeeedkjasjkdjasdkldjaskjkl',
//     allDay: true,
//     start: new Date(2021, 8, 25),
//     end: new Date(2021, 8, 25),
//     resource: {
//       type: '',
//       notes: 'task 123',
//       creator: 'John Doe'
//     }
//   },
//   {
//     id: 1,
//     title: 'Test 222',
//     allDay: true,
//     start: new Date(2021, 8, 26),
//     end: new Date(2021, 8, 26),
//     resource: {
//       type: 'event',
//       notes: 'task 123',
//       creator: 'John Doe'
//     }
//   },
//   {
//     id: 2,
//     title: 'Test 444',
//     allDay: true,
//     start: new Date(2021, 8, 27),
//     end: new Date(2021, 8, 27),
//     resource: {
//       type: '',
//       notes: 'task 123',
//       creator: 'John Doe'
//     }
//   },
//   {
//     id: 3,
//     title: 'Test 42',
//     allDay: true,
//     start: new Date(2021, 8, 30),
//     end: new Date(2021, 8, 31),
//     resource: {
//       type: 'task',
//       notes: 'task 123',
//       creator: 'John Doe'
//     }
//   },
// ]

const paymentObject = [
  {
    id: 1,
    status: 'expired',
    dateCreated: new Date(2021, 8, 12),
    dateExpired: new Date(2021, 8, 15),
    dateValidated: '',
    paymentLink: 'https://gnz.stripecheckout.com/'
  },
  {
    id: 2,
    status: 'validated',
    dateCreated: new Date(2021, 8, 25),
    dateExpired: new Date(2021, 8, 28),
    dateValidated: new Date(2021, 8, 27),
    paymentLink: 'https://gnz.stripecheckout.com/'
  },
  {
    id: 3,
    status: 'on_going',
    dateCreated: new Date(2021, 8, 25),
    dateExpired: new Date(2021, 8, 26),
    dateValidated: new Date(2021, 8, 27),
    paymentLink: 'https://gnz.stripecheckout.com/'
  },
  {
    id: 4,
    status: 'cancelled',
    dateCreated: new Date(2021, 8, 26),
    dateExpired: new Date(2021, 8, 27),
    dateValidated: new Date(2021, 8, 28),
    paymentLink: 'https://gnz.stripecheckout.com/'
  }
]

const TicketRequestInfo = ({ ticketRequest, onTicketSave }) => {
  const [value, setValue] = React.useState(0);
  const [createEventMode, setCreateEventMode] = useState('');
  const [calendarData, setCalendarData] = useState(null);
  const [eventData, setEventData] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [calendarObject, setCalendarObject] = useState([]);
  const [ProviderList, setProviderList] = useState([])
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    calendarObjectData,
    providerData,
    subDomain
  } = useSelector(
    state => ({
      calendarObjectData: getConciergeCalendarObjectsData(state),
      providerData: getConciergeProvidersData(state),
      subDomain: getSubDomain(state)
    }),
    shallowEqual
  );

  useEffect(() => {
    if (calendarObjectData) {
      const data = Object.values(calendarObjectData)
        .filter(item => String(item?.ticket?.id) === id)
        .map(item => {
          return {
            id: item.id,
            title: item.title,
            start: new Date(item.start),
            end: new Date(item.end),
            allDay: item.is_all_day,
            resource: {
              type: item.type,
              note: item.description,
              creator: `${item.creator?.firstname || ""} ${item.creator?.lastname || ""}`
            }
          }
        })
      setCalendarObject(data);
    }
  }, [calendarObjectData])

  const handleTabChange = (value) => {
    setAnchorEl(null);
    setValue(value);
  }

  const handleDelete = (id) => {
    dispatch(deleteCalendarObject(id));
    setAnchorEl(null);
  }

  const handleEventSelect = (event, target) => {
    setEventData(event);
    setAnchorEl(target.currentTarget)
  }

  const handleCreateEvent = (data, mode) => {
    setAnchorEl(null)
    setCalendarData(data);
    setCreateEventMode(mode);
  }

  const handleAddButton = () => {
    if (value === 0) {
      const data = {
        start: new Date(),
        end: new Date(),
      };
      handleCreateEvent(data, 'add');
    }
  }

  const handleSubmit = (data) => {
    dispatch(saveCalendarObject(data));
    setCreateEventMode('');
  }
  return (
    <MainContainer>
      <TicketUserInfo userData={ticketRequest.collaborator} />
      <TicketProviderInfo ticketRequest={ticketRequest} providerData={providerData} onTicketSave={onTicketSave} />
      <TicketSummaryInfo ticketRequest={ticketRequest} onTicketSave={onTicketSave} />
      <StyledTabContainer>
        <Tabs value={value} onChange={(event, value) => handleTabChange(value)}>
          <Tab label={<GenericTitleWithIcon title={'Évênement'} Icon={<EventIcon style={{ fontSize: '1rem' }} />}></GenericTitleWithIcon>} />
          {/* <Tab label={<GenericTitleWithIcon title={'Paiement'} Icon={<EuroIcon style={{ fontSize: '1rem' }} />}></GenericTitleWithIcon>} /> */}
        </Tabs>
        <StyledButton
          startIcon={<AddIcon style={{ fontSize: '0.8rem' }} />}
          onClick={handleAddButton}
          disabled={ticketRequest.status === finalTicketStatus || ticketRequest.status === ticketStatusArray[0]}
        >
          {value === 0 ? 'Événement' : 'Paiement'}
        </StyledButton>
      </StyledTabContainer>
      {value === 0 ?
        <TicketEventTab calendarObject={calendarObject} handleEventSelect={handleEventSelect} />
        :
        <TicketPaymentTab paymentObject={paymentObject}></TicketPaymentTab>
      }
      {!!anchorEl &&
        <CalendarObjectModal
          onClose={() => setAnchorEl(null)}
          onEdit={(data) => handleCreateEvent(data, 'edit')}
          onDelete={(id) => handleDelete(id)}
          data={eventData}
          anchorEl={anchorEl}
        />
      }
      {!!createEventMode &&
        <CreateEventModal
          mode={createEventMode}
          onClose={() => setCreateEventMode('')}
          onSubmit={(data) => handleSubmit(data)}
          data={calendarData}
          ticketId={id}
        />
      }
    </MainContainer>
  )
}

export default TicketRequestInfo;