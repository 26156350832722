export const isOnBoardingStepCGUOnLoad = state => state.onboarding.cgu.save.loading;
export const isOnBoardingStepCGUOnSuccess = state => state.onboarding.cgu.save.success;

export const isOnBoardingStepOneOnLoad = state => state.onboarding.one.save.loading;
export const isOnBoardingStepOneOnSuccess = state => state.onboarding.one.save.success;

export const isOnBoardingStepThreeOnLoad = state => state.onboarding.three.save.loading;
export const isOnBoardingStepThreeOnSuccess = state => state.onboarding.three.save.success;

export const isOnBoardingStepFourOnLoad = state => state.onboarding.four.save.loading;
export const isOnBoardingStepFourOnSuccess = state => state.onboarding.four.save.success;

export const getCorporationData = state => state.onboarding.corporation.data;
export const isCorporationOnLoad = state => state.onboarding.corporation.get.loading;