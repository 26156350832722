import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';

// Styles
import {
  TextFieldCustom,
} from "./style";

const InputField = ({
  type = "text",
  label = "Label",
  value,
  placeholder,
  defaultValue,
  error = false,
  helperTextError,
  select = false,
  variant = "filled",
  startAdornment,
  endAdornment,
  ...rest
}) => {

  return (
      <TextFieldCustom
        type={type}
        label={label}
        value={value}
        placeholder={placeholder}
        defaultValue={defaultValue}
        error={error}
        helperText={helperTextError}
        select={select}
        variant={variant}
        InputProps={{
          startAdornment: startAdornment,
          endAdornment: endAdornment,
        }}
        {...rest}
      >
        {select &&
          <MenuItem key="input-select-item-1" value="Value Item">
            Label Item
          </MenuItem>
        }
      </TextFieldCustom>
  );
}

export default InputField;
