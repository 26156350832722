import React from 'react'
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import GenericTitleWithToolTip from '../GenericTitleWithToolTip';
import GenericErrorMessage from '../GenericErrorMessage';
import _ from 'lodash';

import {
  MainContainer,
} from './style'

const GenericRadioButton = ({
  title = "",
  toolTipText = null,
  isRequired,
  choices = [], // Example data is at the bottom of this file
  className, // to pass styled component className to mainContainer
  ...rest // the rest of the props will be given to RadioGroup component
}) => {
  return (
    <MainContainer className={`${className ? className : ''} genericRadioButton`}>
      <GenericTitleWithToolTip
        title={title}
        toolTipText={toolTipText}
        isRequired={isRequired}
        className={'radioButtonTitle'}
      />
      <RadioGroup {...rest}>
        {
          _.map(choices, (choice, index) => {
            return (
              <FormControlLabel key={index} control={<Radio />} {...choice} />
            )
          })
        }
      </RadioGroup>
      {rest.id && <GenericErrorMessage name={rest.id} />}
    </MainContainer>
  )
}

GenericRadioButton.propTypes = {
  title: PropTypes.string,
  toolTipText: PropTypes.string,
  choices: PropTypes.array,
  isRequired: PropTypes.bool,
  className: PropTypes.string,
}

export default GenericRadioButton

// Example for choices (Please dont delete!): 

// each key of the object correspond to the props accepts by FormControlLabel

// choices = [
//   {
//     label: "Option 1",
//     value: "1",
//   },
//   {
//     label: "Option 2",
//     value: "2",
//     disabled: true,
//   },
// ]

// The provided className of individual tag can be used to be styled when wrapped by styled component
// For the styling of material ui component please refer to the style.js
