import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';

import GenericMultipleSelect from '../../../../components/molecules/GenericMultipleSelect';
import GenericDrawerValidationButtons from '../../../../components/molecules/GenericDrawerValidationButtons';

import { CONTENT_STATUS } from '../../../../constants/contentConstants';

import {
  StyledAccordion,
  StyledForm,
  InputGroup
} from './style';

const ContentFilter = ({
  sitesData,
  setFilterConditions,
  selectedCategory
}) => {
  const [sitesList, setSitesList] = useState([]);

  const statusList = [
    { label: CONTENT_STATUS.NOT_PUBLISHED, value: CONTENT_STATUS.NOT_PUBLISHED },
    { label: CONTENT_STATUS.PUBLISHED, value: CONTENT_STATUS.PUBLISHED },
    ...selectedCategory === 0 ? [{ label: CONTENT_STATUS.PLANNED, value: CONTENT_STATUS.PLANNED }] : [],
    { label: CONTENT_STATUS.ARCHIVED, value: CONTENT_STATUS.ARCHIVED },
  ]

  useEffect(() => {
    if (sitesData != null) {
      const newList = sitesData.map((site) => {
        return { label: site.name, value: site.id }
      })
      setSitesList(newList)
    }
  }, [sitesData])

  const formInitValue = {
    sites: [],
    status: [],
  }

  const handleResetForm = (props) => {
    props.resetForm();
    setFilterConditions(formInitValue);
  }

  return (
    <StyledAccordion
      headerComponent={"Recherche"}
      detailComponent={
        <Formik
          initialValues={formInitValue}
          setFieldValue
          onSubmit={(values) => setFilterConditions(values)}
        >
          {props => (
            <StyledForm>
              <InputGroup>
                <GenericMultipleSelect
                  id="sites"
                  name="sites"
                  onChange={(e, value) => props.setFieldValue('sites', value)}
                  value={props.values.sites}
                  limitItem={5}
                  hasSelectAll={true}
                  list={sitesList}
                  fildName="sites"
                  title="Sites"
                />
                <GenericMultipleSelect
                  id="status"
                  name="status"
                  onChange={(e, value) => props.setFieldValue('status', value)}
                  value={props.values.status}
                  limitItem={5}
                  hasSelectAll={true}
                  list={statusList}
                  fildName="status"
                  title="Statut"
                />
              </InputGroup>
              <GenericDrawerValidationButtons
                leftButonText={'Réinitialiser'}
                RightButtonText={'Chercher'}
                onclickLefttButton={() => handleResetForm(props)}
                submitable={true}
              />
            </StyledForm>
          )}
        </Formik>
      }
    />
  )
}

export default ContentFilter;
