import styled from "styled-components";
import {
  NavLink,
} from "react-router-dom";
import breakpoints from "../../../../theme-style/breakpoints";

export const SendIconWrapper = styled.span`
  max-width: 18px;
  max-height: 14px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin: 0 0 0 auto;
  svg path{
    transition: all .2s ease-out;
  }
`;

export const SkillsItemText = styled.p`
  margin: 4px 0 0 0;
  font-size: 12px;
  color: #BBBBBB;
  line-height: 14px;
  font-weight: 600;
  width: calc(100% - 20px);
`;

export const SkillsItemTitle = styled.strong`
  font-weight: 600;
  font-size: 14px;
  color: #5A5A5A;
  transition: all .2s ease-out;
`;

export const SkillsItemContent = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 0 20px 0 0;
  margin: auto 0;
`;

export const SkillsItemCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  min-width: 50px;
  height: 50px;
  min-height: 50px;
  border-radius: 100px;
  background-color: #397dd2;
  font-size: 24px;
  color: #FFFFFF;
  font-weight: 700;
  margin-right: 15px;
`;

export const SkillsItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  background-color: #FFFFFF;
  height: auto;
  cursor: pointer;
  border-bottom: 1px solid #F4F4F4;
  transition: all .2s ease-out;
  text-decoration: none;
  list-style: none;
  padding: 10px 15px 10px 10px;
  @media ${breakpoints.mobileXL} {
    padding: 15px 20px 15px 15px;
  }
  &:last-child{
    border-bottom: 0;
  }
  &:hover{
    background-color: #FAFAFA;
    ${SendIconWrapper}{
      svg{
        path{
          fill: #397dd2;
        }
      }
    }
  }
`;

export const SkillsList = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 0;
  margin: 0;
  border-radius: 6px;
  overflow: hidden;
`;

export const Title = styled.div`
  color: white;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin: 0;
  transition: all .2s ease-out;
`;

export const BackIconWrapper = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 16px;
  max-height: 12px;
  svg path{
    transition: all .2s ease-out;
  }
`;

export const BackButton = styled.div`
  margin: 0 30px 0 0;
`;

export const Header = styled(NavLink)`
  position: sticky;
  top: 0;
  background-color: #357ED3;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  text-decoration: none;
  padding: 15px 0;
  @media ${breakpoints.mobileXL} {
    padding: 25px 0;
  }
  &:hover{
    ${Title}{
      color: #efc31f;
    }
    ${BackIconWrapper}{
      svg{
        path{
          fill: #efc31f;
        }
      }
    }
  }
`;

export const SkillsWrapper = styled.div`
  padding-bottom: 25px;
`;

export const Container = styled.div`
  /* position: absolute; */
  /* z-index: 888; */
  /* top: 0; */
  height: auto;
  min-height: 100vh;
  width: 100%;
  background-color: #357ED3;
  box-sizing: border-box;
  padding: 0 15px;

  @media ${breakpoints.mobileXL} {
    padding: 0 25px;
  }
`;
