import React from "react";

const TargetIcon = ({
  color = "#5A5A5A",
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon targetIcon"
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M8.466 1.873a.469.469 0 01-.666.66L6.355 1.076a.464.464 0 00-.663 0L4.247 2.533a.469.469 0 01-.666-.66L5.026.416C5.292.148 5.646 0 6.023 0s.731.148.998.416l1.445 1.457zM7.8 9.467l-1.445 1.457a.464.464 0 01-.663 0L4.247 9.467a.469.469 0 00-.666.66l1.445 1.457c.266.268.62.416.997.416s.731-.148.998-.416l1.445-1.457a.469.469 0 00-.666-.66zM2.533 4.223a.469.469 0 10-.66-.665L.416 5.003C.148 5.269 0 5.623 0 6s.148.731.416.997l1.457 1.445a.467.467 0 00.663-.003.469.469 0 00-.003-.662L1.076 6.332a.464.464 0 010-.664l1.457-1.445zm9.05.78l-1.456-1.445a.469.469 0 10-.66.665l1.457 1.445a.464.464 0 010 .664L9.467 7.777a.469.469 0 00.66.665l1.457-1.445c.268-.266.416-.62.416-.997s-.148-.731-.416-.997zM6.024 7.64c-.904 0-1.64-.736-1.64-1.641s.736-1.64 1.64-1.64c.905 0 1.641.735 1.641 1.64 0 .905-.736 1.64-1.64 1.64zm0-.938a.704.704 0 10-.001-1.408.704.704 0 00.001 1.408z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export default TargetIcon;
