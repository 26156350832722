import React, { /*useEffect, useState*/ } from 'react';
import {
  shallowEqual,
  useSelector
} from "react-redux";
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// import AppBar from '@material-ui/core/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

// Icons Components
import HomeIcon from '../../atoms/icons/home'
// import DashboardIcon from '../../atoms/icons/dashboard'
import EducationIcon from '../../atoms/icons/education'
// import FeedbackIcon from '../../atoms/icons/feedback'
import UserListIcon from '../../atoms/icons/userList'
import TaskValidatedIcon from '../../atoms/icons/taskValidated'
import BuildingCompanyIcon from '../../atoms/icons/buildingCompany'
// import EditIcon from '../../atoms/icons/edit'
import MenuIcon from '../../atoms/icons/menu'
import EventIcon from '@material-ui/icons/Event';
import CalendarIcon from '../../atoms/icons/calendar';
import NewsIcon from '../../atoms/icons/newsIcon';
import LogoutIcon from '../../../../../components/atoms/icons/logout';

import LogoutModal from '../../../../../components/organisms/LogoutModal';

import {
  getCompanyData
} from '../../../../../store/company/selectors';

// Styles
import {
  Container,
  Wrapper,
  WrapperDrawerAction,
  IconButtonCustom,
  DrawerCustom,
  ListCustom,
  ListItemCustom,
  ListItemCustomHome,
  ListItemIconCustom,
  ListItemTextCustom,
  HomeIconWrapper,
  // DashboardIconWrapper,
  EducationIconWrapper,
  TaskValidatedIconWrapper,
  BuildingCompanyIconWrapper,
  UserListIconWrapper,
  EditIconWrapper,
  MenuIconWrapper,
  TooltipCustom,
  NagivationButton
} from "./style";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: -10,
  },
  hide: {
    display: 'none',
  },
  spacingLeft: {
    Left: 6,
  },
}));

const SideNavbar = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [openLogoutModal, setOpenLogoutModal] = React.useState(false);

  const {
    companyData,
  } = useSelector(
    state => ({
      companyData: getCompanyData(state),
    }),
    shallowEqual
  );

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Container>
      <CssBaseline />
      <DrawerCustom
        variant="permanent"
        open={open}
      >
        <Wrapper>
          <ListCustom>
            <TooltipCustom
              title={open ? "" : "Mon espace Cyconia"}
              placement="right"
              className={classes.spacingLeft}
            >
              <ListItemCustomHome
                href="/"
                key="home"
              >
                <ListItemIconCustom >
                  <HomeIconWrapper >
                    <HomeIcon />
                  </HomeIconWrapper>
                </ListItemIconCustom>
                <ListItemTextCustom primary="Mon espace" />
              </ListItemCustomHome>
            </TooltipCustom>

            <TooltipCustom
              title={open ? "" : "Tableau de bord"}
              placement="right"
              className={classes.spacingLeft}
            >
              <ListItemCustom
                activeClassName="isActive"
                to="/espace-conciergerie/dashboard"
                key="dashboard"
              >
                <ListItemIconCustom>
                  <EditIconWrapper>
                    <CalendarIcon />
                  </EditIconWrapper>
                </ListItemIconCustom>
                <ListItemTextCustom primary="Tableau de bord" />
              </ListItemCustom>
            </TooltipCustom>

            {/* FOR DELIVERY 3 */}
            {/* <TooltipCustom
            title={open ? "" : "Formation de Cyconia"}
            placement="right"
            className={classes.spacingLeft}
          >
            <ListItemCustom
              activeClassName="isActive"
              to="/espace-conciergerie/training"
              key="training"
            >
              <ListItemIconCustom>
                <EducationIconWrapper>
                  <EducationIcon />
                </EducationIconWrapper>
              </ListItemIconCustom>
              <ListItemTextCustom primary="Formation de Cyconia" />
            </ListItemCustom>
          </TooltipCustom> */}
            {/* FOR DELIVERY 3 */}

            <TooltipCustom
              title={open ? "" : "Demandes"}
              placement="right"
              className={classes.spacingLeft}
            >
              <ListItemCustom
                activeClassName="isActive"
                to="/espace-conciergerie/my-requests"
                key="my-requests"
              >
                <ListItemIconCustom>
                  <TaskValidatedIconWrapper>
                    <TaskValidatedIcon />
                  </TaskValidatedIconWrapper>
                </ListItemIconCustom>
                <ListItemTextCustom primary="Demandes" />
              </ListItemCustom>
            </TooltipCustom>

            <TooltipCustom
              title={open ? "" : "Sites et Services"}
              placement="right"
              className={classes.spacingLeft}
            >
              <ListItemCustom
                activeClassName="isActive"
                to="/espace-conciergerie/site-management"
                key="site-management"
              >
                <ListItemIconCustom>
                  <BuildingCompanyIconWrapper>
                    <BuildingCompanyIcon />
                  </BuildingCompanyIconWrapper>
                </ListItemIconCustom>
                <ListItemTextCustom primary="Sites et Services" />
              </ListItemCustom>
            </TooltipCustom>

            <TooltipCustom
              title={open ? "" : "Annuaire des prestataires"}
              placement="right"
              className={classes.spacingLeft}
            >
              <ListItemCustom
                activeClassName="isActive"
                to="/espace-conciergerie/directory-services"
                key="directory-services"
              >
                <ListItemIconCustom>
                  <UserListIconWrapper>
                    <UserListIcon />
                  </UserListIconWrapper>
                </ListItemIconCustom>
                <ListItemTextCustom primary="Annuaire des prestataires" />
              </ListItemCustom>
            </TooltipCustom>

            <TooltipCustom
              title={open ? "" : "Gestion des utilisateurs"}
              placement="right"
              className={classes.spacingLeft}
            >
              <ListItemCustom
                activeClassName="isActive"
                to="/espace-conciergerie/users-management"
                key="users-management"
              >
                <ListItemIconCustom>
                  <UserListIconWrapper>
                    <UserListIcon />
                  </UserListIconWrapper>
                </ListItemIconCustom>
                <ListItemTextCustom primary="Gestion des utilisateurs" />
              </ListItemCustom>
            </TooltipCustom>

            <TooltipCustom
              title={open ? "" : "Gestion du contenu"}
              placement="right"
              className={classes.spacingLeft}
            >
              <ListItemCustom
                activeClassName="isActive"
                to="/espace-conciergerie/content-management"
                key="content-management"
              >
                <ListItemIconCustom>
                  <EditIconWrapper>
                    <NewsIcon />
                  </EditIconWrapper>
                </ListItemIconCustom>
                <ListItemTextCustom primary="Gestion du contenu" />
              </ListItemCustom>
            </TooltipCustom>

            {companyData?.meetingRoom &&
              <TooltipCustom
                title={open ? "" : "Gestion des réservations"}
                placement="right"
                className={classes.spacingLeft}
              >
                <ListItemCustom
                  activeClassName="isActive"
                  to="/espace-conciergerie/booking-report"
                  key="booking-report"
                >
                  <ListItemIconCustom>
                    <EditIconWrapper>
                      <NewsIcon />
                    </EditIconWrapper>
                  </ListItemIconCustom>
                  <ListItemTextCustom primary="Gestion des réservations" />
                </ListItemCustom>
              </TooltipCustom>
            }

            <TooltipCustom
              title={open ? "" : "Déconnexion"}
              placement="right"
              className={classes.spacingLeft}
            >
              <NagivationButton
                onClick={() => setOpenLogoutModal(!openLogoutModal)}
              >
                <ListItemIconCustom>
                  <EditIconWrapper>
                    <LogoutIcon color={"#2B2B2B"} />
                  </EditIconWrapper>
                </ListItemIconCustom>
                <ListItemTextCustom primary="Déconnexion" />
              </NagivationButton>
            </TooltipCustom>
          </ListCustom>
        </Wrapper>
        <WrapperDrawerAction>
          {
            open ?
              <IconButtonCustom onClick={handleDrawerClose}>
                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButtonCustom>
              :
              <IconButtonCustom
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: open,
                })}
              >
                <MenuIconWrapper>
                  <MenuIcon />
                </MenuIconWrapper>
              </IconButtonCustom>
          }
        </WrapperDrawerAction>
        <LogoutModal open={openLogoutModal} setOpenDialog={() => setOpenLogoutModal(!openLogoutModal)} />
      </DrawerCustom>
    </Container>
  );
}

export default SideNavbar;
