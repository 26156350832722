export const getUser = state => state.collaborator.user;
export const isGetUserLoading = state => state.collaborator.getUser.loading;
export const getUserExpressingId = state => {
  const expressingId = state.collaborator.user?.user_expressing_id;
  if (expressingId && !isNaN(parseInt(expressingId, 10))) {
    return expressingId;
  }
  return null;
};

export const hasUserFinishOnboarding = state => {
  const currentUser = getUser(state);
  if (currentUser) {
    return state.collaborator.user.onboarding_step === 'finished';
  }
  return false;
};

export const isSaveCollaboratorTransportsOnLoad = state => state.collaborator.saveTransports.save.loading;
export const isSaveCollaboratorTransportsOnSuccess = state => state.collaborator.saveTransports.save.success;
export const isSaveCollaboratorTransportsOnError = state => state.collaborator.saveTransports.save.error;
export const getSavedCollaboratorTransports = state => state.collaborator.saveTransports.data;

export const isSaveCollaboratorProfileOnLoad = state => state.collaborator.saveProfile.save.loading;
export const isSaveCollaboratorProfileOnSuccess = state => state.collaborator.saveProfile.save.success;
export const isSaveCollaboratorProfileOnError = state => state.collaborator.saveProfile.save.error;
export const getSavedCollaboratorProfile = state => state.collaborator.saveProfile.data;

export const isSaveCollaboratorInformationsOnLoad = state => state.collaborator.saveInformations.save.loading;
export const isSaveCollaboratorInformationsOnSuccess = state => state.collaborator.saveInformations.save.success;
export const isSaveCollaboratorInformationsOnError = state => state.collaborator.saveInformations.save.error;

