import React from 'react';
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";

// Styles
import {
  Container,
} from "./style";

const DrawerCpt = ({
  children,
  anchor,
  visible = false,
  closeFunction }) => {

  return (
    <SwipeableDrawer
      transitionDuration={0}
      anchor={anchor ? anchor : "right"}
      open={visible}
      onClose={closeFunction}
      onOpen={() => {}} // To silent warning
    >
      {children}
    </SwipeableDrawer>
  );
}

export default DrawerCpt;
