import styled from "styled-components";
import {
  Link
} from "react-router-dom";
// import Modal from '@material-ui/core/Modal';
import breakpoints from "../../../../theme-style/breakpoints";

import GenericTitleWithToolTip from "../../../../components/molecules/GenericTitleWithToolTip";

export const Container = styled.div`
  width: 100%;
  height: auto;
  min-height: calc(100vh - 110px);
  background-color: #357ED3;
  padding: 15px;
  box-sizing: border-box;
  >:not(:last-child) {
    margin-bottom: 1.5rem;
  };
  @media ${breakpoints.mobileXL} {
    padding: 25px;
  }
`;

export const StyledTitleWithToolTip = styled(GenericTitleWithToolTip)`
  color: white;
  font-size: 16px;
  font-weight: 700;
  padding: 0 0 8px 0;
  margin-top: 15px;
  @media ${breakpoints.mobileXL} {
    padding: 0 0 15px 0;
  }
`;

export const TransportItemButton = styled(Link)`
  max-width: 180px;
  color: #ffffff;
  background-color: rgb(74, 144, 226);
  border-radius: 3px;
  padding: ${p => p.transportEmpty ? "8px" : "5px 8px"};
  font-size: 12px;
  font-weight: 600;
  text-decoration: none;
`;

export const TransportItemTime = styled.span`
  max-width: 180px;
  color: #5A5A5A;
  background-color: #EDEDED;
  border-radius: 3px;
  padding: 5px 8px;
  font-size: 12px;
  font-weight: 600;
`;

export const TransportItemInfo = styled.strong`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #5A5A5A;
  text-align: left;
  font-weight: 600;
  width: ${p => p.transportEmpty && "68%"};
  padding-right: ${p => p.transportEmpty ? "10px" : "0"};
  font-size: ${p => p.transportEmpty ? "13px" : "14px"};
`;

export const TransportIconWrapper = styled.span`
  max-width: 16px;
  margin-right: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &.subway{
    max-width: 16px;
    margin-left: 0px;
    margin-right: 14px;
  }
  &.bike{
    max-width: 23px;
    margin-left: -3px;
    margin-right: 12px;
  }
  &.walk{
    max-width: 17px;
    margin-right: 13px;
  }
  &.car{
    max-width: 25px;
    margin-left: -4px;
    margin-right: 10px;
  }
  &.bus{
    max-width: 20px;
    margin-left: -1px;
    margin-right: 14px;
  }
`;

export const TransportItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  box-sizing: border-box;
  margin: 0;
  background-color: #FFFFFF;
  height: auto;
  border-bottom: 1px solid #F4F4F4;
  transition: all .2s ease-out;
  list-style: none;
  height: 60px;
  &:last-child{
    border-bottom: 0;
  }
`;

export const TransportList = styled.ul`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 0;
  margin: 0;
  border-radius: 6px;
  overflow: hidden;
`;

export const TransportWrapper = styled.div`
  padding: 0;
`;