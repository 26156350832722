import styled from "styled-components";

import { COLORS } from '../../../../../constants/design';

export const MainContainer = styled.div`
`;

export const StyledTitle = styled.div`
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: 1rem;
`;

export const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 10px;
  font-size: 0.8rem;
  color: ${COLORS.DARK_GREY};
  background-color: ${COLORS.YELLOW_2};
  >:not(:last-child) {
    margin-bottom: 8px;
  };
`;
