import axios from 'axios';

export const saveTicketMessageService = (endpoint, userToken, user, formData) => {
  const newForm = new FormData();
  newForm.append('content', formData.content);
  newForm.append('type', formData.type);
  newForm.append('author', parseInt(user.id));
  newForm.append('attachment', formData.attachment);
  newForm.append('attachment2', formData.attachment2);
  newForm.append('attachment3', formData.attachment3);
  newForm.append('attachment4', formData.attachment4);
  newForm.append('attachment5', formData.attachment5);
  newForm.append('ticket', parseInt(formData.ticket));
  // console.log('newForm', ...newForm);
  // console.log('formData', formData);

  const headers = {};
  if (userToken) {
    headers.Authorization = `Bearer ${userToken}`;
  }

  return axios.request({
    method: 'post',
    url: endpoint,
    data: newForm,
    headers: headers
  })
}

export const deleteTicketMessageService = async (endpoint, userToken) => {
  const headers = {};
  if (userToken) {
    headers.Authorization = `Bearer ${userToken}`;
  }

  return axios.request({
    method: 'delete',
    url: endpoint,
    headers: headers
  });
}