import styled from "styled-components";
import Dialog from '@material-ui/core/Dialog';

export const CustomDialog = styled(Dialog)`
  && {
    .MuiDialog-container {
      .MuiDialog-paper {
        padding: 10px;
        margin: 10px;
        box-sizing: border-box;
        text-align: center;
        & > div {
          & > h2{ 
            font-size: 21px;
            font-weight: 600;
            color: #5A5A5A;
            text-align: center;
          }
          article{
            padding: 30px 0;
            & > h2{
              font-size: 17px;
              font-weight: 600;
              color: #5A5A5A;
              text-align: center;
              margin: 30px auto 15px auto;
            }
            & > h3{
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 15px;
              font-weight: 500;
              color: #797979;
              margin: 5px 0;
            }
            p{
              font-size: 15px;
              color: #838383;
              text-align: center;
              font-weight: 400;
              display: inline-flex;
              flex-direction: column;
              margin: 10px auto;
              line-height: 21px;
              strong{
                font-weight: 600;
              }
            }
            ul{
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              border-radius: 8px;
              border: 1px solid #e8e8e8;
              padding: 10px;
              box-sizing: border-box;
              width: auto;
              margin: 5px 0 15px 0;
              li{
                list-style: initial;
                font-size: 15px;
                color: #838383;
                font-weight: 600;
                display: inline-flex;
                margin: 5px 0 0 0;
                line-height: 21px;
                text-align: center;
                strong{
                  font-weight: 600;
                }
              }
            }
          }
        }
        button{
          display: block;
          width: 100%;
          padding: 14px;
          text-align: center;
          font-weight: 700;
          border: none;
          text-decoration: none;
          border-radius: 6px;
          cursor: pointer;
          font-size: 14px;
          height: 60px;
          margin-top: auto;
          background-color: #463e96;
          color: #fff;
          text-transform: uppercase;
        }
      }
    }
  }
  
`;

export const StyledTitle = styled.div`
  font-size: 1.1rem;
  font-weight: 600;
  display: flex;
  margin-bottom: 15px;
`;