import React from "react";

const FeedbackIcon = ({
  color = "#2B2B2B",
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon feedbackIcon"
      width={width}
      height={height}
      viewBox="0 0 15 16"
      fill="none"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
          <path
            d="M13.0547727,0.5 C14.1286248,0.501181091 14.9988726,1.41286931 15,2.53785714 L15,9.89071429 C14.9988726,11.0157021 14.1286248,11.9273903 13.0547727,11.9285714 L10.5470455,11.9285714 L7.91454545,15.2428571 C7.78506213,15.4057157 7.5932268,15.5 7.39090909,15.5 L7.37727273,15.5 C7.17022086,15.4955895 6.97630513,15.392832 6.85022727,15.2207143 L4.43659091,11.9285714 L1.94522727,11.9285714 C0.871375248,11.9273903 0.00112740463,11.0157021 0,9.89071429 L0,2.53785714 C0.00112740463,1.41286931 0.871375248,0.501181091 1.94522727,0.5 L13.0547727,0.5 Z M13.0547727,1.92857143 L1.94522727,1.92857143 C1.62417924,1.92896503 1.36401208,2.20152111 1.36363636,2.53785714 L1.36363636,9.89071429 C1.36401208,10.2270503 1.62417924,10.4996064 1.94522727,10.5 L4.77272727,10.5 C4.98458469,10.5000378 5.18438793,10.6032449 5.31340909,10.7792857 L7.41204545,13.6428571 L9.70295455,10.7578571 C9.83245947,10.5945399 10.0246046,10.5000439 10.2272727,10.5 L13.0547727,10.5 C13.3758208,10.4996064 13.6359879,10.2270503 13.6363636,9.89071429 L13.6363636,2.53785714 C13.6359879,2.20152111 13.3758208,1.92896503 13.0547727,1.92857143 Z M11.5909091,6.92857143 C11.9674669,6.92857143 12.2727273,7.24836804 12.2727273,7.64285714 C12.2727273,8.03734625 11.9674669,8.35714286 11.5909091,8.35714286 L3.40909091,8.35714286 C3.03253313,8.35714286 2.72727273,8.03734625 2.72727273,7.64285714 C2.72727273,7.24836804 3.03253313,6.92857143 3.40909091,6.92857143 L11.5909091,6.92857143 Z M7.5,4.07142857 C7.87655778,4.07142857 8.18181818,4.39122518 8.18181818,4.78571429 C8.18181818,5.18020339 7.87655778,5.5 7.5,5.5 L3.40909091,5.5 C3.03253313,5.5 2.72727273,5.18020339 2.72727273,4.78571429 C2.72727273,4.39122518 3.03253313,4.07142857 3.40909091,4.07142857 L7.5,4.07142857 Z"
            fill={color}
            fillRule="nonzero"
          >
          </path>
      </g>
    </svg>
  );
};

export default FeedbackIcon;
