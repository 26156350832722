import React, { useState, useEffect, useContext } from "react";
import { VisibilityContext } from "react-horizontal-scrolling-menu";
import LeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import RightIcon from '@material-ui/icons/KeyboardArrowRight';

import { 
  ArrowIcon,
} from './style';

export const LeftArrow = () => {
  const { isFirstItemVisible, scrollPrev, visibleItemsWithoutSeparators } = useContext(VisibilityContext);
  const [disabled, setDisabled] = useState(true);

  // NOTE: detect if whole component visible
  useEffect(() => {
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleItemsWithoutSeparators]);

  return (
    <ArrowIcon disabled={disabled} position={'left'} onClick={() => scrollPrev()}>
      <LeftIcon/>
    </ArrowIcon>
  );
}

export const RightArrow = () => {
  const { isLastItemVisible, scrollNext, visibleItemsWithoutSeparators } = useContext(VisibilityContext);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleItemsWithoutSeparators]);

  return (
    <ArrowIcon disabled={disabled} position={'right'} onClick={() => scrollNext()}>
      <RightIcon/>
    </ArrowIcon>
  );
}
