import styled from "styled-components";
import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import DrawerCpt from "../../../../components/organisms/drawerCpt";
import { COLORS } from '../../../../constants/design';

export const Container = styled.div`
`;

export const DrawerCptCustom = styled(DrawerCpt)`
  &&{
    background-color: red;
    &.contentManagementDrawer{
      /* background-color: blue; */
    }
  }
`;

export const DrawerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 700px;
  max-height: 1700px;
  position: relative;
  /* border: 10px solid red; */
  .textTitle {
    font-weight: 700;
    font-size: 13px;
    color: #5A5A5A;
    margin: 14px 5px 14px 0px;
  }
  .textTooltip {
    color: ${COLORS.GREY_1};
  }
  .textFieldInput {
    input {
      font-size: 13px;
    }
  }
  .genericMultipleSelect {
    max-width: 700px;
  }
  /* >div:not(:last-child) {
    margin-bottom: 15px;
  }; */
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  >div:not(:last-child) {
    margin-bottom: 15px;
  };
`;

export const FieldWithErrorMessageDiv = styled.div`
`;

export const PreviewButton = styled(Button)`
  && {
    &.MuiButton-root {
      text-transform: none;
      color: white;
      font-size: 0.9rem;
      padding: 10px;
      border-radius: 8px;
      background-color: ${COLORS.PRIMARY_BLUE};
    }
  }
`

export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${COLORS.LIGHT_GREY_3};
`;

export const StyledDialog = styled(Dialog)`
  && {
    .MuiDialog-container {
      .MuiDialog-paper {
        color: #212121;
        background-color: #FFFFFF;
        border-radius: 10px;
        &.MuiPaper-rounded {
          border-radius: 10px;
          width: 100%;
          max-width: 750px;
          height: 90vh;
        }
        .MuiDialogTitle-root {
          text-align: center;
          font-weight: 600;
          padding: 20px 50px 0px 50px;
        }
        .MuiDialogContent-root {
          padding: 40px 50px;
          .MuiTypography-colorTextSecondary {
            text-align: center;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.8);
          }
        }
        .MuiDialogActions-root {
          justify-content: space-between;
          padding: 0 50px 40px 50px;
          .MuiButtonBase-root {
            height: 52px;
            margin: 0 10px;
            .MuiButton-label {
              font-size: 12px;
            }
            &:hover {
              .MuiButton-label {
              }
            }
          }
        }
      }
    }
  }
`;