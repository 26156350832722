import styled from "styled-components";
import Popper from '@material-ui/core/Popper';
import Button from '@material-ui/core/Button';

import { COLORS } from "../../../../constants/design";
import { size } from '../../../../theme-style/breakpoints';

export const StyledPopper = styled(Popper)`
  /* border: 10px solid red; */
  z-index: 1500;
  transition: ${props => props.opencount === 1 ? "transform 0.3s" : "none"};
  box-sizing: border-box;
  @media (max-width: ${size.mobileXL}) {
    width: 100%;
    height: 100%;
    transform: none !important;
  }
`;

export const PopUpContainer = styled.div` 
  /* min-width: 400px; */
  padding: 0;
  background-color: white;
  box-shadow: 0 10px 34px rgb(0 0 0 / 0.3);
  padding: 25px;
  border-radius: 6px;
  color: ${COLORS.DARK_GREY};
  width: 560px;
  font-size: 0.9rem;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  line-height: normal;
  /* border: 1px solid black; */
  @media (max-width: ${size.mobileXL}) {
    width: 100%;
    height: 100%;
  }
`;

export const TitleContainer = styled.div`
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  position: relative;
`;

export const Title = styled.span`
  font-size: 1rem;
  font-weight: 600;
  color: ${COLORS.DARK_GREY};
`;

export const StyledCloseContainer = styled.div`
  position: absolute;
  z-index: 9999;
  color: ${COLORS.DARK_GREY};
  background-color: ${COLORS.LIGHT_GREY_1};
  border-radius: 50%;
  padding: 1px;
  display: flex;
  opacity: 0.8;
  top: 0px;
  right: 0px;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;

export const ContentContainer = styled.div`
  /* border: 1px solid red; */
  width: 100%;
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  color: ${COLORS.DARK_GREY};
  max-width: 100%;
  overflow-wrap: break-word;
  flex-wrap: wrap;
  >div:not(:last-child) {
    margin-bottom: 10px;
  };
`;

export const StyledInfoGroup = styled.div`
  /* border: 1px solid black; */
  width: 100%;
  display: flex;
`;

export const StyledInfoTitle = styled.div`
  /* border: 1px solid blue; */
  font-weight: 700;
  margin-right: 30px;
  /* min-width: 160px; */
  width: 30%;
`;

export const StyledInfoText = styled.div`
  /* border: 1px solid red; */
  width: 60%;
  font-size: 0.85rem;
  word-wrap: break-word;
`;

export const StyledOptionsDisplay = styled.div`
  display: flex;
`;

export const StyledButton = styled(Button)`
  && {
    &.MuiButton-root {
      color: ${COLORS.DARK_GREY};
      position: relative;
      /* margin-right: 5px; */
      font-size: 0.85rem;
      font-weight: 500;
      background-color: ${COLORS.LIGHT_GREY_1};
      padding: 10px 15px;
      transition: all ease-in .15s;
      border-radius: 5px;
      text-transform: none;
      align-self: flex-end;
      &:hover {
        background-color: ${COLORS.LIGHT_GREY_2};
      }
    }
  }
`;