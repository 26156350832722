import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';

import { format, differenceInMilliseconds } from 'date-fns';
import fr from 'date-fns/locale/fr';

//Import Formik
import { Formik, Field } from 'formik';
import * as Yup from "yup";


import GenericTextField from '../../../../../components/molecules/GenericTextField'
import GenericTextArea from '../../../../../components/molecules/GenericTextArea'
import GoogleSuggestAndHeader from '../../../../../components/molecules/GoogleSuggestAndHeader';
import GenericMultipleSelect from '../../../../../components/molecules/GenericMultipleSelect';
import GenericFileChooser from '../../../../../components/molecules/GenericFileChooser';
import GenericCheckBoxes from '../../../../../components/molecules/GenericCheckBoxes';

import {
  CustomDialog,
  DialogContainer,
  StyledForm,
  CustomTabContainer,
  CustomTab,
  InputGroup,
  StyledValidationButtons
} from './style'

const checkBoxChoices = [
  {
    label: "Toute la journée",
    value: "allDay",
  },
];

const CreateEventModal = ({ mode, onClose, onSubmit, data, ticketId }) => {
  const [category, setCategory] = useState(0);

  useEffect(() => {
    if (data && data.resource)
      setCategory(data.resource.type === 'event' ? 0 : 1)
  }, [data])

  const generateInitValue = () => {
    const start = new Date(data.start);
    const end = new Date(data.end);
    const milliseconds = differenceInMilliseconds(end, start);
    const isFullDay = milliseconds === 86400000 ? true : false;
    const initValue = category === 0 ? {
      ticketId: ticketId ?? null,
      title: data.title ?? "",
      type: "event",
      allDay: data.allDay || isFullDay ? ['allDay'] : [],
      startDate: format(start, "yyyy-MM-dd", { locale: fr }),
      startTime: format(start, "HH:mm", { locale: fr }),
      endDate: format(end, "yyyy-MM-dd", { locale: fr }),
      endTime: format(end, "HH:mm", { locale: fr }),
      note: data.resource?.note || "",
    } : {
      ticketId: ticketId ?? null,
      title: data.title ?? "",
      type: "task",
      allDay: data.allDay || isFullDay ? ['allDay'] : [],
      startDate: format(start, "yyyy-MM-dd", { locale: fr }),
      startTime: format(start, "HH:mm", { locale: fr }),
      endDate: format(end, "yyyy-MM-dd", { locale: fr }),
      endTime: format(end, "HH:mm", { locale: fr }),
      note: data.resource?.note || "",
    }
    return initValue;
  }

  const newContentActuSchema = Yup.object().shape({
    title: Yup.string()
      .required('Un titre est requis'),
  })

  const handleCheckBox = (event, props) => {
    let newValue = props.values.allDay;
    newValue = event.target.checked ? [...newValue, event.target.value] : newValue.filter(site => site !== event.target.value);
    props.setFieldValue('allDay', newValue);
  }

  const handleTabChange = (_, newValue) => {
    setCategory(newValue);
  };

  const handleSubmit = (values) => {
    onSubmit({ id: mode === 'edit' ? data.id : null, formData: values });
  };


  return (
    <CustomDialog
      open={true}
      onClose={onClose}
    >
      <DialogContainer>
        {data &&
          <Formik
            initialValues={generateInitValue()}
            enableReinitialize
            setFieldValue
            validationSchema={newContentActuSchema}
            onSubmit={handleSubmit}
          >
            {(props) => {
              // console.log('props.values', props.values);
              return (
                <StyledForm>
                  <Field
                    id="title"
                    name="title"
                    title="Titre"
                    placeholder="Ajouter un titre"
                    variant="outlined"
                    size="small"
                    isRequired={true}
                    as={GenericTextField} />
                  {mode === 'add' &&
                    <CustomTabContainer
                      value={category}
                      TabIndicatorProps={{ style: { display: "none" } }}
                      onChange={handleTabChange}
                    >
                      <CustomTab label="Événement" />
                      <CustomTab label="Tâche" />
                    </CustomTabContainer>
                  }
                  <GenericCheckBoxes
                    value={props.values.allDay}
                    choices={checkBoxChoices}
                    onChange={(event) => handleCheckBox(event, props)}
                  />
                  <InputGroup>
                    <Field
                      id="startDate"
                      name="startDate"
                      type="date"
                      title="Début"
                      variant="outlined"
                      size="small"
                      disabled={props.values.allDay.length > 0}
                      as={GenericTextField} />
                    <Field
                      id="startTime"
                      name="startTime"
                      type="time"
                      title=" "
                      variant="outlined"
                      size="small"
                      disabled={props.values.allDay.length > 0}
                      as={GenericTextField} />
                  </InputGroup>
                  <InputGroup>
                    <Field
                      id="endDate"
                      name="endDate"
                      type="date"
                      title="Fin"
                      variant="outlined"
                      size="small"
                      disabled={props.values.allDay.length > 0}
                      as={GenericTextField} />
                    <Field
                      id="endTime"
                      name="endTime"
                      type="time"
                      title=" "
                      variant="outlined"
                      size="small"
                      disabled={props.values.allDay.length > 0}
                      as={GenericTextField} />
                  </InputGroup>
                  {/* {category === 0 &&
                    <>
                      <GenericMultipleSelect
                        list={serviceList}
                        fildName="services"
                        id="services"
                        name="services"
                        title="Services"
                        value={props.values.services || []}
                        onChange={(e, value) => props.setFieldValue('services', value)}
                      />
                      <Field
                        id="address"
                        name="address"
                        title="Address"
                        variant="outlined"
                        size="small"
                        setAddress={(data) => props.setFieldValue('address', data)}
                        as={GoogleSuggestAndHeader} />
                      <Field
                        id="url"
                        name="url"
                        title="Url"
                        placeholder="Ajouter une url"
                        variant="outlined"
                        size="small"
                        as={GenericTextField} />
                      <GenericFileChooser
                        title="Document"
                        emptyFileText="Pas de document"
                        buttonText="Télécharger un document"
                        id="chooseFile"
                        name="chooseFile"
                        onChange={(value) => props.setFieldValue('chooseFile', value)}
                        selectedFileName={props.values.chooseFile?.name || ''}
                      />
                    </>
                  } */}

                  <Field
                    id="note"
                    name="note"
                    title="Note"
                    variant="outlined"
                    placeholder="Ajouter une note"
                    minRows={4}
                    as={GenericTextArea} />
                  <StyledValidationButtons
                    leftButonText={'Annuler'}
                    RightButtonText={'Sauvegarder'}
                    onclickLefttButton={onClose}
                    submitable={true}
                  />
                </StyledForm>
              )
            }}
          </Formik>
        }
      </DialogContainer>
    </CustomDialog >
  )
}

CreateEventModal.propTypes = {
  mode: PropTypes.string,
  onClose: PropTypes.func,
  data: PropTypes.object
}

export default CreateEventModal