import React, { useEffect, useState } from 'react';
import {
  shallowEqual,
  useDispatch,
  useSelector,
} from "react-redux";

// Import services
// import getUntreatedFb from '../../services/getUntreatedFeedback.service';
import {
  getUntreatedFeedbackData,
} from '../../../../../../store/adminFront/selectors';
import {
  getUntreatedFeedback,
} from '../../../../../../store/adminFront/actions';

import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Link } from 'react-router-dom';

// components
import EnhancedTableToolbar from './components/tableToolbar'
import EnhancedTableHead from './components/tableHead'

import moment from 'moment';

// Styles
import {
  Container,
  Wrapper,
  PaperCustom,
  TableCellCustom,
  TableCustom,
  TableRowCustom,
  TablePaginationCustom
} from "./style";

const TableAllFeedbackUntreated = ({
  id = 'Title headCell',
  numeric = false,
  disablePadding = false,
  label = 'Label',
  titleToolbar = null,
  checkboxActive = false,
  densePadding = false
}) => {
  const dispatch = useDispatch();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [rows, setRows] = useState(null);

  const {
    untreatedFb,
  } = useSelector(
    state => ({
      untreatedFb: getUntreatedFeedbackData(state),
    }),
    shallowEqual
  );

  useEffect(() => {
      dispatch(getUntreatedFeedback());
  }, []);

  useEffect(() => {
      // console.log('untreatedFb', untreatedFb);
      
      if (!!untreatedFb?.items?.length) {
        const rows = untreatedFb?.items.map((item, index) => {
        const indexKey = `key-untreatedFeedback-${index}`;
        return {
          indexKey,
          item
        };
      });
      setRows(rows)
    }
      
  }, [untreatedFb]);
  
  // useEffect(() => {
  //   getUntreatedFb().then(data => {
  //     const rows = data.items.map((item, index) => {
  //       const indexKey = `key-untreatedFeedback-${index}`;

  //       return {
  //         indexKey,
  //         item
  //       };
  //     });
  //     setRows(rows)
  //   });
  // }, []);

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rows ? (rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)) : null;

  return (
    <Container>
      {densePadding && (
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        />
      )}
      <Wrapper>
        <PaperCustom>
          {titleToolbar || checkboxActive && (
            <EnhancedTableToolbar
              numSelected={selected.length}
              titleToolbar={titleToolbar}
            />
          )}
          <TableContainer>
            <TableCustom
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
              aria-label="enhanced table"
            >
              {!!rows?.length &&
                <>
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                    id={id || 'nameHeadCell'}
                    numeric={numeric || false}
                    disablePadding={disablePadding || false}
                    label={label}
                    checkboxactive={checkboxActive || false}
                  />

                  <TableBody>
                    {stableSort(rows, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        const checkboxActiveVal = checkboxActive || false;

                        const {
                          indexKey,
                          item: {
                            created_at,
                            skill,
                            information_element_title,
                            company_site,
                            information_element_id,
                            collaborator,
                            feedback_type,
                            user_comment
                          }
                        } = row;


                        return (

                          <TableRowCustom
                            hover
                            // onClick={checkboxActiveVal ? (event) => handleClick(event, row.id) : null}
                            role="checkbox"
                            checkboxactive={checkboxActiveVal ? checkboxActiveVal : null}
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={indexKey}
                            selected={isItemSelected}
                          >
                            {checkboxActiveVal && (
                              <TableCellCustom className="tableCell-checkbox" padding="checkbox">
                                <Checkbox
                                  checked={isItemSelected}
                                  inputProps={{ 'aria-labelledby': labelId }}
                                />
                              </TableCellCustom>
                            )}

                            <TableCellCustom
                              className="tableCell-date" component="th" id={labelId} scope="row">

                              <Link to={"/admin/feeds/all-feedback?opendrawerbyid=" + information_element_id}>
                                <span>
                                  {moment(created_at).format('DD/MM/YYYY HH:mm') || '-'}
                                </span>
                              </Link>
                            </TableCellCustom>

                            <TableCellCustom
                              className="tableCell-site" align="left">

                              <Link to={"/admin/feeds/all-feedback?opendrawerbyid=" + information_element_id}>
                                <span>
                                  {company_site || '-'}
                                </span>
                              </Link>
                            </TableCellCustom>

                            <TableCellCustom
                              className="tableCell-competence" align="left">

                              <Link to={"/admin/feeds/all-feedback?opendrawerbyid=" + information_element_id}>
                                <span>
                                  {skill || '-'}
                                </span>
                              </Link>
                            </TableCellCustom>

                            <TableCellCustom
                              className="tableCell-info" align="left">

                              <Link to={"/admin/feeds/all-feedback?opendrawerbyid=" + information_element_id}>
                                <span>
                                  {information_element_title || '-'}
                                </span>
                              </Link>
                            </TableCellCustom>

                            {/*<TableCellCustom
                              className="tableCell-collaborator" align="left">

                              <Link to={"/admin/feeds/all-feedback?opendrawerbyid=" + information_element_id}>
                                <span>
                                  {collaborator || '-'}
                                </span>
                              </Link>
                            </TableCellCustom>*/}

                            <TableCellCustom
                              className="tableCell-type" align="left">

                              <Link to={"/admin/feeds/all-feedback?opendrawerbyid=" + information_element_id}>
                                <span>
                                  {feedback_type || '-'}
                                </span>
                              </Link>
                            </TableCellCustom>

                            <TableCellCustom
                              className="tableCell-feedback" align="left">

                              <Link to={"/admin/feeds/all-feedback?opendrawerbyid=" + information_element_id}>
                                <span>
                                  {user_comment || '-'}
                                </span>
                              </Link>
                            </TableCellCustom>

                          </TableRowCustom>
                        );
                      })}
                    {/*emptyRows > 0 && (
                      <TableRowCustom style={{ height: (dense ? 33 : 53) * emptyRows }}>
                        <TableCellCustom colSpan={7} />
                      </TableRowCustom>
                    )*/}
                  </TableBody>
                </>
              }
            </TableCustom>
          </TableContainer>
          <TablePaginationCustom
            labelDisplayedRows={({ from, to, count }) => `${from}-${to}  sur  ${count} au total`}
            labelRowsPerPage="Remarques par page"
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={rows && rows.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </PaperCustom>
      </Wrapper >
    </Container >
  );
}

export default TableAllFeedbackUntreated;
