import React from 'react';
import _ from 'lodash';

import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import GenericTitleWithToolTip from '../GenericTitleWithToolTip';
import { Tooltip } from '@material-ui/core';

import {
  StyledMainContainer,
  StyledMenu,
  StyledButton,
  StyledInputDiv,
  StyledInputText,
  StyledIconDiv
} from './style';

const GenericMaterialMenu = ({
  title,
  list = [],
  onChange,
  toolTipText = '',
  toolTipProps,
  ToolTipIcon,
  isRequired,
  initialValue = null,
  variant = 'input',
  startIcon = null,
  disabled = false,
  error = false,
  className, // to pass styled component className to mainContainer
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedChoice, setSelectedChoice] = React.useState(null);

  React.useEffect(() => {
    if (list) {
      const newSelectedChoice = list.find((data) => data.value === initialValue) || list[0];
      setSelectedChoice(newSelectedChoice);
    }
  }, [list, initialValue]);

  const handleClick = (event) => {
    event.stopPropagation();
    if (disabled) return;
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleItemPick = (event, choice) => {
    event.stopPropagation();
    onChange(choice.value);
    setSelectedChoice(choice)
    setAnchorEl(null);
  }

  const renderElement = () => {
    if (variant === 'button') {
      return (
        <Tooltip title={toolTipText}>
          <StyledButton
            startIcon={startIcon}
            onClick={handleClick}
            className={"menuButton"}
            disabled={disabled}
          >
            {title || "Menu"}
          </StyledButton>
        </Tooltip>
      )
    }
    else if (variant === 'input') {
      return (
        <>
          <GenericTitleWithToolTip
            title={title}
            toolTipText={toolTipText}
            ToolTipIcon={ToolTipIcon}
            toolTipProps={toolTipProps}
            isRequired={isRequired}
            error={error}
            className={'textFieldTitle'}
          />
          <StyledInputDiv
            onClick={handleClick}
            className={"menuInput"}
            disabled={disabled}
          >
            <StyledInputText className={"menuInputText"}>
              {selectedChoice?.label}
            </StyledInputText>
            <StyledIconDiv className={"menuIcon"}>
              {anchorEl ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </StyledIconDiv>
          </StyledInputDiv>
        </>
      )
    }
    else if (variant === 'threeDots') {
      return (
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={anchorEl ? 'long-menu' : undefined}
          aria-expanded={anchorEl ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
      )
    }
  }

  return (
    <StyledMainContainer className={`${className ? className : ''} genericMaterialMenu`} {...rest} >
      {renderElement()}

      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        {...rest}
      >
        {_.map(list, (choice, index) => {
          return (
            <MenuItem
              selected={choice.value === selectedChoice?.value}
              key={index}
              disabled={choice.disabled}
              onClick={(e) => handleItemPick(e, choice)}
            >
              {choice.label}
            </MenuItem>
          )
        })}
      </StyledMenu>
    </StyledMainContainer>
  )
};

export default GenericMaterialMenu;

// usage:

// const list = [
//   {
//     value: "value_to_be_save_1",
//     text: "text_to_display_1"
//   },
//   {
//     value: "value_to_be_save_2",
//     text: "text_to_display_2"
//   },
//   {
//     value: "value_to_be_save_3",
//     text: "text_to_display_3"
//   },
// ];

// variant = 'input' | 'button',
// if variant is button, a title props will be needed for the button,
// if variant is input, the display value will be based on selected choice