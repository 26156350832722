export const PRICE_UNIT = {
  FIXED: 'fixed_price',
  QUOTE: 'quote',
  FREE: 'free',
};

export const VAT = {
  FIVE_FIVE_INC: '5,5_included',
  TEN_INC: '10_included',
  TWENTY_INC: '20_included',
  FIVE_FIVE_EX: '5,5_applicable',
  TEN_EX: '10_applicable',
  TWENTY_EX: '20_applicable',
  NA: 'NA',
}

export const CURRENCY = {
  EUR: 'EUR',
}