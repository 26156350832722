import React from "react";

// Layout
import BaseLayout from "../base";

// Import component
import SideNavbar from '../../components/organisms/sideNavbar/';

// Style
import { PageContent, Container } from "./style";

const GenericLayout = ({ children, ...rest }) => {
  return (
    <BaseLayout {...rest}>
      <PageContent>
        <SideNavbar />
        <Container>
          {children}
        </Container>
      </PageContent>
    </BaseLayout>
  );
};

export default GenericLayout;
