import React from "react";

import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useHistory
} from "react-router-dom";

import NewsContentDrawer from "./components/NewsContentDrawer";
import GoodDealDrawer from "./components/GoodDealDrawer";

const Routes = () => {
  const history = useHistory();
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/newsContent/:newsId/:editType?`} render={() => (
        <NewsContentDrawer
          visible
          closeFunction={() => history.push(`${path}`)}
        />
      )}
      />
      <Route path={`${path}/goodDeal/:goodDealId/:editType?`} render={() => (
        <GoodDealDrawer
          visible
          closeFunction={() => history.push(`${path}`)}
        />
      )}
      />
      <Redirect to={`${path}`} />
    </Switch>
  );
}

export default Routes;
