import styled from "styled-components";
import Dialog from '@material-ui/core/Dialog';
import { Form } from 'formik';

import GenericDrawerValidationButtons from "../../../../../../../components/molecules/GenericDrawerValidationButtons";
import { COLORS } from "../../../../../../../constants/design";

export const CustomDialog = styled(Dialog)`
  && {
    .MuiDialog-container {
      .MuiDialog-paper {
        &.MuiPaper-rounded {
          width: 100%;
          /* height: 100%; */
        }
        &.MuiDialog-paperWidthSm {
          max-width: 500px;
        }
      }
    }
  }
`;

export const StyledTitle = styled.div`
  font-size: 1.1rem;
  font-weight: 600;
  display: flex;
`;

export const StyledForm = styled(Form)`
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: normal;
  box-sizing: border-box;
  /* align-items: center; */
  padding: 20px 0px;
  .textTitle {
    font-weight: 700;
    font-size: 0.9rem;
    color: #5A5A5A;
    margin: 14px 5px 14px 0px;
  }
  .textTooltip {
    color: ${COLORS.GREY_1};
  }
  .textFieldInput {
    input {
      font-size: 13px;
    }
  }
  .genericMaterialMenu {
    .textFieldTitle {
      margin-bottom: 10px;
    }
  }
  >:not(:last-child) {
    margin-bottom: 20px;
  };
`;

export const FieldWithErrorMessage = styled.div`
`;

export const StyledValidationButtons = styled(GenericDrawerValidationButtons)`
/* border: 1px solid red; */
  .customButton {
    width: 48%;
  }
`;

export const LoadingContainer = styled.div`
  height: 450px;
`;