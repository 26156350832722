import React from "react";

const CompetencesIcon = ({
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon competencesIcon"
      width={width}
      height={height}
      viewBox="0 0 27 31"
      fill="none"
    >
      <g fillRule="nonzero" fill="none">
        <path
          d="M20.169 18.61H5.831a.883.883 0 01-.883-.882v-3.53c0-.21.075-.413.211-.572A10.286 10.286 0 0113 10.002c3.021 0 5.88 1.32 7.84 3.624.137.16.212.362.212.572v3.53a.883.883 0 01-.883.883z"
          fill="#FF5E82"
        />
        <path
          d="M20.169 18.61a.883.883 0 00.883-.882v-3.53a.883.883 0 00-.211-.572A10.286 10.286 0 0013 10.002v8.609h7.169z"
          fill="#B7457D"
        />
        <path
          d="M13 30.125a.882.882 0 01-.31-.057l-9.414-3.53a.883.883 0 01-.573-.826V13.356a.883.883 0 011.193-.826L13 15.944l9.104-3.414a.882.882 0 011.193.826v12.356a.883.883 0 01-.573.826l-9.414 3.53a.882.882 0 01-.31.057z"
          fill="#A4E9FF"
        />
        <path
          d="M22.724 26.538a.883.883 0 00.573-.826V13.356a.883.883 0 00-1.193-.826L13 15.944v14.18c.105 0 .21-.018.31-.056l9.414-3.53z"
          fill="#91BBFF"
        />
        <path
          d="M13 11.767a5.89 5.89 0 01-5.884-5.883A5.89 5.89 0 0113 0a5.89 5.89 0 015.884 5.884A5.89 5.89 0 0113 11.767z"
          fill="#FFD6AA"
        />
        <path
          d="M18.884 5.884A5.89 5.89 0 0013 0v11.767a5.89 5.89 0 005.884-5.883zM23.15 22.358h-.883a1.03 1.03 0 01-1.03-1.03v-2.941c0-.568.462-1.03 1.03-1.03h.882c1.38 0 2.501 1.122 2.501 2.5 0 1.38-1.122 2.501-2.5 2.501z"
          fill="#FAC68F"
        />
        <path
          d="M3.733 22.358h-.882a2.503 2.503 0 01-2.501-2.5c0-1.38 1.122-2.501 2.5-2.501h.883c.568 0 1.03.462 1.03 1.03v2.941a1.03 1.03 0 01-1.03 1.03z"
          fill="#FFD6AA"
        />
      </g>
    </svg>
  );
};

export default CompetencesIcon;
