import React from 'react';
import { useHistory } from "react-router";
import { useRouteMatch } from "react-router-dom";
import Checkbox from '@material-ui/core/Checkbox';
import EditIcon from '@material-ui/icons/Edit';
import _ from 'lodash';

import Loader from '../../../../../../components/molecules/GenericLoader';

import {
  COMPANY_SETTINGS,
} from '../../../../../../constants/companySettingConstants';

import {
  MainContainer,
  StyledAccordion,
  StyledAccordionHeader,
  ContentTitle,
  TableIcon,
  SettingContainer,
  SettingTitle,
  EmailDomainContainer
} from './style';

const CompanySettings = ({
  companyData,
  companyIsLoading
}) => {
  const { path } = useRouteMatch();
  const history = useHistory();

  const openCompanyEditDrawer = (event) => {
    event.stopPropagation();
    history.push(`${path}/companyEdit`);
  }

  const renderCompanySettings = () => {
    return _.map(COMPANY_SETTINGS, (data, key) => {
      return (
        <SettingTitle key={key}>
          <Checkbox
            checked={companyData?.settings?.settings?.[key]?.active ? true : false}
            readOnly
          />
          {COMPANY_SETTINGS[key]?.label}
        </SettingTitle>
      )
    });
  };

  return (
    <MainContainer>
      <StyledAccordion
        headerComponent={
          <StyledAccordionHeader>
            <ContentTitle>
              {'Company Setting'}
            </ContentTitle>
            <TableIcon onClick={(event) => openCompanyEditDrawer(event)}>
              <EditIcon />
            </TableIcon>
          </StyledAccordionHeader>
        }
        detailComponent={
          <SettingContainer>
            {companyIsLoading ?
              <Loader />
              :
              <>
                <SettingTitle>
                  {'Settings'}
                </SettingTitle>
                {renderCompanySettings()}
                <SettingTitle>
                  {'Email Domain'}
                </SettingTitle>
                <EmailDomainContainer>
                  {companyData?.domains || 'No data'}
                </EmailDomainContainer>
              </>
            }
          </SettingContainer>
        }
      />
    </MainContainer>
  )
};

export default CompanySettings;