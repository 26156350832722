import { format, isSameDay } from 'date-fns';
import fr from 'date-fns/locale/fr';

export const displayDateRange = (start, end) => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const startTime = format(startDate, "H:mm", { locale: fr });
  const endTime = format(endDate, "H:mm", { locale: fr });
  const startDateString = format(startDate, "EEEE, dd MMM", { locale: fr });
  const endDateString = format(endDate, "EEEE, dd MMM", { locale: fr });

  const rangeDisplay = isSameDay(startDate, endDate) ?
    `${startDateString} ${startTime} - ${endTime}` :
    `${startDateString} ${startTime} - ${endDateString} ${endTime}`

  return rangeDisplay
};