import Dialog from '@material-ui/core/Dialog';
import styled from "styled-components";
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { Form } from 'formik';

import { COLORS } from '../../../../../constants/design'
import GenericDrawerValidationButtons from '../../../../../components/molecules/GenericDrawerValidationButtons';


export const CustomDialog = styled(Dialog)`
  && {
    .MuiDialog-container {
      .MuiDialog-paper {
        &.MuiPaper-rounded {
          width: 100%;
          max-height: 100%;
        }
        &.MuiDialog-paperWidthSm {
          max-width: 500px;
        }
      }
    }
  }
`;

export const DialogContainer = styled.div` 
    width: 100%;
    padding: 0;
    background-color: white;
    border-radius: 6px;
    color: ${COLORS.DARK_GREY};
`;

export const StyledForm = styled(Form)`
  /* border: 10px solid blue; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 30px 30px 30px;
  .textTitle {
    font-weight: 700;
    font-size: 0.9rem;
    color: #5A5A5A;
    margin: 14px 5px 14px 0px;
  }
  .textTooltip {
    color: ${COLORS.GREY_1};
  }
  .textFieldInput {
    input {
      font-size: 13px;
    }
  }
  >div:not(:last-child) {
    margin-bottom: 15px;
  };
`;

export const CustomTabContainer = styled(Tabs)`
  background-color: ${COLORS.SLIGHT_GREY_2};
  border-radius: 10px;
  width: 100%;
  /* border: 1px solid black; */
  /* flex: 1; */
`;

export const CustomTab = styled(Tab)`
  &&{
    width: 50%;
    /* flex: 1; */
    height: 5px;
    color: ${COLORS.DARK_GREY};;
    font-size: 0.8rem;
    text-transform: none;
    &.Mui-selected {
      background-color: ${COLORS.PRIMARY_BLUE};
      color: white;
    }
    &.MuiTab-root {
      max-width: 50%;
      min-width: 10%;
    }
  }
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const StyledValidationButtons = styled(GenericDrawerValidationButtons)`
/* border: 1px solid red; */
  .customButton {
    width: 45%;
  }
`;