import styled from "styled-components";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableCell from '@material-ui/core/TableCell';

export const Container = styled.div`

`;

export const TableSortLabelContent = styled.span`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 20px;
  width: 1px;
`;

export const TableSortLabelCustom = styled(TableSortLabel)`
  &&{
    font-size: 13px;
    font-weight: 700;
    color: #2A2A2A;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
`;

export const TableCellCustom = styled(TableCell)`
  &&{
    padding: 16px 20px;
    border-bottom: 1px solid #F1F1F1;
    &:last-child{
      width: 25%;
    }
  }
`;
