// Style
import {
	Container,
	BubbleText,
	Text,
	NavLinkCustom,
} from './style';

const Step0 = () => {
	return (
		<Container>
			<BubbleText>
				<Text>
					Bonjour, je m'appelle <em>Cyconia et je suis votre assistant intelligent</em> 😃
				</Text>
				<Text>
					Ma mission : faciliter votre vie au travail.
					Comment ?
					Je vous donne accès aux infos et aux services dont vous avez besoin.
					Pas d’inquiétude, je vous explique cela en détails.
				</Text>
			</BubbleText>

			<BubbleText>
				<Text>
					Sachez qu’aucune information ne sera diffusée.
					Ce que je vous demande, est uniquement utilisé pour mieux vous servir et faciliter le quotidien.
				</Text>
			</BubbleText>

			<BubbleText>
				<Text>
					C’est pourquoi je vais vous poser quelques questions
				</Text>
			</BubbleText>

			<NavLinkCustom to="/onboarding/CGU">
				Faisons connaissance
			</NavLinkCustom>
		</Container>
	);
};

export default Step0;
