import React from "react";

const WarningIcon = ({
  color = "#2B2B2B",
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon warningIcon"
      width={width}
      height={height}
      viewBox="0 0 50 46"
      fill="none"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="warningIcon"
          fill={color}
          fillRule="nonzero"
        >
          <path d="M48.9813786,34.1530864 L31.4710905,3.82386831 C28.6110082,-1.18641975 21.3784979,-1.18641975 18.5287037,3.82386831 L1.00812757,34.1530864 C-1.85195473,39.1633745 1.70771605,45.3876543 7.47932099,45.3876543 L42.4484568,45.3876543 C48.2200617,45.3876543 51.8414609,39.1016461 48.9813786,34.1530864 Z M24.9896091,38.6592593 C23.4669753,38.6592593 22.2015432,37.3938272 22.2015432,35.8711934 C22.2015432,34.3485597 23.4669753,33.0831276 24.9896091,33.0831276 C26.5122428,33.0831276 27.7776749,34.3485597 27.7159465,35.9432099 C27.787963,37.3938272 26.4505144,38.6592593 24.9896091,38.6592593 Z M27.5307613,20.6345679 C27.4073045,22.7950617 27.2735597,24.9452675 27.1501029,27.1057613 C27.0883745,27.8053498 27.0883745,28.4432099 27.0883745,29.1325103 C27.0266461,30.2744856 26.1315844,31.1592593 24.9896091,31.1592593 C23.8476337,31.1592593 22.9628601,30.336214 22.8908436,29.1942387 C22.7056584,25.8300412 22.5101852,22.527572 22.325,19.1633745 C22.2632716,18.2786008 22.2015432,17.3835391 22.1295267,16.4987654 C22.1295267,15.0378601 22.952572,13.8341564 24.2900206,13.4534979 C25.6274691,13.1345679 26.9546296,13.772428 27.5307613,15.0378601 C27.7262346,15.4802469 27.787963,15.9226337 27.787963,16.437037 C27.7262346,17.8465021 27.5924897,19.245679 27.5307613,20.6345679 Z"></path>
        </g>
      </g>
    </svg>
  );
};

export default WarningIcon;
