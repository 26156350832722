import styled from "styled-components";

import { COLORS } from "../../../../../../../constants/design";
import GenericAccordion from "../../../../../../../components/molecules/GenericAccordion";

export const MainContainer = styled.div`
`;

export const StyledAccordion = styled(GenericAccordion)`
  && {
    .MuiAccordion-root {
      border: 1px solid ${COLORS.LIGHT_GREY_2};
    }
  }
`;

export const StyledTitle = styled.div`
  font-weight: 600;
  font-size: 1rem;
  align-items: center;
`;

export const DetailContainer = styled.div`
  font-size: 0.9rem;
  .textTitle {
    font-weight: 700;
    font-size: 0.9rem;
    color: ${COLORS.DARK_GREY};
  }
  >div:not(:last-child) {
    margin-bottom: 10px;
  };
`;
