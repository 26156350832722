import styled from "styled-components";
import Tooltip from '@material-ui/core/Tooltip';
import { COLORS } from '../../../../constants/design';

export const CommentBoxDiv = styled.div`
  pointer-events: ${props => props.disabled === true ? 'none' : 'all'};
  position: relative;
  width: 100%;
  .textFieldTitle {
    margin-bottom: 10px;
  }
  .quill {
    /* margin-top: 10px; */
    /* width: 640px;
    max-width: 640px; */
    width: 660px;
    height: 100%;
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;
    .ql-toolbar {
      background-color: #F4F4F4;
      border: ${props => props.error ? "1px solid red" : "1px solid #D2D2D2"};
      border-radius: 10px 10px 0 0;
      height: 43px;
      min-height: 43px;
      padding: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .ql-formats {
        display: inline-flex;
        align-items: center;
        height: 100%;
        height: 36px;
        border: 0;
        margin-left: 0;
        margin-right: 12px;
        &:first-child {
          .ql-header.ql-picker {
            border-radius: 7px 6px 6px 6px;
          }
        }
        .ql-header.ql-picker {
          border-radius: 4px;
          margin-left: 3px;
          height: 36px;
          display: inline-flex;
          align-items: center;
          border: 1px solid transparent;
          transition: all .2S ease-out;
          &:hover,
          &:focus{
            background-color: #F9F9F9;
            border: 1px solid #EDEDED;
          }
          .ql-picker-label {
            display: inline-flex;
            align-items: center;
          }
        }
        button {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          height: 36px;
          padding: 8px;
          width: 36px;
          padding: 5px;
          border: 1px solid transparent;
          transition: all .2S ease-out;
          &:hover,
          &:focus{
            background-color: #F9F9F9;
            border: 1px solid #EDEDED;
          }
          svg{
            width: 18px;
          }
        }
      }
    }
    .ql-container {
      height: ${props => props.isModalMode ? "100%" : "160px"};
      background-color: #FFFFFF;
      border: 0;
      border-left: ${props => props.error ? "1px solid red" : "1px solid #D2D2D2"};
      border-right: ${props => props.error ? "1px solid red" : "1px solid #D2D2D2"};
      border-bottom: ${props => props.error ? "1px solid red" : "1px solid #D2D2D2"};
      border-radius: 0 0 10px 10px;
      .ql-editor {
        min-height: 70px;
      }
    }
  }
`;

export const InputContainer = styled.div`
  position: relative;
  height: ${props => props.isModalMode ? "80vh" : "200px"};
  /* border: 1px solid black; */
`;

export const StyledCustomToolbar = styled.div`
  position: absolute;
  /* border: 1px solid red; */
  display: flex;
  /* align-content: center; */
  align-items: center;
  justify-content: space-evenly;
  width: 7%;
  height: 44px;
  top: 0;
  right: 0;
  .genericMaterialMenu {
    margin-right: 5px;
    .MuiButton-root {
      border: 1px solid ${COLORS.LIGHT_GREY_2};
      font-size: 0.8rem;
      padding: 5px 15px;
      height: 38px;
      border-radius: 5px;
      text-transform: none;
      box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%);
    }
  }
`;

export const StyledTooltip = styled(Tooltip)`
  color: ${COLORS.DARK_GREY};
  /* position: absolute; */
  top: 10px;
  right: 10px;
  cursor: pointer;
  :hover {
    color: ${COLORS.PRIMARY_BLUE};
  }
`;