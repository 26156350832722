import React, { useMemo, useEffect } from 'react';
import {
  shallowEqual,
  useDispatch,
  useSelector,
} from "react-redux";
import moment from 'moment';

import { BLOCK_KEYS } from '../../../../constants/sideBarConstants';

// Layout
import SidebarFrontBaseLayout from "../../layouts/sidebarFrontBaseLayout";

// Components
import MetroIcon from '../../components/atoms/icons/metro';
import BikeIcon from '../../components/atoms/icons/bike';
import CarIcon from '../../components/atoms/icons/car';
import WalkIcon from '../../components/atoms/icons/walk';

import RoomBookingModule from './RoomBookingModule';
import ParoleCyconia from '../../components/organisms/ParoleCyconia';
import UserInfoAndWeatherModule from '../../components/organisms/UserInfoAndWeatherModule';
import DisconnectModule from '../../components/organisms/DisconnectModule';

// Store
import {
  getTraffic,
} from '../../../../store/sidebar/actions';

import {
  getTrafficData,
} from '../../../../store/sidebar/selectors';
import {
  getUser,
} from '../../../../store/collaborator/selectors';

import {
  getCompanyData,
  getDashboardContext
} from '../../../../store/company/selectors';

// Styles
import {
  Container,
  StyledTitleWithToolTip,
  TransportWrapper,
  TransportList,
  TransportItem,
  TransportIconWrapper,
  TransportItemInfo,
  TransportItemTime,
  TransportItemButton,
} from "./style";

moment.locale('fr');

const Dashboard = () => {
  const dispatch = useDispatch();

  const {
    userInfos,
    traffic,
    companyData,
    dashboardContext,
  } = useSelector(
    state => ({
      userInfos: getUser(state),
      traffic: getTrafficData(state),
      companyData: getCompanyData(state),
      dashboardContext: getDashboardContext(state)
    }),
    shallowEqual
  );

  const hasTransports = useMemo(() => {
    if (userInfos) {
      const hasSomeTransport = userInfos.transport_subway || userInfos.transport_bike
        || userInfos.transport_walk || userInfos.transport_car;
      return hasSomeTransport && !!userInfos.address?.length && !!userInfos.lat && !!userInfos.lng
        && !!userInfos.office_lat && !!userInfos.office_lng && !!userInfos.office_address?.length;
    }
    return false;
  }, [userInfos]);

  useEffect(() => {
    if (hasTransports) {
      dispatch(getTraffic({
        subway: userInfos.transport_subway,
        bike: userInfos.transport_bike,
        walk: userInfos.transport_walk,
        car: userInfos.transport_car,
      }));
    }
  }, [hasTransports, userInfos]);

  const transportsNames = {
    subway: 'En transport',
    bike: 'A vélo',
    walk: 'En marchant',
    car: 'En voiture',
  };

  const transportsIcons = {
    subway: <MetroIcon />,
    bike: <BikeIcon />,
    walk: <WalkIcon />,
    car: <CarIcon />,
  };


  if (userInfos) {
    return (
      <SidebarFrontBaseLayout
        id="sidebarLayoutDashboard"
        className="sidebarLayoutDashboard page-container sidebarPage"
      >
        <Container>
          {dashboardContext?.[BLOCK_KEYS.WEATHER] &&
            <UserInfoAndWeatherModule />
          }

          {/* meetingRoom flag will be here temporary until everything is good */}
          {dashboardContext?.[BLOCK_KEYS.SERVICE] && companyData?.meetingRoom &&
            <RoomBookingModule />
          }

          {dashboardContext?.[BLOCK_KEYS.TRANSPORT] &&
            <TransportWrapper>
              <StyledTitleWithToolTip
                title={"Mes trajets"}
                toolTipText={"Je vous donne la durée de trajet de votre entreprise à votre domicile en fonction des modalités de transport renseignées dans votre espace personnel 'Mon compte'."}
              />

              <TransportList>
                {!hasTransports &&
                  <TransportItem>
                    <TransportItemInfo transportEmpty>
                      Cyconia calcule en temps réel vos temps de trajets de votre domicile à votre lieu de travail !
                    </TransportItemInfo>
                    <TransportItemButton transportEmpty to="/compte/trajets">
                      Régler mes trajets
                    </TransportItemButton>
                  </TransportItem>
                }
                {(hasTransports && traffic) && Object.entries(traffic).map((item) => {
                  const [type, value] = item;
                  const {
                    duration,
                  } = value;

                  let checkWordDuration = duration?.text;
                  const mapWordDurationObj = {
                    heure: "h",
                    heures: "h",
                    minute: "min",
                    minutes: "min"
                  };
                  if (!!checkWordDuration) {
                    checkWordDuration = checkWordDuration.replace(/\b(?:heure|heures|minute|minutes)\b/gi, matched => mapWordDurationObj[matched]);
                  } else {
                    checkWordDuration = "Aucun résultat disponible";
                  }

                  return (
                    <TransportItem key={type}>
                      <TransportItemInfo>
                        <TransportIconWrapper className={type}>
                          {transportsIcons[type]}
                        </TransportIconWrapper>
                        {transportsNames[type]}
                      </TransportItemInfo>
                      <TransportItemTime>
                        {checkWordDuration}
                      </TransportItemTime>
                    </TransportItem>
                  )
                })}
              </TransportList>
            </TransportWrapper>
          }

          {dashboardContext?.[BLOCK_KEYS.NEWS] &&
            <ParoleCyconia />
          }

          {dashboardContext?.[BLOCK_KEYS.ACCOUNT_DISCONNECT] &&
            <DisconnectModule />
          }
        </Container>
      </SidebarFrontBaseLayout>
    );
  }
  return null;
}

export default Dashboard;