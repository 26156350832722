import { call, put, select } from 'redux-saga/effects';
import endpoint from '../../../helpers/api';
import apiCall from '../../apiCall';
import * as authenticationSelectors from '../../authentication/selectors';
import { toaster } from '../../../helpers/toaster';

import contextTemplate from '../../../../dummyData/contextTemplate.json';

import {
  getContextsSuccess,
  getContextsError,
  saveContextsSuccess,
  saveContextsError
} from '../actions';

import { saveContextsService } from '../../../services/contextSettingService';

export function* getContextsHandler() {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const response = yield call(apiCall, endpoint.getContexts, userToken);
    // There is flag coming from the response that is current unused
    yield put(getContextsSuccess(response?.context));
  } catch (err) {
    yield put(getContextsError(err));
  }
}

export function* saveContextsHandler(action) {
  const { formData } = action.data;
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const response = yield call(saveContextsService, endpoint.saveContexts, userToken, formData);
    yield put(saveContextsSuccess(JSON.parse(response?.data?.context)));
    toaster('Context setting modified', 'success');
  } catch (err) {
    yield put(saveContextsError(err));
    toaster(`Une erreur s'est produite. Veuillez réessayer`, 'error');
  }
}