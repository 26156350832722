import React, { useState, useEffect } from 'react';
import { format, differenceInMinutes } from 'date-fns';
import fr from 'date-fns/locale/fr';
import DownloadIcon from '@material-ui/icons/GetApp';
import _ from 'lodash';

import { BOOKING_CANCEL_STATUS } from '../../../../../../constants/bookingConstants';
import { calculatePrice } from '../../../../../../helpers/roomBookingPriceHelper';

import {
  StyledCSVLink,
  StyledButton
} from './style';

const bookingHeaders = [
  { label: 'Reservation id', key: 'id' },
  { label: 'Site', key: 'site' },
  { label: 'Corporation_name', key: 'corporation_name' },
  { label: 'Owner', key: 'owner' },
  { label: 'Room_name', key: 'room_name' },
  { label: 'Start_date', key: 'start_date' },
  { label: 'End_date', key: 'end_date' },
  { label: 'Duration', key: 'duration' },
  { label: 'Quantity', key: 'quantity' },
  { label: 'Room_price_value', key: 'value' },
  { label: 'Room_price_unit', key: 'unit' },
  { label: 'Room_pice_VAT', key: 'vat' },
  { label: 'Room_price_currency', key: 'currency' },
  { label: 'Options', key: 'options' },
  { label: 'Cancel_by', key: 'cancel_by' },
  { label: 'Cancellation_status', key: 'cancel_status' },
  { label: 'Cancellation_price_value', key: 'cancel_value' },
  { label: 'Cancellation_price_unit', key: 'cancel_unit' },
  { label: 'Cancellation_price_VAT', key: 'cancel_vat' },
  { label: 'Cancellation_price_currency', key: 'cancel_currency' },
  { label: 'Total_value', key: 'total' },
];

const optionsHeaders = [
  { label: 'Reservation id', key: 'id' },
  { label: 'Options', key: 'option_title' },
  { label: 'Site', key: 'site' },
  { label: 'Corporation_name', key: 'corporation_name' },
  { label: 'Room_name', key: 'room_name' },
  { label: 'Start_date', key: 'start_date' },
  { label: 'End_date', key: 'end_date' },
  { label: 'Option_price_value', key: 'value' },
  { label: 'Option_price_unit', key: 'unit' },
  { label: 'Option_price_VAT', key: 'vat' },
  { label: 'Option_price_currency', key: 'currency' },
  { label: 'Cancel_by', key: 'cancel_by' },
  { label: 'Cancellation_status', key: 'cancel_status' },
  { label: 'Cancellation_price_value', key: 'cancel_value' },
  { label: 'Cancellation_price_unit', key: 'cancel_unit' },
  { label: 'Cancellation_price_VAT', key: 'cancel_vat' },
  { label: 'Cancellation_price_currency', key: 'cancel_currency' },
  { label: 'Total_value', key: 'total' },
];


const DownloadCsv = ({
  bookingData,
  bookingDataIsLoading,
  siteData,
  siteDataIsLoading,
  corporationData,
  corporationDataIsLoading,
  selectedCategory,
  filterConditions
}) => {
  const [headers, setHeaders] = useState([]);
  const [csvData, setCsvData] = useState([]);

  useEffect(() => {
    if (bookingData) {
      let headerList = [];
      let dataList = [];

      if (selectedCategory === 0) {
        headerList = bookingHeaders;
        _.map(bookingData, (booking) => {
          const selectedSite = _.find(siteData, (site) => site.id === booking.company_site_id);
          const selectedCorporation = _.find(corporationData, (corporation) => corporation.id === booking.corporation_id);

          dataList.push({
            id: booking.id,
            site: selectedSite?.name || '-',
            corporation_name: selectedCorporation?.name || '-',
            owner: `${booking.owner.firstname} ${booking.owner.lastname}`,
            room_name: booking.room.name,
            start_date: `${format(new Date(booking.start), 'dd/MM/yyyy HH:mm', { locale: fr })}`,
            end_date: `${format(new Date(booking.end), 'dd/MM/yyyy HH:mm', { locale: fr })}`,
            duration: `${differenceInMinutes(new Date(booking.end), new Date(booking.start))} min`,
            quantity: booking.quantity,
            value: booking.price.value,
            unit: booking.price.unit,
            vat: booking.price.vat,
            currency: booking.price.currency,
            options: generateOptions(booking),
            cancel_by: booking.cancel_status ? `${booking.cancel_by.firstname} ${booking.cancel_by.lastname}` : '-',
            cancel_status: booking.cancel_status || '-',
            ...generateCancelPrice(booking.cancel_status, booking.cancel_fees),
            total: getTotal(booking),
          });
        });
      }
      else {
        headerList = optionsHeaders;
        _.map(bookingData, (booking) => {
          const selectedSite = _.find(siteData, (site) => site.id === booking.company_site_id);
          const selectedCorporation = _.find(corporationData, (corporation) => corporation.id === booking.corporation_id);

          _.map(booking.options, (option) => {
            dataList.push({
              id: booking.id,
              option_title: option.title,
              site: selectedSite?.name || '-',
              corporation_name: selectedCorporation?.name || '-',
              room_name: booking.room.name,
              start_date: `${format(new Date(booking.start), 'dd/MM/yyyy HH:mm', { locale: fr })}`,
              end_date: `${format(new Date(booking.end), 'dd/MM/yyyy HH:mm', { locale: fr })}`,
              value: option.price.value,
              unit: option.price.unit,
              vat: option.price.vat,
              currency: option.price.currency,
              cancel_by: booking.cancel_status ? `${booking.cancel_by.firstname} ${booking.cancel_by.lastname}` : '-',
              cancel_status: booking.cancel_status || '-',
              ...generateCancelPrice(booking.cancel_status, option.cancel_fees),
              total: getOptionTotal(booking, option),
            })
          });
        });
      }
      // console.log('headerList', headerList);
      // console.log('dataList', dataList);
      setHeaders(headerList);
      setCsvData(dataList);
    }
  }, [bookingData, selectedCategory]);

  const generateOptions = (booking) => {
    let optionsText = '-';

    if (booking.options && booking.options.length !== 0) {
      const optionTitles = _.map(booking.options, (option) => option.title);
      optionsText = optionTitles.join('; ');
    }

    return optionsText;
  };

  const generateCancelPrice = (status, fees) => {
    return ({
      cancel_value: status === BOOKING_CANCEL_STATUS.PAYABLE ? fees?.value : '-',
      cancel_unit: status === BOOKING_CANCEL_STATUS.PAYABLE ? fees?.unit : '-',
      cancel_vat: status === BOOKING_CANCEL_STATUS.PAYABLE ? fees?.vat : '-',
      cancel_currency: status === BOOKING_CANCEL_STATUS.PAYABLE ? fees?.currency : '-',
    })
  };

  const getTotal = (booking) => {
    if (booking.cancel_status !== BOOKING_CANCEL_STATUS.NON_PAYABLE) {
      const bookingPrice = booking.cancel_status === BOOKING_CANCEL_STATUS.PAYABLE ? booking.cancel_fees : booking.price;
      let total = calculatePrice(bookingPrice);

      if (booking.options) {
        for (const option of booking.options) {
          const optionPrice = booking.cancel_status === BOOKING_CANCEL_STATUS.PAYABLE ? option.cancel_fees : option.price;
          total = total + calculatePrice(optionPrice);
        }
      }
      return total;
    }
    else {
      return 0;
    }
  }

  const getOptionTotal = (booking, option) => {
    if (booking.cancel_status !== BOOKING_CANCEL_STATUS.NON_PAYABLE) {
      return calculatePrice(booking.cancel_status === BOOKING_CANCEL_STATUS.PAYABLE ? option.cancel_fees : option.price);
    }
    else {
      return 0;
    }
  }

  const generateFileName = () => {
    const reportName = selectedCategory === 0 ? `reservations` : `options`;
    const reportStart = `${format(new Date(filterConditions.startDate), 'ddMMyyyy', { locale: fr })}`;
    const reportEnd = `${format(new Date(filterConditions.endDate), 'ddMMyyyy', { locale: fr })}`;

    return (`${reportName}_${reportStart}-${reportEnd}.csv`);
  };

  return (
    <>
      {bookingDataIsLoading || siteDataIsLoading || corporationDataIsLoading || csvData.length === 0 ?
        <StyledButton>
          <DownloadIcon />
        </StyledButton>
        :
        <StyledCSVLink
          data={csvData}
          headers={headers}
          filename={generateFileName()}
        >
          <DownloadIcon />
        </StyledCSVLink>
      }
    </>
  );
};

export default DownloadCsv;