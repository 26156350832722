import * as a from "./actions";

const initGetValue = {
  loading: false,
  success: false,
  error: null,
}

const modifyGet = (type, error = null) => {
  return {
    loading: type === 'request' ? true : false,
    success: type === 'success' ? true : false,
    error: type === 'error' ? error : null,
  }
}

const INITIAL_STATE = {
  getGroundToken: {
    data: null,
    get: initGetValue,
  },
};

export function getGround(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case a.VALIDATE_GETGROUND_TOKEN.REQUEST:
      return {
        ...state,
        getGroundToken: {
          data: null,
          get: modifyGet('request')
        },
      };
    case a.VALIDATE_GETGROUND_TOKEN.SUCCESS:
      return {
        ...state,
        getGroundToken: {
          data: action.data,
          get: modifyGet('success')
        },
      };
    case a.VALIDATE_GETGROUND_TOKEN.FAILURE:
      return {
        ...state,
        getGroundToken: {
          data: null,
          get: modifyGet('error', action.error)
        },
      };
    default:
      return state;
  }
}

export default getGround;
