import {
  all,
  put,
  takeEvery,
  call,
  select,
  delay,
} from "redux-saga/effects";
import endpoint from '../../helpers/api';
import apiCall from '../apiCall';
import { authHeader } from '../../helpers/auth-header';
import _ from 'lodash';

/** Actions */
import * as a from './actions';
import * as authenticationSelectors from '../authentication/selectors';
import * as collaboratorActions from '../collaborator/actions';
import * as collaboratorSelectors from '../collaborator/selectors';
import { getCompanyData } from '../company/selectors';
import { getParoleCyconiaData, getServicesOrdersListData } from '../sidebar/selectors';
import { getServiceMessagesHandler, saveServiceMessageHandler } from './handler/serviceMessageHandler';
import { getServiceTicketHandler } from './handler/serviceTicketHandler';

async function postAPICall(endpoint, data) {
  return fetch(endpoint, {
    method: 'POST',
    headers: authHeader(),
    body: data
  })
    .then(res => res.json())
    .catch(err => err);
}

// DASHBOARD - WEATHER
function* getWeatherSaga() {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const weatherData = yield call(apiCall, endpoint.getWeather, userToken);
    if (typeof weatherData === 'object') {
      const {
        name,
        main,
        weather,
      } = weatherData;
      const _weather = {
        city: name,
        temparature: main.temp,
        condition: weather[0].id,
      };
      yield put(a.getWeatherSuccess(_weather));
    }
  } catch (error) {
    yield put(a.getWeatherError(error));
  }
}

// DASHBOARD - TRAFFIC
function* getTrafficSaga({
  subway,
  bike,
  walk,
  car,
}) {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const allTypes = {
      subway,
      bike,
      walk,
      car,
    };
    const refs = {
      subway: 'transit',
      bike: 'bicycling',
      walk: 'walking',
      car: 'driving',
    };
    const typeCalls = Object.entries(allTypes).reduce((all, x) => {
      const [key, value] = x;
      if (value === true) {
        all.push(refs[key]);
      }
      return all;
    }, []);

    const dataTraffic = yield all(typeCalls.map(type => {
      return call(apiCall, endpoint.getTraffic(type), userToken);
    }));
    const data = dataTraffic.reduce((all, x, index) => {
      const [itemData] = x;
      const hasNoResult = (itemData?.status === 'ZERO_RESULTS');
      // if (hasNoResult) {
        const [found] = Object.entries(refs).find(x => {
          const [itemKey, value] = x;
          return typeCalls[index] === value;
        });
        all[found] = {
          distance: hasNoResult ? null : itemData.distance,
          duration: hasNoResult ? null : itemData.duration,
        };
      // }
      return all;
    }, {});
    yield put(a.getTrafficSuccess(data));
    // yield put(collaboratorActions.updateUserLocalInfosSuccess(data));
  } catch (error) {
    yield put(a.getTrafficError(error));
  }
}

// DASHBOARD - PAROLE CYCONIA
function* getParoleCyconiaSaga() {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const getParoleCyconiaData = yield call(apiCall, endpoint.getFeedNewsDeals, userToken);
    yield put(a.getParoleCyconiaSuccess(getParoleCyconiaData));
  } catch (error) {
    yield put(a.getParoleCyconiaError(error));
  }
}

// MES COMPETENCES - SKILLS LIST
function* getExpertiseAreasSaga() {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const getExpertiseAreasData = yield call(apiCall, endpoint.getExpertiseAreas, userToken);
    yield put(a.getExpertiseAreasSuccess(getExpertiseAreasData));
  } catch (error) {
    yield put(a.getExpertiseAreasError(error));
  }
}

// MES COMPETENCES - SKILLS THEMATIQUE LIST
function* getExpertiseAreasThemeSaga({ id }) {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const getExpertiseAreasThemeData = yield call(apiCall, endpoint.getExpertiseAreasTheme(id), userToken);
    yield put(a.getExpertiseAreasThemeSuccess(getExpertiseAreasThemeData));
  } catch (error) {
    yield put(a.getExpertiseAreasThemeError(error));
  }
}

// CONCIERGERIE - SERVICES LIST
function* getServiceCategoryListSaga() {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const getServiceCategoryListData = yield call(apiCall, endpoint.getServiceCategoryList, userToken);
    yield put(a.getServiceCategoryListSuccess(getServiceCategoryListData));
  } catch (error) {
    yield put(a.getServiceCategoryListError(error));
  }
}

// CONCIERGERIE - SERVICES ORDERS LIST
function* getServicesOrdersListSaga({ pageNumber }) {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const user = yield select(collaboratorSelectors.getUser);
    const companyData = yield select(getCompanyData);
    const apiEndpoint = companyData.serviceVersion === 1 ? endpoint.getServicesOrdersList(user.id, pageNumber) : endpoint.getServiceTicketList(user.id, pageNumber);
    const getServicesOrdersListData = yield call(apiCall, apiEndpoint, userToken);
    yield put(a.getServicesOrdersListSuccess(getServicesOrdersListData));
  } catch (error) {
    yield put(a.getServicesOrdersListError(error));
  }
}

// CONCIERGERIE - SERVICES CATEGORY BY ID
function* getServicesCategoryByIdSaga({ id }) {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const getServicesCategoryByIdData = yield call(apiCall, endpoint.getServicesCategoryById(id), userToken);
    const data = {
      title: Object.keys(getServicesCategoryByIdData)[0],
      ...Object.values(getServicesCategoryByIdData)[0],
    };
    yield put(a.getServicesCategoryByIdSuccess(data));
  } catch (error) {
    yield put(a.getServicesCategoryByIdError(error));
  }
}

// Login expressing
function* loginExpressingSaga({ phone, code }) {
  try {
    const formData = new FormData();
    formData.append('phone', phone);
    formData.append('code', code);
    const loginData = yield call(postAPICall, endpoint.loginExpressing, formData);
    if (loginData?.error || loginData.status !== 200) {
      yield put(a.loginExpressingError({ code: 'error' }));
      yield delay(100);
      yield put(a.resetLoginExpressing());
    } else if (loginData.status === 200) {
      const user_expressing_id = loginData.data?.ID;
      const currentUser = yield select(collaboratorSelectors.getUser);
      const newUser = {
        ...currentUser,
        user_expressing_id,
      };
      yield put(collaboratorActions.updateUserLocalInfos(newUser));
      yield put(a.loginExpressingSuccess());
      yield delay(100);
      yield put(a.resetLoginExpressing());
    }
  } catch (error) {
    yield put(a.loginExpressingError(error));
    yield delay(100);
    yield put(a.resetLoginExpressing());
  }
}

// Logout expressing
function* logoutExpressingSaga() {
  try {
    const formData = new FormData();
    const logoutData = yield call(postAPICall, endpoint.logoutExpressing, formData);
    if (logoutData?.error) {
      yield put(a.loginExpressingError(logoutData.error));
    } else if (logoutData.status === 200) {
      const user_expressing_id = null;
      const currentUser = yield select(collaboratorSelectors.getUser);
      const newUser = {
        ...currentUser,
        user_expressing_id,
      };
      yield put(collaboratorActions.updateUserLocalInfos(newUser));
      yield put(a.logoutExpressingSuccess());
    }
  } catch (error) {
    yield put(a.logoutExpressingError(error));
  }
}

// Logout expressing
function* getExpressingDataSaga() {
  try {
    yield put(a.getExpressingAmount());
    yield put(a.getExpressingOnGoingOrders());
    yield put(a.getExpressingPastOrders());
    yield put(a.getExpressingDataSuccess());
  } catch (error) {
    console.log('Error');
  }
}
function* getExpressingAmountSaga() {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const user = yield select(collaboratorSelectors.getUser);
    if (user?.user_expressing_id) {
      const amount = yield call(apiCall, endpoint.getExpressingAmount(user.user_expressing_id), userToken);
      if (amount.status === 200 && amount.message === 'Ok') {
        const { status, message, ...data } = amount;
        yield put(a.getExpressingAmountSuccess(data.data));
      } else {
        yield put(a.getExpressingAmountError(amount));
      }
    } else {
      yield put(a.getExpressingAmountError('error getting amount'));
    }
  } catch (error) {
    yield put(a.getExpressingAmountError(error));
  }
}

function* getExpressingPastOrdersSaga() {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const user = yield select(collaboratorSelectors.getUser);
    if (user?.user_expressing_id) {
      const pastOrders = yield call(apiCall, endpoint.getExpressingPastOrders(user.user_expressing_id), userToken);
      if (pastOrders.status === 200 && pastOrders.message === 'Ok') {
        const { status, message, ...data } = pastOrders;
        yield put(a.getExpressingPastOrdersSuccess(data.data));
      } else {
        yield put(a.getExpressingPastOrdersError(pastOrders));
      }
    } else {
      yield put(a.getExpressingPastOrdersError('error getting pastOrders'));
    }
  } catch (error) {
    yield put(a.getExpressingPastOrdersError(error));
  }
}

function* getExpressingOnGoingOrdersSaga() {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const user = yield select(collaboratorSelectors.getUser);
    if (user?.user_expressing_id) {
      const onGoingOrders = yield call(apiCall, endpoint.getExpressingOnGoingOrders(user.user_expressing_id), userToken);
      if (onGoingOrders.status === 200 && onGoingOrders.message === 'Ok') {
        const { status, message, ...data } = onGoingOrders;
        yield put(a.getExpressingOnGoingOrdersSuccess(data.data));
      } else {
        yield put(a.getExpressingOnGoingOrdersError(onGoingOrders));
      }
    } else {
      yield put(a.getExpressingOnGoingOrdersError('error getting onGoingOrders'));
    }
  } catch (error) {
    yield put(a.getExpressingOnGoingOrdersError(error));
  }
}

function* setSelectedParoleSaga({ newsId }) {
  const paroleCyconiaData = yield select(getParoleCyconiaData);
  const selectedParole = newsId
    ? paroleCyconiaData?.items.find(parole => parole.id === newsId)
    : null;
  yield put(a.setSelectedParoleSuccess(selectedParole));
}

function* setSelectedPrestation({ prestation }) { 
  yield put(a.setSelectedExpressinPrestationSuccess( prestation));
}

export default function* sidebarRootSaga() {
  yield all([
    takeEvery(a.GET_WEATHER.REQUEST, getWeatherSaga),
    takeEvery(a.GET_TRAFFIC.REQUEST, getTrafficSaga),
    takeEvery(a.GET_PAROLE_CYCONIA.REQUEST, getParoleCyconiaSaga),
    takeEvery(a.GET_EXPERTISE_AREAS.REQUEST, getExpertiseAreasSaga),
    takeEvery(a.GET_EXPERTISE_AREAS_THEME.REQUEST, getExpertiseAreasThemeSaga),
    takeEvery(a.GET_SERVICES_LIST.REQUEST, getServiceCategoryListSaga),
    takeEvery(a.GET_SERVICES_ORDERS_LIST.REQUEST, getServicesOrdersListSaga),
    takeEvery(a.GET_SERVICES_CATEGORY_BY_ID.REQUEST, getServicesCategoryByIdSaga),
    takeEvery(a.LOGIN_EXPRESSING.REQUEST, loginExpressingSaga),
    takeEvery(a.LOGOUT_EXPRESSING.REQUEST, logoutExpressingSaga),
    takeEvery(a.EXPRESSING_GET_AMOUNT.REQUEST, getExpressingAmountSaga),
    takeEvery(a.EXPRESSING_GET_PAST_ORDERS.REQUEST, getExpressingPastOrdersSaga),
    takeEvery(a.EXPRESSING_GET_ONGOING_ORDERS.REQUEST, getExpressingOnGoingOrdersSaga),
    takeEvery(a.GET_EXPRESSING_DATA.REQUEST, getExpressingDataSaga),
    takeEvery(a.SET_SELECTED_PAROLE.REQUEST, setSelectedParoleSaga),
    takeEvery(a.SET_EXPRESSING_PUBLICATION.REQUEST, setSelectedPrestation),
    takeEvery(a.GET_SERVICE_TICKET.REQUEST, getServiceTicketHandler),
    takeEvery(a.GET_SERVICE_MESSAGES.REQUEST, getServiceMessagesHandler),
    takeEvery(a.SAVE_SERVICE_MESSAGE.REQUEST, saveServiceMessageHandler),
  ]);
}
