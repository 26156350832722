import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import GenericTitleWithToolTip from '../GenericTitleWithToolTip';
import GenericErrorMessage from '../GenericErrorMessage';

import {
  MainContainer,
  StyledTextArea,
} from './style'

const GenericTextArea = ({
  title = "",
  toolTipText = null,
  isRequired,
  className, // to pass styled component className to mainContainer
  value,     // formik props
  onChange,  // formik props
  ...rest // the rest of the props will be given to TextField component
}) => {
  const [currentValue, setCurrentValue] = useState();

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);
  
  const handleOnChange = (event) => {
    setCurrentValue(event.target.value);
  }

  const handelOnBlur = (event) => {
    onChange(event);
  }

  return (
    <MainContainer className={`${className ? className : ''} genericTextArea`}>
      <GenericTitleWithToolTip
        title={title}
        toolTipText={toolTipText}
        isRequired={isRequired}
        className={'textAreaTitle'}
      />
      <StyledTextArea
        {...rest}
        className={'textAreaInput'}
        value={currentValue}
        onChange={handleOnChange}
        onBlur={handelOnBlur}
      />
      {rest.id && <GenericErrorMessage name={rest.id} />}
    </MainContainer>
  )
}

GenericTextArea.propTypes = {
  title: PropTypes.string,
  toolTipText: PropTypes.string,
  className: PropTypes.string,
  isRequired: PropTypes.bool
}

export default GenericTextArea
