import styled from "styled-components";
import DonutChart from '../../../../components/molecules/donutChart';

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  min-height: 140px;
  height: 100%;
  max-height: 22%;
  padding: 20px 20px 18px 20px;
  overflow: hidden;
  border-radius: 6px;
  background-image: linear-gradient(-45deg, #2964A8 0%, #194E8B 100%);
`;

export const Title = styled.h3`
  margin: 0 0 auto 0;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const DonutChartType = styled(DonutChart)`
  &&{
    .donutchart-text-val{

    }
    .donutchart-text-percent{
      font-size: 12px;
    }
    .donutchart-text-label{
      font-size: 12px;
    }
    .donutchart-text-label-outside{

    }
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  & > div{
    margin: 0 30px 0 0;
    &:first-child{
      margin: 0 40px 0 0;
    }
  }
`;
