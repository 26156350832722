import React from 'react';
import _ from 'lodash';

import CancelIcon from '@material-ui/icons/Cancel';
import CoinIcon from '../../../../components/atoms/icons/coin';
import PeopleIcon from '@material-ui/icons/People';
import ListIcon from '@material-ui/icons/List';
import DescriptionIcon from '@material-ui/icons/Description';

import { priceDisplay } from '../../../../helpers/roomBookingPriceHelper';

import {
  CustomDialog,
  DialogContainer,
  StyledCloseContainer,
  StyledTitle,
  StyledImg,
  StyledInfoContainer,
  StyledTitleWithIcon,
  StyledTextContainer,
  StyledOptionsDisplay
} from './style';

const RoomInfoModal = ({
  selectedRoom,
  onClose
}) => {

  const generateOptions = () => {
    if (selectedRoom.options && selectedRoom.options.lenght !== 0) {
      return _.map(selectedRoom.options, (option, index) => {
        return (
          <StyledOptionsDisplay key={index}>
            {`- ${option.title}`}
          </StyledOptionsDisplay>
        )
      });
    }
    else {
      return (
        <StyledOptionsDisplay>
          {`Pas d'option disponible`}
        </StyledOptionsDisplay>
      )
    }
  };

  return (
    <CustomDialog
      open={true}
      onClose={onClose}
    >
      <DialogContainer>
        <StyledCloseContainer onClick={onClose}>
          <CancelIcon style={{ fontSize: 30 }} />
        </StyledCloseContainer>
        <StyledTitle>{selectedRoom.name}</StyledTitle>
        <StyledImg src={selectedRoom.image} alt={selectedRoom.name} />
        <StyledInfoContainer>
          <StyledTitleWithIcon
            title={`Prix`}
            Icon={<CoinIcon style={{ fontSize: 18 }} />}
          />
          <StyledTextContainer>
            {`${priceDisplay(selectedRoom.price)}`}
          </StyledTextContainer>
        </StyledInfoContainer>
        <StyledInfoContainer>
          <StyledTitleWithIcon
            title={`Capacité`}
            Icon={<PeopleIcon style={{ fontSize: 18 }} />}
          />
          <StyledTextContainer>
            {selectedRoom.max_number}
          </StyledTextContainer>
        </StyledInfoContainer>
        <StyledInfoContainer>
          <StyledTitleWithIcon
            title={`Options `}
            Icon={<ListIcon style={{ fontSize: 20 }} />}
          />
          <StyledTextContainer>
            {generateOptions()}
          </StyledTextContainer>
        </StyledInfoContainer>
        <StyledInfoContainer>
          <StyledTitleWithIcon
            title={`Description `}
            Icon={<DescriptionIcon style={{ fontSize: 20 }} />}
          />
          <StyledTextContainer>
            {selectedRoom.description}
          </StyledTextContainer>
        </StyledInfoContainer>

      </DialogContainer>
    </CustomDialog>
  )
};

export default RoomInfoModal;