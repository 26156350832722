import React from 'react'
import PropTypes from 'prop-types';
import GenericTitleWithToolTip from '../GenericTitleWithToolTip';

import {
  MainContainer,
  StyledSwitch,
} from './style'

const GenericSwitch = ({
  title = "",
  toolTipText = null,
  className, // to pass styled component className to mainContainer
  ...rest // the rest of the props will be given to TextField component
}) => {
  return (
    <MainContainer className={`${className ? className : ''} genericSwitch`}>
      <GenericTitleWithToolTip
        title={title}
        toolTipText={toolTipText}
        className={'textAreaTitle'}
      />
      <StyledSwitch
        {...rest}
        className={'switchButton'}
      />
    </MainContainer>
  )
}

GenericSwitch.propTypes = {
  title: PropTypes.string,
  toolTipText: PropTypes.string,
  className: PropTypes.string,
}

export default GenericSwitch;
