import React from 'react';
import { useHistory } from "react-router";
import { useRouteMatch } from "react-router-dom";

import CalendarIcon from '../../../../../components/atoms/icons/calendar';

import Routes from './routes';

import {
  MainContainer,
  StyledTitleWithToolTip,
  BookingContainer,
  BookingTitleInfo,
  BookingIconWrapper,
  BookingHighlight
} from './style';

const RoomBookingModule = () => {
  const history = useHistory();
  const { path } = useRouteMatch();

  const openRoomBooking = () => {
    history.push(`${path}/roomBooking`);
  }

  return (
    <MainContainer>
      <StyledTitleWithToolTip
        title={"Mes services"}
        toolTipText={""}
      />

      <BookingContainer onClick={() => openRoomBooking()}>
        <BookingTitleInfo>
          <BookingIconWrapper>
            <CalendarIcon />
          </BookingIconWrapper>
          {`Réserver une salle de réunion`}
        </BookingTitleInfo>
        <BookingHighlight>
          {`Réserver`}
        </BookingHighlight>
      </BookingContainer>
      <Routes />
    </MainContainer>
  );
};

export default RoomBookingModule;