import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";

import {
  isUserTokenValid,
  isUserAdmin as isUserAdminSelector,
} from '../store/authentication/selectors';

import { getUser } from '../store/collaborator/actions';

export const AdminRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();

  const {
    isTokenValid,
    isUserAdmin
  } = useSelector(
    state => ({
      isTokenValid: isUserTokenValid(state),
      isUserAdmin: isUserAdminSelector(state),
    }),
    shallowEqual
  );

  useEffect(() => {
    if (isTokenValid) {
      dispatch(getUser());
    }
  }, [isTokenValid]);

  const handleTokenInvalid = (props) => {
    if (!isUserAdmin) {
      return (
        <Redirect
          to={{
            pathname: '/landing',
            state: { from: props.location }
          }}
        />
      )
    }
    else {
      return (
        <Component {...props} />
      )
    }
  }

  return (
    <Route
      {...rest}
      render={props =>
        isTokenValid ?
          handleTokenInvalid(props)
          :
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
      }
    />
  )
};
