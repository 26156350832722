import React from "react";

const CheckIcon = ({
  color = "#2B2B2B",
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon checkIcon"
      width={width}
      height={height}
      viewBox="0 0 10 7"
      fill="none"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M9.70708597,1.64349442 L4.43167252,6.71809177 C4.04102178,7.09396941 3.4073247,7.09396941 3.01630384,6.71809177 L0.293099088,4.09817597 C-0.0976996959,3.72229834 -0.0976996959,3.11260985 0.293099088,2.736661 C0.683971895,2.36064094 1.31761963,2.36064094 1.70831971,2.73651858 L3.72419792,4.67581932 L8.29164327,0.281908239 C8.68251608,-0.0941118222 9.31621316,-0.0938269765 9.70693792,0.281908239 C10.0976627,0.657857088 10.0976627,1.26733194 9.70708597,1.64349442 Z"
          id="checkIcon"
          fill={color}
          fillRule="nonzero"
        >
        </path>
      </g>
    </svg>
  );
};

export default CheckIcon;
