import { call, put, select } from 'redux-saga/effects'
import {
  saveTicketMessageSuccess,
  saveTicketMessageError,
  deleteTicketMessageSuccess,
  deleteTicketMessageError
} from '../actions';
import endpoint from '../../../helpers/api';
import * as authenticationSelectors from '../../authentication/selectors';
import { getUser } from '../../collaborator/selectors';
import {
  saveTicketMessageService,
  deleteTicketMessageService
} from '../../../services/ticketMessageServices';
import { toaster } from '../../../helpers/toaster';

export function* saveTicketMessageHandler(action) {
  const { formData } = action.data;
  try {
    const user = yield select(getUser);
    const userToken = yield select(authenticationSelectors.getUserToken);
    const response = yield call(saveTicketMessageService, endpoint.saveTicketMessage, userToken, user, formData);
    yield put(saveTicketMessageSuccess(response.data));
    if (formData.type !== 'system') toaster('Votre message est envoyé', 'success');
  } catch (err) {
    yield put(saveTicketMessageError(err));
    toaster(`Une erreur s'est produite. Veuillez réessayer`, 'error');
  }
}

export function* deleteTicketMessageHandler(action) {
  const { formData } = action.data;
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    yield call(deleteTicketMessageService, endpoint.deleteTicketMessage(formData.messageId), userToken);
    yield put(deleteTicketMessageSuccess(formData));
    toaster('Ce message est supprimé', 'success');
  } catch (err) {
    yield put(deleteTicketMessageError(err));
    toaster(`Une erreur s'est produite. Veuillez réessayer`, 'error');
  }
}