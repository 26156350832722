import _ from 'lodash';

import { PRICE_UNIT } from '../constants/priceConstant';

export const vatDisplay = (vat) => {
  let text = `(TVA non applicable)`;
  const vatSplitted = vat.split('_');
  const type = _.last(vatSplitted);
  const vatAmount = _.first(vatSplitted);

  if (type === 'included') {
    text = `TTC (${vatAmount}% TVA incluse)`;
  }
  else if (type === 'applicable') {
    text = `HT (${vatAmount}% TVA applicable)`;
  }

  return text;
}

export const priceDisplay = (price) => {
  const { value, vat, unit, currency } = price;
  let text = `-`;

  if (unit === PRICE_UNIT.FREE) {
    text = 'Gratuit';
  }
  else if (unit === PRICE_UNIT.QUOTE) {
    text = 'Sur devis';
  }
  else if (unit === PRICE_UNIT.FIXED) {
    text = `${value} ${currency} ${vatDisplay(vat)} forfaitaire`;
  }
  return text;
};

export const calculateVat = (value, vat) => {
  const amount = parseFloat(value);
  const vatAmount = parseFloat(vat.replace(/,/g, '.'));
  const price = amount + (amount * vatAmount / 100);

  return Math.round(price * 100) / 100;
};

export const calculatePrice = (price) => {
  let total = 0;

  if (price) {
    if (price.unit === PRICE_UNIT.FIXED) {
      if (price.vat.includes('applicable')) {
        total = total + calculateVat(price.value, price.vat);
      }
      else {
        total = total + parseFloat(price.value);
      }
    }
  }

  return total;
}

export const displayDeadline = (deadline) => {
  if (deadline < 60) {
    return `${deadline} min`;
  }
  else {
    return `${deadline / 60}h`;
  }
}