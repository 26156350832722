import React from 'react';
import GenericTitleWithToolTip from '../GenericTitleWithToolTip';
import GenericErrorMessage from '../GenericErrorMessage';
import Checkbox from '@material-ui/core/Checkbox';

import {
  MainContainer,
  StyledLabel
} from './style';

const GenericCheckBox = ({
  title = "",
  toolTipText = null,
  toolTipProps,
  ToolTipIcon,
  isRequired,
  className, // to pass styled component className to mainContainer
  checked,     // formik props
  onChange,  // formik props
  label,
  disabled = false,
  posTop = false,
  ...rest    // the rest of the props will be given to TextField component
}) => {

  return (
    <MainContainer className={`${className ? className : ''} genericCheckBox`} disabled={disabled}>
      <StyledLabel className='styledLabel' posTop={posTop}>
        <Checkbox
          {...rest}
          disabled={disabled}
          className={'checkbox'}
          checked={checked}
          onChange={onChange}
        />
        <GenericTitleWithToolTip
          title={title}
          toolTipText={toolTipText}
          ToolTipIcon={ToolTipIcon}
          toolTipProps={toolTipProps}
          isRequired={isRequired}
          className={'generictitle'}
        />
      </StyledLabel>
      {rest.id && <GenericErrorMessage name={rest.id} />}
    </MainContainer>
  )
}

export default GenericCheckBox;