import styled from "styled-components";
import PersonIcon from '@material-ui/icons/Person';
import AssignmentIcon from '@material-ui/icons/Assignment';
import SettingsIcon from '@material-ui/icons/Settings';
import Dialog from "@material-ui/core/Dialog";
import { COLORS } from '../../../../../constants/design';

import {
  MESSENGER_TYPE,
  MESSAGE_TYPE,
} from '../../constants';

const sideMainContainer = {
  [MESSENGER_TYPE.CONCIERGE]: {
    [MESSAGE_TYPE.CLIENT]: 'row',
    [MESSAGE_TYPE.CONCIERGE]: 'row-reverse',
    [MESSAGE_TYPE.NOTE]: 'row-reverse',
    [MESSAGE_TYPE.SYSTEM]: 'row-reverse',
    [MESSAGE_TYPE.SYSTEM_CLIENT]: 'row-reverse',
  },
  [MESSENGER_TYPE.CLIENT]: {
    [MESSAGE_TYPE.CLIENT]: 'row-reverse',
    [MESSAGE_TYPE.CONCIERGE]: 'row',
    [MESSAGE_TYPE.SYSTEM_CLIENT]: 'row',
  }
}

const personIconColor = {
  [MESSENGER_TYPE.CONCIERGE]: {
    [MESSAGE_TYPE.CLIENT]: COLORS.LIGHT_GREY_4,
    [MESSAGE_TYPE.CONCIERGE]: COLORS.PRIMARY_BLUE,
    [MESSAGE_TYPE.NOTE]: COLORS.LIGHT_GREY_4,
    [MESSAGE_TYPE.SYSTEM]: COLORS.LIGHT_GREY_4,
    [MESSAGE_TYPE.SYSTEM_CLIENT]: COLORS.LIGHT_GREY_4,
  },
  [MESSENGER_TYPE.CLIENT]: {
    [MESSAGE_TYPE.CLIENT]: COLORS.PRIMARY_BLUE,
    [MESSAGE_TYPE.CONCIERGE]: COLORS.LIGHT_GREY_4,
    [MESSAGE_TYPE.SYSTEM_CLIENT]: COLORS.LIGHT_GREY_4,
  }
}

const messageBackgroundColor = {
  [MESSENGER_TYPE.CONCIERGE]: {
    [MESSAGE_TYPE.CLIENT]: COLORS.WHITE,
    [MESSAGE_TYPE.CONCIERGE]: COLORS.PRIMARY_BLUE,
    [MESSAGE_TYPE.NOTE]: COLORS.YELLOW_1,
    [MESSAGE_TYPE.SYSTEM]: COLORS.LIGHT_GREY_1,
    [MESSAGE_TYPE.SYSTEM_CLIENT]: COLORS.LIGHT_GREY_1
  },
  [MESSENGER_TYPE.CLIENT]: {
    [MESSAGE_TYPE.CLIENT]: COLORS.PRIMARY_BLUE,
    [MESSAGE_TYPE.CONCIERGE]: COLORS.WHITE,
    [MESSAGE_TYPE.SYSTEM_CLIENT]: COLORS.LIGHT_GREY_1
  }
}

const messageFontColor = {
  [MESSENGER_TYPE.CONCIERGE]: {
    [MESSAGE_TYPE.CLIENT]: COLORS.DARK_GREY,
    [MESSAGE_TYPE.CONCIERGE]: COLORS.WHITE,
    [MESSAGE_TYPE.NOTE]: COLORS.DARK_GREY,
    [MESSAGE_TYPE.SYSTEM]: COLORS.DARK_GREY,
    [MESSAGE_TYPE.SYSTEM_CLIENT]: COLORS.DARK_GREY
  },
  [MESSENGER_TYPE.CLIENT]: {
    [MESSAGE_TYPE.CLIENT]: COLORS.WHITE,
    [MESSAGE_TYPE.CONCIERGE]: COLORS.DARK_GREY,
    [MESSAGE_TYPE.SYSTEM_CLIENT]: COLORS.DARK_GREY
  }
}

const messageBorderColor = {
  [MESSENGER_TYPE.CONCIERGE]: {
    [MESSAGE_TYPE.CLIENT]: COLORS.LIGHT_GREY_2,
    [MESSAGE_TYPE.CONCIERGE]: 'transparent',
    [MESSAGE_TYPE.NOTE]: 'transparent',
    [MESSAGE_TYPE.SYSTEM]: 'transparent',
    [MESSAGE_TYPE.SYSTEM_CLIENT]: 'transparent'
  },
  [MESSENGER_TYPE.CLIENT]: {
    [MESSAGE_TYPE.CLIENT]: 'transparent',
    [MESSAGE_TYPE.CONCIERGE]: COLORS.LIGHT_GREY_2,
    [MESSAGE_TYPE.SYSTEM_CLIENT]: 'transparent'
  }
}

const sideExtraInfos = {
  [MESSENGER_TYPE.CONCIERGE]: {
    [MESSAGE_TYPE.CLIENT]: 'start',
    [MESSAGE_TYPE.CONCIERGE]: 'end',
    [MESSAGE_TYPE.NOTE]: 'end',
    [MESSAGE_TYPE.SYSTEM]: 'end',
    [MESSAGE_TYPE.SYSTEM_CLIENT]: 'end',
  },
  [MESSENGER_TYPE.CLIENT]: {
    [MESSAGE_TYPE.CLIENT]: 'end',
    [MESSAGE_TYPE.CONCIERGE]: 'start',
    [MESSAGE_TYPE.SYSTEM_CLIENT]: 'start',
  }
}

export const MainContainer = styled.div`
  display: flex;
  flex-direction: ${props => sideMainContainer[props.messengertype][props.type]};
  flex: 0 0 auto;
`;

export const CircleDiv = styled.div`
  background-color: ${props => props.type === MESSAGE_TYPE.SYSTEM || props.type === MESSAGE_TYPE.SYSTEM_CLIENT ? 'transparent' : COLORS.LIGHT_GREY_1};
  border-radius: 50%;
  display: flex;
  height: 50px;
  width: 50px;
  justify-content: center;
  align-items: center;
  color: ${COLORS.LIGHT_GREY_4};
  position: relative;
`;

export const StyledPersonIcon = styled(PersonIcon)`
  color: ${props => personIconColor[props.messengertype][props.type]};
`;

export const StyledNoteIcon = styled(AssignmentIcon)`
  color: ${COLORS.LIGHT_GREY_4};
`;

export const StyledSystemIcon = styled(SettingsIcon)`
  color: ${COLORS.LIGHT_GREY_4};
`;

export const StyledNoteIndicator = styled.div`
  background-color: ${props => props.system ? COLORS.LIGHT_GREY_2 : COLORS.YELLOW_1};
  color: ${COLORS.DARK_GREY};
  border-radius: 6px;
  padding: ${props => props.system ? '2px 9px' : '2px 10px'};
  font-size:${props => props.system ? '0.55rem' : '0.6rem'};
  position: absolute;
  bottom: 0;
`;

export const MainMessageContainer = styled.div`
  position: relative;
  width: 75%;
  margin: 0px 10px;
`;

export const StyledMessageContainer = styled.div`
  border: 1px solid ${props => messageBorderColor[props.messengertype][props.type]};
  background-color: ${props => messageBackgroundColor[props.messengertype][props.type]};
  border-radius: 6px;
  padding: 10px;
  color: ${props => messageFontColor[props.messengertype][props.type]};
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 1.4;
  strong, b {
    font-weight: 600;
  }
`;

export const StyledMessageAttachmentContainer = styled.div`
  /* border: 1px solid white; */
  /* box-sizing: border-box; */
  margin-top: 5px;
  /* width: 80%; */
  display: flex;
  /* justify-content: space-between; */
  >div:not(:last-child) {
    margin-right: 12px;
  };
`;

export const StyledThumbnail = styled.div`
  /* box-sizing: border-box; */
  border: 1px solid ${COLORS.LIGHT_GREY_2};
  border-radius: 5px;
  width: 60px;
  height: 60px;
  position: relative;
  padding: 5px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;

export const StyledImg = styled.img`
  /* width: 55px;
  height: 55px; */
  object-fit: cover;
`

export const StyledMessageExtraInfo = styled.div`
  color: ${props => COLORS.LIGHT_GREY_4};
  font-size: 0.7rem;
  padding: 0px 10px;
  display: flex;
  flex-direction: row;
  justify-content: ${props => sideExtraInfos[props.messengertype][props.type]};
`;

export const ConciergeImageContainer = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
`;

export const StyledMessageButtonContainer = styled.div`
  position: absolute;
  right: 10px;
  bottom: 0px;
  display: -webkit-box;
`;

export const StyledMessageButtonIcon = styled.div`
  box-sizing: border-box;
  width: 40px;
  transition: all ease-in .15s;
  touch-action: manipulation;
  cursor: pointer;
  border: 2px solid ${COLORS.LIGHT_GREY_1};
  padding: 3px;
  padding-top: 6px;
  background-color: ${COLORS.WHITE};
  border-radius: 30px;
  margin-left: 10px;
  text-align: center;
  color: ${COLORS.GREY_1};
  &:hover {
    background-color: ${COLORS.RED};
    color: ${COLORS.WHITE};
  }
`;

export const StyledMessageImage = styled.img`
  border-radius: 6px;
  max-width: 400px;
  cursor: pointer;
`;