import * as a from "./actions";

const INITIAL_STATE = {
  cgu: {
    data: null,
    save: {
      loading: false,
      success: false,
      error: null,
    }
  },
  one: {
    data: null,
    save: {
      loading: false,
      success: false,
      error: null,
    }
  },
  three: {
    data: null,
    save: {
      loading: false,
      success: false,
      error: null,
    }
  },
  four: {
    data: null,
    save: {
      loading: false,
      success: false,
      error: null,
    }
  },
  corporation: {
    data: null,
    get: {
      loading: false,
      success: false,
      error: null,
    }
  }
};

export function onboarding(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case a.RESET_ONBOARDING:
      return INITIAL_STATE;
    // CGU
    case a.SAVE_ONBOARDING_CGU.REQUEST:
      return {
        ...state,
        cgu: {
          data: null,
          save: {
            loading: true,
            success: false,
            error: null,
          }
        },
      };
    case a.SAVE_ONBOARDING_CGU.SUCCESS:
      return {
        ...state,
        cgu: {
          data: action.data,
          save: {
            loading: false,
            success: true,
            error: null,
          }
        },
      };
    case a.SAVE_ONBOARDING_CGU.FAILURE:
      return {
        ...state,
        cgu: {
          data: null,
          save: {
            loading: false,
            success: false,
            error: action.error,
          }
        },
      };
    // ONE
    case a.SAVE_ONBOARDING_ONE.REQUEST:
      return {
        ...state,
        one: {
          data: null,
          save: {
            loading: true,
            success: false,
            error: null,
          }
        },
      };
    case a.SAVE_ONBOARDING_ONE.SUCCESS:
      return {
        ...state,
        one: {
          data: action.data,
          save: {
            loading: false,
            success: true,
            error: null,
          }
        },
      };
    case a.SAVE_ONBOARDING_ONE.FAILURE:
      return {
        ...state,
        one: {
          data: null,
          save: {
            loading: false,
            success: false,
            error: action.error,
          }
        },
      };
    // THREE
    case a.SAVE_ONBOARDING_THREE.REQUEST:
      return {
        ...state,
        three: {
          data: null,
          save: {
            loading: true,
            success: false,
            error: null,
          }
        },
      };
    case a.SAVE_ONBOARDING_THREE.SUCCESS:
      return {
        ...state,
        three: {
          data: action.data,
          save: {
            loading: false,
            success: true,
            error: null,
          }
        },
      };
    case a.SAVE_ONBOARDING_THREE.FAILURE:
      return {
        ...state,
        three: {
          data: null,
          save: {
            loading: false,
            success: false,
            error: action.error,
          }
        },
      };
    // FOUR
    case a.SAVE_ONBOARDING_FOUR.REQUEST:
      return {
        ...state,
        four: {
          data: null,
          save: {
            loading: true,
            success: false,
            error: null,
          }
        },
      };
    case a.SAVE_ONBOARDING_FOUR.SUCCESS:
      return {
        ...state,
        four: {
          data: action.data,
          save: {
            loading: false,
            success: true,
            error: null,
          }
        },
      };
    case a.SAVE_ONBOARDING_FOUR.FAILURE:
      return {
        ...state,
        four: {
          data: null,
          save: {
            loading: false,
            success: false,
            error: action.error,
          }
        },
      };
    // CORPORATION
    case a.GET_CORPORATION.REQUEST:
      return {
        ...state,
        corporation: {
          data: null,
          get: {
            loading: true,
            success: false,
            error: null,
          }
        },
      };
    case a.GET_CORPORATION.SUCCESS:
      return {
        ...state,
        corporation: {
          data: action.data,
          get: {
            loading: false,
            success: true,
            error: null,
          }
        },
      };
    case a.GET_CORPORATION.FAILURE:
      return {
        ...state,
        corporation: {
          data: null,
          get: {
            loading: false,
            success: false,
            error: action.error,
          }
        },
      };
    default:
      return state;
  }
}

export default onboarding;
