import React from 'react';

import GenericDialogContainer from '../../molecules/GenericDialogContainer';

import {
  CustomDialog,
} from './style';

const CGUcontent = (subDomaine) => {
  switch (subDomaine) {
    case 'conciergerie-izipop-bpalc.cyconia.io':
      return (
        <article >
          <h2><strong>CGVU CYCONIA</strong></h2>
          <p><strong>1 Septembre 2021&nbsp;</strong></p>
          <p> Version 3.1</p>
          <p><strong>Cyonia, c&rsquo;est Grandiose, Unique et Vanill&eacute; (on a pas trouv&eacute; mieux d&eacute;sol&eacute;&hellip;)</strong></p>
          <h2><strong>Sommaire :</strong></h2>
          <h3><strong> Pr&eacute;ambule</strong></h3>
          <h3><strong>1) Objet</strong></h3>
          <h3><strong>2) Conditions d&rsquo;inscription</strong></h3>
          <h3><strong>3) Signature &eacute;lectronique</strong></h3>
          <h3><strong>4) Condition de paiement</strong></h3>
          <h3><strong>5) D&eacute;lai d&rsquo;annulation</strong></h3>
          <h3><strong>6) Obligation de l&rsquo;entreprise</strong></h3>
          <h3><strong>7) Obligation de l&rsquo;utilisateur</strong></h3>
          <h3><strong>8) Droit de r&eacute;tractation</strong></h3>
          <h3><strong>9) Donn&eacute;es personnelles</strong></h3>
          <h3><strong>10) Droit de d&eacute;sabonnement</strong></h3>
          <h3><strong>11) Responsabilit&eacute;s</strong></h3>
          <h3><strong>12) Forces majeures</strong></h3>
          <h3><strong>13) Lois applicables</strong></h3>
          <p></p>
          <p> Cyconia, est une solution logiciel, d&rsquo;intelligence artificielle, qui reproduit le comportement d&rsquo;un assistant au travail. Il s&rsquo;agit de permettre au collaborateur de gagner du temps en rendant disponible tous les services et l&rsquo;information dont il a besoin au travail : les essentiels pour sa vie personnelle, les essentiels pour sa vie professionnelle.</p>
          <p> </p>
          <h2><strong>Dans ma PR&Eacute;AM-BULLE (oui, c&rsquo;est tr&egrave;s recherch&eacute; <span role="img">😎</span>) :</strong></h2>
          <p>Toute utilisation de la solution Cyconia implique l&rsquo;acceptation sans r&eacute;serve des pr&eacute;sentes Conditions G&eacute;n&eacute;rales par le Client.</p>
          <p>Ce site web est exploit&eacute; par Cyconia . Sur ce site, les termes &laquo;&nbsp;nous&nbsp;&raquo;, &laquo;&nbsp;notre&nbsp;&raquo; et &laquo;&nbsp;nos&nbsp;&raquo; &ldquo;La Soci&eacute;t&eacute;&rdquo; font r&eacute;f&eacute;rence &agrave; Cyconia . Cyconia propose ce site web, y compris toutes les informations, tous les outils et tous les services qui y sont disponibles pour vous, l&rsquo;utilisateur, sous r&eacute;serve de votre acceptation de l&rsquo;ensemble des modalit&eacute;s, conditions, politiques et avis &eacute;nonc&eacute;s ici.</p>
          <p>Les Conditions G&eacute;n&eacute;rales sont susceptibles d&rsquo;&ecirc;tre modifi&eacute;es &agrave; tout moment par la soci&eacute;t&eacute; Cyconia. Un num&eacute;ro de version et une date de mise &agrave; jour sont indiqu&eacute;s conform&eacute;ment &agrave; la loi en vigueur.</p>
          <p>EXPRESS&rsquo;ING ayant pour marque commerciale Cyconia, est une soci&eacute;t&eacute; par actions simplifi&eacute;e au capital de 41110 euros, inscrite au RCS de Paris sous le num&eacute;ro 812375657.</p>
          <p>Les pr&eacute;sentes Conditions g&eacute;n&eacute;rales de vente et d&rsquo;utilisation (CGVU) entrent en vigueur &agrave; leur date de mise en ligne et s&rsquo;appliquent d&egrave;s la premi&egrave;re utilisation du Site. Elles constituent un accord ayant force obligatoire entre vous et Cyconia. Ces CGVU sont accessibles &agrave; tout moment sur le Site et pr&eacute;vaudront, le cas &eacute;ch&eacute;ant, sur toute autre version ou tout autre document contradictoire.</p>
          <h2><strong>1 &ndash; Pas d&rsquo;Objet chez nous, on propose des services <span role="img">😏</span></strong></h2>
          <p>L&rsquo;objet du Service est, sans &ecirc;tre limitatif, de permettre &agrave; l&rsquo;Utilisateur par le biais d&rsquo;un moyen de communication &agrave; distance (logiciel Cyconia) de solliciter la Soci&eacute;t&eacute; aux fins d&rsquo;effectuer la r&eacute;alisation d&rsquo;une prestation de service, de demander une aide collaborative, de diffuser une information ou d&rsquo;organiser des r&eacute;unions &laquo; * sous r&eacute;serve de disponibilit&eacute; du service au sein de votre entreprise &raquo;.</p>
          <p>A cet effet la Soci&eacute;t&eacute; tient le r&ocirc;le d&rsquo;interm&eacute;diaire transparent entre l&rsquo;Utilisateur et l&rsquo;Entreprise et/ou le Commer&ccedil;ant.</p>
          <p>En cas de probl&egrave;me tenant &agrave; la r&eacute;alisation d&rsquo;une prestation non conforme &agrave; la commande, l&rsquo;Utilisateur devra prendre contact avec le Commer&ccedil;ant concern&eacute;, sans pr&eacute;judice des droits de r&eacute;tractation dont il dispose conform&eacute;ment &agrave; la l&eacute;gislation en vigueur, un accompagnement sera propos&eacute; dans le cadre de notre service client.</p>
          <h2><strong>2 &ndash; Pour en profiter, il faut d&rsquo;abord s&rsquo;inscrire <span role="img">😩</span></strong></h2>
          <p>2.1. Avant d&rsquo;effectuer une commande, l&rsquo;Utilisateur doit pr&eacute;alablement effectuer son inscription sur le site https://cyconia.io, au moyen des formulaires qui y figurent et renseigner ses informations personnelles.</p>
          <p>L&rsquo;acc&egrave;s et l&rsquo;utilisation du Service Cyconia implique le remplissage d&rsquo;un formulaire d&rsquo;inscription, ainsi que la cr&eacute;ation d&rsquo;un compte utilisateur, permettant &agrave; l&rsquo;abonn&eacute; de s&rsquo;authentifier.</p>
          <p>Un compte correspond &agrave; un identifiant, associ&eacute; &agrave; un mot de passe, qui sont tous deux propres et strictement personnels &agrave; l&rsquo;utilisateur. Ils permettent &agrave; tout utilisateur, via une interface d&eacute;di&eacute;e, de souscrire/modifier ses informations personnelles.</p>
          <p>Une fois le compte cr&eacute;&eacute; Cyconia adressera un accus&eacute; de r&eacute;ception &agrave; l&rsquo;adresse email renseign&eacute;e dans le formulaire d&rsquo;inscription, &agrave; titre de confirmation de l&rsquo;acceptation de la demande d&rsquo;inscription.</p>
          <p>En cas de non r&eacute;ception dudit email adresser un email sur : serviceclient@cyconia.io</p>
          <p>Pour que son inscription soit valid&eacute;e, l&rsquo;Utilisateur devra accepter, en cliquant &agrave; l&rsquo;endroit indiqu&eacute;, les pr&eacute;sentes CGVU.</p>
          <p>2.2. La commande de l&rsquo;Utilisateur n&rsquo;est valid&eacute;e et sa carte bancaire d&eacute;bit&eacute;e, qu&rsquo;apr&egrave;s avoir donn&eacute; son accord sur le prix correspondant &agrave; la prestation demand&eacute;e.</p>
          <p>Toute contestation sur ce point interviendra dans le cadre d&rsquo;un &eacute;ventuel &eacute;change et des garanties ci-dessous mentionn&eacute;es.</p>
          <p>Dans certaines hypoth&egrave;ses, notamment d&eacute;faut de paiement, adresse erron&eacute;e ou autre probl&egrave;me sur le compte de l&rsquo;Utilisateur, la Soci&eacute;t&eacute; se r&eacute;serve le droit de bloquer la commande de l&rsquo;Utilisateur jusqu&rsquo;&agrave; la compl&egrave;te r&eacute;solution du probl&egrave;me.</p>
          <p>En cas d&rsquo;impossibilit&eacute; d&rsquo;ex&eacute;cuter une prestation, l&rsquo;Utilisateur en sera inform&eacute; par email.</p>
          <p>L&rsquo;Utilisateur est inform&eacute; que les services sont offerts &agrave; la vente dans la limite de leur disponibilit&eacute;.</p>
          <p>L&rsquo;indisponibilit&eacute; d&rsquo;un service ne pourra donner lieu &agrave; aucune indemnisation.</p>
          <p>De mani&egrave;re g&eacute;n&eacute;rale, l&rsquo;Utilisateur accepte et reconna&icirc;t que l&rsquo;effectivit&eacute; de la prestation n&rsquo;est pas garantie par le site.</p>
          <p>Si la prestation ne peut &ecirc;tre assur&eacute;e, l&rsquo;Utilisateur en est inform&eacute; concomitamment &agrave; sa demande.</p>
          <p>Consid&eacute;rant le grand nombre de demandes formul&eacute;es aupr&egrave;s du Site, si la prestation ne peut &ecirc;tre r&eacute;alis&eacute;e dans l&rsquo;imm&eacute;diat ou le bien livr&eacute;, l&rsquo;Utilisateur est plac&eacute; en liste d&rsquo;attente et servi en fonction :</p>
          <ul>
            <li> Du niveau de difficult&eacute; de la commande,</li>
            <li> De la disponibilit&eacute; des prestations command&eacute;s.</li>
            <li> </li>
          </ul>
          <p>L&rsquo;Utilisateur est inform&eacute; et accepte que ses donn&eacute;es &agrave; caract&egrave;re personnel &ndash; nom, pr&eacute;nom, e-mail, t&eacute;l&eacute;phone, civilit&eacute;, adresse postale &ndash; soient communiqu&eacute;es au Commer&ccedil;ant.</p>
          <p>Pour toute question relative au suivi d&rsquo;une prestation, le client doit se connecter sur son interface de suivi ou appeler le 06 19 15 32 89 (co&ucirc;t d&rsquo;un appel local) ou envoyer ou envoyer un email &agrave; l&rsquo;adresse serviceclient@cyconia.io</p>
          <h2><strong>3 &ndash; On peut avoir une autographe ? </strong></h2>
          <p>La fourniture en ligne du num&eacute;ro de carte bancaire de l&rsquo;Utilisateur et la validation finale de la commande par mail, vaudront preuve de l&rsquo;accord entre les parties conform&eacute;ment aux dispositions de la Loi n&deg;2000-230 du 13 mars 2000 et vaudront :</p>
          <ul>
            <li> Exigibilit&eacute; des sommes dues au titre du bon de commande ;</li>
            <li> Signature et acception expresse de toutes les op&eacute;rations effectu&eacute;es ;</li>
          </ul>
          <p>En cas d&rsquo;utilisation frauduleuse de la carte bancaire, l&rsquo;Utilisateur est invit&eacute;, d&egrave;s le constat de cette utilisation, &agrave; contacter le 06 19 15 32 89 par t&eacute;l&eacute;phone ou par email serviceclient@cyconia.io</p>
          <h2><strong>4 &ndash; D&eacute;sol&eacute;, la maison ne fait pas cr&eacute;dit <span role="img" aria-label="carte de credit">💳</span></strong></h2>
          <p>&mdash;&ndash;La Soci&eacute;t&eacute; utilise le service de paiement s&eacute;curis&eacute; fourni par la soci&eacute;t&eacute; Stripe (<a href="https://stripe.com/fr/privacy)">https://stripe.com/fr/privacy)</a>, dont le si&egrave;ge social est situ&eacute; au 3180 18HT Street, Suite 100, San Francisco, CA 94110, USA.</p>
          <p>Les donn&eacute;es confidentielles telles que le num&eacute;ro de carte bancaire &agrave; 16 chiffres, la date d&rsquo;expiration ainsi que le code CVV, sont directement transmises crypt&eacute;es sur le serveur de l&rsquo;&eacute;tablissement bancaire.</p>
          <p>La Soci&eacute;t&eacute; n&rsquo;a pas acc&egrave;s &agrave; ces donn&eacute;es. &mdash;&mdash;</p>
          <p>La confirmation de la commande se mat&eacute;rialise par l&rsquo;acceptation du devis.</p>
          <p><strong>4.1.&nbsp;</strong>Toute demande de facture doit &ecirc;tre adress&eacute;e directement &agrave; la Soci&eacute;t&eacute;.</p>
          <p>Afin d&rsquo;assurer la s&eacute;curit&eacute; des transactions et de r&eacute;pondre au souci de pr&eacute;vention des fraudes en mati&egrave;re de vente en ligne, la Soci&eacute;t&eacute; peut &ecirc;tre conduite &agrave; effectuer des contr&ocirc;les sur les commandes.</p>
          <p>La Soci&eacute;t&eacute; se r&eacute;serve le droit de suspendre toute gestion de commande en cas de refus d&rsquo;autorisation de paiement de la part des organismes officiellement accr&eacute;dit&eacute;s ou en cas de non-paiement.</p>
          <p>La Soci&eacute;t&eacute; a mis en place une proc&eacute;dure de v&eacute;rification des commandes destin&eacute;e &agrave; assurer qu&rsquo;aucune personne n&rsquo;utilise les coordonn&eacute;es bancaires d&rsquo;une autre personne &agrave; son insu.</p>
          <p>Dans le cadre de cette v&eacute;rification, il pourra &ecirc;tre demand&eacute; &agrave; l&rsquo;Utilisateur d&rsquo;adresser par fax ou e-mail &agrave; la Soci&eacute;t&eacute; une copie d&rsquo;une pi&egrave;ce d&rsquo;identit&eacute; ainsi qu&rsquo;un justificatif de domicile.</p>
          <p>La commande ne sera alors valid&eacute;e qu&rsquo;apr&egrave;s r&eacute;ception et v&eacute;rification par la Soci&eacute;t&eacute; des pi&egrave;ces envoy&eacute;es.</p>
          <p><strong>4.2.</strong> Les registres informatis&eacute;s, conserv&eacute;s dans les syst&egrave;mes informatiques de la Soci&eacute;t&eacute; dans des conditions raisonnables de s&eacute;curit&eacute;, seront consid&eacute;r&eacute;s comme les preuves de communications, des commandes et des paiements intervenus entre les Parties.</p>
          <p>L&rsquo;archivage des bons de commande et des factures est effectu&eacute; sur un support fiable et durable pouvant &ecirc;tre produit &agrave; titre de preuve.</p>
          <h2><strong>5 &ndash; Urgence de derni&egrave;re minute ? Vous pouvez encore annuler, enfin sous certaines conditions <span role="img">😅</span></strong></h2>
          <p>Pour la r&eacute;alisation d&rsquo;une prestation :</p>
          <p>La prestation est r&eacute;alis&eacute;e &agrave; l&rsquo;adresse indiqu&eacute;e par l&rsquo;Utilisateur sur le bon de commande, l&rsquo;Utilisateur devra veiller &agrave; son exactitude.</p>
          <p>Tout d&eacute;placement en pure perte du prestataire &agrave; cause d&rsquo;une adresse erron&eacute;e ou incompl&egrave;te sera factur&eacute; &agrave; l&rsquo;Utilisateur.</p>
          <p>L&rsquo;Utilisateur peut, &agrave; sa demande, obtenir l&rsquo;envoi d&rsquo;une facture &agrave; l&rsquo;adresse de facturation et non &agrave; l&rsquo;adresse de livraison, en validant l&rsquo;option pr&eacute;vue &agrave; cet effet sur le bon de commande.</p>
          <p>L&rsquo;Utilisateur veillera &agrave; donner acc&egrave;s &agrave; la Soci&eacute;t&eacute; notamment s&rsquo;il n&rsquo;est pas personnellement pr&eacute;sent le jour de la r&eacute;alisation de la prestation.</p>
          <p>La fin de la prestation donnera lieu &agrave; un document de fin d&rsquo;intervention.</p>
          <p>L&rsquo;Utilisateur doit indiquer sur ce document et sous forme de r&eacute;serves manuscrites accompagn&eacute;es de sa signature toute anomalie concernant la prestation ou les conditions de sa r&eacute;alisation.</p>
          <p>Cette v&eacute;rification est consid&eacute;r&eacute;e comme effectu&eacute;e d&egrave;s lors que l&rsquo;Utilisateur ou une personne autoris&eacute;e par lui, a sign&eacute; le document de fin d&rsquo;intervention.</p>
          <h2><strong>6 &ndash; Plusieurs obligations pour un credo : &agrave; votre service 😊</strong></h2>
          <p><strong>6.1</strong> La Soci&eacute;t&eacute; s&rsquo;engage &agrave; apporter tout le soin et la diligence n&eacute;cessaires &agrave; la fourniture d&rsquo;un service de qualit&eacute;.</p>
          <p><strong>6.2.</strong> La Soci&eacute;t&eacute; ne r&eacute;pond cependant que d&rsquo;une obligation de moyens au terme de laquelle les prestations ou livraisons de biens seront ex&eacute;cut&eacute;es dans le strict respect des r&egrave;gles professionnelles en usage, ainsi, le cas &eacute;ch&eacute;ant, que conform&eacute;ment aux conditions de la commande. La Soci&eacute;t&eacute; ne serait &ecirc;tre responsable des agissements des Commer&ccedil;ants.</p>
          <p><strong>6.3.</strong> La responsabilit&eacute; civile et p&eacute;nale de la Soci&eacute;t&eacute; ne sera pas engag&eacute;e en cas de faute imputable &agrave; l&rsquo;Utilisateur. Constituent des fautes, sans &ecirc;tre limitatifs, les cas suivants :</p>
          <ul>
            <li> La mauvaise utilisation du service</li>
            <li> La divulgation ou utilisation illicite du compte cr&eacute;&eacute;</li>
          </ul>
          <p><strong>6.4.</strong> L&rsquo;Utilisateur accepte (I) qu&rsquo;il est techniquement impossible d&rsquo;offrir un Service exempt de tout d&eacute;faut et que la Soci&eacute;t&eacute; ne peut pas s&rsquo;engager &agrave; cet effet, (II) que des d&eacute;fauts peuvent rendre le Service temporairement indisponible, et que (III) le fonctionnement du site peut &ecirc;tre affect&eacute; par des &eacute;v&eacute;nements et/ou des probl&egrave;mes sur lesquels la Soci&eacute;t&eacute; n&rsquo;a aucun contr&ocirc;le.</p>
          <p>La Soci&eacute;t&eacute; peut, &agrave; tout moment, modifier ou interrompre, temporairement ou d&eacute;finitivement, tout ou partie du Site pour effectuer la maintenance et/ou apporter des am&eacute;liorations et/ou des modifications au Site. La Soci&eacute;t&eacute; d&eacute;cline toute responsabilit&eacute; pour toute modification, suspension ou interruption du site.</p>
          <h2><strong>7 &ndash; Vous avez le droit de garder le silence. Tout ce que vous direz pourra &ecirc;tre et sera utilis&eacute; contre vous devant une cour de justice&hellip;. ⚖️ ! </strong></h2>
          <p><strong>7.1</strong> L&rsquo;Utilisateur est seul responsable de l&rsquo;utilisation du Service, des informations transmises, de leur exploitation et de leur mise &agrave; jour. L&rsquo;Utilisateur s&rsquo;oblige &agrave; veiller &agrave; ce que des tiers n&rsquo;utilisent le service Cyconia &agrave; partir de leur portable, ou &agrave; contacter d&egrave;s que possible les op&eacute;rateurs du Site via la rubrique &laquo; Contact &raquo; en cas de perte ou de vol de leur portable.</p>
          <p>L&rsquo;Utilisateur est seul responsable de l&rsquo;utilisation qui est faite de son compte. Tout acc&egrave;s ou utilisation des services effectu&eacute; &agrave; partir du compte d&rsquo;un Utilisateur sera r&eacute;put&eacute; avoir &eacute;t&eacute; effectu&eacute; par ce dernier.</p>
          <p>L&rsquo;Utilisateur s&rsquo;engage notamment :</p>
          <ul>
            <li> A fournir des informations sinc&egrave;res et v&eacute;ritables ;</li>
            <li> A adresser &agrave; la Soci&eacute;t&eacute; toutes les informations et les documents requis en vue de l&rsquo;ex&eacute;cution du Service</li>
          </ul>
          <p><strong>7.2</strong> L&rsquo;Utilisateur supporte seul les cons&eacute;quences d&rsquo;une utilisation frauduleuse de son compte.</p>
          <p><strong>7.3</strong> Il appartient &agrave; l&rsquo;Utilisateur de s&rsquo;acquitter du montant correspondant au montant de sa commande, aupr&egrave;s de la Soci&eacute;t&eacute;.</p>
          <p>A d&eacute;faut la Soci&eacute;t&eacute; se r&eacute;serve le droit de suspendre sans pr&eacute;avis le Service.</p>
          <p><strong>7.4</strong>. La Soci&eacute;t&eacute; se r&eacute;serve la possibilit&eacute; d&rsquo;exercer des contr&ocirc;les sur la conformit&eacute; de l&rsquo;utilisation par l&rsquo;Utilisateur du service &agrave; ces dispositions.</p>
          <p><strong>7.5</strong>. L&rsquo;Utilisateur s&rsquo;engage &agrave; ne divulguer aucune information ayant trait au service.</p>
          <p>Ces informations sont confidentielles et constituent, &agrave; ce titre, des secrets commerciaux.</p>
          <h2><strong>8 &ndash; Droit de r&eacute;tractation : Tu veux ou tu veux pas Tu veux c&rsquo;est bien Si tu veux pas tant pis Si tu veux pas J&rsquo;en f&rsquo;rai pas une maladie 🎼</strong></h2>
          <p><strong>8.1</strong> Conform&eacute;ment aux dispositions du Code de la consommation, l&rsquo;Utilisateur dispose d&rsquo;un d&eacute;lai de 14 (quatorze) jours ouvrables &agrave; compter de la date de validation de la commande par email.</p>
          <p>Le consommateur qui exerce son droit de r&eacute;tractation n&rsquo;a pas &agrave; motiver sa d&eacute;cision (article L. 121-21 du code de la consommation)</p>
          <p>Conform&eacute;ment &agrave; l&rsquo;article L121-21-8 du Code de la consommation, ce droit de r&eacute;tractation est exclu pour :</p>
          <ul>
            <li> Les contrats de services dont l&rsquo;ex&eacute;cution a commenc&eacute;, avec l&rsquo;accord de l&rsquo;Utilisateur, avant la fin du d&eacute;lai de r&eacute;tractation ;</li>
            <li> Les contrats de biens ou de services dont le prix d&eacute;pend des taux du march&eacute; financier ;</li>
            <li> Les contrats de biens qui, par leur nature, ne peuvent &ecirc;tre r&eacute;exp&eacute;di&eacute;s ou peuvent se d&eacute;t&eacute;riorer ou se p&eacute;rimer rapidement ;</li>
          </ul>
          <p>La r&eacute;tractation doit se faire par email &agrave; l&rsquo;adresse serviceclient@cyconia.io. </p>
          <p>Ce droit de r&eacute;tractation s&rsquo;exerce sans p&eacute;nalit&eacute;.</p>
          <h2><strong> 9 &ndash; Vos donn&eacute;es sont en s&eacute;curit&eacute;, nous n&rsquo;avons rien vu, rien entendu <span role="img">🙈</span> <span role="img">🙉</span> !</strong></h2>
          <p><strong>9.1</strong> Toutes les informations de l&rsquo;Utilisateur ne sont utilis&eacute;es que dans le cadre de sa relation commerciale avec la Soci&eacute;t&eacute;.</p>
          <p>Elles proviennent de l&rsquo;enregistrement volontaire d&rsquo;une adresse e-mail ou d&rsquo;une adresse postale lors d&rsquo;une commande.</p>
          <p>Ces informations ne sont jamais revendues.</p>
          <p>La Soci&eacute;t&eacute; informe l&rsquo;Utilisateur qu&rsquo;elle pourra communiquer ses donn&eacute;es pour assurer la livraison des commandes, certains aspects du service apr&egrave;s-vente, et pour r&eacute;aliser des enqu&ecirc;tes de satisfaction.</p>
          <p><strong>9.2</strong> La Soci&eacute;t&eacute; s&rsquo;engage &agrave; respecter la confidentialit&eacute; de vos donn&eacute;es personnelles et &agrave; les traiter dans le respect de la loi Informatique et Libert&eacute;s du 6 janvier 1978 et de la r&eacute;glementation g&eacute;n&eacute;rale protection des donn&eacute;es. Une d&eacute;claration a &eacute;t&eacute; faite aupr&egrave;s de la CNIL (2022038 v 0).</p>
          <p><strong>9.3</strong> Dans le cadre de son partenariat avec CYCONIA-HANDIZEN, le client &eacute;change et traite des donn&eacute;es &agrave; caract&egrave;re personnel de ses collaborateurs : identit&eacute;, adresses mail et usage du service. Ce traitement a pour finalit&eacute; la gestion comptable, organisationnelle et statistiques de ce partenariat. La dur&eacute;e de conservation de ces donn&eacute;es ne pourra exc&eacute;der la dur&eacute;e du contrat entre l&rsquo;entreprise et CYCONIA-HANDIZEN . Les collaborateurs concern&eacute;s par ce traitement b&eacute;n&eacute;ficient &agrave; tout moment pour les donn&eacute;es &agrave; caract&egrave;re personnel les concernant et dans les conditions pr&eacute;vues par la loi, d’un droit d’acc&egrave;s, de rectification, d’opposition, de limitation, d’effacement, &agrave; la portabilit&eacute;, &agrave; communiquer des instructions sur leur sort en cas de d&eacute;c&egrave;s, de retrait de leur consentement (si donn&eacute;) et du droit de former une r&eacute;clamation aupr&egrave;s de l’autorit&eacute; comp&eacute;tente.
            Les modalit&eacute;s d’exercice de ces droits sont consultables sur simple demande aupr&egrave;s de Cyconia ou aupr&egrave;s de votre Direction des Ressources Humaines. </p>
          <ul>
            <li> L&rsquo;Utilisateur peut &agrave; tout moment demander la suppression de ses informations &agrave; l&rsquo;adresse &eacute;lectronique suivante : serviceclient@cyconia.io</li>
          </ul>
          <h2><strong>10 &ndash; Et si vous voulez partir, cela nous rendra triste, mais c&rsquo;est votre droit&hellip; <span role="img">😔</span></strong></h2>
          <p>10.1 Fermeture du compte &agrave; votre initiative</p>
          <p>Vous pouvez fermer d&eacute;finitivement votre compte et vous d&eacute;sinscrire.</p>
          <ul>
            <li> Depuis votre espace membre sur https://xxx.cyconia.io</li>
            <li> En adressant un courriel &agrave; serviceclient@cyconia.io</li>
          </ul>
          <p>La fermeture de votre compte entrera en vigueur dans un d&eacute;lai raisonnable apr&egrave;s la r&eacute;ception de votre demande. Votre abonnement &eacute;tant achet&eacute; par votre employeur, vous pouvez vous r&eacute;inscrire au moment souhait&eacute;. </p>
          <p><strong>10.2</strong> Fermeture du compte &agrave; l&rsquo;initiative de la Soci&eacute;t&eacute;</p>
          <p>Le manque au r&egrave;glement de votre abonnement par votre employeur. </p>
          <p>En cas de non-respect des pr&eacute;sentes CGVU, la Soci&eacute;t&eacute; aura la facult&eacute; de suspendre ou clore votre compte &agrave; tout moment, de plein droit et sans formalit&eacute; judiciaire, apr&egrave;s l&rsquo;envoi d&rsquo;une mise en demeure via courriel rest&eacute;e sans effet pendant une dur&eacute;e de cinq (5) jours calendaires &agrave; compter de sa r&eacute;ception.</p>
          <p><strong>10.3</strong> Cons&eacute;quences de la fermeture du compte</p>
          <p>Toute fermeture de votre compte entra&icirc;ne la suppression d&eacute;finitive dans un d&eacute;lai raisonnable des informations communiqu&eacute;es &agrave; la Soci&eacute;t&eacute; lors de l&rsquo;ouverture ou de la mise &agrave; jour de votre compte, des documents et autres &eacute;l&eacute;ments t&eacute;l&eacute;vers&eacute;s dans votre espace ainsi que, le cas &eacute;ch&eacute;ant, de tout document ou contenu que vous auriez publi&eacute;, t&eacute;l&eacute;charg&eacute; ou mis &agrave; disposition sur la Plateforme.</p>
          <p>Nonobstant ce qui pr&eacute;c&egrave;de, l&rsquo;&Eacute;diteur se r&eacute;serve le droit de conserver les Documents, informations et autres &eacute;l&eacute;ments susmentionn&eacute;s sur ses serveurs pendant un d&eacute;lai raisonnable.</p>
          <h2><strong>11 &ndash; Certes on perd 1-0, mais c&rsquo;est la faute du gardien <span role="img">🙄</span></strong></h2>
          <p>Certains contenus, produits et services disponibles par le biais de notre Service pourraient inclure des &eacute;l&eacute;ments provenant de tierces parties.</p>
          <p>Les liens provenant de tierces parties sur ce site pourraient vous rediriger vers des sites web de tiers qui ne sont pas affili&eacute;s &agrave; nous. Nous ne sommes pas tenus d&rsquo;examiner ou d&rsquo;&eacute;valuer le contenu ou l&rsquo;exactitude de ces sites, et nous ne garantissons pas et n&rsquo;assumons aucune responsabilit&eacute; quant &agrave; tout contenu, site web, produit, service ou autre &eacute;l&eacute;ment accessible sur ou depuis ces sites tiers.</p>
          <p>Nous ne sommes pas responsables des pr&eacute;judices ou dommages li&eacute;s &agrave; l&rsquo;achat ou &agrave; l&rsquo;utilisation de biens, de services, de ressources, de contenu, ou de toute autre transaction effectu&eacute;e en rapport avec ces sites web de tiers. Veuillez lire attentivement les politiques et pratiques des tierces parties et assurez-vous de bien les comprendre avant de vous engager dans toute transaction. Les plaintes, r&eacute;clamations, pr&eacute;occupations, ou questions concernant les produits de ces tiers doivent &ecirc;tre soumises &agrave; ces m&ecirc;mes tiers, notre service client vous proposera un accompagnement raisonnable </p>
          <p>Les prix de nos produits peuvent &ecirc;tre modifi&eacute;s sans pr&eacute;avis.</p>
          <p>Nous nous r&eacute;servons le droit &agrave; tout moment de modifier ou d&rsquo;interrompre le Service (ainsi que toute partie ou tout contenu du Service) sans pr&eacute;avis et en tout temps.</p>
          <p>Nous ne serons pas responsables envers vous ou toute autre tierce partie de toute modification de prix, suspension ou interruption du Service.</p>
          <h2><strong>12 &ndash; ATTENTION, UNE M&Eacute;T&Eacute;ORITE !!!!!! <span role="img">💥</span></strong></h2>
          <p>Tout &eacute;v&eacute;nement ind&eacute;pendant de la volont&eacute; des parties et qu&rsquo;il est impossible raisonnablement de pr&eacute;voir et de surmonter sera consid&eacute;r&eacute;e comme un &eacute;v&eacute;nement de force majeure conform&eacute;ment aux dispositions de l&rsquo;article 1218 du Code civil.</p>
          <h2><strong>13 &ndash;C&rsquo;est tout, pour le moment&hellip; Enfin il reste encore les lois applicables <span role="img">😅</span></strong></h2>
          <p>Ces Conditions G&eacute;n&eacute;rales de Vente et d&rsquo;Utilisation, ainsi que tout autre accord s&eacute;par&eacute; par le biais duquel nous vous fournissons des Services seront r&eacute;gis et interpr&eacute;t&eacute;s en vertu des lois en vigueur &agrave; 28 rue du chemin vert, Paris ,75011, France.</p>
          <p>Les pr&eacute;sentes CGVU ainsi que l&rsquo;ensemble des informations contractuelles mentionn&eacute;es sur le Site sont r&eacute;dig&eacute;es en langue fran&ccedil;aise et soumises &agrave; la loi fran&ccedil;aise.</p>
          <p>En cas de diff&eacute;rend ou litige entre les parties, celles-ci s&rsquo;efforceront, de bonne foi, de parvenir &agrave; un accord amiable.</p>
          <p>Si aucune solution amiable n&rsquo;&eacute;tait trouv&eacute;e les tribunaux de Paris seront comp&eacute;tents pour conna&icirc;tre du litige ou de la difficult&eacute;.</p>
          <p>Ce fut une longue aventure pour en arriver l&agrave;, nous en avons s&ucirc;rement perdu en route, si vous nous lisez c&rsquo;est que vous avez rempli votre mission, nous sommes fiers de vous ! Et nous tenons &agrave; vous r&eacute;compenser ! Envoyez-nous un mail avec le code : &ldquo;#JevousaimeJeledis&rdquo;, une surprise vous attend <span role="img">😇</span> !</p>
        </article >
      )
    default:
      return (
        <article>
          <h2><strong>CGVU CYCONIA</strong></h2>
          <p><strong>1 Janvier 2019&nbsp;</strong></p>
          <p>Version 1.0.0.7</p>
          <p><strong>Cyonia, c&rsquo;est Grandiose, Unique et Vanill&eacute; (on a pas trouv&eacute; mieux d&eacute;sol&eacute;&hellip;)</strong></p>
          <h2><strong>Sommaire :</strong></h2>
          <h3><strong> Pr&eacute;ambule</strong></h3>
          <h3><strong>1) Objet</strong></h3>
          <h3><strong>2) Conditions d&rsquo;inscription</strong></h3>
          <h3><strong>3) Signature &eacute;lectronique</strong></h3>
          <h3><strong>4) Condition de paiement</strong></h3>
          <h3><strong>5) D&eacute;lai d&rsquo;annulation</strong></h3>
          <h3><strong>6) Obligation de l&rsquo;entreprise</strong></h3>
          <h3><strong>7) Obligation de l&rsquo;utilisateur</strong></h3>
          <h3><strong>8) Droit de r&eacute;tractation</strong></h3>
          <h3><strong>9) Donn&eacute;es personnelles</strong></h3>
          <h3><strong>10) Droit de d&eacute;sabonnement</strong></h3>
          <h3><strong>11) Responsabilit&eacute;s</strong></h3>
          <h3><strong>12) Forces majeures</strong></h3>
          <h3><strong>13) Lois applicables</strong></h3>
          <p>Cyconia, est une solution logiciel, d&rsquo;intelligence artificielle, qui reproduit le comportement d&rsquo;un assistant au travail. Il s&rsquo;agit de permettre au collaborateur de gagner du temps en rendant disponible tous les services et l&rsquo;information dont il a besoin au travail : les essentiels pour sa vie personnelle, les essentiels pour sa vie professionnelle.</p>
          <p> </p>
          <h2><strong>Dans ma PR&Eacute;AM-BULLE (oui, c&rsquo;est tr&egrave;s recherch&eacute; <span role="img">😎</span>) :</strong></h2>
          <p>Toute utilisation de la solution Cyconia implique l&rsquo;acceptation sans r&eacute;serve des pr&eacute;sentes Conditions G&eacute;n&eacute;rales par le Client.</p>
          <p>Ce site web est exploit&eacute; par Cyconia . Sur ce site, les termes &laquo;&nbsp;nous&nbsp;&raquo;, &laquo;&nbsp;notre&nbsp;&raquo; et &laquo;&nbsp;nos&nbsp;&raquo; &ldquo;La Soci&eacute;t&eacute;&rdquo; font r&eacute;f&eacute;rence &agrave; Cyconia . Cyconia propose ce site web, y compris toutes les informations, tous les outils et tous les services qui y sont disponibles pour vous, l&rsquo;utilisateur, sous r&eacute;serve de votre acceptation de l&rsquo;ensemble des modalit&eacute;s, conditions, politiques et avis &eacute;nonc&eacute;s ici.</p>
          <p>Les Conditions G&eacute;n&eacute;rales sont susceptibles d&rsquo;&ecirc;tre modifi&eacute;es &agrave; tout moment par la soci&eacute;t&eacute; Cyconia. Un num&eacute;ro de version et une date de mise &agrave; jour sont indiqu&eacute;s conform&eacute;ment &agrave; la loi en vigueur.</p>
          <p>EXPRESS&rsquo;ING ayant pour marque commerciale Cyconia, est une soci&eacute;t&eacute; par actions simplifi&eacute;e au capital de 41110 euros, inscrite au RCS de Paris sous le num&eacute;ro 812375657.</p>
          <p>Les pr&eacute;sentes Conditions g&eacute;n&eacute;rales de vente et d&rsquo;utilisation (CGVU) entrent en vigueur &agrave; leur date de mise en ligne et s&rsquo;appliquent d&egrave;s la premi&egrave;re utilisation du Site. Elles constituent un accord ayant force obligatoire entre vous et Cyconia. Ces CGVU sont accessibles &agrave; tout moment sur le Site et pr&eacute;vaudront, le cas &eacute;ch&eacute;ant, sur toute autre version ou tout autre document contradictoire.</p>
          <h2><strong>1 &ndash; Pas d&rsquo;Objet chez nous, on propose des services <span role="img">😏</span></strong></h2>
          <p>L&rsquo;objet du Service est, sans &ecirc;tre limitatif, de permettre &agrave; l&rsquo;Utilisateur par le biais d&rsquo;un moyen de communication &agrave; distance (logiciel Cyconia) de solliciter la Soci&eacute;t&eacute; aux fins d&rsquo;effectuer la r&eacute;alisation d&rsquo;une prestation de service, de demander une aide collaborative, de diffuser une information ou d&rsquo;organiser des r&eacute;unions.</p>
          <p>A cet effet la Soci&eacute;t&eacute; tient le r&ocirc;le d&rsquo;interm&eacute;diaire transparent entre l&rsquo;Utilisateur et l&rsquo;Entreprise et/ou le Commer&ccedil;ant.</p>
          <p>En cas de probl&egrave;me tenant &agrave; la r&eacute;alisation d&rsquo;une prestation non conforme &agrave; la commande, l&rsquo;Utilisateur devra prendre contact avec le Commer&ccedil;ant concern&eacute;, sans pr&eacute;judice des droits de r&eacute;tractation dont il dispose conform&eacute;ment &agrave; la l&eacute;gislation en vigueur, un accompagnement sera propos&eacute; dans le cadre de notre service client.</p>
          <h2><strong>2 &ndash; Pour en profiter, il faut d&rsquo;abord s&rsquo;inscrire <span role="img">😩</span></strong></h2>
          <p>2.1. Avant d&rsquo;effectuer une commande, l&rsquo;Utilisateur doit pr&eacute;alablement effectuer son inscription sur le site https://cyconia.io, au moyen des formulaires qui y figurent et renseigner ses informations personnelles.</p>
          <p>L&rsquo;acc&egrave;s et l&rsquo;utilisation du Service Cyconia implique le remplissage d&rsquo;un formulaire d&rsquo;inscription, ainsi que la cr&eacute;ation d&rsquo;un compte utilisateur, permettant &agrave; l&rsquo;abonn&eacute; de s&rsquo;authentifier.</p>
          <p>Un compte correspond &agrave; un identifiant, associ&eacute; &agrave; un mot de passe, qui sont tous deux propres et strictement personnels &agrave; l&rsquo;utilisateur. Ils permettent &agrave; tout utilisateur, via une interface d&eacute;di&eacute;e, de souscrire/modifier ses informations personnelles.</p>
          <p>Une fois le compte cr&eacute;&eacute; Cyconia adressera un accus&eacute; de r&eacute;ception &agrave; l&rsquo;adresse email renseign&eacute;e dans le formulaire d&rsquo;inscription, &agrave; titre de confirmation de l&rsquo;acceptation de la demande d&rsquo;inscription.</p>
          <p>En cas de non r&eacute;ception dudit email adresser un email sur : onvouaimeonvousaide@cyconia.io</p>
          <p>Pour que son inscription soit valid&eacute;e, l&rsquo;Utilisateur devra accepter, en cliquant &agrave; l&rsquo;endroit indiqu&eacute;, les pr&eacute;sentes CGVU.</p>
          <p>2.2. La commande de l&rsquo;Utilisateur n&rsquo;est valid&eacute;e et sa carte bancaire d&eacute;bit&eacute;e, qu&rsquo;apr&egrave;s avoir donn&eacute; son accord sur le prix correspondant &agrave; la prestation demand&eacute;e.</p>
          <p>Toute contestation sur ce point interviendra dans le cadre d&rsquo;un &eacute;ventuel &eacute;change et des garanties ci-dessous mentionn&eacute;es.</p>
          <p>Dans certaines hypoth&egrave;ses, notamment d&eacute;faut de paiement, adresse erron&eacute;e ou autre probl&egrave;me sur le compte de l&rsquo;Utilisateur, la Soci&eacute;t&eacute; se r&eacute;serve le droit de bloquer la commande de l&rsquo;Utilisateur jusqu&rsquo;&agrave; la compl&egrave;te r&eacute;solution du probl&egrave;me.</p>
          <p>En cas d&rsquo;impossibilit&eacute; d&rsquo;ex&eacute;cuter une prestation, l&rsquo;Utilisateur en sera inform&eacute; par email.</p>
          <p>L&rsquo;Utilisateur est inform&eacute; que les services sont offerts &agrave; la vente dans la limite de leur disponibilit&eacute;.</p>
          <p>L&rsquo;indisponibilit&eacute; d&rsquo;un service ne pourra donner lieu &agrave; aucune indemnisation.</p>
          <p>De mani&egrave;re g&eacute;n&eacute;rale, l&rsquo;Utilisateur accepte et reconna&icirc;t que l&rsquo;effectivit&eacute; de la prestation n&rsquo;est pas garantie par le site.</p>
          <p>Si la prestation ne peut &ecirc;tre assur&eacute;e, l&rsquo;Utilisateur en est inform&eacute; concomitamment &agrave; sa demande.</p>
          <p>Consid&eacute;rant le grand nombre de demandes formul&eacute;es aupr&egrave;s du Site, si la prestation ne peut &ecirc;tre r&eacute;alis&eacute;e dans l&rsquo;imm&eacute;diat ou le bien livr&eacute;, l&rsquo;Utilisateur est plac&eacute; en liste d&rsquo;attente et servi en fonction :</p>
          <ul>
            <li> Du niveau de difficult&eacute; de la commande,</li>
            <li> De la disponibilit&eacute; des prestations command&eacute;s.</li>
            <li> </li>
          </ul>
          <p>L&rsquo;Utilisateur est inform&eacute; et accepte que ses donn&eacute;es &agrave; caract&egrave;re personnel &ndash; nom, pr&eacute;nom, e-mail, t&eacute;l&eacute;phone, civilit&eacute;, adresse postale &ndash; soient communiqu&eacute;es au Commer&ccedil;ant.</p>
          <p>Pour toute question relative au suivi d&rsquo;une prestation, le client doit se connecter sur son interface de suivi ou appeler le 06 19 15 32 89 (co&ucirc;t d&rsquo;un appel local) ou envoyer ou envoyer un email &agrave; l&rsquo;adresse onvousaimeonvousaide@cyconia.io</p>
          <h2><strong>3 &ndash; On peut avoir une autographe ? </strong></h2>
          <p>La fourniture en ligne du num&eacute;ro de carte bancaire de l&rsquo;Utilisateur et la validation finale de la commande par mail, vaudront preuve de l&rsquo;accord entre les parties conform&eacute;ment aux dispositions de la Loi n&deg;2000-230 du 13 mars 2000 et vaudront :</p>
          <ul>
            <li> Exigibilit&eacute; des sommes dues au titre du bon de commande ;</li>
            <li> Signature et acception expresse de toutes les op&eacute;rations effectu&eacute;es ;</li>
          </ul>
          <p>En cas d&rsquo;utilisation frauduleuse de la carte bancaire, l&rsquo;Utilisateur est invit&eacute;, d&egrave;s le constat de cette utilisation, &agrave; contacter le 06 19 15 32 89 par t&eacute;l&eacute;phone ou par email onvousaimeonvousaide@cyconia.io</p>
          <h2><strong>4 &ndash; D&eacute;sol&eacute;, la maison ne fait pas cr&eacute;dit <span role="img" aria-label="carte de credit">💳</span></strong></h2>
          <p>&mdash;&ndash;La Soci&eacute;t&eacute; utilise le service de paiement s&eacute;curis&eacute; fourni par la soci&eacute;t&eacute; Stripe (<a href="https://stripe.com/fr/privacy)">https://stripe.com/fr/privacy)</a>, dont le si&egrave;ge social est situ&eacute; au 3180 18HT Street, Suite 100, San Francisco, CA 94110, USA.</p>
          <p>Les donn&eacute;es confidentielles telles que le num&eacute;ro de carte bancaire &agrave; 16 chiffres, la date d&rsquo;expiration ainsi que le code CVV, sont directement transmises crypt&eacute;es sur le serveur de l&rsquo;&eacute;tablissement bancaire.</p>
          <p>La Soci&eacute;t&eacute; n&rsquo;a pas acc&egrave;s &agrave; ces donn&eacute;es. &mdash;&mdash;</p>
          <p>La confirmation de la commande se mat&eacute;rialise par l&rsquo;acceptation du devis.</p>
          <p><strong>4.1.&nbsp;</strong>Toute demande de facture doit &ecirc;tre adress&eacute;e directement &agrave; la Soci&eacute;t&eacute;.</p>
          <p>Afin d&rsquo;assurer la s&eacute;curit&eacute; des transactions et de r&eacute;pondre au souci de pr&eacute;vention des fraudes en mati&egrave;re de vente en ligne, la Soci&eacute;t&eacute; peut &ecirc;tre conduite &agrave; effectuer des contr&ocirc;les sur les commandes.</p>
          <p>La Soci&eacute;t&eacute; se r&eacute;serve le droit de suspendre toute gestion de commande en cas de refus d&rsquo;autorisation de paiement de la part des organismes officiellement accr&eacute;dit&eacute;s ou en cas de non-paiement.</p>
          <p>La Soci&eacute;t&eacute; a mis en place une proc&eacute;dure de v&eacute;rification des commandes destin&eacute;e &agrave; assurer qu&rsquo;aucune personne n&rsquo;utilise les coordonn&eacute;es bancaires d&rsquo;une autre personne &agrave; son insu.</p>
          <p>Dans le cadre de cette v&eacute;rification, il pourra &ecirc;tre demand&eacute; &agrave; l&rsquo;Utilisateur d&rsquo;adresser par fax ou e-mail &agrave; la Soci&eacute;t&eacute; une copie d&rsquo;une pi&egrave;ce d&rsquo;identit&eacute; ainsi qu&rsquo;un justificatif de domicile.</p>
          <p>La commande ne sera alors valid&eacute;e qu&rsquo;apr&egrave;s r&eacute;ception et v&eacute;rification par la Soci&eacute;t&eacute; des pi&egrave;ces envoy&eacute;es.</p>
          <p><strong>4.2.</strong> Les registres informatis&eacute;s, conserv&eacute;s dans les syst&egrave;mes informatiques de la Soci&eacute;t&eacute; dans des conditions raisonnables de s&eacute;curit&eacute;, seront consid&eacute;r&eacute;s comme les preuves de communications, des commandes et des paiements intervenus entre les Parties.</p>
          <p>L&rsquo;archivage des bons de commande et des factures est effectu&eacute; sur un support fiable et durable pouvant &ecirc;tre produit &agrave; titre de preuve.</p>
          <h2><strong>5 &ndash; Urgence de derni&egrave;re minute ? Vous pouvez encore annuler, enfin sous certaines conditions <span role="img">😅</span></strong></h2>
          <p>Pour la r&eacute;alisation d&rsquo;une prestation :</p>
          <p>La prestation est r&eacute;alis&eacute;e &agrave; l&rsquo;adresse indiqu&eacute;e par l&rsquo;Utilisateur sur le bon de commande, l&rsquo;Utilisateur devra veiller &agrave; son exactitude.</p>
          <p>Tout d&eacute;placement en pure perte du prestataire &agrave; cause d&rsquo;une adresse erron&eacute;e ou incompl&egrave;te sera factur&eacute; &agrave; l&rsquo;Utilisateur.</p>
          <p>L&rsquo;Utilisateur peut, &agrave; sa demande, obtenir l&rsquo;envoi d&rsquo;une facture &agrave; l&rsquo;adresse de facturation et non &agrave; l&rsquo;adresse de livraison, en validant l&rsquo;option pr&eacute;vue &agrave; cet effet sur le bon de commande.</p>
          <p>L&rsquo;Utilisateur veillera &agrave; donner acc&egrave;s &agrave; la Soci&eacute;t&eacute; notamment s&rsquo;il n&rsquo;est pas personnellement pr&eacute;sent le jour de la r&eacute;alisation de la prestation.</p>
          <p>La fin de la prestation donnera lieu &agrave; un document de fin d&rsquo;intervention.</p>
          <p>L&rsquo;Utilisateur doit indiquer sur ce document et sous forme de r&eacute;serves manuscrites accompagn&eacute;es de sa signature toute anomalie concernant la prestation ou les conditions de sa r&eacute;alisation.</p>
          <p>Cette v&eacute;rification est consid&eacute;r&eacute;e comme effectu&eacute;e d&egrave;s lors que l&rsquo;Utilisateur ou une personne autoris&eacute;e par lui, a sign&eacute; le document de fin d&rsquo;intervention.</p>
          <h2><strong>6 &ndash; Plusieurs obligations pour un credo : &agrave; votre service 😊</strong></h2>
          <p><strong>6.1</strong> La Soci&eacute;t&eacute; s&rsquo;engage &agrave; apporter tout le soin et la diligence n&eacute;cessaires &agrave; la fourniture d&rsquo;un service de qualit&eacute;.</p>
          <p><strong>6.2.</strong> La Soci&eacute;t&eacute; ne r&eacute;pond cependant que d&rsquo;une obligation de moyens au terme de laquelle les prestations ou livraisons de biens seront ex&eacute;cut&eacute;es dans le strict respect des r&egrave;gles professionnelles en usage, ainsi, le cas &eacute;ch&eacute;ant, que conform&eacute;ment aux conditions de la commande. La Soci&eacute;t&eacute; ne serait &ecirc;tre responsable des agissements des Commer&ccedil;ants.</p>
          <p><strong>6.3.</strong> La responsabilit&eacute; civile et p&eacute;nale de la Soci&eacute;t&eacute; ne sera pas engag&eacute;e en cas de faute imputable &agrave; l&rsquo;Utilisateur. Constituent des fautes, sans &ecirc;tre limitatifs, les cas suivants :</p>
          <ul>
            <li> La mauvaise utilisation du service</li>
            <li> La divulgation ou utilisation illicite du compte cr&eacute;&eacute;</li>
          </ul>
          <p><strong>6.4.</strong> L&rsquo;Utilisateur accepte (I) qu&rsquo;il est techniquement impossible d&rsquo;offrir un Service exempt de tout d&eacute;faut et que la Soci&eacute;t&eacute; ne peut pas s&rsquo;engager &agrave; cet effet, (II) que des d&eacute;fauts peuvent rendre le Service temporairement indisponible, et que (III) le fonctionnement du site peut &ecirc;tre affect&eacute; par des &eacute;v&eacute;nements et/ou des probl&egrave;mes sur lesquels la Soci&eacute;t&eacute; n&rsquo;a aucun contr&ocirc;le.</p>
          <p>La Soci&eacute;t&eacute; peut, &agrave; tout moment, modifier ou interrompre, temporairement ou d&eacute;finitivement, tout ou partie du Site pour effectuer la maintenance et/ou apporter des am&eacute;liorations et/ou des modifications au Site. La Soci&eacute;t&eacute; d&eacute;cline toute responsabilit&eacute; pour toute modification, suspension ou interruption du site.</p>
          <h2><strong>7 &ndash; Vous avez le droit de garder le silence. Tout ce que vous direz pourra &ecirc;tre et sera utilis&eacute; contre vous devant une cour de justice&hellip;. ⚖️ ! </strong></h2>
          <p><strong>7.1</strong> L&rsquo;Utilisateur est seul responsable de l&rsquo;utilisation du Service, des informations transmises, de leur exploitation et de leur mise &agrave; jour. L&rsquo;Utilisateur s&rsquo;oblige &agrave; veiller &agrave; ce que des tiers n&rsquo;utilisent le service Cyconia &agrave; partir de leur portable, ou &agrave; contacter d&egrave;s que possible les op&eacute;rateurs du Site via la rubrique &laquo; Contact &raquo; en cas de perte ou de vol de leur portable.</p>
          <p>L&rsquo;Utilisateur est seul responsable de l&rsquo;utilisation qui est faite de son compte. Tout acc&egrave;s ou utilisation des services effectu&eacute; &agrave; partir du compte d&rsquo;un Utilisateur sera r&eacute;put&eacute; avoir &eacute;t&eacute; effectu&eacute; par ce dernier.</p>
          <p>L&rsquo;Utilisateur s&rsquo;engage notamment :</p>
          <ul>
            <li> A fournir des informations sinc&egrave;res et v&eacute;ritables ;</li>
            <li> A adresser &agrave; la Soci&eacute;t&eacute; toutes les informations et les documents requis en vue de l&rsquo;ex&eacute;cution du Service</li>
          </ul>
          <p><strong>7.2</strong> L&rsquo;Utilisateur supporte seul les cons&eacute;quences d&rsquo;une utilisation frauduleuse de son compte.</p>
          <p><strong>7.3</strong> Il appartient &agrave; l&rsquo;Utilisateur de s&rsquo;acquitter du montant correspondant au montant de sa commande, aupr&egrave;s de la Soci&eacute;t&eacute;.</p>
          <p>A d&eacute;faut la Soci&eacute;t&eacute; se r&eacute;serve le droit de suspendre sans pr&eacute;avis le Service.</p>
          <p><strong>7.4</strong>. La Soci&eacute;t&eacute; se r&eacute;serve la possibilit&eacute; d&rsquo;exercer des contr&ocirc;les sur la conformit&eacute; de l&rsquo;utilisation par l&rsquo;Utilisateur du service &agrave; ces dispositions.</p>
          <p><strong>7.5</strong>. L&rsquo;Utilisateur s&rsquo;engage &agrave; ne divulguer aucune information ayant trait au service.</p>
          <p>Ces informations sont confidentielles et constituent, &agrave; ce titre, des secrets commerciaux.</p>
          <h2><strong>8 &ndash; Droit de r&eacute;tractation : Tu veux ou tu veux pas Tu veux c&rsquo;est bien Si tu veux pas tant pis Si tu veux pas J&rsquo;en f&rsquo;rai pas une maladie 🎼</strong></h2>
          <p><strong>8.1</strong> Conform&eacute;ment aux dispositions du Code de la consommation, l&rsquo;Utilisateur dispose d&rsquo;un d&eacute;lai de 14 (quatorze) jours ouvrables &agrave; compter de la date de validation de la commande par email.</p>
          <p>Le consommateur qui exerce son droit de r&eacute;tractation n&rsquo;a pas &agrave; motiver sa d&eacute;cision (article L. 121-21 du code de la consommation)</p>
          <p>Conform&eacute;ment &agrave; l&rsquo;article L121-21-8 du Code de la consommation, ce droit de r&eacute;tractation est exclu pour :</p>
          <ul>
            <li> Les contrats de services dont l&rsquo;ex&eacute;cution a commenc&eacute;, avec l&rsquo;accord de l&rsquo;Utilisateur, avant la fin du d&eacute;lai de r&eacute;tractation ;</li>
            <li> Les contrats de biens ou de services dont le prix d&eacute;pend des taux du march&eacute; financier ;</li>
            <li> Les contrats de biens qui, par leur nature, ne peuvent &ecirc;tre r&eacute;exp&eacute;di&eacute;s ou peuvent se d&eacute;t&eacute;riorer ou se p&eacute;rimer rapidement ;</li>
          </ul>
          <p>La r&eacute;tractation doit se faire par email &agrave; l&rsquo;adresse onvousaimeonvousaide@cyconia.io. </p>
          <p>Ce droit de r&eacute;tractation s&rsquo;exerce sans p&eacute;nalit&eacute;.</p>
          <h2><strong> 9 &ndash; Vos donn&eacute;es sont en s&eacute;curit&eacute;, nous n&rsquo;avons rien vu, rien entendu <span role="img">🙈</span> <span role="img">🙉</span> !</strong></h2>
          <p><strong>9.1</strong> Toutes les informations de l&rsquo;Utilisateur ne sont utilis&eacute;es que dans le cadre de sa relation commerciale avec la Soci&eacute;t&eacute;.</p>
          <p>Elles proviennent de l&rsquo;enregistrement volontaire d&rsquo;une adresse e-mail ou d&rsquo;une adresse postale lors d&rsquo;une commande.</p>
          <p>Ces informations ne sont jamais revendues.</p>
          <p>La Soci&eacute;t&eacute; informe l&rsquo;Utilisateur qu&rsquo;elle pourra communiquer ses donn&eacute;es pour assurer la livraison des commandes, certains aspects du service apr&egrave;s-vente, et pour r&eacute;aliser des enqu&ecirc;tes de satisfaction.</p>
          <p><strong>9.2</strong> La Soci&eacute;t&eacute; s&rsquo;engage &agrave; respecter la confidentialit&eacute; de vos donn&eacute;es personnelles et &agrave; les traiter dans le respect de la loi Informatique et Libert&eacute;s du 6 janvier 1978 et de la r&eacute;glementation g&eacute;n&eacute;rale protection des donn&eacute;es. Une d&eacute;claration a &eacute;t&eacute; faite aupr&egrave;s de la CNIL (2022038 v 0).</p>
          <ul>
            <li> L&rsquo;Utilisateur peut &agrave; tout moment demander la suppression de ses informations &agrave; l&rsquo;adresse &eacute;lectronique suivante : onvousaimeonvousaide@cyconia.io</li>
          </ul>
          <h2><strong>10 &ndash; Et si vous voulez partir, cela nous rendra triste, mais c&rsquo;est votre droit&hellip; <span role="img">😔</span></strong></h2>
          <p>10.1 Fermeture du compte &agrave; votre initiative</p>
          <p>Vous pouvez fermer d&eacute;finitivement votre compte et vous d&eacute;sinscrire.</p>
          <ul>
            <li> Depuis votre espace membre sur https://xxx.cyconia.io</li>
            <li> En adressant un courriel &agrave; onvousaimeonvousaide@cyconia.io</li>
          </ul>
          <p>La fermeture de votre compte entrera en vigueur dans un d&eacute;lai raisonnable apr&egrave;s la r&eacute;ception de votre demande. Votre abonnement &eacute;tant achet&eacute; par votre employeur, vous pouvez vous r&eacute;inscrire au moment souhait&eacute;. </p>
          <p><strong>10.2</strong> Fermeture du compte &agrave; l&rsquo;initiative de la Soci&eacute;t&eacute;</p>
          <p>Le manque au r&egrave;glement de votre abonnement par votre employeur. </p>
          <p>En cas de non-respect des pr&eacute;sentes CGVU, la Soci&eacute;t&eacute; aura la facult&eacute; de suspendre ou clore votre compte &agrave; tout moment, de plein droit et sans formalit&eacute; judiciaire, apr&egrave;s l&rsquo;envoi d&rsquo;une mise en demeure via courriel rest&eacute;e sans effet pendant une dur&eacute;e de cinq (5) jours calendaires &agrave; compter de sa r&eacute;ception.</p>
          <p><strong>10.3</strong> Cons&eacute;quences de la fermeture du compte</p>
          <p>Toute fermeture de votre compte entra&icirc;ne la suppression d&eacute;finitive dans un d&eacute;lai raisonnable des informations communiqu&eacute;es &agrave; la Soci&eacute;t&eacute; lors de l&rsquo;ouverture ou de la mise &agrave; jour de votre compte, des documents et autres &eacute;l&eacute;ments t&eacute;l&eacute;vers&eacute;s dans votre espace ainsi que, le cas &eacute;ch&eacute;ant, de tout document ou contenu que vous auriez publi&eacute;, t&eacute;l&eacute;charg&eacute; ou mis &agrave; disposition sur la Plateforme.</p>
          <p>Nonobstant ce qui pr&eacute;c&egrave;de, l&rsquo;&Eacute;diteur se r&eacute;serve le droit de conserver les Documents, informations et autres &eacute;l&eacute;ments susmentionn&eacute;s sur ses serveurs pendant un d&eacute;lai raisonnable.</p>
          <h2><strong>11 &ndash; Certes on perd 1-0, mais c&rsquo;est la faute du gardien <span role="img">🙄</span></strong></h2>
          <p>Certains contenus, produits et services disponibles par le biais de notre Service pourraient inclure des &eacute;l&eacute;ments provenant de tierces parties.</p>
          <p>Les liens provenant de tierces parties sur ce site pourraient vous rediriger vers des sites web de tiers qui ne sont pas affili&eacute;s &agrave; nous. Nous ne sommes pas tenus d&rsquo;examiner ou d&rsquo;&eacute;valuer le contenu ou l&rsquo;exactitude de ces sites, et nous ne garantissons pas et n&rsquo;assumons aucune responsabilit&eacute; quant &agrave; tout contenu, site web, produit, service ou autre &eacute;l&eacute;ment accessible sur ou depuis ces sites tiers.</p>
          <p>Nous ne sommes pas responsables des pr&eacute;judices ou dommages li&eacute;s &agrave; l&rsquo;achat ou &agrave; l&rsquo;utilisation de biens, de services, de ressources, de contenu, ou de toute autre transaction effectu&eacute;e en rapport avec ces sites web de tiers. Veuillez lire attentivement les politiques et pratiques des tierces parties et assurez-vous de bien les comprendre avant de vous engager dans toute transaction. Les plaintes, r&eacute;clamations, pr&eacute;occupations, ou questions concernant les produits de ces tiers doivent &ecirc;tre soumises &agrave; ces m&ecirc;mes tiers, notre service client vous proposera un accompagnement raisonnable </p>
          <p>Les prix de nos produits peuvent &ecirc;tre modifi&eacute;s sans pr&eacute;avis.</p>
          <p>Nous nous r&eacute;servons le droit &agrave; tout moment de modifier ou d&rsquo;interrompre le Service (ainsi que toute partie ou tout contenu du Service) sans pr&eacute;avis et en tout temps.</p>
          <p>Nous ne serons pas responsables envers vous ou toute autre tierce partie de toute modification de prix, suspension ou interruption du Service.</p>
          <h2><strong>12 &ndash; ATTENTION, UNE M&Eacute;T&Eacute;ORITE !!!!!! <span role="img">💥</span></strong></h2>
          <p>Tout &eacute;v&eacute;nement ind&eacute;pendant de la volont&eacute; des parties et qu&rsquo;il est impossible raisonnablement de pr&eacute;voir et de surmonter sera consid&eacute;r&eacute;e comme un &eacute;v&eacute;nement de force majeure conform&eacute;ment aux dispositions de l&rsquo;article 1218 du Code civil.</p>
          <h2><strong>13 &ndash;C&rsquo;est tout, pour le moment&hellip; Enfin il reste encore les lois applicables <span role="img">😅</span></strong></h2>
          <p>Ces Conditions G&eacute;n&eacute;rales de Vente et d&rsquo;Utilisation, ainsi que tout autre accord s&eacute;par&eacute; par le biais duquel nous vous fournissons des Services seront r&eacute;gis et interpr&eacute;t&eacute;s en vertu des lois en vigueur &agrave; 28 rue du chemin vert, Paris ,75011, France.</p>
          <p>Les pr&eacute;sentes CGVU ainsi que l&rsquo;ensemble des informations contractuelles mentionn&eacute;es sur le Site sont r&eacute;dig&eacute;es en langue fran&ccedil;aise et soumises &agrave; la loi fran&ccedil;aise.</p>
          <p>En cas de diff&eacute;rend ou litige entre les parties, celles-ci s&rsquo;efforceront, de bonne foi, de parvenir &agrave; un accord amiable.</p>
          <p>Si aucune solution amiable n&rsquo;&eacute;tait trouv&eacute;e les tribunaux de Paris seront comp&eacute;tents pour conna&icirc;tre du litige ou de la difficult&eacute;.</p>
          <p>Ce fut une longue aventure pour en arriver l&agrave;, nous en avons s&ucirc;rement perdu en route, si vous nous lisez c&rsquo;est que vous avez rempli votre mission, nous sommes fiers de vous ! Et nous tenons &agrave; vous r&eacute;compenser ! Envoyez-nous un mail avec le code : &ldquo;#JevousaimeJeledis&rdquo;, une surprise vous attend <span role="img">😇</span> !</p>
        </article>
      )
  }
}

const CGUModal = ({
  onClose,
  subDomain = ''
}) => {
  return (
    <CustomDialog
      open={true}
      onClose={onClose}
    >
      <GenericDialogContainer onClose={onClose}>
        {CGUcontent(subDomain)}
      </GenericDialogContainer>
    </CustomDialog>
  );
};

export default CGUModal;
