import React from 'react';
import { useRouteMatch, withRouter, Link } from 'react-router-dom';
import { generatePath } from "react-router";
import moment from 'moment';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

// Icons
import SeeIcon from '../../../../atoms/icons/see';
import NotSeeIcon from '../../../../atoms/icons/notSee';
import CompanyIcon from '../../../../atoms/icons/company';
import EditInfoIcon from '../../../../atoms/icons/editInfo';
import FeedbacksIcon from '../../../../atoms/icons/feedbacks';
import CheckPlainIcon from '../../../../atoms/icons/checkPlain';

//import component 
import CrossElement from '../CrossElement'

// Import Style
import {
  StyledSubTableCell,
  SeeIconWrapper,
  NotSeeIconWrapper,
  CompanyIconWrapper,
  EditInfoIconWrapper,
  FeedbacksIconWrapper,
  DislikeIconWrapper,
  TableBodyRow,
  TableBodyRowDivider,
  EiStatusOnOff,
  SeeText,
  NotSeeText,
  TooltipCustom,
  BoxCustom,
  CrooElemDiv,
  DivParaTitle
} from './style';

const TableContent = ({
  InfosListId,
  sub,
  i,
}) => {
  const { path } = useRouteMatch();

  return (
    <Table key={i} aria-label="a dense table">
      <TableBody>
        {sub && sub.informationElements ?
          sub.informationElements.map((row, i) => (
            <TableBodyRow
              id={row.id}
              onClick={(e) => localStorage.setItem("currElem", row.id)}
              key={i}
              style={{ cursor: "pointer", textDecoration: "none" }}
            >
              {row.isShared ?
                <StyledSubTableCell align="left">
                  <Link className="tableCellLink" to={`${generatePath(path, { dmId: InfosListId })}?opendrawerbyid=` + row.id + `&title=${sub.title}`}>
                    <CrooElemDiv>
                      <DivParaTitle>
                        <p>{row.title}</p>
                      </DivParaTitle>
                      <TooltipCustom className="tooltipCustom" title="Cet élément est partagé par plusieurs thématiques : Cyconia peut s’y référer comme réponse pour plusieurs questions différentes. A noter, si vous modifiez la réponse d’un élément partagé, elle sera modifiée pour toutes les thématiques à laquelle elle se rattache." >
                        <div>
                          <CrossElement />
                        </div>
                      </TooltipCustom>
                    </CrooElemDiv>
                    <span>
                      {row.description}
                    </span>
                  </Link>
                </StyledSubTableCell> :
                <StyledSubTableCell align="left">
                  <Link className="tableCellLink" to={`${generatePath(path, { dmId: InfosListId })}?opendrawerbyid=` + row.id + `&title=${sub.title}`}>
                    <p>{row.title}</p>
                    <span>
                      {row.description}
                    </span>
                  </Link>
                </StyledSubTableCell>}

              <StyledSubTableCell sub align="right">
                <Link className="tableCellLink" to={`${generatePath(path, { dmId: InfosListId })}?opendrawerbyid=` + row.id}>
                  <TooltipCustom className="tooltipCustom" title="Nombre d'entreprises concernées par le sujet">
                    <BoxCustom>
                      <CompanyIconWrapper>
                        <CompanyIcon color="#BBBBBB" />
                      </CompanyIconWrapper>
                      <span>
                        {row.countCompanySiteResponses}
                      </span>
                    </BoxCustom>
                  </TooltipCustom>
                </Link>
              </StyledSubTableCell>

              <StyledSubTableCell sub align="right" className="feedBackWrapper">
                <Link className="tableCellLink" to={`${generatePath(path, { dmId: InfosListId })}?opendrawerbyid=` + row.id}>
                  <TooltipCustom className="tooltipCustom" title="Nombre de remarques collaborateur à traiter">
                    <BoxCustom>
                      <FeedbacksIconWrapper>
                        <FeedbacksIcon color="#BBBBBB" />
                      </FeedbacksIconWrapper>
                      <span>
                        {row.feedbacks && row.feedbacks !== "" && row.feedbacks > 0
                          ? row.feedbacks + " remarque(s)" : " - "}
                      </span>
                    </BoxCustom>
                  </TooltipCustom>
                </Link>
              </StyledSubTableCell>

              <StyledSubTableCell sub align="right">
                <Link className="tableCellLink" to={`${generatePath(path, { dmId: InfosListId })}?opendrawerbyid=` + row.id}>
                  <TooltipCustom className="tooltipCustom" title="Date de la dernière validation du sujet">
                    <BoxCustom>
                      <DislikeIconWrapper>
                        <CheckPlainIcon color="#BBBBBB" />
                      </DislikeIconWrapper>
                      <span>
                        {row.validatedAt && row.validatedAt !== "" ?
                          moment(row.validatedAt).format("DD/MM/YYYY") : " - "}
                      </span>
                    </BoxCustom>
                  </TooltipCustom>
                </Link>
              </StyledSubTableCell>

              <StyledSubTableCell sub align="right">
                <Link className="tableCellLink" to={`${generatePath(path, { dmId: InfosListId })}?opendrawerbyid=` + row.id}>
                  <TooltipCustom className="tooltipCustom" title="Date de la dernière modification du sujet">
                    <BoxCustom>
                      <EditInfoIconWrapper>
                        <EditInfoIcon color="#BBBBBB" />
                      </EditInfoIconWrapper>
                      <span>
                        {row.responseLastUpdate && row.responseLastUpdate !== "" ?
                          moment(row.responseLastUpdate).format("DD/MM/YYYY")
                          : " - "}
                      </span>
                    </BoxCustom>
                  </TooltipCustom>
                </Link>
              </StyledSubTableCell>

              <StyledSubTableCell sub align="right">
                <Link className="tableCellLink" to={`${generatePath(path, { dmId: InfosListId })}?opendrawerbyid=` + row.id}>
                  {row.active ?
                    <EiStatusOnOff>
                      <SeeIconWrapper>
                        <SeeIcon color="#BBBBBB" />
                      </SeeIconWrapper>
                      <SeeText>
                        Activé
                      </SeeText>
                    </EiStatusOnOff>
                    :
                    <EiStatusOnOff>
                      <NotSeeIconWrapper>
                        <NotSeeIcon color="#BBBBBB" />
                      </NotSeeIconWrapper>
                      <NotSeeText>
                        Désactivé
                      </NotSeeText>
                    </EiStatusOnOff>
                  }
                </Link>
              </StyledSubTableCell>
            </TableBodyRow>

          )) : null}
        <TableBodyRowDivider />
      </TableBody>
    </Table>
  )
}

export default withRouter(TableContent);
