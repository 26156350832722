export const actionGenerator = (value) => ({
  REQUEST: `ADMINFRONT/${value}/REQUEST`,
  SUCCESS: `ADMINFRONT/${value}/SUCCESS`,
  FAILURE: `ADMINFRONT/${value}/FAILURE`,
})

export const GET_NEWS_CONTENT = actionGenerator("GET_NEWS_CONTENT");
export const UPDATE_NEWS_CONTENT = actionGenerator("UPDATE_NEWS_CONTENT");
export const DELETE_NEWS_CONTENT = actionGenerator("DELETE_NEWS_CONTENT");
export const GET_NEWS_CONTENT_BY_ID = actionGenerator("GET_NEWS_CONTENT_BY_ID");
export const CLEAR_SELECTED_NEWS_CONTENT = actionGenerator("CLEAR_SELECTED_NEWS_CONTENT");

export const GET_GOOD_DEALS = actionGenerator("GET_GOOD_DEALS");
export const UPDATE_GOOD_DEAL = actionGenerator("UPDATE_GOOD_DEAL");
export const DELETE_GOOD_DEAL = actionGenerator("DELETE_GOOD_DEAL");
export const GET_GOOD_DEAL_BY_ID = actionGenerator("GET_GOOD_DEAL_BY_ID");
export const CLEAR_SELECTED_GOOD_DEAL = actionGenerator("CLEAR_SELECTED_GOOD_DEAL");

// News
export const getNewsContent = (data) => ({
  type: GET_NEWS_CONTENT.REQUEST,
  data,
});

export const getNewsContentSuccess = (data) => ({
  type: GET_NEWS_CONTENT.SUCCESS,
  data,
});

export const getNewsContentError = (error) => ({
  type: GET_NEWS_CONTENT.FAILURE,
  error,
});

export const updateNewsContent = (data) => ({
  type: UPDATE_NEWS_CONTENT.REQUEST,
  data
});

export const updateNewsContentSuccess = (data) => ({
  type: UPDATE_NEWS_CONTENT.SUCCESS,
  data
});

export const updateNewsContentError = (error) => ({
  type: UPDATE_NEWS_CONTENT.FAILURE,
  error
});

export const deleteNewsContent = (data) => ({
  type: DELETE_NEWS_CONTENT.REQUEST,
  data
});

export const deleteNewsContentSuccess = (data) => ({
  type: DELETE_NEWS_CONTENT.SUCCESS,
  data
});

export const deleteNewsContentError = (error) => ({
  type: DELETE_NEWS_CONTENT.FAILURE,
  error,
});

export const getNewsContentById = (data) => ({
  type: GET_NEWS_CONTENT_BY_ID.REQUEST,
  data
});

export const getNewsContentByIdSuccess = (data) => ({
  type: GET_NEWS_CONTENT_BY_ID.SUCCESS,
  data,
});

export const getNewsContentByIdError = (error) => ({
  type: GET_NEWS_CONTENT_BY_ID.FAILURE,
  error,
});

export const clearSelectedNewsContent = () => ({
  type: CLEAR_SELECTED_NEWS_CONTENT.REQUEST,
});

// Good Deal
export const getGoodDeals = (data) => ({
  type: GET_GOOD_DEALS.REQUEST,
  data,
});

export const getGoodDealsSuccess = (data) => ({
  type: GET_GOOD_DEALS.SUCCESS,
  data,
});

export const getGoodDealsError = (error) => ({
  type: GET_GOOD_DEALS.FAILURE,
  error,
});

export const updateGoodDeal = (data) => ({
  type: UPDATE_GOOD_DEAL.REQUEST,
  data
});

export const updateGoodDealSuccess = (data) => ({
  type: UPDATE_GOOD_DEAL.SUCCESS,
  data
});

export const updateGoodDealError = (error) => ({
  type: UPDATE_GOOD_DEAL.FAILURE,
  error
});

export const deleteGoodDeal = (data) => ({
  type: DELETE_GOOD_DEAL.REQUEST,
  data
});

export const deleteGoodDealSuccess = (data) => ({
  type: DELETE_GOOD_DEAL.SUCCESS,
  data
});

export const deleteGoodDealError = (error) => ({
  type: DELETE_GOOD_DEAL.FAILURE,
  error,
});

export const getGoodDealById = (data) => ({
  type: GET_GOOD_DEAL_BY_ID.REQUEST,
  data
});

export const getGoodDealByIdSuccess = (data) => ({
  type: GET_GOOD_DEAL_BY_ID.SUCCESS,
  data,
});

export const getGoodDealByIdError = (error) => ({
  type: GET_GOOD_DEAL_BY_ID.FAILURE,
  error,
});

export const clearSelectedGoodDeal = () => ({
  type: CLEAR_SELECTED_GOOD_DEAL.REQUEST,
});