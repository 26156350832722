import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';

// Styles
import {
  TextFieldCustom,
} from "./style";

const InputField = ({
  type = "text",
  label = "Label",
  value,
  placeholder,
  defaultValue,
  error = null,
  helperTextError,
  select = false,
  variant = "filled",
  startAdornment,
  endAdornment,
  passwordStrength = null,
  passwordStrengthLabel = null,
  ...rest
}) => {

  return (
    <>
      <TextFieldCustom
        type={type}
        label={label}
        value={value}
        placeholder={placeholder}
        defaultValue={defaultValue}
        error={error}
        helperText={helperTextError}
        select={select}
        variant={variant}
        InputProps={{
          startAdornment: startAdornment,
          endAdornment: endAdornment,
        }}
        {...rest}
      >
        {select &&
          <MenuItem key="input-select-item-1" value="Value Item">
            Label Item
          </MenuItem>
        }
      </TextFieldCustom>
      {(passwordStrength !== null && passwordStrengthLabel !== null && !!value?.length) && (
        <div className="input-password-complexity-meter">
          <meter max="5" value={passwordStrength + 1} className="a-password-meter"></meter>
          <p className="a-password-indicator">Votre mot de passe est : {passwordStrengthLabel[passwordStrength]}</p>
        </div>
      )}
    </>
  );
}

export default InputField;
