import React from 'react';
import { Formik, Field } from 'formik';

import GenericTextField from '../../../../../../components/molecules/GenericTextField';
import GenericDrawerValidationButtons from '../../../../../../components/molecules/GenericDrawerValidationButtons';

import {
  StyledAccordion,
  StyledForm,
  InputGroup
} from './style';

const ContentFilter = ({
  filterConditions,
  setFilterConditions,
  resetFilter
}) => {

  const formInitValue = {
    startDate: filterConditions.startDate,
    endDate: filterConditions.endDate,
  }

  const handleResetForm = (props) => {
    props.resetForm();
    resetFilter();
  }

  const handleSubmit = (values) => {
    // console.log(values, 'values');
    setFilterConditions(values);
  }

  return (
    <StyledAccordion
      headerComponent={"Recherche"}
      detailComponent={
        <Formik
          initialValues={formInitValue}
          setFieldValue
          onSubmit={handleSubmit}
        >
          {props => (
            <StyledForm>
              <InputGroup>
                <Field
                  id="startDate"
                  name="startDate"
                  type="date"
                  title="Debut"
                  variant="outlined"
                  size="small"
                  as={GenericTextField}
                />
                <Field
                  id="endDate"
                  name="endDate"
                  type="date"
                  title="Fin"
                  variant="outlined"
                  size="small"
                  as={GenericTextField}
                />
              </InputGroup>

              <GenericDrawerValidationButtons
                leftButonText={'Réinitialiser'}
                RightButtonText={'Chercher'}
                onclickLefttButton={() => handleResetForm(props)}
                submitable={true}
              />
            </StyledForm>
          )}
        </Formik>
      }
    />
  )
}

export default ContentFilter;
