import React, { useEffect } from "react";
import {
  Redirect,
  useParams,
  useHistory
} from "react-router-dom";
import {
  shallowEqual,
  useDispatch,
  useSelector
} from "react-redux";

import Loader from "../../../../components/atoms/lottie/Loader";

import { logout } from '../../../../store/authentication/actions';
import { validateGetGroundToken } from '../../../../store/getGround/actions';

import {
  getGetGroundTokenIsSuccess,
  getGetGroundTokenIsError,
} from '../../../../store/getGround/selectors';

import { isUserTokenValid } from '../../../../store/authentication/selectors';

export const GetGroundLogin = () => {
  const history = useHistory();
  const { token } = useParams();
  const dispatch = useDispatch();

  const {
    isTokenValid,
    isValidatingSuccess,
    isValidatingError
  } = useSelector(
    state => ({
      isTokenValid: isUserTokenValid(state),
      isValidatingSuccess: getGetGroundTokenIsSuccess(state),
      isValidatingError: getGetGroundTokenIsError(state)
    }),
    shallowEqual
  );

  useEffect(() => {
    // localStorage.removeItem('id_token');
    // localStorage.removeItem('user');
    dispatch(logout());
  }, []);

  useEffect(() => {
    if (token) {
      // let testToken = '{"uiid": 109, "email": "zoh.zoh@cyconia.io", "firstname":"zoh", "lastname": "ra"}';
      dispatch(validateGetGroundToken({ token: token }));
    }
  }, [token]);

  const rendering = () => {
    if (isValidatingError) {
      return (
        <Redirect
          to={{
            pathname: "/getground/restricted",
            state: {
              from: history.location,
              error: isValidatingError
            }
          }}
        />
      )
    }
    else if (isTokenValid && isValidatingSuccess) {
      return (
        <Redirect
          to={{
            pathname: "/landing",
            state: { from: history.location }
          }}
        />
      )
    }
    else {
      return <Loader />
    }
  }

  return (
    <>
      {rendering()}
    </>
  )
};

export default GetGroundLogin;