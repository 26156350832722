import * as a from "./actions";

const INITIAL_STATE = {
  data: null,
  get: {
    loading: false,
    success: false,
    error: null,
  }
};

export function config(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case a.GET_CONFIG.REQUEST:
      return {
        ...state,
        get: {
          loading: true,
          success: false,
          error: null,
        },
      };
    case a.GET_CONFIG.SUCCESS:
      return {
        ...state,
        data: action.config,
        get: {
          loading: false,
          success: true,
          error: null,
        },
      };
    case a.GET_CONFIG.FAILURE:
      return {
        ...state,
        get: {
          loading: false,
          success: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
}

export default config;
