import styled from 'styled-components';

import GenericModal from '../../molecules/GenericModal';
import { COLORS } from '../../../constants/design';
import breakpoints from '../../../theme-style/breakpoints';

export const StyledLinkModal = styled(GenericModal)`
  /* transition: all 1s ease-in; */
  
  animation: modalAppear 0.3s;
  && {
    .modalContainer {
      border-radius: 15px 15px 0px 0px;
      display: flex;
      flex-direction: column;
      height: 100%;
      width: ${props => props.target === 'modal_big' ? '94%' : '40%'};
      flex: unset;
      max-width: unset;
      overflow: unset;

      @media ${breakpoints.mobileXL} {
        width: ${props => props.target === 'modal_big' ? '90%' : '40%'};
      }
    }
  }
  @keyframes modalAppear {
    0% {
      bottom: -500px;
    }
    100% {
      bottom: 0px;
    }
  }
`;

export const StyledLinkHeader = styled.div`
  /* border: 1px solid black; */
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  position: relative;
  background-color: ${COLORS.PRIMARY_BLUE};
  color: ${COLORS.LIGHT_GREY_1};
  border-radius: 15px 15px 0px 0px;
  padding: 12px 15px;

  @media ${breakpoints.mobileXL} {
    padding: 20px;
  }

  && {
    .MuiButton-root {
      text-transform: none;
      font-size: initial;
      color: ${COLORS.LIGHT_GREY_1};
      padding: 4px 5px;
      
      @media ${breakpoints.mobileXL} {
        font-size: 0.875rem; 
        padding: 6px 12px;
      }
    }
  }
`;

export const StyledLinkTitle = styled.div`
  // position: absolute;
  // top:calc(50% - 15px);
  // left:calc(50% - 100px);
  font-size: initial;
  font-weight: 600;

  @media ${breakpoints.mobileXL} {
    font-size: 1.2rem; 
  }
`;

export const IframeContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const LoaderContainer = styled.div`
  background-color: rgba(0,0,0,0.5);
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;