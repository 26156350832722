import React, { useEffect } from "react";
import {
  Redirect,
  useParams,
  useHistory
} from "react-router-dom";
import {
  shallowEqual,
  useDispatch,
  useSelector
} from "react-redux";

import {
  isUserTokenValid
} from '../../../../../../store/authentication/selectors';

import { logout } from '../../../../../../store/authentication/actions';

import {
  samlLogin
} from '../../../../../../store/sso/actions';

import {
  getSamlSuccess,
  getSamlIsError
} from '../../../../../../store/sso/selectors';

import {
  getCompanyData
} from '../../../../../../store/company/selectors';

import Loader from "../../../../../../components/atoms/lottie/Loader";

const HqoLogin = () => {
  const { jwtToken } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const redirect = new URLSearchParams(window.location.search).get(
    'Redirect'
  );

  const {
    isTokenValid,
    samlSuccess,
    samlError,
    companyData
  } = useSelector(
    state => ({
      isTokenValid: isUserTokenValid(state),
      samlSuccess: getSamlSuccess(state),
      samlError: getSamlIsError(state),
      companyData: getCompanyData(state)
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(logout());
  }, []);

  useEffect(() => {
    if (jwtToken) {
      const formData = { jwtToken }
      dispatch(samlLogin(formData));
    }
    else {
      <Redirect
        to={{
          pathname: '/login',
          state: { from: history.location }
        }}
      />
    }
  }, []);

  if (!companyData.hqoSsoSaml || samlError) {
    return (
      <Redirect
        to={{
          pathname: "/sso/hqo-gecina/restricted",
          state: {
            from: history.location,
            error: {
              message: samlError ? 'Login Error' : 'Pas activé'
            }
          }
        }}
      />
    )
  }
  if (isTokenValid && samlSuccess) {
    return (
      <Redirect
        to={{
          pathname: redirect || '/landing',
          state: { from: history.location }
        }}
      />
    )
  }
  else {
    return (
      <Loader/>
    );
  }
};

export default HqoLogin;