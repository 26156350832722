import React from "react";

const WeatherDrizzelIcon = ({
  width = "100%",
  height = "100%",
  colorSun = "#FEDB83",
  colorCloud = "#F7FBFF",
  colorRain = "#2768B3",
}) => {

  return (
    <svg
      className="icon weatherDrizzelIcon"
      width={width}
      height={height}
      viewBox="0 0 600 547"
      fill="none"
    >
      <g fillRule="nonzero" fill="none">
        <path
          d="M409.3 296.4l.267.004a9.175 9.175 0 018.933 9.196v65.5c0 5.1-4.1 9.2-9.2 9.2l-.267-.004a9.175 9.175 0 01-8.933-9.196v-65.5c0-5.1 4.1-9.2 9.2-9.2zm75.21-31.304a9.173 9.173 0 0112.79.204l46.3 46.4c3.6 3.6 3.6 9.4 0 13l-.206.2a9.084 9.084 0 01-12.794-.3l-46.3-46.3c-3.6-3.6-3.6-9.4 0-13zm-163.11.204c3.6-3.6 9.4-3.6 13 0l.204.21a9.173 9.173 0 01-.204 12.79l-46.3 46.3c-3.6 3.6-9.4 3.6-13 0l-.204-.21a9.173 9.173 0 01.204-12.79zm75.524-150.686c41.86-6.85 81.347 21.531 88.197 63.39 6.85 41.86-21.531 81.348-63.391 88.198-41.86 6.85-81.347-21.53-88.197-63.39-6.85-41.86 21.53-81.348 63.39-88.198zM294.1 181.2c5.1 0 9.2 4.1 9.2 9.2l-.004.262c-.14 4.884-4.185 8.938-9.196 8.938h-65.5c-5.1 0-9.2-4.1-9.2-9.2l.004-.267a9.175 9.175 0 019.196-8.933zm296 0c5.1 0 9.2 4.1 9.2 9.2l-.004.262c-.14 4.884-4.185 8.938-9.196 8.938h-65.5c-5.1 0-9.2-4.1-9.2-9.2l.004-.267a9.175 9.175 0 019.196-8.933zM275.31 55.896a9.173 9.173 0 0112.79.204l46.3 46.3c3.6 3.6 3.6 9.4 0 13l-.21.204c-3.616 3.395-9.357 3.327-12.79-.204l-46.3-46.3c-3.6-3.6-3.6-9.4 0-13zM530.6 56c3.6-3.6 9.4-3.6 13 0l.204.21c3.395 3.616 3.327 9.357-.204 12.79l-46.3 46.3c-3.6 3.6-9.4 3.6-13 0l-.204-.21a9.173 9.173 0 01.204-12.79zM409.3.4l.267.004A9.175 9.175 0 01418.5 9.6v65.5c0 5.1-4.1 9.2-9.2 9.2l-.267-.004A9.175 9.175 0 01400.1 75.1V9.6c0-5.1 4.1-9.2 9.2-9.2z"
          fill={colorSun}
        />
        <path
          d="M57.3 324.6c.2 0 .5.1.7.1-4.6-10.5-7.1-22.1-7.1-34.3 0-47.8 38.8-86.6 86.6-86.6 16.9 0 32.6 4.9 46 13.3 18-43.8 61.1-74.7 111.5-74.7 66.6 0 120.5 54 120.5 120.5 0 5-.4 10-1 14.8.3 0 .7-.1 1-.1 44.6 0 80.8 36.2 80.8 80.8 0 44.6-36.2 80.8-80.8 80.8H57.3C25.6 439.2 0 413.5 0 381.9c0-31.6 25.7-57.3 57.3-57.3z"
          fill={colorCloud}
        />
        <path
          d="M120.5 460.6c3.1 3.1 3.1 8.2 0 11.3l-72 72c-1.5 1.5-3.6 2.3-5.6 2.3-2 0-4.1-.8-5.7-2.3-3.1-3.1-3.1-8.2 0-11.3l72-72c3.1-3.1 8.2-3.1 11.3 0zm120.5 0c3.1 3.1 3.1 8.2 0 11.3l-72 72c-1.5 1.5-3.6 2.3-5.6 2.3-2 0-4.1-.8-5.7-2.3-3.1-3.1-3.1-8.2 0-11.3l72-72c3.1-3.1 8.2-3.1 11.3 0zm119.5 0c3.1 3.1 3.1 8.2 0 11.3l-72 72c-1.5 1.5-3.6 2.3-5.6 2.3-2 0-4.1-.8-5.7-2.3-3.1-3.1-3.1-8.2 0-11.3l72-72c3.1-3.1 8.2-3.1 11.3 0z"
          fill={colorRain}
        />
      </g>
    </svg>
  );
};

export default WeatherDrizzelIcon;
