import React, { useCallback, useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

// Hooks
import useTheme from "../../../../../theme-style/useThemeCustomHook";

// Icons
import ArrowDownIcon from "../../atoms/icons/arrowDown";

// Styles
import { Container, ClassicButton, ArrowDownIconWrapper } from "./style";

const ButtonDropdown = ({
  children = null,
  labelButton,
  itemsList = null,
  backgroundColor,
  backgroundHover,
  textColor,
  textHover,
  startIcon,
  appearence,
  ...rest
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const onClickMenuItem = useCallback(
    click => {
      click();
      handleClose();
    },
    [handleClose]
  );

  return (
    <Container>
      <ClassicButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        backgroundColor={appearence ? backgroundColor : null}
        backgroundHover={appearence ? backgroundHover : null}
        textColor={appearence ? textColor : null}
        textHover={appearence ? textHover : null}
        appearence={appearence}
        startIcon={startIcon}
        endIcon={
          <ArrowDownIconWrapper>
            <ArrowDownIcon color={theme.colors.black} />
          </ArrowDownIconWrapper>
        }
        {...rest}
      >
        {labelButton}
      </ClassicButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {itemsList &&
          itemsList.map((item, index) => (
            <MenuItem
              key={`menuItem-${index}`}
              onClick={() => onClickMenuItem(item.click)}
              selected={item.selected}
              disabled={item.disabled}
            >
              {item.label}
            </MenuItem>
          ))}
      </Menu>
    </Container>
  );
};

export default ButtonDropdown;
