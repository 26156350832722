import React from "react";
import {
  Route,
  Redirect,
  useRouteMatch,
} from "react-router-dom";
import {
  shallowEqual,
  useSelector,
} from "react-redux";

import {
  NAV_BAR_KEYS,
} from "../constants/sideBarConstants";

import {
  getContextSetting,
  getLandingRoute
} from '../store/company/selectors';

import {
  isUserAdmin as isUserAdminSelector,
} from '../store/authentication/selectors';

import {
  isUserHaveConciergeRole,
} from '../store/config/selectors';

export const ContextRoute = ({ component: Component, ...rest }) => {
  const isDashboardRoute = useRouteMatch("/dashboard");
  const isConciergeRoute = useRouteMatch("/conciergerie");
  const isSkillRoute = useRouteMatch("/competences");
  const isAccountRoute = useRouteMatch("/compte");
  const isConsoleRoute = useRouteMatch("/my-admin");

  const {
    isUserAdmin,
    isUserConcierge,
    contextSetting,
    landingRoute
  } = useSelector(
    state => ({
      isUserAdmin: isUserAdminSelector(state),
      isUserConcierge: isUserHaveConciergeRole(state),
      contextSetting: getContextSetting(state),
      landingRoute: getLandingRoute(state),
    }),
    shallowEqual
  );

  // console.log('landingRoute', landingRoute);

  // console.log('contextSetting', contextSetting);

  if (isDashboardRoute && contextSetting?.[NAV_BAR_KEYS.DASHBOARD] === false) {
    return (
      <Redirect
        to={landingRoute}
      />
    )
  }
  else if (isConciergeRoute && contextSetting?.[NAV_BAR_KEYS.CONCIERGE] === false) {
    return (
      <Redirect
        to={landingRoute}
      />
    )
  }
  else if (isSkillRoute && contextSetting?.[NAV_BAR_KEYS.SKILL] === false) {
    return (
      <Redirect
        to={landingRoute}
      />
    )
  }
  else if (isAccountRoute && contextSetting?.[NAV_BAR_KEYS.ACCOUNT] === false) {
    return (
      <Redirect
        to={landingRoute}
      />
    )
  }
  else if (isConsoleRoute && !isUserAdmin && !isUserConcierge) {
    return (
      <Redirect
        to={landingRoute}
      />
    )
  }
  else {
    return (
      <Route
        {...rest}
        render={props =>
          <Component {...props} />
        }
      />
    )
  }
};