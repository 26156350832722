import React from "react";
import VisibilityIcon from '@material-ui/icons/Visibility';
import InfoIcon from '@material-ui/icons/Info';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import GenericTitleWithToolTip from "../../../../../../../components/molecules/GenericTitleWithToolTip";
import servicePreview from '../../../../../../../assets/images/servicePreview.png';
import customServiceExample from '../../../../../../../assets/images/customServiceExample.png';
import standardServiceExample from '../../../../../../../assets/images/standardServiceExample.png';

// Import Style
import {
  Container,
  CustomTab,
  CustomTabs,
  ImagePreviewContainer
} from './style';

const useStyles = makeStyles((theme) => ({
  noMaxWidth: {
    maxWidth: 'none !important',
  },
}));

const ContentBar = ({
  list,
  selectedTab,
  onChange
}) => {
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    onChange(newValue);
  };

  return (
    <Container>
      <CustomTabs
        value={selectedTab}
        onChange={handleChange}
        TabIndicatorProps={{
          style: {
            display: 'none',
          }
        }}
      >
        {_.map(list, (data) => {
          return (
            <CustomTab
              key={data}
              label={
                <GenericTitleWithToolTip
                  title={data}
                  ToolTipIcon={data === 'PEC Chatbot' ? VisibilityIcon : InfoIcon}
                  toolTipProps={{
                    classes: { tooltip: classes.noMaxWidth },
                  }}
                  toolTipText={
                    data === 'PEC Chatbot' ?
                      <ImagePreviewContainer
                        image={standardServiceExample}
                      />
                      :
                      'Indiquez ici toutes les informations utiles sur ce service : conditions, tarifs ect…'
                  }
                />
              }
              value={data}
            />
          )
        })}
      </CustomTabs>
    </Container>
  )
}

export default ContentBar