import React from 'react';

import sm_logo from "../../assets/images/small-logo.svg";

export default class Offline extends React.Component {

    render(){

        return(
            <div className="OfflinePage">
                {/* <div className="LoginPage-cover">
                    <img src={logo_white} alt="Cyconia Logo white" className="LoginPage-cover_logo" />
                    <h3 className="LoginPage-cover_title">Le r&eacute;flexe de votre quotidien</h3>
                    <Link to="/onboarding">Découvrir notre produit</Link>
                </div> */}
                <div className="header-page__header">
                    <div className="header-page__header-icon">
                        <img src={sm_logo} alt="Logo Cyconia" className="header-page__small-logo"/>
                    </div>
                    <span className="header-page__logo-text">Cyconia</span>
                </div>
                <div className="m-card-onboarding">
                    <h3>Erreur de connexion <span role="img" aria-label="croix">❌</span></h3>
                    <p>
                        Il semblerait que vous ne soyez pas connecter à internet. Veuillez vérifier que votre connexion et recharger l'application ! <span role="img" aria-label="smile">🙂</span>
                    </p>
                </div>
                <button className="a-button a-button-big a-button-pink" onClick={()=> {
                    window.location.reload();
                }}>
                    Recharger l'application
                </button>
            </div>
        )
    }
}