import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import ContextSettings from '../ContextSettings';
import HelperSettings from '../HelperSettings';

import {
  StyledPanel,
  PanelHeader,
  PanelTitle,
  IconContainer,
  TableIcon
} from './style';

const SiteSettingPanel = ({
  context,
  site,
  openEditDrawer,
  prepareDelete,
  tabValue,
  index,
}) => {

  return (
    <StyledPanel
      role="tabpanel"
      hidden={tabValue !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
    >
      <PanelHeader>
        <PanelTitle>
          {`Context: ${site?.name || context?.siteId}`}
          <Checkbox
            checked={context.active}
            readOnly
          />
        </PanelTitle>
        <IconContainer>
          <TableIcon onClick={(event) => openEditDrawer(event, context.siteId)}>
            <EditIcon />
          </TableIcon>
          <TableIcon onClick={(event) => prepareDelete(event, context.siteId)}>
            <DeleteIcon />
          </TableIcon>
        </IconContainer>
      </PanelHeader>
      <ContextSettings
        context={context}
      />
      <HelperSettings
        context={context}
      />
    </StyledPanel>
  );
};

export default SiteSettingPanel;