import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import _ from 'lodash';

import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DragIndicatorIcon from '@material-ui/icons/SwapVert';

import ResponseEditor from './ResponseEditor';
import { MESSAGE_ELEMENT_TYPE } from '../../../constants/chatBotConstants';

import {
  StyledMainContainer,
  StyledElementContainer,
  StyledElementIconContainer,
  StyledElementIcon,
  StyledElementDragIcon,
  StyledResponsePreviewContainer,
  StyledResponseBlock,
  StyledResponseTextPreview,
  StyledResponseButton,
  StyledMenu,
  StyledNestedContainer
} from './style';

const inputList = [
  {
    value: MESSAGE_ELEMENT_TYPE.TEXT,
    label: "Texte simple"
  },
  {
    value: MESSAGE_ELEMENT_TYPE.BUTTON_SIMPLE,
    label: "Bouton avec texte simple"
  },
  {
    value: MESSAGE_ELEMENT_TYPE.BUTTON_INTENT,
    label: "Bouton déclencheur"
  },
  {
    value: MESSAGE_ELEMENT_TYPE.BUTTON_FORM,
    label: "Bouton formulaire simple"
  },
  {
    value: MESSAGE_ELEMENT_TYPE.BUTTON_LINK,
    label: "Bouton lien externe"
  },
];

const ReponseBuilder = ({
  messageElements,
  responseBuilderSetting,
  siteId,
  parentIndex,
  saveResponse
}) => {
  const [menuList, setMenuList] = useState(inputList);
  const [elementList, setElementList] = useState(messageElements);
  const [showIcon, setShowIcon] = useState([]);
  const [showNested, setShowNested] = useState([]);
  const [selectedElementIndex, setSelectedElementIndex] = useState(null);
  const [editMode, setEditMode] = useState(null);
  const messageElementsRef = useRef();
  const draggingItem = useRef();
  const dragOverItem = useRef();

  useEffect(() => {
    messageElementsRef.current = messageElements;
  }, []);

  useEffect(() => {
    if (responseBuilderSetting) {
      if (responseBuilderSetting.menuList) {
        const newMenuList = _.filter(inputList, (input) => _.includes(responseBuilderSetting.menuList, input.value));
        setMenuList(newMenuList);
      }
    }
  }, [responseBuilderSetting]);

  useEffect(() => {
    if (!_.isEqual(messageElements, messageElementsRef.current)) {
      setElementList(messageElements);
      messageElementsRef.current = messageElements;
    }
  }, [messageElements]);

  useEffect(() => {
    setShowNested(showNested => (
      Array(messageElements.length).fill().map((_, i) => showNested[i] || false)
    ));
  }, [siteId]); // for reloading purpose when change site, siteId is optional

  useEffect(() => {
    setShowIcon(showIcon => (
      Array(elementList.length).fill().map((_, i) => showIcon[i] || false)
    ));

    // This will update the whole messageElements hierachy when a changes is made within the messageElements no matter which nested level it currently at
    if (!_.isEqual(elementList, messageElements))
      saveResponse(parentIndex, elementList);
  }, [elementList]);

  // To update the current messageElements state when the nested messageElements have being changed
  const onNestedResponseChange = (index, newMessageElements) => {
    const newList = _.cloneDeep(elementList);

    newList[index].messageElements = newMessageElements;
    setElementList(newList);
  }

  const handleDragStart = (index) => {
    draggingItem.current = index;
  };

  const handleDragEnter = (index) => {
    dragOverItem.current = index;
  };

  const handleDragEnd = (e) => {
    if (!showNested[draggingItem.current]) {
      const newElementList = _.cloneDeep(elementList);
      const newShowNested = _.cloneDeep(showNested);
      const selectedElement = newElementList[draggingItem.current];
      const selectedShowNested = newShowNested[draggingItem.current];

      newElementList.splice(draggingItem.current, 1);
      newElementList.splice(dragOverItem.current, 0, selectedElement);
      newShowNested.splice(draggingItem.current, 1);
      newShowNested.splice(dragOverItem.current, 0, selectedShowNested);

      draggingItem.current = null;
      dragOverItem.current = null;
      setElementList(newElementList);
      setShowNested(newShowNested);
    }
  };

  const handlePick = (value) => {
    const newList = _.cloneDeep(elementList);
    const newShowNested = _.cloneDeep(showNested);
    let newElement = {};

    if (value === MESSAGE_ELEMENT_TYPE.TEXT) {
      newElement = {
        type: MESSAGE_ELEMENT_TYPE.TEXT,
        content: "<p>Texte Simple</p>"
      }
    }
    else if (value === MESSAGE_ELEMENT_TYPE.BUTTON_SIMPLE) {
      newElement = {
        type: MESSAGE_ELEMENT_TYPE.BUTTON_SIMPLE,
        title: "Bouton avec texte simple",
        userMessage: "Réponse de l’utilisateur",
        messageElements: [
          {
            type: "text",
            content: "<p>Exemple de réponse</p>"
          },
        ],
      }
    }
    else if (value === MESSAGE_ELEMENT_TYPE.BUTTON_INTENT) {
      newElement = {
        type: MESSAGE_ELEMENT_TYPE.BUTTON_INTENT,
        title: "Bouton déclencheur",
        userMessage: "Réponse de l’utilisateur",
      }
    }
    else if (value === MESSAGE_ELEMENT_TYPE.BUTTON_FORM) {
      newElement = {
        type: MESSAGE_ELEMENT_TYPE.BUTTON_FORM,
        title: "Bouton formulaire simple",
        action: "",
        service: "baseService",
      }
    }
    else if (value === MESSAGE_ELEMENT_TYPE.BUTTON_LINK) {
      newElement = {
        type: MESSAGE_ELEMENT_TYPE.BUTTON_LINK,
        title: "Bouton lien externe",
        url: "https://",
        target: "_blank",
        urlParams: [],
      }
    }

    newList.push(newElement);
    newShowNested.push(value === MESSAGE_ELEMENT_TYPE.BUTTON_SIMPLE ? true : false);
    const newElementIndex = newList.length - 1;
    setElementList(newList)
    setShowNested(newShowNested);
    toggleEditMode(newElementIndex, 'add');
  }

  const handleDelete = () => {
    const newList = _.cloneDeep(elementList);
    const newShowNested = _.cloneDeep(showNested);

    newList.splice(selectedElementIndex, 1);
    newShowNested.splice(selectedElementIndex, 1);
    setElementList(newList);
    setShowNested(newShowNested);
    toggleEditMode(null, null);
  }

  const toggleEditMode = (index, value) => {
    setSelectedElementIndex(index);
    setEditMode(value);
  }

  const saveForm = (form) => {
    const newList = _.cloneDeep(elementList);
    let selectedElement = _.clone(newList[selectedElementIndex]);

    if (selectedElement.type === MESSAGE_ELEMENT_TYPE.TEXT) {
      selectedElement.content = form.content;
    }
    else if (selectedElement.type === MESSAGE_ELEMENT_TYPE.BUTTON_LINK) {
      const newUrlParams = _.filter(form.urlParams, (param) => param.variable !== "" && param.value !== "");

      selectedElement = {
        ...selectedElement,
        ...form,
        urlParams: newUrlParams,
      }
      // console.log('newUrlParams', newUrlParams);
    }
    else {
      selectedElement.title = form.title;
      if (selectedElement.type !== MESSAGE_ELEMENT_TYPE.BUTTON_FORM)
        selectedElement.userMessage = form.userMessage;
    }
    newList.splice(selectedElementIndex, 1, selectedElement);
    setElementList(newList);
    toggleEditMode(null, null);
  }

  const toggleIconContainer = (index) => {
    setShowIcon(_.map(showIcon, (_, i) => i === index ? true : false));
  }

  const resetShowIcon = () => {
    setShowIcon(Array(messageElements.length).fill().map((_, i) => false));
  }

  const showNestedElement = (index) => {
    const newShowNested = _.clone(showNested);

    newShowNested[index] = !showNested[index];
    setShowNested(newShowNested);
  }

  const getPreview = (element, index) => {
    if (element.type === MESSAGE_ELEMENT_TYPE.TEXT) {
      return (
        <StyledResponseTextPreview onDoubleClick={() => toggleEditMode(index, "edit")}>
          {parse(element.content)}
        </StyledResponseTextPreview>
      )
    }
    else {
      return (
        <StyledResponseButton
          appearence={"gradientBlue"}
          onClick={() => element.type === MESSAGE_ELEMENT_TYPE.BUTTON_SIMPLE && showNestedElement(index)}
        >
          {element.title}
        </StyledResponseButton>
      )
    }
  }

  const getElementPreview = (index, element) => {
    return (
      <StyledResponsePreviewContainer
        draggable={!showNested[index]}
        onDragStart={() => handleDragStart(index)}
        onDragEnter={() => handleDragEnter(index)}
        onDragEnd={handleDragEnd}
        onDragOver={(e) => e.preventDefault()}
        onMouseOver={() => toggleIconContainer(index)}
        onMouseLeave={() => resetShowIcon()}
        showNested={showNested[index]}
        key={index}
      >
        <StyledResponseBlock>
          {getPreview(element, index)}
          {showIcon[index] &&
            <StyledElementIconContainer>
              <StyledElementIcon onClick={() => toggleEditMode(index, "edit")}>
                <EditIcon />
              </StyledElementIcon>
              <React.Fragment>
                <StyledElementIcon onClick={() => toggleEditMode(index, "delete")}>
                  <DeleteIcon />
                </StyledElementIcon>
                <StyledElementDragIcon disableRipple showNested={showNested[index]} onClick={(e) => { e.preventDefault() }}>
                  <Tooltip title={showNested[index] ? "Le glissement n'est pas autorisé" : ""}>
                    <DragIndicatorIcon />
                  </Tooltip>
                </StyledElementDragIcon>
              </React.Fragment>
            </StyledElementIconContainer>
          }
        </StyledResponseBlock>
        {showNested[index] && element.type === MESSAGE_ELEMENT_TYPE.BUTTON_SIMPLE &&
          <StyledNestedContainer>
            <ReponseBuilder messageElements={element.messageElements} siteId={siteId} parentIndex={index} saveResponse={onNestedResponseChange} />
          </StyledNestedContainer>
        }
      </StyledResponsePreviewContainer>
    )
  }

  return (
    <StyledMainContainer>
      {elementList.length !== 0 ?
        <StyledElementContainer>
          {_.map(elementList, (element, index) => getElementPreview(index, element))}
          <StyledMenu title={"Ajouter un nouvel élément de message +"} list={menuList} onChange={handlePick} variant={"button"}/>
        </StyledElementContainer>
        :
        <StyledMenu title={"Ajouter un nouvel élément de message +"} list={menuList} onChange={handlePick} variant={"button"}/>
      }
      {editMode !== null &&
        <ResponseEditor
          element={elementList[selectedElementIndex]}
          editMode={editMode}
          onSave={(form) => saveForm(form)}
          onDelete={() => handleDelete()}
          onCancel={() => toggleEditMode(null, null)}
        />
      }
    </StyledMainContainer>
  )
};

ReponseBuilder.propTypes = {
  messageElements: PropTypes.array,
  responseBuilderSetting: PropTypes.any,
  siteId: PropTypes.number,
  parentIndex: PropTypes.number,
  saveResponse: PropTypes.func
};

export default ReponseBuilder;

// Note
// responseBuilderSetting props is to control the behaviour of the builder
// menuList is to control which menu show be available
// example:

// const responseBuilderSetting = {
//   menuList: [
//     MESSAGE_ELEMENT_TYPE.TEXT,
//     MESSAGE_ELEMENT_TYPE.BUTTON_LINK
//   ]
// }