import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  /* min-width: 450px; */
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 150px;
  max-height: 20vh;
  padding: 20px;
  border-radius: 10px;
  background-color: #D8E5FD;
  margin: 0 0 40px 0;
`;

export const Title = styled.h3`
  margin: 0 0 auto 0;
  color: #5A5A5A;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const ChatCommunicationIllustrationWrapper = styled.span`
  display: flex;
  width: auto;
  max-width: 90px;
`;

export const FeedbackTypeIllustration = styled.div`

`;

export const FeedbackTypeList = styled.ul`
  margin: 0;
  padding: 0;
  width: auto;
`;

export const FeedbackTypeItem = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin: 0 0 16px 0;
  &:nth-child(2){
    span{
      background-color: #40B176;
    }
  }
  &:nth-child(3){
    span{
      background-color: #FF9F6D;
    }
  }
  &:last-child{
    margin: 0 0 7px 0;
    span{
      background-color: #EC6B87;
    }
  }
  span{
    width: 6px;
    height: 12px;
    background-color: #4A90E2;
    border-radius: 50px;
    margin: 0 8px 0 0;
  }
  strong{
    color: #5A5A5A;
    font-size: 14px;
    line-height: 12px;
    font-weight: 700;
    margin: 0 4px 0 0;
  }
  p{
    color: #5A5A5A;
    font-size: 10px;
    line-height: 10px;
    font-weight: 400;
    margin: 0 4px 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    strong{
      font-size: 10px;
    }
  }
`;
