import * as a from "./actions";
import _ from 'lodash';

const initGetValue = {
  loading: false,
  success: false,
  error: null,
}

const modifyGet = (type, error = null) => {
  return {
    loading: type === 'request' ? true : false,
    success: type === 'success' ? true : false,
    error: type === 'error' ? error : null,
  }
}

const INITIAL_STATE = {
  lastUntreatedFeedback: {
    data: null,
    get: {
      loading: false,
      success: false,
      error: null,
    }
  },
  untreatedFeedbackStats: {
    data: null,
    get: {
      loading: false,
      success: false,
      error: null,
    }
  },
  activeInfoElement: {
    data: null,
    get: {
      loading: false,
      success: false,
      error: null,
    }
  },
  inactiveInfoElement: {
    data: null,
    get: {
      loading: false,
      success: false,
      error: null,
    }
  },
  collaboratorsList: {
    data: null,
    get: {
      loading: false,
      success: false,
      error: null,
    }
  },
  collaboratorsRules: {
    data: null,
    get: {
      loading: false,
      success: false,
      error: null,
    }
  },
  untreatedFeedback: {
    data: null,
    get: {
      loading: false,
      success: false,
      error: null,
    }
  },
  untreatedFbBySkill: {
    data: null,
    get: {
      loading: false,
      success: false,
      error: null,
    }
  },
  untreatedFbBySkillStats: {
    data: null,
    get: {
      loading: false,
      success: false,
      error: null,
    }
  },
  skillsDomainsList: {
    data: null,
    get: {
      loading: false,
      success: false,
      error: null,
    }
  },
  feebackByInfoEl: {
    data: null,
    get: {
      loading: false,
      success: false,
      error: null,
    }
  },
  treatedFeedback: {
    data: null,
    get: {
      loading: false,
      success: false,
      error: null,
    }
  },
  treatedFeedbackStats: {
    data: null,
    get: {
      loading: false,
      success: false,
      error: null,
    }
  },
  treatedFbBySkillStats: {
    data: null,
    get: {
      loading: false,
      success: false,
      error: null,
    }
  },
  positiveBotFeedback: {
    data: null,
    get: {
      loading: false,
      success: false,
      error: null,
    }
  },
  negativeBotFeedback: {
    update: {
      loading: false,
      success: false,
      error: null,
    }
  },
  informationList: {
    data: null,
    get: {
      loading: false,
      success: false,
      error: null,
    },
  },
  elementInformationList: {
    data: null,
    updatedResponseId: null,
    get: {
      loading: false,
      success: false,
      error: null,
    },
    update: {
      loading: false,
      success: false,
      error: null,
    },
    updateAll: {
      loading: false,
      success: false,
      error: null,
    }
  },
  feedsStatInfo: {
    data: null,
    get: {
      loading: false,
      success: false,
      error: null,
    }
  },
  feedsListBySite: {
    data: null,
    get: {
      loading: false,
      success: false,
      error: null,
    }
  },
  elementInformationResponseById: {
    data: null,
    get: {
      loading: false,
      success: false,
      error: null,
    }
  },
  elementInformationByCompany: {
    data: null,
    get: {
      loading: false,
      success: false,
      error: null,
    }
  },
  updateEiActivation: {
    isActive: false,
    get: {
      loading: false,
      success: false,
      error: null,
    }
  },
  updateEiResponse: {
    data: null,
    get: {
      loading: false,
      success: false,
      error: null,
    }
  },
  updateProcessedFeed: {
    update: {
      loading: false,
      success: false,
      error: null,
    }
  },
  setInformationElementTitle: {
    data: null,
  },
  specificCase: {
    data: null,
    get: {
      loading: false,
      success: false,
      error: null,
    }
  },
};

export function adminFront(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    // LAST UNTREATED FEEDBACK
    case a.GET_LAST_UNTREATED_FB.REQUEST:
      return {
        ...state,
        lastUntreatedFeedback: {
          data: null,
          get: {
            loading: true,
            success: false,
            error: null,
          }
        }
      };
    case a.GET_LAST_UNTREATED_FB.SUCCESS:
      return {
        ...state,
        lastUntreatedFeedback: {
          data: action.lastUntreatedFeedback,
          get: {
            loading: false,
            success: true,
            error: null,
          }
        }
      };
    case a.GET_LAST_UNTREATED_FB.FAILURE:
      return {
        ...state,
        lastUntreatedFeedback: {
          data: null,
          get: {
            loading: false,
            success: false,
            error: action.error,
          }
        }
      };
    // UNTREATED FEEDBACK STATS
    case a.GET_UNTREATED_FB_STATS.REQUEST:
      return {
        ...state,
        untreatedFeedbackStats: {
          data: null,
          get: {
            loading: true,
            success: false,
            error: null,
          }
        }
      };
    case a.GET_UNTREATED_FB_STATS.SUCCESS:
      return {
        ...state,
        untreatedFeedbackStats: {
          data: action.untreatedFeedbackStats,
          get: {
            loading: false,
            success: true,
            error: null,
          }
        }
      };
    case a.GET_UNTREATED_FB_STATS.FAILURE:
      return {
        ...state,
        untreatedFeedbackStats: {
          data: null,
          get: {
            loading: false,
            success: false,
            error: action.error,
          }
        }
      };
    // ACTIVE INFO ELEMENT
    case a.GET_ACTIVE_INFO_ELEMENT.REQUEST:
      return {
        ...state,
        activeInfoElement: {
          data: null,
          get: {
            loading: true,
            success: false,
            error: null,
          }
        }
      };
    case a.GET_ACTIVE_INFO_ELEMENT.SUCCESS:
      return {
        ...state,
        activeInfoElement: {
          data: action.activeInfoElement,
          get: {
            loading: false,
            success: true,
            error: null,
          }
        }
      };
    case a.GET_ACTIVE_INFO_ELEMENT.FAILURE:
      return {
        ...state,
        activeInfoElement: {
          data: null,
          get: {
            loading: false,
            success: false,
            error: action.error,
          }
        }
      };
    // INACTIVE INFO ELEMENT
    case a.GET_INACTIVE_INFO_ELEMENT.REQUEST:
      return {
        ...state,
        inactiveInfoElement: {
          data: null,
          get: {
            loading: true,
            success: false,
            error: null,
          }
        }
      };
    case a.GET_INACTIVE_INFO_ELEMENT.SUCCESS:
      return {
        ...state,
        inactiveInfoElement: {
          data: action.inactiveInfoElement,
          get: {
            loading: false,
            success: true,
            error: null,
          }
        }
      };
    case a.GET_INACTIVE_INFO_ELEMENT.FAILURE:
      return {
        ...state,
        inactiveInfoElement: {
          data: null,
          get: {
            loading: false,
            success: false,
            error: action.error,
          }
        }
      };
    // COLLABORATOR LIST
    case a.GET_COLLABORATORS_LIST.REQUEST:
      return {
        ...state,
        collaboratorsList: {
          data: null,
          get: {
            loading: true,
            success: false,
            error: null,
          }
        }
      };
    case a.GET_COLLABORATORS_LIST.SUCCESS:
      return {
        ...state,
        collaboratorsList: {
          data: action.collaboratorsList,
          get: {
            loading: false,
            success: true,
            error: null,
          }
        }
      };
    case a.GET_COLLABORATORS_LIST.FAILURE:
      return {
        ...state,
        collaboratorsList: {
          data: null,
          get: {
            loading: false,
            success: false,
            error: action.error,
          }
        }
      };
    case a.GET_COLLABORATORS_LIST.RESET:
      return {
        ...state,
        collaboratorsList: INITIAL_STATE.collaboratorsList,
      };
    // COLLABORATOR RULES
    case a.GET_COLLABORATORS_RULES.REQUEST:
      return {
        ...state,
        collaboratorsRules: {
          data: null,
          get: {
            loading: true,
            success: false,
            error: null,
          }
        }
      };
    case a.GET_COLLABORATORS_RULES.SUCCESS:
      return {
        ...state,
        collaboratorsRules: {
          data: action.collaboratorsRules,
          get: {
            loading: false,
            success: true,
            error: null,
          }
        }
      };
    case a.GET_COLLABORATORS_RULES.FAILURE:
      return {
        ...state,
        collaboratorsRules: {
          data: null,
          get: {
            loading: false,
            success: false,
            error: action.error,
          }
        }
      };
    // UNTREATED FEEDBACK
    case a.GET_UNTREATED_FEEDBACK.REQUEST:
      return {
        ...state,
        untreatedFeedback: {
          data: null,
          get: {
            loading: true,
            success: false,
            error: null,
          }
        }
      };
    case a.GET_UNTREATED_FEEDBACK.SUCCESS:
      return {
        ...state,
        untreatedFeedback: {
          data: action.untreatedFeedback,
          get: {
            loading: false,
            success: true,
            error: null,
          }
        }
      };
    case a.GET_UNTREATED_FEEDBACK.FAILURE:
      return {
        ...state,
        untreatedFeedback: {
          data: null,
          get: {
            loading: false,
            success: false,
            error: action.error,
          }
        }
      };
    // UNTREATED FEEDBACK BY SKILL
    case a.GET_UNTREATED_FEEDBACK_BY_SKILL.REQUEST:
      return {
        ...state,
        untreatedFbBySkill: {
          data: null,
          get: {
            loading: true,
            success: false,
            error: null,
          }
        }
      };
    case a.GET_UNTREATED_FEEDBACK_BY_SKILL.SUCCESS:
      return {
        ...state,
        untreatedFbBySkill: {
          data: action.untreatedFbBySkill,
          get: {
            loading: false,
            success: true,
            error: null,
          }
        }
      };
    case a.GET_UNTREATED_FEEDBACK_BY_SKILL.FAILURE:
      return {
        ...state,
        untreatedFbBySkill: {
          data: null,
          get: {
            loading: false,
            success: false,
            error: action.error,
          }
        }
      };
    // UNTREATED FEEDBACK BY SKILL STATS
    case a.GET_UNTREATED_FEEDBACK_BY_SKILL_STATS.REQUEST:
      return {
        ...state,
        untreatedFbBySkillStats: {
          data: null,
          get: {
            loading: true,
            success: false,
            error: null,
          }
        }
      };
    case a.GET_UNTREATED_FEEDBACK_BY_SKILL_STATS.SUCCESS:
      return {
        ...state,
        untreatedFbBySkillStats: {
          data: action.untreatedFbBySkillStats,
          get: {
            loading: false,
            success: true,
            error: null,
          }
        }
      };
    case a.GET_UNTREATED_FEEDBACK_BY_SKILL_STATS.FAILURE:
      return {
        ...state,
        untreatedFbBySkillStats: {
          data: null,
          get: {
            loading: false,
            success: false,
            error: action.error,
          }
        }
      };
    // SIKLLS DOMAINS LIST
    case a.GET_SKILLS_DOMAINS_LIST.REQUEST:
      return {
        ...state,
        skillsDomainsList: {
          data: null,
          get: {
            loading: true,
            success: false,
            error: null,
          }
        }
      };
    case a.GET_SKILLS_DOMAINS_LIST.SUCCESS:
      return {
        ...state,
        skillsDomainsList: {
          data: action.skillsDomainsList,
          get: {
            loading: false,
            success: true,
            error: null,
          }
        }
      };
    case a.GET_SKILLS_DOMAINS_LIST.FAILURE:
      return {
        ...state,
        skillsDomainsList: {
          data: null,
          get: {
            loading: false,
            success: false,
            error: action.error,
          }
        }
      };
    // FEEDBACK BY INFO ELEMENT
    case a.GET_FEEDBACK_BY_INFO_ELEMENT.REQUEST:
      return {
        ...state,
        feebackByInfoEl: {
          data: null,
          get: {
            loading: true,
            success: false,
            error: null,
          }
        }
      };
    case a.GET_FEEDBACK_BY_INFO_ELEMENT.SUCCESS:
      return {
        ...state,
        feebackByInfoEl: {
          data: action.feebackByInfoEl,
          get: {
            loading: false,
            success: true,
            error: null,
          }
        }
      };
    case a.GET_FEEDBACK_BY_INFO_ELEMENT.FAILURE:
      return {
        ...state,
        feebackByInfoEl: {
          data: null,
          get: {
            loading: false,
            success: false,
            error: action.error,
          }
        }
      };
    // TREATED FEEDBACK
    case a.GET_TREATED_FEEDBACK.REQUEST:
      return {
        ...state,
        treatedFeedback: {
          data: null,
          get: {
            loading: true,
            success: false,
            error: null,
          }
        }
      };
    case a.GET_TREATED_FEEDBACK.SUCCESS:
      return {
        ...state,
        treatedFeedback: {
          data: action.treatedFeedback,
          get: {
            loading: false,
            success: true,
            error: null,
          }
        }
      };
    case a.GET_TREATED_FEEDBACK.FAILURE:
      return {
        ...state,
        treatedFeedback: {
          data: null,
          get: {
            loading: false,
            success: false,
            error: action.error,
          }
        }
      };
    // TREATED FEEDBACK STATS
    case a.GET_TREATED_FEEDBACK_STATS.REQUEST:
      return {
        ...state,
        treatedFeedbackStats: {
          data: null,
          get: {
            loading: true,
            success: false,
            error: null,
          }
        }
      };
    case a.GET_TREATED_FEEDBACK_STATS.SUCCESS:
      return {
        ...state,
        treatedFeedbackStats: {
          data: action.treatedFeedbackStats,
          get: {
            loading: false,
            success: true,
            error: null,
          }
        }
      };
    case a.GET_TREATED_FEEDBACK_STATS.FAILURE:
      return {
        ...state,
        treatedFeedbackStats: {
          data: null,
          get: {
            loading: false,
            success: false,
            error: action.error,
          }
        }
      };
    // TREATED FEEDBACK BY SKILL STATS
    case a.GET_TREATED_FEEDBACK_BY_SKILL_STATS.REQUEST:
      return {
        ...state,
        treatedFbBySkillStats: {
          data: null,
          get: {
            loading: true,
            success: false,
            error: null,
          }
        }
      };
    case a.GET_TREATED_FEEDBACK_BY_SKILL_STATS.SUCCESS:
      return {
        ...state,
        treatedFbBySkillStats: {
          data: action.treatedFbBySkillStats,
          get: {
            loading: false,
            success: true,
            error: null,
          }
        }
      };
    case a.GET_TREATED_FEEDBACK_BY_SKILL_STATS.FAILURE:
      return {
        ...state,
        treatedFbBySkillStats: {
          data: null,
          get: {
            loading: false,
            success: false,
            error: action.error,
          }
        }
      };
    // POSITIVE BOT FEEDBACK
    case a.GET_POSITIVE_BOT_FEEDBACK.REQUEST:
      return {
        ...state,
        positiveBotFeedback: {
          data: null,
          get: {
            loading: true,
            success: false,
            error: null,
          }
        }
      };
    case a.GET_POSITIVE_BOT_FEEDBACK.SUCCESS:
      return {
        ...state,
        positiveBotFeedback: {
          data: action.positiveBotFeedback,
          get: {
            loading: false,
            success: true,
            error: null,
          }
        }
      };
    case a.GET_POSITIVE_BOT_FEEDBACK.FAILURE:
      return {
        ...state,
        positiveBotFeedback: {
          data: null,
          get: {
            loading: false,
            success: false,
            error: action.error,
          }
        }
      };
    // NEGATIVE BOT FEEDBACK
    case a.UPDATE_NEGATIVE_BOT_FEEDBACK.REQUEST:
      return {
        ...state,
        negativeBotFeedback: {
          update: {
            loading: true,
            success: false,
            error: null,
          }
        }
      };
    case a.UPDATE_NEGATIVE_BOT_FEEDBACK.SUCCESS:
      return {
        ...state,
        negativeBotFeedback: {
          update: {
            loading: false,
            success: true,
            error: null,
          }
        }
      };
    case a.UPDATE_NEGATIVE_BOT_FEEDBACK.FAILURE:
      return {
        ...state,
        negativeBotFeedback: {
          update: {
            loading: false,
            success: false,
            error: action.error,
          }
        }
      };
    case a.UPDATE_NEGATIVE_BOT_FEEDBACK.RESET:
      return {
        ...state,
        negativeBotFeedback: {
          update: INITIAL_STATE.negativeBotFeedback.update,
        },
      };
    // CYCONIA TRAINING - COMPETENCE DOMAIN
    case a.GET_INFORMATION_LIST.REQUEST:
      return {
        ...state,
        informationList: {
          data: null,
          get: {
            loading: true,
            success: false,
            error: null,
          }
        }
      };
    case a.GET_INFORMATION_LIST.SUCCESS:
      return {
        ...state,
        informationList: {
          data: action.informationList,
          get: {
            loading: false,
            success: true,
            error: null,
          }
        }
      };
    case a.GET_INFORMATION_LIST.FAILURE:
      return {
        ...state,
        informationList: {
          data: null,
          get: {
            loading: false,
            success: false,
            error: action.error,
          }
        }
      };
    // CYCONIA TRAINING - ELEMENT INFO LIST
    case a.GET_ELEMENT_INFORMATION_LIST.REQUEST:
      return {
        ...state,
        elementInformationList: {
          ...state.elementInformationList,
          data: null,
          get: {
            loading: true,
            success: false,
            error: null,
          }
        }
      };
    case a.GET_ELEMENT_INFORMATION_LIST.SUCCESS:
      return {
        ...state,
        elementInformationList: {
          ...state.elementInformationList,
          data: action.elementInformationList,
          get: {
            loading: false,
            success: true,
            error: null,
          }
        }
      };
    case a.GET_ELEMENT_INFORMATION_LIST.FAILURE:
      return {
        ...state,
        elementInformationList: {
          ...state.elementInformationList,
          data: null,
          get: {
            loading: false,
            success: false,
            error: action.error,
          }
        }
      };
    case a.UPADATE_ELEMENT_INFORMATION_RESPONSE.REQUEST:
      return {
        ...state,
        elementInformationList: {
          ...state.elementInformationList,
          update: {
            loading: true,
            success: false,
            error: null,
          }
        }
      };
    case a.UPADATE_ELEMENT_INFORMATION_RESPONSE.SUCCESS:
      return {
        ...state,
        elementInformationList: {
          ...state.elementInformationList,
          updatedResponseId: action.responseId,
          update: {
            loading: false,
            success: true,
            error: null,
          }
        }
      };
    case a.UPADATE_ELEMENT_INFORMATION_RESPONSE.FAILURE:
      return {
        ...state,
        elementInformationList: {
          ...state.elementInformationList,
          update: {
            loading: false,
            success: false,
            error: action.error,
          }
        }
      };
    case a.UPDATE_ALL_ELEMENT_INFORMATION.REQUEST:
      return {
        ...state,
        elementInformationList: {
          ...state.elementInformationList,
          updateAll: {
            loading: true,
            success: false,
            error: null,
          }
        }
      };
    case a.UPDATE_ALL_ELEMENT_INFORMATION.SUCCESS:
      return {
        ...state,
        elementInformationList: {
          ...state.elementInformationList,
          updateAll: {
            loading: false,
            success: true,
            error: null,
          }
        }
      };
    case a.UPDATE_ALL_ELEMENT_INFORMATION.FAILURE:
      return {
        ...state,
        elementInformationList: {
          ...state.elementInformationList,
          updateAll: {
            loading: false,
            success: false,
            error: action.error,
          }
        }
      };
    case a.UPDATE_ALL_ELEMENT_INFORMATION.RESET:
      return {
        ...state,
        elementInformationList: {
          ...state.elementInformationList,
          update: INITIAL_STATE.elementInformationList.update,
          updateAll: INITIAL_STATE.elementInformationList.updateAll,
        },
        updateProcessedFeed: {
          ...state.updateProcessedFeed,
          update: INITIAL_STATE.updateProcessedFeed.update,
        },
      };
    // CYCONIA TRAINING - ELEMENT INFO FEEDBACK STATS
    case a.GET_FEEDS_STAT_INFO.REQUEST:
      return {
        ...state,
        feedsStatInfo: {
          data: null,
          get: {
            loading: true,
            success: false,
            error: null,
          }
        }
      };
    case a.GET_FEEDS_STAT_INFO.SUCCESS:
      return {
        ...state,
        feedsStatInfo: {
          data: action.feedsStatInfo,
          get: {
            loading: false,
            success: true,
            error: null,
          }
        }
      };
    case a.GET_FEEDS_STAT_INFO.FAILURE:
      return {
        ...state,
        feedsStatInfo: {
          data: null,
          get: {
            loading: false,
            success: false,
            error: action.error,
          }
        }
      };
    // CYCONIA TRAINING - ELEMENT INFO FEEDBACK LIST (BY LIST)
    case a.GET_FEEDS_LIST_BY_SITE.REQUEST:
      return {
        ...state,
        feedsListBySite: {
          data: null,
          get: {
            loading: true,
            success: false,
            error: null,
          }
        }
      };
    case a.GET_FEEDS_LIST_BY_SITE.SUCCESS:
      return {
        ...state,
        feedsListBySite: {
          data: action.feedsListBySite,
          get: {
            loading: false,
            success: true,
            error: null,
          }
        }
      };
    case a.GET_FEEDS_LIST_BY_SITE.FAILURE:
      return {
        ...state,
        feedsListBySite: {
          data: null,
          get: {
            loading: false,
            success: false,
            error: action.error,
          }
        }
      };
    // CYCONIA TRAINING - ELEMENT INFO BY EIID
    case a.GET_ELEMENT_INFORMATION_RESPONSE_BY_ID.REQUEST:
      return {
        ...state,
        elementInformationResponseById: {
          data: null,
          get: {
            loading: true,
            success: false,
            error: null,
          }
        }
      };
    case a.GET_ELEMENT_INFORMATION_RESPONSE_BY_ID.SUCCESS:
      return {
        ...state,
        elementInformationResponseById: {
          data: action.elementInformationResponseById,
          get: {
            loading: false,
            success: true,
            error: null,
          }
        },
        updateEiActivation: {
          ...state.updateEiActivation,
          isActive: action.elementInformationResponseById.active,
        }
      };
    case a.GET_ELEMENT_INFORMATION_RESPONSE_BY_ID.FAILURE:
      return {
        ...state,
        elementInformationResponseById: {
          data: null,
          get: {
            loading: false,
            success: false,
            error: action.error,
          }
        }
      };
    // CYCONIA TRAINING - ELEMENT INFO EIID BY COMPANY SITE
    case a.GET_ELEMENT_INFORMATION_BY_COMPANY.REQUEST:
      return {
        ...state,
        elementInformationByCompany: {
          data: null,
          get: {
            loading: true,
            success: false,
            error: null,
          }
        }
      };
    case a.GET_ELEMENT_INFORMATION_BY_COMPANY.SUCCESS:
      return {
        ...state,
        elementInformationByCompany: {
          data: action.elementInformationByCompany,
          get: {
            loading: false,
            success: true,
            error: null,
          }
        }
      };
    case a.GET_ELEMENT_INFORMATION_BY_COMPANY.FAILURE:
      return {
        ...state,
        elementInformationByCompany: {
          data: null,
          get: {
            loading: false,
            success: false,
            error: action.error,
          }
        }
      };
    // CYCONIA TRAINING - ELEMENT INFO ACTIVATION UPDATE
    case a.UPADATE_ELEMENT_INFORMATION_ACTIVATION.REQUEST:
      return {
        ...state,
        updateEiActivation: {
          isActive: false,
          get: {
            loading: true,
            success: false,
            error: null,
          }
        }
      };
    case a.UPADATE_ELEMENT_INFORMATION_ACTIVATION.SUCCESS:
      console.log('isActive reducer', action.updateEiActivation.message.split(' ')[1])
      return {
        ...state,
        updateEiActivation: {
          isActive: action.updateEiActivation.message.split(' ')[1] === "activated",
          get: {
            loading: false,
            success: true,
            error: null,
          }
        }
      };
    case a.UPADATE_ELEMENT_INFORMATION_ACTIVATION.FAILURE:
      return {
        ...state,
        updateEiActivation: {
          isActive: false,
          get: {
            loading: false,
            success: false,
            error: action.error,
          }
        }
      };
    // CYCONIA TRAINING - FEEDBACKS PROCESSED UPDATE
    case a.UPADATE_FEEDBACK_PROCESSED.REQUEST:
      return {
        ...state,
        updateProcessedFeed: {
          update: {
            loading: true,
            success: false,
            error: null,
          }
        }
      };
    case a.UPADATE_FEEDBACK_PROCESSED.SUCCESS:
      return {
        ...state,
        updateProcessedFeed: {
          update: {
            loading: false,
            success: true,
            error: null,
          }
        }
      };
    case a.UPADATE_FEEDBACK_PROCESSED.FAILURE:
      return {
        ...state,
        updateProcessedFeed: {
          update: {
            loading: false,
            success: false,
            error: action.error,
          }
        }
      };
    case a.SET_TITLE_INFORMATION_ELEMENT:
      return {
        ...state,
        setInformationElementTitle: {
          data: action.informationTitle,
        }
      };
    case a.RESET_EDIT_TRAINING:
      return {
        ...state,
        elementInformationList: {
          ...INITIAL_STATE.elementInformationList,
          data: [],
        },
        updateEiResponse: {
          ...INITIAL_STATE.updateEiResponse,
          data: "",
        },
        updateEiActivation: {
          ...INITIAL_STATE.updateEiActivation,
          data: {},
        },
        feedsStatInfo: {
          ...INITIAL_STATE.feedsStatInfo,
          data: {},
        },
      };
    case a.GET_SPECIFIC_CASE.REQUEST:
      return {
        ...state,
        specificCase: {
          data: null,
          get: {
            loading: true,
            success: false,
            error: null,
          }
        },
      };
    case a.GET_SPECIFIC_CASE.SUCCESS:
      return {
        ...state,
        specificCase: {
          data: action.data,
          get: {
            loading: false,
            success: true,
            error: null,
          }
        },
      };
    case a.GET_SPECIFIC_CASE.FAILURE:
      return {
        ...state,
        specificCase: {
          data: null,
          get: {
            loading: false,
            success: false,
            error: action.error,
          }
        },
      };
    case a.UPDATE_SPECIFIC_CASE.REQUEST:
      return {
        ...state,
        specificCase: {
          data: state.specificCase.data,
          get: {
            loading: true,
            success: false,
            error: null,
          }
        },
      };
    case a.UPDATE_SPECIFIC_CASE.SUCCESS:
      return {
        ...state,
        specificCase: {
          data: { ...state.specificCase.data, items: _.orderBy(_.unionBy([action.data], state.specificCase.data.items, "id"), ['id'], ['asc']) },
          get: {
            loading: false,
            success: true,
            error: null,
          }
        },
      };
    case a.UPDATE_SPECIFIC_CASE.FAILURE:
      return {
        ...state,
        specificCase: {
          data: state.specificCase.data,
          get: {
            loading: false,
            success: false,
            error: action.error,
          }
        },
      };
    case a.DELETE_SPECIFIC_CASE.REQUEST:
      return {
        ...state,
        specificCase: {
          data: state.specificCase.data,
          get: {
            loading: true,
            success: false,
            error: null,
          }
        },
      };
    case a.DELETE_SPECIFIC_CASE.SUCCESS:
      return {
        ...state,
        specificCase: {
          data: { ...state.specificCase.data, items: state.specificCase.data.items.filter((item) => item.id !== action.data) },
          get: {
            loading: false,
            success: true,
            error: null,
          }
        },
      };
    case a.DELETE_SPECIFIC_CASE.FAILURE:
      return {
        ...state,
        specificCase: {
          data: state.specificCase.data,
          get: {
            loading: false,
            success: false,
            error: action.error,
          }
        },
      };
    default:
      return state;
  }
}

export default adminFront;
