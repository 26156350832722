import React, { useState, useEffect } from 'react'
import { Formik, Field } from 'formik';

import GenericTextField from '../../../../../../components/molecules/GenericTextField';
import GenericMultipleSelect from '../../../../../../components/molecules/GenericMultipleSelect';
import GenericDrawerValidationButtons from '../../../../../../components/molecules/GenericDrawerValidationButtons';

import {
  StyledAccordion,
  StyledForm,
  InputGroup
} from './style'

const ProviderFilter = ({
  servicesData,
  sitesData,
  setFilterConditions
}) => {
  const [servicesList, setServicesList] = useState([]);
  const [sitesList, setSitesList] = useState([]);

  useEffect(() => {
    if (servicesData != null) {
      const newList = servicesData.map((service) => {
        return { label: service.name, value: service.id }
      })
      setServicesList(newList)
    }
  }, [servicesData])

  useEffect(() => {
    if (sitesData != null) {
      const newList = sitesData.map((site) => {
        return { label: site.name, value: site.id }
      })
      setSitesList(newList)
    }
  }, [sitesData])

  const formInitValue = {
    services: [],
    sites: [],
    company: "",
    manager: "",
  }

  const handleResetForm = (props) => {
    props.resetForm();
    setFilterConditions(formInitValue);
  }

  return (
    <StyledAccordion
      headerComponent={"Recherche"}
      detailComponent={
        <Formik
          initialValues={formInitValue}
          setFieldValue
          onSubmit={(values) => setFilterConditions(values)}
        >
          {props => (
            <StyledForm>
              <InputGroup>
                <GenericMultipleSelect
                  id="services"
                  name="services"
                  onChange={(e, value) => props.setFieldValue('services', value)}
                  value={props.values.services}
                  list={servicesList}
                  fildName="services"
                  title="Services"
                />
                <GenericMultipleSelect
                  id="sites"
                  name="sites"
                  onChange={(e, value) => props.setFieldValue('sites', value)}
                  value={props.values.sites}
                  limitItem={5}
                  hasSelectAll={true}
                  list={sitesList}
                  fildName="sites"
                  title="Sites"
                />
              </InputGroup>

              <InputGroup>
                <Field
                  id="company"
                  name="company"
                  title="Prestataires"
                  placeholder="ex: Microsoft"
                  variant="outlined"
                  size="small"
                  as={GenericTextField}
                />
                <Field
                  id="manager"
                  name="manager"
                  title="Responsable"
                  placeholder="ex: Jack Black"
                  variant="outlined"
                  size="small"
                  as={GenericTextField}
                />
              </InputGroup>

              <GenericDrawerValidationButtons
                leftButonText={'Réinitialiser'}
                RightButtonText={'Chercher'}
                onclickLefttButton={() => handleResetForm(props)}
                submitable={false}
              />
            </StyledForm>
          )}
        </Formik>
      }
    />
  )
}

export default ProviderFilter
