import React, {useEffect, useState, useContext} from 'react';
import {
  shallowEqual,
  useDispatch,
  useSelector,
} from "react-redux";
import _ from 'lodash';

// Import services
// import getActiveInfoElement from '../../services/getActiveInfoEl.service';

// Components
import DonutChart from '../../../../components/molecules/donutChart';
import BarChart from '../../../../components/molecules/barChart';

// Import context
// import { Context as EditorContext } from '../../../../context/editorState';

import {
  getActiveInfoElementDataByCode,
} from '../../../../../../store/adminFront/selectors';
import {
  getActiveInfoElement
} from '../../../../../../store/adminFront/actions';

// Styles
import {
  Container,
  Title,
  Content,
  BarChartContainer,
  DonutChartRH
} from "./style";

const InfoActiveStats = () => {
  const dispatch = useDispatch();
  const {
    activeInfoElement,
  } = useSelector(
    state => ({
      activeInfoElement: getActiveInfoElementDataByCode(state, ['Ressources Humaines', 'Services Généraux']),
    }),
    shallowEqual
  );

  useEffect(() => {
      dispatch(getActiveInfoElement());
  }, []);

  // const totalActiveCompetence  = activeInfoElement?.total;
  let totalActiveCompetence = 0;
  _.map(activeInfoElement, (info) => { totalActiveCompetence += info.active });
  const domainCompetence  = activeInfoElement 
  ? Object.values(activeInfoElement).filter(activeInfoElement => activeInfoElement?.total)
  : null;

  // TESTING //
  // if (domainCompetence)
  //   domainCompetence = [ ...domainCompetence, ...domainCompetence ];

  // console.log('activeInfoElement', activeInfoElement);
  // console.log('domainCompetence', domainCompetence);

  return (
    <Container>
      <Title>
          Les sujets activés
      </Title>
      <Content>
        <DonutChart
          value={totalActiveCompetence}
          globalValue={totalActiveCompetence}
          size={56}
          strokeWidth={8}
          colorText={'#ffffff'}
          strokeColorBg={'rgba(0,0,0,0.1)'}
          strokeColorValue={'#40B176'}
          valueLabelOutside={'au total'}
        />

        <BarChartContainer>
          {domainCompetence && domainCompetence.map((item, index) => {
            const {
              total,
              title,
              active
            } = item;

            const titles = title.split(" ");
            const chartTitle = titles.length > 1 ? `${titles[0][0].toUpperCase()}. ${titles[1]}` : titles[0];

            return(
              /*<BarChart
                key={index}
                valueBarChart={active}
                globalValueRatio={totalActiveCompetence}
                valueLabelDown={`${title} - ${active} éléments activés`}
                colorTextDown={'#ffffff'}
                maxWidthSize={260}
                heightSize={10}
                colorBg={'#ffffff'}
                colorValue={'#357ED3'}
              />*/
              <DonutChartRH
                key={index}
                value={active}
                globalValue={totalActiveCompetence}
                size={56}
                strokeWidth={8}
                colorText={'#ffffff'}
                strokeColorBg={'rgba(0,0,0,0.1)'}
                strokeColorValue={'#357ED3'}
                valueLabelOutside={chartTitle}
              />
            )
          })}
        </BarChartContainer>

      </Content>
    </Container>
  );
}

export default InfoActiveStats;
