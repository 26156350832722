import React from "react";
import {
  useDispatch,
} from "react-redux";
import CancelIcon from '@material-ui/icons/Cancel';

import {
  setSelectedParole,
} from '../../../store/sidebar/actions';

import NewsContainer from "../NewsContainer";

//Import Style
import {
  CustomDialog,
  StyledCloseContainer,
} from './style'

const NewsModal = ({ newsData }) => {
  const dispatch = useDispatch();

  const handleModalClose = () => {
    dispatch(setSelectedParole());
  }

  return (
    <CustomDialog
      open
      onClose={handleModalClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <StyledCloseContainer onClick={handleModalClose}>
        <CancelIcon style={{ fontSize: 30 }} />
      </StyledCloseContainer>
      <NewsContainer newsData={newsData}/>
    </CustomDialog >
  )
}

export default NewsModal
