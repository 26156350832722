import React from 'react'
import PropTypes from 'prop-types';

import {
  Container
} from './style'

const GenericAnimatedTextBubble = ({
  text,
  isIndicatorVisible,
  arrowPosition,
  boxPosition,
  animationData,
  className,
  children
}) => {
  return (
    <Container
      className={`${className ? className : ''} genericTextBubble`}
      isIndicatorVisible={isIndicatorVisible}
      animationData={animationData}
      arrowPosition={arrowPosition}
      boxPosition={boxPosition}
    >
      {children}
    </Container>
  )
}

GenericAnimatedTextBubble.propTypes = {
  text: PropTypes.object,
  isIndicatorVisible: PropTypes.bool,
  position: PropTypes.string,
  className: PropTypes.string,
}

export default GenericAnimatedTextBubble
