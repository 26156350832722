import React from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { FormControl, Box, InputLabel, Select, MenuItem } from '@material-ui/core'
import { Formik, Form, Field, useFormikContext } from 'formik';
import { NavLink } from "react-router-dom";
import _ from 'lodash'
import { useHistory } from "react-router";

import { jsonSafeParse } from '../../../../../../../../../../helpers/jsonSafeParse';

import GenericTextArea from '../../../../../../../../../../components/molecules/GenericTextArea';
import GenericTitleWithToolTip from '../../../../../../../../../../components/molecules/GenericTitleWithToolTip'
import GenericMaterialMenu from "../../../../../../../../../../components/molecules/GenericMaterialMenu";

import { ticketStatusArray, finalTicketStatus } from '../../../../../../../../../../constants/ticketConstant';

import {
  StyledAccordion,
  StyledAccordionSummary,
  CircleDiv,
  StyledAccordionTitle,
  StyledSummaryButtonContainer,
  StyledButton,
  StyledBox,
  StyledSelect,
  StyledFormControl,
  ProviderSelector,
  Wrapper,
  FormContainer
} from "./style"

const TicketProviderInfo = ({ ticketRequest, providerData, subDomain, onTicketSave }) => {
  const [selectedProvider, setSelectedProvider] = React.useState("");
  const [currentNote, setCurrentNote] = React.useState("");
  const [providerList, setProviderList] = React.useState([]);
  const history = useHistory();

  React.useEffect(() => {
    if (providerData && ticketRequest?.service_id) {
      const listProvider = providerData.filter((provider) => _.includes(jsonSafeParse(provider.services), String(ticketRequest.service_id))).map((item) => {
        return { label: item.company, value: item.id }
      })
      let finalList = []

      if (ticketRequest?.provider_sheet === null) {
        finalList = [
          {
            label: `Sélectionner un prestataire`,
            value: '---'
          },
          ...listProvider,
          {
            label: <NavLink to={'/espace-conciergerie/directory-services/new'}> <a >Ajouter un prestataire +</a></ NavLink>,
            value: 'link'
          }
        ]
      } else {
        const providerSheet = jsonSafeParse([ticketRequest?.provider_sheet]);
        setCurrentNote(providerSheet?.note || "")
        setSelectedProvider(providerSheet?.selectedProvider || "")
        const currentProvider = listProvider.find((el) => el.value === providerSheet?.selectedProvider);

        const newListProvider = listProvider.filter((el) => el.value !== providerSheet?.selectedProvider)
        finalList = [
          {
            label: currentProvider?.label || `Sélectionner un prestataire`,
            value: currentProvider?.value || '---'
          },
          ...newListProvider,
          {
            label: <NavLink to={'/espace-conciergerie/directory-services/new'}> <a >Ajouter un prestataire +</a></ NavLink>,
            value: 'link'
          }
        ]

      }
      setProviderList(finalList)
    }
  }, [providerData, ticketRequest])

  const formInitValue =
  {
    selectedProvider: selectedProvider || "",
    note: currentNote || ""
  }

  return (
    <StyledAccordion>
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <CircleDiv><FlashOnIcon style={{ fontSize: '1rem' }} /></CircleDiv>
        <StyledAccordionTitle>{`Mon prestataire`}</StyledAccordionTitle>
      </StyledAccordionSummary>
      <AccordionDetails>

        <Formik
          initialValues={formInitValue}
          enableReinitialize
          setFieldValue
          // validationSchema={newContentActuSchema}
          onSubmit={(values) => onTicketSave(values, 'ticket_provider')}
        >
          {(props) => {
            return (
              <Wrapper>
                <Form>
                  <FormContainer>
                    <ProviderSelector >
                      {providerList.length > 0 &&
                        <GenericMaterialMenu
                          title={"Societé"}
                          list={providerList}
                          onChange={(value) => props.setFieldValue('selectedProvider', value)}
                          disabled={ticketRequest.status === finalTicketStatus || ticketRequest.status === ticketStatusArray[0]}
                        />
                      }
                      {ticketRequest.status !== finalTicketStatus && ticketRequest.status !== ticketStatusArray[0] &&
                        <NavLink to={'/espace-conciergerie/directory-services/new'}>
                          <a>Ajouter un prestataire + </a>
                        </NavLink>
                      }
                    </ProviderSelector >
                    <Field
                      id="note"
                      name="note"
                      title="Note"
                      variant="outlined"
                      placeholder="Ajouter une note"
                      minRows={4}
                      as={GenericTextArea} />
                    <StyledSummaryButtonContainer type="formik">
                      <StyledButton
                        type="submit"
                        disabled={ticketRequest.status === finalTicketStatus || ticketRequest.status === ticketStatusArray[0]}
                      >
                        Enregistrer
                      </StyledButton>
                    </StyledSummaryButtonContainer>
                  </FormContainer>
                </Form>
              </Wrapper>
            )
          }}
        </Formik>
      </AccordionDetails>
    </StyledAccordion>
  )
}

export default TicketProviderInfo
