import { all, takeEvery } from "redux-saga/effects";

/** Actions */
import * as a from './actions';

//Import handlers
import {
  getUserRoomsHandler,
} from './handler/roomHandler';

import {
  getBookingsHandler,
  getBookingsByDateHandler,
  getBookingsByRangeDateHandler,
  saveBookingHandler,
  cancelBookingHandler
} from './handler/bookingHandler';

export default function* roomBookingSaga() {
  yield all([
    takeEvery(a.GET_USER_ROOMS.REQUEST, getUserRoomsHandler),
    takeEvery(a.GET_BOOKINGS.REQUEST, getBookingsHandler),
    takeEvery(a.GET_BOOKINGS_BY_DATE.REQUEST, getBookingsByDateHandler),
    takeEvery(a.GET_BOOKINGS_BY_RANGE_DATE.REQUEST, getBookingsByRangeDateHandler),
    takeEvery(a.SAVE_BOOKING.REQUEST, saveBookingHandler),
    takeEvery(a.CANCEL_BOOKING.REQUEST, cancelBookingHandler),
  ]);
}
