import styled from "styled-components";

export const StyledMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: calc(100% - 80px);
  padding: 60px 0 20px 0;
  box-sizing: border-box;
  flex-flow: column nowrap;
  > :first-child {
    margin-top: auto !important;
  }
  scrollbar-width: none;
  -ms-overflow-style: none; /* IE and Edge */
  ::-webkit-scrollbar { // for Safari
    -webkit-appearance: none;
    width: 0;
    height: 0;
  }
`;