import _ from 'lodash';

import {
  DEFAULT_LANDING,
  NAV_BAR_KEYS,
  NAV_BAR_ROUTES,
  NAV_BAR_BLOCKS_LIST,
  BLOCK_KEYS
} from "../../constants/sideBarConstants";

import {
  COMPANY_SETTINGS
} from "../../constants/companySettingConstants";

export const getCompanyData = state => state.company?.data;
export const getCompanyIsLoading = state => state.company?.init.loading;

export const getCompanyModules = state => state.company?.modules?.data;
export const getCompanyModulesIsLoading = state => state.company?.modules?.get.loading;

export const getCompanyContextData = state => state.company?.context?.data;
export const getCompanyContextIsLoading = state => state.company?.context.get.loading;
export const getCompanyContextSuccess = state => state.company?.context.get.success;

export const getCompanySettings = (state) => {
  const companySetings = state.company?.data?.settings?.settings;
  const newSettings = {};

  _.map(COMPANY_SETTINGS, (data, key) => {
    const currentSettings = companySetings?.[key];
    newSettings[key] = currentSettings ? currentSettings.active : false;
  })

  return newSettings;
}

export const getLandingRoute = (state) => {
  let landingRoute = `/${DEFAULT_LANDING}`;
  const currentContext = state.company?.context?.data;
  if (currentContext) {
    landingRoute = `/${NAV_BAR_ROUTES[currentContext.landingRoute]?.route || DEFAULT_LANDING}`;
  }
  return landingRoute;
}

export const getContextSetting = (state) => {
  const currentContext = state.company?.context?.data;
  const newSettings = {};

  _.map(NAV_BAR_ROUTES, (data, key) => {
    const currentNavBar = currentContext?.navBar[key];
    newSettings[key] = currentNavBar ? currentNavBar.active : true;
  })

  return newSettings;
}

export const getDashboardContext = (state) => {
  const currentContext = state.company?.context?.data;
  const currentNavBar = currentContext?.navBar[NAV_BAR_KEYS.DASHBOARD];
  const newSettings = {
    [BLOCK_KEYS.WEATHER]: true,
    [BLOCK_KEYS.SERVICE]: true,
    [BLOCK_KEYS.TRANSPORT]: true,
    [BLOCK_KEYS.NEWS]: true,
    [BLOCK_KEYS.ACCOUNT_DISCONNECT]: false,
  };

  _.map(NAV_BAR_BLOCKS_LIST[NAV_BAR_KEYS.DASHBOARD], (blockName) => {
    const currentBlock = currentNavBar?.blocks[blockName];
    newSettings[blockName] = currentBlock ? currentBlock.active : newSettings[blockName];
    // newSettings[blockName] = currentBlock ? currentBlock.active : true;
  })

  return newSettings;
};

export const getConciergeContext = (state) => {
  const currentContext = state.company?.context?.data;
  const currentNavBar = currentContext?.navBar[NAV_BAR_KEYS.CONCIERGE];
  const newSettings = {
    [BLOCK_KEYS.WEATHER]: false,
    [BLOCK_KEYS.MY_CONCIERGE]: true,
    [BLOCK_KEYS.MY_ORDER]: true,
    [BLOCK_KEYS.BORNE]: true,
    [BLOCK_KEYS.CONCIERGE_SERVICES]: true,
    [BLOCK_KEYS.NEWS]: true,
    [BLOCK_KEYS.ACCOUNT_DISCONNECT]: false,
  };

  _.map(NAV_BAR_BLOCKS_LIST[NAV_BAR_KEYS.CONCIERGE], (blockName) => {
    const currentBlock = currentNavBar?.blocks[blockName];
    newSettings[blockName] = currentBlock ? currentBlock.active : newSettings[blockName];
    // newSettings[blockName] = currentBlock ? currentBlock.active : true;
    // if (blockName === BLOCK_KEYS.NEWS) { // special case
    //   if (!currentBlock) {
    //     newSettings[blockName] = false;
    //   }
    // }
  })

  return newSettings;
};

export const getSkillContext = (state) => {
  const currentContext = state.company?.context?.data;
  const currentNavBar = currentContext?.navBar[NAV_BAR_KEYS.SKILL];
  const newSettings = {
    [BLOCK_KEYS.SKILL_CATALOG]: true,
    [BLOCK_KEYS.SKILL_RH]: false,
    [BLOCK_KEYS.SKILL_SG]: false,
  };

  _.map(NAV_BAR_BLOCKS_LIST[NAV_BAR_KEYS.SKILL], (blockName) => {
    const currentBlock = currentNavBar?.blocks[blockName];
    newSettings[blockName] = currentBlock ? currentBlock.active : newSettings[blockName];
  })

  return newSettings;
};

export const getAccountContext = (state) => {
  const currentContext = state.company?.context?.data;
  const currentNavBar = currentContext?.navBar[NAV_BAR_KEYS.ACCOUNT];
  const newSettings = {
    [BLOCK_KEYS.WEATHER]: false,
    [BLOCK_KEYS.ACCOUNT_INFO]: true,
    [BLOCK_KEYS.ACCOUNT_PROFILE]: true,
    [BLOCK_KEYS.ACCOUNT_TRAVEL]: true,
    [BLOCK_KEYS.ACCOUNT_DISCONNECT]: true,
  };

  _.map(NAV_BAR_BLOCKS_LIST[NAV_BAR_KEYS.ACCOUNT], (blockName) => {
    const currentBlock = currentNavBar?.blocks[blockName];
    newSettings[blockName] = currentBlock ? currentBlock.active : newSettings[blockName];
  })

  return newSettings;
};

export const getConsoleContext = (state) => {
  const currentContext = state.company?.context?.data;
  const currentNavBar = currentContext?.navBar[NAV_BAR_KEYS.CONSOLE];
  const newSettings = {
    [BLOCK_KEYS.WEATHER]: false,
    [BLOCK_KEYS.ACCOUNT_DISCONNECT]: false,
  };

  _.map(NAV_BAR_BLOCKS_LIST[NAV_BAR_KEYS.CONSOLE], (blockName) => {
    const currentBlock = currentNavBar?.blocks[blockName];
    newSettings[blockName] = currentBlock ? currentBlock.active : newSettings[blockName];
  })

  return newSettings;
};

export const getHelperContext = state => state.company?.context?.data?.helper;