import React from "react";

import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useHistory
} from "react-router-dom";

import UserEditDrawer from "./components/UserEditDrawer";

const Routes = () => {
  const history = useHistory();
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/edit/:collaboratorId?`} render={() => (
        <UserEditDrawer
          visible
          closeFunction={() => history.push(`${path}`)}
        />
      )}
      />
      <Redirect to={`${path}`} />
    </Switch>
  );
}

export default Routes;
