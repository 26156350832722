import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import GenericTitleWithToolTip from '../GenericTitleWithToolTip';
import GenericErrorMessage from '../GenericErrorMessage';

import {
  MainContainer,
} from './style';

const GenericCheckBoxes = ({
  title = "",
  toolTipText = null,
  isRequired,
  choices = [],
  value, // for the initial value
  className, // to pass styled component className to mainContainer
  onChange,
  ...rest
}) => {

  return (
    <MainContainer className={`${className ? className : ''} genericCheckBoxes`}>
      <GenericTitleWithToolTip
        title={title}
        toolTipText={toolTipText}
        isRequired={isRequired}
        className={'textFieldTitle'}
      />
      <FormGroup onChange={onChange} {...rest}>
        {_.map(choices, (choice, index) => {
          return (
            <FormControlLabel key={index} control={<Checkbox checked={value.includes(choice.value)}/>} {...choice} />
          )
        })}
      </FormGroup>
      {rest.id && <GenericErrorMessage name={rest.id} />}
    </MainContainer>
  );
}

GenericCheckBoxes.propTypes = {
  title: PropTypes.string,
  toolTipText: PropTypes.string,
  isRequired: PropTypes.bool,
  choices: PropTypes.array,
  value: PropTypes.array,
  className: PropTypes.string,
  onChange: PropTypes.func,
}

export default GenericCheckBoxes;


// const exampleChoice = [
//   { label: 'site1 label', value: 'site1' },
//   { label: 'site2 label', value: 'site2' },
//   { label: 'site3 label', value: 'site3' }
// ]

// const exampleValue = ["site1", "site2", "site3"]