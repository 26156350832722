import React, { useEffect, useMemo } from 'react';
import {
  useParams,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import {
  shallowEqual,
  useDispatch,
  useSelector,
} from "react-redux";

// Layout
import DetailsSidebarFrontBaseLayout from "../../layouts/detailsSidebarFrontBaseLayout";

// Components
// import ButtonCpt from '../../components/molecules/buttonCpt';
import BackIcon from '../../components/atoms/icons/back';
import SendIcon from '../../components/atoms/icons/send';

// Hooks
import useTheme from "../../../../theme-style/useThemeCustomHook";

// Store
import {
  getExpertiseAreas,
  getExpertiseAreasTheme,
} from '../../../../store/sidebar/actions';

import {
  getExpertiseAreasData,
  getExpertiseAreasThemeData,
} from '../../../../store/sidebar/selectors';

import {
  setMessageFromSideBar
} from '../../../../store/chatbot/actions';

// Styles
import {
  Container,
  SkillsWrapper,
  Header,
  BackButton,
  Title,
  BackIconWrapper,
  SkillsList,
  SkillsItem,
  SkillsItemCircle,
  SkillsItemContent,
  SkillsItemTitle,
  SkillsItemText,
  SendIconWrapper
} from "./style";

const CompetencesList = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { id } = useParams();
  const match = useRouteMatch();
  const location = useLocation();
  const baseUrl = `/${location.pathname.split('/')[1]}`;

  // console.log('match', match);
  // console.log('location', location);
  // console.log('baseUrl', baseUrl);

  const {
    expertiseAreas,
    expertiseAreasTheme,
  } = useSelector(
    state => ({
      expertiseAreas: getExpertiseAreasData(state),
      expertiseAreasTheme: getExpertiseAreasThemeData(state),
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!expertiseAreas) {
      dispatch(getExpertiseAreas());
    }
    dispatch(getExpertiseAreasTheme(id));
  }, []);

  const expertiseAreasSkillTitle = useMemo(() => {
    if (!!expertiseAreas?.length) {
      const allSkills = expertiseAreas.reduce((skillsItems, area) => {
        skillsItems.push(...area.skills);
        return skillsItems;
      }, []);
      return allSkills.find(x => parseInt(x.id, 10) === parseInt(id, 10))?.title;
    }
    return null;
  }, [expertiseAreas, id]);

  const onSkillsItemClick = (message) => {
    dispatch(setMessageFromSideBar(message));
  }

  return (
    <DetailsSidebarFrontBaseLayout
      id="detailsSidebarLayoutSkillsList"
      className="detailsSidebarLayoutSkillsList page-container sidebarPage"
    >
      <Container>
        <SkillsWrapper>
          <Header to={baseUrl}>
            <BackButton>
              <BackIconWrapper>
                <BackIcon color={theme.colors.white}/>
              </BackIconWrapper>
            </BackButton>
            <Title>
              {expertiseAreasSkillTitle}
            </Title>
          </Header>

          <SkillsList>
            {expertiseAreasTheme && expertiseAreasTheme.map((item, index) => {
              const {
                id,
                title,
                description,
                illustrationPhrase,
              } = item;
              return(
                <SkillsItem key={index} onClick={() => onSkillsItemClick(illustrationPhrase)}>
                  <SkillsItemCircle>
                    {title.charAt(0)}
                  </SkillsItemCircle>

                  <SkillsItemContent>
                    <SkillsItemTitle>
                      {title}
                    </SkillsItemTitle>
                    <SkillsItemText>
                      {description}
                    </SkillsItemText>
                  </SkillsItemContent>

                  <SendIconWrapper>
                    <SendIcon />
                  </SendIconWrapper>
                </SkillsItem>
              )
            })}
          </SkillsList>
        </SkillsWrapper>
      </Container>
    </DetailsSidebarFrontBaseLayout>
  );
}

export default CompetencesList;
