import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

// Styles
import {
  Container,
} from "./style";

const RadioCpt = ({
  title,
  radioList,
  defaultValueSelected,
  ...rest
}) => {
  const [valueRadioGroup, setValueRadioGroup] = React.useState(defaultValueSelected);

  const handleChange = (event) => {
    setValueRadioGroup(event.target.value);
  };

  // README: radioList exemple for call RadioGroup
  // const radioList = [
  //   {
  //     value: 'value1',
  //     label: 'label1',
  //     disabled: false,
  //   },
  //   {
  //     value: 'value2',
  //     label: 'label2',
  //     disabled: false,
  //   },
  // ];

  return (
    <FormControl component="fieldset">
      {title && (
        <FormLabel component="legend">{title}</FormLabel>
      )}
      <RadioGroup
        aria-label={title || valueRadioGroup || 'ariaLabelRadioGroup'}
        name={title || valueRadioGroup || 'nameRadioGroup'}
        value={valueRadioGroup}
        onChange={handleChange}
      >
      {radioList.map((item, index) => {
        const {
          name,
          label,
          disabled
        } = item;

        return(
          <FormControlLabel
            key={index}
            value={name}
            disabled={disabled}
            control={<Radio />}
            label={label}
            {...rest}
          />
        );
      })}
      </RadioGroup>
    </FormControl>
  );
}

export default RadioCpt;
