import React from "react";
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch
} from "react-router-dom";

// Containers
import HqoLogin from "./components/hqo-gecina/v1/Login";
import HqoRegister from "./components/hqo-gecina/v1/Register";
import Restricted from "./components/hqo-gecina/Restricted";

import AzureadAuth from "./components/saml/azuread/Auth";
import AzureadRegister from "./components/saml/azuread/Register";


const Routes = () => {
  const { path } = useRouteMatch();

  return (
    <React.Fragment>
      <Switch>
      <Route path={`${path}/hqo-gecina/restricted`} component={Restricted} />
        <Route path={`${path}/hqo-gecina/v1/auth/:jwtToken`} component={HqoLogin} />
        <Route path={`${path}/hqo-gecina/v1/register-confirmation`} component={HqoRegister} />
        <Route path={`${path}/saml/azuread/auth/:jwtToken`} component={AzureadAuth} />
        <Route path={`${path}/saml/azuread/register-confirmation`} component={AzureadRegister} />
        <Redirect to={`/`} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
