import axios from 'axios';

export const saveContextsService = (endpoint, userToken, formData) => {
  const newForm = new FormData();
  newForm.append('context', JSON.stringify(formData));

  // console.log('newForm', ...newForm);
  // console.log('formData', formData);
  const headers = {};
  if (userToken) {
    headers.Authorization = `Bearer ${userToken}`;
  }

  return axios.request({
    method: 'post',
    url: endpoint,
    data: newForm,
    headers: headers
  })
}