import styled from "styled-components";
import Dialog from '@material-ui/core/Dialog';

import GenericButton from "../../../../../../../components/molecules/GenericButton";
import { COLORS } from "../../../../../../../constants/design";

export const CustomDialog = styled(Dialog)`
  && {
    .MuiDialog-container {
      .MuiDialog-paper {
        &.MuiPaper-rounded {
          width: 100%;
          height: 100%;
        }
        &.MuiDialog-paperWidthSm {
          max-width: 800px;
        }
      }
    }
    .genericDialogContainer {
      height: 100%;
    }
  }
`;

export const StyledTitle = styled.div`
  font-size: 1.1rem;
  font-weight: 600;
  display: flex;
`;

export const ContentContainer = styled.div`
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  padding: 15px 0px;
  height: 100%;
  >:not(:last-child) {
    margin-bottom: 15px;
  };
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: row;
  .textTitle {
    font-weight: 600;
    font-size: 0.9rem;
  }
  .genericMaterialMenu {
    .textFieldTitle {
      margin-bottom: 10px;
    }
    width: 50%;
  }
  .genericTextField {
    width: 50%;
    input {
      height: 22px;
    }
  }
  >:not(:last-child) {
    margin-right: 15px;
  };
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  >:not(:last-child) {
    margin-right: 15px;
  };
`;

export const StyledUserInfoContainer = styled.div`
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: auto;
  >:not(:last-child) {
    margin-right: 15px;
  };
`;

export const StyledUserList = styled.div`
  border: 1px solid ${COLORS.LIGHT_GREY_4};
  border-radius: 5px;
  width: 50%;
  overflow: auto;
  padding: 10px;
  >:not(:last-child) {
    margin-bottom: 10px;
  };
`;

export const StyledUser = styled.div`
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  background-color: ${props => props.selected ? COLORS.LIGHT_BLUE_1 : 'transparent'};
  :hover {
    background-color: ${COLORS.LIGHT_BLUE_1};
  }
`;

export const StyledUserEmail = styled.div`
  font-size: 0.8rem;
`;

export const StyledUserInfo = styled.div`
  border: 1px solid ${COLORS.LIGHT_GREY_4};
  border-radius: 5px;
  /* background-color: ${COLORS.YELLOW_1}; */
  width: 50%;
  overflow: auto;
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  >:not(:last-child) {
    margin-bottom: 10px;
  };
`;

export const SeletecUserContainer = styled.div`
  /* border: 1px solid ${COLORS.LIGHT_GREY_4}; */
  /* box-sizing: border-box; */
  height: 90%;
  .gtwi-iconContainer {
    margin-right: 10px;
  }
  .gtwi-titleContainer {
    max-width: 100%;
  }
  >:not(:last-child) {
    margin-bottom: 10px;
  };
`;

export const StyledButton = styled(GenericButton)`
  align-self: flex-end;
  && {
    &.MuiButton-root {
      width: 100%;
    }
  }
`;

export const EmptyDataContainer = styled.div`
  font-weight: 600;
`;

export const StyledSuggestionContainer = styled.div`
  position: relative;
  width: 100%;
  .genericTextBubble {
    width: 220px;
  }
`;