import React from "react";

// Import style
import { StyledLoader } from './Loader.style';

const Loader = props => {
    return (
        <StyledLoader>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </StyledLoader>
    )
}

export default Loader;
