import React, { useEffect, useState } from 'react';
import {
  shallowEqual,
  useDispatch,
  useSelector,
} from "react-redux";
import _ from "lodash";

// //Import Component
import GenericAdminFrontHeader from '../../../../../../components/molecules/GenericAdminFrontHeader';
import GenericCheckbox from '../../../../../../components/molecules/GenericCheckbox';
import GenericTextArea from '../../../../../../components/molecules/GenericTextArea';

import {
  COMPANY_SETTINGS,
} from '../../../../../../constants/companySettingConstants';

import { saveCompanySettings } from '../../../../../../store/company/actions';
import {
  getCompanyData,
  getCompanyIsLoading
} from '../../../../../../store/company/selectors';

import {
  Container,
  DrawerCptCustom,
  Wrapper,
  StyledFormContainer,
  SettingContainer
} from './style';

const CompanyEditDrawer = ({
  visible,
  closeFunction,
  edit
}) => {
  const dispatch = useDispatch();
  const [companySettings, setCompanySettings] = useState(null);
  const [settingForm, setSettingForm] = useState([]);
  const [companyForm, setCompanyForm] = useState({
    emailDomain: ''
  });

  const {
    companyData,
    companyIsLoading
  } = useSelector(
    state => ({
      companyData: getCompanyData(state),
      companyIsLoading: getCompanyIsLoading(state)
    }),
    shallowEqual
  );

  useEffect(() => {
    if (companyData) {
      const newForm = {
        emailDomain: companyData.domains
      };

      setCompanySettings(companyData.settings);
      setCompanyForm(newForm);
    }
  }, [companyData]);

  useEffect(() => {
    const newForm = {};
    _.map(COMPANY_SETTINGS, (data, key) => {
      const currentSetting = companySettings?.settings?.[key];

      newForm[key] = {
        active: currentSetting ? currentSetting.active : false,
      };
    });
    setSettingForm(newForm);
  }, [companySettings]);

  const handleCheck = (event, key) => {
    const newForm = _.cloneDeep(settingForm);
    newForm[key].active = event.target.checked;
    setSettingForm(newForm);
  }

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    const newCompanyForm = _.cloneDeep(companyForm);
    newCompanyForm[name] = value;
    setCompanyForm(newCompanyForm);
  }

  const handleSubmit = () => {
    const formData = {
      settings: {
        version: 1,
        settings: settingForm,
      },
      companyForm,
    }
    dispatch(saveCompanySettings({ formData }));
  }

  const renderForm = () => {
    if (settingForm) {
      return _.map(settingForm, (data, key) => {
        return (
          <SettingContainer key={key} active={data.active}>
            <GenericCheckbox
              title={COMPANY_SETTINGS[key]?.label}
              onChange={(event) => handleCheck(event, key)}
              checked={data.active}
            />
          </SettingContainer>
        )
      });
    }
  };

  return (
    <Container>
      <DrawerCptCustom
        onOpen={visible}
        visible={visible}
        closeFunction={() => closeFunction()}
        className="companyEditDrawer"
      >
        <Wrapper>
          <GenericAdminFrontHeader headerTitle={`Company Settings`} />
          <StyledFormContainer
            isLoading={companyIsLoading}
            onClose={closeFunction}
            onSubmit={handleSubmit}
          >
            <>
              {renderForm()}
              <GenericTextArea
                name="emailDomain"
                title="Email Domain"
                variant="outlined"
                placeholder=""
                value={companyForm.emailDomain}
                minRows={4}
                onChange={(event) => handleFormChange(event)}
              />
            </>
          </StyledFormContainer>
        </Wrapper>
      </DrawerCptCustom>
    </Container>
  )
}

export default CompanyEditDrawer;