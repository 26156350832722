import React from 'react';
// import { Link } from "react-router-dom";

// Styles
import {
  ButtonCustom,
  LabelContainer,
  LabelText,
  StartIconContainer,
  EndIconContainer,
} from "./style";


const ContentButton= ({ appearence, startIcon, endIcon, label }) => (
  <LabelContainer appearence={appearence}>
    {startIcon && (
      <StartIconContainer noMargin={label === null}>
        {startIcon}
      </StartIconContainer>
    )}
    {label && (
      <LabelText>
        {label}
      </LabelText>
    )}
    {endIcon && (
      <EndIconContainer noMargin={label === null}>
        {endIcon}
      </EndIconContainer>
    )}
  </LabelContainer>
);

const ButtonCpt = ({
  label = null,
  appearence = 'primary',
  startIcon = null,
  endIcon = null,
  onClick = () => {},
  disabled,
  hrefTo,
  ...rest
}) => {
    const commonProps = {
      appearence,
    };

    return (
      <ButtonCustom
        onClick={onClick}
        disabled={disabled}
        to={hrefTo}
        {...commonProps}
        {...rest}
      >
        <ContentButton
          appearence={appearence}
          startIcon={startIcon}
          endIcon={endIcon}
          label={label}
        />
      </ButtonCustom>
    );
}

export default ButtonCpt;
