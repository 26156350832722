import React, { useEffect, useState } from 'react';
// import {
//   Link,
//   useRouteMatch,
//   NavLink
// } from "react-router-dom";

import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// import AppBar from '@material-ui/core/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

// Icons Components
import HomeIcon from '../../atoms/icons/home'
import DashboardIcon from '../../atoms/icons/dashboard'
import EducationIcon from '../../atoms/icons/education'
import FeedbackIcon from '../../atoms/icons/feedback'
import UserListIcon from '../../atoms/icons/userList'
import EditIcon from '../../atoms/icons/edit'
import MenuIcon from '../../atoms/icons/menu'
import NewsIcon from '../../atoms/icons/newsIcon';
import LogoutIcon from '../../../../../components/atoms/icons/logout';

import LogoutModal from '../../../../../components/organisms/LogoutModal';

// Styles
import {
  Container,
  Wrapper,
  WrapperDrawerAction,
  IconButtonCustom,
  DrawerCustom,
  ListCustom,
  ListItemCustom,
  ListItemCustomHome,
  ListItemIconCustom,
  ListItemTextCustom,
  HomeIconWrapper,
  DashboardIconWrapper,
  EducationIconWrapper,
  FeedbackIconWrapper,
  UserListIconWrapper,
  EditIconWrapper,
  MenuIconWrapper,
  TooltipCustom,
  NagivationButton,
  NagivationGroup,
  NagivationGroupTitle
} from "./style";

// const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: -10,
  },
  hide: {
    display: 'none',
  },
  spacingLeft: {
    Left: 6,
  },
}));

const SideNavbar = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [openLogoutModal, setOpenLogoutModal] = React.useState(false);
  // const { path } = useRouteMatch();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Container>
      <CssBaseline />
      <DrawerCustom
        variant="permanent"
        open={open}
      >
        <Wrapper>
          <ListCustom>
            <TooltipCustom
              title={open ? "" : "Mon espace Cyconia"}
              placement="right"
              className={classes.spacingLeft}
            >
              <ListItemCustomHome
                href="/"
                key="home"
              >
                <ListItemIconCustom >
                  <HomeIconWrapper >
                    <HomeIcon />
                  </HomeIconWrapper>
                </ListItemIconCustom>
                <ListItemTextCustom primary="Mon espace" />
              </ListItemCustomHome>
            </TooltipCustom>

            <NagivationGroup>
              <TooltipCustom
                title={open ? "" : "Tableau de bord"}
                placement="right"
                className={classes.spacingLeft}
              >
                <ListItemCustom
                  activeClassName="isActive"
                  to="/admin/dashboard"
                  key="dashboard"
                >
                  <ListItemIconCustom
                  >
                    <DashboardIconWrapper>
                      <DashboardIcon />
                    </DashboardIconWrapper>
                  </ListItemIconCustom>
                  <ListItemTextCustom primary="Tableau de bord" />
                </ListItemCustom>
              </TooltipCustom>

              <TooltipCustom
                title={open ? "" : "Formation de Cyconia"}
                placement="right"
                className={classes.spacingLeft}
              >
                <ListItemCustom
                  activeClassName="isActive"
                  // to="/admin/training/1"
                  to="/admin/training"
                  key="training"
                >
                  <ListItemIconCustom>
                    <EducationIconWrapper>
                      <EducationIcon />
                    </EducationIconWrapper>
                  </ListItemIconCustom>
                  <ListItemTextCustom primary="Formation de Cyconia" />
                </ListItemCustom>
              </TooltipCustom>

              <TooltipCustom
                title={open ? "" : "Liste des remarques"}
                placement="right"
                className={classes.spacingLeft}
              >
                <ListItemCustom
                  activeClassName="isActive"
                  to="/admin/feeds"
                  key="feeds"
                >
                  <ListItemIconCustom>
                    <FeedbackIconWrapper>
                      <FeedbackIcon />
                    </FeedbackIconWrapper>
                  </ListItemIconCustom>
                  <ListItemTextCustom primary="Liste des remarques" />
                </ListItemCustom>
              </TooltipCustom>

              <TooltipCustom
                title={open ? "" : "Gestion des utilisateurs"}
                placement="right"
                className={classes.spacingLeft}
              >
                <ListItemCustom
                  activeClassName="isActive"
                  to="/admin/users-management"
                  key="users-management"
                >
                  <ListItemIconCustom>
                    <UserListIconWrapper>
                      <UserListIcon />
                    </UserListIconWrapper>
                  </ListItemIconCustom>
                  <ListItemTextCustom primary="Gestion des utilisateurs" />
                </ListItemCustom>
              </TooltipCustom>

              <TooltipCustom
                title={open ? "" : "Gestion du contenu"}
                placement="right"
                className={classes.spacingLeft}
              >
                <ListItemCustom
                  activeClassName="isActive"
                  to="/admin/content-management"
                  key="content-management"
                >
                  <ListItemIconCustom>
                    <EditIconWrapper>
                      <NewsIcon />
                    </EditIconWrapper>
                  </ListItemIconCustom>
                  <ListItemTextCustom primary="Gestion du contenu" />
                </ListItemCustom>
              </TooltipCustom>
            </NagivationGroup>

            <NagivationGroup>
              <NagivationGroupTitle open={open}>
                {`Configurations`}
              </NagivationGroupTitle>
              <TooltipCustom
                title={open ? "" : "Cas spécifiques"}
                placement="right"
                className={classes.spacingLeft}
              >
                <ListItemCustom
                  activeClassName="isActive"
                  to="/admin/specific-smalltalk"
                  key="smalltalk"
                >
                  <ListItemIconCustom>
                    <EditIconWrapper>
                      <EditIcon />
                    </EditIconWrapper>
                  </ListItemIconCustom>
                  <ListItemTextCustom primary="Cas spécifiques" />
                </ListItemCustom>
              </TooltipCustom>

              <TooltipCustom
                title={open ? "" : "Page setting"}
                placement="right"
                className={classes.spacingLeft}
              >
                <ListItemCustom
                  activeClassName="isActive"
                  to="/admin/settings"
                  key="sidebarSetting"
                >
                  <ListItemIconCustom>
                    <EditIconWrapper>
                      <EditIcon />
                    </EditIconWrapper>
                  </ListItemIconCustom>
                  <ListItemTextCustom primary="Company and site setting" />
                </ListItemCustom>
              </TooltipCustom>
            </NagivationGroup>


            <TooltipCustom
              title={open ? "" : "Déconnexion"}
              placement="right"
              className={classes.spacingLeft}
            >
              <NagivationButton
                onClick={() => setOpenLogoutModal(!openLogoutModal)}
              >
                <ListItemIconCustom>
                  <EditIconWrapper>
                    <LogoutIcon color={"#2B2B2B"} />
                  </EditIconWrapper>
                </ListItemIconCustom>
                <ListItemTextCustom primary="Déconnexion" />
              </NagivationButton>
            </TooltipCustom>
          </ListCustom>
        </Wrapper>
        <WrapperDrawerAction>
          {
            open ?
              <IconButtonCustom onClick={handleDrawerClose}>
                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButtonCustom>
              :
              <IconButtonCustom
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: open,
                })}
              >
                <MenuIconWrapper>
                  <MenuIcon />
                </MenuIconWrapper>
              </IconButtonCustom>
          }
        </WrapperDrawerAction>
        <LogoutModal open={openLogoutModal} setOpenDialog={() => setOpenLogoutModal(!openLogoutModal)} />
      </DrawerCustom>
    </Container>
  );
}

export default SideNavbar;
