import React from "react";

const InfoBulleIcon = ({
  color = "#FFFFFF",
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon infoBulleIcon"
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M10.5 0c.828 0 1.5.672 1.5 1.5v6.818c0 .828-.672 1.5-1.5 1.5H7.91l-1.474 1.964a.545.545 0 01-.872 0L4.09 9.818H1.5c-.828 0-1.5-.672-1.5-1.5V1.5C0 .672.672 0 1.5 0zm0 1.09h-9a.41.41 0 00-.41.41v6.818a.41.41 0 00.41.41h2.864c.171 0 .333.08.436.217l1.2 1.6 1.2-1.6a.545.545 0 01.436-.218H10.5a.41.41 0 00.41-.409V1.5a.41.41 0 00-.41-.41zM6 3.819c.301 0 .545.244.545.546v3.272a.545.545 0 01-1.09 0V4.91h-.546a.545.545 0 110-1.09zm0-1.636a.545.545 0 110 1.09.545.545 0 010-1.09z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export default InfoBulleIcon;
