import _ from 'lodash';

// Import services
import getEiResponse from '../../../services/getEiResponse.service';
import getEiByIdService from '../../../services/getEiById.service';
import getEntrepriseSiteList from '../../../services/getEntrepriseSiteList.service';
import getFeedsStats from '../../../services/getFeedsStats.service';
import getFeedsListById from '../../../services/getFeedsListById.service';
import sendPositifFeed from '../../../services/sendPositifFeed.service';
import sendNegativeFeed from '../../../services/sendNegativeFeed.service';
import updateProcessedFeeds from '../../../services/updateProcessedFeeds.service';
import updateEiinformationService from '../../../services/updateEiinformation.service';
import { MESSAGE_ELEMENT_TYPE } from '../../../../../../constants/chatBotConstants';


export const setEiByIdService = (setEiData, Eiid) => {
  let data = [Eiid];

  getEiByIdService(JSON.stringify(data)).then(data => {
    if (data && data.length > 0) {
      setEiData(data);
    }
  });
}

export const setHeaderDataEi = (
  setFeedsList,
  setEiResponses,
  Eiid,
  setSelectedSite,
  setSitesList,
  setCurrResponse,
  setContentData,
  setIsNewPayload
) => {
  getEntrepriseSiteList().then(data => {
    let sites = [];
    if (data.companySites && data.companySites.length > 0) {
      sites.push({
        name: "Réponse principale de l'entreprise",
        value: "Entreprise générale",
        id: null
      })
      data.companySites.map(site => {
        sites.push({
          name: site.companySite,
          value: site.companySite,
          id: site.id
        })
      })
      setSelectedSite(sites[0]);
      setSitesList(sites);

      // Get response list
      getEiResponseFunc(setCurrResponse, setContentData, setIsNewPayload,
        Eiid, setEiResponses, sites, true)
      //                       ^
      //                       ^
      //                       |
      //                       |
      // REPLACE WITH getElementInformationResponseById action


      // Get feeds list
      getFeedsBySite(sites[0].id, Eiid, setFeedsList)

    }
  });
}

export const getFeedsBySite = (sitesId, Eiid, setFeedsList) => {
  getFeedsListById(sitesId, Eiid).then(data => {
    let notProcessed = [];

    if (data && data.length > 0)
      data.map(feed => {
        if (!feed.isProcessed)
          notProcessed.push(feed)
      })
    setFeedsList(notProcessed)
  })
}

// to allow new messageElements to work with current chatbot, will remove when newChatbot is done
export const convertMessageElementsToOldVersion = (messageElements) => {
  if (messageElements) {
    let convertedData = "";
    _.map(messageElements, (element, index) => {
      if (index !== 0) {
        if (messageElements.length === 2 && messageElements[index - 1].type === MESSAGE_ELEMENT_TYPE.TEXT && element.type !== MESSAGE_ELEMENT_TYPE.TEXT) {
          convertedData = convertedData.substring(0, convertedData.length - 4);
        }
        else if (element.type !== MESSAGE_ELEMENT_TYPE.TEXT) {
          convertedData = `${convertedData}<br><br>`;
        }
      }
      if (element.type === MESSAGE_ELEMENT_TYPE.TEXT) {
        convertedData = `${convertedData}${element.content}`;
      }
      else if (element.type === MESSAGE_ELEMENT_TYPE.BUTTON_SIMPLE) {
        convertedData = `${convertedData}[title::${element.title};; type::text;; userMessage::${element.userMessage};; `;
        if (element.messageElements.length !== 0) {
          convertedData = `${convertedData}response::${convertMessageElementsToOldVersion(element.messageElements)}]`
        }
        else {
          convertedData = `${convertedData}response::Exemple de réponse]`
        }
      }
      else if (element.type === MESSAGE_ELEMENT_TYPE.BUTTON_INTENT) {
        convertedData = `${convertedData}[title::${element.title};; type::intent;; userMessage::${element.userMessage}]`;
      }
      else if (element.type === MESSAGE_ELEMENT_TYPE.BUTTON_FORM) {
        convertedData = `${convertedData}[title::${element.title};; type::form;; action::${element.action};; service::${element.service}]`;
      }
      if (index !== 0) {
        if (messageElements.length === 2 && messageElements[index - 1].type === MESSAGE_ELEMENT_TYPE.TEXT && element.type !== MESSAGE_ELEMENT_TYPE.TEXT) {
          convertedData = `${convertedData}</p>`;
        }
      }
    });
    return convertedData;
  }
}

// handle new messageElements and old messageElements when received it from the server, will modify when newChatbot is done
export const handleResponseVersionV1 = (response, setContentData, setCurrResponse, setIsNewPayload) => {
  try {
    const parsedResponse = JSON.parse(response.response);
    // if (!parsedResponse.messageElements) {
    //   parsedResponse.messageElements = parsedResponse.payload;
    //   delete parsedResponse.payload;
    // } 
    setCurrResponse({
      ...response,
      // response: parsedResponse.converted === "<p><br></p>" ? '' : parsedResponse.converted
    })
    setContentData(parsedResponse);
    setIsNewPayload(true); // if this is new payload, old response button will not be shown anymore
  }
  catch (e) {
    console.warn("This is old response version, use default messageElements structure", e);
    if (response.response === "") {
      setIsNewPayload(true); // if this is new payload, old response button will not be shown anymore
    }
    else {
      setIsNewPayload(false);
    }
    setCurrResponse(response);
    setContentData({ version: "1", messageElements: [] })
  }
}

export const getEiResponseFunc = (setCurrResponse, setContentData, setIsNewPayload, Eiid, setEiResponses, sites, update) => {
  getEiResponse(Eiid).then(data => {
    setEiResponses(data);
    let resCurr = [];
    if (data && data.responses.length > 0) {
      resCurr = data.responses.filter(res => res.company_site_id
        == sites[0].id)
      if (resCurr.length > 0) {
        if (update) {
          handleResponseVersionV1(resCurr[0], setContentData, setCurrResponse, setIsNewPayload);
        }
      }
      else {
        setCurrResponse({ response: "" });
        setContentData({ version: "1", messageElements: [] })
      }
    } else {
      setCurrResponse({ response: "" });
      setContentData({ version: "1", messageElements: [] })
    }
  });
}

export const getFeedsStatsFunc = (Eiid, setFeedsStats) => {
  getFeedsStats(Eiid).then(data => {
    if (typeof data == 'object' && Object.keys(data).length > 0) {
      setFeedsStats(data)
    }
  });
}

export const handleIsSelectedFeedHelper = (
  setProcessedFeeds,
  processedFeeds,
  idx,
  setFeedsList,
  handleUpdate,
  feedsList) => {

  let feedId = feedsList.filter(a => a.id == idx)[0].id;

  if (!feedsList.filter(a => a.id == feedId)[0].isProcessed) {
    processedFeeds.push(feedId);
    setProcessedFeeds(processedFeeds);
  } else {
    processedFeeds.splice(processedFeeds.indexOf(feedId), 1);
    setProcessedFeeds(processedFeeds);
  }

  feedsList.filter(a =>
    a.id == feedId)[0].isProcessed
    = !feedsList.filter(a => a.id == feedId)[0].isProcessed


  setFeedsList(feedsList);
  handleUpdate();
}



export const handlePositiveFeedbackHelper = async (selectedSite, currResponse) => {
  let data = {
    source: "FRONT ADMIN",
    companyResponseElementId: currResponse.id
  }

  let reponse = await sendPositifFeed(data);
  return reponse;
}

export const handlenegativeFeedHelper = async (
  currResponse,
  negativeFeedMsg,
  setNegativeFeedMsg,
  selectedFeedStat) => {
  if (negativeFeedMsg && negativeFeedMsg !== "") {
    let data = {
      responseStatus: selectedFeedStat,
      source: "FRONT ADMIN",
      companyResponseElementId: currResponse.id,
      comment: negativeFeedMsg
    }
    await sendNegativeFeed(data);
    setNegativeFeedMsg("");
  }
}

export const handleUpdateProcessedFeedsHelper = async (Eiid, processedFeeds
  , getFeedsBySite, selectedSite, setFeedsList, setProcessedFeeds) => {
  if (!!processedFeeds) {
    await updateProcessedFeeds(processedFeeds);
    getFeedsBySite(selectedSite.id, Eiid, setFeedsList)
    setProcessedFeeds([]);
  }
}

export const handleUpdateResponseHelper = async (Eiid, eiResponses
  , currResponse, contentData, setContentData, isAdvanceResponse, setIsNewPayload, selectedSite, setCurrResponse, setEiResponses, sitesList) => {
  let resCurr = [];
  if (eiResponses && eiResponses.responses.length > 0) {
    resCurr = eiResponses.responses.filter(res => res.company_site_id
      == selectedSite.id)
  }

  let responseData;

  // new messageElements format
  // if (isAdvanceResponse && currResponse.response !== "" && currResponse.response !== "<p><br></p>") { // the new messageElements is empty, save it as the old version instead
  if (isAdvanceResponse) {
    const formatedResponse = {
      ...contentData,
      converted: currResponse.response
    }
    delete contentData.converted; // removed converted, will be remove once everything is okay
    delete contentData.payload; // to change migrate payload to messageElements, will be remove once everything is okay
    if (contentData.messageElements.length > 0) {
      responseData = JSON.stringify(contentData);
    }
    else {
      responseData = "";
    }
    delete formatedResponse.payload; // to change migrate payload to messageElements, will be remove once everything is okay
    responseData = JSON.stringify(formatedResponse);
    // console.log('formatedResponse', formatedResponse);
  }
  else {
    responseData = currResponse.response === "<p><br></p>" ? '' : currResponse.response;
    // console.log('responseData old', responseData);
  }
  let data = {
    eiId: Eiid,
    response: responseData,
    responseId: resCurr[0] ? resCurr[0].id : null,
    companySiteId: selectedSite.id,
  }
  await updateEiinformationService(data);
  getEiResponseFunc2(setCurrResponse, setContentData, setIsNewPayload,
    Eiid, setEiResponses, sitesList, currResponse)
}


export const getEiResponseFunc2 = (setCurrResponse, setContentData, setIsNewPayload, Eiid,
  setEiResponses, sites, currResponse) => {
  getEiResponse(Eiid).then(data => {
    setEiResponses(data);
    let resCurr = [];
    if (data && data.responses.length > 0 && currResponse.id !== null) {
      resCurr = data.responses.filter(res => res.id
        == currResponse.id)
      if (resCurr.length > 0) {
        if (currResponse.id) {
          const newCurrResponse = resCurr.filter(a => a.id == currResponse.id).length > 0 ?
            resCurr.filter(a => a.id == currResponse.id)[0] : resCurr[0];
          handleResponseVersionV1(newCurrResponse, setContentData, setCurrResponse, setIsNewPayload);
        }
      }
      else {
        setCurrResponse({ response: "" });
        setContentData({ version: "1", messageElements: [] });
      }
    } else if (data && data.responses.length > 0
      && currResponse.id == null && currResponse.response !== "") {
      // this is unsafe, due to response might be the same for every site and this is not a good condition to fetch data, should be delete if the new condition is tested and create no other bug
      // resCurr = data.responses.filter(res => res.response
      //  == currResponse.response) 
      // alert("---")
      resCurr = data.responses.filter(res => res.company_site_id
        === currResponse.company_site_id)
      if (resCurr.length > 0) {
        if (resCurr[0].id) {
          resCurr[0].updated_by = resCurr[0].created_by;
          resCurr[0].updated_at = resCurr[0].created_at;
          handleResponseVersionV1(resCurr[0], setContentData, setCurrResponse, setIsNewPayload);
        }
      } else {
        setCurrResponse({ response: "" });
        setContentData({ version: "1", messageElements: [] });
      }
    } else {
      setCurrResponse({ response: "" });
      setContentData({ version: "1", messageElements: [] });
    }
  });
}