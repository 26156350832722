function getURLApi() {
  let URL = window.location.hostname;
  return `https://front2.admin.preprod.cyconia.io/api/`;
  // const company = URL.split('.');
  // if (process.env.NODE_ENV === 'development') {
  //   return 'https://' + company[0] + '.' + process.env.REACT_APP_API_URL;
  // } else {
  //   let protocol;
  //   switch (process.env.REACT_APP_ENV) {
  //     case 'master':
  //       protocol = 'https://';
  //       break;
  //     case 'develop':
  //       protocol = 'https://';
  //       break;
  //     default:
  //       break;
  //   }
  //   return protocol + company[0] + '.' + process.env.REACT_APP_API_URL;
  // }
}

const URL_API = getURLApi();
const BASE_URL = URL_API.slice(0, URL_API.length - 4);
const VERSION_API = process.env.REACT_APP_API_VERSION;
const REACT_APP_URL = process.env.REACT_APP_URL;
const NEGATIVE_FEEDBACK_FORM = 'CompanyBundle/Form/NegativeBotFeedbackType';
const NEGATIVE_FEEDBACK_URL = '/api/v1/collaborator/feedback/bot/form';

export {
  getURLApi,
  URL_API,
  BASE_URL,
  VERSION_API,
  REACT_APP_URL,
  NEGATIVE_FEEDBACK_FORM,
  NEGATIVE_FEEDBACK_URL
}