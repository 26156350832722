import styled from "styled-components";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';

export const Container = styled.div`

`;

export const Wrapper = styled.div`
  width: 100%;
  /* padding: 0 20px; */
`;

export const PaperCustom = styled(Paper)`
  &&{
    width: 100%;
    margin-bottom: 16px;
    box-shadow: none;
  }
`;

export const TableCellCustom = styled(TableCell)`
  &&{
    padding: 0 !important;
    border-bottom: 1px solid #F1F1F1;
    transition: all .2s ease-out;
    a{
      text-decoration: none;
      padding: 16px;
      box-sizing: border-box;
      height: 100%;
      display: flex;
      width: 100%;
    }
    span{
      display: inline-flex;
      justify-content: flex-start;
      font-size: 12px;
      line-height: 8px;
      font-weight: 500;
      color: #5A5A5A;
      text-align: left;
      padding: 9px 10px;
      max-height: 26px;
      background-color: #ededed;
      border-radius: 4px;
      transition: all .2s ease-out;
    }
    &.tableCell-competence{
      span{
        padding-top: 0;
        line-height: 26px;
        width: auto;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
    &.tableCell-info{
      span{
        font-size: 12px;
        line-height: 13px;
        background-color: transparent;
        padding: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    &.tableCell-site{
      span{
        padding-top: 0;
        line-height: 26px;
        width: auto;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
    &.tableCell-type{
      span{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    &.tableCell-feedback{
      span{
        font-size: 12px;
        line-height: 13px;
        border-radius: 0;
        height: auto;
        padding: 0;
        background-color: transparent;
        white-space: initial;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
`;

export const TablePaginationCustom = styled(TablePagination)`
  &&{
    .MuiTablePagination-toolbar{
      display: flex;
      justify-content: flex-start;
      padding: 0 20px;
      .MuiTablePagination-spacer{
        display: none;
        & + p.MuiTablePagination-caption{
          margin: 0;
        }
      }
      .MuiTablePagination-caption{
        font-size: 13px;
        margin: 0 auto;
      }
      .MuiTablePagination-input{
        font-size: 13px;
        margin-right: 0;
      }
      .MuiTablePagination-actions{
        font-size: 13px;
        margin-left: 0;
      }
    }
  }
`;

export const TableRowCustom = styled(TableRow)`
  &&{
    cursor: pointer;
    &.MuiTableRow-hover{
      &:hover,
      &:focus{
        background-color: #F7FBFF;
        ${TableCellCustom}{
          border-bottom: 1px solid #F7FBFF;
          &.tableCell-date{
            span{
              color: #ffffff;
              background-color: #2768B3;
            }
          }
          &.tableCell-competence{
            span{
              color: #ffffff;
              background-color: #357ED3;
            }
          }
          &.tableCell-info{
            span{
              color: #357ED3;
            }
          }
          &.tableCell-site{
            span{
              color: #2B2B2B;
              background-color: #FFD700;
            }
          }
          &.tableCell-collaborator{
            span{
              color: #ffffff;
              background-color: #357ED3;
            }
          }
          &.tableCell-type{
            span{
              color: #ffffff;
              background-color: #40B176;
            }
          }
          &.tableCell-feedback{
            span{
              color: #357ED3;
            }
          }
        }
      }
      &:focus{
        background-color: #e4ecf5;
      }
    }
  }
`;

export const TableCustom = styled(Table)`
  &&{
    min-width: 750px;
  }
`;
