import styled from "styled-components";
import {
  NavLink,
} from "react-router-dom";
import Accordion from '@material-ui/core/Accordion';
import ButtonDropdown from '../../components/molecules/buttonDropdown';
import breakpoints from "../../../../theme-style/breakpoints";

export const GearIconWrapper = styled.div`
  max-width: 16px;
  max-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  svg{

  }
`;

export const ButtonDropdownCustom = styled(ButtonDropdown)`
  &&{
    &.MuiButton-text{
      min-width: auto;
      width: 36px;
      height: 36px;
      margin: -9px 0;
      border-radius: 6px;
      transition: all .2s ease-out;
      &:hover,
      &:focus{
        background-color: #2768b3;
        box-shadow: none;
      }
      .MuiButton-label{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        box-sizing: border-box;
        margin: 0;
        .MuiButton-startIcon{
          margin-right: 0px;
          margin-left: 0px;
          width: 16px;
        }
      }
    }
  }
`;

export const LogoutBorne = styled.div`
  position: absolute;
  top: 23px;
  right: 25px;
`;

export const BorneWallet = styled.div`
  padding: 25px;
  margin: auto 0 0 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  strong{
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 10px;
    text-align: center;
  }
  span{
    color: #ffffff;
    text-align: center;
    font-size: 34px;
    font-weight: 700;
    text-align: center;
  }
`;

export const EuroIconWrapper = styled.span`
  max-width: 14px;
  max-height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 8px 0 0;
  svg{
    width: 100%;
    height: 100%;
  }
`;

export const EventAvailableIconWrapper = styled.span`
  max-width: 14px;
  max-height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 8px 0 0;
  svg{
    width: 100%;
    height: 100%;
  }
`;

export const ArrowIconWrapper = styled.span`
  max-width: 7px;
  max-height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 auto;
  svg path{
    transition: all .2s ease-out;
  }
`;

export const AccordionDetailsPrice = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  line-height: 9px;
  font-weight: 500;
  padding: 5px 0;
  color: #357ED3;
`;

export const AccordionDetailsDate = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  padding: 5px 0;
  color: #00B16A;
  line-height: 10px;
`;

export const AccordionOrders = styled.strong`
  text-transform: inherit;
  font-size: 14px;
  font-weight: 600;
  line-height: 11px;
  color: #5A5A5A;
  margin-top: 10px;
`;

export const AccordionDate = styled.span`
  text-transform: inherit;
  font-size: 12px;
  line-height: 9px;
  font-weight: 600;
  color: #CCCCCC;
  letter-spacing: 0.6px;
`;

export const AccordionCustom = styled(Accordion)`
  &&{
    &.MuiAccordion-root{
      &:before{
        background-color: #F4F4F4;
      }
      .MuiAccordionSummary-root{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 15px 20px 15px 23px;
        min-height: auto;
        transition: all .2s ease-out;
        background-color: #FFFFFF;
        .MuiAccordionSummary-content{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          margin: 0;
          &:before{
            content: "";
            position: absolute;
            top: 12px;
            left: 15px;
            width: 5px;
            height: 38px;
            border-radius: 100px;
            background-color: #EDEDED;
            transform: translateX(-100%);
            display: block;
            transition: all .2s ease-out;
          }
        }
        .MuiAccordionSummary-expandIcon{
          max-width: 10px;
          &.Mui-expanded{
            transform: rotate(90deg);
          }
        }
        &:hover{
          background-color: #FAFAFA;
          .MuiAccordionSummary-content{
            &:before{
              background-color: #357ED3;
            }
          }
          .MuiAccordionSummary-expandIcon{
            svg{
              path{
                stroke: #357ED3;
                fill: #357ED3;
              }
            }
          }
        }
      }
      .MuiCollapse-container{
        .MuiCollapse-wrapper{
          .MuiCollapse-wrapperInner{
            .MuiAccordionDetails-root{
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              background-color: #F7F7F7;
              padding: 10px 25px;
              box-sizing: border-box;
            }
          }
        }
      }
      &.Mui-expanded{
        margin: 0;
        &:before{
          display: none;
        }
        .MuiAccordionSummary-content{
          &:before{
            background-color: #357ED3;
          }
        }
      }
    }
  }
`;

export const BorneOrdersItem = styled.div`
  border-radius: 6px;
  overflow: hidden;
`;

export const BorneOrdersList = styled.div`

`;

export const BorneAccountBox = styled.div`
  .MuiAppBar-root{
    background-color: transparent;
    box-shadow: none;
    margin: 25px -25px 0 -25px;
    position: sticky;
    top: 0;
    background-color: #397DD2;
    padding: 10px 25px 20px 25px;
    box-sizing: initial;
    .MuiTabs-root{
      .MuiTabs-fixed{
        .MuiTabs-flexContainer{
          button{
            color: #EAEAEA;
            background-color: #2768B3;
            border-radius: 6px;
            transition: all .2s ease-out;
            font-size: 13px;
            font-weight: 600;
            text-transform: inherit;
            font-family: 'Quicksand', sans-serif;
            letter-spacing: 0;
            &.MuiTab-textColorPrimary.Mui-selected{
              background-color: #40B176;
              color: #FFFFFF;
            }
            &:first-child{
              margin-right: 5px;
            }
            &:last-child{
              margin-left: 5px;
            }
          }
        }
        .MuiTabs-indicator{
          display: none;
        }
      }
    }
  }
`;

export const AnswerIllustrationWrapper = styled.span`
  width: 100px;
`;

export const IllustrationText = styled.div`
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 5px;
  color: white;
`;

export const EmailDiv = styled.div`
  color: white;
  font-size: 0.9rem;
  text-decoration: underline;
  cursor: pointer;
`;

export const CyconiaInfoContact = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #2768B3;
  border-radius: 6px;
  height: 100%;
  max-height: 120px;
  padding: 10px 15px;

  @media ${breakpoints.mobileXL} {
    padding: 20px 25px;
  }

  .o-aside-dashboardV2__connect-expressing-contact-content {
    display: flex;
    flex-direction: column;
    margin-left: 25px;

    span {
      font-size: 16px;
      line-height: 16px;
      font-weight: 700;
      margin-bottom: 20px;
      color: white;
    }
    a {
      font-size: 14px;
      font-weight: 600;
      font-size: 14px;
      color: white;
      text-decoration: underline;
      margin-bottom: 10px;
    }
    p {
      font-size: 14px;
      font-weight: 600;
      font-size: 14px;
      color: white;
      margin: 0;
    }
  }
`;

export const Title = styled.div`
  color: white;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin: 0 auto 0 0;
  transition: all .2s ease-out;
`;

export const BackIconWrapper = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 16px;
  max-height: 12px;
  svg path{
    transition: all .2s ease-out;
  }
`;

export const BackButton = styled.div`
  margin: 0 30px 0 0;
`;

export const Header = styled(NavLink)`
  position: sticky;
  top: 0;
  background-color: #357ED3;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  text-decoration: none;
  padding: 15px 0;
  @media ${breakpoints.mobileXL} {
    padding: 25px 0;
  }
  &:hover{
    ${Title}{
      color: #efc31f;
    }
    ${BackIconWrapper}{
      svg{
        path{
          fill: #efc31f;
        }
      }
    }
  }
`;

export const BorneAccountWrapper = styled.div`
  height: auto;
  min-height: 100vh;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

export const Container = styled.div`
  /* position: absolute; */
  /* z-index: 888; */
  /* top: 0; */
  height: auto;
  min-height: 100vh;
  width: 100%;
  background-color: #357ED3;
  box-sizing: border-box;
  padding: 0 15px;

  @media ${breakpoints.mobileXL} {
    padding: 0 25px;
  }
`;
