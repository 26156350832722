import styled from "styled-components";

import { COLORS } from "../../../../../constants/design";
import breakpoints from "../../../../../theme-style/breakpoints";

import GenericTitleWithToolTip from '../../../../../components/molecules/GenericTitleWithToolTip';

export const MainContainer = styled.div`
`;

export const StyledTitleWithToolTip = styled(GenericTitleWithToolTip)`
  color: white;
  font-size: 16px;
  font-weight: 700;
  padding: 0 0 8px 0;
  @media ${breakpoints.mobileXL} {
    padding: 0 0 15px 0;
  }
`;

export const EmptyList = styled.div`
  background-color: ${COLORS.WHITE};
  border-radius: 6px;
  padding: 10px;
`;

export const ParoleCyconiaList = styled.ul`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 0;
  margin: 0;
  border-radius: 6px;
  overflow: hidden;
`;

export const ParoleCyconiaDate = styled.span`
  color: #777777;
  background-color: #F7F7F7;
  border-radius: 3px;
  padding: 5px 8px;
  font-size: 12px;
  margin-right: 20px;
  font-weight: 500;
  transition: all .2s ease-out;
`;

export const ParoleCyconiaItem = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin: 0;
  background-color: #FFFFFF;
  height: auto;
  cursor: pointer;
  border-bottom: 1px solid #F4F4F4;
  transition: all .2s ease-out;
  list-style: none;
  &:last-child{
    border-bottom: 0;
  }
  &:hover{
    background-color: #FAFAFA;
    ${ParoleCyconiaDate}{
      background-color: #4A90E2;
      color: #ffffff;
    }
  }
`;

export const ParoleCyconiaPicture = styled.div`
  width: 60px;
  min-width: 60px;
  height: 60px;
  min-height: 60px;
  display: flex;
  background-color: #FAFAFA;
  justify-content: center;
  align-items: center;
  svg{
    width: 30px;
    height: 24px;
    path{
      fill: #DEDEDE;
    }
  }
`;

export const PictureBox = styled.div`
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
`;

export const ParoleCyconiaTitle = styled.strong`
  color: #5A5A5A;
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  padding-right: 20px;
  margin-left: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 30px;
  line-height: 30px;
`;