import { makeStyles, fade } from '@material-ui/core/styles';
import styled from "styled-components";

// Styled Material UI componenets
import TableContainer from '@material-ui/core/TableContainer';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { COLORS } from '../../../constants/design';


export const StyledTableRow = styled(TableRow)`
  padding: 117px 0px;
`;

export const EmptyContentContainer = styled.div`
  padding: 40vh 20vw;
  width: 100%;
  /* height: 100vh; */
  display: flex;
  justify-content: center;
  align-content: center;
  color: ${COLORS.LIGHT_GREY_4};
`;

// Style Themes list contents
export const ThemesMainContainer = styled.div`
    display: flex;
    width: 100%;
`;

export const ThemesEiContainer = styled(TableContainer)`
    height: 100vh;
    overflow: auto;
    table, tbody, thead, tr, th, td {

    }
    .genericTextField {
      /* border: 1px solid black; */
      width: 300px;
      .MuiInputBase-input {
        font-size: 0.8rem;
      }
    }
`;

export const StyledSubTableCell = styled(TableCell)`
    font-size: 14px;
    font-weight: 500;
    color: #5A5A5A;
    span {
    display: block;
    font-size: 12px;
    font-weight: 500;
    color: #BBBBBB;
    }
`;

export const StyledTableContainer = styled(TableContainer)`
    &&{
      padding: 20px 20px;
      box-shadow: none;
      width: 100%;
      overflow: auto;
    }
`;

const useStylesHeader = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 0,
    padding: 0,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

// Style Table content

const useStylesTable = makeStyles({
  root: {
    // paddingLeft: 20,
    flex: 1,
    padding: "0px 25px",
    // width: "100%",
  },
  tablePAddings: {
    padding: "110px 110px",
  },
  tableRowDescRightIcon: {
    color: "#4A90E2",
    fontSize: 14,
    marginRight: 5,
    position: "relative",
    top: 3
  },
  tableRowDescRight: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#777777",
  },
  tableRowItem: {
    color: "#5A5A5A",
    width: "50%",
    fontWeight: 600,
    fontSize: 14
  },
  tableRowDesc: {
    color: "#BBBBBB",
    fontWeight: 500,
    fontSize: 12,
  },
  tableTitle: {
    fontSize: 16,
    width: "60%",
    padding: "0px 0px 0px 0px",
    fontWeight: "bold",
    color: "#5A5A5A",
  },
  tagDiv: {
    position: "relative",
    '&::before': {
      content: '""',
      height: 16,
      position: "absolute",
      width: 6,
      backgroundColor: "#357ED3",
      top: 3,
      borderRadius: 100,
      left: -18,
    }
  },
  table: {
    minWidth: 650,
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  contentData: {
    flex: 1,
    height: "100vh",
    overflow: "auto"
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  tabs: {

  },
}));


export {
  useStylesHeader,
  useStyles,
  useStylesTable
}
