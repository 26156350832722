import styled from "styled-components";

import { COLORS } from "../../../../constants/design";

export const MainContainer = styled.div`
`;

export const ContentContainer = styled.div`
  padding: 20px;
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: ${COLORS.PRIMARY_BLUE};
  }
  .MuiCheckbox-colorSecondary.Mui-disabled {
    color: ${COLORS.LIGHT_GREY_4};
  }
  >div:not(:last-child) {
    margin-bottom: 1rem;
  };
`;