import styled, { keyframes } from "styled-components";
import { COLORS } from '../../../constants/design';

const arrowPosition = {
  top: {
    borderWidth: '0px 6px 6px 6px',
    borderColor: "transparent transparent rgba(0, 0, 0, 0.7) transparent",
  },
  bottom: {
    borderWidth: '6px 6px 0px 6px',
    borderColor: "rgba(0, 0, 0, 0.7) transparent transparent transparent",
  },
  right: {
    borderWidth: '6px 0px 6px 6px',
    borderColor: "transparent transparent transparent rgba(0, 0, 0, 0.7)",
  },
  left: {
    borderWidth: '6px 6px 6px 0px',
    borderColor: "transparent rgba(0, 0, 0, 0.7) transparent transparent",
  }
}

const generateKeyframesVertical = (min, max) => keyframes`
  0% {
    bottom: ${min};
  }
  50% {
    bottom: ${max};
  }
  100% {
    bottom: ${min};
  }
`;

const generateKeyframesHorizontal = (min, max) => keyframes`
  0% {
    left: ${min};
  }
  50% {
    left: ${max};
  }
  100% {
    left: ${min};
  }
`;

export const Container = styled.div`
  visibility: ${props => props.isIndicatorVisible ? 'visible' : 'hidden'};
  width: 190px;
  position: absolute;
  z-index: 9999;
  /* top: 50%;
  left: 50%; */
  top: ${props => props.boxPosition?.top || 'unset'};
  bottom: ${props => props.boxPosition?.bottom || 'unset'};
  right: ${props => props.boxPosition?.right || 'unset'};
  left: ${props => props.boxPosition?.left || 'unset'};
  margin: 0 auto;
  padding: 6px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: ${COLORS.WHITE};
  background-color: rgba(0, 0, 0, 0.7);
  animation: ${props => props.animationData.direction === 'vertical' ?
    generateKeyframesVertical(props.animationData.min, props.animationData.max)
    :
    generateKeyframesHorizontal(props.animationData.min, props.animationData.max)
  }
    1s infinite;
  ::after {
    content: '';
    position: absolute;
    top: ${props => props.arrowPosition?.top || 'unset'};
    bottom: ${props => props.arrowPosition?.bottom || 'unset'};
    right: ${props => props.arrowPosition?.right || 'unset'};
    left: ${props => props.arrowPosition?.left || 'unset'};
    width: 0;
    height: 0;
    border-style: solid;
    border-width: ${props => arrowPosition[props.arrowPosition?.direction].borderWidth};
    border-radius: 1px;
    border-color: ${props => arrowPosition[props.arrowPosition?.direction].borderColor};
  }
`;