import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';

import { COLORS } from '../../../../constants/design';
import GenericTitleWithToolTip from '../../../../components/molecules/GenericTitleWithToolTip';
import GenericMultipleSelect from '../../../../components/molecules/GenericMultipleSelect';

export const StyledSiteBlock = styled.div`
  /* border: 1px solid black; */
  /* width: 50%;
  display: flex; */
  display: flex;
  flex-direction: row;
`;

export const StyledTitle = styled(GenericTitleWithToolTip)`
  margin-right: 5px;
  .textTitle {
    font-weight: 700;
    font-size: 0.9rem;
  }
  .textTooltip {
    color: ${COLORS.GREY_1}
  }
`;

export const StyledSiteBlockSpan = styled.span`
  margin-right: 5px;
  font-weight: 700;
  font-size: 0.9rem;
`;

export const StyledSiteBlockIcon = styled(IconButton)`
  &&{
    height: fit-content;
    margin-top: -10px;
    .MuiSvgIcon-root {
      font-size: 1rem;
      color: ${props => props.isNew ? COLORS.RED : COLORS.DARK_GREY};
    }
  }
`;

export const StyledMultipleSelect = styled(GenericMultipleSelect)`
  padding: 10px 20px;
  .textTitle {
    font-weight: 600;
  }
`;
