import styled from "styled-components";

import { COLORS } from "../../../constants/design";

import GenericTitleWithToolTip from "../../../components/molecules/GenericTitleWithToolTip";

export const MainContainer = styled.div`
  box-sizing: border-box;
  line-height: normal;
  display: flex;
  flex-direction: column;
`;

export const StyledExpandableHeader = styled.div`
  /* border: 1px solid black; */
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const StyledExpandableTitle = styled(GenericTitleWithToolTip)`

`;

export const StyledExpandableIcon = styled.div`
  position: relative;
  top: 0;
  right: 0;
  z-index: 9999;
  color: ${COLORS.DARK_GREY};
  background-color: ${COLORS.LIGHT_GREY_1};
  border-radius: 50%;
  padding: 1px;
  display: flex;
  opacity: 0.8;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;

export const StyledCancelExpandableContainer = styled.div`
  border: 1px solid ${COLORS.LIGHT_GREY_4};
  border-radius: 5px;
  padding: ${props => props.isVisible ? '15px' : '0px'};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-top: ${props => props.isVisible ? '15px' : '0px !important'};
  margin-bottom: ${props => props.isVisible ? '15px' : '0px !important'};
  height: ${props => props.isVisible ? '180px' : '0px'};
  transform: ${props => props.isVisible ? 'scaleY(1)' : 'scaleY(0)'};
  opacity: ${props => props.isVisible ? '1' : '0'};
  transform-origin: top;
  transition: all 0.3s ease-in-out;
  overflow: auto;
  >div:not(:last-child) {
    margin-bottom: 15px;
  };
`;

export const StyledInfoContainer = styled.div`
  box-sizing: border-box;
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  width: 100%;
  >div:not(:last-child) {
    margin-bottom: 5px;
  };
`;

export const InfoTitle = styled.div`
  /* border: 1px solid blue; */
  font-weight: 700;
  width: 100%;
`;

export const InfoDetails = styled.div`
  width: 100%;
  /* border: 1px solid blue; */
`;