import styled from "styled-components";

import { COLORS } from "../../../../../../../constants/design";
import GenericAccordion from "../../../../../../../components/molecules/GenericAccordion";

export const MainContainer = styled.div`
  .textTitle {
    font-weight: 700;
    font-size: 0.9rem;
    color: ${COLORS.DARK_GREY};
  }
`;

export const StyledAccordion = styled(GenericAccordion)`
  && {
    .MuiAccordion-root {
      border: 1px solid ${COLORS.LIGHT_GREY_2};
    }
  }
`;

export const StyledTitle = styled.div`
  font-weight: 600;
  font-size: 1rem;
  align-items: center;
`;

export const DetailContainer = styled.div`
  font-size: 0.9rem;
  >div:not(:last-child) {
    margin-bottom: 15px;
  };
`;

export const BarContainer = styled.div`
  border-radius: 10px;
  padding: 0px 10px;
  >div:not(:last-child) {
    margin-bottom: 10px;
  };
`;

export const ModuleContainer = styled.div`
  border: 1px solid ${COLORS.LIGHT_GREY_3};
  border-radius: 5px;
  padding: 5px 10px;
  color: ${props => props.active ? COLORS.DARK_GREY : COLORS.LIGHT_GREY_4};
  >div:not(:last-child) {
    margin-bottom: 5px;
  };
`;