import styled from "styled-components";

import { COLORS } from "../../../../constants/design";
import { size } from '../../../../theme-style/breakpoints';

export const MainContainer = styled.div`
  border: 1px solid ${COLORS.LIGHT_GREY_3};
  border-radius: 10px;
  box-sizing: border-box;
  /* width: 100%; */
  height: 100%;
  /* max-width: 510px; */
  /* width: 90%; */
  /* max-width: 800px; */
  padding: 10px;
`;

export const RoomsContainer = styled.div`
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  /* justify-content: space-around; */
  align-items: center;
  /* flex-wrap: wrap; */
  min-height: 180px;
  /* min-width: 510px; */
  /* max-height: 200px; */
  >div:not(:last-child) {
    margin-right: 15px;
  };
  
`;

export const StyledRoom = styled.div`
  border: 1px solid ${COLORS.LIGHT_GREY_3};
  border-radius: 10px;
  /* padding: 5px; */
  /* margin: 0px 5px 5px 0px; */
  /* width: 120px; */
  width: 24%;
  height: 175px;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  @media screen and (max-width: ${size.mobileXL}) {
    width: 100%;
  }
`;

export const StyledRoomName = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 30%;
  position: absolute;
  bottom: 0px;
  color: white;
  padding: 4px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.7rem;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  background-color: ${COLORS.BLACK_TRANSPARENT_70};
`;

export const StyledImg = styled.img`
  /* width: 60px;
  height: 60px; */
  width: 100%;
  object-fit: cover;
`

export const ToolContainer = styled.div`
  /* border: 1px solid black; */
  display: flex;
  margin-top: 10px;
  /* justify-content: center; */
  align-items: center;
`;

export const StyledIconContainer = styled.div`
  /* position: absolute; */
  /* z-index: 9999; */
  color: ${COLORS.WHITE};
  /* background-color: ${COLORS.PRIMARY_BLUE}; */
  background-color: ${props => props.disabled ? COLORS.LIGHT_GREY_4 : COLORS.PRIMARY_BLUE};
  border-radius: 50%;
  padding: 1px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  /* pointer-events: ${props => props.disabled ? 'none' : 'auto'} */
  /* opacity: 0.8; */
  /* top: 10px;
  right: 10px; */
  cursor: pointer;
  :hover {
    background-color: ${props => props.disabled ? COLORS.LIGHT_GREY_4 : COLORS.PRIMARY_DEEP_BLUE};
  }
`;