import styled from "styled-components";

import GenericMaterialMenu from "../../../../../components/molecules/GenericMaterialMenu";

export const MainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
`;

export const StyledMenu = styled(GenericMaterialMenu)`
  border-radius: 5px;
  min-width: 200px;
  font-size: 0.8rem;
  font-weight: 500;
  .textTitle {
    color: #ffffff;
    font-weight: 600;
  }
  .menuInput {
    margin-top: 8px;
    background-color: #2C68B2;
    border: 0;
    color: #ffffff;
    padding: 8px 15px;
  }
  .menuIcon {
    margin-left: 20px;
    .MuiSvgIcon-root {
      color: #ffffff;
      width: 1rem;
    }
  }
`;

export const WeatherInfoContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  strong{
    text-align: right;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    font-size: 16px;
    font-weight: 700;
    color: #ffffff;
    span{
      font-size: 16px;
      font-weight: 700;
      color: #DCDCDC;
    }
  }
`;

export const WeatherIconWrapper = styled.span`
  min-width: 40px;
  max-width: 40px;
  margin-left: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;