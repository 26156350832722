import styled from "styled-components";
import DonutChart from '../../../../components/molecules/donutChart';

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  min-height: 140px;
  height: 100%;
  max-height: 22%;
  padding: 20px;
  border-radius: 6px;
  background-image: linear-gradient(-45deg, #3C7DC9 0%, #2A65AA 100%);
`;

export const Title = styled.h3`
  margin: 0 0 auto 0;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
`;

export const DonutChartRH = styled(({ ...rest }) => (
  <DonutChart {...rest} />
))`
  margin-right: 30px;
`;

export const BarChartContainer = styled.div`
  margin-left: 30px;
  display: flex;
  width: auto;
  .donutChartCpt{
    .donutchart-text{
      .donutchart-text-val{
        font-size: 12px;
        font-weight: 700;
      }
    }
  }
`;
