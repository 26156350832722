export const PAYLOAD_VERSION = "1";

export const CYCONIA_BOT_ICON = '/static/media/chat-icon1.dad8d224.svg';

export const ERROR_MESSAGE = '<p>Désolé, quelque chose ne va pas, veuillez réessayer</p>';

export const BOT_SERVICE_TYPE = {
  RESPONSE: 'RESPONSE',
  WHISPER: 'WHISPER'
}

export const MESSAGE_ELEMENT_TYPE = {
  TEXT: 'text',
  BUTTON_SIMPLE: 'button-simple',
  BUTTON_INTENT: 'button-intent',
  BUTTON_FORM: 'button-form',
  BUTTON_LINK: 'button-link',
  BUTTON_FUNCTION: 'button-function',
  BUTTON_SHOW_RELATED: 'button-show-related',
}

export const FEEDBACK = {
  POSITIVE: 'bot_positive_feedback',
  NEGATIVE: 'bot_negative_feedback'
}

export const BOT_MESSAGE_LOADING_BUBBLE = {
  isLoading: true,
  isReceived: true
}

export const LOADING_ANIMATION = '<div class="loading-indicator"><div></div><div></div><div></div><div></div><div></div><div></div></div>';
