export const actionGenerator = (value) => ({
  REQUEST: `PERSIST_REDUCERS/${value}/REQUEST`,
  SUCCESS: `PERSIST_REDUCERS/${value}/SUCCESS`,
  FAILURE: `PERSIST_REDUCERS/${value}/FAILURE`,
})

export const SAVE_SITE_MANAGEMENT_SELECTED_SITE_LIST = actionGenerator('SAVE_SITE_MANAGEMENT_SELECTED_SITE_LIST');
export const SAVE_TICKET_REQUEST_SELECTED_SITE_LIST = actionGenerator('SAVE_TICKET_REQUEST_SELECTED_SITE_LIST');
export const SAVE_CONSOLE_TAB_SWITCH = actionGenerator('SAVE_CONSOLE_TAB_SWITCH');
// ticket request
export const saveTicketRequestSelectedSiteList = (data) => ({
  type: SAVE_TICKET_REQUEST_SELECTED_SITE_LIST.REQUEST,
  data
});

// site management 
export const saveSiteManagementSelectedSiteList = (data) => ({
  type: SAVE_SITE_MANAGEMENT_SELECTED_SITE_LIST.REQUEST,
  data
});

// console tab switch
export const saveConsoleTabSwitch = (data) => ({
  type: SAVE_CONSOLE_TAB_SWITCH.REQUEST,
  data
});

export const actions = {
  SAVE_SITE_MANAGEMENT_SELECTED_SITE_LIST,
  SAVE_TICKET_REQUEST_SELECTED_SITE_LIST,
  SAVE_CONSOLE_TAB_SWITCH
}