import styled from "styled-components";
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import { COLORS } from "../../../../../../../constants/design";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  /* padding: 20px; */
  /* border: 1px solid black; */
`;

export const CustomTabs = styled(Tabs)`
  background-color: #F7F7F7;
  border-radius: 6px;
  width: 100%;
  /* border: 1px solid black; */
`;

export const CustomTab = styled(Tab)`
  &&{
    /* width: 150px; */
    max-width: 100%;
    width: 50%;
    font-size: 0.8rem;
    /* min-width: auto; */
    text-transform: none;
    color: ${COLORS.DARK_GREY};
    text-transform:capitalize;
    .textTitle {
      font-weight: 500;
    }
    .textTooltip {
      position: absolute;
      top: 15px;
      right: 15px;
    }
    &.Mui-selected{
      background-color: ${COLORS.PRIMARY_BLUE};
      .textTitle {
        color: #FFFFFF;
      }
      .textTooltip {
        color: #FFFFFF;
      }
    }
  }
`;

export const ImagePreviewContainer = styled.div`
  width: 400px;
  min-height: 400px;
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
`;