import React, { useEffect } from 'react';
import {
  shallowEqual,
  useDispatch,
  useSelector,
} from "react-redux";
import moment from 'moment';

import endpoint from "../../../../../helpers/api";

import GenericLoader from '../../../../../components/molecules/GenericLoader';

import bannerImage from '../../../../../assets/images/news-1.png';

import {
  getParoleCyconia,
  setSelectedParole,
} from '../../../../../store/sidebar/actions';

import {
  getParoleCyconiaData,
  getParoleCyconiaIsLoading,
} from '../../../../../store/sidebar/selectors';

import {
  MainContainer,
  StyledTitleWithToolTip,
  EmptyList,
  ParoleCyconiaList,
  ParoleCyconiaItem,
  ParoleCyconiaPicture,
  PictureBox,
  ParoleCyconiaDate,
  ParoleCyconiaTitle,
} from "./style";

moment.locale('fr');

const ParoleCyconia = () => {
  const dispatch = useDispatch();

  const {
    paroleCyconia,
    paroleCyconiaIsLoading,
  } = useSelector(
    state => ({
      paroleCyconia: getParoleCyconiaData(state),
      paroleCyconiaIsLoading: getParoleCyconiaIsLoading(state),
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!paroleCyconia) {
      dispatch(getParoleCyconia());
    }
  }, []);

  const ModalGestion = (data) => {
    dispatch(setSelectedParole(data.id));
  }

  return (
    <MainContainer>
      <StyledTitleWithToolTip
        title={"Mon actualité"}
        toolTipText={"Toute l'actualité de votre entreprise !"}
      />

      {paroleCyconiaIsLoading ?
        <EmptyList>
          <GenericLoader />
        </EmptyList>
        :
        <ParoleCyconiaList>
          {paroleCyconia && paroleCyconia?.items.map((item, index) => {
            const {
              title,
              published_at,
              banner,
              link,
              description,
              deal_value,
            } = item;

            let contentType = 'news';
            if (description && deal_value && link) contentType = 'good_deals'; // This means the content type is good deal, there is no other better way to differentiate them for now

            const imageSrc = banner ? `${endpoint.base_url}/uploads/${contentType}/${banner}` : bannerImage;

            return (
              <ParoleCyconiaItem key={index} onClick={() => ModalGestion(item)}>
                <ParoleCyconiaPicture>
                  <PictureBox style={{ backgroundImage: `url("${imageSrc}")` }} />
                </ParoleCyconiaPicture>
                <ParoleCyconiaTitle>
                  {title}
                </ParoleCyconiaTitle>
                <ParoleCyconiaDate>
                  {moment(published_at).format("DD/MM/YYYY")}
                </ParoleCyconiaDate>
              </ParoleCyconiaItem>
            )
          })}
        </ParoleCyconiaList>
      }
    </MainContainer>
  );
}

export default ParoleCyconia;