import styled from "styled-components";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { COLORS } from "../../../../../../constants/design";
import GenericAccordion from '../../../../../../components/molecules/GenericAccordion';
import GenericButton from "../../../../../../components/molecules/GenericButton";

export const MainContainer = styled.div`
`;

export const StyledAccordion = styled(GenericAccordion)`
  && {
    .MuiAccordion-root {
    }
  }
`;

export const StyledAccordionHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SettingTitle = styled.div`
  font-weight: 600;
  font-size: 1.1rem;
`;

export const StyledButton = styled(GenericButton)`
  && {
    &.MuiButton-root {
      width: 200px;
      margin-right: 0px;
    }
  }
`;

export const SettingContainer = styled.div`
  font-size: 1rem;
`;

export const TabContainer = styled.div`
  display: flex;
`;

export const StyledTabs = styled(Tabs)`
  padding-right: 1rem;
`;

export const StyledTab = styled(Tab)`
  border: 1px solid blue;
  &&{
    width: 200px;
    flex: 1;
    border-radius: 5px;
    display: flex;
    align-items: flex-start;
    text-align: left;
    color: ${COLORS.DARK_GREY};
    font-size: 0.9rem;
    text-transform: capitalize;
    &.Mui-selected {
      background-color: ${COLORS.PRIMARY_BLUE};
      color: white;
    }
  }
`;

export const EmptyContainer = styled.div`
`;