import styled from "styled-components";
import IconButton from '@material-ui/core/IconButton';
import Popper from '@material-ui/core/Popper';
import Button from '@material-ui/core/Button';

import { COLORS } from '../../../../../constants/design'
import GenericTitleWithIcon from '../../../../../components/molecules/GenericTitleWithIcon';

export const StyledPopper = styled(Popper)`
  /* border: 10px solid red; */
  z-index: 9999;
  transition: ${props => props.opencount === 1 ? "transform 0.3s" : "none"};
`;

export const PopUpContainer = styled.div` 
  /* min-width: 400px; */
  padding: 0;
  background-color: white;
  box-shadow: 0 10px 34px rgb(0 0 0 / 0.3);
  padding: 20px;
  border-radius: 6px;
  color: ${COLORS.DARK_GREY};
  max-width: 500px;
`;

export const TitleContainer = styled.div`
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  position: relative;
`;

export const Title = styled.span`
  font-size: 1rem;
  font-weight: 600;
  max-width: 280px;
  color: ${COLORS.DARK_GREY};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
    /* margin-bottom: 40px; */

`;

export const StyledIconButton = styled(IconButton)`
  &&{
    height: fit-content;
    position: absolute;
    right: 0;
    padding: 8px;
    justify-self: flex-end;
    .MuiSvgIcon-root {
      font-size: 1.1rem;
    }
  }
`;

export const TagContainer = styled.div`
  /* border: 1px solid red; */
  margin: 5px 0px;
  display: flex;
  align-items: center;
`;

export const StyledTag = styled.div`
  border-radius: 6px;
  padding: 5px 10px;
  margin-right: 5px;
  font-size: 0.7rem;
  font-weight: 600;
  color: ${props => props.type ? COLORS.WHITE : COLORS.DARK_GREY};
  /* background-color: ${COLORS.LIGHT_GREY_2}; */
  background-color: ${props => props.type ? props.type === 'event' ? COLORS.PRIMARY_BLUE : COLORS.GREEN : COLORS.LIGHT_GREY_2};
`;

export const ContentContainer = styled.div`
  /* border: 1px solid red; */
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  color: ${COLORS.LIGHT_GREY_4};
  max-width: 100%;
  overflow-wrap: break-word;
  flex-wrap: wrap;
  :hover {
    cursor: pointer;
    border-radius: 6px;
    color: ${COLORS.DARK_GREY}; 
    margin-right: 5px;
  } 
`;

export const StyledTitleWithIcon = styled(GenericTitleWithIcon)`
   text-transform: ${props => props.longText ? "" : "capitalize"};
   .gtwi-titleContainer {
     max-width: ${props => props.longText ? "250px" : "200px"};
   }

`;

export const ButtonContainer = styled.div`
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
`;

export const StyledButton = styled(({ ...rest }) => (
  <Button {...rest} />
))`
  && {
    &.MuiButton-root {
      color: ${COLORS.DARK_GREY};
      position: relative;
      margin-right: 5px;
      font-size: 0.7rem;
      font-weight: 500;
      background-color: ${COLORS.LIGHT_GREY_1};
      padding: 5px 15px;
      transition: all ease-in .15s;
      border-radius: 5px;
      text-transform: none;
      &:hover {
        background-color: ${COLORS.LIGHT_GREY_2};
      }
    }
  }
`;
