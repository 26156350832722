import React from "react";

const WalkIcon = ({
  color = "#5A5A5A",
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon walkIcon"
      width={width}
      height={height}
      viewBox="0 0 14 18"
      fill="none"
    >
      <path
        d="M6.992.975c.551 0 1.051.224 1.414.586a1.994 1.994 0 01.104 2.714c.445.175.858.429 1.223.754l.179.168 2.658 2.657c.293.293.439.676.439 1.06a1.493 1.493 0 01-1.5 1.496 1.49 1.49 0 01-.944-.335l-.114-.102-1.459-1.46v1.994c0 .207.067.402.19.562l.08.091 3.308 3.308c.293.293.439.676.439 1.06a1.493 1.493 0 01-1.498 1.497c-.336 0-.672-.112-.946-.336l-.114-.102-2.86-2.86-.735 1.08-3.108 1.982a1.49 1.49 0 01-1.138.185 1.492 1.492 0 01-.932-.68 1.562 1.562 0 01-.18-1.13c.074-.323.254-.617.525-.831l.121-.087 2.424-1.55c.298-.89.404-1.459.421-2.005l.003-.163V8.513l-1.459 1.46a1.493 1.493 0 01-1.06.438A1.495 1.495 0 01.976 8.914c0-.336.112-.671.336-.946l.103-.113 2.658-2.658c.4-.4.881-.713 1.404-.92a1.994 1.994 0 01.102-2.716A1.994 1.994 0 016.992.975zm.969 10.026H5.962l-.032.326c-.053.433-.15.86-.293 1.281-.102.306-.19.571-.384.82h0l-1.907 2.449 2.763-1.72 1.416-2.092.123-.198c.153-.27.258-.563.313-.866h0zm.73.984c-.094.217-.217.439-.531.905h0l3.57 2.134zM7.437 5.079a3.683 3.683 0 01-1.041 2.029h0l-.418.418v2.487h2.026V5.987l-.007-.12a1.005 1.005 0 00-.56-.788zm-.993-.055c-.63.112-1.22.416-1.674.87h0L2.96 9.149 5.7 6.41l.138-.148c.309-.357.516-.78.608-1.237zm2.546.969l.001 1.124 2.88 1.436-2.88-2.56zm-1.999-4.03a1.01 1.01 0 00-.716 1.728 1.01 1.01 0 001.432 0 1.01 1.01 0 000-1.432 1.01 1.01 0 00-.716-.296z"
        stroke={color}
        strokeWidth={0.05}
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export default WalkIcon;
