import _ from 'lodash';

import plumberImage from '../../../assets/images/plumberService.jpg'
import lavageImage from '../../../assets/images/lavageService.png';

export const PAYLOAD_VERSION = "1";

export const CYCONIA_BOT_ICON = '/static/media/chat-icon1.dad8d224.svg';

export const ERROR_MESSAGE = '<p>Désolé, quelque chose ne va pas, veuillez réessayer</p>';

export const BOT_SERVICE_TYPE = {
  RESPONSE: 'RESPONSE',
  WHISPER: 'WHISPER'
}

export const MESSAGE_ELEMENT_TYPE = {
  TEXT: 'text',
  BUTTON_SIMPLE: 'button-simple',
  BUTTON_INTENT: 'button-intent',
  BUTTON_FORM: 'button-form',
  BUTTON_LINK: 'button-link',
  BUTTON_FUNCTION: 'button-function',
  BUTTON_SHOW_RELATED: 'button-show-related',
  CARD_LIST: 'card-list',
}

export const MESSAGE_CARD_TYPE = {
  SERVICE_CARD: 'service-card',
}

export const FEEDBACK = {
  POSITIVE: 'bot_positive_feedback',
  NEGATIVE: 'bot_negative_feedback'
}

export const BOT_MESSAGE_LOADING_BUBBLE = {
  isLoading: true,
  isReceived: true
}

export const LOADING_ANIMATION = '<div class="loading-indicator"><div></div><div></div><div></div><div></div><div></div><div></div></div>';

const social = {
  version: "1",
  messageElements: [{
    type: "text",
    content: "<p>Créée en 1945, la Sécurité Sociale est financée par les cotisations sociales prélevées sur les salaires. Elle garantit la protection sociale des Français. Plus dinformation sur la rubrique dédiée de l'Assurance maladie.</p>"
  },
  // {
  //   type: 'button-function',
  //   title: 'Test news button',
  //   transmitData: {
  //     type: 'news',
  //     payload: '608fc9f9221f0'
  //   }
  // },
  {
    type: 'button-form',
    title: 'Button form example title',
    action: 'internal.bot.integration',
    service: 'baseService',
    payload: {
      type: 'form',
      url: '/api/v1/partner/request/form',
      form: 'CollaboratorsBundle/Form/Cyconia/BookingPlumberType',
      defaultValues: {
        comment: 'Sociale',
        partnerId: 22,
        serviceId: 66,
        service: 'Default',
      }
    }
  },
  {
    type: 'button-simple',
    title: 'Simple button example title',
    userMessage: 'Show me nested message!',
    messageElements: [
      {
        type: 'text',
        content: '<p>Hello im nested message ! </p>'
      }
    ]
  }],
  defaultValues: {
    action: "kb.rh.bonuses.social.general",
    userMessage: "Sécurité Sociale",
    botReply: "",
    companyResponseElement: "60af99ae63be4",
    informationElementId: "60af8bce8eeb0"
  },
}

const path = {
  version: "1",
  messageElements: [{
    type: "text",
    content: "<p>Le parcours de soin mis à disposition de chaque français permet de désigner un médecin traitant, afin de lui faire bénéficier d'un suivi médical coordonné, d'une gestion rigoureuse de son dossier médical et d'une prévention personnalisée. Plus d'information sur le site du Ministère des solidarités et de la santé.</p>"
  },
  {
    type: 'button-simple',
    title: 'Simple button example title',
    userMessage: 'Show me nested message!',
    messageElements: [
      {
        type: 'text',
        content: '<p>Hello im nested message ! </p>'
      }
    ]
  }],
  defaultValues: {
    action: "kb.rh.mutual.socialsecurity.path",
    userMessage: "Parcours de soins",
    botReply: "",
    companyResponseElement: "60af99ae65e4e",
    informationElementId: "60af8bce90452"
  },
}

const sheet = {
  version: "1",
  messageElements: [{
    type: "text",
    content: "<p>Une feuille de soin correspond à la facture du praticien suite à la réalisation d'un acte médicale. Plus d'information sur le site dédié de l'Assurance Maladie Ameli.</p>"
  }],
  defaultValues: {
    action: "kb.rh.mutual.socialsecurity.sheet",
    userMessage: "Feuille de soins",
    botReply: "",
    companyResponseElement: "60af99ae6827f",
    informationElementId: "60af8bce919b6"
  },
}

const obtaincard = {
  version: "1",
  messageElements: [{
    type: "text",
    content: "<p>La carte vitale contient l'ensemble de vos informations nécessaires à l'obtention de vos remboursements médicaux. Plus d'information sur la rubrique dédiée du site Ameli :</p>"
  }],
  defaultValues: {
    action: "kb.rh.mutual.socialsecurity.obtaincard",
    userMessage: "Obtenir ma carte vitale",
    botReply: "",
    companyResponseElement: "60af99ae6a7f2",
    informationElementId: "60af8bce92f7c"
  },
}

const cardvalidity = {
  version: "1",
  messageElements: [{
    type: "text",
    content: "<p>La carte viest émise sans durée de validité. Il convient néanmoins de la mettre à jour lors de chaque changement de situation (mariage, etc.) ou au moins une fois par an. Plus d'information sur la rubrique dédiée du site Ameli :</p>"
  }],
  defaultValues: {
    action: "kb.rh.mutual.socialsecurity.cardvalidity",
    userMessage: "Durée de validité de ma carte vitale",
    botReply: "",
    companyResponseElement: "60af99ae6d65b",
    informationElementId: "60af8bce945df"
  },
}

const europeancard = {
  version: "1",
  messageElements: [{
    type: "text",
    content: "<p>La carte européenne d'assurance maladie permet de bénéficier de soins médicaux lors d'un séjour au sein de l'Union Européenne ou de l'Espace Économique Européen ou de la Suisse. Plus d'information sur la rubrique dédiée du Service Public.</p>"
  }],
  defaultValues: {
    action: "kb.rh.mutual.socialsecurity.europeancard",
    userMessage: "Carte vitale européenne",
    botReply: "",
    companyResponseElement: "60af99ae6fbee",
    informationElementId: "60af8bce95b6"
  },
}

const bankchange = {
  version: "1",
  messageElements: [{
    type: "text",
    content: "<p>En cas de changement de votre situation personnelle, vous devez prendre contact avec votre caisse primaire d'assurance maladie.</p>"
  }],
  defaultValues: {
    action: "kb.rh.pay.payment.bankchange",
    userMessage: "Changement de situation",
    botReply: "",
    companyResponseElement: "60af99ad8cde1",
    informationElementId: "60af8bce05bac"
  },
}

const testSocialSecurityStructure = {
  parent: social,
  parentTitle: 'Sécurité Sociale',
  relatedSubjects: [path, sheet, obtaincard, cardvalidity, europeancard, bankchange],
  searchSubjects: ['Parcours de soins', 'Feuille de soins', 'Obtenir ma carte vitale', 'Durée de validité de ma carte vitale', 'Carte vitale européenne', 'Changement de situation']
}

const getRelatedSubjectMessage = () => {
  return {
    version: "1",
    className: 'relatedSubjects',
    messageElements: [
      {
        type: "text",
        content: "<p>Liste de sujets relatif au Sécurité Sociale</p>"
      },
      {
        type: "button-function",
        title: "Parcours de soins",
        userMessage: "Je souhaite plus d'informations sur : Parcours de soins",
        transmitData: {
          type: 'relatedSubject',
          companyResponseElement: "60af99ae65e4e",
          informationElementId: "60af8bce90452"
        }
      },
      {
        type: "button-function",
        title: "Feuille de soins",
        userMessage: "Je souhaite plus d'informations sur : Feuille de soins",
        transmitData: {
          type: 'relatedSubject',
          companyResponseElement: "60af99ae6827f",
          informationElementId: "60af8bce919b6"
        }
      },
      {
        type: "button-function",
        title: "Obtenir ma carte vitale",
        userMessage: "Je souhaite plus d'informations sur : Obtenir ma carte vitale",
        transmitData: {
          type: 'relatedSubject',
          companyResponseElement: "60af99ae6a7f2",
          informationElementId: "60af8bce92f7c"
        }
      },
      {
        type: "button-function",
        title: "Durée de validité de ma carte vitale",
        userMessage: "Je souhaite plus d'informations sur : Durée de validité de ma carte vitale",
        transmitData: {
          type: 'relatedSubject',
          companyResponseElement: "60af99ae6d65b",
          informationElementId: "60af8bce945df"
        }
      },
      {
        type: "button-function",
        title: "Carte vitale européenne",
        userMessage: "Je souhaite plus d'informations sur : Carte vitale européenne",
        transmitData: {
          type: 'relatedSubject',
          companyResponseElement: "60af99ae6fbee",
          informationElementId: "60af8bce95b6"
        }
      },
      {
        type: "button-function",
        title: "Changement de situation",
        userMessage: "Je souhaite plus d'informations sur : Changement de situation",
        transmitData: {
          type: 'relatedSubject',
          companyResponseElement: "60af99ad8cde1",
          informationElementId: "60af8bce05bac"
        }
      }
    ]
  }
}

export const getRelatedSubjectsApi = (data) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        status: 200,
        messages: [getRelatedSubjectMessage()]
      });
    }, [500]);
  });
}

const packSubjectMessageResponse = (subject) => {
  const relatedMessageButtonElement = {
    type: MESSAGE_ELEMENT_TYPE.BUTTON_SHOW_RELATED,
    title: `Plus d'infomation à ce sujet`,
    transmitData: {
      type: 'showAllRelatedSubjects',
      headCompanyResponseElement: "60af99ae63be4",
      headInformationElementId: "60af8bce8eeb0"
    }
  }
  return ({
    status: 200,
    messages: [
      {
        ...subject,
        messageElements: [...subject.messageElements, relatedMessageButtonElement]
      }
    ]
  });
}

export const getTestData = (messageToBot) => {
  return new Promise((resolve, reject) => {
    let response = null;

    if (testSocialSecurityStructure.parentTitle.toLowerCase().includes(messageToBot.toLowerCase())) {
      response = {
        status: 200,
        messages: [
          social,
          getRelatedSubjectMessage(),
        ]
      }
    } else {
      let result = null;
      _.map(testSocialSecurityStructure.searchSubjects, (subject, index) => {
        if (subject.toLowerCase().includes(messageToBot.toLowerCase())) result = testSocialSecurityStructure.relatedSubjects[index];
      })
      if (result) {
        response = packSubjectMessageResponse(result);
      } else {
        response = {
          status: 200,
          messages: [
            { version: "1", messageElements: [{ type: "text", content: `<p>${messageToBot} 1!!</p>` }] },
            // { version: "1", messageElements: [{ type: "text", content: `<p>${messageToBot} 2!!</p>` }] },
            // { version: "1", messageElements: [{ type: "text", content: `<p>${messageToBot} 3!!</p>` }] },
          ]
        }
      }
    }
    setTimeout(() => {
      resolve(response);
    }, [500]);
  })
}

export const getRelatedSubjectRespsonseApi = (data) => {
  return new Promise((resolve, reject) => {
    let result = null;
    let response = null;

    _.map(testSocialSecurityStructure.relatedSubjects, (subject, index) => {
      if (subject.defaultValues.informationElementId === data.informationElementId &&
        subject.defaultValues.companyResponseElement === data.companyResponseElement) {
        result = subject;
      }
    })

    if (result) {
      response = packSubjectMessageResponse(result);
    } else {
      response = {
        status: 200,
        messages: [
          { version: "1", messageElements: [{ type: "text", content: "<p>No answer !!</p>" }] },
        ]
      }
    }

    setTimeout(() => {
      resolve(response);
    }, [500]);
  });
}

const dummyServiceCards = [
  {
    type: "service-card",
    image: '',
    content_source: 'custom',
    content_custom: "{\"serviceName\":\"Plombier 123\",\"illustrationPhrase\":\"J'ai besoin d'un plombier\",\"description\":\"Testing description This is Plombier 123\",\"messageElements\":[{\"type\": \"text\", \"content\": \"<p>Testing chatbot plombier 111</p>\"}]}",
    form_source: 'internal',
    form: {
      type: 'form',
      url: '/api/v1/partner/request/form',
      form: 'CollaboratorsBundle/Form/Cyconia/BookingPlumberType',
      defaultValues: {
        service: 'Plombier',
        partnerId: 22,
        serviceId: 18,
        beneficiaryFirstname: 'Julianto',
        beneficiaryLastname: 'Yeo',
        address: '53 Rue Jules Michelet, 92700 Colombes, France',
        phone: null
      }
    },
  },
  {
    // NOTE TO SELF: Current payload does not have the info of form_source, and the form data structure is wrong as well, should not have the 'url' key twice 
    type: "service-card",
    image: '',
    content_source: 'custom',
    content_custom: "{\"serviceName\":\"Plombier 123456\",\"illustrationPhrase\":\"J'ai besoin d'un plombier\",\"description\":\"Testing description This is Plombier 123\",\"messageElements\":[{\"type\": \"text\", \"content\": \"<p>Testing chatbot plombier 111</p>\"}]}",
    form_source: 'external',
    form: {
      url: "{\"url\":\"https://form.jotform.com/212563860480355\",\"title\":\"123\",\"target\":\"modal_big\",\"urlParams\":[{\"variable\":\"email\",\"value\":\"{userEmail} \"}]}"
    },
  },
  {
    type: "service-card",
    image: '',
    content_source: 'custom',
    content_custom: "{\"serviceName\":\"Plombier 123456\",\"illustrationPhrase\":\"J'ai besoin d'un plombier\",\"description\":\"plombier 123456 plombier 123456 plombier 123456 plombier 123456 plombier 123456 plombier 123456 plombier 123456 plombier 123456 plombier 123456 plombier 123456 plombier 123456 plombier 123456\",\"messageElements\":[{\"type\": \"text\", \"content\": \"<p>Testing chatbot plombier 111</p>\"}]}",
    form_source: 'internal',
    form: {
      type: 'form',
      url: '/api/v1/partner/request/form',
      form: 'CollaboratorsBundle/Form/Cyconia/BookingPlumberType',
      defaultValues: {
        service: 'Plombier',
        partnerId: 22,
        serviceId: 18,
        beneficiaryFirstname: 'Julianto',
        beneficiaryLastname: 'Yeo',
        address: '53 Rue Jules Michelet, 92700 Colombes, France',
        phone: null
      }
    },
  },
  {
    type: "service-card",
    image: '',
    content_source: 'custom',
    content_custom: "{\"serviceName\":\"Plombier 123456\",\"illustrationPhrase\":\"J'ai besoin d'un plombier\",\"description\":\"Testing description This is Plombier 123\",\"messageElements\":[{\"type\": \"text\", \"content\": \"<p>Testing chatbot plombier 111</p>\"}]}",
    title: 'Plombier 123456',
    description: 'plombier 123456 plombier 123456 plombier 123456 ',
    form_source: 'internal',
    form: {
      type: 'form',
      url: '/api/v1/partner/request/form',
      form: 'CollaboratorsBundle/Form/Cyconia/BookingPlumberType',
      defaultValues: {
        service: 'Plombier',
        partnerId: 22,
        serviceId: 18,
        beneficiaryFirstname: 'Julianto',
        beneficiaryLastname: 'Yeo',
        address: '53 Rue Jules Michelet, 92700 Colombes, France',
        phone: null
      }
    },
  }
]

const dummyServiceResponse = {
  // eslint-disable-next-line no-useless-computed-key
  [`J'ai besoin d'un plombier`]: {
    type: "service-card",
    image: plumberImage,
    content_source: 'custom',
    content_custom: "{\"serviceName\":\"Plombier\",\"illustrationPhrase\":\"J'ai besoin d'un plombier\",\"description\":\"Votre plombier Joe est un artisan local, et sélectionné pour son sérieux.\",\"messageElements\":[{\"type\": \"text\", \"content\": \"<p>Avec notre partenaire Plomberie Joe, vous bénéficiez de 30% de réduction sur la première intervention. N'hésitez plus !</p>\"}]}",
    form_source: 'internal',
    form: {
      type: 'form',
      url: '/api/v1/partner/request/form',
      form: 'CollaboratorsBundle/Form/Cyconia/BookingPlumberType',
      defaultValues: {
        service: 'Plombier',
        partnerId: 22,
        serviceId: 18,
        beneficiaryFirstname: 'Julianto',
        beneficiaryLastname: 'Yeo',
        address: '53 Rue Jules Michelet, 92700 Colombes, France',
        phone: null
      }
    },
  },
  // eslint-disable-next-line no-useless-computed-key
  [`J'ai besoin d'un lavage auto`]: {
    // NOTE TO SELF: Current payload does not have the info of form_source, and the form data structure is wrong as well, should not have the 'url' key twice 
    type: "service-card",
    image: lavageImage,
    content_source: 'custom',
    content_custom: "{\"serviceName\":\"Maxime Lavage\",\"illustrationPhrase\":\"J'ai besoin d'un lavage auto\",\"description\":\"Lavage auto rapide\",\"messageElements\":[{\"type\": \"text\", \"content\": \"<p>Pour un lavage auto rapide, écologique et économique, Maxime se déplace à votre bureau ou à votre domicile. </br> Votre conciergerie vous fait bénéficier de prix négociés : -15% sur tous vos lavages auto !</p>\"}]}",
    form_source: 'external',
    form: {
      url: "{\"url\":\"https://form.jotform.com/212563860480355\",\"title\":\"Lavage auto\",\"target\":\"modal_big\",\"urlParams\":[{\"variable\":\"email\",\"value\":\"{userEmail} \"}]}"
    },
  },
}

const getDummyServiceCard = (number) => {
  const cardArray = []
  let i = 0;
  while(i < number) {
    cardArray.push(dummyServiceCards[i]);
    i++;
  }
  return cardArray;
}

const getDummyServiceCard2 = (message) => {
  const cardArray = []
  cardArray.push(dummyServiceResponse[message]);
  return cardArray;
}


const dummyConciergeResponse = (message) => {
  const number = message.split(' ')[1];
  return {
    status: 200,
    messages: [
      {
        version: "1",
        messageElements: [
          {
            type: "text",
            content: "<p>Voici ce qui peut répondre à votre demande :</p>"
          },
        ]
      },
      {
        version: "1",
        type: 'no-border',
        className: 'full-width',
        messageElements: [
          {
            type: 'card-list',
            cards: getDummyServiceCard2(message)
          }
        ]
      }
    ]
  }
}

export const getConciergeApi = (message) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(dummyConciergeResponse(message));
    }, [100]);
  });
}