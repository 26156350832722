import React from "react";

const WifiIcon = ({
  color = "#5A5A5A",
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon wifiIcon"
      width={width}
      height={height}
      viewBox="0 0 20 24"
      fill="none"
    >
      <path
        d="M11.667.2l.17.005A2.805 2.805 0 0114.467 3v1.883l-.007.1a.8.8 0 01-1.593-.1V3l-.007-.122A1.203 1.203 0 0011.667 1.8H3l-.122.006A1.203 1.203 0 001.8 3v15.2h11.867l.1.006a.8.8 0 01.7.794v2l-.005.17a2.805 2.805 0 01-2.795 2.63H3l-.17-.005A2.805 2.805 0 01.2 21V3l.005-.17A2.805 2.805 0 013 .2h8.667zm.8 20H2.2v.8c0 .406.307.744.7.794l.1.006h8.667a.803.803 0 00.793-.7l.007-.1v-.8zm5.111-13.432a.8.8 0 10-1.136 1.127 5.786 5.786 0 011.687 4.104c0 1.55-.6 3.009-1.687 4.103a.8.8 0 101.136 1.125 7.372 7.372 0 002.151-5.23 7.37 7.37 0 00-1.937-5.005l-.214-.224zm-2.114 2.124a.8.8 0 00-1.133 1.13c.526.528.816 1.23.816 1.978 0 .747-.29 1.45-.816 1.978a.802.802 0 00.566 1.366.791.791 0 00.567-.236A4.375 4.375 0 0016.747 12c0-1.09-.393-2.119-1.112-2.926l-.171-.182zm-3.095 2.01a1.099 1.099 0 100 2.197 1.099 1.099 0 000-2.198z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export default WifiIcon;
