import { useState, useEffect } from 'react';
import {
  useHistory,
  NavLink,
} from "react-router-dom";
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EuroIcon from '@material-ui/icons/Euro';
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import moment from 'moment';
import { toast } from 'react-toastify';

// Store
import {
  logoutExpressing,
} from '../../../../store/sidebar/actions';
import {
  isExpressingLogoutOnError,
  isExpressingLogoutOnSuccess,
  getExpressingAmountData,
  getExpressingOnGoingOrdersData,
  getExpressingPastOrders,
} from '../../../../store/sidebar/selectors';
import {
  getUserExpressingId,
} from '../../../../store/collaborator/selectors';

// Layout
import DetailsSidebarFrontBaseLayout from "../../layouts/detailsSidebarFrontBaseLayout";

// Components
import BackIcon from '../../components/atoms/icons/back';
import ArrowRightIcon from '../../components/atoms/icons/arrowRight';
import GearIcon from '../../components/atoms/icons/gear';
import AnswerIllustration from '../../components/atoms/illustrations/answerIllustration';

// Hooks

// Styles
import {
  Container,
  BorneAccountWrapper,
  Header,
  BackButton,
  Title,
  AnswerIllustrationWrapper,
  IllustrationText,
  EmailDiv,
  BackIconWrapper,
  BorneAccountBox,
  CyconiaInfoContact,
  BorneOrdersList,
  BorneOrdersItem,
  AccordionCustom,
  AccordionDate,
  AccordionOrders,
  AccordionDetailsDate,
  AccordionDetailsPrice,
  ArrowIconWrapper,
  EuroIconWrapper,
  EventAvailableIconWrapper,
  BorneWallet,
  LogoutBorne,
  GearIconWrapper,
  ButtonDropdownCustom
} from "./style";

const ConciergerieBorneAccount = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    logoutOnError,
    logoutOnSuccess,
    userExpressingId,
    expressingAmount,
    onGoingOrders,
    pastOrders,
  } = useSelector(
    state => ({
      logoutOnError: isExpressingLogoutOnError(state),
      logoutOnSuccess: isExpressingLogoutOnSuccess(state),
      userExpressingId: getUserExpressingId(state),
      expressingAmount: getExpressingAmountData(state),
      onGoingOrders: getExpressingOnGoingOrdersData(state),
      pastOrders: getExpressingPastOrders(state),
    }),
    shallowEqual);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onLogoutExpressing = () => {
    dispatch(logoutExpressing());
  };

  useEffect(() => {
    if (logoutOnSuccess) {
      toast.success("Votre compte est déconnecté de Cyconia.", {
        position: toast.POSITION.TOP_RIGHT,
        className: "custom-toast custom-toast-success",
        bodyClassName: "custom-toast__body custom-toast__body-success",
        progressClassName: "custom-toast__progress"
    });
    }
    if (!userExpressingId) {
      setTimeout(() => {
        history.push('/conciergerie/borne/connexion');
      }, 200);
    }
  }, [userExpressingId, logoutOnSuccess]);

  useEffect(() => {
    if (logoutOnError) {
      toast.error("Un problème est survenu, merci de ré-essayer ou de contacter l'équipe Cyconia.", {
        position: toast.POSITION.TOP_RIGHT,
        className: "custom-toast custom-toast-error",
        bodyClassName: "custom-toast__body custom-toast__body-success",
        progressClassName: "custom-toast__progress"
    });
    }
  }, [logoutOnError]);

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleSendEmail = () => {
    const subject = encodeURIComponent('J’ai une question / besoin d’aide');
    const targetEmail = `onvousaimeonvousaide@cyconia.io`;
    // dont change the indent as it will affect the email body
    let body = `Bonjour l’équipe Cyconia,%0A%0A
J’ai une question au sujet de .....%0A%0A`;

    window.open(`mailto:${targetEmail}?subject=${subject}&body=${body}`, '_blank');
  }

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <div>
            {children}
          </div>
        )}
      </div>
    );
  };

  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  };

  return (
    <DetailsSidebarFrontBaseLayout
      id="detailsSidebarLayoutBorneAccount"
      className="detailsSidebarLayoutBorneAccount page-container sidebarPage"
    >
      <Container>
        <BorneAccountWrapper>
          <Header to="/conciergerie">
            <BackButton>
              <BackIconWrapper>
                <BackIcon />
              </BackIconWrapper>
            </BackButton>

            <Title>
              Mon compte Borne
            </Title>
          </Header>

          <LogoutBorne>
            <ButtonDropdownCustom
              className="buttonDropdownBorne"
              startIcon={
                <GearIconWrapper>
                  <GearIcon style={{ color: "#ffffff"}} />
                </GearIconWrapper>
              }
              endIcon={null}
              itemsList={[
                {
                  click: () => onLogoutExpressing(),
                  label: "Déconnexion de mon compte Borne",
                },
              ]}
            />
          </LogoutBorne>

          <CyconiaInfoContact>
            <AnswerIllustrationWrapper className="expressing-contact-icon">
              <AnswerIllustration />
            </AnswerIllustrationWrapper>
            <div className="o-aside-dashboardV2__connect-expressing-contact-content">
              <IllustrationText>Besoin d’aide sur une commande ?</IllustrationText>
              <EmailDiv
                onClick={handleSendEmail}
              >
                  onvousaimeonvousaide@cyconia.io
              </EmailDiv>
              {/* <p>06 80 98 73 37</p> */}
            </div>
          </CyconiaInfoContact>

          <BorneAccountBox className="o-aside-dashboardV2__connect-expressing-orders">
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label="Commandes en cours" {...a11yProps(0)} />
                <Tab label="Commandes passées" {...a11yProps(1)} />
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                {!!onGoingOrders ? (
                  <BorneOrdersList>
                    <BorneOrdersItem>
                      {onGoingOrders.map(order => {
                        const {
                          ID,
                          ServicesAmount,
                          Planned,
                          Started,
                        } = order;
                        return (
                          <AccordionCustom key={ID}>
                            <AccordionSummary
                              expandIcon={
                                <ArrowIconWrapper>
                                  <ArrowRightIcon />
                                </ArrowIconWrapper>
                              }
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <AccordionDate>Le {moment(Started, "YYYY-MM-DD").format("DD/MM/YYYY")}</AccordionDate>
                              <AccordionOrders>Commande N° {ID}</AccordionOrders>
                            </AccordionSummary>
                            <AccordionDetails>
                              <AccordionDetailsPrice>
                                <EuroIconWrapper>
                                  <EuroIcon style={{ color: "#357ED3"}}/>
                                </EuroIconWrapper>
                                {ServicesAmount} €
                              </AccordionDetailsPrice>
                              <AccordionDetailsDate>
                                <EventAvailableIconWrapper>
                                  <EventAvailableIcon style={{ color: "#00B16A"}}/>
                                </EventAvailableIconWrapper>
                                Disponible le {moment(Planned, "YYYY-MM-DD").format("DD/MM/YYYY")}
                              </AccordionDetailsDate>
                            </AccordionDetails>
                          </AccordionCustom>
                        );
                      })}
                    </BorneOrdersItem>
                  </BorneOrdersList>
                ) : (
                  <p>Pas d'ordres en cours</p>
                )}
              </TabPanel>

              <TabPanel value={value} index={1} dir={theme.direction}>
                {!!pastOrders ? (
                  <BorneOrdersList>
                    <BorneOrdersItem>
                      {pastOrders.map(order => {
                        const {
                          ID,
                          ServicesAmount,
                          Planned,
                          Started,
                        } = order;
                        return (
                          <AccordionCustom>
                            <AccordionSummary
                              expandIcon={
                                <ArrowIconWrapper>
                                  <ArrowRightIcon />
                                </ArrowIconWrapper>
                              }
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <AccordionDate>Le {moment(Started, "YYYY-MM-DD").format("DD/MM/YYYY")}</AccordionDate>
                              <AccordionOrders>Commande N° {ID}</AccordionOrders>
                            </AccordionSummary>
                            <AccordionDetails>
                              <AccordionDetailsPrice>
                                <EuroIconWrapper>
                                  <EuroIcon style={{ color: "#357ED3"}}/>
                                </EuroIconWrapper>
                                {ServicesAmount} €
                              </AccordionDetailsPrice>
                              <AccordionDetailsDate>
                                <EventAvailableIconWrapper>
                                  <EventAvailableIcon style={{ color: "#00B16A"}}/>
                                </EventAvailableIconWrapper>
                                Disponible le {moment(Planned, "YYYY-MM-DD").format("DD/MM/YYYY")}
                              </AccordionDetailsDate>
                            </AccordionDetails>
                          </AccordionCustom>
                        );
                      })}
                    </BorneOrdersItem>
                  </BorneOrdersList>
                ) : (
                  <p>Vous n'avez pas encore passé de commandes.</p>
                )}
              </TabPanel>
            </SwipeableViews>
          </BorneAccountBox>

          <BorneWallet>
            <strong>Votre crédit Cyconia</strong>
            <span>{expressingAmount} €</span>
          </BorneWallet>
        </BorneAccountWrapper>
      </Container>
    </DetailsSidebarFrontBaseLayout>
  );
}

export default ConciergerieBorneAccount;
