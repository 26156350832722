import React from 'react'
import { ErrorMessage } from 'formik';

//Style
import {
  ErrorStyle
} from './style'

const GenericErrorMessage = ({ name }) => {
  return (
    <ErrorMessage component={ErrorStyle} name={name} />
  )
}

export default GenericErrorMessage