import React from 'react';
import CancelIcon from '@material-ui/icons/Cancel';

import {
  DialogContainer,
  StyledCloseContainer,
} from './style';

const GenericDialogContainer = ({
  children,
  onClose,
  className
}) => {
  return (
    <DialogContainer className={`${className ? className : ''} genericDialogContainer`}>
      <StyledCloseContainer onClick={onClose}>
        <CancelIcon style={{ fontSize: 30 }} />
      </StyledCloseContainer>
      {children}
    </DialogContainer>
  );
};

export default GenericDialogContainer;