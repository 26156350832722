import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

const MainDefaultStyle = createGlobalStyle`
  ${reset};
  body, * {
    font-family: 'Quicksand', sans-serif !important;
    font-style:normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;
  }
  button {
    margin: 0;
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background: none;
  }
  input,
 	input:focus,
 	button,
 	button:focus {
 	  border: none;
		outline:none;
  }
  main {
  	min-height: 100vh;
  	// overflow: hidden;
  	display: flex;
  }
  input[type="number"] {
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
    right: -10px !important;
  }

  // Start Global SnackBar Style
  .MuiSnackbar-root{
    background-color: transparent;
    .MuiSnackbarContent-root{
      background-color: rgba(0, 0, 0, 0.7);
      backdrop-filter: blur(4px);
      overflow: hidden;
      .MuiSnackbarContent-message{
        color: #ffffff;
        svg{
          path{
            fill: #ffffff;
          }
        }
      }

      &.success,
      &.SnackbarItem-variantSuccess-28,
      &.SnackbarItem-variantSuccess-56{
        &:before{
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 8px;
          height: 100%;
          background-color: #80d69e;
        }
        .MuiSnackbarContent-message{
          svg{
            path{
              fill: #80d69e;
            }
          }
        }
      }

      &.error,
      &.SnackbarItem-variantError-29,
      &.SnackbarItem-variantError-113,
      &.SnackbarItem-variantError-57{
        &:before{
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 8px;
          height: 100%;
          background-color: #ff6d6d;
        }
        .MuiSnackbarContent-message{
          svg{
            path{
              fill: #ff6d6d;
            }
          }
        }
      }

      &.info{
        &:before{
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 8px;
          height: 100%;
          background-color: #357ed3;
        }
        .MuiSnackbarContent-message{
          svg{
            path{
              fill: #357ed3;
            }
          }
        }
      }

      &.warning{
        &:before{
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 8px;
          height: 100%;
          background-color: orange;
        }
        .MuiSnackbarContent-message{
          svg{
            path{
              fill: orange;
            }
          }
        }
      }

      .MuiSnackbarContent-action{
        .MuiButton-text{
          .MuiButton-label{

          }
          .MuiTouchRipple-root{

          }
        }
        .MuiIconButton-root{
          .MuiIconButton-label{
            svg{

            }
          }
          .MuiTouchRipple-root{

          }
        }
      }
    }
  }
  // End Global SnackBar Style

  .MuiTooltip-popper{
    .MuiTooltip-tooltip{
      border-radius: 6px;
      background-color: rgba(0, 0, 0, 0.8);
      backdrop-filter: blur(4px);
      margin: 8px 6px;
      padding: 8px 12px;
      font-size: 12px;
      color: #ffffff;
    }
  }

  .MuiPaper-root.MuiMenu-paper{
    border-radius: 15px;
    .MuiList-root{
      padding: 0;
      .MuiListItem-root{
        font-size: 16px;
        padding: 12px 25px;
        box-sizing: border-box;
        &:hover{
          background-color: #fdd43e;
        }
      }
    }
  }
`;

export default MainDefaultStyle;
