export const BOOKING_CANCEL_STATUS = {
  PAYABLE: 'payable',
  NON_PAYABLE: 'nonPayable',
  NA: null,
};

export const BOOKING_STATUS_DISPLAY = {
  [BOOKING_CANCEL_STATUS.PAYABLE]: 'Payable',
  [BOOKING_CANCEL_STATUS.NON_PAYABLE]: 'Non payable',
  [BOOKING_CANCEL_STATUS.NA]: 'Aucun',
}

export const CANCEL_STATUS_TEXT = {
  [BOOKING_CANCEL_STATUS.PAYABLE]: 'Réservation annulée avec frais',
  [BOOKING_CANCEL_STATUS.NON_PAYABLE]: 'Réservation annulée sans frais',
  [BOOKING_CANCEL_STATUS.NA]: `Pas d'annulation`,
}