import styled from "styled-components";
import Button from '@material-ui/core/Button';

import DrawerCpt from "../../../../components/organisms/drawerCpt";
import { COLORS } from '../../../../constants/design';

export const Container = styled.div`
`;

export const DrawerCptCustom = styled(DrawerCpt)`
  &&{
    background-color: red;
    &.contentManagementDrawer{
      /* background-color: blue; */
    }
  }
`;

export const DrawerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 700px;
  max-height: 1700px;
  position: relative;
  /* border: 10px solid red; */
  .textTitle {
    font-weight: 700;
    font-size: 13px;
    color: #5A5A5A;
    margin: 14px 5px 14px 0px;
  }
  .textTooltip {
    color: ${COLORS.GREY_1};
  }
  .textFieldInput {
    input {
      font-size: 13px;
    }
  }
  .genericMultipleSelect {
    max-width: 700px;
  }
  /* >div:not(:last-child) {
    margin-bottom: 15px;
  }; */
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  >div:not(:last-child) {
    margin-bottom: 15px;
  };
`;

export const FieldWithErrorMessageDiv = styled.div`
`;

export const PreviewButton = styled(Button)`
  && {
    &.MuiButton-root {
      text-transform: none;
      color: white;
      font-size: 0.9rem;
      padding: 10px;
      border-radius: 8px;
      background-color: ${COLORS.PRIMARY_BLUE};
    }
  }
`

export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${COLORS.LIGHT_GREY_3};
`;