import JwtDecode from "jwt-decode";
import moment from "moment";

export const isTokenValid = (token) => {
  if (token !== undefined && token !== null){
    const decoded = JwtDecode(token);
    return (decoded.exp < Date.now() / 1000) ? false : true;
  }
  return false;
};

const isRoleExists = (token, role) => {
  if (isTokenValid(token)) {
    const decoded = JwtDecode(token);
    return decoded["roles"] ? decoded["roles"].includes(role) : false;
  }
  return false;
};

export const isUserAdmin = token => {
  return (
    isRoleExists(token, "ROLE_COMPANY_MANAGER") 
    // && isRoleExists(token, "ROLE_COMPANY_SITE_MANAGER")
    // && isRoleExists(token, "ROLE_CYCONIA_TRAINING")
    // && isRoleExists(token, "ROLE_USER_MANAGER")
    // && isRoleExists(token, "ROLE_USER_ROLE_MANAGER")
    // && isRoleExists(token, "ROLE_CONTENT_MANAGER")
  );
};

export const isAdminCompanySiteManager = token => {
  return isRoleExists(token, "ROLE_COMPANY_SITE_MANAGER");
};

export const isAdminCyconiaTraining = token => {
  return isRoleExists(token, "ROLE_CYCONIA_TRAINING");
};

export const isAdminUserManager = token => {
  return isRoleExists(token, "ROLE_USER_MANAGER");
};

export const isAdminUserRulesManager = token => {
  return isRoleExists(token, "ROLE_USER_ROLE_MANAGER");
};

export const isAdminContentManager = token => {
  return isRoleExists(token, "ROLE_CONTENT_MANAGER");
};

export const isUserConcierge = token => {
  return (
    isRoleExists(token, "ROLE_COMPANY_MANAGER") ||
    isRoleExists(token, "ROLE_CONCIERGE")
  );
};

