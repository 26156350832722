import styled from "styled-components";
import {
  NavLink,
} from "react-router-dom";
import breakpoints from "../../../../../theme-style/breakpoints";

export const AdminIconWrapper = styled.span`
  max-width: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AccountIconWrapper = styled.span`
  max-width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CompetencesIconWrapper = styled.span`
  max-width: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ConciergerieIconWrapper = styled.span`
  max-width: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DashboardIconWrapper = styled.span`
  max-width: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NavLinkTitle = styled.span`
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  margin-top: 4px;
  line-height: 9px;
  font-weight: 500;
  font-size: 10px;
  width: 80%;
  transition: all .2s ease-out;
  @media ${breakpoints.mobileXL} {
    white-space: nowrap;
    width: 100%;
  }
`;

export const NavLinkCircle = styled.div`
  position: relative;
  z-index: 99;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  min-width: 46px;
  height: 46px;
  margin: 6px;
  cursor: pointer;
  padding: 0;
  background-color: #fafafa;
  border-radius: 100px;
  transition: all .2s ease-out;
`;

export const NavLinkCustom = styled(NavLink)`
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  &:before{
    content: '';
    position: absolute;
    top: 0;
    /* left: 0; */
    width: 58px;
    height: 58px;
    border-radius: 100px;
    background-color: transparent;
  }
  &:after{
    content: '';
    position: absolute;
    top: 6px;
    /* left: 0; */
    width: 46px;
    height: 46px;
    border-radius: 100px;
    background-color: #efc31f;
    transition: all .2s ease-out;
  }
  &.active,
  &:hover{
    &:after{
      top: 0;
      width: 58px;
      height: 58px;
    }
    &.navlink-admin{
      ${NavLinkTitle}{
        color: #FFD700;
      }
    }
    ${NavLinkCircle}{

    }
    ${NavLinkTitle}{
      color: rgba(255, 255, 255, 1);
    }
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: auto;
  max-height: 110px;
  padding: 10px 6px;
  box-sizing: border-box;
  background-color: #357ED3;
  position: sticky;
  z-index: 10;
  width: 100%;
  top: 0;
  @media ${breakpoints.mobileL} {
    padding: 10px 10px;
  }
  @media ${breakpoints.mobileXL} {
    padding: 20px 25px;
  }

  ${p => p.numberItems === 1 && {
    justifyContent: 'flex-start',
    // padding: '10px 15px',
    position: 'absolute',
    top: 0,
    left: 0,
    '@media screen and (max-width: 767px)': {
      position: 'fixed',
      padding: '10px 15px !important',
      maxHeight: '50px',
    },
    a: {
      '&:before': {
        width: '40px',
        height: '40px',
      },
      '&:after, &.active:after': {
        width: '40px',
        height: '40px',
      },
      flexDirection: 'row',
      '& > div': {
        width: '30px',
        minWidth: '30px',
        height: '30px',
        margin: '5px',
        '& > span': {
          maxWidth: '17px',
        },
      },
      '& > span': {
        fontSize: '18px',
        fontWeight: '700',
        margin: '0 0 0 10px',
        width: '100%',
      },
    },
    '& ~ div': {
      '& > div': {
        padding: '80px 25px 25px 25px',
        minHeight: '100vh',
        
        '@media screen and (max-width: 767px)': {
          padding: '30px 15px 15px 15px',
          minHeight: 'calc(100vh - 50px)',
        },
      },
    },
  }}
`;
