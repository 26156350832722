import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";

import notFound from "../../assets/images/notFound.png";

import {
  getCompanyData,
} from '../../store/company/selectors';

import {
  MainContainer,
  StyledNotFoundImage,
  StyledNotFoundMessage,
  StyledButton
} from './style';

const NotFound = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const {
    companyData,
  } = useSelector(
    state => ({
      companyData: getCompanyData(state),
    }),
    shallowEqual
  );

  useEffect(() => {
    if (companyData) {
      if (companyData.getGround) {
        setErrorMessage(`Votre conciergerie est disponible sur l'application GetGround ! RDV sur Applestore et Android pour profiter de ses nombreux services 😄`)
      }
    }
  }, [companyData]);

  return (
    <MainContainer>
      <StyledNotFoundImage src={notFound} alt={'notFoundImg'} />
      <StyledNotFoundMessage>
        {`Oups ! cette page n'éxiste pas ☹️`}
      </StyledNotFoundMessage>
      {errorMessage &&
        <StyledNotFoundMessage>
          {errorMessage}
        </StyledNotFoundMessage>
      }
      {!companyData?.getGround &&
        <Link to="/">
          <StyledButton>
            Revenir au menu principal
          </StyledButton>
        </Link>
      }
    </MainContainer>
  );
}

export default NotFound;
