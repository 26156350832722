import React from 'react';
import {
  useDispatch,
} from "react-redux";

// Layout
import DetailsSidebarFrontBaseLayout from "../../../layouts/detailsSidebarFrontBaseLayout";

// Components
import BackIcon from '../../../components/atoms/icons/back';
import SendIcon from '../../../components/atoms/icons/send';

// Hooks
import useTheme from "../../../theme-style/useThemeCustomHook";

// Store
import {
  setMessageFromSideBar
} from '../../../../../store/chatbot/actions';

// Styles
import {
  Container,
  SkillsWrapper,
  Header,
  BackButton,
  Title,
  BackIconWrapper,
  SkillsList,
  SkillsItem,
  SkillsItemCircle,
  SkillsItemContent,
  SkillsItemTitle,
  SkillsItemText,
  SendIconWrapper
} from "../style";

const Practice = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const onSkillsItemClick = (message) => {
    dispatch(setMessageFromSideBar(message));
  }

  return (
    <DetailsSidebarFrontBaseLayout
      id="detailsSidebarLayoutSkillsHelpers"
      className="detailsSidebarLayoutSkillsHelpers page-container sidebarPage"
    >
      <Container>
        <SkillsWrapper>
          <Header to="/competences">
            <BackButton>
              <BackIconWrapper>
                <BackIcon color={theme.colors.white}/>
              </BackIconWrapper>
            </BackButton>
            <Title>
              Cyconia pratique ⚙️
            </Title>
          </Header>

          <SkillsList>
            <SkillsItem 
              onClick={() => onSkillsItemClick(
                "Quel temps fait-il demain ?"
              )}
            >
              <SkillsItemCircle> M </SkillsItemCircle>
              <SkillsItemContent>
                <SkillsItemTitle>
                  Météo
                </SkillsItemTitle>
                <SkillsItemText>
                  Dois-je prendre mon parapluie ? Cyconia vous dit tout ! 🌤
                </SkillsItemText>
              </SkillsItemContent>
              <SendIconWrapper>
                <SendIcon />
              </SendIconWrapper>
            </SkillsItem>

            <SkillsItem 
              onClick={() => onSkillsItemClick(
                "Peux-tu me donner les actus du moment ?"
              )}
            >
              <SkillsItemCircle> A </SkillsItemCircle>
              <SkillsItemContent>
                <SkillsItemTitle>
                  Actualité
                </SkillsItemTitle>
                <SkillsItemText>
                  Pour avoir l’essentiel de l’actu, il suffit de demander ! 🗞
                </SkillsItemText>
              </SkillsItemContent>
              <SendIconWrapper>
                <SendIcon />
              </SendIconWrapper>
            </SkillsItem>

          </SkillsList>
        </SkillsWrapper>
      </Container>
    </DetailsSidebarFrontBaseLayout>
  );
}

export default Practice;
