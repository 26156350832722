import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { toast } from 'react-toastify';
import {
  shallowEqual,
  useSelector,
  useDispatch,
} from "react-redux";

// import {
//   withRouter,
//   useLocation,
//   useParams,
// } from 'react-router-dom';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
// import { NotificationManager } from 'react-notifications';
import moment from 'moment';

// Store
import {
  getElementInformationList,
  getFeebackByInfoEl,
  getFeedsStatInfo,
  getElementInformationResponseById,
  getPositiveBotFeedback,
  updateNegativeBotFeedback,
  updateAllElementInformation,
  resetEditTraining,
} from '../../../../../../store/adminFront/actions';
import {
  getElementInformationByCompanyData,
  getElementInformationListData,
  getFeebackByInfoElData,
  getFeedsStatInfoData,
  getElementInformationResponseByIdData,
  getPositiveBotFeedbackData,
  isActiveElementInformation,
  isUpdateNegativeBotFeedbackOnSuccess,
  isAllUpdateElementInformationOnSuccess,
  getUpdatedResponseId,
} from '../../../../../../store/adminFront/selectors';
import {
	getUser,
} from '../../../../../../store/collaborator/selectors';
// Import components
import HeaderTrainingEditor from './components/Header/';
import InformationElementBanner from './components/Banners/InformationElemBanner'
import FeedBanner from './components/Banners/FeedsBanner/index';
import FeedList from './components/FeedsList/';
import ModalDisapprove from './components/modalDisapprove/';
import Loader from '../../helpers/Loader';
import CroosElementBanner from './components/Banners/CrossElementBanner'
import ResponseBuilder from '../../../../../../components/organisms/ResponseBuilder';

// Styles Theme Hooks
import useTheme from "../../../../theme-style/useThemeCustomHook";

// Import Icons
import LikeIcon from '../../../../components/atoms/icons/like'
import DislikeIcon from '../../../../components/atoms/icons/dislike'
import CheckPlainIcon from '../../../../components/atoms/icons/checkPlain'
import WarningIcon from '../../../../components/atoms/icons/warning'

import { history } from '../../../../helpers/history';

// Import style
import {
  Container,
  SwipeableDrawerCustom,
  SwipeableContainer,
  ContentContainer,
  CustomFeedModalHeader,
  CustomFeedModalOption,
  CustomFeedModalInput,
  CustomFeedModalFooter,
  CustomFeedModalFooterBtnCancel,
  CustomFeedModalFooterBtnSend,
  CustomFeedModal,
  CustomFeedModalContainer,
  ApproveDisapproveWrapper,
  ApproveDisapproveStatus,
  SwipeableContainerLoader,
  EditInformationHead,
  EditInformationTitle,
  EditInformationStatus,
  ContentRightContainer,
  ContentLeftContainer,
  ButtonCptClose,
  ButtonCptCustom,
  EditInformationWrapper,
  LikeIconWrapper,
  DislikeIconWrapper,
  Approve,
  Disapprove,
  ResponseContainer,
  StyledMenu,
  StyledTitle,
} from './style';

import {
  convertMessageElementsToOldVersion
} from './helpers';

const allPermissions = [
  'Accès au dashboard',
  'Liste des utilisateurs'
];

const SwipeableTrainingEditor = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
		user,
    allSites,
    informationListData,
    feedbacks,
    feedbacksStats,
    elementInformationResponseByIdData,
    positiveBotFeedback,
    isActiveEI,
    isNegativeUpdateSuccess,
    isUpdateAllOnSuccess,
    updatedResponseId,
  } = useSelector(
    state => ({
			user: getUser(state),
      allSites: getElementInformationByCompanyData(state),
      informationListData: getElementInformationListData(state),
      feedbacks: getFeebackByInfoElData(state),
      feedbacksStats: getFeedsStatInfoData(state),
      elementInformationResponseByIdData: getElementInformationResponseByIdData(state),
      positiveBotFeedback: getPositiveBotFeedbackData(state),
      isActiveEI: isActiveElementInformation(state),
      isNegativeUpdateSuccess: isUpdateNegativeBotFeedbackOnSuccess(state),
      isUpdateAllOnSuccess: isAllUpdateElementInformationOnSuccess(state),
      updatedResponseId: getUpdatedResponseId(state),
    }),
    shallowEqual
	);

  const [eiid, setEiid] = useState(null);
  const [toggleEditor, setToggleEditor] = useState(false);
  const [selectedSiteId, setSelectedSiteId] = useState(null);
  const [eiParentTitle, setEiParentTitle] = useState(null);
  const [contentData, setContentData] = useState(null);
  const [processedFeeds, setProcessedFeeds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currResponse, setCurrResponse] = useState({});
  const [eiData, setEiData] = useState(null);
  const [feedsStats, setFeedsStats] = useState({})
  const [showList, setShowList] = useState(false);
  const [feedsList, setFeedsList] = useState([]);
  const [savedSiteId, setSaveSideId] = useState(null);
  
  const [isAdvanceResponse, setIsAdvanceResponse] = useState(true);
  const [openModalDisapprove, setOpenModalDisapprove] = useState(false);
  const [isNewPayload, setIsNewPayload] = useState(false);
  const [siteList, setSiteList] = useState([]);

  const toggleDrawer = (open) => {
    if (!open) {
      if (eiid) {
        history.push(window.location.pathname.substring(
          window.location.pathname.indexOf("?opendrawerbyid="),
          window.location.pathname.length
        ));
      }
      dispatch(resetEditTraining());
    } else {
      setProcessedFeeds([]);
      setIsLoading(false);
      setCurrResponse({});
      setEiData(null);
      setFeedsStats(null);
      setSelectedSiteId(null);
      setShowList(false);
      setFeedsList(null);
    }
    setToggleEditor(open);
  };

  const closeModalDisapprove = (event) => {
    if (event && event.type === 'keydown'
      && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpenModalDisapprove(false);
  };

  useEffect(() => {
    if (isNewPayload) {
      setIsAdvanceResponse(true);
    }
  }, [isNewPayload]);

  useEffect(() => {
    if (user) {
      let title = null;
      let _eiid = null;

      if (user && user.permissions
        && (user.permissions[allPermissions[0]] !== undefined ||
          user.permissions[allPermissions[1]] !== undefined)) {
        if (window.location.search.indexOf("?opendrawerbyid=") > -1) {
          /* Params = window.location.search.substring(
            16, window.location.search.length
          ) */
          /* Params = Params.split('&'); */
          const urlParams = new URLSearchParams(window.location.search);
          _eiid = urlParams.get('opendrawerbyid');
          title = urlParams.get('title');
          dispatch(getElementInformationList(_eiid));
          dispatch(getFeebackByInfoEl(_eiid));
          dispatch(getFeedsStatInfo(_eiid));
          dispatch(getElementInformationResponseById(_eiid));
          toggleDrawer(true);
          setEiid(_eiid);
          setEiParentTitle(title);
        }
      }
    }
  }, [window.location.search, user]);

  useEffect(() => {
    if (feedbacks !== null) {
      setFeedsList(feedbacks);
    }
  }, [feedbacks]);
  
  useEffect(() => {
    if (feedbacksStats !== null) {
      setFeedsStats(feedbacksStats);
    }
  }, [feedbacksStats]);

  useEffect(() => {
    if (elementInformationResponseByIdData !== null) {
      setEiData(elementInformationResponseByIdData);
      if (!!elementInformationResponseByIdData?.responses?.length && !!allSites?.length) {
        let resCurr = [];
        // console.log('updatedResponseId', updatedResponseId)
        resCurr = elementInformationResponseByIdData.responses.filter(x => x.company_site_id === (savedSiteId || allSites[0].id));
        console.log('resCurr', resCurr)
        if (updatedResponseId) {
          resCurr = elementInformationResponseByIdData.responses.filter(x => x.id === updatedResponseId);
          console.log('resCurr', resCurr)
        }
        if (!!resCurr?.length) {
          const [response] = resCurr;
          try {
            const parsedResponse = JSON.parse(response.response);
            setCurrResponse({
              ...response
            });
            setContentData(parsedResponse);
            setIsNewPayload(true);
          }
          catch(error) {
            console.warn("This is old response version, use default messageElements structure", error);
            if (response.response === '') {
              setIsNewPayload(true); // if this is new payload, old response button will not be shown anymore
            }
            else {
              setIsNewPayload(false);
            }
            setCurrResponse(response);
            setContentData({ version: "1", messageElements: [] });
          }    
        }
      }
      setSaveSideId(null);
    }
  }, [elementInformationResponseByIdData, allSites, updatedResponseId]);

  useEffect(() => {
    if (allSites) {
      const formatedSiteList = allSites.map((site) => ({
        value: site.id,
        label: site.name
      }))
      const newSiteList = [
        {
          value: '--',
          label: '--'
        },
        ...formatedSiteList,
      ]
      setSiteList(newSiteList);
    }
  }, [allSites]);

  const handleChangeSite = useCallback((e, selectedSiteId) => {
    e.preventDefault();
    setSelectedSiteId(selectedSiteId);
    setIsNewPayload(false);
    let resCurr = [];
    if (eiData && eiData.responses.length > 0) {
      resCurr = eiData.responses.filter(res => res.company_site_id
        == selectedSiteId)
      
      console.log('change site currRes', resCurr)
        
      if (!!resCurr?.length) {
        const [response] = resCurr;
        try {
          const parsedResponse = JSON.parse(response.response);
          setCurrResponse({
            ...response
          });
          setContentData(parsedResponse);
          setIsNewPayload(true);
        }
        catch (error) {
          console.warn("This is old response version, use default messageElements structure", error);
          if (response.response === '') {
            setIsNewPayload(true); // if this is new payload, old response button will not be shown anymore
          }
          else {
            setIsNewPayload(false);
          }
          setCurrResponse(response);
          setContentData({ version: "1", messageElements: [] });
        }
      } else {
        console.log('ICI')
        setCurrResponse({ response: "", id: null, company_site_id: selectedSiteId });
        setContentData({ version: "1", messageElements: [] });
      }
    } else {
      setCurrResponse({ response: "", id: null, company_site_id: selectedSiteId });
      setContentData({ version: "1", messageElements: [] });
    }
    setShowList(false);
  }, [eiData]);

  const handleIsSelectedFeed = (idx) => {
    let newProcessedFeeds = [...processedFeeds];

    if (newProcessedFeeds.includes(idx)) {
      newProcessedFeeds = newProcessedFeeds.filter(x => x !== idx);
    } else {
      newProcessedFeeds.push(idx);
    }

    setProcessedFeeds(newProcessedFeeds);
  }

  // Handle update All
  const handleUpdateAllData = () => {
    dispatch(updateAllElementInformation(
      eiid,
      processedFeeds,
      isAdvanceResponse ? contentData : currResponse.response,
      currResponse.id,// || eiData.responses[0]?.id,
      selectedSiteId
    ));
    setSaveSideId(selectedSiteId);
  };

  const hanldeCurrReponseChanges = (data) => {
    if (Object.keys(currResponse).length > 1 && !showList) {
      const newCurrResponse = { ...currResponse };
      newCurrResponse.response = data;
      setCurrResponse(newCurrResponse);
    }
  };

  useEffect(() => {
    if (positiveBotFeedback) {
      const newCurrentResponse = { ...currResponse };
      newCurrentResponse.validated_at = positiveBotFeedback.created_at
      newCurrentResponse.approuved_by = positiveBotFeedback.collaborator_id.firstname + " " + positiveBotFeedback.collaborator_id.lastname;
      setCurrResponse(newCurrentResponse);
      toast.success("Vos modification ont bien été enregistrées.", {
        position: toast.POSITION.TOP_RIGHT,
        className: "custom-toast custom-toast-success",
        bodyClassName: "custom-toast__body custom-toast__body-success",
        progressClassName: "custom-toast__progress"
      }); 
    }
  }, [positiveBotFeedback]);

  useEffect(() => {
    if (isNegativeUpdateSuccess) {
      closeModalDisapprove();
      toast.success("Votre retour a été enregistré avec succès.", {
        position: toast.POSITION.TOP_RIGHT,
        className: "custom-toast custom-toast-success",
        bodyClassName: "custom-toast__body custom-toast__body-success",
        progressClassName: "custom-toast__progress"
      });
    }
  }, [isNegativeUpdateSuccess]);

  useEffect(() => {
    if (isUpdateAllOnSuccess) {
      toast.success("Vos changements ont été enregistrés avec succès.", {
        position: toast.POSITION.TOP_RIGHT,
        className: "custom-toast custom-toast-success",
        bodyClassName: "custom-toast__body custom-toast__body-success",
        progressClassName: "custom-toast__progress"
      });
    }
  }, [isUpdateAllOnSuccess]);

  const handlePositiveFeedback = () => {
    dispatch(getPositiveBotFeedback(currResponse.id));
  }

  const handlenegativeFeed = ({ comment, responseStatus }) => {
    dispatch(updateNegativeBotFeedback(eiid, currResponse.id, responseStatus, comment));
  };

  // Update contentData everytime the data inside the new builder have changed
  const saveResponse = (index, newMessageElements) => {
    const newContentData = { ...contentData, messageElements: newMessageElements };
    setContentData(newContentData);
    // // convert the new messageElements to the old version, to be remove when newChatbot is ready
    const convertedMessageElements = convertMessageElementsToOldVersion(newMessageElements);
    const newCurrResponse = { ...currResponse, response: convertedMessageElements };
    setCurrResponse(newCurrResponse);
  };

  const currentSite = useMemo(() => {
    return !!allSites?.length ? allSites.find(x => x.id === selectedSiteId) : null
  }, [allSites, selectedSiteId]);

  const handleResponseCopy = (value) => {
    const selectedResponse = elementInformationResponseByIdData.responses.filter(response => response.company_site_id === value)[0];
    if (selectedResponse) {
      try {
        const parsedResponse = JSON.parse(selectedResponse.response);
        setContentData(parsedResponse);
        setIsNewPayload(true);
      }
      catch (e) {
        console.warn("This is old response version, use default messageElements structure", e);
        setContentData({ version: "1", messageElements: [] })
        setIsNewPayload(true);
      }
      toast.success("Votre réponse est copiée, veuillez appuyer sur enregistrer pour l'enregistrer.", {
        position: toast.POSITION.TOP_RIGHT,
        className: "custom-toast custom-toast-success",
        bodyClassName: "custom-toast__body custom-toast__body-success",
        progressClassName: "custom-toast__progress"
      });
    }
  }

  if (toggleEditor) {
    return (
      <Container>
        <SwipeableDrawerCustom
          anchor="right"
          open
          onClose={() => toggleDrawer(false)}
          onOpen={() => toggleDrawer(true)}
        >
          <ButtonCptClose
            label="Fermer"
            appearence="outlineWhite"
            onClick={() => toggleDrawer(false)}
          />

          {isLoading ?
            <SwipeableContainerLoader>
              <Loader />
            </SwipeableContainerLoader>
            :
            <SwipeableContainer>
              <HeaderTrainingEditor
                toggleDrawer={toggleDrawer}
                setToggleEditor={setToggleEditor}
                setShowList={setShowList}
                showList={showList}
                handleUpdateAllData={handleUpdateAllData}
                sitesList={allSites || []}
                selectedSite={currentSite}
                handleSelection={handleChangeSite}
              />

              <ContentContainer>
                <ContentLeftContainer>
                  <InformationElementBanner
                    data={informationListData}
                    toggled={isActiveEI}
                  />
                  {(eiData?.eip !== undefined && eiParentTitle != undefined && eiData?.eip?.length !== 0) && (
                    <CroosElementBanner eic={eiData.eip} parent={eiParentTitle} />
                  )}
                  <EditInformationWrapper>
                    <EditInformationHead>
                      <EditInformationTitle>
                        Vos informations pour : {currentSite?.name || '-'}
                      </EditInformationTitle>
                      {(!!currResponse?.updated_at) ?
                        <EditInformationStatus>
                          Dernière modification le :
                          {" "}{moment(currResponse.updated_at).format("DD/MM/YYYY HH:mm") + " "}
                          {currResponse?.updated_by == null ? '' : `par ${currResponse.updated_by}`}
                        </EditInformationStatus>
                        :
                        <EditInformationStatus>
                          Dernière modification le : -
                        </EditInformationStatus>}
                    </EditInformationHead>
                    {!isNewPayload && (
                      <ButtonCptCustom
                        label={isAdvanceResponse ? "Passer à une réponse simple" : "Passer à une réponse avancée"}
                        appearence={isAdvanceResponse ? "lightBlue" : "gradientBlue"}
                        onClick={() => setIsAdvanceResponse(!isAdvanceResponse)}
                      />
                    )}
                    <ResponseContainer key="simpleResponse">
                      <ReactQuill
                        theme="snow"
                        value={currResponse.response || ''}
                        onChange={hanldeCurrReponseChanges}
                        readOnly={isAdvanceResponse}
                        style={isAdvanceResponse ? { display: "none" } : {}}
                      />
                      <ApproveDisapproveWrapper isAdvanceResponse={isAdvanceResponse}>

                        <Approve onClick={handlePositiveFeedback}>
                          <LikeIconWrapper>
                            <CheckPlainIcon color={theme && theme.colors ? theme.colors.grey4 : ""} />
                          </LikeIconWrapper>
                          <span>J’approuve cette information</span>
                        </Approve>

                        <Disapprove
                          onClick={() => setOpenModalDisapprove(true)}
                        >
                          <DislikeIconWrapper>
                            <WarningIcon color={theme && theme.colors ? theme.colors.grey4 : ""} />
                          </DislikeIconWrapper>
                          <span>Je désapprouve cette information</span>
                        </Disapprove>
                      </ApproveDisapproveWrapper>
                    </ResponseContainer>
                    {(isAdvanceResponse && contentData) &&
                      <ResponseContainer key="advanceResponse">
                        <StyledTitle
                          title={'Copier la réponse d’un autre site'}
                        />
                        <StyledMenu
                          key={selectedSiteId}
                          list={siteList}
                          onChange={(value) => handleResponseCopy(value)}
                        />
                        <ResponseBuilder
                          messageElements={contentData.messageElements || []}
                          siteId={currResponse.company_site_id}
                          parentIndex={0}
                          saveResponse={saveResponse}
                        />
                      </ResponseContainer>
                    }
                    {currResponse.validated_at !== null && currResponse.validated_at !== undefined &&
                    currResponse.approuved_by !== null && currResponse.approuved_by !== undefined ?(
                        <ApproveDisapproveStatus>
                        Dernière validation le : {" "}
                        {moment(currResponse.validated_at).format("DD/MM/YYYY HH:mm")} par
                        {" " + currResponse.approuved_by}
                      </ApproveDisapproveStatus>
                    ) : (
                      <ApproveDisapproveStatus>
                        Dernière validation le : -
                      </ApproveDisapproveStatus>
                    )}
                  </EditInformationWrapper>

                  <ModalDisapprove
                    openModal={openModalDisapprove}
                    closeModal={closeModalDisapprove}
                    actionClick={handlenegativeFeed}
                  />

                </ContentLeftContainer>
                
                <ContentRightContainer>
                  <FeedBanner
                    feedsStats={feedsStats}
                    feedsList={feedsList}
                    selectedSite={currentSite}
                  />
                  <FeedList
                    key={null}
                    sitesList={allSites}
                    processedFeeds={processedFeeds}
                    handleIsSelectedFeed={handleIsSelectedFeed}
                    feedsList={feedsList}
                    selectedSite={currentSite}
                  />
                </ContentRightContainer>
              </ContentContainer>
            </SwipeableContainer>
          }
        </SwipeableDrawerCustom>
      </Container>
    );
  }
  return null;
};

export default SwipeableTrainingEditor;
