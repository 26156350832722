import React from "react";

const LocationIcon = ({
  color = "#FFFFFF",
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon locationIcon"
      width={width}
      height={height}
      viewBox="0 0 14 16"
      fill="none"
    >
      <path
        d="M7 .95c1.224 0 2.387.362 3.361 1a5.954 5.954 0 012.229 2.664 5.822 5.822 0 01.343 3.43 5.886 5.886 0 01-1.448 2.822l-.21.216-3.915 3.84-4.635-3.84a5.893 5.893 0 01-1.658-3.038 5.822 5.822 0 01.343-3.43A5.954 5.954 0 013.64 1.95c.974-.638 2.137-1 3.361-1zm0 1.096c-.998 0-1.947.296-2.742.817A4.86 4.86 0 002.44 5.036a4.75 4.75 0 00-.28 2.796c.186.92.644 1.786 1.35 2.478h0L7 13.733l3.49-3.423.187-.193c.6-.657.994-1.45 1.163-2.285a4.75 4.75 0 00-.28-2.796 4.86 4.86 0 00-1.818-2.173A5.003 5.003 0 007 2.046zm0 2.016c.712 0 1.356.283 1.823.74a2.497 2.497 0 010 3.577c-.467.458-1.111.74-1.823.74a2.595 2.595 0 01-1.823-.74 2.497 2.497 0 010-3.577A2.595 2.595 0 017 4.062zm0 1.091c-.405 0-.772.161-1.038.422a1.419 1.419 0 000 2.032c.266.26.633.421 1.038.421s.772-.16 1.038-.421a1.419 1.419 0 000-2.032A1.477 1.477 0 007 5.153z"
        stroke={color}
        strokeWidth={0.1}
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export default LocationIcon;
