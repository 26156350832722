import React from "react";

import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useHistory
} from "react-router-dom";

import TicketRequestDrawer from "./components/TicketRequestDrawer";
import CreateNewDrawer from "./components/CreateNewDrawer";

const Routes = () => {
  const history = useHistory();
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/new`} render={() => (
        <CreateNewDrawer
          visible={true}
          closeFunction={() => history.push(`${path}`)}
        />
      )} />
      <Route exact path={`${path}/:id`} render={() => (
        <TicketRequestDrawer
          visible={true}
          closeFunction={() => history.push(`${path}`)}
        />
      )} />
      <Redirect to={`${path}`} />
    </Switch>
  );
}

export default Routes;
