import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';

import GenericButton from '../../../../components/molecules/GenericButton';

export const ErrorText = styled.p`
  display: flex;
  width: 100%;
  font-size: 13px;
  font-weight: 500;
  font-style: italic;
  color: #ff4f4f;
  text-align: left;
  padding: 5px 0 0 0;
`;

export const Text = styled.p`
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
  line-height: 18px;
  max-width: 420px;
  margin: 0 auto 10px auto;
  span{
    font-size: 13px;
    font-weight: 400;
    color: #808080;
  }
`;

export const Title = styled.h2`
  font-size: 21px;
  font-weight: 600;
  margin-bottom: 20px;
`;

export const ModalCustom = styled(Dialog)`
  &&{
    .MuiDialog-container{
      .MuiDialog-paper{
        width: 100%;
        padding: 30px;
        box-sizing: border-box;
        text-align: center;
        form{
          .input-group.input-password-complexity{
            margin-bottom: 0;
            & > label{

            }
            & > input{
              height: 60px;
              font-size: 14px;
              color: #5a5a5a;
              border-radius: 6px;
              display: block;
              width: 100%;
              padding: 14px 20px;
              box-sizing: border-box;
              border: none;
              background-color: #f5f5f5;
              outline: none;
              font-weight: 800;
            }
            .input-password-complexity-meter{
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding: 5px 0;
              meter{

              }
              p{
                font-size: 12px;
                color: #5a5a5a;
                text-align: left;
                margin: 0 0 0 10px;
              }
            }
          }
          .input-group{
            position: relative;
            margin-top: 30px;
            margin-bottom: 15px;
            & > label{
              font-size: 12px;
              font-weight: 900;
              color: #838383;
              margin-bottom: 10px;
              display: block;
            }
            & > div{
              position: relative;
              input{
                height: 60px;
                font-size: 14px;
                color: #5a5a5a;
                border-radius: 6px;
                display: block;
                width: 100%;
                padding: 14px 20px;
                box-sizing: border-box;
                border: none;
                background-color: #f5f5f5;
                outline: none;
                font-weight: 800;
              }
              svg{
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
              }
            }
          }
        }
        button{
          display: block;
          width: 100%;
          padding: 14px;
          text-align: center;
          font-weight: 700;
          border: none;
          text-decoration: none;
          border-radius: 6px;
          cursor: pointer;
          font-size: 14px;
          height: 60px;
          margin-top: auto;
          background-color: #463e96;
          color: #fff;
          text-transform: uppercase;
        }
      }
    }
  }
`;

export const StyledAzureButton = styled(GenericButton)`
  && {
    &.MuiButton-root {
      width: 100%;
      height: 60px;
      margin-top: 1rem;
    }
  }
`;