import styled from "styled-components";

export const CardText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-left: 6px;
  font-size: 11px;
  font-weight: 500;
  color: ${props => props.txtColor || "#5A5A5A"};
`;

export const StatusBlock = styled.div`
    display: flex;
    width: 8px;
    min-width: 8px;
    height: 8px;
    border-radius: 2px;
    background-color: ${props => props.bgColor || "#DEDEDE"};
`;

export const CompanyIconWrapper = styled.div`
    display: flex;
    width: 10px;
    min-width: 10px;
    height: 10px;
`;

export const CheckIconWrapper = styled.div`
    display: flex;
    width: 10px;
    min-width: 10px;
    height: 7px;
    margin-left: 6px;
`;

export const UserIconWrapper = styled.div`
    display: flex;
    width: 10px;
    min-width: 10px;
    height: 10px;
`;

export const TargetIconWrapper = styled.div`
    display: flex;
    width: 10px;
    min-width: 10px;
    height: 10px;
`;

export const FeedsCard2Content = styled.div`
    color: #5A5A5A;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: auto;
`;

export const FeedsCard2FooterRight = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  span{
    font-size: 11px;
    font-weight: 600;
    color: #BBBBBB;
    text-align: right;
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
`;

export const FeedsCard2HeaderRight = styled.div`

`;

export const FeedsCard2FooterLeft = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    span{
      font-size: 11px;
      font-weight: 600;
      color: #BBBBBB;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      max-width: 200px;
    }
`;


export const FeedsCard2Footer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
`;

export const BlockTitle = styled.div`
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    color: #777777;
    margin-bottom: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    /* &.feedBlockTitle{
      &:first-child{
        background: red;
      }
      &:nth-child(2){
        background: blue;
      }
      &:nth-child(3){
        background: pink;
      }
      &:nth-child(4){
        background: grey;
      }
      &:last-child{
        background: green;
      }
    } */
`;

export const FeedsCard2Block = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 4px;
    margin-right: 0px;
    line-height: 8px;
    height: 20px;
    padding: 5px 6px;
    background-color: ${props => props.bgColor || "#EDEDED"};
    transition: all .2s ease-out;
    span{

    }
`;

export const FeedsCard2HeaderLeft = styled.div`
    display: flex;
    ${FeedsCard2Block}{
      margin-right: 6px;
    }
`;

export const FeedsCard2Header = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 13px;
`;

export const FeedsCard2 = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 14px 12px;
    margin-bottom: 19px;
    border-radius: 6px;
    cursor: pointer;
    margin: 6px -12px;
    height: auto;
    min-height: 140px;
    padding: 12px;
    background-color: ${props => props.isSlected ? "#F2F6FF" : "#FFFFFF"};
    transition: all .2s ease-out;
    &:last-child{
      &:after{
        display: none;
      }
    }
    &:before{
      content: '';
      position: absolute;
      bottom: -6px;
      left: 0;
      width: 100%;
      height: 7px;
      transition: all .2s ease-out;
      background-color: transparent;
    }
    &:after{
      content: '';
      position: absolute;
      bottom: -7px;
      left: 12px;
      left: ${props => props.isSlected ? "0" : "12px"};
      width: ${props => props.isSlected ? "calc(100% - 0px)" : "calc(100% - 24px)"};
      height: 1px;
      transition: all .2s ease-out;
      background-color: #F4F4F4;
      transition: all .2s ease-out;
    }
    &:hover{
      background-color: #F2F6FF;
      &:after{
        left: 0px;
        width: calc(100% - 0px);
      }
      ${FeedsCard2HeaderRight}{
        ${FeedsCard2Block}{
          background-color: #40B176;
          ${TargetIconWrapper}{
            svg path{
              fill: #ffffff;
            }
          }
          span{
            color: #ffffff;
          }
        }
      }
      ${FeedsCard2FooterRight}{
        span{
          color: #40B176;
        }
        ${CheckIconWrapper}{
          svg path{
            fill: #40B176;
          }
        }
      }
    }
    ${FeedsCard2HeaderRight}{
      ${FeedsCard2Block}{
        background-color: ${props => props.isSlected ? "#40B176" : "#EDEDED"};
        ${TargetIconWrapper}{
          svg path{
            fill: ${props => props.isSlected ? "#FFFFFF" : "#BBBBBB"};
          }
        }
        span{
          color: ${props => props.isSlected ? "#FFFFFF" : "#5A5A5A"};
          transition: all .2s ease-out;
        }
      }
    }
    ${FeedsCard2FooterRight}{
      span{
        color: ${props => props.isSlected ? "#40B176" : "#BBBBBB"};
        transition: all .2s ease-out;
      }
      ${CheckIconWrapper}{
        svg path{
          fill: ${props => props.isSlected ? "#40B176" : "#BBBBBB"};
        }
      }
    }
`;

export const FeedsCardOther = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 14px 12px;
    margin-bottom: 19px;
    cursor: pointer;
    margin: 0 -10px;
    height: auto;
    min-height: 76px;
    border-bottom: 1px solid #e5eeff;
    padding: 12px;
    /* background-color: #40B176; */
    background-color: #eff4ff;
    transition: all .2s ease-out;
    border-radius: 0;
    &:first-child{
      border-radius: 6px 6px 0 0;
    }
    &:last-child{
      border-radius: 0 0 6px 6px;
      border-bottom: 0;
    }
    &:hover{
      background-color: #D8E5FD;
      &:after{
        left: 0px;
        width: calc(100% - 0px);
      }
      ${FeedsCard2FooterRight}{
        span{
          color: #40B176;
        }
        ${CheckIconWrapper}{
          svg path{
            fill: #40B176;
          }
        }
      }
    }
    ${FeedsCard2HeaderLeft}{
      ${FeedsCard2Block}{
        background-color: #e4edff;
        ${CompanyIconWrapper}{
          svg path{

          }
        }
        span{
          color: #5A5A5A;
        }
      }
    }
    ${FeedsCard2HeaderRight}{
      ${FeedsCard2Block}{
        background-color: #e4edff;
        ${TargetIconWrapper}{
          svg path{
            fill: #40B176;
          }
        }
        span{
          color: #5A5A5A;
          transition: all .2s ease-out;
        }
      }
    }
    ${FeedsCard2Footer}{
      ${UserIconWrapper}{
        svg path{
          fill: #ffffff;
        }
      }
      ${CardText}{
        color: #FFFFFFF;
        & > span{
          color: #FFFFFFF;
        }
      }
    }
`;
