import * as chatBot from './actions';
import _ from 'lodash';

const initGetValue = {
  loading: false,
  success: false,
  error: null,
}

const modifyGet = (type, error = null) => {
  return {
    loading: type === 'request' ? true : false,
    success: type === 'success' ? true : false,
    error: type === 'error' ? error : null,
  }
}

const INITIAL_STATE = {
  messages: {
    data: [],
    get: initGetValue
  },
  formSchema: {
    data: null,
    get: initGetValue
  },
  messageFromSideBar: {
    data: null,
    get: initGetValue
  },
  feedbackStatus: {
    data: null,
    get: initGetValue
  }
};

export function chatbot(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case chatBot.GET_BOT_RESPONSE.REQUEST:
      return {
        ...state,
        messages: {
          data: action.message ? [...state.messages.data, action.message] : state.messages.data,
          get: modifyGet('request')
        },
      };
    case chatBot.GET_BOT_RESPONSE.SUCCESS:
      return {
        ...state,
        messages: {
          data: [...state.messages.data, ...action.messages],
          get: modifyGet('success')
        },
      };
    case chatBot.GET_BOT_RESPONSE.FAILURE:
      return {
        ...state,
        messages: {
          data: [...state.messages.data, ...action.messages],
          get: modifyGet('error', action.error)
        },
      };
    case chatBot.GET_BOT_FORM.REQUEST:
      return {
        ...state,
        formSchema: {
          data: null,
          get: modifyGet('request')
        },
      };
    case chatBot.GET_BOT_FORM.SUCCESS:
      return {
        ...state,
        formSchema: {
          data: action.formSchema,
          get: modifyGet('success')
        },
      };
    case chatBot.GET_BOT_FORM.FAILURE:
      return {
        ...state,
        formSchema: {
          data: null,
          get: modifyGet('error', action.error)
        },
      };
    case chatBot.GET_NESTED_BUTTON_RESPONSE.REQUEST:
      return {
        ...state,
        messages: {
          data: [...state.messages.data, action.message],
          get: modifyGet('request')
        },
      };
    case chatBot.GET_NESTED_BUTTON_RESPONSE.SUCCESS:
      return {
        ...state,
        messages: {
          data: [...state.messages.data, ...action.messages],
          get: modifyGet('success')
        },
      };
    case chatBot.SET_MESSAGE_FROM_SIDEBAR.REQUEST:
      return {
        ...state,
        messageFromSideBar: {
          data: null,
          get: modifyGet('request')
        },
      };
    case chatBot.SET_MESSAGE_FROM_SIDEBAR.SUCCESS:
      return {
        ...state,
        messageFromSideBar: {
          data: action.message,
          get: modifyGet('success')
        },
      };
    case chatBot.CLEAN_CHATBOT_MESSAGE.REQUEST:
      return {
        ...state,
        messages: {
          data: [],
          get: {
            loading: false,
            success: false,
            error: null,
          }
        },
      };
    case chatBot.SAVE_FEEDBACK.REQUEST:
    case chatBot.SAVE_NEGATIVE_FEEDBACK.REQUEST:
      return {
        ...state,
        feedbackStatus: {
          data: null,
          get: modifyGet('request')
        },
      };
    case chatBot.SAVE_FEEDBACK.SUCCESS:
    case chatBot.SAVE_NEGATIVE_FEEDBACK.SUCCESS:
      return {
        ...state,
        feedbackStatus: {
          data: action.feedbackStatus,
          get: modifyGet('success')
        },
      };
    case chatBot.SAVE_FEEDBACK.FAILURE:
    case chatBot.SAVE_NEGATIVE_FEEDBACK.FAILURE:
      return {
        ...state,
        feedbackStatus: {
          data: null,
          get: modifyGet('error', action.error)
        },
      };
    default:
      return state;
  }
}

export default chatbot;
