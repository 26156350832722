import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import { COLORS } from '../../../constants/design';
import breakpoints from "../../../theme-style/breakpoints";

export const MainContainer = styled.div`
  /* border: 1px solid red; */
  height: 100%;
  width: 620px;
  padding: 0px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  box-sizing: content-box;
  
  .genericLoader {
    height: ${props => props.fullHeight ? 'calc(100% - 30px)' : 'calc(100% - 240px)'};
  }
`;

export const StyledMessageContainer = styled.div`
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow-y: auto;
  scrollbar-width: none;
  flex-flow: column nowrap;
  height: 100%;
  padding: 20px 0 0 0;

  @media ${breakpoints.mobileXL} {
    height: ${props => props.fullHeight ? 'calc(100% - 30px)' : 'calc(100% - 240px)'};
  }

  >div:not(:last-child) {
    margin-bottom: 25px;
  };
  > :first-child {
    margin-top: auto !important;
  }
  scrollbar-width: none;
  -ms-overflow-style: none; /* IE and Edge */
  ::-webkit-scrollbar { // for Safari
    -webkit-appearance: none;
    width: 0;
    height: 0;
  }
`;

export const StyledTicketInputPlaceholder = styled.div`
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  color: ${COLORS.LIGHT_GREY_3};
  border: 1px solid ${COLORS.LIGHT_GREY_2};
  height: 100px;

  @media ${breakpoints.mobileXL} {
    height: 200px;
  }

  .ql-editor {
    padding: 10px;
    @media ${breakpoints.mobileXL} {
      padding: 12px 15px;
    }
  }
`;

export const StyledDialog = styled(Dialog)`
  && {
    .MuiDialog-container {
      .MuiDialog-paper {
        color: #212121;
        background-color: #FFFFFF;
        border-radius: 10px;
        &.MuiPaper-rounded {
          border-radius: 10px;
          width: 100%;
          max-width: 750px;
          height: 730px;
        }
        .MuiDialogTitle-root {
          text-align: center;
          font-weight: 600;
          padding: 20px 50px 0px 50px;
        }
        .MuiDialogContent-root {
          padding: 40px 50px;
          .MuiTypography-colorTextSecondary {
            text-align: center;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.8);
          }
        }
        .MuiDialogActions-root {
          justify-content: space-between;
          padding: 0 50px 40px 50px;
          .MuiButtonBase-root {
            height: 52px;
            margin: 0 10px;
            .MuiButton-label {
              font-size: 12px;
            }
            &:hover {
              .MuiButton-label {
              }
            }
          }
        }
      }
    }
  }
`;

export const StyledImageDialog = styled(Dialog)`
  && {
    .MuiDialog-container {
      .MuiDialog-paper {
        color: #212121;
        background-color: #FFFFFF;
        border-radius: 10px;
        &.MuiPaper-rounded {
          border-radius: 10px;
          /* max-width: 1000px; */
        }
      }
    }
  }
`;

export const StyledMessageImageModal = styled.img`
  border-radius: 6px;
  /* min-width: 600px;
  max-width: 900px; */
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
`;

