import React from "react";
import styled, { css } from "styled-components";
import Button from '@material-ui/core/Button';

const commonCSSStyle = css`
  min-width: auto;
  width: 100%;
  max-width: 100%;
  height: 38px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 600;
  box-shadow: none;
  padding: 12px;
  background-image: ${props => props.theme.components
    ? props.theme.components.buttons[props.appearence].backgroundImage : "none" };
  background-color: ${props => props.theme.components
    ? props.theme.components.buttons[props.appearence].backgroundColor : ""};
  border: ${props => props.theme.components ?
    props.theme.components.buttons[props.appearence].border: "" || 0};
  transition: all 0.2s ease-out;
  &:hover {
    box-shadow: none;
    background-color: ${props => props.theme.components ?
      props.theme.components.buttons[props.appearence].hover.backgroundColor : ""};
    border: ${props => props.theme.components ?
      props.theme.components.buttons[props.appearence].border: "" || 0};
  }
  &:disabled {
    opacity: 0.3;
  }
`;

const textLabelStyle = css`
  font-size: 12px;
  line-height: 10px;
  font-weight: 500;
  text-transform: initial;
  text-align: center;
  transition: all 0.2s ease-out;
`;

const commonLabelStyle = css`
  ${textLabelStyle};
  color: ${p => p.theme.components ?
    p.theme.components.buttons[p.appearence].textColor : ""};
  transition: all 0.2s ease-out;
`;

const commonStartIconStyle = css`
  width: auto;
  max-width: 14px;
  height: auto;
  max-height: 14px;
`;

const commonEndIconStyle = css`
  width: auto;
  max-width: 14px;
  height: auto;
  max-height: 14px;
`;

export const LabelContainer = styled.span`
  align-items: center;
  display: flex;
  ${p => {
    if (p.appearence) {
      return commonLabelStyle;
    } else {
      return css`
        ${textLabelStyle};
      `;
    }
  }};
`;

export const LabelText = styled.span`
  font-size: 12px;
  line-height: 10px;
  font-weight: 500;
  text-transform: initial;
  text-align: center;
`;

export const StartIconContainer = styled.span`
  width: auto;
  max-width: 14px;
  height: auto;
  max-height: 100%;
  display: inherit;
  margin-left: 0;
  margin-right: ${props => !props.noMargin ? '8px' : '0px'};
  margin-top: 0;
`;

export const EndIconContainer = styled.span`
  width: auto;
  max-width: 14px;
  height: auto;
  max-height: 100%;
  display: inherit;
  margin-left: ${props => !props.noMargin ? '8px' : '0px'};
  margin-right: 0;
  margin-top: 0;
`;

export const ButtonCustom = styled(({ appearence, ...rest }) => (
  <Button {...rest} />
))`
  &.MuiButton-root {
    ${commonCSSStyle};
    &:hover{
      ${LabelText}{
        color: ${props => props.theme.components ?
          props.theme.components.buttons[props.appearence].hover.textColor: ""};
      }
    .MuiButton-label {
      ${commonLabelStyle};
      .MuiButton-startIcon {
        ${commonStartIconStyle};
      }
      .MuiButton-endIcon {
        ${commonEndIconStyle};
      }
    }
  }
`;
