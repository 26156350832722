import styled from "styled-components";
import { COLORS } from '../../../../../../constants/design';

import GenericAccordion from "../../../../../../components/molecules/GenericAccordion";
import GenericFormikForm from "../../../../../../components/molecules/GenericFormikForm";

export const StyledAccordion = styled(GenericAccordion)`
  width: 100%;
  padding: 20px;
  && {
    .MuiAccordion-root {
      /* font-size: 0.9rem; */
    }
    .MuiAccordionSummary-content {
      font-size: 0.9rem;
      font-weight: 600;
    }
  }
`;

export const StyledForm = styled(GenericFormikForm)`
  padding: 0px;
  .genericValidationButtons {
    button {
      width: 24%;
    };
  }
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  >:not(:last-child) {
    margin-right: 20px;
  };
`;