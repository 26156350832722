import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import { ThemeProvider } from "styled-components";
import { useDispatch } from 'react-redux';

// Import main style
import theme from "./theme-style";
import MainDefaultStyle from "./theme-style/main-default-style";

// Import main Routes
import RoutesApp from './routes';

import {
  getSkillsDomainsList,
  getElementInformationByCompany,
} from '../../store/adminFront/actions';

import {
  getSites,
} from '../../store/concierge/actions';

import {
  getContexts,
} from '../../store/contextSetting/actions';

// Import context
import { Provider as EditorProvider } from "./context/editorState";

const RootAdminFront = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSkillsDomainsList());
    dispatch(getElementInformationByCompany());
    
    dispatch(getSites());
    dispatch(getContexts());
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <EditorProvider>
        <div className="app">
          <MainDefaultStyle />
          <main id="content" className="appContent">
            {/* <Router> */}
              <Switch>
                <Route path="/admin" component={RoutesApp} />
                <Redirect to="/admin" />
              </Switch>
            {/* </Router> */}
            {/*<RoutesApp />*/}
          </main>
          <NotificationContainer />
        </div>
      </EditorProvider>
    </ThemeProvider>
  );
};

export default RootAdminFront;
