import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import DialogContent from "@material-ui/core/DialogContent";

import GenericLoader from '../../molecules/GenericLoader';

import TicketMessage from './components/TicketMessage';
import TicketInput from './components/TicketInput';

import { jsonSafeParse } from '../../../helpers/jsonSafeParse';

import {
  deleteTicketMessage
} from '../../../store/concierge/actions';

import {
  getServiceMessages
} from '../../../store/sidebar/actions';

import {
  getServiceMessagesData,
  getServiceMessagesIsLoading
} from '../../../store/sidebar/selectors';

import { saveServiceMessage } from '../../../store/sidebar/actions';

import {
  MESSENGER_TYPE,
  MESSAGE_TYPE,
} from './constants';

import {
  MainContainer,
  StyledMessageContainer,
  StyledTicketInputPlaceholder,
  StyledDialog,
  StyledImageDialog,
  StyledMessageImageModal
} from './style';

const TicketRequestMessage = ({
  currentUser,
  ticketRequest,
  messengertype,
  setIsDirty,
  isPreview
}) => {
  const dispatch = useDispatch();
  const [messageList, setMessageList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [messageInput, setMessageInput] = useState("<p><br></p>");
  const [category, setCategory] = useState(0);
  const [openMessageInputModal, setOpenMessageInputModal] = useState(false);
  const [messageImageModal, setMessageImageModal] = useState(null);
  const messagesEndRef = useRef();

  const {
    serviceMessagesData,
    serviceMessagesIsLoading,
  } = useSelector(
    state => ({
      serviceMessagesData: getServiceMessagesData(state),
      serviceMessagesIsLoading: getServiceMessagesIsLoading(state),
    }),
    shallowEqual
  );

  useEffect(() => {
    if (ticketRequest) {
      dispatch(getServiceMessages(ticketRequest.id));
    }
  }, [ticketRequest]);

  useEffect(() => { // For auto scroll to bottom
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'auto' });
    }
  }, [messageList, fileList]);

  useEffect(() => {
    if (setIsDirty) {
      if (messageInput !== "<p><br></p>") setIsDirty(true);
      else setIsDirty(false);
    }
  }, [messageInput]);

  useEffect(() => {
    let fullSummaryMessage = null;
    let firstStatusMessage = null;
    let firstWelcomeMessage = null;
    if (ticketRequest) {
      const humanReadableSubmission = jsonSafeParse(ticketRequest.human_readable_submission);
      let fullSummaryText = messengertype === MESSENGER_TYPE.CONCIERGE ? `<p><strong>Nouvelle demande</strong></p><br>` : '';
      _.map(humanReadableSubmission, (data) => {
        fullSummaryText = `${fullSummaryText} <p>- <strong>${data.label}:</strong> ${data.value ? data.value : '-'}</p><br>`;
      });
      fullSummaryMessage = {
        type: MESSAGE_TYPE.CLIENT,
        content: fullSummaryText,
      }
      firstStatusMessage = {
        content: `Votre demande est en attente de prise en charge`,
        type: MESSAGE_TYPE.SYSTEM_CLIENT,
        created_at: ticketRequest.created_at
      };
      firstWelcomeMessage = {
        content: `Bonjour ${ticketRequest.collaborator.firstname},<p><br></p>
        Merci pour votre demande. Nous faisons au mieux pour y répondre au plus vite.<p><br></p>
        Merci de votre confiance et à bientôt.`,
        type: MESSAGE_TYPE.CONCIERGE,
        created_at: ticketRequest.created_at,
        author: { firstname: 'Votre Concierge' },
      }
    }
    if (fullSummaryMessage) {
      setMessageList([
        fullSummaryMessage,
        firstStatusMessage,
        firstWelcomeMessage,
        ...serviceMessagesData ? serviceMessagesData : []]);
    }
  }, [ticketRequest, serviceMessagesData]);

  const handleSubmit = () => {
    if (messageInput !== "<p><br></p>") {
      const newTicketMessage = {
        content: messageInput,
        type: category === 0 ? messengertype : MESSAGE_TYPE.NOTE,
        ticket: ticketRequest.id,
        attachment: fileList[0] || null,
        attachment2: fileList[1] || null,
        attachment3: fileList[2] || null,
        attachment4: fileList[3] || null,
        attachment5: fileList[4] || null,
      }
      // console.log('newTicketMessage', newTicketMessage);
      // if (messengertype === MESSENGER_TYPE.CLIENT)
      dispatch(saveServiceMessage({ formData: newTicketMessage }));
      // else
      //   dispatch(saveTicketMessage({ formData: newTicketMessage }));
      setMessageInput("<p><br></p>");
      setFileList([]);
      setOpenMessageInputModal(false);
    }
  }

  const handleMessageDelete = (selectedMessage) => {
    const formData = {
      ticketId: ticketRequest.id,
      messageId: selectedMessage.id
    }
    dispatch(deleteTicketMessage({ formData }));
  }

  return (
    <MainContainer fullHeight={ticketRequest?.status === 'archive'}>
      {(serviceMessagesIsLoading) &&
        <GenericLoader />
      }
      {(!serviceMessagesIsLoading) &&
        <StyledMessageContainer fullHeight={ticketRequest?.status === 'archive'}>
          {_.compact(_.map(messageList, (message, index) => {
            if ((messengertype === MESSENGER_TYPE.CLIENT && (message.type === MESSAGE_TYPE.SYSTEM || message.type === MESSAGE_TYPE.NOTE)) ||
              (messengertype === MESSENGER_TYPE.CONCIERGE && message.type === MESSAGE_TYPE.SYSTEM_CLIENT))
              return null
            else
              return (
                <TicketMessage
                  currentUser={currentUser}
                  key={index}
                  message={message}
                  messengertype={messengertype}
                  onMessageDelete={handleMessageDelete}
                  setMessageImageModal={setMessageImageModal}
                />
              )
          }))}
          <div ref={messagesEndRef} />
        </StyledMessageContainer>
      }
      {(ticketRequest?.status !== 'archive' && !openMessageInputModal) &&
        <>
          <TicketInput
            ticketRequest={ticketRequest}
            messengertype={messengertype}
            isPreview={isPreview}
            value={messageInput}
            onSubmit={handleSubmit}
            onChange={setMessageInput}
            onOpenModal={setOpenMessageInputModal}
            fileList={fileList}
            setFileList={setFileList}
            category={category}
            setCategory={setCategory}
            setMessageImageModal={setMessageImageModal}
            // isLoading={ticketRequestIsLoading}
          />
        </>
      }
      {openMessageInputModal &&
        <StyledTicketInputPlaceholder>
          {`En mode modal`}
        </StyledTicketInputPlaceholder>
      }

      {openMessageInputModal &&
        <StyledDialog open={true} onClose={() => setOpenMessageInputModal(false)}>
          <DialogContent>
            <TicketInput
              ticketRequest={ticketRequest}
              messengertype={messengertype}
              isPreview={isPreview}
              value={messageInput}
              onSubmit={handleSubmit}
              onChange={setMessageInput}
              onOpenModal={setOpenMessageInputModal}
              fileList={fileList}
              setFileList={setFileList}
              category={category}
              setCategory={setCategory}
              setMessageImageModal={setMessageImageModal}
              // isLoading={ticketRequestIsLoading}
              isModalMode={openMessageInputModal}
            />
          </DialogContent>
        </StyledDialog>
      }
      {messageImageModal &&
        <StyledImageDialog open={true} onClose={() => setMessageImageModal(null)}>
          <StyledMessageImageModal
            alt={"img"}
            src={messageImageModal}
          />
        </StyledImageDialog>
      }
    </MainContainer>
  )
};

export default TicketRequestMessage;