import React from 'react'
import {
  Container,
  Content,
  Title,
  TicketNumber
} from './style'

const TableRequestHeader = ({ title, ticketNumber }) => {
  return (
    <Container>
      <Content>
        <Title>{title}</Title>
        <TicketNumber>{ticketNumber} tickets</TicketNumber>
      </Content>
    </Container>
  )
}

export default TableRequestHeader

