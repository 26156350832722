import styled from "styled-components";
import { Button } from '@material-ui/core/'
import ClearIcon from '@material-ui/icons/Clear';

import { COLORS } from '../../../constants/design';
import noPreview from '../../../assets/images/noPreviewAvailable.png';

export const ChooseFileContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  .fileChooserButtonTooltip {
    font-size: 1rem;
  }
`;

export const ChooseFileDiv = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CustomButon = styled(Button)`
  height: 47px;
  width: 180px;
  font-size: 40px;
  color: ${COLORS.DARK_GREY};
`;
export const ChooseFileButtonSpan = styled.span`
  font-size: 10px;
  font-weight: 700;
  color: ${COLORS.DARK_GREY};
`;

export const ChoosedFileContainer = styled.div`
  display: flex;
`;

export const ChoosedFileSpan = styled.span`
  font-size: 11px;
  font-weight: 500;
  margin-left: 10px;
  margin-right: 5px;
  color: ${props => props.primary ? "#327FD4" : ""};
`;

export const DeleteIcon = styled(ClearIcon)`
&&{
  cursor: pointer;
  height: 15px;
  .MuiSvgIcon-root {
    font-size: 1.2rem;
  }
}
`;

export const ChooseFileInput = styled.input`
  display: none;
`;

export const PreviewContainer = styled.div`
  margin-left: auto;
  cursor: pointer;
  .fileChooserPreviewTooltip {
    color: ${COLORS.GREY_1};
  }
`;

export const ImagePreviewContainer = styled.div`
  width: 300px;
  height: 140px;
  background-image: url(${props => props.image}), url(${noPreview});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
`;
