import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useHistory } from "react-router";
import { useRouteMatch } from "react-router-dom";
import fr from 'date-fns/locale/fr';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import {
  shallowEqual,
  useDispatch,
  useSelector,
} from "react-redux";
import _ from 'lodash';

import Routes from './routes';
import GenericLayout from '../../layouts/genericLayout';
import GenericAdminFrontHeader from '../../../../components/molecules/GenericAdminFrontHeader';
import GenericWarningModal from '../../../../components/molecules/GenericWarningModal';
import GenericTable from '../../../../components/molecules/GenericTable';
import GenericLoader from '../../../../components/molecules/GenericLoader';

import SpecificCaseFilter from './components/SpecificCaseFilter';

import {
  getSpecificCase,
  deleteSpecificCase
} from '../../../../store/adminFront/actions';

import {
  getSpecificCaseData,
  getSpecificCaseIsLoading,
  getElementInformationByCompanyData
} from '../../../../store/adminFront/selectors';

import {
  Container,
  StyledButton,
  TableButtonDiv,
  TableIcon,
  FilterContainer,
  SitesListContainer,
  EmptySitesList,
  SettingContainer,
  StyledPaginationArrow
} from "./style";

const headerList = [
  { id: "id", label: "Id" },
  { id: "action", label: "Action DialogFlow" },
  { id: "sites", label: "Sites", disableSort: true },
  { id: "created_date", label: "Date de création" },
  { id: "edited_date", label: "Date d'édition" },
  { id: "rowAction", label: "Action", disableSort: true },
];

const TableAction = ({ openEditDrawer, openWarning }) => {
  return (
    <TableButtonDiv >
      <TableIcon onClick={openEditDrawer}>
        <EditIcon />
      </TableIcon>
      <TableIcon onClick={openWarning} primary="true">
        <DeleteIcon />
      </TableIcon>
    </TableButtonDiv>
  )
}

const SmallTalk = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const [itemList, setItemList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [maxPageNumber, setMaxPageNumber] = useState(1);
  const [openDelete, setOpenDelete] = useState(false);
  const [specificCaseId, setSpecificCaseId] = useState(null);
  const [updateSitesData, setUpdateSitesData] = useState([])
  const [filterConditions, setFilterConditions] = useState({
    sites: [],
  });
  const {
    specificCaseData,
    specificCaseIsLoading,
    sitesData
  } = useSelector(
    state => ({
      specificCaseData: getSpecificCaseData(state),
      specificCaseIsLoading: getSpecificCaseIsLoading(state),
      sitesData: getElementInformationByCompanyData(state)
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getSpecificCase({ limit: 10, pageNumber: pageNumber }));
  }, [pageNumber]);

  useEffect(() => {
    if (specificCaseData) {
      const newList = _.compact(_.map(specificCaseData?.items, (data) => {
        if (filterCondition(filterConditions, data) === false) return;
        return ({
          id: data.id,
          action: data.df_action,
          sites: <SiteList siteList={data?.company_sites} />,
          created_date: data.created_at ? format(new Date(data.created_at), "dd/MM/yyyy", { locale: fr }) : '-',
          edited_date: data.edited_date ? format(new Date(data.edited_date), "dd/MM/yyyy", { locale: fr }) : '-',
          rowAction: <TableAction openEditDrawer={() => openEditDrawer(data.id)} openWarning={() => prepareDelete(data.id)} />
        })
      }))
      const total = Math.ceil(specificCaseData.total_count / (specificCaseData.num_items_per_page));

      setMaxPageNumber(total);

      setItemList(newList);
    }
  }, [specificCaseData, filterConditions]);

  useEffect(() => {
    if (sitesData) {
      const newChoices = _.compact(_.map(sitesData, (site) => {
        if (site.id) {
          return site
        }
      }))
      setUpdateSitesData(newChoices);
    }
  }, [sitesData])

  const SiteList = ({ siteList }) => {
    if (siteList != null && Object.keys(siteList).length !== 0) {
      const list = _.map((siteList), (item) => {
        return item.name
      }).join(', ')

      return (
        <Tooltip title={list} >
          <SitesListContainer>
            {list}
          </SitesListContainer>
        </Tooltip>
      )
    } else {
      return (
        <EmptySitesList>-</EmptySitesList>
      )
    }
  }


  const checkSiteFilter = (form, item) => {
    let stat = false;
    _.map(item.company_sites, (site) => {
      if (form?.sites.find(e => String(e.value) === String(site.id))) {
        stat = true
      }
    })
    return stat;
  }

  const filterCondition = (form, item) => {
    const conditionCheckArray = [];
    if (form.sites.length !== 0) conditionCheckArray.push(checkSiteFilter(form, item))
    if (conditionCheckArray.length > 0) return !conditionCheckArray.includes(false);
    return true;
  }

  const prepareDelete = (id) => {
    setSpecificCaseId(id);
    setOpenDelete(!openDelete);
  }

  const handleDelete = () => {
    if (specificCaseId != null) dispatch(deleteSpecificCase(specificCaseId));
    setOpenDelete(false);
  }

  const openNewDrawer = () => {
    history.push(`${path}/new`);
  }

  const openEditDrawer = (id) => {
    history.push(`${path}/edit/${id}`);
  }

  return (
    <GenericLayout
      id="smallTalkLayout"
      className="smallTalkLayout"
    >
      <Container>
        <GenericAdminFrontHeader
          headerTitle={'Gestion des cas spécifiques'}
          headerDesc={`Le cas spécifique vous permet de générer, pour un code d'action, une exception de réponse qui sera propre à l'environnement ou au site sélectionné`}
          RightSideComponent={
            <StyledButton onClick={() => openNewDrawer()}  >
              + Créer une nouvelle réponse spécifique
            </StyledButton>
          }
        />
        <SpecificCaseFilter sitesData={updateSitesData} setFilterConditions={setFilterConditions} />
        <SettingContainer>
          <StyledPaginationArrow
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            maxPageNumber={maxPageNumber}
          />
        </SettingContainer>
        {specificCaseIsLoading &&
          <GenericLoader />
        }
        {!specificCaseIsLoading &&
          <GenericTable
            headerList={headerList}
            itemList={itemList}
          />
        }
      </Container>
      <GenericWarningModal
        open={openDelete}
        setOpenDialog={() => setOpenDelete(!openDelete)}
        title={"Supprimer un cas spécifique"}
        text={"Êtes-vous certain de vouloir supprimer ce cas spécifique ?"}
        leftButtonText={"Annuler"}
        rightButtonText={"Supprimer"}
        leftButtonFunction={() => setOpenDelete(!openDelete)}
        rightButtonFunction={() => handleDelete()}
      />
      <Routes />
    </GenericLayout>
  )
}

export default SmallTalk;