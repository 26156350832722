import React from "react";

const NotSeeIcon = ({
  color = "#2B2B2B",
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon notSeeIcon"
      width={width}
      height={height}
      viewBox="0 0 15 9"
      fill="none"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M12.1464886,0.146312759 C12.341738,-0.0487709195 12.6582374,-0.0487709195 12.8534868,0.146312759 C13.0486773,0.341455361 13.0486773,0.657781538 12.8534868,0.85292414 L12.8534868,0.85292414 L2.85351141,10.8474274 C2.72797449,10.9773343 2.54207323,11.0294336 2.36725895,10.983701 C2.19244466,10.9379685 2.05592252,10.801521 2.01016491,10.6268024 C1.9644073,10.4520838 2.01653518,10.2662843 2.14651315,10.140816 L2.14651315,10.140816 L3.51800978,8.77006991 C2.22617485,7.97502089 1.07368734,6.97341357 0.106518163,5.80520051 C-0.0355060544,5.62415243 -0.0355060544,5.36958774 0.106518163,5.18853966 C0.240517834,5.01763365 3.43700998,0.999343613 7.5,0.999343613 C8.5612868,1.01646446 9.60580782,1.26674618 10.5594925,1.73244043 L10.5594925,1.73244043 Z M12.3354881,2.78386217 C13.2859714,3.47468063 14.1454261,4.28262299 14.8934818,5.18853966 C15.0355061,5.36958774 15.0355061,5.62415243 14.8934818,5.80520051 C14.7594822,5.97610652 11.56299,9.99439656 7.5,9.99439656 C6.80551096,9.98832502 6.1160994,9.87539621 5.45600502,9.6595807 L5.45600502,9.6595807 L7.15600085,7.96051514 C7.26969388,7.98030126 7.38464955,7.99199094 7.5,7.99549591 C8.88002319,7.99384376 9.9983408,6.8761381 9.99999386,5.49687009 C9.99648697,5.38158275 9.9847909,5.26668999 9.96499394,5.15305917 L9.96499394,5.15305917 Z M7.50000614,2.99824427 C6.11997681,2.99989642 5.0016592,4.11760207 5.00000614,5.49687009 C5.00346032,5.98408678 5.152502,6.45915819 5.42800509,6.86111978 L5.42800509,6.86111978 L8.86499665,3.42600901 C8.46281497,3.15065668 7.98748346,3.00169656 7.50000614,2.99824427 Z"
          id="notSeeIcon"
          fill={color}
          fillRule="nonzero"
        >
        </path>
      </g>
    </svg>
  );
};

export default NotSeeIcon;
