import styled from "styled-components";
import IconButton from '@material-ui/core/IconButton';

import { COLORS } from "../../../constants/design";

export const MainContainer = styled.div`
  font-size: 0.8rem;
  color: ${COLORS.DARK_GREY};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledIconButton = styled(IconButton)`
  &&{
    height: fit-content;
    .MuiSvgIcon-root {
      color: ${COLORS.DARK_GREY};
      font-size: 1.2rem;
    }
    :hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
`;