import React, { useState, useEffect } from "react";
import {
  Redirect,
  useHistory,
} from "react-router-dom";
import {
  shallowEqual,
  useDispatch,
  useSelector
} from "react-redux";

import {
  isGetUserLoading
} from '../../../../../../store/collaborator/selectors';

import {
  isUserTokenValid
} from '../../../../../../store/authentication/selectors';

import {
  getAzureadIsLoading,
  getAzureadSuccess,
  getAzureadIsError
} from '../../../../../../store/sso/selectors';

import {
  getCompanyData
} from '../../../../../../store/company/selectors';

import {
  azureadRegisteration
} from '../../../../../../store/sso/actions';

import { logout } from '../../../../../../store/authentication/actions';

import GenericLoader from '../../../../../../components/molecules/GenericLoader';
import GenericCheckbox from '../../../../../../components/molecules/GenericCheckbox';
import CGUModal from "../../../../../../components/organisms/CGUModal";
import LogoWithCircle from "../../../../../../components/atoms/icons/logoWithCircle";

import { toaster } from '../../../../../../helpers/toaster';

import {
  MainContainer,
  Header,
  HeaderTitle,
  HeaderSubtitle,
  LogoWithCircleWrapper,
  StyledConfirmationContainer,
  StyledTitle,
  StyledInfoContainer,
  StyledMessage,
  StyledInfo,
  StyledInfoTitle,
  StyledInfoText,
  StyledLink,
  StyledButton,
  StyledErrorContainer,
  StyledErrorText
} from "./style";

const QUERY_ARRAY = [
  'email',
  'firstname',
  'lastname',
  'Redirect',
  'companySite'
];

const ERROR_TEXT = {
  'Collaborator already exists': `L'utilisateur existe déjà`,
};

const AzureadRegister = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [openGCU, setOpenGCU] = useState(false);
  const [acceptAgreement, setAcceptAgreement] = useState(false);

  const query = new URLSearchParams(window.location.search);

  const queryObject = QUERY_ARRAY.reduce((acc, value) => {
    acc[value] = query.get(value);
    return (acc);
  }, {});

  const {
    isUserLoading,
    isTokenValid,
    azureAdIsLoading,
    azureAdSuccess,
    azureError,
    companyData
  } = useSelector(
    state => ({
      isUserLoading: isGetUserLoading(state),
      isTokenValid: isUserTokenValid(state),
      azureAdIsLoading: getAzureadIsLoading(state),
      azureAdSuccess: getAzureadSuccess(state),
      azureError: getAzureadIsError(state),
      companyData: getCompanyData(state)
    }),
    shallowEqual
  );

  // console.log('queryObject', queryObject);

  useEffect(() => {
    dispatch(logout());
  }, []);

  const handleCheckBox = (event) => {
    setAcceptAgreement(event.target.checked);
  }

  const handleConfirm = () => {
    if (!acceptAgreement) {
      toaster(`Veuillez accepter les conditions générales d'utilisation`, 'error');
      return;
    }
    dispatch(azureadRegisteration(queryObject));
  };

  const generateErrorText = () => {
    return (ERROR_TEXT[azureError.message] || ERROR_TEXT[azureError] || azureError.message);
  }

  const sendEmail = () => {
    const subject = encodeURIComponent(`${queryObject.firstname} ${queryObject.lastname} rencontre un problème d’inscription`);
    const targetEmail = `onvousaimeonvousaide@cyconia.io`;

    window.open(`mailto:${targetEmail}?subject=${subject}`, '_blank');
  }

  if (!companyData.azureSso) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: history.location }
        }}
      // to={{
      //   pathname: "/sso/hqo-gecina/restricted",
      //   state: {
      //     from: history.location,
      //     error: {
      //       message: 'Pas activé'
      //     }
      //   }
      // }}
      />
    )
  }
  if (isTokenValid && azureAdSuccess) {
    return (
      <Redirect
        to={{
          pathname: queryObject.redirect || '/landing',
          state: { from: history.location }
        }}
      />
    )
  }
  else {
    return (
      <MainContainer>
        <Header>
          <LogoWithCircleWrapper>
            <LogoWithCircle />
          </LogoWithCircleWrapper>
          <HeaderTitle>{'cyconia'}</HeaderTitle>
          <HeaderSubtitle>{'Votre assistant intelligent au bureau'}</HeaderSubtitle>
        </Header>
        <StyledConfirmationContainer>
          {azureAdIsLoading || isUserLoading ?
            <GenericLoader />
            :
            azureError ?
              <StyledErrorContainer>
                <StyledErrorText>
                  {generateErrorText()}
                </StyledErrorText>
                <StyledLink onClick={sendEmail}>
                  {`Je rencontre un problème`}
                </StyledLink>
              </StyledErrorContainer>
              :
              <>
                <StyledTitle>{'Créer votre compte'}</StyledTitle>
                <StyledMessage>
                  {`Pour bénéficier des services de Cyconia et permettre la prise en charge de vos demandes, un compte vous sera créé sur la base des informations :`}
                </StyledMessage>
                <StyledInfoContainer>
                  <StyledInfo>
                    <StyledInfoTitle>{'Votre email'}</StyledInfoTitle>
                    <StyledInfoText>{queryObject.email}</StyledInfoText>
                  </StyledInfo>
                  <StyledInfo>
                    <StyledInfoTitle>{'Votre nom'}</StyledInfoTitle>
                    <StyledInfoText>{queryObject.firstname}</StyledInfoText>
                  </StyledInfo>
                  <StyledInfo>
                    <StyledInfoTitle>{'Votre prénom'}</StyledInfoTitle>
                    <StyledInfoText>{queryObject.lastname}</StyledInfoText>
                  </StyledInfo>
                  {queryObject.companySite &&
                    <StyledInfo>
                      <StyledInfoTitle>{'Votre site d’entreprise'}</StyledInfoTitle>
                      <StyledInfoText>{queryObject.companySite}</StyledInfoText>
                    </StyledInfo>
                  }
                </StyledInfoContainer>

                <StyledLink onClick={() => setOpenGCU(true)}>
                  {`Lire les conditions générales d'utilisation`}
                </StyledLink>

                <GenericCheckbox
                  title={`J'ai lu et j'accepte les conditions générales d'utilisation`}
                  onChange={(event) => handleCheckBox(event)}
                  checked={acceptAgreement}
                />

                {openGCU &&
                  <CGUModal
                    onClose={() => setOpenGCU(false)}
                  />
                }

                <StyledButton
                  title={`Je confirme mon inscription`}
                  onClick={handleConfirm}
                />
              </>
          }
        </StyledConfirmationContainer>
      </MainContainer>
    );
  }
};

export default AzureadRegister;