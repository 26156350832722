export const actionGenerator = (value) => ({
  REQUEST: `ADMINFRONT/${value}/REQUEST`,
  SUCCESS: `ADMINFRONT/${value}/SUCCESS`,
  FAILURE: `ADMINFRONT/${value}/FAILURE`,
})

export const GET_LAST_UNTREATED_FB = {
  REQUEST: 'ADMINFRONT/GET_LAST_UNTREATED_FB/REQUEST',
  SUCCESS: 'ADMINFRONT/GET_LAST_UNTREATED_FB/SUCCESS',
  FAILURE: 'ADMINFRONT/GET_LAST_UNTREATED_FB/FAILURE',
};

export const GET_UNTREATED_FB_STATS = {
  REQUEST: 'ADMINFRONT/GET_UNTREATED_FB_STATS/REQUEST',
  SUCCESS: 'ADMINFRONT/GET_UNTREATED_FB_STATS/SUCCESS',
  FAILURE: 'ADMINFRONT/GET_UNTREATED_FB_STATS/FAILURE',
};

export const GET_ACTIVE_INFO_ELEMENT = {
  REQUEST: 'ADMINFRONT/GET_ACTIVE_INFO_ELEMENT/REQUEST',
  SUCCESS: 'ADMINFRONT/GET_ACTIVE_INFO_ELEMENT/SUCCESS',
  FAILURE: 'ADMINFRONT/GET_ACTIVE_INFO_ELEMENT/FAILURE',
};

export const GET_INACTIVE_INFO_ELEMENT = {
  REQUEST: 'ADMINFRONT/GET_INACTIVE_INFO_ELEMENT/REQUEST',
  SUCCESS: 'ADMINFRONT/GET_INACTIVE_INFO_ELEMENT/SUCCESS',
  FAILURE: 'ADMINFRONT/GET_INACTIVE_INFO_ELEMENT/FAILURE',
};

export const GET_COLLABORATORS_LIST = {
  REQUEST: 'ADMINFRONT/GET_COLLABORATORS_LIST/REQUEST',
  SUCCESS: 'ADMINFRONT/GET_COLLABORATORS_LIST/SUCCESS',
  FAILURE: 'ADMINFRONT/GET_COLLABORATORS_LIST/FAILURE',
  RESET: 'ADMINFRONT/GET_COLLABORATORS_LIST/RESET',
};

export const GET_COLLABORATORS_RULES = {
  REQUEST: 'ADMINFRONT/GET_COLLABORATORS_RULES/REQUEST',
  SUCCESS: 'ADMINFRONT/GET_COLLABORATORS_RULES/SUCCESS',
  FAILURE: 'ADMINFRONT/GET_COLLABORATORS_RULES/FAILURE',
};

export const GET_UNTREATED_FEEDBACK = {
  REQUEST: 'ADMINFRONT/GET_UNTREATED_FEEDBACK/REQUEST',
  SUCCESS: 'ADMINFRONT/GET_UNTREATED_FEEDBACK/SUCCESS',
  FAILURE: 'ADMINFRONT/GET_UNTREATED_FEEDBACK/FAILURE',
};

export const GET_UNTREATED_FEEDBACK_BY_SKILL = {
  REQUEST: 'ADMINFRONT/GET_UNTREATED_FEEDBACK_BY_SKILL/REQUEST',
  SUCCESS: 'ADMINFRONT/GET_UNTREATED_FEEDBACK_BY_SKILL/SUCCESS',
  FAILURE: 'ADMINFRONT/GET_UNTREATED_FEEDBACK_BY_SKILL/FAILURE',
};

export const GET_UNTREATED_FEEDBACK_BY_SKILL_STATS = {
  REQUEST: 'ADMINFRONT/GET_UNTREATED_FEEDBACK_BY_SKILL_STATS/REQUEST',
  SUCCESS: 'ADMINFRONT/GET_UNTREATED_FEEDBACK_BY_SKILL_STATS/SUCCESS',
  FAILURE: 'ADMINFRONT/GET_UNTREATED_FEEDBACK_BY_SKILL_STATS/FAILURE',
};

export const GET_SKILLS_DOMAINS_LIST = {
  REQUEST: 'ADMINFRONT/GET_SKILLS_DOMAINS_LIST/REQUEST',
  SUCCESS: 'ADMINFRONT/GET_SKILLS_DOMAINS_LIST/SUCCESS',
  FAILURE: 'ADMINFRONT/GET_SKILLS_DOMAINS_LIST/FAILURE',
};

export const GET_FEEDBACK_BY_INFO_ELEMENT = {
  REQUEST: 'ADMINFRONT/GET_FEEDBACK_BY_INFO_ELEMENT/REQUEST',
  SUCCESS: 'ADMINFRONT/GET_FEEDBACK_BY_INFO_ELEMENT/SUCCESS',
  FAILURE: 'ADMINFRONT/GET_FEEDBACK_BY_INFO_ELEMENT/FAILURE',
};

export const GET_TREATED_FEEDBACK = {
  REQUEST: 'ADMINFRONT/GET_TREATED_FEEDBACK/REQUEST',
  SUCCESS: 'ADMINFRONT/GET_TREATED_FEEDBACK/SUCCESS',
  FAILURE: 'ADMINFRONT/GET_TREATED_FEEDBACK/FAILURE',
};

export const GET_TREATED_FEEDBACK_STATS = {
  REQUEST: 'ADMINFRONT/GET_TREATED_FEEDBACK_STATS/REQUEST',
  SUCCESS: 'ADMINFRONT/GET_TREATED_FEEDBACK_STATS/SUCCESS',
  FAILURE: 'ADMINFRONT/GET_TREATED_FEEDBACK_STATS/FAILURE',
};

export const GET_TREATED_FEEDBACK_BY_SKILL_STATS = {
  REQUEST: 'ADMINFRONT/GET_TREATED_FEEDBACK_BY_SKILL_STATS/REQUEST',
  SUCCESS: 'ADMINFRONT/GET_TREATED_FEEDBACK_BY_SKILL_STATS/SUCCESS',
  FAILURE: 'ADMINFRONT/GET_TREATED_FEEDBACK_BY_SKILL_STATS/FAILURE',
};

export const GET_POSITIVE_BOT_FEEDBACK = {
  REQUEST: 'ADMINFRONT/GET_POSITIVE_BOT_FEEDBACK/REQUEST',
  SUCCESS: 'ADMINFRONT/GET_POSITIVE_BOT_FEEDBACK/SUCCESS',
  FAILURE: 'ADMINFRONT/GET_POSITIVE_BOT_FEEDBACK/FAILURE',
};

export const UPDATE_NEGATIVE_BOT_FEEDBACK = {
  REQUEST: 'ADMINFRONT/UPDATE_NEGATIVE_BOT_FEEDBACK/REQUEST',
  SUCCESS: 'ADMINFRONT/UPDATE_NEGATIVE_BOT_FEEDBACK/SUCCESS',
  FAILURE: 'ADMINFRONT/UPDATE_NEGATIVE_BOT_FEEDBACK/FAILURE',
  RESET: 'ADMINFRONT/UPDATE_NEGATIVE_BOT_FEEDBACK/RESET',
};

export const GET_INFORMATION_LIST = {
  REQUEST: 'ADMINFRONT/GET_INFORMATION_LIST/REQUEST',
  SUCCESS: 'ADMINFRONT/GET_INFORMATION_LIST/SUCCESS',
  FAILURE: 'ADMINFRONT/GET_INFORMATION_LIST/FAILURE',
};

export const GET_ELEMENT_INFORMATION_LIST = {
  REQUEST: 'ADMINFRONT/GET_ELEMENT_INFORMATION_LIST/REQUEST',
  SUCCESS: 'ADMINFRONT/GET_ELEMENT_INFORMATION_LIST/SUCCESS',
  FAILURE: 'ADMINFRONT/GET_ELEMENT_INFORMATION_LIST/FAILURE',
};

export const GET_FEEDS_STAT_INFO = {
  REQUEST: 'ADMINFRONT/GET_FEEDS_STAT_INFO/REQUEST',
  SUCCESS: 'ADMINFRONT/GET_FEEDS_STAT_INFO/SUCCESS',
  FAILURE: 'ADMINFRONT/GET_FEEDS_STAT_INFO/FAILURE',
};

export const GET_FEEDS_LIST_BY_SITE = {
  REQUEST: 'ADMINFRONT/GET_FEEDS_LIST_BY_SITE/REQUEST',
  SUCCESS: 'ADMINFRONT/GET_FEEDS_LIST_BY_SITE/SUCCESS',
  FAILURE: 'ADMINFRONT/GET_FEEDS_LIST_BY_SITE/FAILURE',
};

export const GET_ELEMENT_INFORMATION_RESPONSE_BY_ID = {
  REQUEST: 'ADMINFRONT/GET_ELEMENT_INFORMATION_RESPONSE_BY_ID/REQUEST',
  SUCCESS: 'ADMINFRONT/GET_ELEMENT_INFORMATION_RESPONSE_BY_ID/SUCCESS',
  FAILURE: 'ADMINFRONT/GET_ELEMENT_INFORMATION_RESPONSE_BY_ID/FAILURE',
};

export const GET_ELEMENT_INFORMATION_BY_COMPANY = {
  REQUEST: 'ADMINFRONT/GET_ELEMENT_INFORMATION_BY_COMPANY/REQUEST',
  SUCCESS: 'ADMINFRONT/GET_ELEMENT_INFORMATION_BY_COMPANY/SUCCESS',
  FAILURE: 'ADMINFRONT/GET_ELEMENT_INFORMATION_BY_COMPANY/FAILURE',
};

export const UPADATE_ELEMENT_INFORMATION_ACTIVATION = {
  REQUEST: 'ADMINFRONT/UPADATE_ELEMENT_INFORMATION_ACTIVATION/REQUEST',
  SUCCESS: 'ADMINFRONT/UPADATE_ELEMENT_INFORMATION_ACTIVATION/SUCCESS',
  FAILURE: 'ADMINFRONT/UPADATE_ELEMENT_INFORMATION_ACTIVATION/FAILURE',
};

export const UPADATE_ELEMENT_INFORMATION_RESPONSE = {
  REQUEST: 'ADMINFRONT/UPADATE_ELEMENT_INFORMATION_RESPONSE/REQUEST',
  SUCCESS: 'ADMINFRONT/UPADATE_ELEMENT_INFORMATION_RESPONSE/SUCCESS',
  FAILURE: 'ADMINFRONT/UPADATE_ELEMENT_INFORMATION_RESPONSE/FAILURE',
};

export const UPADATE_FEEDBACK_PROCESSED = {
  REQUEST: 'ADMINFRONT/UPADATE_FEEDBACK_PROCESSED/REQUEST',
  SUCCESS: 'ADMINFRONT/UPADATE_FEEDBACK_PROCESSED/SUCCESS',
  FAILURE: 'ADMINFRONT/UPADATE_FEEDBACK_PROCESSED/FAILURE',
};

export const UPDATE_ALL_ELEMENT_INFORMATION = {
  REQUEST: 'ADMINFRONT/UPDATE_ALL_ELEMENT_INFORMATION/REQUEST',
  SUCCESS: 'ADMINFRONT/UPDATE_ALL_ELEMENT_INFORMATION/SUCCESS',
  FAILURE: 'ADMINFRONT/UPDATE_ALL_ELEMENT_INFORMATION/FAILURE',
  RESET: 'ADMINFRONT/UPDATE_ALL_ELEMENT_INFORMATION/RESET',
};

export const GET_SPECIFIC_CASE = {
  REQUEST: 'ADMINFRONT/GET_SPECIFIC_CASE/REQUEST',
  SUCCESS: 'ADMINFRONT/GET_SPECIFIC_CASE/SUCCESS',
  FAILURE: 'ADMINFRONT/GET_SPECIFIC_CASE/FAILURE',
};

export const UPDATE_SPECIFIC_CASE = {
  REQUEST: 'ADMINFRONT/UPDATE_SPECIFIC_CASE/REQUEST',
  SUCCESS: 'ADMINFRONT/UPDATE_SPECIFIC_CASE/SUCCESS',
  FAILURE: 'ADMINFRONT/UPDATE_SPECIFIC_CASE/FAILURE',
};

export const DELETE_SPECIFIC_CASE = {
  REQUEST: 'ADMINFRONT/DELETE_SPECIFIC_CASE/REQUEST',
  SUCCESS: 'ADMINFRONT/DELETE_SPECIFIC_CASE/SUCCESS',
  FAILURE: 'ADMINFRONT/DELETE_SPECIFIC_CASE/FAILURE',
};

export const RESET_EDIT_TRAINING = "ADMINFRONT/EDIT_TRAINING/RESET";

export const SET_TITLE_INFORMATION_ELEMENT = "ADMINFRONT/SET_TITLE_INFORMATION_ELEMENT";

/**
 * LAST UNTREATED FEEDBACK
 */
export const getLastUntreatedFeedback = () => ({
  type: GET_LAST_UNTREATED_FB.REQUEST,
});

export const getLastUntreatedFeedbackSuccess = (lastUntreatedFeedback) => ({
  type: GET_LAST_UNTREATED_FB.SUCCESS,
  lastUntreatedFeedback,
});

export const getLastUntreatedFeedbackError = (error) => ({
  type: GET_LAST_UNTREATED_FB.FAILURE,
  error,
});

/**
 * UNTREATED FEEDBACK STATS
 */
export const getUntreatedFeedbackStats = () => ({
  type: GET_UNTREATED_FB_STATS.REQUEST,
});

export const getUntreatedFeedbackStatsSuccess = (untreatedFeedbackStats) => ({
  type: GET_UNTREATED_FB_STATS.SUCCESS,
  untreatedFeedbackStats,
});

export const getUntreatedFeedbackStatsError = (error) => ({
  type: GET_UNTREATED_FB_STATS.FAILURE,
  error,
});

/**
 * ACTIVE INFO ELEMENT
 */
export const getActiveInfoElement = () => ({
  type: GET_ACTIVE_INFO_ELEMENT.REQUEST,
});

export const getActiveInfoElementSuccess = (activeInfoElement) => ({
  type: GET_ACTIVE_INFO_ELEMENT.SUCCESS,
  activeInfoElement,
});

export const getActiveInfoElementError = (error) => ({
  type: GET_ACTIVE_INFO_ELEMENT.FAILURE,
  error,
});

/**
 * INACTIVE INFO ELEMENT
 */
export const getInactiveInfoElement = () => ({
  type: GET_INACTIVE_INFO_ELEMENT.REQUEST,
});

export const getInactiveInfoElementSuccess = (inactiveInfoElement) => ({
  type: GET_INACTIVE_INFO_ELEMENT.SUCCESS,
  inactiveInfoElement,
});

export const getInactiveInfoElementError = (error) => ({
  type: GET_INACTIVE_INFO_ELEMENT.FAILURE,
  error,
});

/**
 * COLLABORATOR LIST
 */
export const getCollaboratorsList = (data) => ({
  type: GET_COLLABORATORS_LIST.REQUEST,
  data
});

export const getCollaboratorsListSuccess = (collaboratorsList) => ({
  type: GET_COLLABORATORS_LIST.SUCCESS,
  collaboratorsList,
});

export const getCollaboratorsListError = (error) => ({
  type: GET_COLLABORATORS_LIST.FAILURE,
  error,
});

export const getCollaboratorsListReset = () => ({
  type: GET_COLLABORATORS_LIST.RESET,
});

/**
 * COLLABORATOR RULES
 */
export const getCollaboratorsRules = () => ({
  type: GET_COLLABORATORS_RULES.REQUEST,
});

export const getCollaboratorsRulesSuccess = (collaboratorsRules) => ({
  type: GET_COLLABORATORS_RULES.SUCCESS,
  collaboratorsRules,
});

export const getCollaboratorsRulesError = (error) => ({
  type: GET_COLLABORATORS_RULES.FAILURE,
  error,
});

/**
 * UNTREATED FEEDBACK
 */
export const getUntreatedFeedback = () => ({
  type: GET_UNTREATED_FEEDBACK.REQUEST,
});

export const getUntreatedFeedbackSuccess = (untreatedFeedback) => ({
  type: GET_UNTREATED_FEEDBACK.SUCCESS,
  untreatedFeedback,
});

export const getUntreatedFeedbackError = (error) => ({
  type: GET_UNTREATED_FEEDBACK.FAILURE,
  error,
});

/**
 * UNTREATED FEEDBACK BY SKILL
 */
export const getUntreatedFbBySkill = () => ({
  type: GET_UNTREATED_FEEDBACK_BY_SKILL.REQUEST,
});

export const getUntreatedFbBySkillSuccess = (untreatedFbBySkill) => ({
  type: GET_UNTREATED_FEEDBACK_BY_SKILL.SUCCESS,
  untreatedFbBySkill,
});

export const getUntreatedFbBySkillError = (error) => ({
  type: GET_UNTREATED_FEEDBACK_BY_SKILL.FAILURE,
  error,
});

/**
 * UNTREATED FEEDBACK BY SKILL STATS
 */
export const getUntreatedFbBySkillStats = () => ({
  type: GET_UNTREATED_FEEDBACK_BY_SKILL_STATS.REQUEST,
});

export const getUntreatedFbBySkillStatsSuccess = (untreatedFbBySkillStats) => ({
  type: GET_UNTREATED_FEEDBACK_BY_SKILL_STATS.SUCCESS,
  untreatedFbBySkillStats,
});

export const getUntreatedFbBySkillStatsError = (error) => ({
  type: GET_UNTREATED_FEEDBACK_BY_SKILL_STATS.FAILURE,
  error,
});

/**
 * SKILLS DOMAINS LIST
 */
export const getSkillsDomainsList = () => ({
  type: GET_SKILLS_DOMAINS_LIST.REQUEST,
});

export const getSkillsDomainsListSuccess = (skillsDomainsList) => ({
  type: GET_SKILLS_DOMAINS_LIST.SUCCESS,
  skillsDomainsList,
});

export const getSkillsDomainsListError = (error) => ({
  type: GET_SKILLS_DOMAINS_LIST.FAILURE,
  error,
});

/**
 * FEEDBACK BY INFO ELEMENT
 */
export const getFeebackByInfoEl = (eiId) => ({
  type: GET_FEEDBACK_BY_INFO_ELEMENT.REQUEST,
  eiId
});

export const getFeebackByInfoElSuccess = (feebackByInfoEl) => ({
  type: GET_FEEDBACK_BY_INFO_ELEMENT.SUCCESS,
  feebackByInfoEl,
});

export const getFeebackByInfoElError = (error) => ({
  type: GET_FEEDBACK_BY_INFO_ELEMENT.FAILURE,
  error,
});

/**
 * TREATED FEEDBACK
 */
export const getTreatedFeedback = () => ({
  type: GET_TREATED_FEEDBACK.REQUEST,
});

export const getTreatedFeedbackSuccess = (treatedFeedback) => ({
  type: GET_TREATED_FEEDBACK.SUCCESS,
  treatedFeedback,
});

export const getTreatedFeedbackError = (error) => ({
  type: GET_TREATED_FEEDBACK.FAILURE,
  error,
});

/**
 * TREATED FEEDBACK STATS
 */
export const getTreatedFeedbackStats = () => ({
  type: GET_TREATED_FEEDBACK_STATS.REQUEST,
});

export const getTreatedFeedbackStatsSuccess = (treatedFeedbackStats) => ({
  type: GET_TREATED_FEEDBACK_STATS.SUCCESS,
  treatedFeedbackStats,
});

export const getTreatedFeedbackStatsError = (error) => ({
  type: GET_TREATED_FEEDBACK_STATS.FAILURE,
  error,
});

/**
 * TREATED FEEDBACK BY SKILL STATS
 */
export const getTreatedFbBySkillStats = () => ({
  type: GET_TREATED_FEEDBACK_BY_SKILL_STATS.REQUEST,
});

export const getTreatedFbBySkillStatsSuccess = (treatedFbBySkillStats) => ({
  type: GET_TREATED_FEEDBACK_BY_SKILL_STATS.SUCCESS,
  treatedFbBySkillStats,
});

export const getTreatedFbBySkillStatsError = (error) => ({
  type: GET_TREATED_FEEDBACK_BY_SKILL_STATS.FAILURE,
  error,
});

/**
 * POSITIVE BOT FEEDBACK
 */
export const getPositiveBotFeedback = (companyResponseElementId) => ({
  type: GET_POSITIVE_BOT_FEEDBACK.REQUEST,
  companyResponseElementId,
});

export const getPositiveBotFeedbackSuccess = (positiveBotFeedback) => ({
  type: GET_POSITIVE_BOT_FEEDBACK.SUCCESS,
  positiveBotFeedback,
});

export const getPositiveBotFeedbackError = (error) => ({
  type: GET_POSITIVE_BOT_FEEDBACK.FAILURE,
  error,
});

/**
 * UPDATE NEGATIVE BOT FEEDBACK
 */
export const updateNegativeBotFeedback = (eiId, companyResponseElementId, responseStatus, comment) => ({
  type: UPDATE_NEGATIVE_BOT_FEEDBACK.REQUEST,
  eiId,
  companyResponseElementId,
  responseStatus,
  comment
});

export const updateNegativeBotFeedbackSuccess = (negativeBotFeedback) => ({
  type: UPDATE_NEGATIVE_BOT_FEEDBACK.SUCCESS,
  negativeBotFeedback,
});

export const updateNegativeBotFeedbackError = (error) => ({
  type: UPDATE_NEGATIVE_BOT_FEEDBACK.FAILURE,
  error,
});

export const resetUpdateNegativeBotFeedback = () => ({
  type: UPDATE_NEGATIVE_BOT_FEEDBACK.RESET,
});

/**
 * CYCONIA TRAINING - COMPETENCE DOMAIN
 */
export const getInformationList = (id) => ({
  type: GET_INFORMATION_LIST.REQUEST,
  id
});

export const getInformationListSuccess = (informationList) => ({
  type: GET_INFORMATION_LIST.SUCCESS,
  informationList,
});

export const getInformationListError = (error) => ({
  type: GET_INFORMATION_LIST.FAILURE,
  error,
});

/**
 * CYCONIA TRAINING - ELEMENT INFO LIST
 */
export const getElementInformationList = (eiId) => ({
  type: GET_ELEMENT_INFORMATION_LIST.REQUEST,
  eiId
});

export const getElementInformationListSuccess = (elementInformationList) => ({
  type: GET_ELEMENT_INFORMATION_LIST.SUCCESS,
  elementInformationList,
});

export const getElementInformationListError = (error) => ({
  type: GET_ELEMENT_INFORMATION_LIST.FAILURE,
  error,
});

/**
 * CYCONIA TRAINING - RESET ALL INFORMATION
 */
export const resetEditTraining = () => ({
  type: RESET_EDIT_TRAINING
});

/**
 * CYCONIA TRAINING - ELEMENT INFO FEEDBACK STATS
 */
export const getFeedsStatInfo = (eiId) => ({
  type: GET_FEEDS_STAT_INFO.REQUEST,
  eiId
});

export const getFeedsStatInfoSuccess = (feedsStatInfo) => ({
  type: GET_FEEDS_STAT_INFO.SUCCESS,
  feedsStatInfo,
});

export const getFeedsStatInfoError = (error) => ({
  type: GET_FEEDS_STAT_INFO.FAILURE,
  error,
});

/**
 * CYCONIA TRAINING - ELEMENT INFO FEEDBACK LIST (BY LIST)
 */
export const getFeedsListBySite = (siteId, eiId) => ({
  type: GET_FEEDS_LIST_BY_SITE.REQUEST,
  siteId,
  eiId
});

export const getFeedsListBySiteSuccess = (feedsListBySite) => ({
  type: GET_FEEDS_LIST_BY_SITE.SUCCESS,
  feedsListBySite,
});

export const getFeedsListBySiteError = (error) => ({
  type: GET_FEEDS_LIST_BY_SITE.FAILURE,
  error,
});

/**
 * CYCONIA TRAINING - ELEMENT INFO BY EIID
 */
export const getElementInformationResponseById = (eiId) => ({
  type: GET_ELEMENT_INFORMATION_RESPONSE_BY_ID.REQUEST,
  eiId
});

export const getElementInformationResponseByIdSuccess = (elementInformationResponseById) => ({
  type: GET_ELEMENT_INFORMATION_RESPONSE_BY_ID.SUCCESS,
  elementInformationResponseById,
});

export const getElementInformationResponseByIdError = (error) => ({
  type: GET_ELEMENT_INFORMATION_RESPONSE_BY_ID.FAILURE,
  error,
});

/**
 * CYCONIA TRAINING - ELEMENT INFO EIID BY COMPANY SITE
 */
export const getElementInformationByCompany = () => ({
  type: GET_ELEMENT_INFORMATION_BY_COMPANY.REQUEST,
});

export const getElementInformationByCompanySuccess = (elementInformationByCompany) => ({
  type: GET_ELEMENT_INFORMATION_BY_COMPANY.SUCCESS,
  elementInformationByCompany,
});

export const getElementInformationByCompanyError = (error) => ({
  type: GET_ELEMENT_INFORMATION_BY_COMPANY.FAILURE,
  error,
});

/**
 * CYCONIA TRAINING - ELEMENT INFO ACTIVATION UPDATE
 */
export const updateElementInformationActivation = (eiId) => ({
  type: UPADATE_ELEMENT_INFORMATION_ACTIVATION.REQUEST,
  eiId
});

export const updateElementInformationActivationSuccess = (updateEiActivation) => ({
  type: UPADATE_ELEMENT_INFORMATION_ACTIVATION.SUCCESS,
  updateEiActivation,
});

export const updateElementInformationActivationError = (error) => ({
  type: UPADATE_ELEMENT_INFORMATION_ACTIVATION.FAILURE,
  error,
});

/**
 * CYCONIA TRAINING - ELEMENT INFO RESPONSE UPDATE
 */
export const updateElementInformationResponse = (eiId, response, responseId, companySiteId) => ({
  type: UPADATE_ELEMENT_INFORMATION_RESPONSE.REQUEST,
  eiId, response, responseId, companySiteId
});

export const updateElementInformationResponseSuccess = (responseId) => ({
  type: UPADATE_ELEMENT_INFORMATION_RESPONSE.SUCCESS,
  responseId,
});

export const updateElementInformationResponseError = (error) => ({
  type: UPADATE_ELEMENT_INFORMATION_RESPONSE.FAILURE,
  error,
});

/**
 * CYCONIA TRAINING - FEEDBACKS PROCESSED UPDATE
 */
export const updateProcessedFeedback = (eiId, processedIds) => ({
  type: UPADATE_FEEDBACK_PROCESSED.REQUEST,
  eiId,
  processedIds,
});

export const updateProcessedFeedbackSuccess = (updateProcessedFeed) => ({
  type: UPADATE_FEEDBACK_PROCESSED.SUCCESS,
  updateProcessedFeed,
});

export const updateProcessedFeedbackError = (error) => ({
  type: UPADATE_FEEDBACK_PROCESSED.FAILURE,
  error,
});


/**
 * CYCONIA TRAINING - SET TITLE INFORMATION ELEMENT
 */

export const setInformationElementTitle = (informationTitle) => ({
  type: SET_TITLE_INFORMATION_ELEMENT,
  informationTitle,
});

/**
 * CYCONIA TRAINING - UPDATE ALL ELEMENT INFORMATION
 */

export const updateAllElementInformation = (eiId, processedIds, response, responseId, companySiteId) => ({
  type: UPDATE_ALL_ELEMENT_INFORMATION.REQUEST,
  eiId, processedIds, response, responseId, companySiteId
});

export const updateAllElementInformationSuccess = () => ({
  type: UPDATE_ALL_ELEMENT_INFORMATION.SUCCESS,
});

export const updateAllElementInformationError = (error) => ({
  type: UPDATE_ALL_ELEMENT_INFORMATION.FAILURE,
  error,
});

export const resetUpdateAllElementInformation = () => ({
  type: UPDATE_ALL_ELEMENT_INFORMATION.RESET,
});

// CASE SPECIFIC
export const getSpecificCase = (data) => ({
  type: GET_SPECIFIC_CASE.REQUEST,
  data
});

export const getSpecificCaseSuccess = (data) => ({
  type: GET_SPECIFIC_CASE.SUCCESS,
  data
});

export const getSpecificCaseError = (error) => ({
  type: GET_SPECIFIC_CASE.FAILURE,
  error
});

// Update case specific
export const editSpecificCase = (data) => ({
  type: UPDATE_SPECIFIC_CASE.REQUEST,
  data
});

export const editSpecificCaseSuccess = (data) => ({
  type: UPDATE_SPECIFIC_CASE.SUCCESS,
  data
});

export const editSpecificCaseError = (error) => ({
  type: UPDATE_SPECIFIC_CASE.FAILURE,
  error
});

// delete case specific
export const deleteSpecificCase = (data) => ({
  type: DELETE_SPECIFIC_CASE.REQUEST,
  data
});

export const deleteSpecificCaseSuccess = (data) => ({
  type: DELETE_SPECIFIC_CASE.SUCCESS,
  data
});

export const deleteSpecificCaseError = (error) => ({
  type: DELETE_SPECIFIC_CASE.FAILURE,
  error
});