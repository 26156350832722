import React, { useState, useEffect } from 'react';

import Routes from './routes';

const SSO = () => {
  return (
    <div>
      <Routes />
    </div>
  )
};

export default SSO;