import React, { useState, useEffect, useRef } from 'react';
import { Formik, Field, useFormikContext } from 'formik';
import * as Yup from "yup";
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';
import _ from 'lodash';

import CancelIcon from '@material-ui/icons/Cancel';
import ScheduleIcon from '@material-ui/icons/Schedule';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { displayDateRange } from '../../../../helpers/displayDateRange';
import { priceDisplay } from '../../../../helpers/roomBookingPriceHelper';

import Loader from '../../../../components/molecules/GenericLoader';
import GenericTextField from '../../../../components/molecules/GenericTextField';
import GenericTextArea from '../../../../components/molecules/GenericTextArea';
import GenericWarningModal from '../../../../components/molecules/GenericWarningModal';

import OptionListInput from './OptionListInput';
import RoomBookingCancellationInfo from '../../../../components/organisms/RoomBookingCancellationInfo';

import {
  CustomDialog,
  DialogContainer,
  StyledCloseContainer,
  StyledTitle,
  StyledInputTitle,
  StyledForm,
  InputGroup,
  StyledGenericMaterialMenu,
  StyledExpandableHeader,
  StyledExpandableTitle,
  StyledExpandableIcon,
  StyledExpandableContainer,
  StyledOptionExpandableContainer,
  StyledValidationButtons,
  StyledTitleWithIcon,
  StyledSummaryContainer,
  StyledInfoHeader,
  StyledInfoGroup,
  StyledInfoTitle,
  StyledInfoText,
  StyledOptionsContainer,
  StyledWarningDiv,
} from './style';

const CreateBookingModal = ({
  selectedSlot,
  onClose,
  onSubmit,
  roomData,
  saveBookingIsLoading
}) => {
  const [isDirty, setIsDirty] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [roomList, setRoomList] = useState(null);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [showInfo, setShowInfo] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const containerRef = useRef();

  useEffect(() => {
    if (selectedSlot) {
      const { selectedRoom } = selectedSlot;
      const newRoomList = [{ value: selectedRoom.id, label: selectedRoom.name }];

      _.map(selectedRoom.group, (group) => {
        newRoomList.push({ value: group.id, label: group.name });
      });

      setRoomList(newRoomList);
      setSelectedRoom(selectedRoom);
    }
  }, [selectedSlot]);

  useEffect(() => { // For auto scroll to view
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: 'auto' });
    }
  }, [showSummary]);

  const generateInitValue = () => {
    const start = new Date(selectedSlot.start);
    const end = new Date(selectedSlot.end);
    const initValue = {
      public_title: '',
      private_title: '',
      description: '',
      customer_reference: '',
      contact_number: '',
      startTime: format(start, "HH:mm", { locale: fr }),
      endTime: format(end, "HH:mm", { locale: fr }),
      options: [],
      note: '',
      selectedRoomId: selectedRoom.id,
    }
    return initValue;
  }

  const phoneRegExp = /^\+?[0-9]*$/g;

  const newContentActuSchema = Yup.object().shape({
    public_title: Yup.string()
      .required('Un titre public est requis'),
    contact_number: Yup.string()
      .required('Un numéro de téléphone est requis')
      .matches(phoneRegExp, `Le numéro de téléphone n'est pas valide`)
  });

  const handleRoomChange = (formikProps, value) => {
    const currentRoom = _.find(roomData, (room) => room.id === value);
    formikProps.setFieldValue('selectedRoomId', value);
    formikProps.setFieldValue('options', []);
    setSelectedRoom(currentRoom);
  }

  const handleSubmit = (values) => {
    onSubmit({
      formData: {
        ...values,
        startDate: format(selectedSlot.start, "yyyy-MM-dd", { locale: fr }),  // formik form only contains start and end time, need to retrive date from the slot data
        endDate: format(selectedSlot.end, "yyyy-MM-dd", { locale: fr }),      // formik form only contains start and end time, need to retrive date from the slot data
        quantity: 1,
        price: selectedRoom.price,
        companySiteId: selectedRoom.site.id,
        cancelFees: selectedRoom.cancel_fees
      }
    });
  }

  const generateTitle = () => {
    const { name, price } = selectedRoom;
    return `Réserver ${name} - ${priceDisplay(price)}`;
  }

  const displayOptions = (options) => {
    let optionsArray = [];
    if (options && options?.length !== 0) {
      optionsArray = _.compact(_.map(options, (option, index) => {
        if (option.checked) {
          return (
            <StyledOptionsContainer key={index}>
              <StyledInfoGroup>
                <StyledInfoTitle>{option.title}</StyledInfoTitle>
              </StyledInfoGroup>
              <StyledInfoGroup>
                <StyledInfoTitle>{`Note`}</StyledInfoTitle>
                <StyledInfoText>{option.note || '-'}</StyledInfoText>
              </StyledInfoGroup>
            </StyledOptionsContainer>
          )
        }
      }));
    }
    if (optionsArray.length !== 0) {
      return optionsArray;
    }
    else {
      return (
        <StyledOptionsContainer>
          {`Aucune option sélectionnée`}
        </StyledOptionsContainer>
      )
    }
  };

  const handleClose = () => {
    if (isDirty) setOpenDelete(true);
    else onClose();
  }

  const CheckDirty = () => {
    const { dirty } = useFormikContext();

    useEffect(() => {
      setIsDirty(dirty)
    }, [dirty]);
    return null;
  };

  return (
    <CustomDialog
      open={true}
      onClose={handleClose}
    >
      <div ref={containerRef}></div>
      {!selectedRoom || saveBookingIsLoading ?
        <Loader />
        :
        <DialogContainer>
          <StyledCloseContainer onClick={handleClose}>
            <CancelIcon style={{ fontSize: 30 }} />
          </StyledCloseContainer>

          <>
            <StyledTitle
              title={generateTitle()}
              toolTipText={selectedRoom.description}
            />
            <Formik
              initialValues={generateInitValue()}
              // enableReinitialize
              setFieldValue
              validationSchema={newContentActuSchema}
              onSubmit={() => setShowSummary(true)}
            >
              {(props) => {
                if (showSummary) {
                  const selectedDate = format(selectedSlot.start, "yyyy/MM/dd", { locale: fr }); // because safari only recognize yyyy/MM/dd as valid date instead of yyyy-MM-dd
                  const startDateTime = new Date(`${selectedDate} ${props.values.startTime}`);
                  const endDateTime = new Date(`${selectedDate} ${props.values.endTime}`);
                  return (
                    <StyledSummaryContainer>
                      <StyledInfoHeader>{`Récapitulatif de votre réservation`}</StyledInfoHeader>
                      <StyledInfoGroup>
                        <StyledInfoTitle>{`Salle`}</StyledInfoTitle>
                        <StyledInfoText>{selectedRoom.name}</StyledInfoText>
                      </StyledInfoGroup>
                      <StyledInfoGroup>
                        <StyledInfoTitle>{`Date`}</StyledInfoTitle>
                        <StyledInfoText>{displayDateRange(startDateTime, endDateTime)}</StyledInfoText>
                      </StyledInfoGroup>
                      {props.values.public_title &&
                        <StyledInfoGroup>
                          <StyledInfoTitle>{`Titre de la réservation`}</StyledInfoTitle>
                          <StyledInfoText>{props.values.public_title}</StyledInfoText>
                        </StyledInfoGroup>
                      }
                      {props.values.contact_number &&
                        <StyledInfoGroup>
                          <StyledInfoTitle>{`Numéro de téléphone`}</StyledInfoTitle>
                          <StyledInfoText>{props.values.contact_number}</StyledInfoText>
                        </StyledInfoGroup>
                      }
                      {props.values.private_title &&
                        <StyledInfoGroup>
                          <StyledInfoTitle>{`Titre privé`}</StyledInfoTitle>
                          <StyledInfoText>{props.values.private_title}</StyledInfoText>
                        </StyledInfoGroup>
                      }
                      {props.values.customer_reference &&
                        <StyledInfoGroup>
                          <StyledInfoTitle>{`Référence commande`}</StyledInfoTitle>
                          <StyledInfoText>{props.values.customer_reference}</StyledInfoText>
                        </StyledInfoGroup>
                      }
                      {props.values.description &&
                        <StyledInfoGroup>
                          <StyledInfoTitle>{`Descriptif`}</StyledInfoTitle>
                          <StyledInfoText>{props.values.description}</StyledInfoText>
                        </StyledInfoGroup>
                      }
                      <StyledInfoTitle>{`Options`}</StyledInfoTitle>
                      {displayOptions(props.values.options)}
                      {props.values.note &&
                        <StyledInfoGroup>
                          <StyledInfoTitle>{`Autres précisions au sujet de votre réservation`}</StyledInfoTitle>
                          <StyledInfoText>{props.values.note}</StyledInfoText>
                        </StyledInfoGroup>
                      }

                      <StyledWarningDiv>
                        <div>
                          {`Des frais d’annulation peuvent s’appliquer à votre réservation.`}
                        </div>
                        <div>
                          {`Vous pouvez les consulter ci-dessous.`}
                        </div>
                      </StyledWarningDiv>
                      <RoomBookingCancellationInfo
                        selectedRoom={selectedRoom}
                      />

                      <StyledValidationButtons
                        leftButonText={'Je corrige'}
                        RightButtonText={'Je confirme'}
                        onclickLefttButton={() => setShowSummary(false)}
                        onclickRightButton={() => handleSubmit(props.values)}
                        submitable={false}
                      />
                    </StyledSummaryContainer>
                  )
                }
                else {
                  return (
                    <StyledForm>
                      <CheckDirty />
                      <StyledTitleWithIcon
                        title={`Date: ${format(selectedSlot.start, "dd-MM-yyyy", { locale: fr })}`}
                        Icon={<ScheduleIcon style={{ fontSize: 18 }} />}
                      />
                      <InputGroup>
                        <Field
                          id="startTime"
                          name="startTime"
                          type="time"
                          title="Debut"
                          variant="outlined"
                          size="small"
                          as={GenericTextField}
                        />
                        <Field
                          id="endTime"
                          name="endTime"
                          type="time"
                          title="Fin"
                          variant="outlined"
                          size="small"
                          as={GenericTextField}
                        />
                      </InputGroup>
                      <StyledInputTitle title={`Salle`} />
                      <StyledGenericMaterialMenu
                        list={roomList}
                        onChange={(value) => handleRoomChange(props, value)}
                      />
                      <Field
                        id="public_title"
                        name="public_title"
                        title="Titre de la réservation"
                        placeholder="Ajouter titre (visible par tout le monde)"
                        variant="outlined"
                        size="small"
                        isRequired={true}
                        toolTipText={`Cette information est visible par l'ensemble des personnes internes et externes à votre entreprise.`}
                        as={GenericTextField}
                      />
                      <Field
                        id="contact_number"
                        name="contact_number"
                        title="Numéro de téléphone"
                        placeholder="Ajouter numéro de téléphone"
                        variant="outlined"
                        size="small"
                        isRequired={true}
                        toolTipText={``}
                        as={GenericTextField}
                      />
                      <StyledExpandableHeader onClick={() => setShowInfo(!showInfo)}>
                        <StyledExpandableTitle
                          title={`Informations à usage interne`}
                          toolTipText={`Les informations indiquées ici sont à usage interne uniquement et sont visibles par vous et votre entreprise.`}
                        />
                        <StyledExpandableIcon>
                          {showInfo ?
                            <ExpandLessIcon style={{ fontSize: 14 }} />
                            :
                            <ExpandMoreIcon style={{ fontSize: 14 }} />
                          }
                        </StyledExpandableIcon>
                      </StyledExpandableHeader>
                      <StyledExpandableContainer isVisible={showInfo}>
                        <Field
                          id="private_title"
                          name="private_title"
                          title="Titre privé"
                          placeholder="Ajouter titre privé"
                          variant="outlined"
                          size="small"
                          toolTipText={`Ce titre sera visible par vous et les collaborateurs de votre entreprise.`}
                          as={GenericTextField}
                        />
                        <Field
                          id="customer_reference"
                          name="customer_reference"
                          title="Référence commande"
                          placeholder="Ajouter référence"
                          variant="outlined"
                          size="small"
                          toolTipText={`Si besoin, vous pouvez renseigner votre référence de commande.`}
                          as={GenericTextField}
                        />
                        <Field
                          id="description"
                          name="description"
                          title="Descriptif"
                          placeholder="Ajouter descriptif"
                          variant="outlined"
                          size="small"
                          minRows={2}
                          toolTipText={`Vous pouvez indiquer votre ordre du jour par exemple ou toute autre information que vous jugez nécessaire.`}
                          as={GenericTextArea}
                        />
                      </StyledExpandableContainer>

                      <StyledExpandableHeader onClick={() => setShowOptions(!showOptions)}>
                        <StyledExpandableTitle
                          title={`Options`}
                          toolTipText={`Vous pouvez ajouter des options à votre réservation.`}
                        />
                        <StyledExpandableIcon>
                          {showOptions ?
                            <ExpandLessIcon style={{ fontSize: 14 }} />
                            :
                            <ExpandMoreIcon style={{ fontSize: 14 }} />
                          }
                        </StyledExpandableIcon>
                      </StyledExpandableHeader>
                      <StyledOptionExpandableContainer isVisible={showOptions}>
                        <OptionListInput
                          options={selectedRoom.options}
                          values={props.values.options}
                          onChange={(value) => props.setFieldValue('options', value)}
                        />
                      </StyledOptionExpandableContainer>

                      <RoomBookingCancellationInfo
                        selectedRoom={selectedRoom}
                        expanded={false}
                      />

                      <Field
                        id="note"
                        name="note"
                        title="Autres précisions au sujet de votre réservation"
                        variant="outlined"
                        placeholder="Ajouter une note"
                        minRows={4}
                        toolTipText={`Les informations indiquées ici seront visibles par la personne en charge des réservations de salles.`}
                        as={GenericTextArea}
                      />
                      <StyledValidationButtons
                        leftButonText={'Annuler'}
                        RightButtonText={'Réserver'}
                        onclickLefttButton={handleClose}
                        submitable={true}
                      />
                    </StyledForm>
                  )
                }
              }}
            </Formik>
          </>
        </DialogContainer>
      }
      <GenericWarningModal
        open={openDelete}
        setOpenDialog={() => setOpenDelete(!openDelete)}
        title={"Attention"}
        text={"Êtes-vous sur de vouloir annuler votre saisie?"}
        leftButtonText={"Non"}
        rightButtonText={"Oui"}
        leftButtonFunction={() => setOpenDelete(!openDelete)}
        rightButtonFunction={onClose}
      />
    </CustomDialog>
  )
};

export default CreateBookingModal;