import React, { useEffect, useState } from 'react'
import { connect } from 'formik';
import { format } from 'date-fns';

import GenericTitleWithToolTip from '../../../../components/molecules/GenericTitleWithToolTip';

import {
  CONTENT_TYPE,
  CONTENT_STATUS,
} from '../../../../constants/contentConstants';

//Import style
import {
  PublicationDiv,
  CustomRadio,
  CustomRadioDiv,
  PublicationBoxDiv,
  PublicationSpan,
  DateField,
} from './style';


const PublicationBox = ({
  isRequired,
  toolTipText,
  setDisabledFields,
  setFieldValue,
  publicationDate,
  status,
  contentType
}) => {
  const today = new Date();
  const tomorrow = new Date(today.getTime() + (24 * 60 * 60 * 1000));
  const [currentValue, setCurrentValue] = useState(true);


  const selectPublicationDate = (date) => {
    setFieldValue("publicationDate", date);
  }

  const setNewStatus = (status) => {
    if (status === CONTENT_STATUS.PLANNED && publicationDate === "") {
      setFieldValue("publicationDate", format(tomorrow, "yyyy-MM-dd"));
    }
    setDisabledFields(status === CONTENT_STATUS.ARCHIVED);
    setFieldValue("status", status);
  }

  useEffect(() => {
    if (status) {
      if (status === CONTENT_STATUS.NOT_PUBLISHED) setCurrentValue('1');
      else if (status === CONTENT_STATUS.PUBLISHED) setCurrentValue('2');
      else if (status === CONTENT_STATUS.PLANNED) setCurrentValue('3');
      else if (status === CONTENT_STATUS.ARCHIVED) setCurrentValue('4');
    }
  }, [status]);

  return (
    <PublicationBoxDiv >
      <GenericTitleWithToolTip
        title={"Publication de votre contenu"}
        className={'textFieldTitle'}
        toolTipText={toolTipText}
        isRequired={isRequired}
      />
      <PublicationDiv id="1" active={currentValue === '1'} onClick={() => setNewStatus(CONTENT_STATUS.NOT_PUBLISHED)} type={'other'}>
        <CustomRadioDiv>
          <CustomRadio active={currentValue === '1'} type={'other'} />
        </CustomRadioDiv>
        <PublicationSpan>{`Ne pas publier (contenu préservé sous forme d'ébauche)`}</PublicationSpan>
      </PublicationDiv>
      <PublicationDiv id="2" active={currentValue === '2'} onClick={() => setNewStatus(CONTENT_STATUS.PUBLISHED)} type={'other'}>
        <CustomRadioDiv >
          <CustomRadio active={currentValue === '2'} type={'other'} />
        </CustomRadioDiv>
        <PublicationSpan>{`Publier immédiatement`}</PublicationSpan>
      </PublicationDiv>
      {contentType !== CONTENT_TYPE.GOOD_DEAL &&
        <PublicationDiv id="3" active={currentValue === '3'} onClick={() => setNewStatus(CONTENT_STATUS.PLANNED)} type={'other'}>
          <CustomRadioDiv>
            <CustomRadio active={currentValue === '3'} type={'other'} />
          </CustomRadioDiv>
          <PublicationSpan>{`Définir une date de publication`}</PublicationSpan>
        </PublicationDiv>
      }
      {currentValue === '3' &&
        <DateField
          id="date"
          type="date"
          variant="outlined"
          InputProps={{ inputProps: { min: format(tomorrow, "yyyy-MM-dd") } }}
          value={publicationDate ? format(new Date(publicationDate), "yyyy-MM-dd") : format(tomorrow, "yyyy-MM-dd")}
          onChange={(event) => selectPublicationDate(event.target.value)}
          InputLabelProps={{ shrink: true }}
        />
      }
      <PublicationDiv id="4" active={currentValue === '4'} onClick={() => setNewStatus(CONTENT_STATUS.ARCHIVED)} type={'archive'}>
        <CustomRadioDiv>
          <CustomRadio active={currentValue === '4'} type={'archive'} />
        </CustomRadioDiv>
        <PublicationSpan>{'Archiver votre contenu'}</PublicationSpan>
      </PublicationDiv>
    </PublicationBoxDiv >
  )
}

export default connect(PublicationBox);
