import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import _ from 'lodash';
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import {
  StyledForm,
  StyledTextField,
  StyledDeleteMessage,
  StyledDialog,
  StyledButton
} from './style';

// import ButtonCpt from '../../../../../../../components/molecules/buttonCpt';
import { MESSAGE_ELEMENT_TYPE } from '../../../../constants/chatBotConstants';
import UrlButtonForm from '../../UrlButtonForm';


const ResponseEditor = ({ element, editMode, onSave, onDelete, onCancel }) => {
  const [form, setForm] = useState({});

  useEffect(() => {
    if (element) {
      const newForm = _.pickBy(element, (item, key) => key !== 'messageElements' && key !== 'type');
      setForm(newForm);
    }
  }, [element]);

  const handleFormChange = (value, key) => {
    const newForm = _.clone(form);

    newForm[key] = value;
    setForm(newForm);
  }

  const modules = {
    toolbar: {
      container: [['bold', 'italic', 'underline', { 'color': [] }], ['link'], [{ 'list': 'bullet' }, { 'list': 'ordered' }], ['clean']], 
    },
  };

  const getModalContent = () => {
    if (editMode === "delete") {
      return (<StyledDeleteMessage>Êtes-vous sûr de supprimer cet élément</StyledDeleteMessage>)
    }
    else if (form) {
      if (element.type === MESSAGE_ELEMENT_TYPE.TEXT) {
        return (
          <ReactQuill
            theme="snow"
            value={form.content || ''}
            onChange={(data) => handleFormChange(data, "content")}
            modules={modules}
          />
        )
      }
      else if (element.type === MESSAGE_ELEMENT_TYPE.BUTTON_LINK) {
        return (
          <UrlButtonForm
            form={form}
            handleFormChange={handleFormChange}
          />
        )
      }
      else {
        return (
          <StyledForm noValidate autoComplete="off">
            <StyledTextField
              title={"Texte bouton"}
              value={form.title}
              variant={"outlined"}
              onChange={(e) => handleFormChange(e.target.value, "title")}
            />
            {element.type !== MESSAGE_ELEMENT_TYPE.BUTTON_FORM &&
              <StyledTextField
                title={"Phrase déclenchée"}
                value={form.userMessage}
                variant={"outlined"}
                onChange={(e) => handleFormChange(e.target.value, "userMessage")}
              />
            }
          </StyledForm>
        )
      }
    }
  }

  return (
    <StyledDialog
      open={true}
      onClose={() => onCancel()}
      aria-labelledby="premiumAlert-dialog-title"
      aria-describedby="premiumAlert-dialog-description"
      type={element.type}
      editmode={editMode}
    >
      <DialogContent id="premiumAlert-dialog-description">
        {getModalContent()}
      </DialogContent>
      <DialogActions>
        <StyledButton
          type={'cancelButton'}
          onClick={() => editMode === "add" ? onDelete() : onCancel()}
        >
          Annuler
        </StyledButton>
        <StyledButton
          onClick={() => editMode === "delete" ? onDelete() : onSave(form)}
        >
          Valider
        </StyledButton>
      </DialogActions>
    </StyledDialog>
  )
};

ResponseEditor.propTypes = {
  element: PropTypes.object,
  editMode: PropTypes.string,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func
};

export default ResponseEditor;