import styled from "styled-components";
import { NavLink } from "react-router-dom";
import ButtonCpt from '../../components/molecules/buttonCpt';
import FormControl from '@material-ui/core/FormControl';
import breakpoints from "../../../../theme-style/breakpoints";


export const ButtonCptSave = styled(ButtonCpt)`
  &&{
    height: 60px;
    margin: auto 0 25px 0;
    &.MuiButtonBase-root{
      .MuiButton-label{
        span{
          color: white;
          font-weight: 600;
          font-size: 13px;
          line-height: 10px;
          text-align: center;
        }
      }
    }
  }
`;

export const ProfileIconWrapper = styled.span`
  position: absolute;
  top: 9px;
  right: -16px;
  padding: 0px;
  max-width: 14px;
  max-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  svg path{
    transition: all .2s ease-out;
  }
`;

export const FormControlCustom = styled(FormControl)`
  &&{
    &.MuiFormControl-root{
      background-color: #ffffff;
      border-radius: 0;
      width: 100%;
      padding: 0;
      box-sizing: border-box;
      border-bottom: 1px solid #F4F4F4;
      &:first-child{
        border-radius: 6px 6px 0 0;
      }
      &:last-child{
        border-radius: 0 0 6px 6px;
        border-bottom: 0;
      }
      .MuiFormLabel-root{
        width: 100%;
        padding: 20px 25px 10px 20px;
        box-sizing: border-box;
        font-weight: 500;
        font-size: 11px;
        color: #838383;
        transition: all .2s ease-out;
      }
      .MuiFormGroup-root{
        padding: 0 20px 15px 20px;
        .MuiFormControlLabel-root{
          position: relative;
          &:hover{
            .MuiRadio-root{
              color: #40B176;
            }
            .MuiTypography-root{
              span{
                svg path{
                  fill: #40B176;
                  stroke: #40B176;
                }
              }
            }
          }
          .MuiRadio-root{
            color: #E5E5E5;
            padding: 5px 5px;
            margin: 0 5px;
            transition: all .2s ease-out;
            &.Mui-checked{
              color: #40B176;
              & + .MuiTypography-root{
                span{
                  svg path{
                    fill: #40B176;
                    stroke: #40B176;
                  }
                }
              }
            }
            .MuiIconButton-label{
              input{

              }
              div{
                svg{

                }
              }
            }
          }
          .MuiTypography-root{
            color: #5A5A5A;
            font-weight: 600;
            font-size: 13px;
            line-height: 9px;
            text-align: left;
            margin: 0;
          }
        }
      }
    }
  }
`;

export const ProfilForm = styled.form`

`;

export const ProfilFormContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 70px);
`;

export const Title = styled.div`
  color: white;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin: 0;
  transition: all .2s ease-out;
`;

export const BackIconWrapper = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 16px;
  max-height: 12px;
  svg path{
    transition: all .2s ease-out;
  }
`;

export const BackButton = styled.div`
  margin: 0 30px 0 0;
`;

export const Header = styled(NavLink)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  text-decoration: none;
  padding: 15px 0;
  @media ${breakpoints.mobileXL} {
    padding: 25px 0;
  }
  &:hover{
    ${Title}{
      color: #efc31f;
    }
    ${BackIconWrapper}{
      svg{
        path{
          fill: #efc31f;
        }
      }
    }
  }
`;

export const ProfilWrapper = styled.div`
  height: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const Container = styled.div`
  /* position: absolute; */
  /* z-index: 888; */
  /* top: 0; */
  height: auto;
  min-height: 100vh;
  width: 100%;
  background-color: #357ED3;
  box-sizing: border-box;
  padding: 0 15px;

  @media ${breakpoints.mobileXL} {
    padding: 0 25px;
  }
`;
