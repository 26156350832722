import styled from "styled-components";
import Button from '@material-ui/core/Button';

export const CloseButton = styled(Button)`
	&&{
		&.MuiButton-root{
			position: absolute;
			right: 5px;
			top: 10px;
			border: none;
			background-color: transparent;
			box-shadow: none;
			-webkit-tap-highlight-color: transparent;
			svg {
					width: 14px;
					height: auto;
			}
		}
	}
`;

export const Content = styled.p`
	vertical-align: middle;
	font-size: 14px;
	line-height: 18px;
	font-weight: 500;
	color: #5A5A5A;
	svg {
			width: auto;
			height: 14px;
	}
	strong{
		box-sizing: border-box;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		background-color: #5A5A5A;
		padding: 9px;
		height: 15px;
		width: 15px;
		border-radius: 2px;
		color: #fff;
		font-size: 16px;
		font-weight: 500;
		line-height: 1px;
	}
`;

export const Container = styled.div`
	position: fixed;
	z-index: 1000;
	left: 50%;
	transform: translateX(-50%);
	bottom: 10px;
	background-color: #fff;
	border-radius: 6px;
	padding: 15px;
	width: 94vw;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
  box-shadow: 1px 1px 3px 1px rgb(0 0 0 / 20%);
	@media screen and (min-width: 470px){
			max-width: 375px;
	}
`;