import { takeLatest } from 'redux-saga/effects';
import {
  actions
} from './actions';

import {
  getContextsHandler,
  saveContextsHandler
} from './handler/contextSettingHandler';


export default function* pageSetting() {
  yield takeLatest(actions.GET_CONTEXTS.REQUEST, getContextsHandler);
  yield takeLatest(actions.SAVE_CONTEXTS.REQUEST, saveContextsHandler);
}