import React from "react";

const DashboardIcon = ({
  color = "#2B2B2B",
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon dashboardIcon"
      width={width}
      height={height}
      viewBox="0 0 15 15"
      fill="none"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
          <path
            d="M11.5909091,8.18181818 C13.473698,8.18181818 15,9.70812017 15,11.5909091 C14.9977458,13.4727635 13.4727635,14.9977458 11.5909091,15 C9.70812017,15 8.18181818,13.473698 8.18181818,11.5909091 C8.18181818,9.70812017 9.70812017,8.18181818 11.5909091,8.18181818 Z M6.13636364,8.18181818 C6.51292142,8.18181818 6.81818182,8.48707858 6.81818182,8.86363636 L6.81818182,8.86363636 L6.81818182,14.3181818 C6.81818182,14.6947396 6.51292142,15 6.13636364,15 L6.13636364,15 L0.681818182,15 C0.305260398,15 0,14.6947396 0,14.3181818 L0,14.3181818 L0,8.86363636 C0,8.48707858 0.305260398,8.18181818 0.681818182,8.18181818 L0.681818182,8.18181818 Z M11.5909091,9.54545455 C10.4612357,9.54545455 9.54545455,10.4612357 9.54545455,11.5909091 C9.54545455,12.7205824 10.4612357,13.6363636 11.5909091,13.6363636 C12.7205824,13.6363636 13.6363636,12.7205824 13.6363636,11.5909091 C13.6363636,10.4612357 12.7205824,9.54545455 11.5909091,9.54545455 Z M5.45454545,9.54545455 L1.36363636,9.54545455 L1.36363636,13.6363636 L5.45454545,13.6363636 L5.45454545,9.54545455 Z M14.3181818,0 C14.6947396,0 15,0.305260398 15,0.681818182 L15,6.13636364 C15,6.51292142 14.6947396,6.81818182 14.3181818,6.81818182 L8.86363636,6.81818182 C8.48707858,6.81818182 8.18181818,6.51292142 8.18181818,6.13636364 L8.18181818,0.681818182 C8.18181818,0.305260398 8.48707858,0 8.86363636,0 L14.3181818,0 Z M6.13636364,0 C6.51292142,0 6.81818182,0.305260398 6.81818182,0.681818182 L6.81818182,6.13636364 C6.81818182,6.51292142 6.51292142,6.81818182 6.13636364,6.81818182 L0.681818182,6.81818182 C0.305260398,6.81818182 0,6.51292142 0,6.13636364 L0,0.681818182 C0,0.305260398 0.305260398,0 0.681818182,0 L6.13636364,0 Z M13.6363636,1.36363636 L9.54545455,1.36363636 L9.54545455,5.45454545 L13.6363636,5.45454545 L13.6363636,1.36363636 Z M5.45454545,1.36363636 L1.36363636,1.36363636 L1.36363636,5.45454545 L5.45454545,5.45454545 L5.45454545,1.36363636 Z"
            fill={color}
            fillRule="nonzero"
          >
          </path>
      </g>
    </svg>
  );
};

export default DashboardIcon;
