import React from "react";

const NewsIcon = ({
  color = "#2B2B2B",
  width = "100%",
  height = "100%"
}) => {
  return (
    <svg
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      width={width}
      height={height}
    >
      <path
        d="M 4 5 L 4 6 L 4 27 L 23 27 L 24 27 L 28 27 L 28 12 L 24 12 L 24 5 L 4 5 z M 6 7 L 22 7 L 22 25 L 6 25 L 6 7 z M 9 10 L 9 13 L 19 13 L 19 10 L 9 10 z M 24 14 L 26 14 L 26 25 L 24 25 L 24 14 z M 9 16 L 9 18 L 19 18 L 19 16 L 9 16 z M 9 20 L 9 22 L 19 22 L 19 20 L 9 20 z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
}

export default NewsIcon;