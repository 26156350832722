import styled from 'styled-components';
import { COLORS } from '../../../../../constants/design';

export const LinkRgpdWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-top: 24px;
  hr{
    width: 30px;
    height: 1px;
    background-color: ${COLORS.LIGHT_GREY_1};
    border: 0;
    margin: 0 auto 5px auto;
  }
  a{
    font-size: 14px;
    text-align: center;
    color: ${COLORS.GREY_1};
    margin: 0 auto;
    /* border: 1px solid ${COLORS.LIGHT_GREY_3}; */
    border-radius: 9px;
    padding: 14px 10px;
    width: 100%;
    /* text-decoration: none; */
    box-sizing: border-box;
    transition: all .2s ease-out;
    &:hover{
      color: ${COLORS.PRIMARY_BLUE};
      /* border: 1px solid ${COLORS.PRIMARY_BLUE}; */
    }
  }
`;