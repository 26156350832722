import React, { useEffect, useState } from 'react'
import _ from 'lodash';
import { useParams } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { Formik, Form, Field, useFormikContext } from 'formik';
import * as Yup from "yup";
import DialogContent from "@material-ui/core/DialogContent";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import { toaster } from '../../../../helpers/toaster';

//Import Generic components
import GenericAdminFrontHeader from '../../../../components/molecules/GenericAdminFrontHeader';
import GenericMultipleSelect from '../../../../components/molecules/GenericMultipleSelect';
import GenericTextField from "../../../../components/molecules/GenericTextField";
import GenericFileChooser from '../../../../components/molecules/GenericFileChooser';
import GenericErrorMessage from '../../../../components/molecules/GenericErrorMessage';
import GenericDrawerValidationButtons from '../../../../components/molecules/GenericDrawerValidationButtons';
import GenericWarningModal from '../../../../components/molecules/GenericWarningModal';
import GenericLoader from '../../../../components/molecules/GenericLoader';

import {
  CONTENT_TYPE,
  CONTENT_SUPPORTED_FORMATS
} from '../../../../constants/contentConstants';

import PublicationBox from '../PublicationBox';
import CommentBox from '../CommentBox';
import Preview from '../Preview';
import { getContentStatus } from '../../utils/contentManagementUtils';

import {
  updateNewsContent,
  getNewsContentById,
  clearSelectedNewsContent
} from '../../../../store/contentManagement/actions';

import {
  getNewsContentData,
  getNewsContentIsLoading,
  getSelectedNewsContentData,
  getSelectedNewsContentIsLoading
} from '../../../../store/contentManagement/selectors';

import { getConciergeSitesData } from '../../../../store/concierge/selectors';

import {
  Container,
  DrawerCptCustom,
  DrawerWrapper,
  Wrapper,
  FormContainer,
  FieldWithErrorMessageDiv,
  PreviewButton,
  Divider,
  StyledDialog
} from './style';

const NewsContentDrawer = ({ visible, closeFunction }) => {
  const { newsId, editType } = useParams();
  const dispatch = useDispatch();
  const [openPreview, setOpenPreview] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [listRef, setListRef] = useState(null);
  const [disabledFields, setDisabledFields] = useState(false);
  const [openInputModal, setOpenInputModal] = useState(false);
  const [sitesList, setSitesList] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedNews, setSelectedNews] = useState({
    title: '',
    chooseFile: null,
    sites: [],
    content: "",
    publicationDate: "",
    status: '',
  });

  const {
    sitesData,
    newsContentData,
    newsContentDataIsLoading,
    selectedNewsContent,
    selectedNewsContentIsLoading
  } = useSelector(
    state => ({
      sitesData: getConciergeSitesData(state),
      newsContentData: getNewsContentData(state),
      newsContentDataIsLoading: getNewsContentIsLoading(state),
      selectedNewsContent: getSelectedNewsContentData(state),
      selectedNewsContentIsLoading: getSelectedNewsContentIsLoading(state),
    }),
    shallowEqual
  );

  useEffect(() => {
    if (newsId !== 'new')
      dispatch(getNewsContentById({ id: newsId }));
  }, [newsId]);

  useEffect(() => {
    if (sitesData) {
      const newList = _.map(sitesData, (site) => {
        return { label: site.name, value: site.id }
      })
      setSitesList(newList);
    }
  }, [sitesData]);

  useEffect(() => {
    if (!selectedNewsContentIsLoading && sitesList) {
      if (newsId !== 'new' && selectedNewsContent) {
        setSelectedNews({
          title: selectedNewsContent.title || '',
          chooseFile: selectedNewsContent.banner || null,
          sites: cleanSitesList(selectedNewsContent.companySites) || [],
          content: selectedNewsContent.content || "",
          publicationDate: selectedNewsContent.plannedFor || "",
          status: getContentStatus(selectedNewsContent)
        })
        setDisabledFields(selectedNewsContent.archived);
      }
      setIsLoading(false);
    }
  }, [selectedNewsContentIsLoading, sitesList, selectedNewsContent])

  useEffect(() => {
    if (listRef && newsContentData?.items) {
      if (!_.isEqual(listRef, newsContentData?.items)) doCloseDrawer();
    }
    setListRef(newsContentData?.items);
  }, [newsContentData?.items])

  const handleImageEdit = (setFieldValue, value) => {
    setFieldValue('chooseFile', !value && selectedNewsContent?.banner ? selectedNewsContent?.banner : value);
  }

  const handleContentEdit = (setFieldValue, value) => {
    setFieldValue('content', value === '<p><br></p>' ? '' : value);
  }

  const handleClose = () => {
    if (isDirty) setOpenCancel(true);
    else doCloseDrawer();
  }

  const doCloseDrawer = () => {
    if (newsId !== 'new')
      dispatch(clearSelectedNewsContent());
    closeFunction();
  }

  const CheckDirty = () => {
    const { dirty } = useFormikContext();

    React.useEffect(() => {
      setIsDirty(dirty);
    }, [dirty]);

    return null;
  };

  const checkIsValid = (props) => {
    if (!props.isValid) {
      toaster(`Erreur de sauvegarde, merci de vérifier que tous les champs obligatoires sont complétés.`, 'error');
    }
  };

  const cleanSitesList = (list) => {
    return list?.map((item) => ({ label: item.name, value: item.id }));
  }

  const getFormInitvalue = () => {
    const formInitValue = { ...selectedNews };
    return formInitValue;
  }

  const validationSchema = Yup.object().shape({
    sites: Yup.array()
      .of(
        Yup.object().shape({
          title: Yup.string()
        })
      )
      .required('Vous devez choisir au moins un site')
      .min(1, 'Minimum 1 site'),

    title: Yup.string()
      .required('Un titre est requis'),
    content: Yup.string()
      .required('Un contenu est requis'),
    chooseFile: Yup.mixed()
      .nullable()
      .required("Une image de couverture est requise")
      .test('Size', 'Fichier trop volumineux', (value) => !value || typeof value === 'string' || (value && value.size <= 1024 * 1024))
      .test('format', 'not supported file format', (value) => !value || typeof value === 'string' || (value && CONTENT_SUPPORTED_FORMATS.includes(value.type))),
    status: Yup.string()
      .required('Un choix est requis'),
  });

  const submitForm = (formData) => {
    // console.log('formData', formData);
    const id = (newsId === 'new' || editType === 'duplicate') ? 'new' : newsId;
    dispatch(updateNewsContent({ id, formData }));
  }

  return (
    <Container>
      <DrawerCptCustom
        onOpen={visible}
        visible={visible}
        closeFunction={handleClose}
        className="contentManagementDrawer"
      >
        <DrawerWrapper>
          <Formik
            initialValues={getFormInitvalue()}
            enableReinitialize
            setFieldValue
            validationSchema={validationSchema}
            onSubmit={(values) => submitForm(values)}
          >
            {(props) => {
              return (
                <>
                  <Wrapper>
                    <GenericAdminFrontHeader
                      headerTitle={`${newsId === 'new' || editType === 'duplicate' ? 'Ajouter une nouvelle actualité' : 'Éditer un actualité'}`}
                      RightSideComponent={
                        <PreviewButton
                          onClick={() => setOpenPreview(!openPreview)}
                          endIcon={openPreview ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        >
                          {openPreview ? `Fermer l'aperçu` : `Ouvrir l'aperçu`}
                        </PreviewButton>
                      }
                    />
                    {(isLoading || newsContentDataIsLoading) &&
                      <GenericLoader />
                    }
                    {(!isLoading && !newsContentDataIsLoading) &&
                      <Form>
                        <CheckDirty />
                        <FormContainer>
                          <GenericMultipleSelect
                            list={sitesList || []}
                            fildName="sites"
                            id="sites"
                            name="sites"
                            title="Site(s) de diffusion"
                            isRequired={true}
                            value={props.values.sites}
                            limitItem={10}
                            disabled={disabledFields}
                            hasSelectAll={true}
                            onChange={(e, value) => { return props.setFieldValue('sites', value) }}
                            toolTipText={'Ce champ vous permet de sélectionner les Sites de diffusion.'}
                          />
                          <Divider />
                          <GenericFileChooser
                            title="Image de couverture"
                            buttonText="Choisir un fichier"
                            isRequired={true}
                            id="chooseFile"
                            disabled={disabledFields}
                            name="chooseFile"
                            onChange={(value) => handleImageEdit(props.setFieldValue, value)}
                            selectedFileName={props.values.chooseFile?.name}
                            shouldShowImagePreview={true}
                            currentFile={props.values.chooseFile || selectedNewsContent?.banner}
                          />
                          <Field
                            id="title"
                            name="title"
                            title="Titre de votre contenu"
                            placeholder="Saisissez un titre"
                            variant="outlined"
                            disabled={disabledFields}
                            size="small"
                            isRequired={true}
                            as={GenericTextField}
                          />
                          <FieldWithErrorMessageDiv>
                            <CommentBox
                              value={props.values.content}
                              onChange={(e) => handleContentEdit(props.setFieldValue, e)}
                              onOpenModal={setOpenInputModal}
                              disabled={disabledFields}
                            />
                            <GenericErrorMessage name={'content'} />
                          </FieldWithErrorMessageDiv>
                          <Divider />
                          <FieldWithErrorMessageDiv>
                            <PublicationBox
                              isRequired={true}
                              publicationDate={props.values.publicationDate}
                              toolTipText={'Vous devez choisir le statut de votre contenu avant de sauvegarder.'}
                              setDisabledFields={setDisabledFields}
                              setFieldValue={props.setFieldValue}
                              status={props.values.status}
                              contentType={CONTENT_TYPE.ACTUAL}
                            />
                            <GenericErrorMessage name={'status'} />
                          </FieldWithErrorMessageDiv>
                          <GenericDrawerValidationButtons
                            leftButonText={'Annuler'}
                            RightButtonText={'Sauvegarder'}
                            onclickLefttButton={handleClose}
                            onclickRightButton={() => checkIsValid(props)}
                            submitable={false}
                          />
                        </FormContainer>
                      </Form>
                    }
                  </Wrapper>
                  {openPreview === true && (
                    <Preview
                      contentTitle={props.values.title}
                      publicationDate={props.values.publicationDate}
                      link={props.values.goodDealLink}
                      choosenFile={props.values.chooseFile || selectedNewsContent?.banner}
                      selectedType={CONTENT_TYPE.ACTUAL}
                      dealValue={props.values.goodDealValue}
                      content={props.values.content}
                    />)
                  }
                  {openInputModal &&
                    <StyledDialog open={true} onClose={() => setOpenInputModal(false)}>
                      <DialogContent>
                        <CommentBox
                          value={props.values.content}
                          onChange={(e) => handleContentEdit(props.setFieldValue, e)}
                          isModalMode={openInputModal}
                          onOpenModal={setOpenInputModal}
                          disabled={disabledFields}
                        />
                      </DialogContent>
                    </StyledDialog>
                  }
                </>
              )
            }}
          </Formik>
        </DrawerWrapper>
      </DrawerCptCustom>
      <GenericWarningModal
        open={openCancel}
        setOpenDialog={() => setOpenCancel(!openCancel)}
        title={"Attention"}
        text={"Êtes-vous sur de vouloir annuler votre saisie ?"}
        leftButtonText={"Non"}
        rightButtonText={"Oui"}
        leftButtonFunction={() => setOpenCancel(!openCancel)}
        rightButtonFunction={doCloseDrawer}
      />
    </Container >
  )
}

export default NewsContentDrawer;
