import styled from "styled-components";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';

export const ContentSiteDiv = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    pointer-events: ${props => props.disabled === true ? 'none' : 'all'};
`;

export const ListTypeContainer = styled.div`
    margin-top: 10px;
    width: 100%;
    max-width: 100%;
`;

export const CustomTextFild = styled(TextField)`
    background-color: white;
`;

export const ListTitle = styled.span`
    font-size: 13px;
`;

export const AutocompleteCustom = styled(Autocomplete)`
    width: 100%;
    &.tag {
      color: #4891E3;
  /*     &.MuiChip-label{
        color: "red"
      } */
    }
`;

export const CustomRadioDiv = styled.div`
  width: 18px;
  height: 18px;
  margin: 0px 5px 0px 5px;
  border-radius: 50%;
  background-color: #EEEEEE;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CustomRadio = styled.div`
  width: 13px;
  height: 13px;
  border-radius: 50%;
`;

export const CustomChip = styled(Chip)`
  margin: 1px;
`;