import React from "react";

const ArrowDownIcon = props => {
  const {
    color = "#292929",
    width = "100%",
    height = "100%"
  } = props;
  
  return (
    <svg
      className="icon arrowDownIcon"
      width={width}
      height={height}
      viewBox="0 0 9 5"
      fill="none"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M7.67460431,0.620371699 C7.88426723,0.440660623 8.19991723,0.464941392 8.3796283,0.674604313 C8.53937148,0.860971354 8.53793592,1.13108127 8.38839179,1.31502169 L8.32539569,1.3796283 L4.82539569,4.3796283 C4.66155643,4.52006195 4.42953308,4.53761616 4.2486478,4.43229092 L4.17460431,4.3796283 L0.674604313,1.3796283 C0.464941392,1.19991723 0.440660623,0.884267235 0.620371699,0.674604313 C0.780114877,0.488237272 1.04726658,0.448345018 1.25191446,0.567997733 L1.32539569,0.620371699 L4.49999094,3.341 L7.67460431,0.620371699 Z"
          fill={color}
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

export default ArrowDownIcon;
