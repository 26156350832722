import React from "react";

const WeatherAtmosphereIcon = ({
  width = "100%",
  height = "100%",
  color = "#F7FBFF",
}) => {

  return (
    <svg
      className="icon weatherAtmosphereIcon"
      width={width}
      height={height}
      viewBox="0 0 601 324"
      fill="none"
    >
      <path
        d="M428.6.9c6 0 10.8 4.8 10.8 10.8 0 6-4.8 10.8-10.8 10.8H290.7c21.8 14.4 39 35.1 49.1 59.6 14.4-9.1 31.5-14.4 49.9-14.4h120.5c6 0 10.8 4.8 10.8 10.8 0 6-4.8 10.8-10.8 10.8h-60.5c20.8 17.3 34.1 43.3 34.1 72.5 0 13-2.7 25.3-7.4 36.5h112.5c6 0 10.8 4.8 10.8 10.8 0 6-4.8 10.8-10.8 10.8h-65.6c9.8 11 15.8 25.5 15.8 41.4 0 15.6-5.7 29.8-15.1 40.7h65c5.9 0 10.8 4.9 10.8 10.7 0 6-4.8 10.8-10.8 10.8H87.8C39.3 323.5 0 284.2 0 235.7s39.3-87.8 87.8-87.8c.4 0 .7.1 1.1.1-.7-5.3-1.1-10.6-1.1-16.1 0-72.4 58.7-131 131-131 2.8 0 5.6.1 8.4.3.8-.2 1.6-.3 2.4-.3zM589 67.7a10.753 10.753 0 0110.796 10.51l.004.29c0 6-4.9 10.8-10.8 10.8h-31.6a10.753 10.753 0 01-10.796-10.51l-.004-.29c0-6 4.8-10.8 10.8-10.8H589zm.6-66.7a10.753 10.753 0 0110.796 10.51l.004.29c0 5.9-4.9 10.8-10.8 10.8H474.1a10.753 10.753 0 01-10.796-10.51l-.004-.29c0-6 4.8-10.8 10.8-10.8h115.5z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export default WeatherAtmosphereIcon;
