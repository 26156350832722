import { shallowEqual, useSelector, useDispatch } from "react-redux";
import {
	Redirect,
} from "react-router-dom";
import LogoWithCircle from "../../components/atoms/icons/logoWithCircle";
import Routes from "./routes";

import {
	hasUserFinishOnboarding,
	isGetUserLoading,
} from '../../store/collaborator/selectors';

// Components
import Loader from "../../components/atoms/lottie/Loader";

// Styles
import {
	Container,
	Wrapper,
	Header,
	LogoWithCircleWrapper,
	TitleCyconia,
} from "./style";

const Onboard = () => {
	const {
		isGetUserOnLoad,
		userHasYetFill,
  } = useSelector(
    state => ({
			isGetUserOnLoad: isGetUserLoading(state),
			userHasYetFill: hasUserFinishOnboarding(state),
    }),
    shallowEqual
	);

	if (userHasYetFill) {
    return <Redirect to="/landing" />;
  }

	if (isGetUserOnLoad) {
		return <Loader />
	}

	return (
		<Container>
			<Wrapper>
				<Header>
					<LogoWithCircleWrapper>                         
						<LogoWithCircle />                     
					</LogoWithCircleWrapper>
					<TitleCyconia>
						Cyconia
					</TitleCyconia>
				</Header>
				<Routes />
			</Wrapper>
		</Container>
	);
}

export default Onboard;
