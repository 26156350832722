import styled from "styled-components";
import ButtonCpt from '../../components/molecules/buttonCpt';
import {
  NavLink,
} from "react-router-dom";
import breakpoints from "../../../../theme-style/breakpoints";

export const LockIconWrapper = styled.span`
  position: absolute;
  right: 0;
  top: 41px;
  padding: 0px 20px 0px 0px;
  max-width: 14px;
  max-height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  svg path{
    transition: all .2s ease-out;
  }
`;

export const SmartphoneIconWrapper = styled.span`
  position: absolute;
  right: 0;
  top: 41px;
  padding: 0px 20px 0px 0px;
  max-width: 14px;
  max-height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  svg path{
    transition: all .2s ease-out;
  }
`;

export const ArrowIconWrapper = styled.span`
  max-width: 7px;
  max-height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  svg path{
    transition: all .2s ease-out;
  }
`;

export const ButtonCptMoreInfo = styled(ButtonCpt)`
  &&{
    height: 30px;
    padding: 8px 12px;
  }
`;

export const BorneLoginDiscoverButton = styled.div`
  margin: 0 auto;
`;

export const BorneLoginDiscoverText = styled.p`
  color: white;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin: 0 auto 25px auto;
`;

export const BorneLoginDiscoverTitle = styled.strong`
  color: white;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  margin: 0 auto 15px auto;
`;

export const BorneLoginDiscover = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin: 0 auto;
  padding: 25px 0 0 0;
`;

export const ButtonCptConnexion = styled(ButtonCpt)`
  &&{
    height: 60px;
    &.MuiButtonBase-root{
      .MuiButton-label{
        span{
          color: white;
          font-weight: 600;
          font-size: 13px;
          line-height: 10px;
          text-align: center;
        }
      }
    }
  }
`;

export const BorneLoginFormInput = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 10px;
`;

export const BorneLoginForm = styled.form`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin: 0 auto;
  padding: 50px 0 25px 0;
  box-sizing: border-box;
  width: 100%;
`;

export const BorneLoginTitle = styled.strong`
  color: white;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  margin: 0 auto;
  padding: 25px 0 0 0;
`;

export const BorneLoginContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  /* padding: 25px 0; */
  box-sizing: border-box;
`;


export const Title = styled.div`
  color: white;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin: 0;
  transition: all .2s ease-out;
`;

export const BackIconWrapper = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 16px;
  max-height: 12px;
  svg path{
    transition: all .2s ease-out;
  }
`;

export const BackButton = styled.div`
  margin: 0 30px 0 0;
`;

export const Header = styled(NavLink)`
  position: sticky;
  top: 0;
  background-color: #357ED3;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  text-decoration: none;
  padding: 15px 0;
  @media ${breakpoints.mobileXL} {
    padding: 25px 0;
  }
  &:hover{
    ${Title}{
      color: #efc31f;
    }
    ${BackIconWrapper}{
      svg{
        path{
          fill: #efc31f;
        }
      }
    }
  }
`;

export const BorneLoginWrapper = styled.div`

`;

export const Container = styled.div`
  /* position: absolute; */
  /* z-index: 888; */
  /* top: 0; */
  height: auto;
  min-height: 100vh;
  width: 100%;
  background-color: #357ED3;
  box-sizing: border-box;
  padding: 0 15px;

  @media ${breakpoints.mobileXL} {
    padding: 0 25px;
  }
`;
