import { shallowEqual, useSelector } from "react-redux";
import iconVideo from "../../../../assets/images/video.png";
import iconEnglishFlag from "../../../../assets/images/englishFlag.png";
import iconCalendar from "../../../../assets/images/calendar.png";

// Store
import { getCompanyData } from "../../../../store/company/selectors";

import {
  Container,
  MediaList,
  MediaListItem,
  Aelement,
  Image,
  Paragraphe,
} from "./style";

const VideoDoodleLink = ({ company }) => {
  const { videoUrl, calendarUrl } = company;
  const { companyInit } = useSelector(
    (state) => ({
      companyInit: getCompanyData(state),
    }),
    shallowEqual
  );

  const customLoginTopRightBox =
    companyInit && companyInit?.customLoginTopRightBox;

  return (
    <Container className="infoBlock">
      <MediaList>
        {company && videoUrl ? (
          <MediaListItem>
            <Aelement
              target="_blank"
              href={
                videoUrl === true
                  ? "https://www.youtube.com/watch?v=P5hk-aeqAG4"
                  : videoUrl
              }
            >
              <Image src={iconVideo} />
              <Paragraphe>En savoir plus sur Cyconia LAP</Paragraphe>
            </Aelement>
          </MediaListItem>
        ) : null}

        {customLoginTopRightBox &&
        customLoginTopRightBox?.title &&
        customLoginTopRightBox?.link ? (
          <MediaListItem>
            <Aelement target="_blank" href={customLoginTopRightBox?.link}>
              <Image src={customLoginTopRightBox?.image || iconEnglishFlag} />
              <Paragraphe>{customLoginTopRightBox?.title}</Paragraphe>
            </Aelement>
          </MediaListItem>
        ) : null}

        {calendarUrl ? (
          <MediaListItem>
            <Aelement
              target="_blank"
              href={
                calendarUrl === true
                  ? "https://doodle.com/poll/zb8hb26dawdck3hq"
                  : calendarUrl
              }
            >
              <Image src={iconCalendar} />
              <Paragraphe>Réserver une démo</Paragraphe>
            </Aelement>
          </MediaListItem>
        ) : null}
      </MediaList>
    </Container>
  );
};

export { VideoDoodleLink };
