import styled from "styled-components";
import IconButton from '@material-ui/core/IconButton';

import { COLORS } from '../../constants/design';

export const Container = styled.div`
  flex: 1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const TableButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TableIcon = styled(IconButton)`
  &&{
    height: fit-content;
    .MuiSvgIcon-root {
      font-size: 1.2rem;
    }
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
`;

export const WrapperList = styled.div`
  width: 30%;
  min-width: 300px;
  background-color: #fafafa;
`;

export const WrapperEdit = styled.div`
  width: 70%;
  background-color: #ffffff;
`;

export const TableWrapper = styled.div`
  padding-bottom: 20px;
  .genericTable {
    max-height: 75vh;
  }
`;

export const SettingContainer = styled.div`
  /* border: 1px solid black; */
  display: flex;
  position: relative;
  height: 50px;
`;

export const PaginationContainer = styled.div`
  font-size: 0.8rem;
  color: ${COLORS.DARK_GREY};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  margin: 0px 10px;
  /* margin: 10px 0px; */
  /* border: 1px solid black; */
  /* height: 50px; */
  width: 20%;
`;

export const StyledIconButton = styled(IconButton)`
  &&{
    height: fit-content;
    .MuiSvgIcon-root {
      color: ${COLORS.DARK_GREY};
      font-size: 1.2rem;
    }
    :hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
`;

export const HeaderButton = styled.button`
  background-color: ${COLORS.PRIMARY_BLUE};
  color: white;
  cursor: pointer;
  padding: 0px 20px;
  border-radius: 6px;
`

export const TableCellCustom = styled.span`
  display: inline-flex;
  justify-content: flex-start;
  font-size: 12px;
  line-height: 8px;
  font-weight: 500;
  color: #5A5A5A;
  text-align: left;
  padding: 9px 10px;
  max-height: 26px;
  background-color: #ededed;
  border-radius: 4px;
  transition: all .2s ease-out;
`;

export const ContentServicesListContainer = styled.div`
  overflow: hidden;
  white-space: nowrap;
  width: 150px;
  text-overflow: "…";
  cursor: pointer;
`;
