import React from "react";
import {
    Route,
    Redirect,
    withRouter
} from "react-router-dom";


// Redirect to dashboard if trying to access page while connected 
// For instance --- Login redirected to dashboard

export class RedirectOffline extends React.Component {
    constructor(){
        super();
        this.state = {
            isAppOnline : navigator.onLine
        }
    }
    render(){
        const { component: Component, ...rest } = this.props;
        return(
            <Route
                {...rest}
                render={props =>
                    !this.state.isAppOnline ? (
                        <Component {...props} />
                    ) : (
                            <Redirect
                                to={{
                                    pathname: "/landing",
                                    state: { from: props.location }
                                }}
                            />
                        )
                }
            />
        )
    }
}
