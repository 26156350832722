import endpointAPI from "../../../../helpers/api";
import { authHeader } from '../../../../helpers/auth-header';

const sendPositifFeed = (data) => {

  return new Promise((resolve, reject) => {
    let url = endpointAPI.positiveBotFeedback;
    let fdata = new FormData();

    fdata.append('source', data.source);
    fdata.append('companyResponseElementId', data.companyResponseElementId);
    fdata.append('responseStatus', 'ok');

    let reqOptions = {
      headers: authHeader(),
      method: "POST",
      body: fdata
    }

    return fetch(url, reqOptions)
      .then(response => {
        if (!response.ok) {
          return Promise.reject(response);
        }
        return response.json();
      })
      .then(data => {
        resolve(data)
      })
  })
};


export default sendPositifFeed;