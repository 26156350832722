import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import Checkbox from '@material-ui/core/Checkbox';
import {
  shallowEqual,
  useDispatch,
  useSelector,
} from "react-redux";
import _ from "lodash";

import { toaster } from '../../../../../../helpers/toaster';

// //Import Component
import GenericAdminFrontHeader from '../../../../../../components/molecules/GenericAdminFrontHeader';
import GenericMaterialMenu from "../../../../../../components/molecules/GenericMaterialMenu";

import ContextEdit from './ContextEdit';
import HelperEdit from './HelperEdit';

import {
  saveContexts
} from '../../../../../../store/contextSetting/actions';

import {
  getConciergeSitesData,
  getConciergeSitesIsLoading
} from '../../../../../../store/concierge/selectors';

import {
  getContextsData,
  getContextsIsLoading
} from '../../../../../../store/contextSetting/selectors';

import {
  NAV_BAR_KEYS,
  NAV_BAR_BLOCKS_LIST,
} from '../../../../../../constants/sideBarConstants';

import { HELPER_KEY } from '../../../../../../constants/helperConstants';

import {
  Container,
  DrawerCptCustom,
  Wrapper,
  StyledFormContainer,
  ListTitle,
} from './style';

const SiteEditDrawer = ({
  visible,
  closeFunction,
  edit
}) => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const [siteList, setSiteList] = useState([]);
  const [landingRoute, setLandingRoute] = useState(null);
  const [selectedSiteId, setSelectedSiteId] = useState(id);
  const [currentTemplateActive, setCurrentTemplateActive] = useState(false);
  const [navigationForm, setNavigationForm] = useState({});
  const [helperForm, setHelperForm] = useState({});
  const [listRef, setListRef] = useState(null);

  const {
    sitesData,
    sitesIsLoading,
    contextsData,
    contextsIsLoading
  } = useSelector(
    state => ({
      sitesData: getConciergeSitesData(state),
      sitesIsLoading: getConciergeSitesIsLoading(state),
      contextsData: getContextsData(state),
      contextsIsLoading: getContextsIsLoading(state)
    }),
    shallowEqual
  );

  useEffect(() => {
    if (listRef && contextsData) {
      if (!_.isEqual(listRef, contextsData)) closeFunction();
    }
    setListRef(contextsData);
  }, [contextsData]);

  useEffect(() => {
    const selectedContext = _.find(contextsData, (data) => String(data.siteId) === selectedSiteId);

    const newNavForm = {};
    _.map(NAV_BAR_BLOCKS_LIST, (data, navKey) => {
      const currentNavBar = selectedContext?.navBar?.[navKey];
      const newBlocks = {};

      _.map(data, (blockKey) => {
        const currentBlock = currentNavBar?.blocks?.[blockKey];
        newBlocks[blockKey] = {
          active: currentBlock ? currentBlock.active : false,
        }
      });

      newNavForm[navKey] = {
        active: currentNavBar ? currentNavBar.active : true,
        blocks: newBlocks
      };
    });

    const newHelperForm = {};
    _.map(HELPER_KEY, (data, helperKey) => {
      newHelperForm[helperKey] = selectedContext?.helper?.[helperKey] || '';
    })

    setLandingRoute(selectedContext?.landingRoute || null);
    setCurrentTemplateActive(selectedContext ? selectedContext.active : true);
    setNavigationForm(newNavForm);
    setHelperForm(newHelperForm);
  }, [contextsData]);

  useEffect(() => {
    if (sitesData) {
      const newSiteList = [{
        value: '-- select site --',
        label: '-- select site --'
      }];

      const defaultTemplate = _.find(contextsData, (template) => String(template.siteId) === 'default');
      if (!defaultTemplate) {
        newSiteList.push({
          value: 'default',
          label: 'Default'
        })
      }

      _.map(sitesData, (site) => {
        const selectedTemplate = _.find(contextsData, (template) => String(site.id) === String(template.siteId));
        if (!selectedTemplate) {
          newSiteList.push({
            value: String(site.id),
            label: site.name
          })
        }
      });

      setSiteList(newSiteList);
    }
  }, [sitesData]);

  const handleSelectSite = (value) => {
    if (value !== '-- select site --') {
      setSelectedSiteId(value);
    }
    else {
      setSelectedSiteId(null);
    }
  };

  const handleLandingRoute = (value) => {
    setLandingRoute(value);
  }

  const handleSelectAll = (key, value) => {
    const newNavForm = _.cloneDeep(navigationForm);
    _.map(newNavForm, (nav, navKey) => {
      if (key === 'all' || key === navKey) {
        _.map(nav.blocks, (block) => {
          block.active = value;
        });
      }
    });
    setNavigationForm(newNavForm);
  }

  const handleCheckBox = (event, navKey, blockKey) => {
    const newNavForm = _.cloneDeep(navigationForm);
    newNavForm[navKey].blocks[blockKey].active = event.target.checked;
    setNavigationForm(newNavForm);
  }

  const handleCheckMainBar = (event, key) => {
    if (key === NAV_BAR_KEYS.CONSOLE) {
      return;
    }
    const newNavForm = _.cloneDeep(navigationForm);
    newNavForm[key].active = event.target.checked;
    setNavigationForm(newNavForm);
  }

  const handleCurrentTemplateActiveChange = (event) => {
    setCurrentTemplateActive(event.target.checked)
  }

  const handleHelperFormChange = (event) => {
    if (event) {
      const { name, value } = event.target;
      const newHelperForm = _.cloneDeep(helperForm);

      newHelperForm[name] = value;
      setHelperForm(newHelperForm);
    }
  }

  const handleSubmit = () => {
    if (!navigationForm[NAV_BAR_KEYS.DASHBOARD].active && landingRoute === null) {
      toaster(`Tableau de bord is not active and it cannot be selected as landing route, please select another landing route`, 'error');
      return;
    }
    const newTemplate = {
      version: 1,
      siteId: selectedSiteId,
      active: currentTemplateActive,
      landingRoute: landingRoute,
      navBar: navigationForm,
      helper: helperForm
    }
    const newTemplateArray = _.orderBy(_.unionBy([newTemplate], contextsData, "siteId"), ['siteId']);
    const lastElement = _.last(newTemplateArray);
    newTemplateArray.unshift(lastElement);
    newTemplateArray.pop();
    dispatch(saveContexts({ formData: newTemplateArray }));
  }

  const renderForm = () => {
    if (selectedSiteId) {
      const selectedSite = _.find(sitesData, (site) => String(site.id) === selectedSiteId);

      return (
        <>
          <ListTitle>
            {`Configurations: ${selectedSite?.name || selectedSiteId}`}
            <Checkbox
              checked={currentTemplateActive}
              onChange={(event) => handleCurrentTemplateActiveChange(event)}
            />
          </ListTitle>
          <ContextEdit
            navigationForm={navigationForm}
            currentTemplateActive={currentTemplateActive}
            landingRoute={landingRoute}
            handleSelectAll={handleSelectAll}
            handleLandingRoute={handleLandingRoute}
            handleCheckMainBar={handleCheckMainBar}
            handleCheckBox={handleCheckBox}
          />
          <HelperEdit
            helperForm={helperForm}
            currentTemplateActive={currentTemplateActive}
            handleHelperFormChange={handleHelperFormChange}
          />
        </>
      )
    }
  }

  return (
    <Container>
      <DrawerCptCustom
        onOpen={visible}
        visible={visible}
        closeFunction={() => closeFunction()}
        className="siteEditDrawer"
      >
        <Wrapper>
          <GenericAdminFrontHeader headerTitle={`${edit ? 'Edit' : 'Add'} context`} />
          <StyledFormContainer
            isLoading={sitesIsLoading || contextsIsLoading}
            onClose={closeFunction}
            onSubmit={handleSubmit}
          >
            <>
              {!edit &&
                <GenericMaterialMenu
                  title={'Select site'}
                  list={siteList}
                  onChange={handleSelectSite}
                />
              }
              {renderForm()}
            </>
          </StyledFormContainer>
        </Wrapper>
      </DrawerCptCustom>
    </Container>
  )
}

export default SiteEditDrawer;