import React, { useState, useEffect } from 'react';
import { Formik, Field } from 'formik';
import * as Yup from "yup";
import {
  shallowEqual,
  useDispatch,
  useSelector,
} from "react-redux";
import _ from 'lodash';

import GenericDialogContainer from '../../../../../../../components/molecules/GenericDialogContainer';
import GenericTextField from '../../../../../../../components/molecules/GenericTextField';
import GenericMaterialMenu from '../../../../../../../components/molecules/GenericMaterialMenu';
import GenericErrorMessage from '../../../../../../../components/molecules/GenericErrorMessage';
import Loader from '../../../../../../../components/molecules/GenericLoader';

import {
  createCollaborator,
} from '../../../../../../../store/authentication/actions';

import {
  isCreateCollaboratorSuccess,
  isCreateCollaboratorLoading,
} from '../../../../../../../store/authentication/selectors';

import {
  CustomDialog,
  StyledTitle,
  StyledForm,
  FieldWithErrorMessage,
  StyledValidationButtons,
  LoadingContainer
} from './style';

const CreateUserModal = ({
  sitesData,
  onClose
}) => {
  const dispatch = useDispatch();
  const [siteList, setSiteList] = useState([]);

  const {
    createCollaboratorIsSuccess,
    createCollaboratorIsLoading
  } = useSelector(
    state => ({
      createCollaboratorIsSuccess: isCreateCollaboratorSuccess(state),
      createCollaboratorIsLoading: isCreateCollaboratorLoading(state),
    }),
    shallowEqual
  );

  useEffect(() => {
    if (createCollaboratorIsSuccess) {
      onClose();
    }
  }, [createCollaboratorIsSuccess]);

  useEffect(() => {
    if (sitesData) {
      const newList = [{
        value: null,
        label: '-- Sélectionner une site de entreprise ---'
      }]
      for (const site of sitesData) {
        newList.push({
          value: site.id,
          label: site.name
        });
      }
      setSiteList(newList);
    }
  }, [sitesData]);

  const generateInitValue = () => {
    const initValue = {
      email: '',
      firstname: '',
      lastname: '',
      companySiteId: '',
    }
    return initValue;
  }

  const newContentActuSchema = Yup.object().shape({
    email: Yup.string()
      .required('Un email est requis'),
    firstname: Yup.string()
      .required('Un prénom est requis'),
    lastname: Yup.string()
      .required('Un nom est requis'),
    companySiteId: Yup.mixed()
      .required('Un site de entreprise est requis'),
  });

  const handleSubmit = (values) => {
    // console.log('values', values);
    dispatch(createCollaborator(values));
  }

  return (
    <CustomDialog
      open={true}
      onClose={onClose}
    >
      <GenericDialogContainer onClose={onClose}>
        <StyledTitle>
          {`Créer un collaborateur`}
        </StyledTitle>
        <Formik
          initialValues={generateInitValue()}
          setFieldValue
          validationSchema={newContentActuSchema}
          onSubmit={handleSubmit}
        >
          {(props) => {
            if (createCollaboratorIsLoading) {
              return (
                <LoadingContainer>
                  <Loader />
                </LoadingContainer>
              );
            }
            else {
              return (
                <StyledForm>
                  <Field
                    id="email"
                    name="email"
                    title="E-mail"
                    variant="outlined"
                    size="small"
                    as={GenericTextField}
                  />
                  <Field
                    id="firstname"
                    name="firstname"
                    title="Prénom"
                    variant="outlined"
                    size="small"
                    as={GenericTextField}
                  />
                  <Field
                    id="lastname"
                    name="lastname"
                    title="Nom"
                    variant="outlined"
                    size="small"
                    as={GenericTextField}
                  />
                  <FieldWithErrorMessage>
                    <GenericMaterialMenu
                      title={"Site de entreprise"}
                      list={siteList}
                      initialValue={props.values.companySiteId}
                      onChange={(value) => props.setFieldValue('companySiteId', value)}
                    />
                    <GenericErrorMessage name={'companySiteId'} />
                  </FieldWithErrorMessage>
                  <StyledValidationButtons
                    leftButonText={'Annuler'}
                    RightButtonText={'Confirmer'}
                    onclickLefttButton={onClose}
                    submitable={true}
                  />
                </StyledForm>
              )
            }
          }}
        </Formik>
      </GenericDialogContainer>
    </CustomDialog>
  );
};

export default CreateUserModal;