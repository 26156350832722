import { call, put } from 'redux-saga/effects';
import endpoint from '../../../helpers/api';
import axios from 'axios';
import _ from 'lodash';
import JwtDecode from "jwt-decode";

import {
  samlLoginSuccess,
  samlLoginError,
  samlRegisterationSuccess,
  samlRegisterationError,
  azureadLoginSuccess,
  azureadLoginError,
  azureadRegisterationSuccess,
  azureadRegisterationError,
} from '../actions';

import {
  loginSuccess
} from '../../authentication/actions';

import * as collaboratorActions from '../../collaborator/actions';

// for samlLogin, we only need to save the jwtToken to the store. no need to call the backend api
export function* samlLoginHandler(action) {
  const formData = action.data;
  try {
    // eslint-disable-next-line no-unused-vars
    const decoded = JwtDecode(formData.jwtToken); // Test if token if valid JwtToken, else catch will throw error
    yield put(loginSuccess(formData.jwtToken));
    yield call([localStorage, 'setItem'], 'id_token', JSON.stringify(formData.jwtToken));
    // yield put(collaboratorActions.getUser());
    yield put(samlLoginSuccess(formData.jwtToken));
  } catch (error) {
    yield put(samlLoginError({ message: error.message }));
  }
}

export const samlRegisterationService = async (endpoint, formData) => {
  const newForm = new FormData();

  _.map(formData, (data, key) => {
    newForm.append(key, data);
  });

  return axios.request({
    method: 'post',
    url: endpoint,
    data: newForm,
  });
}

export function* samlRegisterationHandler(action) {
  const formData = action.data;
  try {
    const response = yield call(samlRegisterationService, endpoint.samlRegisterationApi, formData);
    const { message, code } = response.data;
    if (code === 200 || code === 201) {
      yield put(loginSuccess(message));
      yield call([localStorage, 'setItem'], 'id_token', JSON.stringify(message));
      // yield put(collaboratorActions.getUser());
      yield put(samlRegisterationSuccess(message));
    }
    else {
      yield put(samlRegisterationError({ code, message }));
    }
  } catch (error) {
    yield put(samlRegisterationError(error.response.data));
  }
}

// AzureAd
// for azureadLogin, we only need to save the jwtToken to the store. no need to call the backend api
export function* azureadLoginHandler(action) {
  const formData = action.data;
  try {
    // eslint-disable-next-line no-unused-vars
    const decoded = JwtDecode(formData.jwtToken); // Test if token if valid JwtToken, else catch will throw error
    yield put(loginSuccess(formData.jwtToken));
    yield call([localStorage, 'setItem'], 'id_token', JSON.stringify(formData.jwtToken));
    // yield put(collaboratorActions.getUser());
    yield put(azureadLoginSuccess(formData.jwtToken));
  } catch (error) {
    yield put(azureadLoginError({ message: error.message }));
  }
}

export const azureadRegisterationService = async (endpoint, formData) => {
  const newForm = new FormData();

  _.map(formData, (data, key) => {
    newForm.append(key, data);
  });

  return axios.request({
    method: 'post',
    url: endpoint,
    data: newForm,
  });
}

export function* azureadRegisterationHandler(action) {
  const formData = action.data;
  try {
    const response = yield call(azureadRegisterationService, endpoint.azureadRegisterationApi, formData);
    const { message, code } = response.data;
    if (code === 200 || code === 201) {
      yield put(loginSuccess(message));
      yield call([localStorage, 'setItem'], 'id_token', JSON.stringify(message));
      // yield put(collaboratorActions.getUser());
      yield put(azureadRegisterationSuccess(message));
    }
    else {
      yield put(azureadRegisterationError({ code, message }));
    }
  } catch (error) {
    yield put(azureadRegisterationError(error.response.data));
  }
}