export const PARAM_EXPLAINATION = [
  `{userId} = Id de l'utilisateur`,
  `{userEmail} = Email de l'utilisateur`,
  `{userFirstName} = Prénom de l'utilisateur`,
  `{userLastName} = Nom de famille de l'utilisateur`,
  `{userCompanyName} = Nom de l'entreprise de l'utilisateur`,
  `{userCompanyId} = Id l'entreprise de l'utilisateur`,
  `{userSiteName} = Nom du site d’entreprise auquel l’utilisateur appartient`,
  `{userSiteId} = Id du site d’entreprise auquel l’utilisateur appartient`,
  `{companyUrl} = Url de l'entreprise`
];

export const URL_PRESET_TYPE = {
  NONE: 'none',
  JOTFORM: 'jotform',
}

export const JOT_FORM_PRESET = [
  {
    variable: "system_companyUrl",
    value: "{companyUrl}",
  },
  {
    variable: "system_userId",
    value: "{userId}",
  },
  {
    variable: "system_companySiteId",
    value: "{userSiteId}",
  },
  {
    variable: "system_companyEnvironmentId",
    value: "{userCompanyId}",
  },
  {
    variable: "name[first]",
    value: "{userFirstName}",
  },
  {
    variable: "name[last]",
    value: "{userLastName}",
  },
  {
    variable: "email",
    value: "{userEmail}"
  }
]
