import React from 'react';
import { format } from 'date-fns';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import fr from 'date-fns/locale/fr';
import _ from 'lodash';

import { COLORS } from '../../../../../../../../../../constants/design'

import {
  MainContainer,
  StyledTabItem,
  StyledDateBlock,
  StyledDateOnly,
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionTitle,
  StyledPaymentStatusContainer,
  StyledPaymentStatusIndicator,
  StyledPaymentDetail,
  StyledPaymentDetailItem,
  StyledDateInfo,
  StyledDateText,
  StyledDateTitle,
} from './style';


// TODO scroll to todays date

const TicketPaymentTab = ({ paymentObject }) => {

  const getPaymentStatus = (payment) => {
    const paymentStatus = {color: '', text: ''};
    if (payment.status ===  'validated') {
      paymentStatus.color = COLORS.GREEN;
      paymentStatus.text = 'Validé';
    } else if (payment.status ===  'on_going') {
      const expireDate = format(new Date(payment.dateExpired), "dd MMM yyyy", { locale: fr });
      paymentStatus.color = COLORS.ORANGE_1;
      paymentStatus.text = `En attente - expore le ${expireDate}`;
    } else if (payment.status ===  'expired') {
      paymentStatus.color = COLORS.RED;
      paymentStatus.text = 'Expiré';
    } else if (payment.status ===  'cancelled') {
      paymentStatus.color = COLORS.RED;
      paymentStatus.text = 'Abandonné';
    }
    return (
      <StyledPaymentStatusContainer>
        <StyledPaymentStatusIndicator color={paymentStatus.color}/>
        {paymentStatus.text}
      </StyledPaymentStatusContainer>
    )
  }

  const getPaymentDetail = (payment) => {
    const paymentDetail = [`- Lien de paiement créé le ${format(new Date(payment.dateCreated), "dd MMMM yyyy", { locale: fr })}`];
    if (payment.status ===  'validated') {
      paymentDetail.push(`- Paiement validé le ${format(new Date(payment.dateValidated), "dd MMMM yyyy", { locale: fr })}`)
    } else if (payment.status ===  'on_going') {
    } else if (payment.status ===  'expired') {
    } else if (payment.status ===  'cancelled') {
    }
    return (
      <StyledPaymentDetail>
        {_.map(paymentDetail, (detail, index) => {
          return (
            <StyledPaymentDetailItem key={index}>{detail}</StyledPaymentDetailItem>
          )
        })}
      </StyledPaymentDetail>
    )
  }


  return (
    <MainContainer>
      {_.map(paymentObject, (payment, index) => {
        const date = new Date(payment.dateCreated);
        // const dateString = format(date, "EEEE dd MMMM yyyy", { locale: fr });
        const dateOnly = format(date, "dd", { locale: fr });
        const monthOnly = format(date, "MMM", { locale: fr });
        return (
          <StyledTabItem key={index}>
            <StyledDateBlock>
              <StyledDateOnly>{dateOnly}</StyledDateOnly>
              {monthOnly}
            </StyledDateBlock>
            <StyledAccordion>
              <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                <StyledAccordionTitle>{`Paiement Stripe N°${payment.id}`}</StyledAccordionTitle>
                {getPaymentStatus(payment)}
              </StyledAccordionSummary>
              <AccordionDetails>
                {getPaymentDetail(payment)}
                {/* <StyledUserInfoContainer>
                  <GenericTitleWithIcon title={'john.doe@johndoe.fr'} Icon={<EmailIcon style={{ fontSize: '1rem' }} />}></GenericTitleWithIcon>
                  <GenericTitleWithIcon title={'0606060606'} Icon={<PhoneIcon style={{ fontSize: '1rem' }} />}></GenericTitleWithIcon>
                  <GenericTitleWithIcon title={'12 place due marché, 91290 - Arpajon'} Icon={<HomeIcon style={{ fontSize: '1rem' }} />}></GenericTitleWithIcon>
                  <GenericTitleWithIcon title={'27 rue du chemin vert, 75011 - Paris'} Icon={<LocationCityIcon style={{ fontSize: '1rem' }} />}></GenericTitleWithIcon>
                </StyledUserInfoContainer> */}
              </AccordionDetails>
            </StyledAccordion>
          </StyledTabItem>
        )
      })}
    </MainContainer>
  )
}

export default TicketPaymentTab;