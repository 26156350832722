import React, { useState, useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

// Layout
import DetailsSidebarFrontBaseLayout from "../../layouts/detailsSidebarFrontBaseLayout";

// Components
// import ButtonCpt from '../../components/molecules/buttonCpt';
import BackIcon from '../../components/atoms/icons/back';
import InputField from '../../components/molecules/inputField';
import ArrowRightIcon from '../../components/atoms/icons/arrowRight';
import SmartphoneIcon from '../../components/atoms/icons/smartphone';
import LockIcon from '../../components/atoms/icons/lock';
import DrawerBorneInfo from '../../components/organisms/drawerBorneInfo';

// Store
import {
  loginExpressing,
} from '../../../../store/sidebar/actions';
import {
  isExpressingLoginOnError,
  isExpressingLoginOnSuccess,
} from '../../../../store/sidebar/selectors';
import {
  getUserExpressingId,
} from '../../../../store/collaborator/selectors';

// Hooks
import useTheme from "../../../../theme-style/useThemeCustomHook";

// Styles
import {
  Container,
  BorneLoginWrapper,
  Header,
  BackButton,
  Title,
  BackIconWrapper,
  BorneLoginContent,
  BorneLoginTitle,
  BorneLoginForm,
  BorneLoginFormInput,
  ButtonCptConnexion,
  BorneLoginDiscover,
  BorneLoginDiscoverTitle,
  BorneLoginDiscoverText,
  BorneLoginDiscoverButton,
  ButtonCptMoreInfo,
  ArrowIconWrapper,
  SmartphoneIconWrapper,
  LockIconWrapper,
} from "./style";

const ConciergerieBorneLogin = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const {
    loginOnError,
    loginOnSuccess,
    userExpressingId,
  } = useSelector(
    state => ({
      loginOnError: isExpressingLoginOnError(state),
      loginOnSuccess: isExpressingLoginOnSuccess(state),
      userExpressingId: getUserExpressingId(state),
    }),
    shallowEqual);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [pinCode, setPinCode] = useState('');

  const onInputChange = e => {
    const { target: { name, value } } = e;
    if (name === 'phoneNumber') {
      setPhoneNumber(value);
    } else
    if (name === 'pinCode') {
      setPinCode(value);
    }
  };

  useEffect(() => {
    if (loginOnSuccess) {
      toast.success("Votre compte est désormais synchronisé avec Cyconia.", {
        position: toast.POSITION.TOP_RIGHT,
        className: "custom-toast custom-toast-success",
        bodyClassName: "custom-toast__body custom-toast__body-success",
        progressClassName: "custom-toast__progress"
    });
    }
    if (userExpressingId) {
      setTimeout(() => {
        history.push('/conciergerie/borne/ma-borne');
      }, 200);
    }
  }, [userExpressingId, loginOnSuccess]);

  const onSubmit = e => {
    if (e) e.preventDefault();
    dispatch(loginExpressing(phoneNumber, pinCode));
  };

  useEffect(() => {
    if (loginOnError) {
      toast.error("Le numéro de téléphone ou le code confidentiel indiqué n’est pas valide.", {
        position: toast.POSITION.TOP_RIGHT,
        className: "custom-toast custom-toast-error",
        bodyClassName: "custom-toast__body custom-toast__body-success",
        progressClassName: "custom-toast__progress"
    });
    }
  }, [loginOnError]);

  return (
    <DetailsSidebarFrontBaseLayout
      id="detailsSidebarLayoutBorneLogin"
      className="detailsSidebarLayoutBorneLogin page-container sidebarPage"
    >
      <Container>
        <BorneLoginWrapper>
          <Header to="/conciergerie">
            <BackButton>
              <BackIconWrapper>
                <BackIcon color={theme.colors.white}/>
              </BackIconWrapper>
            </BackButton>
            <Title>
              Connexion à mon compte Borne
            </Title>
          </Header>

          <BorneLoginContent>
            <BorneLoginTitle>
              Connexion à votre compte Borne <br /> pour vos suivis de commandes
            </BorneLoginTitle>

            <BorneLoginForm noValidate autoComplete="off" onSubmit={onSubmit}>
              <BorneLoginFormInput>
                <InputField
                  value={phoneNumber}
                  name="phoneNumber"
                  onChange={onInputChange}
                  type="phone"
                  label="Numéro de téléphone"
                  placeholder="Saisissez votre numéro de téléphone"
                  endAdornment={
                    <SmartphoneIconWrapper>
                      <SmartphoneIcon />
                    </SmartphoneIconWrapper>
                  }
                />
                <InputField
                  value={pinCode}
                  name="pinCode"
                  onChange={onInputChange}
                  type="password"
                  label="Code borne"
                  placeholder="Saisissez votre code borne"
                  endAdornment={
                    <LockIconWrapper>
                      <LockIcon />
                    </LockIconWrapper>
                  }
                />
              </BorneLoginFormInput>
              <ButtonCptConnexion
                label="Me connecter"
                appearence="sidebarFrontPrimary"
                type="submit"
              />
            </BorneLoginForm>

            <BorneLoginDiscover>
              <BorneLoginDiscoverTitle>
                Pas encore de compte Borne ?
              </BorneLoginDiscoverTitle>
              <BorneLoginDiscoverText>
                Rendez-vous à la borne Express’ing de votre <br /> entreprise pour créer votre compte.
              </BorneLoginDiscoverText>
              <BorneLoginDiscoverButton>
                <ButtonCptMoreInfo
                  type="button"
                  label="En savoir plus"
                  appearence="sidebarFrontConciergerie"
                  onClick={() => setDrawerOpen(true)}
                  endIcon={
                    <ArrowIconWrapper>
                      <ArrowRightIcon />
                    </ArrowIconWrapper>
                  }
                />
              </BorneLoginDiscoverButton>
            </BorneLoginDiscover>
          </BorneLoginContent>
        </BorneLoginWrapper>

        <DrawerBorneInfo
          visible={drawerOpen}
          closeFunction={() => setDrawerOpen(false)}
        />
      </Container>
    </DetailsSidebarFrontBaseLayout>
  );
}

export default ConciergerieBorneLogin;
