import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field, useFormikContext } from 'formik';
import * as Yup from "yup";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { makeStyles } from '@material-ui/core/styles';

import _ from 'lodash';

//Import components
import GenericTitleWithToolTip from '../../../../../../components/molecules/GenericTitleWithToolTip';
import GenericAdminFrontHeader from '../../../../../../components/molecules/GenericAdminFrontHeader';
import GenericTextField from '../../../../../../components/molecules/GenericTextField'
import GenericTextArea from '../../../../../../components/molecules/GenericTextArea';
import GenericDrawerValidationButtons from '../../../../../../components/molecules/GenericDrawerValidationButtons';
import GenericLoader from '../../../../../../components/molecules/GenericLoader';
import helperTextExample from '../../../../../../assets/images/helperTextExample.gif';
import GenericWarningModal from '../../../../../../components/molecules/GenericWarningModal';
import { editConcierge } from '../../../../../../store/concierge/actions';
import {
  getConciergeSitesData,
  getConciergeConciergesData,
  getConciergeConciergesLoading,
} from '../../../../../../store/concierge/selectors';


//Import style
import {
  Container,
  DrawerCptCustom,
  Wrapper,
  DrawerWrapper,
  FormContainer,
  FormSection,
  ImagePreviewContainer,
  SitesSelectorBox,
  StyledGenericMaterialMenu
} from "./style"

const useStyles = makeStyles((theme) => ({
  noMaxWidth: {
    maxWidth: 'none',
  },
}));

const ConciergeEditDrawer = ({ visible, closeFunction }) => {
  const classes = useStyles();
  const [siteInfo, setSiteInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedConcierge, setSelectedConcierge] = useState(null);
  const [isDirty, setIsDirty] = useState(false);
  const [openDelete, setopenDelete] = useState(false);
  const [listRef, setListRef] = useState(null);
  const [updatedSitesList, setUpdatedSiteList] = useState([]);
  const [selectedSite, setSelectedSite] = useState(null);
  const { id, siteId } = useParams();
  const dispatch = useDispatch();

  const {
    sitesData,
    conciergesData,
    conciergesIsLoading
  } = useSelector(
    state => ({
      sitesData: getConciergeSitesData(state),
      conciergesData: getConciergeConciergesData(state),
      conciergesIsLoading: getConciergeConciergesLoading(state)
    }),
    shallowEqual
  );

  useEffect(() => {
    if (sitesData, conciergesData) {
      const newList = _.compact(sitesData.map((site) => {
        const value = conciergesData.find((item) => item.company_site.id === site.id)
        if (value) return { label: site.name, value: site.id }
      }))

      newList.length > 0 ?
        setUpdatedSiteList([{ label: "Appliquer une fiche existante  de site ?", value: null }, ...newList])
        :
        setUpdatedSiteList([{ label: "Pas de fiche de site existante pour le moment !", value: null }])
    }
  }, [sitesData])

  useEffect(() => {
    if (listRef !== null) {
      const diff = _.xor(listRef, conciergesData);
      if (diff.length !== 0) closeFunction();
    }
    setListRef(conciergesData);
    if (sitesData && conciergesData) {
      const concierge = conciergesData.find(item => String(item.id) === id);
      const site = _.find(sitesData, (data) => String(data.id) === siteId);
      setSiteInfo(site);
      setSelectedConcierge(concierge);
    }
  }, [sitesData, conciergesData])


  useEffect(() => {
    if (selectedSite != null) {
      const value = conciergesData.find((item) => item.company_site.id === selectedSite)
      if (value) {
        setSelectedConcierge(value)
      }
    }
  }, [selectedSite])

  useEffect(() => {
    if (isLoading && siteInfo && updatedSitesList) {
      setIsLoading(false);
    }
  }, [siteInfo, updatedSitesList])

  const formInitValue =
  {
    name: selectedConcierge?.name || "",
    presentation: selectedConcierge?.presentation || "",
    email: selectedConcierge?.mail || "",
    phoneNumber: selectedConcierge?.phone || "",
    service1: selectedConcierge?.service_highlighted || "",
    service2: selectedConcierge?.service_highlighted2 || "",
    service3: selectedConcierge?.service_highlighted3 || "",
    site: siteId
  }

  const EditSchema = Yup.object().shape({
    name: Yup.string()
      .required('Un responsable est requis'),
    presentation: Yup.string()
      .required('Une presentation est requise'),
    email: Yup.string()
      .email()
      .required('Un email est requis'),
    phoneNumber: Yup.number()
      .typeError("Un numéro valide est requis")
      .positive("Les nombres doivent être positifs")
      .integer("Un numéro ne peut inclure que des chiffres")
      .min(10)
      .required('Un numéro est requis'),
  })

  const handleClose = () => {
    if (isDirty) setopenDelete(true)
    else closeFunction()
  }

  const validateForm = (values) => {
    dispatch(editConcierge({ id: id, formData: values }));
  }

  const CheckDirty = () => {
    const { dirty } = useFormikContext();

    useEffect(() => {
      setIsDirty(dirty)
    }, [dirty]);
    return null;
  };

  return (
    <Container>
      <DrawerCptCustom
        onOpen={visible}
        visible={visible}
        closeFunction={handleClose}
        className="conciergeEditDrawer" >
        <DrawerWrapper>

          <Formik
            initialValues={formInitValue}
            enableReinitialize
            setFieldValue
            validationSchema={EditSchema}
            onSubmit={(values) => validateForm(values)}
          >
            {(props) => {
              return (
                <Wrapper>
                  <GenericAdminFrontHeader
                    headerTitle={`Fiche du site - ${siteInfo?.name || '' }`}
                    headerDesc={'Présentez la conciergerie et sélectionnez les services à mettre en avant'}
                  />
                  {(isLoading || conciergesIsLoading) &&
                    <GenericLoader />
                  }
                  {!isLoading && !conciergesIsLoading &&
                    <Form>
                      <CheckDirty />
                      <FormContainer>
                        <FormSection>
                          <GenericTitleWithToolTip
                            title="Votre conciergerie sur site"
                          />
                          <span>Donnez à vos bénéficiaires les informations générales concernant votre conciergerie</span>
                        </FormSection>
                        <SitesSelectorBox  >
                          <StyledGenericMaterialMenu
                            title={"Site"}
                            list={updatedSitesList}
                            onChange={(value) => setSelectedSite(value)}
                          />
                        </SitesSelectorBox  >
                        <Field
                          id="name"
                          name="name"
                          title="Nom de la conciergerie"
                          placeholder="Votre conciergerie entreprise"
                          variant="outlined"
                          size="small"
                          isRequired={true}
                          as={GenericTextField}
                        />
                        <Field
                          id="presentation"
                          name="presentation"
                          title="Présentation générale"
                          placeholder="Votre conciergerie vous permet de bénéficier des services de…."
                          toolTipText="La présentation générale permet de donner aux bénéficiaires les informations générales concernant votre conciergerie : les prestations que vous offrez ou encore votre valeur ajoutée"
                          variant="outlined"
                          minRows={4}
                          isRequired={true}
                          as={GenericTextArea}
                        />
                        <Field
                          id="email"
                          name="email"
                          title="Mail"
                          type='email'
                          toolTipText='Il s’agit du mail contact sur lequel les bénéficiaires peuvent vous contacter'
                          placeholder="ex: nicholas.cage@cyconia.io"
                          variant="outlined"
                          size="small"
                          isRequired={true}
                          as={GenericTextField}
                        />
                        <Field
                          id="phoneNumber"
                          name="phoneNumber"
                          title="Téléphone"
                          placeholder="ex: 06 00 00 00 00"
                          variant="outlined"
                          size="small"
                          isRequired={true}
                          as={GenericTextField}
                        />
                        <div style={{ margin: '10px 0px' }}></div>
                        <FormSection>
                          <GenericTitleWithToolTip
                            title="Les services mis en avant"
                            ToolTipIcon={VisibilityIcon}
                            toolTipProps={{ classes: { tooltip: classes.noMaxWidth } }}
                            toolTipText={<ImagePreviewContainer image={helperTextExample} />}
                          />
                          <span>Indiquez ci-dessous les services à mettre en avant dans le chatbot</span>
                        </FormSection>
                        <Field
                          id="service1"
                          name="service1"
                          title="Service nº1 mis en avant sur le chatbot"
                          placeholder="Comment bénéficier du pressing à domicile"
                          variant="outlined"
                          size="small"
                          as={GenericTextField}
                        />
                        <Field
                          id="service2"
                          name="service2"
                          title="Service nº2 mis en avant sur le chatbot"
                          placeholder="Je souhaite faire laver ma voiture"
                          variant="outlined"
                          size="small"
                          as={GenericTextField}
                        />
                        <Field
                          id="service3"
                          name="service3"
                          title="Service nº3 mis en avant sur le chatbot"
                          placeholder="Fais-moi livrer un panier bio"
                          variant="outlined"
                          size="small"
                          as={GenericTextField}
                        />

                        <GenericDrawerValidationButtons
                          leftButonText={'Annuler'}
                          RightButtonText={'Sauvegarder'}
                          onclickLefttButton={handleClose}
                          submitable={true}
                        />
                      </FormContainer>
                    </Form>
                  }
                </Wrapper>
              )
            }}
          </Formik>
        </DrawerWrapper>
      </DrawerCptCustom>
      <GenericWarningModal
        open={openDelete}
        setOpenDialog={() => setopenDelete(!openDelete)}
        title={"Attention"}
        text={"Êtes-vous sur de vouloir annuler votre saisie?"}
        leftButtonText={"Non"}
        rightButtonText={"Oui"}
        leftButtonFunction={() => setopenDelete(!openDelete)}
        rightButtonFunction={closeFunction}
      />
    </Container>
  )
}

export default ConciergeEditDrawer