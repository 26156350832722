import React, { useEffect, useState } from 'react';

import GenericTextField from '../GenericTextField';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@material-ui/icons/Cancel';

import {
  MainContainer,
  StyledActionContainer,
  StyledButton,
  StyledIcon
} from './style'

const GenericSearchField = ({
  className, // to pass styled component className to mainContainer
  onSearchConfirm,
  onReset,
  value,
  ...rest    // the rest of the props will be given to component
}) => {
  const [searchText, setSeachText] = useState("");

  useEffect(() => {
    setSeachText(value);
  }, [value]);

  const handleClick = () => {
    onSearchConfirm(searchText);
  }

  const handleReset = () => {
    setSeachText("");
    onSearchConfirm("");
  }

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      onSearchConfirm(searchText);
    }
  }

  return (
    <MainContainer className={`${className ? className : ''} genericSearchField`}>
      <GenericTextField
        {...rest}
        onChange={(event) => setSeachText(event.target.value)}
        value={searchText}
        onKeyDown={keyPress}
        updateOnBlur={false}
        InputProps={{
          endAdornment:
            <StyledActionContainer>
              <StyledIcon onClick={handleReset}>
                <CancelIcon />
              </StyledIcon>
              <StyledButton
                startIcon={<SearchIcon />}
                onClick={handleClick}
              />
            </StyledActionContainer>
        }}
      />
    </MainContainer>

  )
};

export default GenericSearchField;