import React from "react";

const ChatCommunicationIllustration = ({
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon chatCommunicationIllustration"
      width={width}
      height={height}
      viewBox="0 0 142 110"
      fill="none"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
          <g
            id="chat"
            transform="translate(71.000000, 55.000000) scale(-1, 1) translate(-71.000000, -55.000000) "
            fillRule="nonzero"
          >
              <rect id="Rectangle" fill="#9CC4F3" x="81.0561006" y="0.0357232704" width="47.7173585" height="55.8577987"></rect>
              <path d="M82.775283,60.8545912 L72.5941509,71.5715723 C73.0987421,68.5306289 73.6033333,64.4849686 74.331195,59.0907547 C77.1042138,38.4784277 61.5288679,35.9822642 61.5288679,35.9822642 C61.5288679,35.9822642 54.3842138,79.0734591 63.212327,79.9576101 C63.8419497,80.0201258 64.413522,80.0692453 64.9538365,80.1049686 L64.4626415,83.6505031 C78.4393711,86.3297484 86.6914465,62.4308805 86.6914465,62.4308805 L82.775283,60.8545912 Z" id="Path" fill="#357ED3"></path>
              <path d="M82.775283,60.8545912 L72.5941509,71.5715723 C73.0987421,68.5306289 73.6033333,64.4849686 74.331195,59.0907547 C77.1042138,38.4784277 61.5288679,35.9822642 61.5288679,35.9822642 C61.5288679,35.9822642 54.3842138,79.0734591 63.212327,79.9576101 C63.8419497,80.0201258 64.413522,80.0692453 64.9538365,80.1049686 L64.4626415,83.6505031 C78.4393711,86.3297484 86.6914465,62.4308805 86.6914465,62.4308805 L82.775283,60.8545912 Z" id="Path" fill="#FFFFFF" opacity="0.46"></path>
              <ellipse id="Oval" fill="#9CC4F3" cx="62.3415723" cy="100.279686" rx="62.1227673" ry="9.66314465"></ellipse>
              <path d="M61.5288679,35.9867296 C60.367666,35.5865635 59.1562502,35.3505929 57.9297484,35.2856604 C54.0671698,35.0355975 43.5198742,34.9686164 31.3069811,40.0502516 C15.8789937,46.4715094 36.7637107,89.6743396 36.7637107,89.6743396 L62.9622642,87.7140252 C62.9622642,87.7140252 81.2704403,43.0018868 61.5288679,35.9867296 Z" id="Path" fill="#357ED3"></path>
              <rect id="Rectangle" fill="#F2F2F2" transform="translate(127.192655, 55.731568) rotate(-1.610000) translate(-127.192655, -55.731568) " x="112.655517" y="46.6399954" width="29.0742767" height="18.1831447"></rect>
              <polygon id="Path" fill="#C9C9C9" points="112.398805 47.0609434 127.250755 58.0860377 141.464151 46.2437736"></polygon>
              <path d="M35.7500629,43.1938994 C35.7500629,43.1938994 45.7168553,43.6404403 45.2658491,59.0952201 C44.8148428,74.55 51.7987421,70.4998742 51.7987421,70.4998742 L63.2078616,52.3345912 C63.2078616,52.3345912 66.8427044,51.9103774 67.3472956,55.7372327 C67.668805,58.2066038 63.3284277,68.749434 60.2026415,75.9164151 C58.4232112,80.0274887 59.5439583,84.8187733 62.9622642,87.7140252 L62.9622642,87.7140252 L36.7860377,89.6743396 L35.892956,87.7140252 L35.7500629,43.1938994 Z" id="Path" fill="#000000" opacity="0.08"></path>
              <path d="M42.0016352,21.5723899 C42.0016352,21.5723899 44.1807547,24.5865409 44.9130818,27.0201887 C45.0553928,27.4968891 45.0015142,28.0106767 44.7634079,28.4474845 C44.5253015,28.8842924 44.1226712,29.2079734 43.6449057,29.3466667 C42.4124528,29.699434 40.6486164,29.7932075 39.2822013,28.3419497 L36.6610063,26.1092453 C36.6610063,26.1092453 35.0668553,24.6624528 36.0938994,22.2913208 C37.1209434,19.9201887 40.7691824,19.6701258 42.0016352,21.5723899 Z" id="Path" fill="#F4A28C"></path>
              <polygon id="Path" fill="#F4A28C" points="38.2105031 25.9797484 36.8798113 38.0944025 43.6091824 38.3489308 41.8766038 29.4315094"></polygon>
              <path d="M41.2916352,22.1796855 C40.3281516,22.8064263 39.295821,23.3203441 38.2149686,23.7113208 C39.3222083,24.2550022 40.0061629,25.3990081 39.9609434,26.6316981 C39.9314045,27.8412385 39.0502308,28.8608556 37.8577358,29.0653459 L35.4598113,24.9303774 C34.7201124,23.7496984 34.6858411,22.2588946 35.3705031,21.0454717 C35.6640165,20.5302121 35.9891237,20.0336033 36.3439623,19.5584906 C37.237044,18.3796226 39.4384906,18.1340252 40.0234591,16.7006289 C40.1427558,16.4077146 40.4080786,16.1997428 40.7210085,16.1538587 C41.0339385,16.1079745 41.3477978,16.2310222 41.5461635,16.4773585 C42.4749686,17.6115723 43.4261006,19.7103145 42.1445283,21.3893082 C41.9057255,21.6979648 41.6175432,21.9650238 41.2916352,22.1796855 Z" id="Path" fill="#24285B"></path>
              <path d="M40.2467296,26.3459119 C40.2467296,26.3459119 39.4876101,25.1313208 38.6213208,25.778805 C37.7550314,26.4262893 38.8401258,28.1901258 40.1931447,27.6051572 L40.2467296,26.3459119 Z" id="Path" fill="#F4A28C"></path>
              <path d="M43.2787421,23.5416352 L44.9041509,24.238239 C45.0916361,24.3193165 45.2248092,24.4904326 45.2573633,24.692087 C45.2899173,24.8937414 45.2173543,25.0980705 45.0649057,25.2340252 L43.9798113,26.1940881 L43.2787421,23.5416352 Z" id="Path" fill="#F4A28C"></path>
              <path d="M41.7828302,29.721761 C40.9224298,29.7319167 40.0779105,29.4897383 39.3536478,29.0251572 C39.3536478,29.0251572 39.4563522,31.2578616 42.381195,32.7894969 L41.7828302,29.721761 Z" id="Path" fill="#CE8172" opacity="0.31"></path>
              <path d="M36.7637107,89.6743396 C36.7637107,89.6743396 23.1933333,94.7202516 26.4932704,102.289119 C28.9537107,107.969119 41.4300629,103.11522 49.601761,100.703899 C52.5238142,99.8483953 55.5798223,99.5455204 58.612956,99.8108176 C64.2259748,100.293082 71.3616981,102.01673 73.7596226,98.7569811 C77.291761,93.9655975 72.7683019,89.7681132 62.9577987,87.7318868 L36.7637107,89.6743396 Z" id="Path" fill="#24285B"></path>
              <path d="M39.1035849,48.2264151 C37.3174214,40.3003145 26.4352201,39.4116981 23.3451572,46.9269811 C22.1350314,49.8652201 21.3669811,53.6474214 21.5589937,58.4655975 C22.327044,75.3671698 25.680566,89.754717 38.4025157,87.4773585 C51.1244654,85.2 63.2257233,58.4522013 63.2257233,58.4522013 L57.4206918,55.1790566 L39.5322642,72.5941509 C39.5322642,72.5941509 41.5015094,58.7201258 39.1035849,48.2264151 Z" id="Path" fill="#357ED3"></path>
              <path d="M39.1035849,48.2264151 C37.3174214,40.3003145 26.4352201,39.4116981 23.3451572,46.9269811 C22.1350314,49.8652201 21.3669811,53.6474214 21.5589937,58.4655975 C22.327044,75.3671698 25.680566,89.754717 38.4025157,87.4773585 C51.1244654,85.2 63.2257233,58.4522013 63.2257233,58.4522013 L57.4206918,55.1790566 L39.5322642,72.5941509 C39.5322642,72.5941509 41.5015094,58.7201258 39.1035849,48.2264151 Z" id="Path" fill="#FFFFFF" opacity="0.46"></path>
              <path d="M58.1351572,55.5809434 C58.1351572,55.5809434 61.1761006,50.0974214 64.3867296,51.240566 C67.5973585,52.3837107 63.1721384,53.8840881 63.1721384,53.8840881 C63.1721384,53.8840881 65.4316352,55.8577987 62.4442767,58.0145912 L58.1351572,55.5809434 Z" id="Path" fill="#F4A28C"></path>
              <path d="M53.053522,95.9259119 L53.9019497,99.8420755 C53.9019497,99.8420755 57.9208176,102.793711 60.4527044,100.945031 C62.9845912,99.0963522 59.1086164,96.9618868 53.053522,95.9259119 Z" id="Path" fill="#357ED3"></path>
              <rect id="Rectangle" fill="#FFD200" transform="translate(82.324482, 53.543966) rotate(7.890000) translate(-82.324482, -53.543966) " x="78.2877528" y="46.3591235" width="8.07345912" height="14.3696855"></rect>
              <path d="M86.6914465,62.4353459 C86.6914465,62.4353459 88.4776101,55.7372327 84.1684906,55.7372327 C79.8593711,55.7372327 81.5205031,62.8238365 86.6914465,62.4353459 Z" id="Path" fill="#F4A28C"></path>
              <path d="M35.3079874,38.5454088 C35.3079874,38.5454088 35.2454717,35.6741509 37.2950943,34.3300629 C38.8401258,33.3208805 42.4303145,35.0311321 44.1003774,35.9331447 C44.9161613,36.3526503 45.3442856,37.2713721 45.1408176,38.1658491 C44.940618,38.8669642 44.3343615,39.3760076 43.6091824,39.4518868 C41.1933962,39.9698742 35.3079874,38.5454088 35.3079874,38.5454088 Z" id="Path" fill="#357ED3"></path>
              <path d="M35.3079874,38.5454088 C35.3079874,38.5454088 35.2454717,35.6741509 37.2950943,34.3300629 C38.8401258,33.3208805 42.4303145,35.0311321 44.1003774,35.9331447 C44.9161613,36.3526503 45.3442856,37.2713721 45.1408176,38.1658491 C44.940618,38.8669642 44.3343615,39.3760076 43.6091824,39.4518868 C41.1933962,39.9698742 35.3079874,38.5454088 35.3079874,38.5454088 Z" id="Path" fill="#FFFFFF" opacity="0.46"></path>
              <polygon id="Path" fill="#FFD200" points="108.326352 16.1603145 108.326352 6.90352201 75.4296855 6.90352201 75.4296855 19.464717 108.326352 19.464717 114.68956 19.464717"></polygon>
              <polygon id="Path" fill="#24285B" points="101.42283 38.732956 101.42283 29.4761635 134.319497 29.4761635 134.319497 42.0373585 101.42283 42.0373585 95.0596226 42.0373585"></polygon>
              <circle id="Oval" fill="#F2F2F2" cx="120.423145" cy="19.5316981" r="3.07220126"></circle>
              <circle id="Oval" fill="#F2F2F2" cx="88.6740881" cy="41.7471069" r="3.07220126"></circle>
              <rect id="Rectangle" fill="#FFFFFF" x="81.5338994" y="9.65867925" width="20.4292453" height="2.34433962"></rect>
              <rect id="Rectangle" fill="#FFFFFF" opacity="0.37" x="81.5338994" y="14.1240881" width="20.4292453" height="2.34433962"></rect>
              <rect id="Rectangle" fill="#FFFFFF" x="107.455597" y="32.3518868" width="20.4292453" height="2.34433962"></rect>
              <rect id="Rectangle" fill="#FFFFFF" opacity="0.37" x="107.455597" y="36.8172956" width="20.4292453" height="2.34433962"></rect>
              <path d="M97.5602516,90.6879874 C97.5602516,90.6879874 91.6435849,89.0983019 90.3352201,83.6058491 C90.3352201,83.6058491 99.4848428,81.7169811 99.7840252,91.1657862 L97.5602516,90.6879874 Z" id="Path" fill="#357ED3" opacity="0.58"></path>
              <path d="M98.2836478,90.0985535 C98.2836478,90.0985535 94.1263522,83.5924528 97.7344025,77.4703774 C97.7344025,77.4703774 104.713836,81.8643396 101.655031,90.0985535 L98.2836478,90.0985535 Z" id="Path" fill="#357ED3" opacity="0.73"></path>
              <path d="M99.3464151,90.0985535 C99.3464151,90.0985535 101.498742,83.1994969 108.08522,81.8643396 C108.08522,81.8643396 109.344465,86.3297484 103.852013,90.0985535 L99.3464151,90.0985535 Z" id="Path" fill="#357ED3"></path>
              <polygon id="Path" fill="#24285B" points="95.0596226 89.9645912 96.2920755 98.1541509 103.838616 98.1541509 104.91478 89.9645912"></polygon>
              <rect id="Rectangle" fill="#ADADAD" x="116.591824" y="13.1863522" width="7.70283019" height="1.54949686"></rect>
              <rect id="Rectangle" fill="#ADADAD" opacity="0.55" x="84.8249057" y="35.2097484" width="7.70283019" height="1.54949686"></rect>
          </g>
      </g>
    </svg>
  );
};

export default ChatCommunicationIllustration;
