import * as React from "react"

const CroosedElement = ({ color, width, height}) => {
    return (
        <svg
            width={width }
            height={ height}
            viewBox="0 0 6 7"
        >
            <title>{"eic"}</title>
            <path
                d="M5.542 0c.177 0 .34.097.426.252L9.274 6.28l.033.075a.487.487 0 01-.46.645H5.542a.487.487 0 01-.486-.486V.486c0-.268.218-.486.486-.486zm-1.75 0c.268 0 .486.218.486.486v6.028A.487.487 0 013.792 7H.486a.487.487 0 01-.426-.72L3.365.252A.487.487 0 013.792 0z"
                fill={color}
                fillRule="nonzero"
            />
        </svg>
    )
}

export default CroosedElement