import { all, put, takeEvery, call, select } from 'redux-saga/effects';
import endpoint from '../../helpers/api';
import apiCall from '../apiCall';
import { authHeader } from '../../helpers/auth-header';
import _ from 'lodash';
import Parser from 'html-react-parser';

import {
  PAYLOAD_VERSION,
  MESSAGE_ELEMENT_TYPE,
  MESSAGE_CARD_TYPE,
  FEEDBACK,
  ERROR_MESSAGE,
  getConciergeApi,
  getTestData
} from '../../containers/Chatbot/constants/chatBotConstants';

import { FORM_SOURCE } from '../../constants/serviceConstants';
import { generateUrlParamsString } from '../../helpers/generateUrlParamsString';

/** Actions */
import * as chatBot from './actions';

// Selector //
import { getChatBotMessagesData } from './selectors';
import { getUser } from '../collaborator/selectors';
import {
  getCompanyData,
  getCompanyModules
} from '../company/selectors';

// const getDataFromOnClick = (funuctionName, dataIn) => {
//   let params = dataIn.replace(funuctionName, "");
//   params = params.slice(1, -2);
//   params = params.split(',');
//   params = _.map(params, (param) => {
//     return param.slice(1, -1);
//   });
//   return params;
// }

// const cleanJsonData = (dataIn, funcName) => {
//   let start = dataIn.indexOf(funcName + '(')
//   let end = dataIn.indexOf('})');
//   let data = dataIn.substring(start + funcName.length + 1, end + 1);
//   data = data.replace(/\'/g, '"');
//   return JSON.parse(data);
// }

// const oldResponseConverter = (message) => {
//   // console.log('oldResponseConverter', message);
//   let messageElements = [];
//   let defaultValues = null;
//   Parser(message.text, {
//     replace: function (domNode) {
//       // console.log('domNode', domNode);
//       let newElement = null;
//       if (domNode.attribs === undefined && domNode.parent === null) {
//         if (domNode.data !== null) {
//           let link = '';
//           if (domNode.next && domNode.next.name === 'a') {
//             const nextNode = domNode.next;
//             link = `<a href="${nextNode.attribs.href}" target="${nextNode.attribs.target}">${nextNode.children[0]?.data || ""}</a>`
//           }
//           if (domNode.data) {
//             newElement = {
//               type: MESSAGE_ELEMENT_TYPE.TEXT,
//               content: `${domNode.data || "<p>Texte Simple</p>"}${link}`
//             }
//           }
//         }
//       }
//       else if (domNode.name === 'p') {
//         if (domNode.children[0] && domNode.children[0].data) {
//           newElement = {
//             type: MESSAGE_ELEMENT_TYPE.TEXT,
//             content: domNode.children[0].data || "<p>Texte Simple</p>"
//           }
//         }
//       }
//       else if (domNode.name === 'button' && domNode.attribs) {
//         if (domNode.attribs['data-type'] === 'text') {
//           const params = getDataFromOnClick("customSmalltalkTextType", domNode.attribs.onclick);
//           newElement = {
//             type: MESSAGE_ELEMENT_TYPE.BUTTON_SIMPLE,
//             title: params[0] || "Bouton texte simple",
//             userMessage: params[1] || "Réponse de l’utilisateur",
//             messageElements: [{
//               type: MESSAGE_ELEMENT_TYPE.TEXT,
//               content: params[2] || "<p>Texte Simple</p>"
//             }]
//           }
//         }
//         else if (domNode.attribs['data-type'] === 'intent') {
//           const params = getDataFromOnClick("customSmalltalkIntentType", domNode.attribs.onclick);
//           newElement = {
//             type: MESSAGE_ELEMENT_TYPE.BUTTON_INTENT,
//             title: params[0] || "Bouton déclencheur",
//             userMessage: params[1] || "Réponse de l’utilisateur"
//           }
//         }
//         else if (domNode.attribs['data-type'] === 'form') {
//           let buttonTitle = "Bouton formulaire";
//           // console.log(' im here ');

//           if (_.get(domNode, 'children[5].children[1].data')) {
//             buttonTitle = domNode.children[5].children[1].data;
//           }
//           else if (_.get(domNode, 'children[5].children[0].data')) {
//             buttonTitle = domNode.children[5].children[0].data;
//           }
//           else if (_.get(domNode, 'children[2].children[1].data')) {
//             buttonTitle = domNode.children[2].children[1].data;
//           }
//           else if (_.get(domNode, 'children[3].children[0].data')) {
//             buttonTitle = domNode.children[3].children[0].data;
//           }
//           else if (_.get(domNode, 'children[2].children[0].data')) {
//             buttonTitle = domNode.children[2].children[0].data;
//           }
//           // console.log(' im here after');

//           const transmitData = cleanJsonData(domNode.attribs.onclick, 'transmitData');
//           // console.log('im here data-type orm', transmitData);
//           newElement = {
//             type: MESSAGE_ELEMENT_TYPE.BUTTON_FORM,
//             title: buttonTitle,
//             transmitData: {
//               type: 'form',
//               url: transmitData.payload?.url || "/api/v1/partner/request/form",
//               form: transmitData.payload?.form || "CollaboratorsBundle/Form/Cyconia/DefaultType",
//               defaultValues: transmitData.payload?.defaultValues || {},
//             }
//           }
//         }
//         else if (domNode.attribs.class === 'btn_negative_feedback') {
//           const transmitData = cleanJsonData(domNode.attribs.onclick, 'transmitData');
//           // console.log('feedback button transmitData', transmitData);
//           if (transmitData && transmitData.payload && transmitData.payload.defaultValues && transmitData.payload.defaultValues.informationElementId) {
//             // is allowed to edit and make a feedback button appear
//             defaultValues = transmitData.payload?.defaultValues || {};
//           }
//         }
//         else if (domNode.attribs.class === "btn"
//           && domNode.attribs.onclick.indexOf("CollaboratorsBundle/Form/Cyconia/DefaultType") !== -1) {
//           const transmitData = cleanJsonData(domNode.attribs.onclick, 'transmitData');
//           const buttonTitle = domNode.children[2].children[0]?.data || "Bouton formulaire";
//           newElement = {
//             type: MESSAGE_ELEMENT_TYPE.BUTTON_FORM,
//             title: buttonTitle,
//             transmitData: {
//               type: 'form',
//               url: transmitData.payload?.url || "/api/v1/partner/request/form",
//               form: transmitData.payload?.form || "CollaboratorsBundle/Form/Cyconia/DefaultType",
//               defaultValues: transmitData.payload?.defaultValues || {},
//             }
//           }
//         }
//         else if (domNode.attribs.class === "btn"
//           && domNode.attribs.onclick.indexOf("transmitData({'type': 'feedback'})") !== -1) {
//           // const transmitData = cleanJsonData(domNode.attribs.onclick, 'transmitData');
//           // console.log('im here feedback', transmitData);
//           const buttonTitle = domNode.children[2].children[0]?.data || "Feedback";
//           newElement = {
//             type: MESSAGE_ELEMENT_TYPE.BUTTON_FORM,
//             title: buttonTitle,
//             transmitData: {
//               type: 'feedback',
//             }
//           }
//         }
//         else if (domNode.attribs.class === "btn"
//           && domNode.attribs.onclick.indexOf("transmitData") !== -1
//           && domNode.attribs.onclick.indexOf("api") !== -1
//           && domNode.attribs.onclick.indexOf("feedback") === -1) {
//           const transmitData = cleanJsonData(domNode.attribs.onclick, "transmitData");
//           // console.log('im here', transmitData);
//           const buttonTitle = domNode.children[3].children[0]?.data || "Button Service";
//           newElement = {
//             type: MESSAGE_ELEMENT_TYPE.BUTTON_FORM,
//             title: buttonTitle,
//             transmitData: {
//               type: 'form',
//               url: transmitData.payload?.url || "/api/v1/partner/request/form",
//               form: transmitData.payload?.form || "CollaboratorsBundle/Form/Cyconia/DefaultType",
//               defaultValues: transmitData.payload?.defaultValues || {},
//             }
//           }
//         }
//       }
//       if (newElement) {
//         messageElements = [...messageElements, newElement];
//       }
//     }
//   });
//   const convertedMessage = {
//     messageElements,
//     defaultValues,
//   }
//   return convertedMessage;
// }

const recursiveConvert = (payload) => {
  const newElements = _.cloneDeep(payload);
  _.map(newElements, (element) => {
    if (element.type === MESSAGE_ELEMENT_TYPE.BUTTON_SIMPLE && element.payload && !element.messageElements) {
      element.messageElements = recursiveConvert(element.payload);
      delete element.payload;
    }
  })
  return newElements;
}

// to convert payload to messageElements
const payloadConverter = (response) => {
  const newResponse = _.cloneDeep(response);
  if (!_.isArray(newResponse.messages)) {
    newResponse.messages = [{ ...newResponse.messages[0], defaultValues: newResponse.messages.defaultValues }];
  }
  _.map(newResponse.messages, (message) => {
    if (!message.messageElements && message.payload) {
      message.messageElements = recursiveConvert(message.payload);
      delete message.payload;
    }
    // else if (!message.messageElements) { // to convert old response to new response
    // console.log('oldRes', response);
    // const convertedMessage = oldResponseConverter(message);
    // message.version = PAYLOAD_VERSION;
    // message.messageElements = convertedMessage.messageElements;
    // message.defaultValues = convertedMessage.defaultValues;
    // message.isReceived = true;
    // console.log('converted message', message);
    // }
  });
  return newResponse;
}

async function botService(url, formData) {
  const reqOptions = {
    method: 'POST',
    headers: authHeader(),
    body: formData
  }

  let status;
  let ok;
  return fetch(url, reqOptions)
    .then(res => {
      // console.log('botService', res);
      if (res.ok) {
        ok = res.ok;
        status = res.status;
        return res.json();
      }
      return res.status;
    })
    .then(data => {
      // console.log('data', data);
      return ({
        ...data,
        ok,
        status
      })
    })
    .catch(err => err);
}

function* generateMessageId() {
  const messages = yield select(getChatBotMessagesData);
  return messages.length;
}

function* generateErrorMessage() {
  const messageId = yield call(generateMessageId);
  return ({
    id: messageId,
    isReceived: true,
    version: PAYLOAD_VERSION,
    messageElements: [{
      type: MESSAGE_ELEMENT_TYPE.TEXT,
      content: ERROR_MESSAGE
    }]
  })
}

// function* generateUrlParamsString(urlParams) {
//   let urlParamsString = '';
//   const user = yield select(getUser);
//   const companyModule = yield select(getCompanyModules);

//   _.map(urlParams, (param, index) => {
//     let valueString = param.value;
//     const tokenArray = valueString.match(/{\w+}/g);

//     _.map(tokenArray, (token) => {
//       let value = '';

//       if (token === '{userId}') value = user.id;
//       else if (token === '{userFirstName}') value = user.firstname;
//       else if (token === '{userLastName}') value = user.lastname;
//       else if (token === '{userEmail}') value = user.email;
//       else if (token === '{userCompanyName}') value = companyModule.name;
//       else if (token === '{userCompanyId}') value = companyModule.id;
//       else if (token === '{userSiteName}') value = user.company_site.name;
//       else if (token === '{userSiteId}') value = user.company_site.id;
//       else if (token === '{companyUrl}') value = endpoint.base_url;

//       valueString = valueString.replace(token, value);
//     });
//     if (valueString !== '') {
//       urlParamsString = `${urlParamsString}${index > 0 ? '&' : ''}${param.variable}=${valueString}`;
//     }
//   });
//   return urlParamsString;
// }

function* formatingMessageElements(messageElements) {
  const newMessageElements = _.cloneDeep(messageElements);
  const user = yield select(getUser);
  const companyModule = yield select(getCompanyModules);

  yield all(_.map(newMessageElements, function* (element) {
    if (element.type === MESSAGE_ELEMENT_TYPE.BUTTON_LINK) {
      const urlParamsString = yield call(generateUrlParamsString, element, user, companyModule, endpoint);
      if (urlParamsString !== '')
        element.url += '?' + urlParamsString;
    }
    else if (element.type === MESSAGE_ELEMENT_TYPE.CARD_LIST) {
      yield all(_.map(element.cards, function* (card) {
        if (card.type === MESSAGE_CARD_TYPE.SERVICE_CARD && card.form_source === FORM_SOURCE.EXTERNAL) {
          try {
            const parsedUrl = JSON.parse(card.form.url);
            const urlParamsString = yield call(generateUrlParamsString, parsedUrl, user, companyModule, endpoint);
            if (urlParamsString !== '') {
              parsedUrl.url += '?' + urlParamsString;
            }
            card.form.url = JSON.stringify(parsedUrl);
          } catch (err) {
            console.error('Form url formating error :', err);
          }
        }
      }))
    }
  }));
  return newMessageElements;
}

function* formatingMessagesFromResponse(messages) {
  let messageId = yield call(generateMessageId);

  return yield all(_.map(messages, function* (message) {
    const formatedMessageElements = yield call(formatingMessageElements, message.messageElements);

    return ({
      id: messageId++,
      isReceived: true,
      ...message,
      messageElements: formatedMessageElements
    })
  }));
}

function* getBotResponseSaga({ messageToBot, serviceType }) {
  const user = yield select(getUser);
  const companyData = yield select(getCompanyData);

  const url = companyData?.frontVersion === 3 ? endpoint.botServerV3 : endpoint.botServer;
  const formData = new FormData();

  formData.append('driver', 'web');
  formData.append('userId', user.id);
  formData.append('message', messageToBot);
  formData.append('payloadVersion', PAYLOAD_VERSION);

  const testInput = [
    // `J'ai besoin d'un plombier`,
    // `J'ai besoin d'un lavage auto`,
  ];

  const shouldUseTestData = testInput.includes(messageToBot)

  if (shouldUseTestData) { // for testing concierge response with fake data
    let response = yield call(getConciergeApi, messageToBot);

    const formatedMessages = yield call(formatingMessagesFromResponse, response.messages);
    yield put(chatBot.getBotResponseSuccess(formatedMessages));
  }
  else {
    try {
      let response = yield call(botService, url, formData);
      // let response = yield call(getTestData, messageToBot);
      // console.log("response", response);
      if (response.ok) {
        response = payloadConverter(response);
        // console.log('convertedResponse', response);
        const formatedMessages = yield call(formatingMessagesFromResponse, response.messages);
        // console.log('formatedMessages', formatedMessages);
        yield put(chatBot.getBotResponseSuccess(formatedMessages));
      }
      else {
        const errorMessage = yield call(generateErrorMessage);
        yield put(chatBot.getBotResponseError([errorMessage], { status: response?.status || 400 }));
      }
    } catch (error) {
      const errorMessage = yield call(generateErrorMessage);
      yield put(chatBot.getBotResponseError([errorMessage], error));
    }
  }
}

function* getBotFormSaga({ formRequestData, requestedFormType }) {
  let url = '';
  let formType = '';
  if (requestedFormType === FEEDBACK.NEGATIVE) { // this will for negative feedback
    url = endpoint.negativeFeedbackFormUrl;
    formType = 'CompanyBundle/Form/NegativeBotFeedbackType';
  }
  else { // this will for all other form
    url = endpoint.base_url + formRequestData.url.slice(1);;
    formType = formRequestData.formType.replace(/[\/]/g, '\\');
  }

  const formData = new FormData();
  formData.append('formType', formType.split('/').join('\\'));
  formData.append('defaultValues', JSON.stringify(formRequestData.defaultValues));

  try {
    const response = yield call(botService, url, formData);
    response.requiredList = response.required; // the required data become lost, maybe because it is a reserved keyword...
    if (response.ok) {
      yield put(chatBot.getBotFormSuccess(response));
    }
    else {
      yield put(chatBot.getBotFormError({ status: response || 400 }));
    }
  } catch (error) {
    yield put(chatBot.getBotFormError(error));
  }
}

function* getNestedButtonResponseSaga({ message, nestedResponse }) { // Not needed to be in saga as it is not an async function, but handled it this way so the messages display have the same behavior
  const response = payloadConverter(nestedResponse);
  const formatedMessages = yield call(formatingMessagesFromResponse, response.messages);
  yield put(chatBot.getNestedButtonResponseSuccess(formatedMessages));
}

function* setMessageFromSideBarSaga({ message }) {
  yield put(chatBot.setMessageFromSideBarSuccess(message));
}

function* saveBotFinishIntroSaga() {
  const user = yield select(getUser);
  const url = endpoint.getUserIntroUpdateUrl(user.id);
  const response = yield call(botService, url, null);
  localStorage.setItem('user', JSON.stringify(response)); // this is old code, it should update the store with the new user value instead, but for now it doesnt really matter, user will be refreshed when page reloaded
}

function* savePositiveFeedbackSaga({ feedbackData }) {
  // console.log('positive', feedbackData);
  const url = endpoint.sendPositiveBotFeedback;
  const formData = new FormData();

  formData.append('action', feedbackData.action);
  formData.append('userMessage', feedbackData.userMessage);
  formData.append('botReply', feedbackData.botReply);
  formData.append('informationElementId', feedbackData.informationElementId);
  formData.append('companyResponseElement', feedbackData.companyResponseElement);
  formData.append('responseStatus', 'ok');

  yield call(botService, url, formData);
}

function* saveNegativeFeedbackSaga({ feedbackData }) {
  // console.log('negative', feedbackData);
  const url = endpoint.sendNegativeBotFeedback;
  const formData = new FormData();
  let info = {
    action: feedbackData.action,
    anonymous: feedbackData.anonymous,
    botReply: feedbackData.botReply,
    comment: feedbackData.comment,
    companyResponseElement: feedbackData.companyResponseElement,
    responseStatus: feedbackData.responseStatus,
    userMessage: feedbackData.userMessage,
  }

  formData.append("data", JSON.stringify(info));
  try {
    const response = yield call(botService, url, formData);
    // console.log('response', response);
    if (response.ok) {
      yield put(chatBot.saveNegativeFeedbackSuccess(response));
    }
    else {
      yield put(chatBot.saveNegativeFeedbackError({ status: response || 400 }));
    }
  } catch (error) {
    yield put(chatBot.saveNegativeFeedbackError(error));
  }
}

function* saveFeedbackSaga({ feedbackData }) {
  // console.log('im here saveFeedbackSaga', feedbackData);
  const url = endpoint.sendFeedback;
  const formData = new FormData();

  formData.append("content", feedbackData.content);
  formData.append("subject", feedbackData.subject);
  try {
    const response = yield call(botService, url, formData);
    if (response.ok) {
      yield put(chatBot.saveFeedbackSuccess(response));
    }
    else {
      yield put(chatBot.saveFeedbackError({ status: response || 400 }));
    }
  } catch (error) {
    yield put(chatBot.saveFeedbackError(error));
  }

}

export default function* chatbotRootSaga() {
  yield all([
    takeEvery(chatBot.GET_BOT_RESPONSE.REQUEST, getBotResponseSaga),
    takeEvery(chatBot.GET_BOT_FORM.REQUEST, getBotFormSaga),
    takeEvery(chatBot.GET_NESTED_BUTTON_RESPONSE.REQUEST, getNestedButtonResponseSaga),
    takeEvery(chatBot.SET_MESSAGE_FROM_SIDEBAR.REQUEST, setMessageFromSideBarSaga),
    takeEvery(chatBot.SAVE_BOT_FINISH_INTRO.REQUEST, saveBotFinishIntroSaga),
    takeEvery(chatBot.SAVE_POSITIVE_FEEDBACK.REQUEST, savePositiveFeedbackSaga),
    takeEvery(chatBot.SAVE_NEGATIVE_FEEDBACK.REQUEST, saveNegativeFeedbackSaga),
    takeEvery(chatBot.SAVE_FEEDBACK.REQUEST, saveFeedbackSaga),
  ]);
}
