import styled from "styled-components";
import { CSVLink } from "react-csv";

import { COLORS } from "../../../../../../constants/design";

export const StyledCSVLink = styled(CSVLink)`
  background-color: ${COLORS.PRIMARY_BLUE};
  color: white;
  cursor: pointer;
  padding: 0px 20px;
  border-radius: 6px;
  font-size: 0.825rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledButton = styled.button`
  background-color: ${COLORS.LIGHT_GREY_4};
  color: white;
  cursor: not-allowed;
  padding: 0px 20px;
  border-radius: 6px;
  font-size: 0.825rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;