import { call, put } from 'redux-saga/effects';
import endpoint from '../../../helpers/api';
import axios from 'axios';

import {
  validateGetGroundTokenSuccess,
  validateGetGroundTokenError
} from '../actions';

import {
  loginSuccess
} from '../../authentication/actions';

import * as collaboratorActions from '../../collaborator/actions';

export const getGroundLoginService = async (endpoint, token) => {
  const newForm = new FormData();

  newForm.append('token', token);

  return axios.request({
    method: 'post',
    url: endpoint,
    data: newForm,
  });
}

export function* validateGetGroundTokenHandler(action) {
  const { token } = action.data;
  try {
    const response = yield call(getGroundLoginService, endpoint.getgroundLoginApi, token);
    const { message, code } = response.data;
    if (code === 200 || code === 201) {
      yield put(loginSuccess(message)); // This is the one that matter
      yield call([localStorage, 'setItem'], 'id_token', JSON.stringify(message));
      // yield put(collaboratorActions.getUser());
      yield put(validateGetGroundTokenSuccess(message)); // not really useful, but well just to let the redux store know the action is completed
    }
    else {
      yield put(validateGetGroundTokenError({ code, message }));
    }
  } catch (error) {
    yield put(validateGetGroundTokenError({ message: error.message }));
  }
}