import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  shallowEqual,
  useSelector
} from "react-redux";
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import _ from 'lodash';

import {
  getConciergeDataByCurentSite
} from '../../../../store/concierge/selectors';

import {
  getHelperContext
} from '../../../../store/company/selectors';

import {
  HELPER_KEY,
  HELPER_TEXT
} from "../../../../constants/helperConstants";

import {
  StyledMainContainer,
  StyledSuggestionOverlay,
  StyledSuggestionItemContainer,
  StyledSuggestionItem,
  StyledSuggestionIconContainer,
  StyledSuggestionIndicator,
  StyledForm,
  StyledTextField,
  StyledInputIcon,
  StyledSuggestionIcon,
  StyledInputIndicator
} from './style';

const UserInput = ({ onSend, inputMessage }) => {
  const [userInput, setUserInput] = useState('');
  const [suggestionMessage, setSuggestionMessage] = useState([]);
  const [isSuggestionActive, setIsSuggestionActive] = useState(false);
  const [isIndicatorVisible, setIsIndicatorVisible] = useState(false);
  const [isSendIndicatorVisible, setIsSendIndicatorVisible] = useState(false);
  const [textInputFocus, setTextInputFocus] = useState(false);
  const [sendIconFocus, setSendIconFocus] = useState(false);
  const [inputIndicator, setInputIndicator] = useState(false);
  const [inputTimerArray, setInputTimerArray] = useState([]);
  const textInputRef = useRef(null);

  const {
    currentConciergeData,
    helperContext
  } = useSelector(
    state => ({
      currentConciergeData: getConciergeDataByCurentSite(state),
      helperContext: getHelperContext(state),
    }),
    shallowEqual
  );

  useEffect(() => {
    if (localStorage.getItem("isIndicatorVisible") !== null)
      setIsIndicatorVisible(false);
    else setIsIndicatorVisible(true);
    textInputRef.current.focus();
  }, []);

  useEffect(() => {
    if (inputMessage) {
      typeWriting(inputMessage);
    }
  }, [inputMessage]);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const newSuggestion = [
      currentConciergeData?.service_highlighted,
      currentConciergeData?.service_highlighted2,
      currentConciergeData?.service_highlighted3,
    ].filter((item) => item != null);

    _.map(HELPER_KEY, (data, helperKey) => {
      newSuggestion.push(helperContext?.[helperKey] || HELPER_TEXT[helperKey]);
    });

    setSuggestionMessage(newSuggestion);
  }, [helperContext, currentConciergeData]);

  const typeWriting = (msg) => {
    setUserInput('');
    _.map(inputTimerArray, (timerId) => {
      clearTimeout(timerId);
    })
    setIsSendIndicatorVisible(false);
    setSendIconFocus(false);
    setInputIndicator(true);
    setTextInputFocus(true);
    setTimeout(() => {
      setInputIndicator(false);
      setIsSendIndicatorVisible(true);
      setSendIconFocus(true);
    }, 1000);
    textInputRef.current.focus();
    if (isIndicatorVisible)
      setIsIndicatorVisible(false);
    const string = msg.split('');
    const newTimerArray = Array(string.length);
    _.map(string, (char, index) => {
      newTimerArray[index] = setTimeout(() => {
        setUserInput((prev) => prev + char);
        if (index === string.length - 1) {
          setInputTimerArray([]);
        }
      }, 100 + (index * 10));
    })
    setInputTimerArray(newTimerArray);
  }

  const handleOnChange = (e) => {
    setUserInput(e.target.value);
    setTextInputFocus(false);
    setIsSendIndicatorVisible(false);
    setSendIconFocus(false);
  }

  const handleOnSubmit = (e) => {
    e.preventDefault();
    handleSend();
  }

  const handleSend = () => {
    setTextInputFocus(false);
    setIsSendIndicatorVisible(false);
    setSendIconFocus(false);
    if (userInput === '' || inputTimerArray.length > 0)
      return;
    onSend(userInput);
    setUserInput('');
  }

  const handleOpenSuggestion = () => {
    setIsSuggestionActive(true);
    if (isIndicatorVisible)
      setIsIndicatorVisible(false);
  }

  const handleSuggestionPick = (message) => {
    typeWriting(message)
    setIsSuggestionActive(false);
  }

  return (
    <StyledMainContainer>
      <StyledSuggestionOverlay onClick={() => setIsSuggestionActive(false)} isSuggestionActive={isSuggestionActive}>
      </StyledSuggestionOverlay>
      <StyledSuggestionItemContainer isSuggestionActive={isSuggestionActive}>
        {_.map(suggestionMessage, (msg, index) => {
          return (
            <StyledSuggestionItem key={index} onClick={() => handleSuggestionPick(msg)}>
              {msg}
            </StyledSuggestionItem>
          )
        })}
      </StyledSuggestionItemContainer>

      <StyledSuggestionIconContainer>
        {isIndicatorVisible &&
          <StyledSuggestionIndicator isIndicatorVisible={isIndicatorVisible} icon={'suggestion'}>
            <span role={'img'} aria-label={'emoji'}>👇</span> Laissez moi vous aider !
          </StyledSuggestionIndicator>
        }
        <StyledSuggestionIcon isIndicatorVisible={isIndicatorVisible} onClick={() => handleOpenSuggestion()}>
          <QuestionAnswerIcon />
        </StyledSuggestionIcon>
      </StyledSuggestionIconContainer>

      <StyledForm noValidate autoComplete='off' onSubmit={(e) => handleOnSubmit(e)}>
        <StyledTextField
          id={'user-input'}
          ref={textInputRef}
          type={'text'}
          value={userInput}
          textInputFocus={textInputFocus}
          placeholder={'Posez-moi une question 🙂'}
          onChange={(e) => handleOnChange(e)}
        />
        {inputIndicator &&
          <StyledInputIndicator>
            <SendRoundedIcon />
          </StyledInputIndicator>
        }
      </StyledForm>
      <StyledSuggestionIconContainer>
        {textInputFocus &&
          <StyledSuggestionIndicator isIndicatorVisible={isSendIndicatorVisible} icon={'send'}>
            Cliquez ici ou appuyez sur Entrer pour envoyer <span role={'img'} aria-label={'emoji'}>👇</span>
          </StyledSuggestionIndicator>
        }
        <StyledInputIcon isIndicatorVisible={sendIconFocus} isFocus={sendIconFocus} onClick={() => handleSend()}>
          <SendRoundedIcon />
        </StyledInputIcon>
      </StyledSuggestionIconContainer>
    </StyledMainContainer>
  )
};

UserInput.propTypes = {
  onSend: PropTypes.func,
  inputMessage: PropTypes.string
};

export default UserInput