import React, { useEffect, useState, useRef, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import {
  shallowEqual,
  useDispatch,
  useSelector,
} from "react-redux";

// Layout
import TrainingCycoBaseLayout from "../../layouts/trainingCycoBaseLayout";

// Import Material UI components
import Paper from '@material-ui/core/Paper';

// Import services
import getInforList from './services/GetInforLust';
import getEiById from './services/getEiById.service';
import {
  getInformationListData,
  getElementInformationListData,
  isInformationListLoading,
} from '../../../../store/adminFront/selectors';
import {
  getInformationList,
  getElementInformationList,
} from '../../../../store/adminFront/actions';

// Import components
import ContentHeader from './components/ContentHeader';
import SkillsNavBar from './components/SkillsNavBar/index';
import TableHeader from './components/TableHeader';
import TableContent from './components/TableContent';
import SwipeableTrainingEditor from './components/EditTraining';
import Loader from './helpers/Loader';

// Import helpers
import { history } from '../../helpers/history';

// Import Style
import {
  ThemesEiContainer,
  ThemesMainContainer,
  StyledTableContainer,
  LoaderWrapper
} from './style';

// Import context
import { Context as EditorContext } from '../../context/editorState';

function TrainingCyco({ match }) {
  const dispatch = useDispatch();
  let [InfosList, setInfosList] = useState([]);
  const [getInfoData, setGetInfoData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { state } = useContext(EditorContext);

  const {
    informationList,
    eiById,
    isInformationListOnLoad,
  } = useSelector(
    state => ({
      informationList: getInformationListData(state),
      eiById: getElementInformationListData(state),
      isInformationListOnLoad: isInformationListLoading(state),
    }),
    shallowEqual
  );

  useEffect(() => {
      dispatch(getInformationList(1));
  }, []);

  // console.log('informationList', informationList);
  // console.log('getInformationList', getInformationList);



  const getInformationListById = async (id) => {
    history.push("/admin/training/" + id);
    dispatch(getInformationList(id));
    // const data = await getInforList(id);
    // setInfosList(data);
  }

  // useEffect(() => {
  //   let dmId = match.params.dmId ? match.params.dmId : 1;
  //   setIsLoading(true);
  //   getInforList(dmId).then(data => {
  //     setInfosList(data)
  //     setIsLoading(false);
  //   })
  // }, []);

  // useEffect(() => {
  //   if (!state.storeState) {
  //     let dmId = match.params.dmId ? match.params.dmId : 1;
  //     // setIsLoading(true);
  //     getInforList(dmId).then(data => {
  //       setInfosList(data)
  //       setIsLoading(false);
  //       if (localStorage.getItem("currElem") !== null) {
  //         let elem = document.getElementById(localStorage.getItem("currElem"));
  //         if (elem) { 
  //           elem.scrollIntoView({behavior: "smooth", block: "center", inline: "start"});
  //           elem.style.borderLeft = "3px solid #357ED3";
  //         }
  //           localStorage.setItem("currElem", null);
  //       }
  //     })
  //   }
  // }, [state.storeState]);

  // console.log('informationLIst', informationList);


  return (
    <TrainingCycoBaseLayout
      id="trainingCycoLayout"
      className="trainingCycoLayout"
    >
      <ThemesMainContainer>

        {/* Skills navbar */}
        <SkillsNavBar
          isLoading={false}
          getInforListById={getInformationListById}
          feedbackStatusValue={informationList}
        />

        {isInformationListOnLoad ?
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
          :
          <ThemesEiContainer >
            {/* Add Header */}
            <ContentHeader
              {...informationList}
            />
            <StyledTableContainer component={Paper} >
              {informationList && informationList.subjects ?
                informationList.subjects.map((sub, i) => {
                  return (
                    <React.Fragment key={i}>
                      {/* Add table Header */}
                      <TableHeader sub={sub} i={i} />

                      <TableContent
                        setGetInfoData={setGetInfoData}
                        sub={sub}
                        InfosListId={informationList.skillId}
                        i={i}
                      />
                    </React.Fragment>)
                })
                : null}
            </StyledTableContainer>
          </ThemesEiContainer>
        }
      </ThemesMainContainer>

    </TrainingCycoBaseLayout>
  );
}


export default withRouter(TrainingCyco);
