import React from "react";

import Parser from 'html-react-parser';

import moment from 'moment';
import 'moment/locale/fr';
import locale from 'antd/es/date-picker/locale/fr_FR';

import { DatePicker, TimePicker } from 'antd';
import 'antd/lib/date-picker/style/index.css';
import 'antd/lib/time-picker/style/index.css';
import { isMobile } from 'react-device-detect';

class CustomFieldDatepicker extends React.Component {
    constructor(props) {
        super(props);
        let label = props.schema.title.replace(/\n/ig, '<br>');

        this.state = {
            ...props.formData,
            label,
            date: null,
            focused: false,
            nativeDate: null,


        }
        moment.locale('fr');
        this.handleChange = this.handleChange.bind(this);
        this.handleInputDateChange = this.handleInputDateChange.bind(this);
    }

    handleChange(val) {
      this.setState({ date: val });
      if (val) {
        this.props.onChange(val.format('YYYY-MM-DD'));
      }
    }

    // FOR NATIVE INPUT 
    handleInputDateChange(e) {
        this.setState({
            nativeDate: e.target.value
        }, () => {
            if ((this.state.nativeDate !== "" && this.state.nativeDate !== null)) {
                let fullDate = moment(`${this.state.nativeDate}`);
                this.props.onChange(fullDate.format("YYYY-MM-DD"));
            }
        })
    }

    // Près de quelle endroit souhaites-tu consulter ? ↵Ex : 28 rue du Chemin Vert, 75011 Paris
    render() {

        return (
            <React.Fragment>
                <label className="control-label" htmlFor={this.props.field_name}>
                    {Parser(this.state.label)} {this.props.required ? <span className="required">*</span> : null}
                </label>
                <input type="date" className="input-native-datepicker__date" required={this.props.required} onChange={this.handleInputDateChange} placeholder="Date" />
                {/* {
                    isMobile
                        ?
                        <input type="date" className="input-native-datepicker__date" required={this.props.required} onChange={this.handleInputDateChange} placeholder="Date" />

                        :
                        <DatePicker
                            locale={locale}
                            format="DD/MM/YYYY"
                            style={{ width: "100%" }}
                            required
                            onChange={(date) => {
                                this.handleChange(date);
                            }}
                        />
                } */}
            </React.Fragment>
        )
    }
}

class CustomFieldDatetimePicker extends React.Component {

    constructor(props) {
        super(props);
        let label = props.schema.title.replace(/\n/ig, '<br>');

        this.state = {
            label,
            time: null,
            date: null,
            nativeDate: null,
            nativeTime: null
        }

        moment.locale('fr');
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleChangeTime = this.handleChangeTime.bind(this);
        this.handleInputDateChange = this.handleInputDateChange.bind(this);
        this.handleInputTimeChange = this.handleInputTimeChange.bind(this);
    }

    handleChangeDate(val) {

        if (val !== null) {
            this.setState({
                date: val.format("YYYY-MM-DD")
            }, () => {
                if (this.state.date !== null && this.state.time !== null) {
                    let fullDate = moment(`${this.state.date} ${this.state.time}`);
                    this.props.onChange(fullDate.format("YYYY-MM-DD HH:mm:ss"));
                }
            })
        } else {
            this.setState({
                date: null
            })
        }

    }
    handleChangeTime(val) {
        if (val !== null) {
            this.setState({
                time: val.format("HH:mm:ss")
            }, () => {
                if (this.state.date !== null && this.state.time !== null) {
                    let fullDate = moment(`${this.state.date} ${this.state.time}`);
                    this.props.onChange(fullDate.format("YYYY-MM-DD HH:mm:ss"));
                }
            })
        } else {
            this.setState({
                time: null
            })
        }
    }
    disabledSeconds(a, b) {
    }

    // FOR NATIVE INPUT 
    handleInputDateChange(e) {
        this.setState({
            nativeDate: e.target.value
        }, () => {
            if ((this.state.nativeDate !== "" && this.state.nativeDate !== null) && (this.state.nativeTime !== "" && this.state.nativeTime !== null)) {
                let fullDate = moment(`${this.state.nativeDate} ${this.state.nativeTime}`);
                this.props.onChange(fullDate.format("YYYY-MM-DD HH:mm:ss"));
            }
        })
    }
    // FOR NATIVE INPUT 
    handleInputTimeChange(e) {
        this.setState({
            nativeTime: e.target.value
        }, () => {
            if ((this.state.nativeDate !== "" && this.state.nativeDate !== null) && (this.state.nativeTime !== "" && this.state.nativeTime !== null)) {
                let fullDate = moment(`${this.state.nativeDate} ${this.state.nativeTime}`);
                this.props.onChange(fullDate.format("YYYY-MM-DD HH:mm:ss"));
            }
        })

    }

    render() {

        return (
            <React.Fragment>
                <label className="control-label" htmlFor={this.props.field_name}>
                    {Parser(this.state.label)} {this.props.required ? <span className="required">*</span> : null}
                </label>
                <div class="chatbot-custom-datepickertime__container">
                    <input type="date" className="input-native-datepicker__date" required={this.props.required} onChange={this.handleInputDateChange} placeholder="Date" />
                    <input type="time" className="input-native-datepicker__time" required={this.props.required} onChange={this.handleInputTimeChange} placeholder="Heure" />
                </div>
                {/* {
                    isMobile
                        ?
                        <div class="chatbot-custom-datepickertime__container">
                            <input type="date" className="input-native-datepicker__date" required={this.props.required} onChange={this.handleInputDateChange} placeholder="Date" />
                            <input type="time" className="input-native-datepicker__time" required={this.props.required} onChange={this.handleInputTimeChange} placeholder="Heure" />
                        </div>
                        :
                        <React.Fragment>

                            <div className="chatbot-custom-datepickertime__container">
                                <DatePicker
                                    locale={locale}
                                    className="chatbot-custom-datepickertime__date"
                                    format="DD/MM/YYYY"
                                    style={{ width: "100%" }}
                                    required={this.props.required}
                                    onChange={(date) => {
                                        this.handleChangeDate(date);
                                    }}
                                />
                                <TimePicker
                                    className="chatbot-custom-datepickertime__time"
                                    defaultOpenValue={moment('12:00', 'HH:mm')}
                                    placeholder="Heure"
                                    format="HH:mm"
                                    disabledSeconds={this.disabledSeconds}
                                    minuteStep={5}
                                    required={this.props.required}
                                    onChange={(time) => {
                                        this.handleChangeTime(time)
                                    }}
                                />
                            </div>
                        </React.Fragment>
                } */}
            </React.Fragment>
        )

    }
}

export {
    CustomFieldDatepicker,
    CustomFieldDatetimePicker
};