import _ from 'lodash';

// News Content
export const getNewsContentData = state => state.contentManagement.newsContent.data;
export const getNewsContentIsLoading = state => state.contentManagement.newsContent.get.loading;
export const getSelectedNewsContentData = state => state.contentManagement.selectedNewsContent.data;
export const getSelectedNewsContentIsLoading = state => state.contentManagement.selectedNewsContent.get.loading;

// Good deals Content
export const getGoodDealsData = state => state.contentManagement.goodDeals.data;
export const getGoodDealsIsLoading = state => state.contentManagement.goodDeals.get.loading;
export const getSelectedGoodDealData = state => state.contentManagement.selectedGoodDeal.data;
export const getSelectedGoodDealIsLoading = state => state.contentManagement.selectedGoodDeal.get.loading;
