import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router";
import { useRouteMatch } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { useDispatch } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import _ from 'lodash';

import Routes from './routes';
import ItemBlock from './components/ItemBlock';
import ServiceActifFilter from './components/ServiceActifFilter';

import GenericLoader from '../../../../components/molecules/GenericLoader';
import GenericTable from '../../../../components/molecules/GenericTable';
import GenericAdminFrontHeader from '../../../../components/molecules/GenericAdminFrontHeader';

import {
  getConciergeSitesData,
  getConciergeConciergesData,
  getConciergeServicesData,
  getConciergeServicesActifsData
} from '../../../../store/concierge/selectors';

import {
  saveSiteManagementSelectedSiteList
} from '../../../../store/persistReducers/actions';

import {
  getSiteManagementSelectedSiteData
} from '../../../../store/persistReducers/selectors';

import GenericLayout from '../../layouts/genericLayout';

import {
  StyledSiteBlock,
  StyledTitle,
  StyledSiteBlockSpan,
  StyledSiteBlockIcon,
  StyledMultipleSelect,
} from './style';



// const testHeaderList = [
//   { id: "header", label: "Services" },
//   { id: "plomberie", label: "Plomberie" },
//   { id: "panier_bio", label: "Panier bio" },
//   { id: "coiffeur", label: "Coiffeur" },
//   { id: "traiteur", label: "Traiteur" },
//   { id: "lavage_auto", label: "Lavage auto" },
// ];

// const testItemList = [
//   { header: <SiteBlock label={'bp metz'} />, plomberie: 'activé', panier_bio: 'activé', coiffeur: 'désactivé', traiteur: 'activé', lavage_auto: 'désactivé' },
//   { header: <SiteBlock label={'bp metz'} />, plomberie: 'activé', panier_bio: 'désactivé', coiffeur: 'activé', traiteur: 'désactivé', lavage_auto: 'activé' },
//   { header: <SiteBlock label={'bp metz'} />, plomberie: 'désactivé', panier_bio: 'activé', coiffeur: 'activé', traiteur: 'activé', lavage_auto: 'activé' },
//   { header: <SiteBlock label={'bp metz'} />, plomberie: 'activé', panier_bio: 'activé', coiffeur: 'désactivé', traiteur: 'activé', lavage_auto: 'désactivé' },
//   { header: <SiteBlock label={'bp metz'} />, plomberie: 'activé', panier_bio: 'désactivé', coiffeur: 'activé', traiteur: 'désactivé', lavage_auto: 'activé' },
//   { header: <SiteBlock label={'bp metz'} />, plomberie: 'désactivé', panier_bio: 'activé', coiffeur: 'activé', traiteur: 'activé', lavage_auto: 'activé' },
// ];

const SitesManagement = () => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [headerList, setHeaderList] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [sitesList, setSitesList] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [filteredSiteData, setFilteredSiteData] = useState([]);
  const [filterConditions, setFilterConditions] = useState({
    services: [],
  });

  const {
    sitesData,
    conciergesData,
    servicesData,
    servicesActifsData,
    selectedSiteListData,
  } = useSelector(
    state => ({
      sitesData: getConciergeSitesData(state),
      conciergesData: getConciergeConciergesData(state),
      servicesData: getConciergeServicesData(state),
      servicesActifsData: getConciergeServicesActifsData(state),
      selectedSiteListData: getSiteManagementSelectedSiteData(state),
    }),
    shallowEqual
  );

  useEffect(() => {
    if (selectedSiteListData !== null && selectedSiteListData.length > 0) {
      setSelectedList(selectedSiteListData);
    }
  }, [selectedSiteListData]);

  useEffect(() => {
    if (sitesData && selectedList.length > 0) {
      const newFilteredSiteData = _.compact(_.map(selectedList, (selected) => {
        return _.find(sitesData, (site) => site.id === selected.value);
      }));
      setFilteredSiteData(newFilteredSiteData);
    }
  }, [sitesData, selectedList]);

  useEffect(() => {
    if (sitesData != null) {
      const newList = sitesData.map((site) => {
        return { label: site.name, value: site.id }
      })
      setSitesList(newList);
    }
  }, [sitesData]);

  useEffect(() => {
    if (filteredSiteData && conciergesData) {
      const newHeaderList = _.map(filteredSiteData, (site, index) => {
        const concierge = _.find(conciergesData, (concierge) => concierge.company_site.id === site.id);
        const data = {
          id: concierge?.id || 'new',
          siteId: site.id
        }
        return ({
          id: `concierge ${site.id}`,
          label: <SiteBlock key={index} data={data} label={site.name} />,
          disableSort: true
        })
      })
      setHeaderList([
        { id: "service", label: "Services" },
        ...newHeaderList,
      ]);
      setIsLoading(false);
    }
  }, [filteredSiteData, conciergesData]) // To pack the headerList

  const checkServiceFilter = (form, service) => {
    if (form?.services.find((item) => String(item.value) === String(service.id))) return true;
    return false
  }

  const filterCondition = (form, item) => {
    const conditionCheckArray = [];
    if (form.services.length !== 0) conditionCheckArray.push(checkServiceFilter(form, item))
    if (conditionCheckArray.length > 0) return !conditionCheckArray.includes(false);
    return true;
  }

  useEffect(() => {
    if (filteredSiteData && servicesData && servicesActifsData) {
      const newItemList = _.compact(_.map(servicesData, (service) => {
        if (filterCondition(filterConditions, service) === false) return;
        let newItem = {
          service: service.name,
        }
        _.map(filteredSiteData, (site, index) => {
          const servicesActif = _.find(servicesActifsData, (data) => data?.company_site?.id === site.id && data.service === service.id);
          newItem = {
            ...newItem,
            [`concierge ${site.id}`]:
              <ItemBlock
                key={index}
                data={servicesActif}
                handleClick={() => openServiceActifsDrawer({ id: servicesActif?.id || 'new', siteId: site.id, serviceId: service.id })}
              />
          }
        })
        return newItem;

      }));
      setItemList(newItemList);
    }
  }, [filteredSiteData, filterConditions, servicesData, servicesActifsData]); // To pack the itemList

  const openConciergeEditDrawer = ({ id, siteId }) => {
    history.push(`${path}/conceirgeEdit/${siteId}/${id}`);
  }

  const openServiceActifsDrawer = ({ id, siteId, serviceId }) => {
    history.push(`${path}/serviceActifEdit/${siteId}/${serviceId}/${id}`);
  }

  const SiteBlock = ({ data, label }) => {
    return (
      <StyledSiteBlock>
        <StyledTitle title={label} />
        <Tooltip
          title={data.id === 'new' ? `Cliquez sur le crayon a droit pour compléter la présentation de votre conciergerie` : ''}
        >
          <StyledSiteBlockIcon onClick={() => openConciergeEditDrawer(data)} isNew={data.id === 'new'}>
            <EditIcon />
          </StyledSiteBlockIcon>
        </Tooltip>
      </StyledSiteBlock>
    )
  }

  const saveSiteAndSelect = (e, value) => {
    dispatch(saveSiteManagementSelectedSiteList(value));
    setSelectedList(value)
  }

  return (
    <GenericLayout
      id="siteManagementLayout"
      className="siteManagementLayout"
    >
      <GenericAdminFrontHeader
        headerTitle={'Sites et Services'}
        headerDesc={'Visualisez et paramétrez vos services et vos sites'}
      />
      {isLoading &&
        <GenericLoader />
      }
      {!isLoading &&
        <>
          <StyledMultipleSelect
            title={"Sélectionnez votre site"}
            list={sitesList}
            value={selectedList}
            limitItem={10}
            hasSelectAll={true}
            onChange={saveSiteAndSelect}
          />
          <ServiceActifFilter servicesData={servicesData} setFilterConditions={setFilterConditions} />
          <GenericTable
            headerList={headerList}
            itemList={itemList}
          />
        </>
      }
      <Routes />
    </GenericLayout>
  )
}

export default SitesManagement;