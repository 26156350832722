import styled from 'styled-components'
import breakpoints from "../../../../theme-style/breakpoints";
import { NavLink } from "react-router-dom";

import GenericTitleWithToolTip from "../../../../components/molecules/GenericTitleWithToolTip";

export const Container = styled.div`
  width: 100%;
  height: auto;
  min-height: calc(100vh - 110px);
  background-color: #357ED3;
  padding: 15px;
  box-sizing: border-box;
  >:not(:last-child) {
    margin-bottom: 1.5rem;
  };
  @media ${breakpoints.mobileXL} {
    padding: 25px;
  }
`;

export const StyledTitleWithToolTip = styled(GenericTitleWithToolTip)`
  color: white;
  font-size: 16px;
  font-weight: 700;
  padding: 0 0 8px 0;
  @media ${breakpoints.mobileXL} {
    padding: 0 0 15px 0;
  }
`;

export const AdminWrapper = styled.div`

`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  .genericSwitch {
    .textTitle{
      font-size: 0.9rem;
      font-weight: 700;
      color: white;
    }
  }
`

export const SkillsDomainsItemCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;
  border-radius: 100px;
  background-color: #397dd2;
  font-size: 1rem;
  color: #FFFFFF;
  font-weight: 700;
  margin-right: 25px;
`;

export const AdminList = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 0;
  margin: 0;
`;

export const ArrowIconWrapper = styled.span`
  max-width: 7px;
  max-height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 auto;
  svg path{
    transition: all .2s ease-out;
  }
`;


export const AdminItemTitle = styled.strong`
  font-size: 16px;
  color: #5A5A5A;
  font-weight: 600;
  text-align: left;
  transition: all .2s ease-out;
`;

export const AdminItem = styled(NavLink)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 25px;
  margin: 0 0 10px 0;
  background-color: #FFFFFF;
  height: auto;
  cursor: pointer;
  transition: all .2s ease-out;
  text-decoration: none;
  list-style: none;
  border-radius: 6px;
  &:last-child{
    border-bottom: 0;
  }
  &:hover{
    background-color: #FAFAFA;
    ${AdminItemTitle}{
      color: #4A90E2;
    }
    ${ArrowIconWrapper}{
      svg{
        path{
          fill: #397dd2;
          stroke: #397dd2;
        }
      }
    }
  }
`;
