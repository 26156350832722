import styled from "styled-components";
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';

import { COLORS } from '../../../constants/design';

export const StyledMainContainer = styled.div`
`;

export const StyledMenu = styled(Menu)`
  && {
    .MuiPaper-root.MuiMenu-paper {
      border-radius: 5px;
    }
    .MuiPaper-root.MuiMenu-paper .MuiList-root {
      li {
        font-size: 0.8rem;
        min-width: 10vw;
        &:hover {
          background-color: ${COLORS.PRIMARY_BLUE};
          color: ${COLORS.WHITE};
        }
      }
    }
  }
`;

export const StyledButton = styled(Button)`
  && {
    &.MuiButton-root {
      font-size: 0.7rem;
      width: 100%;
      border-radius: 10px;
      padding: 20px 20px;
      background-color: ${COLORS.WHITE};
      box-shadow: 1px 1px 3px 1px rgb(0 0 0 / 20%);
      &:hover {
        background-color: ${COLORS.LIGHT_GREY_1};
      }
    }
    .MuiButton-label {
      color: ${COLORS.DARK_GREY};
    }
  }
`;

export const StyledInputDiv = styled.div`
  border: 1px solid ${COLORS.LIGHT_GREY_4};
  border-radius: 3px;
  color: ${COLORS.DARK_GREY};
  padding: 10px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
`;

export const StyledInputText = styled.div`
  margin-right: 20px;
`;

export const StyledIconDiv = styled.div`
  color: ${COLORS.DARK_GREY};
  display: flex;
  align-self: center;
  justify-self: center;
  position: absolute;
  right: 10px;
`;