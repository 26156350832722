import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import ReactQuill from 'react-quill';
import Dropzone from 'react-dropzone';
// import ImageResize from 'quill-image-resize-module-withfix';
// import ImageCompress from 'quill-image-compress';
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import AssignmentIcon from '@material-ui/icons/Assignment';
import LockIcon from '@material-ui/icons/Lock';
import ChatIcon from '@material-ui/icons/Chat';
import AttachmentIcon from '@material-ui/icons/Attachment';
import { Tooltip } from '@material-ui/core';

import GenericLoader from '../../../../molecules/GenericLoader';
import GenericMaterialMenu from '../../../../molecules/GenericMaterialMenu';
import GenericTitleWithIcon from '../../../../molecules/GenericTitleWithIcon';
import { resizeImage } from '../../../../../helpers/resizeImage';
import { toaster } from '../../../../../helpers/toaster';

import { ticketStatusArray } from '../../../../../constants/ticketConstant';

import TicketAttachments from '../TicketAttachments';

import {
  MESSENGER_TYPE,
  macrosTemplate
} from '../../constants';

import {
  StyledDropZoneDiv,
  StyledInputContainer,
  StyledQuillContainer,
  StyledQuillSubmitContainer,
  StyledCustomToolbar,
  StyledTabContainer,
  BlockingDiv,
  StyledTooltip,
  StyledFileInputContainer,
  StyledButton,
  StyledFileInput,
} from './style';

// Quill.register('modules/ImageResize', ImageResize);
// Quill.register('modules/imageCompress', ImageCompress);

var formats = [
  'background',
  'bold',
  'color',
  'font',
  'code',
  'italic',
  'link',
  'size',
  'strike',
  'script',
  'underline',
  'blockquote',
  'header',
  'indent',
  'list',
  'align',
  'direction',
  'code-block',
  'formula'
  // 'image'
  // 'video'
];

const TicketInput = ({
  ticketRequest,
  messengertype,
  isPreview,
  value,
  onSubmit,
  onChange,
  onOpenModal,
  fileList,
  setFileList,
  category,
  setCategory,
  setMessageImageModal,
  isModalMode = false,
  isLoading = false
}) => {
  const [currentValue, setCurrentValue] = useState('');
  const [macroChoice, setMacroChoice] = useState([]);

  const acceptedFormat = "image/png, image/jpeg, image/jpg, application/pdf";

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  useEffect(() => {
    if (messengertype === MESSENGER_TYPE.CONCIERGE) {
      const clientName = ticketRequest.collaborator.firstname;
      const ticketNumber = ticketRequest.id;
      const newTemplate = _.map(macrosTemplate, (macro) => {
        const newValue = _.replace(macro.value, /\[\w+\]/g, (match) => {
          if (match === '[name]') return clientName;
          else if (match === '[order_number]') return ticketNumber;
        });
        return ({
          ...macro,
          value: newValue
        });
      });
      setMacroChoice(newTemplate);
    }
  }, [messengertype, ticketRequest]);

  const modules = {
    // ImageResize: {
    //   modules: ['Resize', 'DisplaySize'],
    // },
    // imageCompress: {
    //   quality: 0.7,
    //   maxWidth: 1000,
    //   maxHeight: 1000,
    //   imageType: 'image/jpeg',
    //   debug: false,
    //   suppressErrorLogging: false,
    // },
    toolbar: messengertype === MESSENGER_TYPE.CLIENT ? false :
      {
        container: [['bold', 'italic', 'underline'], ['link'], [{ 'list': 'bullet' }, { 'list': 'ordered' }, 'clean']],
      },
  };

  const handleAttachment = async (files) => {
    if (files.length > 5) {
      toaster(`Un maximum de 5 pièces jointes est autorisé`, 'error');
    }
    else {
      const errorPdf = [];
      const errorImage = [];
      const newFileList = _.cloneDeep(fileList);
      // console.log('newFileList', newFileList);
      for (const file of files) {
        if (newFileList.length > 4) {
          toaster(`Un maximum de 5 pièces jointes est autorisé`, 'error');
          break;
        }
        if (file.type === 'application/pdf') {
          file.size > (2000 * 1000) ? errorPdf.push(file) : newFileList.push(file);
        }
        else if (file.type !== 'image/heic') {
          if (file.size > (1000 * 1000)) {
            try {
              const image = await resizeImage({ file: file });
              newFileList.push(image);
            } catch (err) {
              errorImage.push(file);
              console.error(err);
            }
          }
          else newFileList.push(file);
        }
        else {
          newFileList.push(file);
        }
      }
      _.map(errorPdf, (pdf) => {
        toaster(`${pdf.name} exède 2 Mo, merci de réduire le document ou d’en joindre un nouveau`, 'error');
      })
      _.map(errorImage, (img) => {
        toaster(`Quelque chose ne va pas avec cette image ${img.name}, Veuillez en choisir un autre`, 'error');
      })
      setFileList(newFileList);
    }
  };

  const handleOnChange = (newValue) => {
    setCurrentValue(newValue);
  };

  const handleOnBlur = () => {
    onChange(currentValue);
  };

  return (
    <Dropzone
      accept={acceptedFormat}
      onDrop={(files) => handleAttachment(files)}
    >
      {({ getRootProps, getInputProps }) => (
        <StyledDropZoneDiv
          {...getRootProps({
            onClick: event => event.stopPropagation(),
          })}>
          {/* <input {...getInputProps()} /> */}
          <StyledInputContainer messengertype={messengertype} category={category} isModalMode={isModalMode}>
            <StyledCustomToolbar>
              {messengertype === MESSENGER_TYPE.CONCIERGE &&
                <>
                  <GenericMaterialMenu
                    title={"Modèle"}
                    toolTipText={"Le modèle de texte vous permet d'écrire facilement un type de message à l’intention du bénéficiaire"}
                    startIcon={<FlashOnIcon style={{ fontSize: '1rem' }} />}
                    variant={"button"}
                    list={macroChoice}
                    onChange={(val) => onChange(val)}
                  />
                  <StyledTabContainer category={category}>
                    <Tabs
                      value={category}
                      TabIndicatorProps={{ style: { display: "none" } }}
                      onChange={(event, val) => setCategory(val)}
                    >
                      <Tab disableRipple label={<GenericTitleWithIcon title={'Message'} Icon={<ChatIcon style={{ fontSize: '1rem' }} />}></GenericTitleWithIcon>} />
                      <Tooltip title="La note est exclusivement réservée au(x) concierge(s). Elle sert à mentionner des informations importantes concernant la demande. Le bénéficiaire n’y a jamais accès.">
                        <Tab disableRipple label={<GenericTitleWithIcon title={'Note'} Icon={<AssignmentIcon style={{ fontSize: '1rem' }} />}></GenericTitleWithIcon>} />
                      </Tooltip>
                    </Tabs>
                  </StyledTabContainer>
                  {!isModalMode &&
                    <StyledTooltip title={'Ouvrir dans un modale'}>
                      <OpenInNewIcon onClick={() => onOpenModal(true)} style={{ fontSize: 18 }} />
                    </StyledTooltip>
                  }
                </>
              }
            </StyledCustomToolbar>
            <StyledQuillContainer onBlur={handleOnBlur}>
              <ReactQuill
                theme="snow"
                value={currentValue}
                onChange={(data) => handleOnChange(data)}
                modules={modules}
                formats={formats}
              />
            </StyledQuillContainer>
            <StyledQuillSubmitContainer hasContent={(currentValue === "<p><br></p>") ? false : true}>
              <SendRoundedIcon onClick={() => onSubmit(category)} />
            </StyledQuillSubmitContainer>
          </StyledInputContainer>
          <TicketAttachments
            fileList={fileList}
            setFileList={setFileList}
            setMessageImageModal={setMessageImageModal}
          />
          <StyledFileInputContainer>
            <StyledTooltip title={'Joindre une image ou un document (Max 5 fichiers)'}>
              <StyledButton component="label">
                <AttachmentIcon style={{ fontSize: 18 }} />
                <StyledFileInput
                  onChange={(e) => handleAttachment(e.target.files)}
                  type={"file"}
                  accept={acceptedFormat}
                  multiple
                />
              </StyledButton>
            </StyledTooltip>
          </StyledFileInputContainer>
          {((ticketRequest.status === ticketStatusArray[0] && messengertype === MESSENGER_TYPE.CONCIERGE) || isPreview) &&
            <BlockingDiv>
              <LockIcon style={{ fontSize: '1rem', marginRight: '10px' }} />
              {isPreview ? 'Vue de la messagerie bénéficiaire' : 'Veuillez changer le statut pour commencer votre prise en charge'}
            </BlockingDiv>
          }
          {isLoading &&
            <BlockingDiv>
              <GenericLoader />
            </BlockingDiv>
          }
        </StyledDropZoneDiv>
      )}
    </Dropzone>
  )
};

export default TicketInput;