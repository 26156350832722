import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { shallowEqual, useSelector } from "react-redux";

import { BLOCK_KEYS } from '../../../../constants/sideBarConstants';

import SidebarFrontBaseLayout from "../../layouts/sidebarFrontBaseLayout";
import ArrowRightIcon from '../../components/atoms/icons/arrowRight';

import GenericSwitch from '../../../../components/molecules/GenericSwitch';

import UserInfoAndWeatherModule from '../../components/organisms/UserInfoAndWeatherModule';
import DisconnectModule from '../../components/organisms/DisconnectModule';

// Hooks
import useTheme from "../../../../theme-style/useThemeCustomHook";


import {
  isUserHaveCompanyManagerRole,
  isUserHaveConciergeRole
} from '../../../../store/config/selectors';

import {
  saveConsoleTabSwitch
} from '../../../../store/persistReducers/actions';

import {
  getConsoleTabSwitchData
} from '../../../../store/persistReducers/selectors';

import {
  getConsoleContext,
} from '../../../../store/company/selectors';

import {
  Container,
  StyledTitleWithToolTip,
  AdminWrapper,
  TitleWrapper,
  AdminList,
  AdminItem,
  ArrowIconWrapper,
  AdminItemTitle,
  SkillsDomainsItemCircle
} from './style'

const Admin = () => {
  const [consoleSwitch, setConsoleSwitch] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();

  const {
    companyManagerRole,
    conciergeRole,
    consoleSwitchTab,
    consoleContext
  } = useSelector(
    state => ({
      companyManagerRole: isUserHaveCompanyManagerRole(state),
      conciergeRole: isUserHaveConciergeRole(state),
      consoleSwitchTab: getConsoleTabSwitchData(state),
      consoleContext: getConsoleContext(state)
    }),
    shallowEqual
  );

  useEffect(() => {
    if (consoleSwitchTab) {
      setConsoleSwitch(consoleSwitchTab);
    }
  }, [consoleSwitchTab]);

  const onSwitchChange = () => {
    dispatch(saveConsoleTabSwitch(!consoleSwitchTab));
    setConsoleSwitch(!consoleSwitchTab);
  }


  return (
    <SidebarFrontBaseLayout>
      <Container>
        {consoleContext?.[BLOCK_KEYS.WEATHER] &&
          <UserInfoAndWeatherModule />
        }
        <AdminWrapper>
          <TitleWrapper>
            <StyledTitleWithToolTip
              title={"Ma console"}
              toolTipText={"Sélectionnez votre espace afin d’accéder à la console correspondante."}
            />
            <GenericSwitch
              title={'Ouvrir dans un nouvel onglet'}
              checked={consoleSwitch}
              onChange={onSwitchChange}
            />
          </TitleWrapper>

          <AdminList>
            {companyManagerRole && (
              <AdminItem to={`admin`} target={consoleSwitch ? "_blank" : "_self"}>
                <SkillsDomainsItemCircle>
                  A
                </SkillsDomainsItemCircle>
                <AdminItemTitle>
                  Espace admin
                </AdminItemTitle>
                <ArrowIconWrapper>
                  <ArrowRightIcon color={theme.colors.grey4} />
                </ArrowIconWrapper>
              </AdminItem >
            )}

            {conciergeRole && (
              <AdminItem to={`espace-conciergerie`} target={consoleSwitch ? "_blank" : "_self"}>
                <SkillsDomainsItemCircle>
                  C
                </SkillsDomainsItemCircle>
                <AdminItemTitle>
                  Espace conciergerie
                </AdminItemTitle>
                <ArrowIconWrapper>
                  <ArrowRightIcon color={theme.colors.grey4} />
                </ArrowIconWrapper>
              </AdminItem >
            )}
          </AdminList>
        </AdminWrapper>

        {consoleContext?.[BLOCK_KEYS.ACCOUNT_DISCONNECT] &&
          <DisconnectModule />
        }
      </Container>
    </SidebarFrontBaseLayout>
  )
}

export default Admin
