import React, { useEffect } from 'react';
import {
  shallowEqual,
  useDispatch,
  useSelector,
} from "react-redux";
import {
  useRouteMatch,
} from "react-router-dom";

import { BLOCK_KEYS } from '../../../../constants/sideBarConstants';

// Layout
import SidebarFrontBaseLayout from "../../layouts/sidebarFrontBaseLayout";

// Components
import ArrowRightIcon from '../../components/atoms/icons/arrowRight';
import DesktopMessageIcon from '../../components/atoms/icons/desktopMessage';
import SkillDomains from '../../components/organisms/SkillDomains';

// Hooks
import useTheme from "../../../../theme-style/useThemeCustomHook";

// Store
import {
  getExpertiseAreas,
} from '../../../../store/sidebar/actions';

import {
  getExpertiseAreasData,
} from '../../../../store/sidebar/selectors';

import {
  getSkillContext
} from '../../../../store/company/selectors';

// Styles
import {
  StyledTitleWithToolTip,
  Container,
  SkillsDomainsWrapper,
  DesktopMessageIconWrapper,
  SkillsDomainsDiv,
  SkillsDomainsHead,
  SkillsDomainsHeadCircle,
  SkillsDomainsHeadTitle,
  SkillsDomainsList,
  SkillsDomainsItem,
  SkillsDomainsItemCircle,
  SkillsDomainsItemContent,
  SkillsDomainsItemTitle,
  SkillsDomainsItemText,
  ArrowIconWrapper
} from "./style";

const Competences = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { path } = useRouteMatch();

  const {
    expertiseAreas,
    skillContext
  } = useSelector(
    state => ({
      expertiseAreas: getExpertiseAreasData(state),
      skillContext: getSkillContext(state)
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!expertiseAreas) {
      dispatch(getExpertiseAreas());
    }
  }, []);

  return (
    <SidebarFrontBaseLayout
      id="sidebarLayoutSkills"
      className="sidebarLayoutSkills page-container sidebarPage"
    >
      <Container>
        <SkillsDomainsWrapper>
          {skillContext?.[BLOCK_KEYS.SKILL_CATALOG] &&
            <SkillsDomainsDiv>
              <StyledTitleWithToolTip
                title={"Mon catalogue de compétences"}
                toolTipText={"Découvrez tous les sujets auxquels je réponds. Cliquez sur le sujet qui vous intéresse pour avoir un aperçu de la façon dont j'y réponds !"}
              />
              <SkillsDomainsHead>
                <SkillsDomainsHeadCircle>
                  <DesktopMessageIconWrapper>
                    <DesktopMessageIcon color={theme.colors.white} />
                  </DesktopMessageIconWrapper>
                </SkillsDomainsHeadCircle>
                <SkillsDomainsHeadTitle>
                  Cyconia au quotidien
                </SkillsDomainsHeadTitle>
              </SkillsDomainsHead>

              <SkillsDomainsList>
                <SkillsDomainsItem to={`${path}/aide/connaissance`}>
                  <SkillsDomainsItemCircle>
                    F
                  </SkillsDomainsItemCircle>
                  <SkillsDomainsItemContent>
                    <SkillsDomainsItemTitle>
                      Faire connaissance avec Cyconia 🙂
                    </SkillsDomainsItemTitle>
                    <SkillsDomainsItemText>
                      Qui est Cyconia ? Comment fonctionne t-il ?
                    </SkillsDomainsItemText>
                  </SkillsDomainsItemContent>
                  <ArrowIconWrapper>
                    <ArrowRightIcon color={theme.colors.grey4} />
                  </ArrowIconWrapper>
                </SkillsDomainsItem>

                <SkillsDomainsItem to={`${path}/aide/pratique`}>
                  <SkillsDomainsItemCircle>
                    C
                  </SkillsDomainsItemCircle>
                  <SkillsDomainsItemContent>
                    <SkillsDomainsItemTitle>
                      Cyconia pratique ⚙️
                    </SkillsDomainsItemTitle>
                    <SkillsDomainsItemText>
                      Cyconia simplifie votre quotidien
                    </SkillsDomainsItemText>
                  </SkillsDomainsItemContent>
                  <ArrowIconWrapper>
                    <ArrowRightIcon color={theme.colors.grey4} />
                  </ArrowIconWrapper>
                </SkillsDomainsItem>

                <SkillsDomainsItem to={`${path}/aide/echange`}>
                  <SkillsDomainsItemCircle>
                    C
                  </SkillsDomainsItemCircle>
                  <SkillsDomainsItemContent>
                    <SkillsDomainsItemTitle>
                      Cyconia échange 💬
                    </SkillsDomainsItemTitle>
                    <SkillsDomainsItemText>
                      Discuter avec Cyconia... et pourquoi pas ?
                    </SkillsDomainsItemText>
                  </SkillsDomainsItemContent>
                  <ArrowIconWrapper>
                    <ArrowRightIcon color={theme.colors.grey4} />
                  </ArrowIconWrapper>
                </SkillsDomainsItem>

              </SkillsDomainsList>
            </SkillsDomainsDiv>
          }

          {expertiseAreas &&
            expertiseAreas.map((expertiseArea, index) => {
              if ((expertiseArea.title === "Ressources Humaines" && skillContext?.[BLOCK_KEYS.SKILL_RH]) ||
                (expertiseArea.title === "Services Généraux" && skillContext?.[BLOCK_KEYS.SKILL_SG])) {
                return (
                  <div key={index}>
                    <SkillDomains key={index} title={expertiseArea.title} skills={expertiseArea.skills} />
                  </div>
                );
              }
            })
          }
        </SkillsDomainsWrapper>

      </Container>
    </SidebarFrontBaseLayout>
  );

}

export default Competences;
