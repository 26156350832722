import styled from "styled-components";
import Dialog from '@material-ui/core/Dialog';

import { COLORS } from '../../../../../../constants/design';

export const CustomDialog = styled(Dialog)`
  && {
    .MuiDialog-container {
      .MuiDialog-paper {
        &.MuiPaper-rounded {
          width: 100%;
          /* min-height: 70vh; */
          max-height: 100%;
        }
        &.MuiDialog-paperWidthSm {
          max-width: 700px;
        }
      }
    }
  }
`;

export const DialogContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 20px 25px;
  background-color: white;
  border-radius: 6px;
  color: ${COLORS.DARK_GREY};
  font-size: 0.9rem;
  >div:not(:last-child) {
    margin-bottom: 15px;
  };
`;

export const StyledCloseContainer = styled.div`
  position: absolute;
  z-index: 9999;
  color: ${COLORS.DARK_GREY};
  background-color: ${COLORS.LIGHT_GREY_1};
  border-radius: 50%;
  padding: 1px;
  display: flex;
  opacity: 0.8;
  top: 10px;
  right: 10px;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;

export const StyledTitle = styled.div`
  font-weight: 700;
  font-size: 1.1rem;
  margin-bottom: 0px !important;
`;

export const TitleContainer = styled.div`
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  position: relative;
`;

export const ContentContainer = styled.div`
  /* border: 1px solid red; */
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  color: ${COLORS.DARK_GREY};
  max-width: 100%;
  overflow-wrap: break-word;
  flex-wrap: wrap;
  >div:not(:last-child) {
    margin-bottom: 10px;
  };
  ${StyledTitle} {
    font-size: 1rem;
    margin: 20px 0px !important;
  }
`;

export const StyledInfoGroup = styled.div`
  /* border: 1px solid black; */
  display: flex;
`;

export const StyledInfoTitle = styled.div`
  /* border: 1px solid blue; */
  font-weight: 700;
  margin-right: 20px;
  min-width: 240px;
`;

export const StyledInfoText = styled.div`
  /* border: 1px solid red; */
  font-size: 0.85rem;
`;

export const StyledOptionsContainer = styled.div`
  border: 1px solid ${COLORS.LIGHT_GREY_4};
  border-radius: 5px;
  padding: 10px;
  >div:not(:last-child) {
    margin-bottom: 5px;
  };
  ${StyledInfoTitle} {
    margin-right: 10px;
  }
  /* display: flex; */
`;