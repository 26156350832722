import { combineReducers } from "redux";

// Reducers
import authentication from "./authentication/reducers";
import collaborator from "./collaborator/reducers";
import sidebar from "./sidebar/reducers";
import adminFront from "./adminFront/reducers";
import userManagement from "./userManagement/reducers";
import onboarding from "./onboarding/reducers";
import chatbot from "./chatbot/reducers";
import config from "./config/reducers";
import company from "./company/reducers";
import concierge from "./concierge/reducers";
import contentManagement from './contentManagement/reducers';
import getGround from './getGround/reducers';
import sso from './sso/reducers';
import roomBooking from "./roomBooking/reducers";
import contextSetting from "./contextSetting/reducers";
import persistReducers from './persistReducers/reducers';

const rootReducer = () =>
  combineReducers({
    authentication,
    collaborator,
    sidebar,
    adminFront,
    userManagement,
    onboarding,
    chatbot,
    config,
    company,
    concierge,
    contentManagement,
    getGround,
    sso,
    roomBooking,
    contextSetting,
    persistReducers
  });

export default rootReducer;
