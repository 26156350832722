import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { COLORS } from '../../../../../../../../constants/design';
import breakpoints from "../../../../../../../../theme-style/breakpoints";

export const StyledMainContainer = styled.div`
  flex: 0 0 auto;
  box-sizing: border-box;
  color: ${COLORS.DARK_GREY};
  font-size: 0.8rem;
  font-family: 'Quicksand';
  font-weight: 500;
  word-wrap: break-word;
  height: auto;
  max-height: 360px;
  width: auto;
  margin: 5px 0 -15px 0;
  position: relative;
  border-radius: 5px;
  background-color: ${COLORS.WHITE};
  box-shadow: none;
  user-select: none;
  @media ${breakpoints.mobileL} {
    width: 300px;
  }
  @media ${breakpoints.mobileXL} {
    margin: 0;
    // box-shadow: 1px 1px 3px 1px rgb(0 0 0 / 20%);
  }
`;

export const ImagePreviewContainer = styled.div`
  width: 100%;
  height: 140px;
  border-radius: 5px 5px 0px 0px;
  background-image: url(${props => props.image}), url(${props => props.defaultImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
`;

export const ContentContainer = styled.div`
  padding: 15px 2px;
  @media ${breakpoints.mobileXL} {
    padding: 15px 0 0;
  }
  >div:not(:last-child) {
    margin-bottom: 10px;
  };
`;

export const ContentTitle = styled.div`
  color: ${COLORS.PRIMARY_BLUE};
  font-weight: 700;
`;

export const ContentDescription = styled.div`
  display: -webkit-box;
  height: fit-content;
  max-height: 50px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const StyledButton = styled(Button)`
  && {
    &.MuiButton-root {
      color: ${COLORS.DARK_GREY};
      position: relative;
      font-family: Quicksand;
      margin: 5px 0px;
      font-size: 0.75rem;
      font-weight: 500;
      background-color: ${COLORS.LIGHT_GREY_2};
      width: 100%;
      padding: 10px 10px;
      transition: all ease-in .15s;
      border: transparent;
      border-radius: 5px;
      background-image: none;
      text-transform: none;
      &:hover {
        background-color: ${COLORS.LIGHT_GREY_4};
      }

      .MuiButton-label{
        line-height: 1.4;
      }
    }
  }
`;