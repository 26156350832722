import styled from "styled-components";

export const PageContent = styled.div`
  display: flex;
  flex: 1;
`;

export const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100vh;
`;
