import React from "react";

const BackIcon = ({
  color = "#FFFFFF",
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon backIcon"
      width={width}
      height={height}
      viewBox="0 0 17 12"
      fill="none"
    >
      <path
        d="M15.72 5.249H2.554l3.962-3.962A.749.749 0 105.46.229l-5.24 5.24a.749.749 0 000 1.058l5.24 5.24a.749.749 0 001.058-1.059L2.555 6.746H15.72a.749.749 0 000-1.497z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export default BackIcon;
