import styled from "styled-components";
import IconButton from '@material-ui/core/IconButton';

import { COLORS } from '../../../../constants/design';
import { BOOKING_CANCEL_STATUS } from '../../../../constants/bookingConstants';

const CANCEL_STATUS_COLORS = {
  [BOOKING_CANCEL_STATUS.PAYABLE]: COLORS.RED,
  [BOOKING_CANCEL_STATUS.NON_PAYABLE]: COLORS.LIGHT_BLUE_1,
  [BOOKING_CANCEL_STATUS.NA]: COLORS.GREEN_99,
}

export const MainContainer = styled.div`
  /* border: 1px solid black; */
  height: 75vh;
`;

export const HeaderGroupContainer = styled.div`
  display: flex;
  >:not(:last-child) {
    margin-right: 10px;
  };
`;

export const HeaderButton = styled.button`
  background-color: ${COLORS.PRIMARY_BLUE};
  color: white;
  cursor: pointer;
  padding: 0px 20px;
  border-radius: 6px;
  font-size: 0.825rem;
`

export const TableButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TableIcon = styled(IconButton)`
  &&{
    height: fit-content;
    .MuiSvgIcon-root {
      font-size: 1.2rem;
    }
  }
`;

export const TableWrapper = styled.div`
  padding-bottom: 20px;
  .genericTable {
    max-height: 75vh;
    .MuiTableCell-head .MuiButtonBase-root {
      font-size: 0.825rem;
    }
    .MuiTableCell-root {
      font-size: 0.825rem;
    }
  }
`;

export const TableItemWithStatus = styled.div`
  padding: 5px 10px;
  border-radius: 5px;
  background-color: ${props => CANCEL_STATUS_COLORS[props.cancelStatus]};
  color: white;
`;