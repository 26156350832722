export const COMPANY_SETTING_KEYS = {
  HIDE_REGISTERATION_TAB: 'HIDE_REGISTERATION_TAB',
  HIDE_DOWNLOAD_POP_UP: 'HIDE_DOWNLOAD_POP_UP',
}

export const COMPANY_SETTINGS = {
  [COMPANY_SETTING_KEYS.HIDE_DOWNLOAD_POP_UP]: {
    label: 'Hide download pop up',
  },
  [COMPANY_SETTING_KEYS.HIDE_REGISTERATION_TAB]: {
    label: 'Hide registeration tab',
  },
}