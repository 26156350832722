const components = colors => {
  const buttons = {
    primary: {
      backgroundColor: colors.white,
      textColor: colors.black,
      hover: {
        backgroundColor: colors.white,
        textColor: colors.main,
      },
    },
    darkPrimary: {
      backgroundColor: colors.black,
      textColor: colors.white,
      hover: {
        backgroundColor: colors.black,
        textColor: colors.main,
      },
    },
    secondary: {
      backgroundColor: colors.main,
      textColor: colors.black,
      hover: {
        backgroundColor: colors.main,
        textColor: colors.black,
      },
    },
    secondaryNoBackground: {
      backgroundColor: colors.ghost,
      textColor: colors.black,
      hover: {
        backgroundColor: colors.main,
        textColor: colors.black,
      },
    },
    text: {
      backgroundColor: colors.ghost,
      textColor: colors.black,
      hover: {
        backgroundColor: colors.main,
        textColor: colors.black,
      },
    },
    textWithBackground: {
      backgroundColor: colors.ghost,
      textColor: colors.black,
      hover: {
        backgroundColor: colors.grey,
        textColor: colors.black,
      },
    },
    textWithBackgroundGrey: {
      backgroundColor: colors.ghost,
      textColor: colors.black,
      hover: {
        backgroundColor: colors.grey,
        textColor: colors.black,
      },
    },
    facebook: {
      backgroundColor: colors.facebook,
      textColor: colors.white,
      hover: {
        backgroundColor: colors.facebookDark,
        textColor: colors.white,
      },
    },
    headerProduct: {
      backgroundColor: colors.ghost,
      textColor: colors.ghost,
      hover: {
        backgroundColor: colors.ghost,
        textColor: colors.ghost,
      },
    },
    headerApp: {
      backgroundColor: colors.white,
      textColor: colors.black,
      hover: {
        backgroundColor: colors.white,
        textColor: colors.main,
      },
    },
    boardAddPlat: {
      backgroundColor: colors.white,
      textColor: colors.greyDark,
      hover: {
        backgroundColor: colors.grey,
        textColor: colors.black,
      },
    },
    boardAddSection: {
      backgroundColor: colors.ghost,
      textColor: colors.black,
      hover: {
        backgroundColor: colors.black,
        textColor: colors.white,
      },
    },
    boardAddSection2: {
      backgroundColor: colors.ghost,
      textColor: colors.greyDark,
      hover: {
        backgroundColor: "#F1F1F1",
        textColor: colors.black,
      },
    },
    qrCodeUrl: {
      backgroundColor: colors.grey,
      textColor: colors.greyDark,
      hover: {
        backgroundColor: colors.greyDark,
        textColor: colors.black,
      },
    },
    themeDetailsArianeLink: {
      backgroundColor: colors.ghost,
      textColor: colors.greyDarkMedium,
      hover: {
        backgroundColor: colors.ghost,
        textColor: colors.main,
      },
    },
    themeDetailsCloseBtn: {
      backgroundColor: colors.ghost,
      textColor: colors.greyDark,
      hover: {
        backgroundColor: colors.grey,
        textColor: colors.black,
      },
    },
    kitchenSidebar: {
      backgroundColor: colors.kitchenMain,
      textColor: colors.white,
      hover: {
        backgroundColor: colors.kitchenSecondary,
        textColor: colors.main,
      },
    },
    kitchenCtaMain: {
      backgroundColor: colors.kitchenMain,
      textColor: colors.white,
      hover: {
        backgroundColor: colors.main,
        textColor: colors.black,
      },
    },
    kitchenCtaMainEdit: {
      backgroundColor: colors.main,
      textColor: colors.black,
      hover: {
        backgroundColor: colors.mainDark,
        textColor: colors.black,
      },
    },
    kitchenCtaDark: {
      backgroundColor: colors.kitchenMain,
      textColor: colors.white,
      hover: {
        backgroundColor: colors.kitchenSecondary,
        textColor: colors.white,
      },
    },
    kitchenCtaDarkwithBorder: {
      backgroundColor: colors.kitchenMain,
      textColor: colors.white,
      hover: {
        backgroundColor: colors.kitchenSecondary,
        textColor: colors.main,
      },
    },
    productTourNavigation: {
      backgroundColor: colors.main10,
      textColor: colors.mainDark,
      hover: {
        backgroundColor: colors.main50,
        textColor: colors.mainDark,
      },
    },
    productTourNavigationLanguage: {
      backgroundColor: colors.greyBackground,
      textColor: colors.mainDark,
      hover: {
        backgroundColor: colors.main80,
        textColor: colors.mainDark,
      },
    },
    qrCodeStickerWelcome: {
      backgroundColor: colors.black10,
      textColor: colors.black,
      hover: {
        backgroundColor: colors.black,
        textColor: colors.white,
      },
    },
    qrCodeStickerOrders: {
      backgroundColor: colors.main,
      textColor: colors.black,
      hover: {
        backgroundColor: colors.main95,
        textColor: colors.black,
      },
    },
  };

  return {
    buttons,
  };
};

export default components;
