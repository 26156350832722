import styled from "styled-components";
import Button from '@material-ui/core/Button';

import { COLORS } from "../../../constants/design";

export const StyledButton = styled(Button)`
  && {
    &.MuiButton-root {
      text-transform: none;
      color: white;
      width: 30%;
      font-size: 0.85rem;
      padding: 10px 0px;
      border-radius: 8px;
      background-color: ${COLORS.PRIMARY_BLUE};
      :hover {
        background-color: ${COLORS.PRIMARY_DEEP_BLUE};
      }
    }
  }
`;