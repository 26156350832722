import React from "react";

const HelpIcon = ({
  color = "#FFFFFF",
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon helpIcon"
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M6.98.85a.648.648 0 01.206 1.261l-.103.027-.103.009H2.495a.352.352 0 00-.322.21l-.019.062-.007.076v8.97c0 .145.089.271.21.322l.062.019.076.007H6.98a.648.648 0 01.206 1.262l-.103.027-.103.008H2.495a1.648 1.648 0 01-1.617-1.347l-.021-.147-.007-.15V2.494c0-.798.574-1.473 1.347-1.617l.147-.021.151-.007H6.98zm2.03 2.685a.65.65 0 01.738-.132l.089.051.09.074 3.03 2.99a.648.648 0 01.118.762l-.058.093-.06.069-3.03 2.99a.646.646 0 01-.917-.007.648.648 0 01-.115-.747l.054-.091.068-.078 1.905-1.881H5.485a.648.648 0 01-.618-.45l-.023-.102-.007-.096c0-.28.178-.522.442-.613l.103-.027.103-.008 5.437-.001-1.905-1.88a.648.648 0 01-.127-.747l.052-.088.068-.081z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export default HelpIcon;
