import React from 'react';

// Styles
import {
	Container,
	Content,
	CloseButton,
} from "./style";

const InstallPwa = ({
	variant = "contained",
	onClick,
	contentButton = "PRESS ME",
	children,
	...rest
}) => {

	return (
		<Container>
			<CloseButton
				variant={variant}
				onClick={onClick}
				{...rest}
			>
				{contentButton}
			</CloseButton>
			<Content>
				{children}
			</Content>
		</Container>
	);
}

export default InstallPwa;