import { useState, useRef, useCallback } from "react";

export default function useDrag() {
  const [clicked, setClicked] = useState(false);
  const [dragging, setDragging] = useState(false);
  const position = useRef(0);

  const dragStart = useCallback((event) => {
    position.current = event.clientX;
    setClicked(true);
  }, []);

  // NOTE: need some delay so item under cursor won't be clicked
  const dragStop = useCallback(() =>
    window.requestAnimationFrame(() => {
      setDragging(false);
      setClicked(false);
    }), []);

  const dragMove = (event, cb) => {
    const newDiff = position.current - event.clientX;
    const movedEnough = Math.abs(newDiff) > 5;

    if (clicked && movedEnough) {
      setDragging(true);
    }

    if (dragging && movedEnough) {
      position.current = event.clientX;
      cb(newDiff);
    }
  };

  return {
    dragStart,
    dragStop,
    dragMove,
    dragging, // this will be true is the current action is dragging
    position,
    setDragging
  };
}
