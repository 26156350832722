import React from "react"
import ReactGoogleMapLoader from "react-google-maps-loader"
// import { GooglePlacesSuggest } from 'react-google-places-suggest'
// import ReactGooglePlacesSuggest from "react-google-places-suggest";
import ReactGooglePlacesSuggest from 'react-google-places-suggest'
import { IconSearch2 } from "../../assets/icons/Icon";
// import { IconSearch2 } from "../../helpers/Icon";

const MY_API_KEY = process.env.REACT_APP_API_GMAP_PLACES;

export default class GoogleSuggest extends React.Component {
    state = {
        search: "",
        value: this.props.defaultAddress,
        lat: "",
        lng: "",
    }

    handleInputChange = e => {
        if (e.target.value.length > 8) {
            this.setState({ search: e.target.value, value: e.target.value });
            this.props.setAddress(e.target.value);
        }
        else {
            this.setState({ search: "", value: e.target.value });
            if (e.target.value === "") {
                this.props.setAddress("", 0, 0);
            }
        }

    }

    handleSelectSuggest = (geocodedPrediction, originalPrediction) => {
        const address = geocodedPrediction.formatted_address;
        const lat = geocodedPrediction.geometry.location.lat();
        const lng = geocodedPrediction.geometry.location.lng();

        this.setState({
            search: "",
            value: address,
            lat: lat,
            lng: lng,
        });

        if (this.props.getCoordinates) {
            this.props.setAddress(address, lat, lng);
        } else {
            this.props.setAddress(address);
        }
    }

    render() {
        const { search, value } = this.state
        return (
            <React.Fragment>
                <ReactGoogleMapLoader
                    params={{
                        key: MY_API_KEY,
                        libraries: "places",
                    }}
                    render={googleMaps =>
                        googleMaps && (
                            <ReactGooglePlacesSuggest
                                googleMaps={googleMaps}
                                autocompletionRequest={{
                                    input: search,
                                    componentRestrictions: { country: "fr" }
                                    // Optional options
                                    // https://developers.google.com/maps/documentation/javascript/reference?hl=fr#AutocompletionRequest
                                }}
                                // Optional props
                                onSelectSuggest={this.handleSelectSuggest}
                                textNoResults="Pas de résultat" // null or "" if you want to disable the no results item

                            >
                                <input
                                    type="text"
                                    value={value}
                                    placeholder={this.props.placeholder}
                                    onChange={this.handleInputChange}
                                    className="chatbot-m-custom-search-location__input"
                                />
                            </ReactGooglePlacesSuggest>
                        )
                    }
                />
                <div className="chatbot-m-custom-search-location__icon">
                    <IconSearch2 />
                </div>
            </React.Fragment>
        )
    }
}