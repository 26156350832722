import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Button from '@material-ui/core/Button';

// import Loader from '../Loader';
import Loader from '../../molecules/GenericLoader';

import {
  StyledLinkModal,
  StyledLinkHeader,
  StyledLinkTitle,
  IframeContainer,
  LoaderContainer
} from './style';

const LinkModal = ({
  linkModalData,
  onClose,
}) => {
  const { source, title, target } = linkModalData;
  const [showLinkModalLoader, setShowLinkModalLoader] = useState(true);

  const closeLoader = () => {
    setShowLinkModalLoader(false);
  }

  return (
    <StyledLinkModal
      open={true}
      onClose={onClose}
      target={target}
      hideCloseButton
    >
      <StyledLinkHeader>
        <Button
          onClick={onClose}
          startIcon={<ArrowBackIosIcon />}
        >
          {`Retour`}
        </Button>
        <StyledLinkTitle>{title}</StyledLinkTitle>
      </StyledLinkHeader>
      <IframeContainer>
        {showLinkModalLoader &&
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        }
        <iframe
          title={"linkModal"}
          width={"100%"}
          height={"100%"}
          src={source}
          onLoad={closeLoader}
        />
      </IframeContainer>
    </StyledLinkModal>
  )
}

LinkModal.propTypes = {
  linkModalData: PropTypes.object,
  onClose: PropTypes.func,
}

export default LinkModal;