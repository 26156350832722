import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  box-sizing: border-box;
`;

export const StyledTitle = styled.div`
  width: 40%;
  font-weight: 600;
  margin-right: 10px;
`;

export const StyledValue = styled.div`
  width: 60%;
`;