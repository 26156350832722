import styled from 'styled-components';
import breakpoints from "../../theme-style/breakpoints";

export const LogoIconWrapper = styled.span`
  max-width: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NavLinkTitle = styled.span`
  color: rgba(255, 255, 255, 0.6);
  text-align: left;
  margin-top: 0px;
  margin-left: 10px;
  line-height: 9px;
  font-weight: 700;
  font-size: 18px;
  width: auto;
  color: #FFF;
  transition: all .2s ease-out;
  @media ${breakpoints.mobileXL} {
    white-space: nowrap;
    width: 100%;
  }
`;

export const NavLinkCircle = styled.div`
  position: relative;
  z-index: 99;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  min-width: 46px;
  height: 46px;
  margin: 6px;
  cursor: pointer;
  padding: 0;
  background-color: #fafafa;
  border-radius: 100px;
  transition: all .2s ease-out;
`;

export const NavLinkCustom = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  &:before{
    content: '';
    position: absolute;
    top: 0;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    background-color: transparent;
  }
  &:after{
    content: '';
    position: absolute;
    top: 0;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    background-color: #efc31f;
    transition: all .2s ease-out;
  }

  & > div {
    width: 30px;
    min-width: 30px;
    height: 30px;
    min-height: 30px;
    margin: 5px;
    & > span {
      max-width: 17px;
    }
  }

  & > span {
    font-wize: 18px,
    font-weight: 700,
    margin: 0 0 0 10px,
    width: 100%,
  },
`;

export const HeaderMobileGetGround = styled.div`
  position: absolute;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  padding: 0 15px;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  background-color: #7094e5;
  
  button{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  @media ${breakpoints.tablet} {
    display: none;
  }
`;

export const HeaderMobile = styled.div`
  position: absolute;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  padding: 0 15px;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  background-color: #7094e5;
  h1{
    color: #ffffff;
    font-size: 20px;
    font-weight: 600;
  }
  button{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  @media ${breakpoints.tablet} {
    display: none;
  }
`;

export const SidebarContainer = styled.div`
  position: fixed;
  right: ${props => props.sidebarState ? "0%" : "-100%"};
  // top: 50px;
  top: ${p => p.getGroundContext ? '60px' : '50px'};
  z-index: 999;
  padding: 0;
  box-sizing: border-box;
  // height: calc(100vh - 50px);
  height: ${p => p.getGroundContext ? 'calc(100vh - 60px)' : 'calc(100vh - 50px)'};
  width: 100%;
  overflow-y: scroll;
  transition: all .3s ease-out;
  @media ${breakpoints.tablet} {
    top: 0;
    height: 100vh;
    right: 0;
    width: 32%;
    min-width: 520px;
    max-width: 620px;
    max-height: 100vh;
  }
`;

export const ChatbotContainer = styled.div`
  height: 100vh;
  width: 100%;
  @media ${breakpoints.tablet} {
    width: 68%;
    max-width: calc(100% - 520px);
  }
`;

export const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
  width: 100vw;
  height: 100vh;
`;
