import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  /* min-width: 450px; */
  width: 100%;
  max-width: 62%;
  height: 100%;
  min-height: 210px;
  max-height: 30vh;
  padding: 20px;
  border-radius: 6px;
  background-color: #357ED3;
  margin: 10px;
`;

export const Title = styled.h3`
  margin: 0 0 20px 0;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const Content = styled.div`
  width: 100%;
`;

export const FeedbackCompetenceList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-flow: row wrap;
`;

export const FeedbackCompetenceItem = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 20px 8px 0;
  width: 100%;
  max-width: calc(50% - 20px);
  &:last-child{
    margin: 0 0 7px 0;
  }
  span{
    color: #ffffff;
    font-size: 11px;
    line-height: 8px;
    font-weight: 700;
    padding: 8px;
    border-radius: 4px;
    background-color: #2768B3;
    margin: 0 15px 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  strong{
    color: #ffffff;
    font-size: 14px;
    line-height: 12px;
    font-weight: 700;
    margin: 0 4px 0 0;
  }
  p{
    color: #ffffff;
    font-size: 10px;
    line-height: 8px;
    font-weight: 400;
    margin: 0 4px -3px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
