import React from 'react';
import { shallowEqual, useSelector } from "react-redux";

import { NavLink } from 'react-router-dom';

import {
	getSubDomain,
} from '../../../../store/config/selectors';
import {
	getUser
} from '../../../../store/collaborator/selectors';

// Style
import {
	Container,
	Text,
	BubbleText,
	NavlinkCustom,
} from './style';

const Step5 = () => {
	const {
    subDomain,
		user,
  } = useSelector(
    state => ({
      subDomain: getSubDomain(state),
			user: getUser(state),
    }),
    shallowEqual
  );

    return(
        <Container>
            <BubbleText>
                <Text>
                    Merci {user?.firstname} pour votre inscription.<br/>
                    J’ai hâte d’échanger avec vous !
                </Text>
            </BubbleText>
            <NavlinkCustom to="/landing">
                Allons-y !
            </NavlinkCustom>
        </Container>
    )
};

export default Step5;
