import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { toast } from 'react-toastify';

import GenericModal from '../GenericModal';

import {
  ModalHeaderContainer,
  FormContainer,
  InputLabel,
  StyledTextField,
  StyledTextArea,
  TextAreaErrorLabel,
  StyledButton
} from './style';

const FeedbackModal = ({ isFeedbackModalOpen, setIsFeedbackModalOpen, handleFeedbackModalSubmit }) => {
  const [form, setForm] = useState({
    subject: '',
    content: '',
  });
  const [error, setError] = useState({
    subject: false,
    content: false,
  });
  // const [open, setOpen] = useState(true);

  const handleOnChange = (event, type) => {
    setForm({
      subject: type === 'subject' ? event.target.value : form.subject,
      content: type === 'content' ? event.target.value : form.content,
    })
  }

  const handleSubmit = () => {
    const errorStatus = {};
    if (form.subject === '') errorStatus.subject = true;
    if (form.content === '') errorStatus.content = true;
    setError(errorStatus);
    if (!_.isEmpty(errorStatus)) {
      return;
    } else {
      handleFeedbackModalSubmit(form);
      setIsFeedbackModalOpen(false);
      toast.success("Nous avons bien reçu vos retours, merci pour votre aide !", { // Toaster not working right now
        position: toast.POSITION.TOP_RIGHT,
        className: "custom-toast custom-toast-success",
        bodyClassName: "custom-toast__body custom-toast__body-success",
        progressClassName: "custom-toast__progress"
      });
    }
    // console.log('errorStatus', errorStatus);
  }

  return (
    <GenericModal
      open={isFeedbackModalOpen}
      onClose={() => setIsFeedbackModalOpen(false)}
    >
      <ModalHeaderContainer>
        Votre remarque
      </ModalHeaderContainer>
      <FormContainer>
        <InputLabel>
          Cela concerne ...
        </InputLabel>
        <StyledTextField
          variant={'outlined'}
          onChange={(event) => handleOnChange(event, 'subject')}
          error={error.subject}
          helperText={error.subject ? 'Ce champ est requis' : ''}
        />
        <InputLabel>
          Dites-nous tout :
        </InputLabel>
        <StyledTextArea
          error={error.content}
          onChange={(event) => handleOnChange(event, 'content')}
        />
        {error.content &&
          <TextAreaErrorLabel>
            Ce champ est requis
          </TextAreaErrorLabel>
        }
        <InputLabel fontSize={'0.8rem'}>
          En tant qu’assistant, Cyconia traite votre remarque dans un premier temps, et la communique aux personnes concernées si nécessaire.
          Pensez à ne pas y inclure de données personnelles non indispensables au traitement de celui-ci, avec des propos adéquats, pertinents et non excessifs :)
        </InputLabel>
        <StyledButton onClick={handleSubmit}>
          ENVOYER
        </StyledButton>
      </FormContainer>
    </GenericModal>
  )
}

FeedbackModal.propTypes = {
  isFeedbackModalOpen: PropTypes.bool,
  setIsFeedbackModalOpen: PropTypes.func,
  handleFeedbackModalSubmit: PropTypes.func
}

export default FeedbackModal;