import { 
  isTokenValid, 
  isUserAdmin as isUserAdminHelper,
  isUserConcierge  as isUserConciergeHelper,
} from '../../helpers/token';

export const getUserToken = state => {
  const currentStateToken = state.authentication?.userToken;
  if (!currentStateToken) {
    if(localStorage.getItem('id_token')) {
      const idToken = localStorage.getItem('id_token');
      try {
        const result = JSON.parse(idToken);
        return result;
      }
      catch (err) {
        console.warn('err', err);
        return idToken;
      }
    };
  }
  return state.authentication?.userToken;
};//  || localStorage.getItem('id_token');

export const isUserTokenValid = state => {
  const tokenId = getUserToken(state);
  if (tokenId) {
    return isTokenValid(tokenId);
  }
  return false;
};

export const isUserAdmin = state => {
  const token = getUserToken(state);
  return isUserAdminHelper(token)
}

export const isUserConcierge = state => {
  const token = getUserToken(state);
  return isUserConciergeHelper(token)
}

// Get user request status
export const isUserLoginOnError = state => state.authentication.login.error;
export const isUserLoginOnSuccess = state => state.authentication.login.success;
export const isUserLoginOnLoading = state => state.authentication.login.loading;

// Send email reset password request status
export const isSendEmailResetPasswordOnError = state => state.authentication.sendEmailResetPassword.error;
export const isSendEmailResetPasswordOnSuccess = state => state.authentication.sendEmailResetPassword.success;
export const isSendEmailResetPasswordOnLoading = state => state.authentication.sendEmailResetPassword.loading;

// Get register request
export const isUserRegisterOnError = state => state.authentication.register.error;
export const isUserRegisterOnSuccess = state => state.authentication.register.success;
export const isUserRegisterOnLoading = state => state.authentication.register.loading;

// Get create collaborato
export const isCreateCollaboratorError = state => state.authentication.createCollaborator.error;
export const isCreateCollaboratorSuccess = state => state.authentication.createCollaborator.success;
export const isCreateCollaboratorLoading = state => state.authentication.createCollaborator.loading;

// New password update
export const isUserNewPasswordOnError = state => state.authentication.updatePassword.error;
export const isUserNewPasswordOnSuccess = state => state.authentication.updatePassword.success;
export const isUserNewPasswordOnLoading = state => state.authentication.updatePassword.loading;


// Logout request
export const logoutSuccess = state => state.authentication.logout.success;
