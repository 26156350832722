import React, { useEffect, useState } from 'react'
import _ from 'lodash';
import { useParams } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { Formik, Form, Field, useFormikContext } from 'formik';
import * as Yup from "yup";
import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import { toaster } from '../../../../helpers/toaster';

//Import Generic components
import GenericAdminFrontHeader from '../../../../components/molecules/GenericAdminFrontHeader';
import GenericMultipleSelect from '../../../../components/molecules/GenericMultipleSelect';
import GenericTextField from "../../../../components/molecules/GenericTextField";
import GenericFileChooser from '../../../../components/molecules/GenericFileChooser';
import GenericErrorMessage from '../../../../components/molecules/GenericErrorMessage';
import GenericDrawerValidationButtons from '../../../../components/molecules/GenericDrawerValidationButtons';
import GenericWarningModal from '../../../../components/molecules/GenericWarningModal';
import GenericLoader from '../../../../components/molecules/GenericLoader';

import {
  CONTENT_TYPE,
  CONTENT_SUPPORTED_FORMATS
} from '../../../../constants/contentConstants';

import PublicationBox from '../PublicationBox';
import CommentBox from '../CommentBox';
import Preview from '../Preview';
import { getContentStatus } from '../../utils/contentManagementUtils';

import {
  updateGoodDeal,
  getGoodDealById,
  clearSelectedGoodDeal
} from '../../../../store/contentManagement/actions';
import {
  getGoodDealsData,
  getGoodDealsIsLoading,
  getSelectedGoodDealData,
  getSelectedGoodDealIsLoading
} from '../../../../store/contentManagement/selectors';

import { getConciergeSitesData } from '../../../../store/concierge/selectors';

import GoodDealValueIcon from '../atoms/icons/GoodDealValue';
import GoodDealLinkIcon from '../atoms/icons/GoodDealLink';


import {
  Container,
  DrawerCptCustom,
  DrawerWrapper,
  Wrapper,
  FormContainer,
  FieldWithErrorMessageDiv,
  PreviewButton,
  Divider
} from './style'

const GoodDealDrawer = ({ visible, closeFunction }) => {
  const { goodDealId, editType } = useParams();
  const dispatch = useDispatch();
  const [openPreview, setOpenPreview] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [listRef, setListRef] = useState(null);
  const [disabledFields, setDisabledFields] = useState(false);
  const [sitesList, setSitesList] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDeal, setSelectedDeal] = useState({
    title: '',
    chooseFile: null,
    sites: [],
    description: "",
    dealValue: '',
    link: 'https://',
    publicationDate: "",
    status: '',
  });


  const {
    sitesData,
    goodDealsData,
    goodDealsIsLoading,
    selectedGoodDeal,
    selectedGoodDealIsLoading
  } = useSelector(
    state => ({
      sitesData: getConciergeSitesData(state),
      goodDealsData: getGoodDealsData(state),
      goodDealsIsLoading: getGoodDealsIsLoading(state),
      selectedGoodDeal: getSelectedGoodDealData(state),
      selectedGoodDealIsLoading: getSelectedGoodDealIsLoading(state),
    }),
    shallowEqual
  );

  useEffect(() => {
    if (goodDealId !== 'new')
      dispatch(getGoodDealById({ id: goodDealId }));
  }, [goodDealId]);

  useEffect(() => {
    if (sitesData) {
      const newList = _.map(sitesData, (site) => {
        return { label: site.name, value: site.id }
      })
      setSitesList(newList);
    }
  }, [sitesData]);

  useEffect(() => {
    if (isLoading && !selectedGoodDealIsLoading && sitesList) {
      if (goodDealId !== 'new' && selectedGoodDeal) {
        setSelectedDeal({
          title: selectedGoodDeal.title || '',
          chooseFile: selectedGoodDeal.banner || null,
          sites: cleanSitesList(selectedGoodDeal.companySites) || [],
          content: selectedGoodDeal.content || "",
          description: selectedGoodDeal.description || "",
          dealValue: selectedGoodDeal.dealValue || "",
          link: selectedGoodDeal.link || "https://",
          publicationDate: selectedGoodDeal.plannedFor || "",
          status: getContentStatus(selectedGoodDeal)
        })
        setDisabledFields(selectedGoodDeal.archived);
      }
      setIsLoading(false);
    }
  }, [selectedGoodDealIsLoading, sitesList, selectedGoodDeal])

  useEffect(() => {
    if (listRef && goodDealsData?.items) {
      if (!_.isEqual(listRef, goodDealsData?.items)) doCloseDrawer();
    }
    setListRef(goodDealsData?.items);
  }, [goodDealsData?.items])

  const handleImageEdit = (setFieldValue, value) => {
    setFieldValue('chooseFile', !value && selectedGoodDeal?.banner ? selectedGoodDeal?.banner : value);
  }

  const handleContentEdit = (setFieldValue, value) => {
    setFieldValue('description', value === '<p><br></p>' ? '' : value);
  }

  const handleClose = () => {
    if (isDirty) setOpenCancel(true);
    else doCloseDrawer();
  }

  const doCloseDrawer = () => {
    if (goodDealId !== 'new')
      dispatch(clearSelectedGoodDeal());
    closeFunction();
  }

  const CheckDirty = () => {
    const { dirty } = useFormikContext();

    React.useEffect(() => {
      setIsDirty(dirty);
    }, [dirty]);

    return null;
  };

  const checkIsValid = (props) => {
    if (!props.isValid) {
      toaster(`Erreur de sauvegarde, merci de vérifier que tous les champs obligatoires sont complétés.`, 'error');
    }
  };

  const cleanSitesList = (list) => {
    return list?.map((item) => ({ label: item.name, value: item.id }));
  }

  const getFormInitvalue = () => {
    const formInitValue = { ...selectedDeal };
    return formInitValue;
  }

  const validationSchema = Yup.object().shape({
    sites: Yup.array()
      .of(
        Yup.object().shape({
          title: Yup.string()
        })
      )
      .required('Vous devez choisir au moins un site')
      .min(1, 'Minimum 1 site'),

    title: Yup.string()
      .required('Un titre est requis'),
    description: Yup.string()
      .required('Un contenu est requis'),
    chooseFile: Yup.mixed()
      .nullable()
      .required("Une image de couverture est requise")
      .test('Size', 'Fichier trop volumineux', (value) => !value || typeof value === 'string' || (value && value.size <= 1024 * 1024))
      .test('format', 'not supported file format', (value) => !value || typeof value === 'string' || (value && CONTENT_SUPPORTED_FORMATS.includes(value.type))),
    dealValue: Yup.string()
      .required('Une valeur est requise'),
    link: Yup.string()
      .required('Un lien est requis'),
    status: Yup.string()
      .required('Un choix est requis'),
  });

  const submitForm = (formData) => {
    const id = (goodDealId === 'new' || editType === 'duplicate') ? 'new' : goodDealId;
    dispatch(updateGoodDeal({ id, formData }));
  }

  return (
    <Container>
      <DrawerCptCustom
        onOpen={visible}
        visible={visible}
        closeFunction={handleClose}
        className="contentManagementDrawer"
      >
        <DrawerWrapper>
          <Formik
            initialValues={getFormInitvalue()}
            enableReinitialize
            setFieldValue
            validationSchema={validationSchema}
            onSubmit={(values) => submitForm(values)}
          >
            {(props) => {
              return (
                <>
                  <Wrapper>
                    <GenericAdminFrontHeader
                      headerTitle={`${goodDealId === 'new' || editType === 'duplicate' ? 'Ajouter un nouveau bon plan' : 'Éditer un bon plan'}`}
                      RightSideComponent={
                        <PreviewButton
                          onClick={() => setOpenPreview(!openPreview)}
                          endIcon={openPreview ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        >
                          {openPreview ? `Fermer l'aperçu` : `Ouvrir l'aperçu`}
                        </PreviewButton>
                      }
                    />
                    {(isLoading || goodDealsIsLoading) &&
                      <GenericLoader />
                    }
                    {(!isLoading && !goodDealsIsLoading) &&
                      <Form>
                        <CheckDirty />
                        <FormContainer>
                          <GenericMultipleSelect
                            list={sitesList || []}
                            fildName="sites"
                            id="sites"
                            name="sites"
                            title="Site(s) de diffusion"
                            isRequired={true}
                            value={props.values.sites}
                            limitItem={10}
                            disabled={disabledFields}
                            hasSelectAll={true}
                            onChange={(e, value) => { return props.setFieldValue('sites', value) }}
                            toolTipText={'Ce champ vous permet de sélectionner les Sites de diffusion.'}
                          />
                          <Divider />
                          <GenericFileChooser
                            title="Image de couverture"
                            buttonText="Choisir un fichier"
                            isRequired={true}
                            id="chooseFile"
                            disabled={disabledFields}
                            name="chooseFile"
                            onChange={(value) => handleImageEdit(props.setFieldValue, value)}
                            selectedFileName={props.values.chooseFile?.name}
                            shouldShowImagePreview={true}
                            currentFile={props.values.chooseFile || selectedGoodDeal?.banner}
                          />
                          <Field
                            id="title"
                            name="title"
                            title="Titre de votre contenu"
                            placeholder="Saisissez un titre"
                            variant="outlined"
                            disabled={disabledFields}
                            size="small"
                            isRequired={true}
                            as={GenericTextField}
                          />
                          <FieldWithErrorMessageDiv>
                            <CommentBox
                              value={props.values.description}
                              onChange={(e) => handleContentEdit(props.setFieldValue, e)}
                              disabled={disabledFields}
                            />
                            <GenericErrorMessage name={'description'} />
                          </FieldWithErrorMessageDiv>
                          <Field
                            InputProps={{
                              endAdornment: <InputAdornment position="end"><GoodDealValueIcon /></InputAdornment>,
                              style: {
                                fontSize: '13px'
                              }
                            }}
                            id="dealValue"
                            name="dealValue"
                            title="Valeur du bon plan"
                            disabled={disabledFields}
                            placeholder="Saisissez la valeur du bon plan"
                            variant="outlined"
                            size="small"
                            isRequired={true}
                            as={GenericTextField}
                          />
                          <Field
                            InputProps={{
                              endAdornment: <InputAdornment position="end"><GoodDealLinkIcon /></InputAdornment>,
                              style: {
                                fontSize: '13px'
                              }
                            }}
                            id="link"
                            name="link"
                            title="Lien vers le bon plan"
                            disabled={disabledFields}
                            placeholder="Indiquez le lien vers le bon plan"
                            variant="outlined"
                            size="small"
                            isRequired={true}
                            as={GenericTextField}
                          />
                          <Divider />
                          <FieldWithErrorMessageDiv>
                            <PublicationBox
                              isRequired={true}
                              publicationDate={props.values.publicationDate}
                              toolTipText={'Vous devez choisir le statut de votre contenu avant de sauvegarder.'}
                              setDisabledFields={setDisabledFields}
                              setFieldValue={props.setFieldValue}
                              status={props.values.status}
                              contentType={CONTENT_TYPE.GOOD_DEAL}
                            />
                            <GenericErrorMessage name={'status'} />
                          </FieldWithErrorMessageDiv>
                          <GenericDrawerValidationButtons
                            leftButonText={'Annuler'}
                            RightButtonText={'Sauvegarder'}
                            onclickLefttButton={handleClose}
                            onclickRightButton={() => checkIsValid(props)}
                            submitable={false}
                          />
                        </FormContainer>
                      </Form>
                    }
                  </Wrapper>
                  {openPreview === true && (
                    <Preview
                      contentTitle={props.values.title}
                      publicationDate={props.values.publicationDate}
                      link={props.values.link}
                      choosenFile={props.values.chooseFile || selectedGoodDeal?.banner}
                      selectedType={CONTENT_TYPE.GOOD_DEAL}
                      dealValue={props.values.dealValue}
                      description={props.values.description}
                    />)
                  }
                </>
              )
            }}
          </Formik>
        </DrawerWrapper>
      </DrawerCptCustom>
      <GenericWarningModal
        open={openCancel}
        setOpenDialog={() => setOpenCancel(!openCancel)}
        title={"Attention"}
        text={"Êtes-vous sur de vouloir annuler votre saisie ?"}
        leftButtonText={"Non"}
        rightButtonText={"Oui"}
        leftButtonFunction={() => setOpenCancel(!openCancel)}
        rightButtonFunction={doCloseDrawer}
      />
    </Container >
  )
}

export default GoodDealDrawer;
