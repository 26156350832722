import React from "react";

const DashboardIcon = ({
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon dashboardIcon"
      width={width}
      height={height}
      viewBox="0 0 28 16"
      fill="none"
    >
      <g fillRule="nonzero" fill="none">
        <path
          d="M27.491 14.65c-.773.848-1.32.64-5.842.64a1.972 1.972 0 01-1.94-1.67 5.694 5.694 0 00-1.833-3.395 5.804 5.804 0 00-3.874-1.497h-.008a5.773 5.773 0 00-3.895 1.511l-.01.008a5.722 5.722 0 00-1.798 3.374 1.972 1.972 0 01-1.94 1.67H1.962A1.96 1.96 0 01.01 13.152a13.924 13.924 0 014.157-8.677l.071-.07A14.04 14.04 0 0113.994.478h.008a14.04 14.04 0 019.723 3.893 13.923 13.923 0 014.266 8.782 1.956 1.956 0 01-.5 1.497z"
          fill="#4A90E2"
        />
        <path
          d="M27.491 14.65c-.773.848-1.32.64-5.842.64a1.972 1.972 0 01-1.94-1.67 5.694 5.694 0 00-1.833-3.395 5.804 5.804 0 00-3.874-1.497h-.008V.478h.008a14.04 14.04 0 019.723 3.892 13.923 13.923 0 014.266 8.782 1.956 1.956 0 01-.5 1.497z"
          fill="#40B176"
        />
        <path
          d="M23.725 4.37l-5.85 5.855a5.804 5.804 0 00-3.873-1.497H14a5.77 5.77 0 00-3.901 1.511l-.01.008-5.923-5.772.071-.07A14.04 14.04 0 0114 .478h.002a14.04 14.04 0 019.723 3.893z"
          fill="#FFDB83"
        />
        <path
          d="M23.725 4.37l-5.85 5.855a5.804 5.804 0 00-3.873-1.497V.478a14.04 14.04 0 019.723 3.892z"
          fill="#FF9F6D"
        />
      </g>
    </svg>
  );
};

export default DashboardIcon;
