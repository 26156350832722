import React from "react";

const WeatherClearIcon = ({
  width = "100%",
  height = "100%",
  color = "#FEDB83",
}) => {

  return (
    <svg
      className="icon weatherClearIcon"
      width={width}
      height={height}
      viewBox="0 0 599 599"
      fill="none"
    >
      <path
        d="M299.5 466.6c8 0 14.5 6.5 14.5 14.5v103.3c0 7.895-6.33 14.329-14.185 14.497l-.315.003c-8 0-14.5-6.5-14.5-14.5V481.1c0-7.895 6.33-14.329 14.185-14.497l.315-.003zm-118.553-49.151l.253.251c5.7 5.7 5.7 14.9 0 20.5l-73 73c-5.516 5.615-14.526 5.699-20.242.251l-.258-.251c-5.7-5.7-5.7-14.9 0-20.5l73-73c5.615-5.615 14.626-5.699 20.247-.251zm257.253.151l73 73c5.615 5.615 5.699 14.626.251 20.247l-.251.253c-5.7 5.7-14.9 5.7-20.5 0l-73-73c-5.615-5.516-5.699-14.526-.251-20.242l.251-.258c5.7-5.7 14.9-5.7 20.5 0zM213.839 213.766c47.292-47.292 123.968-47.292 171.26 0 47.292 47.292 47.292 123.968 0 171.26-47.292 47.292-123.968 47.292-171.26 0-47.292-47.292-47.292-123.968 0-171.26zM584.4 284.9c7.895 0 14.329 6.33 14.497 14.185l.003.315c0 8-6.5 14.5-14.5 14.5H481.1c-7.895 0-14.329-6.33-14.497-14.185l-.003-.315c0-8 6.5-14.5 14.5-14.5h103.3zm-466.6 0c7.895 0 14.329 6.33 14.497 14.185l.003.315c0 8-6.5 14.5-14.5 14.5H14.5c-7.895 0-14.329-6.33-14.497-14.185L0 299.4c0-8 6.5-14.5 14.5-14.5h103.3zM510.947 87.449l.253.251c5.7 5.7 5.7 14.9 0 20.5l-73 73c-5.615 5.615-14.626 5.699-20.247.251l-.253-.251c-5.7-5.7-5.7-14.9 0-20.5l73-73c5.615-5.615 14.626-5.699 20.247-.251zM108.2 87.7l73 73c5.615 5.615 5.699 14.626.251 20.247l-.251.253c-5.7 5.7-14.9 5.7-20.5 0l-73-73c-5.615-5.615-5.699-14.626-.251-20.247l.251-.253c5.7-5.7 14.9-5.7 20.5 0zM299.5 0c8 0 14.5 6.5 14.5 14.5v103.3c0 7.895-6.33 14.329-14.185 14.497l-.315.003c-8 0-14.5-6.5-14.5-14.5V14.5C285 6.605 291.33.171 299.185.003L299.5 0z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export default WeatherClearIcon;
