import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { useHistory } from "react-router";
import { useRouteMatch } from "react-router-dom";
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

// Components
import GenericTable from '../../components/molecules/GenericTable';
import GenericAdminFrontHeader from '../../components/molecules/GenericAdminFrontHeader';
import GenericWarningModal from '../../components/molecules/GenericWarningModal';
import GenericLoader from '../../components/molecules/GenericLoader';

import ContentBar from './components/ContentBar';
import ContentFilter from './components/ContentFilter';
import { getContentStatus } from './utils/contentManagementUtils';

import {
  getNewsContentData,
  getNewsContentIsLoading,
  getGoodDealsData,
  getGoodDealsIsLoading,
} from '../../store/contentManagement/selectors';

import {
  getConciergeSitesData,
  getConciergeSitesIsLoading
} from '../../store/concierge/selectors';

import {
  getNewsContent,
  deleteNewsContent,
  getGoodDeals,
  deleteGoodDeal
} from '../../store/contentManagement/actions';

//Import route
import Routes from './routes';

// Styles
import {
  Container,
  TableButtonDiv,
  TableIcon,
  TableWrapper,
  SettingContainer,
  PaginationContainer,
  StyledIconButton,
  HeaderButton,
  TableCellCustom,
  ContentServicesListContainer
} from "./style";

const headerList = [
  { id: "title", label: "Titre du contenu" },
  { id: "created_at", label: "Date de création" },
  { id: "published_at", label: "Date de publication" },
  { id: "site", label: "Site(s) d'entreprises", disableSort: true },
  { id: "status", label: "Statut" },
  { id: "rowAction", label: "Action", disableSort: true },
];

const TableAction = ({ openEditDrawer, openCopyDrawer, openWarning }) => {
  return (
    <TableButtonDiv >
      <Tooltip title={'Éditer'} >
        <TableIcon onClick={openEditDrawer}>
          <EditIcon />
        </TableIcon>
      </Tooltip>
      <Tooltip title={'Copier'} >
        <TableIcon onClick={openCopyDrawer}>
          <FileCopyIcon />
        </TableIcon>
      </Tooltip>
      <Tooltip title={'Supprimer'} >
        <TableIcon onClick={openWarning} primary="true">
          <DeleteIcon />
        </TableIcon>
      </Tooltip>
    </TableButtonDiv>
  )
}

const ContentManagement = () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [dataList, setDataList] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);
  const [newsPageNumber, setNewsPageNumber] = useState(1);
  const [dealsPageNumber, setDealsPageNumber] = useState(1);
  const [maxNewsPageNumber, setMaxNewsPageNumber] = useState(1);
  const [maxDealsPageNumber, setMaxDealsPageNumber] = useState(1);
  const [selectedContentId, setSelectedContentId] = useState(null);
  const [filterConditions, setFilterConditions] = useState({
    sites: [],
    status: [],
  });

  const {
    newsContentData,
    newsContentIsLoading,
    goodDealsData,
    goodDealsIsLoading,
    sitesData,
    sitesIsLoading
  } = useSelector(
    state => ({
      newsContentData: getNewsContentData(state),
      newsContentIsLoading: getNewsContentIsLoading(state),
      goodDealsData: getGoodDealsData(state),
      goodDealsIsLoading: getGoodDealsIsLoading(state),
      sitesData: getConciergeSitesData(state),
      sitesIsLoading: getConciergeSitesIsLoading(state)
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getNewsContent({ limit: 10, pageNumber: newsPageNumber }));
  }, [newsPageNumber]);

  useEffect(() => {
    dispatch(getGoodDeals({ limit: 10, pageNumber: dealsPageNumber }));
  }, [dealsPageNumber]);

  useEffect(() => {
    // console.log('selectedCategory', selectedCategory);
    let currentList = selectedCategory === 0 ? newsContentData : goodDealsData;
    const newList = _.compact(_.map(currentList?.items, (data) => {
      if (filteringCondition(filterConditions, data) === false) return;
      const newListItem = {
        title: data.title,
        created_at: `${format(new Date(data.created_at), 'dd/MM/yyyy')}`,
        published_at: data.published && data.published_at ? `${format(new Date(data.published_at), 'dd/MM/yyyy')}` : "En attente",
        site: siteListItem(data.company_site),
        status: getContentStatus(data),
        rowAction:
          <TableAction
            openEditDrawer={() => openEditDrawer({ id: data.id })}
            openCopyDrawer={() => openEditDrawer({ id: data.id, type: 'duplicate' })}
            openWarning={() => prepareDelete(data.id)}
          />
      }
      return (newListItem);
    }))
    if (currentList) {
      const total = Math.ceil(currentList.total_count / (currentList.num_items_per_page));
      if (selectedCategory === 0) {
        setMaxNewsPageNumber(total);
      }
      else if (selectedCategory === 1) {
        setMaxDealsPageNumber(total);
      }
    }
    setDataList(newList);
  }, [selectedCategory, newsContentData, goodDealsData, filterConditions]);

  useEffect(() => {
    // if (isLoading) {
    if (selectedCategory === 0) {
      if (!newsContentIsLoading && !sitesIsLoading) {
        setIsLoading(false);
      }
      else if (newsContentIsLoading || sitesIsLoading) {
        setIsLoading(true);
      }
    }
    else if (selectedCategory === 1) {
      if (!goodDealsIsLoading && !sitesIsLoading) {
        setIsLoading(false);
      }
      else if (goodDealsIsLoading || sitesIsLoading) {
        setIsLoading(true);
      }
    }
    // }
  }, [newsContentIsLoading, goodDealsIsLoading, sitesIsLoading, selectedCategory]);

  const siteListItem = (list) => {
    if (list != null && Object.keys(list).length !== 0) {
      const newList = _.map((list), (el) => {
        return el?.name || '-';
      }).join(', ')

      return (
        <Tooltip title={newList} >
          <ContentServicesListContainer>
            <TableCellCustom> {newList}</TableCellCustom>
          </ContentServicesListContainer>
        </Tooltip>
      )
    } else {
      return (
        <TableCellCustom>-</TableCellCustom>
      )
    }
  }

  const checkSiteFilter = (form, item) => {
    let stat = false;
    _.map(item.company_site, (site) => {
      if (form?.sites.find(e => String(e.value) === String(site.id))) {
        stat = true;
      }
    })
    return stat;
  }

  const checkStatusFilter = (form, item) => {
    let stat = false;
    const itemStatus = getContentStatus(item);
    _.map(form?.status, (status) => {
      if (itemStatus.toLowerCase() === status.value.toLowerCase()) {
        stat = true;
      }
    })
    return stat;
  }

  const filteringCondition = (form, item) => {
    const conditionCheckArray = [];
    if (form.sites.length !== 0) {
      conditionCheckArray.push(checkSiteFilter(form, item))
    }
    if (form.status.length !== 0) {
      conditionCheckArray.push(checkStatusFilter(form, item))
    }
    if (conditionCheckArray.length > 0) {
      return !conditionCheckArray.includes(false);
    }
    return true;
  }

  const renderPaginationInfo = () => {
    const current = selectedCategory === 0 ? newsPageNumber : dealsPageNumber;
    const max = selectedCategory === 0 ? maxNewsPageNumber : maxDealsPageNumber;
    return `${current} sur ${max} au total`;
  }

  const handlePagination = (value) => {
    if (selectedCategory === 0) {
      const newValue = newsPageNumber + value;
      if (newValue === 0 || newValue > maxNewsPageNumber) return;
      setNewsPageNumber(newValue);
    }
    else if (selectedCategory === 1) {
      const newValue = dealsPageNumber + value;
      if (newValue === 0 || newValue > maxDealsPageNumber) return;
      setDealsPageNumber(newValue);
    }
  }

  const prepareDelete = (id) => {
    setSelectedContentId(id);
    setOpenDelete(!openDelete);
  }

  const handleDelete = () => {
    if (selectedContentId != null) {
      if (selectedCategory === 0) {
        dispatch(deleteNewsContent(selectedContentId));
      }
      else if (selectedCategory === 1) {
        dispatch(deleteGoodDeal(selectedContentId));
      }
    }
    setOpenDelete(false);
  }

  const openEditDrawer = ({ id, type }) => {
    const contentType = selectedCategory === 0 ? 'newsContent' : 'goodDeal';
    if (type === 'duplicate') {
      history.push(`${path}/${contentType}/${id}/duplicate`);
    }
    else {
      history.push(`${path}/${contentType}/${id}`);
    }
  }

  const changeSelectedCategory = (value) => {
    setSelectedCategory(value);
  }

  return (
    <Container>
      <GenericAdminFrontHeader
        headerTitle={'Gestion de contenu'}
        headerDesc={`Créez facilement et rapidement le contenu dynamique et rédactionnel à l'attention de vos collaborateurs.`}
        headerDescToolTip={`Créez facilement et rapidement le contenu dynamique et rédactionnel à l'attention de vos collaborateurs.`}
        RightSideComponent={
          <>
            {/* THIS IS ONLY TEMPORARY UNTIL BON PLAN FUNCTION IS COMPLETLY COMPLETE */}
            {/* <ContentBar onChange={(cat) => changeSelectedCategory(cat)} /> */}
            <HeaderButton onClick={() => openEditDrawer({ id: 'new' })} >
              {`+ Ajouter ${selectedCategory === 0 ? 'une nouvelle actualité' : 'un nouveau bon plan'}`}
            </HeaderButton>
          </>
        }
      />
        <ContentFilter sitesData={sitesData} setFilterConditions={setFilterConditions} selectedCategory={selectedCategory} />
      <SettingContainer>
        <PaginationContainer>
          <StyledIconButton onClick={() => handlePagination(-1)}>
            <ArrowBackIcon />
          </StyledIconButton>
          {renderPaginationInfo()}
          <StyledIconButton onClick={() => handlePagination(1)}>
            <ArrowForwardIcon />
          </StyledIconButton>
        </PaginationContainer>
      </SettingContainer>
      {isLoading &&
        <GenericLoader />
      }
      {!isLoading &&
        <TableWrapper>
          <GenericTable
            headerList={headerList}
            itemList={dataList}
          />
        </TableWrapper>
      }
      <GenericWarningModal
        open={openDelete}
        setOpenDialog={() => setOpenDelete(!openDelete)}
        title={"Attention"}
        text={"Êtes-vous sur de vouloir supprimer ce contenu ?"}
        leftButtonText={"Annuler"}
        rightButtonText={"Supprimer"}
        leftButtonFunction={() => setOpenDelete(!openDelete)}
        rightButtonFunction={() => handleDelete()}
      />
      <Routes />
    </Container>
  );
}

export default ContentManagement;
