import React, { useEffect, useState } from 'react';
import {
  shallowEqual,
  useDispatch,
  useSelector,
} from "react-redux";
import _ from 'lodash';

import Loader from '../../../../../components/molecules/GenericLoader';
import GenericWarningModal from '../../../../../components/molecules/GenericWarningModal';

import { jsonSafeParse } from '../../../../../helpers/jsonSafeParse';

import WeatherClear from '../../atoms/icons/weatherClear';
import WeatherClouds from '../../atoms/icons/weatherClouds';
import WeatherDrizzel from '../../atoms/icons/weatherDrizzel';
import WeatherRain from '../../atoms/icons/weatherRain';
import WeatherSnow from '../../atoms/icons/weatherSnow';
import WeatherAtmosphere from '../../atoms/icons/weatherAtmosphere';
import WeatherThunderstorm from '../../atoms/icons/weatherThunderstorm';

import {
  getSites
} from '../../../../../store/concierge/actions';

import {
  getConciergeSitesData,
  getConciergeSitesIsLoading
} from '../../../../../store/concierge/selectors';

import {
  getWeather,
} from '../../../../../store/sidebar/actions';

import {
  getWeatherData,
} from '../../../../../store/sidebar/selectors';

import {
  getUser,
} from '../../../../../store/collaborator/selectors';

import {
  switchCollaboratorSite
} from '../../../../../store/userManagement/actions';

import {
  getSwitchCollaboratorSiteIsLoading,
  getSwitchCollaboratorSiteIsSuccess
} from '../../../../../store/userManagement/selectors';

import {
  MainContainer,
  StyledMenu,
  WeatherInfoContainer,
  WeatherIconWrapper
} from './style';

const UserInfoAndWeatherModule = () => {
  const dispatch = useDispatch();
  const [siteList, setSiteList] = useState([]);
  const [openCancel, setOpenCancel] = useState(false);
  const [initialSite, setInitialSite] = useState(null);
  const [selectedSite, setSelectedSite] = useState(null);

  const {
    userInfos,
    sitesData,
    sitesIsLoading,
    switchCollaboratorSiteIsLoading,
    switchCollaboratorSiteSuccess,
    weather,
  } = useSelector(
    state => ({
      userInfos: getUser(state),
      sitesData: getConciergeSitesData(state),
      sitesIsLoading: getConciergeSitesIsLoading(state),
      switchCollaboratorSiteIsLoading: getSwitchCollaboratorSiteIsLoading(state),
      switchCollaboratorSiteSuccess: getSwitchCollaboratorSiteIsSuccess(state),
      weather: getWeatherData(state),
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!weather) {
      dispatch(getWeather());
    }
    if (!sitesData) {
      dispatch(getSites());
    }
  }, [dispatch]);

  useEffect(() => {
    if (sitesData !== null && userInfos !== null) {
      const currentUserSite = userInfos.company_site;
      const available_company_sites = jsonSafeParse(userInfos.available_company_sites);
      let newList = _.compact(_.map(available_company_sites, (available_site) => {
        const site = _.find(sitesData, (site) => String(site.id) === String(available_site));
        if (site) {
          return ({
            label: site.name,
            value: site.id
          })
        }
      }));
      if (currentUserSite) {
        const currentSite = {
          label: currentUserSite.name,
          value: currentUserSite.id
        }
        newList = _.unionBy([currentSite], newList, "value");
      }
      setSiteList(newList);
    }
  }, [sitesData, userInfos]);

  useEffect(() => {
    if (userInfos) {
      setSelectedSite(userInfos.company_site?.id);
      setInitialSite(userInfos.company_site?.id);
    }
  }, [userInfos]);

  useEffect(() => {
    if (switchCollaboratorSiteSuccess) {
      setOpenCancel(false);
      window.location.reload();
    }
  }, [switchCollaboratorSiteSuccess]);

  const handleSiteChange = (value) => {
    setSelectedSite(value);
    setOpenCancel(true);
  };

  const handleCancel = () => {
    setSelectedSite(initialSite);
    setOpenCancel(false);
  };

  const doChangeSite = () => {
    const formData = { site: selectedSite };
    dispatch(switchCollaboratorSite({ formData }));
  };

  const generateCancelText = () => {
    const site = _.find(sitesData, (site) => String(site.id) === String(selectedSite));
    return (
      <>
        <div>
          {`Êtes-vous sûr de vouloir passer sur ${site?.name || ''}? `}
        </div>
        <div>
          {`La page sera rafraichie pour vous y emmener`}
        </div>
      </>
    )
  };

  const weatherConditionsIcons = {
    2: <WeatherThunderstorm />,
    3: <WeatherDrizzel />,
    5: <WeatherRain />,
    6: <WeatherSnow />,
    7: <WeatherAtmosphere />,
    8: <WeatherClouds />,
    800: <WeatherClear />,
  };

  const weatherConditionsData = weather && weather?.condition;
  const weatherConditionsDataSlice = String(weatherConditionsData).slice(1);
  const weatherConditionsVerify = weatherConditionsData === 800 ? 800 : parseInt(weatherConditionsDataSlice, 10);
  const weatherCondition = weatherConditionsIcons[weatherConditionsVerify] || weatherConditionsIcons[800];

  return (
    <MainContainer>
      {sitesIsLoading ?
        <Loader />
        :
        <>
          <StyledMenu
            title={`${userInfos?.firstname} ${userInfos?.lastname}`}
            list={siteList}
            initialValue={selectedSite}
            onChange={(value) => handleSiteChange(value)}
          />
          {weather && (
            <WeatherInfoContainer>
              <strong>
                {weather?.city}
                <span>
                  {!!weather?.temparature ? weather.temparature.toFixed(1) : "-"} °C
                </span>
              </strong>
              <WeatherIconWrapper>
                {weatherCondition}
              </WeatherIconWrapper>
            </WeatherInfoContainer>
          )}
        </>
      }
      <GenericWarningModal
        open={openCancel}
        setOpenDialog={() => handleCancel()}
        title={"Attention"}
        text={generateCancelText()}
        leftButtonText={"Non"}
        rightButtonText={"Oui"}
        leftButtonFunction={() => handleCancel()}
        rightButtonFunction={doChangeSite}
        isLoading={switchCollaboratorSiteIsLoading}
      />
    </MainContainer>
  );
};

export default UserInfoAndWeatherModule;
