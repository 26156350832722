import styled from "styled-components";
import RadioCpt from '../../../../../../components/molecules/radioCpt/';

export const Container = styled.div`

`;

export const CustomFeedModalContainer = styled.div`
  width: 100%;
  padding: 20px 10px;
  background-color: #ffffff;
  /* max-width: 600px; */
`

export const CustomFeedModalInput = styled.textarea`
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  width: 100%;
  max-width: 100%;
  min-height: 120px;
  height: 100%;
  color: #5A5A5A;
  margin-top: 20px;
  outline: none;
  border: 1px solid #EDEDED;
  border-radius: 6px;
  background-color: #F2F2F2;
  resize: none;
  font-size: 15px;
  padding: 12px;
`;

export const RadioCptCustom = styled(RadioCpt)`
  &.MuiFormControlLabel-root{
    .MuiButtonBase-root{
      padding: 5px;
      .MuiIconButton-label{
        input{

        }
      }
    }
    .MuiTypography-root{
      font-size: 13px;
    }
  }
`;

export const CustomFeedModalOption = styled.form`
  display: flex;
  justify-content: space-between;
  .MuiFormControl-root{
    width: 100%;
    .MuiFormGroup-root{
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: space-between;
    }
  }
`;

export const ThinkingIllustrationWrapper = styled.div`
  display: flex;
  width: 200px;
`;
