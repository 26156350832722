import React from "react";
import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import Button from '@material-ui/core/Button';

import { MESSAGE_ELEMENT_TYPE } from '../../../../constants/chatBotConstants';
import { COLORS } from '../../../../constants/design';
import GenericTextField from '../../../molecules/GenericTextField';

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export const StyledTextField = styled(GenericTextField)`
  margin: 10px 0px;
`;

export const StyledDeleteMessage = styled.div`
  font-size: 1rem;
  text-align: center;
`;

export const StyledButton = styled(Button)`
  && {
    &.MuiButton-root {
      color: ${props => props.type === 'cancelButton' ? COLORS.DARK_GREY : COLORS.WHITE};
      position: relative;
      font-family: Quicksand;
      background-color: ${props => props.type === 'cancelButton' ? COLORS.LIGHT_GREY_2 : COLORS.PRIMARY_BLUE};
      width: 180px;
      transition: all ease-in .15s;
      border: transparent;
      border-radius: 5px;
      background-image: none;
      text-transform: none;
    }
  }
`;

export const StyledDialog = styled(({ ...rest }) => (
  <Dialog {...rest} />
))`
  && {
    .MuiDialog-container {
      .MuiDialog-paper {
        color: #212121;
        background-color: #FFFFFF;
        border-radius: 10px;
        &.MuiPaper-rounded {
          border-radius: 10px;
          width: 100%;
          max-width: ${props => 
            props.editmode !== 'delete' && (props.type === MESSAGE_ELEMENT_TYPE.TEXT || props.type === MESSAGE_ELEMENT_TYPE.BUTTON_LINK) ?
            '750px' : '480px'
          };
        }
        .MuiDialogTitle-root {
          text-align: center;
          font-weight: 600;
          padding: 20px 50px 0px 50px;
        }
        .MuiDialogContent-root {
          padding: 40px 50px;
          .MuiTypography-colorTextSecondary {
            text-align: center;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.8);
          }
        }
        .MuiDialogActions-root {
          justify-content: space-between;
          padding: 0 50px 40px 50px;
          .MuiButtonBase-root {
            height: 52px;
            margin: 0 10px;
            .MuiButton-label {
              font-size: 12px;
            }
            &:hover {
              .MuiButton-label {
              }
            }
          }
        }
      }
    }
    .quill .ql-toolbar {
      background-color: #F4F4F4;
      border: 1px solid #D2D2D2;
      border-radius: 10px 10px 0 0;
      height: 44px;
      min-height: 44px;
      padding: 0px 10px;
      display: -ms-flexbox;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .quill .ql-container {
      border-radius: 0 0 10px 10px;
      height: 250px;
      em {
        font-style: revert;
      }
      ol, ul {
        padding-left: 0em;
        list-style-position: inside;
      }
    }
  }
`;
