import styled from "styled-components";
import IconButton from '@material-ui/core/IconButton';

import { COLORS } from "../../../constants/design";
import GenericButton from "../GenericButton";

export const MainContainer = styled.div`
  .MuiOutlinedInput-adornedEnd {
    padding: 0;
  }
`;

export const StyledActionContainer = styled.div`
  display: flex;
`;

export const StyledButton = styled(GenericButton)`
  && {
    &.MuiButton-root {
      border-radius: 0px 6px 6px 0px;
      min-width: 50px;
      width: 50px;
      .MuiButton-startIcon {
        margin: 0;
      }
      .MuiSvgIcon-root {
        font-size: 1rem;
      }
    }
  }
`;

export const StyledIcon = styled(IconButton)`
  &.MuiIconButton-root {
    border-radius: 0;
    padding: 0px 10px;
    color: ${COLORS.LIGHT_GREY_3};
    :hover {
      background-color: transparent;
      color: ${COLORS.GREY_1};
    }
  }
  .MuiSvgIcon-root {
    font-size: 1rem;
  }
`;
