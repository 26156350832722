import React from 'react';
import { useHistory } from "react-router-dom";

import notFound from "../../../../assets/images/notFound.png";

import {
  MainContainer,
  StyledRestrictedImage,
  StyledRestrictedMessage,
  StyledErrorLog,
} from './style';

const NotFound = () => {
  const history = useHistory();

  // console.log('history', history);

  return (
    <MainContainer>
      <StyledRestrictedImage src={notFound} alt={'notFoundImg'} />
      <StyledRestrictedMessage>
        {`Votre conciergerie est disponible sur l'application GetGround ! RDV sur Applestore et Android pour profiter de ses nombreux services 😄`}
      </StyledRestrictedMessage>
      {history.location.state?.error &&
        <>
          <StyledErrorLog>
            {`Error Code: ${history.location.state?.error.message}`}
          </StyledErrorLog>
          <StyledErrorLog>
            {`Url: ${history.location.state?.from.pathname}`}
          </StyledErrorLog>
        </>
      }
    </MainContainer>
  );
}

export default NotFound;
