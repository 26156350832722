import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useSelector, useDispatch } from "react-redux";

// Import third party component
import LaddaButton, { SLIDE_UP } from 'react-ladda';

import {
  getSubDomain,
} from '../../../../store/config/selectors';
import {
	isOnBoardingStepFourOnSuccess,
	isOnBoardingStepFourOnLoad,
} from '../../../../store/onboarding/selectors';
import {
	getUser,
} from '../../../../store/collaborator/selectors';
import {
  saveOnBoardingFour,
  resetOnBoarding,
} from '../../../../store/onboarding/actions';

// Style
import {
	Container,
	BubbleText,
	Text,
	ActionBox,
	BackButton,
} from './style';

const Step4 = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const {
		subDomain,
		saveOnSuccess,
		saveOnLoad,
		user,
	} = useSelector(
		state => ({
			subDomain: getSubDomain(state),
			saveOnSuccess: isOnBoardingStepFourOnSuccess(state),
			saveOnLoad: isOnBoardingStepFourOnLoad(state),
			user: getUser(state),
		}),
		shallowEqual
	);

	const onGoStep = (dir) => {
		if (dir > 0) {
			dispatch(saveOnBoardingFour());
		} else {
			history.push(`/onboarding/${dir > 0 ? '5' : '3'}`);
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		onGoStep(1);
	};

	useEffect(() => {
		dispatch(resetOnBoarding());
	}, []);

	useEffect(() => {
		if (saveOnSuccess) {
			history.push("/onboarding/5");
		}
	}, [saveOnSuccess]);


	switch (subDomain) {
		case "mmh":
			return (
				<Container>
					<form onSubmit={handleSubmit} noValidate>
						<BubbleText>
							<Text>
								C’est déjà fini, bravo vous êtes inscrit.
							</Text>
						</BubbleText>
						<BubbleText>
							<Text>
								<em>Comment je vous assiste ?</em>
							</Text>
							<Text>
								Je simplifie vos missions de gestionnaire en vous donnant accès facilement et rapidement aux informations, documents et contrats.
							</Text>
							<Text>Quelques exemples de questions auxquelles je peux vous aider à répondre :</Text>
							<Text>
								Quels droits pour un assuré ayant été licencié dans le contrat Ucanss ?<br /><br />
								Est-ce qu'un enfant en apprentissage peut être affilié ?<br /><br />
								Quels justificatifs faut-il pour affilier un conjoint ?<br /><br />
								Comment radier un ayant droit sur le contrat Pôle Emploi ?<br /><br />
							</Text>
						</BubbleText>

						<ActionBox>
							<BackButton to="/onboarding/3">
								Revenir
							</BackButton>
							<LaddaButton
								type="submit"
								className="a-button-onboarding"
								// className="a-button a-button-red"
								loading={this.state.loadingForm}
								data-style={SLIDE_UP}
								data-spinner-size={30}
								data-spinner-color="#fff"
								data-spinner-lines={12}
							>
								Suivant
							</LaddaButton>
						</ActionBox>
					</form>
				</Container>
			)
		case 'conciergerie-izipop-bpalc.cyconia.io':
			return (
				<Container>
					<form onSubmit={handleSubmit} noValidate>
						<BubbleText>
							<Text>
								C’est déjà fini, bravo vous êtes inscrit.
							</Text>
						</BubbleText>
						<BubbleText>
							<Text>
								<em>Comment je vous assiste ?</em>
							</Text>
							<Text>
								Il suffit de me demander !
							</Text>
							<Text>Quelques exemples de ce vous pouvez me demander :</Text>
							<Text>
								"Comment fonctionnent les bornes ?"<br />
								"Comment avoir Cyconia dans mon téléphone ?"<br />
								"Quels sont les tarifs du pressing ?"<br />
								"J'ai besoin de faire un ourlet."<br />
							</Text>
						</BubbleText>
						<ActionBox>
							<BackButton to="/onboarding/3">
								Revenir
							</BackButton>
							<LaddaButton
								type="submit"
								className="ladda-button"
								loading={false}
								// data-style={SLIDE_UP}
								data-spinner-size={30}
								data-spinner-color="#fff"
								data-spinner-lines={12}
							>
								Suivant
							</LaddaButton>
						</ActionBox>
					</form>
				</Container>

			)
		default:
			return (
				<Container>
					<form onSubmit={handleSubmit} noValidate>
						<BubbleText>
							<Text>
								C’est déjà fini, bravo vous êtes inscrit.
							</Text>
						</BubbleText>
						<BubbleText>
							<Text>
								<em>Comment je vous assiste ?</em>
							</Text>
							<Text>
								Il suffit de me demander !
							</Text>
							<Text>Quelques exemples pour mieux vous rendre service :</Text>
							<Text>
								"J’ai besoin d’un pressing"<br />
								"Livre-moi un panier bio"<br />
								"Trouve moi une nounou en urgence"<br />
								"Réserve moi un resto"
							</Text>
						</BubbleText>
						<ActionBox>
							<BackButton to="/onboarding/3">
								Revenir
							</BackButton>
							<LaddaButton
								type="submit"
								className="ladda-button"
								loading={false}
								// data-style={SLIDE_UP}
								data-spinner-size={30}
								data-spinner-color="#fff"
								data-spinner-lines={12}
							>
								Suivant
							</LaddaButton>
						</ActionBox>
					</form>
				</Container>
			)
	}
};
export default Step4;
