import React from "react";

const AccountIcon = ({
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon accountIcon"
      width={width}
      height={height}
      viewBox="0 0 18 29"
      fill="none"
    >
      <g fillRule="nonzero" fill="none">
        <path
          d="M17.48 26.513l-.548-4.738c-.083-.716-.539-1.316-1.172-1.544l-2.114-.761a.817.817 0 00-.832.168l-.623-.224c-.354-.128-.592-.485-.592-.888v-1.51l1.866-.84c.968-.435 1.599-1.458 1.599-2.591v-.468c.717 0 1.3-.629 1.3-1.404 0-.35-.12-.67-.315-.915.367-1.446 1.4-6.02.314-7.976L16.796.95s-4.548.117-6.93.936l.866-.936s-6.09 0-7.362 2.34c0 0-2.166-.02-2.166 2.808 0 1.034.534 3.618.763 4.68-.205.248-.33.576-.33.935 0 .775.582 1.404 1.3 1.404v.468c0 1.133.63 2.156 1.598 2.592l1.866.84v1.509c0 .403-.238.76-.592.888l-.623.224a.817.817 0 00-.832-.168l-2.114.761c-.633.228-1.089.829-1.172 1.544L.52 26.514c-.097.837.508 1.578 1.29 1.578H16.19c.781 0 1.386-.74 1.289-1.579z"
          fill="#FFD7A3"
        />
        <path
          d="M11.599 17.017H6.4v1.509a.942.942 0 01-.434.799c3.633.687 5.632-2.308 5.632-2.308z"
          fill="#FDC88E"
        />
        <path
          d="M2.07 11.245s-.866-3.83-.866-5.147c0-2.828 2.165-2.808 2.165-2.808C4.643.95 10.733.95 10.733.95l-.866.936c2.382-.819 6.93-.936 6.93-.936l-.433 1.872c1.3 2.34-.433 8.423-.433 8.423H2.07z"
          fill="#734A3E"
        />
        <path
          d="M10.732.95s-6.09 0-7.362 2.34c0 0-2.166-.02-2.166 2.808 0 1.317.866 5.147.866 5.147S11.075 6.897 16.796.95c0 0-4.548.117-6.93.936l.866-.936z"
          fill="#623F33"
        />
        <path
          d="M15.064 10.31v-.937c0-1.292-.97-2.34-2.166-2.34H5.102c-1.196 0-2.166 1.048-2.166 2.34v.936c-.717 0-1.3.629-1.3 1.404 0 .775.583 1.404 1.3 1.404v.468c0 1.133.631 2.156 1.6 2.592l2.798 1.26c.528.237 1.094.36 1.666.36.572 0 1.138-.123 1.666-.36l2.799-1.26c.968-.436 1.599-1.459 1.599-2.592v-.468c.717 0 1.3-.629 1.3-1.404 0-.775-.583-1.404-1.3-1.404z"
          fill="#FFE1B2"
        />
        <path
          d="M5.968 12.181c-.238 0-.433-.21-.433-.468v-.468c0-.257.195-.468.433-.468s.433.21.433.468v.468c0 .257-.195.468-.433.468zM12.032 12.181c-.238 0-.433-.21-.433-.468v-.468c0-.257.195-.468.433-.468s.433.21.433.468v.468c0 .257-.195.468-.433.468z"
          fill="#623F33"
        />
        <path
          d="M9 15.47c-.77 0-1.49-.205-1.976-.562a.492.492 0 01-.115-.65.414.414 0 01.602-.124c.34.25.897.4 1.489.4.592 0 1.148-.15 1.489-.4a.413.413 0 01.602.124.492.492 0 01-.115.65c-.486.357-1.206.561-1.976.561z"
          fill="#E4B07B"
        />
        <path
          d="M17.48 26.514l-.548-4.74c-.083-.714-.539-1.315-1.172-1.543l-2.114-.761a.82.82 0 00-.99.363C11.888 21.14 10.54 22.008 9 22.008c-1.539 0-2.888-.868-3.656-2.175a.82.82 0 00-.99-.363l-2.114.761c-.633.228-1.089.829-1.172 1.544L.52 26.514c-.097.837.508 1.578 1.29 1.578h14.38c.782 0 1.387-.74 1.29-1.578z"
          fill="#4A90E2"
        />
        <path
          d="M1.43 20.848a1.95 1.95 0 00-.362.927L.52 26.513c-.097.838.508 1.579 1.29 1.579h2.426v-3.046c0-.45-.15-.885-.423-1.226l-2.384-2.972zM16.57 20.848c.193.263.322.58.362.927l.548 4.738c.097.838-.508 1.579-1.29 1.579h-2.426v-3.046c0-.45.15-.885.423-1.226l2.384-2.972z"
          fill="#2768B3"
        />
        <path
          d="M5.21 15.206a2.294 2.294 0 01-.541-1.498V8.404c1.378-.111 5.725-.517 8.23-1.37H5.101c-.15 0-.294.018-.433.051v-.004c-.989.216-1.733 1.16-1.733 2.292v.936c-.717 0-1.3.629-1.3 1.404 0 .775.583 1.404 1.3 1.404v.468c0 1.133.631 2.156 1.6 2.592l2.798 1.26c.137.061.277.114.418.16-1.236-.977-2.06-1.832-2.542-2.39z"
          fill="#FFD7A3"
        />
      </g>
    </svg>
  );
};

export default AccountIcon;
