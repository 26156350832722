import styled from "styled-components";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { COLORS } from '../../../constants/design';

export const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #5A5A5A;
`;

export const StyledTextArea = styled(TextareaAutosize)`
  && {
    margin-top: 10px;
    max-width: 100%;
    font-size: 0.9rem;
    color: #5A5A5A;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid ${COLORS.LIGHT_GREY_4};
  }
`;