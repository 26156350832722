import _ from 'lodash'

export const getTicketName = (data) => {
  // console.log('data', data)
  try {
    const parsedFullSubmission = JSON.parse(data.full_submission);
    try {
      const raw = JSON.parse(parsedFullSubmission.rawRequest);
      const formData = {};

      _.map(raw, (item, key) => {
        const infos = key.split("_");

        if (infos[1] === "system") {
          const dataKey = infos[2];
          formData[dataKey] = item;
        }
      });
      return formData?.ticketTitle !== "" ? formData?.ticketTitle : null
    } catch (err) {
      console.error('err2', err)
      return null
    }
  } catch (err) {
    console.error('err1', err)
    return null
  }
}