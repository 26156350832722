import React from "react";

const HomeIcon = ({
  color = "#2B2B2B",
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon homeIcon"
      width={width}
      height={height}
      viewBox="0 0 16 15"
      fill="none"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
          <path
            d="M14.3757962,14 L9.82802548,14 C9.50106157,14 9.23354565,13.7317722 9.23354565,13.4039383 L9.23354565,10.572645 C9.23354565,9.85737094 8.66878981,9.29111229 7.95541401,9.29111229 C7.24203822,9.29111229 6.67728238,9.85737094 6.67728238,10.572645 L6.67728238,13.4039383 C6.67728238,13.7317722 6.40976645,14 6.08280255,14 L1.59447983,14 C1.26751592,14 1,13.7317722 1,13.4039383 L1,5.77434806 C1,5.2378925 1.26751592,4.73124002 1.6836518,4.43320915 L7.6581741,0.111761575 C7.86624204,-0.0372538584 8.13375796,-0.0372538584 8.3418259,0.111761575 L14.3163482,4.43320915 C14.7324841,4.73124002 15,5.2378925 15,5.77434806 L15,13.4337414 C14.970276,13.7317722 14.7027601,14 14.3757962,14 Z M10.4225053,12.8376796 L13.8110403,12.8376796 L13.8110403,5.77434806 C13.8110403,5.62533262 13.7218684,5.47631719 13.6029724,5.38690793 L7.985138,1.30388505 L2.36730361,5.35710484 C2.24840764,5.4465141 2.15923567,5.59552954 2.15923567,5.74454497 L2.15923567,12.8078765 L5.5477707,12.8078765 L5.5477707,10.572645 C5.5477707,9.23150612 6.64755839,8.12879191 7.985138,8.12879191 C9.32271762,8.12879191 10.4225053,9.23150612 10.4225053,10.572645 L10.4225053,12.8376796 Z"
            stroke={color}
            strokeWidth="0.2"
            fill={color}
            fillRule="nonzero"
          >
          </path>
      </g>
    </svg>
  );
};

export default HomeIcon;
