import React from 'react';

import {
  StyledForm
} from './style';

const GenericFormikForm = ({
  className,
  children,
  ...rest
}) => {
  return (
    <StyledForm className={`${className ? className : ''} genericFormikForm`} {...rest}>
      {children}
    </StyledForm>
  )
};

export default GenericFormikForm;