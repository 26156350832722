import React from "react";
import {
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";

import TrainingCyco from "../../../../components/organisms/TrainingCyco";

const Routes = ({ dmId, skillsDomainsList }) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:dmId?`} render={() => (
        <TrainingCyco skillsDomainsList={skillsDomainsList} />
      )} />
    </Switch>
  );
};

export default Routes;