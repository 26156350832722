import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  shallowEqual,
  useSelector,
} from "react-redux";

// Components
import ModalCpt from '../../../../../../components/organisms/modalCpt/';
import ThinkingIllustration from '../../../../../../components/atoms/illustrations/thinkingIllustration';

import {
  isUpdateNegativeBotFeedbackOnSuccess,
} from '../../../../../../../../store/adminFront/selectors';

// Import style
import {
  CustomFeedModalContainer,
  CustomFeedModalOption,
  CustomFeedModalInput,
  ThinkingIllustrationWrapper,
  RadioCptCustom
} from './style'

const ModalDisapprove = ({
  openModal,
  closeModal,
  actionClick,
}) => {
  const {
    isNegativeUpdateSuccess,
  } = useSelector(
    state => ({
      isNegativeUpdateSuccess: isUpdateNegativeBotFeedbackOnSuccess(state),
    }),
    shallowEqual
  );
  const [noteStatus, setNoteStatus] = useState('Incomplète');
  const [noteContent, setNoteContent] = useState('');

  const onSubmit = () => {
    if (!!noteContent?.length) {
      actionClick({
        responseStatus: noteStatus,
        comment: noteContent,
      });
    } else {
      toast.error("Tous les champs sont obligatoires", {
        position: toast.POSITION.TOP_RIGHT,
        className: "custom-toast custom-toast-error",
        bodyClassName: "custom-toast__body custom-toast__body-error",
        progressClassName: "custom-toast__progress"
      });
    }
  };

  const onCloseModal = () => {
    setNoteStatus('Incomplète');
    setNoteContent('');
    closeModal();
  };

  useEffect(() => {
    if (isNegativeUpdateSuccess) {
      setNoteStatus('Incomplète');
      setNoteContent('');
    }
  }, [isNegativeUpdateSuccess])

  return (
    <ModalCpt
      modalHeadIllustration={
        <ThinkingIllustrationWrapper>
          <ThinkingIllustration />
        </ThinkingIllustrationWrapper>
      }
      dialogTitle="Laissez une note"
      dialogContent={(
        <CustomFeedModalContainer>
          <CustomFeedModalOption>
            <RadioCptCustom
              defaultValueSelected={noteStatus}
              onChange={(e) => setNoteStatus(e.target.value)}
              radioList={[
                {
                  // name: 'incompleteStatus',
                  name: 'Incomplète',
                  label: 'Incomplète',
                  disabled: false,
                },
                {
                  // name: 'wrongStatus',
                  name: 'Incorrecte',
                  label: 'Incorrecte',
                  disabled: false,
                },
                {
                  // name: 'badContextStatus',
                  name: 'Hors contexte',
                  label: 'Hors contexte',
                  disabled: false,
                },
              ]}
            />
          </CustomFeedModalOption>

          <CustomFeedModalInput
            value={noteContent}
            onChange={(e) => setNoteContent(e.target.value)}
          />
        </CustomFeedModalContainer >
      )}
      appearenceBtnClose="primary"
      dialogBtnCloseText="Annuler"
      appearenceBtnAction="secondary"
      dialogBtnActionText="Valider"
      actionClick={onSubmit}
      openModal={openModal}
      closeModal={onCloseModal}
    />
  );
}

export default ModalDisapprove;
