import { call, put, select } from 'redux-saga/effects'
import { getCorporationsSuccess, getCorporationsError } from '../actions';
import endpoint from '../../../helpers/api';
import apiCall from '../../apiCall';
import * as authenticationSelectors from '../../authentication/selectors';

export function* getCorporationsHandler() {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const corporationList = yield call(apiCall, endpoint.getAllCorporations, userToken);
    yield put(getCorporationsSuccess(corporationList));
  } catch (err) {
    yield put(getCorporationsError(err));
  }
}