import React from "react";

const CloseIcon = ({
  color = "#ffffff",
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon closeIcon"
      width={width}
      height={height}
      viewBox="0 0 13 13"
      fill="none"
    >
      <path
        d="M11.97 9.95L8.537 6.516a.286.286 0 010-.404l3.435-3.435A1.429 1.429 0 009.95.657L6.515 4.09a.286.286 0 01-.404 0L2.677.657a1.429 1.429 0 00-2.02 2.02L4.09 6.112a.286.286 0 010 .404L.657 9.95a1.429 1.429 0 002.02 2.02l3.435-3.434a.286.286 0 01.404 0l3.434 3.435a1.429 1.429 0 002.02-2.02z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export default CloseIcon;
