import { call, put, select } from 'redux-saga/effects'
import {
  getProvidersError,
  getProvidersSuccess,
  saveProviderError,
  saveProviderSuccess,
  deleteProviderError,
  deleteProviderSuccess
} from '../actions';
import endpoint from '../../../helpers/api';
import apiCall from '../../apiCall';
import * as authenticationSelectors from '../../authentication/selectors';
import { addNewServiceProvider, editServiceProvider, deleteServiceProvider } from '../../../services/providerService';
import { toaster } from '../../../helpers/toaster';

export function* getProvidersHandler() {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const providersList = yield call(apiCall, endpoint.getServiceProviders, userToken);
    yield put(getProvidersSuccess(providersList));
  } catch (err) {
    yield put(getProvidersError(err));
  }
}

export function* saveProviderHandler(action) {
  const { formdata, id } = action.data;
  if (id === null) {
    try {
      const userToken = yield select(authenticationSelectors.getUserToken);
      const provider = yield call(addNewServiceProvider, formdata, endpoint.postServiceProvider, userToken);
      yield put(saveProviderSuccess(provider.data))
      toaster('Ce prestataire a bien été ajouté', 'success');
    } catch (err) {
      yield put(saveProviderError(err))
      toaster(`Une erreur s'est produite. Veuillez réessayer`, 'error');
    }
  } else if (id !== null) {
    try {
      const userToken = yield select(authenticationSelectors.getUserToken);
      const provider = yield call(editServiceProvider, formdata, endpoint.editServiceProvider(id), userToken);
      yield put(saveProviderSuccess(provider.data))
      toaster('Votre mise à jour est bien enregistrée', 'success');
    } catch (err) {
      yield put(saveProviderError(err))
      toaster(`Une erreur s'est produite. Veuillez réessayer`, 'error');
    }
  }
}

export function* deleteProviderHandler(action) {
  const id = action.data
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    yield call(deleteServiceProvider, endpoint.deleteServiceProvider, userToken, id);
    yield put(deleteProviderSuccess(id));
    toaster('Ce prestataire a bien été supprimé', 'success');
  } catch (err) {
    yield put(deleteProviderError(err));
    toaster(`Une erreur s'est produite. Veuillez réessayer`, 'error');
  }
}
