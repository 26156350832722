const components = colors => {
  const buttons = {
    primary: {
      backgroundColor: colors.primary,
      textColor: colors.white,
      hover: {
        backgroundColor: colors.primaryDark,
        textColor: colors.white,
      },
    },
    secondary: {
      backgroundColor: colors.grey1,
      textColor: colors.textSecondary,
      hover: {
        backgroundColor: colors.primary,
        textColor: colors.white,
      },
    },
    tertiary: {
      backgroundColor: colors.grey2,
      textColor: colors.textSecondary,
      hover: {
        backgroundColor: colors.grey3,
        textColor: colors.textPrimary,
      },
    },
    lightBlue: {
      backgroundColor: colors.blue1,
      textColor: colors.blue5,
      hover: {
        backgroundColor: colors.blue2,
        textColor: colors.blue5,
      },
    },
    darkBlue: {
      backgroundColor: colors.blue2,
      textColor: colors.blue5,
      hover: {
        backgroundColor: colors.blue3,
        textColor: colors.blue5,
      },
    },
    outlineBlack: {
      backgroundColor: colors.ghost,
      textColor: colors.textSecondary,
      border: '1px solid ' + colors.grey7,
      hover: {
        backgroundColor: colors.dark,
        textColor: colors.white,
        border: '1px solid ' + colors.dark,
      },
    },
    outlineWhite: {
      backgroundColor: colors.ghost,
      textColor: colors.white,
      border: '1px solid ' + colors.white,
      hover: {
        backgroundColor: colors.white,
        textColor: colors.textPrimary,
        border: '1px solid ' + colors.white,
      },
    },
    gradientBlue: {
      backgroundColor: colors.ghost,
      backgroundImage: colors.blueGradient,
      textColor: colors.white,
      hover: {
        backgroundColor: colors.ghost,
        backgroundImage: colors.blueGradient,
        textColor: colors.white,
      },
    },
  };

  return {
    buttons,
  };
};

export default components;
