import {
  all,
  put,
  takeEvery,
  call,
  select,
  take,
  delay,
} from "redux-saga/effects";
import endpoint from '../../helpers/api';
import apiCall from '../apiCall';
import { authHeader } from '../../helpers/auth-header';

/** Actions / Selectors */
import * as a from './actions';
import * as authenticationSelectors from '../authentication/selectors';
import * as collaboratorSelectors from '../collaborator/selectors';

async function postAPICall(endpoint, data) {
  return fetch(endpoint, {
    method: 'POST',
    headers: authHeader(),
    body: data
  })
  .then(res => res.json())
  .catch(err => err);
}

function* getUserSaga() {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const user = yield call(apiCall, endpoint.get_user, userToken);
    // console.log('user collab :', user);
    yield call([localStorage, 'setItem'], 'user', JSON.stringify(user));
    yield put(a.getUserSuccess(user));
  } catch (error) {
    yield put(a.getUserError(error));
  }
}

function* getCollaboratorSaga({ id }) {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const user = yield call(apiCall, endpoint.getCollabById(id), userToken);
    yield put(a.getCollaboratorSuccess(user));
  } catch (error) {
    yield put(a.getCollaboratorError(error));
  }
}

function* saveCollaboratorTransportsSaga({
  address,
  lat,
  lng,
  officeAddress,
  officeLat,
  officeLng,
  transport,
}) {
  try {
    const formData = new FormData();
    formData.append("address", address);
    formData.append("lat", lat);
    formData.append("lng", lng);
    formData.append("officeAddress", officeAddress);
    formData.append("officeLat", officeLat);
    formData.append("officeLng", officeLng);
    formData.append("transport", JSON.stringify(transport));
    const data = yield call(postAPICall, endpoint.onboardingStep3, formData);
    yield put(a.saveUserTransportsSuccess(data));
    yield delay(100);
    yield put(a.resetSaveUserTransports());
  } catch(error) {
    yield put(a.saveUserTransportsError(error));
    yield delay(100);
    yield put(a.resetSaveUserTransports());
  }
}

function* saveCollaboratorProfileSaga({ gender, age }) {
  try {
    const formData = new FormData();
    formData.append("gender", gender === "no" ? null : gender);
		formData.append("age", age === "empty" ? null : age);
    const data = yield call(postAPICall, endpoint.onboardingStep1, formData);
    yield put(a.saveUserProfileSuccess(data));
    yield delay(100);
    yield put(a.resetSaveUserProfile());
  } catch(error) {
    yield put(a.saveUserProfileError(error));
    yield delay(100);
    yield put(a.resetSaveUserProfile());
  }
}

function* saveCollaboratorInformationsSaga({
  firstname, lastname, email, currentPassword, newPassword, repeatNewPassword
}) {
  try {
    const formData = new FormData();
    const passwordValue = (!!currentPassword?.length && (newPassword?.length === 0 || repeatNewPassword?.length === 0))
      ? ''
      : currentPassword;
    const user = {
      firstname,
      lastname,
      email,
      plainPassword: passwordValue,
      newPassword,
    };
    Object.keys(user).forEach(key => {
      if (!!user[key]?.length) {
        if (key !== "newPassword") {
          formData.append(key, user[key]);
        } else {
          formData.append("newPassword", JSON.stringify({ first: newPassword, second: repeatNewPassword }));
        }
      }
    });
    const { id } = yield select(collaboratorSelectors.getUser);
    const data = yield call(postAPICall, endpoint.userInfoUpdate(id), formData);
    if (data.code === 400 || data.status === 400) {
      yield put(a.saveUserInformationsError(data));
      yield delay(100);
      yield put(a.resetSaveUserInformations());
    } else {
      yield put(a.saveUserInformationsSuccess(data));
      yield delay(100);
      yield put(a.resetSaveUserInformations());
    }
  } catch(error) {
    yield put(a.saveUserInformationsError(error));
    yield delay(100);
    yield put(a.resetSaveUserInformations());
  }
}

function* updateLocalUserInfosSaga({ user }) {
  yield call([localStorage, 'setItem'], 'user', JSON.stringify(user));
  yield put(a.updateUserLocalInfosSuccess(user));
}

export default function* collaboratorRootSaga() {
  yield all([
    takeEvery(a.GET_USER.REQUEST, getUserSaga),
    takeEvery(a.GET_COLLABORATEUR.REQUEST, getCollaboratorSaga),
    takeEvery(a.SAVE_COLLABORATEUR_TRANSPORTS.REQUEST, saveCollaboratorTransportsSaga),
    takeEvery(a.SAVE_COLLABORATEUR_PROFILE.REQUEST, saveCollaboratorProfileSaga),
    takeEvery(a.SAVE_COLLABORATEUR_INFORMATIONS.REQUEST, saveCollaboratorInformationsSaga),
    takeEvery(a.UPDATE_LOCAL_USER_INFOS.REQUEST, updateLocalUserInfosSaga),
  ]);
}
