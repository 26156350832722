import { ThemeProvider } from "styled-components";
import Routes from './routes';
import theme from "./theme-style";
import MainDefaultStyle from "./theme-style/main-default-style";

const Conciergerie = () => (
  <ThemeProvider theme={theme}>
    <MainDefaultStyle />
    <Routes />
  </ThemeProvider>
);

export default Conciergerie;
