import Dialog from '@material-ui/core/Dialog';
import styled from "styled-components";
import {
  NavLink,
} from "react-router-dom";


export const CustomDialog = styled(Dialog)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
 /*  padding: 20px; */
`;

export const DialogContainer = styled.div` 
    max-width: 460px;
    width: 100%;
    padding: 0;
    background-color: white;
    border-radius: 6px;
    color: #5a5a5a;
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 60px;
`;

export const Title = styled.span`
    font-size: 19px;
    font-weight: 900;
    text-align: center;
    color: #212121;
    margin-bottom: 40px;
    text-transform: uppercase;

`;

export const Text = styled.span`
  font-size: 13px;
  font-weight: 500;
  color: #5A5A5A;
`;

export const LogOutButton = styled(NavLink)`
  width: 100%;
  background-color: #463e96;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  border-radius: 6px;
  cursor: pointer;
  border-bottom: 0;
  text-decoration: none;
  cursor: pointer;
`;

export const LogOutButtonText = styled.span`
  padding: 15px;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
`;

