// DASHBOARD
export const getWeatherData = state => state.sidebar.weather.data;
export const getTrafficData = state => state.sidebar.traffic.data;
export const getParoleCyconiaData = state => state.sidebar.paroleCyconia.data;
export const getParoleCyconiaIsLoading = state => state.sidebar.paroleCyconia.loading;
export const getSelectedParoleData = state => state.sidebar.selectedParole.data;

// COMPETENCES
export const getExpertiseAreasData = state => state.sidebar.expertiseAreas.data;
export const getExpertiseAreasThemeData = state => state.sidebar.expertiseAreasTheme.data;

// CONCIERGERIE
export const getServiceCategoryListData = state => state.sidebar.serviceCategoryList.data;
export const getServiceCategoryListIsLoading = state => state.sidebar.serviceCategoryList.loading;

export const getServicesOrdersListData = state => state.sidebar.servicesOrdersList.data;
export const getServicesOrdersListIsLoading = state => state.sidebar.servicesOrdersList.loading;

export const getServicesCategoryByIdData = state => state.sidebar.servicesCategoryById.data;

// EXPRESSING LOGIN
export const isExpressingLoginOnError = state => state.sidebar.expressing.login.error;
export const isExpressingLoginOnSuccess = state => state.sidebar.expressing.login.success;
// EXPRESSING LOGOUT
export const isExpressingLogoutOnError = state => state.sidebar.expressing.logout.error;
export const isExpressingLogoutOnSuccess = state => state.sidebar.expressing.logout.success;
// EXPRESSING AMOUNT
export const getExpressingAmountData = state => state.sidebar.expressing.amount.data?.amount || 0;

export const getExpressingPastOrders = state => state.sidebar.expressing.pastOrders.data;

export const getExpressingOnGoingOrdersData = state => state.sidebar.expressing.onGoingOrders.data;
export const getExpressingPrestation = state => state.sidebar.expressing.selectedPrestation;

export const getSelectedServiceTicketData = state => state.sidebar.selectedServiceTicket.data;
export const getSelectedServiceTicketIsLoading = state => state.sidebar.selectedServiceTicket.get.loading;

export const getServiceMessagesData = state => state.sidebar.serviceMessages.data;
export const getServiceMessagesIsLoading = state => state.sidebar.serviceMessages.get.loading;
