import React, { useEffect, useState } from 'react';

// Components
import CheckIcon from '../../../../../../components/atoms/icons/check'
import CompanyIcon from '../../../../../../components/atoms/icons/company'
import { makeStyles } from '@material-ui/core/styles';

// Import style
import {
  HeaderContainer,
  HeaderLeftMenu,
  SitesLstBox,
  SitesLstBoxHeader,
  SitesLstBoxUl,
  SitesLstBoxLi,
  StyledButtonSelect,
  StyledSelectSearch,
  StyledDomainIcon,
  HeaderRightButtons,
  ButtonCptCustom,
  CheckIconWrapper,
  SitesLstBoxLiText,
  CompanyIconWrapper,
  TooltipCustom
} from './header.style'

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles((theme) => ({ // TODO UGLY NEED REWORK
    menuButton: {
        marginRight: -10,
    },
    hide: {
        display: 'none',
    },
    spacingLeft: {
      Left: 6,
    },
}));

const HeaderTrainingEditor = ({
  selectedSite,
  handleUpdateAllData,
  handleSelection,
  toggleDrawer,
  showList,
  setShowList,
  sitesList,
}) => {
  const classes = useStyles();

  return (
    <HeaderContainer>
      <HeaderLeftMenu>

        {(selectedSite?.name === "Réponse principale de l'entreprise") ? (
          <TooltipCustom
            title="La 'réponse principale de l'entreprise' est la réponse qui est donnée pour l'ensemble de tous les sites de l'entreprise."
            placement="right"
            className={classes.spacingLeft}
          >
            <StyledButtonSelect
              onClick={(e) => {
                e.preventDefault();
                setShowList(!showList)
              }}
            >
              <span>{selectedSite.name}</span>
              <div><ArrowDropDownIcon /></div>
            </StyledButtonSelect>
          </TooltipCustom>
        ) : (
          <StyledButtonSelect
            onClick={(e) => {
              e.preventDefault();
              setShowList(!showList)
            }}
          >
            <span>{selectedSite?.name}</span>
            <div><ArrowDropDownIcon /></div>
          </StyledButtonSelect>
        )}
        {showList &&
          <SitesLstBox>
            <SitesLstBoxHeader>
              <span>  Tout les sites d’entreprise</span>
              <p>{sitesList.length} sites d’entreprise</p>
            </SitesLstBoxHeader>
            <SitesLstBoxUl>
              {(sitesList && !!sitesList?.length) ? (
                sitesList.map(site => {
                  const isItemActive = parseInt(selectedSite.id, 10) === parseInt(site.id, 10);
                  return (
                    <SitesLstBoxLi
                      key={site.id}
                      onClick={(e) => handleSelection(e, site.id)}
                      isActive={isItemActive}
                    >
                      <SitesLstBoxLiText>
                        <CompanyIconWrapper>
                          <CompanyIcon color={isItemActive ? "#357ED3" : "#5A5A5A"} />
                        </CompanyIconWrapper>
                        {site.name}
                      </SitesLstBoxLiText>
                      {(isItemActive) &&
                        <CheckIconWrapper>
                          <CheckIcon color="#357ED3" />
                        </CheckIconWrapper>
                      }
                    </SitesLstBoxLi>
                  );
                })
              ): (
                <SitesLstBoxLi>
                  <span>pas de site ...</span>
                </SitesLstBoxLi>
              )}
            </SitesLstBoxUl>
          </SitesLstBox>
        }


      </HeaderLeftMenu>

      <HeaderRightButtons>
        <ButtonCptCustom
          label="Annuler"
          appearence="outlineBlack"
          disabled={false}
          onClick={() => toggleDrawer(false)}
        />
        <ButtonCptCustom
          label="Enregistrer"
          appearence="gradientBlue"
          disabled={false}
          onClick={handleUpdateAllData}
        />
      </HeaderRightButtons>

    </HeaderContainer>
  );
}

export default HeaderTrainingEditor;
