import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const HeadList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
`;

export const Title = styled.h3`
  margin: 0 0 auto 0;
  color: #5A5A5A;
  font-size: 15px;
  line-height: 19px;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const BodyList = styled.ul`
  margin: 0;
  padding: 0;
`;

export const CompanyIconWrapper = styled.div`
  width: 12px;
  height: 10px;
  margin: 0 6px 0 0;
`;

export const BodyListItem = styled.li`
  margin: 0 -12px;
  padding: 6px 12px;
  list-style: none;
  border-radius: 6px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  &:hover{
    background-color: #F7FBFF;
    h4{
      background-color: #2768B3;
    }
    h5{
      color: #4A90E2;
    }
    span{
      color: #4A90E2;
    }
  }
  h4{
    color: #ffffff;
    font-size: 11px;
    line-height: 8px;
    font-weight: 700;
    padding: 7px 10px;
    border-radius: 4px;
    background-color: #357ED3;
    margin: 0 10px 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: all .2s ease-out;
  }
  h5{
    color: #5A5A5A;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin: 0 auto 0 0;
    transition: all .2s ease-out;
  }
  span{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    color: #777777;
    font-size: 11px;
    line-height: 8px;
    font-weight: 500;
    text-align: right;
    transition: all .2s ease-out;
  }
`;
