import styled from 'styled-components';

import GenericModal from '../../molecules/GenericModal';
import GenericAdminFrontHeader from '../../molecules/GenericAdminFrontHeader';
import breakpoints from "../../../theme-style/breakpoints";

import { COLORS } from '../../../constants/design';

export const StyledModal = styled(GenericModal)`
  animation: modalAppear 0.5s;
  position: relative;
  && {
    .modalContainer {
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 94%;
      flex: unset;
      max-width: unset;
      overflow: unset;

      @media ${breakpoints.mobileXL} {
        width: 650px;
      }
    }
  }
  @keyframes modalAppear {
    0% {
      bottom: -500px;
    }
    100% {
      bottom: 0px;
    }
  }
`;

export const StyledHeader = styled(GenericAdminFrontHeader)`
  border-radius: 10px 10px 0px 0px;
`;

export const StyledHeaderDescription = styled.div`
  display: flex;
  >div:not(:last-child) {
    margin-right: 15px;
  };
`;

export const ContentContainer = styled.div`
  display: flex;
  height: calc(100% - 74px);

  @media ${breakpoints.mobileXL} {
    height: calc(100% - 89px);
  }
`;

export const StyledCloseContainer = styled.div`
  position: absolute;
  z-index: 9999;
  color: ${COLORS.DARK_GREY};
  background-color: ${COLORS.LIGHT_GREY_1};
  border-radius: 50%;
  padding: 1px;
  display: flex;
  opacity: 0.8;
  top: 10px;
  right: 10px;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;