import { call, put, select } from 'redux-saga/effects'
import { getSitesError, getSitesSuccess } from '../actions';
import endpoint from '../../../helpers/api';
import apiCall from '../../apiCall';
import * as authenticationSelectors from '../../authentication/selectors';

export function* getSitesHandler() {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const sitesList = yield call(apiCall, endpoint.getSitesList, userToken);
    yield put(getSitesSuccess(sitesList));
  } catch (err) {
    yield put(getSitesError(err));
  }
}