import { call, put, select } from 'redux-saga/effects'
import {
  getConciergesError,
  getConciergesSuccess,
  editConciergeSuccess,
  editConciergeError
} from '../actions';
import endpoint from '../../../helpers/api';
import apiCall from '../../apiCall';
import * as authenticationSelectors from '../../authentication/selectors';
import { editConciergeService } from '../../../services/conciergeService';
import { toaster } from '../../../helpers/toaster';

export function* getConciergesHandler() {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const conciergeInfos = yield call(apiCall, endpoint.getConciergeInfos, userToken);
    yield put(getConciergesSuccess(conciergeInfos));
  } catch (err) {
    yield put(getConciergesError(err));
  }
}

export function* editConciergeHandler(action) {
  const { id, formData } = action.data;
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const endPointUrl = id !== 'new' ? endpoint.editConciergeInfo(id) : endpoint.saveConciergeInfo;
    const response = yield call(editConciergeService, endPointUrl, userToken, formData)
    yield put(editConciergeSuccess(response.data));
    toaster('Vos changements ont été enregistrés', 'success');
  } catch (err) {
    yield put(editConciergeError(err));
    toaster(`Une erreur s'est produite. Veuillez réessayer`, 'error');
  }
}