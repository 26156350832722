import React from "react";
import {
  Redirect,
} from "react-router-dom";
import {
  shallowEqual,
  useSelector,
} from "react-redux";

import {
  getLandingRoute,
} from '../../store/company/selectors';

import {
  getUser as getUserData,
} from '../../store/collaborator/selectors';

const Landing = () => {
  const {
    landingRoute,
    userData
  } = useSelector(
    state => ({
      landingRoute: getLandingRoute(state),
      userData: getUserData(state),
    }),
    shallowEqual
  );

  // console.log('landingRoute', landingRoute);

  if (userData.onboarding_step !== "finished") {
    return (
      <Redirect
        to={`/onboarding/${userData.onboarding_step || ''}`}
      />
    )
  }
  else {
    return (
      <Redirect
        to={landingRoute}
      />
    )
  }
};

export default Landing;