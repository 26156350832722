import styled from "styled-components";
import { COLORS } from '../../../../../../constants/design'
import MessageIcon from '@material-ui/icons/Message';

export const Card = styled.div`
  width: 100%;
  min-height: 7vw;
  background-color: ${COLORS.WHITE};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  padding: 10px;
  position: relative;
  cursor: pointer;
  // margin-top: 15px;
  box-shadow: 1px 1px 3px 1px rgb(187 187 187 / 30%);
  .new_request {
    background-color: ${COLORS.PRIMARY_BLUE};
  }
  .in_Progres {
    background-color: ${COLORS.ORANGE_1};
  }
  .send {
    background-color:${COLORS.LIGHT_BLUE_1};
  }
  .validated {
    background-color: ${COLORS.YELLOW_1};
  }
  .treated {
    background-color: ${COLORS.GREEN};
  }
`

export const CardHeader = styled.div`
  width: 85%;
  display: flex;
  flex-direction: column;
`;

export const CardBody = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
`;

export const ColorChip = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 3px;
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: ${props => props.onBehalf ? COLORS.PRIMARY_BLUE : 'transparent'};
`;

export const CardTitle = styled.span`
  font-size: 14px;
  font-weight: 700;
  word-wrap: break-word;
  color: ${COLORS.DARK_GREY};
`

export const CardDate = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: ${COLORS.DARK_GREY};
`
export const MessageNumber = styled.span`
  color: ${COLORS.LIGHT_GREY_4};
  font-size: 12px;
  font-weight: 500;
`;

export const CustomMessageIcon = styled(MessageIcon)`
`;

export const IconContainer = styled.div`
  position: absolute;
  right: 10px;
  bottom: 5px;
  &&{
    height: fit-content;
    .MuiSvgIcon-root {
      color: ${COLORS.PRIMARY_BLUE};
      font-size: 1.5rem;
    }
  }
`;