import React from 'react';
import _ from 'lodash';

import {
  NAV_BAR_ROUTES,
  SIDE_BAR_BLOCKS
} from '../../../../../../../constants/sideBarConstants';

import GenericCheckBox from '../../../../../../../components/molecules/GenericCheckbox';


import {
  MainContainer,
  StyledAccordion,
  StyledTitle,
  DetailContainer,
  BarContainer,
  ModuleContainer,
} from './style';

const ContextSettings = ({
  context
}) => {
  const renderBlocks = (navBar) => {
    if (!_.isEmpty(navBar.blocks)) {
      return _.map(navBar.blocks, (data, key) => {
        return (
          <ModuleContainer key={key} active={context.active && data.active && navBar.active}>
            {SIDE_BAR_BLOCKS[key]?.label}
          </ModuleContainer>
        )
      });
    }
    else {
      return (
        <ModuleContainer>
          {`No module available`}
        </ModuleContainer>
      );
    }
  };

  const renderNavBar = (navBar) => {
    return _.map(navBar, (data, key) => {
      return (
        <BarContainer key={key}>
          <GenericCheckBox
            title={NAV_BAR_ROUTES[key]?.label}
            readOnly
            checked={data.active}
            disabled={!context.active}
          />
          {renderBlocks(data)}
        </BarContainer>
      )
    });
  };

  return (
    <MainContainer>
      {context &&
        <StyledAccordion
          headerComponent={
            <StyledTitle>
              {`Navigation settings ${context.active ? '' : '(pas actif)'}`}
            </StyledTitle>
          }
          detailComponent={
            <DetailContainer>
              <StyledTitle>
                {`Landing route : ${NAV_BAR_ROUTES[context.landingRoute]?.label || 'Comportement par défaut (Tableau de bord)'}`}
              </StyledTitle>
              {renderNavBar(context.navBar)}
            </DetailContainer>
          }
        />
      }
    </MainContainer>
  );
};

export default ContextSettings;