import React from 'react';
import {
  useDispatch,
} from "react-redux";

// Layout
import DetailsSidebarFrontBaseLayout from "../../../layouts/detailsSidebarFrontBaseLayout";

// Components
import BackIcon from '../../../components/atoms/icons/back';
import SendIcon from '../../../components/atoms/icons/send';

// Hooks
import useTheme from "../../../theme-style/useThemeCustomHook";

// Store
import {
  setMessageFromSideBar
} from '../../../../../store/chatbot/actions';

// Styles
import {
  Container,
  SkillsWrapper,
  Header,
  BackButton,
  Title,
  BackIconWrapper,
  SkillsList,
  SkillsItem,
  SkillsItemCircle,
  SkillsItemContent,
  SkillsItemTitle,
  SkillsItemText,
  SendIconWrapper
} from "../style";

const Knowledge = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const onSkillsItemClick = (message) => {
    dispatch(setMessageFromSideBar(message));
  }

  return (
    <DetailsSidebarFrontBaseLayout
      id="detailsSidebarLayoutSkillsHelpers"
      className="detailsSidebarLayoutSkillsHelpers page-container sidebarPage"
    >
      <Container>
        <SkillsWrapper>
          <Header to="/competences">
            <BackButton>
              <BackIconWrapper>
                <BackIcon color={theme.colors.white}/>
              </BackIconWrapper>
            </BackButton>
            <Title>
              Faire connaissance avec Cyconia 🙂
            </Title>
          </Header>

          <SkillsList>
            <SkillsItem 
              onClick={() => onSkillsItemClick(
                "Comment tu t'appelles ?"
              )}
            >
              <SkillsItemCircle> C </SkillsItemCircle>
              <SkillsItemContent>
                <SkillsItemTitle>
                  Cyconia
                </SkillsItemTitle>
                <SkillsItemText>
                  Découvrez qui est Cyconia
                </SkillsItemText>
              </SkillsItemContent>
              <SendIconWrapper>
                <SendIcon />
              </SendIconWrapper>
            </SkillsItem>

            <SkillsItem 
              onClick={() => onSkillsItemClick(
                "D'où viens-tu ?"
              )}
            >
              <SkillsItemCircle> H </SkillsItemCircle>
              <SkillsItemContent>
                <SkillsItemTitle>
                  L'histoire de Cyconia
                </SkillsItemTitle>
                <SkillsItemText>
                  Quelles est l'histoire de Cyconia ?
                </SkillsItemText>
              </SkillsItemContent>
              <SendIconWrapper>
                <SendIcon />
              </SendIconWrapper>
            </SkillsItem>

            <SkillsItem 
              onClick={() => onSkillsItemClick(
                "Qui es-tu ?"
              )}
            >
              <SkillsItemCircle> C </SkillsItemCircle>
              <SkillsItemContent>
                <SkillsItemTitle>
                  Cyconia, qui est-il ?
                </SkillsItemTitle>
                <SkillsItemText>
                  Vous souhaitez savoir qui est Cyconia ? Demandez-lui !
                </SkillsItemText>
              </SkillsItemContent>
              <SendIconWrapper>
                <SendIcon />
              </SendIconWrapper>
            </SkillsItem>

            <SkillsItem 
              onClick={() => onSkillsItemClick(
                "C'est quoi l'intelligence artificielle ?"
              )}
            >
              <SkillsItemCircle> I </SkillsItemCircle>
              <SkillsItemContent>
                <SkillsItemTitle>
                  L'intelligence Cyconia
                </SkillsItemTitle>
                <SkillsItemText>
                  Cyconia est intelligent, oui mais comment ?
                </SkillsItemText>
              </SkillsItemContent>
              <SendIconWrapper>
                <SendIcon />
              </SendIconWrapper>
            </SkillsItem>

            <SkillsItem 
              onClick={() => onSkillsItemClick(
                "À quoi tu sers ?"
              )}
            >
              <SkillsItemCircle> U </SkillsItemCircle>
              <SkillsItemContent>
                <SkillsItemTitle>
                  L'usage Cyconia
                </SkillsItemTitle>
                <SkillsItemText>
                  À quoi sert Cyconia ? Vaste question !
                </SkillsItemText>
              </SkillsItemContent>
              <SendIconWrapper>
                <SendIcon />
              </SendIconWrapper>
            </SkillsItem>

            <SkillsItem 
              onClick={() => onSkillsItemClick(
                "Où habites-tu ?"
              )}
            >
              <SkillsItemCircle> L </SkillsItemCircle>
              <SkillsItemContent>
                <SkillsItemTitle>
                  Localisation Cyconia
                </SkillsItemTitle>
                <SkillsItemText>
                  Où se trouve Cyconia ?
                </SkillsItemText>
              </SkillsItemContent>
              <SendIconWrapper>
                <SendIcon />
              </SendIconWrapper>
            </SkillsItem>
            
          </SkillsList>
        </SkillsWrapper>
      </Container>
    </DetailsSidebarFrontBaseLayout>
  );
}

export default Knowledge;
