import { getURLApi } from './helpers';
require('dotenv').config();

const URL_API = getURLApi();
const BASE_URL = URL_API.slice(0, URL_API.length - 4);
const VERSION_API = process.env.REACT_APP_API_VERSION;
const REACT_APP_URL = process.env.REACT_APP_URL;

const endpoint = {
  base_url: BASE_URL,
  url_api: URL_API,
  react_app_url: REACT_APP_URL,
  getNews: URL_API + VERSION_API + 'news',
  userIntroUpdate: URL_API + VERSION_API + 'collaborator/',
  // botServer: URL_API.replace('api/', '') + 'home/bot_server', // This is the old bot_server api
  botServer: URL_API + 'v2/home/bot_server',
  botForm: URL_API + VERSION_API + 'collaborator/feedback/bot/form',
  sendPositiveBotFeedback: URL_API + VERSION_API + 'collaborator/feedback/bot/positive',
  addRequestService: URL_API + VERSION_API + 'partner/request/add',
}
export {
  BASE_URL,
  URL_API
}

export default endpoint;