import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Message from './components/Message';

import {
  StyledMainContainer,
} from './style';

const MessageList = ({ 
  messageList,
  isAllowedToEditResponse, 
  onMessageButtonClick, 
  onFeedbackClick,
  // PROPS FOR BOTMAN
  onBotmanFeedbackClick,
}) => {
  const messagesEndRef = useRef();

  useEffect(() => {
    messagesEndRef.current.scrollIntoView({ behavior: 'auto' });
  }, [messageList])

  return (
    <StyledMainContainer>
      {_.map(messageList, (message, index) => {
        return (
          <Message
            key={index}
            message={message}
            messageIndex={index}
            isAllowedToEditResponse={isAllowedToEditResponse}
            onMessageButtonClick={onMessageButtonClick}
            onFeedbackClick={onFeedbackClick}
            isReceived={message.isReceived}
            // PROPS FOR BOTMAN
            onBotmanFeedbackClick={onBotmanFeedbackClick}
          />
        )
      })}
      <div ref={messagesEndRef} />
    </StyledMainContainer>
  )
};

MessageList.propTypes = {
  messageList: PropTypes.array,
  isAllowedToEditResponse: PropTypes.bool,
  onMessageButtonClick: PropTypes.func,
  onFeedbackClick: PropTypes.func,
};

export default MessageList;