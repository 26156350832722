import styled from "styled-components";
// import Dialog from "@material-ui/core/Dialog";

// import { COLORS } from "../../../../../../../constants/design";

export const MainContainer = styled.div`
  >div:not(:last-child) {
    margin-bottom: 10px;
  }
`;

// export const OpenResponseBuilderModalButton = styled.div`
//   border: 1px solid ${COLORS.LIGHT_GREY_4};
//   padding: 15px;
//   border-radius: 5px;
//   color: ${COLORS.DARK_GREY};
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   cursor: pointer;
//   :hover {
//     background-color: ${COLORS.BLACK_TRANSPARENT_10};
//   }
//   >div:not(:last-child) {
//     margin-right: 10px;
//   }
// `;

// export const StyledDialog = styled(Dialog)`
//   && {
//     .MuiDialog-container {
//       .MuiDialog-paper {
//         color: #212121;
//         background-color: #FFFFFF;
//         border-radius: 10px;
//         &.MuiPaper-rounded {
//           border-radius: 10px;
//           width: 100%;
//           max-width: 750px;
//         }
//         .MuiDialogTitle-root {
//           text-align: center;
//           font-weight: 600;
//           padding: 20px 50px 0px 50px;
//         }
//         .MuiDialogContent-root {
//           padding: 40px 50px;
//           .MuiTypography-colorTextSecondary {
//             text-align: center;
//             font-size: 14px;
//             color: rgba(0, 0, 0, 0.8);
//           }
//         }
//         .MuiDialogActions-root {
//           justify-content: space-between;
//           padding: 0 50px 40px 50px;
//           .MuiButtonBase-root {
//             height: 52px;
//             margin: 0 10px;
//             .MuiButton-label {
//               font-size: 12px;
//             }
//             &:hover {
//               .MuiButton-label {
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `;