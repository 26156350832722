import {
  all,
  put,
  takeEvery,
  call,
  delay,
  select,
  take,
} from "redux-saga/effects";
import endpoint from '../../helpers/api';
import apiCall from '../apiCall';

import * as authenticationSelectors from '../authentication/selectors';
import * as collaboratorSelectors from '../collaborator/selectors';
import * as companyActions from '../company/actions';

/** Actions / Slectors */
import * as a from './actions';

function* getConfigSaga({ subdomain, companysite }) {
  // console.log('getConfigSagaSubDomain', subdomain)
  // console.log('getConfigSagaCompanySite', companysite)
  try {
    yield delay(450);// TO REPLACE WITH API CALL
    
    const userTokenVerification = yield select(authenticationSelectors.isUserTokenValid);
    const userToken = userTokenVerification ? 
      (yield select(authenticationSelectors.getUserToken)) :
      null;
    const user = userTokenVerification ? 
      (yield call(apiCall, endpoint.get_user, userToken)) :
      null;
    const userCompany = userTokenVerification ? user.company_site?.name : null;
    const companyInfo = yield call(apiCall, endpoint.companyInit);
    const formatedCompanyInfo = {
      ...companyInfo,
      settings: companyInfo.settings ? JSON.parse(companyInfo.settings) : null
    }
    // console.log('CONFIGURATION USER', user);
    // console.log('CONFIGURATION USER COMPANY', userCompany);
    // console.log('CONFIGURATION USER TOKEN', userToken);
    
    const configuration = {
      subDomain: subdomain || (process.env.REACT_APP_ENV === "develop"
        ? process.env.REACT_APP_WORKSPACE_ENV
        : window.location.hostname),
      companySite: userCompany || null,
      companyInfo: formatedCompanyInfo || null,
      };
    
    // console.log('CONFIGURATION SAGA', configuration);
    yield put(companyActions.companyInit());
    yield take(companyActions.INIT.SUCCESS);
    yield put(a.getConfigSuccess(configuration));
  } catch (error) {
    yield put(a.getConfigError(error));
  }
}

export default function* configRootSaga() {
  yield all([
    takeEvery(a.GET_CONFIG.REQUEST, getConfigSaga),
  ]);
}
