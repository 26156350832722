import styled, { css } from "styled-components";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import ButtonCpt from '../../../../molecules/buttonCpt';
import { COLORS } from '../../../../../constants/design';
import GenericMaterialMenu from "../../../../molecules/GenericMaterialMenu";
import GenericTitleWithToolTip from "../../../../molecules/GenericTitleWithToolTip";

export const StyledArrowDropDownIcon = styled(ArrowDropDownIcon)`
    font-weight: 400;
    margin-left: 5px;
    width: 24px;
`

// Shared style
const RowColumnFlexed = css`
  display: flex;
  height: 100%;
  padding: 0;
`;

// End Shared style
export const Container = styled.div`
  flex: 1;
`;

export const ButtonCptClose = styled(ButtonCpt)`
    &&{
      border-radius: 10px;
      position: absolute;
      width: auto;
      min-width: 80px;
      height: 46px;
      top: 20px;
      right: calc(100% + 20px);
    }
`;

export const ButtonCptCustom = styled(ButtonCpt)`
  &&{
    border-radius: 10px;
    width: auto;
    min-width: 80px;
    height: 46px;
  }
`;

export const SwipeableContainer = styled.div`
  min-width: 700px;
  width: 80vw;
  max-width: 1200px;
  height: 100%;
`;

// Training Eidt content
export const ContentContainer = styled.div`
  ${RowColumnFlexed}
`;

export const ContentLeftContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  overflow-y: auto;
  padding: 20px 25px;
  height: calc(100% - 98px);
`;

export const EditInformationWrapper = styled.div`
  height: 100%;
  .quill{
    width: 100%;
    height: calc(100% - 65px);
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;
    .ql-toolbar{
      background-color: #F4F4F4;
      border: 1px solid #D2D2D2;
      border-radius: 10px 10px 0 0;
      height: 44px;
      min-height: 44px;
      padding: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .ql-formats{
        display: inline-flex;
        align-items: center;
        height: 100%;
        height: 36px;
        border: 0;
        margin-left: 0;
        margin-right: 12px;
        &:first-child{
          .ql-header.ql-picker{
            border-radius: 7px 6px 6px 6px;
          }
        }
        .ql-header.ql-picker{
          border-radius: 4px;
          margin-left: 3px;
          height: 36px;
          display: inline-flex;
          align-items: center;
          border: 1px solid transparent;
          transition: all .2S ease-out;
          &:hover,
          &:focus{
            background-color: #F9F9F9;
            border: 1px solid #EDEDED;
          }
          .ql-picker-label{
            display: inline-flex;
            align-items: center;
          }
        }
        button{
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          height: 36px;
          padding: 8px;
          width: 36px;
          padding: 5px;
          border: 1px solid transparent;
          transition: all .2S ease-out;
          &:hover,
          &:focus{
            background-color: #F9F9F9;
            border: 1px solid #EDEDED;
          }
          svg{
            width: 18px;
          }
        }
      }
    }
    .ql-container{
      height: 100%;
      background-color: #FFFFFF;
      border: 0;
      border-left: 1px solid #D2D2D2;
      border-right: 1px solid #D2D2D2;
      border-radius: 0;
      .ql-editor{
        min-height: 60px;
      }
    }
  }
`;

// Styles feedbacks
export const EditInformationStatus = styled.span`
  margin: 0 0 12px 0;
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  color: #999999;
  text-align: left;
  line-height: 14px;
  overflow: hidden;
  white-space: nowrap;
  align-items: center;
  display: flex;
  text-overflow: ellipsis;
`;

export const EditInformationTitle = styled.p`
  margin: 0 0 6px 0;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  color: #777777;
  text-align: left;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const EditInformationHead = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

export const DislikeIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 100%;
  margin: 0 6px 0 0;
  svg{
    width: 14px;
    height: 14px;
  }
`;

export const LikeIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 100%;
  margin: 0 6px 0 0;
  svg{
    width: 14px;
    height: 14px;
    margin-top: 2px;
  }
`;

export const Approve = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  &:hover,
  &:focus{
    svg path{
      fill: #357ED3;
    }
    span{
      color: #357ED3;
    }
  }
  span{
    font-size: 12px;
    font-weight: 500;
    color: #777777;
    letter-spacing: 0;
    line-height: 9px;
    transition: all .2S ease-out;
  }
`;

export const Disapprove = styled.a`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  text-decoration: none;
  &:hover,
  &:focus{
    svg path{
      fill: #EC6B87;
    }
    span{
      color: #EC6B87;
    }
  }
  span{
    font-size: 12px;
    font-weight: 500;
    color: #777777;
    letter-spacing: 0;
    line-height: 9px;
    transition: all .2S ease-out;
  }
`;

export const ApproveDisapproveWrapper = styled.div`
  display: flex;
  background-color: #F4F4F4;
  border: 1px solid #D2D2D2;
  border-radius: ${props => props.isAdvanceResponse ? "10px 10px 10px 10px" : "0 0 10px 10px"};
  height: 44px;
  position: relative;
  padding: 0 10px 0 5px;
  svg path{
    transition: all .2S ease-out;
  }
`;

export const ApproveDisapproveStatus = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  letter-spacing: 0;
  display: flex;
  line-height: 10px;
  margin: 10px 0;
`;

// Card Information Elem
export const ContentRightContainer = styled.div`
  height: calc(100% - 88px);
  padding: 20px 25px;
  overflow-y: auto;
  flex: 0.6;
  // TODO FIX THE STYLE
  // display: flex;
  justify-content: flex-start;
  flex-direction: column;
  border-left: 1px solid #EFEFEF;
  .hrTemporary{
    border: 0;
    border-radius: 50px;
    border-top: 1px solid #ededed;
    /* &:first-child{
      display: none;
    } */
  }
`;

// Style Editor
export const EditorEl = styled.textarea`
  font-size: 24px;
  font-weight: 500;
  line-height: 18px;
  color: #5A5A5A;
`;

export const SwipeableContainerLoader = styled.div`
  min-width: 700px;
  width: 80vw;
  max-width: 1200px;
  height: 100%;
  display: flex;
  padding: 40vh 0px;
  justify-content: center;
  align-content: center;
`;

// Feeds modal
export const CustomFeedModal = styled.div`
  top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
  z-index: 9999;
  overflow: auto;
	position: fixed;
  visibility: hidden;
	margin-top: -250px;
	background: rgba(0,0,0,.8);
	transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  &:target {
    opacity: 1;
    margin-top: 0px;
    visibility: visible;
  }
`

export const CustomFeedModalContainer = styled.div`
  width: 70%;
  padding: 25px;
  background: #FFF;
  max-width: 600px;
  margin: 70px auto;
  position: relative;
  border-radius: 8px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
`

export const CustomFeedModalHeader = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  color: #5A5A5A;
  text-align: center;
`;

export const CustomFeedModalInput = styled.textarea`
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  width: 100%;
  color: #5A5A5A;
  margin-top: 20px;
`;

export const CustomFeedModalFooter = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const CustomFeedModalFooterBtnCancel = styled.a`
  width: 20%;
  text-decoration: none;
`;

export const CustomFeedModalFooterBtnSend = styled.span`
  width: 20%;
`;

export const CustomFeedModalOption = styled.form`
  display: flex;
  justify-content: space-between;
`;

export const SwipeableDrawerCustom = styled(SwipeableDrawer)`
  &&{
    &.MuiDrawer-root{

      .MuiDrawer-paper{
        overflow-y: initial;
      }
    }
  }
`;

export const ResponseContainer = styled.div`
	margin: 1vw 0vw;
`;

export const StyledMenu = styled(GenericMaterialMenu)`
  /* border: 1px solid red; */
  font-size: 0.8rem;
  margin-top: 10px;
`;

export const StyledTitle = styled(GenericTitleWithToolTip)`
  /* border: 1px solid red; */
  .textTitle {
    font-size: 0.85rem;
    color: ${COLORS.DARK_GREY};
  };
`;