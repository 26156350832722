import React, { useState } from 'react';
import { Tooltip } from '@material-ui/core';
import ArrowLeftIcon from '@material-ui/icons/ArrowBack';
import ArrowRightIcon from '@material-ui/icons/ArrowForward';
import _ from 'lodash';

import RoomInfoModal from '../RoomInfoModal';

import {
  MainContainer,
  RoomsContainer,
  StyledRoom,
  StyledRoomName,
  StyledImg,
  ToolContainer,
  StyledIconContainer
} from './style';
import Loader from '../../../../components/molecules/GenericLoader';


const RoomInfo = ({
  roomData,
  handlePageChange,
  pageNumber,
  maxPageNumber,
  roomDataIsLoading
}) => {
  const [selectedRoom, setSelectedRoom] = useState(null);

  const generateRoomInfo = (roomData) => {
    return _.map(roomData, (data, index) =>
      <Tooltip title={data.name} key={index} onClick={() => setSelectedRoom(data)}>
        <StyledRoom>
          <StyledImg src={data.image} alt={data.name} />
          <StyledRoomName>{data.name}</StyledRoomName>
        </StyledRoom>
      </Tooltip>
    )
  }

  return (
    <MainContainer>
      <RoomsContainer>
        {roomDataIsLoading ?
          <Loader />
          :
          generateRoomInfo(roomData)
        }
      </RoomsContainer>
      <ToolContainer>
        <StyledIconContainer onClick={() => handlePageChange(-1)} disabled={pageNumber === 1}>
          <ArrowLeftIcon style={{ fontSize: 14 }} />
        </StyledIconContainer>
        <StyledIconContainer onClick={() => handlePageChange(1)} disabled={pageNumber === maxPageNumber}>
          <ArrowRightIcon style={{ fontSize: 14 }} />
        </StyledIconContainer>
      </ToolContainer>
      {selectedRoom &&
        <RoomInfoModal
          selectedRoom={selectedRoom}
          onClose={() => setSelectedRoom(null)}
        />
      }
    </MainContainer>
  )
};

export default RoomInfo;