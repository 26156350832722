import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field, useFormikContext } from 'formik';
import * as Yup from "yup";
import _ from "lodash";

import { jsonSafeParse } from '../../../../../../helpers/jsonSafeParse';

//Import components
import GenericLoader from '../../../../../../components/molecules/GenericLoader';
import GenericAdminFrontHeader from '../../../../../../components/molecules/GenericAdminFrontHeader';
import GenericFileChooser from '../../../../../../components/molecules/GenericFileChooser';
import GenericTextArea from '../../../../../../components/molecules/GenericTextArea';
import GenericDrawerValidationButtons from '../../../../../../components/molecules/GenericDrawerValidationButtons';
import GenericErrorMessage from '../../../../../../components/molecules/GenericErrorMessage';
import GenericMultipleSelect from '../../../../../../components/molecules/GenericMultipleSelect';
import GenericWarningModal from '../../../../../../components/molecules/GenericWarningModal';


import {
  getConciergeProvidersData,
  getConciergeServicesData,
  getConciergeSitesData,
  getConciergeProvidersLoading
} from '../../../../../../store/concierge/selectors';

import { saveProviderRequests } from '../../../../../../store/concierge/actions';

//Import style
import {
  Container,
  DrawerCptCustom,
  Wrapper,
  DrawerWrapper,
  ContentTextField,
  FormContainer
} from "./style"

const Drawer = ({ visible, closeFunction, edit }) => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const [listRef, setListRef] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [provider, setProvider] = useState(null);
  const [servicesList, setServicesList] = useState(null);
  const [sitesList, setSitesList] = useState(null);
  const [isDirty, setIsDirty] = useState(false);
  const [openDelete, setopenDelete] = useState(false);

  const {
    providersData,
    servicesData,
    sitesData,
    providerIsLoading
  } = useSelector(
    state => ({
      providersData: getConciergeProvidersData(state),
      servicesData: getConciergeServicesData(state),
      sitesData: getConciergeSitesData(state),
      providerIsLoading: getConciergeProvidersLoading(state)
    }),
    shallowEqual
  );

  useEffect(() => {
    if (listRef !== null) {
      const diff = _.xor(listRef, providersData);
      if (diff.length !== 0) closeFunction();
    }
    setListRef(providersData);
  }, [providersData]);

  useEffect(() => {
    if (servicesData != null) {
      const newList = servicesData.map((service) => {
        return { label: service.name, value: service.id }
      })
      setServicesList([...newList])
    }
  }, [servicesData]);

  useEffect(() => {
    if (sitesData != null) {
      const newList = sitesData.map((service) => {
        return { label: service.name, value: service.id }
      })
      setSitesList(newList)
    }
  }, [sitesData]);

  useEffect(() => {
    if (edit && providersData != null) {
      const curentProvider = providersData.find((item) => String(item.id) === id);
      setProvider(curentProvider);
    }
  }, [providersData]);

  useEffect(() => {
    if (isLoading && servicesList && sitesList) {
      setIsLoading(false);
    }
  }, [servicesList, sitesList])

  const generateServiceList = (list) => {
    const data = jsonSafeParse(list);
    return _.compact(_.map(data, (item) => {
      const service = servicesData.find((value) => String(value.id) === item);
      if (service) {
        return { label: service.name, value: service.id };
      }
    }))
  }

  const generateSiteList = (list) => {
    const data = jsonSafeParse(list);
    return _.compact(_.map(data, (item) => {
      const site = sitesData.find((value) => String(value.id) === item);
      if (site) {
        return { label: site.name, value: site.id };
      }
    }))
  }

  const formInitValue = edit && provider != null ?
    {
      services: servicesData != null ? generateServiceList(provider.services) : [],
      sites: sitesData != null ? generateSiteList(provider.company_sites) : [],
      society: provider.company,
      name: provider.manager,
      email: provider.email,
      phoneNumber: provider.phone_number,
      address: provider?.address || "",
      website: provider?.website || "",
      chooseFile: provider?.file || [],
      note: provider.note

    }
    :
    {
      services: [],
      sites: [],
      society: "",
      name: "",
      email: "",
      phoneNumber: "",
      address: "",
      website: "",
      chooseFile: [],
      note: ""

    }

  const newContentActuSchema = Yup.object().shape({
    services: Yup.array()
      .of(
        Yup.object().shape({
          title: Yup.string()
        })
      )
      .required('Vous devez choisir au moins un service')
      .min(1, 'minimum 1 service'),
    /*     sites: Yup.array()
          .of(
            Yup.object().shape({
              title: Yup.string()
            })
          )
          .required('Vous devez choisir au moins un site')
          .min(1, 'minimum 1 site'), */
    society: Yup.string()
      .required('Une société est requise'),
  })

  const handleClose = () => {
    if (isDirty) setopenDelete(true)
    else closeFunction()
  }

  const CheckDirty = () => {
    const { dirty } = useFormikContext();

    useEffect(() => {
      setIsDirty(dirty)
    }, [dirty]);
    return null;
  };

  const submitForm = (formdata) => {
    edit ? dispatch(saveProviderRequests({ id: provider.id, formdata })) : dispatch(saveProviderRequests({ id: null, formdata }))
  }

  return (
    <Container>
      <DrawerCptCustom
        onOpen={visible}
        visible={visible}
        closeFunction={handleClose}
        className="contentManagementDrawer" >
        <DrawerWrapper>

          <Formik
            initialValues={formInitValue}
            enableReinitialize
            setFieldValue
            validationSchema={newContentActuSchema}
            onSubmit={(values) => submitForm(values)}
          >
            {(props) => {
              return (
                <Wrapper>
                  {edit ?
                    <GenericAdminFrontHeader
                      headerTitle={'Editer un prestataire'} />
                    :
                    <GenericAdminFrontHeader
                      headerTitle={'Ajouter un prestataire'} />
                  }
                  {(isLoading || providerIsLoading) &&
                    <GenericLoader />
                  }
                  {(!isLoading && !providerIsLoading) &&
                    <Form>
                      <CheckDirty />

                      <FormContainer>
                        <GenericMultipleSelect
                          list={servicesList}
                          fildName="services"
                          id="services"
                          name="services"
                          title="Services"
                          limitItem={5}
                          isRequired={true}
                          value={props.values.services}
                          onChange={(e, value) => { return props.setFieldValue('services', value) }}
                          toolTipText={'Ce champ vous permet de sélectionner les services de votre prestataire.'}
                        />
                        <GenericMultipleSelect
                          list={sitesList}
                          fildName="sites"
                          id="sites"
                          name="sites"
                          title="Site(s) d’intervention"
                          limitItem={5}
                          hasSelectAll={true}
                          value={props.values.sites}
                          onChange={(e, value) => {
                            return props.setFieldValue('sites', value)
                          }}
                          toolTipText={'Ce champ vous permet de sélectionner les sites de votre prestataire.'}
                        />
                        <Field
                          id="society"
                          name="society"
                          title="Société"
                          placeholder="Votre Société"
                          variant="outlined"
                          size="small"
                          isRequired={true}
                          as={ContentTextField} />
                        <Field
                          id="name"
                          name="name"
                          title="Prénom et nom"
                          placeholder="ex: Marie Four"
                          variant="outlined"
                          size="small"
                          as={ContentTextField} />
                        <Field
                          id="email"
                          name="email"
                          title="Mail"
                          type='email'
                          placeholder="ex: marie.four@estetik.fr"
                          variant="outlined"
                          size="small"
                          as={ContentTextField} />
                        <Field
                          id="phoneNumber"
                          name="phoneNumber"
                          title="Téléphone ou contact"
                          placeholder="ex: 06 00 00 00 00"
                          variant="outlined"
                          size="small"
                          as={ContentTextField} />
                        <Field
                          id="website"
                          name="website"
                          title="Site Web"
                          placeholder="www.cyconia.io"
                          variant="outlined"
                          size="small"
                          as={ContentTextField} />
                        <Field
                          id="address"
                          name="address"
                          title="Adresse postale"
                          placeholder="ex: 43 rue de Napoléon"
                          variant="outlined"
                          size="small"
                          as={ContentTextField} />
                        {/* FOR DELIVERY 3 */}
                        {/* This component have changed abit, make sure to readjust when reusing it */}
                        {/* <GenericFileChooser
                        title="Grille tarifaire"
                        emptyFileText="Pas de document"
                        buttonText="Télécharger un document"
                        id="chooseFile"
                        name="chooseFile"
                        onChange={(value) => props.setFieldValue('chooseFile', value)}
                        selectedFileName={props.values.chooseFile.name}
                      /> */}
                        {/* FOR DELIVERY 3 */}
                        <Field
                          id="note"
                          name="note"
                          title="Notes"
                          variant="outlined"
                          placeholder="Ajouter des notes"
                          minRows={4}
                          as={GenericTextArea} />
                        <GenericErrorMessage name={'note'} />
                        <GenericDrawerValidationButtons
                          leftButonText={'Annuler'}
                          RightButtonText={'Sauvegarder'}
                          onclickLefttButton={handleClose}
                          submitable={true}
                        />
                      </FormContainer>
                    </Form>
                  }
                </Wrapper>
              )
            }}
          </Formik>
        </DrawerWrapper>
      </DrawerCptCustom>
      <GenericWarningModal
        open={openDelete}
        setOpenDialog={() => setopenDelete(!openDelete)}
        title={"Attention"}
        text={"Êtes-vous sur de vouloir annuler votre saisie?"}
        leftButtonText={"Non"}
        rightButtonText={"Oui"}
        leftButtonFunction={() => setopenDelete(!openDelete)}
        rightButtonFunction={closeFunction}
      />
    </Container>
  )
}

export default Drawer
