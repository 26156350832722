import React, { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import moment from 'moment';

// Import Material UI components
import { Divider } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

// Import helpers
import { history } from '../../../../helpers/history';

// Styles Theme Hooks
import useTheme from "../../../../theme-style/useThemeCustomHook";

// Icons
import SeeIcon from '../../../../components/atoms/icons/see';
import NotSeeIcon from '../../../../components/atoms/icons/notSee';
import CompanyIcon from '../../../../components/atoms/icons/company';
import EditInfoIcon from '../../../../components/atoms/icons/editInfo';
import FeedbacksIcon from '../../../../components/atoms/icons/feedbacks';
import DislikeIcon from '../../../../components/atoms/icons/dislike';
import LikeIcon from '../../../../components/atoms/icons/like';
import CheckPlainIcon from '../../../../components/atoms/icons/checkPlain';
import { useDispatch } from 'react-redux'

//import component 
import CrossElement from '../CrossElement'

// Import Action
import { setInformationElementTitle, SET_TITLE_INFORMATION_ELEMENT } from '../../../../../../store/adminFront/actions'

// Import Style
import {
  StyledSubTableCell,
  SeeIconWrapper,
  NotSeeIconWrapper,
  CompanyIconWrapper,
  EditInfoIconWrapper,
  FeedbacksIconWrapper,
  DislikeIconWrapper,
  TableBodyRow,
  TableBodyRowDivider,
  EiStatusOnOff,
  SubTableCellWrapper,
  SeeText,
  NotSeeText,
  TooltipCustom,
  BoxCustom,
  CrooElemDiv,
  DivParaTitle
} from './style';

const TableContent = ({
  InfosListId,
  sub,
  i,
}) => {
  const dispatch = useDispatch();

 /*  const setInformationElementTitle = (title) => {
    dispatch({
      type: SET_TITLE_INFORMATION_ELEMENT,
      informetionTitle: title,
    });
  } */

  return (
    <Table key={i} aria-label="a dense table">
      <TableBody>
        {sub && sub.informationElements ?
          sub.informationElements.map((row, i) => (
            <TableBodyRow
              /*to={"/admin/training/" + InfosListId + "?opendrawerbyid=" + row.id}*/
              id={row.id}
              onClick={(e) => localStorage.setItem("currElem", row.id)}
              key={i}
              style={{ cursor: "pointer", textDecoration: "none" }}
            >
              {/* <StyledSubTableCell align="left">
                <Link className="tableCellLink" to={"/admin/training/" + InfosListId + "?opendrawerbyid=" + row.id}>
                  <p>{row.title}</p>
                  <span>
                    {row.description}
                  </span>
                </Link>
              </StyledSubTableCell> */}
              {row.isShared ?
                <StyledSubTableCell align="left">
                  <Link className="tableCellLink" to={"/admin/training/" + InfosListId + "?opendrawerbyid=" + row.id + `&title=${sub.title}`}/*  onClick={() => setInformationElementTitle(sub.title)} */>
                    <CrooElemDiv>
                      <DivParaTitle>
                        <p>{row.title}</p>
                      </DivParaTitle>
                      <TooltipCustom className="tooltipCustom" title="Cet élément est partagé par plusieurs thématiques : Cyconia peut s’y référer comme réponse pour plusieurs questions différentes. A noter, si vous modifiez la réponse d’un élément partagé, elle sera modifiée pour toutes les thématiques à laquelle elle se rattache." >
                        <div>
                          <CrossElement />
                        </div>
                      </TooltipCustom>
                    </CrooElemDiv>
                    <span>
                      {row.description}
                    </span>
                  </Link>
                </StyledSubTableCell> :
                <StyledSubTableCell align="left">
                  <Link className="tableCellLink" to={"/admin/training/" + InfosListId + "?opendrawerbyid=" + row.id + `&title=${sub.title}`}>
                    <p>{row.title}</p>
                    <span>
                      {row.description}
                    </span>
                  </Link>
                </StyledSubTableCell>}

              <StyledSubTableCell sub align="right">
                <Link className="tableCellLink" to={"/admin/training/" + InfosListId + "?opendrawerbyid=" + row.id}>
                  <TooltipCustom className="tooltipCustom" title="Nombre d'entreprises concernées par le sujet">
                    <BoxCustom>
                      <CompanyIconWrapper>
                        <CompanyIcon color="#BBBBBB" />
                      </CompanyIconWrapper>
                      <span>
                        {row.countCompanySiteResponses}
                      </span>
                    </BoxCustom>
                  </TooltipCustom>
                </Link>
              </StyledSubTableCell>

              <StyledSubTableCell sub align="right" className="feedBackWrapper">
                <Link className="tableCellLink" to={"/admin/training/" + InfosListId + "?opendrawerbyid=" + row.id}>
                  <TooltipCustom className="tooltipCustom" title="Nombre de remarques collaborateur à traiter">
                    <BoxCustom>
                      <FeedbacksIconWrapper>
                        <FeedbacksIcon color="#BBBBBB" />
                      </FeedbacksIconWrapper>
                      <span>
                        {row.feedbacks && row.feedbacks !== "" && row.feedbacks > 0
                          ? row.feedbacks + " remarque(s)" : " - "}
                      </span>
                    </BoxCustom>
                  </TooltipCustom>
                </Link>
              </StyledSubTableCell>

              <StyledSubTableCell sub align="right">
                <Link className="tableCellLink" to={"/admin/training/" + InfosListId + "?opendrawerbyid=" + row.id}>
                  <TooltipCustom className="tooltipCustom" title="Date de la dernière validation du sujet">
                    <BoxCustom>
                      <DislikeIconWrapper>
                        <CheckPlainIcon color="#BBBBBB" />
                      </DislikeIconWrapper>
                      <span>
                        {row.validatedAt && row.validatedAt !== "" ?
                          moment(row.validatedAt).format("DD/MM/YYYY") : " - "}
                      </span>
                    </BoxCustom>
                  </TooltipCustom>
                </Link>
              </StyledSubTableCell>

              <StyledSubTableCell sub align="right">
                <Link className="tableCellLink" to={"/admin/training/" + InfosListId + "?opendrawerbyid=" + row.id}>
                  <TooltipCustom className="tooltipCustom" title="Date de la dernière modification du sujet">
                    <BoxCustom>
                      <EditInfoIconWrapper>
                        <EditInfoIcon color="#BBBBBB" />
                      </EditInfoIconWrapper>
                      <span>
                        {row.responseLastUpdate && row.responseLastUpdate !== "" ?
                          moment(row.responseLastUpdate).format("DD/MM/YYYY")
                          : " - "}
                      </span>
                    </BoxCustom>
                  </TooltipCustom>
                </Link>
              </StyledSubTableCell>

              <StyledSubTableCell sub align="right">
                <Link className="tableCellLink" to={"/admin/training/" + InfosListId + "?opendrawerbyid=" + row.id}>
                  {row.active ?
                    <EiStatusOnOff>
                      <SeeIconWrapper>
                        <SeeIcon color="#BBBBBB" />
                      </SeeIconWrapper>
                      <SeeText>
                        Activé
                      </SeeText>
                    </EiStatusOnOff>
                    :
                    <EiStatusOnOff>
                      <NotSeeIconWrapper>
                        <NotSeeIcon color="#BBBBBB" />
                      </NotSeeIconWrapper>
                      <NotSeeText>
                        Désactivé
                      </NotSeeText>
                    </EiStatusOnOff>
                  }
                </Link>
              </StyledSubTableCell>
            </TableBodyRow>

          )) : null}
        <TableBodyRowDivider />
      </TableBody>
    </Table>
  )
}

export default withRouter(TableContent);
