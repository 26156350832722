// Main theme
import mainTheme from '../../../theme-style';
// Import configs
import colors from "./colors";
import components from "./components";

const theme = {
  ...mainTheme,
  colors: {
    ...mainTheme.colors,
    ...colors,
  },
  components: {
    ...mainTheme.components,
    ...components(colors),
  },
};

export default theme;
