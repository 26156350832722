import { call, put, select } from 'redux-saga/effects';
import endpoint from '../../../helpers/api';
import apiCall from '../../apiCall';
import * as authenticationSelectors from '../../authentication/selectors';
import { toaster } from '../../../helpers/toaster';

import {
  editGoodDealService,
  deleteGoodDealService
} from '../../../services/goodDealService';

import {
  getGoodDealsSuccess,
  getGoodDealsError,
  updateGoodDealSuccess,
  updateGoodDealError,
  deleteGoodDealSuccess,
  deleteGoodDealError,
  getGoodDealByIdSuccess,
  getGoodDealByIdError,
} from '../actions';

export function* getGoodDealsHandler(action) {
  const { limit = 1, pageNumber = 1 } = action.data;
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const newsList = yield call(apiCall, endpoint.getGoodDealsList(limit, pageNumber), userToken);
    yield put(getGoodDealsSuccess(newsList));
  } catch (error) {
    yield put(getGoodDealsError(error));
  }
}

export function* updateGoodDealHandler(action) {
  const { id, formData } = action.data;
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const endPointUrl = id !== 'new' ? endpoint.editGoodDeal(id) : endpoint.saveGoodDeal;
    const response = yield call(editGoodDealService, endPointUrl, userToken, formData);
    yield put(updateGoodDealSuccess(response.data));
    toaster('Vos changements ont été enregistrés', 'success');
  } catch (err) {
    yield put(updateGoodDealError(err));
    toaster(`Une erreur s'est produite. Veuillez réessayer`, 'error');
  }
}

export function* deleteGoodDealHandler(action) {
  const id = action.data;
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    yield call(deleteGoodDealService, endpoint.deleteGoodDeal(id), userToken);
    yield put(deleteGoodDealSuccess(id));
    toaster('Le contenu a bien été supprimé', 'success');
  } catch (err) {
    yield put(deleteGoodDealError(err));
    toaster(`Une erreur s'est produite. Veuillez réessayer`, 'error');
  }
}

export function* getGoodDealByIdHandler(action) {
  const { id } = action.data;
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const newContent = yield call(apiCall, endpoint.getGoodDealById(id), userToken);
    yield put(getGoodDealByIdSuccess(newContent));
  } catch (error) {
    yield put(getGoodDealByIdError(error));
  }
}