import React, { useEffect } from 'react';
import {
  shallowEqual,
  useDispatch,
  useSelector,
} from "react-redux";

import { BLOCK_KEYS } from '../../../../constants/sideBarConstants';

// Layout
import SidebarFrontBaseLayout from "../../layouts/sidebarFrontBaseLayout";

// Components
import UserInfoAndWeatherModule from '../../components/organisms/UserInfoAndWeatherModule';
import ParoleCyconia from '../../components/organisms/ParoleCyconia';
import ConciergeModule from '../../components/organisms/ConciergeModule';
import RequestModule from '../../components/organisms/RequestModule';
import BorneModule from '../../components/organisms/BorneModule';
import ConciergeServiceModule from '../../components/organisms/ConciergeServiceModule';
import DisconnectModule from '../../components/organisms/DisconnectModule';

// Store
import {
  getExpressingOnGoingOrders,
  getExpressingAmount,
} from '../../../../store/sidebar/actions';

import {
  getCompanyModules,
  getCompanyData,
  getConciergeContext,
} from '../../../../store/company/selectors';

// Styles
import {
  Container,
} from "./style";

const Conciergerie = () => {
  const dispatch = useDispatch();

  const {
    companyModules,
    companyData,
    conciergeContext
  } = useSelector(
    state => ({
      companyModules: getCompanyModules(state),
      companyData: getCompanyData(state),
      conciergeContext: getConciergeContext(state)
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getExpressingOnGoingOrders());
    dispatch(getExpressingAmount());
  }, []);

  return (
    <SidebarFrontBaseLayout
      id="sidebarLayoutConciergerie"
      className="sidebarLayoutConciergerie page-container sidebarPage"
    >
      <Container>
        {conciergeContext?.[BLOCK_KEYS.WEATHER] &&
          <UserInfoAndWeatherModule />
        }

        {conciergeContext?.[BLOCK_KEYS.MY_CONCIERGE] && companyData.serviceVersion === 2 &&
          <ConciergeModule />
        }

        {conciergeContext?.[BLOCK_KEYS.MY_ORDER] &&
          <RequestModule />
        }

        {conciergeContext?.[BLOCK_KEYS.BORNE] && (!!companyModules?.expressing_module) &&
          <BorneModule />
        }

        {conciergeContext?.[BLOCK_KEYS.CONCIERGE_SERVICES] &&
          <ConciergeServiceModule
          />}

        {conciergeContext?.[BLOCK_KEYS.NEWS] &&
          <ParoleCyconia />
        }

        {conciergeContext?.[BLOCK_KEYS.ACCOUNT_DISCONNECT] &&
          <DisconnectModule />
        }
      </Container>
    </SidebarFrontBaseLayout>
  );

}

export default Conciergerie;
