export const LOGIN = {
  REQUEST: 'AUTHENTICATION/LOGIN/REQUEST',
  SUCCESS: 'AUTHENTICATION/LOGIN/SUCCESS',
  FAILURE: 'AUTHENTICATION/LOGIN/FAILURE',
  RESET: 'AUTHENTICATION/LOGIN/RESET',
};

export const SEND_EMAIL_RESET_PASSWORD = {
  REQUEST: 'AUTHENTICATION/SEND_EMAIL_RESET_PASSWORD/REQUEST',
  SUCCESS: 'AUTHENTICATION/SEND_EMAIL_RESET_PASSWORD/SUCCESS',
  FAILURE: 'AUTHENTICATION/SEND_EMAIL_RESET_PASSWORD/FAILURE',
  RESET: 'AUTHENTICATION/SEND_EMAIL_RESET_PASSWORD/RESET',
};

export const RESET_PASSWORD = {
  REQUEST: 'AUTHENTICATION/RESET_PASSWORD/REQUEST',
  SUCCESS: 'AUTHENTICATION/RESET_PASSWORD/SUCCESS',
  FAILURE: 'AUTHENTICATION/RESET_PASSWORD/FAILURE',
  RESET: 'AUTHENTICATION/RESET_PASSWORD/RESET',
};

export const REGISTER = {
  REQUEST: 'AUTHENTICATION/REGISTER/REQUEST',
  SUCCESS: 'AUTHENTICATION/REGISTER/SUCCESS',
  FAILURE: 'AUTHENTICATION/REGISTER/FAILURE',
  RESET: 'AUTHENTICATION/REGISTER/RESET',
};

export const CREATE_COLLABORATOR = {
  REQUEST: 'AUTHENTICATION/CREATE_COLLABORATOR/REQUEST',
  SUCCESS: 'AUTHENTICATION/CREATE_COLLABORATOR/SUCCESS',
  FAILURE: 'AUTHENTICATION/CREATE_COLLABORATOR/FAILURE',
  RESET: 'AUTHENTICATION/CREATE_COLLABORATOR/RESET',
};

export const UPDATE_PASSWORD = {
  REQUEST: 'AUTHENTICATION/UPDATE_PASSWORD/REQUEST',
  SUCCESS: 'AUTHENTICATION/UPDATE_PASSWORD/SUCCESS',
  FAILURE: 'AUTHENTICATION/UPDATE_PASSWORD/FAILURE',
  RESET: 'AUTHENTICATION/UPDATE_PASSWORD/RESET',
};

export const LOGOUT = {
  REQUEST: "AUTHENTICATION/LOGOUT/REQUEST",
  SUCCESS: "AUTHENTICATION/LOGOUT/SUCCESS"
};

/**
 * LOGIN
 */
export const login = (username, password) => ({
  type: LOGIN.REQUEST,
  username,
  password,
});

export const loginSuccess = (userToken) => ({
  type: LOGIN.SUCCESS,
  userToken,
});

export const loginError = (error) => ({
  type: LOGIN.FAILURE,
  error,
});

export const loginReset = () => ({
  type: LOGIN.RESET,
});

/**
 * SEND EMAIL RESET PASSWORD
 */
export const sendEmailResetPassword = (username, resetPasswordUrl) => ({
  type: SEND_EMAIL_RESET_PASSWORD.REQUEST,
  username,
  resetPasswordUrl,
});

export const sendEmailResetPasswordSuccess = () => ({
  type: SEND_EMAIL_RESET_PASSWORD.SUCCESS,
});

export const sendEmailResetPasswordError = (error) => ({
  type: SEND_EMAIL_RESET_PASSWORD.FAILURE,
  error,
});

export const resetSendEmailResetPassword = () => ({
  type: SEND_EMAIL_RESET_PASSWORD.RESET,
});

/**
 * RESET PASSWORD
 */
export const resetPassword = () => ({
  type: RESET_PASSWORD.REQUEST,
});

export const resetPasswordSuccess = () => ({
  type: RESET_PASSWORD.SUCCESS,
});

export const resetPasswordError = (error) => ({
  type: RESET_PASSWORD.FAILURE,
  error,
});

export const resetPasswordReset = () => ({
  type: RESET_PASSWORD.RESET,
});

/**
 * REGISTER
 */
export const register = (email, firstname, lastname, acceptPtdcp) => ({
  type: REGISTER.REQUEST,
  email,
  firstname,
  lastname,
  acceptPtdcp,
});

export const registerSuccess = (userToken) => ({
  type: REGISTER.SUCCESS,
  userToken,
});

export const registerError = (error) => ({
  type: REGISTER.FAILURE,
  error,
});

export const registerReset = () => ({
  type: REGISTER.RESET,
});

/**
 * CREATE COLLABORATOR
 */
export const createCollaborator = (data) => ({
  type: CREATE_COLLABORATOR.REQUEST,
  data
});

export const createCollaboratorSuccess = () => ({
  type: CREATE_COLLABORATOR.SUCCESS,
});

export const createCollaboratorError = (error) => ({
  type: CREATE_COLLABORATOR.FAILURE,
  error,
});

export const createCollaboratorReset = () => ({
  type: CREATE_COLLABORATOR.RESET,
});

/**
 * UPDATE PASSWORD
 */
export const updatePassword = (password, confirmPassword, token, isRegister = false) => ({
  type: UPDATE_PASSWORD.REQUEST,
  password,
  confirmPassword,
  token,
  isRegister,
});

export const updatePasswordSuccess = (userToken) => ({
  type: UPDATE_PASSWORD.SUCCESS,
  userToken,
});

export const updatePasswordError = (error) => ({
  type: UPDATE_PASSWORD.FAILURE,
  error,
});

export const resetUpdatePassword = () => ({
  type: UPDATE_PASSWORD.RESET,
});

/**
 * LOGOUT
 */
export const logout = () => ({
  type: LOGOUT.REQUEST,
});

export const logoutSuccess = () => ({
  type: LOGOUT.SUCCESS,
});
