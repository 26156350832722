import styled from "styled-components";

import { COLORS } from '../../../../../../../../../../constants/design'


export const MainContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  /* max-height: 595px; // GOLDEN VALUE dont change! */
  /* overflow-y: auto; */
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
`;

export const StyledTabItem = styled.div`
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
`;

export const StyledDateBlock = styled.div`
  border: 1px solid ${COLORS.SLIGHT_GREY_2};
  background-color: ${props => props.type ? props.type === 'event' ? COLORS.PRIMARY_BLUE : COLORS.GREEN : COLORS.SLIGHT_GREY_2};
  padding: 5px 10px;
  border-radius: 6px;
  margin-right: 5px;
  color: ${props => props.type ? COLORS.WHITE : COLORS.DARK_GREY};
  font-size: 0.7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledDateOnly = styled.div`
  font-size: 0.9rem;
  font-weight: 600;
`;

export const StyledDateInfo = styled.div`
  border: 1px solid ${COLORS.SLIGHT_GREY_2};
  background-color: ${COLORS.SLIGHT_GREY_2};
  padding: 5px 10px;
  border-radius: 6px;
  width: 100%;
  cursor: pointer;
  color: ${COLORS.DARK_GREY};
  :hover {
    border: 1px solid ${COLORS.LIGHT_GREY_2}
  }
  display: flex;
  flex-direction: column;
  
`;

export const StyledDateText = styled.div`
  font-size: 0.75rem;
  :first-letter {
    text-transform: uppercase;
  }
`;


export const StyledDateTitle = styled.div`
  width: 300px;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
