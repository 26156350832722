import React from "react";
import {
  // BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch
} from "react-router-dom";

// Layout
// import BaseLayout from '../layouts/base';

// Containers
import Dashboard from '../containers/Dashboard';
import TrainingCyco from '../containers/TrainingCyco';
import Feedback from '../containers/Feeds';
import UsersManagementContainer from '../containers/UsersManagementContainer';
import SmallTalk from '../containers/SmallTalk';
import ContentManagementContainer from '../containers/ContentManagementContainer';
import KbAdminFront from '../containers/KbAdminFront';
import Settings from "../containers/Settings";

const RoutesApp = () => {
  const { path } = useRouteMatch();

  return (
    <React.Fragment>
      <Switch>
        <Route path={`${path}/dashboard`} component={Dashboard} />
        {/* <Route path={`${path}/training`} component={TrainingCyco} /> */}
        <Route path={`${path}/training`} component={KbAdminFront} />
        <Route path={`${path}/feeds`} component={Feedback} />
        <Route path={`${path}/users-management`} component={UsersManagementContainer} />
        <Route path={`${path}/content-management`} component={ContentManagementContainer} />
        <Route path={`${path}/specific-smalltalk`} component={SmallTalk} />
        <Route path={`${path}/settings`} component={Settings} />
        <Redirect to={`${path}/dashboard`} />
      </Switch>
    </React.Fragment>
  );
};

export default RoutesApp;
