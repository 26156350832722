import React, { useEffect, useState } from 'react';
import Routes from './routes';
import { useHistory } from "react-router";
import { useRouteMatch } from "react-router-dom";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import _ from 'lodash';

import { jsonSafeParse } from '../../../../helpers/jsonSafeParse';

import GenericLayout from '../../layouts/genericLayout';
import GenericLoader from '../../../../components/molecules/GenericLoader';
import GenericAdminFrontHeader from '../../../../components/molecules/GenericAdminFrontHeader';
import GenericWarningModal from '../../../../components/molecules/GenericWarningModal';
import GenericTable from '../../../../components/molecules/GenericTable';

import ProviderFilter from './components/ProviderFilter';

import {
  deleteProviderRequests
} from '../../../../store/concierge/actions';

import {
  getConciergeProvidersData,
  getConciergeServicesData,
  getConciergeSitesData
} from '../../../../store/concierge/selectors';

import {
  HeaderButton,
  TableButtonDiv,
  TableIcon,
  ProviderListContainer,
  StyledTableContainer,
  EmptyProviderSites
} from './style';

const TableAction = ({ openEditDrawer, openWarning }) => {
  return (
    <TableButtonDiv >
      <TableIcon onClick={openEditDrawer}>
        <EditIcon />
      </TableIcon>
      <TableIcon onClick={openWarning} primary="true">
        <DeleteIcon />
      </TableIcon>
    </TableButtonDiv>
  )
}

const ProviderListItem = ({ list, data }) => {
  if (list != null && Object.keys(list).length !== 0) {
    const newList = _.map((list), (el) => {
      const value = data.find(item => String(item.id) === String(el));
      return value?.name || '-';
    }).join(', ')

    return (
      <Tooltip title={newList} >
        <ProviderListContainer>
          {newList}
        </ProviderListContainer>
      </Tooltip>
    )
  } else {
    return (
      <EmptyProviderSites>-</EmptyProviderSites>
    )
  }
}

const headerList = [
  { id: "services", label: "Services" },
  { id: "sites", label: "Sites" },
  { id: "company", label: "Prestataires" },
  { id: "manager", label: "Responsable" },
  { id: "phone_number", label: "Contact" },
  { id: "action", label: "Action", disableSort: true },
];

const DirectoryOfServices = () => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const [isLoading, setIsLoading] = useState(true);
  const [itemList, setItemList] = useState([]);
  const [openDelete, setopenDelete] = useState(false);
  const [providerId, setProviderId] = useState(null);
  const [filterConditions, setFilterConditions] = useState({
    services: [],
    sites: [],
    provider: "",
    manager: "",
  });

  const dispatch = useDispatch();

  const {
    providersData,
    servicesData,
    sitesData
  } = useSelector(
    state => ({
      providersData: getConciergeProvidersData(state),
      servicesData: getConciergeServicesData(state),
      sitesData: getConciergeSitesData(state)
    }),
    shallowEqual
  );

  const checkServiceFilter = (form, item) => {
    const itemParsedService = jsonSafeParse(item.services)
    let stat = false;
    _.map(itemParsedService, (service) => {
      if (form?.services.find(e => String(e.value) === String(service))) {
        stat = true
      }
    })
    return stat;
  }

  const checkSiteFilter = (form, item) => {
    const itemParsedSites = jsonSafeParse(item.company_sites)
    let stat = false;
    _.map(itemParsedSites, (site) => {
      if (form?.sites.find(e => String(e.value) === String(site))) {
        stat = true
      }
    })
    return stat;
  }

  const checkManagerFilter = (form, item) => {
    const search = _.toLower(_.deburr(form.manager));

    return _.toLower(_.deburr(item.manager)).includes(search);
  }

  const checkCompanyFilter = (form, item) => {
    const search = _.toLower(_.deburr(form.company));

    return _.toLower(_.deburr(item.company)).includes(search);
  }

  const filterCondition = (form, item) => {

    const conditionCheckArray = [];
    if (form.services.length !== 0) {
      conditionCheckArray.push(checkServiceFilter(form, item))
    }
    if (form.sites.length !== 0) {
      conditionCheckArray.push(checkSiteFilter(form, item))
    }
    if (form.manager) {
      conditionCheckArray.push(checkManagerFilter(form, item))
    }
    if (form.company) {
      conditionCheckArray.push(checkCompanyFilter(form, item))
    }
    if (conditionCheckArray.length > 0) {
      return !conditionCheckArray.includes(false);
    }


    return true;
  }

  useEffect(() => {
    if (providersData && servicesData && sitesData) {
      const newList = _.compact(_.map(providersData, (data) => {
        if (filterCondition(filterConditions, data) === false) return;
        const newItem = _.pickBy(data, (value, key) => (key === "company" || key === "manager" || key === "phone_number"));
        const temps = { company: '', manager: '', phone_number: '' };
        const newOrder = Object.assign(temps, newItem);
        return ({
          services: <ProviderListItem list={jsonSafeParse(data.services)} data={servicesData} />,
          sites: <ProviderListItem list={jsonSafeParse(data.company_sites)} data={sitesData} />,
          ...newOrder,
          action: <TableAction openEditDrawer={() => openEditDrawer(data.id)} openWarning={() => setCurentId(data.id)} />
        })
      }))
      setItemList(newList);
      setIsLoading(false);
    }
  }, [providersData, servicesData, filterConditions, sitesData]) // To pick the neccessary data for the display and add the action component at the end of the list

  const openNewDrawer = () => {
    history.push(`${path}/new`);
  }

  const openEditDrawer = (id) => {
    history.push(`${path}/edit/${id}`);
  }

  const setCurentId = (id) => {
    setProviderId(id);
    setopenDelete(!openDelete);
  }

  const deleteProvider = () => {
    if (providerId != null) dispatch(deleteProviderRequests(providerId))
    setopenDelete(false);
  }

  return (
    <GenericLayout
      id="siteManagementLayout"
      className="siteManagementLayout"
    >
      <GenericAdminFrontHeader
        headerTitle={'Annuaire des prestataires'}
        headerDesc={'Gérez vos prestataires.'}
        RightSideComponent={
          <HeaderButton onClick={() => openNewDrawer()} >
            Ajouter
          </HeaderButton>
        }
      />
      {isLoading &&
        <GenericLoader />
      }
      {
        !isLoading &&
        <>
          <ProviderFilter servicesData={servicesData} sitesData={sitesData} setFilterConditions={setFilterConditions} />
          <StyledTableContainer>
            <GenericTable
              headerList={headerList}
              itemList={itemList}
              hover={false}
            />
          </StyledTableContainer>
        </>
      }

      <Routes />
      <GenericWarningModal
        open={openDelete}
        setOpenDialog={() => setopenDelete(!openDelete)}
        title={"Supprimer un prestataire"}
        text={"Êtes-vous certain de vouloir supprimer ce prestataire ?"}
        leftButtonText={"Annuler"}
        rightButtonText={"Supprimer"}
        leftButtonFunction={() => setopenDelete(!openDelete)}
        rightButtonFunction={() => deleteProvider()}
      />
    </GenericLayout>
  )
}

export default DirectoryOfServices;