import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  min-height: 210px;
  height: 100%;
  max-height: 32%;
  padding: 20px 20px 18px 20px;
  border-radius: 6px;
  background-image: linear-gradient(-45deg, #4A90E2 0%, #3C7ECA 100%);
`;

export const Title = styled.h3`
  margin: 0 0 4px 0;
  color: #ffffff;
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical; */
`;

export const Text = styled.p`
  margin: 0 0 auto 0;
  color: #ffffff;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
`;

export const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
`;

export const DigitalNomadIllustrationWrapper = styled.span`
  max-width: 100px;
`;

export const GlobalData = styled.p`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: auto;
  margin: 0 0 10px 20px;
  text-align: left;
  strong{
    font-size: 30px;
    color: #FFFFFF;
    letter-spacing: 0;
    line-height: 32px;
    margin: 0 0 8px 0;
  }
  span{
    font-size: 10px;
    color: #FFFFFF;
    letter-spacing: 0;
    line-height: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;
