import React, { useState, useEffect } from 'react';
import { Views, dateFnsLocalizer } from 'react-big-calendar'
import {
  format,
  parse,
  startOfWeek,
  getDay,
  isPast,
  differenceInMinutes,
  areIntervalsOverlapping,
} from 'date-fns';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import fr from 'date-fns/locale/fr';
import _ from 'lodash';

import BookingEventModal from '../BookingEventModal';
import CreateBookingModal from '../CreateBookingModal';

import Loader from '../../../../components/molecules/GenericLoader';
import RoomBookingCancellationModal from '../../../../components/organisms/RoombookingCancellationModal';

import { toaster } from '../../../../helpers/toaster';

import {
  saveBooking,
  cancelBooking,
  cancelBookingReset,
} from '../../../../store/roomBooking/actions';

import {
  getSaveBookingIsLoading,
  getCancelBookingIsLoading,
  getCancelBookingIsSuccess,
} from '../../../../store/roomBooking/selectors';

import {
  isUserConcierge as isUserConciergeSelector,
} from '../../../../store/authentication/selectors';

import {
  getUser,
} from '../../../../store/collaborator/selectors';

import {
  MainContainer,
  StyledCalendar,
} from "./style";

const allViews = [Views.DAY];

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales: { 'fr': fr },
});

const today = new Date();

const minTime = new Date();
minTime.setHours(8, 0, 0);
const maxTime = new Date();
maxTime.setHours(20, 0, 0);

const BookingCalendar = ({
  selectedDay,
  roomData,
  roomDataIsLoading,
  bookingData,
  bookingDataIsLoading,
  corporationData,
  corporationDataIsLoading,
}) => {
  const minDuration = 15;
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [resourceMap, setResourceMap] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [bookingEvent, setBookingEvent] = useState([]);   // For display
  const [bookedSlot, setBookedSlot] = useState([]);       // For availability check
  const [openDelete, setOpenDelete] = useState(false);
  const [listRef, setListRef] = useState(null);

  const {
    userData,
    isUserConcierge,
    saveBookingIsLoading,
    cancelBookingIsLoading,
    cancelBookingIsSuccess,
  } = useSelector(
    state => ({
      userData: getUser(state),
      isUserConcierge: isUserConciergeSelector(state),
      saveBookingIsLoading: getSaveBookingIsLoading(state),
      cancelBookingIsLoading: getCancelBookingIsLoading(state),
      cancelBookingIsSuccess: getCancelBookingIsSuccess(state),
    }),
    shallowEqual
  );

  useEffect(() => {
    if (listRef && bookingData) {
      if (!_.isEqual(listRef, bookingData)) setSelectedSlot(null);
    }
    setListRef(bookingData);
  }, [bookingData]);

  useEffect(() => {
    if (cancelBookingIsSuccess) {
      setOpenDelete(false);
    }
  }, [cancelBookingIsSuccess]);

  useEffect(() => {
    if (roomData) {
      const newMap = _.compact(_.map(roomData, (room) => {
        if (room.type === 'room') {
          return ({
            resourceId: String(room.id),
            resourceTitle: room.name
          })
        }
      }));
      setResourceMap(newMap);
    }
  }, [roomData]);

  useEffect(() => {
    if (bookingData) {
      const newBookingEvents = [];
      const newBookedSlot = [];

      for (const booking of bookingData) {
        if (booking.room.type === 'room') {
          newBookingEvents.push({
            title: booking.public_title,
            resourceId: String(booking.room.id),
            start: new Date(booking.start),
            end: new Date(booking.end),
            resource: {
              ...booking,
            }
          });
          newBookedSlot.push({
            start: new Date(booking.start),
            end: new Date(booking.end),
            room: booking.room
          });
        }
        else {
          const selectedRoom = _.find(roomData, (data) => String(data.id) === String(booking.room.id));
          if (selectedRoom) {
            // console.log('group booking', booking);
            // console.log('group selectedRoom', selectedRoom);
            for (const room of selectedRoom.room) {
              newBookingEvents.push({
                title: `${booking.room?.name} | ${booking.public_title}`,
                resourceId: String(room.id),
                start: new Date(booking.start),
                end: new Date(booking.end),
                resource: {
                  ...booking,
                }
              });
              newBookedSlot.push({
                start: new Date(booking.start),
                end: new Date(booking.end),
                room: room
              });
            }
          }
        }
      }
      // console.log('newBookingEvents', newBookingEvents);
      // console.log('newBookedSlot', newBookedSlot);
      setBookingEvent(newBookingEvents);
      setBookedSlot(newBookedSlot);
    }
  }, [roomData, bookingData]);

  const handleEventSelect = (event, target) => {
    // console.log('handleCreateEvent event, target', event, target, target.currentTarget);

    setSelectedEvent(event);
    setAnchorEl(target.currentTarget)
  }

  const handleSelectSlot = (slot) => {
    if (slot.resourceId) {
      const selectedRoom = _.find(roomData, (data) => String(data.id) === String(slot.resourceId));
      const newSlotData = {
        selectedRoom,
        start: slot.start,
        end: slot.end
      };
      setSelectedSlot(newSlotData);
    }
  };

  const checkSlotAvailable = (values) => {
    const { formData } = values;
    const startDateTime = new Date(`${formData.startDate.replace(/-/g, "/")} ${formData.startTime}`);
    const endDateTime = new Date(`${formData.endDate.replace(/-/g, "/")} ${formData.endTime}`);
    const selectedRoom = _.find(roomData, (room) => room.id === formData.selectedRoomId);
    const roomToCheck = selectedRoom.type === 'room' ? [selectedRoom] : [...selectedRoom.room];
    const duration = differenceInMinutes(new Date(endDateTime), new Date(startDateTime));
    // If room type is 'room', only need to check its own avaibility
    // if room type is 'group', need to check its avaibility on all rooms inside the 'room' array

    if (isPast(startDateTime) || isPast(endDateTime)) {
      toaster(`Impossible de réserver une salle sur des horaires antérieurs à l'heure actuelle. Merci de modifier les horaires de réservation.`, 'error');
      return false;
    }
    if (startDateTime > endDateTime) {
      toaster(`Attention, les horaires indiqués ne sont pas valides. Merci de les vérifier et les modifier en conséquence.`, 'error');
      return false;
    }
    if (duration < minDuration) {
      toaster(`Impossible de finaliser votre réservation, la durée minimum est de ${minDuration} minutes. Merci de modifier les horaires en conséquence.`, 'error');
      return false;
    }
    // console.log('check roomToCheck', roomToCheck);
    // console.log('check bookedSlot', bookedSlot);
    for (const room of roomToCheck) {
      // console.log('checking room', room.name);
      for (const booking of bookedSlot) {
        if (booking.room.id !== room.id) {
          continue;
        }
        // console.log('check booking', booking);
        const isOverlapping = areIntervalsOverlapping({ start: startDateTime, end: endDateTime }, { start: booking.start, end: booking.end });

        if (isOverlapping) {
          toaster(`Le créneau sélectionné est déjà réservé. Merci de sélectionner un autre créneau.`, 'error');
          return false;
        }
      }
    }
    return true;
  }

  const handleSubmit = (values) => {
    // console.log('submit values', values);
    const isAvailable = checkSlotAvailable(values);
    // console.log('isAvailable', isAvailable);
    if (isAvailable) {
      dispatch(saveBooking(values));
    }
  };

  const handleOpenDelete = () => {
    dispatch(cancelBookingReset());
    setOpenDelete(true);
    setAnchorEl(null);
  }

  const handleDelete = (values) => {
    dispatch(cancelBooking({ id: values.id, formData: values }));
  }

  return (
    <MainContainer>
      {roomDataIsLoading || bookingDataIsLoading || corporationDataIsLoading ?
        <Loader />
        :
        <StyledCalendar
          selectable={!anchorEl} // If popper is open, disabled calendar select event
          toolbar={false}
          events={bookingEvent}
          views={allViews}
          defaultView={Views.DAY}
          step={15}
          timeslots={4}
          showMultiDayTimes
          culture={'fr'}
          scrollToTime={today}
          date={selectedDay}
          min={minTime}
          max={maxTime}
          resourceIdAccessor="resourceId"
          resources={resourceMap}
          resourceTitleAccessor="resourceTitle"
          localizer={localizer}
          onSelectEvent={handleEventSelect}
          onSelectSlot={handleSelectSlot}
          components={{
            // eventWrapper: CustomEventWrapper,
          }}
        />
      }
      {!!openDelete &&
        <RoomBookingCancellationModal
          bookingData={selectedEvent.resource}
          roomData={roomData}
          isUserConcierge={isUserConcierge}
          onClose={() => setOpenDelete(false)}
          onConfirm={(data) => handleDelete(data)}
          cancelBookingIsLoading={cancelBookingIsLoading}
        />
      }
      {!!anchorEl &&
        <BookingEventModal
          onClose={() => setAnchorEl(null)}
          data={selectedEvent}
          anchorEl={anchorEl}
          handleOpenDelete={handleOpenDelete}
          userData={userData}
          corporationData={corporationData}
          isUserConcierge={isUserConcierge}
        />
      }
      {!!selectedSlot &&
        <CreateBookingModal
          onClose={() => setSelectedSlot(null)}
          onSubmit={(values) => handleSubmit(values)}
          selectedSlot={selectedSlot}
          roomData={roomData}
          saveBookingIsLoading={saveBookingIsLoading}
        />
      }
    </MainContainer>
  )
};

export default BookingCalendar;