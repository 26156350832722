import React from 'react';
import {
  // Switch,
  // Route,
  // Redirect,
  withRouter,
  // useRouteMatch
} from "react-router-dom";

// Containers
// import Dashboard from '../../containers/Dashboard';
// import TrainingCyco from '../../containers/TrainingCyco';
// import Feeds from '../../containers/Feeds';
// import UsersManagement from '../../containers/UsersManagement';
// import ContentManagement from '../../containers/ContentManagement';

// Styles
import {
  // Container,
  Layout
} from "./style";

const BaseLayout = ({
  children,
  id,
  className,
  ...rest
}) => {
  // const { path } = useRouteMatch();

  return (
    <Layout {...rest}>
      <div id={id} className={className}>
        {children}
      </div>
    </Layout>
  )
}

export default withRouter(BaseLayout);
