import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';
import _ from 'lodash';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import ListAltIcon from '@material-ui/icons/ListAlt';
import AddBoxIcon from '@material-ui/icons/AddBox';
import MenuIcon from '@material-ui/icons/Menu';
import FlagIcon from '@mui/icons-material/Flag';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import { toaster } from '../../../../../../helpers/toaster';
import { jsonSafeParse } from '../../../../../../helpers/jsonSafeParse';

import GenericTitleWithIcon from '../../../../../../components/molecules/GenericTitleWithIcon';
import GenericWarningModal from '../../../../../../components/molecules/GenericWarningModal';
import TicketRequestMessage from '../../../../../../components/organisms/TicketRequestMessage';
import TicketMessageModal from '../../../../../../components/organisms/TicketMessageModal';
import {
  ticketStatusArray,
  finalTicketStatus,
  ticketStatusConstant,
  ticketStatusButtonConstant
} from '../../../../../../constants/ticketConstant';
import TicketRequestInfo from './components/TicketRequestInfo';
import { getTicketName } from '../../../../../../services/getTicketNameService'

import { saveTicketRequest, saveTicketMessage } from '../../../../../../store/concierge/actions';

import GenericAnimatedTextBubble from '../../../../../../components/molecules/GenericAnimatedTextBubble';

import {
  getConciergeSitesData,
  getConciergeServicesData,
  getConciergeTicketRequestsData
} from '../../../../../../store/concierge/selectors';

import { getUser } from '../../../../../../store/collaborator/selectors';
//Import style
import {
  DrawerCptCustom,
  DrawerWrapper,
  StyledHeader,
  StyledHeaderDescription,
  HeaderButtonContainer,
  ContentContainer,
  StyledButton,
  StyledMenu,
  StyledSuggestionIconContainer,
  StyledSuggestionIndicator
} from "./style"

const menuList = [
  {
    value: "revertTicketStatus",
    label: "Revenir au statut précédent"
  },
  {
    value: "messageReview",
    label: "Aperçu bénéficiaire"
  }
];

const TicketRequestDrawer = ({ visible, closeFunction, archive }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isSuggestionActive, setIsSuggestionActive] = useState(false);
  const [ticketRequest, setTicketRequest] = useState(null);
  const [warningMessage, setWarningMessage] = useState('');
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const {
    sitesData,
    servicesData,
    ticketRequestData,
    userData,
  } = useSelector(
    state => ({
      sitesData: getConciergeSitesData(state),
      servicesData: getConciergeServicesData(state),
      ticketRequestData: getConciergeTicketRequestsData(state),
      userData: getUser(state)
    }),
    shallowEqual
  );

  useEffect(() => {
    if (ticketRequestData && servicesData && sitesData) {
      const currentTicketRequest = ticketRequestData.items.find(item => item.id == id);
      if (currentTicketRequest) {
        const currentSite = sitesData.find(item => item.id == currentTicketRequest?.siteId);
        const currentService = servicesData.find(item => item.id == currentTicketRequest?.serviceId);
        currentTicketRequest.site = currentSite;
        currentTicketRequest.service = currentService;
        setTicketRequest(currentTicketRequest);
        setIsSuggestionActive(currentTicketRequest.status === ticketStatusArray[0]);
      }
    }
  }, [id, sitesData, servicesData, ticketRequestData])

  const generateHeaderDesc = () => {
    const dateString = format(new Date(ticketRequest.created_at), "dd MMMM yyyy 'à' HH'h'mm", { locale: fr });
    const author = ticketRequest.author ? `${ticketRequest.author.firstname} ${ticketRequest.author.lastname}` : null;
    return (
      <StyledHeaderDescription>
        <GenericTitleWithIcon title={ticketRequest.company_site?.name} Icon={<LocationCityIcon style={{ fontSize: '0.8rem' }} />} />
        <GenericTitleWithIcon title={ticketStatusConstant[ticketRequest.status]} Icon={<ListAltIcon style={{ fontSize: '0.8rem' }} />} />
        <GenericTitleWithIcon title={`Créé le ${dateString}`} Icon={<AddBoxIcon style={{ fontSize: '0.8rem' }} />} />
        {author &&
          <GenericTitleWithIcon title={`Créé par ${author}`} Icon={<FlagIcon style={{ fontSize: '1rem' }} />} />
        }
      </StyledHeaderDescription>
    )
  }

  const generateTicketStatusChangeButton = () => {
    return (
      <StyledSuggestionIconContainer>
        <StyledButton
          startIcon={<CheckCircleOutlineIcon style={{ fontSize: '0.8rem' }} />}
          onClick={() => handleTicketStatusChange(ticketRequest.status, 'forward')}
          disabled={ticketRequest.status === finalTicketStatus}
        >
          {ticketRequest.status === finalTicketStatus ? 'Archivée' : ticketStatusButtonConstant[ticketRequest.status]}
        </StyledButton>
        <GenericAnimatedTextBubble
          isIndicatorVisible={isSuggestionActive}
          arrowPosition={{
            direction: 'top',
            right: '50px',
            top: '-6px'
          }}
          boxPosition={{
            right: '0px',
          }}
          animationData={{ min: '-60px', max: '-72px', direction: 'vertical' }}
        >
          Cliquez ici pour prendre en charge <span role={'img'} aria-label={'emoji'}>☝️</span>
        </GenericAnimatedTextBubble>
        <>
        </>
      </StyledSuggestionIconContainer>
    )
  }

  const handlePick = (value) => {
    if (value === 'revertTicketStatus') {
      handleTicketStatusChange(ticketRequest.status, 'backward');
    }
    else if (value === 'messageReview') {
      setShowPreview(true);
    }
  }

  const handleTicketStatusChange = (status, type) => {
    const currentIndex = _.findIndex(ticketStatusArray, (ticketStatus) => ticketStatus === status);
    const newIndex = type === 'forward' ? currentIndex + 1 : currentIndex - 1;
    if (type === 'backward' && (newIndex <= 0 || newIndex === 3)) toaster('Statut précédent indisponible', 'warn');
    else if (newIndex === 1 && currentIndex === 0) {
      setSelectedStatus(ticketStatusArray[newIndex]);
      setWarningMessage('Voulez-vous vraiment prendre en charge cette demande ?');
    }
    else if (newIndex === 4 && currentIndex === 3) {
      setSelectedStatus(ticketStatusArray[newIndex]);
      setWarningMessage('Voulez-vous vraiment passer cette demande en "traitée" ?');
    }
    else if (newIndex >= ticketStatusArray.length) {
      setSelectedStatus(finalTicketStatus);
      setWarningMessage('Voulez-vous vraiment archiver cette demande ?');
    }
    else handleTicketSave(ticketStatusArray[newIndex], 'change_status');
  }

  const handleTicketStatusChangeConfirmed = () => {
    if (selectedStatus === finalTicketStatus) {
      handleTicketSave(finalTicketStatus, 'change_status');
      closeFunction();
    }
    else {
      handleTicketSave(selectedStatus, 'change_status');
    }
    setWarningMessage('');
    setSelectedStatus(null);
  }

  const handleTicketSave = (data, type) => {
    const newTicketRequest = _.cloneDeep(ticketRequest);
    if (type === 'mission_sheet') {
      const newMissionSheet = jsonSafeParse(ticketRequest.mission_sheet);
      _.map(newMissionSheet, (item, key) => {
        item.value = data[key];
      })
      newTicketRequest.mission_sheet = newMissionSheet ? JSON.stringify(newMissionSheet) : ticketRequest.mission_sheet;
      const newTicketMessage = {
        content: `La fiche de mission a été éditée`,
        type: 'system',
        ticket: ticketRequest.id
      }
      dispatch(saveTicketMessage({ formData: newTicketMessage }));
    }
    else if (type === 'change_status') {
      const oldStatus = ticketStatusConstant[ticketRequest.status];
      const newStatus = data === finalTicketStatus ? 'Archivée' : ticketStatusConstant[data];
      newTicketRequest.status = data;
      const newTicketMessage = {
        content: `Ce ticket est passé du statut '${oldStatus}' au statut '${newStatus}'`,
        type: 'system',
        ticket: ticketRequest.id
      }
      if ((data === ticketStatusArray[1] && ticketRequest.status === ticketStatusArray[0]) ||
        (data === ticketStatusArray[4] && ticketRequest.status === ticketStatusArray[3])) {

        const newClientTicketMessage = {
          content: data === ticketStatusArray[1] ?
            `Votre demande est en cours de prise en charge`
            :
            `Votre demande a été traitée et les échanges avec votre concierge sont désormais terminés. Si vous avez des questions, vous pouvez contacter le service client. Merci pour votre demande, et à bientôt !`,
          type: 'system-client',
          ticket: ticketRequest.id
        }
        dispatch(saveTicketMessage({ formData: newClientTicketMessage }));
      }
      dispatch(saveTicketMessage({ formData: newTicketMessage }));
    }
    else if (type === 'ticket_provider') {
      newTicketRequest.provider_sheet = JSON.stringify({ ...data });
      const newTicketMessage = {
        content: `La fiche du prestataire a été éditée`,
        type: 'system',
        ticket: ticketRequest.id
      }
      dispatch(saveTicketMessage({ formData: newTicketMessage }));
    }
    dispatch(saveTicketRequest({ id: newTicketRequest.id, formData: newTicketRequest }));
  }

  const getServiceName = (data) => {
    let ticketTitle = ""
    const service = servicesData.find((item) => String(item.id) === String(data.service_id))
    if (data.source === "jotform" && data.full_submission) ticketTitle = getTicketName(data);
    if (ticketTitle == null || ticketTitle === "") ticketTitle = service?.name
    return ticketTitle
  }

  return (
    <DrawerCptCustom
      onOpen={visible}
      visible={visible}
      closeFunction={closeFunction}
      className="ticketRequestDrawer"
    >
      {ticketRequest != null &&
        <DrawerWrapper>
          <StyledHeader
            headerTitle={getServiceName(ticketRequest)}
            headerDesc={generateHeaderDesc()}
            RightSideComponent={
              <HeaderButtonContainer>
                <StyledMenu
                  startIcon={<MenuIcon style={{ fontSize: '0.8rem' }} />}
                  title={"Autres actions"}
                  list={menuList}
                  onChange={handlePick}
                  variant={"button"}
                  disabled={ticketRequest.status === finalTicketStatus}
                />
                {generateTicketStatusChangeButton()}
              </HeaderButtonContainer>
            }
          />
          <ContentContainer>
            <TicketRequestMessage currentUser={userData} ticketRequest={ticketRequest} messengertype={'concierge'} />
            <TicketRequestInfo ticketRequest={ticketRequest} onTicketSave={handleTicketSave} />
          </ContentContainer>
        </DrawerWrapper>
      }
      <GenericWarningModal
        open={!!warningMessage}
        setOpenDialog={() => setWarningMessage('')}
        title={warningMessage}
        text={"Impossible d'annuler cette opération"}
        leftButtonText={"Annuler"}
        rightButtonText={"Oui"}
        leftButtonFunction={() => setWarningMessage('')}
        rightButtonFunction={handleTicketStatusChangeConfirmed}
      />
      {showPreview &&
        <TicketMessageModal
          onClose={() => setShowPreview(false)}
          ticketRequest={ticketRequest}
          isPreview={true}
        />
      }
    </DrawerCptCustom>
  )
}

export default TicketRequestDrawer