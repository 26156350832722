import { call, put, select } from 'redux-saga/effects'
import {
  getServiceTicketSuccess,
  getServiceTicketError,
} from '../actions';
import endpoint from '../../../helpers/api';
import apiCall from '../../apiCall';
import * as authenticationSelectors from '../../authentication/selectors';

export function* getServiceTicketHandler(data) {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const serviceTicketData = yield call(apiCall, endpoint.getServiceTicketById(data.ticketId), userToken);
    if (serviceTicketData) {
      yield put(getServiceTicketSuccess(serviceTicketData));
    }
  } catch (error) {
    yield put(getServiceTicketError(error));
  }
}
