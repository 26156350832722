import styled from "styled-components";

export const Title = styled.h1`
  font-size: 28px;
  font-weight: 700;
  text-align: center;
  margin: 0 auto;
`;

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > div[aria-label="animation"]{
    height: 20vh !important;
    max-height: 160px;
  }
`;
