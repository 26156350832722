import React, { useState, useEffect } from 'react';
import { differenceInMilliseconds } from 'date-fns';
import _ from 'lodash';

import CancelIcon from '@material-ui/icons/Cancel';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { displayDateRange } from '../../../../helpers/displayDateRange';


import {
  StyledPopper,
  PopUpContainer,
  TitleContainer,
  Title,
  StyledCloseContainer,
  ContentContainer,
  StyledInfoGroup,
  StyledInfoTitle,
  StyledInfoText,
  StyledOptionsDisplay,
  StyledButton,
} from './style';

const BookingEventModal = ({
  onClose,
  data,
  anchorEl,
  handleOpenDelete,
  userData,
  corporationData,
  isUserConcierge,
}) => {
  const [openCount, setOpenCount] = useState(0);
  const [shouldRender, setShouldRender] = useState({});
  const [shouldDisplayDelete, setShouldDisplayDelete] = useState(false);

  // const fakeUser = { id: 1, corporation: { id: 'fake', name: 'enterprise 1' }};
  const isOwner = String(data.resource.owner.id) === String(userData.id);
  const isSameCompany = String(data.resource.corporation_id) === String(userData.corporation?.id);
  const isConcierge = isUserConcierge;
  // const isOwner = false;
  // const isSameCompany = false;
  // const isConcierge = false;

  useEffect(() => {
    // console.log('isOwner', isOwner, shouldDisplayDelete);
    setShouldDisplayDelete(false);
    if (data) {
      if (isOwner || isConcierge) {
        // console.log('isOwner', isOwner);
        const now = new Date();
        const diff = differenceInMilliseconds(new Date(data.start), now);
        if (diff > 0) {
          setShouldDisplayDelete(true);
        }
      }
    }
  }, [data]);

  // console.log('data', data);

  useEffect(() => {
    setTimeout(() => {
      setOpenCount(1);
    }, [100]);
  }, []);

  useEffect(() => {
    setShouldRender({
      private_title: isOwner || isSameCompany,
      description: isOwner,
      customer_reference: isOwner,
      options: isOwner || isSameCompany || isConcierge,
      note: isOwner || isConcierge,
      name: isOwner || isSameCompany || isConcierge,
      enterprise: true,
      email: isOwner || isSameCompany || isConcierge,
      contact_number: isOwner || isConcierge
    })
  }, [data]);

  const displayOption = (options) => {
    if (options === null || options.length === 0) {
      return `-`;
    }
    else {
      return _.map(options, (option, index) => {
        return (
          <StyledOptionsDisplay key={index}>
            {`- ${option.title}`}
          </StyledOptionsDisplay>
        )
      });
    }
  };

  const getCorporationName = () => {
    const selectedCorporation = _.find(corporationData, (corporation) => corporation.id === data.resource.corporation_id);
    return `${selectedCorporation?.name || '-'}`;
  }

  const handleClickAway = (props) => {
    const acceptedTarget = [
      'MuiSvgIcon-root',
      'rbc-event-content',
      'rbc-event-label',
      'acceptableCalenderObject'
    ]
    const intersect = _.intersectionWith(acceptedTarget, props.target.classList, _.isEqual);
    if (intersect.length === 0) onClose();
  }

  // console.log('anchorEl', anchorEl);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <StyledPopper
        open={true}
        anchorEl={anchorEl}
        opencount={openCount}
        placement={'right-end'}
      >
        {data &&
          <PopUpContainer>
            <TitleContainer>
              <Title>{data.title}</Title>
              <StyledCloseContainer onClick={onClose}>
                <CancelIcon style={{ fontSize: 30 }} />
              </StyledCloseContainer>
            </TitleContainer>
            <ContentContainer>
              <StyledInfoGroup>
                <StyledInfoTitle>{`Date`}</StyledInfoTitle>
                <StyledInfoText>{displayDateRange(data.start, data.end)}</StyledInfoText>
              </StyledInfoGroup>
              {shouldRender.private_title &&
                <StyledInfoGroup>
                  <StyledInfoTitle>{`Titre Privé`}</StyledInfoTitle>
                  <StyledInfoText>{data.resource.private_title || '-'}</StyledInfoText>
                </StyledInfoGroup>
              }
              {shouldRender.description &&
                <StyledInfoGroup>
                  <StyledInfoTitle>{`Descriptif`}</StyledInfoTitle>
                  <StyledInfoText>{data.resource.description || '-'}</StyledInfoText>
                </StyledInfoGroup>
              }
              {shouldRender.customer_reference &&
                <StyledInfoGroup>
                  <StyledInfoTitle>{`Référence commande`}</StyledInfoTitle>
                  <StyledInfoText>{data.resource.customer_reference || '-'}</StyledInfoText>
                </StyledInfoGroup>
              }
              {shouldRender.options &&
                <StyledInfoGroup>
                  <StyledInfoTitle>{`Options`}</StyledInfoTitle>
                  <StyledInfoText>{displayOption(data.resource.options)}</StyledInfoText>
                </StyledInfoGroup>
              }
              {shouldRender.note &&
                <StyledInfoGroup>
                  <StyledInfoTitle>{`Note`}</StyledInfoTitle>
                  <StyledInfoText>{data.resource.note || '-'}</StyledInfoText>
                </StyledInfoGroup>
              }

              {shouldRender.name &&
                <StyledInfoGroup>
                  <StyledInfoTitle>{`Nom`}</StyledInfoTitle>
                  <StyledInfoText>{`${data.resource.owner.firstname} ${data.resource.owner.lastname}`}</StyledInfoText>
                </StyledInfoGroup>
              }
              {shouldRender.enterprise &&
                <StyledInfoGroup>
                  <StyledInfoTitle>{`Entreprise`}</StyledInfoTitle>
                  <StyledInfoText>{getCorporationName()}</StyledInfoText>
                </StyledInfoGroup>
              }
              {shouldRender.email &&
                <StyledInfoGroup>
                  <StyledInfoTitle>{`Mail`}</StyledInfoTitle>
                  <StyledInfoText>{data.resource.owner.email}</StyledInfoText>
                </StyledInfoGroup>
              }
              {shouldRender.contact_number &&
                <StyledInfoGroup>
                  <StyledInfoTitle>{`Numéro de téléphone`}</StyledInfoTitle>
                  <StyledInfoText>{data.resource.contact_number}</StyledInfoText>
                </StyledInfoGroup>
              }
            </ContentContainer>
            {shouldDisplayDelete &&
              <StyledButton
                onClick={handleOpenDelete}
              >
                {`Annuler la réservation`}
              </StyledButton>
            }
          </PopUpContainer>
        }
      </StyledPopper>
    </ClickAwayListener>
  )
};

export default BookingEventModal;