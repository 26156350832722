import React from "react";

const SmartphoneIcon = ({
  color = "#777777",
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon smartphoneIcon"
      width={width}
      height={height}
      viewBox="0 0 13 18"
      fill="none"
    >
      <path
        d="M9.195.826a3.27 3.27 0 013.27 3.269v10.461a3.27 3.27 0 01-3.27 3.27h-5.23a3.27 3.27 0 01-3.27-3.27V4.095a3.27 3.27 0 013.27-3.27zm1.96 5.092H2.003v8.638c0 1.034.8 1.882 1.816 1.957l.146.005h5.23a1.962 1.962 0 001.962-1.962V5.918zM7.94 13.9a.654.654 0 01.097 1.3l-.097.008H5.403a.654.654 0 01-.097-1.3l.097-.008H7.94zM9.195 2.133h-5.23a1.962 1.962 0 00-1.962 1.962l-.001.515h9.154v-.515c0-1.034-.8-1.881-1.815-1.956l-.146-.006z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export default SmartphoneIcon;
