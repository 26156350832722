import React, { useEffect, useState } from 'react';
import { generatePath } from "react-router";
import { withRouter, useRouteMatch, useParams } from 'react-router-dom';
import {
  shallowEqual,
  useDispatch,
  useSelector,
} from "react-redux";
import Paper from '@material-ui/core/Paper';
import _ from 'lodash';

import {
  getInformationListData,
  isInformationListLoading,
} from '../../../store/adminFront/selectors';
import {
  getInformationList,
} from '../../../store/adminFront/actions';

import GenericAdminFrontHeader from '../../molecules/GenericAdminFrontHeader';
import GenericSearchField from '../../molecules/GenericSearchField';

// Import components
import SkillsNavBar from './components/SkillsNavBar/index';
import TableHeader from './components/TableHeader';
import TableContent from './components/TableContent';
import SwipeableTrainingEditor from './components/EditTraining';
import Loader from './helpers/Loader';

// Import helpers
import { history } from '../../../helpers/history';

// Import Style
import {
  ThemesEiContainer,
  ThemesMainContainer,
  StyledTableContainer,
  EmptyContentContainer
} from './style';

function TrainingCyco({ skillsDomainsList, match }) {
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const { dmId } = useParams();
  const [searchText, setSeachText] = useState("");
  const [informationList, setInformationList] = useState(null);

  const {
    informationListData,
    isInformationListOnLoad,
  } = useSelector(
    state => ({
      informationListData: getInformationListData(state),
      isInformationListOnLoad: isInformationListLoading(state),
    }),
    shallowEqual
  );

  useEffect(() => {
    let id = null;
    if (dmId) id = dmId;
    else if (skillsDomainsList && skillsDomainsList[0] && skillsDomainsList[0].skills) id = skillsDomainsList[0]?.skills[0]?.id;
    if (id && skillsDomainsList) dispatch(getInformationList(id));
  }, []);

  const getInformationListById = async (id) => {
    history.push(generatePath(path, { dmId: id }));
    dispatch(getInformationList(id));
  }

  useEffect(() => {
    if (informationListData) {
      if (informationListData.error) {
        const basePath = path.split('/')[1];
        const baseUrl = `${window.location.origin}/${basePath}/training`;
        window.location = baseUrl;
      }
      else {
        const newSubjects = _.compact(_.map(informationListData.subjects, (subject) => {
          const newInformationElements = _.filter(subject.informationElements, (info) => searchInfo(info));
          if (newInformationElements.length !== 0) {
            return ({
              ...subject,
              informationElements: newInformationElements,
            })
          }
        }));
        const newList = {
          ...informationListData,
          subjects: newSubjects,
        }
        setInformationList(newList);
      }
    }
  }, [informationListData, searchText]);

  const searchInfo = (info) => {
    const search = _.toLower(_.deburr(searchText));
    const title = _.toLower(_.deburr(info.title));
    const description = _.toLower(_.deburr(info.description));


    if (!_.toLower(_.deburr(title)).includes(search) && !_.toLower(_.deburr(description)).includes(search)) return false;
    return true;
  }

  const handleSearch = (value) => {
    setSeachText(value);
  }

  const renderInformationList = () => {
    if (informationList && informationList.subjects && informationList.subjects.length !== 0) {
      return _.map(informationList.subjects, (sub, i) => {
        return (
          <React.Fragment key={i}>
            <TableHeader sub={sub} i={i} />
            <TableContent
              sub={sub}
              InfosListId={informationList.skillId}
              i={i}
            />
          </React.Fragment>
        )
      });
    }
    else {
      return (
        <EmptyContentContainer>
          {`Aucune donnée à afficher`}
        </EmptyContentContainer>
      )
    }
  }

  return (
    <ThemesMainContainer>
      <SkillsNavBar
        isLoading={false}
        getInforListById={getInformationListById}
        feedbackStatusValue={informationList}
        skillsDomainsList={skillsDomainsList}
      />
      {isInformationListOnLoad || informationList === null ?
        <EmptyContentContainer>
          <Loader />
        </EmptyContentContainer>
        :
        <ThemesEiContainer >
          {/* Add Header */}
          <GenericAdminFrontHeader
            headerTitle={`${informationList.skillTitle ? informationList.skillTitle : "NaN"}`}
            headerDesc={`${informationList.skillDesc ?
              informationList.skillDesc.replace(/(<([^>]+)>)/ig, '') : ""}`
            }
            RightSideComponent={
              <GenericSearchField
                placeholder={"Rechercher un sujet"}
                value={searchText}
                onSearchConfirm={handleSearch}
              />
            }
          />
          <StyledTableContainer component={Paper} >
            {renderInformationList()}
          </StyledTableContainer>
        </ThemesEiContainer>
      }
      <SwipeableTrainingEditor />
    </ThemesMainContainer>
  );
}

export default withRouter(TrainingCyco);
