import React from "react";

const LogoIcon = ({
  color = "#4a90e2",
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon logoIcon"
      width={width}
      height={height}
      viewBox="0 0 543 531"
      fill="none"
    >
      <path
        d="M403.184 88.498H543V77.44L403.652 45.978C394.808 19.362 369.552 0 339.375 0 301.901 0 271.5 29.71 271.5 66.375c0 18.326 7.601 34.92 19.888 46.927l85.02 83.14c13.255 12.963 19.888 29.949 19.888 46.927 0 24.46-20.26 44.246-45.252 44.246H135.75C60.802 287.616 0 347.035 0 420.366V531h215.292c124.945 0 226.248-98.999 226.248-221.248v-66.375c0-29.556-11.77-57.348-33.143-78.235l-39.925-39.049c16.129-7.52 28.638-21.042 34.712-37.595ZM45.252 420.373c0-48.892 40.521-88.498 90.498-88.498h107.908c-20.593 88.67-101.527 154.873-198.406 154.873v-66.375Zm170.04 66.375h-27.021c52.162-35.949 89.547-91.047 101.54-154.873h61.23c16.351 0 31.488-4.58 44.723-11.994-5.394 93.038-84.007 166.867-180.472 166.867Zm124.083-398.25c-12.483 0-22.623-9.89-22.623-22.123s10.14-22.123 22.623-22.123c12.51 0 22.623 9.89 22.623 22.123s-10.114 22.123-22.623 22.123Z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export default LogoIcon;
