import styled from "styled-components";
import GenericTextField from "../../../../components/molecules/GenericTextField";
import { COLORS } from '../../../../constants/design';


export const PublicationBoxDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border: ${props => props.error === true ? "1px solid red;" : ""};
    border-radius: ${props => props.error === true ? "4px" : ""};
`;
export const PublicationTitle = styled.span`
  color: #5A5A5A;
`;

const radioActiveColors = {
  'other': COLORS.PRIMARY_BLUE,
  'archive': COLORS.RED_4,
}

const radioActiveDivColors = {
  'other': COLORS.LIGHT_BLUE_1,
  'archive': COLORS.RED,
}

export const PublicationDiv = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  margin-top: 5px;
  cursor: pointer;
  border: solid 2px;
  border-color: #F7F7F7;
  font-size: 0.85rem;
  font-weight: 500;
  padding: 0px 5px;
  background-color: ${props => props.active && radioActiveDivColors[props.type]};
  color: ${COLORS.DARK_GREY};
  &:hover {
    background-color: ${props => radioActiveDivColors[props.type]};
  }
`;

export const PublicationSpan = styled.span`
  margin-left: 5px;
`;

export const CustomRadioDiv = styled.div`
  width: 18px;
  height: 18px;
  margin: 0px 5px 0px 5px;
  border-radius: 50%;
  background-color: #EEEEEE;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CustomRadio = styled.div`
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: ${props => props.active === true && radioActiveColors[props.type]};
`;

export const HeaderDiv = styled.span`       
  margin-bottom: 14px;
  width: 100%;
`;

export const DateField = styled(GenericTextField)`
  margin-bottom: 5px;
`;