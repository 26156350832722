import styled from "styled-components";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import { COLORS } from "../../../constants/design";

export const MainContainer = styled.div`
`;

export const StyledAccordion = styled(Accordion)`
&& {
  &.MuiPaper-elevation1 {
    box-shadow: unset;
  }
  &.MuiAccordion-root {
    border: 1px solid ${COLORS.LIGHT_GREY_4};
    background-color: ${COLORS.WHITE};
    color: ${COLORS.DARK_GREY};
    ::before {
      background-color: unset;
    }
  }
  &.MuiAccordion-rounded{
    border-radius: 6px;
  }
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 0px;
  }
  .MuiAccordionSummary-expandIcon .MuiSvgIcon-root {
    color: ${COLORS.DARK_GREY};
  }
  .MuiAccordionDetails-root {
    flex-direction: column;
  }
}
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
&& {
  .MuiAccordionSummary-content {
    display: flex;
    align-items: center;
    >div:not(:last-child) {
      margin-right: 12px;
    };
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 12px 0;
  }
}
`;

export const StyledAccordionDetail = styled(AccordionDetails)`
`;