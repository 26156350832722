import React from 'react';
import { withRouter } from 'react-router-dom';
// import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';

// Styles Theme Hooks
import useTheme from '../../../../../../../theme-style/useThemeCustomHook';

// Icons
import CompanyIcon from '../../../../../../atoms/icons/company';
import CheckIcon from '../../../../../../atoms/icons/check';
import UserIcon from '../../../../../../atoms/icons/user';
import TargetIcon from '../../../../../../atoms/icons/target';

// Import style
import {
  FeedsCard2Header,
  FeedsCard2HeaderLeft,
  FeedsCard2HeaderRight,
  BlockTitle,
  FeedsCard2Footer,
  FeedsCard2FooterLeft,
  FeedsCard2FooterRight,
  FeedsCard2Block,
  FeedsCard2,
  FeedsCard2Content,
  CompanyIconWrapper,
  CheckIconWrapper,
  UserIconWrapper,
  TargetIconWrapper,
  StatusBlock,
  CardText,
  FeedsCardOther
} from './feedsList.style';

const FeedCard2 = ({
  feed,
  selectedSite,
  site,
  handleIsSelectedFeed,
  idx,
  theme,
  processedFeeds,
}) => {
  return (
    <>
      {selectedSite.id !== site.id ?

        <FeedsCardOther
          className="feedsCardOther"
          key={feed.id}
          onClick={(e) => null}
          isSlected={processedFeeds.includes(idx)}
        >
          <FeedsCard2Header>
            <FeedsCard2HeaderLeft>
              <FeedsCard2Block bgColor={theme.colors.white}>
                <CompanyIconWrapper>
                  <CompanyIcon color={theme.colors.secondary} />
                </CompanyIconWrapper>
                <CardText txtColor={theme.colors.secondary}>
                  {site.name}
                </CardText>
              </FeedsCard2Block>

              <FeedsCard2Block bgColor={theme.colors.white}>
                <StatusBlock bgColor={theme.colors.orange} />
                <CardText>{feed.responseStatus}</CardText>
              </FeedsCard2Block>
            </FeedsCard2HeaderLeft>

            <FeedsCard2HeaderRight>
              <FeedsCard2Block>
                <TargetIconWrapper>
                  <TargetIcon color={theme.colors.grey4} />
                </TargetIconWrapper>
                <CardText>Version actuelle</CardText>
              </FeedsCard2Block>
            </FeedsCard2HeaderRight>
          </FeedsCard2Header>

          <FeedsCard2Content>
            {feed.comment}
          </FeedsCard2Content>
        </FeedsCardOther>


      :



        <FeedsCard2
          className="FeedsCard2"
          key={feed.id}
          onClick={(e) => selectedSite.name == site.name ?
            handleIsSelectedFeed(feed.id) : null}
          isSlected={processedFeeds.includes(idx)}
        >
          <FeedsCard2Header>
            <FeedsCard2HeaderLeft>
              <FeedsCard2Block bgColor={theme.colors.secondary}>
                <CompanyIconWrapper>
                  <CompanyIcon color={theme.colors.white} />
                </CompanyIconWrapper>
                <CardText txtColor={theme.colors.white}>
                  {site.name}</CardText>
              </FeedsCard2Block>

              <FeedsCard2Block>
                <StatusBlock bgColor={theme.colors.orange} />
                <CardText>{feed.responseStatus}</CardText>
              </FeedsCard2Block>
            </FeedsCard2HeaderLeft>

            <FeedsCard2HeaderRight>
              <FeedsCard2Block>
                <TargetIconWrapper>
                  <TargetIcon color={theme.colors.grey4} />
                </TargetIconWrapper>
                <CardText>Version actuelle</CardText>
              </FeedsCard2Block>
            </FeedsCard2HeaderRight>
          </FeedsCard2Header>

          <FeedsCard2Content>
            {feed.comment}
          </FeedsCard2Content>

          <FeedsCard2Footer>
            <FeedsCard2FooterLeft>
              <UserIconWrapper>
                <UserIcon color={theme.colors.grey4} />
              </UserIconWrapper>
              <CardText>
                {feed.collaborator} {
                  feed.source === "FRONT ADMIN" ? 
                  "depuis admin"
                  : ""
                } le {moment(feed.createdAt).format("DD/MM/YYYY HH:mm")}
              </CardText>
            </FeedsCard2FooterLeft>


            <FeedsCard2FooterRight>
              {(processedFeeds.includes(idx) || feed.isProcessed) ?
                <span>Remarque traité</span>
                :
                <span>Marquer comme traité</span>
              }

              {(processedFeeds.includes(idx) || feed.isProcessed) ?
                < CheckIconWrapper >
                  <CheckIcon color={theme.colors.grey4} />
                </CheckIconWrapper>
                : null}

            </FeedsCard2FooterRight>

          </FeedsCard2Footer>
        </FeedsCard2>
      }
    </>
  )
}

const FeedListItem = ({ processedFeeds, site, handleIsSelectedFeed, feedsList, selectedSite }) => {
  const theme = useTheme();
  return (
    <React.Fragment>
      <BlockTitle className="feedBlockTitle" key={site.id}>
        {feedsList && feedsList.filter(fd => fd.company_site_id == site.id)
          .length > 0 && (
          <>
            {(feedsList.filter(fd => fd.company_site_id == site.id && !fd.isProcessed).length > 0) ? `${feedsList.filter(fd => fd.company_site_id == site.id && !fd.isProcessed).length} remarque(s) ` : 'Aucune remarque '}
            à traiter pour {site.name}
          </>
        )
        }

        {feedsList && feedsList.filter(fd => fd.company_site_id == site.id)
          .length == 0 && (
            feedsList.filter(fd => fd.company_site_id == site.id) + "Aucune remarque à traiter pour " + site.name
          )
        }
      </BlockTitle>

      <div>
        {feedsList && feedsList.filter(fd => fd.company_site_id == site.id)
          .length > 0 ?
          feedsList.filter(fd => ((fd.isProcessed == false
            && fd.company_site_id == site.id)
          )).map((feed, idx) => {
            return <FeedCard2
              processedFeeds={processedFeeds}
              feed={feed}
              idx={feed.id}
              key={idx}
              selectedSite={selectedSite}
              site={site}
              handleIsSelectedFeed={handleIsSelectedFeed}
              theme={theme}
            />
          }) : null
        }
      </div>
    </React.Fragment >
  )
}

const FeedList = ({
  processedFeeds,
  sitesList,
  handleIsSelectedFeed,
  feedsList,
  selectedSite }) => {

  let orderedSitesList = sitesList ? sitesList.filter(a => a.id === selectedSite?.id) : null;
  if (orderedSitesList) {
    orderedSitesList = orderedSitesList.concat(sitesList.filter(a => a?.id !== selectedSite?.id));
  }

  if (orderedSitesList) {
    return (
      orderedSitesList
      .map(site => {
        return (
          <>
            <FeedListItem
              site={site}
              processedFeeds={processedFeeds}
              handleIsSelectedFeed={handleIsSelectedFeed}
              feedsList={feedsList}
              selectedSite={selectedSite}
            />
            <br />
            <hr className="hrTemporary"/>
            <br />
          </>
        );
      })
    );
  }
  return null;
};

export default withRouter(FeedList);
