import {
  all,
  put,
  takeEvery,
  call,
  select,
  take,
  delay,
} from "redux-saga/effects";
import endpoint from '../../helpers/api';
import apiCall from '../apiCall';
import { authHeader } from '../../helpers/auth-header';

/** Actions */
import * as a from './actions';
import * as authenticationSelectors from '../authentication/selectors';

//Import handlers

import {
  getSpecificCaseHandler,
  editSpecificCaseHandler,
  deleteSpecificCaseHandler
} from './handler/specificCaseHandler';

async function postAPIService(endpoint, data) {
  return fetch(endpoint, {
    method: 'POST',
    headers: authHeader(),
    body: data
  })
    .then(res => {
      const body = res.json()
      if (
        [200, 201].includes(res?.status)
        || body?.response_status === 'ok'
      ) {
        return body;
      }
      return res.status;
    })
    .then(data => data)
    .catch(err => err);
}

function* getLastUntreatedFeedbackSaga() {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const lastUntreatedFeedbackData = yield call(apiCall, endpoint.getLastUntreatedFeedback, userToken);
    yield put(a.getLastUntreatedFeedbackSuccess(lastUntreatedFeedbackData));
  } catch (error) {
    yield put(a.getLastUntreatedFeedbackError(error));
  }
}

function* getUntreatedFeedbackStatsSaga() {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const untreatedFeedbackStatsData = yield call(apiCall, endpoint.getUntreatedFeedbackStats, userToken);
    yield put(a.getUntreatedFeedbackStatsSuccess(untreatedFeedbackStatsData));
  } catch (error) {
    yield put(a.getUntreatedFeedbackStatsError(error));
  }
}

function* getActiveInfoElementSaga() {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const activeInfoElementData = yield call(apiCall, endpoint.getActiveInfoElement, userToken);
    yield put(a.getActiveInfoElementSuccess(activeInfoElementData));
  } catch (error) {
    yield put(a.getActiveInfoElementError(error));
  }
}

function* getInactiveInfoElementSaga() {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const inactiveInfoElementData = yield call(apiCall, endpoint.getInactiveInfoElement, userToken);
    yield put(a.getInactiveInfoElementSuccess(inactiveInfoElementData));
  } catch (error) {
    yield put(a.getInactiveInfoElementError(error));
  }
}

function* getCollaboratorsListSaga(action) {
  const formData = action.data;
  let url = endpoint.getCollaboratorsList;

  url = `${url}?page=${formData?.page ? formData.page : 1}&limit=${formData?.limit ? formData.limit : 50}`;

  if (formData?.keyword) {
    url = `${url}&keyword=${formData.keyword}`;
  }
  if (formData?.companySite) {
    url = `${url}&companySite=${formData.companySite}`;
  }

  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const collaboratorsListData = yield call(apiCall, url, userToken);
    yield put(a.getCollaboratorsListSuccess(collaboratorsListData));
  } catch (error) {
    yield put(a.getCollaboratorsListError(error));
  }
}

function* getCollaboratorsRulesSaga() {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const collaboratorsRulesData = yield call(apiCall, endpoint.getCollaboratorsRoles, userToken);
    yield put(a.getCollaboratorsRulesSuccess(collaboratorsRulesData));
  } catch (error) {
    yield put(a.getCollaboratorsRulesError(error));
  }
}

function* getUntreatedFeedbackSaga() {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const untreatedFeedbackData = yield call(apiCall, endpoint.getUntreatedFeedback, userToken);
    yield put(a.getUntreatedFeedbackSuccess(untreatedFeedbackData));
  } catch (error) {
    yield put(a.getUntreatedFeedbackError(error));
  }
}

function* getUntreatedFbBySkillSaga() {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const untreatedFbBySkillData = yield call(apiCall, endpoint.getUntreatedFbBySkill, userToken);
    yield put(a.getUntreatedFbBySkillSuccess(untreatedFbBySkillData));
  } catch (error) {
    yield put(a.getUntreatedFbBySkillError(error));
  }
}

function* getUntreatedFbBySkillStatsSaga() {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const untreatedFbBySkillStatsData = yield call(apiCall, endpoint.getUntreatedFbBySkillStats, userToken);
    yield put(a.getUntreatedFbBySkillStatsSuccess(untreatedFbBySkillStatsData));
  } catch (error) {
    yield put(a.getUntreatedFbBySkillStatsError(error));
  }
}

function* getSkillsDomainsListSaga() {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const getSkillsDomainsListData = yield call(apiCall, endpoint.getSkillsDomainsList, userToken);
    yield put(a.getSkillsDomainsListSuccess(getSkillsDomainsListData));
  } catch (error) {
    yield put(a.getSkillsDomainsListError(error));
  }
}

function* getFeebackByInfoElSaga({ eiId }) {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const feedbacks = yield call(apiCall, endpoint.getFeebackByInfoEl(eiId), userToken);
    if (feedbacks.code === 404 && feedbacks.message === "Feedbacks not found") {
      yield put(a.getFeebackByInfoElSuccess([]));
    } else {
      yield put(a.getFeebackByInfoElSuccess(feedbacks));
    }
  } catch (error) {
    yield put(a.getFeebackByInfoElError(error));
  }
}

function* getTreatedFeedbackSaga() {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const treatedFeedbackData = yield call(apiCall, endpoint.getTreatedFeedback, userToken);
    yield put(a.getTreatedFeedbackSuccess(treatedFeedbackData));
  } catch (error) {
    yield put(a.getTreatedFeedbackError(error));
  }
}

function* getTreatedFeedbackStatsSaga() {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const treatedFeedbackStatsData = yield call(apiCall, endpoint.getTreatedFeedbackStats, userToken);
    yield put(a.getTreatedFeedbackStatsSuccess(treatedFeedbackStatsData));
  } catch (error) {
    yield put(a.getTreatedFeedbackStatsError(error));
  }
}

function* getTreatedFbBySkillStatsSaga() {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const treatedFbBySkillStatsData = yield call(apiCall, endpoint.getTreatedFbBySkillStats, userToken);
    yield put(a.getTreatedFbBySkillStatsSuccess(treatedFbBySkillStatsData));
  } catch (error) {
    yield put(a.getTreatedFbBySkillStatsError(error));
  }
}

function* getPositiveBotFeedbackSaga({ companyResponseElementId }) {
  try {
    const formData = new FormData();
    formData.append("source", "FRONT ADMIN");
    formData.append('companyResponseElementId', companyResponseElementId);
    formData.append('responseStatus', 'ok');
    const positiveBotFeedbackData = yield call(postAPIService, endpoint.getPositiveBotFeedback, formData);
    yield put(a.getPositiveBotFeedbackSuccess(positiveBotFeedbackData));
  } catch (error) {
    yield put(a.getPositiveBotFeedbackError(error));
  }
}

function* updateNegativeFeedbackSaga({ eiId, companyResponseElementId, responseStatus, comment }) {
  try {
    const formData = new FormData();
    formData.append("source", "FRONT ADMIN");
    formData.append('companyResponseElementId', companyResponseElementId);
    formData.append('responseStatus', responseStatus);
    formData.append('comment', comment);
    const positiveBotFeedbackData = yield call(postAPIService, endpoint.getNegativeBotFeedback, formData);
    yield put(a.updateNegativeBotFeedbackSuccess(positiveBotFeedbackData));
    yield put(a.getFeebackByInfoEl(eiId));
    yield put(a.getFeedsStatInfo(eiId));
    yield delay(100);
    yield put(a.resetUpdateNegativeBotFeedback());
  } catch (error) {
    yield put(a.updateNegativeBotFeedbackError(error));
    yield delay(100);
    yield put(a.resetUpdateNegativeBotFeedback());
  }
}

function* getInformationListSaga({ id }) {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const getInformationListData = yield call(apiCall, endpoint.getInformationList(id), userToken);
    yield put(a.getInformationListSuccess(getInformationListData));
  } catch (error) {
    yield put(a.getInformationListError(error));
  }
}

function* getElementInformationListSaga({ eiId }) {
  try {
    const formData = new FormData();
    formData.append("eiIds", JSON.stringify([eiId]));
    const getElementInformationListData = yield call(postAPIService, endpoint.getElementInformationList, formData);
    yield put(a.getElementInformationListSuccess(getElementInformationListData));
  } catch (error) {
    yield put(a.getElementInformationListError(error));
  }
}

function* getFeedsStatInfoSaga({ eiId }) {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const data = yield call(apiCall, endpoint.getfeedsStatInfo(eiId), userToken);
    if (typeof data == 'object' && Object.keys(data).length > 0) {
      yield put(a.getFeedsStatInfoSuccess(data));
    }
  } catch (error) {
    yield put(a.getFeedsStatInfoError(error));
  }
}

function* getFeedsListBySiteSaga() {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const getFeedsListBySiteData = yield call(apiCall, endpoint.getFeedsListBySite, userToken);
    yield put(a.getFeedsListBySiteSuccess(getFeedsListBySiteData));
  } catch (error) {
    yield put(a.getFeedsListBySiteError(error));
  }
}

function* getElementInformationResponseByIdSaga({ eiId }) {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const getElementInformationResponseByIdData = yield call(apiCall, endpoint.getEiResponseList(eiId), userToken);
    yield put(a.getElementInformationResponseByIdSuccess(getElementInformationResponseByIdData));
  } catch (error) {
    yield put(a.getElementInformationResponseByIdError(error));
  }
}

function* getElementInformationByCompanySaga() {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const companyData = yield call(apiCall, endpoint.getElementInformationByCompany, userToken);
    const sites = [];
    if (companyData.companySites && !!companyData?.companySites?.length) {
      sites.push({
        name: "Réponse principale de l'entreprise",
        value: "Entreprise générale",
        id: null
      });
      companyData.companySites.map(site => {
        sites.push({
          name: site.companySite,
          value: site.companySite,
          id: site.id
        });
      });
    }
    yield put(a.getElementInformationByCompanySuccess(sites));
  } catch (error) {
    yield put(a.getElementInformationByCompanyError(error));
  }
}

function* updateElementInformationActivationSaga({ eiId }) {
  try {
    const formData = new FormData();
    formData.append("eiId", eiId);
    const updateElementInformationActivationData = yield call(postAPIService, endpoint.isActiveEiState, formData);
    yield put(a.updateElementInformationActivationSuccess(updateElementInformationActivationData));
  } catch (error) {
    yield put(a.updateElementInformationActivationError(error));
  }
}

function* updateElementInformationResponseSaga({
  eiId, response, responseId, companySiteId
}) {
  try {
    const hasResponse = !!response?.messageElements?.length || (typeof response === 'string' && !!response?.length);
    const formData = new FormData();
    formData.append("eiId", eiId);
    formData.append("response", hasResponse ? JSON.stringify(response) : '');
    formData.append("responseId", responseId);
    formData.append("companySiteId", companySiteId);
    const updatedElementInformation = yield call(postAPIService, endpoint.updateEiResponse, formData);
    if (updatedElementInformation?.code === 200) {
      yield put(a.updateElementInformationResponseSuccess(updatedElementInformation.id));
      yield put(a.getElementInformationResponseById(eiId));
    } else {
      yield put(a.updateElementInformationResponseError(updatedElementInformation));
    }
  } catch (error) {
    yield put(a.updateElementInformationResponseError(error));
  }
}

function* updateProcessedFeedbackSaga({ eiId, processedIds }) {
  try {
    const formData = new FormData();
    formData.append("feedbackIds", JSON.stringify(processedIds));
    const updatedProcessedData = yield call(postAPIService, endpoint.updateProcessedFeedback, formData);
    if (updatedProcessedData?.code === 200) {
      yield put(a.updateProcessedFeedbackSuccess());
      yield put(a.getFeebackByInfoEl(eiId));
      yield put(a.getFeedsStatInfo(eiId));
    } else {
      yield put(a.updateProcessedFeedbackError(updatedProcessedData));
    }
  } catch (error) {
    yield put(a.updateProcessedFeedbackError(error));
  }
}

function* updateAllElementInformationSaga({ eiId, processedIds, response, responseId, companySiteId }) {
  try {
    if (!!processedIds?.length) {
      yield put(a.updateProcessedFeedback(eiId, processedIds));
    } else {
      yield put(a.updateProcessedFeedbackSuccess());
    }
    yield put(a.updateElementInformationResponse(eiId, response, responseId, companySiteId));
    if (!!processedIds?.length) {
      yield take(a.UPADATE_FEEDBACK_PROCESSED.SUCCESS);
    }
    yield take(a.UPADATE_ELEMENT_INFORMATION_RESPONSE.SUCCESS);
    yield put(a.updateAllElementInformationSuccess());
    yield delay(100);
    yield put(a.resetUpdateAllElementInformation());
  } catch (error) {
    yield put(a.updateElementInformationResponseError(error));
    yield delay(100);
    yield put(a.resetUpdateAllElementInformation());
  }
}

export default function* adminFrontRootSaga() {
  yield all([
    takeEvery(a.GET_LAST_UNTREATED_FB.REQUEST, getLastUntreatedFeedbackSaga),
    takeEvery(a.GET_UNTREATED_FB_STATS.REQUEST, getUntreatedFeedbackStatsSaga),
    takeEvery(a.GET_ACTIVE_INFO_ELEMENT.REQUEST, getActiveInfoElementSaga),
    takeEvery(a.GET_INACTIVE_INFO_ELEMENT.REQUEST, getInactiveInfoElementSaga),
    takeEvery(a.GET_COLLABORATORS_LIST.REQUEST, getCollaboratorsListSaga),
    takeEvery(a.GET_COLLABORATORS_RULES.REQUEST, getCollaboratorsRulesSaga),
    takeEvery(a.GET_UNTREATED_FEEDBACK.REQUEST, getUntreatedFeedbackSaga),
    takeEvery(a.GET_UNTREATED_FEEDBACK_BY_SKILL.REQUEST, getUntreatedFbBySkillSaga),
    takeEvery(a.GET_UNTREATED_FEEDBACK_BY_SKILL_STATS.REQUEST, getUntreatedFbBySkillStatsSaga),
    takeEvery(a.GET_SKILLS_DOMAINS_LIST.REQUEST, getSkillsDomainsListSaga),
    takeEvery(a.GET_FEEDBACK_BY_INFO_ELEMENT.REQUEST, getFeebackByInfoElSaga),
    takeEvery(a.GET_TREATED_FEEDBACK.REQUEST, getTreatedFeedbackSaga),
    takeEvery(a.GET_TREATED_FEEDBACK_STATS.REQUEST, getTreatedFeedbackStatsSaga),
    takeEvery(a.GET_TREATED_FEEDBACK_BY_SKILL_STATS.REQUEST, getTreatedFbBySkillStatsSaga),
    takeEvery(a.GET_POSITIVE_BOT_FEEDBACK.REQUEST, getPositiveBotFeedbackSaga),
    takeEvery(a.GET_INFORMATION_LIST.REQUEST, getInformationListSaga),
    takeEvery(a.GET_ELEMENT_INFORMATION_LIST.REQUEST, getElementInformationListSaga),
    takeEvery(a.GET_FEEDS_STAT_INFO.REQUEST, getFeedsStatInfoSaga),
    takeEvery(a.GET_FEEDS_LIST_BY_SITE.REQUEST, getFeedsListBySiteSaga),
    takeEvery(a.GET_ELEMENT_INFORMATION_RESPONSE_BY_ID.REQUEST, getElementInformationResponseByIdSaga),
    takeEvery(a.GET_ELEMENT_INFORMATION_BY_COMPANY.REQUEST, getElementInformationByCompanySaga),
    takeEvery(a.UPADATE_ELEMENT_INFORMATION_ACTIVATION.REQUEST, updateElementInformationActivationSaga),
    takeEvery(a.UPADATE_ELEMENT_INFORMATION_RESPONSE.REQUEST, updateElementInformationResponseSaga),
    takeEvery(a.UPADATE_FEEDBACK_PROCESSED.REQUEST, updateProcessedFeedbackSaga),
    takeEvery(a.UPDATE_NEGATIVE_BOT_FEEDBACK.REQUEST, updateNegativeFeedbackSaga),
    takeEvery(a.UPDATE_ALL_ELEMENT_INFORMATION.REQUEST, updateAllElementInformationSaga),
    takeEvery(a.GET_SPECIFIC_CASE.REQUEST, getSpecificCaseHandler),
    takeEvery(a.UPDATE_SPECIFIC_CASE.REQUEST, editSpecificCaseHandler),
    takeEvery(a.DELETE_SPECIFIC_CASE.REQUEST, deleteSpecificCaseHandler),
  ]);
}
