import styled from "styled-components";
import TextField from '@material-ui/core/TextField';

export const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #5A5A5A;
`;

export const StyledTextField = styled(TextField)`
  && {
    margin-top: 10px;
    .MuiInputBase-input {
      font-size: 0.9rem;
      color: #5A5A5A;
    }
    .MuiInputBase-input.Mui-disabled {
        opacity: 0.3;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #357ED3;
    }
  }
`;