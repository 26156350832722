import React, {useEffect, useState, useContext} from 'react';
import {
  shallowEqual,
  useDispatch,
  useSelector,
} from "react-redux";
import {
  Link,
  // useRouteMatch,
  // useHistory
} from "react-router-dom";

// Styles Theme Hooks
// import useTheme from "../../../../theme-style/useThemeCustomHook";

// Import services
// import getLastUntreatedFb from '../../services/getLastUntreatedFeedback.service';
// import getUntreatedFbStats from '../../services/getUntreatedFeedbackStats.service';

// Store
import {
  getLastUntreatedFeedbackData,
  getUntreatedFeedbackStatsData,
} from '../../../../../../store/adminFront/selectors';
import {
getLastUntreatedFeedback,
getUntreatedFeedbackStats
} from '../../../../../../store/adminFront/actions';

// Components
import ButtonCpt from '../../../../components/molecules/buttonCpt';

// Styles
import {
  Container,
  HeadList,
  Title,
  Indicator,
  BodyList,
  BodyListItem,
  HeadItem,
  BodyItem,
  FooterList,
  EmptyList,
  Text
} from "./style";

// Import context
// import { Context as EditorContext } from '../../../../context/editorState';
import moment from 'moment';

const FeedbackUntreatedList = () => {
  const dispatch = useDispatch();
  const {
    lastUntreatedFeedback,
    untreatedFeedbackStats,
  } = useSelector(
    state => ({
      lastUntreatedFeedback: getLastUntreatedFeedbackData(state),
      untreatedFeedbackStats: getUntreatedFeedbackStatsData(state),
    }),
    shallowEqual
  );

  useEffect(() => {
      dispatch(getLastUntreatedFeedback());
      dispatch(getUntreatedFeedbackStats());
  }, []);

  // console.log(lastUntreatedFeedback);
  // console.log('untreatedFeedbackStats', untreatedFeedbackStats);

  const untreatedFeedbackTotal = untreatedFeedbackStats?.total;
  // console.log('untreatedFeedbackTotal', untreatedFeedbackTotal);

  return (
    <Container>
      <HeadList>
        <Title>
            Dernière(s) remarque(s) non traitée(s)
        </Title>
        <Indicator>
            {untreatedFeedbackTotal ? untreatedFeedbackTotal : 0}
        </Indicator>
      </HeadList>

      {lastUntreatedFeedback && lastUntreatedFeedback.length > 0?
        <>
          <BodyList>
            {lastUntreatedFeedback.map((item, index) => {
              const {
                subjectTitle,
                informationElementTitle,
                informationElementId,
                createdAt,
                userComment
              } = item;

              return(
                <BodyListItem
                  to={"/admin/dashboard?opendrawerbyid=" +informationElementId}
                  key={`key-lastUntreatedFeedback-${index}`}
                  style={{textDecoration: "none"}}
                >
                  <HeadItem>
                    <h4>{subjectTitle}</h4>
                    <h5>{informationElementTitle}</h5>
                    <span>{moment(createdAt).format("DD/MM/YYYY HH:mm")}</span>
                  </HeadItem>
                  <BodyItem>
                    {userComment}
                  </BodyItem>
                </BodyListItem>
              )
            })}
          </BodyList>
          <FooterList>
            <ButtonCpt
              label="Voir la liste des remarques"
              appearence="lightBlue"
              component={Link}
              to={`/admin/feeds`}
            />
          </FooterList>
        </>
        :
        <EmptyList>
          <Text>
            Vous n’avez aucune remarque non traitée actuellement.
          </Text>
          <ButtonCpt
            label="Accédez à la liste des remarques de vos collaborateurs"
            appearence="darkBlue"
            component={Link}
            to="/admin/feeds"
          />
        </EmptyList>
      }

    </Container>
  );
}

export default FeedbackUntreatedList;
