import styled from "styled-components";
import { Link } from "react-router-dom";

export const TransportIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  margin-left: 8px;
  &.traffic_type_subway{

  }
  &.traffic_type_walk{
    width: 18px;
  }
  &.traffic_type_bike{
    width: 28px;
  }
  &.traffic_type_car{
    width: 28px;
  }
`;

export const GoogleSuggestWrapper = styled.div`
  &&{
    .MuiFormControl-root {
      background-color: #ffffff;
      border: 1px solid #F4F4F4;
      border-radius: 8px;
      .MuiInputBase-root{
        .MuiInputBase-input{
          height: 60px !important; 
          padding: 30px 20px 10px 20px !important;
        }
        & > span{
          top: 29px;
        }
      }
    }
  }
`;

export const TransportBox = styled.div`
  margin: 15px auto 5px auto;
  .MuiFormControlLabel-root{
    width: calc(50% - 19px);
    border-radius: 8px;
    border: 1px solid #F4F4F4;
    padding: 0px 12px 0px 0px;
    margin: 0 0px 10px 0;
    transition: all .2s ease-out;
    &:hover{
      background-color: #FAFAFA;
    }
  }
  .MuiFormLabel-root{
    width: 100%;
    padding: 20px 25px 10px 20px;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 11px;
    color: #838383;
    transition: all .2s ease-out;
  }
  .MuiFormGroup-root{
    justify-content: space-between;
    flex-direction: column;
    flex-flow: row wrap;
    .MuiFormControlLabel-root{
      .MuiCheckbox-root{
        padding: 9px;
        color: #F4F4F4;
        &:hover,
        &.Mui-checked{
          color: rgb(64, 177, 118);
        }
      }
      .MuiTypography-root{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size 14px;
        font-weight: 500;
        text-align: left;
        color: #838383;
      }
    }
  }
`;

export const InputBox = styled.div`
  
`;

export const ActionBox = styled.div`
  &&{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 0 0;
  }
`;

export const BackButton = styled(Link)`
  &&{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 10px 0 0;
    padding: 14px;
    text-align: center;
    font-weight: 700;
    border: none;
    text-decoration: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    height: 60px;
    background-color: #eaeaea;
    box-sizing: border-box;
    color: #696969;
    text-transform: uppercase;
  }
`;

export const Text = styled.p`
  display: block;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 600;
  color: #838383;
  text-align: center;
  font-weight: 400;
  &:last-child{
    margin: 0;
  }
`;

export const BubbleText = styled.div`
  position: relative;
  padding: 15px;
  margin: 0 0 15px 0;
  border-radius: 5px 10px 10px 10px;
  background-color: #FFFFFF;
  box-shadow: 1px 1px 3px 1px rgb(0 0 0 / 20%);
`;

export const Container = styled.div`
  
`;