import { all, takeEvery } from "redux-saga/effects";

/** Actions */
import * as a from './actions';

//Import handlers
import {
  validateGetGroundTokenHandler,
} from './handler/getGroundHandler';

export default function* getGroundSaga() {
  yield all([
    takeEvery(a.VALIDATE_GETGROUND_TOKEN.REQUEST, validateGetGroundTokenHandler),
  ]);
}
