import styled from "styled-components";
import breakpoints from "../../../theme-style/breakpoints";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  position: sticky;
  z-index: 99;
  top: 0;
  background-color: #ffffff;
  border-bottom: 1px solid #efefef;
  padding: 12px;

  @media ${breakpoints.mobileXL} {
    padding: 20px;
  }
`;

export const WrapperContent = styled.div`
  flex: 6;
  margin-right: 20px;
`;

export const Title = styled.h1`
  margin-bottom: 10px;
  color: #5A5A5A;
  font-size: 25px;
  line-height: 18px;
  height: 24px;
  font-weight: 700;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Text = styled.div`
  margin: 0;
  color: #BBBBBB;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;