import i18n from "i18next";
// import BackendXHR from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

// Import languages files
import lang_fr from "./locales/fr.json";

const resources = {
  fr: {
    translation: lang_fr,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "fr",
    keySeparator: ".", // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
