import React, { useState } from 'react';

import { COLORS } from '../../../../../constants/design';

import LogoutModal from '../../../../../components/organisms/LogoutModal';
import ArrowRightIcon from '../../atoms/icons/arrowRight';
import LogoutIcon from '../../atoms/icons/logout';

import {
  MainContainer,
  AccountLogoutItem,
  AccountItemCircle,
  LogoutIconWrapper,
  AccountItemTitle,
  ArrowIconWrapper
} from './style';

const DisconnectModule = () => {
  const [openLogoutModal, setOpenLogoutModal] = useState(false);

  return (
    <MainContainer>
      <AccountLogoutItem onClick={() => setOpenLogoutModal(!openLogoutModal)}>
        <AccountItemCircle className="accountItemCircle-logout">
          <LogoutIconWrapper>
            <LogoutIcon color={COLORS.WHITE} />
          </LogoutIconWrapper>
        </AccountItemCircle>

        <AccountItemTitle>
          Déconnexion
        </AccountItemTitle>

        <ArrowIconWrapper>
          <ArrowRightIcon color={COLORS.LIGHT_GREY_4} />
        </ArrowIconWrapper>
      </AccountLogoutItem>

      <LogoutModal
        open={openLogoutModal}
        setOpenDialog={() => setOpenLogoutModal(!openLogoutModal)}
      />
    </MainContainer>
  );
};

export default DisconnectModule;