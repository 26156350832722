import React from 'react';
import {
  shallowEqual,
  useSelector,
} from "react-redux";
import {
  useRouteMatch,
} from "react-router-dom";

import { BLOCK_KEYS } from '../../../../constants/sideBarConstants';

// Layout
import SidebarFrontBaseLayout from "../../layouts/sidebarFrontBaseLayout";

// Components
import ArrowRightIcon from '../../components/atoms/icons/arrowRight';
import LocationIcon from '../../components/atoms/icons/location';
import ProfileIcon from '../../components/atoms/icons/profile';
import InfoBulleIcon from '../../components/atoms/icons/infoBulle';

import UserInfoAndWeatherModule from '../../components/organisms/UserInfoAndWeatherModule';
import DisconnectModule from '../../components/organisms/DisconnectModule';

// Hooks
import useTheme from "../../../../theme-style/useThemeCustomHook";

import {
  getAccountContext,
} from '../../../../store/company/selectors';

// Styles
import {
  StyledTitleWithToolTip,
  Container,
  ArrowIconWrapper,
  AccountWrapper,
  AccountList,
  AccountItem,
  AccountItemCircle,
  AccountItemTitle,
  InfoBulleIconWrapper,
  ProfileIconWrapper,
  LocationIconWrapper,
} from "./style";

const Account = () => {
  const theme = useTheme();
  const { path } = useRouteMatch();

  const {
    accountContext
  } = useSelector(
    state => ({
      accountContext: getAccountContext(state)
    }),
    shallowEqual
  );

  return (
    <SidebarFrontBaseLayout
      id="sidebarLayoutAccount"
      className="sidebarLayoutAccount page-container sidebarPage"
    >
      <Container>
        {accountContext?.[BLOCK_KEYS.WEATHER] &&
          <UserInfoAndWeatherModule />
        }
        {(accountContext?.[BLOCK_KEYS.ACCOUNT_INFO] ||
          accountContext?.[BLOCK_KEYS.ACCOUNT_PROFILE] ||
          accountContext?.[BLOCK_KEYS.ACCOUNT_TRAVEL] ||
          accountContext?.[BLOCK_KEYS.ACCOUNT_DISCONNECT]) &&
          <AccountWrapper>
            <StyledTitleWithToolTip
              title={"Mon compte Cyconia"}
              toolTipText={"Cet espace vous appartient entièrement. Gérez les informations de votre profil ou configurez vos données pour que nous puissions vous assistez dans les meilleures conditions 🙂"}
            />
            <AccountList>
              {accountContext?.[BLOCK_KEYS.ACCOUNT_INFO] &&
                <AccountItem to={`${path}/informations`}>
                  <AccountItemCircle className="accountItemCircle-info">
                    <InfoBulleIconWrapper>
                      <InfoBulleIcon color={theme.colors.white} />
                    </InfoBulleIconWrapper>
                  </AccountItemCircle>

                  <AccountItemTitle>
                    Mes informations
                  </AccountItemTitle>

                  <ArrowIconWrapper>
                    <ArrowRightIcon color={theme.colors.grey4} />
                  </ArrowIconWrapper>
                </AccountItem>
              }

              {accountContext?.[BLOCK_KEYS.ACCOUNT_PROFILE] &&
                <AccountItem to={`${path}/profil`}>
                  <AccountItemCircle className="accountItemCircle-profil">
                    <ProfileIconWrapper>
                      <ProfileIcon color={theme.colors.white} />
                    </ProfileIconWrapper>
                  </AccountItemCircle>

                  <AccountItemTitle>
                    Mon profil
                  </AccountItemTitle>

                  <ArrowIconWrapper>
                    <ArrowRightIcon color={theme.colors.grey4} />
                  </ArrowIconWrapper>
                </AccountItem>
              }

              {accountContext?.[BLOCK_KEYS.ACCOUNT_TRAVEL] &&
                <AccountItem to={`${path}/trajets`}>
                  <AccountItemCircle className="accountItemCircle-trajets">
                    <LocationIconWrapper>
                      <LocationIcon color={theme.colors.white} />
                    </LocationIconWrapper>
                  </AccountItemCircle>

                  <AccountItemTitle>
                    Mes trajets
                  </AccountItemTitle>

                  <ArrowIconWrapper>
                    <ArrowRightIcon color={theme.colors.grey4} />
                  </ArrowIconWrapper>
                </AccountItem>
              }

              {/*<AccountItem to={`${path}/centre-aide`}>
                <AccountItemCircle className="accountItemCircle-help">
                  <HelpIconnWrapper>
                    <HelpIcon color={theme.colors.white}/>
                  </HelpIconnWrapper>
                </AccountItemCircle>

                <AccountItemTitle>
                  Centre d'aide
                </AccountItemTitle>

                <ArrowIconWrapper>
                  <ArrowRightIcon color={theme.colors.grey4}/>
                </ArrowIconWrapper>
              </AccountItem>*/}

              {accountContext?.[BLOCK_KEYS.ACCOUNT_DISCONNECT] &&
                <DisconnectModule />
              }
            </AccountList>
          </AccountWrapper>
        }
      </Container>
    </SidebarFrontBaseLayout>
  );

}

export default Account;
