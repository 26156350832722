import React from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';

import Button from '@material-ui/core/Button';
import { COLORS } from '../../../../constants/design';
import breakpoints from "../../../../../../theme-style/breakpoints";

export const StyledMainContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex: 0 0 auto;
  justify-content: ${props => props.isReceived ? 'flex-start' : 'flex-end'};
  align-content: center;
  align-items: flex-start;
  padding: 10px;
  @media ${breakpoints.mobileXL} {
    align-items: center;
    padding: 20px;
  }
  &:hover {
    .bot_reply_feedback {
      display: -webkit-box;
    }
  }
  strong {
    font-weight: 700;
  }
`;

export const StyledAvatar = styled.div`
  box-sizing: border-box;
  background-image: url(${props => props.image});
  content: "";
  width: 40px;
  height: 33px;
  border: 4px solid ${COLORS.LIGHT_GREY_2};
  border-radius: 100%;
  margin-right: 10px;
  -webkit-flex: 0 0 30px;
  flex: 0 0 30px;
  // background-color:rgba(0, 0, 0, 0);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70%;
  padding: 16px 16px;

  @media ${breakpoints.mobileXL} {
    padding: 20px 20px;
  }
`;

export const StyledMessageBubble = styled.div`
  box-sizing: border-box;
  &.relatedSubjects {
    max-width: 80%;
  }
  &.full-width {
    max-width: 90%;
  }
  color: ${props => props.isReceived ? COLORS.DARK_GREY : COLORS.WHITE};
  font-size: 0.8rem;
  font-family: 'Quicksand';
  font-weight: 500;
  width: calc(100% - 52px);
  word-wrap: break-word;
  max-width: 60%;
  margin: 0;
  padding: 9px 12px;
  position: relative;
  border-radius: ${props => props.isReceived ? '10px' : '16px'};
  border-top-left-radius: ${props => props.isReceived ? '5px' : '16px'};
  border-bottom-right-radius: ${props => props.isReceived ? '10px' : '5px'};
  background-color: ${props => props.isReceived ? COLORS.WHITE : COLORS.PRIMARY_BLUE};
  box-shadow: 1px 1px 3px 1px rgb(0 0 0 / 20%);

  @media ${breakpoints.mobileM} {
    width: auto;
  }

  @media ${breakpoints.mobileXL} {
    margin: 1vw 0vw;
    padding: 15px;
  }

  .loading-indicator {
    display: flex;
    align-items: center;
    height: 16.5px;
    & > div {
      width: 4px;
      border-radius: 2px;
      height: 100%;
      transition: all ease-in-out 0s;
      background: linear-gradient(0deg, #867CCF 0%, #DEA8E7 100%);
      animation-name: wave;
      animation-duration: 2s;
      animation-fill-mode: both;
      animation-direction: normal;
      animation-iteration-count: infinite;
      &:not(:last-child){
              margin-right: 3px;
      }
      &:nth-child(1) {
              animation-delay: 0s
      }
      &:nth-child(2) {
              animation-delay: 0.12s
      }
      &:nth-child(3) {
              animation-delay: 0.24s
      }
      &:nth-child(4) {
              animation-delay: 0.36s
      }
      &:nth-child(5) {
              animation-delay: 0.48s
      }
      &:nth-child(6) {
              animation-delay: 0.6s
      }
      &:nth-child(7) {
              animation-delay: 0.72s
      }
    }
  }
  @keyframes wave {
    0%, 40%, 100% {
        max-height: 50%;
      }  20% {
        max-height: 100%;
      }
  }
`;

export const StyledMessageText = styled.div`
  padding: 0px;
  @media ${breakpoints.mobileXL} {
    padding: 5px 0px;
  }
  ol, ul {
    padding-left: 1.5em;
    list-style-position: inside;
  }
  ul {
    list-style: disc;
  }
  ol {
    list-style: decimal;
  }
  em {
    font-style: revert;
  }
`;

export const StyledMessageButton = styled(({ ...rest }) => (
  <Button {...rest} />
))`
  && {
    &.relatedSubjectButton {
      &.MuiButton-root {
        background-color: ${COLORS.LIGHT_GREY_2};
        width: 49.25%;
        span {
          color: ${COLORS.DARK_GREY};
        }
        :nth-child(even) {
          margin-right: 1.5%;
        }
        &:hover {
          background-color: ${COLORS.PRIMARY_BLUE};
          span {
            color: ${COLORS.WHITE};
          }
        }
      }
    }
    &.MuiButton-root {
      color: ${COLORS.WHITE};
      position: relative;
      font-family: Quicksand;
      margin: 5px 0px;
      font-size: 0.75rem;
      font-weight: 500;
      background-color: ${props => props.disabled ? COLORS.LIGHT_GREY_2 : COLORS.PRIMARY_BLUE};
      width: 100%;
      padding: 10px 10px;
      transition: all ease-in .15s;
      border: transparent;
      border-radius: 5px;
      background-image: none;
      text-transform: none;
      &:hover {
        background-color: ${COLORS.PRIMARY_DEEP_BLUE};
      }

      .MuiButton-label{
        line-height: 1.4;
      }
    }
  }
`;

export const StyledFeedbackContainer = styled.div`
  width: auto;
  max-width: 90%;
  margin-top: -0.5%;
  position: absolute;
  right: 0px;
  display: -webkit-box;
`;

export const StyledFeedbackIcon = styled.div`
  box-sizing: border-box;
  width: 40px;
  transition: all ease-in .15s;
  touch-action: manipulation;
  cursor: pointer;
  border: 2px solid ${COLORS.LIGHT_GREY_1};
  padding: 3px;
  padding-top: 6px;
  background-color: ${props => props.isResponded ? props.hoverColor : COLORS.WHITE};
  font-size: 1.1em;
  border-radius: 30px;
  border-bottom-left-radius: 5px;
  margin-left: 10px;
  text-align: center;
  &:hover {
    background-color: ${props => props.hoverColor};
  }
  &.messageEditIcon {
    width: 80px;
  }
`;

export const StyledShowRelatedSubjectsContainer = styled.div`
  display: flex;
  cursor: pointer;
  padding: 10px;
  margin-left: 10px;
`;

export const StyledShowRelatedSubjectsIcon = styled(IconButton)`
	&&{
		height: fit-content;
    &.MuiIconButton-root {
      padding: 10px;
      background-color: ${COLORS.LIGHT_GREY_2};
      .MuiSvgIcon-root {
        font-size: 1rem;
      }
    }
	}
`;


export const StyledShowRelatedSubjectsText = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 500;
  color: ${COLORS.DARK_GREY};
  padding: 10px;
`;