import styled from "styled-components";
import {
  Link,
} from "react-router-dom";

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const HeadList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
`;

export const Title = styled.h3`
  margin: 0;
  color: #5A5A5A;
  font-size: 15px;
  line-height: 16px;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const Indicator = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  min-width: 18px;
  height: 18px;
  min-height: 18px;
  background-color: #EC6B87;
  border-radius: 50px;
  color: #ffffff;
  font-size: 10px;
  line-height: 18px;
  font-weight: 700;
`;

export const BodyList = styled.ul`
  margin: 0;
  padding: 0;
`;

export const EditInfoIconWrapper = styled.div`
  position: absolute;
  display: inline-flex;
  align-items: center;
  width: 11px;
  top: 13px;
  right: 6px;
  opacity: 0;
  transition: all .2s ease-out;
`;

export const BodyListItem = styled(Link)`
  text-decoration: none;
  position: relative;
  margin: 0 -12px;
  padding: 6px 12px;
  list-style: none;
  border-radius: 6px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  &:hover{
    background-color: #F7FBFF;
    h4{
      background-color: #2768B3;
    }
    h5{
      background-color: #357ED3;
      color: #ffffff;
    }
    span{
      color: #4A90E2;
      ${EditInfoIconWrapper}{
        right: 12px;
        opacity: 1;
      }
    }
  }
  h4{
    color: #ffffff;
    font-size: 11px;
    line-height: 8px;
    font-weight: 700;
    padding: 8px;
    border-radius: 4px;
    background-color: #357ED3;
    margin: 0 6px 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: all .2s ease-out;
  }
  h5{
    color: #999999;
    font-size: 11px;
    line-height: 8px;
    font-weight: 700;
    padding: 8px;
    border-radius: 4px;
    background-color: #ededed;
    margin: 0 10px 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: all .2s ease-out;
  }
  span{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #5A5A5A;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin: 0 auto 0 0;
    transition: all .2s ease-out;
  }
`;

export const FooterList = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
  padding: 20px 0;
`;

export const EmptyList = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #F7FBFF;
  border-radius: 6px;
  padding: 20px;
`;

export const Text = styled.p`
  margin: 0 0 20px 0;
  color: #5A5A5A;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  text-align: center;
`;
