import {
  all,
  put,
  takeEvery,
  call,
  delay,
  select,
} from "redux-saga/effects";
import axios from 'axios';

import endpoint from '../../helpers/api';
import { toaster } from '../../helpers/toaster';

/** Actions */
import * as a from './actions';
import * as collaboratorActions from '../collaborator/actions';

import * as authenticationSelectors from '../authentication/selectors';

import { getCollaboratorsList } from '../adminFront/actions';

async function loginAPICall(data) {
  return fetch(endpoint.login, {
    method: 'POST',
    body: data
  })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      }
      return res.status;
    })
    .then(data => data)
    .catch(err => err);
}

async function resetPasswordEmailAPICall(data) {
  return fetch(endpoint.getNewPassword, {
    method: 'POST',
    body: data
  })
    .then(res => res)
    .catch(err => err);
}

function* loginSaga({ username, password }) {
  try {
    const formData = new FormData();
    formData.append('_username', username);
    formData.append('_password', password);
    const userRes = yield call(loginAPICall, formData);
    if (userRes?.token) {
      yield put(a.loginSuccess(userRes.token));
      yield call([localStorage, 'setItem'], 'id_token', JSON.stringify(userRes.token));
      yield put(collaboratorActions.getUser());
      yield delay(100);
      yield put(a.loginReset());
    } else {
      yield put(a.loginError({ status: userRes }));
      yield delay(100);
      yield put(a.loginReset());
    }
  } catch (error) {
    yield put(a.loginError(error));
    yield delay(100);
    yield put(a.loginReset());
  }
}

function* sendEmailResetPasswordSaga({ username, resetPasswordUrl }) {
  try {
    const formData = new FormData();
    formData.append('email', username);
    formData.append('resetPasswordUrl', resetPasswordUrl);
    const emailSent = yield call(resetPasswordEmailAPICall, formData);
    if (emailSent?.status === 200) {
      yield put(a.sendEmailResetPasswordSuccess());
    } else {
      yield put(a.sendEmailResetPasswordError({ status: emailSent?.status || 400 }));
    }
  } catch (error) {
    yield put(a.sendEmailResetPasswordError(error));
  }
}

async function registerAPICall(endpointURL, data) {
  return fetch(endpointURL, {
    method: 'POST',
    body: data
  })
    .then(res => res.json())
    .then(data => data)
    .catch(err => err);
}


function* registerSaga({ email, firstname, lastname, acceptPtdcp }) {
  try {
    const passwordUrl = `${window.location.origin}/login/reset`;
    const formData = new FormData();
    formData.append('email', email);
    formData.append("firstname", firstname);
    formData.append('lastname', lastname);
    formData.append('acceptPtdcp', acceptPtdcp);
    formData.append("resetPasswordUrl", passwordUrl);
    const userRegisterRes = yield call(registerAPICall, endpoint.register, formData);
    if (userRegisterRes?.status === 400 || userRegisterRes?.code === 400) {
      yield put(a.registerError(userRegisterRes));
      yield delay(100);
      yield put(a.registerReset());
    } else {
      yield put(a.registerSuccess());
      yield delay(100);
      yield put(a.registerReset());
    }
  } catch (error) {
    yield put(a.registerError(error));
    yield delay(100);
    yield put(a.registerReset());
  }
}

async function createCollaboratorService(endpoint, userToken, formData) {
  const passwordUrl = `${window.location.origin}/login/reset`;
  const newForm = new FormData();
  newForm.append('email', formData.email);
  newForm.append("firstname", formData.firstname);
  newForm.append('lastname', formData.lastname);
  newForm.append('acceptPtdcp', formData.acceptPtdcp);
  newForm.append("companySiteId", formData.companySiteId);
  newForm.append("resetPasswordUrl", passwordUrl);

  const headers = {};
  if (userToken) {
    headers.Authorization = `Bearer ${userToken}`;
  }

  return axios.request({
    method: 'post',
    url: endpoint,
    data: newForm,
    headers: headers
  })
}

function* createCollaboratorSaga(action) {
  const formData = action.data;
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const response = yield call(createCollaboratorService, endpoint.newCollaborator, userToken, formData);

    if (response?.data?.status === 400) {
      yield put(a.createCollaboratorError(response));
      let errorMsg = `Une erreur s'est produite. Veuillez réessayer`;
      if (response?.data?.message === 'User already used.') {
        errorMsg = `L'email du collaborateur existe déjà`;
      }
      else if (response?.data?.message === 'User mail is not valid.') {
        errorMsg = `L'email n'est pas valide pour cette entreprise`;
      }
      toaster(errorMsg, 'error');
    }
    else {
      yield put(a.createCollaboratorSuccess());
      toaster('Le collaborateur est bien créé', 'success');
    }
  } catch (err) {
    yield put(a.createCollaboratorError(err));
    toaster(`Une erreur s'est produite. Veuillez réessayer`, 'error');
  }
}

function* logoutSaga() {
  try {
    console.log('LOGOUT')
    // yield call([localStorage, localStorage.clear]);
    yield call([localStorage, 'removeItem'], 'id_token');
    yield call([localStorage, 'removeItem'], 'user');
    yield put(collaboratorActions.clearData());
    yield put(a.logoutSuccess());
  } catch (error) {

  }
}

function* updatePasswordSaga({ password, confirmPassword, token, isRegister }) {
  try {
    const formData = new FormData();
    formData.append('newPassword', password);
    formData.append('newPasswordRepeated', confirmPassword);
    formData.append('confirmationToken', token);
    if (isRegister) {
      formData.append('register', true);
    }
    const updateRes = yield call(registerAPICall, endpoint.submitNewPassword, formData);
    // console.log('UPDATE RES', updateRes);
    if (updateRes?.token) {
      // localStorage.setItem('id_token', updateRes.token);
      yield call([localStorage, 'setItem'], 'id_token', JSON.stringify(updateRes.token));
      yield put(a.updatePasswordSuccess(updateRes.token));
      yield put(collaboratorActions.updateUserLocalInfos(updateRes));
      yield delay(100);
      yield put(a.resetUpdatePassword());
      yield put(collaboratorActions.getUser());
    } else {
      yield put(a.updatePasswordError(updateRes));
      yield delay(100);
      yield put(a.resetUpdatePassword());
    }
  } catch (error) {
    yield put(a.updatePasswordError(error));
    yield delay(100);
    yield put(a.resetUpdatePassword());
  }
}

export default function* authenticationRootSaga() {
  yield all([
    takeEvery(a.LOGIN.REQUEST, loginSaga),
    takeEvery(a.SEND_EMAIL_RESET_PASSWORD.REQUEST, sendEmailResetPasswordSaga),
    takeEvery(a.REGISTER.REQUEST, registerSaga),
    takeEvery(a.CREATE_COLLABORATOR.REQUEST, createCollaboratorSaga),
    takeEvery(a.LOGOUT.REQUEST, logoutSaga),
    takeEvery(a.UPDATE_PASSWORD.REQUEST, updatePasswordSaga),
  ]);
}
