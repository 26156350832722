import React from 'react';
import {
  useRouteMatch,
} from "react-router-dom";

// Layout
import DashboardBaseLayout from "../../layouts/dashboardBaseLayout";

// Components
import Header from './components/header';
import FeedbackUntreatedStats from './components/feedbackUntreatedStats';
import FeedbackUntreatedList from './components/feedbackUntreatedList';
import InfoNoActiveList from './components/infoNoActiveList';
import InfoActiveStats from './components/infoActiveStats';
import GlobalResponseStats from './components/globalResponseStats';
import InfoMostRequestList from './components/infoMostRequestList';

// Styles
import {
  Container,
  ColumnWrapper,
  ColumnWrapperFeedback,
  ColumnWrapperInfoElement,
  ColumnWrapperActivity
} from "./style";

const Dashboard = () => {
  const { path } = useRouteMatch();

  return (
    <DashboardBaseLayout
      id="dashboardLayout"
      className="dashboardLayout"
    >
      <Container>
        <Header />

        <ColumnWrapper>
          <ColumnWrapperFeedback>
            <FeedbackUntreatedStats />
            <FeedbackUntreatedList />
          </ColumnWrapperFeedback>

          <ColumnWrapperInfoElement>
            <InfoActiveStats />
            <InfoNoActiveList />
          </ColumnWrapperInfoElement>

          {/* <ColumnWrapperActivity>
            <GlobalResponseStats />
            <InfoMostRequestList />
          </ColumnWrapperActivity> */}
        </ColumnWrapper>

      </Container>
    </DashboardBaseLayout>
  );
}

export default Dashboard;
