import React, { useEffect, useState } from 'react';
import {
  shallowEqual,
  useDispatch,
  useSelector,
} from "react-redux";

import SkillDomains from '../SkillDomains';

import {
  getExpertiseAreas,
} from '../../../../../store/sidebar/actions';

import {
  getExpertiseAreasData
} from '../../../../../store/sidebar/selectors';

import {
  MainContainer,
  StyledTitleWithToolTip,
} from './style';

const ConciergeModule = () => {
  const dispatch = useDispatch();
  const [selectedExpertise, setSelectedExpertise] = useState(null);

  const {
    expertiseAreas,
  } = useSelector(
    state => ({
      expertiseAreas: getExpertiseAreasData(state),
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!expertiseAreas) {
      dispatch(getExpertiseAreas());
    }
  }, []);

  useEffect(() => {
    if (expertiseAreas) {
      const expertise = expertiseAreas.find((el) => el.title === "Conciergerie")
      setSelectedExpertise(expertise);
    }
  }, [expertiseAreas]);

  return (
    <MainContainer>
      <StyledTitleWithToolTip
        title={"Ma conciergerie"}
        toolTipText={"Découvrez votre conciergerie connectée ! Cliquez sur le sujet pour mieux la connaitre 🙂"}
      />
      <SkillDomains title={selectedExpertise?.title || "Conciergerie"} skills={selectedExpertise?.skills} type='Conciergerie' />
    </MainContainer>
  );
};

export default ConciergeModule;