import React from "react";
import Lottie from "lottie-react-web";

// Lottie components
import loaderAnimationData from "./loader.json";

// Style
import { Container, Title } from "./style";

const LoaderLottie = () => {
  return (
    <Container className="lottie-loader">
      <Lottie
        options={{
          animationData: loaderAnimationData,
        }}
      />
      <Title>Cyconia</Title>
    </Container>
  );
};

export default LoaderLottie;
