import styled from "styled-components";
import Button from '@material-ui/core/Button';
import { COLORS } from "../../constants/design";

export const MainContainer = styled.div`
  box-sizing: border-box;
  background-color: ${COLORS.LIGHT_BLUE_1};
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Quicksand;
`;

export const StyledNotFoundImage = styled.img`
  width: 500px;
  @media screen and (max-width: 768px){
    width: 300px;
  }
`;

export const StyledNotFoundMessage = styled.div`
  /* border: 1px solid black; */
  margin: 15px;
  color: ${COLORS.DARK_GREY};
  font-size: 1.2rem;
  width: 50%;
  padding: 0px 10px;
  text-align: center;
  @media screen and (max-width: 768px){
    width: 70%;
    font-size: 1rem;
  }
`;

export const StyledButton = styled(Button)`
  && {
    &.MuiButton-root {
      color: ${COLORS.WHITE};
      background-color: ${COLORS.PRIMARY_BLUE};
      padding: 10px 20px;
      margin: 15px;
      font-size: 1rem;
      font-weight: 300;
      text-transform: none;
    }
  }
`;