import React, { useEffect, useState, useContext } from 'react';
import {
  shallowEqual,
  useDispatch,
  useSelector,
} from "react-redux";
import {
  Link,
  // useRouteMatch
} from "react-router-dom";

// Import services
// import getInactiveInfoElement from '../../services/getInactiveInfoEl.service';

// Components
import ButtonCpt from '../../../../components/molecules/buttonCpt';
import EditInfoIcon from '../../../../components/atoms/icons/editInfo';

// Store
import {
    getInactiveInfoElementDataByCode,
} from '../../../../../../store/adminFront/selectors';
import {
  getInactiveInfoElement
} from '../../../../../../store/adminFront/actions';

// Styles
import {
  Container,
  HeadList,
  Title,
  Indicator,
  BodyList,
  BodyListItem,
  FooterList,
  EmptyList,
  Text,
  EditInfoIconWrapper
} from "./style";

// Import context
// import { Context as EditorContext } from '../../../../context/editorState';

const InfoNoActiveList = () => {
  const dispatch = useDispatch();
  const {
    inactiveInfoElement,
  } = useSelector(
    state => ({
      inactiveInfoElement: getInactiveInfoElementDataByCode(state, ['Ressources Humaines', 'Services Généraux']),
    }),
    shallowEqual
  );

  useEffect(() => {
      dispatch(getInactiveInfoElement());
  }, []);

  return (
    <Container>
      <HeadList>
        <Title>
          Les sujets non activés
        </Title>
        <Indicator>
          {inactiveInfoElement?.length}
        </Indicator>
      </HeadList>

      {!!inactiveInfoElement?.length ?
        <React.Fragment>
          <BodyList>
            {inactiveInfoElement.map((item, index) => {
              const {
                expertiseArea,
                subject,
                ei,
                eiId
              } = item;

              return (
                <BodyListItem
                  to={"/admin/dashboard?opendrawerbyid=" + eiId}
                  key={`key-inactiveInfoElement-${index}`}>
                  <h4>{expertiseArea}</h4>
                  <h5>{subject}</h5>
                  <span>
                    {ei}
                    <EditInfoIconWrapper>
                      <EditInfoIcon />
                    </EditInfoIconWrapper>
                  </span>
                  {/*<h4>Ressources Humaines</h4>
                  <h5>Paie</h5>
                  <span>
                    Versement de la Paie
                    <EditInfoIconWrapper>
                      <EditInfoIcon />
                    </EditInfoIconWrapper>
                  </span>*/}
                </BodyListItem>
              )
            })}
            {/*<BodyListItem>
              <h4>Ressources Humaines</h4>
              <h5>Paie</h5>
              <span>Versement de la Paie</span>
            </BodyListItem>*/}
          </BodyList>
          <FooterList>
            <ButtonCpt
              label="Accédez à la liste de mes compétences"
              appearence="lightBlue"
              component={Link}
              to="/admin/training/1"
            />
          </FooterList>
        </React.Fragment>
        :
        <EmptyList>
          <Text>
            Toutes les sujets sont activés.
          </Text>
          <ButtonCpt
            label="Accédez à la liste de mes compétences"
            appearence="darkBlue"
            component={Link}
            to="/admin/training/1"
          />
        </EmptyList>
      }
    </Container>
  );
}

export default InfoNoActiveList;
