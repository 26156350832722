import React from "react";

const CalendarIcon = ({
  color = "#2B2B2B",
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon calendarIcon"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M12.063 0c.345 0 .624.28.624.625v.625h.813c1.379 0 2.5 1.122 2.5 2.5v3.563a.625.625 0 11-1.25 0V3.75c0-.69-.56-1.25-1.25-1.25h-.813v.625a.625.625 0 11-1.25 0V2.5H8.595v.625a.625.625 0 11-1.25 0V2.5H4.53v.625a.625.625 0 11-1.25 0V2.5H2.5c-.69 0-1.25.56-1.25 1.25v9.75c0 .69.56 1.25 1.25 1.25h4.781a.625.625 0 110 1.25H2.5A2.503 2.503 0 010 13.5V3.75c0-1.378 1.121-2.5 2.5-2.5h.781V.625a.625.625 0 111.25 0v.625h2.813V.625a.625.625 0 111.25 0v.625h2.844V.625c0-.345.28-.625.624-.625zm.156 8.438A3.786 3.786 0 0116 12.217 3.786 3.786 0 0112.219 16a3.786 3.786 0 01-3.781-3.781 3.786 3.786 0 013.78-3.781zm0 1.25a2.534 2.534 0 00-2.531 2.53 2.534 2.534 0 002.53 2.532 2.534 2.534 0 002.532-2.531 2.534 2.534 0 00-2.531-2.531zm0 .624c.345 0 .625.28.625.626v.656h.281a.625.625 0 110 1.25h-.906a.625.625 0 01-.625-.625v-1.281c0-.346.28-.626.625-.626zm-8.313 1.063a.625.625 0 110 1.25.625.625 0 010-1.25zm2.719 0a.625.625 0 110 1.25.625.625 0 010-1.25zm0-2.719a.625.625 0 110 1.25.625.625 0 010-1.25zm-2.719 0a.625.625 0 110 1.25.625.625 0 010-1.25zm8.156-2.719a.625.625 0 110 1.25.625.625 0 010-1.25zm-2.718 0a.625.625 0 110 1.25.625.625 0 010-1.25zm-5.438 0a.625.625 0 110 1.25.625.625 0 010-1.25zm2.719 0a.625.625 0 110 1.25.625.625 0 010-1.25z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export default CalendarIcon;
