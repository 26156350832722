import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";

import Routes from './routes';
import GenericLayout from '../../layouts/genericLayout';

import { getSkillsDomainsListDataByCode } from '../../../../store/adminFront/selectors';

const KbConciergerie = () => {
  const [dmId, setDmId] = useState(null);
  const {
    skillsDomainsList
  } = useSelector(
    state => ({
      skillsDomainsList: getSkillsDomainsListDataByCode(state, ['RH', 'SG']),
    }),
    shallowEqual
  );

  useEffect(() => {
    if (skillsDomainsList) {
      setDmId(skillsDomainsList[0].skills[0]?.id);
    }
  }, [skillsDomainsList]);

  return (
    <GenericLayout
      id="trainingCycoLayout"
      className="trainingCycoLayout"
    >
      <Routes key={dmId} skillsDomainsList={skillsDomainsList}/> 
      {/* the key is needed to refresh the routes, do not delete until a better way is found */}
    </GenericLayout>
  )
}

export default KbConciergerie;