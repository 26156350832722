import React, { useEffect, useState, useRef, } from 'react';
import {
  // NavLink,
  useRouteMatch,
} from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import _ from 'lodash';

import {
  isUserAdmin as isUserAdminSelector,
} from '../../../../../store/authentication/selectors';

import {
  getContextSetting
} from '../../../../../store/company/selectors';

import {
  isUserHaveConciergeRole,
} from '../../../../../store/config/selectors';

import { NAV_BAR_KEYS } from '../../../../../constants/sideBarConstants';

// Components
// import ButtonCpt from '../../molecules/buttonCpt';
import DashboardIcon from '../../atoms/icons/dashboard';
import ConciergerieIcon from '../../atoms/icons/conciergerie';
import CompetencesIcon from '../../atoms/icons/competences';
import AccountIcon from '../../atoms/icons/account';
import AdminIcon from '../../atoms/icons/admin';

// Hooks
// import useTheme from "../../../theme-style/useThemeCustomHook";

// Styles
import {
  Container,
  NavLinkCustom,
  NavLinkTitle,
  NavLinkCircle,
  DashboardIconWrapper,
  ConciergerieIconWrapper,
  CompetencesIconWrapper,
  AccountIconWrapper,
  AdminIconWrapper,
} from "./style";

const Navigation = () => {
  // const theme = useTheme();
  const match = useRouteMatch();
  // console.log('match', match);
  const [navItems, setNavItems] = useState(0);
  const wrapperRef = useRef(null);

  useEffect(() => {
    const innerElements = wrapperRef.current.innerHTML;
    let count = (innerElements.match(/<div/g) || []).length;
    setNavItems(count);
  }, []);

  const {
    isUserAdmin,
    isUserConcierge,
    contextSetting
  } = useSelector(
    state => ({
      isUserAdmin: isUserAdminSelector(state),
      isUserConcierge: isUserHaveConciergeRole(state),
      contextSetting: getContextSetting(state),
    }),
    shallowEqual
  );

  return (
    <Container ref={wrapperRef} numberItems={navItems}>
      {contextSetting?.[NAV_BAR_KEYS.DASHBOARD] && (
        <NavLinkCustom
          to="/dashboard"
          className={`navlink-dashboard${(match.path === '/dashboard' || match.path === '/dashboard/') ? ' active' : ''}`}
        >
          <NavLinkCircle>
            <DashboardIconWrapper>
              <DashboardIcon />
            </DashboardIconWrapper>
          </NavLinkCircle>
          <NavLinkTitle>
            Tableau de bord
          </NavLinkTitle>
        </NavLinkCustom>
      )}

      {contextSetting?.[NAV_BAR_KEYS.CONCIERGE] && (
        <NavLinkCustom
          to="/conciergerie"
          activeClassName="active"
          className="navlink-conciergerie"
        >
          <NavLinkCircle>
            <ConciergerieIconWrapper>
              <ConciergerieIcon />
            </ConciergerieIconWrapper>
          </NavLinkCircle>
          <NavLinkTitle>
            Ma conciergerie
          </NavLinkTitle>
        </NavLinkCustom>
      )}

      {contextSetting?.[NAV_BAR_KEYS.SKILL] && (
        <NavLinkCustom
          to="/competences"
          activeClassName="active"
          className="navlink-skills"
        >
          <NavLinkCircle>
            <CompetencesIconWrapper>
              <CompetencesIcon />
            </CompetencesIconWrapper>
          </NavLinkCircle>
          <NavLinkTitle>
            Compétences
          </NavLinkTitle>
        </NavLinkCustom>
      )}

      {contextSetting?.[NAV_BAR_KEYS.ACCOUNT] && (
        <NavLinkCustom
          to="/compte"
          activeClassName="active"
          className="navlink-account"
        >
          <NavLinkCircle>
            <AccountIconWrapper>
              <AccountIcon />
            </AccountIconWrapper>
          </NavLinkCircle>
          <NavLinkTitle>
            Mon compte
          </NavLinkTitle>
        </NavLinkCustom>
      )}

      {(isUserAdmin || isUserConcierge) &&
        <NavLinkCustom to="/my-admin" className="navlink-admin">
          <NavLinkCircle>
            <AdminIconWrapper>
              <AdminIcon />
            </AdminIconWrapper>
          </NavLinkCircle>
          <NavLinkTitle>
            Ma console
          </NavLinkTitle>
        </NavLinkCustom>
      }

    </Container>
  );
}

export default Navigation;
