import { call, put, select } from 'redux-saga/effects'
import {
  getServicesActifsError,
  getServicesActifsSuccess,
  editServiceActifSuccess,
  editServiceActifError
} from '../actions';
import endpoint from '../../../helpers/api';
import apiCall from '../../apiCall';
import * as authenticationSelectors from '../../authentication/selectors';
import { editServiceActifService } from '../../../services/serviceActifService';
import { toaster } from '../../../helpers/toaster';

export function* getServiceActifsHandler() {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const activeServices = yield call(apiCall, endpoint.getActifeServices, userToken);
    yield put(getServicesActifsSuccess(activeServices));
  } catch (err) {
    yield put(getServicesActifsError(err));
  }
}

export function* editServiceActifHandler(action) {
  const { id, formData } = action.data;
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const endPointUrl = id !== 'new' ? endpoint.editActiveService(id) : endpoint.saveActiveService;
    const response = yield call(editServiceActifService, endPointUrl, userToken, formData);
    yield put(editServiceActifSuccess(response.data));
    toaster('Vos changements ont été enregistrés', 'success');
  } catch (err) {
    yield put(editServiceActifError(err));
    toaster(`Une erreur s'est produite. Veuillez réessayer`, 'error');
  }
}