import styled from "styled-components";
import { Calendar } from 'react-big-calendar';

import { COLORS } from "../../../../constants/design";

export const MainContainer = styled.div`
  overflow: auto;
  margin-bottom: 20px;
  min-height: 85vh;
  /* border: 1px solid black; */
  display: flex;
  /* justify-content: center; */
  align-items: center;
`;

export const StyledCalendar = styled(Calendar)`
  /* border: 1px solid black; */
  height: 85vh;
  width: 100%;
  /* width: fit-content; */
  .rbc-toolbar {
    .rbc-toolbar-label {
      text-transform: capitalize;
      font-weight: 600;
      font-size: 1.1rem;
    }
    button {
      font-size: 0.85rem;
      padding: 10px 20px;
      cursor: pointer;
      border: 1px solid;
      border-color: ${COLORS.WHITE};
      background-color: ${COLORS.LIGHT_GREY_1};
      color: ${COLORS.DARK_GREY};
    }
    button:focus {
      background-color: ${COLORS.PRIMARY_BLUE};
      color: ${COLORS.WHITE};
      border-color: ${COLORS.WHITE};
    }
    button:active,
    button.rbc-active {
      background-color: ${COLORS.PRIMARY_BLUE};
      color: ${COLORS.WHITE};
      border-color: ${COLORS.WHITE};
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0);
    }
    button:active:hover,
    button:active:focus,
    button.rbc-active:hover,
    button.rbc-active:focus {
      background-color: ${COLORS.PRIMARY_BLUE};
      color: ${COLORS.WHITE};
      border-color: ${COLORS.WHITE};
    }
    .rbc-btn-group > button:first-child:not(:last-child) {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    .rbc-btn-group > button:last-child:not(:first-child) {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
  .rbc-agenda-view table.rbc-agenda-table thead > tr > th { // agenda header
    display: table-cell;
    padding: 11px;
  }
  .rbc-header { // all other view header
    font-size: 0.8rem;
    padding: 12px;
    display: table-cell;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    a {
      padding: 10px;
    }
  }
  .rbc-row-segment { // for the event slot container
    padding: 2px 5px 0px 5px;
  }
  .rbc-event, .rbc-day-slot .rbc-background-event { // for the event slot
    padding: 5px 10px;
    font-size: 0.8rem;
    background-color: ${COLORS.PRIMARY_BLUE};
    color: ${COLORS.WHITE};
    border-color: ${COLORS.WHITE};
  }
  .rbc-event.rbc-selected, .rbc-day-slot .rbc-selected.rbc-background-event {
    background-color: ${COLORS.PRIMARY_DEEP_BLUE};
  }
  .rbc-event:focus, .rbc-day-slot .rbc-background-event:focus {
    outline: none;
  }
  .rbc-show-more {
    font-weight: 500;
    text-decoration: none;
    font-size: 0.7rem;
    padding: 5px 5px;
    color: ${COLORS.DARK_GREY};
  }
  .rbc-date-cell {
    padding: 5px 10px 0px 0px;
    display: flex;
    justify-content: flex-end;
    a {
      height: 25px;
      width: 25px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      :hover {
        background-color: ${COLORS.PRIMARY_BLUE};
        color: ${COLORS.WHITE};
      }
    }
  }
  .rbc-today {
    background-color: transparent;
  }
  .rbc-off-range-bg {
    background-color: ${COLORS.LIGHT_GREY_1};
  }
  .rbc-off-range {
    a {
      :hover {
        background-color: ${COLORS.LIGHT_GREY_2};
        color: ${COLORS.DARK_GREY};
      }
    }
  }
  .rbc-current-time-indicator { // className for current-time-indicator
    height: 1.5px;
    background-color: ${COLORS.RED_4};
  }
  .otherCompanyEventWrapper { // custom className for different event wrapper styling
    background-color: ${COLORS.LIGHT_GREY_4};
  }
  .otherCompanyEventWrapperSelected.rbc-event.rbc-selected {
    background-color: ${COLORS.DARK_GREY};
  }
  .rbc-event-label {
    margin-bottom: 2px;
  }
  .rbc-time-header {
    margin-right: 0px !important;
  }
  .rbc-events-container {
    margin-right: 0px;
  }
  /* .rbc-time-header-content{
    min-width: 251px;
  }
  .rbc-day-slot {
    min-width: 250px;
  } */
`;
