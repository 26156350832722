export const MESSENGER_TYPE = {
  CLIENT: 'client',
  CONCIERGE: 'concierge',
}

export const MESSAGE_TYPE = {
  CLIENT: 'client',
  CONCIERGE: 'concierge',
  NOTE: 'note',
  SYSTEM: 'system',
  SYSTEM_CLIENT: 'system-client'
}

export const MESSAGE_DELETE_TIME_LIMIT = 3;

export const macrosTemplate = [
  {
    label: "Proposition PEC",
    value:
      `<p>Bonjour [name],</p><p><br></p>
      <p>Voici notre proposition pour votre demande n° [order_number].</p><p><br></p>
      <p>Si celle-ci vous convient, vous pouvez dès à présent procéder au règlement au lien suivant : [lien de paiement].</p><p><br></p>
      <p>À bientôt !</p>`,
  },
  {
    label: "Demande de précisions",
    value:
      `<p>Bonjour [name],</p><p><br></p>
      <p>Merci pour votre demande.</p><p><br></p>
      <p>Il nous faudrait quelques informations complémentaires pour y répondre au mieux.</p><p><br></p>
      <p>Pourriez-vous nous indiquer [Indiquer ici le type d'informations manquantes]...</p><p><br></p>
      <p>Merci de votre confiance et à bientôt !<p>`,
  },
  {
    label: "Relance",
    value:
      `<p>Bonjour [name],</p><p><br></p>
      <p>Votre commande n° [order_number] attend toujours votre confirmation.</p><p><br></p>
      <p>Si vous souhaitez apporter des changements à notre proposition, je reste à votre disposition pour en discuter.</p><p><br></p>
      <p>Merci de votre confiance et à bientôt !</p>`,
  },
  {
    label: "Paiement",
    value:
      `<p>Bonjour [name],</p><p><br></p>
      <p>Pour procéder au règlement de votre commande n° [order_number], veuillez cliquer sur le lien suivant [lien de paiement].</p><p><br></p>
      <p>Merci de votre confiance et à bientôt !</p>`,
  },
  {
    label: "Message de suspension de demande",
    value:
      `<p>Bonjour [name],</p><p><br></p>
      <p>N’ayant pas eu de retour de votre part au sujet de votre commande n°[order_number], nous l’avons suspendue.</p><p><br></p>
      <p>À tout moment, vous pouvez rouvrir votre commande en l’indiquant ici.</p><p><br></p>
      <p>Merci de votre confiance et à bientôt !</p>`,
  },
  {
    label: "Message de satisfaction client",
    value:
      `<p>Bonjour [name],</p><p><br></p>
      <p>Comment s'est passée votre expérience ? Votre réponse nous aide à améliorer notre service de conciergerie.</p><p><br></p>
      <p>Exprimez-vous <a href="https://form.jotform.com/222904539724359" target={"_blank"} rel={"noreferrer"}>ici</a></p><p><br></p>
      <p>Merci de votre confiance et à bientôt !</p>`,
  },
  {
    label: "RECEPTION DE COLIS : Confirmation de disponibilité à la conciergerie",
    value:
      `<p>Bonjour [name],</p><p><br></p>
      <p>J’ai le plaisir de vous informer que votre colis est disponible dès aujourd’hui à la Conciergerie.</p><p><br></p>
      <p>Je vous invite à venir le récupérer lors des heures de permanences.</p><p><br></p>
      <p>Pour rappel, votre Conciergerie est ouverte : [compléter avec jours et heures].</p><p><br></p>
      <p>Restant à votre disposition, je vous souhaite une belle journée</p>`,
  },
  {
    label: "PRC : Confirmation de disponibilité à la conciergerie",
    value:
      `<p>Bonjour [name],</p><p><br></p>
      <p>J’ai le plaisir de vous informer que vos articles sont disponibles dès aujourd’hui à la Conciergerie.</p><p><br></p>
      <p>Je vous invitons à venir les récupérer lors des heures de permanences.</p><p><br></p>
      <p>Pour rappel, votre Conciergerie est ouverte : [compléter avec jours et heures].</p><p><br></p>
      <p>Restant à votre disposition, je vous souhaite une belle journée.</p>`,
  },
  {
    label: "Confirmation de rendez-vous LAVAGE AUTO",
    value:
      `<p>Bonjour [name],</p><p><br></p>
      <p>J’ai le plaisir de confirmer votre rendez-vous de [compléter avec nom du service] le [compléter date, heure et lieu].</p><p><br></p>
      <p>Vous devrez déposer votre véhicule [compléter avec emplacement du parking] et déposer vos clés de voiture à l’accueil du site.</p><p><br></p>
      <p>Le règlement de la prestation se fera directement auprès du partenaire, soit en CB, chèque ou espèces.</p><p><br></p>
      <p>Au besoin notre partenaire pourra vous joindre au [compléter avec numéro de tel] pour la restitution de vos clés ou celui-ci les remettra aux hôtesses.</p><p><br></p>
      <p>Merci de votre confiance et à bientôt !</p>`,
  },
  {
    label: "Confirmation de rendez-vous BIEN-ETRE",
    value:
      `<p>Bonjour [name],</p><p><br></p>
      <p>J’ai le plaisir de vous confirmer votre rendez-vous de [spécifier la prestation], le [date et heure et lieu].</p><p><br></p>
      <p>La prestation est à régler le jour J au partenaire par chèque, espèce ou carte bancaire.</p><p><br></p>
      <p>Au besoin, l’esthéticienne pourra vous contacter au [compléter avec numéro de tel].</p><p><br></p>
      <p>Restant à votre disposition, je vous souhaite un agréable moment détente et une belle journée.</p>`,
  },
  {
    label: "Panier bio : Confirmation de disponibilité à la conciergerie",
    value:
    `<p>Bonjour [name],</p><p><br></p>
    <p>J’ai le plaisir de vous informer que votre panier de fruits & légumes est disponible dès maintenant au comptoir de votre conciergerie.</p><p><br></p>
    <p>Je vous invite à venir le récupérer lors des heures de permanences.</p><p><br></p>
    <p>Pour rappel, votre Conciergerie est ouverte : [compléter avec jours et heures].</p><p><br></p>
    <p>Je vous remercie de votre confiance et vous souhaite une belle journée.</p>`,
  },
  {
    label: "SERVICE A DOMICILE PEC 02",
    value:
    `<p>Bonjour [name],</p><p><br></p>
    <p>Notre partenaire O2 vous contactera [indiquer jour] entre [compléter avec heures] au [compléter avec numéro de téléphone] selon votre souhait.</p><p><br></p>
    <p>Je vous remercie de votre confiance et vous souhaite une belle journée.</p>`,
  },
  {
    label: "PET SITTING ANIMAUX",
    value:
    `<p>Bonjour [name],</p><p><br></p>
    <p>J’ai le plaisir de vous proposer les pet-sitter suivants qui sont référencés par notre partenaire [nom du partenaire]</p><p><br></p>
    <p>[Compléter avec Prénom] dont le tarif est de ..€ euros/visite/ journée à votre domicile (Frais de services et assurance incluse).</p><p><br></p>
    <p>[Compléter avec Prénom] dont le tarif est de ..€ euros/visite/ journée à votre domicile (Frais de services et assurance incluse).</p><p><br></p>
    <p>[Compléter avec Prénom] dont le tarif est de ..€ euros/visite/ journée à votre domicile (Frais de services et assurance incluse).</p><p><br></p>
    <p>[Décrire ici la société partenaire. Ex Animaute]</p><p><br></p>
    <p>"Animaute" est la seule société de garde d'animaux créée et suivie par un vétérinaire, avec :</p><p><br></p>
    <p><strong>Un Service support 7/7j à votre</strong> disposition du client et celle du pet Sitter tous les jours de la semaine.</p><p><br></p>
    <p><strong>Une assistance vétérinaire pendant la garde</strong>, mise à disposition du pet Sitter si celui-ci rencontre un problème de santé avec l’animal gardé.</p><p><br></p>
    <p><strong>Une Assurance Responsabilité Civile Professionnelle (RCP)</strong> active tout au long de la prestation. Elle couvre le propriétaire et le pet Sitter.</p><p><br></p>
    <p>Vous pouvez échanger avec eux via la plateforme</p><br>
    <p><a href="https://www.animaute.fr/c_connexion.php" target="_blank">Garde d'animaux en famille ou à domicile dans toute la France</a></p><p><br></p>
    <p>Votre identifiant est : .. [email renseigné à la création du compte] suivi de "<strong>zenetbien</strong>" comme mot de passe.</p><p><br></p>
    <p><strong>ATTENTION</strong> ! Afin de garantir la confidentialité de vos coordonnées, nous enregistrons à la création de votre compte une adresse email générique.</p><p><br></p>
    <p>Il est impératif que vous mettiez ces informations à jour avant votre paiement en ligne. Cette étape est importante, tant pour vos futurs échanges avec votre pet-sitter que pour l'assurance liée à la prestation.</p><p><br></p>
    <p>Je vous remercie de votre confiance et vous souhaite une belle journée.</p>`,
  }
]