export const CONTENT_TYPE = {
  ACTUAL: 'Actualité',
  GOOD_DEAL: 'Bon plan'
}

export const CONTENT_STATUS = {
  NOT_PUBLISHED: 'Non publié',
  PUBLISHED: 'Publié',
  PLANNED: 'En attente de publication',
  ARCHIVED: 'Archivé',
  NEW: 'Nouveau contenu',
}

export const CONTENT_SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/png'
];