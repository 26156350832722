import { all, takeEvery } from "redux-saga/effects";

/** Actions */
import * as a from './actions';

//Import handlers
import {
  getCollaboratorListHandler,
  getCollaboratorRolesHandler,
  getSelectedCollaboratorHandler,
  editCollaboratorHandler,
  switchCollaboratorSiteHandler,
} from './handler/userManagementHandler';

export default function* userManagementSaga() {
  yield all([
    takeEvery(a.GET_COLLABORATOR_LIST.REQUEST, getCollaboratorListHandler),
    takeEvery(a.GET_COLLABORATOR_ROLES.REQUEST, getCollaboratorRolesHandler),
    takeEvery(a.GET_SELECTED_COLLABORATOR.REQUEST, getSelectedCollaboratorHandler),
    takeEvery(a.EDIT_COLLABORATOR.REQUEST, editCollaboratorHandler),
    takeEvery(a.SWITCH_COLLABORATOR_SITE.REQUEST, switchCollaboratorSiteHandler),
  ]);
}