import styled from "styled-components";
import ButtonCpt from '../../../../components/molecules/buttonCpt';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 99;
  background-color: #ffffff;
  height: 78px;
  padding: 20px;
  border-bottom: 1px solid #efefef;
`;

export const WrapperContent = styled.div`

`;

export const Title = styled.h1`
  margin: 0 0 8px 0;
  color: #5A5A5A;
  font-size: 25px;
  line-height: 18px;
  font-weight: 700;
  text-align: left;
`;

export const Text = styled.p`
  margin: 0;
  color: #777777;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const NotificationIconWrapper = styled.div`
  display: flex;
  witdh: 14px;
  height: 15px;
`;

export const NotificationIndicator = styled.div`
  position: absolute;
  top: 5px;
  right: 7px;
  border-radius: 100px;
  width: 13px;
  height: 13px;
  background-color: #EC6B87;
  display: flex;
  justify-center: center;
  align-items: center;
  span{
    font-size: 7px;
    font-weight: 600;
    color: #FFFFFF;
    letter-spacing: 0;
    text-align: center;
    line-height: 7px;
    margin: 0 auto;
  }
`;

export const ButtonCptNotification = styled(ButtonCpt)`
  &&{
    &:hover{
      svg path{
        fill: #FFFFFF;
      }
    }
  }
`;

export const Toolbar = styled.div`

`;
