import React from "react";

const ArrowRightIcon = ({
  color = "#EDEDED",
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon arrowRightIcon"
      width={width}
      height={height}
      viewBox="0 0 7 10"
      fill="none"
    >
      <path
        d="M1.4 9a.42.42 0 01-.283-.107.34.34 0 010-.514L4.834 5 1.117 1.62a.34.34 0 010-.513.429.429 0 01.566 0l4 3.636a.34.34 0 010 .514l-4 3.636A.42.42 0 011.4 9z"
        stroke={color}
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export default ArrowRightIcon;
