import { authHeader } from '../helpers/auth-header'
import endpoint, { BASE_URL } from "../helpers/api";

export const editPrestation = async (id, message) => {
  const url = endpoint.postRequestMessage;
  let formData = new FormData();

  formData.append("message", message);

  let reqOptions = {
    headers: authHeader(),
    method: "POST",
    body: formData
  }

  try {
    const data = await fetch(url + "/" + id + "/edit", reqOptions)
    if (data) return { success: true }
  } catch (err) {
    return { success: false, error: err }
  }

}

export const deletePrestation = async (id, message) => {
  let url = endpoint.postRequestMessage;
  let formData = new FormData();

  formData.append("message", message);

  let reqOptions = {
    headers: authHeader(),
    method: "POST",
    body: formData
  }

  try {
    const data = await fetch(url + "/" + id + "/cancel", reqOptions)
    if (data) return { success: true }
  } catch (err) {
    return { success: false, error: err }
  }
}
