import { call, put, select } from 'redux-saga/effects'
import { getServicesError, getServicesSuccess } from '../actions';
import endpoint from '../../../helpers/api';
import apiCall from '../../apiCall';
import * as authenticationSelectors from '../../authentication/selectors';

export function* getServicesHandler() {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const servicesList = yield call(apiCall, endpoint.getServicesList, userToken);
    yield put(getServicesSuccess(servicesList));
  } catch (err) {
    yield put(getServicesError(err));
  }
}
