export const actionGenerator = (value) => ({
  REQUEST: `SIDEBAR/${value}/REQUEST`,
  SUCCESS: `SIDEBAR/${value}/SUCCESS`,
  FAILURE: `SIDEBAR/${value}/FAILURE`,
})

export const GET_WEATHER = {
  REQUEST: 'SIDEBAR/GET_WEATHER/REQUEST',
  SUCCESS: 'SIDEBAR/GET_WEATHER/SUCCESS',
  FAILURE: 'SIDEBAR/GET_WEATHER/FAILURE',
};

export const GET_TRAFFIC = {
  REQUEST: 'SIDEBAR/GET_TRAFFIC/REQUEST',
  SUCCESS: 'SIDEBAR/GET_TRAFFIC/SUCCESS',
  FAILURE: 'SIDEBAR/GET_TRAFFIC/FAILURE',
};

export const GET_PAROLE_CYCONIA = {
  REQUEST: 'SIDEBAR/GET_PAROLE_CYCONIA/REQUEST',
  SUCCESS: 'SIDEBAR/GET_PAROLE_CYCONIA/SUCCESS',
  FAILURE: 'SIDEBAR/GET_PAROLE_CYCONIA/FAILURE',
};

export const GET_EXPERTISE_AREAS = {
  REQUEST: 'SIDEBAR/GET_EXPERTISE_AREAS/REQUEST',
  SUCCESS: 'SIDEBAR/GET_EXPERTISE_AREAS/SUCCESS',
  FAILURE: 'SIDEBAR/GET_EXPERTISE_AREAS/FAILURE',
};

export const GET_EXPERTISE_AREAS_THEME = {
  REQUEST: 'SIDEBAR/GET_EXPERTISE_AREAS_THEME/REQUEST',
  SUCCESS: 'SIDEBAR/GET_EXPERTISE_AREAS_THEME/SUCCESS',
  FAILURE: 'SIDEBAR/GET_EXPERTISE_AREAS_THEME/FAILURE',
};

export const GET_SERVICES_LIST = {
  REQUEST: 'SIDEBAR/GET_SERVICES_LIST/REQUEST',
  SUCCESS: 'SIDEBAR/GET_SERVICES_LIST/SUCCESS',
  FAILURE: 'SIDEBAR/GET_SERVICES_LIST/FAILURE',
};

export const GET_SERVICES_ORDERS_LIST = {
  REQUEST: 'SIDEBAR/GET_SERVICES_ORDERS_LIST/REQUEST',
  SUCCESS: 'SIDEBAR/GET_SERVICES_ORDERS_LIST/SUCCESS',
  FAILURE: 'SIDEBAR/GET_SERVICES_ORDERS_LIST/FAILURE',
};

export const GET_SERVICES_CATEGORY_BY_ID = {
  REQUEST: 'SIDEBAR/GET_SERVICES_CATEGORY_BY_ID/REQUEST',
  SUCCESS: 'SIDEBAR/GET_SERVICES_CATEGORY_BY_ID/SUCCESS',
  FAILURE: 'SIDEBAR/GET_SERVICES_CATEGORY_BY_ID/FAILURE',
};

export const LOGIN_EXPRESSING = {
  REQUEST: 'SIDEBAR/LOGIN_EXPRESSING/REQUEST',
  SUCCESS: 'SIDEBAR/LOGIN_EXPRESSING/SUCCESS',
  FAILURE: 'SIDEBAR/LOGIN_EXPRESSING/FAILURE',
  RESET: 'SIDEBAR/LOGIN_EXPRESSING/RESET',
};

export const LOGOUT_EXPRESSING = {
  REQUEST: 'SIDEBAR/LOGOUT_EXPRESSING/REQUEST',
  SUCCESS: 'SIDEBAR/LOGOUT_EXPRESSING/SUCCESS',
  FAILURE: 'SIDEBAR/LOGOUT_EXPRESSING/FAILURE',
};

export const GET_EXPRESSING_DATA = {
  REQUEST: 'SIDEBAR/EXPRESSING_GET_DATA/REQUEST',
  SUCCESS: 'SIDEBAR/EXPRESSING_GET_DATA/SUCCESS',
  FAILURE: 'SIDEBAR/EXPRESSING_GET_DATA/FAILURE',
};

export const EXPRESSING_GET_AMOUNT = {
  REQUEST: 'SIDEBAR/EXPRESSING_GET_AMOUT/REQUEST',
  SUCCESS: 'SIDEBAR/EXPRESSING_GET_AMOUT/SUCCESS',
  FAILURE: 'SIDEBAR/EXPRESSING_GET_AMOUT/FAILURE',
};

export const EXPRESSING_GET_PAST_ORDERS = {
  REQUEST: 'SIDEBAR/EXPRESSING_GET_PAST_ORDERS/REQUEST',
  SUCCESS: 'SIDEBAR/EXPRESSING_GET_PAST_ORDERS/SUCCESS',
  FAILURE: 'SIDEBAR/EXPRESSING_GET_PAST_ORDERS/FAILURE',
};

export const EXPRESSING_GET_ONGOING_ORDERS = {
  REQUEST: 'SIDEBAR/EXPRESSING_GET_ONGOING_ORDERS/REQUEST',
  SUCCESS: 'SIDEBAR/EXPRESSING_GET_ONGOING_ORDERS/SUCCESS',
  FAILURE: 'SIDEBAR/EXPRESSING_GET_ONGOING_ORDERS/FAILURE',
};

export const SET_SELECTED_PAROLE = {
  REQUEST: 'SIDEBAR/SET_SELECTED_PAROLE/REQUEST',
  SUCCESS: 'SIDEBAR/SET_SELECTED_PAROLE/SUCCESS',
  FAILURE: 'SIDEBAR/SET_SELECTED_PAROLE/FAILURE',
};

export const SET_EXPRESSING_PUBLICATION = {
  REQUEST: 'SIDEBAR/SET_EXPRESSING_PUBLICATION/REQUEST',
  SUCCESS: 'SIDEBAR/SET_EXPRESSING_PUBLICATION/SUCCESS',
  FAILURE: 'SIDEBAR/SET_EXPRESSING_PUBLICATION/FAILURE',
};

export const GET_SERVICE_TICKET = actionGenerator("GET_SERVICE_TICKET");
export const GET_SERVICE_MESSAGES = actionGenerator("GET_SERVICE_MESSAGES");
export const SAVE_SERVICE_MESSAGE = actionGenerator('SAVE_SERVICE_MESSAGE');

/**
 * DASHBOARD - WEATHER
 */
export const getWeather = () => ({
  type: GET_WEATHER.REQUEST,
});

export const getWeatherSuccess = (weather) => ({
  type: GET_WEATHER.SUCCESS,
  weather,
});

export const getWeatherError = (error) => ({
  type: GET_WEATHER.FAILURE,
  error,
});

/**
 * DASHBOARD - TRAFFIC
 */
export const getTraffic = ({
  subway,
  bike,
  walk,
  car,
}) => ({
  type: GET_TRAFFIC.REQUEST,
  subway,
  bike,
  walk,
  car,
});

export const getTrafficSuccess = (traffic) => ({
  type: GET_TRAFFIC.SUCCESS,
  traffic,
});

export const getTrafficError = (error) => ({
  type: GET_TRAFFIC.FAILURE,
  error,
});

/**
 * DASHBOARD - PAROLE CYCONIA
 */
export const getParoleCyconia = () => ({
  type: GET_PAROLE_CYCONIA.REQUEST,
});

export const getParoleCyconiaSuccess = (paroleCyconia) => ({
  type: GET_PAROLE_CYCONIA.SUCCESS,
  paroleCyconia,
});

export const getParoleCyconiaError = (error) => ({
  type: GET_PAROLE_CYCONIA.FAILURE,
  error,
});

/**
 * MES COMPETENCES - SKILLS LIST
 */
export const getExpertiseAreas = () => ({
  type: GET_EXPERTISE_AREAS.REQUEST,
});

export const getExpertiseAreasSuccess = (expertiseAreas) => ({
  type: GET_EXPERTISE_AREAS.SUCCESS,
  expertiseAreas,
});

export const getExpertiseAreasError = (error) => ({
  type: GET_EXPERTISE_AREAS.FAILURE,
  error,
});

/**
 * MES COMPETENCES - SKILLS THEMATIQUE LIST
 */
export const getExpertiseAreasTheme = (id) => ({
  type: GET_EXPERTISE_AREAS_THEME.REQUEST,
  id
});

export const getExpertiseAreasThemeSuccess = (expertiseAreasTheme) => ({
  type: GET_EXPERTISE_AREAS_THEME.SUCCESS,
  expertiseAreasTheme,
});

export const getExpertiseAreasThemeError = (error) => ({
  type: GET_EXPERTISE_AREAS_THEME.FAILURE,
  error,
});

/**
 * CONCIERGERIE - SERVICES LIST
 */
export const getServiceCategoryList = () => ({
  type: GET_SERVICES_LIST.REQUEST
});

export const getServiceCategoryListSuccess = (serviceCategoryList) => ({
  type: GET_SERVICES_LIST.SUCCESS,
  serviceCategoryList,
});

export const getServiceCategoryListError = (error) => ({
  type: GET_SERVICES_LIST.FAILURE,
  error,
});

/**
 * CONCIERGERIE - SERVICES ORDERS LIST
 */
export const getServicesOrdersList = (pageNumber) => ({
  type: GET_SERVICES_ORDERS_LIST.REQUEST,
  pageNumber
});

export const getServicesOrdersListSuccess = (servicesOrdersList) => ({
  type: GET_SERVICES_ORDERS_LIST.SUCCESS,
  servicesOrdersList,
});

export const getServicesOrdersListError = (error) => ({
  type: GET_SERVICES_ORDERS_LIST.FAILURE,
  error,
});

/**
 * CONCIERGERIE - SERVICES CATEGORY BY ID
 */
export const getServicesCategoryById = (id) => ({
  type: GET_SERVICES_CATEGORY_BY_ID.REQUEST,
  id
});

export const getServicesCategoryByIdSuccess = (servicesCategoryById) => ({
  type: GET_SERVICES_CATEGORY_BY_ID.SUCCESS,
  servicesCategoryById,
});

export const getServicesCategoryByIdError = (error) => ({
  type: GET_SERVICES_CATEGORY_BY_ID.FAILURE,
  error,
});

/**
 * CONCIERGERIE EXPRESSING - LOGIN
 */
export const loginExpressing = (phone, code) => ({
  type: LOGIN_EXPRESSING.REQUEST,
  phone,
  code,
});

export const loginExpressingSuccess = (data) => ({
  type: LOGIN_EXPRESSING.SUCCESS,
  data,
});

export const loginExpressingError = (error) => ({
  type: LOGIN_EXPRESSING.FAILURE,
  error,
});

export const resetLoginExpressing = () => ({
  type: LOGIN_EXPRESSING.RESET,
});

/**
 * CONCIERGERIE EXPRESSING - LOGOUT
 */
export const logoutExpressing = () => ({
  type: LOGOUT_EXPRESSING.REQUEST,
});

export const logoutExpressingSuccess = () => ({
  type: LOGOUT_EXPRESSING.SUCCESS,
});

export const logoutExpressingError = (error) => ({
  type: LOGOUT_EXPRESSING.FAILURE,
  error,
});

/**
 * CONCIERGERIE EXPRESSING - GET DATA
 */
export const getExpressingData = () => ({
  type: GET_EXPRESSING_DATA.REQUEST,
});

export const getExpressingDataSuccess = () => ({
  type: GET_EXPRESSING_DATA.SUCCESS,
});

export const getExpressingDataError = () => ({
  type: GET_EXPRESSING_DATA.FAILURE,
});


/**
 * CONCIERGERIE EXPRESSING - GET AMOUNT
 */
export const getExpressingAmount = () => ({
  type: EXPRESSING_GET_AMOUNT.REQUEST,
});

export const getExpressingAmountSuccess = (amount) => ({
  type: EXPRESSING_GET_AMOUNT.SUCCESS,
  amount,
});

export const getExpressingAmountError = (error) => ({
  type: EXPRESSING_GET_AMOUNT.FAILURE,
  error,
});

/**
 * CONCIERGERIE EXPRESSING - GET PAST ORDERS
 */
export const getExpressingPastOrders = () => ({
  type: EXPRESSING_GET_PAST_ORDERS.REQUEST,
});

export const getExpressingPastOrdersSuccess = (pastOrders) => ({
  type: EXPRESSING_GET_PAST_ORDERS.SUCCESS,
  pastOrders,
});

export const getExpressingPastOrdersError = (error) => ({
  type: EXPRESSING_GET_PAST_ORDERS.FAILURE,
  error,
});

/**
 * CONCIERGERIE EXPRESSING - GET ON GOING ORDERS
 */
export const getExpressingOnGoingOrders = () => ({
  type: EXPRESSING_GET_ONGOING_ORDERS.REQUEST,
});

export const getExpressingOnGoingOrdersSuccess = (onGoingOrders) => ({
  type: EXPRESSING_GET_ONGOING_ORDERS.SUCCESS,
  onGoingOrders,
});

export const getExpressingOnGoingOrdersError = (error) => ({
  type: EXPRESSING_GET_ONGOING_ORDERS.FAILURE,
  error,
});

/**
 * DASHBOARD - PAROLE CYCONIA - MODAL
 */
export const setSelectedParole = (newsId = null) => ({
  type: SET_SELECTED_PAROLE.REQUEST,
  newsId
});

export const setSelectedParoleSuccess = (data) => ({
  type: SET_SELECTED_PAROLE.SUCCESS,
  data
});

export const setSelectedParoleError = (error) => ({ // Not In use, parole is already in the stoe, we just need to select it and will never have error since it is not calling server
  type: SET_SELECTED_PAROLE.FAILURE,
  error,
});

/**
 * DASHBOARD - EXPRESSING PRESTATION  - MODAL
 */
export const setSelectedExpressinPrestation = (prestation = null) => ({
  type: SET_EXPRESSING_PUBLICATION.REQUEST,
  prestation
});

export const setSelectedExpressinPrestationSuccess = (data) => ({
  type: SET_EXPRESSING_PUBLICATION.SUCCESS,
  data
});

export const setSelectedExpressinPrestationError = (error) => ({
  type: SET_EXPRESSING_PUBLICATION.FAILURE,
  error,
});

// SIDEBAR - SERVICE TICKET
export const getServiceTicket = (ticketId) => ({
  type: GET_SERVICE_TICKET.REQUEST,
  ticketId
});

export const getServiceTicketSuccess = (data) => ({
  type: GET_SERVICE_TICKET.SUCCESS,
  data
});

export const getServiceTicketError = (error) => ({
  type: GET_SERVICE_TICKET.FAILURE,
  error,
});

// SIDEBAR - SERVICE MESSAGES
export const getServiceMessages = (ticketId = null) => ({
  type: GET_SERVICE_MESSAGES.REQUEST,
  ticketId
});

export const getServiceMessagesSuccess = (data) => ({
  type: GET_SERVICE_MESSAGES.SUCCESS,
  data
});

export const getServiceMessagesError = (error) => ({
  type: GET_SERVICE_MESSAGES.FAILURE,
  error,
});

// SIDEBAR - SAVE SERVICE MESSAGES
export const saveServiceMessage = (data) => ({
  type: SAVE_SERVICE_MESSAGE.REQUEST,
  data
});

export const saveServiceMessageSuccess = (data) => ({
  type: SAVE_SERVICE_MESSAGE.SUCCESS,
  data
});

export const saveServiceMessageError = (error) => ({
  type: SAVE_SERVICE_MESSAGE.FAILURE,
  error
});
