import React, { useState, useEffect } from "react";
import {Helmet} from "react-helmet";
import { createBrowserHistory } from "history";
import {
  shallowEqual,
  useDispatch,
  useSelector,
} from "react-redux";
// Import third party components
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import moment from 'moment';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

// Import components
import Loader from "./components/atoms/lottie/Loader";
import InstallPwa from "./components/organisms/InstallPwa";
import Login from "./containers/Login";
import Logout from "./containers/Logout/Logout";
import Onboard from './containers/Onboard/';
import OfflinePage from './containers/Offline/OfflinePage';
import NotAvailable from './containers/IE/NotAvailable';
import Home from './containers/Home';
import NotFound from "./containers/NotFound/NotFound";
import AdminFront from './containers/AdminFront';
import Conciergerie from './containers/Conciergerie';
import NotFoundCompany from "./containers/NotFoundCompany/NotFoundCompany";
import GetGroundLogin from './containers/GetGround/components/Login';
import GetGroundRestricted from './containers/GetGround/components/Restricted';
// import { AdminFront } from 'adminfront';
import SSO from './containers/SSO';
import Landing from './containers/Landing';

// routes
import { HomePrivateRoute } from './routes/HomePrivateRoute';
import { PrivateRoute } from './routes/PrivateRoute';
import { RedirectAuthRoute } from './routes/RedirectAuthRoute';
import { RedirectOffline } from './routes/RedirectOffline.js';
import { ConciergeRoute } from "./routes/ConciergeRoute";
import { AdminRoute } from "./routes/AdminRoute";

// Import helpers
import { COMPANY_SETTING_KEYS } from "./constants/companySettingConstants";

// Store
import {
  getConfig,
} from './store/config/actions';
import {
  getSubDomain,
  getCompanyInfo,
  isGetConfigError,
  isGetConfigLoading,
  isGetConfigSuccess,
  hasConfigData as hasConfigDataSelector,
} from './store/config/selectors';
import {
  getCompanyData,
  getCompanySettings
} from './store/company/selectors';

import {
  IconIosShare,
  IconClose
} from './helpers/Icon';

console.ignoredYellowBox = [
  'NetInfo\'s "change" event', // Safe to ignore because reasons
  'Using <Image> with children' // TODO: Will be fixed in release foo
];

moment.locale('fr');

const App = () => {
  const dispatch = useDispatch();
  const {
    configOnLoad,
    configOnSuccess,
    configOnError,
    hasConfigData,
    company,
    companySettings,
    subdomain,
    companyInfo,
  } = useSelector(
    state => ({
      configOnLoad: isGetConfigLoading(state),
      configOnSuccess: isGetConfigSuccess(state),
      configOnError: isGetConfigError(state),
      hasConfigData: hasConfigDataSelector(state),
      company: getCompanyData(state),
      companySettings: getCompanySettings(state),
      subdomain: getSubDomain(state),
      companyInfo: getCompanyInfo(state),
    }),
    shallowEqual
  );
  const [showInstallMessage, setShowInstallMessage] = useState(false);
  
  useEffect(() => {
    // getUserByIdFirst();

    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    }

    const isStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

    if (isIos() && !isStandaloneMode()) {
      setShowInstallMessage(true);
    }

    if (!hasConfigData) {
      // Write your custom subDomain in the Dispatch for test in dev 
      // Let empty for "bureaux" subDomain
      dispatch(getConfig());
      // dispatch(getConfig("conciergerie-izipop-bpalc"));
    }
  }, []);

  const history = createBrowserHistory();
  // console.log('configOnSuccess && hasConfigData', configOnSuccess, hasConfigData, configOnLoad, configOnError)
  const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
  if (isIE11) {
    return (
      <NotAvailable />
    );
  }

  const favicon = company?.favicon || '/favicon.ico';
  const title = `Cyconia${company?.title ? ` - ${company?.title}` : ''}`;
  const metaPreviewSubdomain = window.location.hostname;
  // const metaPreview = `https://${metaPreviewSubdomain}/meta-preview-cyconia.png`;
  // console.log('subdomain', subdomain);
  // console.log('metaPreview', metaPreview);
  return (
    <>
      <Helmet>
        <title>{title}</title>
        {company?.favicon && <link rel="shortcut icon" id="fav_icon" href={favicon} type="image/x-icon"/>}

        {/* Primary Meta Tags */}
        <meta name="title" content={title} />
        {/* <meta name="description" content="Demandes RH, environnement de travail, services de conciergerie : gagnez du temps et facilitez le quotidien de vos collaborateurs !" /> */}

        {/* Open Graph / Facebook  Meta Tags */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://${subdomain}`} />
        <meta property="og:title" content={title} />
        {/* <meta property="og:description" content="Demandes RH, environnement de travail, services de conciergerie : gagnez du temps et facilitez le quotidien de vos collaborateurs !" /> */}
        {/* <meta property="og:image" content={metaPreview} /> */}

        {/* Twitter Meta Tags */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={`https://${subdomain}`} />
        <meta property="twitter:title" content={title} />
        {/* <meta property="twitter:description" content="Demandes RH, environnement de travail, services de conciergerie : gagnez du temps et facilitez le quotidien de vos collaborateurs !" /> */}
        {/* <meta property="twitter:image" content={metaPreview} /> */}
      </Helmet>

      {(configOnSuccess && hasConfigData) ?
        <>
          <ToastContainer
            autoClose={8000}
            className="custom-toast-container custom-toast-container__success"
          />
          <Router history={history}>
            <Switch>
              <RedirectOffline exact path="/offlinePage" component={OfflinePage} />
              <RedirectAuthRoute exact path="/login" name="Login Page" component={Login} />
              <RedirectAuthRoute exact path="/login/:state" name="Login Page" component={Login} />
              <RedirectAuthRoute exact path="/espace-conciergerie/login" component={Login} componentProps={{ isConcierge: true }}/>
              <PrivateRoute exact path="/logout" name="Logout" component={Logout} />
              <PrivateRoute path="/onboarding" name="Onboard Page" component={Onboard} />
              <Route exact path="/notfound" component={NotFound} />
              <Route exact path="/companynotfound" component={NotFoundCompany} />
              <AdminRoute path="/admin" component={AdminFront} />
              <ConciergeRoute path="/espace-conciergerie" component={Conciergerie} />
              <Route exact path="/getground/auth/:token" component={GetGroundLogin} />
              <Route exact path="/getground/restricted" component={GetGroundRestricted} />
              <Route path="/sso" component={SSO} />
              <HomePrivateRoute path="/landing" component={Landing} />
              <HomePrivateRoute path="/" component={Home} />
            </Switch>
          </Router>

          {!companySettings?.[COMPANY_SETTING_KEYS.HIDE_DOWNLOAD_POP_UP] && showInstallMessage && (
            <InstallPwa
              onClick={() => setShowInstallMessage(false)}
              contentButton={<IconClose />}
            >
              Installer cette web app sur votre iPhone: <br />
              appuyer sur <IconIosShare /> et sélectionner l'option <br />
              <strong>+</strong> sur l'écran d'accueil
            </InstallPwa>
          )}
        </>
        : <Loader />
      }
    </>
  );
};

export default App;
