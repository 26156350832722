import React from "react";

const ThinkingIllustration = ({
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon thinkingIllustration"
      width={width}
      height={height}
      viewBox="0 0 275 221"
      fill="none"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="thinkingIllustration">
          <path d="M97.8257,89.02565 C97.8257,89.02565 76.937,95.3288 70.89775,112.16765 C64.8585,129.0065 70.30905,160.38015 94.54725,179.44185 C118.78545,198.50355 157.3656,183.40035 157.3656,183.40035 C157.3656,183.40035 183.86725,147.6013 172.7124,121.8 C161.55755,95.9987 143.115,69.96395 97.8257,89.02565 Z" id="Path" fill="#357ED3" fill-rule="nonzero"></path>
          <path d="M57.855,161.0399 C57.855,161.0399 46.8118,204.6849 63.3969,204.6849 C79.982,204.6849 108.3005,204.0556 108.3005,204.0556 L86.9855,166.08445 L57.855,161.0399 Z" id="Path" fill="#F4A28C" fill-rule="nonzero"></path>
          <path d="M99.78465,89.0967 C99.78465,89.0967 88.0005,101.8045 99.78465,109.5591 C111.5688,117.3137 125.82955,106.1081 122.41915,98.22155 C119.00875,90.335 108.73695,84.9352 108.73695,84.9352 L99.78465,89.0967 Z" id="Path" fill="#F4A28C" fill-rule="nonzero"></path>
          <path d="M99.78465,89.0967 C99.78465,89.0967 88.0005,101.8045 99.78465,109.5591 C111.5688,117.3137 125.82955,106.1081 122.41915,98.22155 C119.00875,90.335 108.73695,84.9352 108.73695,84.9352 L99.78465,89.0967 Z" id="Path" fill="#000000" fill-rule="nonzero" opacity="0.09"></path>
          <path d="M69.57825,114.7559 C64.13785,126.1442 55.7235,146.2209 56.20055,160.7557 L86.9855,166.0946 C86.9855,166.0946 104.1593,125.6164 87.40165,111.0004 C84.6966138,108.66546 81.0565145,107.727399 77.5598947,108.464158 C74.0632749,109.200917 71.1111614,111.527999 69.57825,114.7559 Z" id="Path" fill="#357ED3" fill-rule="nonzero"></path>
          <path d="M69.57825,114.7559 C64.13785,126.1442 55.7235,146.2209 56.20055,160.7557 L86.9855,166.0946 C86.9855,166.0946 104.1593,125.6164 87.40165,111.0004 C84.6966138,108.66546 81.0565145,107.727399 77.5598947,108.464158 C74.0632749,109.200917 71.1111614,111.527999 69.57825,114.7559 Z" id="Path" fill="#FFFFFF" fill-rule="nonzero" opacity="0.54"></path>
          <path d="M155.02095,92.53755 C166.3179,100.5053 180.9745,115.88255 186.14085,143.55145 L158.00505,154.0161 C158.00505,154.0161 133.99015,129.60535 135.67505,101.8451 C135.963333,97.4067561 138.621088,93.4682165 142.629048,91.5399376 C146.637008,89.6116588 151.373081,89.9929372 155.02095,92.53755 Z" id="Path" fill="#357ED3" fill-rule="nonzero"></path>
          <path d="M155.02095,92.53755 C166.3179,100.5053 180.9745,115.88255 186.14085,143.55145 L158.00505,154.0161 C158.00505,154.0161 133.99015,129.60535 135.67505,101.8451 C135.963333,97.4067561 138.621088,93.4682165 142.629048,91.5399376 C146.637008,89.6116588 151.373081,89.9929372 155.02095,92.53755 Z" id="Path" fill="#FFFFFF" fill-rule="nonzero" opacity="0.54"></path>
          <path d="M20.6654,189.5208 C20.6654,189.5208 8.2621,186.14085 5.5622,174.58 C5.5622,174.58 24.77615,170.7027 25.32425,190.5358 L20.6654,189.5208 Z" id="Path" fill="#357ED3" fill-rule="nonzero" opacity="0.58"></path>
          <path d="M22.1879,188.29265 C22.1879,188.29265 13.5198,174.59015 21.1729,161.791 C21.1729,161.791 35.7889,171.0681 29.2929,188.3231 L22.1879,188.29265 Z" id="Path" fill="#357ED3" fill-rule="nonzero" opacity="0.73"></path>
          <path d="M24.4209,188.3028 C24.4209,188.3028 28.99855,173.83905 42.833,171.0478 C42.833,171.0478 45.4314,180.43655 33.87055,188.3028 L24.4209,188.3028 Z" id="Path" fill="#357ED3" fill-rule="nonzero"></path>
          <polygon id="Path" fill="#24285B" fill-rule="nonzero" points="15.41785 187.978 17.93505 205.18225 33.76905 205.24315 36.10355 188.0592"></polygon>
          <path d="M90.81205,73.59765 C90.81205,73.59765 93.59315,93.4409 104.139,92.82175 C114.68485,92.2026 108.199,70.1974 108.199,70.1974 C108.199,70.1974 106.778,59.0324 100.51545,59.0324 C94.2529,59.0324 86.14305,59.885 90.81205,73.59765 Z" id="Path" fill="#F4A28C" fill-rule="nonzero"></path>
          <path d="M89.6245,76.125 L91.76615,78.155 C91.76615,78.155 102.58605,72.4101 102.58605,63.6405 C102.58605,63.6405 104.61605,71.2327 109.10235,74.0544 C109.10235,74.0544 113.58865,58.7482 105.22505,57.043 C105.22505,57.043 102.5556,52.6988 99.8557,52.3943 C97.6633,52.14055 86.1938,55.71335 85.39195,65.5893 C85.1424383,69.5596789 86.697563,73.4307088 89.6245,76.125 L89.6245,76.125 Z" id="Path" fill="#24285B" fill-rule="nonzero"></path>
          <path d="M91.76615,78.155 C91.76615,78.155 86.16335,77.8099 87.087,80.43875 C88.01065,83.0676 88.01065,86.884 93.67435,83.8187 L91.76615,78.155 Z" id="Path" fill="#F4A28C" fill-rule="nonzero"></path>
          <path d="M102.07855,98.5565 C102.07855,98.5565 96.8716,103.1037 103.6112,106.5953 C110.3508,110.0869 108.49335,143.318 118.25765,151.91505 C128.02195,160.5121 143.8255,155.87355 143.8255,155.87355 L124.89575,120.0745 L121.1504,102.18005 L111.53835,100.31245 L102.07855,98.5565 Z" id="Path" fill="#000000" fill-rule="nonzero" opacity="0.09"></path>
          <path d="M108.7268,73.65855 C108.7268,73.65855 113.62925,70.91805 113.93375,73.65855 C114.23825,76.39905 115.8927,79.87035 109.46775,79.56585 L108.7268,73.65855 Z" id="Path" fill="#F4A28C" fill-rule="nonzero"></path>
          <path d="M115.913,94.9837 C115.913,94.9837 103.82435,90.0305 101.0331,94.9837 C98.24185,99.9369 114.3499,103.66195 114.3499,103.66195 L115.913,94.9837 Z" id="Path" fill="#F4A28C" fill-rule="nonzero"></path>
          <path d="M115.913,94.9837 C115.913,94.9837 103.82435,90.0305 101.0331,94.9837 C98.24185,99.9369 114.3499,103.66195 114.3499,103.66195 L115.913,94.9837 Z" id="Path" fill="#F4A28C" fill-rule="nonzero"></path>
          <path d="M184.73,144.05895 C184.73,144.05895 197.7626,178.64 182.4158,186.76 C167.069,194.88 123.47475,150.2809 114.3499,103.6315 L115.913,94.95325 C115.913,94.95325 131.2801,111.88345 137.0859,122.1248 C142.8917,132.36615 160.86735,146.16 160.86735,146.16 L184.73,144.05895 Z" id="Path" fill="#F4A28C" fill-rule="nonzero"></path>
          <polygon id="Path" fill="#FFD200" fill-rule="nonzero" points="92.6086 204.69505 81.7684 150.45345 168.03325 150.45345 161.13125 206.07545"></polygon>
          <circle id="Oval" fill="#FFFFFF" fill-rule="nonzero" cx="124.8856" cy="177.02615" r="5.9276"></circle>
          <rect id="Rectangle" fill="#E6E6E6" fill-rule="nonzero" x="0.9541" y="203.45675" width="239.73285" height="17.0317"></rect>
          <rect id="Rectangle" fill="#C1C1C1" fill-rule="nonzero" x="208.77535" y="179.3099" width="18.44255" height="24.22805"></rect>
          <path d="M212.5207,185.38975 L206.045,185.38975 C202.193891,185.38975 199.071943,188.511691 199.071943,192.3628 L199.071943,192.3628 C199.069251,194.212994 199.803043,195.988185 201.111329,197.296471 C202.419615,198.604757 204.194806,199.338549 206.045,199.335857 L212.55115,199.335857" id="Path" stroke="#C1C1C1" stroke-width="2.03"></path>
          <circle id="Oval" fill="#E6E6E6" fill-rule="nonzero" cx="219.6663" cy="192.4237" r="5.3998"></circle>
          <path d="M210.92715,179.3099 C210.92715,179.3099 206.1668,166.257 218.03215,164.0849 C229.8975,161.9128 232.42485,157.57875 225.13715,148.58585 C217.84945,139.59295 220.06215,133.0868 227.2788,131.8485 C234.49545,130.6102 245.0819,143.6631 250.34975,127.7885 C255.6176,111.9139 266.16345,117.5776 270.193,127.7885 C274.22255,137.9994 281.358,154.1176 259.64715,155.3559 C237.9363,156.5942 248.48215,172.03235 240.74785,175.0063 C236.356596,176.718629 231.857139,178.139332 227.2788,179.25915 L210.92715,179.3099 Z" id="Path" fill="#E6E6E6" fill-rule="nonzero" opacity="0.38"></path>
          <path d="M210.80535,26.4509 C210.902226,25.8602672 210.953128,25.2630081 210.9576,24.6645 C210.9576,18.0834197 205.62258,12.7484 199.0415,12.7484 C197.178223,12.7475977 195.341405,13.1894081 193.6823,14.03745 C193.6823,13.61115 193.75335,13.18485 193.75335,12.7484 C193.853101,6.29975194 188.800065,0.944145929 182.356512,0.669132981 C175.912958,0.394120033 170.421971,5.29970369 169.9719,11.7334 C165.601707,8.23796142 159.386114,8.26115713 155.042132,11.7891158 C150.698149,15.3170744 149.400502,21.395746 151.9252,26.39 L151.69175,26.39 C148.45569,26.3873993 145.516784,28.2765384 144.175437,31.2215139 C142.834089,34.1664894 143.337901,37.6236823 145.463947,40.0633593 C147.589993,42.5030363 150.945821,43.4748524 154.04655,42.5488 C154.742166,48.5475854 159.822168,53.0733253 165.86115,53.07435 C167.451305,58.2682876 172.352771,61.7295065 177.779417,61.4905525 C183.206062,61.2515985 187.784328,57.3729542 188.9118,52.05935 C192.471001,54.9883474 197.400252,55.6019147 201.568891,53.6348382 C205.73753,51.6677618 208.397799,47.4728898 208.3998,42.86345 C208.3998,42.86345 208.3998,42.80255 208.3998,42.7721 C208.78986,42.8281407 209.183164,42.8586555 209.5772,42.86345 C213.873965,42.8368978 217.431271,39.5175257 217.754741,35.2328711 C218.078212,30.9482166 215.059578,27.1323935 210.8155,26.46105 L210.80535,26.4509 Z" id="Path" fill="#E6E6E6" fill-rule="nonzero" opacity="0.38"></path>
          <path d="M163.9022,65.09195 C163.978317,64.9042395 164.039409,64.7107816 164.0849,64.5134 C164.380348,63.1558283 163.961092,61.7414482 162.973544,60.764187 C161.985996,59.7869259 160.567304,59.3825029 159.2129,59.69215 C159.264152,59.5567756 159.304873,59.4176453 159.3347,59.276 C159.586222,57.2756354 158.331738,55.3937305 156.388555,54.8563629 C154.445373,54.3189953 152.402229,55.2889742 151.59025,57.13435 C150.618141,55.9318042 149.047328,55.3858735 147.538952,55.7263354 C146.030576,56.0667974 144.846562,57.2345321 144.48525,58.73805 C144.342889,59.3353005 144.342889,59.9576495 144.48525,60.5549 L144.40405,60.5549 C143.392983,60.2175719 142.278072,60.4854856 141.530667,61.2453751 C140.783262,62.0052647 140.533853,63.124461 140.887883,64.1298014 C141.241912,65.1351418 142.137571,65.8511037 143.1962,65.975 C143.457562,66.0437466 143.728672,66.067769 143.99805,66.04605 C143.785146,68.0560198 145.083149,69.9164908 147.04305,70.41055 C147.224404,72.2008599 148.562588,73.6581981 150.330973,73.991236 C152.099357,74.324274 153.875983,73.4535445 154.69615,71.85185 C155.659475,73.0545991 157.220673,73.6083121 158.726553,73.2813209 C160.232434,72.9543296 161.423395,71.803005 161.80115,70.30905 L161.80115,70.30905 C161.922652,70.3602274 162.048289,70.4009744 162.1767,70.43085 C163.607234,70.7944696 165.07265,69.9772064 165.515061,68.5690448 C165.957472,67.1608832 165.222753,65.65239 163.8413,65.13255 L163.9022,65.09195 Z" id="Path" fill="#E6E6E6" fill-rule="nonzero" opacity="0.38"></path>
          <path d="M137.46145,69.1621 C137.46145,69.0403 137.54265,68.9185 137.5731,68.78655 C137.730902,68.1173933 137.614894,67.4129257 137.250856,66.829703 C136.886818,66.2464803 136.30491,65.8328196 135.63445,65.68065 C135.241031,65.5842878 134.830169,65.5842878 134.43675,65.68065 C134.469775,65.5946281 134.496904,65.5064576 134.51795,65.41675 C134.899137,64.0377502 134.09025,62.6108369 132.71125,62.22965 C131.33225,61.8484631 129.905337,62.6573502 129.52415,64.03635 C128.906363,63.2651199 127.903354,62.9129508 126.939033,63.1286827 C125.974711,63.3444146 125.217374,64.0903972 124.9871,65.05135 C124.895643,65.4350589 124.895643,65.8348911 124.9871,66.2186 L124.9871,66.2186 C124.300584,66.0582159 123.583713,66.3141907 123.154049,66.8731317 C122.724384,67.4320728 122.66135,68.1906595 122.992869,68.8128508 C123.324388,69.435042 123.989178,69.8058254 124.69275,69.76095 C124.548757,71.0799172 125.425469,72.2941632 126.72275,72.5725 C126.804975,73.7472918 127.669914,74.7184726 128.827382,74.935647 C129.98485,75.1528214 131.14301,74.5612323 131.6455,73.49615 C132.261026,74.2906904 133.279572,74.658998 134.260913,74.4418872 C135.242253,74.2247764 136.010379,73.4611874 136.2333,72.48115 L136.2333,72.48115 L136.4769,72.56235 C137.381498,72.7534933 138.28346,72.226856 138.561627,71.3451212 C138.839793,70.4633864 138.403321,69.514505 137.5528,69.15195 L137.46145,69.1621 Z" id="Path" fill="#E6E6E6" fill-rule="nonzero" opacity="0.38"></path>
        </g>
      </g>
    </svg>
  );
};

export default ThinkingIllustration;
