import { call, put, select } from 'redux-saga/effects';
import endpoint from '../../../helpers/api';
import apiCall from '../../apiCall';
import * as authenticationSelectors from '../../authentication/selectors';
import { toaster } from '../../../helpers/toaster';

// import {
//   editGoodDealService,
//   deleteGoodDealService
// } from '../../../services/goodDealService';

import {
  editCollaboratorService,
  switchCollaboratorSiteService
} from '../../../services/userManagementService';

import {
  getCollaboratorListData
} from '../selectors';

import {
  getCollaboratorList,
  getCollaboratorListSuccess,
  getCollaboratorListError,
  getCollaboratorRolesSuccess,
  getCollaboratorRolesError,
  getSelectedCollaboratorSuccess,
  getSelectedCollaboratorError,
  editCollaboratorSuccess,
  editCollaboratorError,
  switchCollaboratorSiteSuccess,
  switchCollaboratorSiteError
} from '../actions';

export function* getCollaboratorListHandler(action) {
  const {
    limit = 10,
    pageNumber = 1,
    keyword = '',
    companySite = ''
  } = action.data;
  const url = `${endpoint.getCollaboratorsList}?page=${pageNumber}&limit=${limit}&keyword=${keyword}&companySite=${companySite}`;

  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const collaboratorsListData = yield call(apiCall, url, userToken);
    yield put(getCollaboratorListSuccess(collaboratorsListData));
  } catch (error) {
    yield put(getCollaboratorListError(error));
  }
}

export function* getCollaboratorRolesHandler() {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const collaboratorsRolesData = yield call(apiCall, endpoint.getCollaboratorsRoles, userToken);
    yield put(getCollaboratorRolesSuccess(collaboratorsRolesData));
  } catch (error) {
    yield put(getCollaboratorRolesError(error));
  }
}

export function* getSelectedCollaboratorHandler(action) {
  const { collaboratorId } = action.data;
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const collaboratorData = yield call(apiCall, endpoint.getCollabByIdApi(collaboratorId), userToken);
    yield put(getSelectedCollaboratorSuccess(collaboratorData));
  } catch (error) {
    yield put(getSelectedCollaboratorError(error));
  }
}

export function* editCollaboratorHandler(action) {
  const { id, formData } = action.data;

  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const collaboratorListData = yield select(getCollaboratorListData);
    const response = yield call(editCollaboratorService, endpoint.editCollaborator(id), userToken, formData);

    yield put(editCollaboratorSuccess(response.data));
    // To refresh the list once edit is success
    yield put(getCollaboratorList({
      limit: collaboratorListData.num_items_per_page,
      pageNumber: collaboratorListData.current_page_number
    }));
    toaster('Vos changements ont été enregistrés', 'success');
  } catch (err) {
    yield put(editCollaboratorError(err));
    toaster(`Une erreur s'est produite. Veuillez réessayer`, 'error');
  }
}

export function* switchCollaboratorSiteHandler(action) {
  const { formData } = action.data;

  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const response = yield call(switchCollaboratorSiteService, endpoint.switchCollaboratorSite, userToken, formData);
    yield put(switchCollaboratorSiteSuccess(response.data));
    toaster('Vos changements ont été enregistrés', 'success');
  } catch (err) {
    yield put(switchCollaboratorSiteError(err));
    toaster(`Une erreur s'est produite. Veuillez réessayer`, 'error');
  }
}