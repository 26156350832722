import React, { useEffect, useState } from 'react';
import {
  useRouteMatch,
  useLocation,
  useParams,
  useHistory,
  withRouter
} from "react-router-dom";
import {
  shallowEqual,
  useDispatch,
  useSelector,
} from "react-redux";

// Import Material UI components
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import {
  getSkillsDomainsListData,
} from '../../../../../../store/adminFront/selectors';
import {
  getSkillsDomainsList
} from '../../../../../../store/adminFront/actions';

// Import components
import Loader from '../../helpers/Loader';

// Import Style
import {
    StyledListItem,
    StyledListItemText,
    StyledTabs,
    ListCustom,
    ListNavCustom,
    CollapseCustom,
    LoaderWrapper,
    StatusFeeback
} from './style';

const SkillsNavBar = ({
  getInforListById,
  feedbackStatusValue,
  ...rest
}) => {
    const dispatch = useDispatch();
    // const path = useRouteMatch();
    // const location = useLocation();
    const { dmId } = useParams();
    const [value, setValue] = React.useState(0);
    const [open, setOpen] = React.useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(1);
    const [valueFeedback, setValueFeedback] = useState(false);
    // const [valFB, setValFB] = useState(null);

    const {
      skillsDomainsList,
    } = useSelector(
      state => ({
        skillsDomainsList: getSkillsDomainsListData(state),
      }),
      shallowEqual
    );

    useEffect(() => {
      setIsLoading(true);
      dispatch(getSkillsDomainsList());
      setIsLoading(false);
      setActiveTab(Number(dmId));
    }, [dmId]);

    // const handleClick = () => {
    //     setOpen(!open);
    // };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClickActiveTab = (currentTab) => {
        setActiveTab(currentTab);
    };

    const statusFeedback = () => {
        setValueFeedback(true);
    };


    return (
      <StyledTabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
      >
        {isLoading ?
            <LoaderWrapper>
               <Loader />
            </LoaderWrapper>
            :
            <ListCustom
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                {skillsDomainsList && skillsDomainsList?.length > 0 ? skillsDomainsList.map((skills, idx) => {
                  return (
                    <React.Fragment key={idx}>
                      <StyledListItem>
                          <StyledListItemText>
                              {skills.title}
                          </StyledListItemText>
                          {open ?
                            <ExpandLess style={{ fontSize: 15, marginLeft: 5 }} />
                            :
                            <ExpandMore style={{ fontSize: 15, marginLeft: 5 }} />
                          }
                      </StyledListItem>
                      <CollapseCustom in={open} timeout="auto" unmountOnExit>
                          <ListNavCustom component="div" disablePadding>
                              {skills.skills && skills.skills.length > 0 ?
                                skills.skills.map((sk, i) => {
                                    return (
                                      <StyledListItem
                                          key={i}
                                          button
                                          onClick={(e) => {
                                            getInforListById(sk.id);
                                            handleClickActiveTab(sk.id);
                                          }}
                                          className={activeTab === sk.id ? "isActiveTab" : ""}
                                          isActiveTab={activeTab === sk.id}
                                          isnested>
                                          <StyledListItemText>
                                              {sk.title}
                                              {valueFeedback &&
                                                <StatusFeeback />
                                              }
                                          </StyledListItemText>
                                      </StyledListItem>
                                    )
                                })
                                : null
                              }
                          </ListNavCustom>

                      </CollapseCustom>
                    </React.Fragment>
                  )
                }) : null
              }
            </ListCustom>
        }


    </StyledTabs>)
}

export default withRouter(SkillsNavBar);
