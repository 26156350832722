import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";

import {
  isUserTokenValid,
  isUserConcierge as isUserConciergeSelector,
} from '../store/authentication/selectors';

import { getUser } from '../store/collaborator/actions';

export const ConciergeRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();

  const {
    isTokenValid,
    isUserConcierge
  } = useSelector(
    state => ({
      isTokenValid: isUserTokenValid(state),
      isUserConcierge: isUserConciergeSelector(state),
    }),
    shallowEqual
  );

  useEffect(() => {
    if (isTokenValid) {
      dispatch(getUser());
    }
  }, [isTokenValid]);

  const handleTokenInvalid = (props) => {
    if (!isUserConcierge) {
      return (
        <Redirect
          to={{
            pathname: '/landing',
            state: { from: props.location }
          }}
        />
      )
    }
    else {
      return (
        <Component {...props} />
      )
    }
  }

  return (
    <Route
      {...rest}
      render={props =>
        isTokenValid ?
          handleTokenInvalid(props)
          :
          <Redirect
            to={{
              pathname: "/espace-conciergerie/login",
              state: { from: props.location }
            }}
          />
      }
    />
  )
};
