import styled from "styled-components";
import IconButton from '@material-ui/core/IconButton';

import { COLORS } from "../../../../../../constants/design";
import GenericAccordion from '../../../../../../components/molecules/GenericAccordion';

export const MainContainer = styled.div`

`;

export const StyledAccordion = styled(GenericAccordion)`
  && {
    .MuiAccordion-root {
    }
  }
`;

export const StyledAccordionHeader = styled.div`
  font-weight: 600;
  font-size: 1.1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContentTitle = styled.div`
  font-weight: 600;
  font-size: 1.1rem;
`;

export const TableIcon = styled(IconButton)`
  &&{
    padding: 10px;
    .MuiSvgIcon-root {
      font-size: 1.2rem;
    }
  }
`;

export const SettingContainer = styled.div`
  font-size: 1rem;
  >div:not(:last-child) {
    margin-bottom: 10px;
  };
`;

export const SettingTitle = styled.div`
  font-weight: 600;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
`;

export const EmailDomainContainer = styled.div`
  border: 1px solid ${COLORS.LIGHT_GREY_3};
  border-radius: 5px;
  width: 100%;
  padding: 5px 10px;
  font-size: 0.9rem;
  color: ${COLORS.DARK_GREY};
`;