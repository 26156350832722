import React from "react";

function GoodDealValueIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="14"
            viewBox="0 0 10 14"
        >
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g fill="#999" fillRule="nonzero" transform="translate(-1380 -1038)">
                    <g transform="translate(740 134)">
                        <g transform="translate(30 860)">
                            <g transform="translate(0 27)">
                                <path d="M616.923 17v3.125H620v10.208h-10V17h6.923zm1.438 10.444h-6.699v.781h6.7v-.78zm0-1.562h-6.699v.78h6.7v-.78zm-.028-4.507h-2.06v.782h.748l-1.79 1.82-1.675-1.7-1.901 1.93.544.552 1.357-1.378 1.676 1.702 2.332-2.373v.768h.77v-2.103zm-.64-4.203l2.096 2.172h-2.097v-2.172z"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default GoodDealValueIcon;