import styled from 'styled-components';
import { COLORS } from '../../../../constants/design'
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import GenericButton from '../../../../components/molecules/GenericButton';
import GenericMultipleSelect from '../../../../components/molecules/GenericMultipleSelect';

export const StyledButton = styled(GenericButton)`
  && {
    &.MuiButton-root {
      width: 260px;
      margin-right: 0px;
    }
  }
`;

export const CustomTabs = styled(Tabs)`
  background-color: #F7F7F7;
  border-radius: 10px;
  margin-left: 20px;
`;

export const CustomTab = styled(Tab)`
  &&{
    width: 33.33%;
    height: 5px;
    color: #676767;
    font-size: 0.725rem;
    text-transform: none;
    &.Mui-selected{
      background-color: ${COLORS.PRIMARY_BLUE};
      color: #FFFFFF;
    }
  }
`;

export const ContentContainer = styled.div`
  padding: 10px 20px;
  width: 100%;
  height: 100%;
`;

export const StyledMultipleSelect = styled(GenericMultipleSelect)`
  padding-bottom: 10px;
  .textTitle {
    font-weight: 600;
  }
`;
