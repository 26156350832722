import styled from "styled-components";
import Switch from "@material-ui/core/Switch";

import { COLORS } from '../../../constants/design';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  color: #5A5A5A;
`;

export const StyledSwitch = styled(Switch)`
  &&{
    &.MuiSwitch-root{
      padding: 0;
      margin: 0 0 0 6px;
      height: 22px;
      width: 42px;
      border-radius: 100px;
      .MuiSwitch-switchBase{
        padding: 1px;
        width: 22px;
        height: 22px;
        &.Mui-checked{
          color: #FFFFFF;
        }
        &.Mui-checked + .MuiSwitch-track{
          background-color: ${COLORS.GREEN};
          opacity: 1;
        }
      }
    }
  }
`;