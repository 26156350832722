import React, { useState, useEffect } from 'react';
import { Formik, Field } from 'formik';
import { differenceInMinutes } from 'date-fns';
import _ from 'lodash';

import { BOOKING_CANCEL_STATUS } from '../../../constants/bookingConstants';

import GenericTextArea from '../../../components/molecules/GenericTextArea';
import Loader from '../../../components/molecules/GenericLoader';

import RoomBookingCancellationInfo from '../RoomBookingCancellationInfo';

import {
  CustomDialog,
  DialogContainer,
  StyledTitle,
  StyledWarningText,
  StyledForm,
  StyledValidationButtons,
  LoaderContainer
} from './style';

const RoomBookingCancellationModal = ({
  bookingData,
  roomData,
  isUserConcierge,
  onClose,
  onConfirm,
  cancelBookingIsLoading
}) => {
  const [deadlineRespected, setDeadlineRespected] = useState(true);
  const [selecteRoomData, setSelectedRoomData] = useState(null);

  useEffect(() => {
    setDeadlineRespected(true);
    if (!isUserConcierge) {
      const now = new Date();
      const currentRoom = _.find(roomData, (data) => String(data.id) === String(bookingData.room.id));
      if (currentRoom) {
        const deadlineArray = [currentRoom.cancel_deadline];

        _.map(currentRoom.room, (data) => {
          deadlineArray.push(data.cancel_deadline);
        });
        _.map(bookingData.options, (option) => {
          deadlineArray.push(option.cancel_deadline);
        });
        const maxDelay = _.max(deadlineArray);
        const diff = differenceInMinutes(new Date(bookingData.start), now);
        if (maxDelay >= diff) {
          setDeadlineRespected(false);
        }
      }
    }
    if (bookingData) {
      const { room } = bookingData;
      const formatedRoomData = {
        name: room.name,
        cancel_fees: room.cancel_fees,
        cancel_deadline: room.cancel_deadline,
        options: bookingData.options,
      }
      setSelectedRoomData(formatedRoomData);
    }
  }, [bookingData, roomData]);

  const generateInitValue = () => {
    const initValue = {
      note: "",
    }
    return initValue;
  }

  const generateTitle = () => {
    return (`Annulation de la réservation  - ${bookingData.public_title}`)
  }

  const handleSubmit = (values) => {
    onConfirm({
      id: bookingData.id,
      note: values.note,
      status: deadlineRespected ? BOOKING_CANCEL_STATUS.NON_PAYABLE : BOOKING_CANCEL_STATUS.PAYABLE,
    });
  }

  // console.log('bookingData', bookingData);

  return (
    <CustomDialog
      open={true}
      onClose={onClose}
    >
      <DialogContainer>
        <StyledTitle
          title={generateTitle()}
        />

        <Formik
          initialValues={generateInitValue()}
          enableReinitialize
          setFieldValue
          onSubmit={handleSubmit}
        >
          {(props) => {
            if (cancelBookingIsLoading) {
              return (
                <LoaderContainer>
                  <Loader />
                </LoaderContainer>
              )
            }
            else {
              return (
                <StyledForm>
                  {!deadlineRespected &&
                    <StyledWarningText>
                      {`Votre réservation va être annulée. Cependant des frais d’annulation peuvent être appliqués. Merci de vous reporter aux conditions d’annulation.`}
                    </StyledWarningText>
                  }
                  {selecteRoomData &&
                    <RoomBookingCancellationInfo
                      selectedRoom={selecteRoomData}
                    />
                  }
                  <Field
                    id="note"
                    name="note"
                    title="Raison de l’annulation"
                    variant="outlined"
                    placeholder="Ajouter une note"
                    minRows={6}
                    toolTipText={``}
                    as={GenericTextArea}
                  />
                  <StyledValidationButtons
                    leftButonText={'Annuler'}
                    RightButtonText={'Annuler la réservation '}
                    onclickLefttButton={onClose}
                    submitable={true}
                  />
                </StyledForm>
              )
            }
          }}
        </Formik>
      </DialogContainer>
    </CustomDialog>
  )
};

export default RoomBookingCancellationModal;