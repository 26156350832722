import { all, takeEvery } from "redux-saga/effects";

/** Actions */
import * as a from './actions';

//Import handlers
import {
  samlLoginHandler,
  samlRegisterationHandler,
  azureadLoginHandler,
  azureadRegisterationHandler
} from './handler/samlHandler';

export default function* ssoSaga() {
  yield all([
    takeEvery(a.SAML_LOGIN.REQUEST, samlLoginHandler),
    takeEvery(a.SAML_REGISTERATION.REQUEST, samlRegisterationHandler),
    takeEvery(a.AZUREAD_LOGIN.REQUEST, azureadLoginHandler),
    takeEvery(a.AZUREAD_REGISTERATION.REQUEST, azureadRegisterationHandler),
  ]);
}
