import styled from "styled-components";
import { COLORS } from '../../../../constants/design';

export const MainContainer = styled.div`
  width: 500px;
  height: 100%;
  /* background: rgba(0,0,0,0.5); */
  /* min-height: 550px ; */
  background-color: #2E2E2E;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-around; */
`

export const PreviewHeaderTitle = styled.div`
  font-size: 0.8rem;
  font-weight: 600;
  width: 100%;
  padding: 10px 20px;
  color: white;
  /* border: 1px solid red; */
`

export const PreviewHeader = styled.div`
  width: 100%;
  /* height: 20%; */
  /* border: 1px solid red; */
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
`

export const PreviewHeaderContent = styled.div`
  height: 180px;
  width: 100%;
`

export const PreviewHeaderContentElement = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: ${props => props.top && ("6px 6px 0 0")};
  border-radius: ${props => props.bottom && ("0 0 6px 6px")};
`

export const PreviewHeaderContentImg = styled.img`
  height: 60px;
  width: 60px;
  object-fit: cover;
`

export const PreviewHeaderContentFakeImg = styled.div`
  height: 60px;
  width: 60px;
  background-color:  ${COLORS.LIGHT_GREY_2};
  border-radius: 6px;
`

export const PreviewHeaderContentTitle = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: #5A5A5A;
`

export const PreviewHeaderContenFakeTitle = styled.span`
  width: 160px;
  height: 10px;
  background-color: ${COLORS.LIGHT_GREY_2};
`

export const PreviewHeaderContentDate = styled.span`
  width: 81px;
  height: 22px;
  background-color: ${COLORS.LIGHT_GREY_2};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  color: #777777;
  margin-right: 15px;
`

export const PreviewHeaderContentFakeDate = styled.span`
  width: 81px;
  height: 10px;
  background-color: ${COLORS.LIGHT_GREY_2}; //#F7F7F7;
  margin-right: 15px;
`

export const PreviewBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 60%;
  padding: 0 20px 20px 20px;
`