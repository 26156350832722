import React, { useEffect, useState } from 'react';
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useRouteMatch } from "react-router-dom";
import _ from 'lodash';

import Routes from './routes';
import CurentRequestsTable from './components/CurentRequestsTable';
import ArchiveRequestsTable from './components/ArchiveRequestsTable';

import GenericLayout from '../../layouts/genericLayout';
import GenericAdminFrontHeader from '../../../../components/molecules/GenericAdminFrontHeader';
import GenericLoader from '../../../../components/molecules/GenericLoader';

import { saveTicketRequestSelectedSiteList } from '../../../../store/persistReducers/actions';
import { getTicketRequestSelectedSiteData } from '../../../../store/persistReducers/selectors';

import {
  getConciergeSitesData,
  getConciergeSitesIsLoading,
  getConciergeServicesData,
  getConciergeServicesLoading,
  getConciergeTicketRequestsData,
  getConciergeTicketRequestsLoading
} from '../../../../store/concierge/selectors';

import {
  StyledButton,
  CustomTab,
  CustomTabs,
  ContentContainer,
  StyledMultipleSelect
} from './style'

const Requests = () => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const [categorie, setCategorie] = useState(0);
  const [currentTicketRequests, setCurrentTicketRequests] = useState([]);
  const [archieveTicketRequests, setArchieveTicketRequests] = useState([]);
  const [sitesList, setSitesList] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [filteredSiteData, setFilteredSiteData] = useState([]);

  const {
    sitesData,
    siteIsLoading,
    servicesData,
    serviceIsLoading,
    ticketRequestData,
    ticketRequestLoading,
    selectedSiteListData
  } = useSelector(
    state => ({
      sitesData: getConciergeSitesData(state),
      siteIsLoading: getConciergeSitesIsLoading(state),
      servicesData: getConciergeServicesData(state),
      serviceIsLoading: getConciergeServicesLoading(state),
      ticketRequestData: getConciergeTicketRequestsData(state),
      ticketRequestLoading: getConciergeTicketRequestsLoading(state),
      selectedSiteListData: getTicketRequestSelectedSiteData(state)
    }),
    shallowEqual
  );

  useEffect(() => {
    if (selectedSiteListData !== null && selectedSiteListData.length > 0) {
      setSelectedList(selectedSiteListData);
    }
  }, [selectedSiteListData]);

  useEffect(() => {
    if (sitesData && selectedList.length > 0) {
      const newFilteredSiteData = _.compact(_.map(selectedList, (selected) => {
        return _.find(sitesData, (site) => site.id === selected.value);
      }));
      setFilteredSiteData(newFilteredSiteData);
    }
  }, [sitesData, selectedList]);


  useEffect(() => {
    if (sitesData != null) {
      const newList = sitesData.map((site) => {
        return { label: site.name, value: site.id }
      })
      setSitesList(newList);
    }
  }, [sitesData]);

  useEffect(() => {
    if (filteredSiteData && servicesData && ticketRequestData) {
      const currentTickets = [];
      const archivedTickets = [];
      ticketRequestData?.items.map((ticket) => {
        const site = filteredSiteData.find((data) => data.id == ticket.siteId);
        const service = servicesData.find((data) => data.id == ticket.serviceId);
        const formatedTicket = {
          ...ticket,
          siteName: site?.name || '',
          serviceName: service?.name || ''
        }
        ticket.status === 'archive' ? archivedTickets.push(formatedTicket) : currentTickets.push(formatedTicket);
      })
      setCurrentTicketRequests(currentTickets);
      setArchieveTicketRequests(archivedTickets);
    }
  }, [filteredSiteData, servicesData, ticketRequestData]) // To pack the data

  const handleChangeCategorie = (event, newValue) => {
    setCategorie(newValue)
  };

  const openTicketRequestDrawer = (id) => {
    history.push(`${path}/${id}`);
  }

  const openCreateNew = () => {
    history.push(`${path}/new`);
  };

  const saveSiteAndSelect = (e, value) => {
    dispatch(saveTicketRequestSelectedSiteList(value));
    setSelectedList(value)
  }


  return (
    <GenericLayout
      id="siteManagementLayout"
      className="siteManagementLayout"
    >
      <GenericAdminFrontHeader
        headerTitle={categorie === 0 ? 'Demandes' : "Demandes archivées"}
        headerDesc={categorie === 0 ? 'Retrouvez les demandes de vos bénéficiaires.' : "Retrouvez l'ensemble des demandes qui on été archivées"}
        RightSideComponent={
          <>
            <StyledButton
              title={`Créer une demande/une inscription`}
              onClick={openCreateNew}
            />
            <CustomTabs
              value={categorie}
              TabIndicatorProps={{ style: { display: "none" } }}
              onChange={handleChangeCategorie}
              aria-label="disabled tabs example"
            >
              <CustomTab label="Les demandes en cours" />
              <CustomTab label="Les demandes archivées" />
            </CustomTabs>
          </>
        }
      />
      <ContentContainer>
        {(siteIsLoading || serviceIsLoading || ticketRequestLoading) ?
          <GenericLoader />
          :
          <>
            <StyledMultipleSelect
              title={"Sélectionnez votre site"}
              list={sitesList}
              value={selectedList}
              limitItem={10}
              hasSelectAll={true}
              onChange={saveSiteAndSelect}
            />

            {categorie === 0 ?
              <CurentRequestsTable
                currentTicketRequests={currentTicketRequests}
                currentSites={selectedList}
                servicesData={servicesData}
                onRequestClick={(id) => openTicketRequestDrawer(id)}
              />
              :
              <ArchiveRequestsTable
                archieveTicketRequests={archieveTicketRequests}
                currentSites={selectedList}
                servicesData={servicesData}
                onRequestClick={(id) => openTicketRequestDrawer(id)}
              />
            }
          </>
        }
      </ContentContainer>
      <Routes />
    </GenericLayout>
  )
}

export default Requests