import React, { useState, useEffect } from 'react'
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'formik';

import GenericTitleWithToolTip from '../GenericTitleWithToolTip'
import GenericErrorMessage from '../GenericErrorMessage';

//Import Formik
import { Field } from 'formik';

import _ from 'lodash';


//Import style 
import {
  ListTypeContainer,
  CustomTextFild,
  ListTitle,
  AutocompleteCustom,
  ContentSiteDiv,
  CustomRadioDiv,
  CustomRadio,
  CustomChip
} from './style'



const theme = createTheme({
  palette: {
    primary: {
      main: '#4891E3'
    }
  },
  overrides: {
    MuiChip: {
      root: {
        fontSize: '12px',
        fontWeight: '500'
      }
    },
  }
});

const MyChip = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <CustomChip color="primary" label={props.label} onDelete={props.onDelete} />
    </ThemeProvider>
  );
};

const GenericMultipleSelect = ({
  list,
  fildName,
  title,
  toolTipText = null,
  limitItem,
  isRequired = false,
  shouldRenderInputLabel = true,
  className, // to pass styled component className to ListTypeContainer
  value,
  disabled = false,
  onChange,
  hasSelectAll = false,
  ...rest }) => {

  const [selectedValue, setSelectedValue] = useState([]);
  const [optionsList, setOptionsList] = useState([]);
  const [allSelected, setAllSelected] = useState(false);

  useEffect(() => {                                       // we must always refer to the initial list, so this component could work properly (Thank MUI! u shit)
    if (optionsList.length > 0) {                         // this useeffect is here to solve that 
      const newValue = _.map(value, (data) => {
        return _.find(optionsList, (item) => _.isEqual(data, item));
      })
      setSelectedValue(newValue);
    }
  }, [value, optionsList]);

  useEffect(() => {
    if (hasSelectAll === true && list) {
      const newList = [{ label: 'Tout sélectionner', value: 'all' }, ...list]
      setOptionsList(newList);
    } else {
      setOptionsList(list);
    }
  }, [list]);


  const handleOnChange = (event, value) => {
    let newValue = value;
    if (hasSelectAll) {
      if (!allSelected && value.find(el => el.value === 'all')) {
        setAllSelected(true);
        newValue = optionsList;
      }
      else if (allSelected && !!value.find(el => el.value === 'all') === false) {
        setAllSelected(false);
        newValue = [];
      }
    }
    const newSelectedValue = _.map(newValue, (data) => {
      return _.find(optionsList, (item) => _.isEqual(data, item));
    })
    setSelectedValue(newSelectedValue);
    const filteredList = newValue.filter(el => el.value !== 'all');
    onChange(event, filteredList);
  }

  return (
    <ContentSiteDiv className={`${className ? className : ''} genericMultipleSelect`} disabled={disabled} >
      <GenericTitleWithToolTip
        title={title}
        toolTipText={toolTipText}
        isRequired={isRequired}
        className={'genericMultipleSelectTitle'}
      />
      <ListTypeContainer>
        <AutocompleteCustom
          multiple
          options={optionsList}
          limitTags={limitItem}
          disableCloseOnSelect
          getOptionLabel={(option) => option.label}
          className={'genericMultipleSelectAutoComplete'}
          value={selectedValue}
          {...rest}
          onChange={handleOnChange}
          renderTags={(tagValue, getTagProps) => {
            if (shouldRenderInputLabel) {
              return tagValue.map((option, index) => (
                <MyChip {...getTagProps({ index })} label={option?.label} />
              ));
            }
          }}
          renderOption={(option, { selected }) => {
            return (
              <React.Fragment>
                <CustomRadioDiv>
                  <CustomRadio style={{ backgroundColor: selected ? '#4891E3' : '#EEEEEE' }} />
                </CustomRadioDiv>
                <ListTitle>
                  {option.label}
                </ListTitle>
              </React.Fragment>
            )
          }}
          renderInput={(params) => (
            <ThemeProvider theme={theme}>
              <CustomTextFild
                {...params}
                variant="outlined"
                size="small"
              />
            </ThemeProvider>
          )}
        />
      </ListTypeContainer >
      {rest.id && <GenericErrorMessage name={rest.id} />}
    </ContentSiteDiv>
  )
}

GenericMultipleSelect.propTypes = {
  list: PropTypes.array,
  fildName: PropTypes.string,
  title: PropTypes.string,
  toolTipText: PropTypes.string,
  isRequired: PropTypes.bool,
  shouldRenderInputLabel: PropTypes.bool,
  className: PropTypes.string,
}

export default GenericMultipleSelect;