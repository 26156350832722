import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
// Sagas
import authentication from './authentication/sagas';
import collaborator from './collaborator/sagas';
import sidebar from './sidebar/sagas';
import adminFront from './adminFront/sagas';
import userManagement from './userManagement/sagas';
import onboarding from './onboarding/sagas';
import chatbot from './chatbot/sagas';
import config from './config/sagas';
import company from './company/sagas';
import concierge from './concierge/sagas';
import contentManagement from './contentManagement/sagas';
import getGround from './getGround/sagas';
import sso from './sso/sagas';
import roomBooking from './roomBooking/sagas';
import contextSetting from './contextSetting/sagas';

export const sagaMiddleware = createSagaMiddleware();

export default function* rootSaga() {
  yield all([
    fork(authentication),
    fork(collaborator),
    fork(sidebar),
    fork(adminFront),
    fork(userManagement),
    fork(onboarding),
    fork(chatbot),
    fork(config),
    fork(company),
    fork(concierge),
    fork(contentManagement),
    fork(getGround),
    fork(sso),
    fork(roomBooking),
    fork(contextSetting),
  ]);
}
