import * as a from "./actions";
import _ from 'lodash';

const initGetValue = {
  loading: false,
  success: false,
  error: null,
}

const modifyGet = (type, error = null) => {
  return {
    loading: type === 'request' ? true : false,
    success: type === 'success' ? true : false,
    error: type === 'error' ? error : null,
  }
}

const INITIAL_STATE = {
  newsContent: {
    data: null,
    get: initGetValue,
  },
  selectedNewsContent: {
    data: null,
    get: initGetValue,
  },
  goodDeals: {
    data: null,
    get: initGetValue,
  },
  selectedGoodDeal: {
    data: null,
    get: initGetValue,
  },
};

export function contentManagement(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case a.GET_NEWS_CONTENT.REQUEST:
      return {
        ...state,
        newsContent: {
          data: null,
          get: modifyGet('request')
        },
      };
    case a.GET_NEWS_CONTENT.SUCCESS:
      return {
        ...state,
        newsContent: {
          data: action.data,
          get: modifyGet('success')
        },
      };
    case a.GET_NEWS_CONTENT.FAILURE:
      return {
        ...state,
        newsContent: {
          data: null,
          get: modifyGet('error', action.error)
        },
      };
    case a.UPDATE_NEWS_CONTENT.REQUEST:
      return {
        ...state,
        newsContent: {
          data: state.newsContent.data,
          get: modifyGet('request')
        },
      };
    case a.UPDATE_NEWS_CONTENT.SUCCESS:
      return {
        ...state,
        newsContent: {
          data: { ...state.newsContent.data, items: _.unionBy([action.data], state.newsContent.data.items, "id") },
          get: modifyGet('success')
        },
      };
    case a.UPDATE_NEWS_CONTENT.FAILURE:
      return {
        ...state,
        newsContent: {
          data: state.newsContent.data,
          get: modifyGet('error', action.error)
        },
      };
    case a.DELETE_NEWS_CONTENT.REQUEST:
      return {
        ...state,
        newsContent: {
          data: state.newsContent.data,
          get: modifyGet('request')
        }
      };
    case a.DELETE_NEWS_CONTENT.SUCCESS:
      return {
        ...state,
        newsContent: {
          data: { ...state.newsContent.data, items: state.newsContent.data.items.filter((item) => item.id !== action.data) },
          get: modifyGet('success')
        }
      };
    case a.DELETE_NEWS_CONTENT.FAILURE:
      return {
        ...state,
        newsContent: {
          data: state.newsContent.data,
          get: modifyGet('error', action.error)
        }
      };
    case a.GET_NEWS_CONTENT_BY_ID.REQUEST:
      return {
        ...state,
        selectedNewsContent: {
          data: null,
          get: modifyGet('request')
        }
      };
    case a.GET_NEWS_CONTENT_BY_ID.SUCCESS:
      return {
        ...state,
        selectedNewsContent: {
          data: action.data,
          get: modifyGet('success')
        }
      };
    case a.GET_NEWS_CONTENT_BY_ID.FAILURE:
      return {
        ...state,
        selectedNewsContent: {
          data: null,
          get: modifyGet('error', action.error)
        }
      };
    case a.CLEAR_SELECTED_NEWS_CONTENT.REQUEST:
      return {
        ...state,
        selectedNewsContent: {
          data: null,
          get: modifyGet('success')
        },
      };
    case a.GET_GOOD_DEALS.REQUEST:
      return {
        ...state,
        goodDeals: {
          data: null,
          get: modifyGet('request')
        },
      };
    case a.GET_GOOD_DEALS.SUCCESS:
      return {
        ...state,
        goodDeals: {
          data: action.data,
          get: modifyGet('success')
        },
      };
    case a.GET_GOOD_DEALS.FAILURE:
      return {
        ...state,
        goodDeals: {
          data: null,
          get: modifyGet('error', action.error)
        },
      };
    case a.UPDATE_GOOD_DEAL.REQUEST:
      return {
        ...state,
        goodDeals: {
          data: state.goodDeals.data,
          get: modifyGet('request')
        },
      };
    case a.UPDATE_GOOD_DEAL.SUCCESS:
      return {
        ...state,
        goodDeals: {
          data: { ...state.goodDeals.data, items: _.unionBy([action.data], state.goodDeals.data.items, "id") },
          get: modifyGet('success')
        },
      };
    case a.UPDATE_GOOD_DEAL.FAILURE:
      return {
        ...state,
        goodDeals: {
          data: state.goodDeals.data,
          get: modifyGet('error', action.error)
        },
      };
    case a.DELETE_GOOD_DEAL.REQUEST:
      return {
        ...state,
        goodDeals: {
          data: state.goodDeals.data,
          get: modifyGet('request')
        }
      };
    case a.DELETE_GOOD_DEAL.SUCCESS:
      return {
        ...state,
        goodDeals: {
          data: { ...state.goodDeals.data, items: state.goodDeals.data.items.filter((item) => item.id !== action.data) },
          get: modifyGet('success')
        }
      };
    case a.DELETE_GOOD_DEAL.FAILURE:
      return {
        ...state,
        goodDeals: {
          data: state.goodDeals.data,
          get: modifyGet('error', action.error)
        }
      };
    case a.GET_GOOD_DEAL_BY_ID.REQUEST:
      return {
        ...state,
        selectedGoodDeal: {
          data: null,
          get: modifyGet('request')
        }
      };
    case a.GET_GOOD_DEAL_BY_ID.SUCCESS:
      return {
        ...state,
        selectedGoodDeal: {
          data: action.data,
          get: modifyGet('success')
        }
      };
    case a.GET_GOOD_DEAL_BY_ID.FAILURE:
      return {
        ...state,
        selectedGoodDeal: {
          data: null,
          get: modifyGet('error', action.error)
        }
      };
    case a.CLEAR_SELECTED_GOOD_DEAL.REQUEST:
      return {
        ...state,
        selectedGoodDeal: {
          data: null,
          get: modifyGet('success')
        },
      };
    default:
      return state;
  }
}

export default contentManagement;
