import styled from "styled-components";
import TableRow from '@material-ui/core/TableRow';

export const FeedbacksIconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  width: 12px;
  height: 12px;
  margin-right: 10px;
`;

export const StyledTableCell = styled.td`
    position: relative;
    padding: ${({ sub }) => sub ? "0 10px 0 0" : "0"};
    border-bottom: 1px solid #F7F7F7;
    width: ${({ sub }) => sub ? "auto" : "100%"};
    min-width: ${({ sub }) => sub ? "330px" : "initial"};
    font-size: ${({ sub }) => sub ? "14px" : "16px"};
    font-weight: 700;
    color: ${({ sub }) => sub ? "#777777" : "#5A5A5A"};
    line-height: ${({ sub }) => sub ? "16px" : "18px"};
    vertical-align: middle;
    &:first-child{
      // CHANGED
      padding: 0px 0 0 20px;
    }
    span{
      text-align: ${({ sub }) => sub ? "right" : "left"};
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
`;

export const TagDiv = styled.span`
  position: absolute;
  height: 16px;
  width: 6px;
  background-color: #357ED3;
  border-radius: 100px;
  left: 0;
  top: 22px;
`;

export const TableRowCustom = styled(TableRow)`
  &&{
    &.MuiTableRow-root{
      height: 60px;
    }
  }
`;
