import styled from 'styled-components';

import { COLORS } from '../../constants/design';
import breakpoints from "../../../../theme-style/breakpoints";

export const StyledMainContainer = styled.div`
  align-items: center;
  display: flex;
  height: auto;
  // position: sticky;
  // bottom: 0px;
  // padding: 0 0 8px 0;
  // background-color: #ffffff;
`;

export const StyledSuggestionOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  align-items: flex-end;
  transition: all 0.4s ease-in;
  background-color: ${props => props.isSuggestionActive ? 'rgba(0,0,0,0.5)' : 'rgba(0,0,0,0)'};
  visibility: ${props => props.isSuggestionActive ? 'visible' : 'hidden'};
`;

export const StyledSuggestionItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: space-around;
  left: 10px;
  bottom: 40px;
  transition: all 0.5s ease-out;
  overflow: hidden;
  z-index: 10;
  visibility: ${props => props.isSuggestionActive ? 'visible' : 'hidden'};
  max-height: ${props => props.isSuggestionActive ? '100%' : '0%'};
  width: calc(100% - 20px);
  pointer-events: none;
  @media ${breakpoints.mobileXL} {
    bottom: 90px;
  }
`;

export const StyledSuggestionItem = styled.div`
  box-sizing: border-box;
  font-family: "Quicksand";
  font-size: 0.8rem;
  font-weight: 500;
  margin: 4px 0px;
  display: inline-flex;
  align-items: center;
  border-radius: 12px;
  min-height: 40px;
  width: fit-content;
  min-width: 50px;
  padding: 10px 15px;
  cursor: pointer;
  transition: all 0.2s ease-out;
  background-color: ${COLORS.SLIGHT_GREY};
  text-decoration: none;
  color: black;
  pointer-events: visible;
  @media ${breakpoints.mobileXL} {
    margin: 5px 0px;
  }
  :hover {
    background-color: ${COLORS.GREEN};
    color: ${COLORS.WHITE};
  }
`;

export const StyledSuggestionIconContainer = styled.div`
  position: relative;
`;

export const StyledSuggestionIndicator = styled.div`
  visibility: ${props => props.isIndicatorVisible ? 'visible' : 'hidden'};
  margin: 0px 10px;
  width: 190px;
  position: absolute;
  top: ${props => props.icon === 'send' ? '-60px': '-40px'};
  left: ${props => props.icon === 'send' ? 'unset': 0};
  right: ${props => props.icon === 'send' ? 0 : 'unset'};
  padding: 4px 8px 6px 6px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: ${COLORS.WHITE};
  background-color: rgba(0, 0, 0, 0.7);
  animation: infobulleAnimation 1s infinite;
  ::after {
    content: '';
    position: absolute;
    bottom: -6px;
    left: ${props => props.icon === 'send' ? 'unset' : '24px'};
    right: ${props => props.icon === 'send' ? '24px' : 'unset'};
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 6px 0 6px;
    border-radius: 1px;
    border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent;
  }
  @keyframes infobulleAnimation {
    0% {
      top: ${props => props.icon === 'send' ? '-60px': '-40px'};
    }
    50% {
      top: ${props => props.icon === 'send' ? '-72px': '-52px'};
    }
    100% {
      top: ${props => props.icon === 'send' ? '-60px': '-40px'};
    }
  }
`;

export const StyledForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const StyledTextField = styled.input`
  box-sizing: border-box;
  background-color: ${COLORS.SLIGHT_GREY};
  border-radius: 6px;
  border: 1.4px solid ${COLORS.LIGHT_GREY_1};
  color: ${COLORS.DARK_GREY};
  font-family: "Quicksand";
  font-size: 0.8em;
  font-weight: 500;
  height: 60px;
  max-height: 200px;
  outline: none;
  padding: 20px;
  white-space: pre-wrap;
  width: 100%;
  word-wrap: break-word;
  box-shadow: ${props => props.textInputFocus ? '0 0 20px rgba(52, 126, 211, 0.7)' : '0' };
  transition: all .2s ease-out;
  -webkit-font-smoothing: antialiased;
  :hover {
    background-color: ${COLORS.LIGHT_GREY_1};
  }
  :focus {
    border: 1.4px solid ${COLORS.LIGHT_GREY_1};
  }
`;

export const StyledInputIcon = styled.div`
  align-items: center;
  background-color: ${props => props.isFocus ? COLORS.GREEN : COLORS.SLIGHT_GREY};
  border-radius: 6px;
  border: 1.4px solid ${COLORS.LIGHT_GREY_1};
  cursor: pointer;
  display: flex;
  height: 60px;
  justify-content: center;
  margin: 0px 10px;
  min-width: 60px;
  transition: all .2s ease-out;
  :hover {
    background-color: ${COLORS.GREEN};
    && {
      .MuiSvgIcon-root{
        color: ${COLORS.SLIGHT_GREY};
      }
    }
  }
  && {
    .MuiSvgIcon-root{
      z-index: 1;
      font-size: 1.5rem;
      color: ${props => props.isFocus ? COLORS.SLIGHT_GREY : COLORS.GREEN};
    }
  }
  :after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 100px;
    border: 1px solid ${COLORS.WHITE};
    opacity: 0;
    animation: ${props => props.isIndicatorVisible ? 'scaleIn 2s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32)' : 'none'};
    transition: all 0.1s ease-out;
    animation-delay: 0s;
  }
  @keyframes scaleIn {
    0% {
      -webkit-transform: scale(0.5, 0.5);
      transform: scale(0.5, 0.5);
      opacity: 0.6;
    }
    100% {
      -webkit-transform: scale(2.5, 2.5);
      transform: scale(2.5, 2.5);
      opacity: 0;
      background-color: ${COLORS.WHITE};
    }
  }
`;

export const StyledSuggestionIcon = styled(StyledInputIcon)`
  background-color: ${props => props.isIndicatorVisible ? COLORS.PRIMARY_BLUE : COLORS.SLIGHT_GREY};
  :hover {
    background-color: ${COLORS.PRIMARY_BLUE};
  }
  && {
    .MuiSvgIcon-root{
      color: ${props => props.isIndicatorVisible ? COLORS.SLIGHT_GREY : COLORS.PRIMARY_BLUE};
    }
  }
  :after {
    background-color: ${COLORS.PRIMARY_BLUE};
  }
`;

export const StyledInputIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 10px;
  position: absolute;
  padding: 4px 8px 6px 6px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: ${COLORS.WHITE};
  top: 25%;
  animation-name: abc;
  animation-duration: 1s;
  animation-timing-function: linear;
  @keyframes abc {
    0% { left: 0%; }
    100% { left: 100% }
  }
  .MuiSvgIcon-root{
    z-index: 1;
    font-size: 1.5rem;
    color: ${COLORS.GREEN};
  }
`;