import React, { useState, useEffect } from 'react';
import {
  shallowEqual,
  useDispatch,
  useSelector,
} from "react-redux";
import moment from 'moment';
import { Link } from 'react-router-dom';

// Import services
// import getUntreatedFbBySkill from '../../services/getUntreatedFbBySkill.service';
import {
  getUntreatedFbBySkillData,
} from '../../../../../../store/adminFront/selectors';
import {
  getUntreatedFbBySkill,
} from '../../../../../../store/adminFront/actions';

import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FeedbackIcon from '@material-ui/icons/Feedback';
// components
import EnhancedTableToolbar from './components/tableToolbar'

// Styles
import {
  Container,
  Wrapper,
  PaperCustom,
  TableCellCustom,
  TableCustom,
  TableRowCustom,
  TableCellContainer,
  RowTitle,
  CellContentContainer,
  RowContent,
  RowContentRow,
  TableRowContentContainer,
  TableRowContainer,
  TheadRowTitle,
  TbodyRowContent,
  TheadRowContentRow,
  TbodyRowContentRow,
  RowContentRowTd,
  RowContentRowTable,
  RowContentRowThead,
  RowContentRowTbody,
  RowContentRowSubRow,
  Divider,
  Divider2
} from "./style";

const TableFeedbackByCompetence = ({
  id = 'Title headCell',
  titleToolbar = null,
  checkboxActive = false,
  densePadding = false
}) => {
  const dispatch = useDispatch();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [rows, setRows] = useState(null);

  const {
    untreatedFbBySkill,
  } = useSelector(
    state => ({
      untreatedFbBySkill: getUntreatedFbBySkillData(state),
    }),
    shallowEqual
  );


  useEffect(() => {
      dispatch(getUntreatedFbBySkill());
  }, []);

  useEffect(() => {
    if (!!untreatedFbBySkill?.items) {
      const rows = Object.keys(untreatedFbBySkill?.items).map((item, index) => {
      // console.log('untreatedFbBySkill', untreatedFbBySkill);
        
      const {
          expertiseArea,
          skillDesc,
          skillId,
          skillTitle,
          subjects
        } = untreatedFbBySkill.items[item];
        const indexKey = `key-untreatedFbBySkill-${index}`;

        return {
          indexKey,
          expertiseArea,
          skillDesc,
          skillId,
          skillTitle,
          subjects
        };
      });
      setRows(rows)
    }
      
  }, [untreatedFbBySkill]);

  // useEffect(() => {
  //   getUntreatedFbBySkill().then(data => {
  //     const rows = Object.keys(data.items).map((item, index) => {
  //       const {
  //         expertiseArea,
  //         skillDesc,
  //         skillId,
  //         skillTitle,
  //         subjects
  //       } = data.items[item];
  //       const indexKey = `key-untreatedFbBySkill-${index}`;

  //       return {
  //         indexKey,
  //         expertiseArea,
  //         skillDesc,
  //         skillId,
  //         skillTitle,
  //         subjects
  //       };
  //     });
  //     setRows(rows)
  //   });
  // }, []);

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rows ? (rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)) : null;

  console.log('Get Untreated FeedBack by Competence', rows);

  return (
    <Container>
      {densePadding && (
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        />
      )}
      <Wrapper>
        <PaperCustom>
          {titleToolbar || checkboxActive && (
            <EnhancedTableToolbar
              numSelected={selected.length}
              titleToolbar={titleToolbar}
            />
          )}
          <TableContainer>
            {!!rows?.length &&
              <TableCustom
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
                aria-label="enhanced table"
              >
                {/*<EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                    id={id || 'nameHeadCell'}
                    numeric={numeric || false}
                    disablePadding={disablePadding || false}
                    label={label}
                    checkboxactive={checkboxActive || false}
                  />*/}
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      const checkboxActiveVal = checkboxActive || false;

                      const {
                        indexKey,
                        expertiseArea,
                        skillDesc,
                        skillId,
                        skillTitle,
                        subjects
                      } = row;

                      return (
                        <>
                          <TableRowCustom
                            hover
                            onClick={checkboxActiveVal ? (event) => handleClick(event, row.id) : null}
                            role="checkbox"
                            checkboxactive={checkboxActiveVal ? checkboxActiveVal : null}
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={indexKey}
                            selected={isItemSelected}
                          >
                            <TableCellContainer colSpan={6}>
                              <TableRowContainer>

                                <TheadRowTitle>
                                  <RowTitle className="row-title">
                                    <TableCellCustom colSpan={6} className="cell-title" align="left">
                                      <span>{skillTitle}<strong>{expertiseArea}</strong></span>
                                      {/*<p style={{ lineHeight: "18px" }}>{skillDesc.replace(/(<([^>]+)>)/ig, '')}</p>*/}
                                    </TableCellCustom>
                                  </RowTitle>
                                </TheadRowTitle>

                                <TbodyRowContent>
                                  {Object.keys(subjects).map((itemSubject, indexSubject) => {
                                    const {
                                      countEi,
                                      countFeedbacks,
                                      ei,
                                      subjectId,
                                      subjectTitle,
                                    } = subjects[itemSubject];
                                    const indexKeySubject = `key-untreatedFbBySkillSubject-${indexSubject}`;
                                    // console.log('countFeedbacks', countFeedbacks);
                                    // console.log('countEi', countEi);
                                    return (
                                      <RowContent key={indexKeySubject} className="row-content">
                                        <CellContentContainer colSpan={6}>
                                          <TableRowContentContainer>

                                            <TheadRowContentRow>
                                              <Divider2 className="dividerTwo"/>
                                              <RowContentRow className="row-content-title">
                                                <TableCellCustom colSpan={3} className="cell-content-title" align="left">
                                                  <span>{subjectTitle}</span>
                                                </TableCellCustom>
                                                <TableCellCustom colSpan={3} className="cell-content-text" align="right">
                                                  <span>{countFeedbacks} remarque(s) non traitée(s) pour {countEi} élément(s) d’information</span>
                                                </TableCellCustom>
                                              </RowContentRow>
                                            </TheadRowContentRow>

                                            <TbodyRowContentRow>
                                              {Object.keys(ei).map((itemEi, indexEi) => {
                                                const {
                                                  eiId,
                                                  eiTitle,
                                                  feedbacks
                                                } = ei[itemEi];
                                                const indexKeyEi = `key-untreatedFbBySkillEi-${indexEi}`;
                                                // console.log('ei', ei);
                                                // console.log('itemEi', itemEi);
                                                // console.log('feedbacks', feedbacks);

                                                return (
                                                  <RowContentRow key={indexKeyEi} className="row-content-info">
                                                    <RowContentRowTd colSpan={6}>
                                                      <RowContentRowTable>

                                                        <RowContentRowThead>
                                                          <RowContentRowSubRow className="row-content-subtitle">
                                                            <TableCellCustom colSpan={2} className="cell-content-subtitle" align="left">
                                                              <span>{eiTitle}</span>
                                                            </TableCellCustom>
                                                            <TableCellCustom colSpan={1} className="cell-content-headLabel cell-content-date" align="left">
                                                              <span>Date</span>
                                                            </TableCellCustom>
                                                            <TableCellCustom colSpan={1} className="cell-content-headLabel cell-content-type" align="left">
                                                              <span>Type</span>
                                                            </TableCellCustom>
                                                            <TableCellCustom colSpan={1} className="cell-content-headLabel cell-content-site" align="left">
                                                              <span>Site rattaché à l'élément d'information</span>
                                                            </TableCellCustom>
                                                            <TableCellCustom colSpan={1} className="cell-content-headLabel cell-content-collaborator" align="left">
                                                              <span>Collaborateur</span>
                                                            </TableCellCustom>
                                                          </RowContentRowSubRow>
                                                        </RowContentRowThead>

                                                        <RowContentRowTbody>
                                                          {Object.keys(feedbacks).map((itemFeedbacks, indexFeedbacks) => {
                                                            const {
                                                              collaborator,
                                                              comment,
                                                              companySite,
                                                              date,
                                                              feedbackId,
                                                              type,
                                                            } = feedbacks[itemFeedbacks];
                                                            const indexKeyFeedbacks = `key-untreatedFbBySkillFeedbacks-${indexFeedbacks}`;

                                                            // console.log('feedbacks[itemFeedbacks]', feedbacks[itemFeedbacks]);

                                                            return (
                                                              <RowContentRowSubRow key={indexKeyFeedbacks} className="row-content-info">

                                                                <TableCellCustom
                                                                  colSpan={2}
                                                                  className="cell-content-feedback"
                                                                  align="left"
                                                                >
                                                                  <Link  style={{textDecoration: "none"}} to={"/admin/feeds/competence-feedback?opendrawerbyid=" + eiId}>
                                                                      <span>
                                                                        <FeedbackIcon
                                                                          fontSize='small'
                                                                          style={{
                                                                            fontSize: '14px',
                                                                            fill: "#357ed3",
                                                                            marginBottom: -4,
                                                                            marginRight: 8
                                                                          }}
                                                                        />
                                                                        {comment}
                                                                      </span>
                                                                  </Link>
                                                                </TableCellCustom>

                                                                <TableCellCustom colSpan={1} className="cell-content-info" align="right">
                                                                   <Link  style={{textDecoration: "none"}} to={"/admin/feeds/competence-feedback?opendrawerbyid=" + eiId}>
                                                                      <span>{moment(date).format("DD/MM/YYYY hh:mm")}</span>
                                                                  </Link>
                                                                </TableCellCustom>

                                                                <TableCellCustom colSpan={1} className="cell-content-info" align="right">
                                                                   <Link  style={{textDecoration: "none"}} to={"/admin/feeds/competence-feedback?opendrawerbyid=" + eiId}>
                                                                      <span>{type}</span>
                                                                  </Link>
                                                                </TableCellCustom>

                                                                <TableCellCustom colSpan={1} className="cell-content-info" align="right">
                                                                   <Link  style={{textDecoration: "none"}} to={"/admin/feeds/competence-feedback?opendrawerbyid=" + eiId}>
                                                                      <span>{companySite}</span>
                                                                  </Link>
                                                                </TableCellCustom>

                                                                <TableCellCustom colSpan={1} className="cell-content-info" align="right">
                                                                   <Link  style={{textDecoration: "none"}} to={"/admin/feeds/competence-feedback?opendrawerbyid=" + eiId}>
                                                                      <span>{collaborator}</span>
                                                                  </Link>
                                                                </TableCellCustom>

                                                              </RowContentRowSubRow>
                                                            )
                                                          })}
                                                        </RowContentRowTbody>

                                                      </RowContentRowTable>
                                                    </RowContentRowTd>
                                                  </RowContentRow>
                                                )
                                              })}

                                            </TbodyRowContentRow>

                                          </TableRowContentContainer>
                                        </CellContentContainer>
                                      </RowContent>
                                    )
                                  })}

                                </TbodyRowContent>

                              </TableRowContainer>
                            </TableCellContainer>
                          </TableRowCustom>

                          <Divider  className="dividerOne"/>
                        </>
                      );
                    })}
                  {/*emptyRows > 0 && (
                    <TableRowCustom style={{ height: (dense ? 33 : 53) * emptyRows }}>
                      <TableCellCustom colSpan={7} />
                    </TableRowCustom>
                  )*/}
                </TableBody>
              </TableCustom>
            }
          </TableContainer>
          {/*<TablePaginationCustom
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />*/}
        </PaperCustom>
      </Wrapper>
    </Container>
  );
}

export default TableFeedbackByCompetence;
