import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
  min-height: 100vh;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  /* justify-content: flex-start; */
`;

export const ColumnWrapperFeedback = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 20px;
  width: 50%;
  max-width: 650px;
`;

export const ColumnWrapperInfoElement = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 20px;
  width: 50%;
  max-width: 650px;
`;

export const ColumnWrapperActivity = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  max-width: 26%;
`;
