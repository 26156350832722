export const actionGenerator = (value) => ({
  REQUEST: `CONCIERGE/${value}/REQUEST`,
  SUCCESS: `CONCIERGE/${value}/SUCCESS`,
  FAILURE: `CONCIERGE/${value}/FAILURE`,
})

export const GET_CORPORATIONS = actionGenerator('GET_CORPORATIONS');
export const GET_SITES = actionGenerator('GET_SITES');
export const GET_CONCIERGES = actionGenerator('GET_CONCIERGES');
export const GET_PROVIDERS = actionGenerator('GET_PROVIDERS');
export const GET_SERVICES = actionGenerator('GET_SERVICES');
export const GET_SERVICE_ACTIFS = actionGenerator('GET_SERVICE_ACTIFS');
export const GET_INTERNAL_SERVICE_FORM = actionGenerator('GET_INTERNAL_SERVICE_FORM');
export const GET_INTERNAL_SERVICE_FORM_SCHEMA = actionGenerator('GET_INTERNAL_SERVICE_FORM_SCHEMA');
export const UPDATE_SERVICE_ACTIF = actionGenerator("UPDATE_SERVICE_ACTIF")
export const GET_TICKET_REQUESTS = actionGenerator('GET_TICKET_REQUESTS');
export const SAVE_TICKET_REQUESTS = actionGenerator('SAVE_TICKET_REQUESTS');
export const SAVE_TICKET_MESSAGE = actionGenerator('SAVE_TICKET_MESSAGE');
export const DELETE_TICKET_MESSAGE = actionGenerator('DELETE_TICKET_MESSAGE');
export const GET_CALENDAR_OBJECTS = actionGenerator('GET_CALENDAR_OBJECTS');
export const SAVE_CALENDAR_OBJECT = actionGenerator('SAVE_CALENDAR_OBJECT');
export const DELETE_CALENDAR_OBJECT = actionGenerator('DELETE_CALENDAR_OBJECT');
export const SAVE_PROVIDER = actionGenerator('SAVE_PROVIDER');
export const DELETE_PROVIDER = actionGenerator("DELETE_PROVIDER");
export const UPDATE_CONCIERGE = actionGenerator("UPDATE_CONCIERGE")

// Corporations
export const getCorporations = () => ({
  type: GET_CORPORATIONS.REQUEST,
});

export const getCorporationsSuccess = (data) => ({
  type: GET_CORPORATIONS.SUCCESS,
  data
});

export const getCorporationsError = (error) => ({
  type: GET_CORPORATIONS.FAILURE,
  error
});

// Internal form
export const getInternalServiceForm = () => ({
  type: GET_INTERNAL_SERVICE_FORM.REQUEST,
});

export const getInternalServiceFormSuccess = (data) => ({
  type: GET_INTERNAL_SERVICE_FORM.SUCCESS,
  data
});

export const getInternalServiceFormError = (error) => ({
  type: GET_INTERNAL_SERVICE_FORM.FAILURE,
  error
});

export const getInternalServiceFormSchema = (data) => ({
  type: GET_INTERNAL_SERVICE_FORM_SCHEMA.REQUEST,
  data
});

export const getInternalServiceFormSchemaSuccess = (data) => ({
  type: GET_INTERNAL_SERVICE_FORM_SCHEMA.SUCCESS,
  data
});

export const getInternalServiceFormSchemaError = (error) => ({
  type: GET_INTERNAL_SERVICE_FORM_SCHEMA.FAILURE,
  error
});

// Sites
export const getSites = () => ({
  type: GET_SITES.REQUEST,
});

export const getSitesSuccess = (data) => ({
  type: GET_SITES.SUCCESS,
  data
});

export const getSitesError = (error) => ({
  type: GET_SITES.FAILURE,
  error
});

// Concierges
export const getConcierges = () => ({
  type: GET_CONCIERGES.REQUEST,
});

export const getConciergesSuccess = (data) => ({
  type: GET_CONCIERGES.SUCCESS,
  data
});

export const getConciergesError = (error) => ({
  type: GET_CONCIERGES.FAILURE,
  error
});

// Providers
export const getProviders = () => ({
  type: GET_PROVIDERS.REQUEST,
});

export const getProvidersSuccess = (data) => ({
  type: GET_PROVIDERS.SUCCESS,
  data
});

export const getProvidersError = (error) => ({
  type: GET_PROVIDERS.FAILURE,
  error
});

// save new prestatair
export const saveProviderRequests = (data) => ({
  type: SAVE_PROVIDER.REQUEST,
  data
});

export const saveProviderSuccess = (data) => ({
  type: SAVE_PROVIDER.SUCCESS,
  data
});

export const saveProviderError = (error) => ({
  type: SAVE_PROVIDER.FAILURE,
  error
});

// delete new prestatair
export const deleteProviderRequests = (data) => ({
  type: DELETE_PROVIDER.REQUEST,
  data
});

export const deleteProviderSuccess = (data) => ({
  type: DELETE_PROVIDER.SUCCESS,
  data
});

export const deleteProviderError = (error) => ({
  type: DELETE_PROVIDER.FAILURE,
  error
});

// Services
export const getServices = () => ({
  type: GET_SERVICES.REQUEST,
});

export const getServicesSuccess = (data) => ({
  type: GET_SERVICES.SUCCESS,
  data
});

export const getServicesError = (error) => ({
  type: GET_SERVICES.FAILURE,
  error
});

// Actifs Services
export const getServicesActifs = () => ({
  type: GET_SERVICE_ACTIFS.REQUEST,
});

export const getServicesActifsSuccess = (data) => ({
  type: GET_SERVICE_ACTIFS.SUCCESS,
  data
});

export const getServicesActifsError = (error) => ({
  type: GET_SERVICE_ACTIFS.FAILURE,
  error
});

// Update actif service
export const editServiceActif = (data) => ({
  type: UPDATE_SERVICE_ACTIF.REQUEST,
  data
});

export const editServiceActifSuccess = (data) => ({
  type: UPDATE_SERVICE_ACTIF.SUCCESS,
  data
});

export const editServiceActifError = (error) => ({
  type: UPDATE_SERVICE_ACTIF.FAILURE,
  error
});

// Ticket Requests
export const getTicketRequests = () => ({
  type: GET_TICKET_REQUESTS.REQUEST,
});

export const getTicketRequestsSuccess = (data) => ({
  type: GET_TICKET_REQUESTS.SUCCESS,
  data
});

export const getTicketRequestsError = (error) => ({
  type: GET_TICKET_REQUESTS.FAILURE,
  error
});

export const saveTicketRequest = (data) => ({
  type: SAVE_TICKET_REQUESTS.REQUEST,
  data
});

export const saveTicketRequestSuccess = (data) => ({
  type: SAVE_TICKET_REQUESTS.SUCCESS,
  data
});

export const saveTicketRequestError = (error) => ({
  type: SAVE_TICKET_REQUESTS.FAILURE,
  error
});

// TICKET MESSAGE
export const saveTicketMessage = (data) => ({
  type: SAVE_TICKET_MESSAGE.REQUEST,
  data
});

export const saveTicketMessageSuccess = (data) => ({
  type: SAVE_TICKET_MESSAGE.SUCCESS,
  data
});

export const saveTicketMessageError = (error) => ({
  type: SAVE_TICKET_MESSAGE.FAILURE,
  error
});

export const deleteTicketMessage = (data) => ({
  type: DELETE_TICKET_MESSAGE.REQUEST,
  data
});

export const deleteTicketMessageSuccess = (data) => ({
  type: DELETE_TICKET_MESSAGE.SUCCESS,
  data
});

export const deleteTicketMessageError = (error) => ({
  type: DELETE_TICKET_MESSAGE.FAILURE,
  error
});

// CALENDAR
export const getCalendarObjects = () => ({
  type: GET_CALENDAR_OBJECTS.REQUEST,
});

export const getCalendarObjectsSuccess = (data) => ({
  type: GET_CALENDAR_OBJECTS.SUCCESS,
  data
});

export const getCalendarObjectsError = (error) => ({
  type: GET_CALENDAR_OBJECTS.FAILURE,
  error
});

export const saveCalendarObject = (data) => ({
  type: SAVE_CALENDAR_OBJECT.REQUEST,
  data
});

export const saveCalendarObjectSuccess = (data) => ({
  type: SAVE_CALENDAR_OBJECT.SUCCESS,
  data
});

export const saveCalendarObjectError = (error) => ({
  type: SAVE_CALENDAR_OBJECT.FAILURE,
  error
});

export const deleteCalendarObject = (data) => ({
  type: DELETE_CALENDAR_OBJECT.REQUEST,
  data
});

export const deleteCalendarObjectSuccess = (data) => ({
  type: DELETE_CALENDAR_OBJECT.SUCCESS,
  data
});

export const deleteCalendarObjectError = (error) => ({
  type: DELETE_CALENDAR_OBJECT.FAILURE,
  error
});

export const editConcierge = (data) => ({
  type: UPDATE_CONCIERGE.REQUEST,
  data
});

export const editConciergeSuccess = (data) => ({
  type: UPDATE_CONCIERGE.SUCCESS,
  data
});

export const editConciergeError = (error) => ({
  type: UPDATE_CONCIERGE.FAILURE,
  error
});

export const actions = {
  GET_CORPORATIONS,
  GET_SITES,
  GET_CONCIERGES,
  GET_PROVIDERS,
  GET_SERVICES,
  GET_SERVICE_ACTIFS,
  GET_TICKET_REQUESTS,
  SAVE_TICKET_REQUESTS,
  SAVE_TICKET_MESSAGE,
  DELETE_TICKET_MESSAGE,
  GET_CALENDAR_OBJECTS,
  SAVE_CALENDAR_OBJECT,
  DELETE_CALENDAR_OBJECT,
  SAVE_PROVIDER,
  UPDATE_CONCIERGE,
  DELETE_PROVIDER,
  UPDATE_SERVICE_ACTIF,
  GET_INTERNAL_SERVICE_FORM,
  GET_INTERNAL_SERVICE_FORM_SCHEMA
}