import React from 'react'
import PropTypes from 'prop-types';

import {
  ButtonListDiv,
  CustomButton,
  ButtonSpan
} from './style'

const GenericDrawerValidationButtons = (
  {
    leftButonText,
    RightButtonText,
    onclickLefttButton,
    submitable = true,
    onclickRightButton,
    className
  }) => {

  return (
    <ButtonListDiv className={`${className ? className : ''} genericValidationButtons`}>
      {
        submitable ?
          <CustomButton className={'customButton'} type="submit" isPrimary>
            <ButtonSpan>{RightButtonText}</ButtonSpan>
          </CustomButton>
          :
          <CustomButton className={'customButton'} isPrimary onClick={onclickRightButton}>
            <ButtonSpan>{RightButtonText}</ButtonSpan>
          </CustomButton>
      }
      <CustomButton className={'customButton'} type="button" onClick={onclickLefttButton} >
        <ButtonSpan>{leftButonText}</ButtonSpan>
      </CustomButton>
    </ButtonListDiv>
  )
}

GenericDrawerValidationButtons.propTypes = {
  leftButonText: PropTypes.string,
  RightButtonText: PropTypes.string,
  onclickLefttButton: PropTypes.func,
  submitable: PropTypes.bool,
  onclickRightButton: PropTypes.func,
  className: PropTypes.string,
}

export default GenericDrawerValidationButtons

// Note:
// This component is using flex-direction: row-reverse so that when user hit the enter button on the Keyboard, it will trigger the first button function
// Please make the nessecary changes when applying style to this component