import React, { useState, useEffect } from "react";
import {
  Route,
  Redirect,
} from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";

import Loader from '../components/atoms/lottie/Loader';

import {
  isUserTokenValid,
} from '../store/authentication/selectors';

import {
  getUser
} from '../store/collaborator/actions';

import {
  getUser as getUserData,
} from '../store/collaborator/selectors';

import {
  companyGetModules,
  getCompanyContext,
} from '../store/company/actions';

import {
  getCompanyContextSuccess
} from '../store/company/selectors';

export const HomePrivateRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();

  const {
    isTokenValid,
    userData,
    companyContextSuccess
  } = useSelector(
    state => ({
      isTokenValid: isUserTokenValid(state),
      userData: getUserData(state),
      companyContextSuccess: getCompanyContextSuccess(state)
    }),
    shallowEqual
  );

  useEffect(() => {
    if (isTokenValid) {
      dispatch(getUser());
      dispatch(getCompanyContext());
    }
  }, [isTokenValid]);

  useEffect(() => {
    if (userData) {
      dispatch(companyGetModules());
    }
  }, [userData]);

  const handleTokenInvalid = (props) => {
    if (!companyContextSuccess || userData === null) {
      return (
        <Loader />
      )
    }
    else {
      return (
        <Component {...props} />
      )
    }
  }

  return (
    <Route
      {...rest}
      render={props =>
        isTokenValid ?
          handleTokenInvalid(props)
          :
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
      }
    />
  )
};
