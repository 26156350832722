import React from 'react';

// Components
import FeedbackUntreatedTypeStats from '../components/feedbackUntreatedTypeStats';
import FeedbackUntreatedCompetenceStats from '../components/feedbackUntreatedCompetenceStats';
import TableFeedbackByCompetence from '../components/tableFeedbackByCompetence';

// Styles
import {
  Container,
  HeadWrapper,
  Table
} from "./style";

const CompetenceFeedBack = () => {
    return (
      <Container>
        <HeadWrapper>
          <FeedbackUntreatedCompetenceStats />
          <FeedbackUntreatedTypeStats />
        </HeadWrapper>

        <Table>
          <TableFeedbackByCompetence />
        </Table>
      </Container>
    );
}

export default CompetenceFeedBack;
