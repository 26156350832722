import { select, call, put } from 'redux-saga/effects';

import endpoint from '../../../helpers/api';
import apiCall from '../../apiCall';
import { toaster } from '../../../helpers/toaster';

import {
  saveBookingService,
  cancelBookingService
} from '../../../services/roomBookingService';

import {
  getBookingsSuccess,
  getBookingsError,
  getBookingsByRangeDateSuccess,
  getBookingsByRangeDateError,
  saveBookingSuccess,
  saveBookingError,
  cancelBookingSuccess,
  cancelBookingError,
} from '../actions';

import { getUser } from '../../collaborator/selectors';

import * as authenticationSelectors from '../../authentication/selectors';

export function* getBookingsHandler() {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const bookings = yield call(apiCall, endpoint.getAllBookings, userToken);
    yield put(getBookingsSuccess(bookings));
  } catch (err) {
    yield put(getBookingsError(err));
  }
}

export function* getBookingsByDateHandler(action) {
  const { date } = action.data;
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const bookings = yield call(apiCall, endpoint.getBookingByDate(date), userToken);
    const bookingData = [];
    // ONLY SHOW BOOKING THAT IS NOT CANCELLED
    for (const booking of bookings) {
      if (booking.cancel_status === null) {
        bookingData.push(booking);
      }
    }
    yield put(getBookingsSuccess(bookingData));
  } catch (err) {
    yield put(getBookingsError(err));
  }
}

export function* getBookingsByRangeDateHandler(action) {
  const { startDate, endDate } = action.data;
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const bookings = yield call(apiCall, endpoint.getBookingByRangeDate(startDate, endDate), userToken);
    yield put(getBookingsByRangeDateSuccess(bookings));
  } catch (err) {
    yield put(getBookingsByRangeDateError(err));
  }
}

export function* saveBookingHandler(action) {
  const { formData } = action.data;
  try {
    const user = yield select(getUser);
    const userToken = yield select(authenticationSelectors.getUserToken);
    const endPointUrl = endpoint.saveRoomBooking;
    const response = yield call(saveBookingService, endPointUrl, user, userToken, formData);
    yield put(saveBookingSuccess(response.data));
    toaster('Merci, votre réservation a bien été prise en compte', 'success');
  } catch (err) {
    yield put(saveBookingError(err));
    toaster(`La réservation est impossible. Veuillez réessayer sur un autre créneau`, 'error');
  }
}

export function* cancelBookingHandler(action) {
  const { id, formData } = action.data;
  // console.log('cancel booking', id, formData);
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const endPointUrl = endpoint.cancelRoomBooking(id);
    const response = yield call(cancelBookingService, endPointUrl, userToken, formData);
    // console.log('cancel booking response', response.data);
    yield put(cancelBookingSuccess(response.data));
    toaster('Votre annulation a bien été prise en compte.', 'success');
  } catch (err) {
    yield put(cancelBookingError(err));
    toaster(`Votre annulation n'a pas pu aboutir.`, 'error');
  }
}