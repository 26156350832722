import React from "react";
import { shallowEqual, useSelector } from "react-redux";
// Components
import LoginForm from "./components/LoginForm";
import RegisterForm from "./components/RegisterForm";
import LogoWithCircle from "../../components/atoms/icons/logoWithCircle";
import { VideoDoodleLink } from "./components/VideoDoodleLink";

import managerImage from "../../assets/images/engieManager.jpg";

import { COMPANY_SETTING_KEYS } from "../../constants/companySettingConstants";

// Style
import {
  LoginContainer,
  Wrapper,
  LoginBox,
  TabListWrapper,
  TabItem,
  Subtitle,
  Title,
  LoginHead,
  TabView,
  TabsWrapper,
  LogoWithCircleWrapper,
  StyledBox,
  StyledText,
  StyledImage,
  StyledButton,
  StyledLink,
} from './style';

import {
  getCompanyData,
  getCompanySettings
} from '../../store/company/selectors';

import {
  getSubDomain,
} from '../../store/config/selectors';

const Login = (props) => {
  const { isConcierge } = props;
  const query = new URLSearchParams(window.location.search);
  const action = query.get('action');
  const ADMIN_BASE_URL = process.env.REACT_APP_ADMIN_BASE_URL;
  const LinkRgpdFiles = "https://" + ADMIN_BASE_URL + "/build/files/conciergerie-engie/Politique-traitement-données.pdf";
  const LinkCguFiles = "https://" + ADMIN_BASE_URL + "/build/files/cyconia/ZB__CGV_230801.pdf";
  const LinkEngieTarifsFiles = "https://" + ADMIN_BASE_URL + "/build/files/conciergerie-engie/ENGIE-Tarif_flyer.pdf";

  const {
    company,
    companySettings,
    subDomain
  } = useSelector(
    state => ({
      company: getCompanyData(state),
      companySettings: getCompanySettings(state),
      subDomain: getSubDomain(state),
    }),
    shallowEqual);

  return (
    <LoginContainer>
      <VideoDoodleLink company={company} />
      <Wrapper>
        <LoginHead>
          {company?.logo
            ? <img src={company?.logo} alt={company.name} />
            : <LogoWithCircleWrapper><LogoWithCircle /></LogoWithCircleWrapper>
          }
          <Title>{company?.title || 'cyconia'}</Title>
          <Subtitle>{company?.subtitle || 'Votre assistant intelligent au bureau'}</Subtitle>
        </LoginHead>
        {subDomain === "conciergerie-engie.cyconia.io" ?
          <>
            <StyledText>
              <div>
                <StyledButton
                  title={"💬 Je demande mon service de conciergerie"}
                  onClick={() => {window.open("https://form.jotform.com/230851769105357", "_blank")}}
                />
              </div>
              <div>
                <StyledButton
                  title={"Grille tarifaire des services"}
                  onClick={() => {window.open(LinkEngieTarifsFiles, "_blank")}}
                />
              </div>
              <div>
                Une question ? Besoin d’aide ? Contactez votre hospitality manager Wilda, du lundi au vendredi de 9h à 17h, joignable au
                <strong>
                  06 25 28 92 24
                </strong>
                par mail sur
                <strong>
                  <a href="mailto:conciergerie-engie@myzenetbien.fr" target={"_blank"} rel={"noreferrer"}> conciergerie-engie@myzenetbien.fr, </a>
                </strong>
                et vous accueille à la loge située au rez-de-chaussée à gauche.
              </div>
            </StyledText>
            <StyledBox>
              <StyledImage
                src={managerImage}
              />
            </StyledBox>
            <StyledLink>
              <a href={LinkCguFiles} target={"_blank"} rel={"noreferrer"}>Conditions générales d'utilisation</a>
              |
              <a href={LinkRgpdFiles} target={"_blank"} rel={"noreferrer"}>RGPD</a>
            </StyledLink>
          </>
          :
          <LoginBox>
            <TabsWrapper defaultIndex={action === 'register' ? 1 : 0}>
              {!isConcierge && !companySettings?.[COMPANY_SETTING_KEYS.HIDE_REGISTERATION_TAB] &&
                <TabListWrapper>
                  <TabItem>Connexion</TabItem>
                  <TabItem>Inscription</TabItem>
                </TabListWrapper>
              }
              <TabView>
                <LoginForm
                  isConcierge={isConcierge}
                  companyData={company}
                />
              </TabView>
              <TabView>
                <RegisterForm />
              </TabView>
            </TabsWrapper>
          </LoginBox>
        }
      </Wrapper>
    </LoginContainer>
  );
};

export default Login;
