import axios from 'axios';

export const editSpecificCaseService = (endpoint, userToken, formData) => {
  const newForm = new FormData();
  newForm.append('dfAction', formData.dfAction || '');
  newForm.append('reply', formData.reply || '');
  newForm.append('companySites', formData.companySites || '');

  // console.log('id', id);
  // console.log('newForm', ...newForm);
  // console.log('formData', formData);

  const headers = {};
  if (userToken) {
    headers.Authorization = `Bearer ${userToken}`;
  }

  return axios.request({
    method: 'post',
    url: endpoint,
    data: newForm,
    headers: headers
  })
}

export const deleteSpecificCaseService = async (endpoint, userToken) => {
  const headers = {};
  if (userToken) {
    headers.Authorization = `Bearer ${userToken}`;
  }

  return axios.request({
    method: 'delete',
    url: endpoint,
    headers: headers
  });
}