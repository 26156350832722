import { getURLApi } from "./helpers";
require('dotenv').config();

const URL_API = getURLApi();
const BASE_URL = URL_API.slice(0, URL_API.length - 4);
const VERSION_API = process.env.REACT_APP_API_VERSION;
const REACT_APP_URL = process.env.REACT_APP_URL;

const endpoint = {
    base_url: BASE_URL,
    url_api: URL_API,
    react_app_url: REACT_APP_URL,
    // Login + Auth
    login: URL_API + "login_check",
    loginExpressing: `${URL_API}${VERSION_API}expressing/user/login`,
    logoutExpressing: `${URL_API}${VERSION_API}expressing/user/unlink`,
    get_user: URL_API + VERSION_API + "collaborator",

    getgroundLoginApi: `${URL_API}${VERSION_API}getground/login`,

    samlRegisterationApi: `${URL_API}saml/register`,
    azureadRegisterationApi: `${URL_API}${VERSION_API}azure/saml/register`,

    // Front sidebar - concierge
    // For concierge V1
    getServicesOrdersList: (userId, pageNumber) => `${URL_API}${VERSION_API}collaborator/${userId}/requests?page=${pageNumber}`,
    // For concierge V2
    getServiceTicketById: (ticketId) => `${URL_API}${VERSION_API}company/ticket/${ticketId}`,
    getServiceTicketList: (userId, pageNumber) => `${URL_API}${VERSION_API}collaborator/${userId}/tickets?page=${pageNumber}`,
    getServiceMesaggesList: (ticketId) => `${URL_API}${VERSION_API}company/ticket/${ticketId}/messages`,

    userInfoUpdate: (userId) => `${URL_API}${VERSION_API}collaborator/${userId}`,
    updateIntro: URL_API + VERSION_API + "collaborator/introjs",
    getNewPassword: URL_API + VERSION_API + "collaborator/password-reset/request",
    submitNewPassword: URL_API + VERSION_API + "collaborator/password-reset/reset",

    // Bienetre
    // getHelpBlockInfo: URL_API + VERSION_API + "partners/options",
    // updateHelpBlockInfo: URL_API + VERSION_API + "partners/edit/options",

    // ADMIN API :
    // Header Training Editor API
    getElementInformationByCompany: URL_API + VERSION_API + "manager/training/ei/{eiId}/companySites",
    // Dashboard API
    getLastUntreatedFeedback: URL_API + VERSION_API + "manager/dashboard/last/feedbacks",
    getUntreatedFeedbackStats: URL_API + VERSION_API + "manager/dashboard/feedback/unprocessed/stat",
    getActiveInfoElement: URL_API + VERSION_API + "manager/dashboard/ei/active/stat",
    getInactiveInfoElement: URL_API + VERSION_API + "manager/dashboard/ei/inactive",
    // Feedback API
    getUntreatedFeedback: URL_API + VERSION_API + "manager/feedbacks/unprocessed",
    getUntreatedFbBySkill: URL_API + VERSION_API + "manager/feedbacks/unprocessed/by/skill",
    getUntreatedFbBySkillStats: URL_API + VERSION_API + "manager/feedbacks/unprocessed/by/skill/stat",
    getFeebackByInfoEl: (eiId) => {
        return `${URL_API}${VERSION_API}manager/${eiId}/feedbacks`
    },
    getTreatedFeedback: URL_API + VERSION_API + "manager/feedbacks/processed",
    getTreatedFeedbackStats: URL_API + VERSION_API + "manager/feedbacks/processed/stat",
    getTreatedFbBySkillStats: URL_API + VERSION_API + "manager/feedbacks/processed/by/skill/stat",
    getPositiveBotFeedback: URL_API + VERSION_API + "manager/feedbacks/bot/positive",
    getNegativeBotFeedback: URL_API + VERSION_API + "manager/feedbacks/bot/negative",

    // User Manager API
    getCollaboratorsList: `${URL_API}${VERSION_API}manager/collaborators`,
    getCollaboratorsRoles: URL_API + VERSION_API + "manager/collaborator/roles",
    getCollabByIdApi: (id) => {
      return `${URL_API}${VERSION_API}get/collaborator/${id}`
    },
    editCollaborator: (id) => {
      return `${URL_API}${VERSION_API}manager/collaborators/${id}/edit`
    },
    switchCollaboratorSite: `${URL_API}${VERSION_API}collaborator/switch/site`,

    postCollaboratorsBlock: (id) => {
        return `${URL_API}${VERSION_API}manager/collaborators/${id}/block`
    },
    postCollaboratorsUnblock: (id) => {
        return `${URL_API}${VERSION_API}manager/collaborators/${id}/unblock`
    },
    postCollaboratorsResetPw: (id) => {
        return `${URL_API}${VERSION_API}manager/collaborators/${id}/shoot/reset/password/mail`
    },
    
    // Rules Manager API
    getRulesPermissions: URL_API + VERSION_API + "manager/role/permissions",
    getRulesList: URL_API + VERSION_API + "manager/role/list",
    postRulesAdd: URL_API + VERSION_API + "manager/role/add",
    postRulesAssign: URL_API + VERSION_API + "manager/role/assign",

    // Training Feeds API
    getfeedsStatInfo: (eiId) => {
        return `${URL_API}${VERSION_API}manager/training/ei/${eiId}/feedback/stats`
    },
    getfeedsListBySite: (siteId, eiId) => {
        // return `${URL_API}${VERSION_API}manager/${eiId}/feedbacks?companySiteId=${siteId}`
        return `${URL_API}${VERSION_API}manager/${eiId}/feedbacks`
    },

    getEiResponseList: (eiId) => {
        return `${URL_API}${VERSION_API}manager/training/ei/${eiId}`
    },
    // Traingin API
    isActiveEiState: URL_API + VERSION_API + "manager/training/ei/activation",
    getElementInformationList: URL_API + VERSION_API + "manager/training/ei/list",
    getSkills: URL_API + VERSION_API + "manager/training/skills",
    updateEiResponse: URL_API + VERSION_API + "manager/training/ei/response",
    updateProcessedFeedback: URL_API + VERSION_API + "manager/training/feedback/processed",

    getInformationList: (id) => {
        return `${URL_API}${VERSION_API}manager/training/${id}/subjects`
    },
    getSkillsDomainsList: URL_API + VERSION_API + "manager/training/expertiseareas",

    // Admin API Old & More
    getAttentesList: URL_API + VERSION_API + "manager/training/list",
    getAllCollabs: URL_API + VERSION_API + "manager/collaborators",
    // getPermsList: URL_API + VERSION_API + "manager/role/permissions",
    // getRolesList: URL_API + VERSION_API + "manager/role/list",
    // assignPermissions: URL_API + VERSION_API + "manager/role/assign",
    addAttenteReply: URL_API + VERSION_API + "manager/training/smalltalk",
    getResponseHistory: URL_API + VERSION_API + "manager/training/history",
    // updateProcceededFeedback: URL_API + VERSION_API + "manager/training/feedback/processed",
    // getSkillsList: URL_API + VERSION_API + "manager/training/skills",
    getAttentesListByRequest: URL_API + VERSION_API + "manager/training/dashboard",
    // getListRemarques: URL_API + VERSION_API + "manager/training/feedbacks",

    // getRolesFrList: URL_API + VERSION_API + "manager/collaborator/roles",
    getStatsInfo: URL_API + VERSION_API + "manager/dashboard/stats",
    companyState: URL_API + VERSION_API + "company/status",

    getAttenteById: (action) => {
        return `${URL_API}${VERSION_API}manager/training/smalltalk?action=${action}`
    },
    getResponseHistory: (action) => {
        return `${URL_API}${VERSION_API}manager/training/history?action=${action}`
    },

    getServicesCategoryById: (id) => {
        return `${URL_API}${VERSION_API}category/${id}/services`
    },

    getExpertiseAreasTheme: (id) => {
        return `${URL_API}${VERSION_API}skill/${id}/subjects`
    },

    // getCollabById : (id) => {
    //     return `${URL_API}${VERSION_API}get/collaborator/${id}`
    // },
    // blockCollab : (id) => {
    //     return `${URL_API}${VERSION_API}manager/collaborators/${id}/block`
    // },
    // unblockCollab: (id) => {
    //     return `${URL_API}${VERSION_API}manager/collaborators/${id}/unblock`
    // },

    // Cutom login
    companyInit: URL_API + VERSION_API + "company/init",
    saveCompanySetting: `${URL_API}${VERSION_API}company/set/settings`,

    // Register
    register: URL_API + "register",

    // Register on behalf
    newCollaborator: URL_API + VERSION_API + "collaborator/new",

    // Onboarding form
    onboardingStep0: URL_API + VERSION_API + "onboarding/step/0",
    onboardingStep1: URL_API + VERSION_API + "onboarding/step/1",
    onboardingStep2: URL_API + VERSION_API + "onboarding/step/2",
    onboardingStep3: URL_API + VERSION_API + "onboarding/step/3",
    onboardingStep4: URL_API + VERSION_API + "onboarding/step/4",

    // News
    getNewsTags: URL_API + VERSION_API + "tags",
    getNews: URL_API + VERSION_API + "news",
    getDeals: URL_API + VERSION_API + "deals?page=",
    likeNews: URL_API + VERSION_API + "news/like",
    unlikeNews: URL_API + VERSION_API + "news/unlike",

    // Dashboard
    getFeedNewsDeals: URL_API + VERSION_API + "dashboard/feed",
    sendFeedback: URL_API + VERSION_API + "collaborator/feedback/add",
    sendPositiveBotFeedback: URL_API + VERSION_API + "collaborator/feedback/bot/positive",
    sendNegativeBotFeedback: URL_API + VERSION_API + "collaborator/feedback/bot/negative",

    // Competences
    getExpertiseAreas: URL_API + VERSION_API + "expertiseareas",

    // Partners available
    getPartners: URL_API + VERSION_API + "partners",

    // Bot + services requests
    botmanFrame: BASE_URL + "home/bot",
    addRequestService: URL_API + VERSION_API + "partner/request/add",

    // ChatBot
    botServer: URL_API + 'v2/home/bot_server',
    botServerV3: URL_API + 'v3/bot_server',
    getUserIntroUpdateUrl: (userId) => {
        return `${URL_API}${VERSION_API}collaborator/${userId}/introjs`;
    },
    negativeFeedbackFormUrl: URL_API + VERSION_API + 'collaborator/feedback/bot/form',

    //RequestPartners
    // getPartnerTerms : URL_API + VERSION_API + "partner/terms",
    getPartnerTerms: (partnerId, serviceId) => {
        return `${URL_API}${VERSION_API}partner/terms/${partnerId}/${serviceId}`;
    },
    postRequestMessage: URL_API + VERSION_API + "partner/request",

    getPartnerRequest: (requestId) => {
        return `${URL_API}${VERSION_API}collaborator/requests/${requestId}`;
    },
    // /api/v1/collaborator/{id}/requests/{requestId}

    // Traffic
    getTraffic: type => `${URL_API}${VERSION_API}dashboard/traffic?mode=${type}`,
    getWeather: URL_API + VERSION_API + "dashboard/weather",

    // Expressing
    getOngoingOrders: URL_API + VERSION_API + "expressing/user/",
    getBalance: URL_API + VERSION_API + "expressing/user/",
    getExpressingAmount: userId => `${URL_API}${VERSION_API}expressing/user/${userId}/amount`,
    getExpressingPastOrders: userId => `${URL_API}${VERSION_API}expressing/user/${userId}/past/orders`,
    getExpressingOnGoingOrders: userId => `${URL_API}${VERSION_API}expressing/user/${userId}/ongoing/orders`,

    // Calendar
    // getUserCalendar: URL_API + VERSION_API + "collaborator/calendars",
    // getUserEventCalendar: URL_API + VERSION_API + "collaborator/events",
    // addUserEventCalendar: URL_API + VERSION_API + "collaborator/event/add",
    // patchUserEventCalendar: URL_API + VERSION_API + "collaborator/event/edit",
    // deleteUserEventCalendar: URL_API + VERSION_API + "collaborator/event/delete",
    // loginMicrosoftService: URL_API + VERSION_API + "collaborator/ms/login",
    // calendar: URL_API + VERSION_API + "collaborator/calendar/",
    // deleteCalendar: URL_API + VERSION_API + "collaborator/calendar/account/delete",

    getServiceCategoryList: URL_API + VERSION_API + "services",

    // Case Specific
    getSpecificCaseList: URL_API + VERSION_API + "manager/specific_smalltalk",
    saveSpecificCase: URL_API + VERSION_API + "manager/specific_smalltalk/add",
    editSpecificCase: (id) => {
        return `${URL_API}${VERSION_API}manager/specific_smalltalk/edit/${id}`;
    },
    deleteSpecificCase: (id) => {
        return `${URL_API}${VERSION_API}manager/specific_smalltalk/${id}`;
    },

    //Content gestion API
    getNewsContentList: (limit, pageNumber) => `${URL_API}${VERSION_API}manager/lpc?limit=${limit}&page=${pageNumber}`,
    getNewsContentById: (id) => `${URL_API}${VERSION_API}manager/lpc/${id}`,
    saveNewsContent: URL_API + VERSION_API + "manager/lpc/add",
    editNewsContent: (id) => `${URL_API}${VERSION_API}manager/lpc/edit/${id}`,
    deleteNewsContent: (id) => `${URL_API}${VERSION_API}manager/lpc/delete/${id}`,

    getGoodDealsList: (limit, pageNumber) => `${URL_API}${VERSION_API}manager/gd?limit=${limit}&page=${pageNumber}`,
    getGoodDealById: (id) => `${URL_API}${VERSION_API}manager/gd/${id}`,
    saveGoodDeal: URL_API + VERSION_API + "manager/gd/add",
    editGoodDeal: (id) => `${URL_API}${VERSION_API}manager/gd/edit/${id}`,
    deleteGoodDeal: (id) => `${URL_API}${VERSION_API}manager/gd/delete/${id}`,

    // Conciergerie
    getServicesList: URL_API + VERSION_API + "cyconia/services",
    getSitesList: URL_API + VERSION_API + "company/sites",

    getActifeServices: URL_API + VERSION_API + "company/site/service/active",
    saveActiveService: URL_API + VERSION_API + "company/site/service/active/add",
    editActiveService: (id) => {
        return `${URL_API}${VERSION_API}company/site/service/active/${id}`;
    },

    getServiceProviders: URL_API + VERSION_API + "company/service/provider",
    postServiceProvider: URL_API + VERSION_API + "company/service/provider/add",
    deleteServiceProvider: URL_API + VERSION_API + "company/service/provider/",
    editServiceProvider: (providerId) => {
        return `${URL_API}${VERSION_API}company/service/provider/${providerId}`;
    },

    getConciergeInfos: URL_API + VERSION_API + "company/site/concierge",
    saveConciergeInfo: URL_API + VERSION_API + "company/site/concierge/add",
    editConciergeInfo: (conciergeId) => {
        return `${URL_API}${VERSION_API}company/site/concierge/${conciergeId}/edit`;
    },

    // Ticket Request
    getTicketRequests: URL_API + VERSION_API + "company/tickets",
    editTicketRequest: (ticketId) => {
        return `${URL_API}${VERSION_API}company/ticket/${ticketId}`;
    },

    // Ticket Message
    saveTicketMessage: URL_API + VERSION_API + "company/ticket/message/add",
    deleteTicketMessage: (ticketMessageId) => {
        return `${URL_API}${VERSION_API}company/ticket/message/${ticketMessageId}`;
    },

    // Company Sites
    getCompanySites: URL_API + VERSION_API + "company/sites",
    getCompanyModules: URL_API + VERSION_API + "company/modules",

    // Corporation
    getCorporation: URL_API + VERSION_API + "corporation/available",
    getAllCorporations: URL_API + VERSION_API + "corporation/all",

    // Internal service form
    getInternalServiceForm: URL_API + VERSION_API + "company/site/service/forms",
    getInternalServiceFormSchema: URL_API + VERSION_API + "partner/request/formbyid",

    // Calendar
    getCalendarObjects: URL_API + VERSION_API + "company/calendar_object",
    saveCalendarObject: URL_API + VERSION_API + "company/calendar_object/add",
    editCalendarObject: (calendarObjectId) => {
        return `${URL_API}${VERSION_API}company/calendar_object/edit/${calendarObjectId}`;
    },
    deleteCalendarObject: (calendarObjectId) => {
        return `${URL_API}${VERSION_API}company/calendar_object/${calendarObjectId}`;
    },

    // Room
    getAllRooms: URL_API + VERSION_API + "company/meetingroom",
    getUserRooms: URL_API + VERSION_API + "company/user/meetingroom",

    // Booking
    getAllBookings: URL_API + VERSION_API + "company/roombooking/all",
    getBookingByDate: (date) => {
      return `${URL_API}${VERSION_API}company/roombooking?date=${date}`;
    },
    getBookingByRangeDate: (startDate, endDate) => {
      return `${URL_API}${VERSION_API}company/roombooking/rangedate?startDate=${startDate}&endDate=${endDate}`;
    },
    saveRoomBooking: URL_API + VERSION_API + "company/roombooking/add",
    editRoomBooking: (bookingId) => {
      return `${URL_API}${VERSION_API}company/roombooking/edit/${bookingId}`;
    },
    cancelRoomBooking: (bookingId) => {
      return `${URL_API}${VERSION_API}company/roombooking/cancel/${bookingId}`;
    },

    // Context setting

    getContexts: URL_API + VERSION_API + "company/context",
    getCompanySiteContext: `${URL_API}${VERSION_API}companysite/context`,
    saveContexts: URL_API + VERSION_API + "company/set/context",
}
export {
    BASE_URL,
    URL_API
}
export default endpoint;
