import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  withRouter,
  useRouteMatch
} from "react-router-dom";

// Layout
import FeedsBaseLayout from "../../layouts/feedsBaseLayout";

//Routes
import Routes from "./routes";

// Styles
import {
  Container,
  Wrapper
} from "./style";

// Components
import Header from './components/header';

const Feedback = () => {
  const { path } = useRouteMatch();

  return (
    <FeedsBaseLayout
      id="feedsLayout"
      className="feedsLayout"
    >
      <Container>
        <Header />

        <Wrapper>
          <Routes />
        </Wrapper>

      </Container>
    </FeedsBaseLayout>
  );
};

export default Feedback;
