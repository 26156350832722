import React from "react";

class StepConfirmation extends React.Component { 
    constructor(props){
        super(props);
        this.closeModal = this.closeModal.bind(this)
        this.state = {
            message : "",
            status: "",
            apiAction: props.apiAction
        }
    }

    closeModal(){
        this.props.closeModal();
        this.props.firstStep();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        let message;

        if (this.props.requestData.action === "askHelp") {
            if (nextProps.statusRequest >= 200 && nextProps.statusRequest < 300) {
                message = (
                    <p>
                        <em>Ok, je vais prendre contacte avec cette personne et compléter ma réponse</em> <br/>
                        Merci pour votre aide !
                    </p>
                )

            } else if (nextProps.statusRequest >= 400 && nextProps.statusRequest < 600) {
                message = (
                    <p>
                        <em>Désolé, votre proposition d'amélioration de Cyconia n'a pas été prise en compte !</em> <br/>
                        Veuillez réessayer plus tard. <br/>
                        Si le problème persiste, vous pouvez contacter le support <br/>
                        <a href="tel:+33 6 19 15 32 89 ">06.19.15.32.89</a> <br/>
                        <a href="mailto:onvousaimeonvousaide@cyconia.io">onvousaimeonvousaide@cyconia.io</a>
                    </p>
                )
            }
        }
        else if (this.props.requestData.action === "askSmalltalkReply") {
            if (nextProps.statusRequest >= 200 && nextProps.statusRequest < 300) {
                if (this.props.requestData.moderation === 1) {
                    message = (
                        <p>
                            <em>Ok ! Je me rapproche des personnes en charge de ces sujets.</em> <br/>
                        </p>
                    )
                }
                else {
                    message = (
                        <p>
                            <em>Ok ! J'améliore tout de suite ma réponse !</em> <br/>
                        </p>
                    )
                }
            } else if (nextProps.statusRequest >= 400 && nextProps.statusRequest < 600) {
                message = (
                    <p>
                        <em>Désolé, votre proposition d'amélioration de Cyconia n'a pas été prise en compte !</em> <br/>
                        Veuillez réessayer plus tard. <br/>
                        Si le problème persiste, vous pouvez contacter le support <br/>
                        <a href="tel:+33 6 19 15 32 89 ">06.19.15.32.89</a> <br/>
                        <a href="mailto:onvousaimeonvousaide@cyconia.io">onvousaimeonvousaide@cyconia.io</a>
                    </p>
                )
            }
        }
        else if (this.state.apiAction === 'bot_negative_feedback') {
            if (nextProps.statusRequest >= 200 && nextProps.statusRequest < 300) {
                message = (
                    <p>
                        <em>Merci pour votre aide !</em> <br/>
                        Je vais m'atteler à améliorer cette réponse.
                    </p>
                )
            }
            else if (nextProps.statusRequest >= 400 && nextProps.statusRequest < 600) {
                message = (
                    <p>
                        <em>Désolé, votre retour n'a pas été prise en compte !</em> <br/>
                        Veuillez réessayer plus tard. <br/>
                        Si le problème persiste, vous pouvez contacter le support <br/>
                        <a href="tel:+33 6 19 15 32 89 ">06.19.15.32.89</a> <br/>
                        <a href="mailto:onvousaimeonvousaide@cyconia.io">onvousaimeonvousaide@cyconia.io</a>
                    </p>
                )
            }
        }
        else {
            if (nextProps.statusRequest >= 200 && nextProps.statusRequest < 300) {
                message = (
                    <p>
                        <em>Votre demande est en cours de traitement.</em> <br/>
                        Retrouvez et suivez vos commandes dans la section “Mes commandes” sous l'onglet “Ma conciergerie” en haut à droite de votre écran.
                    </p>
                )

            } else if (nextProps.statusRequest >= 400 && nextProps.statusRequest < 600) {
                message = (
                    <p>
                        <em>Désolé, votre demande n'a pas été prise en compte !</em> <br/>
                        Veuillez réessayer plus tard. <br/>
                        Si le problème persiste, vous pouvez contacter le support <br/>
                        <a href="tel:+33 6 19 15 32 89 ">06.19.15.32.89</a> <br/>
                        <a href="mailto:onvousaimeonvousaide@cyconia.io">onvousaimeonvousaide@cyconia.io</a>
                    </p>
                )
            }
        }
        this.setState({
            message,
            status: nextProps.statusRequest,
            requestData: {}
        });

    }

    render(){
        return(
            <div className="chatbot-m">
                <div className="chatbot-m-card">
                    {this.state.message}
                </div>
    
                <div className="modal-form-controls-centered">
                    <button 
                        type="button" 
                        onClick={this.closeModal}
                        className="chatbot-a-button chatbot-a-button-darkblue">
                        Fermer
                    </button>
                </div>

            </div>
        )
    }
}

export default StepConfirmation;