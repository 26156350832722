import React from "react";

const Loader = props => {
    return (
        <div className={"chatbot-lds-ring " + props.className} >
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    )
}

export default Loader;