import styled from "styled-components";
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

export const StyledTableContainer = styled(TableContainer)`
  &&{
    max-height: 90vh;
  }
`;

export const StyledTable = styled(Table)`
  &&{
    min-width: 750px;
  }
`;

export const TableRowCustom = styled(TableRow)`
  &&{
    cursor: ${props => props.hover ? 'pointer' : 'initial'};
    &.MuiTableRow-hover{
      &:hover,
      &:focus{
        background-color: #F7FBFF;
        .hover-deep-blue{
          span{
            color: #ffffff;
            background-color: #2768B3;
          }
        }
        .hover-green{
          span{
            color: #ffffff;
            background-color: #3EB377;
          }
        }
        .hover-yellow{
          span{
            color: #2B2B2B;
            background-color: #FFD700;
          }
        }
        .hover-blue{
          span{
            color: #ffffff;
            background-color: #357ED3;
          }
        }
      } 
      &:focus{
        background-color: #e4ecf5;
      }
    }
  }
`;

export const TableCellCustom = styled(TableCell)`
  &&{
    border-bottom: 1px solid #F1F1F1;
    transition: all .2s ease-out;
    font-size: 0.85rem;
    padding: 8px 16px;
  }
`;

export const TableCellText = styled.span`
  display: inline-flex;
  justify-content: flex-start;
  line-height: 8px;
  font-weight: 500;
  color: #5A5A5A;
  text-align: left;
  padding: 9px 10px;
  max-height: 26px;
  background-color: ${props => props.displayBackgroundColor ? '#EDEDED' : 'none'};
  border-radius: 4px;
  transition: all .2s ease-out;
`;

export const EmptyRowContainer = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  color: #5A5A5A;
  padding: 28px;
  text-align: center;
  border-bottom: 1px solid #F1F1F1;
`;