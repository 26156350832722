import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import {
  MainContainer,
  StyledIconButton
} from './style';

const GenericPaginationArrow = ({
  pageNumber,
  setPageNumber,
  maxPageNumber,
  className, // to pass styled component className to mainContainer
}) => {

  const renderPaginationInfo = () => {
    return `page ${pageNumber} sur ${maxPageNumber}`;
  }

  const handlePagination = (value) => {
    const newValue = pageNumber + value;
    if (newValue === 0 || newValue > maxPageNumber) return;
    setPageNumber(newValue);
  }

  return (
    <MainContainer className={`${className ? className : ''} genericPaginationArrow`}>
      <StyledIconButton
        onClick={() => handlePagination(-1)}
        className={'gpa-iconButton gpa-iconButton-left'}
      >
        <ArrowBackIcon />
      </StyledIconButton>
      {renderPaginationInfo()}
      <StyledIconButton
        onClick={() => handlePagination(1)}
        className={'gpa-iconButton gpa-iconButton-right'}
      >
        <ArrowForwardIcon />
      </StyledIconButton>
    </MainContainer>
  );
};

export default GenericPaginationArrow;