import React from "react";

const BorneSmallIcon = ({
  color = "#5A5A5A",
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon borneSmallIcon"
      width={width}
      height={height}
      viewBox="0 0 16 19"
      fill="none"
    >
      <path
        d="M14.548.6H2.161C1.52.6 1 .99 1 1.471v15.677c0 .481.52.871 1.161.871h.387v.29c0 .161.174.291.387.291h1.549c.214 0 .387-.13.387-.29v-.29h6.968v.29c0 .16.173.29.387.29h1.548c.214 0 .387-.13.387-.29v-.29h.387c.642 0 1.162-.39 1.162-.872V1.471C15.71.99 15.19.6 14.548.6zm-12.774.98h6.194v5.033H1.774V1.58zm2.71 15.872H2.16a.387.387 0 01-.387-.387V7.387h6.194v10.065H4.484zm10.451-.286c0 .158-.173.286-.387.286H8.742v-16h5.806c.214 0 .387.128.387.285v15.429z"
        stroke={color}
        strokeWidth={0.5}
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export default BorneSmallIcon;
