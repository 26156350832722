import React, { useEffect, useState } from "react";
import Parser from "html-react-parser";

//Helper
import { toast } from 'react-toastify';

import { useDispatch } from "react-redux";
import moment from "moment";
import {
  setSelectedExpressinPrestation,
} from '../../../../../store/sidebar/actions';

//Service
import { editPrestation, deletePrestation } from '../../../../../services/handleEditOrDeletePrestation'

//Icon
import {
  IconRequestLocation,
} from "../../../../../helpers/Icon";


//Import Style
import {
  CustomDialog,
  DialogContainer,
  ListButton,
  Button,
  ButtonText,
  PublicationTitleContainer,
  PublicationTitle,
  ReglementationText,
  ReglementationTitle,
  EditText,
  TypeBloc,
  TypeBlocInfo,
  TypeBlocDate,
  InfoBloc,
  InfoBlocAddress,
  ReglementationBloc,
  ReglementationContent,
  ReasonBloc,
  ReasonForm,
  ReasonTitle,
  Wrapper,
} from './style'

const defaultMessage = "<p>Contactez le Club des Cigognes au <a href='tel:+33619153289'>06 19 15 32 89</a> ou à <a href='mailto:onvousaimeonvousaide@cyconia.io'>onvousaimeonvousaide@cyconia.io</a></p>";

const PrestationModal = ({ data }) => {
  const { item, type } = data;
  const [inputEdit, setInputValue] = useState("");
  const [inputDelete, setInputDelete] = useState("");
  const [sucessMessage, setSucessMessage] = useState("");
  const [responseStatus, setResponseStatus] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (responseStatus === "sucess") {
      toast.success(sucessMessage, {
        position: toast.POSITION.TOP_RIGHT,
        position: toast.POSITION.TOP_RIGHT,
        className: "custom-toast custom-toast-success",
        bodyClassName: "custom-toast__body custom-toast__body-success",
        progressClassName: "custom-toast__progress"
      });
      dispatch(setSelectedExpressinPrestation());
    }
    if (responseStatus === "error") {
      toast.error("Une erreur est survenue ! Veuillez réessayer plus tard. Si le problème persiste, contactez le support.", {
        position: toast.POSITION.TOP_RIGHT,
        className: "custom-toast custom-toast-error",
        bodyClassName: "custom-toast__body custom-toast__body-success",
        progressClassName: "custom-toast__progress"
      });
    }
  }, [responseStatus]);

  const handleInputChange = (event) => {
    type === "cancel" ? setInputDelete(event.target.value) : setInputValue(event.target.value);
  }

  const handleEditForm = async (id) => {

    if (inputEdit !== "") {
      const response = await editPrestation(id, inputEdit);
      if (response.success === true) {
        setSucessMessage("Votre demande de modification a bien été envoyée.");
        setResponseStatus("sucess");
      }
      if (response.success === false) setResponseStatus("error");

    }
  }

  const handleDeleteForm = async (id) => {
    if (inputDelete !== "") {
      const response = await deletePrestation(id, inputEdit);
      if (response.success === true) {
        setSucessMessage("Votre demande d’annulation a bien été envoyée.");
        setResponseStatus("sucess");
      }
      if (response.success === false) setResponseStatus("error");

    }
  }

  return (
    <CustomDialog
      open
      onClose={() => dispatch(setSelectedExpressinPrestation())}
    >
      <DialogContainer>
        <Wrapper>
          <PublicationTitleContainer>
            <PublicationTitle>
              {type === "cancel" 
                ? "Annulation de votre commande"
                : "Modification de votre commande"
              }
            </PublicationTitle>
          </PublicationTitleContainer>

          {
            <>
              <TypeBloc>
                <TypeBlocInfo>
                  {item?.service} - Demande n°{item?.id} 
                  <TypeBlocDate>
                    - Prestation commandée le {moment(item?.created_at).format("DD/MM/YYYY")}
                  </TypeBlocDate>
                </TypeBlocInfo>
              </TypeBloc>

              {item?.address && (
                <InfoBloc>
                  <InfoBlocAddress>
                    <IconRequestLocation /> 
                    {item?.address}
                  </InfoBlocAddress>
                </InfoBloc>
              )}

              <ReglementationBloc>
                <ReglementationTitle>
                  Réglementation de notre partenaire
                </ReglementationTitle>
                <ReglementationContent>
                  <ReglementationText>
                    {Parser(defaultMessage)}
                  </ReglementationText>
                  {/* {cancellation !== undefined && cancellation !== null ? Parser(cancellation) : Parser(defaultMessage)} */}
                </ReglementationContent>
              </ReglementationBloc>

              <ReasonBloc>
                <ReasonTitle>
                  {type === "cancel" 
                    ? "Pourquoi voulez-vous supprimer votre commande ?"
                    : "Exprimer votre modification :"
                  }
                </ReasonTitle>
                <ReasonForm onSubmit={(e) => { e.preventDefault();
                  {type === "cancel" 
                    ? handleDeleteForm(item?.id)
                    : handleEditForm(item?.id)
                  }
                }}>
                  <EditText
                    placeholder="Écrire ici"
                    onChange={(event) => handleInputChange(event)}
                  >
                  </EditText>
                  {/* <div className={modificationFormSubmitted && !inputDelete ? "errorMsg errorMsg__active" : "errorMsg"}  >Ce champ est obligatoire</div> */}
                  <ListButton>
                    <Button red onClick={() => dispatch(setSelectedExpressinPrestation())}>
                      <ButtonText>Annuler</ButtonText>
                    </Button>

                    <Button type="submit" blue>
                      <ButtonText>Confirmer</ButtonText>
                    </Button>
                  </ListButton>
                </ReasonForm>

              </ReasonBloc>
            </>
          }
        </Wrapper>
      </DialogContainer>
    </CustomDialog >
  )
}

export default PrestationModal

