import * as a from "./actions";
import _ from 'lodash';

const initGetValue = {
  loading: false,
  success: false,
  error: null,
}

const modifyGet = (type, error = null) => {
  return {
    loading: type === 'request' ? true : false,
    success: type === 'success' ? true : false,
    error: type === 'error' ? error : null,
  }
}

const INITIAL_STATE = {
  userRooms: {
    data: null,
    get: initGetValue,
  },
  bookings: {
    data: null,
    get: initGetValue,
  },
  reportBookings: {
    data: null,
    get: initGetValue,
  },
  saveBooking: {
    get: initGetValue,
  },
  cancelBooking: {
    get: initGetValue,
  }
};

export function roomBooking(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case a.GET_USER_ROOMS.REQUEST:
      return {
        ...state,
        userRooms: {
          data: null,
          get: modifyGet('request')
        },
      };
    case a.GET_USER_ROOMS.SUCCESS:
      return {
        ...state,
        userRooms: {
          data: action.data,
          get: modifyGet('success')
        },
      };
    case a.GET_USER_ROOMS.FAILURE:
      return {
        ...state,
        userRooms: {
          data: null,
          get: modifyGet('error', action.error)
        },
      };
    case a.GET_BOOKINGS.REQUEST:
      return {
        ...state,
        bookings: {
          data: null,
          get: modifyGet('request')
        },
      };
    case a.GET_BOOKINGS.SUCCESS:
      return {
        ...state,
        bookings: {
          data: action.data,
          get: modifyGet('success')
        },
      };
    case a.GET_BOOKINGS.FAILURE:
      return {
        ...state,
        bookings: {
          data: null,
          get: modifyGet('error', action.error)
        },
      };
    case a.GET_BOOKINGS_BY_DATE.REQUEST:
      return {
        ...state,
        bookings: {
          data: null,
          get: modifyGet('request')
        },
      };
    case a.GET_BOOKINGS_BY_DATE.SUCCESS:
      return {
        ...state,
        bookings: {
          data: action.data,
          get: modifyGet('success')
        },
      };
    case a.GET_BOOKINGS_BY_DATE.FAILURE:
      return {
        ...state,
        bookings: {
          data: null,
          get: modifyGet('error', action.error)
        },
      };
    case a.GET_BOOKINGS_BY_RANGE_DATE.REQUEST:
      return {
        ...state,
        reportBookings: {
          data: null,
          get: modifyGet('request')
        },
      };
    case a.GET_BOOKINGS_BY_RANGE_DATE.SUCCESS:
      return {
        ...state,
        reportBookings: {
          data: action.data,
          get: modifyGet('success')
        },
      };
    case a.GET_BOOKINGS_BY_RANGE_DATE.FAILURE:
      return {
        ...state,
        reportBookings: {
          data: null,
          get: modifyGet('error', action.error)
        },
      };
    case a.SAVE_BOOKING.REQUEST:
      return {
        ...state,
        saveBooking: {
          get: modifyGet('request')
        },
      };
    case a.SAVE_BOOKING.SUCCESS:
      return {
        ...state,
        bookings: {
          ...state.bookings,
          data: _.unionBy([action.data], state.bookings.data, "id"),
        },
        reportBookings: {
          ...state.reportBookings,
          data: _.orderBy(_.unionBy([action.data], state.reportBookings.data, "id"), ['id'], ['asc']),
        },
        saveBooking: {
          get: modifyGet('success')
        }
      };
    case a.SAVE_BOOKING.FAILURE:
      return {
        ...state,
        saveBooking: {
          get: modifyGet('error', action.error)
        },
      };
    case a.CANCEL_BOOKING.REQUEST:
      return {
        ...state,
        cancelBooking: {
          get: modifyGet('request')
        },
      };
    case a.CANCEL_BOOKING.SUCCESS:
      return {
        ...state,
        bookings: {
          ...state.bookings,
          // data: state.bookings.data.filter((item) => item.id !== action.data.id),
          data: _.filter(state.bookings.data, (item) => item.id !== action.data.id),
        },
        reportBookings: {
          ...state.reportBookings,
          data: _.orderBy(_.unionBy([action.data], state.reportBookings.data, "id"), ['id'], ['asc']),
        },
        cancelBooking: {
          get: modifyGet('success')
        }
      };
    case a.CANCEL_BOOKING.FAILURE:
      return {
        ...state,
        cancelBooking: {
          get: modifyGet('error', action.error)
        },
      };
    case a.CANCEL_BOOKING.RESET:
      return {
        ...state,
        cancelBooking: {
          get: initGetValue
        },
      };
    default:
      return state;
  }
}

export default roomBooking;
