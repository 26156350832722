import styled from "styled-components";
import Modal from '@material-ui/core/Modal';
import breakpoints from "../../../theme-style/breakpoints";

export const CustomModal = styled(Modal)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContainer = styled.div` 
  max-width: 600px;
  flex: 1;
  background-color: white;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 4px;
  position: relative;
  max-height: calc(100vh - 1.4rem);
  width: 94%;
      
  @media ${breakpoints.mobileXL} {
    max-height: calc(100vh - 2.4rem);
    width: 90%;
  }
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  right: 0;
  padding: 5px;
`;
