import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { format, isWithinInterval } from 'date-fns'
import { Tooltip } from '@material-ui/core';

import GenericTable from '../../../../../../components/molecules/GenericTable';
import ArchiveFilterForm from '../ArchiveFilterForm';
import VisibilityIcon from '@material-ui/icons/Visibility';

import {
  Container,
  IconContainer,
} from './style';

const headerList = [
  { id: "date", label: "Date de création" },
  { id: "site", label: "Site" },
  { id: "client", label: "Collaborateur" },
  { id: "service", label: "Service" },
  { id: "action", label: "Action", disableSort: true },
];

const ArchiveRequestsTable = ({
  archieveTicketRequests,
  currentSites,
  servicesData,
  onRequestClick
}) => {
  const [curentList, setCurentList] = useState([]);
  const initFilterConditions = {
    searchType: 'combination',
    creationDate_start: "",
    creationDate_end: "",
    services: [],
    collaborator: "",
    email: ""
  };
  const [filterConditions, setFilterConditions] = useState(initFilterConditions);

  const checkDateFilter = (ticketDate, startDate, endDate) => {
    return isWithinInterval(new Date(ticketDate).setHours(0, 0, 0, 0), {
      start: new Date(startDate).setHours(0, 0, 0, 0),
      end: new Date(endDate).setHours(0, 0, 0, 0)
    })
  }

  const checkServiceFilter = (form, item) => {
    return !!form.services.find((value) => value.value === item.service_id)
  }

  const checkNameFilter = (form, item) => {
    const search = _.toLower(_.deburr(form.collaborator));
    const name = `${item.collaborator.firstname} ${item.collaborator.lastname}`;

    return _.toLower(_.deburr(name)).includes(search);
  }

  const checkEmailFilter = (form, item) => {
    const search = _.toLower(_.deburr(form.email));

    return _.toLower(_.deburr(item.collaborator.email)).includes(search);
  }

  const checkFilterConditions = (form, item) => {
    const conditionCheckArray = [];
    if (form.creationDate_start && form.creationDate_end)
      conditionCheckArray.push(checkDateFilter(item.created_at, form.creationDate_start, form.creationDate_end))
    // if (form.date_pec_start && form.date_pec_end)
    //   conditionCheckArray.push(checkDateFilter(item.date_pec, form.date_pec_start, form.date_pec_end))
    if (form.services.length !== 0)
      conditionCheckArray.push(checkServiceFilter(form, item))
    if (form.collaborator)
      conditionCheckArray.push(checkNameFilter(form, item))
    if (form.email)
      conditionCheckArray.push(checkEmailFilter(form, item))

    if (conditionCheckArray.length > 0) {
      if (form.searchType === 'combination') return !conditionCheckArray.includes(false);
      else return conditionCheckArray.includes(true);
    }
    return true;
  }

  useEffect(() => {
    if (servicesData) {
      const newList = _.compact(_.map(archieveTicketRequests, (data) => {
        if (checkFilterConditions(filterConditions, data) === false) return;
        const selectedData = currentSites.find((site) => site.value === data.company_site.id) ? data : null;
        //console.log("selectedData", selectedData)
        if (selectedData) {
          const newItem = _.pickBy(selectedData, (value, key) => {
            return (key === "company_site" || key === 'collaborator' || key === "created_at") //|| key === "date_pec")
          })
          const service_Name = servicesData.find((value) => String(value.id) === String(selectedData.service_id))
          return ({
            date: newItem.created_at ? format(new Date(newItem.created_at), "dd/MM/yyyy") : '-',
            //date_pec: newItem.date_pec ? format(new Date(newItem.date_pec), "dd/MM/yyyy") : '-',
            site: newItem.company_site.name,
            client: `${newItem.collaborator.firstname} ${newItem.collaborator.lastname}`,
            service: service_Name?.name || '-',
            action: <Tooltip title="Visualiser la demande archivée"><IconContainer onClick={() => onRequestClick(data.id)} ><VisibilityIcon /></IconContainer></Tooltip>
          })
        }
      }))
      setCurentList(newList);
    }

  }, [archieveTicketRequests, currentSites, filterConditions, servicesData])


  return (
    <Container>
      <ArchiveFilterForm
        servicesData={servicesData}
        currentSites={currentSites}
        initFilterConditions={initFilterConditions}
        setFilterConditions={(form) => setFilterConditions(form)}
      />
      <GenericTable
        headerList={headerList}
        itemList={curentList}
        // hover={true}
        // onTableRowClick={(item) => console.log('this will give you the data of the rowItem to be process as needed', item)}
      />
    </Container>
  )
}

export default ArchiveRequestsTable
