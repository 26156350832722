import styled from "styled-components";
import { Button } from '@material-ui/core/';
import Tooltip from '@material-ui/core/Tooltip';
import { COLORS } from '../../../../../constants/design';
import breakpoints from "../../../../../theme-style/breakpoints";

export const StyledDropZoneDiv = styled.div`
  /* width: 100%;
  height: 100%; */
  position: relative;
  margin-bottom: 15px;
`;

export const StyledInputContainer = styled.div`
  /* border: 1px solid ${COLORS.LIGHT_GREY_2}; */
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  color: ${COLORS.LIGHT_GREY_3};
  height: ${props => props.isModalMode ? '520px' : '100px'};

  @media ${breakpoints.mobileXL} {
    height: ${props => props.isModalMode ? '520px' : '200px'};
  }
  .quill {
    /* border: 10px solid red; */
    height: 100%;
    width: 100%;
  }
  .quill .ql-toolbar {
    background-color: #F4F4F4;
    border: 1px solid #D2D2D2;
    border-radius: 10px 10px 0 0;
    height: 44px;
    min-height: 44px;
    padding: 0px 10px;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .quill .ql-container {
    border-radius: ${props => props.messengertype === 'client' ? '10px' : '0 0 10px 10px'};
    color: ${COLORS.DARK_GREY};
    height: ${props => props.messengertype === 'client' ? '100%' : 'calc(100% - 44px)'};
    background-color: ${props => props.category === 0 ? 'white' : 'rgba(255, 215, 0, 0.5)'};
    width: 100%;
    overflow: hidden;
    em {
      font-style: revert;
    }
    ol, ul {
      padding-left: 0em;
      list-style-position: inside;
    }
  }
`;

export const StyledQuillContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const StyledQuillSubmitContainer = styled.div`
  position: absolute;
  bottom: 5px;
  right: 10px;
  cursor: pointer;
  color: ${props => props.hasContent ? COLORS.PRIMARY_BLUE : COLORS.LIGHT_GREY_3};

  @media ${breakpoints.mobileXL} {
    bottom: 10px;
    right: 20px;
  }
`;

export const StyledCustomToolbar = styled.div`
  position: absolute;
  /* border: 1px solid red; */
  display: flex;
  /* align-content: center; */
  align-items: center;
  justify-content: space-evenly;
  width: 60%;
  height: 44px;
  top: 0;
  right: 0;
  .genericMaterialMenu {
    margin-right: 5px;
    .MuiButton-root {
      border: 1px solid ${COLORS.LIGHT_GREY_2};
      font-size: 0.8rem;
      padding: 5px 15px;
      height: 38px;
      border-radius: 5px;
      text-transform: none;
      box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%);
    }
  }
`;

export const StyledTabContainer = styled.div`
  /* border: 1px solid red; */
  display: flex;
  height: 36px;
  flex-direction: row;
  justify-content: space-between;
  .genericTitleWithIcon {
    >div:not(:last-child) {
      margin-right: 10px;
    };
  }
  && {
    .MuiTabs-root {
      min-height: 36px;
    }
    .MuiTab-root {
      border-radius: 5px;
      min-height: 36px;
      font-size: 0.8rem;
      min-width: auto;
      text-transform: none;
      color: ${COLORS.DARK_GREY}
    }
    .Mui-selected{
      background-color: ${props => props.category === 0 ? COLORS.PRIMARY_BLUE : COLORS.YELLOW_1};
      color: ${props => props.category === 0 ? 'white' : COLORS.DARK_GREY};
    }
  }
`;

export const BlockingDiv = styled.div`
  background-color: ${COLORS.BLACK_TRANSPARENT_30};
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  color: ${COLORS.WHITE};
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
`;

export const StyledTooltip = styled(Tooltip)`
  color: ${COLORS.DARK_GREY};
  /* position: absolute; */
  /* border: 1px solid black; */
  padding: 5px;
  margin: 0px 5px;
  cursor: pointer;
  :hover {
    color: ${COLORS.PRIMARY_BLUE};
  }
`;

export const StyledFileInputContainer = styled.div`
  position: absolute;
  bottom: 5px;
  right: 10px;
  @media ${breakpoints.mobileXL} {
    right: 20px;
  }

  .MuiSvgIcon-root {
    color: ${COLORS.GREY_1};
    cursor: pointer;
    :hover {
      color: ${COLORS.PRIMARY_BLUE};
    }
  }
`;

export const StyledButton = styled(Button)`
  &.MuiButton-root {
    padding: 5px;
    min-width: 0px;
    :hover {
      background-color: transparent;
    }
  }
`;

export const StyledFileInput = styled.input`
  display: none;
`;