const colors = {
  dark: "#2B2B2B",
  light: "#BBBBBB",
  ghost: "transparent",

  primary: "#2768B3",
  primaryDark: "#2160A9",
  secondary: "#4A90E2",
  secondaryDark: "#357ED3",

  textPrimary: "#2B2B2B",
  textSecondary: "#5A5A5A",
  textTertiary: "#777777",

  green: "#40B176",
  greenDark: "#35a56a",
  orange: "#FF9F6D",
  yellow: "#FFDB83",
  red: "#EC6B87",

  blue1: "#F3F9FF",
  blue2: "#E8EEFF",
  blue3: "#DEE6FB",
  blue4: "#9CC4F3",
  blue5: "#7C8EC0",
  blue6: "#4A90E2",
  blue7: "#357ED3",
  blue8: "#2768B3",
  blue9: "#2160A9",

  blueGradientLight: "linear-gradient(135deg, #4A90E2 0%, #3777C1 100%)",
  blueGradient: "linear-gradient(135deg, #3877C1 0%, #245C9E 64%, #205898 82%, #194E8C 100%)",
  blueGradientInverse: "linear-gradient(-47deg, #4A90E2 0%, #2C67AD 100%)",
  blueGradientDark: "linear-gradient(-59deg, #2964A8 2%, #245C9E 77%, #205898 82%, #194E8C 100%)",

  grey1: "#F2F2F2",
  grey2: "#EDEDED",
  grey3: "#DBDBDB",
  grey4: "#BBBBBB",
  grey5: "#999999",
  grey6: "#777777",
  grey7: "#5A5A5A",

  black: "#000000",
  black10: "rgba(0,0,0, 0.1)",
  black20: "rgba(0,0,0, 0.2)",
  black30: "rgba(0,0,0, 0.3)",
  black40: "rgba(0,0,0, 0.4)",
  black50: "rgba(0,0,0, 0.5)",
  black60: "rgba(0,0,0, 0.6)",
  black70: "rgba(0,0,0, 0.7)",
  black80: "rgba(0,0,0, 0.8)",
  black90: "rgba(0,0,0, 0.9)",
  black100: "rgba(0,0,0, 1)",

  white: "#FFFFFF",
  white10: "rgba(255,255,255, 0.1)",
  white20: "rgba(255,255,255, 0.2)",
  white30: "rgba(255,255,255, 0.3)",
  white40: "rgba(255,255,255, 0.4)",
  white50: "rgba(255,255,255, 0.5)",
  white60: "rgba(255,255,255, 0.6)",
  white70: "rgba(255,255,255, 0.7)",
  white80: "rgba(255,255,255, 0.8)",
  white90: "rgba(255,255,255, 0.9)",
  white100: "rgba(255,255,255, 1)",
};

export default colors;
