import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

// components
import GoogleSuggest from "../../../../components/molecules/GoogleSuggest";
import MetroIcon from '../../../../components/atoms/icons/metro';
import BikeIcon from '../../../../components/atoms/icons/bike';
import CarIcon from '../../../../components/atoms/icons/car';
import WalkIcon from '../../../../components/atoms/icons/walk';
// third part components
import LaddaButton from 'react-ladda';

// helpers
import { IconCar, IconBike, IconWalk, IconSubway } from "../../../../helpers/Icon";

// Store
import {
  saveOnBoardingThree,
	resetOnBoarding,
} from '../../../../store/onboarding/actions';
import {
	isOnBoardingStepThreeOnSuccess,
	isOnBoardingStepThreeOnLoad,
} from '../../../../store/onboarding/selectors';
import {
	getUser,
} from '../../../../store/collaborator/selectors';

// Style
import {
	Container,
	BubbleText,
	Text,
	ActionBox,
	BackButton,
	InputBox,
	TransportBox,
	GoogleSuggestWrapper,
	TransportIconWrapper,
} from './style';

const  Step3 = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [address, setAddress] = useState("");
	const [lat, setLat] = useState(null);
	const [lng, setLng] = useState(null);
	const [addressError, setAddressError] = useState(null);
	const [officeAddress, setOfficeAddress] = useState("");
	const [officeLat, setOfficeLat] = useState(null);
	const [officeLng, setOfficeLng] = useState(null);
	const [officeAddressError, setOfficeAddressError] = useState(null);
	const [transport, setTransport] = useState({
		subway: false,
		bike: false,
		walk: false,
		car: false
	});
	const {
    saveOnSuccess,
		saveOnLoad,
		user,
  } = useSelector(
    state => ({
      saveOnSuccess: isOnBoardingStepThreeOnSuccess(state),
			saveOnLoad: isOnBoardingStepThreeOnLoad(state),
			user: getUser(state),
    }),
    shallowEqual
	);

	useEffect(() => {
		dispatch(resetOnBoarding());
	}, []);

	const handleSubmit = (e) => {
		e.preventDefault();

		const onErrorAddress = (!!address.length && !lat && !lng);
		const onErrorOfficeAddress = (!!officeAddress.length && !officeLat && !officeLng);

		setAddressError(onErrorAddress);
		setOfficeAddressError(onErrorOfficeAddress);

		if (!onErrorAddress && !onErrorOfficeAddress) {
			dispatch(saveOnBoardingThree(
				address,
				lat,
				lng,
				officeAddress,
				officeLat,
				officeLng,
				transport,
			));
		}
	};

	useEffect(() => {
		if (user) {
			setAddress(user.address || '');
			setLat(user.lat || 0);
			setLng(user.lng || 0);
			setOfficeAddress(user.office_address || '');
			setOfficeLat(user.office_lat || 0);
			setOfficeLng(user.office_lng || 0);
			const newTransport = { ...transport };
			newTransport.subway = user.transport_subway;
			newTransport.bike = user.transport_bike;
			newTransport.walk = user.transport_walk;
			newTransport.car = user.transport_car;
			setTransport(newTransport);
		}
	}, [user]);

	const onSetHomeddress = (address, lat, lng) => {
		setAddress(address);
		setLat(lat);
		setLng(lng);
		setAddressError(null);
	};

	const onSetOfficeAddress = (officeAddress, officeLat, officeLng) => {
		setOfficeAddress(officeAddress);
		setOfficeLat(officeLat);
		setOfficeLng(officeLng);
		setOfficeAddressError(null);
	};

	const transportType = [
		{
			label: "Transport",
			value: "subway",
			icon: <TransportIconWrapper className="traffic_type_subway"><MetroIcon /></TransportIconWrapper>
		},
		{
			label: "À pied",
			value: "walk",
			icon: <TransportIconWrapper className="traffic_type_walk"><WalkIcon /></TransportIconWrapper>
		},
		{
			label: "Vélo",
			value: "bike",
			icon: <TransportIconWrapper className="traffic_type_bike"><BikeIcon /></TransportIconWrapper>
		},
		{
			label: "Voiture",
			value: "car",
			icon: <TransportIconWrapper className="traffic_type_car"><CarIcon /></TransportIconWrapper>
		}
	];

	useEffect(() => {
		if (saveOnSuccess) {
			history.push("/onboarding/4");
		}
	}, [saveOnSuccess]);

	const onChangeTransportType = (e) => {
		const { target: { name }} = e;
		const newTransport = { ...transport };
		newTransport[name] = !newTransport[name];
		setTransport(newTransport);
	};

	return (
		<Container>
			<form onSubmit={handleSubmit} noValidate>
				<BubbleText>
					<Text>
						Indiquez-moi votre lieu de domicile et je vous tiendrai informé du temps de trajet en temps réel selon votre mode de transport !
					</Text>
				</BubbleText>

				<InputBox>
					<GoogleSuggestWrapper>
						<GoogleSuggest
							defaultValue={address}
							setAddress={onSetHomeddress}
							label="Votre domicile"
						/>
						{addressError && <p>Merci de choisir parmi les propositions.</p>}
					</GoogleSuggestWrapper>
				</InputBox>

				<TransportBox>
					{/* <FormLabel component="label">
							Vos moyens de transport
					</FormLabel> */}
					<RadioGroup>
						{transportType.map((item, index) => {
							const {
								label,
								value,
								icon
							} = item;
								return (
									<FormControlLabel
										key={index}
										value={item.value}
										control={
											<Checkbox 
												checked={transport[value]} 
												onChange={onChangeTransportType} 
												name={item.value}
											/>
										}
										label={
											<>
												{label} {icon}
											</>
										}
									/>
								);
							}
						)}
					</RadioGroup>
				</TransportBox>

				<InputBox>
					<GoogleSuggestWrapper>
						<GoogleSuggest
							defaultValue={officeAddress}
							setAddress={onSetOfficeAddress}
							label="Votre lieu de travail"
						/>
						{officeAddressError && <p>Merci de choisir parmi les propositions.</p>}
					</GoogleSuggestWrapper>
				</InputBox>

				<ActionBox>
					<BackButton to="/onboarding/1">
						Revenir
					</BackButton>
					<LaddaButton
						type="submit"
						className="ladda-button"
						loading={saveOnLoad}
						// data-style={SLIDE_UP}
						data-spinner-size={30}
						data-spinner-color="#fff"
						data-spinner-lines={12}
					>
						Suivant
					</LaddaButton>
				</ActionBox>
			</form>
		</Container>
	);
};

export default Step3;
