import { createStore, applyMiddleware, compose } from 'redux';
import { devToolsEnhancer } from 'redux-devtools-extension';
import rootReducer from './rootReducer';
import rootSaga, { sagaMiddleware } from "./sagas";
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'
import { createMigrate } from 'redux-persist';
import { migrations } from '../store/persistReducers/reducers';

const MIGRATION_DEBUG = false;

const configureStore = () => {
  const enhancers = [];

  const persistConfig = {
    key: 'root',
    storage,
    version: 1,
    whitelist: ['persistReducers'],
    migrate: createMigrate(migrations, { debug: MIGRATION_DEBUG })
  }

  if (process.env.REACT_APP_ENV === 'develop') {
    enhancers.push(devToolsEnhancer());
  }

  const middleware = [sagaMiddleware];
  const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

  const persistedReducer = persistReducer(persistConfig, rootReducer());
  const store = createStore(
    persistedReducer,
    composedEnhancers,
  );

  sagaMiddleware.run(rootSaga);

  return { store };
};

export default configureStore;
