import React from 'react'
import PropTypes from 'prop-types';

import {
  MainContainer,
  IconContainer,
  Title
} from './style'

const GenericTitleWithIcon = ({
  title = "",
  Icon,
  // isRequired,
  className, // to pass styled component className to mainContainer
  iconClassName, // hack for popper clickAwayListener
  titleClassName, // hack for popper clickAwayListener
  ...rest
}) => {
  return (
    <MainContainer className={`${className ? className : ''} genericTitleWithIcon`} {...rest}>
      <IconContainer className={`${iconClassName} gtwi-iconContainer`}>
        {Icon}
      </IconContainer>
      <Title className={`${titleClassName} gtwi-titleContainer`}>
        {title}
      </Title>
    </MainContainer>
  )
}

GenericTitleWithIcon.propTypes = {
  title: PropTypes.string,
  Icon: PropTypes.object,
  className: PropTypes.string,
  iconClassName:  PropTypes.string,
  titleClassName:  PropTypes.string
}

export default GenericTitleWithIcon