import React from "react";

const LogoWithCircle = props => {
  const { 
    colorBg = "#F6F7F7", 
    colorCircle = "#EEE", 
    colorLogo = "#4A90E2", 
    width = "100%", 
    height = "100%" 
} = props;
  return (
    <svg
      className="icon logoWithCircle"
      width={width}
      height={height}
      viewBox="0 0 58 58"
      fill="none"
    >
      <g fill="none" fillRule="evenodd">
        <rect fill={colorBg} width={58} height={58} rx={29} />
        <path
          d="M29 0C12.984 0 0 12.984 0 29s12.984 29 29 29 29-12.984 29-29S45.016 0 29 0zm0 6c12.703 0 23 10.297 23 23S41.703 52 29 52 6 41.703 6 29 16.297 6 29 6z"
          fill={colorCircle}
          fillRule="nonzero"
        />
        <path
          d="M35.563 21.078H42v-.51l-6.416-1.45A3.108 3.108 0 0032.625 17C30.9 17 29.5 18.369 29.5 20.059c0 .844.35 1.609.916 2.162l3.914 3.83c.61.598.916 1.38.916 2.163 0 1.127-.933 2.04-2.084 2.04H23.25c-3.45 0-6.25 2.737-6.25 6.116v5.098h9.912c5.753 0 10.417-4.562 10.417-10.195v-3.058a5.01 5.01 0 00-1.526-3.605l-1.838-1.8a3.091 3.091 0 001.598-1.732zM19.083 36.37c0-2.252 1.866-4.077 4.167-4.077h4.968c-.948 4.085-4.674 7.136-9.135 7.136V36.37zm7.83 3.059h-1.245c2.402-1.657 4.123-4.195 4.675-7.136h2.82c.752 0 1.449-.211 2.058-.553-.248 4.287-3.868 7.689-8.309 7.689zm5.712-18.351a1.03 1.03 0 01-1.042-1.02c0-.563.467-1.019 1.042-1.019a1.03 1.03 0 011.042 1.02 1.03 1.03 0 01-1.042 1.019z"
          stroke={colorLogo}
          strokeWidth={0.6}
          fill={colorLogo}
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

export default LogoWithCircle;
