import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { useParams, useHistory } from "react-router-dom";
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import RoomIcon from '@material-ui/icons/Room';
import {
  shallowEqual,
  useDispatch,
  useSelector,
} from "react-redux";

// Layout
import DetailsSidebarFrontBaseLayout from "../../layouts/detailsSidebarFrontBaseLayout";


// Components
import BackIcon from '../../components/atoms/icons/back';
import ArrowRightIcon from '../../components/atoms/icons/arrowRight';
import AnswerIllustration from '../../components/atoms/illustrations/answerIllustration';
import GenericLoader from '../../../../components/molecules/GenericLoader';

//Modal
import PrestationModal from '../../components/organisms/PrestationModal';
import TicketMessageModal from '../../../../components/organisms/TicketMessageModal';

import { ticketStatusArray, finalTicketStatus } from '../../../../constants/ticketConstant';

//Store
import {
  getServices,
} from '../../../../store/concierge/actions';

import {
  getServicesOrdersList,
  setSelectedExpressinPrestation,
  getServiceTicket
} from '../../../../store/sidebar/actions';

import {
  getExpressingPrestation,
  getServicesOrdersListData,
  getSelectedServiceTicketData
} from '../../../../store/sidebar/selectors';

import {
  getConciergeServicesData,
} from '../../../../store/concierge/selectors';

import {
  getUser
} from '../../../../store/collaborator/selectors';

import {
  getCompanyData
} from '../../../../store/company/selectors';

//Resource
import moment from 'moment';
// Hooks

// Styles
import {
  Container,
  ServicesPrestationsWrapper,
  Header,
  BackButton,
  Title,
  AnswerIllustrationWrapper,
  BackIconWrapper,
  ServicesPrestationsBox,
  CyconiaInfoContact,
  CyconiaInfoContent,
  ServicesPrestationsOrdersItem,
  NonAccordionCustom,
  AccordionCustom,
  AccordionDate,
  AccordionOrders,
  AccordionDetailsDate,
  AccordionDetailsPrice,
  AccordionDetailsAddress,
  ArrowIconWrapper,
  EuroIconWrapper,
  RoomIconWrapper,
  EventAvailableIconWrapper,
  PrestationsButtonList,
  CanselPrestationsButton,
  EditPrestationsButton,
  PaginationContainer,
  StyledIconButton
} from "./style";


const ConciergerieServicesPrestations = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { ticketId } = useParams();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [maxPageNumber, setMaxPageNumber] = useState(1);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [serviceTickets, setServiceTickets] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const containerRef = useRef();

  const {
    userData,
    servicesOrdersList,
    selectedPrestationData,
    companyData,
    serviceData,
    selectedServiceTicket
  } = useSelector(
    state => ({
      userData: getUser(state),
      servicesOrdersList: getServicesOrdersListData(state),
      selectedPrestationData: getExpressingPrestation(state),
      companyData: getCompanyData(state),
      serviceData: getConciergeServicesData(state),
      selectedServiceTicket: getSelectedServiceTicketData(state),
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!serviceData) {
      dispatch(getServices());
    }
  }, []);

  useEffect(() => {
    dispatch(getServicesOrdersList(pageNumber));
  }, [pageNumber]);

  useEffect(() => { // For auto scroll to view
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: 'auto' });
    }
  }, [serviceTickets]);

  useEffect(() => {
    if (ticketId) {
      dispatch(getServiceTicket(ticketId));
      setOpenModal(true);
    }
  }, [ticketId]);

  useEffect(() => {
    if (selectedServiceTicket) {
      setSelectedRequest(selectedServiceTicket);
    }
  }, [selectedServiceTicket])

  useEffect(() => {
    if (serviceData && companyData && servicesOrdersList) {
      if (companyData.serviceVersion === 1) setServiceTickets(servicesOrdersList);
      else {
        const formatedTickets = _.cloneDeep(servicesOrdersList);
        _.map(formatedTickets.items, (ticket) => {
          const service = _.find(serviceData, (service) => service.id == ticket.service_id);
          ticket.service = service?.name;
          ticket.collaborator = userData;
          ticket.company_site = userData.company_site;
          ticket.messages = [];
        });
        setServiceTickets(formatedTickets);
      }
      const total = Math.ceil(servicesOrdersList.total_count / (servicesOrdersList.num_items_per_page));
      setMaxPageNumber(total);
      setIsLoading(false);
    }
  }, [companyData, serviceData, servicesOrdersList]);

  const renderPaginationInfo = () => {
    if (serviceTickets) {
      const current = serviceTickets.current_page_number;
      return `${current} sur ${maxPageNumber} au total`;
    }
  }

  const cancelOrder = (data) => {
    dispatch(setSelectedExpressinPrestation({ type: 'cancel', item: data }));
  }

  const editOrder = (data) => {
    dispatch(setSelectedExpressinPrestation({ type: 'edit', item: data }));
  }

  const handleRequestClick = (item) => {
    if (companyData.serviceVersion !== 2) return;
    history.push(`${history.location.pathname.replace(/\/$/, '')}/${item.id}`);
  }

  const handlePagination = (value) => {
    if (serviceTickets) {
      const newPageNumber = pageNumber + value;
      if (newPageNumber === 0 || newPageNumber > maxPageNumber) return;
      setPageNumber(newPageNumber);
    }
  }

  const closeModal = () => {
    setSelectedRequest(null);
    setOpenModal(false);
    history.push(".");
  }

  return (
    <DetailsSidebarFrontBaseLayout
      id="detailsSidebarLayoutBorneServicesPrestations"
      className="detailsSidebarLayoutBorneServicesPrestations page-container sidebarPage"
    >
      <Container ref={containerRef}>
        <ServicesPrestationsWrapper>
          <Header to="/conciergerie">
            <BackButton>
              <BackIconWrapper>
                <BackIcon />
              </BackIconWrapper>
            </BackButton>
            <Title>
              Liste de mes Prestations
            </Title>
          </Header>

          <CyconiaInfoContact>
            <AnswerIllustrationWrapper>
              <AnswerIllustration />
            </AnswerIllustrationWrapper>
            <CyconiaInfoContent>
              <span>Besoin d’aide sur une commande ?</span>
              <span>Suivez facilement vos demandes en cliquant dessus</span>
            </CyconiaInfoContent>
          </CyconiaInfoContact>

          <ServicesPrestationsBox className="o-aside-dashboardV2__connect-expressing-orders">
            <PaginationContainer>
              <StyledIconButton onClick={() => handlePagination(-1)}>
                <ArrowBackIcon />
              </StyledIconButton>
              {renderPaginationInfo()}
              <StyledIconButton onClick={() => handlePagination(1)}>
                <ArrowForwardIcon />
              </StyledIconButton>
            </PaginationContainer>
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}
              onChangeIndex={() => { }}
            >
              <ServicesPrestationsOrdersItem>
                {isLoading &&
                  <GenericLoader />
                }
                {!isLoading &&
                  serviceTickets != null &&
                  serviceTickets?.items.map((item) => {
                    const CustomComponent = companyData.serviceVersion === 2 ? NonAccordionCustom : AccordionCustom
                    return (
                      <CustomComponent key={item.id} onClick={() => handleRequestClick(item)}>
                        <AccordionSummary
                          expandIcon={
                            <ArrowIconWrapper>
                              <ArrowRightIcon />
                            </ArrowIconWrapper>
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <AccordionDate>PRESTATION LE {moment(item.created_at).format("DD-MM-YYYY")}</AccordionDate>
                          <AccordionOrders>
                            {item.service} N°{item.id} {(item.status === ticketStatusArray[4] || item.status === finalTicketStatus) ? '✅' : ''}
                          </AccordionOrders>
                        </AccordionSummary>
                        {companyData.serviceVersion === 1 && item.address != null &&
                          (<AccordionDetailsAddress>
                            <RoomIconWrapper>
                              <RoomIcon style={{ color: "#483D8B" }} />
                            </RoomIconWrapper>
                            {item.address}
                          </AccordionDetailsAddress>)
                        }
                        {companyData.serviceVersion === 1 &&
                          <PrestationsButtonList>
                            <CanselPrestationsButton onClick={() => cancelOrder(item)}>Annuler ma commande</CanselPrestationsButton>
                            <EditPrestationsButton onClick={() => editOrder(item)} >Modifier ma commande</EditPrestationsButton>
                          </PrestationsButtonList>
                        }
                      </CustomComponent>)
                  })
                }
                {!isLoading && serviceTickets != null && serviceTickets.items.length === 0 && (
                  <span>Vous n'avez pas de prestations en cours</span>
                )
                }
              </ServicesPrestationsOrdersItem>
              {selectedPrestationData.data && (<PrestationModal data={selectedPrestationData.data} />)}
            </SwipeableViews>
          </ServicesPrestationsBox>
        </ServicesPrestationsWrapper>
        {companyData.serviceVersion === 2 && openModal &&
          <TicketMessageModal
            onClose={closeModal}
            ticketRequest={selectedRequest}
          />
        }
      </Container>
    </DetailsSidebarFrontBaseLayout>
  );
}

export default ConciergerieServicesPrestations;
