import styled from "styled-components";
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from "@material-ui/core/Dialog";
import Button from '@material-ui/core/Button';

import DrawerCpt from '../../../../components/organisms/drawerCpt';
import { COLORS } from '../../../../../../constants/design';
import GenericMaterialMenu from "../../../../../../components/molecules/GenericMaterialMenu";
import GenericAccordion from "../../../../../../components/molecules/GenericAccordion";

export const Container = styled.div`
`;

export const DrawerCptCustom = styled(DrawerCpt)`
  &&{
    background-color: red;
    &.contentManagementDrawer{
      /* background-color: blue; */
    }
  }
`;

export const DrawerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 700px;
  max-height: 1700px;
  position: relative;
  .textTitle {
    font-weight: 600;
    font-size: 0.9rem;
    color: ${COLORS.DARK_GREY};
    margin: 14px 5px 14px 0px;
  };
  .textTooltip {
    color: ${COLORS.GREY_1};
  }
  .textFieldInput {
    input {
      font-size: 0.9rem;
    }
  };
`;

export const InfoContainer = styled.div`
  font-size: 0.9rem;
  color: ${COLORS.DARK_GREY};
  display: flex;
  padding: 20px 20px 0px 20px;
  >div:not(:last-child) {
    margin-right: 30px;
  };
`;

export const InfoTextContainer = styled.div`
  display: flex;
  width: 40%;
`;

export const InfoTextTitle = styled.div`
  font-size: rem;
  font-weight: 600;
  margin-right: 10px;
`;

export const ContentBarContainer = styled.div`
  /* border: 1px solid black; */
  padding: 20px 20px 0px 20px;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  position: relative;
  >div:not(:last-child) {
    margin-bottom: 15px;
  };
`;

export const ContentContainer = styled.div`
  /* border: 1px solid black; */
  min-height: 68vh;
  >div:not(:last-child) {
    margin-bottom: 15px;
  };
`;

export const ContentWrapper = styled.div`
  border: 1px solid ${COLORS.LIGHT_GREY_4};
  padding: 15px;
  border-radius: 5px;
  position: relative;
  >div:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const StyledAccordion = styled(GenericAccordion)`
  && {
    .MuiAccordion-root {
      font-size: 0.9rem;
    }
  }
`;

export const ContentEditContainer = styled.div`
  position: relative;
  >div:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const ContentEditBlocker = styled.div`
  background-color: ${COLORS.BLACK_TRANSPARENT_30};
  width: 100%;
  height: 100%;
  position: absolute;
  color: ${COLORS.WHITE};
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

export const StyledTooltip = styled(Tooltip)`
  color: ${COLORS.GREY_1};
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  :hover {
    color: ${COLORS.DARK_GREY};
  }
`;

export const SitesSelectorBox = styled.div`
  margin-bottom: 10px;
`;

export const StyledGenericMaterialMenu = styled(GenericMaterialMenu)`
  .textFieldTitle {
    margin-bottom: 10px;
  }
`;

export const SwitchContainer = styled.div`
    position: 'relative';
`;

export const StyledDialog = styled(Dialog)`
  && {
    .MuiDialog-container {
      .MuiDialog-paper {
        color: #212121;
        background-color: #FFFFFF;
        border-radius: 10px;
        &.MuiPaper-rounded {
          border-radius: 10px;
          width: 100%;
          max-width: 750px;
        }
        .MuiDialogTitle-root {
          text-align: center;
          font-weight: 600;
          padding: 20px 50px 0px 50px;
        }
        .MuiDialogContent-root {
          padding: 40px 50px;
          .MuiTypography-colorTextSecondary {
            text-align: center;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.8);
          }
        }
        .MuiDialogActions-root {
          justify-content: space-between;
          padding: 0 50px 40px 50px;
          .MuiButtonBase-root {
            height: 52px;
            margin: 0 10px;
            .MuiButton-label {
              font-size: 12px;
            }
            &:hover {
              .MuiButton-label {
              }
            }
          }
        }
      }
    }
  }
`;