import styled from "styled-components";

export const MainContainer = styled.div`
`;

export const AccountItemTitle = styled.strong`
  font-size: 16px;
  color: #5A5A5A;
  font-weight: 600;
  text-align: left;
  transition: all .2s ease-out;
`;

export const ArrowIconWrapper = styled.span`
  max-width: 7px;
  max-height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 auto;
  svg path{
    transition: all .2s ease-out;
  }
`;

export const AccountLogoutItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 25px;
  margin: 0 0 10px 0;
  background-color: #FFFFFF;
  height: auto;
  cursor: pointer;
  transition: all .2s ease-out;
  border-radius: 6px;
  &:last-child{
    border-bottom: 0;
  }
  &:hover{
    background-color: #FAFAFA;
    ${AccountItemTitle}{
      color: #4A90E2;
    }
    ${ArrowIconWrapper}{
      svg{
        path{
          fill: #397dd2;
          stroke: #397dd2;
        }
      }
    }
  }
`;

export const AccountItemCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4A90E2;
  border-radius: 100px;
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;
  margin-right: 25px;
  &.accountItemCircle-logout{
    background-color: #EC6B87;
  }
`;

export const LogoutIconWrapper = styled.span`
  max-width: 14px;
  max-height: 14px;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;