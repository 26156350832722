import { call, put, select } from 'redux-saga/effects'
import {
  getTicketRequestsSuccess,
  getTicketRequestsError,
  saveTicketRequestSuccess,
  saveTicketRequestError
} from '../actions';
// import ticketRequests from '../../../../dummyData/ticketRequests.json';
import endpoint from '../../../helpers/api';
import apiCall from '../../apiCall';
import * as authenticationSelectors from '../../authentication/selectors';
import { saveTicketRequestService } from '../../../services/ticketRequestServices';
import { toaster } from '../../../helpers/toaster';

export function* getTicketRequestsHandler() {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    // TO DO: to get a separate API and store reducer for all ticket status vs archieve ticket status
    const ticketList = yield call(apiCall, `${endpoint.getTicketRequests}?limit=1000`, userToken);
    yield put(getTicketRequestsSuccess(ticketList));
  } catch (err) {
    yield put(getTicketRequestsError(err));
  }
}

export function* saveTicketRequestHandler(action) {
  const { id, formData } = action.data;
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const response = yield call(saveTicketRequestService, endpoint.editTicketRequest(id), userToken, formData);
    yield put(saveTicketRequestSuccess(response.data));
    toaster('Vos changements ont été enregistrés', 'success');
  } catch (err) {
    yield put(saveTicketRequestError(err));
    toaster(`Une erreur s'est produite. Veuillez réessayer`, 'error');
  }
}