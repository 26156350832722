import React from 'react';
import {
  shallowEqual,
  useSelector,
} from "react-redux";
import {
  useRouteMatch,
} from "react-router-dom";
import moment from 'moment';
import _ from 'lodash';

import ArrowRightIcon from '../../atoms/icons/arrowRight';
import BorneSmallIcon from '../../atoms/icons/borneSmall';
import useTheme from "../../../../../theme-style/useThemeCustomHook";

import { getUserExpressingId } from '../../../../../store/collaborator/selectors';
import {
  getExpressingOnGoingOrdersData,
  getExpressingAmountData
} from '../../../../../store/sidebar/selectors';

import {
  MainContainer,
  BorneList,
  BorneItemNotConnected,
  BorneItemCircle,
  BorneSmallIconWrapper,
  BorneItemTitle,
  ArrowIconWrapper,
  BorneItemConnected,
  BorneItemConnectedHead,
  BorneItemWithoutOrders,
  BorneItemWithoutOrdersTop,
  BorneItemConnectedBodyCircle,
  BorneItemConnectedCircle,
  BorneItemConnectedBodyContent,
  BorneItemConnectedBodyTitle,
  BorneItemConnectedBodyText,
  BorneItemConnectedBody,
  BorneItemConnectedBodyTop,
  BorneItemConnectedBodyMiddle,
  BorneItemConnectedBodyMiddleItem,
  BorneItemConnectedOnlyCircle,
  BorneItemConnectedBodyMiddleItemText,
  BorneItemConnectedBodyMiddleItemDate,
  BorneItemConnectedFoot,
  BorneItemConnectedFootCircle,
  BorneItemConnectedFootTitle,
  BorneItemConnectedFootCash
} from './style';

const BorneModule = () => {
  const theme = useTheme();
  const { path } = useRouteMatch();

  const {
    userExpressingId,
    onGoingOrders,
    totalBalance,
  } = useSelector(
    state => ({
      userExpressingId: getUserExpressingId(state),
      onGoingOrders: getExpressingOnGoingOrdersData(state),
      totalBalance: getExpressingAmountData(state),
    }),
    shallowEqual
  );

  return (
    <MainContainer>
        <BorneList>
          {!userExpressingId ?
            <BorneItemNotConnected to={`${path}/borne/connexion`}>
              <BorneItemCircle>
                <BorneSmallIconWrapper>
                  <BorneSmallIcon color={theme.colors.white} />
                </BorneSmallIconWrapper>
              </BorneItemCircle>
              <BorneItemTitle>
                Connexion à ma Borne
              </BorneItemTitle>
              <ArrowIconWrapper>
                <ArrowRightIcon color={theme.colors.grey4} />
              </ArrowIconWrapper>
            </BorneItemNotConnected>
            :
            <BorneItemConnected to={`${path}/borne/ma-borne`}>
              <BorneItemConnectedHead>
                <BorneItemCircle>
                  <BorneSmallIconWrapper>
                    <BorneSmallIcon color={theme.colors.white} />
                  </BorneSmallIconWrapper>
                </BorneItemCircle>
                <BorneItemTitle>
                  Ma Borne
                </BorneItemTitle>
                <ArrowIconWrapper>
                  <ArrowRightIcon color={theme.colors.grey4} />
                </ArrowIconWrapper>
              </BorneItemConnectedHead>

              {(onGoingOrders && onGoingOrders.length === 0) ?
                <BorneItemWithoutOrders>
                  <BorneItemWithoutOrdersTop>
                    <BorneItemConnectedBodyCircle>
                      <BorneItemConnectedCircle className="borneItemCircle-blue" />
                      <BorneItemConnectedCircle className="borneItemCircle-green" />
                      <BorneItemConnectedCircle className="borneItemCircle-orange" />
                    </BorneItemConnectedBodyCircle>
                    <BorneItemConnectedBodyContent>
                      <BorneItemConnectedBodyTitle>
                        Aucune commande pour le moment
                      </BorneItemConnectedBodyTitle>
                      <BorneItemConnectedBodyText>
                        Vous n’avez jamais utilisé Express’ing ? Essayer dès maintenant les services de votre conciergerie connectée.
                        <span role="img" aria-label="emoji-smile">🙂</span>
                      </BorneItemConnectedBodyText>
                    </BorneItemConnectedBodyContent>
                  </BorneItemWithoutOrdersTop>
                </BorneItemWithoutOrders>
                :
                <BorneItemConnectedBody>
                  <BorneItemConnectedBodyTop>
                    <BorneItemConnectedBodyCircle>
                      <BorneItemConnectedCircle className="borneItemCircle-blue" />
                      <BorneItemConnectedCircle className="borneItemCircle-green" />
                      <BorneItemConnectedCircle className="borneItemCircle-orange" />
                    </BorneItemConnectedBodyCircle>
                    <BorneItemConnectedBodyTitle>
                      Mes commandes en cours
                    </BorneItemConnectedBodyTitle>
                  </BorneItemConnectedBodyTop>

                  <BorneItemConnectedBodyMiddle>
                    {onGoingOrders && onGoingOrders.slice(0, 2).map((order, idx) => (
                      <BorneItemConnectedBodyMiddleItem key={idx}>
                        <BorneItemConnectedOnlyCircle className="borneItemOnlyCircle-blue" />
                        <BorneItemConnectedBodyMiddleItemText>
                          Commande N°{order.ID}
                        </BorneItemConnectedBodyMiddleItemText>
                        <BorneItemConnectedBodyMiddleItemDate>
                          Disponible le {moment(order.Planned, "YYYY-MM-DD").format("DD/MM/YYYY")}
                        </BorneItemConnectedBodyMiddleItemDate>
                      </BorneItemConnectedBodyMiddleItem>
                    ))}

                  </BorneItemConnectedBodyMiddle>
                </BorneItemConnectedBody>
              }


              <BorneItemConnectedFoot>
                <BorneItemConnectedFootCircle>
                  €
                </BorneItemConnectedFootCircle>
                <BorneItemConnectedFootTitle>
                  Mon crédit
                </BorneItemConnectedFootTitle>
                <BorneItemConnectedFootCash>
                  {totalBalance} €
                </BorneItemConnectedFootCash>
              </BorneItemConnectedFoot>
            </BorneItemConnected>
          }

        </BorneList>
    </MainContainer>
  );
};

export default BorneModule;