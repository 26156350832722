import React from 'react';
import _ from 'lodash';

import GenericTextField from '../../../../../../../components/molecules/GenericTextField';

import {
  HELPER_KEY,
  HELPER_TEXT
} from '../../../../../../../constants/helperConstants';

import {
  MainContainer,
  StyledAccordion,
  StyledTitle,
  DetailContainer,
} from './style';

const HelperSettings = ({
  context
}) => {

  const renderHelperField = () => {
    let index = 0;
    return _.map(HELPER_KEY, (data, helperKey) => {
      index++;
      return (
        <GenericTextField
          key={helperKey}
          title={`Helper nº${index}`}
          readOnly={true}
          placeholder={HELPER_TEXT[helperKey]}
          value={context?.helper?.[helperKey] || ''}
          disabled={!context.active}
        />
      );
      
    });
  };

  return (
    <MainContainer>
      {context &&
        <StyledAccordion
          headerComponent={
            <StyledTitle>
              {`Helper settings ${context.active ? '' : '(pas actif)'}`}
            </StyledTitle>
          }
          detailComponent={
            <DetailContainer>
              {renderHelperField()}
            </DetailContainer>
          }
        />
      }
    </MainContainer>
  );
};

export default HelperSettings;