import React from "react";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { elementScrollIntoViewPolyfill } from "seamless-scroll-polyfill";

import useDrag from "./hooks/useDrag";
import { LeftArrow, RightArrow } from "./components/Arrow";

import {
  MainContainer,
} from './style';

if (typeof window !== "undefined" && typeof document !== "undefined") {
  elementScrollIntoViewPolyfill();
}

const GenericHorizontalScrollView = ({
  children,
  className
}) => {
  const { dragStart, dragStop, dragMove } = useDrag();

  const handleDrag = ({ scrollContainer }) => (event) =>
    dragMove(event, (posDiff) => {
      if (scrollContainer.current) {
        scrollContainer.current.scrollLeft += posDiff;
      }
    });

  const onWheel = (apiObj, event) => {
    const isTouchpad = Math.abs(event.deltaX) !== 0 || Math.abs(event.deltaY) < 15;

    if (isTouchpad) {
      event.stopPropagation();
      return;
    }

    if (event.deltaY > 0) {
      apiObj.scrollNext();
    } else if (event.deltaY < 0) {
      apiObj.scrollPrev();
    }
  }

  return (
    <MainContainer
      className={`${className ? className : ''} genericHorizontalScrollView`}
      onMouseLeave={dragStop}
    >
      <ScrollMenu
        LeftArrow={LeftArrow}
        RightArrow={RightArrow}
        onWheel={onWheel}
        onMouseDown={() => dragStart}
        onMouseUp={() => dragStop}
        onMouseMove={handleDrag}
      >
        {children}
      </ScrollMenu>
    </MainContainer>
  );
}

export default GenericHorizontalScrollView;

// NOTE:

// This component is created from react - horizontal - scrolling - menu,
// For the documentation: https://github.com/asmyshlyaev177/react-horizontal-scrolling-menu

// Most of the code are taken directly from the demo,
// Some modification was made to make this component resuable,

// The children given to this component must contains 'itemId' for the scrollmenu to function properly
// Example usage in cyconia project can be seen at src/app/containers/Chatbot/components/MessageList/components/Message/index.jsx
// Example children:
// <ServiceCard
//   key={index}
//   itemId={index} // This props is important for Horizontal scroll view to work!
//   card={card}
//   onMessageButtonClick={onMessageButtonClick}
// />
