import Resizer from "react-image-file-resizer";

export const resizeImage = ({
  file,
  maxWidth = 1200,
  maxHeight = 1200,
  compressFormat = 'JPEG',
  quality = 100,
  rotation = 0,
  outputType = 'file',
  minWidth = 0,
  minHeight = 0,
}) => {
  if (file) {
    return (
      new Promise((resolve) => {
        Resizer.imageFileResizer(
          file,
          maxWidth,
          maxHeight,
          compressFormat,
          quality,
          rotation,
          (uri) => {
            resolve(uri);
          },
          outputType,
          minWidth,
          minHeight
        );
      })
    )
  }
};