import styled from "styled-components";
import { COLORS } from "../../../../../constants/design";

export const MainContainer = styled.div`
  box-sizing: border-box;
  background-color: ${COLORS.LIGHT_BLUE_1};
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Quicksand;
`;

export const StyledRestrictedImage = styled.img`
  width: 500px;
  @media screen and (max-width: 768px){
    width: 300px;
  }
`;

export const StyledRestrictedMessage = styled.div`
  /* border: 1px solid black; */
  margin: 15px;
  color: ${COLORS.DARK_GREY};
  font-size: 1.2rem;
  width: 50%;
  padding: 0px 10px;
  text-align: center;
  @media screen and (max-width: 768px){
    width: 70%;
    font-size: 1rem;
  }
`;

export const StyledErrorLog = styled(StyledRestrictedMessage)`
  margin: 3px;
  font-size: 0.7rem;
  @media screen and (max-width: 768px){
    font-size: 0.6rem;
  }
`;