export const ticketStatusArray = [
  'new_request',
  'in_progress',
  'sent',
  'accepted',
  'treated',
]

export const finalTicketStatus = 'archive';

export const ticketStatusConstant = {
  [ticketStatusArray[0]]: 'En attente de PEC',
  [ticketStatusArray[1]]: 'PEC en cours',
  [ticketStatusArray[2]]: 'PEC envoyée',
  [ticketStatusArray[3]]: 'PEC acceptée',
  [ticketStatusArray[4]]: 'PEC traitée'
}

export const ticketStatusButtonConstant = {
  [ticketStatusArray[0]]: 'Prendre en charge',
  [ticketStatusArray[1]]: 'Envoyer LA PEC',
  [ticketStatusArray[2]]: 'Poursuivre la PEC ',
  [ticketStatusArray[3]]: 'Clôturer la PEC',
  [ticketStatusArray[4]]: 'Archiver la PEC'
}