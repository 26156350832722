import React, { useState, useEffect } from 'react'
import { Formik } from 'formik';

import GenericMultipleSelect from '../../../../../../components/molecules/GenericMultipleSelect';
import GenericDrawerValidationButtons from '../../../../../../components/molecules/GenericDrawerValidationButtons';

import {
  StyledAccordion,
  StyledForm,
} from './style'

const ServiceActifFilter = ({
  servicesData,
  setFilterConditions
}) => {
  const [servicesList, setServicesList] = useState([]);

  useEffect(() => {
    if (servicesData != null) {
      const newList = servicesData.map((service) => {
        return { label: service.name, value: service.id }
      })
      setServicesList(newList)
    }
  }, [servicesData])

  const formInitValue = {
    services: [],
  }

  const handleResetForm = (props) => {
    props.resetForm();
    setFilterConditions(formInitValue);
  }

  return (
    <StyledAccordion
      headerComponent={"Recherche"}
      detailComponent={
        <Formik
          initialValues={formInitValue}
          setFieldValue
          onSubmit={(values) => setFilterConditions(values)}
        >
          {props => (
            <StyledForm>
              <GenericMultipleSelect
                id="services"
                name="services"
                onChange={(e, value) => props.setFieldValue('services', value)}
                value={props.values.services}
                list={servicesList}
                fildName="services"
                title="Services"
              />
              <GenericDrawerValidationButtons
                leftButonText={'Réinitialiser'}
                RightButtonText={'Chercher'}
                onclickLefttButton={() => handleResetForm(props)}
                submitable={false}
              />
            </StyledForm>
          )}
        </Formik>
      }
    />
  )
}

export default ServiceActifFilter
