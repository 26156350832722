import React from 'react';

import Loader from '../GenericLoader';
import GenericDrawerValidationButtons from '../GenericDrawerValidationButtons';

import {
  MainContainer,
  FormContainer
} from './style';

const GenericDrawerFormContainer = ({
  isLoading,
  onClose,
  onSubmit,
  submitable = false,
  className, // to pass styled component className to mainContainer
  children,
}) => {
  return (
    <MainContainer className={`${className ? className : ''} genericDrawerFormContainer`}>
      <FormContainer>
        {isLoading ?
          <Loader />
          :
          children
        }
      </FormContainer>
      <GenericDrawerValidationButtons
        leftButonText={'Annuler'}
        RightButtonText={'Sauvegarder'}
        onclickLefttButton={onClose}
        onclickRightButton={onSubmit}
        submitable={submitable}
      />
    </MainContainer>
  );
};

export default GenericDrawerFormContainer;