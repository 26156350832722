import styled from "styled-components";

import { COLORS } from '../../../../../../constants/design';
import DrawerCpt from '../../../../components/organisms/drawerCpt';
import GenericDrawerFormContainer from "../../../../../../components/molecules/GenericDrawerFormContainer";

export const Container = styled.div`
`;

export const DrawerCptCustom = styled(DrawerCpt)`
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 700px;
  height: 100%;
  position: relative;
  box-sizing: border-box;
`;

export const StyledFormContainer = styled(GenericDrawerFormContainer)`
  .genericMaterialMenu {
    .textFieldTitle {
      margin-bottom: 10px;
    }
  }
  .MuiCheckbox-root {
    padding: 0px;
    margin-right: 10px;
  }
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: ${COLORS.PRIMARY_BLUE};
  }
  .MuiCheckbox-colorSecondary.Mui-disabled {
    color: ${COLORS.LIGHT_GREY_4};
  }
`;

export const ListTitle = styled.div`
  font-weight: 600;
  font-size: 1rem;
  display: flex;
  align-items: center;
  .MuiButtonBase-root {
    margin: 0px 10px;
  }
`;