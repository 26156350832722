export const isGetConfigLoading = state => state.config.get.loading;
export const isGetConfigSuccess = state => state.config.get.success;
export const isGetConfigError = state => state.config.get.error;
export const hasConfigData = state => state.config?.data
  ? !!Object.keys(state.config.data)?.length
  : false;

export const getSubDomain = state => {
  if (hasConfigData(state)) {
    return state.config.data.subDomain;
  }
  return null;
};

export const getCompanySite = state => {
  if (hasConfigData(state)) {
    return state.config.data.companySite;
  }
  return null;
};

export const getCompanyInfo = state => {
  if (hasConfigData(state)) {
    return state.config.data.companyInfo;
  }
  return null;
};

export const isUserHaveConciergeRole = state => {
  const currentUserRoles = state.collaborator.user.roles;
  const allowedRoles = [
    "ROLE_CONCIERGE",
    "ROLE_COMPANY_MANAGER"
  ]

  const userRolesCheck = allowedRoles.some((el) => currentUserRoles.includes(el));
  return userRolesCheck

}

export const isUserHaveCompanyManagerRole = state => {
  const currentUserRoles = state.collaborator.user.roles;
  const allowedRoles = [
    "ROLE_COMPANY_MANAGER"
  ]

  const userRolesCheck = allowedRoles.some((el) => currentUserRoles.includes(el));
  return userRolesCheck

}