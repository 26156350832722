import React, { useEffect } from "react";
import {
  // BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch
} from "react-router-dom";
import { useDispatch } from "react-redux";

// Containers
import Dashboard from '../containers/Dashboard';
import KbConciergerie from "../containers/KbConciergerie";
import SitesManagement from "../containers/SitesManagement";
import DirectoryOfServices from '../containers/DirectoryOfServices';
import ContentManagementContainer from '../containers/ContentManagementContainer';
import UsersManagementContainer from '../containers/UsersManagementContainer';
import Requests from "../containers/Requests";
import BookingReport from "../containers/BookingReport";

import {
  getCorporations,
  getSites,
  getConcierges,
  getProviders,
  getServices,
  getServicesActifs,
  getTicketRequests,
  getCalendarObjects,
  getInternalServiceForm
} from '../../../store/concierge/actions';

import {
  getSpecificCase,
} from '../../../store/adminFront/actions';

import { getSkillsDomainsList, getElementInformationByCompany } from '../../../store/adminFront/actions';
import { RoomBookingRoute } from "../../../routes/RoomBookingRoute";

const RoutesApp = () => {
  const dispatch = useDispatch();
  const { path } = useRouteMatch();

  useEffect(() => {
    dispatch(getCorporations());
    dispatch(getSites());
    dispatch(getInternalServiceForm());
    dispatch(getConcierges());
    dispatch(getProviders());
    dispatch(getServices());
    dispatch(getServicesActifs());
    dispatch(getTicketRequests());
    dispatch(getCalendarObjects());
    dispatch(getSkillsDomainsList());
    dispatch(getElementInformationByCompany());
    dispatch(getSpecificCase({ limit: 100, pageNumber: 1 }));
  }, []);


  return (
    <React.Fragment>
      <Switch>
        <Route path={`${path}/dashboard`} component={Dashboard} />
        <Route path={`${path}/training`} component={KbConciergerie} />
        <Route path={`${path}/site-management`} component={SitesManagement} />
        <Route path={`${path}/directory-services`} component={DirectoryOfServices} />
        <Route path={`${path}/content-management`} component={ContentManagementContainer} />
        <Route path={`${path}/users-management`} component={UsersManagementContainer} />
        <Route path={`${path}/my-requests`} component={Requests} />
        <RoomBookingRoute path={`${path}/booking-report`} component={BookingReport} />
        <Redirect to={`${path}/dashboard`} />
      </Switch>
    </React.Fragment>
  );
};

export default RoutesApp;
