import React, { useEffect, useState } from 'react';
import {
  shallowEqual,
  useDispatch,
  useSelector,
} from "react-redux";
import { useHistory } from "react-router";
import { useRouteMatch } from "react-router-dom";
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import _ from 'lodash';

//Import route
import Routes from './routes';

import GenericAdminFrontHeader from '../../components/molecules/GenericAdminFrontHeader';
import GenericTable from '../../components/molecules/GenericTable';
import Loader from '../../components/molecules/GenericLoader';

import {
  getCollaboratorList,
  getCollaboratorRoles,
  editCollaboratorReset
} from '../../store/userManagement/actions';

import {
  getCollaboratorListData,
  getCollaboratorListIsLoading,
  getCollaboratorRolesData,
  getCollaboratorRolesIsLoading
} from '../../store/userManagement/selectors';

import {
  Container,
  TableButtonDiv,
  TableIcon,
  SettingContainer,
  StyledPaginationArrow,
  TableWrapper
} from './style';

const headerList = [
  { id: "lastname", label: "Nom" },
  { id: "firstname", label: "Prénom" },
  { id: "email", label: "Adresse e-mail" },
  { id: "site", label: "Site d'entreprise" },
  { id: "roles", label: "Rôles" },
  { id: "rowAction", label: "Action", disableSort: true },
];

const rowItemProps = {
  id: {
    display: 'hidden',
  },
};


const UserManagement = () => {
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const history = useHistory();
  const [pageNumber, setPageNumber] = useState(1);
  const [maxPageNumber, setMaxPageNumber] = useState(1);
  const [itemList, setItemList] = useState([]);

  const {
    collaboratorListData,
    collaboratorListIsLoading,
    collaboratorRolesData,
    collaboratorRolesIsLoading,
  } = useSelector(
    state => ({
      collaboratorListData: getCollaboratorListData(state),
      collaboratorListIsLoading: getCollaboratorListIsLoading(state),
      collaboratorRolesData: getCollaboratorRolesData(state),
      collaboratorRolesIsLoading: getCollaboratorRolesIsLoading(state),
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getCollaboratorRoles());
  }, [])

  useEffect(() => {
    dispatch(getCollaboratorList({ limit: 50, pageNumber: pageNumber }));
  }, [pageNumber]);

  useEffect(() => {
    if (collaboratorListData) {
      const newList = _.compact(_.map(collaboratorListData?.items, (data) => {
        const newListItem = {
          id: data.id,
          lastname: data.lastname,
          firstname: data.firstname,
          email: data.email,
          site: data.company_site,
          roles: generateRolesText(data.roles),
          rowAction:
            <TableAction
              openEditDrawer={(event) => openEditDrawer(event, data.id)}
            />
        }
        return (newListItem);
      }))

      const total = Math.ceil(collaboratorListData.total_count / (collaboratorListData.num_items_per_page));

      setMaxPageNumber(total);
      setItemList(newList);
    }
  }, [collaboratorListData, collaboratorRolesData]);

  const openEditDrawer = (event, id) => {
    if (event) {
      event.stopPropagation();
    }
    dispatch(editCollaboratorReset());
    history.push(`${path}/edit/${id}`);
  };

  const handleRowSelect = (data) => {
    openEditDrawer(null, data.id);
  }

  const generateRolesText = (roles) => {
    if (collaboratorRolesData) {
      let text = '';
      _.map(roles, (role, index) => {
        text = `${text}${index !== 0 ? ' ' : ''}${collaboratorRolesData[role]}`;
      });
      return text;
    }
  }

  const TableAction = ({ openEditDrawer }) => {
    return (
      <TableButtonDiv >
        <Tooltip title={'Éditer'} >
          <TableIcon onClick={openEditDrawer}>
            <EditIcon />
          </TableIcon>
        </Tooltip>
      </TableButtonDiv>
    )
  }

  return (
    <Container>
      <GenericAdminFrontHeader
        headerTitle={'Gestion des utilisateurs'}
        headerDesc={`Cet espace vous permet de gérer facilement les informations et droits de vos collaborateurs.`}
      />
      <SettingContainer>
        <StyledPaginationArrow
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          maxPageNumber={maxPageNumber}
        />
      </SettingContainer>
      {collaboratorListIsLoading || collaboratorRolesIsLoading ?
        <Loader />
        :
        <TableWrapper>
          <GenericTable
            headerList={headerList}
            itemList={itemList}
            hover
            rowItemProps={rowItemProps}
            onTableRowClick={handleRowSelect}
          />
        </TableWrapper>
      }
      <Routes />
    </Container>
  );
};

export default UserManagement;