import styled from "styled-components";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { COLORS } from '../../constants/design';
import breakpoints from "../../../../theme-style/breakpoints";


export const ModalHeaderContainer = styled.div`
  padding: 22px 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 16px;
  font-weight: 500;
  font-family: "Quicksand";
`;

export const FormContainer = styled.div`
  padding: 20px 30px 50px 30px;
`;

export const InputLabel = styled.div`
  font-size: ${props => props.fontSize ? props.fontSize : '0.9rem'};
  color: ${COLORS.DARK_GREY};
`;

export const StyledTextField = styled(TextField)`
  && {
    margin: 10px 0px;
    width: 100%;
    .MuiInputBase-input {
      font-size: 0.8rem;
      color: ${COLORS.DARK_GREY};
    }
  }
`;

export const StyledTextArea = styled.textarea`
  margin: 10px 0px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid;
  border-color: ${props => props.error ? COLORS.RED_3 : 'rgba(0, 0, 0, 0.3)'};
  box-sizing: border-box;
  font-size: 0.8rem;
  color: ${COLORS.DARK_GREY};
  padding: 10px;
  height: 100px;

  @media ${breakpoints.mobileXL} {
    height: 200px;
  }
`;

export const TextAreaErrorLabel = styled.div`
  margin: 0px 14px 14px 14px;
  font-size: 0.8rem;
  color: ${COLORS.RED_3}
`;

export const StyledButton = styled(({ ...rest }) => (
  <Button {...rest} />
))`
  && {
    &.MuiButton-root {
      color: ${COLORS.WHITE};
      position: relative;
      font-family: Quicksand;
      margin: 20px 0px;
      font-size: 0.75rem;
      font-weight: 500;
      background-color: ${COLORS.RED};
      width: 100%;
      padding: 15px 10px;
      transition: all ease-in .15s;
      border: transparent;
      border-radius: 5px;
      background-image: none;
      text-transform: none;
      &:hover {
        background-color: ${COLORS.RED_2};
      }
    }
  }
`;