import React from "react";

const CheckPlainIcon = ({
  color = "#2B2B2B",
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon checkPlainIcon"
      width={width}
      height={height}
      viewBox="0 0 50 50"
      fill="none"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="checkPlainIcon"
          fill={color}
          fillRule="nonzero"
        >
          <path d="M24.9740831,0 C11.203912,0 0,11.203912 0,24.9740831 C0,38.7442543 11.203912,49.9481663 24.9740831,49.9481663 C38.7442543,49.9481663 49.9481663,38.7442543 49.9481663,24.9740831 C49.9481663,11.203912 38.7442543,0 24.9740831,0 Z M38.9320293,18.401956 L22.9711491,34.2376528 C22.0322738,35.1765281 20.5300733,35.2391198 19.5286064,34.3002445 L11.0787286,26.601467 C10.0772616,25.6625917 10.0146699,24.0977995 10.8909535,23.0963325 C11.8298289,22.0948655 13.394621,22.0322738 14.396088,22.9711491 L21.0933985,29.1051345 L35.3643032,14.8342298 C36.3657702,13.8327628 37.9305623,13.8327628 38.9320293,14.8342298 C39.9334963,15.8356968 39.9334963,17.400489 38.9320293,18.401956 Z"></path>
        </g>
      </g>
    </svg>
  );
};

export default CheckPlainIcon;
