import React from 'react';
import 'moment/locale/fr';

// import '../Legacy/styles/index.js';
import './styles/index.js';
// Import third party components
import Modal from "react-responsive-modal";
import StepWizard from 'react-step-wizard';

// Import third party components

// Import icons
// import { IconCycNew } from '../../assets/CyconiaChatbot/NewCycoIcon';
import { IconCycNew } from './assets/icons/NewCycoIcon';

// Import components
import ServiceRequestForm from './components/ServiceRequestForm';
import StepRecap from './components/StepRecap';
import StepConfirmation from './components/StepConfirmation';
import StepCancel from './components/StepCancel';
// import ServiceRequestForm from '../Forms/ServiceRequest/ServiceRequestForm';
// import StepRecap from "../Step/FormServiceRequest/StepRecap";
// import StepConfirmation from "../Step/FormServiceRequest/StepConfirmation";
// import StepCancel from "../Step/FormServiceRequest/StepCancel";

import GenericDialogContainer from '../../molecules/GenericDialogContainer/index.js';

import Loader from './atoms/Loader';
// import Loader from "../Legacy/atoms/Loader/Loader.js";

import {
  CustomDialog
} from './style';

class NegativeFeedbackModal extends React.Component {
  constructor(props) {
    super(props);


    // Reset wizard step
    window.location.hash = "";
    // TODO RENDER DYNAMIC TOKEN/USER RETREIVAL FOM LOCALSTORAGE

    /******    
        it is necessary to pass the class into a functional component
        and use the selector in remplacement of the two next lines
    **/
    let user = this.props.userInfo;
    let token = this.props.userToken;

    this.state = {
      formData: {},
      statusFormRequest: "",
      user: {
        id: user?.id,
        token
      },
    };

    this.closeModalServiceRequest = this.closeModalServiceRequest.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.setStatusRequest = this.setStatusRequest.bind(this);
    this.resetFormData = this.resetFormData.bind(this);
    this.goToCancelStep = this.goToCancelStep.bind(this);
  }

  goToCancelStep() {
    if (this.props.requestSent) {
      this.closeModalServiceRequest();
    } else {
      window.location.hash = "Cancel";
    }
  }

  closeModalServiceRequest(func = null) {
    this.props._closeModalServiceRequest()
    this.setState({
      formData: {}
    });
    window.location.hash = "";
  }

  handleForm(formData) {
    this.setState({
      formData: formData
    }, () => {
      window.location.hash = "Recap";
    }
    );
  }

  resetFormData() {
    this.setState({
      formData: {}
    })
  }

  setStatusRequest(status) {
    this.setState({
      statusRequest: status
    })
    if (this.props.setFeedbackStatus) {
      if (this.props.apiAction === 'bot_negative_feedback')
        this.props.setFeedbackStatus((prev) => ({ ...prev, negativeStatus: status }));
    }
  }

  render() {
    const { defaultValuesForm, apiAction, modalServiceRequestSchema } = this.props;

    return (
      <React.Fragment>
        {
          <CustomDialog
            open={this.props.isModalServiceRequestOpen}
            classNames={{ modal: "modal-form-container", closeButton: "modal-container-close_button", overlay: "modal-overlay modal-overlay-scroll" }}
            className={"modal-form-container"}
            onClose={this.goToCancelStep}
          >
            <GenericDialogContainer onClose={this.goToCancelStep}>
              <div className="bot">
                <div className="inside-bot">
                  <IconCycNew />
                </div>
              </div>

              {
                Object.keys(modalServiceRequestSchema).length !== 0 && !this.props.isLoading
                  ?
                  <StepWizard
                    onStepChange={this.onStepChange}
                    isHashEnabled
                    className="chatbot-wizard"
                  >
                    <ServiceRequestForm
                      formSchema={modalServiceRequestSchema}
                      defaultValues={defaultValuesForm}
                      handleForm={this.handleForm}
                      hashKey={'Form'}
                      requestOnBehalf={this.props.requestOnBehalf}
                    />
                    <StepRecap
                      hashKey={'Recap'}
                      requestData={this.state.formData}
                      apiAction={apiAction}
                      formSchema={modalServiceRequestSchema}
                      setStatusRequest={this.setStatusRequest}
                      resetFormData={this.resetFormData}
                      setRequestSent={this.props._setRequestSent}
                      author={this.props.author}
                    />
                    <StepConfirmation
                      hashKey={'Confirmation'}
                      closeModal={this.closeModalServiceRequest}
                      statusRequest={this.state.statusRequest}
                      requestData={this.state.formData}
                      resetFormData={this.resetFormData}
                      apiAction={apiAction}
                    />
                    <StepCancel
                      hashKey={'Cancel'}
                      closeModal={this.closeModalServiceRequest}
                    />
                  </StepWizard>
                  :
                  <div className="modal-loader">
                    <Loader />
                  </div>
              }
            </GenericDialogContainer>
          </CustomDialog>
        }
      </React.Fragment>
    )
  }
}


export default NegativeFeedbackModal;