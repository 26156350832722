import React, { useState } from 'react';
import { Field } from 'formik';
import VisibilityIcon from '@material-ui/icons/Visibility';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from '@material-ui/core/styles';

import GenericTextField from '../../../../../../../components/molecules/GenericTextField';
import GenericTextArea from '../../../../../../../components/molecules/GenericTextArea';
import GenericFileChooser from '../../../../../../../components/molecules/GenericFileChooser';
import GenericTitleWithToolTip from '../../../../../../../components/molecules/GenericTitleWithToolTip';
import servicePreview from '../../../../../../../assets/images/servicePreview.png';
import phraseExample from '../../../../../../../assets/images/phraseExample.gif';

import ResponseBuilder from '../../../../../../../components/organisms/ResponseBuilder';


import {
  MainContainer,
  ImagePreviewContainer,
  OpenResponseBuilderModalButton,
  StyledDialog
} from './style';

const useStyles = makeStyles((theme) => ({
  noMaxWidth: {
    maxWidth: 'none !important',
  },
}));

const CustomContentEditor = ({
  formikProps,
  selectedServiceActif,
}) => {
  const classes = useStyles();
  const [openResponseBuilderModal, setOpenResponseBuilderModal] = useState(false);

  return (
    <MainContainer>
      <GenericFileChooser
        title="Choisir une photo"
        toolTipText="Cette image vous permet d'illustrer votre service et accompagne son descriptif"
        toolTipButton="Votre image ne doit pas dépasser 2Mo"
        buttonText="Choisir une photo"
        id="imageFile"
        name="imageFile"
        onChange={(value) => formikProps.setFieldValue('imageFile', value)}
        selectedFileName={formikProps.values.imageFile?.name}
        accept="image/*"
        shouldShowImagePreview={true}
        currentFile={formikProps.values.imageFile || selectedServiceActif?.content_custom_image}
      />
      <GenericFileChooser
        title="Choisir une icône"
        toolTipText="Cette image vous permet d'illustrer votre service et accompagne son descriptif"
        buttonText="Choisir une icône"
        id="contentCustomIconFile"
        name="contentCustomIconFile"
        onChange={(value) => formikProps.setFieldValue('contentCustomIconFile', value)}
        selectedFileName={formikProps.values.contentCustomIconFile?.name}
        accept="image/*"
        shouldShowImagePreview={true}
        currentFile={formikProps.values.contentCustomIconFile}
      />
      <Field
        id="serviceName"
        name="serviceName"
        title="Nom du service" variant="outlined"
        size="small"
        isRequired={true}
        ToolTipIcon={VisibilityIcon}
        toolTipProps={{ classes: { tooltip: classes.noMaxWidth } }}
        toolTipText={<ImagePreviewContainer image={servicePreview} />}
        as={GenericTextField}
      />
      <Field
        id="illustrationPhrase"
        name="illustrationPhrase"
        title="Phrase déclencheuse"
        variant="outlined"
        size="small"
        isRequired={true}
        ToolTipIcon={VisibilityIcon}
        toolTipProps={{ classes: { tooltip: classes.noMaxWidth } }}
        toolTipText={<ImagePreviewContainer image={phraseExample} />}
        as={GenericTextField}
      />
      <Field
        id="contentShortDescription"
        name="contentShortDescription"
        title="Descriptif court"
        isRequired={true}
        toolTipText="C'est le premier niveau d'information au sujet du service que le bénéficiaire reçoit"
        minRows={4}
        as={GenericTextArea}
      />
      <GenericTitleWithToolTip
        title="Descriptif chatbot"
        toolTipText="C'est le second niveau d'information au sujet du service que le bénéficiaire reçoit lorsqu'il souhaite en savoir plus sur le service"
      />
      <OpenResponseBuilderModalButton onClick={() => setOpenResponseBuilderModal(true)}>
        <div>Modifier la description</div>
        <OpenInNewIcon style={{ fontSize: 18 }} />
      </OpenResponseBuilderModalButton>
      {openResponseBuilderModal &&
        <StyledDialog open={true} onClose={() => setOpenResponseBuilderModal(false)}>
          <DialogContent>
            <ResponseBuilder
              messageElements={formikProps.values.messageElements}
              siteId={null}
              parentIndex={0}
              saveResponse={(index, newMessageElements) => formikProps.setFieldValue('messageElements', newMessageElements)}
            />
          </DialogContent>
        </StyledDialog>
      }
    </MainContainer>
  );
};

export default CustomContentEditor;