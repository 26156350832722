import axios from 'axios';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';
import _ from 'lodash';

export const saveBookingService = (endpoint, user, userToken, formData) => {
  const newForm = new FormData();
  const startDateTime = format(new Date(`${formData.startDate.replace(/-/g, "/")} ${formData.startTime}`), "yyyy-MM-dd HH:mm:ss", { locale: fr });
  const endDateTime = format(new Date(`${formData.endDate.replace(/-/g, "/")} ${formData.endTime}`), "yyyy-MM-dd HH:mm:ss", { locale: fr });
  const options = _.filter(formData.options, (option) => option.checked);
  for (const option of options) {
    delete option.checked;
  }

  newForm.append('start', startDateTime);
  newForm.append('end', endDateTime);
  newForm.append('quantity', formData.quantity);
  newForm.append('publicTitle', formData.public_title);
  newForm.append('privateTitle', formData.private_title);
  newForm.append('customerReference', formData.customer_reference);
  newForm.append('description', formData.description);
  newForm.append('contactNumber', formData.contact_number);
  newForm.append('note', formData.note);
  newForm.append('options', JSON.stringify(options));
  newForm.append('price', JSON.stringify(formData.price));
  newForm.append('cancelFees', JSON.stringify(formData.cancelFees));
  newForm.append('room', formData.selectedRoomId);
  newForm.append('companySiteId', formData.companySiteId);
  newForm.append('corporationId', user.corporation?.id || "");

  // console.log('newForm', ...newForm);
  // console.log('formData', formData);
  const headers = {};
  if (userToken) {
    headers.Authorization = `Bearer ${userToken}`;
  }

  return axios.request({
    method: 'post',
    url: endpoint,
    data: newForm,
    headers: headers
  })
}

export const cancelBookingService = (endpoint, userToken, formData) => {
  const newForm = new FormData();
  newForm.append('cancelNote', formData.note);
  newForm.append('cancelStatus', formData.status);

  const headers = {};
  if (userToken) {
    headers.Authorization = `Bearer ${userToken}`;
  }

  return axios.request({
    method: 'post',
    url: endpoint,
    data: newForm,
    headers: headers
  })
}