import React, { useState } from 'react';
import _ from 'lodash';
import Parser from 'html-react-parser';
import { format, differenceInMinutes } from 'date-fns';
import DeleteIcon from '@material-ui/icons/Delete';
// import DialogContent from "@material-ui/core/DialogContent";

import PDFIcon from '../../../../../components/atoms/icons/pdf';
import ImageIcon from '../../../../../components/atoms/icons/image';
import conciergeImage from '../../../../../assets/images/concierge-default.png';

import GenericWarningModal from '../../../../../components/molecules/GenericWarningModal';

import {
  MESSENGER_TYPE,
  MESSAGE_TYPE,
  MESSAGE_DELETE_TIME_LIMIT,
} from '../../constants';

import {
  MainContainer,
  CircleDiv,
  StyledPersonIcon,
  StyledNoteIcon,
  StyledSystemIcon,
  StyledNoteIndicator,
  MainMessageContainer,
  StyledMessageContainer,
  StyledMessageAttachmentContainer,
  StyledThumbnail,
  StyledImg,
  StyledMessageExtraInfo,
  ConciergeImageContainer,
  StyledMessageButtonContainer,
  StyledMessageButtonIcon,
  StyledMessageImage,
} from './style';

const TicketMessage = ({ currentUser, message, messengertype, onMessageDelete, setMessageImageModal }) => {
  const [showMessageButton, setShowMessageButton] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);

  const handleMouseOver = () => {
    if (!showMessageButton && messengertype === MESSENGER_TYPE.CONCIERGE) {
      const diff = differenceInMinutes(new Date(), new Date(message.created_at));

      if (message.author?.id !== currentUser.id)
        return;
      if (message.type !== MESSAGE_TYPE.CONCIERGE && message.type !== MESSAGE_TYPE.NOTE)
        return;
      if (message.type === MESSAGE_TYPE.CONCIERGE && diff >= MESSAGE_DELETE_TIME_LIMIT)
        return;
      setShowMessageButton(true);
    }
  }

  const handleOnDelete = () => {
    onMessageDelete(message);
    setShowWarningModal(false);
  }

  const createMessageExtraInfos = (message) => {
    const messageDate = message?.created_at ?
      `le ${format(new Date(message.created_at), "dd/MM/yyyy",)} à ${format(new Date(message.created_at), "HH:mm",)}` : "";
    const author = `${(message.type === MESSAGE_TYPE.CONCIERGE || message.type === MESSAGE_TYPE.NOTE) ? 'par' : ''} ${message.author?.firstname || ''}  ${message.author?.lastname || ''}`
    return `${messageDate} ${message.type !== MESSAGE_TYPE.SYSTEM_CLIENT ? author : ''}`
  }

  const CircleOptions = ({ type }) => {
    return (
      <CircleDiv type={type}>
        {type === MESSAGE_TYPE.NOTE && (
          <>
            <StyledNoteIcon />
            <StyledNoteIndicator>Note</StyledNoteIndicator>
          </>
        )}
        {(type === MESSAGE_TYPE.SYSTEM || type === MESSAGE_TYPE.SYSTEM_CLIENT) && (
          <>
            <StyledSystemIcon />
            <StyledNoteIndicator system>Système</StyledNoteIndicator>
          </>
        )}
        {(type === MESSAGE_TYPE.CLIENT) && (
          <StyledPersonIcon type={type} messengertype={messengertype} />
        )}
        {(type === MESSAGE_TYPE.CONCIERGE) && (
          <ConciergeImageContainer image={conciergeImage} />
        )}
      </CircleDiv>
    )
  }

  const CustomParser = (content) => {
    return Parser(content, {
      replace: function (domNode) {
        if (domNode.name === "img") {
          return (
            <StyledMessageImage
              alt={"img"}
              src={domNode.attribs.src}
              onClick={(e) => setMessageImageModal(domNode.attribs.src)}
            />
          )
        }
      }
    });
  }

  const handleThumbnailClick = (file, fileExt) => {
    if (fileExt === 'pdf' || fileExt === 'heic') {
      window.open(file, '_blank');
    }
    else {
      setMessageImageModal(file);
    }
  }

  const renderAttachementThumbnails = () => {
    const attachments = _.compact([
      message.attachment,
      message.attachment2,
      message.attachment3,
      message.attachment4,
      message.attachment5
    ]);
    // console.log('attachments', attachments);
    if (!attachments || attachments.length === 0)
      return;
    else {
      const thumbnailList = _.map(attachments, (file, index) => {
        const fileExt = file?.split('.').pop();
        let ImgComponent = null;
        if (fileExt === 'pdf') ImgComponent = <PDFIcon />
        else if (fileExt === 'heic') ImgComponent = <ImageIcon />
        else ImgComponent = <StyledImg src={file} alt={file} />

        return (
          <StyledThumbnail key={index} onClick={() => handleThumbnailClick(file, fileExt)}>
            {ImgComponent}
          </StyledThumbnail>
        )
      });
      return (
        <StyledMessageAttachmentContainer>
          {thumbnailList}
        </StyledMessageAttachmentContainer>
      )
    }
  }

  return (
    <MainContainer
      type={message.type}
      messengertype={messengertype}
      onMouseOver={handleMouseOver}
      onMouseLeave={() => setShowMessageButton(false)}
    >
      <CircleOptions type={message.type} />
      <MainMessageContainer>
        <StyledMessageContainer type={message.type} messengertype={messengertype}>
          {CustomParser(message.content)}
          {renderAttachementThumbnails()}
        </StyledMessageContainer>
        {/* Hide the delete button until yohan is ready */}
        {/* {showMessageButton &&
          <StyledMessageButtonContainer>
            <StyledMessageButtonIcon onClick={() => setShowWarningModal(true)} >
              <DeleteIcon style={{ fontSize: '1rem' }} />
            </StyledMessageButtonIcon>
          </StyledMessageButtonContainer>
        } */}
        <StyledMessageExtraInfo type={message?.type} messengertype={messengertype}>
          {createMessageExtraInfos(message)}
        </StyledMessageExtraInfo>
      </MainMessageContainer>
      <GenericWarningModal
        open={showWarningModal}
        setOpenDialog={() => setShowWarningModal(false)}
        title={"Attention"}
        text={"Impossible d'annuler cette opération"}
        leftButtonText={"Annuler"}
        rightButtonText={"Oui"}
        leftButtonFunction={() => setShowWarningModal(false)}
        rightButtonFunction={handleOnDelete}
      />
    </MainContainer>
  )
};

export default TicketMessage;