import styled from "styled-components";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@material-ui/core/Button';
import { Box, Select, FormControl } from '@material-ui/core'
import GenericMaterialMenu from "../../../../../../../../../../components/molecules/GenericMaterialMenu";

import { COLORS } from '../../../../../../../../../../constants/design'

export const StyledAccordion = styled(Accordion)`
  && {
    &.MuiPaper-elevation1 {
      box-shadow: unset;
    }
    &.MuiAccordion-root {
      background-color: ${COLORS.GREEN};
      color: ${COLORS.WHITE};
      ::before {
        background-color: unset;
      }
    }
    &.MuiAccordion-rounded{
      border-radius: 6px;
    }
    .MuiAccordionSummary-expandIcon .MuiSvgIcon-root {
      color: ${COLORS.WHITE};
    }
    .MuiAccordionDetails-root {
      flex-direction: column;
    }
    .textAreaInput {
      color: ${COLORS.WHITE} !important;
      background-color: ${COLORS.GREEN_2};
      border-color: ${COLORS.GREEN_2};
    }
  }
  .textTitleWrapper {
    margin-bottom: 3px;
    .textTitle {
      font-weight: 600;
      font-size: 0.8rem;
      color: ${COLORS.WHITE};
      margin: 10px 5px 10px 0px;
    }
  }
  .textTooltip {
    color: ${COLORS.WHITE};
  }
  a:link {
    text-decoration: none;
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  && {
    .MuiAccordionSummary-content {
      display: flex;
      align-items: center;
      >div:not(:last-child) {
        margin-right: 12px;
      };
    }
  }
`;

export const Wrapper = styled.div`
  .textTitle {
    font-weight: 700;
    font-size: 13px;
    color: #5A5A5A;
    margin: 14px 5px 14px 0px;
  }
  .textTooltip {
    color: ${COLORS.GREY_1};
  }
  .textFieldInput {
    input {
      font-size: 13px;
    }
  }
  .menuInput {
    /* background-color: red; */
    background-color: ${COLORS.GREEN_2};
    border-color: ${COLORS.GREEN_2};
    color: white;
  }
  .menuIcon {
    color: white;
  }
  .textFieldTitle {
    margin-bottom: 14px;
  }
  .genericMultipleSelect {
    max-width: 700px;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* padding: 20px; */
  >div:not(:last-child) {
    margin-bottom: 15px;
  };
`;


export const CircleDiv = styled.div`
  background-color: ${COLORS.WHITE};
  border-radius: 50%;
  display: flex;
  height: 25px;
  width: 25px;
  justify-content: center;
  align-items: center;
  color: ${COLORS.LIGHT_GREY_4};
`;

export const StyledAccordionTitle = styled.div`
  font-weight: 700;
  font-size: 0.9rem;
`;

export const StyledSummaryButtonContainer = styled.div`
  width: 100%;
  margin-top: ${props => props.type === 'formik' ? '20px' : '0px'};
  display: flex;
  >button:not(:last-child) {
    margin-right: 10px;
  };
`;

export const StyledButton = styled(Button)`
  && {
    &.MuiButton-root {
      position: relative;
      padding: 4px 15px;
      font-size: 0.8rem;
      background-color: ${COLORS.WHITE};
      color: ${COLORS.DARK_GREY};
      transition: all ease-in .15s;
      border: transparent;
      border-radius: 5px;
      text-transform: none;
      width: 100%;
    }
  }
`;

export const ProviderSelector = styled.div`
  margin-bottom: 10px;
`;

export const StyledBox = styled(Box)`

`;

export const StyledSelect = styled(Select)`
  background-color: ${COLORS.GREEN_2};
  color: ${COLORS.WHITE} !important;

    &.Mui-focused {
      color: cyan !important;
    }
`;

export const StyledFormControl = styled(FormControl)`
`;