import styled from "styled-components";
import { COLORS } from "../../../../../constants/design";
import breakpoints from "../../../../../theme-style/breakpoints";

import GenericTitleWithToolTip from '../../../../../components/molecules/GenericTitleWithToolTip';

export const MainContainer = styled.div`
`;

export const StyledTitleWithToolTip = styled(GenericTitleWithToolTip)`
  color: white;
  font-size: 16px;
  font-weight: 700;
  padding: 0 0 8px 0;
  @media ${breakpoints.mobileXL} {
    padding: 0 0 15px 0;
  }
`;

export const BookingHighlight = styled.span`
  max-width: 180px;
  color: ${COLORS.DARK_GREY};
  background-color: #EDEDED;
  border-radius: 3px;
  padding: 5px 8px;
  font-size: 12px;
  font-weight: 600;
`;

export const BookingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  box-sizing: border-box;
  margin: 0;
  background-color: #FFFFFF;
  border-bottom: 1px solid #F4F4F4;
  transition: all .2s ease-out;
  height: 60px;
  border-radius: 6px;
  cursor: pointer;
  &:hover{
    background-color: #FAFAFA;
    ${BookingHighlight}{
      background-color: #4A90E2;
      color: #ffffff;
    }
  }
`;

export const BookingTitleInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${COLORS.DARK_GREY};
  text-align: left;
  font-weight: 600;
  width: 68%;
  font-size: 14px;
`;

export const BookingIconWrapper = styled.span`
  max-width: 16px;
  margin-right: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

