// import endpointAPI from "../helpers/api";
// import { authHeader } from '../helpers/auth-header';

import endpoint from "../../../helpers/api";
import { authHeader } from "../../../helpers/auth-header";

const declineBotReplyProposal = (userMsg, botResponse, data) => {
    return new Promise((resolve, reject) => {
        // let url = endpointAPI.botServer;
        let url = endpoint.botServer;
        let user = JSON.parse(localStorage.getItem("user"));

        let formData = new FormData();

        formData.append("driver", "web");
        formData.append("userId", user.id);
        formData.append("message", userMsg);
        formData.append("interactive", 0);
        formData.append("type", "text");
        // TODO TRACK MESSAGE M.CYCONIA. ---
        formData.append("message", "m.cyconia.custom.smalltalk.text");
        formData.append("userMessage", userMsg);
        formData.append("botResponse", botResponse);
        formData.append("params", JSON.stringify(data));

        let reqOptions = {
            headers: authHeader(),
            method: "POST",
            body: formData
        }

        return fetch(url, reqOptions)
            .then(response => {
                if (!response.ok) {
                    return Promise.reject(response);
                }
                return response.json();
            })
            .then(data => {
                resolve(data)
            })
    })
};


export default declineBotReplyProposal;