import styled from "styled-components";

import GenericButton from "../../../../../../components/molecules/GenericButton";
import { COLORS } from "../../../../../../constants/design";
import { size } from "../../../../../../theme-style/breakpoints";

export const MainContainer = styled.div`
  border: 1px solid black;
  min-height: 100vh;
  padding: 10px 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background: linear-gradient(to top right,#7094e5,#463e96);
  color: ${COLORS.DARK_GREY};
  font-size: 0.9rem;
`;

export const Header = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
`;

export const HeaderTitle = styled.h1`
  margin-top: 20px;
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  @media (max-width: ${size.mobileXL}) {
    font-size: 20px;
    margin-top: 10px;
  }
`;

export const HeaderSubtitle = styled.h2`
  font-size: 24px;
  color: #fff;
  text-align: center;
  font-weight: 500;
  line-height: 1.4;
  margin-bottom: 20px;
  @media (max-width: ${size.mobileXL}) {
    font-size: 14px;
    margin-bottom: 10px;
  }
`;


export const LogoWithCircleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  @media (max-width: ${size.mobileXL}) {
    width: 50px;
    height: 50px;
  }
`;

export const StyledConfirmationContainer = styled.div`
  border-radius: 5px;
  background-color: ${COLORS.WHITE};
  padding: 20px;
  min-width: 300px;
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  /* height: 500px; */
  >:not(:last-child) {
    margin-bottom: 20px;
  };
  .genericCheckBox {
    .textTitle {
      font-size: 0.8rem;
    }
  }
  @media (max-width: ${size.mobileXL}) {
    padding: 15px 10px;
    >:not(:last-child) {
      margin-bottom: 10px;
    };
    .genericCheckBox {
      .textTitle {
        font-size: 0.7rem;
      }
    }
  }
`;

export const StyledTitle = styled.div`
  font-weight: 700;
  /* align-self: center; */
  font-size: 1.3rem;
  text-align: center;
  @media (max-width: ${size.mobileXL}) {
    font-size: 1rem;
  }
`;

export const StyledMessage = styled.div`
  text-align: center;
  line-height: 1.5;
  font-size: 0.9rem;
  @media (max-width: ${size.mobileXL}) {
    font-size: 0.8rem;
  }
`;

export const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${COLORS.LIGHT_GREY_4};
  border-radius: 5px;
  padding: 15px;
  height: 30vh;
  >:not(:last-child) {
    margin-bottom: 15px;
  };
  @media (max-width: ${size.mobileXL}) {
    >:not(:last-child) {
      margin-bottom: 10px;
    };
  }
`;

export const StyledInfo = styled.div`
  display: flex;
  font-size: 0.9rem;
  @media (max-width: ${size.mobileXL}) {
    font-size: 0.8rem;
  }
`;

export const StyledInfoTitle = styled.div`
  font-weight: 600;
  width: 30%;
  min-width: 50px;
  margin-right: 20px;
`;

export const StyledInfoText = styled.div`
`;

export const StyledLink = styled.div`
  border: 1px solid ${COLORS.LIGHT_GREY_4};
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  font-size: 0.8rem;
  cursor: pointer;
  color: ${COLORS.PRIMARY_BLUE};
`;

export const StyledButton = styled(GenericButton)`
  && {
    &.MuiButton-root {
      width: 100%;
      font-size: 0.8rem;
    }
  }
`;

export const StyledErrorContainer = styled.div`
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const StyledErrorText = styled.div`
  color: ${COLORS.RED_4};
  text-align: center;
`;