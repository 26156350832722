export const actionGenerator = (value) => ({
  REQUEST: `CONCIERGE/${value}/REQUEST`,
  SUCCESS: `CONCIERGE/${value}/SUCCESS`,
  FAILURE: `CONCIERGE/${value}/FAILURE`,
})

export const GET_BOT_RESPONSE = actionGenerator('GET_BOT_RESPONSE');
export const GET_BOT_FORM = actionGenerator('GET_BOT_FORM');
export const GET_NESTED_BUTTON_RESPONSE = actionGenerator('GET_NESTED_BUTTON_RESPONSE');
export const SET_MESSAGE_FROM_SIDEBAR = actionGenerator('SET_MESSAGE_FROM_SIDEBAR');
export const SAVE_BOT_FINISH_INTRO = actionGenerator('SAVE_BOT_FINISH_INTRO');
export const SAVE_POSITIVE_FEEDBACK = actionGenerator('SAVE_POSITIVE_FEEDBACK');
export const SAVE_NEGATIVE_FEEDBACK = actionGenerator('SAVE_NEGATIVE_FEEDBACK');
export const SAVE_FEEDBACK = actionGenerator('SAVE_FEEDBACK');
export const CLEAN_CHATBOT_MESSAGE = actionGenerator('CLEAN_CHATBOT_MESSAGE');

export const getBotResponse = (message, messageToBot, serviceType) => ({
  type: GET_BOT_RESPONSE.REQUEST,
  message,
  messageToBot,
  serviceType
});

export const getBotResponseSuccess = (messages) => ({
  type: GET_BOT_RESPONSE.SUCCESS,
  messages
});

export const getBotResponseError = (messages, error) => ({
  type: GET_BOT_RESPONSE.FAILURE,
  messages,
  error
});

export const getBotForm = (formRequestData, requestedFormType) => ({
  type: GET_BOT_FORM.REQUEST,
  formRequestData,
  requestedFormType
});

export const getBotFormSuccess = (formSchema) => ({
  type: GET_BOT_FORM.SUCCESS,
  formSchema: formSchema,
});

export const getBotFormError = (error) => ({
  type: GET_BOT_FORM.FAILURE,
  error,
});

export const getNestedButtonResponse = (message, nestedResponse) => ({
  type: GET_NESTED_BUTTON_RESPONSE.REQUEST,
  message,
  nestedResponse,
});

export const getNestedButtonResponseSuccess = (messages) => ({
  type: GET_NESTED_BUTTON_RESPONSE.SUCCESS,
  messages
});

export const getNestedButtonResponseError = (messages, error) => ({ // Not In use, nestedButton will never have error since it is not calling server
  type: GET_NESTED_BUTTON_RESPONSE.FAILURE,
  messages,
  error
});

export const setMessageFromSideBar = (message) => ({
  type: SET_MESSAGE_FROM_SIDEBAR.REQUEST,
  message
});

export const setMessageFromSideBarSuccess = (message) => ({
  type: SET_MESSAGE_FROM_SIDEBAR.SUCCESS,
  message
});

export const setMessageFromSideBarError = (message, error) => ({ // Not In use, messageFromSideBar will never have error since it is not calling server
  type: SET_MESSAGE_FROM_SIDEBAR.FAILURE,
  message,
  error
});

export const saveBotFinishIntro = () => ({
  type: SAVE_BOT_FINISH_INTRO.REQUEST
});

export const savePositiveFeedback = (feedbackData) => ({
  type: SAVE_POSITIVE_FEEDBACK.REQUEST,
  feedbackData
});

export const saveNegativeFeedback = (feedbackData) => ({
  type: SAVE_NEGATIVE_FEEDBACK.REQUEST,
  feedbackData
});

export const saveNegativeFeedbackSuccess = (feedbackStatus) => ({
  type: SAVE_NEGATIVE_FEEDBACK.SUCCESS,
  feedbackStatus
});

export const saveNegativeFeedbackError = (error) => ({
  type: SAVE_NEGATIVE_FEEDBACK.FAILURE,
  error
});

export const saveFeedback = (feedbackData) => ({
  type: SAVE_FEEDBACK.REQUEST,
  feedbackData
});

export const saveFeedbackSuccess = (feedbackStatus) => ({
  type: SAVE_FEEDBACK.SUCCESS,
  feedbackStatus
});

export const saveFeedbackError = (error) => ({
  type: SAVE_FEEDBACK.FAILURE,
  error
});

export const cleanChatbotMessage = () => ({
  type: CLEAN_CHATBOT_MESSAGE.REQUEST,
});