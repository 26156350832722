import React from 'react';

// Components
import OldFeedbackTreatedTypeStats from '../components/oldFeedbackTreatedTypeStats';
import OldFeedbackTreatedCompetenceStats from '../components/oldFeedbackTreatedCompetenceStats';
import TableOldFeedbackTreated from '../components/tableOldFeedbackTreated';

// Styles
import {
  Container,
  HeadWrapper,
  Table
} from "./style";

const OldFeedBack = () => {
    return (
      <Container>
        <HeadWrapper>
          <OldFeedbackTreatedTypeStats />
          <OldFeedbackTreatedCompetenceStats />
        </HeadWrapper>

        <Table>
          <TableOldFeedbackTreated />
        </Table>
      </Container>
    );
}

export default OldFeedBack;
