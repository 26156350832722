export const GET_CONFIG = {
  REQUEST: 'CONFIG/GET/REQUEST',
  SUCCESS: 'CONFIG/GET/SUCCESS',
  FAILURE: 'CONFIG/GET/FAILURE'
};

/**
 * CONFIG - INIT SUBDOMAIN
 */
export const getConfig = (subdomain = null, companysite = null) => ({
  type: GET_CONFIG.REQUEST,
  subdomain,
  companysite,
});

export const getConfigSuccess = (config) => {
  // console.log('GET CONFIG SUCCESS', config)
  return {
    type: GET_CONFIG.SUCCESS,
    config,
  };
};

export const getConfigError = (error) => ({
  type: GET_CONFIG.FAILURE,
  error,
});
