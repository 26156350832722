import styled from "styled-components";
import Dialog from '@material-ui/core/Dialog';

import { size } from "../../../theme-style/breakpoints";

export const CustomDialog = styled(Dialog)`
  && {
    .MuiDialog-container {
      .MuiDialog-paper {
        &.MuiPaper-rounded {
          width: 100%;
          height: 100%;
        }
        &.MuiDialog-paperWidthSm {
        }
      }
    }
    &.modal-form-container {
      max-width: 100%;
      width: 100%;
      height: 100%;
      max-height: 100vh;
      padding: 0px;
    }
    .chatbot-wizard {
      padding: 25px;
    }
    @media (max-width: ${size.mobileXL}) {
      .MuiDialog-paper {
        margin: 1rem;
      }
      .chatbot-wizard {
        padding: 0.5rem;
      }
    }
  }
`;