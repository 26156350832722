import { getURLApi } from "./helpers";
require('dotenv').config();

const URL_API = getURLApi();
const BASE_URL = URL_API.slice(0, URL_API.length - 4);
const VERSION_API = process.env.REACT_APP_API_VERSION;
const REACT_APP_URL = process.env.REACT_APP_URL;

const endpoint = {
    base_url: BASE_URL,
    url_api: URL_API,
    react_app_url: REACT_APP_URL,



    // Header API
    getEntrepriseSiteListData: URL_API + VERSION_API + "manager/training/ei/{eiId}/companySites",

    // Dashboard API
    getLastUntreatedFeedback: URL_API + VERSION_API + "manager/dashboard/last/feedbacks",
    getUntreatedFeedbackStat: URL_API + VERSION_API + "manager/dashboard/feedback/unprocessed/stat",
    getActiveInfoElement: URL_API + VERSION_API + "manager/dashboard/ei/active/stat",
    getInactiveInfoElement: URL_API + VERSION_API + "manager/dashboard/ei/inactive",

    // Feedback API
    getUntreatedFeedback: URL_API + VERSION_API + "manager/feedbacks/unprocessed",
    getUntreatedFbBySkill: URL_API + VERSION_API + "manager/feedbacks/unprocessed/by/skill",
    getUntreatedFbBySkillStat: URL_API + VERSION_API + "manager/feedbacks/unprocessed/by/skill/stat",
    getFeebackByInfoEl: (eiId) => {
        return `${URL_API}${VERSION_API}manager/${eiId}/feedbacks`
    },
    getTreatedFeedback: URL_API + VERSION_API + "manager/feedbacks/processed",
    getTreatedFeedbackStat: URL_API + VERSION_API + "manager/feedbacks/processed/stat",
    getTreatedFbBySkillStat: URL_API + VERSION_API + "manager/feedbacks/processed/by/skill/stat",

    // User Manager API
    getCollaborators: `${URL_API}${VERSION_API}manager/collaborators?page=1&limit=50`,
    getCollaboratorsRules: URL_API + VERSION_API + "manager/collaborator/roles",
    postCollaboratorsBlock: (id) => {
        return `${URL_API}${VERSION_API}manager/collaborators/${id}/block`
    },
    postCollaboratorsUnblock: (id) => {
        return `${URL_API}${VERSION_API}manager/collaborators/${id}/unblock`
    },
    postCollaboratorsResetPw: (id) => {
        return `${URL_API}${VERSION_API}manager/collaborators/${id}/shoot/reset/password/mail`
    },
    getCollabByIdApi: (id) => {
        return `${URL_API}${VERSION_API}get/collaborator/${id}`
    },

    // Rules Manager API
    getRulesPermissions: URL_API + VERSION_API + "manager/role/permissions",
    getRulesList: URL_API + VERSION_API + "manager/role/list",
    postRulesAdd: URL_API + VERSION_API + "manager/role/add",
    postRulesAssign: URL_API + VERSION_API + "manager/role/assign",

    // Training Feeds API
    getfeedsStatInfo: (eiId) => {
        return `${URL_API}${VERSION_API}manager/training/ei/${eiId}/feedback/stats`
    },
    getfeedsListBySite: (siteId, eiId) => {
        // return `${URL_API}${VERSION_API}manager/${eiId}/feedbacks?companySiteId=${siteId}`
        return `${URL_API}${VERSION_API}manager/${eiId}/feedbacks`
    },

    getEiResponseList: (eiId) => {
        return `${URL_API}${VERSION_API}manager/training/ei/${eiId}`
    },

    // Traingin API
    isActiveEiState: URL_API + VERSION_API + "manager/training/ei/activation",
    getEiById: URL_API + VERSION_API + "manager/training/ei/list",
    getSkills: URL_API + VERSION_API + "manager/training/skills",
    updateEiinformation: URL_API + VERSION_API + "manager/training/ei/response",
    updateProcessedFeed: URL_API + VERSION_API + "manager/training/feedback/processed",

    // Dashboard
    positiveBotFeedback: URL_API + VERSION_API + "manager/feedbacks/bot/positive",
    negativeBotFeedback: URL_API + VERSION_API + "manager/feedbacks/bot/negative",

    getInfoList: (id) => {
        return `${URL_API}${VERSION_API}manager/training/${id}/subjects`
    },

    // Training Syso page
    getSkillsDomain: URL_API + VERSION_API + "manager/training/expertiseareas",

}
export {
    BASE_URL,
    URL_API
}
export default endpoint;
