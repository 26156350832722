const colors = {
  black: "#231F20",
  white: "#FFFFFF",
  ghost: "transparent",

  main: "#2768B3",
  main95: "rgba(253, 212, 62, 0.95)",
  main80: "rgba(253, 212, 62, 0.8)",
  main50: "rgba(253, 212, 62, 0.5)",
  main20: "rgba(253, 212, 62, 0.2)",
  main10: "rgba(253, 212, 62, 0.1)",
  mainDark: "#bb9d2d",
  grey: "#f7f8f9",
  greyMedium: "#eaeaea",
  greyDark: "#c3c3c3",
  greyDarkMedium: "#878f99",
  lightBackground: "#FFFAFA",
  greyBackground: "#F8F8F8",
  facebook: "#4469B0",
  facebookDark: "#395A98",
  kitchenMain: "#181818",
  kitchenSecondary: "#212121",

  black10: "rgba(0,0,0, 0.1)",
  black20: "rgba(0,0,0, 0.2)",
  black50: "rgba(0,0,0, 0.5)",
  black80: "rgba(0,0,0, 0.8)",
  white50: "rgba(255,255,255, 0.5)",
  white80: "rgba(255,255,255, 0.8)",
  ultralightgrey: "rgba(216,216,216, 1)",
  ultralightgrey2: "rgba(244,244,244, 1)",
  lightgrey: "rgba(131,131,131, 1)",
  darkgrey: "rgba(61,61,58, 1)",
  darkgreyHover: "#5c5c59",
};

export default colors;
