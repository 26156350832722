import React, { useState, useEffect } from 'react';
import PersonIcon from '@material-ui/icons/Person';
import EmailIcon from '@material-ui/icons/Email';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import _ from 'lodash';

import GenericDialogContainer from '../../../../../../../components/molecules/GenericDialogContainer';
import GenericTextField from '../../../../../../../components/molecules/GenericTextField';
import GenericMaterialMenu from '../../../../../../../components/molecules/GenericMaterialMenu';
import GenericTitleWithIcon from '../../../../../../../components/molecules/GenericTitleWithIcon';
import GenericAnimatedTextBubble from '../../../../../../../components/molecules/GenericAnimatedTextBubble';
import Loader from '../../../../../../../components/molecules/GenericLoader';

import { toaster } from '../../../../../../../helpers/toaster';

import {
  CustomDialog,
  StyledTitle,
  ContentContainer,
  InputGroup,
  ButtonGroup,
  StyledUserInfoContainer,
  StyledUserList,
  StyledUser,
  StyledUserEmail,
  StyledUserInfo,
  SeletecUserContainer,
  StyledButton,
  EmptyDataContainer,
  StyledSuggestionContainer
} from './style';

const SearchUserModal = ({
  collaboratorsList,
  collaboratorsListIsLoading,
  collaboratorsListSuccess,
  sitesData,
  onClose,
  onUserSearch,
  onUserSelect,
  handleOpenCreateUser
}) => {
  const [siteList, setSiteList] = useState([]);
  const [selectedSite, setSelectedSite] = useState(null);
  const [searchText, setSeachText] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [isSuggestionActive, setIsSuggestionActive] = useState(false);

  useEffect(() => {
    if (sitesData) {
      const newList = [{
        value: null,
        label: '-- Tous les sites --'
      }]
      for (const site of sitesData) {
        newList.push({
          value: site.id,
          label: site.name
        });
      }
      setSiteList(newList);
    }
  }, [sitesData]);

  useEffect(() => {
    if (collaboratorsList?.length === 0 && collaboratorsListSuccess) {
      toaster('Aucun collaborateur trouvé', 'error');
      setIsSuggestionActive(true);
    }
    else {
      setIsSuggestionActive(false);
    }
  }, [collaboratorsList, collaboratorsListSuccess]);

  const keyPress = (event) => {
    if (event.keyCode === 13) {
      handleSearch(searchText);
    }
  }

  const handleSearch = (keyword) => {
    if (keyword.length < 3) {
      toaster('Un minimum de 3 caractères est requis', 'error');
      return;
    }
    onUserSearch(keyword, selectedSite);
  }

  const handleConfirm = () => {
    if (selectedUser) {
      if (selectedUser.company_site) {
        onUserSelect(selectedUser);
        onClose();
      }
      else {
        toaster('Action impossible : ce collaborateur n’est rattaché à aucun site', 'error');
      }
    }
  }

  const renderUserList = () => {
    if (collaboratorsList && collaboratorsList.length !== 0) {
      return _.map(collaboratorsList, (user, index) => {
        return (
          <StyledUser
            key={index}
            onClick={() => setSelectedUser(user)}
            selected={user.id === selectedUser?.id}
          >
            {`${user.firstname} ${user.lastname}`}
            <StyledUserEmail>
              {`${user.email}`}
            </StyledUserEmail>
          </StyledUser>
        );
      });
    }
    else {
      return (
        <EmptyDataContainer>
          {`Aucun collaborateur`}
        </EmptyDataContainer>
      )
    }
  }

  const renderUserInfo = () => {
    if (selectedUser) {
      const userName = `${selectedUser.firstname} ${selectedUser.lastname}`;
      return (
        <SeletecUserContainer>
          <GenericTitleWithIcon title={userName || '-'} Icon={<PersonIcon style={{ fontSize: '1rem' }} />}></GenericTitleWithIcon>
          <GenericTitleWithIcon title={selectedUser.email || '-'} Icon={<EmailIcon style={{ fontSize: '1rem' }} />}></GenericTitleWithIcon>
          <GenericTitleWithIcon title={selectedUser.company_site || '-'} Icon={<LocationCityIcon style={{ fontSize: '1rem' }} />}></GenericTitleWithIcon>
        </SeletecUserContainer>
      )
    }
    else {
      return (
        <EmptyDataContainer>
          {`Aucun résultat`}
        </EmptyDataContainer>
      )
    }
  }

  return (
    <CustomDialog
      open={true}
      onClose={onClose}
    >
      <GenericDialogContainer onClose={onClose}>
        <StyledTitle>
          {`Sélectionner un collaborateur`}
        </StyledTitle>
        <ContentContainer>
          <InputGroup>
            <GenericTextField
              variant={"outlined"}
              size={"small"}
              title={"Rechercher par nom/prénom/mail"}
              autoFocus
              value={searchText}
              updateOnBlur={false}
              onKeyDown={keyPress}
              onChange={(event) => setSeachText(event.target.value)}
            />
            <GenericMaterialMenu
              title={"Rechercher dans"}
              list={siteList}
              onChange={(value) => setSelectedSite(value)}
            />
          </InputGroup>
          <ButtonGroup>
            <StyledButton
              title={"Chercher"}
              onClick={() => handleSearch(searchText)}
            />
            <StyledSuggestionContainer>
              <StyledButton
                title={"Ajouter un collaborateur"}
                onClick={handleOpenCreateUser}
              />
              <GenericAnimatedTextBubble
                isIndicatorVisible={isSuggestionActive}
                arrowPosition={{
                  direction: 'top',
                  right: '50px',
                  top: '-6px'
                }}
                boxPosition={{
                  right: '0px',
                }}
                animationData={{ min: '-60px', max: '-72px', direction: 'vertical' }}
              >
                Aucun collaborateur trouvé ! Pour en ajouter un, cliquez ici <span role={'img'} aria-label={'emoji'}>☝️</span>
              </GenericAnimatedTextBubble>
            </StyledSuggestionContainer>

          </ButtonGroup>
          <StyledUserInfoContainer>
            <StyledUserList>
              {collaboratorsListIsLoading ?
                <Loader />
                :
                renderUserList()
              }
            </StyledUserList>
            <StyledUserInfo>
              {renderUserInfo()}
              {selectedUser &&
                <StyledButton
                  title={"Choisissez ce collaborateur"}
                  onClick={handleConfirm}
                />
              }
            </StyledUserInfo>
          </StyledUserInfoContainer>
        </ContentContainer>
      </GenericDialogContainer>
    </CustomDialog>
  );
};

export default SearchUserModal;