import styled from 'styled-components';
import { COLORS } from '../../../constants/design'

export const Wrapper = styled.div`
`;

export const LabelStyle = styled.span` 
  font-size: 1rem;
  margin-right: 10px;
`;

export const RequiredLabel = styled.span`
  margin: 0px 5px;
  color: ${COLORS.RED_4};
`;