import React from "react";

// Layout
import BaseLayout from "../base";

// Import component
import SideNavbar from '../../components/organisms/sideNavbar/';

// Style
import { PageContent } from "./style";

// General editor
import SwipeableTrainingEditor from '../../containers/TrainingCyco/components/EditTraining';

const TrainingCycoBaseLayout = ({ children, ...rest }) => {
  return (
    <BaseLayout >
      <PageContent>
        <SideNavbar />
        {children}
        <SwipeableTrainingEditor />
      </PageContent>
    </BaseLayout>
  );
};

export default TrainingCycoBaseLayout;
