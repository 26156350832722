
import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
// // Import React third party components / pacakges
// import moment from 'moment';
import 'moment/locale/fr';
// import LaddaButton, { SLIDE_UP } from 'react-ladda';
// import { toast } from 'react-toastify';
// import { isMobile } from 'react-device-detect';
// import Select from 'react-select';

import Sidebar from "../Sidebar";
// import Chatbot from "../Chatbot/";
import Chatbot from "../Chatbot/index.jsx";
import LogoIcon from './components/atoms/icons/logo';
import { getConcierges } from '../../store/concierge/actions';

// Store
import {
  getExpressingData,
} from '../../store/sidebar/actions';
import {
  getCompanyData,
  getCompanyModules,
} from '../../store/company/selectors';
import {
  getChatBotMessageFromSideBarData,
} from '../../store/chatbot/selectors';
import {
  getUser,
} from '../../store/collaborator/selectors';

// Icons
import {
  IconControlCenter,
} from "../../helpers/Icon";

// Style
import {
  Wrapper,
  ChatbotContainer,
  SidebarContainer,
  HeaderMobile,
  HeaderMobileGetGround,
  NavLinkCircle,
  LogoIconWrapper,
  NavLinkTitle,
  NavLinkCustom,
} from './style';

const Home = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [openSidebar = {}, setOpenSidebar] = useState(false);
  const {
    userData,
    companyInit,
    companyModules,
    isChatBotMessageSidebarSuccess,
  } = useSelector(
    state => ({
      userData: getUser(state),
      companyInit: getCompanyData(state),
      companyModules: getCompanyModules(state),
      isChatBotMessageSidebarSuccess: getChatBotMessageFromSideBarData(state),
    }),
    shallowEqual
  );
  const activeGetGround = companyInit && companyInit?.getGround;

  useEffect(() => {
    if (userData && !!companyModules?.expressing_module) {
      dispatch(getExpressingData());
    }
  }, [userData, companyModules]);

  useEffect(() => {
    if (!!isChatBotMessageSidebarSuccess) {
      setOpenSidebar(false)
    }
  }, [isChatBotMessageSidebarSuccess]);

  useEffect(() => {
    dispatch(getConcierges());
  }, [])

  return (
    <Wrapper>
      <>
        {activeGetGround ? (
          <HeaderMobileGetGround>
            <NavLinkCustom>
              <NavLinkCircle>
                <LogoIconWrapper>
                  <LogoIcon />
                </LogoIconWrapper>
              </NavLinkCircle>
              <NavLinkTitle>
                Ma conciergerie Cyconia
              </NavLinkTitle>
            </NavLinkCustom>
            <button type="button" onClick={() => setOpenSidebar(!openSidebar)}>
              <IconControlCenter />
            </button>
          </HeaderMobileGetGround>
        ) : (
          <HeaderMobile>
            <h1>Cyconia</h1>
            <button type="button" onClick={() => setOpenSidebar(!openSidebar)}>
              <IconControlCenter />
            </button>
          </HeaderMobile>
        )}
        {location.pathname !== "/" && // Quick fix so that the chatbot will not rerender twice due to route changing
          <ChatbotContainer>
            <Chatbot />
          </ChatbotContainer>
        }
        <SidebarContainer sidebarState={openSidebar} getGroundContext={activeGetGround}>
          <Sidebar />
        </SidebarContainer>
      </>
    </Wrapper>
  );
};

export default Home;
