export const getUserRoomData = state => state.roomBooking.userRooms.data;
export const getUserRoomDataIsLoading = state => state.roomBooking.userRooms.get.loading;

export const getBookingData = state => state.roomBooking.bookings.data;
export const getBookingDataIsLoading = state => state.roomBooking.bookings.get.loading;

export const getReportBookingData = state => state.roomBooking.reportBookings.data;
export const getReportBookingDataIsLoading = state => state.roomBooking.reportBookings.get.loading;

export const getSaveBookingIsLoading = state => state.roomBooking.saveBooking.get.loading;

export const getCancelBookingIsLoading = state => state.roomBooking.cancelBooking.get.loading;
export const getCancelBookingIsSuccess = state => state.roomBooking.cancelBooking.get.success;