import React from "react";

const OldIcon = ({
  color = "#5A5A5A",
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon oldIcon"
      width={width}
      height={height}
      viewBox="0 0 11 11"
      fill="none"
    >
      <path
        d="M11 0v11H0V0h11zm-.917.917H.917v9.166h9.166V.917zM4.26 3.343a.458.458 0 01.648.648l-1.05 1.05H8.25a.458.458 0 110 .917H3.857l1.05 1.051a.458.458 0 01-.648.648L2.426 5.824l-.016-.017-.014-.017-.013-.016-.014-.02-.012-.02-.011-.018-.01-.02-.01-.02-.007-.021-.008-.022-.005-.02-.005-.023-.004-.025-.003-.02a.461.461 0 010-.09c0-.007.002-.014.003-.02L2.3 5.41l.005-.022.005-.021.008-.022.008-.02c.002-.007.006-.014.009-.02l.01-.021.01-.018.013-.02.014-.02.013-.017a.465.465 0 01.03-.033z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export default OldIcon;
