import React, { useState, useEffect } from 'react';
import {
  useDispatch,
  shallowEqual,
  useSelector
} from "react-redux";
import { useHistory } from "react-router";
import { useRouteMatch } from "react-router-dom";
import {
  format,
  addMonths,
  startOfMonth,
  endOfMonth,
} from 'date-fns';
import fr from 'date-fns/locale/fr';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility';
import _ from 'lodash';

import GenericLayout from '../../layouts/genericLayout';
import GenericAdminFrontHeader from '../../../../components/molecules/GenericAdminFrontHeader';
import GenericMaterialMenu from '../../../../components/molecules/GenericMaterialMenu';
import GenericLoader from '../../../../components/molecules/GenericLoader';
import GenericTable from '../../../../components/molecules/GenericTable';

import RoomBookingCancellationModal from '../../../../components/organisms/RoombookingCancellationModal';

import {
  BOOKING_STATUS_DISPLAY,
  CANCEL_STATUS_TEXT
} from '../../../../constants/bookingConstants';

import Routes from './routes';
import ContentBar from './components/ContentBar';
import ContentFilter from './components/ContentFilter';
import InfoModal from './components/InfoModal';
import DownloadCsv from './components/DownloadCsv';
import Chart from './components/Chart';

import {
  getBookingsByRangeDate,
  cancelBooking,
  cancelBookingReset
} from '../../../../store/roomBooking/actions';

import {
  getCorporationsData,
  getCorporationsIsLoading,
  getConciergeSitesData,
  getConciergeSitesIsLoading
} from '../../../../store/concierge/selectors';

import {
  getUserRoomData,
  getUserRoomDataIsLoading,
  getReportBookingData,
  getReportBookingDataIsLoading,
  getCancelBookingIsLoading,
  getCancelBookingIsSuccess,
} from '../../../../store/roomBooking/selectors';

import {
  isUserConcierge as isUserConciergeSelector,
} from '../../../../store/authentication/selectors';

import {
  MainContainer,
  HeaderGroupContainer,
  HeaderButton,
  TableButtonDiv,
  TableIcon,
  TableWrapper,
  TableItemWithStatus
} from './style.js';

const roomHeaderList = [
  { id: "reservation_id", label: "Id" },
  { id: "site", label: "Site" },
  { id: "corporation_name", label: "Entreprise" },
  { id: "room_name", label: "Salle" },
  { id: "public_title", label: "Titre" },
  { id: "date", label: "Date" },
  { id: "time", label: "Créneau" },
  { id: "options", label: "Options" },
  { id: "rowAction", label: "Action", disableSort: true },
];

const optionsHeaderList = [
  { id: "reservation_id", label: "Id réservation" },
  { id: "option_name", label: "Option" },
  { id: "site", label: "Site" },
  { id: "corporation_name", label: "Entreprise" },
  { id: "room_name", label: "Salle" },
  { id: "date", label: "Date" },
  { id: "time", label: "Créneau" },
  { id: "cancel_status", label: "Frais d'annulation" },
  { id: "rowAction", label: "Action", disableSort: true },
];

const rowItemProps = {
  dateOriginal: {
    display: 'hidden',
  },
};

const optionList = [
  {
    value: 'edit',
    label: 'Modifier la réservation',
  },
  {
    value: 'cancel',
    label: 'Annuler la réservation',
  },
];

const TableAction = ({ openShowBooking }) => {
  return (
    <TableButtonDiv >
      <Tooltip title={'Show'} >
        <TableIcon onClick={openShowBooking}>
          <VisibilityIcon />
        </TableIcon>
      </Tooltip>
    </TableButtonDiv>
  )
}

const BookingReport = () => {
  const today = new Date();
  const dispatch = useDispatch();
  const history = useHistory();
  const { path } = useRouteMatch();
  const initStart = format(startOfMonth(today), "yyyy-MM-dd", { locale: fr });
  const initEnd = format(endOfMonth(addMonths(today, 2)), "yyyy-MM-dd", { locale: fr });
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [headerList, setHeaderList] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [filterConditions, setFilterConditions] = useState({
    startDate: initStart,
    endDate: initEnd,
  });

  const {
    bookingData,
    bookingDataIsLoading,
    roomData,
    roomDataIsLoading,
    corporationData,
    corporationDataIsLoading,
    siteData,
    siteDataIsLoading,
    isUserConcierge,
    cancelBookingIsLoading,
    cancelBookingIsSuccess,
  } = useSelector(
    state => ({
      bookingData: getReportBookingData(state),
      bookingDataIsLoading: getReportBookingDataIsLoading(state),
      roomData: getUserRoomData(state),
      roomDataIsLoading: getUserRoomDataIsLoading(state),
      corporationData: getCorporationsData(state),
      corporationDataIsLoading: getCorporationsIsLoading(state),
      siteData: getConciergeSitesData(state),
      siteDataIsLoading: getConciergeSitesIsLoading(state),
      isUserConcierge: isUserConciergeSelector(state),
      cancelBookingIsLoading: getCancelBookingIsLoading(state),
      cancelBookingIsSuccess: getCancelBookingIsSuccess(state),
    }),
    shallowEqual
  );

  useEffect(() => {
    // console.log('filterConditions', filterConditions);
    const queryStartDate = format(new Date(filterConditions.startDate), "yyyy-MM-dd", { locale: fr });
    const queryEndDate = format(new Date(filterConditions.endDate), "yyyy-MM-dd", { locale: fr });
    dispatch(getBookingsByRangeDate({ startDate: queryStartDate, endDate: queryEndDate }));
  }, [filterConditions]);

  useEffect(() => {
    if (cancelBookingIsSuccess) {
      handleCancelDelete(false);
    }
  }, [cancelBookingIsSuccess]);

  // console.log('bookingData', bookingData);

  useEffect(() => {
    if (selectedCategory === 0) {
      setHeaderList(roomHeaderList);
    }
    else {
      setHeaderList(optionsHeaderList);
    }
    let newList = [];

    if (selectedCategory === 0) {
      _.map(bookingData, (booking) => {
        const selectedSite = _.find(siteData, (site) => site.id === booking.company_site_id);
        const selectedCorporation = _.find(corporationData, (corporation) => corporation.id === booking.corporation_id);
        // if (filteringCondition(filterConditions, booking) === false) return;
        newList.push({
          reservation_id: booking.id,
          site: selectedSite?.name || '-',
          corporation_name: selectedCorporation?.name || '-',
          room_name: booking.room.name,
          public_title:
            <Tooltip title={CANCEL_STATUS_TEXT[booking.cancel_status]}>
              <TableItemWithStatus cancelStatus={booking.cancel_status}>
                {booking.public_title}
              </TableItemWithStatus>
            </Tooltip>,
          date: format(new Date(booking.start), 'dd/MM/yyyy', { locale: fr }),
          dateOriginal: new Date(booking.start).getTime(),
          time: `${format(new Date(booking.start), 'HH:mm', { locale: fr })} - ${format(new Date(booking.end), 'HH:mm', { locale: fr })}`,
          options: `${generateOptions(booking)}`,
          rowAction:
            <GenericMaterialMenu
              variant='threeDots'
              list={optionList}
              onChange={(value) => handleAction(booking, value)}
            />
          // <TableAction
          //   openShowBooking={() => setSelectedBooking(booking)}
          // />
        })
      });
    }
    else {
      _.map(bookingData, (booking) => {
        const selectedSite = _.find(siteData, (site) => site.id === booking.company_site_id);
        const selectedCorporation = _.find(corporationData, (corporation) => corporation.id === booking.corporation_id);

        _.map(booking.options, (option) => {
          newList.push({
            reservation_id: booking.id,
            option_name: option.title,
            site: selectedSite?.name || '-',
            corporation_name: selectedCorporation?.name || '-',
            room_name: booking.room.name,
            date: `${format(new Date(booking.start), 'dd/MM/yyyy', { locale: fr })}`,
            dateOriginal: new Date(booking.start).getTime(),
            time: `${format(new Date(booking.start), 'HH:mm')} - ${format(new Date(booking.end), 'HH:mm')}`,
            cancel_status: BOOKING_STATUS_DISPLAY[booking.cancel_status] || '-',
            rowAction:
              <TableAction
                openShowBooking={() => setSelectedBooking(booking)}
              />
          })
        });
      });
    }

    setDataList(newList);

  }, [siteData, bookingData, selectedCategory]);

  const handleAction = (data, value) => {
    if (value === 'edit') {
      setSelectedBooking(data);
    }
    else if (value === 'cancel') {
      dispatch(cancelBookingReset());
      setSelectedBooking(data);
      setOpenDelete(true);
    }
  };

  const handleRowSelect = (value) => {
    const selected = _.find(bookingData, (data) => data.id === value.reservation_id);
    if (selected) {
      setSelectedBooking(selected);
    }
  }

  const openRoomBooking = () => {
    history.push(`${path}/roomBooking`);
  }

  const generateOptions = (booking) => {
    let optionsText = '-';

    if (booking.options && booking.options.length !== 0) {
      const optionTitles = _.map(booking.options, (option) => option.title);
      optionsText = optionTitles.join('; ');
    }

    return optionsText;
  }

  const changeSelectedCategory = (value) => {
    setSelectedCategory(value);
  }

  const resetFilter = () => {
    setFilterConditions({
      startDate: initStart,
      endDate: initEnd,
    });
  }

  const handleCancelDelete = () => {
    setSelectedBooking(null);
    setOpenDelete(false);
  }

  const handleDelete = (values) => {
    dispatch(cancelBooking({ id: values.id, formData: values }));
  }

  return (
    <GenericLayout
      id="bookingReportLayout"
      className="bookingReportLayout"
    >
      <GenericAdminFrontHeader
        headerTitle={'Gestion des réservations'}
        headerDesc={`Accédez à l'historique et au planning des réservations`}
        RightSideComponent={
          <HeaderGroupContainer>
            <DownloadCsv
              bookingData={bookingData}
              bookingDataIsLoading={bookingDataIsLoading}
              siteData={siteData}
              siteDataIsLoading={siteDataIsLoading}
              corporationData={corporationData}
              corporationDataIsLoading={corporationDataIsLoading}
              filterConditions={filterConditions}
              selectedCategory={selectedCategory}
            />
            <ContentBar onChange={(cat) => changeSelectedCategory(cat)} />
            <HeaderButton onClick={() => openRoomBooking()}>
              {`Planning des réservations`}
            </HeaderButton>
          </HeaderGroupContainer>
        }
      />
      <MainContainer>
        <ContentFilter
          filterConditions={filterConditions}
          setFilterConditions={setFilterConditions}
          resetFilter={resetFilter}
        />
        {(bookingDataIsLoading || roomDataIsLoading || siteDataIsLoading || corporationDataIsLoading) &&
          <GenericLoader />
        }
        {!bookingDataIsLoading && !siteDataIsLoading && !corporationDataIsLoading &&
          <>
            <Chart
              bookingData={bookingData}
              corporationData={corporationData}
              filterConditions={filterConditions}
            />
            <TableWrapper>
              <GenericTable
                headerList={headerList}
                itemList={dataList}
                rowItemProps={rowItemProps}
                cellWithModifiedData={['date']}
                hover
                onTableRowClick={handleRowSelect}
              />
            </TableWrapper>
          </>
        }
      </MainContainer>
      {selectedBooking && !openDelete &&
        <InfoModal
          data={selectedBooking}
          corporationData={corporationData}
          onClose={() => setSelectedBooking(null)}
        />
      }
      {selectedBooking && openDelete &&
        <RoomBookingCancellationModal
          bookingData={selectedBooking}
          roomData={roomData}
          isUserConcierge={isUserConcierge}
          onClose={handleCancelDelete}
          onConfirm={(data) => handleDelete(data)}
          cancelBookingIsLoading={cancelBookingIsLoading}
        />
      }
      <Routes />
    </GenericLayout >
  );
}

export default BookingReport;