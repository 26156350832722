import React, { useState, useEffect } from "react"
import PlacesAutocomplete,{
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { IconSearch2 } from "../../../helpers/Icon";
// Styles
import {
  Container,
  InputFieldSearch,
  SearchIconWrapper,
  ListSearch,
} from "./style";

const GoogleSuggest = props => {
	const {
		setAddress:setAddressCallback,
    defaultValue = '',
    label = 'Votre adresse',
	} = props;
	const [address, setAddress] = useState(defaultValue);
	const handleChange = selectedAddress => {
    setAddress(selectedAddress);
    if (selectedAddress === "") setAddressCallback(selectedAddress, 0, 0);
  };
 
  const handleSelect = address => {
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        const {
          lat,
          lng,
        } = latLng;
        setAddress(address);
        setAddressCallback(address, lat, lng);
      })
      .catch(error => console.error('Error', error));
  };

  useEffect(() => {
    if (!!defaultValue ?.length) {
      setAddress(defaultValue);
    }
  }, [defaultValue]);

	return (
		<>
			<PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
        searchOptions={{
          componentRestrictions: { country: "fr" },
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <Container>
            <InputFieldSearch
              label={label}
              endAdornment={
                <SearchIconWrapper>
                  <IconSearch2 />
                </SearchIconWrapper>
              }
              {...getInputProps({
                placeholder: 'Rechercher une adresse',
                className: 'location-search-input',
              })}
            />
            <ListSearch>
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion, idx) => {
                return (
                  <div {...getSuggestionItemProps(suggestion)} key={idx}>
                    <span>
                      {suggestion.description}
                    </span>
                  </div>
                );
              })}
            </ListSearch>
          </Container>
        )}
      </PlacesAutocomplete>
		</>
	);
};

export default GoogleSuggest;
