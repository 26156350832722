import React, { useState, useEffect } from 'react';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  LineChart,
  Line,
  ResponsiveContainer,
  Label
} from 'recharts';
import {
  format,
  isBefore,
  addMonths,
  differenceInMinutes
} from 'date-fns';
import fr from 'date-fns/locale/fr';
import _ from 'lodash';

import { COLORS } from '../../../../../../constants/design';

import {
  PIE_COLORS,
  MainContainer,
} from './style';

const Chart = ({
  bookingData,
  corporationData,
  filterConditions
}) => {
  const [pieData, setPieData] = useState([]);
  const [monthData, setMonthData] = useState([]);

  useEffect(() => {
    if (bookingData) {
      let newPieData = [{
        id: 'no_corporation',
        name: 'sans entreprise',
        value: 0,
      }];
      _.map(corporationData, (corporation) => {
        newPieData.push({
          id: corporation.id,
          name: corporation.name,
          value: 0,
        })
      });

      const months = getAllMonths(filterConditions.startDate, filterConditions.endDate);
      const newMonthData = _.map(months, (month) => {
        return ({
          name: month,
          total: 0,
          duration: 0,
          average: 0,
        })
      });

      for (const booking of bookingData) {
        let selectedPie = _.find(newPieData, (pie) => pie.id === booking.corporation_id);
        const currentMonth = format(new Date(booking.start), "MMM", { locale: fr });
        const selectedMonth = _.find(newMonthData, (bar) => bar.name === currentMonth);

        if (selectedPie) {
          selectedPie.value++;
        }
        else if (booking.corporation_id === null) {
          selectedPie = _.find(newPieData, (pie) => pie.id === 'no_corporation');
          selectedPie.value++;
        }

        if (selectedMonth) {
          const duration = differenceInMinutes(new Date(booking.end), new Date(booking.start));
          selectedMonth.total++;
          selectedMonth.duration += duration;
          selectedMonth.average = Math.round(((selectedMonth.duration / selectedMonth.total) / 60) * 100) / 100;
        }
      }

      setPieData(newPieData);
      setMonthData(newMonthData);

      // console.log('newMonthData', newMonthData);
      // console.log('newPieData', newPieData);
    }
  }, [bookingData, corporationData]);

  const getAllMonths = (start, end) => {
    let startDate = new Date(start);
    const endDate = new Date(end);

    const months = [];

    while (isBefore(startDate, endDate)) {
      months.push(format(startDate, "MMM", { locale: fr }));
      startDate = addMonths(startDate, 1);
    }

    return months;
  }

  return (
    <MainContainer>
      <ResponsiveContainer width="25%" height="100%">
        <PieChart
          width={300}
          height={300}
        >
          <text x={145} y={20} textAnchor="middle" dominantBaseline="middle" className='labelText'>
            {`Nombre de réservations / entreprise`}
          </text>
          <Pie
            isAnimationActive={false}
            innerRadius={40}
            data={pieData}
            cx="50%"
            cy="50%"
            outerRadius={80}
            label={false}
          >
            <Label
              value={`Total : ${bookingData?.length}`} position="center" className='label-center'
            />
            {
              pieData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={PIE_COLORS[index % PIE_COLORS.length]} />
              ))
            }
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
      <ResponsiveContainer width="35%" height="100%">
        <BarChart
          width={420}
          height={300}
          data={monthData}
          isAnimationActive={false}
          margin={{
            top: 50,
            right: 5,
            left: 5,
            bottom: 5,
          }}
        >
          <text x={150} y={20} textAnchor="middle" dominantBaseline="middle" className='labelText'>
            {`Nombre de réservations par mois`}
          </text>
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Bar
            isAnimationActive={false}
            dataKey="total"
            fill={COLORS.PRIMARY_BLUE}
          />
        </BarChart>
      </ResponsiveContainer>

      <ResponsiveContainer width="35%" height="100%">
        <LineChart
          width={420}
          height={300}
          data={monthData}
          margin={{
            top: 50,
            right: 5,
            left: 5,
            bottom: 5,
          }}
        >
          <text x={150} y={20} textAnchor="middle" dominantBaseline="middle" className='labelText'>
            {`Nombre moyen d'heures par réservation`}
          </text>
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Line
            isAnimationActive={false}
            type="monotone"
            dataKey="average"
            stroke={COLORS.PRIMARY_BLUE}
          />
        </LineChart>
      </ResponsiveContainer>

    </MainContainer>
  );
};

export default Chart;