import styled from "styled-components";

import { COLORS } from "../../../../../../constants/design";

export const PIE_COLORS = [
  COLORS.PRIMARY_BLUE,
  COLORS.GREEN_99,
  COLORS.RED,
  COLORS.ORANGE_1,
  COLORS.LIGHT_BLUE_1,
  COLORS.YELLOW_1,
]

export const MainContainer = styled.div`
  /* border: 1px solid black; */
  width: 100%;
  height: 300px;
  display: flex;
  .recharts-wrapper {
    /* border: 1px solid black; */
  }
  .labelText {
    font-weight: 700;
  }
  .label-center {
    font-weight: 700;
    font-size: 0.9rem;
  }
`;