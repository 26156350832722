import styled from "styled-components";
import Dialog from '@material-ui/core/Dialog';

import { COLORS } from "../../../../../../../constants/design";
import breakpoints from "../../../../../../../theme-style/breakpoints";

import GenericAccordion from "../../../../../../../components/molecules/GenericAccordion";
import GenericButton from "../../../../../../../components/molecules/GenericButton";

export const CustomDialog = styled(Dialog)`
  && {
    .MuiDialog-container {
      .MuiDialog-paper {
        &.MuiPaper-rounded {
          width: 100%;
          /* height: 100%; */
        }
        &.MuiDialog-paperWidthSm {
          max-width: 800px;
        }
      }
    }
  }
`;

export const StyledTitle = styled.div`
  font-size: 1.1rem;
  font-weight: 600;
  display: flex;
`;

export const ContentContainer = styled.div`
  /* border: 1px solid black; */
  padding: 20px 0px 10px 0px;
  >:not(:last-child) {
    margin-bottom: 15px;
  };
`;

export const StyledAccordion = styled(GenericAccordion)`
  && {
    .MuiAccordion-root {
      font-size: 0.9rem;
    }
    .MuiAccordionSummary-content {
      font-weight: 600;
      font-size: 1rem;
    }
  }
  .textTooltip {
    color: ${COLORS.GREY_1}
  }
`;

export const AccordionContent = styled.div`
`;

export const StyledButton = styled(GenericButton)`
  && {
    &.MuiButton-root {
      /* width: 320px; */
      width: 100%;
      background-color: ${props => props.disabled ? COLORS.LIGHT_GREY_2 : COLORS.PRIMARY_BLUE};
    }
  }
`;

export const ContentConciergeContainer = styled.div`
  >:not(:last-child) {
    margin-bottom: 15px;
  };
`;

export const StyledMessageText = styled.div`
  padding: 0px;
  @media ${breakpoints.mobileXL} {
    padding: 5px 0px;
  }
  ol, ul {
    padding-left: 1.5em;
    list-style-position: inside;
  }
  ul {
    list-style: disc;
  }
  ol {
    list-style: decimal;
  }
  em {
    font-style: revert;
  }
`;

export const StyledMessageButton = styled(GenericButton)`
  && {
    &.MuiButton-root {
      color: ${COLORS.WHITE};
      position: relative;
      font-family: Quicksand;
      margin: 5px 0px;
      font-size: 0.75rem;
      font-weight: 500;
      background-color: ${props => props.disabled ? COLORS.LIGHT_GREY_2 : COLORS.PRIMARY_BLUE};
      width: 100%;
      padding: 10px 10px;
      transition: all ease-in .15s;
      border: transparent;
      border-radius: 5px;
      background-image: none;
      text-transform: none;
      &:hover {
        background-color: ${COLORS.PRIMARY_DEEP_BLUE};
      }

      .MuiButton-label{
        line-height: 1.4;
      }
    }
  }
`;