import React from "react";

const CarIcon = ({
  color = "#5A5A5A",
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon carIcon"
      width={width}
      height={height}
      viewBox="0 0 30 16"
      fill="none"
    >
      <path
        d="M16.73 0c.734 0 1.456.26 2.031.73l5.125 4.203c.315.259.71.4 1.111.4h1.1C28.634 5.333 30 7.737 30 10c0 1.691-1.108 3.012-2.658 3.283C27.06 14.827 25.733 16 24.146 16c-1.57 0-2.884-1.147-3.186-2.666H9.04C8.738 14.853 7.423 16 5.854 16c-1.59 0-2.917-1.176-3.198-2.723C1.148 12.99 0 11.629 0 10V7.333C0 5.496 1.46 4 3.252 4a1.77 1.77 0 001.482-.812L5.84 1.484A3.225 3.225 0 018.546 0zM5.853 10.667c-1.076 0-1.952.897-1.952 2 0 1.102.876 2 1.952 2 1.075 0 1.95-.898 1.95-2 0-1.103-.875-2-1.95-2zm18.292 0c-1.075 0-1.95.897-1.95 2 0 1.102.875 2 1.95 2 1.076 0 1.952-.898 1.952-2 0-1.103-.876-2-1.952-2zM16.73 1.333H8.546c-.653 0-1.26.334-1.623.89L5.816 3.928a3.055 3.055 0 01-2.564 1.406c-1.076 0-1.951.898-1.951 2V10c0 .9.584 1.664 1.384 1.914.334-1.476 1.628-2.58 3.169-2.58 1.57 0 2.884 1.146 3.186 2.665h11.92c.302-1.519 1.617-2.666 3.186-2.666 1.542 0 2.838 1.108 3.17 2.587.752-.216 1.383-.866 1.383-1.92 0-1.34-.693-3.333-2.601-3.333h-1.1a3.038 3.038 0 01-1.924-.694L17.95 1.772a1.933 1.933 0 00-1.22-.439zM17.256 8c.36 0 .65.299.65.667a.659.659 0 01-.65.666h-1.3a.659.659 0 01-.651-.666c0-.368.291-.667.65-.667zM14.96 2.667c.36 0 .65.298.65.666v2h4.635c.359 0 .65.299.65.667a.659.659 0 01-.65.667h-5.285A.659.659 0 0114.31 6V3.333c0-.368.291-.666.65-.666zm-7.085.369a.643.643 0 01.873-.299.676.676 0 01.291.895l-.831 1.701h3.541c.359 0 .65.299.65.667a.659.659 0 01-.65.667H7.154a.648.648 0 01-.552-.316.679.679 0 01-.029-.648z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export default CarIcon;
