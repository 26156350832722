export const actionGenerator = (value) => ({
  REQUEST: `SSO/${value}/REQUEST`,
  SUCCESS: `SSO/${value}/SUCCESS`,
  FAILURE: `SSO/${value}/FAILURE`,
})

export const SAML_LOGIN = actionGenerator("SAML_LOGIN");
export const SAML_REGISTERATION = actionGenerator("SAML_REGISTERATION");
export const AZUREAD_LOGIN = actionGenerator("AZUREAD_LOGIN");
export const AZUREAD_REGISTERATION = actionGenerator("AZUREAD_REGISTERATION");

export const samlLogin = (data) => ({
  type: SAML_LOGIN.REQUEST,
  data,
});

export const samlLoginSuccess = (data) => ({
  type: SAML_LOGIN.SUCCESS,
  data,
});

export const samlLoginError = (error) => ({
  type: SAML_LOGIN.FAILURE,
  error,
});

export const samlRegisteration = (data) => ({
  type: SAML_REGISTERATION.REQUEST,
  data,
});

export const samlRegisterationSuccess = (data) => ({
  type: SAML_REGISTERATION.SUCCESS,
  data,
});

export const samlRegisterationError = (error) => ({
  type: SAML_REGISTERATION.FAILURE,
  error,
});

// AZURE
export const azureadLogin = (data) => ({
  type: AZUREAD_LOGIN.REQUEST,
  data,
});

export const azureadLoginSuccess = (data) => ({
  type: AZUREAD_LOGIN.SUCCESS,
  data,
});

export const azureadLoginError = (error) => ({
  type: AZUREAD_LOGIN.FAILURE,
  error,
});

export const azureadRegisteration = (data) => ({
  type: AZUREAD_REGISTERATION.REQUEST,
  data,
});

export const azureadRegisterationSuccess = (data) => ({
  type: AZUREAD_REGISTERATION.SUCCESS,
  data,
});

export const azureadRegisterationError = (error) => ({
  type: AZUREAD_REGISTERATION.FAILURE,
  error,
});