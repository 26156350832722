import styled from "styled-components";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@material-ui/core/Button';
import { Form } from 'formik';

import { COLORS } from '../../../../../../../../../../constants/design'

export const StyledAccordion = styled(Accordion)`
  && {
    &.MuiPaper-elevation1 {
      box-shadow: unset;
    }
    &.MuiAccordion-root {
      background-color: ${COLORS.PRIMARY_BLUE};
      color: ${COLORS.WHITE};
      ::before {
        background-color: unset;
      }
    }
    &.MuiAccordion-rounded{
      border-radius: 6px;
    }
    .MuiAccordionSummary-expandIcon .MuiSvgIcon-root {
      color: ${COLORS.WHITE};
    }
    .MuiAccordionDetails-root {
      flex-direction: column;
    }
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  && {
    .MuiAccordionSummary-content {
      display: flex;
      align-items: center;
      >div:not(:last-child) {
        margin-right: 12px;
      };
    }
  }
`;

export const CircleDiv = styled.div`
  background-color: ${COLORS.WHITE};
  border-radius: 50%;
  display: flex;
  height: 25px;
  width: 25px;
  justify-content: center;
  align-items: center;
  color: ${COLORS.LIGHT_GREY_4};
`;

export const StyledAccordionTitle = styled.div`
  font-weight: 700;
  font-size: 0.9rem;
`;

export const StyledForm = styled(Form)`
  /* border: 10px solid blue; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 30px 30px 30px 30px; */
  /* margin-bottom: 20px; */
  .textTitleWrapper {
    margin-bottom: 3px;
    .textTitle {
      font-weight: 600;
      font-size: 0.8rem;
      color: ${COLORS.WHITE};
      margin: 10px 5px 10px 0px;
    }
  }
  .textTooltip {
    color: ${COLORS.WHITE};
  }
  .textFieldInput {
    margin-top: 0px;
    input {
      font-size: 0.8rem !important;
      color: ${COLORS.WHITE} !important;
      background-color: ${COLORS.PRIMARY_DEEP_BLUE};
      border-radius: 6px;
    }
  }
  .textAreaInput {
    color: ${COLORS.WHITE} !important;
    background-color: ${COLORS.PRIMARY_DEEP_BLUE};
    border-color: ${COLORS.PRIMARY_DEEP_BLUE};
    /* ::-webkit-resizer {
      border-color: ${COLORS.WHITE} !important;
    } */
  }
  .genericMaterialMenu {
    /* border: 1px solid red; */
    width: 100%;
    .menuInput {
      font-size: 0.8rem;
      color: ${COLORS.WHITE};
      background-color: ${COLORS.PRIMARY_DEEP_BLUE};
      border: none;
      border-radius: 6px;
    }
    .menuIcon {
      color: ${COLORS.WHITE};
    }
  }
  && {
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid transparent;
    }
    /* .Mui-focused {
      border: 1px solid ${COLORS.PRIMARY_DEEP_BLUE};
    } */
    /* .Mui-focused.MuiOutlinedInput-notchedOutline {
      border: 1px solid ${COLORS.PRIMARY_DEEP_BLUE};
    } */
  }
  >div:not(:last-child) {
    margin-bottom: 8px;
  };
`;

export const StyledMissionSummaryContainer = styled.div`
  font-size: 0.8rem;
  padding: 0px 5px;
  margin-bottom: 15px;
  >p:nth-child(even):not(:last-child) {
    border-bottom: 1px solid white;
    padding-bottom: 10px;
    margin-bottom: 10px;
  };
`;

export const StyledSummarySection = styled.div`
  margin-bottom: 10px;
`;

export const StyledSummaryText = styled.p`
  margin-bottom: 8px;
`;

export const StyledSummaryBoldText = styled.b`
  font-weight: 600;
  margin-right: 5px;
`;

export const StyledSummaryButtonContainer = styled.div`
  width: 100%;
  margin-top: ${props => props.type === 'formik' ? '20px' : '0px'};
  display: flex;
  >button:not(:last-child) {
    margin-right: 10px;
  };
`;

export const StyledButton = styled(Button)`
  && {
    &.MuiButton-root {
      position: relative;
      padding: 4px 15px;
      font-size: 0.8rem;
      background-color: ${COLORS.WHITE};
      color: ${COLORS.DARK_GREY};
      transition: all ease-in .15s;
      border: transparent;
      border-radius: 5px;
      text-transform: none;
      width: 100%;
    }
  }
`;