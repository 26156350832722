import styled, { css } from "styled-components";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import PopoverCpt from '../../../../../../../components/molecules/popoverCpt'
import Switch from "@material-ui/core/Switch";

export const SwitchCustom = styled(Switch)`
  &&{
    &.MuiSwitch-root{
      padding: 0;
      margin: 0 0 0 6px;
      height: 22px;
      width: 42px;
      border-radius: 100px;
      .MuiSwitch-switchBase{
        padding: 1px;
        width: 22px;
        height: 22px;
        &.Mui-checked{
          color: #FFFFFF;
        }
        &.Mui-checked + .MuiSwitch-track{
          background-color: rgb(64, 177, 118);
          opacity: 1;
        }
      }
    }
  }
`;

export const ElemCardContent = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: #FFFFFF;
    letter-spacing: 0;
    line-height: 18px;
`;

export const FlatInfoIconWrapper = styled.div`
    min-width: 12px;
    max-width: 12px;
    margin-right: 6px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

export const PopoverCptCustom = styled(PopoverCpt)`
    &&{
      &.popoverCpt{
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      /* #mouse-over-popover{
        background: red;
      } */
    }
`;

export const ElemCardHeaderRight = styled.div`
    font-size: 11px;
    font-weight: 500;
    color: #F7FBFF;
    display: flex;
    justify-content: flex-end;
    min-width: 100px;
    span {
      /* margin-right: 12px; */
      /* align-self: center; */
      /* justify-self: right; */
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-align: right;
      line-height: 14px;
      /* overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical; */
    }
`;

// MTaterial UI compoenents
export const StyledSwitchLabel = styled.label`
    cursor: pointer;
    text-indent: -9999px;
    width: 42px;
    min-width: 42px;
    height: 22px;
    align-self: center;
    justify-self: center;
    background-color: #FFFFFF;
    display: block;
    border-radius: 100px;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: 1px;
      left: 2px;
      width: 20px;
      height: 20px;
      background-color: #BBBBBB;
      border-radius: 90px;
      transition: 0.3s;
    }
    &:active:after {
      width: 22px;
    }
`;

export const StyledSwitch = styled.input`
    &[type=checkbox] {
      height: 0;
      width: 0;
      visibility: hidden;
    }
    &:checked + label {
      background-color: #40B176;
    }

    &:checked + label:after {
      background-color: #FFFFFF;
    }

    &:checked + label:after {
      left: calc(100% - 2px);
      transform: translateX(-100%);
    }

`;

export const StyledBeforeIcon = styled(NavigateBeforeIcon)`
  color: #FFFFFF;
  /* opacity: .7; */
  border: 1px solid white;
  font-weight: 400;
  opacity: ${({ isActive }) => isActive === 1 ? 1 : .5};
  cursor: pointer;
  border-radius: 4px;
  margin-right: 6px;
`;

export const StyledNextIcon = styled(NavigateNextIcon)`
  color: #FFFFFF;
  cursor: pointer;
  border: 1px solid white;
  opacity: ${({ isActive }) => isActive === 1 ? 1 : .5};
  font-weight: 400;
  margin-right: 20px;
  border-radius: 4px;
`;

export const ElemCard = styled.div`
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 150px;
  max-height: 20vh;
  padding: 20px;
  border-radius: 10px;
  background-image: linear-gradient(-45deg, #3C7DC9 0%, #2A65AA 100%);
  margin: 0 0 40px 0;
`;

export const ElemCardHeaderLeft = styled.div`
    font-size: 20px;
    font-weight: 700;
    display:flex;
    color: #FFFFFF;
    text-align: left;
    line-height: 24px;
    /* overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical; */
`;

export const ElemCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 17px;
`;
