import React, { Component } from 'react';

import Form from "react-jsonschema-form";

import Autocomplete from 'react-google-autocomplete';
import { CustomAddressInput } from '../../atoms/Input/CustomFieldAddress';
import { CustomFieldDatepicker, CustomFieldDatetimePicker } from '../../atoms/Input/CustomFieldDatepicker';
// import { CustomAddressInput } from "../../Legacy/atoms/Input/CustomFieldAddress";
// import { CustomFieldDatepicker, CustomFieldDatetimePicker } from "../../Legacy/atoms/Input/CustomFieldDatepicker";
import moment from "moment";

// Style
import {
    LinkRgpdWrapper,
} from './style';

const customSection = (props) => {
    return( 
        <div className="chatbot-a-title-section-form">
            <h2 className="chatbot-a-title-section">{props.schema.title}</h2>              
        </div>
    )
}

export default class ServiceRequestForm extends Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        let initialOrder = [];
        let required = [];
        let schema = props.formSchema.properties;
        let uiSchema = {};
        let initFirstName = null;
        let initLastName = null;

        if (props.requestOnBehalf) {
          delete schema['beneficiary'];
        }

        // ////////////////////////////////////////
        //  Parse Received data to create schema
        // ////////////////////////////////////////
        Object.keys(schema).forEach((key, index) => {

            let parameters = {};

            // Set required field
            if (schema[key].requiredList === true) {
                if (!(key === "beneficiaryFirstname" || key === "beneficiaryLastname")) {
                    required.push(key);
                }
            }
            // Remove required from no
            delete schema[key].requiredList;
            delete schema[key].required;
            // Set required field


            // Check empty title
            if (schema[key].title === null) {
                schema[key].title = schema[key].field_name;
            }
            // Check empty title

            // Set custom input 
            if (schema[key].widget === "google_address") {
                parameters = {
                    ...parameters,
                    "ui:field": "customAddress"
                }
            }
            else if (key === "email") {
                parameters = {
                    ...parameters,
                    "ui:options": {
                        inputType: "email"
                    }
                }
            }
            // Fix empty title 
            else if (key === "beneficiary") {
                schema[key].title = "Bénéficiaire de la prestation";
            }
            // Fix first name and last name missing when switch beneficiary
            else if (key === "beneficiaryFirstname") {
              initFirstName = schema[key];
            }
            else if (key === "beneficiaryLastname") {
              initLastName = schema[key];
            }

            // Hide input
            if (schema[key].display === "none") {
                parameters = {
                    ...parameters,
                    "ui:widget": "hidden"
                }
            }
            if(schema[key].html === "section"){
                parameters = {
                    ...parameters,
                    "ui:field" : "CustomSectionTitle"
                }
            }

            // Set custom fields 
            switch (schema[key].field_type) {
                case "ChoiceType":
                    parameters = {
                        ...parameters,
                        "ui:widget": "radio"
                    };


                    let valuesEnum = schema[key].values_available.enum;
                    schema[key].enum = [];
                    schema[key].enumNames = [];
                    Object.keys(valuesEnum).forEach((enumKey, enumIndex) => {
                        // Convert string to boolean
                        if(valuesEnum[enumKey] === "true" || valuesEnum[enumKey] === "false"){
                            let value = valuesEnum[enumKey] === "true" ? true : false
                            schema[key].enum.push(value);
                        } else {
                            schema[key].enum.push(valuesEnum[enumKey]);
                        }
                        
                        schema[key].enumNames.push(enumKey);
                    })
                    schema[key].messages = {
                        required: "Veuillez sélectionnez une option"
                    }
                    break;
                case "TextareaType":
                    parameters = {
                        ...parameters,
                        "ui:widget": "textarea"
                    }
                    break;
                    
                case "DateType":
                    parameters = {
                        ...parameters,
                        "ui:field": "customDate"
                    };
                    break;
                case "DateTimeType":
                    parameters = {
                        ...parameters,
                        "ui:field": "customDatetime"
                    };
                    break;

                case "TextType":
                    schema[key].messages = {
                        required: "Ce champ est obligatoire"
                    }
                    break;


                default:
                    break;
            }


            uiSchema[key] = parameters;
            initialOrder.push(key);
            // console.log('key', key);


        });
        let requireList = props.formSchema.requiredList.filter(list => list !== "beneficiaryFirstname" && list !== "beneficiaryLastname");
        if (props.requestOnBehalf) {
          requireList = props.formSchema.requiredList.filter(list => list !== "beneficiary");
        }
        props.formSchema.required = [...required, ...requireList];
        // console.log('props.formSchema.required', props.formSchema.required);
        // console.log('props.formSchema', props.formSchema);
        // props.formSchema.required = [...required, ...props.formSchema.requiredList];
        // initialOrder.splice(initialOrder.indexOf('beneficiaryLastname'), 1);
        // initialOrder.splice(initialOrder.indexOf('beneficiaryFirstname'), 1);
        uiSchema["ui:order"] = initialOrder;

        let headerText = (
            <p>
                {props.formSchema.properties.headerText.title}
            </p>
        )

        // if (props.defaultValues.beneficiary === "none") { // i will modify this later
        //   delete props.formSchema.properties.beneficiary;
        // }

        let overrideDefaultValue = {};

        //Set empty string when no default value
        Object.keys(props.defaultValues).forEach((key)=>{
            // Reset date value
            if(key.toLocaleLowerCase().includes("date")){
                overrideDefaultValue[key] = undefined;
            } else {
                if(props.defaultValues[key] === null) {
                    overrideDefaultValue[key] = "";
                }
            }
        });

        // delete props.formSchema.properties.beneficiaryFirstname
        // delete props.formSchema.properties.beneficiaryLastname

        this.state = {
            schema: props.formSchema,
            uiSchema,
            formData: {
                ...props.defaultValues,
                ...overrideDefaultValue
            },
            initialOrder,
            headerText,
            initFirstName,
            initLastName,
            selectedBeneficiary: null,
        }

    }

    handleSubmit({ formData }) {
        // e.preventDefault();        
        this.props.handleForm(formData);
    }

    componentDidMount() {

    }

    handleChange(data) {
        // const { name, value } = e.target;
        // this.setState(
        //     { 
        //         formData: {
        //             [name]: value
        //         } 
        //     });
        const { formData } = data;
        let schema = { ...this.state.schema }
        let selectedBeneficiary = this.state.selectedBeneficiary;
        const { initFirstName, initLastName } = this.state;
        // let newUiSchema = this.state.uiSchema;

        if (formData.hasOwnProperty('beneficiary')) {
            if (formData.beneficiary === "other" && selectedBeneficiary !== "other") {
                formData.beneficiaryFirstname = "";
                formData.beneficiaryLastname = "";
                if (initFirstName) {
                  schema.properties = {
                    ...schema.properties,
                    beneficiaryFirstname: {
                      ...initFirstName,
                      default_value: "",
                      require: true,
                    },
                  }
                }
                if (initLastName) {
                  schema.properties = {
                    ...schema.properties,
                    beneficiaryLastname: {
                      ...initLastName,
                      default_value: "",
                      require: true,
                    },
                  }
                }
                selectedBeneficiary = "other";
                schema.required.push("beneficiaryFirstname", "beneficiaryLastname");

                // console.log('schema.properties', schema.properties);

                // newUiSchema["ui:order"] = this.state.initialOrder;

                // newUiSchema["ui:order"] = [
                //     "beneficiary",
                //     "beneficiaryFirstname",
                //     "beneficiaryLastname",
                //     "*"
                // ]
            } else if (formData.beneficiary === "me" && selectedBeneficiary !== "me"){
                schema.properties = Object.assign({}, schema.properties)
                delete formData.beneficiaryFirstname
                delete formData.beneficiaryLastname
                delete schema.properties.beneficiaryFirstname
                delete schema.properties.beneficiaryLastname
                schema.required = schema.required.filter(list => list !== "beneficiaryFirstname" && list !== "beneficiaryLastname")
                selectedBeneficiary = "me";
                // newUiSchema["ui:order"] = [
                //     "beneficiary",
                //     "*"
                // ]
            }
        }

        // console.log('formData', formData);
        // console.log('schema', schema);
        // console.log('this.state.uiSchema', this.state.uiSchema);

        this.setState({
            formData,
            schema,
            selectedBeneficiary,
        });

    }

    validate(formData, errors) {
        // Check if has departure date and arrival date
        if(formData.arrivalDate !== undefined && formData.departureDate !== undefined){
            let arrivalDate = moment(formData.arrivalDate);
            let departureDate = moment(formData.departureDate);
            if( arrivalDate < departureDate ){
                errors.arrivalDate.addError("La date de retour doit être après la date départ");
            }   
        }

        if(formData.interventionDate !== undefined && formData.endDate !== undefined){
            let startDate = moment(formData.interventionDate);
            let endDate = moment(formData.endDate);
            if( endDate < startDate ){
                errors.endDate.addError("La date de fin doit être après la date début");
            } 
        }
        if(formData.pickupDate !== undefined && formData.depositDate !== undefined){
            let startDate = moment(formData.pickupDate);
            let endDate = moment(formData.depositDate);
            if( endDate < startDate ){
                errors.depositDate.addError("La date de dépôt doit être après la date début");
            } 
        }
        return errors;
    }

    transformErrors(errors){
        return errors.map(error => {
            if(error.name === "required"){
                error.message = "Ce champ est obligatoire."
            } else if (error.name === "type" && error.params.type === "integer"){
                error.message = "Veuillez préciser un chiffre."
            }
            return error;
        })
    }

    render() {
        const widgets = {
            inputaddress: CustomAddressInput,
            inputdatepicker: CustomFieldDatepicker
        }
        const customFields = {
            customAddress: CustomAddressInput,
            customDate: CustomFieldDatepicker,
            customDatetime : CustomFieldDatetimePicker,
            CustomSectionTitle : customSection
        }
        // console.log('this.state ',this.state);

        const ADMIN_BASE_URL = process.env.REACT_APP_ADMIN_BASE_URL;
        const LinkRgpdFiles = "https://" + ADMIN_BASE_URL + "/build/files/cyconia/politique-de-traitement-de-donnees.pdf";

        return (
            <React.Fragment>
                <div className="chatbot-m-card">
                    {this.state.headerText}
                </div>
                <Form
                    schema={this.state.schema}
                    widgets={widgets}
                    fields={customFields}
                    uiSchema={
                        this.state.uiSchema
                    }
                    formData={this.state.formData}
                    noValidate={false}
                    showErrorList={false}
                    onChange={this.handleChange}
                    transformErrors={this.transformErrors}
                    onSubmit={this.handleSubmit}
                    autoComplete="off"
                    // onError={errors => console.log(errors)}
                    validate={this.validate}
                    // ObjectFieldTemplate={CustomCollapse}
                >
                    <p className="chatbot-service-request-form-note">
                        Les champs suivis d'un <span className="required">*</span> sont obligatoires.
                    </p>
                    <div className="modal-form-controls">
                        <button
                            type="button"
                            onClick={this.props.lastStep}
                            className="chatbot-a-link_text chatbot-btn-icon">

                            J'ai chang&eacute; d'avis
                        </button>
                        <button
                            type="submit"
                            className="chatbot-a-button chatbot-a-button-green">
                            Valider
                        </button>
                    </div>
                </Form>
                
                <LinkRgpdWrapper>
                    <a target="_blank" href={LinkRgpdFiles} rel="noreferrer">
                        Politique de traitement des données
                    </a>
                </LinkRgpdWrapper>

                {/* <Form
                    schema={schema}
                    uiSchema={uiSchema}
                    fields={fields} /> */}
            </React.Fragment>
        )
    }

}