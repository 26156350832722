import { actions } from './actions';

export const initGetValue = {
  loading: false,
  success: false,
  error: null,
}

export const modifyGet = (type, error = null) => {
  return {
    loading: type === 'request' ? true : false,
    success: type === 'success' ? true : false,
    error: type === 'error' ? error : null,
  }
}

const INITIAL_STATE = {
  contexts: {
    data: null,
    get: initGetValue
  }
}

export function contextSetting(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case actions.GET_CONTEXTS.REQUEST:
      return {
        ...state,
        contexts: {
          data: null,
          get: modifyGet('request')
        },
      };
    case actions.GET_CONTEXTS.SUCCESS:
      return {
        ...state,
        contexts: {
          data: action.data,
          get: modifyGet('success')
        },
      };
    case actions.GET_CONTEXTS.FAILURE:
      return {
        ...state,
        contexts: {
          data: null,
          get: modifyGet('error', action.error)
        },
      };
    case actions.SAVE_CONTEXTS.REQUEST:
      return {
        ...state,
        contexts: {
          data: state.contexts.data,
          get: modifyGet('request')
        },
      };
    case actions.SAVE_CONTEXTS.SUCCESS:
      return {
        ...state,
        contexts: {
          data: action.data,
          get: modifyGet('success')
        },
      };
    case actions.SAVE_CONTEXTS.FAILURE:
      return {
        ...state,
        contexts: {
          data: null,
          get: modifyGet('error', action.error)
        },
      };
    default:
      return state;
  }
}

export default contextSetting;