import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import {
  PARAM_EXPLAINATION,
  URL_PRESET_TYPE,
  JOT_FORM_PRESET
} from './constants';

import {
  StyledForm,
  StyledDivider,
  StyledTextField,
  StyledRadioButton,
  StyledButton,
  StyledAccordion,
  StyledAccordionHeader,
  StyledAccordionContent,
  StyledMenu,
  StyledParamsHeader,
  StyledParamsTitle,
  StyledNewParamsContainer
} from './style';

const buttonLinkChoices = [
  {
    label: "Nouvelle fenêtre",
    value: "_blank",
  },
  {
    label: "Grande modale",
    value: "modal_big",
  },
  {
    label: "Petite modale",
    value: "modal_small",
  }
];

const menuList = [
  {
    value: URL_PRESET_TYPE.NONE,
    label: 'Aucun',
  },
  {
    value: URL_PRESET_TYPE.JOTFORM,
    label: 'Modèle Jotform',
  },
];

const UrlButtonForm = ({
  form,
  handleFormChange
}) => {

  const addNewParams = () => {
    const newParams = _.cloneDeep(form.urlParams);
    newParams.push({
      variable: "",
      value: "",
    });
    handleFormChange(newParams, "urlParams");
  };

  const handleParamChange = (value, index, key) => {
    const newParams = _.cloneDeep(form.urlParams);

    newParams[index][key] = value;
    handleFormChange(newParams, "urlParams");
  };

  const removeParam = (index) => {
    const newParams = _.cloneDeep(form.urlParams);

    newParams.splice(index, 1);
    handleFormChange(newParams, "urlParams");
  };

  const renderPresetInfo = (preset) => {
    if (preset === URL_PRESET_TYPE.JOTFORM) {
      return _.map(JOT_FORM_PRESET, (preset) => {
        return (
          <span>{`${preset.variable} = ${preset.value}`}</span>
        )
      });
    }
  }

  return (
    <StyledForm noValidate autoComplete="off">
      <StyledDivider>
        <StyledRadioButton
          row
          value={form.target}
          title={"Mode d'ouverture"}
          choices={buttonLinkChoices}
          onChange={(e) => handleFormChange(e.target.value, "target")}
        />
        <StyledTextField
          title={"Texte bouton"}
          value={form.title}
          variant={"outlined"}
          onChange={(e) => handleFormChange(e.target.value, "title")}
        />
        <StyledTextField
          title={"Url"}
          value={form.url}
          variant={"outlined"}
          onChange={(e) => handleFormChange(e.target.value, "url")}
        />
      </StyledDivider>

      <StyledAccordion
        headerComponent={
          <StyledAccordionHeader>
            {`Afficher les options avancées`}
          </StyledAccordionHeader>
        }
        detailComponent={
          <StyledAccordionContent>
            <StyledAccordion
              headerComponent={
                <StyledAccordionHeader>
                  {`Motifs de remplacement`}
                </StyledAccordionHeader>
              }
              detailComponent={
                <StyledAccordionContent>
                  <span>Les variables suivantes sont disponibles pour ce champ.</span>
                  {_.map(PARAM_EXPLAINATION, (explaination, index) => {
                    return <span key={index}>{explaination}</span>
                  })}
                </StyledAccordionContent>
              }
            />
            <StyledMenu
              title={'Sélectionnez un modèle'}
              toolTipText={`Sélectionner un modèle de motifs de remplacement vous permet d’intégrer directement les motifs sur votre formulaire, et obtenir les bonnes informations lors de sa soumission.
              ⚠️ Prenez soin de sélectionner le modèle correspondant à votre formulaire. Ex : Modèle Jotform sur une formulaire Jotform.`}
              list={menuList}
              initialValue={form.preset}
              onChange={(value) => handleFormChange(value, "preset")}
            />
            {form.preset !== URL_PRESET_TYPE.NONE &&
              <StyledAccordion
                headerComponent={
                  <StyledAccordionHeader>
                    {`Informations sur le modèle sélectionné`}
                  </StyledAccordionHeader>
                }
                detailComponent={
                  <StyledAccordionContent>
                    <span>{`Le modèle sélectionné attachera automatiquement la liste de motifs de remplacement suivante dans l’url :`}</span>
                    {renderPresetInfo(form.preset)}
                  </StyledAccordionContent>
                }
              />
            }
            {form.urlParams.length > 0 &&
              <StyledParamsHeader>
                <StyledParamsTitle>
                  Paramètre
                </StyledParamsTitle>
                <StyledParamsTitle>
                  Valeur
                </StyledParamsTitle>
              </StyledParamsHeader>
            }
            {_.map(form.urlParams, (param, index) => {
              return (
                <StyledNewParamsContainer key={index}>
                  <StyledTextField
                    value={param.variable}
                    variant={"outlined"}
                    onChange={(e) => handleParamChange(e.target.value, index, "variable")}
                  />
                  <StyledTextField
                    value={param.value}
                    variant={"outlined"}
                    onChange={(e) => handleParamChange(e.target.value, index, "value")}
                  />
                  <IconButton onClick={() => removeParam(index)}>
                    <HighlightOffIcon />
                  </IconButton>
                </StyledNewParamsContainer>
              )
            })}
            <StyledButton
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => addNewParams()}
            >
              Ajouter un paramètre
            </StyledButton>
          </StyledAccordionContent>
        }
      />
    </StyledForm>
  )
}

UrlButtonForm.propTypes = {
  form: PropTypes.object,
  handleFormChange: PropTypes.func
}

export default UrlButtonForm;