import React from "react";

const BikeIcon = ({
  color = "#5A5A5A",
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon bikeIcon"
      width={width}
      height={height}
      viewBox="0 0 24 14"
      fill="none"
    >
      <path
        d="M19.578 5.325c-.489 0-.979.083-1.455.247l-.1.034-1.6-2.853v-1.24h1.918a.663.663 0 00.668-.655c0-.362-.3-.656-.668-.656h-2.543a.663.663 0 00-.668.656c0 .053.007.108.022.163l.004.015v1.255H9.322l-.48-1.049h1.39c.349 0 .632-.278.632-.62A.628.628 0 0010.231 0H5.495a.628.628 0 00-.633.621c0 .343.284.621.633.621h1.959l.423.94-1.9 3.425-.1-.035a4.465 4.465 0 00-1.455-.246C1.984 5.326 0 7.27 0 9.663 0 12.054 1.984 14 4.422 14c2.164 0 3.995-1.52 4.355-3.614l.018-.102h1.882l.036.034a1.55 1.55 0 001.06.415c.85 0 1.541-.678 1.541-1.511 0-.144-.021-.287-.062-.426l-.015-.049L15.789 4.2l1.125 2.006-.082.064a4.285 4.285 0 00-1.676 3.393c0 2.391 1.983 4.337 4.422 4.337C22.016 14 24 12.054 24 9.663c0-2.392-1.984-4.338-4.422-4.338zm-13.11 1.98l.116.11c.434.4.742.91.89 1.474l.04.152h-2.02l.973-1.735zm1.006 3.132c-.36 1.366-1.616 2.32-3.052 2.32-1.74 0-3.155-1.388-3.155-3.095 0-1.706 1.415-3.095 3.155-3.095.254 0 .517.034.78.1l.157.039-1.491 2.658a.514.514 0 00-.056.144.513.513 0 00-.019.121v.007a.308.308 0 01-.003.025v.004a.874.874 0 00.02.146.547.547 0 00.04.114l.028.05.003.005a.419.419 0 00.07.094c.014.014.027.029.042.042a.94.94 0 00.1.074l.007.005.015.01.005.003.03.013a.515.515 0 00.117.042.58.58 0 00.127.018l.028.003h3.093l-.04.153zm3.113-2.18c-.166.197-.28.434-.328.685l-.019.1H8.795l-.018-.103a4.313 4.313 0 00-1.61-2.67l-.081-.064 1.453-2.59 2.097 4.584-.05.059zm1.733-.452l-.097-.029a1.567 1.567 0 00-.348-.062l-.074-.004-1.91-4.177h4.827L12.32 7.805zm7.258 4.952c-1.74 0-3.156-1.388-3.156-3.095 0-.857.353-1.655.993-2.248l.117-.108 1.491 2.656c.111.198.324.321.556.321a.633.633 0 00.607-.448.609.609 0 00-.054-.473l-1.49-2.656.156-.04c.263-.065.526-.099.78-.099 1.74 0 3.156 1.389 3.156 3.095 0 1.707-1.416 3.095-3.156 3.095z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export default BikeIcon;
