import React from "react";

import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useHistory
} from "react-router-dom";

import CompanyEditDrawer from "./components/CompanyEditDrawer";
import SiteEditDrawer from './components/SiteEditDrawer';

const Routes = () => {
  const history = useHistory();
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/companyEdit`} render={() => (
        <CompanyEditDrawer
          visible={true}
          closeFunction={() => history.push(`${path}`)}
        />
      )} />
      <Route exact path={`${path}/new`} render={() => (
        <SiteEditDrawer
          visible={true}
          closeFunction={() => history.push(`${path}`)}
        />
      )} />
      <Route exact path={`${path}/edit/:id`} render={() => (
        <SiteEditDrawer
          visible={true}
          closeFunction={() => history.push(`${path}`)}
          edit
        />
      )} />
      <Redirect to={`${path}`} />
    </Switch>
  );
}

export default Routes;
