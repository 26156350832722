import styled from "styled-components";

import breakpoints from "../../../../../theme-style/breakpoints";
import GenericTitleWithToolTip from '../../../../../components/molecules/GenericTitleWithToolTip';

export const MainContainer = styled.div`
`;

export const StyledTitleWithToolTip = styled(GenericTitleWithToolTip)`
  color: white;
  font-size: 16px;
  font-weight: 700;
  padding: 0 0 8px 0;
  @media ${breakpoints.mobileXL} {
    padding: 0 0 15px 0;
  }
`;