import { select, call, put } from 'redux-saga/effects'
import {
  getCalendarObjectsSuccess,
  getCalendarObjectsError,
  saveCalendarObjectSuccess,
  saveCalendarObjectError,
  deleteCalendarObjectSuccess,
  deleteCalendarObjectError
} from '../actions';
import endpoint from '../../../helpers/api';
import apiCall from '../../apiCall';
import * as authenticationSelectors from '../../authentication/selectors';
import { getUser } from '../../collaborator/selectors'
import { saveCalendarObjectService, deleteCalendarObjectService } from '../../../services/calendarServices';
import { toaster } from '../../../helpers/toaster';

export function* getCalendarObjectsHandler() {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const calendarObjects = yield call(apiCall, endpoint.getCalendarObjects, userToken);
    yield put(getCalendarObjectsSuccess(calendarObjects));
  } catch (err) {
    yield put(getCalendarObjectsError(err));
  }
}

export function* saveCalendarObjectHandler(action) {
  const { id, formData } = action.data;
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const user = yield select(getUser);
    const endPointUrl = id !== null ? endpoint.editCalendarObject(id) : endpoint.saveCalendarObject;
    const response = yield call(saveCalendarObjectService, endPointUrl, userToken, formData, user.id);
    yield put(saveCalendarObjectSuccess(response.data));
    toaster(id !== null ? 'Vos changements ont été enregistrés' : 'Cet élément est bien créé', 'success');
  } catch (err) {
    yield put(saveCalendarObjectError(err));
    toaster(`Une erreur s'est produite. Veuillez réessayer`, 'error');
  }
}

export function* deleteCalendarObjectHandler(action) {
  const id = action.data;
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    yield call(deleteCalendarObjectService, endpoint.deleteCalendarObject(id), userToken);
    yield put(deleteCalendarObjectSuccess(id));
    toaster('Cet élément est supprimé', 'success');
  } catch (err) {
    yield put(deleteCalendarObjectError(err));
    toaster(`Une erreur s'est produite. Veuillez réessayer`, 'error');
  }
}