export const COLORS = {
  DARK_GREY: '#5A5A5A',
  GREEN: '#40B176',
  GREEN_2: '#209156',
  GREEN_99: '#82ca9d',
  LIGHT_GREY_1: '#EEEEEE',
  LIGHT_GREY_2: '#DDDDDD',
  LIGHT_GREY_3: '#CCCCCC',
  LIGHT_GREY_4: '#BBBBBB',
  GREY_1: '#AAAAAA',
  PRIMARY_BLUE: '#357ED3',
  PRIMARY_DEEP_BLUE: '#155EB3',
  RED: '#EC6B87',
  RED_2: '#CC4B67',
  RED_3: '#F44336',
  RED_4: '#FF0000',
  SLIGHT_GREY: '#FAFAFA',
  SLIGHT_GREY_2: '#F7F7F7',
  WHITE: '#FFFFFF',
  BLACK_TRANSPARENT_10: 'rgba(0, 0, 0, 0.1)',
  BLACK_TRANSPARENT_30: 'rgba(0, 0, 0, 0.3)',
  BLACK_TRANSPARENT_70: 'rgba(0, 0, 0, 0.7)',
  ORANGE_1: '#FF9F6D',
  LIGHT_BLUE_1: '#9CC4F3',
  YELLOW_1: '#FFD700',
  YELLOW_2: '#FFDB83',
  PURPLE_99: '#8884d8',
};