import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import {
  CustomModal,
  ModalContainer,
  CloseButtonWrapper,
} from './style';

const GenericModal = ({
  open,
  onClose,
  hideCloseButton = false,
  className,
  children
}) => {
  return (
    <CustomModal
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={className}
    >
      <ModalContainer className={"modalContainer"}>
        {!hideCloseButton &&
          <CloseButtonWrapper className={"modalCloseButtonWrapper"}>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </CloseButtonWrapper>
        }
        {children}
      </ModalContainer>
    </CustomModal>
  )
}

GenericModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  hideCloseButton: PropTypes.bool,
  children: PropTypes.node
}

export default GenericModal;