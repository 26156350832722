import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import CancelIcon from '@material-ui/icons/Cancel';
import { Tooltip } from '@material-ui/core';

import PDFIcon from '../../../../../components/atoms/icons/pdf';
import ImageIcon from '../../../../../components/atoms/icons/image';

import {
  StyledThumbnailsContainer,
  StyledThumbnail,
  StyledThumbnailDiv,
  StyledThumbnailName,
  StyledThumbnailNoItem,
  StyledCloseButton,
  StyledThumbnailClose,
  StyledImg
} from './style';

const TicketAttachments = ({ fileList, setFileList, setMessageImageModal }) => {

  const handleFileRemove = (fileIndex) => {
    const fileListCopy = Array.from(fileList);

    fileListCopy.splice(fileIndex, 1);
    setFileList(fileListCopy);
  }

  const handleThumbnailClick = (file) => {
    const source = URL.createObjectURL(file);

    if (file.type === 'application/pdf' || file.type === 'image/heic') {
      window.open(source, '_blank');
    }
    else {
      setMessageImageModal(source);
    }
  }

  const ThumbnailComponent = ({ children, file, fileIndex }) => {
    return (
      <Tooltip title={file.name}>
        <StyledThumbnail>
          <StyledThumbnailClose onClick={() => handleFileRemove(fileIndex)}>
            <CancelIcon style={{ fontSize: 20 }} />
          </StyledThumbnailClose>
          <StyledThumbnailDiv onClick={() => handleThumbnailClick(file)}>
            {children}
          </StyledThumbnailDiv>
          <StyledThumbnailName>{file.name}</StyledThumbnailName>
        </StyledThumbnail>
      </Tooltip>
    )
  }

  const renderAttachementThumbnails = () => {
    if (fileList.length === 0) {
      return (
        <StyledThumbnailNoItem>{`Pas de fichier`}</StyledThumbnailNoItem>
      )
    }
    else {
      const thumbnailList = _.map(fileList, (file, index) => {
        if (file.type === 'application/pdf') {
          return (
            <ThumbnailComponent key={index} file={file} fileIndex={index}>
              <PDFIcon />
            </ThumbnailComponent>
          )
        }
        else if (file.type === 'image/heic') {
          return (
            <ThumbnailComponent key={index} file={file} fileIndex={index}>
              <ImageIcon />
            </ThumbnailComponent>
          )
        }
        else {
          const source = URL.createObjectURL(file);
          return (
            <ThumbnailComponent key={index} file={file} fileIndex={index}>
              <StyledImg src={source} alt={file.name} />
            </ThumbnailComponent>
          )
        }
      });
      return (
        <>
          <StyledCloseButton onClick={() => setFileList([])}>
            <CancelIcon style={{ fontSize: 30 }} />
          </StyledCloseButton>
          {thumbnailList}
        </>
      )
    }
  }

  return (
    <StyledThumbnailsContainer>
      {renderAttachementThumbnails()}
    </StyledThumbnailsContainer>
  )
};

export default React.memo(TicketAttachments);