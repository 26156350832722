// export const breakpoints = {
//   mobile: 0,
//   tablet: 768,
//   desktop: 992,
//   lgDesktop: 1200,
// };

export const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  mobileXL: "600px",
  tabletS: "736px",
  tablet: "768px",
  tabletL: "900px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

const breakpoints = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  mobileXL: `(min-width: ${size.mobileXL})`,
  tabletS: `(min-width: ${size.tabletS})`,
  tablet: `(min-width: ${size.tablet})`,
  tabletL: `(min-width: ${size.tabletL})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
};

export default breakpoints;
