import styled from "styled-components";
import Popover from "@material-ui/core/Popover";

export const Container = styled.div``;

export const WrapperTarget = styled.div``;

export const PopoverCustom = styled(Popover)`
  && {
    &.MuiPopover-root{
      pointer-events: none;
      background-color: transparent;
      margin: 4px;
      .MuiPopover-paper{
        border-radius: 6px;
        background-color: rgba(0, 0, 0, 0.7);
        backdrop-filter: blur(4px);
        span{
          display: flex;
          justify-content: flex-start;
          font-size: 12px;
          color: #ffffff;
          padding: 15px 18px;
          margin: 0;
        }
      }
    }
  }
`;
