import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import GenericMaterialMenu from '../../../../../../../components/molecules/GenericMaterialMenu';
import GenericCheckBox from '../../../../../../../components/molecules/GenericCheckbox';

import {
  NAV_BAR_ROUTES,
  SIDE_BAR_BLOCKS
} from '../../../../../../../constants/sideBarConstants';

import {
  MainContainer,
  StyledAccordion,
  StyledTitle,
  DetailContainer,
  TemplateListContainer,
  BarContainer,
  CheckBoxContainer,
  SelectButtonContainer,
  SelectAllButton,
  ModuleContainer,
} from './style';

const ContextEdit = ({
  navigationForm,
  currentTemplateActive,
  landingRoute,
  handleSelectAll,
  handleLandingRoute,
  handleCheckMainBar,
  handleCheckBox,
}) => {
  const [landingRouteList, setLandingRouteList] = useState([]);

  useEffect(() => {
    const newList = [{
      value: null,
      label: 'Comportement par défaut (Tableau de bord)'
    }];
    _.map(navigationForm, (form, key) => {
      if (form.active) {
        newList.push({
          value: key,
          label: NAV_BAR_ROUTES[key]?.label
        })
      }
      if (key === landingRoute && !form.active) {
        handleLandingRoute(null);
      }
    });
    setLandingRouteList(newList);
  }, [navigationForm]);

  const renderModule = (navBar, navKey) => {
    if (!_.isEmpty(navBar.blocks)) {
      return _.map(navBar.blocks, (block, blockKey) => {
        return (
          <ModuleContainer key={blockKey} active={currentTemplateActive && navBar.active}>
            <GenericCheckBox
              title={SIDE_BAR_BLOCKS[blockKey]?.label}
              onChange={(event) => handleCheckBox(event, navKey, blockKey)}
              disabled={!currentTemplateActive || !navBar.active}
              checked={block.active}
            />
          </ModuleContainer>
        )
      });
    }
    else {
      return (
        <ModuleContainer>
          {`No module available`}
        </ModuleContainer>
      );
    }
  };

  const renderForm = () => {
    if (navigationForm) {
      return _.map(navigationForm, (navBar, navKey) => {
        return (
          <BarContainer key={navKey} active={currentTemplateActive}>
            <CheckBoxContainer>
              <GenericCheckBox
                title={NAV_BAR_ROUTES[navKey]?.label}
                onChange={(event) => handleCheckMainBar(event, navKey)}
                disabled={!currentTemplateActive}
                checked={navBar.active}
              />
              <SelectButtonContainer>
                <SelectAllButton title={`Select all`} onClick={() => handleSelectAll(navKey, true)} />
                <SelectAllButton title={`Remove all`} onClick={() => handleSelectAll(navKey, false)} className={'removeAll'} />
              </SelectButtonContainer>
            </CheckBoxContainer>
            {/* {navKey !== NAV_BAR_KEYS.CONSOLE && renderModule(navBar, navKey)} */}
            {renderModule(navBar, navKey)}
          </BarContainer>
        )
      });
    }
  };

  return (
    <MainContainer>
      <StyledAccordion
        defaultExpanded={true}
        headerComponent={
          <StyledTitle>
            {`Navigation settings ${currentTemplateActive ? '' : '(pas actif)'}`}
          </StyledTitle>
        }
        detailComponent={
          <DetailContainer>
            <GenericMaterialMenu
              title={'Select landing route'}
              list={landingRouteList}
              onChange={handleLandingRoute}
              initialValue={landingRoute}
            />
            <SelectButtonContainer>
              <SelectAllButton title={`Select all modules`} onClick={() => handleSelectAll('all', true)} />
              <SelectAllButton title={`Remove all modules`} onClick={() => handleSelectAll('all', false)} className={'removeAll'} />
            </SelectButtonContainer>
            <TemplateListContainer>
              {renderForm()}
            </TemplateListContainer>
          </DetailContainer>
        }
      />
    </MainContainer>
  );
};

export default ContextEdit;