import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Parser from 'html-react-parser';
import ListAltIcon from '@material-ui/icons/ListAlt';
import SearchIcon from '@material-ui/icons/Search';
import _ from 'lodash';

import {
  MESSAGE_ELEMENT_TYPE,
  FEEDBACK,
  LOADING_ANIMATION
} from '../../../../constants/chatBotConstants';
import CycoBotSvg from '../../../../assets/icons/chat-icon.svg';
import { COLORS } from '../../../../constants/design';
import EditIcon from '../../../../assets/icons/IconEdit';

import BotmanTextMessage from './components/BotmanTextMessage/BotmanTextMessage';
import ServiceCard from './components/ServiceCard';
import GenericHorizontalScrollView from '../../../../../../components/molecules/GenericHorizontalScrollView';

import {
  StyledMainContainer,
  StyledAvatar,
  StyledMessageBubble,
  StyledMessageText,
  StyledMessageButton,
  StyledFeedbackContainer,
  StyledFeedbackIcon,
  StyledShowRelatedSubjectsContainer,
  StyledShowRelatedSubjectsIcon,
  StyledShowRelatedSubjectsText
} from './style';

const Message = ({
  message,
  messageIndex,
  isAllowedToEditResponse,
  onMessageButtonClick,
  onFeedbackClick,
  isReceived,
  // PROPS FOR BOTMAN
  onBotmanFeedbackClick,
}) => {
  const [showFeedbackIcon, setShowFeedbackIcon] = useState(false);
  const [relatedSubjectElement, setRelatedSubjectElement] = useState(null);

  useEffect(() => {
    if (!message.feedbackStatus)
      setShowFeedbackIcon(false);
    _.map(message.messageElements, (element) => {
      if (element.type === MESSAGE_ELEMENT_TYPE.BUTTON_SHOW_RELATED) {
        setRelatedSubjectElement(element);
      }
    });
  }, [message]);

  const handleMouseHover = (value) => {
    if (isReceived && message.defaultValues)
      setShowFeedbackIcon(value)
  }

  const handleEditClick = () => {
    let pathUrl = window.location.href.substr(0, window.location.href.indexOf("/dashboard"));
    pathUrl = pathUrl + "/admin/dashboard?opendrawerbyid=" + message.defaultValues.informationElementId;
    window.open(pathUrl, '_blank').focus();
  }

  const handleShowRelatedSubject = () => {
    onMessageButtonClick(message, relatedSubjectElement);
  }

  const renderMessageContent = (message) => {
    if (message.isLoading) {
      return (
        <StyledMessageText>
          {Parser(LOADING_ANIMATION)}
        </StyledMessageText>
      )
    }
    else if (message.messageElements) {
      return _.map(message.messageElements, (element, elementIndex) => {
        if (element.type === MESSAGE_ELEMENT_TYPE.TEXT) {
          return (
            <StyledMessageText key={elementIndex} >
              {Parser(element.content)}
            </StyledMessageText>
          )
        }
        else if (element.type === MESSAGE_ELEMENT_TYPE.CARD_LIST) {
          return (
            <GenericHorizontalScrollView key={elementIndex}>
              {_.map(element.cards, (card, index) => {
                if (card.type === 'service-card') {
                  return (
                    <ServiceCard
                      key={index}
                      itemId={index} // This props is important for Horizontal scroll view to work!
                      card={card}
                      onMessageButtonClick={onMessageButtonClick}
                    />
                  )
                }
              })}
            </GenericHorizontalScrollView>
          )
        }
        else if (element.type !== MESSAGE_ELEMENT_TYPE.BUTTON_SHOW_RELATED) {
          let buttonClassName = '';
          if (element.type === MESSAGE_ELEMENT_TYPE.BUTTON_FUNCTION &&
            element.transmitData.type === 'relatedSubject') {
            buttonClassName = 'relatedSubjectButton';
          }
          return (
            <StyledMessageButton
              key={elementIndex}
              startIcon={element.type === MESSAGE_ELEMENT_TYPE.BUTTON_FORM ? <ListAltIcon /> : null}
              appearence={"gradientBlue"}
              disabled={element.disabled}
              className={buttonClassName}
              onClick={() => onMessageButtonClick(message, element, elementIndex, messageIndex)}
            >
              {element.title}
            </StyledMessageButton>
          )
        }
      })
    }
  }

  const renderMessage = () => {
    return (
      <StyledMessageBubble isReceived={isReceived} className={message.className}>
        {renderMessageContent(message)}
        {isReceived &&
          (showFeedbackIcon ||
            (message.feedbackStatus && (message.feedbackStatus.positiveStatus || message.feedbackStatus.negativeStatus))) &&
          <StyledFeedbackContainer>
            {message.defaultValues &&
              <StyledFeedbackIcon
                hoverColor={COLORS.GREEN}
                isResponded={_.get(message, 'feedbackStatus.positiveStatus') === 201}
                onClick={() => onFeedbackClick(message, FEEDBACK.POSITIVE, messageIndex)}
              >
                <span role={'img'} aria-label={'thumb-up'}>👍</span>
              </StyledFeedbackIcon>
            }
            {message.defaultValues &&
              <StyledFeedbackIcon
                hoverColor={COLORS.RED}
                isResponded={_.get(message, 'feedbackStatus.negativeStatus') === 201}
                onClick={() => onFeedbackClick(message, FEEDBACK.NEGATIVE, messageIndex)}
              >
                <span role={'img'} aria-label={'thumb-up'}>👎</span>
              </StyledFeedbackIcon>
            }
            {isAllowedToEditResponse && message.defaultValues &&
              <StyledFeedbackIcon
                hoverColor={COLORS.PRIMARY_BLUE}
                onClick={() => handleEditClick()}
                className={'messageEditIcon'}
              >
                <EditIcon /> V2
              </StyledFeedbackIcon>
            }
          </StyledFeedbackContainer>
        }
      </StyledMessageBubble>
    )
  }

  return (
    <StyledMainContainer
      isReceived={isReceived}
      onMouseOver={() => handleMouseHover(true)}
      onMouseLeave={() => handleMouseHover(false)}
    >
      {isReceived && <StyledAvatar image={isReceived ? CycoBotSvg : ''} />}
      {(message.isLoading || message.messageElements) && renderMessage()}
      {(!message.isLoading && !message.messageElements && message.text) &&
        <StyledMessageBubble isReceived={isReceived} className={message.className}>
          <BotmanTextMessage
            data={{ text: message.text }}
            messageIndex={messageIndex}
            onMessageButtonClick={onMessageButtonClick}
            onBotmanFeedbackClick={onBotmanFeedbackClick}
          />
        </StyledMessageBubble>
      }
      {relatedSubjectElement &&
        // showFeedbackIcon &&
        <StyledShowRelatedSubjectsContainer onClick={() => handleShowRelatedSubject()}>
          <StyledShowRelatedSubjectsIcon>
            <SearchIcon />
          </StyledShowRelatedSubjectsIcon>
          <StyledShowRelatedSubjectsText>
            {relatedSubjectElement.title}
          </StyledShowRelatedSubjectsText>
        </StyledShowRelatedSubjectsContainer>
      }
    </StyledMainContainer>
  )
};

Message.propTypes = {
  message: PropTypes.object,
  messageIndex: PropTypes.number,
  isAllowedToEditResponse: PropTypes.bool,
  onMessageButtonClick: PropTypes.func,
  onFeedbackClick: PropTypes.func,
  isReceived: PropTypes.bool
};

export default Message;