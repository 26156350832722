import React from "react";

const FileCheckIcon = ({
  color = "#5A5A5A",
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon fileCheckIcon"
      width={width}
      height={height}
      viewBox="0 0 11 11"
      fill="none"
    >
      <path
        d="M7.5 1.072V.544C7.5.244 7.273 0 6.993 0H3.007C2.727 0 2.5.244 2.5.544v.528H1.267C.568 1.072 0 1.682 0 2.432v9.208C0 12.39.568 13 1.267 13h7.466C9.432 13 10 12.39 10 11.64V2.432c0-.75-.568-1.36-1.267-1.36H7.5zm-1.014.016v1.056H3.513V1.088h2.973zm2.5 10.552c0 .15-.113.272-.253.272H1.267c-.14 0-.254-.122-.254-.272V2.432c0-.15.114-.272.254-.272H2.5v.528c0 .3.227.544.507.544h3.986c.28 0 .507-.244.507-.544V2.16h1.233c.14 0 .254.122.254.272v9.208zM7.352 5.424a.573.573 0 010 .77L4.688 9.053a.482.482 0 01-.716 0L2.648 7.632a.573.573 0 010-.77.483.483 0 01.717 0L4.33 7.9l2.305-2.475a.483.483 0 01.716 0z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export default FileCheckIcon;
