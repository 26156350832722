import React from 'react';
import {
  Link,
  useRouteMatch,
  NavLink
} from "react-router-dom";

// Styles Theme Hooks
import useTheme from "../../../../theme-style/useThemeCustomHook";

// Components
import ButtonCpt from '../../../../components/molecules/buttonCpt';

// Icons
import TargetIcon from '../../../../components/atoms/icons/target';
import FileCheckIcon from '../../../../components/atoms/icons/fileCheck';
import OldIcon from '../../../../components/atoms/icons/old';
import SearchIcon from '../../../../components/atoms/icons/search';
import CalendarIcon from '../../../../components/atoms/icons/calendar';
import OptionIcon from '../../../../components/atoms/icons/option';

// Styles
import {
  Container,
  WrapperContent,
  Toolbar,
  AllFeedbackIconWrapper,
  CompetenceFeedbackIconWrapper,
  OldFeedbackIconWrapper,
  ButtonCptAllFeedback,
  ButtonCptCompetenceFeedback,
  ButtonCptOldFeedback,
  SearchIconWrapper,
  DateIconWrapper,
  FiltreIconWrapper,
  ButtonCptSearch,
  ButtonCptDate,
  ButtonCptFiltre
} from "./style";

const Header = () => {
  const { path } = useRouteMatch();
  const theme = useTheme();

  return (
      <Container>
        <WrapperContent>
          <ButtonCptAllFeedback
            appearence="secondary"
            component={NavLink}
            activeClassName={"isActive"}
            label="Toutes les remarques non traitées"
            to={`${path}/all-feedback`}
            startIcon={
              <AllFeedbackIconWrapper>
                <TargetIcon color={theme.colors ? theme.colors.grey7 : ""} />
              </AllFeedbackIconWrapper>
            }
          />

          <ButtonCptCompetenceFeedback
            appearence="secondary"
            component={NavLink}
            activeClassName={"isActive"}
            label="Les remarques par sujet"
            to={`${path}/competence-feedback`}
            startIcon={
              <CompetenceFeedbackIconWrapper>
                <FileCheckIcon color={theme.colors ? theme.colors.grey7 : ""} />
              </CompetenceFeedbackIconWrapper>
            }
          />

          <ButtonCptOldFeedback
            appearence="secondary"
            component={NavLink}
            activeClassName={"isActive"}
            label="Les remarques traitées"
            to={`${path}/old-feedback`}
            startIcon={
              <OldFeedbackIconWrapper>
                <OldIcon color={theme.colors ? theme.colors.grey7 : ""} />
              </OldFeedbackIconWrapper>
            }
          />
        </WrapperContent>
        {/*<Toolbar>
          <ButtonCptSearch
            appearence="secondary"
            startIcon={
              <SearchIconWrapper>
                <SearchIcon color={theme.colors ? theme.colors.grey7 : ""} />
              </SearchIconWrapper>
            }
          />
          <ButtonCptDate
            appearence="secondary"
            startIcon={
              <DateIconWrapper>
                <CalendarIcon color={theme.colors ? theme.colors.grey7 : ""} />
              </DateIconWrapper>
            }
          />
          <ButtonCptFiltre
            appearence="secondary"
            startIcon={
              <FiltreIconWrapper>
                <OptionIcon color={theme.colors ? theme.colors.grey7 : ""} />
              </FiltreIconWrapper>
            }
          />
        </Toolbar>*/}
      </Container>
  );
}

export default Header;
