import React from "react";

class StepCancel extends React.Component {
    constructor(props) {
        super(props);
        this.closeModal = this.closeModal.bind(this)
    }

    closeModal() {
        this.props.closeModal();
        this.props.firstStep();
    }
    render() {



        return (
            <div className="">

                <div className="chatbot-m-card">
                    <p>
                        Voulez-vous vraiment abandonner votre demande ?
                    </p>
                </div>

                <div className="modal-form-controls">
                    <button
                        type="submit"
                        onClick={this.props.firstStep}
                        className="chatbot-a-button chatbot-a-button-red">
                        Non
                    </button>

                    <button
                        type="submit"
                        onClick={this.closeModal}
                        className="chatbot-a-button chatbot-a-button-green">
                        Oui
                    </button>
                </div>
            </div>
        )
    }
}

export default StepCancel;
