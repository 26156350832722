import React from "react";

const ConciergerieIcon = ({
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon conciergerieIcon"
      width={width}
      height={height}
      viewBox="0 0 25 21"
      fill="none"
    >
      <g fillRule="nonzero" fill="none">
        <path
          d="M23.69 19.344a.604.604 0 01-.583.595H1.013a.604.604 0 01-.583-.595c0-.317.272-.594.583-.594h22.094c.35 0 .583.277.583.594z"
          fill="#5D6967"
        />
        <g fill="#444F4D">
          <path d="M23.69 19.344a.604.604 0 00-.583-.594H18.71l1.867.951.312.278h2.217c.35 0 .583-.278.583-.635zM5.409 18.75H1.013a.604.604 0 00-.583.594c0 .318.272.595.583.595H3.23l.312-.277 1.867-.912z" />
        </g>
        <path
          d="M23.146 16.332c-.04-.04-.078-.04-.117-.08l-8.674-5.39c-.622-.595-1.44-.952-2.295-.952-.856 0-1.673.317-2.295.951l-8.713 5.391c-.039.04-.078.04-.117.08-.816.872-1.128 2.061-.816 3.21.038.199.194.318.35.397.039 0 .116.04.155.04a.62.62 0 00.35-.12l2.568-2.14 6.806-3.488c.04-.04.117-.08.156-.12a2.16 2.16 0 011.556-.673c.583 0 1.167.238 1.556.674.039.04.078.079.155.119l6.807 3.488 2.568 2.14c.155.12.35.16.505.08a.52.52 0 00.35-.396c.312-1.15 0-2.34-.855-3.211z"
          fill="#F94184"
        />
        <path
          d="M23.068 17.204l-8.713-5.391c-.622-.595-1.44-.952-2.295-.952-.856 0-1.673.317-2.295.952l-8.713 5.39c-.039.04-.078.04-.117.08a3.535 3.535 0 00-.933 1.863c0 .12.039.238.078.397.039.198.194.317.35.396.039 0 .116.04.155.04a.62.62 0 00.35-.12l2.568-2.14 6.807-3.488c.038-.04.116-.08.155-.12a2.16 2.16 0 011.556-.673c.584 0 1.167.238 1.556.674.039.04.078.079.156.119l6.807 3.488 2.567 2.14c.155.12.35.16.505.08a.52.52 0 00.35-.396c.04-.12.04-.238.078-.397a3.398 3.398 0 00-.933-1.863c.039 0 0-.04-.04-.08z"
          fill="#EF0770"
        />
        <path
          d="M12.643 10.306a.604.604 0 01-.583.595.604.604 0 01-.583-.595V6.818c0-.317.272-.595.583-.595.311 0 .583.278.583.595v3.488z"
          fill="#444F4D"
        />
        <path
          d="M12.06.95c-1.712 0-3.112 1.428-3.112 3.172 0 .317.272.595.584.595a.604.604 0 00.583-.595c0-1.11.895-2.022 1.984-2.022s1.984.912 1.984 2.022c0 1.11-.895 2.022-1.984 2.022a.604.604 0 00-.584.594c0 .318.234.555.545.555 1.711 0 3.112-1.427 3.112-3.17 0-1.745-1.4-3.172-3.112-3.172z"
          fill="#5D6967"
        />
      </g>
    </svg>
  );
};

export default ConciergerieIcon;
