import styled from "styled-components";

import { COLORS } from "../../../../../constants/design";


export const MainContainer = styled.div`
  /* border: 1px solid black; */
  /* margin-top: 10px; */
  height: 375px;
  overflow: auto;
  >div:not(:last-child) {
    margin-bottom: 15px;
  };
`;

export const OptionsContainer = styled.div`
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  .MuiCheckbox-root{
    padding: 0px;
  }
  .Mui-checked{
    color: ${COLORS.PRIMARY_BLUE} !important;
  }
`;

export const OptionsCheckBoxContainer = styled.div`
  /* border: 1px solid blue; */
  align-self: flex-start;
`;

export const OptionsInfoContainer = styled.div`
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  width: 100%;
  >div:not(:last-child) {
    margin-bottom: 5px;
  };
`;

export const OptionsTitle = styled.div`
  /* border: 1px solid blue; */
  font-weight: 700;
  width: 100%;
`;

export const OptionsDetails = styled.div`
  width: 100%;
  /* border: 1px solid blue; */
`;

