import React from 'react';

const IconEditBotMsg = () => {
  return (
    <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
      <title>Personnaliser cette réponse</title>
      <g id="Page-2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="001-pencil" fillRule="nonzero">
          <path d="M15.7627266,2.23734375 L14.1617789,0.636396103 C13.8103113,0.284919955 13.2404629,0.284919955 12.888991,0.636391817 C12.8889896,0.636393246 12.8889881,0.636394675 12.888991,0.636400389 L10.9151719,2.61024609 L10.9151719,2.61024609 L12.7796484,7.45780078 L15.7627266,2.23734375 Z" id="Path" fill="#FF3E3A"></path>
          <path d="M15.7627266,2.23734375 L11.6609766,6.33912891 L15.3898594,7.08489844 L17.3636742,5.1110836 C17.7151461,4.75961174 17.7151461,4.18976326 17.3636742,3.8382914 L15.7627266,2.23734375 L15.7627266,2.23734375 Z" id="Path" fill="#CC3245"></path>
          <path d="M1.20290625,14.2911563 L0.575982861,16.2241273 C0.422635596,16.6969376 0.681611535,17.2045385 1.15442181,17.3578858 C1.34813197,17.4207121 1.55742295,17.4159863 1.74809991,17.3444806 L3.6,16.65 L3.6,16.65 L1.20290625,14.2911563 Z" id="Path" fill="#373E9F"></path>
          <path d="M1.35,17.406 C1.5298809,17.4407467 1.72007913,17.4311357 1.92059469,17.3771668 L3.70894922,16.7971641 L3.20171484,14.7983906 L0.833020161,17.1670853 C0.997792491,17.291615 1.1701191,17.3712533 1.35,17.406 Z" id="Path" fill="#340D66"></path>
          <polygon id="Path" fill="#FFE7B5" points="6.62252344 12.8691211 2.14787109 11.377582 1.20290625 14.2911563 2.45591016 15.5441602"></polygon>
          <polygon id="Path" fill="#FFD06A" points="2.45591016 15.5441602 3.70894922 16.7971641 6.62248828 15.8522344 5.87671875 12.1233516"></polygon>
          <polygon id="Path" fill="#FF9A27" points="4.38517969 13.6149258 6.62248828 15.8522344 15.3898594 7.08489844 13.1525156 4.84758984 7.31063672 7.70635547"></polygon>
          <polygon id="Path" fill="#FFB820" transform="translate(7.648523, 8.112544) rotate(-45.000000) translate(-7.648523, -8.112544) " points="1.45002388 6.53075202 13.8470226 6.53075202 13.8470226 9.69433671 1.45002388 9.69433671"></polygon>
        </g>
      </g>
    </svg>
  )
}

export default IconEditBotMsg;