import { actions } from './actions';
import _ from 'lodash';

const initGetValue = {
  loading: false,
  success: false,
  error: null,
}

const modifyGet = (type, error = null) => {
  return {
    loading: type === 'request' ? true : false,
    success: type === 'success' ? true : false,
    error: type === 'error' ? error : null,
  }
}

const INITIAL_STATE = {
  corporations: {
    data: null,
    get: initGetValue
  },
  sites: {
    data: null,
    get: initGetValue
  },
  concierges: {
    data: null,
    get: initGetValue
  },
  providers: {
    data: null,
    get: initGetValue
  },
  services: {
    data: null,
    get: initGetValue
  },
  servicesActifs: {
    data: null,
    get: initGetValue
  },
  ticketRequests: {
    data: null,
    get: initGetValue
  },
  calendarObjects: {
    data: null,
    get: initGetValue
  },
  internalServiceForm: {
    data: null,
    get: initGetValue
  },
  internalServiceFormSchema: {
    data: null,
    get: initGetValue
  }
}

export function concierge(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case actions.GET_CORPORATIONS.REQUEST:
      return {
        ...state,
        corporations: {
          data: null,
          get: modifyGet('request')
        },
      };
    case actions.GET_CORPORATIONS.SUCCESS:
      return {
        ...state,
        corporations: {
          data: action.data,
          get: modifyGet('success')
        },
      };
    case actions.GET_CORPORATIONS.FAILURE:
      return {
        ...state,
        corporations: {
          data: null,
          get: modifyGet('error', action.error)
        },
      };
    case actions.GET_SITES.REQUEST:
      return {
        ...state,
        sites: {
          data: null,
          get: modifyGet('request')
        },
      };
    case actions.GET_SITES.SUCCESS:
      return {
        ...state,
        sites: {
          data: action.data,
          get: modifyGet('success')
        },
      };
    case actions.GET_SITES.FAILURE:
      return {
        ...state,
        sites: {
          data: null,
          get: modifyGet('error', action.error)
        },
      };
    case actions.GET_CONCIERGES.REQUEST:
      return {
        ...state,
        concierges: {
          data: null,
          get: modifyGet('request')
        },
      };
    case actions.GET_CONCIERGES.SUCCESS:
      return {
        ...state,
        concierges: {
          data: action.data,
          get: modifyGet('success')
        },
      };
    case actions.GET_CONCIERGES.FAILURE:
      return {
        ...state,
        concierges: {
          data: null,
          get: modifyGet('error', action.error)
        },
      };
    case actions.UPDATE_CONCIERGE.REQUEST:
      return {
        ...state,
        concierges: {
          data: state.concierges.data,
          get: modifyGet('request')
        },
      };
    case actions.UPDATE_CONCIERGE.SUCCESS:
      return {
        ...state,
        concierges: {
          data: _.unionBy([action.data], state.concierges.data, "id"),
          get: modifyGet('success')
        },
      };
    case actions.UPDATE_CONCIERGE.FAILURE:
      return {
        ...state,
        concierges: {
          data: state.concierges.data,
          get: modifyGet('error', action.error)
        },
      };
    case actions.GET_PROVIDERS.REQUEST:
      return {
        ...state,
        providers: {
          data: null,
          get: modifyGet('request')
        },
      };
    case actions.GET_PROVIDERS.SUCCESS:
      return {
        ...state,
        providers: {
          data: action.data,
          get: modifyGet('success')
        },
      };
    case actions.GET_PROVIDERS.FAILURE:
      return {
        ...state,
        providers: {
          data: null,
          get: modifyGet('error', action.error)
        },
      };
    case actions.SAVE_PROVIDER.REQUEST:
      return {
        ...state,
        providers: {
          data: state.providers.data,
          get: modifyGet('request')
        },
      };
    case actions.SAVE_PROVIDER.SUCCESS:
      return {
        ...state,
        providers: {
          data: _.orderBy(_.unionBy([action.data], state.providers.data, "id"), ['id'], ['asc']),
          get: modifyGet('success')
        },
      };
    case actions.SAVE_PROVIDER.FAILURE:
      return {
        ...state,
        providers: {
          data: state.providers.data,
          get: modifyGet('error', action.error)
        },
      };
    case actions.DELETE_PROVIDER.REQUEST:
      return {
        ...state,
        providers: {
          data: state.providers.data,
          get: modifyGet('request')
        },
      };
    case actions.DELETE_PROVIDER.SUCCESS:
      return {
        ...state,
        providers: {
          data: state.providers.data.filter((item) => item.id !== action.data),
          get: modifyGet('success')
        },
      };
    case actions.DELETE_PROVIDER.FAILURE:
      return {
        ...state,
        providers: {
          data: state.providers.data,
          get: modifyGet('error', action.error)
        },
      };
    case actions.GET_SERVICES.REQUEST:
      return {
        ...state,
        services: {
          data: null,
          get: modifyGet('request')
        },
      };
    case actions.GET_SERVICES.SUCCESS:
      return {
        ...state,
        services: {
          data: action.data,
          get: modifyGet('success')
        },
      };
    case actions.GET_SERVICES.FAILURE:
      return {
        ...state,
        services: {
          data: null,
          get: modifyGet('error', action.error)
        },
      };
    case actions.GET_SERVICE_ACTIFS.REQUEST:
      return {
        ...state,
        servicesActifs: {
          data: null,
          get: modifyGet('request')
        },
      };
    case actions.GET_SERVICE_ACTIFS.SUCCESS:
      return {
        ...state,
        servicesActifs: {
          data: action.data,
          get: modifyGet('success')
        },
      };
    case actions.GET_SERVICE_ACTIFS.FAILURE:
      return {
        ...state,
        servicesActifs: {
          data: null,
          get: modifyGet('error', action.error)
        },
      };
    case actions.UPDATE_SERVICE_ACTIF.REQUEST:
      return {
        ...state,
        servicesActifs: {
          data: state.servicesActifs.data,
          get: modifyGet('request')
        },
      };
    case actions.UPDATE_SERVICE_ACTIF.SUCCESS:
      return {
        ...state,
        servicesActifs: {
          data: _.unionBy([action.data], state.servicesActifs.data, "id"),
          get: modifyGet('success')
        },
      };
    case actions.UPDATE_SERVICE_ACTIF.FAILURE:
      return {
        ...state,
        servicesActifs: {
          data: state.servicesActifs.data,
          get: modifyGet('error', action.error)
        },
      };
    case actions.GET_TICKET_REQUESTS.REQUEST:
      return {
        ...state,
        ticketRequests: {
          data: null,
          get: modifyGet('request')
        },
      };
    case actions.GET_TICKET_REQUESTS.SUCCESS:
      return {
        ...state,
        ticketRequests: {
          data: action.data,
          get: modifyGet('success')
        },
      };
    case actions.GET_TICKET_REQUESTS.FAILURE:
      return {
        ...state,
        ticketRequests: {
          data: null,
          get: modifyGet('error', action.error)
        },
      };
    case actions.SAVE_TICKET_REQUESTS.REQUEST:
      return {
        ...state,
        ticketRequests: {
          data: state.ticketRequests.data,
          get: modifyGet('request')
        },
      };
    case actions.SAVE_TICKET_REQUESTS.SUCCESS:
      return {
        ...state,
        ticketRequests: {
          data: { ...state.ticketRequests.data, items: _.unionBy([action.data], state.ticketRequests.data.items, "id") },
          get: modifyGet('success')
        },
      };
    case actions.SAVE_TICKET_REQUESTS.FAILURE:
      return {
        ...state,
        ticketRequests: {
          data: state.ticketRequests.data,
          get: modifyGet('error', action.error)
        },
      };
    case actions.SAVE_TICKET_MESSAGE.REQUEST:
      return {
        ...state,
        ticketRequests: {
          data: state.ticketRequests.data,
          get: modifyGet('request')
        },
      };
    case actions.SAVE_TICKET_MESSAGE.SUCCESS:
      const ticketId = action.data.ticket.id;
      const newMessage = { ...action.data, ticket: ticketId };
      const newTicketRequest = _.find(state.ticketRequests.data.items, (ticket) => ticket.id === ticketId);
      newTicketRequest.messages = _.orderBy(_.unionBy([newMessage], newTicketRequest.messages, "id"), ['id'], ['asc']);
      return {
        ...state,
        ticketRequests: {
          data: { ...state.ticketRequests.data, items: _.unionBy([newTicketRequest], state.ticketRequests.data.items, "id") },
          get: modifyGet('success')
        },
      };
    case actions.SAVE_TICKET_MESSAGE.FAILURE:
      return {
        ...state,
        ticketRequests: {
          data: state.ticketRequests.data,
          get: modifyGet('error', action.error)
        },
      };
    case actions.DELETE_TICKET_MESSAGE.REQUEST:
      return {
        ...state,
        ticketRequests: {
          data: state.ticketRequests.data,
          get: modifyGet('request')
        },
      };
    case actions.DELETE_TICKET_MESSAGE.SUCCESS:
      const deletedMessageData = action.data;
      const selectedTicketRequest = _.find(state.ticketRequests.data.items, (ticket) => ticket.id === deletedMessageData.ticketId);
      selectedTicketRequest.messages = selectedTicketRequest.messages.filter((item) => item.id !== deletedMessageData.messageId);
      return {
        ...state,
        ticketRequests: {
          data: { ...state.ticketRequests.data, items: _.unionBy([selectedTicketRequest], state.ticketRequests.data.items, "id") },
          get: modifyGet('success')
        },
      };
    case actions.DELETE_TICKET_MESSAGE.FAILURE:
      return {
        ...state,
        ticketRequests: {
          data: state.ticketRequests.data,
          get: modifyGet('error', action.error)
        },
      };
    case actions.GET_CALENDAR_OBJECTS.REQUEST:
      return {
        ...state,
        calendarObjects: {
          data: null,
          get: modifyGet('request')
        },
      };
    case actions.GET_CALENDAR_OBJECTS.SUCCESS:
      return {
        ...state,
        calendarObjects: {
          data: action.data,
          get: modifyGet('success')
        },
      };
    case actions.GET_CALENDAR_OBJECTS.FAILURE:
      return {
        ...state,
        calendarObjects: {
          data: null,
          get: modifyGet('error', action.error)
        },
      };
    case actions.SAVE_CALENDAR_OBJECT.REQUEST:
      return {
        ...state,
        calendarObjects: {
          data: state.calendarObjects.data,
          get: modifyGet('request')
        },
      };
    case actions.SAVE_CALENDAR_OBJECT.SUCCESS:
      return {
        ...state,
        calendarObjects: {
          data: _.unionBy([action.data], state.calendarObjects.data, "id"),
          get: modifyGet('success')
        },
      };
    case actions.SAVE_CALENDAR_OBJECT.FAILURE:
      return {
        ...state,
        calendarObjects: {
          data: state.calendarObjects.data,
          get: modifyGet('error', action.error)
        },
      };
    case actions.DELETE_CALENDAR_OBJECT.REQUEST:
      return {
        ...state,
        calendarObjects: {
          data: state.calendarObjects.data,
          get: modifyGet('request')
        },
      };
    case actions.DELETE_CALENDAR_OBJECT.SUCCESS:
      return {
        ...state,
        calendarObjects: {
          data: state.calendarObjects.data.filter((item) => item.id !== action.data),
          get: modifyGet('success')
        },
      };
    case actions.DELETE_CALENDAR_OBJECT.FAILURE:
      return {
        ...state,
        calendarObjects: {
          data: state.calendarObjects.data,
          get: modifyGet('error', action.error)
        },
      };

    // Internal form
    case actions.GET_INTERNAL_SERVICE_FORM.REQUEST:
      return {
        ...state,
        internalServiceForm: {
          data: state.internalServiceForm.data,
          get: modifyGet('request')
        },
      };
    case actions.GET_INTERNAL_SERVICE_FORM.SUCCESS:
      return {
        ...state,
        internalServiceForm: {
          data: action.data,
          get: modifyGet('success')
        },
      };
    case actions.GET_INTERNAL_SERVICE_FORM.FAILURE:
      return {
        ...state,
        internalServiceForm: {
          data: state.internalServiceForm.data,
          get: modifyGet('error', action.error)
        },
      };
    case actions.GET_INTERNAL_SERVICE_FORM_SCHEMA.REQUEST:
      return {
        ...state,
        internalServiceFormSchema: {
          data: state.internalServiceFormSchema.data,
          get: modifyGet('request')
        },
      };
    case actions.GET_INTERNAL_SERVICE_FORM_SCHEMA.SUCCESS:
      return {
        ...state,
        internalServiceFormSchema: {
          data: action.data,
          get: modifyGet('success')
        },
      };
    case actions.GET_INTERNAL_SERVICE_FORM_SCHEMA.FAILURE:
      return {
        ...state,
        internalServiceFormSchema: {
          data: state.internalServiceFormSchema.data,
          get: modifyGet('error', action.error)
        },
      };

    default:
      return state;
  }
};

export default concierge;