const  {REACT_APP_API_URL, REACT_APP_WORKSPACE_ENV, REACT_APP_ENV} = process.env;

function getURLApi(){
    let URL = window.location.hostname;
    let company = URL.split(".")[0];
    let protocol = "https://";

    let apiUrl = protocol + company + "." + REACT_APP_API_URL;
    // Develop URLs
    if (REACT_APP_ENV === 'develop') {
        // Preprod URLs
        if (URL && URL.indexOf('preprod') > -1) {
            apiUrl = protocol + company + "."  + REACT_APP_API_URL;
        }
        // AWS Amplify URLs
        let subdomainException = ["amplifyapp", "dev.cyconia.io"];
        let subdomainExceptionChecking = subdomainException.some(el => URL.includes(el));
        if (URL && subdomainExceptionChecking) {
            company = REACT_APP_WORKSPACE_ENV;
            apiUrl = protocol + company + '.' + REACT_APP_API_URL;
        }
        // Local URLs (dev)
        if (URL && URL.indexOf("local.cyconia.io") > -1) {
            apiUrl = protocol + company + ".admin.local.cyconia.io/app_dev.php/api/v1/";
        }
        // Localhost Url (dev local) or Local Network
        if (URL && (URL.indexOf("localhost") > -1 || URL.indexOf("192") > -1)) {
            company = REACT_APP_WORKSPACE_ENV;
            apiUrl = protocol + company + "." + REACT_APP_API_URL;
        }
    }
    return apiUrl;
} 

export {
    getURLApi
}

