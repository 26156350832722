import React from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";

export const Container = styled.div``;

export const ArrowDownIconWrapper = styled.span`
  display: flex;
  height: 5px;
  width: 9px;
`;

export const ClassicButton = styled(
  ({ backgroundColor, backgroundHover, textColor, textHover, ...rest }) => (
    <Button {...rest} />
  )
)`
  &.MuiButton-root {
    min-width: 60px;
    width: 100%;
    max-width: 310px;
    padding: 0;
    margin: 0 0 10px 0;
    border: 0;
    border-radius: 15px;
    box-shadow: none;
    /* background-color: #2d2829; */
    background-color: ${props => props.backgroundColor};
    transition: all 0.2s ease-out;
    &:hover {
      z-index: 99;
      opacity: 1;
      background-color: ${props => props.backgroundHover};
      box-shadow: 0 10px 40px 0 rgba(35, 31, 32, 0.2);
    }
    &:disabled {
      opacity: 0.3;
    }
    .MuiButton-label {
      /*text-align: center;*/
      justify-content: flex-start;
      /* color: #ffffff; */
      color: ${props => props.textColor};
      padding: 15px 25px;
      font-size: 12px;
      font-weight: 500;
      text-transform: initial;
      letter-spacing: 0;
      line-height: 15px;
      transition: all 0.2s ease-out;
      &:hover {
        color: ${props => props.textHover};
      }
      .MuiButton-startIcon {
        width: 10px;
        height: auto;
        margin-right: 20px;
      }
      .MuiButton-endIcon {
        width: 10px;
        height: auto;
      }
    }
  }
`;
