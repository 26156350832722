import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import _ from "lodash";
import {
  shallowEqual,
  useDispatch,
  useSelector,
} from "react-redux";
//Import Formik
import { Formik, Form, Field, useFormikContext } from 'formik';
import * as Yup from "yup";

import { jsonSafeParse } from '../../../../../../helpers/jsonSafeParse';

//Import Component
import GenericAdminFrontHeader from '../../../../../../components/molecules/GenericAdminFrontHeader';
import GenericTextField from '../../../../../../components/molecules/GenericTextField';
import GenericMaterialMenu from "../../../../../../components/molecules/GenericMaterialMenu";
// import GenericCheckBoxes from '../../../../../../components/molecules/GenericCheckBoxes';
import GenericWarningModal from '../../../../../../components/molecules/GenericWarningModal';
import GenericTitleWithToolTip from '../../../../../../components/molecules/GenericTitleWithToolTip';
import GenericDrawerValidationButtons from '../../../../../../components/molecules/GenericDrawerValidationButtons';
import GenericMultipleSelect from '../../../../../../components/molecules/GenericMultipleSelect';
import GenericLoader from '../../../../../../components/molecules/GenericLoader';
import ResponseBuilder from '../../../../../../components/organisms/ResponseBuilder';

import {
  getElementInformationByCompanyData,
  getSpecificCaseData,
  getSpecificCaseIsLoading
} from '../../../../../../store/adminFront/selectors';

import { editSpecificCase } from '../../../../../../store/adminFront/actions';

import {
  Container,
  DrawerCptCustom,
  Wrapper,
  DrawerWrapper,
  FormContainer
} from './style';

// const testChoices = [
//   { label: 'site1', value: 'site1' },
//   { label: 'site2', value: 'site2' },
//   { label: 'site3', value: 'site3' }
// ]

const EditDrawer = ({ visible, closeFunction, edit }) => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const [isDirty, setIsDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedCase, setSelectedCase] = useState(null);
  const [siteChoices, setSiteChoices] = useState(null);
  const [selectedSpecificCaseId, setSelectedSpecificCaseId] = useState(id);
  const [updatedSpecificCaseList, setUpdatedSpecificCaseList] = useState(null);
  const [listRef, setListRef] = useState(null);

  const {
    specificCaseData,
    companySitesData,
    caseSpecificIsLoading
  } = useSelector(
    state => ({
      specificCaseData: getSpecificCaseData(state),
      companySitesData: getElementInformationByCompanyData(state),
      caseSpecificIsLoading: getSpecificCaseIsLoading(state)
    }),
    shallowEqual
  );

  useEffect(() => {
    if (listRef && specificCaseData) {
      if (!_.isEqual(listRef, specificCaseData?.items)) closeFunction();
    }
    setListRef(specificCaseData?.items);
  }, [specificCaseData])

  useEffect(() => {
    if (specificCaseData && selectedSpecificCaseId) {
      const currentCase = _.find(specificCaseData?.items, (data) => data.id == selectedSpecificCaseId);
      setSelectedCase(currentCase);
    }
  }, [specificCaseData, selectedSpecificCaseId]);

  useEffect(() => {
    if (specificCaseData) {
      const newList = _.compact(_.map(specificCaseData?.items, (item, key) => {
        return { label: `Cas spécifiques numéro : ${item.id}`, value: item.id }
      }))
      setUpdatedSpecificCaseList([
        {
          label: `${newList.length > 0 ? 'Appliquer une fiche existante de cas spécifiques?' : 'Pas de fiche de cas spécifiques existante pour le moment !'}`,
          value: null
        },
        ...newList
      ]);
    }
  }, [specificCaseData])

  useEffect(() => {
    if (companySitesData) {
      const newChoices = _.compact(_.map(companySitesData, (site) => {
        if (site.id) {
          return { label: site.name, value: site.id }
        }
      }))
      setSiteChoices(newChoices);
    }
  }, [companySitesData])

  useEffect(() => {
    if (isLoading && siteChoices) {
      setIsLoading(false);
    }
  }, [caseSpecificIsLoading, siteChoices])

  const handleClose = () => {
    if (isDirty) setOpenDelete(true)
    else closeFunction()
  }

  const generateSiteValue = (data) => {
    return _.map(data, (item) => {
      const site = companySitesData.find((value) => value.id == item.id);
      return { label: site.name, value: site.id };
    })
  }

  const getFormInitvalue = () => {
    let messageElements = [];
    if (selectedCase) {
      try {
        const reply = jsonSafeParse(selectedCase.reply);
        messageElements = reply[0].messageElements;
      } catch (e) {
        console.warn(e);
      }
    }

    return {
      dfAction: selectedCase?.df_action || "",
      messageElements: messageElements,
      sites: selectedCase ? generateSiteValue(selectedCase?.company_sites) : [],
    }
  };

  // const handleCheckBox = (event, props) => {
  //   let newValue = props.values.sites;
  //   newValue = event.target.checked ? [...newValue, parseInt(event.target.value)] : newValue.filter(site => site !== parseInt(event.target.value));
  //   props.setFieldValue('sites', newValue);
  // }

  const handleSubmit = (values) => {
    const newSites = _.map(values.sites, (site) => site.value);
    const formatedReply = {
      version: "1",
      0: {
        version: '1',
        messageElements: values.messageElements
      }
    }
    const formatedData = {
      dfAction: values.dfAction,
      reply: JSON.stringify(formatedReply),
      companySites: JSON.stringify(newSites),
    }
    dispatch(editSpecificCase({ id: id || 'new', formData: formatedData }))
  }

  const CheckDirty = () => {
    const { dirty } = useFormikContext();

    useEffect(() => {
      setIsDirty(dirty)
    }, [dirty]);
    return null;
  };

  return (
    <Container>
      <DrawerCptCustom
        onOpen={visible}
        visible={visible}
        closeFunction={handleClose}
        className="contentManagementDrawer"
      >
        <DrawerWrapper>
          <Formik
            initialValues={getFormInitvalue()}
            enableReinitialize
            setFieldValue
            // validationSchema={newContentActuSchema}
            onSubmit={handleSubmit}
          >
            {(props) => {
              return (
                <Wrapper>
                  <GenericAdminFrontHeader headerTitle={`${edit ? 'Editer' : 'Ajouter'} un cas spécifique`} />
                  {(isLoading || caseSpecificIsLoading) &&
                    <GenericLoader />
                  }
                  {!isLoading && !caseSpecificIsLoading &&
                    <Form>
                      <CheckDirty />
                      <FormContainer>
                        <GenericMaterialMenu
                          list={updatedSpecificCaseList}
                          onChange={(value) => setSelectedSpecificCaseId(value)}
                        />
                        <Field
                          id="dfAction"
                          name="dfAction"
                          title="Action dialogflow"
                          variant="outlined"
                          size="small"
                          // isRequired={true}
                          as={GenericTextField} />
                        <div>
                          <GenericTitleWithToolTip
                            title="Réponse spécifique"
                          />
                          <ResponseBuilder
                            messageElements={props.values.messageElements}
                            siteId={null}
                            parentIndex={0}
                            saveResponse={(index, newMessageElements) => props.setFieldValue('messageElements', newMessageElements)}
                          />
                        </div>
                        <GenericMultipleSelect
                          list={siteChoices}
                          fildName="sites"
                          id="sites"
                          name="sites"
                          title="Site d'entreprises"
                          limitItem={5}
                          hasSelectAll={true}
                          value={props.values.sites}
                          onChange={(e, value) => { return props.setFieldValue('sites', value) }}
                          toolTipText={`(Non obligatoire, si aucun site n'est sélectionné, le cas spécifique s'appliquera à l'entreprise au global)`}
                        />
                        {/* <GenericCheckBoxes
                        title="Site d'entreprises"
                        toolTipText={`(Non obligatoire, si aucun site n'est sélectionné, le cas spécifique s'appliquera à l'entreprise au global)`}
                        value={props.values.sites}
                        choices={siteChoices}
                        onChange={(event) => handleCheckBox(event, props)}
                      /> */}
                        <GenericDrawerValidationButtons
                          leftButonText={'Annuler'}
                          RightButtonText={'Sauvegarder'}
                          onclickLefttButton={handleClose}
                          submitable={true}
                        />
                      </FormContainer>
                    </Form>
                  }
                </Wrapper>
              )
            }}
          </Formik>
        </DrawerWrapper>
        <GenericWarningModal
          open={openDelete}
          setOpenDialog={() => setOpenDelete(!openDelete)}
          title={"Attention"}
          text={"Êtes-vous sur de vouloir annuler votre saisie?"}
          leftButtonText={"Non"}
          rightButtonText={"Oui"}
          leftButtonFunction={() => setOpenDelete(!openDelete)}
          rightButtonFunction={closeFunction}
        />
      </DrawerCptCustom>
    </Container>
  )
}

export default EditDrawer