import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import _ from 'lodash';

// FOR BOTMAN
import declineBotReplyProposal from './services/bot_declined_reply';
import customSmalltalkIntentService from './services/customSmalltalkIntentService.js';

import UserInput from './components/UserInput';
import MessageList from './components/MessageList';
import FeedbackModal from './components/FeedbackModal';
// import LinkModal from './components/LinkModal';
// import NegativeFeedbackModal from './components/Modals/Negative-feedback.model'; // [WIP] to convert to functional component
import LinkModal from '../../components/organisms/LinkModal';
import NegativeFeedbackModal from '../../components/organisms/ChabotInternalForm';

import {
  PAYLOAD_VERSION,
  MESSAGE_ELEMENT_TYPE,
  FEEDBACK,
  BOT_MESSAGE_LOADING_BUBBLE,
  BOT_SERVICE_TYPE,
  // LOADING_ANIMATION,
  // getTestData,
  // getRelatedSubjectRespsonseApi,
  // getRelatedSubjectsApi
} from './constants/chatBotConstants';

import {
  getUser
} from '../../store/collaborator/selectors';

import {
  getUserToken
} from '../../store/authentication/selectors';

import {
  getBotResponse,
  getBotForm,
  getNestedButtonResponse,
  setMessageFromSideBar,
  saveBotFinishIntro,
  savePositiveFeedback,
  saveNegativeFeedback,
  saveFeedback,
  cleanChatbotMessage
} from '../../store/chatbot/actions';

import {
  getChatBotMessagesData,
  isChatBotMessagesLoading,
  getChatBotFormSchemaData,
  isChatBotFormSchemaLoading,
  getChatBotMessageFromSideBarData,
  getChatBotFeedbackStatusData,
} from '../../store/chatbot/selectors';

import {
  setSelectedParole,
} from '../../store/sidebar/actions';

import {
  StyledMainContainer,
} from './style';

const ChatBot = () => {
  const dispatch = useDispatch();
  const [messageList, setMessageList] = useState([]);
  const [messageQueue, setMessageQueue] = useState([]);
  const [welcomeWhisperDone, setWelcomeWhisperDone] = useState(false); // TODO BEN & JESSY, this is temporary fix, because the chatbot depends on userInfo to be available, if not it will not be able to display welcome message
  const [isPrintingMessage, setIsPrintingMessage] = useState(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [linkModalData, setLinkModalData] = useState(null);
  // const [articleIframe, setArticleIframe] = useState('');
  const [isAllowedToEditResponse, setIsAllowedToEditResponse] = useState(false);
  const [modalServiceRequestSchema, setModalServiceRequestSchema] = useState({});
  const [defaultValuesForm, setDefaultValuesForm] = useState({});
  const [requestSent, setRequestSent] = useState(false);
  const [feedbackStatus, setFeedbackStatus] = useState(null);
  const [inputMessage, setInputMessage] = useState(null);
  const [apiAction, setApiAction] = useState('');

  const {
    chatBotMessagesData,
    isChatBotMessagesOnLoading,
    formSchemaData,
    isFormSchemaLoading,
    messageFromSideBar,
    botFeedbackStatus,
    userInfo,
    userToken
  } = useSelector(
    state => ({
      chatBotMessagesData: getChatBotMessagesData(state),
      isChatBotMessagesOnLoading: isChatBotMessagesLoading(state),
      formSchemaData: getChatBotFormSchemaData(state),
      isFormSchemaLoading: isChatBotFormSchemaLoading(state),
      messageFromSideBar: getChatBotMessageFromSideBarData(state),
      botFeedbackStatus: getChatBotFeedbackStatusData(state),
      userInfo: getUser(state),
      userToken: getUserToken(state)
    }),
    shallowEqual);

  // The order of the messageList vs chatBotMessagesData might not be the same, but both have the same data
  // Do sort messageList as that will affect the smooth flow of the message displaying
  // messageQueue and isPrintingMessage is use to ensure smoothness in displaying message 

  useEffect(() => {
    if (chatBotMessagesData && !isPrintingMessage) {
      const cleanMessagesList = _.filter(messageList, (message) => !message.isLoading);
      if (isChatBotMessagesOnLoading) {
        setMessageList([...cleanMessagesList, BOT_MESSAGE_LOADING_BUBBLE]);
      }
      else {
        const newMessages = _.xorBy(cleanMessagesList, chatBotMessagesData, 'id'); // use _.xorBy to get the different from messageList vs chatBotMessagesData to get the latest message base on the ID
        setMessageQueue(newMessages); // Add newMessages into messageQueue, and the useEffect below will handle the displaying of the message
      }
    }
  }, [isChatBotMessagesOnLoading, chatBotMessagesData, isPrintingMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!_.isEmpty(messageQueue)) {
      setIsPrintingMessage(true);
      let delayCounter = 500;
      const loadingBubbleExist = _.findIndex(messageList, (message) => message.isLoading);
      _.map(messageQueue, (message, index) => {
        if (index !== 0 || loadingBubbleExist === -1) {
          setTimeout(() => {
            setMessageList((prev) => [...prev, BOT_MESSAGE_LOADING_BUBBLE]);
          }, [delayCounter += 500]);
        }
        setTimeout(() => {
          setMessageList((prev) => {
            const cleanMessagesList = _.filter(prev, (message) => !message.isLoading); // remove previous loading bubble and put in the new message,
            return [...cleanMessagesList, message];
          });
          if (index === messageQueue.length - 1) { // After printing last message in the queue, clean the message Queue and setIsPrintingMessage as false, so the component will check for new bot messages
            setMessageQueue([]);
            setIsPrintingMessage(false);
          }
        }, [delayCounter += 500]);
      })
    };
  }, [messageQueue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!welcomeWhisperDone) {
      if (userInfo) {
        let objKeys;
        let isAllowed = false;

        if (userInfo && userInfo.permissions)
          objKeys = Object.keys(userInfo.permissions);
        if (objKeys && objKeys.length > 0) {
          if (objKeys.includes("Modifier une réponse"))
            isAllowed = true;
        }

        setIsAllowedToEditResponse(isAllowed);
        if (userInfo.intro_js_status) {
          botWhisper("cyconia.news.deals.welcome")
        } else {
          botWhisper("cyconia.welcome.1");
          setTimeout(() => {
            botWhisper("cyconia.welcome.2");
          }, 500);
          setTimeout(() => {
            botWhisper("cyconia.welcome.3");
          }, 1000)
        }
        setWelcomeWhisperDone(true);
      }
    }
  }, [userInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (formSchemaData) {
      setModalServiceRequestSchema(formSchemaData);
    }
  }, [formSchemaData]);

  useEffect(() => {
    return () => {
      setMessageList([]);
      dispatch(cleanChatbotMessage()); // To clean chatBot message in store, so the message will not be archieve when the chatbot is unmounted
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (feedbackStatus && feedbackStatus.index !== null) {
      const newMessages = _.cloneDeep(messageList);
      const selectedMessage = newMessages[feedbackStatus.index];
      selectedMessage.feedbackStatus = {
        positiveStatus: feedbackStatus.positiveStatus,
        negativeStatus: feedbackStatus.negativeStatus,
      }
      setMessageList(newMessages);
    }
  }, [feedbackStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setInputMessage(messageFromSideBar);
    if (messageFromSideBar) {
      dispatch(setMessageFromSideBar(null));
    }
  }, [messageFromSideBar]);  // eslint-disable-line react-hooks/exhaustive-deps

  // console.log('messageList', messageList);
  // console.log('messageQueue', messageQueue);

  const botWhisper = async (wisperMsg) => {
    dispatch(getBotResponse(null, wisperMsg, BOT_SERVICE_TYPE.WHISPER));
  }

  const onSend = async (userInput) => {
    const messageId = chatBotMessagesData.length;
    const userMessage = {
      id: messageId,
      isReceived: false,
      version: PAYLOAD_VERSION,
      messageElements: [{
        type: MESSAGE_ELEMENT_TYPE.TEXT,
        content: userInput
      }]
    };
    setMessageList((prev) => [...prev, userMessage]);
    dispatch(getBotResponse(userMessage, userInput, BOT_SERVICE_TYPE.RESPONSE));
  };

  const onMessageButtonClick = async (message, element, elementIndex, messageIndex) => {
    if (element.type === MESSAGE_ELEMENT_TYPE.BUTTON_SIMPLE) {
      const messageId = chatBotMessagesData.length;
      const userMessage = {
        id: messageId,
        isReceived: false,
        version: PAYLOAD_VERSION,
        messageElements: [{
          type: MESSAGE_ELEMENT_TYPE.TEXT,
          content: element.userMessage
        }]
      };
      const nestedResponse = {
        status: 200,
        messages: [
          {
            ...message,
            id: messageId + 1,
            messageElements: element.messageElements,
            isReceived: true
          }
        ]
      }
      setMessageList((prev) => [...prev, userMessage]);
      dispatch(getNestedButtonResponse(userMessage, nestedResponse));
    }
    else if (element.type === MESSAGE_ELEMENT_TYPE.BUTTON_INTENT) {
      onSend(element.userMessage);
    }
    else if (element.type === MESSAGE_ELEMENT_TYPE.BUTTON_FORM) {

      if (!element.transmitData) // to be change to transmit data
        return;
      if (element.transmitData.type === 'form') {
        const formRequestData = {
          url: element.transmitData.url,
          formType: element.transmitData.form,
          defaultValues: element.transmitData.defaultValues,
        }
        // element.transmitData.defaultValues.ticket = !!element.transmitData.ticket;
        // console.log('formRequestData', formRequestData);
        // console.log('im here cb', element);
        dispatch(getBotForm(formRequestData, ''));
        openFormModal(element.transmitData.defaultValues, '');
      }
      else if (element.transmitData.type === 'feedback') {
        setIsFeedbackModalOpen(true);
      }
    }
    else if (element.type === MESSAGE_ELEMENT_TYPE.BUTTON_LINK) {
      if (element.target === '_blank') {
        if (element.url !== 'https://') window.open(element.url, '_blank');
      }
      else {
        setLinkModalData({
          title: element.title,
          source: element.url,
          target: element.target
        });
      }
    }
    else if (element.type === MESSAGE_ELEMENT_TYPE.BUTTON_SHOW_RELATED) {
      dispatch(getBotResponse(null, 'messageToserver', 'show_related_subjects'));
      // insertLoadingMessage();
      // const response = await getRelatedSubjectsApi(element.transmitData);
      // insertBotMessages(response);
    }
    else if (element.type === MESSAGE_ELEMENT_TYPE.BUTTON_FUNCTION) {
      if (element.transmitData.type === 'rgpd') { // Hate this part, so much code and extra props needed just for something that will happen only once
        dispatch(saveBotFinishIntro());
        const newMessageElement = _.cloneDeep(element);
        newMessageElement.title = 'Vos préférences ont été sauvegardées';
        newMessageElement.disabled = true;
        const newMessages = _.cloneDeep(messageList);
        newMessages[messageIndex].messageElements[elementIndex] = newMessageElement;
        setMessageList(newMessages);
      }
      else if (element.transmitData.type === 'relatedSubject') {
        // const data = {
        //   companyResponseElement: element.transmitData.companyResponseElement,
        //   informationElementId: element.transmitData.informationElementId,
        // }
        // insertUserMessage(element.userMessage);
        // const response = await getRelatedSubjectRespsonseApi(data);
        // insertBotMessages(response);
      }
      else if (element.transmitData.type === 'news' || element.transmitData.type === 'deal') { // This will need to wait the sidebar parole cyconia to complete to update the function
        // const response = await botGetNewsService(element.transmitData.payload);
        // setArticleIframe(response);
        dispatch(setSelectedParole(element.transmitData.payload));
      }
    }
    // FOR BOTMAN
    else if (element.type === 'customSmalltalkTextType') {
      const { userReply1, userReply2, botReply, jsonType } = element;
      const messageId = chatBotMessagesData.length;
      const userMessage = {
        id: messageId,
        isReceived: false,
        version: PAYLOAD_VERSION,
        messageElements: [{
          type: MESSAGE_ELEMENT_TYPE.TEXT,
          content: userReply1
        }]
      };

      setMessageList((prev) => [...prev, userMessage]);
      let response = await declineBotReplyProposal(userReply2, botReply, jsonType);

      let resFormat = "";
      if (response.messages[0].text[0] === '"')
        resFormat = response.messages[0].text.replace(/"/, "");
      else resFormat = response.messages[0].text;

      if (resFormat[resFormat.length - 1] === '"')
        resFormat = resFormat.slice(0, -1);
      resFormat = resFormat.replace(/\\"/g, "'");
      resFormat = resFormat.replace(/\\'/g, "'");
      resFormat = resFormat.replace(/€/g, "&euro;");

      const formatedResponse = {
        ...response,
      }
      formatedResponse.messages[0].text = resFormat;
      const nestedResponse = formatedResponse;

      dispatch(getNestedButtonResponse(userMessage, nestedResponse));
    }
    else if (element.type === 'customSmalltalkIntentType') {
      const { title, userReply } = element;
      const messageId = chatBotMessagesData.length;
      const userMessage = {
        id: messageId,
        isReceived: false,
        version: PAYLOAD_VERSION,
        messageElements: [{
          type: MESSAGE_ELEMENT_TYPE.TEXT,
          content: userReply
        }]
      };

      setMessageList((prev) => [...prev, userMessage]);
      let response = await customSmalltalkIntentService(title, userReply);
      const formatedResponse = {
        ...response,
      }
      // console.log('nestedResponse formatedResponse', formatedResponse);
      if (formatedResponse.messages[0].text) { // THIS IS OLD PAYLOAD, Require some text sanitizing
        if (response.messages.length > 0) {
          response.messages.map(msg => {
            if (msg.text.indexOf("button") === -1) {
              formatedResponse.messages[0].text.replace(/"/g, "");
            }
          })
        }
      }
      else { // THIS IS NEW PAYLOAD, but it is in JSON format, need to parse it
        formatedResponse.messages[0] = JSON.parse(formatedResponse.messages[0]);
      }
      const nestedResponse = formatedResponse;

      dispatch(getNestedButtonResponse(userMessage, nestedResponse));
    }
  };

  const onFeedbackClick = async (message, type, messageIndex) => {
    const selectedMessage = _.cloneDeep(message);
    if (type === FEEDBACK.POSITIVE) {
      dispatch(savePositiveFeedback(message.defaultValues));
      setFeedbackStatus({ ...selectedMessage.feedbackStatus, index: messageIndex, positiveStatus: 201 });
    }
    else {
      const formRequestData = {
        defaultValues: message.defaultValues,
      }
      dispatch(getBotForm(formRequestData, FEEDBACK.NEGATIVE));
      openFormModal(message.defaultValues, FEEDBACK.NEGATIVE);
      setFeedbackStatus({ ...selectedMessage.feedbackStatus, index: messageIndex });
    }
  }

  const handleNegativeFeedback = async (feedbackData) => { // To be redo when the component convert to functional
    // console.log('feedbackData', feedbackData);
    // dispatch(saveNegativeFeedback(feedbackData));
  }

  const handleFeedbackModalSubmit = async (feedbackData) => {
    dispatch(saveFeedback(feedbackData));
  }

  const openFormModal = (defaultValue, action) => {
    setModalServiceRequestSchema({});
    setDefaultValuesForm(defaultValue);
    setIsFormModalOpen(true);
    setRequestSent(false);
    setApiAction(action)
  }

  const closeModalServiceRequest = (func = null) => {
    setIsFormModalOpen(false);
    window.location.hash = "";
  }

  const _setRequestSent = () => {
    setRequestSent(true);
  }

  // function for botmanComponent //

  const onBotmanFeedbackClick = async (data, messageIndex) => {
    console.log('onBotmanFeedbackClick', data, messageIndex);
    const selectedMessage = _.cloneDeep(messageList[messageIndex]);
    if (data.type === FEEDBACK.POSITIVE) {
      dispatch(savePositiveFeedback(data.payload.defaultValues));
      setFeedbackStatus({ ...selectedMessage.feedbackStatus, index: messageIndex, positiveStatus: 201 });
    }
    else {
      const formRequestData = {
        defaultValues: data.payload.defaultValues,
      }
      dispatch(getBotForm(formRequestData, FEEDBACK.NEGATIVE));
      openFormModal(data.payload.defaultValues, FEEDBACK.NEGATIVE);
      setFeedbackStatus({ ...selectedMessage.feedbackStatus, index: messageIndex });
    }
  }

  return (
    <StyledMainContainer>
      <MessageList
        messageList={messageList}
        isAllowedToEditResponse={isAllowedToEditResponse}
        onMessageButtonClick={onMessageButtonClick}
        onFeedbackClick={onFeedbackClick}
        // props for Botman
        onBotmanFeedbackClick={onBotmanFeedbackClick}
      />
      <UserInput onSend={onSend} inputMessage={inputMessage} />
      {isFormModalOpen &&
        <NegativeFeedbackModal
          userInfo={userInfo}
          userToken={userToken}
          isModalOpen={true}
          modalServiceRequestSchema={modalServiceRequestSchema}
          defaultValuesForm={defaultValuesForm}
          setFeedbackStatus={setFeedbackStatus}
          isModalServiceRequestOpen={isFormModalOpen}
          isLoading={isFormSchemaLoading}
          requestSent={requestSent}
          _setRequestSent={_setRequestSent}
          apiAction={apiAction}
          _closeModalServiceRequest={closeModalServiceRequest}
          botFeedbackStatus={botFeedbackStatus}
        // handleNegativeFeedback={handleNegativeFeedback}
        />
      }
      {isFeedbackModalOpen &&
        <FeedbackModal
          isFeedbackModalOpen={isFeedbackModalOpen}
          setIsFeedbackModalOpen={setIsFeedbackModalOpen}
          handleFeedbackModalSubmit={handleFeedbackModalSubmit}
        />
      }
      {linkModalData &&
        <LinkModal
          onClose={() => setLinkModalData(null)}
          linkModalData={linkModalData}
        />
      }
    </StyledMainContainer>
  )
};

export default ChatBot;