import styled from "styled-components";
import ButtonCpt from '../../molecules/buttonCpt';
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";



export const WashIconWrapper = styled.div`
  &&{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 21px;
    min-width: 21px;
    height: 23px;
    min-height: 23px;
    margin: 4px 0 0 -4px;
  }
`;

export const BorneIconWrapper = styled.div`
  &&{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 17px;
    min-width: 17px;
    height: 25px;
    min-height: 25px;
    margin: 4px 0 0 -1px;
  }
`;

export const WifiIconWrapper = styled.div`
  &&{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    min-width: 20px;
    height: 24px;
    min-height: 24px;
    margin: 5px 0 0 0;
  }
`;

export const AllIconWrapper = styled.div`
  &&{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 17px;
    min-width: 17px;
    height: 17px;
    min-height: 17px;
    margin: 4px 0 0 0;
  }
`;

export const Item = styled.li`
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 10px 0 20px 0;
  span{
    font-size: 14px;
    color: #5A5A5A;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    margin-left: 20px;
  }
  &.wifiItem{
    span{
      margin-left: 17px;
    }
  }
`;

export const List = styled.ul`
  padding: 0;
  margin: 0;
  width: 100%;
`;

// export const Subtitle = styled.h3`
//   font-size: 14px;
//   font-weight: 600;
//   color: #5A5A5A;
//   letter-spacing: 0;
//   line-height: 9px;
// `;

export const Title = styled.h2`
  font-size: 18px;
  font-weight: 600;
  color: #357ED3;
  letter-spacing: 0;
  line-height: 20px;
  margin: 15px 0 25px 0;
`;

export const DrawerBorneInfoContent = styled.div`
  padding: 10px 25px 15px 25px;
  box-sizing: border-box;
`;

export const CloseIconWrapper = styled.div`
  &&{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 14px;
    height: 14px;
  }
`;

export const ButtonCptClose = styled(ButtonCpt)`
  &&{
    position: absolute;
    z-index: 99;
    top: 10px;
    right: 10px;
    width: 40px;
    min-width: 40px;
    height: 40px;
    min-height: 40px;
    border-radius: 8px;
    background-color: rgba(0,0,0,0.2);
  }
`;

export const PictureBox = styled.div`
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
`;

export const DrawerBorneInfoPicture = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #357ED3;
  width: 100%;
  height: 28%;
  min-height: 240px;
`;

export const SwipeableDrawerCustom = styled(SwipeableDrawer)`
  &&{
    &.MuiDrawer-root{
      .MuiBackdrop-root{

      }
      .MuiDrawer-paper{
        max-width: calc(100% - 50px);
        height: calc(100% - 50px);
        margin: 25px;
        border-radius: 16px;
      }
    }
  }
`;
