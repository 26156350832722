import React from "react";

const AllIcon = ({
  color = "#5A5A5A",
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon allIcon"
      width={width}
      height={height}
      viewBox="0 0 19 19"
      fill="none"
    >
      <path
        d="M6 10.4a2.6 2.6 0 012.595 2.436L8.6 13v3a2.6 2.6 0 01-2.436 2.595L6 18.6H3a2.6 2.6 0 01-2.595-2.436L.4 16v-3a2.6 2.6 0 012.436-2.595L3 10.4h3zm10 0a2.6 2.6 0 012.595 2.436L18.6 13v3a2.6 2.6 0 01-2.436 2.595L16 18.6h-3a2.6 2.6 0 01-2.595-2.436L10.4 16v-3a2.6 2.6 0 012.436-2.595L13 10.4h3zM6 12H3a1 1 0 00-.993.883L2 13v3a1 1 0 00.883.993L3 17h3l.117-.007a1 1 0 00.876-.876L7 16v-3a1 1 0 00-.883-.993L6 12zm10 0h-3a1 1 0 00-.993.883L12 13v3a1 1 0 00.883.993L13 17h3l.117-.007a1 1 0 00.876-.876L17 16v-3a1 1 0 00-.883-.993L16 12zM6 .4a2.6 2.6 0 012.595 2.436L8.6 3v3a2.6 2.6 0 01-2.436 2.595L6 8.6H3A2.6 2.6 0 01.405 6.164L.4 6V3A2.6 2.6 0 012.836.405L3 .4h3zm10 0a2.6 2.6 0 012.595 2.436L18.6 3v3a2.6 2.6 0 01-2.436 2.595L16 8.6h-3a2.6 2.6 0 01-2.595-2.436L10.4 6V3A2.6 2.6 0 0112.836.405L13 .4h3zM6 2H3a1 1 0 00-.993.883L2 3v3a1 1 0 00.883.993L3 7h3l.117-.007a1 1 0 00.876-.876L7 6V3a1 1 0 00-.883-.993L6 2zm10 0h-3a1 1 0 00-.993.883L12 3v3a1 1 0 00.883.993L13 7h3l.117-.007a1 1 0 00.876-.876L17 6V3a1 1 0 00-.883-.993L16 2z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export default AllIcon;
