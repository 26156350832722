import styled, { css } from "styled-components";
import DomainIcon from '@material-ui/icons/Domain';
import SelectSearch from 'react-select-search';
import ButtonCpt from '../../../../../../components/molecules/buttonCpt';
import PopoverCpt from '../../../../../../components/molecules/popoverCpt'
import Tooltip from '@material-ui/core/Tooltip';

export const TooltipCustom = styled(Tooltip)`
left: 6px;
  &.MuiTooltip-popper{
    left: 6px;
    .MuiTooltip-tooltip{

    }
  }
`;

// Icons style
export const ButtonCptCustom = styled(ButtonCpt)`
    &&{
      width: 210px;
      height: 46px;
      border-radius: 10px;
      margin-left: 10px;
      .MuiButton-label{
        span{
          span{
            line-height: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            -webkit-transition: all .2s ease-out;
          }
        }
      }
    }
`;

export const PopoverCptCustom = styled(PopoverCpt)`
    &&{
      &.popoverCpt{
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
`;

export const StyledDomainIcon = styled(DomainIcon)`
    font-weight: 400;
    margin-right: 10px;
    width: 12px
`;

export const HeaderLeftMenu = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-start;
`;


export const StyledButtonSelect = styled.div`
    background-image: linear-gradient(-45deg, #2964A8 0%, #194E8B 100%);
    padding: 0px 15px;
    font-size: 12px;
    min-width: 200px;
    display: flex;
    cursor: pointer;
    font-weight: 600;
    color: #FFFFFF;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    span{
      line-height: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      -webkit-transition: all .2s ease-out;
    }
`;

export const HeaderRightButtons = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
`;


export const CompanyIconWrapper = styled.div`
  transition: all .2s ease-out;
  width: 12px;
  margin-right: 10px;
`;

export const CheckIconWrapper = styled.div`
  transition: all .2s ease-out;
  width: 12px;
`;

export const SitesLstBoxLiText = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const SitesLstBoxLi = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${props => props.isActive ? '#357ED3' : '#5A5A5A'};
    font-weight: 500;
    cursor: pointer;
    font-size: 12px;
    padding: 0;
    height: 40px;
    border-bottom: 1px solid #F9F9F9;
    transition: all .2s ease-out;
    border-radius: 6px;
    &:last-child{
      border-bottom: 0;
    }
    ${CompanyIconWrapper}{
      svg{
        transition: all .2s ease-out;
      }
    }
    &:hover {
      background-color: #F2F6FF;
      color: #357ED3;
      padding-left: 12px;
      ${CheckIconWrapper}{
        margin-right: 12px;
      }
      ${CompanyIconWrapper}{
        svg path{
          fill: #357ED3;
        }
      }
    }
`;

export const SitesLstBoxUl = styled.div`
    display: flex;
    flex-direction: column;
`;

export const SitesLstBoxHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 10px;
    margin-bottom: 0px;
    border-bottom: 1px solid #F9F9F9;
    span{
      color: #BBBBBB;
      font-weight: 500;
      font-size: 11px;
    }
    p{
      margin: 0;
      color: #BBBBBB;
      font-weight: 500;
      font-size: 11px;
    }
`;

export const SitesLstBox = styled.div`
    height: auto;
    max-height: 320px;
    overflow: hidden;
    overflow-y: auto;
    position: absolute;
    min-width: 320px;
    top: 55px;
    border-radius: 10px;
    width: 100%;
    padding: 16px 16px;
    z-index: 111;
    background-color: #FFFFFF;
    filter: drop-shadow(1px 2px 4px gray);
`;

export const HeaderContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 999;
  justify-content: space-between;
  border: 1px solid #EFEFEF;
  display: flex;
  padding: 20px 25px;
  background-color: #ffffff;
`;

export const StyledSelectSearch = styled(SelectSearch)`
  .select-search{
    width: 210px;
    height: 46px;
    border-radius: 10px;
    margin-left: 10px;
    label{
      span{
        span{
          line-height: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          -webkit-transition: all .2s ease-out;
        }
      }
    }
  }
`;
