import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useRouteMatch } from "react-router-dom";
import _ from 'lodash';

import Loader from '../../../../../../components/molecules/GenericLoader';
import GenericWarningModal from '../../../../../../components/molecules/GenericWarningModal';

import SiteSettingPanel from './SiteSettingPanel';

import { saveContexts } from '../../../../../../store/contextSetting/actions';

import {
  MainContainer,
  StyledAccordion,
  StyledAccordionHeader,
  SettingTitle,
  StyledButton,
  SettingContainer,
  TabContainer,
  StyledTabs,
  StyledTab,
  EmptyContainer
} from './style';


const SiteSettings = ({
  contextsData,
  contextsIsLoading,
  sitesData,
  sitesIsLoading
}) => {
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const history = useHistory();
  const [tabValue, setTabValue] = useState(0);
  const [siteTabs, setSiteTabs] = useState([]);
  const [selectedContextId, setSelectedContextId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);

  useEffect(() => {
    if (contextsData && contextsData.length !== 0) {
      const newSiteTabs = _.map(contextsData, (context) => {
        const selectedSite = _.find(sitesData, (site) => String(site.id) === String(context.siteId));
        return ({
          id: context.siteId,
          label: selectedSite?.name || context.siteId
        })
      });
      setSiteTabs(newSiteTabs);
    }
  }, [contextsData, sitesData]);

  const openNewDrawer = (event) => {
    event.stopPropagation();
    history.push(`${path}/new`);
  }

  const openEditDrawer = (event, id) => {
    event.stopPropagation();
    history.push(`${path}/edit/${id}`);
  }

  const prepareDelete = (event, id) => {
    event.stopPropagation();
    setSelectedContextId(id);
    setOpenDelete(!openDelete);
  }

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleDelete = () => {
    if (selectedContextId != null) {
      const index = _.findIndex(contextsData, (template) => template.siteId === selectedContextId);
      const newIndex = index === 0 ? 0 : index - 1;
      const newTemplateArray = _.filter(contextsData, (template) => template.siteId !== selectedContextId);
      setTabValue(newIndex);
      dispatch(saveContexts({ formData: newTemplateArray }));
      setOpenDelete(false);
    }
  }

  const renderSiteTabs = () => {
    const siteTabsArray = _.map(siteTabs, (tab) => {
      return (
        <StyledTab label={tab.label} key={tab.id} />
      )
    });
    return siteTabsArray;
  }

  const renderTabPanels = () => {
    const tabPanelsArray = _.map(contextsData, (context, index) => {
      const selectedSite = _.find(sitesData, (site) => String(site.id) === String(context.siteId));
      return (
        <SiteSettingPanel
          context={context}
          site={selectedSite}
          openEditDrawer={openEditDrawer}
          prepareDelete={prepareDelete}
          tabValue={tabValue}
          index={index}
          key={index}
        />
      )
    })
    return tabPanelsArray;
  }

  const renderSiteSettings = () => {
    if (contextsData && contextsData.length !== 0) {
      return (
        <TabContainer>
          <StyledTabs
            orientation="vertical"
            variant="scrollable"
            value={tabValue}
            onChange={handleChange}
            TabIndicatorProps={{ style: { display: "none" } }}
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            {renderSiteTabs()}
          </StyledTabs>
          {renderTabPanels()}
        </TabContainer>
      )
    }
    else {
      return (
        <EmptyContainer>
          {'No data'}
        </EmptyContainer>
      )
    }
  }

  return (
    <MainContainer>
      <StyledAccordion
        headerComponent={
          <StyledAccordionHeader>
            <SettingTitle>
              {'Site settings'}
            </SettingTitle>
            <StyledButton
              title={`New context`}
              onClick={(event) => openNewDrawer(event)}
            />
          </StyledAccordionHeader>
        }
        detailComponent={
          <SettingContainer>
            {contextsIsLoading || sitesIsLoading ?
              <Loader />
              :
              renderSiteSettings()
            }
          </SettingContainer>
        }
      />
      <GenericWarningModal
        open={openDelete}
        setOpenDialog={() => setOpenDelete(!openDelete)}
        title={"Supprimer un configuration"}
        text={"Êtes-vous certain de vouloir supprimer ce configuration ?"}
        leftButtonText={"Annuler"}
        rightButtonText={"Supprimer"}
        leftButtonFunction={() => setOpenDelete(!openDelete)}
        rightButtonFunction={() => handleDelete()}
      />
    </MainContainer>
  );
};

export default SiteSettings;