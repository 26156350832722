import React from "react";

const WashIcon = ({
  color = "#5A5A5A",
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon washIcon"
      width={width}
      height={height}
      viewBox="0 0 21 24"
      fill="none"
    >
      <path
        d="M14.553.5l.228.001h.416c.58.002.957.004.974.006a.66.66 0 01.371.185l1.226 1.227 2.063.916A1.971 1.971 0 0121 4.635V21.53a1.971 1.971 0 01-1.969 1.97H5.25a1.971 1.971 0 01-1.969-1.97V20.22a.656.656 0 011.313 0v1.312c0 .362.294.657.656.657h6.234V6.459L9.27 8.231a.657.657 0 01-1.024-.282L6.52 3.351l-1.538.684a.657.657 0 00-.39.6v3.084a.656.656 0 01-1.312 0V4.634c0-.777.459-1.483 1.169-1.799l2.063-.916.406-.407.393-.394L7.57.86c.25-.248.258-.248.325-.283a.667.667 0 01.244-.075h.06l.155-.001H8.746L8.914.5zm3.208 2.851L16.036 7.95a.657.657 0 01-1.024.282L12.797 6.46v15.729h6.234a.657.657 0 00.657-.657V4.634a.658.658 0 00-.39-.6l-1.537-.683zm-13.823 6.68c.362 0 .656.294.656.657a2.624 2.624 0 002.625 2.625.656.656 0 010 1.312 2.624 2.624 0 00-2.625 2.625.656.656 0 01-1.313 0 2.628 2.628 0 00-2.625-2.625.656.656 0 010-1.312 2.624 2.624 0 002.625-2.625c0-.363.294-.657.656-.657zm0 2.835c-.3.45-.684.824-1.105 1.104.436.291.812.667 1.103 1.104.29-.437.669-.815 1.104-1.105a3.992 3.992 0 01-1.103-1.103zm12.14-10.782L13.123 5.04l2.002 1.602 1.503-4.007-.55-.55zm-7.875 0l-.55.55 1.503 4.007 2.002-1.602-2.955-2.955zm6.29-.271H9.788l2.354 2.353 2.353-2.353z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export default WashIcon;
