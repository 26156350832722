import styled from "styled-components";
import IconButton from '@material-ui/core/IconButton';

import GenericPaginationArrow from "../../components/molecules/GenericPaginationArrow";

export const Container = styled.div`
  flex: 1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const TableButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TableIcon = styled(IconButton)`
  &&{
    height: fit-content;
    .MuiSvgIcon-root {
      font-size: 1.2rem;
    }
  }
`;

export const SettingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  min-height: 50px;
`;

export const StyledPaginationArrow = styled(GenericPaginationArrow)`
  position: absolute;
  right: 0;
  margin: 0px 10px;
  width: 20%;
`;

export const TableWrapper = styled.div`
  /* padding-bottom: 20px; */
  .genericTable {
    max-height: 85vh;
  }
`;