import React, { useState, useEffect } from 'react';
import {
  shallowEqual,
  useDispatch,
  useSelector,
} from "react-redux";
import Parser from 'html-react-parser';
import _ from 'lodash';

import GenericDialogContainer from '../../../../../../../components/molecules/GenericDialogContainer';
import GenericTitleWithToolTip from '../../../../../../../components/molecules/GenericTitleWithToolTip';

import LinkModal from '../../../../../../../components/organisms/LinkModal';
import ChatbotInternalForm from '../../../../../../../components/organisms/ChabotInternalForm';

import { FORM_SOURCE } from '../../../../../../../constants/serviceConstants';
import { MESSAGE_ELEMENT_TYPE } from '../../../../../../Chatbot/constants/chatBotConstants';
import { generateUrlParamsString } from '../../../../../../../helpers/generateUrlParamsString';
import endpoint from '../../../../../../../helpers/api';

import { toaster } from '../../../../../../../helpers/toaster';

import {
  getInternalServiceFormSchema,
} from '../../../../../../../store/concierge/actions';

import {
  getInternalServiceFormSchemaData,
  getInternalServiceFormSchemaIsLoading
} from '../../../../../../../store/concierge/selectors';

import {
  CustomDialog,
  StyledTitle,
  ContentContainer,
  StyledAccordion,
  AccordionContent,
  StyledButton,
  ContentConciergeContainer,
  StyledMessageText,
  StyledMessageButton
} from './style';

const ServiceContentModal = ({
  currentUser,
  serviceActif,
  sitesData,
  serviceData,
  selectedUser,
  companyModule,
  setShouldReloadData,
  onClose
}) => {
  const dispatch = useDispatch();
  const [contentConcierge, setContentConcierge] = useState(null);
  const [linkModalData, setLinkModalData] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  // all the state below is for the internal form request
  const [modalServiceRequestSchema, setModalServiceRequestSchema] = useState({});
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [defaultValuesForm, setDefaultValuesForm] = useState({});
  const [requestSent, setRequestSent] = useState(false);
  const [apiAction, setApiAction] = useState('');

  const {
    formSchemaData,
    isFormSchemaLoading
  } = useSelector(
    state => ({
      formSchemaData: getInternalServiceFormSchemaData(state),
      isFormSchemaLoading: getInternalServiceFormSchemaIsLoading(state),
    }),
    shallowEqual
  );

  useEffect(() => {
    if (formSchemaData) {
      setModalServiceRequestSchema(formSchemaData);
    }
  }, [formSchemaData]);

  useEffect(() => {
    if (serviceActif?.content_concierge) {
      const content = JSON.parse(serviceActif.content_concierge);
      setContentConcierge(content);
    }
  }, [serviceActif]);

  useEffect(() => {
    if (serviceActif && serviceData) {
      const service = _.find(serviceData, (service) => service.id === serviceActif.service);
      setSelectedService(service);
    }
  }, [serviceActif, serviceData]);

  const handleExternalLinkClick = (element) => {
    if (!selectedUser) {
      toaster('Aucun collaborateur sélectionné', 'error');
      return;
    }
    const site = _.find(sitesData, (data) => data.name === selectedUser.company_site);
    const user = {
      ...selectedUser,
      company_site: {
        id: site.id,
        name: site.name
      }
    }
    const urlParamsString = generateUrlParamsString(element, user, companyModule, endpoint);
    let url = `${element.url}?${urlParamsString}`;

    if (currentUser) {
      url = `${url}&author=${currentUser.id}`;
    }

    if (element.target === '_blank') {
      if (element.url !== 'https://') window.open(url, '_blank');
    }
    else {
      setLinkModalData({
        title: element.title,
        source: url,
        target: element.target
      });
    }
    setShouldReloadData(true);
  }

  const handleClientRequestClick = () => {
    if (!selectedUser) {
      toaster('Aucun collaborateur sélectionné', 'error');
      return;
    }
    if (!serviceActif) return;
    else if (serviceActif.form_source === FORM_SOURCE.EXTERNAL) {
      const formExternalUrl = JSON.parse(serviceActif.form_external_url);
      handleExternalLinkClick(formExternalUrl);
    }
    else if (serviceActif.form_source === FORM_SOURCE.INTERNAL) {
      const formId = serviceActif.form_cyconia_id;
      const defaultValues = {
        service: selectedService?.name,
        serviceId: serviceActif.service,
        collaboratorId: selectedUser.id,
        beneficiaryLastname: selectedUser.lastname,
        beneficiaryFirstname: selectedUser.firstname,
        address: selectedUser.address,
      }
      dispatch(getInternalServiceFormSchema({ formId, defaultValues }))
      openFormModal(defaultValues, '');
      setShouldReloadData(true);
    }
  }

  // All the function below is for internal form to work

  const openFormModal = (defaultValue, action) => {
    setModalServiceRequestSchema({});
    setDefaultValuesForm(defaultValue);
    setIsFormModalOpen(true);
    setRequestSent(false);
    setApiAction(action)
  }

  const _setRequestSent = () => {
    setRequestSent(true);
  }

  const closeModalServiceRequest = () => {
    setIsFormModalOpen(false);
    window.location.hash = "";
  }

  const renderMessageElement = (messageElements) => {
    return _.map(messageElements, (element, elementIndex) => {
      if (element.type === MESSAGE_ELEMENT_TYPE.TEXT) {
        return (
          <StyledMessageText key={elementIndex} >
            {Parser(element.content)}
          </StyledMessageText>
        )
      }
      else {
        let userName = '';
        if (selectedUser) {
          userName = `pour ${selectedUser?.firstname} ${selectedUser?.lastname}`;
        }
        return (
          <StyledMessageButton
            key={elementIndex}
            onClick={() => handleExternalLinkClick(element)}
            title={`${element.title} ${userName}`}
          />
        )
      }
    })
  };

  const renderContentConcierge = () => {
    if (contentConcierge) {
      return (
        <ContentConciergeContainer>
          {contentConcierge?.messageElements?.length > 0 ? renderMessageElement(contentConcierge.messageElements) : 'Pas de reponse'}
        </ContentConciergeContainer>
      )
    }
    else {
      return "Pas d'information";
    }
  };

  return (
    <CustomDialog
      open={true}
      onClose={onClose}
    >
      <GenericDialogContainer onClose={onClose}>
        <StyledTitle>
          {`Détails du Service ${selectedService?.name}`}
        </StyledTitle>
        <ContentContainer>
          <StyledAccordion
            defaultExpanded={true}
            headerComponent={
              <GenericTitleWithToolTip
                title="PEC Concierge"
                toolTipText='Il s’agit de toutes les informations dont vous avez besoin pour répondre aux demandes du bénéficiaire : conditions particulières, tarifs, formulaires spécifiques…'
              />
            }
            detailComponent={
              <AccordionContent>
                {renderContentConcierge()}
              </AccordionContent>
            }
          />
          <StyledAccordion
            defaultExpanded={true}
            headerComponent={
              <GenericTitleWithToolTip
                title="PEC Chatbot"
                toolTipText='Il s’agit du formulaire tel que le voit le bénéficiaire sur le chatbot'
              />
            }
            detailComponent={
              <AccordionContent>
                <StyledButton
                  disabled={serviceActif.form_source === FORM_SOURCE.NONE}
                  title={`Faire une demande ${selectedUser ? `pour ${selectedUser?.firstname || ''} ${selectedUser?.lastname || ''}` : 'á la place du bénéficiaire' }`}
                  onClick={() => handleClientRequestClick()}
                />
              </AccordionContent>
            }
          />

        </ContentContainer>
      </GenericDialogContainer>
      {linkModalData &&
        <LinkModal
          onClose={() => setLinkModalData(null)}
          linkModalData={linkModalData}
        />
      }
      {isFormModalOpen &&
        <ChatbotInternalForm
          userInfo={null}
          userToken={null}
          isModalOpen={true}
          modalServiceRequestSchema={modalServiceRequestSchema}
          defaultValuesForm={defaultValuesForm}
          setFeedbackStatus={() => { }}
          isModalServiceRequestOpen={isFormModalOpen}
          isLoading={isFormSchemaLoading}
          requestSent={requestSent}
          _setRequestSent={_setRequestSent}
          apiAction={apiAction}
          _closeModalServiceRequest={closeModalServiceRequest}
          botFeedbackStatus={""}
          requestOnBehalf={true}  // if ticket is created by a concierge
          author={currentUser}    // if ticket is created by a concierge
        />
      }
    </CustomDialog>
  );
};

export default ServiceContentModal;