import Dialog from '@material-ui/core/Dialog';
import styled from "styled-components";


export const ReasonTitle = styled.div`
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
`;

export const ReasonForm = styled.form`
    
`;

export const ReasonBloc = styled.div`
    padding: 15px 30px 30px ;
    box-sizing: border-box;
`;

export const TypeBlocDate = styled.span`
    font-size: 14px;
    font-weight: 600;
    color: #AAAAAA;
    margin-left: 6px;
`;

export const TypeBlocInfo = styled.p`
    font-size: 16px;
    font-weight: 600;
`;

export const TypeBloc = styled.div`
    background-color: #E6F2FF;
    padding: 0 30px 30px;
    box-sizing: border-box;
`;

export const InfoBlocAddress = styled.p`
    svg{
        margin-right: 6px;
    }
`;

export const InfoBloc = styled.div`
    padding: 30px 30px;
    box-sizing: border-box;
    background-color: #EEF6FF;
    font-size: 12px;
    & > div {
        display: inline-flex;
        align-items: center;
        svg {
            margin-right: 13px;
        }
    }
`;

export const ReglementationContent = styled.div`
    font-size: 14px;
    margin-bottom: 45px;
`;

export const ReglementationBloc = styled.div`
    padding: 30px 30px 0;
    box-sizing: border-box;
`;

export const PublicationTitleContainer = styled.div`
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background-color: #E6F2FF;
    padding: 30px 30px 20px;
    box-sizing: border-box;
`;

export const PublicationTitle = styled.span`
  font-weight: 900;
  font-size: 30px;
  color: #5A5A5A;
`;

export const ListButton = styled.div`
    display: flex;
    width: 100%;
    margin-top: 30px;
    justify-content: space-between;
`;

export const Button = styled.button`
    background-color: ${props => props.red && "#EC6B87"};
    background-color: ${props => props.blue && "#4A90E2"};
    border-radius: 3px;
    padding: 15px 20px 15px 20px;
    cursor: pointer;
`;

export const ButtonText = styled.span`
    font-weight: 600;
    color: white;
`;

export const ReglementationText = styled.span`
    font-size: 14px;
    font-weight: 500;
`;

export const ReglementationTitle = styled.div`
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
`;

export const EditText = styled.textarea`
    width: 100%;
    background-color: #F1F1F1;
    border: none;
    border-radius: 6px;
    padding: 16px;
    box-sizing: border-box;
    min-height: 100px;
`;

export const Wrapper = styled.div`
    
`;

export const DialogContainer = styled.div` 
    max-width: 660px;
    width: 100%;
    padding: 0;
    background-color: white;
    border-radius: 6px;
    color: #5a5a5a;
`;

export const CustomDialog = styled(Dialog)`
    &.MuiDialog-root{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        box-sizing: border-box;
        .MuiDialog-paper{
            margin: 0;
        }
    }
`;


