import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import Tooltip from '@material-ui/core/Tooltip';
import RoomServiceIcon from '@mui/icons-material/RoomService';
// import FlagIcon from '@mui/icons-material/Flag';
import _ from 'lodash';

import { MESSAGE_TYPE } from '../../../../../../components/organisms/TicketRequestMessage/constants';
import { getTicketName } from '../../../../../../services/getTicketNameService';

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardDate,
  MessageNumber,
  CustomMessageIcon,
  // ColorChip,
  // onBehalfIcon,
  IconContainer,
} from './style'

const CardRequest = ({ data, servicesData, onClick }) => {
  const [messageCount, setMessageCount] = useState(0);
  const user = data?.collaborator;
  const serviceName = servicesData.find((site) => String(site.id) === String(data.service_id));
  const onBehalf = Boolean(data?.author?.id);
  let ticketTitle = null;
  if (data.source === "jotform" && data.full_submission) ticketTitle = getTicketName(data);

  useEffect(() => {
    if (data) {
      let totalMessage = 0;
      _.map(data.messages, (message) => {
        if (message.type !== MESSAGE_TYPE.NOTE && message.type !== MESSAGE_TYPE.SYSTEM && message.type !== MESSAGE_TYPE.SYSTEM_CLIENT) {
          totalMessage = totalMessage + 1;
        }
      });
      setMessageCount(totalMessage);
    }
  }, [data]);

  return (
    <Card onClick={onClick}>
      <CardHeader>
        <CardTitle>{ticketTitle || serviceName?.name || ''}</CardTitle>
        <CardDate>{format(new Date(data?.created_at), 'dd/MM/yyyy')}</CardDate>
        <CardDate>{user?.firstname} {user?.lastname}</CardDate>
      </CardHeader>
      <CardBody>
        <MessageNumber>
          <CustomMessageIcon />
          {messageCount}
        </MessageNumber>
      </CardBody>
      {/* <ColorChip
        className={data.status}
      /> */}
      {onBehalf &&
        <Tooltip title={`Demande créee par un concierge`} >
          <IconContainer>
            <RoomServiceIcon />
          </IconContainer>
        </Tooltip>
      }
    </Card>
  )
}

//service_id

export default CardRequest
