import React, { useState, useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import zxcvbn from "zxcvbn";

// Layout
import DetailsSidebarFrontBaseLayout from "../../layouts/detailsSidebarFrontBaseLayout";

// Components
// import ButtonCpt from '../../components/molecules/buttonCpt';
import BackIcon from '../../components/atoms/icons/back';
import InputField from '../../components/molecules/inputField';
import { FieldPassword } from '../../../../components/molecules/Field';
import LockIcon from '../../components/atoms/icons/lock';
import EmailIcon from '../../components/atoms/icons/email';
import ProfileIcon from '../../components/atoms/icons/profile';

// Store
import {
  getUser as getUserAction,
  saveUserInformations,
} from '../../../../store/collaborator/actions';
import {
  getUser,
  isSaveCollaboratorInformationsOnLoad,
  isSaveCollaboratorInformationsOnSuccess,
  isSaveCollaboratorInformationsOnError,
} from '../../../../store/collaborator/selectors';

// Hooks
import useTheme from "../../../../theme-style/useThemeCustomHook";

// Styles
import {
  Container,
  InformationsWrapper,
  Header,
  BackButton,
  Title,
  BackIconWrapper,
  InformationsForm,
  LockIconWrapper,
  InformationsFormInput,
  ButtonCptSave,
  ProfileIconWrapper,
  EmailIconWrapper,
} from "./style";

const AccountInformations = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const PASSWORD_STRENGTH_LABELS = {
    0: "Médiocre",
    1: "Faible",
    2: "Correct",
    3: "Bon",
    4: "Exceptionnel"
  };
  const {
    user,
    saveOnLoad,
    saveOnSuccess,
    saveOnError,
  } = useSelector(
    state => ({
      user: getUser(state),
      saveOnLoad: isSaveCollaboratorInformationsOnLoad(state),
      saveOnSuccess: isSaveCollaboratorInformationsOnSuccess(state),
      saveOnError: isSaveCollaboratorInformationsOnError(state),
    }),
    shallowEqual);
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  /* const [email, setEmail] = useState(''); */
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatNewPassword, setRepeatNewPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState(-1);
  const [passwordError, setPasswordError] = useState(null);
  const [appendError, setAppendError] = useState(true);

  useEffect(() => {
    dispatch(getUserAction());
  }, []);

  useEffect(() => {
    if (user) {
      setFirstname(user.firstname);
      setLastname(user.lastname);
    }
  }, [user]);

  useEffect(() => {
    if (saveOnSuccess) {
      toast.success("Vos modifications ont bien été enregistrées.", {
        position: toast.POSITION.TOP_RIGHT,
        position: toast.POSITION.TOP_RIGHT,
        className: "custom-toast custom-toast-success",
        bodyClassName: "custom-toast__body custom-toast__body-success",
        progressClassName: "custom-toast__progress"
      });
      setCurrentPassword('');
      setNewPassword('');
      setRepeatNewPassword('');
      setPasswordStrength(-1);
      setPasswordError(null);
    }
  }, [saveOnSuccess]);

  useEffect(() => {
    if (saveOnError) {
      toast.error("Une erreur est survenue ! Veuillez réessayer plus tard. Si le problème persiste, contactez le support.", {
        position: toast.POSITION.TOP_RIGHT,
        className: "custom-toast custom-toast-error",
        bodyClassName: "custom-toast__body custom-toast__body-success",
        progressClassName: "custom-toast__progress"
      });
    }
  }, [saveOnError]);

  useEffect(() => {
    if (appendError) {
      toast.error(passwordError, {
        position: toast.POSITION.TOP_RIGHT,
        className: "custom-toast custom-toast-error",
        bodyClassName: "custom-toast__body custom-toast__body-success",
        progressClassName: "custom-toast__progress"
      });
      setAppendError(false);
      setPasswordError(null);
    }
  }, [appendError]);

  const checkPasswordComplexity = (
    password, minLength, caseSensitive = true, hasNumber = true,
    hasSpecialCharacter = true) => {

    const caseRegex = /[A-Z]/;
    const caseRegex2 = /[a-z]/;
    const numberRegex = /\d/;
    const specialCharacter = /\W/;

    let error = "";
    let result;

    result = zxcvbn(password);

    if (password.length < minLength) {
      error += "Minimum 8 caractères. "
    }

    if (caseSensitive) {
      if (caseRegex.test(password) === false) {
        error += "Minimum une majuscule. ";
      }
    }
    if (caseSensitive) {
      if (caseRegex2.test(password) === false) {
        error += "Minimum une minuscule. ";
      }
    }
    if (hasNumber) {
      if (numberRegex.test(password) === false) {
        error += "Minimum un chiffre. ";
      }
    }
    if (hasSpecialCharacter) {
      if (specialCharacter.test(password) === false) {
        error += "Minimum un caractère spécial. ";
      }
    }
    setPasswordStrength(result.score);
    setPasswordError(error?.length === 0 ? null : `Votre mot de passe doit contenir ${error}`);
    return error?.length === 0;
  };

  const onChangeField = (e) => {
    const { target: { name, value } } = e;
    switch (name) {
      case 'firstname':
        setFirstname(value);
        break;
      case 'lastname':
        setLastname(value);
        break;
      case 'currentPassword':
        setCurrentPassword(value);
        break;
      case 'newPassword':
        checkPasswordComplexity(value, 8);
        setNewPassword(value);
        break;
      case 'repeatNewPassword':
        setRepeatNewPassword(value);
        break;
      default:
    }
  };
  
  const onSubmit = e => {

    console.log('passwordStrength', passwordStrength > 0);
    if (e) e.preventDefault();
    if (currentPassword.length === 0 && newPassword !== "" && repeatNewPassword !== "") {
      setPasswordError("Vous n'avez pas saisi votre mot de passe.");
      setAppendError(true);
      return;
    }
    if (newPassword !== repeatNewPassword && newPassword !== "" && repeatNewPassword !== "") {
      setPasswordError('Le deux nouveaux mot de passe saisis ne sont pas identique.');
      setAppendError(true);
      return;
    }
    if (!!currentPassword?.length && (newPassword === "" || repeatNewPassword === "")) {
      setPasswordError('Pour modifier votre mot de passe, merci de compléter tous les champs correspondants.');
      setAppendError(true);
      return;
    }

    
    const isSubmittableForm = (
      !!currentPassword?.length &&
      (!!newPassword?.length && newPassword === repeatNewPassword && passwordStrength > 0)
    ) || (currentPassword === "" && newPassword === "" && repeatNewPassword === "");

    if (!isSubmittableForm) {
      setAppendError(true);
      return;
    } else {
      dispatch(saveUserInformations({
        firstname,
        lastname,
        email: user.email,
        currentPassword,
        newPassword,
        repeatNewPassword: repeatNewPassword,
      }));
    }
  };

  return (
    <>
      <DetailsSidebarFrontBaseLayout
        id="detailsSidebarLayoutAccountInfo"
        className="detailsSidebarLayoutAccountInfo page-container sidebarPage"
      >
        <Container>
          <InformationsWrapper>
            <Header to="/compte">
              <BackButton>
                <BackIconWrapper>
                  <BackIcon color={theme.colors.white} />
                </BackIconWrapper>
              </BackButton>
              <Title>
                Mes informations
                </Title>
            </Header>

            <InformationsForm>
              <InformationsFormInput noValidate autoComplete="off" onSubmit={onSubmit}>
                <InputField
                  value={firstname}
                  type="text"
                  label="Prénom"
                  name="firstname"
                  placeholder="Saisissez votre prénom"
                  onChange={onChangeField}
                  endAdornment={
                    <ProfileIconWrapper>
                      <ProfileIcon color="#777777" />
                    </ProfileIconWrapper>
                  }
                />
                <InputField
                  value={lastname}
                  type="text"
                  label="Nom"
                  name="lastname"
                  placeholder="Saisissez votre nom"
                  onChange={onChangeField}
                  endAdornment={
                    <ProfileIconWrapper>
                      <ProfileIcon color="#777777" />
                    </ProfileIconWrapper>
                  }
                />
                {/* <InputField
                  name="currentPassword"
                  value={currentPassword}
                  type="password"
                  label="Mot de passe actuel (Ne pas renseigner pour conserver votre mot de passe)"
                  placeholder="Saisissez votre mot de passe"
                  onChange={onChangeField}
                  endAdornment={
                    <LockIconWrapper>
                      <LockIcon />
                    </LockIconWrapper>
                  }
                />
                <InputField
                  name="newPassword"
                  value={newPassword}
                  type="password"
                  label="Nouveau mot de passe (Ne pas renseigner pour conserver votre mot de passe)"
                  placeholder="Saisissez votre nouveau mot de passe"
                  onChange={onChangeField}
                  endAdornment={
                    <LockIconWrapper>
                      <LockIcon />
                    </LockIconWrapper>
                  }
                  passwordStrength={passwordStrength}
                  passwordStrengthLabel={PASSWORD_STRENGTH_LABELS}
                />
                <InputField
                  name="repeatNewPassword"
                  value={repeatNewPassword}
                  type="password"
                  label="Nouveau mot de passe (Ne pas renseigner pour conserver votre mot de passe)"
                  placeholder="Saisissez votre nouveau mot de passe"
                  onChange={onChangeField}
                  endAdornment={
                    <LockIconWrapper>
                      <LockIcon />
                    </LockIconWrapper>
                  }
                /> */}
              </InformationsFormInput>

              <ButtonCptSave
                label={saveOnLoad ? 'LOADING...' : 'Enregistrer'}
                appearence="sidebarFrontSecondary"
                onClick={onSubmit}
                disabled={saveOnLoad}
              />
            </InformationsForm>
          </InformationsWrapper>
        </Container>
      </DetailsSidebarFrontBaseLayout>
    </>
  );
}

export default AccountInformations;
