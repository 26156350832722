import React, { useEffect } from 'react';
import {
  shallowEqual,
  useDispatch,
  useSelector,
} from "react-redux";
import { ThemeProvider } from "styled-components";
import Routes from './routes';
import theme from "./theme-style";
import MainDefaultStyle from "./theme-style/main-default-style";

import NewsModal from '../../components/organisms/NewsModal';

import {
  getSelectedParoleData
} from '../../store/sidebar/selectors';

import {
  getServicesActifs,
  getConcierges
} from '../../store/concierge/actions';

const Sidebar = () => {
  const dispatch = useDispatch();

  const {
    modalParoleData,
  } = useSelector(
    state => ({
      modalParoleData: getSelectedParoleData(state),
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getServicesActifs());
    dispatch(getConcierges());
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <MainDefaultStyle />
      <Routes />
      {modalParoleData && <NewsModal newsData={modalParoleData} />}
    </ThemeProvider>
  )
};

export default Sidebar;
