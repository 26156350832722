// Corporations
export const getCorporationsData = state => state.concierge.corporations.data;
export const getCorporationsIsLoading = state => state.concierge.corporations.get.loading;
// Sites
export const getConciergeSitesData = state => state.concierge.sites.data;
export const getConciergeSitesIsLoading = state => state.concierge.sites.get.loading;
// Concierges
export const getConciergeConciergesData = state => state.concierge.concierges.data;
export const getConciergeConciergesLoading = state => state.concierge.concierges.get.loading;
// Pestation
export const getConciergeProvidersData = state => state.concierge.providers.data;
export const getConciergeProvidersLoading = state => state.concierge.providers.get.loading;
// Services
export const getConciergeServicesData = state => state.concierge.services.data;
export const getConciergeServicesLoading = state => state.concierge.services.get.loading;
// ServicesActifs
export const getConciergeServicesActifsData = state => state.concierge.servicesActifs.data;
export const getConciergeServicesActifsLoading = state => state.concierge.servicesActifs.get.loading;
// TicketRequests
export const getConciergeTicketRequestsData = state => state.concierge.ticketRequests.data;
export const getConciergeTicketRequestsLoading = state => state.concierge.ticketRequests.get.loading;
// CalendarObjects
export const getConciergeCalendarObjectsData = state => state.concierge.calendarObjects.data;
// Internal form
export const getInternalServiceFormData = state => state.concierge.internalServiceForm.data;

export const getInternalServiceFormSchemaData = state => state.concierge.internalServiceFormSchema.data;
export const getInternalServiceFormSchemaIsLoading = state => state.concierge.internalServiceFormSchema.get.loading;

//Concierge data curent user site
export const getConciergeDataByCurentSite = state => {
  const currentUser = state.collaborator.user;
  const conciergeData = getConciergeConciergesData(state);
  if (conciergeData && currentUser) {
    return conciergeData.find((value) => String(value.company_site.id) === String(currentUser.company_site.id)) || null
  }
  return null;
}
