import React from 'react';
import { differenceInMinutes } from 'date-fns';
import CancelIcon from '@material-ui/icons/Cancel';
import _ from 'lodash';

import {
  BOOKING_CANCEL_STATUS,
  BOOKING_STATUS_DISPLAY
} from '../../../../../../constants/bookingConstants';
import { displayDateRange } from '../../../../../../helpers/displayDateRange';
import { priceDisplay } from '../../../../../../helpers/roomBookingPriceHelper';

import {
  CustomDialog,
  DialogContainer,
  StyledCloseContainer,
  StyledTitle,
  ContentContainer,
  StyledInfoGroup,
  StyledInfoTitle,
  StyledInfoText,
  StyledOptionsContainer
} from './style';

const InfoModal = ({
  data,
  corporationData,
  onClose
}) => {

  const displayOptions = () => {
    if (data.options && data.options?.length !== 0) {
      return _.map(data.options, (option, index) => {
        return (
          <StyledOptionsContainer key={index}>
            <StyledInfoGroup>
              <StyledInfoTitle>{option.title}</StyledInfoTitle>
            </StyledInfoGroup>
            <StyledInfoGroup>
              <StyledInfoTitle>{`Prix`}</StyledInfoTitle>
              <StyledInfoText>{priceDisplay(option.price)}</StyledInfoText>
            </StyledInfoGroup>
            {data.cancel_status === BOOKING_CANCEL_STATUS.PAYABLE &&
              <StyledInfoGroup>
                <StyledInfoTitle>{`Montant des frais d'annulation`}</StyledInfoTitle>
                <StyledInfoText>{priceDisplay(option.cancel_fees)}</StyledInfoText>
              </StyledInfoGroup>
            }
            <StyledInfoGroup>
              <StyledInfoTitle>{`Note`}</StyledInfoTitle>
              <StyledInfoText>{option.note || '-'}</StyledInfoText>
            </StyledInfoGroup>
          </StyledOptionsContainer>
        )
      });
    }
    else {
      return (
        <StyledOptionsContainer>
          {`Aucune option sélectionnée`}
        </StyledOptionsContainer>
      )
    }
  };

  const getCorporationName = () => {
    const selectedCorporation = _.find(corporationData, (corporation) => corporation.id === data.corporation_id);
    return `${selectedCorporation?.name || '-'}`;
  }

  // console.log('data', data);

  return (
    <CustomDialog
      open={true}
      onClose={onClose}
    >
      <DialogContainer>
        <StyledCloseContainer onClick={onClose}>
          <CancelIcon style={{ fontSize: 30 }} />
        </StyledCloseContainer>
        <StyledTitle>{`Fiche de la réservation - ${data.id}`}</StyledTitle>
        <ContentContainer>
          <StyledTitle>{`Réservation par`}</StyledTitle>
          <StyledInfoGroup>
            <StyledInfoTitle>{`Nom`}</StyledInfoTitle>
            <StyledInfoText>{`${data.owner.firstname} ${data.owner.lastname}`}</StyledInfoText>
          </StyledInfoGroup>
          <StyledInfoGroup>
            <StyledInfoTitle>{`Entreprise`}</StyledInfoTitle>
            <StyledInfoText>{getCorporationName()}</StyledInfoText>
          </StyledInfoGroup>
          <StyledInfoGroup>
            <StyledInfoTitle>{`Mail`}</StyledInfoTitle>
            <StyledInfoText>{data.owner.email}</StyledInfoText>
          </StyledInfoGroup>
          <StyledInfoGroup>
            <StyledInfoTitle>{`Numéro de téléphone`}</StyledInfoTitle>
            <StyledInfoText>{data.contact_number}</StyledInfoText>
          </StyledInfoGroup>

          <StyledTitle>{`Détails de la réservation`}</StyledTitle>
          <StyledInfoGroup>
            <StyledInfoTitle>{`Date`}</StyledInfoTitle>
            <StyledInfoText>{displayDateRange(data.start, data.end)}</StyledInfoText>
          </StyledInfoGroup>
          <StyledInfoGroup>
            <StyledInfoTitle>{`Durée`}</StyledInfoTitle>
            <StyledInfoText>{`${differenceInMinutes(new Date(data.end), new Date(data.start))} min`}</StyledInfoText>
          </StyledInfoGroup>
          <StyledInfoGroup>
            <StyledInfoTitle>{`Quantité`}</StyledInfoTitle>
            <StyledInfoText>{data.quantity}</StyledInfoText>
          </StyledInfoGroup>
          <StyledInfoGroup>
            <StyledInfoTitle>{`Titre`}</StyledInfoTitle>
            <StyledInfoText>{data.public_title || '-'}</StyledInfoText>
          </StyledInfoGroup>
          <StyledInfoGroup>
            <StyledInfoTitle>{`Prix de salle`}</StyledInfoTitle>
            <StyledInfoText>{priceDisplay(data.price)}</StyledInfoText>
          </StyledInfoGroup>
          <StyledInfoGroup>
            <StyledInfoTitle>{`Frais d'annulation`}</StyledInfoTitle>
            <StyledInfoText>{BOOKING_STATUS_DISPLAY[data.cancel_status] || '-'}</StyledInfoText>
          </StyledInfoGroup>
          {data.cancel_status === BOOKING_CANCEL_STATUS.PAYABLE &&
            <StyledInfoGroup>
              <StyledInfoTitle>{``}</StyledInfoTitle>
              <StyledInfoText>{priceDisplay(data.cancel_fees)}</StyledInfoText>
            </StyledInfoGroup>
          }
          {data.cancel_status !== null &&
            <>
              <StyledInfoGroup>
                <StyledInfoTitle>{`Annulation par`}</StyledInfoTitle>
                <StyledInfoText>{`${data.cancel_by?.firstname} ${data.cancel_by?.lastname}`}</StyledInfoText>
              </StyledInfoGroup>
              <StyledInfoGroup>
                <StyledInfoTitle>{`Note d'annulation`}</StyledInfoTitle>
                <StyledInfoText>{`${data.cancel_note || '-'}`}</StyledInfoText>
              </StyledInfoGroup>
            </>
          }
          <StyledInfoGroup>
            <StyledInfoTitle>{`Note`}</StyledInfoTitle>
            <StyledInfoText>{data.note || '-'}</StyledInfoText>
          </StyledInfoGroup>
          <StyledTitle>{`Options`}</StyledTitle>
          {displayOptions()}
        </ContentContainer>
      </DialogContainer>
    </CustomDialog>
  )
};

export default InfoModal;