import React from "react";

// Layout
import BaseLayout from "../base";

// Import component
// import SideNavbar from '../../components/organisms/sideNavbar/';
import Navigation from '../../components/organisms/navigation';

// Style
import { PageContent } from "./style";

const SidebarFrontBaseLayout = ({ children, ...rest }) => {
  return (
    <BaseLayout {...rest}>
      <Navigation />

      <PageContent>
        {children}
      </PageContent>
    </BaseLayout>
  );
};

export default SidebarFrontBaseLayout;
