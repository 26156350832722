import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  StyledContainer,
  StyledEditButton,
  StyledItemBlock,
  StyledActiveStatus,
} from './style';

const ItemBlock = ({ data, handleClick }) => {
  const [isHover, setIshover] = useState(false);

  return (
    <StyledContainer
      onClick={handleClick}
      onMouseOver={() => setIshover(true)}
      onMouseLeave={() => setIshover(false)}
    >
      {isHover ?
        <StyledEditButton>
          Editer
        </StyledEditButton>
        :
        <StyledItemBlock>
          <StyledActiveStatus active={data?.active}>
            {data?.active ? 'activé' : 'désactivé'}
          </StyledActiveStatus>
        </StyledItemBlock>
      }

    </StyledContainer>
  )
}

ItemBlock.propTypes = {
  data: PropTypes.object,
  handleClick: PropTypes.func
}

export default ItemBlock;