import React from "react";

const CompanyIcon = ({
  color = "#FFDB83",
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon companyIcon"
      width={width}
      height={height}
      viewBox="0 0 12 10"
      fill="none"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
          <path
            d="M10.8751795,9.07902564 L10.8751795,2.53928205 L6.44469231,2.5444359 L6.44469231,9.07902564 L5.54484615,9.07902564 L5.54484615,0 L1.12482051,1.18512821 L1.12482051,9.07905128 L0,9.07905128 L0,9.84615385 L12,9.84615385 L12,9.07902564 L10.8751795,9.07902564 Z M7.63651282,3.63664103 L9.65733333,3.63664103 L9.65733333,4.48230769 L7.63651282,4.48230769 L7.63651282,3.63664103 Z M7.63651282,5.55961538 L9.65733333,5.55961538 L9.65733333,6.40528205 L7.63651282,6.40528205 L7.63651282,5.55961538 Z M7.63651282,7.48261538 L9.65733333,7.48261538 L9.65733333,8.32828205 L7.63651282,8.32828205 L7.63651282,7.48261538 Z M2.33269231,1.8065641 L4.33697436,1.8065641 L4.33697436,2.64530769 L2.33269231,2.64530769 L2.33269231,1.8065641 Z M2.33269231,3.71382051 L4.33697436,3.71382051 L4.33697436,4.5525641 L2.33269231,4.5525641 L2.33269231,3.71382051 Z M2.33269231,5.62105128 L4.33697436,5.62105128 L4.33697436,6.45979487 L2.33269231,6.45979487 L2.33269231,5.62105128 Z M2.33269231,7.52830769 L4.33697436,7.52830769 L4.33697436,8.36705128 L2.33269231,8.36705128 L2.33269231,7.52830769 Z"
            id="companyIcon"
            fill={color}
            fillRule="nonzero"
          >
          </path>
      </g>
    </svg>
  );
};

export default CompanyIcon;
