import React from 'react';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';
import _ from 'lodash';

import { ROLES_DISPLAY } from '../../../../../constants/rolesConstants';

import GenericTitleWithValue from '../../../../../components/molecules/GenericTitleWithValue';

import {
  MainContainer,
  StyledTitle,
  UserInfoContainer
} from './style';

const UserInfoCard = ({
  collaboratorData
}) => {

  const displayCollaboratorRoles = (roles) => {
    let rolesDisplay = '';

    _.map(roles, (role, index) => {
      rolesDisplay = `${rolesDisplay}${index !== 0 ? ', ' : ''}${ROLES_DISPLAY[role]}`;
    });

    return rolesDisplay;
  }

  return (
    <MainContainer>
      <StyledTitle>{`Informations de l'utilisateur`}</StyledTitle>
      {collaboratorData &&
        <UserInfoContainer>
          <GenericTitleWithValue
            title={'Prénom:'}
            value={collaboratorData.firstname}
          />
          <GenericTitleWithValue
            title={'Nom:'}
            value={collaboratorData.lastname}
          />
          <GenericTitleWithValue
            title={'Email:'}
            value={collaboratorData.email}
          />
          <GenericTitleWithValue
            title={'Roles:'}
            value={displayCollaboratorRoles(collaboratorData.roles)}
          />
          <GenericTitleWithValue
            title={`Site d'entreprise:`}
            value={collaboratorData.company_site?.name}
          />
          <GenericTitleWithValue
            title={`Compte créé le:`}
            value={format(new Date(collaboratorData.created_at), "dd/MM/yyyy à HH:mm", { locale: fr })}
          />
          <GenericTitleWithValue
            title={`Compte validé le:`}
            value={format(new Date(collaboratorData.account_validation_at), "dd/MM/yyyy à HH:mm", { locale: fr })}
          />
        </UserInfoContainer>
      }
    </MainContainer>
  );
};

export default UserInfoCard;