import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

// Import third party packages
import LaddaButton, { SLIDE_UP } from 'react-ladda';
// Icons
// import ProfileIcon from '../../../../components/atoms/icons/profile';

// Import helper
import { authHeader } from '../../../../helpers/auth-header';
import endpointAPI from "../../../../helpers/api";

// Store
import {
	saveOnBoardingOne,
	resetOnBoarding,
} from '../../../../store/onboarding/actions';
import {
	isOnBoardingStepOneOnSuccess,
	isOnBoardingStepOneOnLoad,
} from '../../../../store/onboarding/selectors';
import {
	getUser,
} from '../../../../store/collaborator/selectors';

// Style
import {
	Container,
	BubbleText,
	Text,
	ErrorText,
	RadioBox,
	ActionBox,
	RadioGroupCustom,
    ProfileIconWrapper,
    BackButton,
} from './style';

const GENDER_VALUES = [
	{label: 'Femme', value: 'women',},
	{label: 'Homme', value: 'men',},
	{label: 'Pas envie de choisir', value: 'no',}
];

const AGES_VALUES = [
	{ label: '- de 20 ans', value: 'moins de 20 ans', },
	{ label: 'de 20 à 34 ans', value: 'de 20 à 34 ans', },
	{ label: 'de 35 à 49 ans', value: 'de 35 à 49 ans', },
	{ label: '50 ans et +', value: '50 ans et +', }
]

const Step1 = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [gender, setGender] = useState(null);
	const [age, setAge] = useState(null);
	const [errorGender, setErrorGender] = useState(false);
	const [errorAge, setErrorAge] = useState(false);
	const {
		saveOnSuccess,
		saveOnLoad,
		user,
	} = useSelector(
		state => ({
			saveOnSuccess: isOnBoardingStepOneOnSuccess(state),
			saveOnLoad: isOnBoardingStepOneOnLoad(state),
			user: getUser(state),
		}),
	shallowEqual);

	useEffect(() => {
		if (user) {
			const currentGender = ['null', 'women', 'men'].includes(user?.gender)
				? (
					user.gender === 'null'
						? 'no'
						: user.gender
				)
				: '';
			setGender(currentGender);
			setAge([
				'moins de 20 ans',
				'de 20 à 34 ans',
				'de 35 à 49 ans',
				'50 ans et +',
			].includes(user?.age) ? user.age : '');
		}
	}, [user]);

	const onChangeGender = (e) => {
		const { target: { value } } = e;
		setErrorGender(false);
		setGender(value);
	};

	const onChangeAge = (e) => {
		const { target: { value } } = e;
		setErrorAge(false);
		setAge(value);
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (age === null || gender === null) {
			if (!age) {
				setErrorAge(true);
			}
			if (!gender) {
				setErrorGender(true);
			}
			return;
		}

		dispatch(saveOnBoardingOne({gender, age}));
	};

	useEffect(() => {
		dispatch(resetOnBoarding());
	}, []);

	useEffect(() => {
		if (saveOnSuccess) {
			history.push("/onboarding/3");
		}
	}, [saveOnSuccess]);

	return (
		<Container>
			<form onSubmit={handleSubmit} noValidate>
				<BubbleText>
					<Text>
						Souhaitez-vous m’indiquer votre genre ?
					</Text>
				</BubbleText>

                <RadioGroupCustom
									aria-label="gender"
									name="gender"
									onChange={onChangeGender}
									value={gender}
								>
                    {GENDER_VALUES.map(genderValue => (
                        <FormControlLabel
                            key={genderValue.value}
                            value={genderValue.value}
                            control={<Radio />}
                            label={genderValue.label}
                        />
                    ))}
                    {errorGender &&
                        <ErrorText>
                            Veuillez sélectionner une réponse
                        </ErrorText>
                    }
                </RadioGroupCustom>

				<BubbleText>
					<Text>
						Souhaitez-vous m’indiquer dans quelle tranche d’âge vous vous situez ?
					</Text>
				</BubbleText>
                
                <RadioGroupCustom
									aria-label="age"
									name="age"
									onChange={onChangeAge}
									value={age}
								>
                    {AGES_VALUES.map(ageValue => (
                        <FormControlLabel
                            key={ageValue.value}
                            value={ageValue.value}
                            control={<Radio />}
                            label={ageValue.label}
                        />
                    ))}
                    {errorAge &&
                        <ErrorText>
                            Veuillez sélectionner une réponse
                        </ErrorText>
                    }
                </RadioGroupCustom>

				<ActionBox>
					<BackButton to="/onboarding/CGU">
						Revenir
					</BackButton>
					<LaddaButton
						type="submit"
						className="ladda-button"
						loading={saveOnLoad}
						// data-style={SLIDE_UP}
						data-spinner-size={30}
						data-spinner-color="#fff"
						data-spinner-lines={12}
					>
						Suivant
					</LaddaButton>
				</ActionBox>
			</form>
		</Container>
	);
};

export default Step1;
