import axios from 'axios';

export const editServiceActifService = (endpoint, userToken, formData) => {

  const contentCustom = {
    serviceName: formData.serviceName,
    illustrationPhrase: formData.illustrationPhrase,
    description: formData.contentShortDescription,
    messageElements: formData.messageElements
  }

  const contentConcierge = {
    version: 1,
    messageElements: formData?.conciergeContentmessageElements || [],
  }

  const newForm = new FormData();
  newForm.append('service', formData.service);
  newForm.append('companySite', formData.site);
  newForm.append('active', formData.active ? 1 : 0);
  newForm.append('contentSource', formData.contentSource);
  newForm.append('contentTemplateId', 0);
  newForm.append('contentCustom', JSON.stringify(contentCustom));
  newForm.append('contentConcierge', JSON.stringify(contentConcierge));
  newForm.append('formSource', formData.formSource);
  newForm.append('formCyconiaId', formData.internalUrl);
  newForm.append('formExternalUrl', JSON.stringify(formData.externalUrl));
  newForm.append('contentCustomImageFile', formData.imageFile);
  newForm.append('contentCustomIconFile', formData.contentCustomIconFile)

  // console.log('id', id);
  //console.log('newForm', ...newForm);
  //console.log('formData', formData);

  const headers = {};
  if (userToken) {
    headers.Authorization = `Bearer ${userToken}`;
  }

  return axios.request({
    method: 'post',
    url: endpoint,
    data: newForm,
    headers: headers
  })
}