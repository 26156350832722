import { all, takeEvery } from "redux-saga/effects";

/** Actions */
import * as a from './actions';

//Import handlers
import {
  getNewsContentHandler,
  updateNewsContentHandler,
  deleteNewsContentHandler,
  getNewsContentByIdHandler,
} from './handler/newsContentHandler';

import {
  getGoodDealsHandler,
  updateGoodDealHandler,
  deleteGoodDealHandler,
  getGoodDealByIdHandler,
} from './handler/goodDealHandler';

export default function* adminFrontRootSaga() {
  yield all([
    takeEvery(a.GET_NEWS_CONTENT.REQUEST, getNewsContentHandler),
    takeEvery(a.UPDATE_NEWS_CONTENT.REQUEST, updateNewsContentHandler),
    takeEvery(a.DELETE_NEWS_CONTENT.REQUEST, deleteNewsContentHandler),
    takeEvery(a.GET_NEWS_CONTENT_BY_ID.REQUEST, getNewsContentByIdHandler),
    takeEvery(a.GET_GOOD_DEALS.REQUEST, getGoodDealsHandler),
    takeEvery(a.UPDATE_GOOD_DEAL.REQUEST, updateGoodDealHandler),
    takeEvery(a.DELETE_GOOD_DEAL.REQUEST, deleteGoodDealHandler),
    takeEvery(a.GET_GOOD_DEAL_BY_ID.REQUEST, getGoodDealByIdHandler),
  ]);
}
