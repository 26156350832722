import styled, { css } from "styled-components";
import Tooltip from '@material-ui/core/Tooltip';

export const CrossElementList = styled.div`
display: flex;
flex-direction: column;
margin-bottom: 30px;
`;

export const CrossElementContainer = styled.div`
  width: 100%;
  max-width: 100%;
  height: 20%;
  min-height: 50px;
  max-height: 5vh;
  padding: 13px;
  border-radius: 10px;
  background-image: linear-gradient(-45deg, #EEEEEE 0%, #EEEEEE 100%);
  margin: 0 0 10px 0;
`;

export const CrossElement = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  vertical-align: middle;
`;

export const IconAndTitle = styled.div`
  display: flex;
  flex-direction: row;
  vertical-align: middle;
`;

export const TitleFatherKB = styled.p`
  font-size: 15px;
  font-weight: 600;
  color: #787878;
`;

export const InformationBubble = styled(Tooltip)`
`;

export const TitleEIC = styled.span`
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  color: #777777;
  margin-left: 5px;
`;

export const TitleHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  margin-bottom: 10px;
`;

export const HeaderTitleDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const InformationI = styled.div`
  height: 15px;
  width:  15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #DDDDDD;//#4991E3;
  margin-left: 5px;
  border-radius: 50%; 
`;

export const InformationISpan = styled.span`
  text-align: center;
  font-size: 10px;
  font-weight: 500;
`;

