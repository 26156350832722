import React, { useEffect, useState } from 'react';
import _ from 'lodash';
// import OpenInNewIcon from '@material-ui/icons/OpenInNew';
// import DialogContent from "@material-ui/core/DialogContent";

import GenericMaterialMenu from '../../../../../../../components/molecules/GenericMaterialMenu';
import GenericWarningModal from '../../../../../../../components/molecules/GenericWarningModal';
import ResponseBuilder from '../../../../../../../components/organisms/ResponseBuilder';

import { MESSAGE_ELEMENT_TYPE } from '../../../../../../../constants/chatBotConstants';

import {
  MainContainer,
  // OpenResponseBuilderModalButton,
  // StyledDialog
} from './style';

const responseBuilderSetting = {
  menuList: [
    MESSAGE_ELEMENT_TYPE.TEXT,
    MESSAGE_ELEMENT_TYPE.BUTTON_LINK
  ]
}

const ConciergeCustomContent = ({
  formikProps,
  specificCaseData,
  currentSiteId
}) => {
  const [specificCaseList, setSpecificCaseList] = useState([]);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [selectedSpecificCase, setSelectedSpecificCase] = useState(null);
  // const [openResponseBuilderModal, setOpenResponseBuilderModal] = useState(false);

  useEffect(() => {
    const newList = [{
      value: null,
      label: '-- Choiser un cas spécifique pour copier le reponse --',
    }];
    if (specificCaseData) {
      for (const specifiCase of specificCaseData?.items) {
        const caseCompanySites = _.map(specifiCase.company_sites, (site) => site.id);
        if (!_.includes(caseCompanySites, parseInt(currentSiteId))) continue;
        newList.push({
          value: specifiCase.id,
          label: specifiCase.df_action
        });
      }
    }
    setSpecificCaseList(newList);
  }, [specificCaseData, currentSiteId]);

  const handleCaseSelect = (value) => {
    if (value !== null) {
      setSelectedSpecificCase(value);
      setShowWarningModal(true);
    }
  }

  const handleCloseWarning = () => {
    setSelectedSpecificCase(null);
    setShowWarningModal(false);
  }

  const handleOnConfirm = () => {
    const specifiCase = _.find(specificCaseData, (data) => data.id === selectedSpecificCase);
    if (specifiCase) {
      const response =  JSON.parse(specifiCase.reply);
      if (response[0]) {
        formikProps.setFieldValue('conciergeContentmessageElements', response[0].messageElements)
      }
    }
    handleCloseWarning();
  }

  return (
    <MainContainer>
      <GenericMaterialMenu
        list={specificCaseList}
        onChange={(value) => handleCaseSelect(value)}
      />
      <ResponseBuilder
        messageElements={formikProps.values.conciergeContentmessageElements}
        responseBuilderSetting={responseBuilderSetting}
        siteId={null}
        parentIndex={0}
        saveResponse={(index, newMessageElements) => formikProps.setFieldValue('conciergeContentmessageElements', newMessageElements)}
      />
      <GenericWarningModal
        open={showWarningModal}
        setOpenDialog={handleCloseWarning}
        title={"Attention"}
        text={"Êtes-vous sûr de vouloir copier la réponse de ce cas spécifique (la réponse actuelle sera remplacée)"}
        leftButtonText={"Annuler"}
        rightButtonText={"Oui"}
        leftButtonFunction={handleCloseWarning}
        rightButtonFunction={handleOnConfirm}
      />
      {/* <OpenResponseBuilderModalButton onClick={() => setOpenResponseBuilderModal(true)}>
        <div>Modifier la reponse</div>
        <OpenInNewIcon style={{ fontSize: 18 }} />
      </OpenResponseBuilderModalButton>
      {openResponseBuilderModal &&
        <StyledDialog open={true} onClose={() => setOpenResponseBuilderModal(false)}>
          <DialogContent>
            <ResponseBuilder
              messageElements={formikProps.values.conciergeContentmessageElements}
              responseBuilderSetting={responseBuilderSetting}
              siteId={null}
              parentIndex={0}
              saveResponse={(index, newMessageElements) => formikProps.setFieldValue('conciergeContentmessageElements', newMessageElements)}
            />
          </DialogContent>
        </StyledDialog>
      } */}
    </MainContainer>
  );
};

export default ConciergeCustomContent;