import React from "react";

const SendIcon = ({
  color = "#EDEDED",
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon sendIcon"
      width={width}
      height={height}
      viewBox="0 0 20 18"
      fill="none"
    >
      <path
        d="M18.809 7.243L2.672.647A1.944 1.944 0 00.668.966a1.875 1.875 0 00-.607 1.908l1.436 5.549H8.53c.324 0 .586.258.586.577a.582.582 0 01-.586.577H1.497L.061 15.126c-.182.702.05 1.434.607 1.908a1.943 1.943 0 002.004.32l16.137-6.597A1.873 1.873 0 0020 9c0-.784-.456-1.457-1.191-1.757z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export default SendIcon;
