import React, { useState, useEffect, useRef } from 'react';
import {
  useParams,
} from "react-router-dom";
import {
  shallowEqual,
  useDispatch,
  useSelector,
} from "react-redux";
import _ from 'lodash';

// Layout
import DetailsSidebarFrontBaseLayout from "../../layouts/detailsSidebarFrontBaseLayout";

import { jsonSafeParse } from '../../../../helpers/jsonSafeParse';

// Components
import BackIcon from '../../components/atoms/icons/back';
import SendIcon from '../../components/atoms/icons/send';

import defaultImage from '../../../../assets/images/logo_cyconia_rond.png';

import Loader from '../../../../components/molecules/GenericLoader';

// Hooks
import useTheme from "../../../../theme-style/useThemeCustomHook";

// Store
import {
  getServiceCategoryList,
} from '../../../../store/sidebar/actions';

import {
  getServiceCategoryListData,
  getServiceCategoryListIsLoading,
} from '../../../../store/sidebar/selectors';

import {
  getCompanyData
} from '../../../../store/company/selectors';

import {
  setMessageFromSideBar
} from '../../../../store/chatbot/actions';

// Styles
import {
  Container,
  ServicesListWrapper,
  Header,
  BackButton,
  Title,
  BackIconWrapper,
  ServicesListe,
  ServicesItem,
  ServicesItemPicture,
  PictureBox,
  ServicesItemContent,
  ServicesItemTitle,
  ServicesItemText,
  SendIconWrapper,
} from "./style";

const ConciergerieServicesList = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { id } = useParams();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const containerRef = useRef();

  const {
    serviceCategoryListData,
    serviceCategoryListIsLoading,
    companyData
  } = useSelector(
    state => ({
      serviceCategoryListData: getServiceCategoryListData(state),
      serviceCategoryListIsLoading: getServiceCategoryListIsLoading(state),
      companyData: getCompanyData(state)
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!serviceCategoryListData) {
      dispatch(getServiceCategoryList());
    }
  }, []);


  useEffect(() => { // For auto scroll to view
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: 'auto' });
    }
  }, [selectedCategory]);

  useEffect(() => {
    const selectedCategoryKey = _.findKey(serviceCategoryListData, (category) => category.category_id == id);
    if (selectedCategoryKey) {
      const services = serviceCategoryListData[selectedCategoryKey].services;
      if (companyData.serviceVersion === 2) {
        _.map(services, (service) => {
          const content = jsonSafeParse(service.content_custom);
          service.title = content?.serviceName || '-';
          service.short_description = content?.description || '-';
          service.illustration_phrase = content?.illustrationPhrase || '-';
          service.thumbnail = service.icon;
        });
      }
      setSelectedCategory({
        title: selectedCategoryKey,
        ...serviceCategoryListData[selectedCategoryKey],
        services
      })
    }
  }, [id, companyData, serviceCategoryListData]);

  const onSkillsItemClick = (message) => {
    dispatch(setMessageFromSideBar(message));
  }

  return (
    <DetailsSidebarFrontBaseLayout
      id="detailsSidebarLayoutBorneServiceCategoryList"
      className="detailsSidebarLayoutBorneServiceCategoryList page-container sidebarPage"
    >
      <Container ref={containerRef}>
        {serviceCategoryListIsLoading || !selectedCategory ?
          <Loader />
          :
          <ServicesListWrapper>

            <Header to="/conciergerie">
              <BackButton>
                <BackIconWrapper>
                  <BackIcon color={theme.colors.white} />
                </BackIconWrapper>
              </BackButton>
              <Title>
                {selectedCategory.title}
              </Title>
            </Header>

            <ServicesListe>
              {selectedCategory && _.map(selectedCategory.services, (service, index) => {
                const {
                  title,
                  thumbnail,
                  short_description,
                  illustration_phrase
                } = service;

                return (
                  <ServicesItem key={index} onClick={() => onSkillsItemClick(illustration_phrase || '')}>
                    <ServicesItemPicture>
                      <PictureBox image={thumbnail} defaultImage={defaultImage} />
                    </ServicesItemPicture>

                    <ServicesItemContent>
                      <ServicesItemTitle>
                        {title || ''}
                      </ServicesItemTitle>
                      <ServicesItemText>
                        {short_description || ''}
                      </ServicesItemText>
                    </ServicesItemContent>

                    <SendIconWrapper>
                      <SendIcon />
                    </SendIconWrapper>
                  </ServicesItem>
                )
              })}
            </ServicesListe>

          </ServicesListWrapper>
        }
      </Container>

    </DetailsSidebarFrontBaseLayout>
  );
}

export default ConciergerieServicesList;
