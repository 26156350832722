import React from 'react';

import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';

// Styles
import {
  Container,
  TableCellCustom,
  TableSortLabelCustom,
  TableSortLabelContent,
  TableHeadCustom
} from "./style";

const EnhancedTableHead = ({
  // props,
  classes,
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  id = 'Title headCell',
  numeric = false,
  disablePadding = false,
  label = null,
  titleToolbar = 'Title toolbar',
  checkboxActive = false
}) => {

  const headCells = [
    { id: "date", numeric: false, disablePadding: false, label: "Date" },
    { id: "site", numeric: false, disablePadding: false, label: "Site d'entreprise" },
    { id: "competence", numeric: false, disablePadding: false, label: "Compétence" },
    { id: "info", numeric: false, disablePadding: false, label: "Éléments d'information" },
    // { id: "collaborator", numeric: false, disablePadding: false, label: "Collaborateurs" },
    { id: "type", numeric: false, disablePadding: false, label: "Type de remarque" },
    { id: "feedback", numeric: false, disablePadding: false, label: "Remarque(s)" },
    // {id: {id}, numeric: {numeric}, disablePadding: {disablePadding}, label: {label} }
  ];

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHeadCustom>
      <TableRow>
        {checkboxActive && (
          <TableCellCustom padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
            />
          </TableCellCustom>
        )}
        {headCells.map((headCell) => (
          <TableCellCustom
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            // sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabelCustom
              active={false}
              hideSortIcon={true}
              // active={orderBy === headCell.id}
              // direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label ? headCell.label : 'Label'}
              {/*orderBy === headCell.id ? (
                <TableSortLabelContent>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </TableSortLabelContent>
              ) : null*/}
            </TableSortLabelCustom>
          </TableCellCustom>
        ))}
      </TableRow>
    </TableHeadCustom>
  );
}

export default EnhancedTableHead;
