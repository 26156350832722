import * as a from "./actions";

const INITIAL_STATE = {
  user: null,
  collaborator: null,
  getUser: {
    loading: false,
    success: false,
    error: null,
  },
  getCollaborator: {
    loading: false,
    success: false,
    error: null,
  },
  saveTransports: {
    data: null,
    save: {
      loading: false,
      success: false,
      error: null,
    }
  },
  saveProfile: {
    data: null,
    save: {
      loading: false,
      success: false,
      error: null,
    }
  },
  saveInformations: {
    data: null,
    save: {
      loading: false,
      success: false,
      error: null,
    }
  }
};

export function collaborator(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    // USER UPDATE LOCAL INFOS
    case a.UPDATE_LOCAL_USER_INFOS.SUCCESS:
      return {
        ...state,
        user: action.user,
      };
    // USER
    case a.GET_USER.REQUEST:
      return {
        ...state,
        getUser: {
          loading: true,
          success: false,
          error: null,
        }
      };
    case a.GET_USER.SUCCESS:
      return {
        ...state,
        user: action.user,
        getUser: {
          loading: false,
          success: true,
          error: null,
        }
      };
    case a.GET_USER.FAILURE:
      return {
        ...state,
        user: null,
        getUser: {
          loading: false,
          success: false,
          error: action.error,
        }
      };
    // COLLABORATOR
    case a.GET_COLLABORATEUR.REQUEST:
      return {
        ...state,
        collaborator: null,
        getCollaborator: {
          loading: true,
          success: false,
          error: null,
        }
      };
    case a.GET_COLLABORATEUR.SUCCESS:
      return {
        ...state,
        collaborator: action.collaborator,
        getCollaborator: {
          loading: false,
          success: true,
          error: null,
        }
      };
    case a.GET_COLLABORATEUR.FAILURE:
      return {
        ...state,
        collaborator: null,
        getCollaborator: {
          loading: false,
          success: false,
          error: action.error,
        }
      };
    // SAVE TRANSPORTS
    case a.SAVE_COLLABORATEUR_TRANSPORTS.REQUEST:
      return {
        ...state,
        saveTransports: {
          data: null,
          save: {
            loading: true,
            success: false,
            error: null,
          }
        },
      };
    case a.SAVE_COLLABORATEUR_TRANSPORTS.SUCCESS:
      return {
        ...state,
        user: action.data,
        saveTransports: {
          data: action.data,
          save: {
            loading: false,
            success: true,
            error: null,
          }
        },
      };
    case a.SAVE_COLLABORATEUR_TRANSPORTS.SUCCESS:
      return {
        ...state,
        saveTransports: {
          data: null,
          save: {
            loading: false,
            success: false,
            error: action.error,
          }
        },
      };
    case a.SAVE_COLLABORATEUR_TRANSPORTS.RESET:
      return {
        ...state,
        saveTransports: {
          ...state.saveTransports,
          save: INITIAL_STATE.saveTransports.save,
        },
      };
    // SAVE PROFILE
    case a.SAVE_COLLABORATEUR_PROFILE.REQUEST:
      return {
        ...state,
        saveProfile: {
          data: null,
          save: {
            loading: true,
            success: false,
            error: null,
          }
        },
      };
    case a.SAVE_COLLABORATEUR_PROFILE.SUCCESS:
      return {
        ...state,
        user: action.data,
        saveProfile: {
          data: action.data,
          save: {
            loading: false,
            success: true,
            error: null,
          }
        },
      };
    case a.SAVE_COLLABORATEUR_PROFILE.SUCCESS:
      return {
        ...state,
        saveProfile: {
          data: null,
          save: {
            loading: false,
            success: false,
            error: action.error,
          }
        },
      };
    case a.SAVE_COLLABORATEUR_PROFILE.RESET:
      return {
        ...state,
        saveProfile: {
          ...state.saveProfile,
          save: INITIAL_STATE.saveProfile.save,
        },
      };
    // SAVE INFORMATIONS
    case a.SAVE_COLLABORATEUR_INFORMATIONS.REQUEST:
      return {
        ...state,
        saveInformations: {
          data: null,
          save: {
            loading: true,
            success: false,
            error: null,
          }
        },
      };
    case a.SAVE_COLLABORATEUR_INFORMATIONS.SUCCESS:
      const {
        firstname, lastname, email
      } = action.data;
      return {
        ...state,
        user: {
          ...state.user,
          firstname,
          lastname,
          email,
        },
        saveInformations: {
          data: action.data,
          save: {
            loading: false,
            success: true,
            error: null,
          }
        },
      };
    case a.SAVE_COLLABORATEUR_INFORMATIONS.FAILURE:
      return {
        ...state,
        saveInformations: {
          data: null,
          save: {
            loading: false,
            success: false,
            error: action.error,
          }
        },
      };
    case a.SAVE_COLLABORATEUR_INFORMATIONS.RESET:
      return {
        ...state,
        saveInformations: {
          ...state.saveInformations,
          save: INITIAL_STATE.saveInformations.save,
        },
      };
    // CLEAR
    case a.CLEAR_USER_DATA:
      return INITIAL_STATE;
    default:
      return state;
  }
}

export default collaborator;
