import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Checkbox from '@material-ui/core/Checkbox';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import {
  shallowEqual,
  useDispatch,
  useSelector,
} from "react-redux";

// Layout
import DetailsSidebarFrontBaseLayout from "../../layouts/detailsSidebarFrontBaseLayout";

// Components
import BackIcon from '../../components/atoms/icons/back';
// import ProfileIcon from '../../components/atoms/icons/profile';
import MetroIcon from '../../components/atoms/icons/metro';
import BikeIcon from '../../components/atoms/icons/bike';
import CarIcon from '../../components/atoms/icons/car';
import WalkIcon from '../../components/atoms/icons/walk';

import {
  saveUserTransports,
} from '../../../../store/collaborator/actions';
import {
  getUser as getUserAction,
} from '../../../../store/collaborator/actions';
import {
  isSaveCollaboratorTransportsOnLoad,
  isSaveCollaboratorTransportsOnError,
  isSaveCollaboratorTransportsOnSuccess,
  getUser,
} from '../../../../store/collaborator/selectors';

// Hooks
import useTheme from "../../../../theme-style/useThemeCustomHook";

// Styles
import {
  Container,
  TrajetsWrapper,
  Header,
  BackButton,
  Title,
  BackIconWrapper,
  TrajetsForm,
  TrajetsFormInput,
  ButtonCptSave,
  SearchIconWrapper,
  FormControlCustom,
  ProfileIconWrapper,
  InputFieldSearch,
  GoogleSuggestPersonal,
  GoogleSuggestProfessional
} from "./style";

const AccountTrajets = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    saveOnLoad,
    saveOnSuccess,
    saveOnError,
    user,
  } = useSelector(
    state => ({
      saveOnLoad: isSaveCollaboratorTransportsOnLoad(state),
      saveOnSuccess: isSaveCollaboratorTransportsOnSuccess(state),
      saveOnError: isSaveCollaboratorTransportsOnError(state),
      user: getUser(state),
    }),
    shallowEqual
  );
  const [valueTrajets, setValueTrajets] = useState({
    subway: false,
    walk: false,
    bike: false,
    car: false,
  });
  const [address, setAddress] = useState('');
	const [lat, setLat] = useState(0);
	const [lng, setLng] = useState(0);
	const [officeAddress, setOfficeAddress] = useState("");
	const [officeLat, setOfficeLat] = useState(0);
	const [officeLng, setOfficeLng] = useState(0);

  useEffect(() => {
    dispatch(getUserAction());
  }, []);

  useEffect(() => {
    if (user) {
      const {
        address,
        lat,
        lng,
        office_address,
        office_lat,
        office_lng,
        transport_subway,
        transport_walk,
        transport_bike,
        transport_car
      } = user;
      setValueTrajets({
        subway: transport_subway,
        walk: transport_walk,
        bike: transport_bike,
        car: transport_car,
      });
      setAddress(address);
      setLat(lat);
      setLng(lng);
      setOfficeAddress(office_address);
      setOfficeLat(office_lat);
      setOfficeLng(office_lng)
    }
  }, [user]);

  const onSetHomeddress = (address, lat, lng) => {
		setAddress(address);
		setLat(lat);
		setLng(lng);
	};

	const onSetOfficeAddress = (officeAddress, officeLat, officeLng) => {
		setOfficeAddress(officeAddress);
		setOfficeLat(officeLat);
		setOfficeLng(officeLng);
	};

  const onSubmit = e => {
    if (e) {
      e.preventDefault();
    }
    dispatch(saveUserTransports(
      address === null ? '' : address,
			lat,
			lng,
			officeAddress === null ? '' : officeAddress,
			officeLat,
			officeLng,
			valueTrajets,
    ));
  };

  useEffect(() => {
    if (saveOnSuccess) {
      toast.success("Vos modifications ont bien été enregistrées.", {
        position: toast.POSITION.TOP_RIGHT,
        position: toast.POSITION.TOP_RIGHT,
        className: "custom-toast custom-toast-success",
        bodyClassName: "custom-toast__body custom-toast__body-success",
        progressClassName: "custom-toast__progress"
      });
    }
  }, [saveOnSuccess]);

  useEffect(() => {
    if (saveOnError) {
      toast.error("Une erreur est survenue, merci de vérifier votre connection avant de ré-essayez.", {
        position: toast.POSITION.TOP_RIGHT,
        className: "custom-toast custom-toast-error",
        bodyClassName: "custom-toast__body custom-toast__body-success",
        progressClassName: "custom-toast__progress"
      });
    }
  }, [saveOnError]);

  const handleChangeTrajets = (event) => {
    setValueTrajets({ ...valueTrajets, [event.target.name]: event.target.checked });
  };

  const { subway, walk, bike, car } = valueTrajets;

  const ProfilIconLabel = ({children, iconTag}) => {
    return (
      <>
        {children}
        <ProfileIconWrapper className={`traffic_type_${iconTag}`}>
          {iconTag === "subway" ? <MetroIcon color={theme.colors.grey2}/> :
            iconTag === "walk" ? <WalkIcon color={theme.colors.grey2}/> : 
            iconTag === "bike" ? <BikeIcon color={theme.colors.grey2}/> :
            <CarIcon color={theme.colors.grey2}/>
          }
        </ProfileIconWrapper>
      </>
    );
  }
    
  return (
    <>
      {/* <ToastContainer
        autoClose={8000}
        className="custom-toast-container custom-toast-container__success"
      /> */}
      <DetailsSidebarFrontBaseLayout
        id="detailsSidebarLayoutAccountTrajets"
        className="detailsSidebarLayoutAccountTrajets page-container sidebarPage"
      >
        <Container>
          <TrajetsWrapper>
            <Header to="/compte">
              <BackButton>
                <BackIconWrapper>
                  <BackIcon color={theme.colors.white}/>
                </BackIconWrapper>
              </BackButton>
              <Title>
                Mes trajets
              </Title>
            </Header>
            <TrajetsForm>
              <TrajetsFormInput noValidate autoComplete="off">
                <GoogleSuggestPersonal
                  setAddress={onSetHomeddress}
                  defaultValue={user?.address !== null ? user?.address : ""}
                  label="Votre adresse personnelle"
                />
                <FormControlCustom component="fieldset">
                  <FormLabel component="label">
                    Votre moyen de transport
                  </FormLabel>
                  <RadioGroup>
                    <FormControlLabel
                      value="subway"
                      control={<Checkbox checked={subway} onChange={handleChangeTrajets} name="subway" />}
                      label={<ProfilIconLabel iconTag="subway">En transport</ProfilIconLabel>}
                    />
                    <FormControlLabel
                      value="walk"
                      control={<Checkbox checked={walk} onChange={handleChangeTrajets} name="walk" />}
                      label={<ProfilIconLabel iconTag="walk">En marchant</ProfilIconLabel>}
                    />
                    <FormControlLabel
                      value="bike"
                      control={<Checkbox checked={bike} onChange={handleChangeTrajets} name="bike" />}
                      label={<ProfilIconLabel iconTag="bike">À vélo</ProfilIconLabel>}
                    />
                    <FormControlLabel
                      value="car"
                      control={<Checkbox checked={car} onChange={handleChangeTrajets} name="car" />}
                      label={<ProfilIconLabel iconTag="car">En voiture</ProfilIconLabel>}
                    />
                  </RadioGroup>
                </FormControlCustom>
                <GoogleSuggestProfessional
                  setAddress={onSetOfficeAddress}
                  defaultValue={user?.office_address ? user?.office_address : ""}
                  label="Votre adresse professionnelle"
                />
              </TrajetsFormInput>
              <ButtonCptSave
                label={saveOnLoad ? 'LOADING...' : 'Enregistrer'}
                appearence="sidebarFrontSecondary"
                onClick={onSubmit}
                disabled={saveOnLoad}
              />
            </TrajetsForm>
          </TrajetsWrapper>
        </Container>
      </DetailsSidebarFrontBaseLayout>
    </>
  );
}

export default AccountTrajets;
