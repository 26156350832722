import * as a from "./actions";

const INITIAL_STATE = {
  data: null,
  init: {
    loading: false,
    success: false,
    error: null,
  },
  modules: {
    data: null,
    get: {
      loading: false,
      success: false,
      error: null,
    },
  },
  context: {
    data: null,
    get: {
      loading: false,
      success: false,
      error: null,
    },
  }
};

export function company(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    // INIT
    case a.INIT.REQUEST:
      return {
        ...state,
        init: {
          loading: true,
          success: false,
          error: null,
        },
      };
    case a.INIT.SUCCESS:
      return {
        ...state,
        data: action.data,
        init: {
          loading: false,
          success: true,
          error: null,
        },
      };
    case a.INIT.FAILURE:
      return {
        ...state,
        init: {
          loading: false,
          success: false,
          error: action.error,
        },
      };
    // GET MODULES
    case a.GET_MODULES.REQUEST:
      return {
        ...state,
        modules: {
          data: null,
          get: {
            loading: true,
            success: false,
            error: null,
          }
        },
      };
    case a.GET_MODULES.SUCCESS:
      return {
        ...state,
        modules: {
          data: action.data,
          get: {
            loading: false,
            success: true,
            error: null,
          }
        },
      };
    case a.GET_MODULES.FAILURE:
      return {
        ...state,
        modules: {
          data: null,
          get: {
            loading: false,
            success: false,
            error: action.error,
          }
        },
      };
    // GET CONTEXT
    case a.GET_COMPANY_CONTEXT.REQUEST:
      return {
        ...state,
        context: {
          data: null,
          get: {
            loading: true,
            success: false,
            error: null,
          }
        },
      };
    case a.GET_COMPANY_CONTEXT.SUCCESS:
      return {
        ...state,
        context: {
          data: action.data,
          get: {
            loading: false,
            success: true,
            error: null,
          }
        },
      };
    case a.GET_COMPANY_CONTEXT.FAILURE:
      return {
        ...state,
        context: {
          data: null,
          get: {
            loading: false,
            success: false,
            error: action.error,
          }
        },
      };
    // Save company setting
    case a.SAVE_COMPANY_SETTINGS.REQUEST:
      return {
        ...state,
        init: {
          loading: true,
          success: false,
          error: null,
        },
      };
    case a.SAVE_COMPANY_SETTINGS.SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.data,
          settings: JSON.parse(action.data.settings),
        },
        init: {
          loading: false,
          success: true,
          error: null,
        },
      };
    case a.SAVE_COMPANY_SETTINGS.FAILURE:
      return {
        ...state,
        init: {
          loading: true,
          success: false,
          error: null,
        },
      };
    default:
      return state;
  }
}

export default company;
