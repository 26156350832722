import styled from "styled-components";
import { NavLink } from "react-router-dom";

import breakpoints from "../../../../../theme-style/breakpoints";
import GenericTitleWithToolTip from '../../../../../components/molecules/GenericTitleWithToolTip';

export const MainContainer = styled.div`
`;

export const StyledTitleWithToolTip = styled(GenericTitleWithToolTip)`
  color: white;
  font-size: 16px;
  font-weight: 700;
  padding: 0 0 8px 0;
  @media ${breakpoints.mobileXL} {
    padding: 0 0 15px 0;
  }
`;

export const ServicesWrapper = styled.div`
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 15px;

  @media ${breakpoints.mobileXL} {
    margin-bottom: 20px;
  }
`;

export const ServicesHead = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  background-color: #FFFFFF;
  border-bottom: 1px solid #F4F4F4;
  padding: 12px 15px;

  @media ${breakpoints.mobileXL} {
    padding: 15px 25px;
  }
`;

export const ServicesHeadCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #40B176;
  border-radius: 100px;
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;
  margin-right: 25px;
`;

export const LotusIconWrapper = styled.span`
  max-width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
`;

export const ServicesHeadTitle = styled.strong`
  font-size: 16px;
  color: #40B176;
  font-weight: 600;
  text-align: left;
`;

export const ServicesList = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 0;
  margin: 0;
`;

export const ServicesItemTitle = styled.strong`
  font-weight: 600;
  font-size: 14px;
  color: #5A5A5A;
  transition: all .2s ease-out;
`;

export const ArrowIconWrapper = styled.span`
  max-width: 7px;
  max-height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 auto;
  svg path{
    transition: all .2s ease-out;
  }
`;

export const ServicesItem = styled(NavLink)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 15px 10px 10px;
  margin: 0;
  background-color: #FFFFFF;
  height: auto;
  cursor: pointer;
  border-bottom: 1px solid #F4F4F4;
  transition: all .2s ease-out;
  text-decoration: none;
  list-style: none;
  padding: 10px 15px 10px 10px;
  @media ${breakpoints.mobileXL} {
    padding: 15px 20px 15px 15px;
  }
  &:last-child{
    border-bottom: 0;
  }
  &:hover{
    background-color: #FAFAFA;
    ${ServicesItemTitle}{
      color: #40b176;
    }
    ${ArrowIconWrapper}{
      svg{
        path{
          fill: #40b176;
          stroke: #40b176;
        }
      }
    }
  }
`;

export const ServicesItemPicture = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  min-width: 50px;
  height: 50px;
  min-height: 50px;
  border-radius: 100px;
  background-color: #40B176;
  font-size: 24px;
  color: #FFFFFF;
  font-weight: 700;
  margin-right: 15px;
`;

export const PictureBox = styled.div`
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
`;

export const ServicesItemContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const ServicesItemText = styled.p`
  margin: 4px 0 0 0;
  font-size: 12px;
  color: #BBBBBB;
  line-height: 14px;
  font-weight: 600;
`;

export const LoaderContainer = styled.div`
  background-color: #FFFFFF;
  padding: 10px;
`;