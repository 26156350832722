import styled from "styled-components";
import DrawerCpt from '../../../../components/organisms/drawerCpt'
import { COLORS } from '../../../../../../constants/design';
import GenericMaterialMenu from '../../../../../../components/molecules/GenericMaterialMenu'

export const Container = styled.div`
`;


export const DrawerCptCustom = styled(DrawerCpt)`
  &&{
    background-color: red;
    &.contentManagementDrawer{
      /* background-color: blue; */
    }
  }
`;

export const DrawerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 700px;
  max-height: 1700px;
  position: relative;
  .textTitle {
    font-weight: 600;
    font-size: 0.9rem;
    color: ${COLORS.DARK_GREY};
    margin: 14px 5px 14px 0px;
  };
  .textTooltip {
    color: ${COLORS.GREY_1};
  }
  .textFieldInput {
    input {
      font-size: 0.9rem;
    }
  };
  .menuInput {
    /* background-color: red; */
    background-color: ${COLORS.WHITE};
    border-color: ${COLORS.LIGHT_GREY_3};
    color: ${COLORS.DARK_GREY}
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  >div:not(:last-child) {
    margin-bottom: 15px;
  };
`;

export const FormSection = styled.div`
  span {
    font-size: 0.8rem;
    color: ${COLORS.DARK_GREY};
  }
  .textTitle {
    font-weight: 600;
    font-size: 1rem;
  };
`;

export const ImagePreviewContainer = styled.div`
  width: 350px;
  height: 300px;
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
`;

export const SitesSelectorBox = styled.div`
  margin-bottom: 10px;
`;

export const StyledGenericMaterialMenu = styled(GenericMaterialMenu)`
  .textFieldTitle {
    margin-bottom: 10px;
  }
`;