import axios from 'axios';

export const editCollaboratorService = (endpoint, userToken, formData) => {

  const newForm = new FormData();
  newForm.append('block', formData.is_blocked ? 1 : 0);
  newForm.append('availableSites', formData.available_company_sites);
  newForm.append('site', formData.site);

  const headers = {};
  if (userToken) {
    headers.Authorization = `Bearer ${userToken}`;
  }

  return axios.request({
    method: 'post',
    url: endpoint,
    data: newForm,
    headers: headers
  })
}

export const switchCollaboratorSiteService = (endpoint, userToken, formData) => {

  const newForm = new FormData();
  newForm.append('site', formData.site);

  const headers = {};
  if (userToken) {
    headers.Authorization = `Bearer ${userToken}`;
  }

  return axios.request({
    method: 'post',
    url: endpoint,
    data: newForm,
    headers: headers
  })
}