import styled from 'styled-components';
import Button from '@material-ui/core/Button';

import { COLORS } from '../../../../../../constants/design';

export const StyledContainer = styled.div`
  /* border: 1px solid black; */
  cursor: pointer;
  height: 50px;
  display: flex;
  align-items: center;
`;

export const StyledEditButton = styled(Button)`
  && {
    &.MuiButton-root {
      color: ${COLORS.WHITE};
      position: relative;
      font-family: Quicksand;
      font-size: 0.8rem;
      background-color: ${COLORS.PRIMARY_BLUE};
      width: 80px;
      transition: all ease-in .15s;
      border: transparent;
      border-radius: 5px;
      background-image: none;
      text-transform: none;
    }
  }
`;

export const StyledItemBlock = styled.div`
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  min-width: 80px;
`;

export const StyledActiveStatus = styled.span`
  /* border: 1px solid red; */
  color: ${props => props.active ? COLORS.GREEN : COLORS.RED_3};
`;