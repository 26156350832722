import styled from "styled-components";
import DrawerCpt from '../../../../components/organisms/drawerCpt';
import { COLORS } from '../../../../../../constants/design';

export const Container = styled.div`
`;

export const DrawerCptCustom = styled(DrawerCpt)`
`;

export const DrawerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 700px;
  max-height: 1700px;
  position: relative;
  .textTitle {
    font-weight: 700;
    font-size: 13px;
    color: #5A5A5A;
    margin: 14px 5px 14px 0px;
  }
  .textTooltip {
    color: ${COLORS.GREY_1};
  }
  .textFieldInput {
    input {
      font-size: 13px;
    }
  }
  .genericMultipleSelect {
    max-width: 660px;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  >div:not(:last-child) {
    margin-bottom: 15px;
  };
`;