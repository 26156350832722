import React, { useEffect } from "react";
import {
  Redirect,
  useParams,
  useHistory
} from "react-router-dom";
import {
  shallowEqual,
  useDispatch,
  useSelector
} from "react-redux";

import {
  isUserTokenValid
} from '../../../../../../store/authentication/selectors';

import { logout } from '../../../../../../store/authentication/actions';

import {
  azureadLogin
} from '../../../../../../store/sso/actions';

import {
  getAzureadSuccess,
  getAzureadIsError
} from '../../../../../../store/sso/selectors';

import {
  getCompanyData
} from '../../../../../../store/company/selectors';

import Loader from "../../../../../../components/atoms/lottie/Loader";

const AzureadAuth = () => {
  const { jwtToken } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const redirect = new URLSearchParams(window.location.search).get(
    'Redirect'
  );

  // console.log('jwtToken', jwtToken);
  // console.log('redirect', redirect);

  const {
    isTokenValid,
    azureadSuccess,
    azureadError,
    companyData
  } = useSelector(
    state => ({
      isTokenValid: isUserTokenValid(state),
      azureadSuccess: getAzureadSuccess(state),
      azureadError: getAzureadIsError(state),
      companyData: getCompanyData(state)
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(logout());
  }, []);

  useEffect(() => {
    if (jwtToken) {
      const formData = { jwtToken }
      dispatch(azureadLogin(formData));
    }
    else {
      <Redirect
        to={{
          pathname: '/login',
          state: { from: history.location }
        }}
      />
    }
  }, []);

  if (!companyData.azureSso || azureadError) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: history.location }
        }}
      // to={{
      //   pathname: "/sso/hqo-gecina/restricted",
      //   state: {
      //     from: history.location,
      //     error: {
      //       message: azureadError ? 'Login Error' : 'Pas activé'
      //     }
      //   }
      // }}
      />
    )
  }
  if (isTokenValid && azureadSuccess) {
    return (
      <Redirect
        to={{
          pathname: redirect || '/landing',
          state: { from: history.location }
        }}
      />
    )
  }
  else {
    return (
      <Loader />
    );
  }
};

export default AzureadAuth;