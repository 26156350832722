import React, {useEffect, useState} from 'react';
import {
  shallowEqual,
  useDispatch,
  useSelector,
} from "react-redux";

// Import services
import getUntreatedFbStats from '../../services/getUntreatedFeedbackStats.service';

// Components
import DonutChart from '../../../../components/molecules/donutChart';

// Styles
import {
  Container,
  Title,
  Content,
  DonutChartType
} from "./style";


import {
  getUntreatedFeedbackStatsData,
} from '../../../../../../store/adminFront/selectors';
import {
  getUntreatedFeedbackStats
} from '../../../../../../store/adminFront/actions';

const FeedbackUntreatedStats = () => {
  const dispatch = useDispatch();
  const {
    untreatedFeedbackStats,
  } = useSelector(
    state => ({
      untreatedFeedbackStats: getUntreatedFeedbackStatsData(state),
    }),
    shallowEqual
  );

  useEffect(() => {
      dispatch(getUntreatedFeedbackStats());
  }, []);

  // console.log(untreatedFeedbackStats);
  const totalFeedback  = untreatedFeedbackStats?.total;
  const incompleteFeedback = untreatedFeedbackStats?.incompleteStatus;
  const wrongFeedback = untreatedFeedbackStats?.wrongStatus;
  const hsFeedback = untreatedFeedbackStats?.hsStatus;

  // console.log('untreatedFeedbackStats', untreatedFeedbackStats);

  return (
      <Container>
        <Title>
            Les remarque(s) non traitée(s)
        </Title>
        <Content>

          <DonutChart
            value={totalFeedback}
            globalValue={totalFeedback}
            size={56}
            strokeWidth={8}
            colorText={'#ffffff'}
            strokeColorBg={'rgba(0,0,0,0.1)'}
            strokeColorValue={'#357ED3'}
            valueLabelOutside={'au total'}
          />

          <DonutChartType
            value={incompleteFeedback}
            globalValue={totalFeedback}
            size={42}
            strokeWidth={6}
            colorText={'#ffffff'}
            strokeColorBg={'rgba(0,0,0,0.1)'}
            strokeColorValue={'#40B176'}
            valueLabelOutside={'Incomplete(s)'}
          />
          <DonutChartType
            value={wrongFeedback}
            globalValue={totalFeedback}
            size={42}
            strokeWidth={6}
            colorText={'#ffffff'}
            strokeColorBg={'rgba(0,0,0,0.1)'}
            strokeColorValue={'#FF9F6D'}
            valueLabelOutside={'Incorrecte(s)'}
          />
          <DonutChartType
            value={hsFeedback}
            globalValue={totalFeedback}
            size={42}
            strokeWidth={6}
            colorText={'#ffffff'}
            strokeColorBg={'rgba(0,0,0,0.1)'}
            strokeColorValue={'#EC6B87'}
            valueLabelOutside={'Hors sujet(s)'}
          />
        </Content>
      </Container>
  );
}

export default FeedbackUntreatedStats;
