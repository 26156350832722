import React, {useEffect, useState} from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import {
  shallowEqual,
  useDispatch,
  useSelector,
} from "react-redux";

// Import services
// import getUntreatedFbStats from '../../services/getUntreatedFeedbackStats.service';
import {
  getUntreatedFeedbackStatsData,
} from '../../../../../../store/adminFront/selectors';
import {
  getUntreatedFeedbackStats,
} from '../../../../../../store/adminFront/actions';

// Components
import DonutChart from '../../../../components/molecules/donutChart';

// Icons
import ChatCommunicationIllustration from '../../../../components/atoms/illustrations/chatCommunicationIllustration';

// Styles
import {
  Container,
  Title,
  Content,
  FeedbackTypeList,
  FeedbackTypeItem,
  FeedbackTypeIllustration,
  ChatCommunicationIllustrationWrapper
} from "./style";

const FeedbackUntreatedTypeStats = () => {
  const dispatch = useDispatch();
  // const [untreatedFeedbackStats, setUntreatedFeedbackStats] = useState([]);

  // useEffect(() => {
  //     getUntreatedFeedbackStats().then(data => setUntreatedFeedbackStats(data));
  // }, []);

  const {
    untreatedFeedbackStats,
  } = useSelector(
    state => ({
      untreatedFeedbackStats: getUntreatedFeedbackStatsData(state),
    }),
    shallowEqual
  );

  useEffect(() => {
      dispatch(getUntreatedFeedbackStats());
  }, []);

  // console.log(untreatedFeedbackStats);
  const totalFeedback  = untreatedFeedbackStats?.total;
  const incompleteFeedback = untreatedFeedbackStats?.incompleteStatus;
  const wrongFeedback = untreatedFeedbackStats?.wrongStatus;
  const hsFeedback = untreatedFeedbackStats?.hsStatus;

  return (
    <Container>
      <Title>
        Remarque(s) non traitée(s)
      </Title>
      <Content>
        <FeedbackTypeList>
          <FeedbackTypeItem>
            <span></span>
            <strong>{totalFeedback || 0}</strong>
            <p>non traitée(s) <strong>au total</strong></p>
          </FeedbackTypeItem>
          <FeedbackTypeItem>
            <span></span>
            <strong>{incompleteFeedback || 0}</strong>
            <p>
              <Tooltip title="La réponse donnée au collaborateur ne contient pas toutes les informations jugées nécessaires par le collaborateur.">
                <strong> incomplète(s)</strong>
              </Tooltip>
            </p>
          </FeedbackTypeItem>
          <FeedbackTypeItem>
            <span></span>
            <strong>{wrongFeedback || 0}</strong>
            <p>
              <Tooltip title="La réponse donnée au collaborateur n'est pas juste ou n'est plus à jour.">
                <strong> incorrecte(s)</strong>
              </Tooltip>
            </p>
          </FeedbackTypeItem>
          <FeedbackTypeItem>
            <span></span>
            <strong>{hsFeedback || 0}</strong>
            <p>
              <Tooltip title="La réponse indiquée ne répond pas à la demande du collaborateur.">
                <strong> hors-sujet</strong>
              </Tooltip>
            </p>
          </FeedbackTypeItem>
        </FeedbackTypeList>
        <FeedbackTypeIllustration>
          <ChatCommunicationIllustrationWrapper>
            <ChatCommunicationIllustration />
          </ChatCommunicationIllustrationWrapper>
        </FeedbackTypeIllustration>
      </Content>
    </Container>
  );
}

export default FeedbackUntreatedTypeStats;
