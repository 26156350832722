import styled from "styled-components";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';

import { COLORS } from '../../../../../../../../../../constants/design'


export const MainContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  /* max-height: 595px; // GOLDEN VALUE dont change! */
  /* overflow-y: auto;
  overflow-x: hidden; */
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
`;

export const StyledTabItem = styled.div`
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  align-items: flex-start;
`;

export const StyledDateBlock = styled.div`
  border: 1px solid ${COLORS.SLIGHT_GREY_2};
  background-color: ${COLORS.SLIGHT_GREY_2};
  padding: 5px 10px;
  border-radius: 6px;
  margin-right: 5px;
  color: ${COLORS.DARK_GREY};
  font-size: 0.7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledDateOnly = styled.div`
  font-size: 0.9rem;
  font-weight: 600;
`;

export const StyledAccordion = styled(Accordion)`
&& {
  &.MuiPaper-elevation1 {
    box-shadow: unset;
  }
  &.MuiAccordion-root {
    background-color: ${COLORS.SLIGHT_GREY_2};
    width: 100%;
    color: ${COLORS.DARK_GREY};
    ::before {
      background-color: unset;
    }
    
  }
  &.MuiAccordion-root.Mui-expanded {
      /* border: 1px solid red; */
      margin: 0px;
    }
  
  &.MuiAccordion-rounded{
    border-radius: 6px;
  }
  .MuiAccordionSummary-expandIcon .MuiSvgIcon-root {
    color: ${COLORS.DARK_GREY};
  }
  .MuiAccordionDetails-root {
    flex-direction: column;
  }
}
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
&& {
  .MuiAccordionSummary-content {
    display: flex;
    flex-direction: column;
    margin: 0px;
    /* align-items: center; */
    /* >div:not(:last-child) {
      margin-right: 12px;
    }; */
  }
}
`;

export const StyledAccordionTitle = styled.div`
  font-weight: 600;
  font-size: 0.8rem;
`;

export const StyledPaymentStatusContainer = styled.div`
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.7rem;
`;

export const StyledPaymentStatusIndicator = styled.div`
  border-radius: 25%;
  width: 10px;
  height: 10px;
  background-color: ${props => props.color};
  margin-right: 5px;
`;

export const StyledPaymentDetail = styled.div`
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
`;

export const StyledPaymentDetailItem = styled.div`
  /* border: 1px solid red; */
  font-size: 0.7rem;
  margin-bottom: 5px;
`;