import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import {
  shallowEqual,
  useDispatch,
  useSelector,
} from "react-redux";

// Layout
import DetailsSidebarFrontBaseLayout from "../../layouts/detailsSidebarFrontBaseLayout";

// Components
// import ButtonCpt from '../../components/molecules/buttonCpt';
import BackIcon from '../../components/atoms/icons/back';
import ProfileIcon from '../../components/atoms/icons/profile';

import {
  saveUserProfile,
} from '../../../../store/collaborator/actions';
import {
  getUser as getUserAction,
} from '../../../../store/collaborator/actions';
import {
  isSaveCollaboratorProfileOnLoad,
  isSaveCollaboratorProfileOnError,
  isSaveCollaboratorProfileOnSuccess,
  getUser,
} from '../../../../store/collaborator/selectors';

// Hooks
import useTheme from "../../../../theme-style/useThemeCustomHook";

// Styles
import {
  Container,
  ProfilWrapper,
  Header,
  BackButton,
  Title,
  BackIconWrapper,
  ProfilForm,
  FormControlCustom,
  ProfileIconWrapper,
  ButtonCptSave,
  ProfilFormContainer
} from "./style";

const AccountProfil = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    saveOnLoad,
    saveOnError,
    saveOnSuccess,
    user,
  } = useSelector(
    state => ({
      saveOnLoad: isSaveCollaboratorProfileOnLoad(state),
      saveOnError: isSaveCollaboratorProfileOnError(state),
      saveOnSuccess: isSaveCollaboratorProfileOnSuccess(state),
      user: getUser(state),
    }),
    shallowEqual
  );
  const [valueGenre, setValueGenre] = useState(null);
  const [valueAge, setValueAge] = useState(null);

  useEffect(() => {
    dispatch(getUserAction());
  }, []);

  useEffect(() => {
    if (user) {
      setValueGenre(user.gender);
      setValueAge(user.age);
    }
  }, [user]);

  useEffect(() => {
    if (saveOnSuccess) {
      toast.success("Vos modifications ont bien été enregistrées.", {
        position: toast.POSITION.TOP_RIGHT,
        position: toast.POSITION.TOP_RIGHT,
        className: "custom-toast custom-toast-success",
        bodyClassName: "custom-toast__body custom-toast__body-success",
        progressClassName: "custom-toast__progress"
      });
    }
  }, [saveOnSuccess]);

  useEffect(() => {
    if (saveOnError) {
      toast.error("Une erreur est survenue, merci de vérifier votre connection avant de ré-essayez.", {
        position: toast.POSITION.TOP_RIGHT,
        className: "custom-toast custom-toast-error",
        bodyClassName: "custom-toast__body custom-toast__body-success",
        progressClassName: "custom-toast__progress"
      });
    }
  }, [saveOnError]);

  const handleChangeGenre = (event) => {
    setValueGenre(event.target.value);
  };

  const handleChangeAge = (event) => {
    setValueAge(event.target.value);
  };

  const ProfilIconLabel = ({children}) => {
    return (
      <>
        {children}
        <ProfileIconWrapper>
          <ProfileIcon color={theme.colors.grey2}/>
        </ProfileIconWrapper>
      </>
    );
  };

  const onSubmit = e => {
    if (e) {
      e.preventDefault();
    }
    dispatch(saveUserProfile(
      valueGenre,
      valueAge,
    ));
  };

  return (
    <>
      {/* <ToastContainer
        autoClose={8000}
        className="custom-toast-container custom-toast-container__success"
      /> */}
      <DetailsSidebarFrontBaseLayout
        id="detailsSidebarLayoutAccountProfil"
        className="detailsSidebarLayoutAccountProfil page-container sidebarPage"
      >
        <Container>
          <ProfilWrapper>

            <Header to="/compte">
              <BackButton>
                <BackIconWrapper>
                  <BackIcon color={theme.colors.white}/>
                </BackIconWrapper>
              </BackButton>
              <Title>
                Mon profil
              </Title>
            </Header>

            <ProfilFormContainer>
              <ProfilForm>
                <FormControlCustom component="fieldset">
                  <FormLabel component="label">
                    Genre
                  </FormLabel>
                  <RadioGroup aria-label="gender" name="gender" value={valueGenre} onChange={handleChangeGenre}>
                    <FormControlLabel
                      value="women"
                      control={<Radio />}
                      label={<ProfilIconLabel>Femme</ProfilIconLabel>}
                    />
                    <FormControlLabel
                      value="men"
                      control={<Radio />}
                      label={<ProfilIconLabel>Homme</ProfilIconLabel>}
                    />
                    <FormControlLabel
                      value="null"
                      control={<Radio />}
                      label={<ProfilIconLabel>Pas envie de choisir</ProfilIconLabel>}
                    />
                  </RadioGroup>
                </FormControlCustom>

                <FormControlCustom component="fieldset">
                  <FormLabel component="label">Âge</FormLabel>
                  <RadioGroup aria-label="age" name="age" value={valueAge} onChange={handleChangeAge}>
                    <FormControlLabel
                      value="moins de 20 ans"
                      control={<Radio />}
                      label={<ProfilIconLabel>Moins de 20 ans</ProfilIconLabel>}
                    />
                    <FormControlLabel
                      value="de 20 à 34 ans"
                      control={<Radio />}
                      label={<ProfilIconLabel>De 20 ans à 34 ans</ProfilIconLabel>}
                    />
                    <FormControlLabel
                      value="de 35 à 49 ans"
                      control={<Radio />}
                      label={<ProfilIconLabel>De 35 ans à 49 ans</ProfilIconLabel>}
                    />
                    <FormControlLabel
                      value="50 ans et +"
                      control={<Radio />}
                      label={<ProfilIconLabel>50 ans et plus</ProfilIconLabel>}
                    />
                  </RadioGroup>
                </FormControlCustom>
              </ProfilForm>
              <ButtonCptSave
                label={saveOnLoad ? 'LOADING...' : 'Enregistrer'}
                appearence="sidebarFrontSecondary"
                onClick={onSubmit}
                disabled={saveOnLoad}
              />
            </ProfilFormContainer>

          </ProfilWrapper>
        </Container>
      </DetailsSidebarFrontBaseLayout>
    </>
  );
}

export default AccountProfil;
