import { TabList, Tab, Tabs, TabPanel } from 'react-tabs';
import styled from 'styled-components';
import breakpoints from "../../theme-style/breakpoints";

import GenericButton from '../../components/molecules/GenericButton';
import { COLORS } from '../../constants/design';

export const LinkRgpdWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin: 0;
  hr{
    width: 30px;
    height: 1px;
    background-color: ${COLORS.LIGHT_GREY_1};
    border: 0;
    margin: 24px auto 5px auto;
  }
  a{
    font-size: 14px;
    text-align: center;
    color: ${COLORS.GREY_1};
    margin: 0 auto;
    /* border: 1px solid ${COLORS.LIGHT_GREY_3}; */
    border-radius: 9px;
    padding: 14px 10px;
    width: 100%;
    /* text-decoration: none; */
    box-sizing: border-box;
    transition: all .2s ease-out;
    &:hover{
      color: ${COLORS.PRIMARY_BLUE};
      /* border: 1px solid ${COLORS.PRIMARY_BLUE}; */
    }
  }
`;

export const LogoWithCircleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
`;

export const Subtitle = styled.h2`
  font-size: 24px;
  color: #fff;
  text-align: center;
  font-weight: 500;
  line-height: 1.4;
  margin-bottom: 20px;
`;

export const Title = styled.h1`
  margin-top: 20px;
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
`;

export const LoginHead = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
`;

export const TabItem = styled(Tab)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  transition: all .3s ease-in-out;
  color: #5a5a5a;
  height: 60px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  width: 50%;
  paddingg: 10px;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  cursor: pointer;
  &[aria-selected=true] {
    background-color: #ec6b87;
    color: #fff;
    z-index: 10;
    box-shadow: 0 1px 20px 0 rgb(0 0 0 / 30%);
  } 
  &:first-child{
    border-radius: 6px 0 0 6px;
  }
  &:last-child{
    border-radius: 0 6px 6px 0;
  }
`;

export const TabView = styled(TabPanel)`
  width: 100%;
  form{
    .input-group{
      position: relative;
      margin-bottom: 15px;
      & > label{
        font-size: 12px;
        font-weight: 900;
        color: #838383;
        margin-bottom: 10px;
        display: block;
      }
      & > div{
        position: relative;
        input{
          height: 60px;
          font-size: 14px;
          color: #5a5a5a;
          border-radius: 6px;
          display: block;
          width: 100%;
          padding: 14px 20px;
          box-sizing: border-box;
          border: none;
          background-color: #f5f5f5;
          outline: none;
          font-weight: 800;
        }
        svg{
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    & > button.o-form_login-linkForget{
      justify-content: flex-end;
      width: 100%;
      display: flex;
      margin-bottom: 30px;
      color: #b7b7b7;
      font-size: 12px;
      font-style: italic;
      text-decoration: underline;
      font-family: Quicksand;
      font-weight: 500;
      cursor: pointer;
    }
    & > button.ladda-button{
      display: block;
      width: 100%;
      padding: 14px;
      text-align: center;
      font-weight: 700;
      border: none;
      text-decoration: none;
      border-radius: 6px;
      cursor: pointer;
      font-size: 14px;
      height: 60px;
      margin-top: auto;
      background-color: #463e96;
      color: #fff;
      text-transform: uppercase;
    }
    & > button.ladda-button[disabled]{
      cursor: default;
      background-color: #999;
    }
  }
`;

export const TabListWrapper = styled(TabList)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 30px;
`;

export const TabsWrapper = styled(Tabs)`
  width: 100%;
`;

export const LoginBox = styled.div`
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 30px;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  @media ${breakpoints.mobileXL} {
    padding: 40px 50px;
    max-width: 380px;
  }
  .react-tabs {
    display: flex;
    flex-direction: column;
  }
`;

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // min-width: 380px;
  width: calc(100% - 30px);
  max-width: 580px;
  padding: 40px 0px;
  box-sizing: border-box;
  margin: 0 15px;
  @media ${breakpoints.mobileXL} {
    width: 100%;
  }
`;

export const LoginContainer = styled.div`
  align-items: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #e1e4e7;
  background: linear-gradient(to top right,#7094e5,#463e96);
  // background: linear-gradient(to top right,#06c,#357ed3);
  padding: 0;
  box-sizing: border-box;
  overflow: auto;
`;

export const StyledBox = styled.div`
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 10px;
  border-radius: 8px;
  width: 100%;
  font-size: 1rem;
  box-sizing: border-box;
  @media ${breakpoints.mobileXL} {
    /* padding: 40px 50px; */
    max-width: 250px;
  }
`;

export const StyledText = styled.div`
  /* border: 1px solid black; */
  align-items: center;
  /* background-color: #fff; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  font-size: 1rem;
  box-sizing: border-box;
  line-height: 1.5;
  /* @media ${breakpoints.mobileXL} {
    max-width: 380px;
  } */
  width: 100%;
  /* color: #555555; */
  strong {
    font-weight: 800;
    margin: 0px 5px;
  }
  >:not(:last-child) {
    margin-bottom: 15px;
  };
  /* margin: 5px; */
`;

export const StyledLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 60px 20px 0 20px;
  width: 100%;
  font-size: 1rem;
  box-sizing: border-box;
  line-height: 1.5;
  a {
    margin: 0px 10px;
    color: #FFF;
    text-decoration: none;
    &:hover{
      text-decoration: underline;
    }
  }
  @media screen and (max-width: 450px) {
    font-size: 0.75rem;
    a{
      margin: 0px 5px;
    }
  }
`;

export const StyledImage = styled.div`
  /* border: 1px solid black; */
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  width: 100%;
  :after{
    content: "";
    display: block;
    padding-bottom: 100%;
  }
`;

export const StyledButton = styled(GenericButton)`
  && {
    &.MuiButton-root {
      width: 100%;
      padding: 5px 10px;
      margin: 0px 10px;
      background-color: COLORS.PRIMARY_BLUE;
    }
  }
`;