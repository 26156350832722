import creatDataContext from "./CreateDataContext";

const editorCycoReducer = (state, action) => {
  switch (action.type) {
    case "storestate":
      return { storeState: action.payload };
    default:
      return state;
  }
};

const updateEditorSgtate = dispatch => async (val) => {
  dispatch({ type: 'storestate', payload: val });
}

export const { Context, Provider } = creatDataContext(
  editorCycoReducer,
  { updateEditorSgtate },
  {
    storeState: false
  }
);
