import React from "react";

const LockIcon = ({
  color = "#777777",
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon lockIcon"
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M7 .4c2.103 0 3.823 1.637 3.928 3.7l.005.2.001.84h.866a1.8 1.8 0 011.794 1.652l.006.148v4.86a1.8 1.8 0 01-1.652 1.794l-.148.006H2.2a1.8 1.8 0 01-1.794-1.652L.4 11.8V6.94a1.8 1.8 0 011.652-1.794L2.2 5.14h.867V4.3c0-2.088 1.654-3.79 3.73-3.895L7 .4zm4.8 5.94H2.2a.6.6 0 00-.592.503L1.6 6.94v4.86a.6.6 0 00.503.592l.097.008h9.6a.6.6 0 00.592-.503l.008-.097V6.94a.6.6 0 00-.503-.592L11.8 6.34zM7 1.6c-1.455 0-2.642 1.122-2.728 2.536l-.005.164v.839h5.467V4.3c0-1.377-1.045-2.516-2.397-2.68l-.17-.015L7 1.6z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export default LockIcon;
