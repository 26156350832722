export const actionGenerator = (value) => ({
  REQUEST: `GETGROUND/${value}/REQUEST`,
  SUCCESS: `GETGROUND/${value}/SUCCESS`,
  FAILURE: `GETGROUND/${value}/FAILURE`,
})

export const VALIDATE_GETGROUND_TOKEN = actionGenerator("VALIDATE_GETGROUND_TOKEN");

// News
export const validateGetGroundToken = (data) => ({
  type: VALIDATE_GETGROUND_TOKEN.REQUEST,
  data,
});

export const validateGetGroundTokenSuccess = (data) => ({
  type: VALIDATE_GETGROUND_TOKEN.SUCCESS,
  data,
});

export const validateGetGroundTokenError = (error) => ({
  type: VALIDATE_GETGROUND_TOKEN.FAILURE,
  error,
});