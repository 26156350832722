import styled from "styled-components";
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import { COLORS } from "../../../../../../constants/design";

export const Container = styled.div`
  display: flex;
`;

export const CustomTabs = styled(Tabs)`
  background-color: #F7F7F7;
  border-radius: 6px;
`;

export const CustomTab = styled(Tab)`
  &&{
    width: 200px;
    font-size: 0.8rem;
    min-width: auto;
    text-transform: none;
    color: ${COLORS.DARK_GREY};
    &.Mui-selected{
      background-color: ${COLORS.PRIMARY_BLUE};
      color: #FFFFFF;
    }
  }
`;