import styled from "styled-components";

import { COLORS } from "../../../constants/design";

export const MainContainer = styled.div`
  width: 100%;
  color: ${COLORS.DARK_GREY};
  .MuiCheckbox-colorSecondary {
    :hover {
      background-color: transparent !important;
    }
  }
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: ${COLORS.PRIMARY_BLUE};
    
  }
  .MuiCheckbox-colorSecondary.Mui-disabled {
    color: ${COLORS.LIGHT_GREY_4};
  }
  .MuiButtonBase-root {
    margin-right: 10px;
    padding: 0px;
  }
  .generictitle {
    .textTitle{
      color: ${props => props.disabled ? COLORS.LIGHT_GREY_4 : COLORS.DARK_GREY}
    }
  }

  &.generic-checkbox-cgu{
    padding-bottom: 20px;
    .generictitle{
      line-height: 0;
      .textTitle{
        font-size: 11px;
        line-height: 13px;
      }
    }
  }
`;

export const StyledLabel = styled.label`
  display: flex;
  align-items: ${props => props.posTop ? 'flex-start' : 'center'};
  cursor: pointer;
  :hover {
    .textTitle {
      color: ${COLORS.PRIMARY_BLUE};
    }
  }
`;