import React from "react";

const BuildingCompanyIcon = ({
  color = "#2B2B2B",
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon buildingCompanyIcon"
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
    >
      <path
        d="M2.273 1.015l6.868 1.049c.537.09.921.547.921 1.088v3.491l5.075 1.071c.51.114.863.556.863 1.077v6.107A1.1 1.1 0 0114.906 16H2.094A1.1 1.1 0 011 14.898V2.103c0-.325.141-.631.387-.841.246-.21.57-.3.886-.247zm-.179.93a.154.154 0 00-.1.037.159.159 0 00-.056.12v12.796c0 .087.07.157.156.157h1.718v-2.361a1.1 1.1 0 011.094-1.102H6.47a1.1 1.1 0 011.093 1.102v2.361h1.563V3.152a.157.157 0 00-.13-.156L2.125 1.95a.195.195 0 00-.032-.004zm7.969 5.663v7.447h4.843c.086 0 .156-.07.156-.157V8.791a.156.156 0 00-.121-.154l-4.879-1.03zm-3.594 4.929H4.906a.157.157 0 00-.156.157v2.204h1.875v-2.204a.157.157 0 00-.156-.157zm6.562.63a.47.47 0 01.469.472.47.47 0 01-.469.472h-.937a.47.47 0 01-.469-.472c0-.261.21-.473.469-.473h.937zm0-1.89a.47.47 0 01.469.472.47.47 0 01-.469.473h-.937a.47.47 0 01-.469-.473c0-.26.21-.472.469-.472h.937zM4.594 9.388a.47.47 0 01.468.472.47.47 0 01-.468.473h-.938a.47.47 0 01-.469-.473c0-.26.21-.472.47-.472h.937zm3.125 0a.47.47 0 01.468.472.47.47 0 01-.468.473H6.78a.47.47 0 01-.468-.473c0-.26.21-.472.468-.472h.938zm5.312 0a.47.47 0 01.469.472.47.47 0 01-.469.473h-.937a.47.47 0 01-.469-.473c0-.26.21-.472.469-.472h.937zm-8.437-1.89a.47.47 0 01.468.473.47.47 0 01-.468.472h-.938a.47.47 0 01-.469-.472c0-.26.21-.472.47-.472h.937zm3.125 0a.47.47 0 01.468.473.47.47 0 01-.468.472H6.78a.47.47 0 01-.468-.472c0-.26.21-.472.468-.472h.938zM4.594 5.61a.47.47 0 01.468.472.47.47 0 01-.468.472h-.938a.47.47 0 01-.469-.472c0-.26.21-.472.47-.472h.937zm3.125 0a.47.47 0 01.468.472.47.47 0 01-.468.472H6.78a.47.47 0 01-.468-.472c0-.26.21-.472.468-.472h.938zM4.594 3.72a.47.47 0 01.468.473.47.47 0 01-.468.472h-.938a.47.47 0 01-.469-.472c0-.26.21-.472.47-.472h.937zm3.125 0a.47.47 0 01.468.473.47.47 0 01-.468.472H6.78a.47.47 0 01-.468-.472c0-.26.21-.472.468-.472h.938z"
        stroke={color}
        strokeWidth={0.1}
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export default BuildingCompanyIcon;
