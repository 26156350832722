import styled from "styled-components";
import Tooltip from '@material-ui/core/Tooltip';
import Box from "@material-ui/core/Box";

export const BoxCustom = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  svg{
    transition: all .2s ease-out;
  }
`;

export const DislikeIconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  width: 13px;
  min-width: 13px;
  height: 13px;
  margin-right: 6px;
`;

export const EditInfoIconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  width: 12px;
  min-width: 12px;
  height: 12px;
  margin-right: 6px;
`;

export const CompanyIconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  width: 13px;
  min-width: 13px;
  height: 16px;
  margin-right: 6px;
`;

export const FeedbacksIconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  width: 12px;
  min-width: 12px;
  height: 12px;
  margin-right: 6px;
`;

export const SubTableCellWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  svg{
    transition: all .2s ease-out;
  }
`;

export const TooltipCustom = styled(Tooltip)`
  &&{
    .MuiTooltip-popper{
      border-radius: 6px;
      background-color: rgba(0, 0, 0, 0.7);
      backdrop-filter: blur(4px);
    }
  }
`;

export const EiStatusOnOff = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const StyledSubTableCell = styled.td`
    padding: 0;
    border-bottom: 1px solid #F7F7F7;
    width: ${({ sub }) => sub ? "auto" : "100%"};
    height: 100%;
    vertical-align: middle;

    &.feedBackWrapper{
      /* min-width: 130px; */
    }
    .tableCellLink{
      padding: 12px;
      text-decoration: none;
      width: ${({ sub }) => sub ? "auto" : "100%"};
      height: 100%;
      min-height: 58px;
      max-width: ${({ sub }) => sub ? "none" : "90%"};
      display: flex;
      justify-content: flex-start;
      flex-direction: ${({ sub }) => sub ? "row" : "column"};
      align-items: ${({ sub }) => sub ? "flex-end" : "flex-start"};
      div{
        span{
          white-space: nowrap;
        }
      }
    }
    p{
      font-size: ${({ sub }) => sub ? "11px" : "14px"};
      font-weight: ${({ sub }) => sub ? "400" : "500"};
      margin: 0 0 4px 0;
      color: #5A5A5A;
      line-height: 16px;
      text-align: ${({ sub }) => sub ? "right" : "left"};
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      transition: all .2s ease-out;
    }
    span {
      font-size: ${({ sub }) => sub ? "11px" : "12px"};
      font-weight: ${({ sub }) => sub ? "400" : "500"};
      display: block;
      font-weight: 500;
      color: #BBBBBB;
      line-height: ${({ sub }) => sub ? "15px" : "14px"};
      text-align: ${({ sub }) => sub ? "left" : "left"};
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      transition: all .2s ease-out;
    }
`;

export const NotSeeText = styled.span`

`;

export const NotSeeIconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  width: 15px;
  min-width: 15px;
  height: 16px;
  margin-right: 6px;
`;

export const SeeText = styled.span`
  color: #4A90E2 !important;
`;

export const SeeIconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  width: 15px;
  min-width: 15px;
  height: 11px;
  margin-right: 6px;
  svg{
    path{
      fill: #4A90E2;
    }
  }
`;

export const TableBodyRowDivider = styled.tr`
  height: 30px;
  background-color: #FFFFFF;
  &:focus,
  &:hover{
    background-color: #FFFFFF;
  }
`;

export const CrooElemDiv = styled.div`
  display: inline-flex;
`;

export const DivParaTitle = styled.p`
  position: relative;
  top: 12%;
`;


export const TableBodyRow = styled.tr`
  height: 60px;
  transition: all .2s ease-out;
  &:focus,
  &:hover{
    background-color: #F7FBFF;
    ${StyledSubTableCell}{
      p{
        color: #357ED3;
      }
      span{
        color: #777777;
      }
    }
    ${CompanyIconWrapper}{
      svg{
        path{
          fill: #FFDB83;
        }
      }
    }
    ${FeedbacksIconWrapper}{
      svg{
        path{
          fill: #4A90E2;
        }
      }
    }
    ${DislikeIconWrapper}{
      svg{
        path{
          fill: #40B176;
        }
      }
    }
    ${EditInfoIconWrapper}{
      svg{
        path{
          fill: #FF9F6D;
        }
      }
    }
    ${SeeIconWrapper}{
      svg{
        path{
          fill: #357ED3;
        }
      }
    }
    ${NotSeeIconWrapper}{
      svg{
        path{
          fill: #357ED3;
        }
      }
    }
  }
`;
