import styled from "styled-components";
import { COLORS } from "../../../constants/design"

export const ButtonListDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
`;

export const CustomButton = styled.button`
    width: 315px;
    height: 52px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: ${props => props.isPrimary ? COLORS.PRIMARY_BLUE : COLORS.LIGHT_GREY_1};
    span {
      color: ${props => props.isPrimary ? COLORS.WHITE : COLORS.DARK_GREY};
    }
`;

export const ButtonSpan = styled.span`
    font-size: 14px;
    font-weight: 500;
    color: #FFFFFF;
`;

