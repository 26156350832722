import styled from "styled-components";
import Dialog from '@material-ui/core/Dialog';

import { COLORS } from "../../constants/design";
import { size } from "../../theme-style/breakpoints";

export const CustomDialog = styled(Dialog)`
  && {
    .MuiDialog-container {
      box-sizing: border-box;
      line-height: normal;
      font-size: 1rem;
      .MuiDialog-paper {
        &.MuiPaper-rounded {
          width: 90%;
          height: 100%;
        }
        &.MuiDialog-paperWidthSm {
          max-width: 100%;
        }
      }
      @media (max-width: ${size.mobileXL}) {
        .MuiDialog-paper {
          &.MuiDialog-paperScrollPaper {
            max-height: 100%;
          }
          &.MuiDialog-paper {
            margin: 0;
          }
          &.MuiPaper-rounded {
            width: 100%;
          }
        }
      }
    }
  }
`;

export const DialogContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  /* height: 100%; */
  padding: 25px;
  background-color: white;
  border-radius: 6px;
  color: ${COLORS.DARK_GREY};
`;

export const StyledCloseContainer = styled.div`
  position: absolute;
  z-index: 9999;
  color: ${COLORS.DARK_GREY};
  background-color: ${COLORS.LIGHT_GREY_1};
  border-radius: 50%;
  padding: 1px;
  display: flex;
  opacity: 0.8;
  top: 20px;
  right: 20px;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;

export const StyledTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
`;

export const StyledTopContainer = styled.div`
  /* border: 1px solid red; */
  display: flex;
  padding: 10px 0px;
`

export const StyledDayPickerContainer = styled.div`
  --rdp-cell-size: 30px;
  --rdp-accent-color: ${COLORS.PRIMARY_BLUE};
  --rdp-outline-selected: ${COLORS.PRIMARY_DEEP_BLUE};
  @media (max-width: ${size.mobileXL}) {
    --rdp-cell-size: 28px;
    .rdp {
      margin: 1rem 0;
    }
  }
`;

export const StyledRoomInfoContainer = styled.div`
  /* border: 1px solid blue; */
  width: 100%;
  padding: 20px 0px 20px 20px;
`;