import React from "react";

const ProfileIcon = ({
  color = "#FFFFFF",
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon profileIcon"
      width={width}
      height={height}
      viewBox="0 0 12 14"
      fill="none"
    >
      <path
        d="M8.489 6.673A2.983 2.983 0 009 5V4c0-1.655-1.346-3-3-3-1.655 0-3 1.345-3 3v1c0 .619.188 1.194.511 1.673A4.994 4.994 0 001 11v.5c0 .827.673 1.5 1.5 1.5h7c.827 0 1.5-.673 1.5-1.5V11a4.994 4.994 0 00-2.511-4.327zM4 4c0-1.103.897-2 2-2s2 .897 2 2v1c0 1.103-.897 2-2 2s-2-.897-2-2V4zm6 7.5a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5V11c0-1.57.909-2.93 2.228-3.585a2.983 2.983 0 003.544 0A4.003 4.003 0 0110 11v.5h0z"
        stroke={color}
        strokeWidth={0.2}
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export default ProfileIcon;
