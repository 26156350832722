import styled from "styled-components";
import ButtonCpt from '../../components/molecules/buttonCpt';
import {
  NavLink,
} from "react-router-dom";
import breakpoints from "../../../../theme-style/breakpoints";


export const EmailIconWrapper = styled.span`
  position: absolute;
  right: 0;
  top: 41px;
  padding: 0px 20px 0px 0px;
  max-width: 16px;
  max-height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  svg path{
    transition: all .2s ease-out;
  }
`;

export const ProfileIconWrapper = styled.span`
  position: absolute;
  right: 0;
  top: 41px;
  padding: 0px 21px 0px 0px;
  max-width: 14px;
  max-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  svg path{
    transition: all .2s ease-out;
  }
`;

export const LockIconWrapper = styled.span`
  position: absolute;
  right: 0;
  top: 41px;
  padding: 0px 20px 0px 0px;
  max-width: 14px;
  max-height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  svg path{
    transition: all .2s ease-out;
  }
`;

export const ButtonCptSave = styled(ButtonCpt)`
  &&{
    height: 60px;
    margin: auto 0 25px 0;
    &.MuiButtonBase-root{
      .MuiButton-label{
        span{
          color: white;
          font-weight: 600;
          font-size: 13px;
          line-height: 10px;
          text-align: center;
        }
      }
    }
  }
`;

export const InformationsFormInput = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 10px;
  .MuiFormControl-root{
    &:hover,
    &:focus{
      ${ProfileIconWrapper}{
        svg{
          path{
            fill: #357ED3;
            stroke: #357ED3;
          }
        }
      }
      ${EmailIconWrapper}{
        svg{
          path{
            fill: #357ED3;
            stroke: #357ED3;
          }
        }
      }
    }
  }
  .input-password-complexity-meter{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 0;
    width: 100%;
    background-color: #ffffff;
    border-bottom: 1px solid #F4F4F4;
    margin-top: -1px;
    position: relative;
    padding: 5px 20px;
    box-sizing: border-box;
    meter{

    }
    p{
      font-size: 11px;
      color: #5a5a5a;
      text-align: left;
      margin: 0 0 0 10px;
    }
  }
`;

export const InformationsForm = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 70px);
  align-items: flex-start;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
`;

export const Title = styled.div`
  color: white;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin: 0;
  transition: all .2s ease-out;
`;

export const BackIconWrapper = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 16px;
  max-height: 12px;
  svg path{
    transition: all .2s ease-out;
  }
`;

export const BackButton = styled.div`
  margin: 0 30px 0 0;
`;

export const Header = styled(NavLink)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  text-decoration: none;
  padding: 15px 0;
  @media ${breakpoints.mobileXL} {
    padding: 25px 0;
  }
  &:hover{
    ${Title}{
      color: #efc31f;
    }
    ${BackIconWrapper}{
      svg{
        path{
          fill: #efc31f;
        }
      }
    }
  }
`;

export const InformationsWrapper = styled.div`
  height: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const Container = styled.div`
  /* position: absolute; */
  /* z-index: 888; */
  /* top: 0; */
  height: auto;
  min-height: 100vh;
  width: 100%;
  background-color: #357ED3;
  box-sizing: border-box;
  padding: 0 15px;

  @media ${breakpoints.mobileXL} {
    padding: 0 25px;
  }
`;
