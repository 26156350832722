import React, { useEffect, useState } from 'react';

// Import Material UI components
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';

// Styles Theme Hooks
import useTheme from "../../../../theme-style/useThemeCustomHook";

// Icons
import FeedbacksIcon from '../../../../components/atoms/icons/feedbacks';

// Import Style
import {
    TagDiv,
    FeedbacksIconWrapper,
    StyledTableCell,
    TableRowCustom
} from './style';

const TableHeader = ({ sub, i }) => {
  const theme = useTheme();

  return (
    <Table key={i} size="small" aria-label="a dense table">
      <TableHead>
          <TableRowCustom>
              <StyledTableCell align="left">
                  <TagDiv></TagDiv>
                  <span>{sub.title}</span>
              </StyledTableCell>
              <StyledTableCell align="right" sub >
                  <span>
                    <FeedbacksIconWrapper>
                      <FeedbacksIcon color={ theme.colors.secondary}/>
                    </FeedbacksIconWrapper>
                    {sub.feedback == 0 ? "Pas de nouvelle remarque" 
                    : sub.feedback + " remarque(s) à traiter"}
                  </span>
              </StyledTableCell>
              {/*<StyledTableCell align="right" sub >
                  <span>
                    <FeedbacksIconWrapper>
                      <FeedbacksIcon color={theme.colors.orang}/>
                    </FeedbacksIconWrapper>
                    {sub.eiActivated} éléments d'informations activés
                  </span>
              </StyledTableCell>*/}
          </TableRowCustom>
      </TableHead>
  </Table>
);
}

export default TableHeader;
