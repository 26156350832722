import React from "react";

const TaskValidatedIcon = ({
  color = "#2B2B2B",
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon taskValidatedIcon"
      width={width}
      height={height}
      viewBox="0 0 18 19"
      fill="none"
    >
      <path
        d="M8.953 1c.667 0 1.319.271 1.787.745h0l.003.003 2.033 2.09c.462.468.717 1.087.717 1.743h0v4.547c1.974.244 3.507 1.91 3.507 3.92C17 16.229 15.202 18 12.992 18a3.974 3.974 0 01-2.745-1.096h0-7.744A1.5 1.5 0 011 15.41h0V2.494A1.5 1.5 0 012.503 1h0zm4.039 10.094c-1.64 0-2.973 1.325-2.973 2.955 0 1.63 1.333 2.955 2.973 2.955 1.657 0 3.006-1.326 3.006-2.955 0-1.63-1.349-2.955-3.006-2.955zm-4.04-9.098H2.504a.5.5 0 00-.5.498h0V15.41a.5.5 0 00.5.498h6.982a3.908 3.908 0 01-.468-1.86c0-2.01 1.517-3.673 3.474-3.919h0V5.581a1.48 1.48 0 00-.432-1.047l-.002-.003h0l-2.033-2.09a1.518 1.518 0 00-1.071-.445h0zm4.49 10.937a.503.503 0 01.705-.064c.213.176.242.49.065.702h0l-1.336 1.594a.502.502 0 01-.834-.097h0l-.534-1.062a.497.497 0 01.224-.668.502.502 0 01.672.223h0l.192.38zm-7.13-1.806a.5.5 0 01.5.498.5.5 0 01-.5.498h0-2.673a.5.5 0 01-.501-.498.5.5 0 01.5-.498h0zm0-2.656a.5.5 0 01.5.498.5.5 0 01-.5.498h0-2.673a.5.5 0 01-.501-.498.5.5 0 01.5-.498h0zm3.206 0a.5.5 0 01.5.498.5.5 0 01-.5.498h0-.535a.5.5 0 01-.5-.498.5.5 0 01.5-.498h0zM6.31 5.814a.5.5 0 01.501.498.5.5 0 01-.5.499h0-2.673a.5.5 0 01-.501-.498.5.5 0 01.5-.499h0zm3.207 0a.5.5 0 01.5.498.5.5 0 01-.5.499h0-.535a.5.5 0 01-.5-.498.5.5 0 01.5-.499h0z"
        stroke={color}
        strokeWidth={0.2}
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export default TaskValidatedIcon;
