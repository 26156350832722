import * as a from "./actions";

const INITIAL_STATE = {
  userToken: null,
  login: {
    loading: false,
    success: false,
    error: null,
  },
  sendEmailResetPassword: {
    loading: false,
    success: false,
    error: null,
  },
  register: {
    loading: false,
    success: false,
    error: null,
  },
  createCollaborator: {
    loading: false,
    success: false,
    error: null,
  },
  logout: {
    loading: false,
    success: false,
  },
  updatePassword: {
    loading: false,
    success: false,
    error: null,
  }
};

export function authentication(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    // LOGIN
    case a.LOGIN.REQUEST:
      return {
        ...state,
        userToken: null,
        login: {
          loading: true,
          success: false,
          error: null,
        }
      };
    case a.LOGIN.SUCCESS:
      return {
        ...state,
        userToken: action.userToken,
        login: {
          loading: false,
          success: true,
          error: null,
        }
      };
    case a.LOGIN.FAILURE:
      return {
        ...state,
        userToken: null,
        login: {
          loading: false,
          success: false,
          error: action.error,
        }
      };
    case a.LOGIN.RESET:
      return {
        ...state,
        login: INITIAL_STATE.login
      };
    // SEND EMIL RESET PASSWORD
    case a.SEND_EMAIL_RESET_PASSWORD.REQUEST:
      return {
        ...state,
        sendEmailResetPassword: {
          loading: true,
          success: false,
          error: null,
        }
      };
    case a.SEND_EMAIL_RESET_PASSWORD.SUCCESS:
      return {
        ...state,
        sendEmailResetPassword: {
          loading: false,
          success: true,
          error: null,
        }
      };
    case a.SEND_EMAIL_RESET_PASSWORD.FAILURE:
      return {
        ...state,
        sendEmailResetPassword: {
          loading: false,
          success: false,
          error: action.error,
        }
      };
    case a.SEND_EMAIL_RESET_PASSWORD.RESET:
      return {
        ...state,
        sendEmailResetPassword: INITIAL_STATE.sendEmailResetPassword,
      };
    // REGISTER
    case a.REGISTER.REQUEST:
      return {
        ...state,
        userToken: null,
        register: {
          loading: true,
          success: false,
          error: null,
        }
      };
    case a.REGISTER.SUCCESS:
      return {
        ...state,
        userToken: action.userToken,
        register: {
          loading: false,
          success: true,
          error: null,
        }
      };
    case a.REGISTER.FAILURE:
      return {
        ...state,
        userToken: null,
        register: {
          loading: false,
          success: false,
          error: action.error,
        }
      };
    case a.REGISTER.RESET:
      return {
        ...state,
        register: INITIAL_STATE.register
      };
    // CREATE_COLLABORATOR
    case a.CREATE_COLLABORATOR.REQUEST:
      return {
        ...state,
        createCollaborator: {
          loading: true,
          success: false,
          error: null,
        }
      };
    case a.CREATE_COLLABORATOR.SUCCESS:
      return {
        ...state,
        createCollaborator: {
          loading: false,
          success: true,
          error: null,
        }
      };
    case a.CREATE_COLLABORATOR.FAILURE:
      return {
        ...state,
        createCollaborator: {
          loading: false,
          success: false,
          error: action.error,
        }
      };
    case a.CREATE_COLLABORATOR.RESET:
      return {
        ...state,
        createCollaborator: {
          loading: false,
          success: false,
          error: action.error,
        }
      };
    // LOGOUT
    case a.LOGOUT.REQUEST:
      return {
        ...state,
        userToken: null,
        logout: {
          loading: true,
          success: false,
        }
      };
    case a.LOGOUT.SUCCESS:
      return {
        ...state,
        logout: {
          loading: false,
          success: true,
        }
      };
    // UPDATE PASSWORD
    case a.UPDATE_PASSWORD.REQUEST:
      return {
        ...state,
        updatePassword: {
          loading: true,
          success: false,
          error: null,
        }
      };
    case a.UPDATE_PASSWORD.SUCCESS:
      return {
        ...state,
        userToken: action.userToken,
        updatePassword: {
          loading: false,
          success: true,
          error: null,
        }
      };
    case a.UPDATE_PASSWORD.FAILURE:
      return {
        ...state,
        updatePassword: {
          loading: false,
          success: false,
          error: action.error,
        }
      };
    case a.UPDATE_PASSWORD.RESET:
      return {
        ...state,
        updatePassword: INITIAL_STATE.updatePassword,
      };
    default:
      return state;
  }
}

export default authentication;
