import React from "react";
import styled from "styled-components";
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

// import ButtonCpt from '../../../../../../components/molecules/buttonCpt';
import GenericMaterialMenu from '../../molecules/GenericMaterialMenu';
import { COLORS } from '../../../constants/design';

export const StyledMainContainer = styled.div`
`;

export const StyledElementContainer = styled.div`
  margin: 1vw 0vw;
  padding: 1vw;
  border-radius: 10px;
  border-top-left-radius: 5px;
  background-color: #FFFFFF;
  box-shadow: 1px 1px 3px 1px rgb(0 0 0 / 20%);
`;

export const StyledElementIconContainer = styled.div`
  display: flex;
  width: 25%;
  justify-content: center;
  align-items: center;
  transition: all ease-in .15s;
  left: 0;
`;

export const StyledResponseButton = styled(Button)`
  && {
    &.MuiButton-root {
      position: relative;
      font-family: Quicksand;
      margin: 5px 0px;
      font-weight: 500;
      font-size: 0.8rem;
      background-color: ${COLORS.PRIMARY_BLUE};
      color: #fff;
      width: 75%;
      padding: 10px 10px;
      transition: all ease-in .15s;
      border: transparent;
      border-radius: 5px;
      background-image: none;
      text-transform: none;
      &:hover {
        background-color: ${COLORS.PRIMARY_DEEP_BLUE};
        color: #fff;
      }
    }
  }
`;

export const StyledElementIcon = styled(IconButton)`
  &&{
    height: fit-content;
    .MuiSvgIcon-root {
      font-size: 1.2rem;
    }
  }
`;

export const StyledElementDragIcon = styled(({ showNested, ...rest }) => (
  <IconButton {...rest} />
))`
  && {
    &.MuiButtonBase-root {
      cursor: ${props => props.showNested ? "not-allowed" : "grab"};
      cursor: ${props => props.showNested ? "-moz-not-allowed" : "-moz-grab"};
      cursor: ${props => props.showNested ? "-webkit-not-allowed" : "-webkit-grab"};

      :active {
        cursor: ${props => props.showNested ? "not-allowed" : "grabbing"};
        cursor: ${props => props.showNested ? "-moz-not-allowed" : "-moz-grabbing"};
        cursor: ${props => props.showNested ? "-webkit-not-allowed" : "-webkit-grabbing"};
      }
    }
  }
`;

export const StyledResponsePreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 5px;
  border-radius: 10px;
  margin-bottom: ${props => props.showNested ? "5px" : "none"};
  box-shadow: ${props => props.showNested ? "1px 1px 3px 1px rgb(0 0 0 / 20%)" : "none"};
  &:hover {
    background-color: #EEEEEE;
  }
`;

export const StyledResponseBlock = styled.div`
  display: flex;
  padding: 0px 5px;
`;

export const StyledResponseTextPreview = styled.div`
  color: #5A5A5A;
  font-size: 0.9em;
  font-weight: 500;
  width: 75%;
  max-width: 460px;
  padding: 15px 5px;
  transition: all ease-in .15s;
  em {
    font-style: revert;
  }
  ol, ul {
    padding-left: 1.5em;
    list-style-position: inside;
  }
  ul {
    list-style: disc;
  }
  ol {
    list-style: decimal;
  }
`;

// export const StyledResponseButton = styled(ButtonCpt)`
//   && {
//     &.MuiButton-root {
//       position: relative;
//       font-family: Quicksand;
//       margin: 5px 0px;
//       /* font-size: 1.1em; */
//       font-weight: 500;
//       background-color: #357ed3;
//       color: #fff;
//       width: 75%;
//       padding: 20px 20px;
//       transition: all ease-in .15s;
//       border: transparent;
//       border-radius: 5px;
//       background-image: none;
//       &:hover {
//         background-color: #155EB3;
//         color: #fff;
//       }
//     }
//   }
// `;

export const StyledMenu = styled(({ ...rest }) => (
  <GenericMaterialMenu {...rest} />
))`
  margin: 1vw 0vw;
  /* && {
    margin: 1vw 0vw;
    .MuiButton-root {
      font-size: 0.7rem;
      width: 100%;
      border-radius: 10px;
      padding: 20px 20px;
      background-color: #FFFFFF;
      box-shadow: 1px 1px 3px 1px rgb(0 0 0 / 20%);
      &:hover {
        background-color: #EEEEEE;
      }
    }
    .MuiButton-label {
      color: #5A5A5A;
    }
    && {
      .MuiListItem-root {
        font-size: 0.8rem;
        &:hover {
          background-color: #EEEEEE;
        }
      }
    }
  } */
`;

export const StyledNestedContainer = styled.div`
  width: 100%;
  padding: 0px 5px;
`;