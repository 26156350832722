import React from 'react';

import {
  StyledButton
} from './style';

const GenericButton = ({
  className,
  title,
  ...rest
}) => {
  return (
    <StyledButton
      {...rest}
      className={`${className ? className : ''} genericButton`}
    >
      {title}
    </StyledButton>
  );
};

export default GenericButton;