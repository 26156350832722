import React from "react";
import {
  Route,
  Redirect,
  useRouteMatch,
} from "react-router-dom";
import {
  shallowEqual,
  useSelector
} from "react-redux";


import {
  getCompanyData
} from '../store/company/selectors';

export const RoomBookingRoute = ({ ...rest }) => {
  const { path } = useRouteMatch();

  const {
    companyData,
  } = useSelector(
    state => ({
      companyData: getCompanyData(state)
    }),
    shallowEqual
  );

  if(companyData?.meetingRoom) {
    return (
      <Route
        {...rest}
      />
    )
  } else {
    return (
      <Redirect
        to={`${path}`}
      />
    )
  }
};
