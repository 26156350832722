import styled from "styled-components";
import { COLORS } from '../../../../../constants/design';

export const StyledThumbnailsContainer = styled.div`
  border: 1px solid ${COLORS.LIGHT_GREY_3};
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  position: relative;
  display: flex;
  margin-top: 10px;
  padding: 10px 50px 10px 10px;
  >div:not(:last-child) {
    margin-right: 5px;
  };
`;

export const StyledThumbnail = styled.div`
  border: 1px solid ${COLORS.LIGHT_GREY_3};
  border-radius: 10px;
  padding: 5px;
  width: 80px;
  height: 60px;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
`;

export const StyledThumbnailDiv = styled.div`
  cursor: pointer;
`;

export const StyledThumbnailName = styled.div`
  /* border: 1px solid ${COLORS.LIGHT_GREY_3}; */
  box-sizing: border-box;
  width: 100%;
  height: 35%;
  position: absolute;
  bottom: 0px;
  color: white;
  padding: 5px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.8rem;
  background-color: ${COLORS.BLACK_TRANSPARENT_30};
`;
export const StyledThumbnailNoItem = styled.div`
  /* border: 1px solid ${COLORS.LIGHT_GREY_3}; */
  color: ${COLORS.GREY_1};
  font-size: 0.8rem;
`;

export const StyledCloseButton = styled.div`
  color: ${COLORS.GREY_1};
  opacity: 0.5;
  position: absolute;
  top: 5px;
  right: 13px;
  cursor: pointer;
  z-index: 10;
  :hover {
    opacity: 1;
  }
`

export const StyledThumbnailClose = styled(StyledCloseButton)`
  right: 5px;
`

export const StyledImg = styled.img`
  width: 60px;
  height: 60px;
  object-fit: cover;
`