import styled from "styled-components";
import Dialog from '@material-ui/core/Dialog';

import { COLORS } from "../../../../constants/design";
import { size } from '../../../../theme-style/breakpoints';

import GenericTitleWithIcon from "../../../../components/molecules/GenericTitleWithIcon";


export const CustomDialog = styled(Dialog)`
  && {
    .MuiDialog-container {
      line-height: normal;
      .MuiDialog-paper {
        &.MuiPaper-rounded {
          width: 100%;
          min-height: 90vh;
          max-height: 100%;
        }
        &.MuiDialog-paperWidthSm {
          max-width: 700px;
        }
      }
      @media (max-width: ${size.mobileXL}) {
        .MuiDialog-paper {
          &.MuiDialog-paperScrollPaper {
            max-height: 100%;
          }
          &.MuiDialog-paper {
            margin: 0;
          }
          &.MuiPaper-rounded {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
`;

export const DialogContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 25px;
  background-color: white;
  border-radius: 6px;
  color: ${COLORS.DARK_GREY};
  font-size: 0.9rem;
  >div:not(:last-child) {
    margin-bottom: 15px;
  };
`;

export const StyledCloseContainer = styled.div`
  position: absolute;
  z-index: 9999;
  color: ${COLORS.DARK_GREY};
  background-color: ${COLORS.LIGHT_GREY_1};
  border-radius: 50%;
  padding: 1px;
  display: flex;
  opacity: 0.8;
  top: 20px;
  right: 20px;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;

export const StyledTitle = styled.div`
  font-weight: 700;
  font-size: 1.1rem;
`;

export const StyledImg = styled.img`
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  margin-bottom: 15px;
  border-radius: 5px;
`;

export const StyledInfoContainer = styled.div`
  /* border: 1px solid black; */
  display: flex;
`;

export const StyledTitleWithIcon = styled(GenericTitleWithIcon)`
  /* text-transform: ${props => props.longText ? "" : "capitalize"}; */
  /* border: 1px solid blue; */
  min-width: 140px;
  align-self: flex-start;
  .gtwi-titleContainer {
    margin-left: 10px;
  }
`;

export const StyledTextContainer = styled.div`
  /* border: 1px solid red; */
  padding-right: 15px;
`;

export const StyledOptionsDisplay = styled.div`
  display: flex;
`;