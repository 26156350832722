async function apiCall(endpoint, userToken) {
  const headers = {};
  if (userToken) {
    headers.Authorization = `Bearer ${userToken}`;
  }

  return fetch(endpoint, {
    headers,
  })
  .then(res => res.json())
  .then(data => data)
  .catch(err => console.log(err));
}

export default apiCall;
