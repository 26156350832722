import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import {
  shallowEqual,
  useSelector,
  useDispatch
} from 'react-redux';
import { Formik, useFormikContext } from 'formik';
import _ from 'lodash';

import GenericAdminFrontHeader from '../../../../components/molecules/GenericAdminFrontHeader';
import GenericSwitch from '../../../../components/molecules/GenericSwitch';
import GenericMultipleSelect from '../../../../components/molecules/GenericMultipleSelect';
import GenericMaterialMenu from "../../../../components/molecules/GenericMaterialMenu";
import GenericWarningModal from '../../../../components/molecules/GenericWarningModal';

import { jsonSafeParse } from '../../../../helpers/jsonSafeParse';
import { toaster } from '../../../../helpers/toaster';

import UserInfoCard from './UserInfoCard';

import {
  getConciergeSitesData,
  getConciergeSitesIsLoading,
} from '../../../../store/concierge/selectors';

import {
  getSelectedCollaborator,
  editCollaborator,
} from '../../../../store/userManagement/actions';

import {
  getSelectedCollaboratorData,
  getSelectedCollaboratorIsLoading,
  getEditCollaboratorIsLoading,
} from '../../../../store/userManagement/selectors';

import {
  Container,
  DrawerCptCustom,
  Wrapper,
  StyledFormikForm,
  StyledFormContainer
} from './style';

const UserEditDrawer = ({
  visible,
  closeFunction,
}) => {
  const { collaboratorId } = useParams();
  const dispatch = useDispatch();
  const [isDirty, setIsDirty] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [siteList, setSiteList] = useState([]);

  const {
    sitesData,
    sitesIsLoading,
    selectedCollaboratorData,
    selectedCollaboratorIsLoading,
    editCollaboratorIsLoading,
  } = useSelector(
    state => ({
      sitesData: getConciergeSitesData(state),
      sitesIsLoading: getConciergeSitesIsLoading(state),
      selectedCollaboratorData: getSelectedCollaboratorData(state),
      selectedCollaboratorIsLoading: getSelectedCollaboratorIsLoading(state),
      editCollaboratorIsLoading: getEditCollaboratorIsLoading(state),
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getSelectedCollaborator({ collaboratorId }));
  }, [collaboratorId]);

  useEffect(() => {
    if (sitesData != null) {
      const newList = sitesData.map((site) => {
        return { label: site.name, value: site.id }
      })
      setSiteList(newList)
    }
  }, [sitesData]);

  const CheckDirty = () => {
    const { dirty } = useFormikContext();

    React.useEffect(() => {
      setIsDirty(dirty);
    }, [dirty]);

    return null;
  };

  const handleClose = () => {
    if (isDirty) setOpenCancel(true);
    else doCloseDrawer();
  }

  const doCloseDrawer = () => {
    closeFunction();
  }

  const handleSubmit = (values) => {
    // console.log('values', values);
    const siteExits = _.find(values.available_company_sites, (data) => String(data.value) === String(values.site));
    if (!siteExits) {
      toaster(`Le site rattaché au collaborateur n’est pas un site autorisé`, 'error');
      return;
    }
    const newSiteList = _.map(values.available_company_sites, (site) => site.value);
    const formData = {
      is_blocked: values.is_blocked,
      available_company_sites: JSON.stringify(newSiteList),
      site: values.site,
    }
    // console.log('formData', formData);
    dispatch(editCollaborator({
      id: selectedCollaboratorData.id,
      formData: formData
    }));
  };

  const generateSiteList = () => {
    const collaboratorSite = selectedCollaboratorData.company_site;
    const data = jsonSafeParse(selectedCollaboratorData.available_company_sites);
    let allowedSites = _.compact(_.map(data, (item) => {
      const site = sitesData.find((value) => String(value.id) === String(item));
      if (site) {
        return { label: site.name, value: site.id };
      }
    }))
    // since the available site list is initially empty, we need get collaborator site, and append it into the list,
    if (collaboratorSite) {
      const currentSite = {
        label: collaboratorSite.name,
        value: collaboratorSite.id
      }
      allowedSites = _.unionBy([currentSite], allowedSites, "value");
    }
    return allowedSites;
  }

  const getFormInitvalue = () => {
    const formValue = {
      is_blocked: selectedCollaboratorData?.is_blocked || false,
      available_company_sites: selectedCollaboratorData && sitesData ? generateSiteList() : [],
      site: selectedCollaboratorData?.company_site?.id || _.head(siteList)?.value,
    };
    return formValue;
  }

  return (
    <Container>
      <DrawerCptCustom
        onOpen={visible}
        visible={visible}
        closeFunction={handleClose}
        className="userEditDrawer"
      >
        <Wrapper>
          <Formik
            initialValues={getFormInitvalue()}
            enableReinitialize
            setFieldValue
            onSubmit={handleSubmit}
          >
            {(props) => {
              // console.log('props.values', props.values);
              return (
                <StyledFormikForm>
                  <CheckDirty />
                  <GenericAdminFrontHeader
                    headerTitle={`Gestion compte utilisateur`}
                    RightSideComponent={
                      <GenericSwitch
                        title={props.values.is_blocked ? `Utilisateur bloqué` : `Utilisateur non bloqué`}
                        checked={props.values.is_blocked}
                        onChange={() => props.setFieldValue('is_blocked', !props.values.is_blocked)}
                      />
                    }
                  />
                  <StyledFormContainer
                    isLoading={selectedCollaboratorIsLoading || sitesIsLoading || editCollaboratorIsLoading}
                    onClose={handleClose}
                    submitable={true}
                  >
                    <UserInfoCard collaboratorData={selectedCollaboratorData} />
                    <GenericMultipleSelect
                      list={siteList}
                      id="available_company_sites"
                      name="available_company_sites"
                      title="Site(s) autorisé(s)"
                      hasSelectAll={true}
                      value={props.values.available_company_sites}
                      // onChange={(e, value) => handleSitesSelect(props, value)}
                      onChange={(e, value) => props.setFieldValue('available_company_sites', value)}
                    // toolTipText={'Ce champ vous permet de sélectionner les sites de votre prestataire.'}
                    />
                    <GenericMaterialMenu
                      title={'Site de rattachement'}
                      list={siteList}
                      initialValue={props.values.site}
                      onChange={(value) => props.setFieldValue('site', value)}
                    />
                  </StyledFormContainer>
                </StyledFormikForm>
              )
            }}
          </Formik>
        </Wrapper>
        <GenericWarningModal
          open={openCancel}
          setOpenDialog={() => setOpenCancel(!openCancel)}
          title={"Attention"}
          text={"Êtes-vous sur de vouloir annuler votre saisie ?"}
          leftButtonText={"Non"}
          rightButtonText={"Oui"}
          leftButtonFunction={() => setOpenCancel(!openCancel)}
          rightButtonFunction={doCloseDrawer}
        />
      </DrawerCptCustom>
    </Container>
  )
};

export default UserEditDrawer;