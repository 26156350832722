import React from "react";
import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import ButtonCpt from "../../molecules/buttonCpt";

export const DialogPicture = styled.div`
  width: 100%;
  height: 25vh;
  background-image: url(${props => props.bgPicture});
  background-color: #212121;
  background-position: center;
  background-size: cover;
`;

export const DialogIllustration = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 28vh;
`;

export const DialogTitleCustom = styled(({ noPaddingTop, ...rest }) => (
  <DialogTitle {...rest} />
))`
  padding-top: ${props => (props.noPaddingTop ? "25px !important" : "40px")};
`;

export const CustomButtons = styled(ButtonCpt)``;

export const DialogCustom = styled(({ ...rest }) => (
  <Dialog {...rest} />
))`
  && {
    .MuiDialog-container {
      .MuiDialog-paper {
        color: #212121;
        background-color: #FFFFFF;
        border-radius: 10px;
        &.MuiPaper-rounded {
          border-radius: 10px;
          width: 100%;
          max-width: 480px;
        }
        .MuiDialogTitle-root {
          text-align: center;
          font-weight: 600;
          padding: 20px 50px 0px 50px;
        }
        .MuiDialogContent-root {
          padding: 20px 50px;
          .MuiTypography-colorTextSecondary {
            text-align: center;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.8);
          }
        }
        .MuiDialogActions-root {
          justify-content: space-between;
          padding: 0 50px 40px 50px;
          .MuiButtonBase-root {
            height: 52px;
            margin: 0 10px;
            .MuiButton-label {
              font-size: 12px;
            }
            &:hover {
              .MuiButton-label {
              }
            }
          }
        }
      }
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;
