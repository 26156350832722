import React from 'react';

import { IconCycobot } from "../../helpers/Icon";

import sm_logo from "../../assets/images/small-logo.svg";

const NotAvailable = () => {
    return (
        <div className="OnboardPage o-page">
            <div className="header-page__header">
                <img src={sm_logo} alt="Logo Cyconia" className="header-page__small-logo" />
                <span className="header-page__logo-text">Cyconia</span>
            </div>
            <div className="o-onboarding-boxdialog ">
                <div>
                    <img src={IconCycobot} alt="" />
                    <div className="m-card-onboarding">
                        <p>
                            Oups ! Le service Cyconia nécessite l’utilisation d’un navigateur moderne tel 
                            que Microsoft Edge, Firefox ou Chrome … les navigateurs comme Internet Explorer 11 
                            ne sont pas pris en charge :(
                        </p>
                    </div>
 
                </div>
            </div>
        </div>
    );
}

export default NotAvailable;
