import styled from "styled-components";

export const TitleCyconia = styled.span`
  font-size: 38px;
  font-weight: 600;
`;

export const LogoWithCircleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px 0 0;
  box-sizing: border-box;
  max-width: 80px;
  padding: 0;
  border: 0;
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 30px 0;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 380px;
  max-width: 580px;
  margin: 30px auto;
  padding: 40px 50px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 8px;

  .ladda-button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 14px;
    text-align: center;
    font-weight: 700;
    border: none;
    text-decoration: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    height: 60px;
    background-color: #463e96;
    box-sizing: border-box;
    color: #fff;
    text-transform: uppercase;
    position: relative;
    z-index: 0;
  }
`;

export const Container = styled.div`
  align-items: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #e1e4e7;
  background: linear-gradient(to top right,#7094e5,#463e96);
  padding: 0;
  box-sizing: border-box;
  overflow: auto;
`;