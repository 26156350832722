import styled from 'styled-components'


export const Container = styled.div`
  position: fixed;
  top: 30px;
  right: 30px;

  @media (max-width: 768px) {
    position: initial;
    padding: 15px 15px 0 15px;
  }
`;

export const MediaList = styled.ul`
 display: flex;
    justify-content: flex - start;
    flex-direction: column;

`;

export const MediaListItem = styled.li`
  background-color: white;
  border-radius: 4px;
  margin-bottom: 10px;
  width: 260px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Aelement = styled.a`
  padding: 10px;
  display: flex;
  text-decoration: none;
`;

export const Image = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 20px;
`;

export const Paragraphe = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: #3A3A3A;
  display: inline - flex;
  align-items: center;
  text-align: left;
  @media (max-width: 768px) {
    width: calc(100% - 140px);
    display: flex;
    justify-content: center;
  }
`;