import React from 'react';

export const IconCycNew = () => {
    return (<svg version="1.1" id="Calque_1"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 123 123"
        style={{ enableBackground: "new 0 0 123 123" }}
        space="preserve">
        <circle class="st0" style={{fill: "#EDEDED"}} cx="61.5" cy="61.7" r="61" />
        <circle class="st1" style={{fill: "#F7FBFF"}} cx="61.5" cy="61.7" r="51.5" />
        <g id="Clean-Prototype">
            <linearGradient id="logo_icon_bluedark_1_" gradientUnits="userSpaceOnUse" x1="43.761" y1="80.613" x2="89.426" y2="40.7459">
                <stop offset="0" style={{ stopColor: "#357ED3" }} />
                <stop offset="1" style={{ stopColor: "#3949AB" }} />
            </linearGradient>
            <path id="logo_icon_bluedark" 
            style={{fill:"url(#logo_icon_bluedark_1_)"}} 
            class="st2" d="M76.2,41.4h15.6v-1.3l-15.6-3.6c-1-3-3.8-5.2-7.2-5.2c-4.2,0-7.6,3.4-7.6,7.6
                c0,2.1,0.8,4,2.2,5.4l9.5,9.5c1.5,1.5,2.2,3.4,2.2,5.4c0,2.8-2.3,5.1-5.1,5.1h-24c-8.4,0-15.2,6.8-15.2,15.2V92h24
                c13.9,0,25.3-11.3,25.3-25.3v-7.6c0-3.4-1.3-6.5-3.7-8.9l-4.5-4.5C74.1,44.9,75.5,43.3,76.2,41.4z M36.3,79.3
                c0-5.6,4.5-10.1,10.1-10.1h12c-2.3,10.1-11.3,17.7-22.1,17.7V79.3z M55.2,86.9h-3c5.8-4.1,10-10.4,11.3-17.7h6.8
                c1.8,0,3.5-0.5,5-1.4C74.8,78.5,66,86.9,55.2,86.9z M69.1,41.4c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5
                S70.5,41.4,69.1,41.4z"/>
        </g>
    </svg>
    )
}