import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';

export const ArrowIcon = styled(IconButton)`
  &&{
    position: absolute;
    z-index: 1;
    left: ${props => props.position === 'left' && '0px'};
    right: ${props => props.position === 'right' && '0px'};
    opacity: ${props => props.disabled ? "0" : "1"};
    &.MuiIconButton-root {
      background-color: rgba(0, 0, 0, 0.3) !important;
      color: white;
      :hover {
        background-color: rgba(0, 0, 0, 0.5) !important;
      }
    }
    .MuiSvgIcon-root {
      font-size: 1.2rem;
    }
  }
`;