import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import { COLORS } from '../../../../constants/design'

export const HeaderButton = styled.button`
  background-color: ${COLORS.PRIMARY_BLUE};
  color: white;
  cursor: pointer;
  padding: 0px 20px;
  border-radius: 6px;
`;

export const TableButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TableIcon = styled(IconButton)`
  &&{
    height: fit-content;
    .MuiSvgIcon-root {
      font-size: 1.2rem;
    }
  }
`;

export const ProviderListContainer = styled.div`
  overflow: hidden;
  white-space: nowrap;
  width: 150px;
  text-overflow: "…";
  cursor: pointer;
`;

export const StyledTableContainer = styled.div`
  .genericTable {
    height: 80vh;
  }
`;

export const EmptyProviderSites = styled.div`
`;