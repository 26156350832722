import React, { useState, useEffect } from 'react';
import { shallowEqual, useSelector } from "react-redux";
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';
import AddBoxIcon from '@material-ui/icons/AddBox';
import CancelIcon from '@material-ui/icons/Cancel';

import TicketRequestMessage from '../../organisms/TicketRequestMessage';
import GenericWarningModal from '../../molecules/GenericWarningModal';
import GenericTitleWithIcon from '../../molecules/GenericTitleWithIcon';
import GenericLoader from '../../molecules/GenericLoader';

import {
  StyledModal,
  StyledHeader,
  StyledHeaderDescription,
  ContentContainer,
  StyledCloseContainer
} from './style';

import {
  getSelectedServiceTicketIsLoading,
} from '../../../store/sidebar/selectors';

import {
  getConciergeSitesData,
  getConciergeServicesData,
} from '../../../store/concierge/selectors';

const TicketMessageModal = ({ ticketRequest, onClose, isPreview }) => {
  const [currentService, setCurrentService] = useState(null);
  const [isDirty, setIsDirty] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const {
    servicesData,
    selectedServiceTicketIsLoading,
  } = useSelector(
    state => ({
      sitesData: getConciergeSitesData(state),
      servicesData: getConciergeServicesData(state),
      selectedServiceTicketIsLoading: getSelectedServiceTicketIsLoading(state)
    }),
    shallowEqual
  );

  useEffect(() => {
    if (ticketRequest && servicesData) {
      const selectedService = servicesData.find(item => item.id == ticketRequest?.service_id);
      if (selectedService) {
        setCurrentService(selectedService);
      }
    }
  }, [ticketRequest, servicesData]);

  const handleModalClose = () => {
    if (isDirty) setOpenDelete(true);
    else
      onClose();
  }

  const generateHeaderDesc = () => {
    const dateString = format(new Date(ticketRequest.created_at), "dd MMMM yyyy 'à' HH'h'mm", { locale: fr });
    return (
      <StyledHeaderDescription>
        <GenericTitleWithIcon title={`Créé le ${dateString}`} Icon={<AddBoxIcon style={{ fontSize: '0.8rem' }} />} />
      </StyledHeaderDescription>
    )
  }

  return (
    <StyledModal
      open={true}
      onClose={handleModalClose}
      hideCloseButton
    >
      <StyledCloseContainer onClick={handleModalClose}>
        <CancelIcon style={{ fontSize: 30 }} />
      </StyledCloseContainer>
      {(selectedServiceTicketIsLoading) &&
        <GenericLoader />
      }
      {(!selectedServiceTicketIsLoading && ticketRequest) &&
        <>
          <StyledHeader
            headerTitle={`${currentService?.name || ''} N°${ticketRequest?.id}`}
            headerDesc={generateHeaderDesc()}
          />
          <ContentContainer>
            <TicketRequestMessage ticketRequest={ticketRequest} messengertype={'client'} setIsDirty={setIsDirty} isPreview={isPreview} />
          </ContentContainer>
        </>
      }
      <GenericWarningModal
        open={openDelete}
        setOpenDialog={() => setOpenDelete(!openDelete)}
        title={"Attention"}
        text={"Voulez-vous vraiment abandonner votre espace de suivi de demande ?"}
        leftButtonText={"Non"}
        rightButtonText={"Oui"}
        leftButtonFunction={() => setOpenDelete(!openDelete)}
        rightButtonFunction={onClose}
      />
    </StyledModal>
  )
}

export default TicketMessageModal;