import styled from "styled-components";
import { NavLink } from "react-router-dom";

import breakpoints from "../../../../../theme-style/breakpoints";
import GenericTitleWithToolTip from '../../../../../components/molecules/GenericTitleWithToolTip';

export const MainContainer = styled.div`
`;

export const BorneList = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 0;
  margin: 0;
`;

export const BorneItemTitle = styled.strong`
  font-size: 16px;
  color: #5A5A5A;
  font-weight: 600;
  text-align: left;
  transition: all .2s ease-out;
`;

export const ArrowIconWrapper = styled.span`
  max-width: 7px;
  max-height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 auto;
  svg path{
    transition: all .2s ease-out;
  }
`;

export const BorneItemNotConnected = styled(NavLink)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 15px;
  margin: 0;
  background-color: #FFFFFF;
  height: auto;
  cursor: pointer;
  transition: all .2s ease-out;
  text-decoration: none;
  list-style: none;
  border-radius: 6px;
  @media ${breakpoints.mobileXL} {
    padding: 25px 20px 25px 25px;
  }

  &:last-child{
    border-bottom: 0;
  }
  &:hover{
    background-color: #FAFAFA;
    ${BorneItemTitle}{
      color: #4A90E2;
    }
    ${ArrowIconWrapper}{
      svg{
        path{
          fill: #397dd2;
          stroke: #397dd2;
        }
      }
    }
  }
`;

export const BorneItemCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4A90E2;
  border-radius: 100px;
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;
  margin-right: 25px;
`;

export const BorneSmallIconWrapper = styled.span`
  max-width: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
`;

export const BorneItemConnected = styled(NavLink)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  background-color: #FFFFFF;
  height: auto;
  cursor: pointer;
  transition: all .2s ease-out;
  text-decoration: none;
  list-style: none;
  border-radius: 6px;
  &:last-child{
    border-bottom: 0;
  }
  &:hover{
    background-color: #FAFAFA;
    ${BorneItemTitle}{
      color: #4A90E2;
    }
    ${ArrowIconWrapper}{
      svg{
        path{
          fill: #397dd2;
          stroke: #397dd2;
        }
      }
    }
  }
`;

export const BorneItemConnectedHead = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 12px 15px;
  box-sizing: border-box;
  border-bottom: 1px solid #F4F4F4;

  @media ${breakpoints.mobileXL} {
    padding: 15px 20px 15px 25px;
  }
`;

export const BorneItemWithoutOrders = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  border-bottom: 1px solid #F4F4F4;
`;

export const BorneItemWithoutOrdersTop = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  width: 100%;
  padding: 12px 15px;

  @media ${breakpoints.mobileXL} {
    padding: 15px 25px;
  }
`;

export const BorneItemConnectedBodyCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;
  background-color: #EDEDED;
  border-radius: 100px;
  margin: 0 25px 0 0;
`;

export const BorneItemConnectedCircle = styled.div`
  width: 5px;
  min-width: 5px;
  height: 5px;
  min-height: 5px;
  background-color: #4A90E2;
  border-radius: 100px;
  margin: 0;
  &:nth-child(2){
    margin: 0 2px;
  }
  &.borneItemCircle-blue{
    background-color: #4A90E2;
  }
  &.borneItemCircle-green{
    background-color: #40B176;
  }
  &.borneItemCircle-orange{
    background-color: #FF9F6D;
  }
`;

export const BorneItemConnectedBodyContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const BorneItemConnectedBodyTitle = styled.strong`
  font-weight: 600;
  font-size: 14px;
  color: #5A5A5A;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  transition: all .2s ease-out;
`;

export const BorneItemConnectedBodyText = styled.p`
  margin: 4px 0 0 0;
  font-size: 12px;
  color: #BBBBBB;
  line-height: 14px;
  font-weight: 600;
`;

export const BorneItemConnectedBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  border-bottom: 1px solid #F4F4F4;
`;

export const BorneItemConnectedBodyTop = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 12px 15px;

  @media ${breakpoints.mobileXL} {
    padding: 15px 25px;
  }
`;

export const BorneItemConnectedBodyMiddle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 0px 25px 15px 25px;
  box-sizing: border-box;
`;

export const BorneItemConnectedBodyMiddleItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 3px 0;
  box-sizing: border-box;
`;

export const BorneItemConnectedOnlyCircle = styled.div`
  width: 8px;
  min-width: 8px;
  height: 8px;
  min-height: 8px;
  background-color: #4A90E2;
  border-radius: 100px;
  margin: 0 36px 0 11px;
  &.borneItemOnlyCircle-blue{
    background-color: #4A90E2;
  }
  &.borneItemOnlyCircle-green{
    background-color: #40B176;
  }
  &.borneItemOnlyCircle-orange{
    background-color: #FF9F6D;
  }
`;

export const BorneItemConnectedBodyMiddleItemText = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  color: #c7c7c7;
  margin: 0px 10px 0 0;
`;

export const BorneItemConnectedBodyMiddleItemDate = styled.div`
  margin: 0 0 0 auto;
  background-color: #4A90E2;
  color: #FFFFFF;
  font-size: 11px;
  font-weight: 600;
  padding: 4px 7px;
  border-radius: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const BorneItemConnectedFoot = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 12px 15px;

  @media ${breakpoints.mobileXL} {
    padding: 15px 25px;
  }
`;

export const BorneItemConnectedFootCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;
  font-size: 16px;
  color: #FFFFFF;
  font-weight: 600;
  background-color: #F9D57C;
  border-radius: 100px;
  margin-right: 25px;
`;

export const BorneItemConnectedFootTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: #5A5A5A;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const BorneItemConnectedFootCash = styled.div`
  background-color: #F9D57C;
  padding: 5px 10px;
  color: #FFFFFF;
  font-size: 13px;
  font-weight: 700;
  border-radius: 4px;
  margin: 0 0 0 auto;
`;