import React from "react";

const LotusIcon = ({
  color = "#FFFFFF",
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon lotusIcon"
      width={width}
      height={height}
      viewBox="0 0 18 13"
      fill="none"
    >
      <path
        d="M9.473.18c.018.015.09.08.2.188l.152.15c.151.156.342.36.552.608.273.496 1.376 1.738 1.415 2.105.19.375.362.775.5 1.197.4-.201 1.406-.59 1.494-.624.115-.045.217-.075.307-.09l.755-.123.507-.082.043-.007a10.925 10.925 0 011.925-.107c.361.02.65.306.67.663 0 .02.005.092.006.209v.255c-.017 1.16-.263 4.218-2.284 6.218C13.609 12.823 10.342 13 9.292 13c-.14 0-.239-.003-.292-.005a7.259 7.259 0 01-.293.005c-1.05 0-4.317-.177-6.423-2.26C.263 8.74.017 5.682 0 4.522v-.255c.002-.117.005-.189.006-.209a.706.706 0 01.67-.663 11.334 11.334 0 012.444.19l.002-.01c.49.087.803.14.636.14 0 0 .76.12.74.218.402.131.81.294 1.208.495.104-.316.227-.62.362-.911l.14-.286.806-1.322c.439-.616.87-1.094 1.16-1.39l.151-.151c.111-.109.183-.173.2-.189a.716.716 0 01.948 0zm-8.04 4.626c.055 1.136.36 3.462 1.856 4.94 1.154 1.142 2.807 1.583 4.03 1.75-.894-1.147-1.958-2.942-1.958-4.999 0-.215.011-.428.033-.637C4 5.048 2.341 4.85 1.432 4.806zm15.136 0c-.909.043-2.57.241-3.964 1.054.022.21.034.422.034.637 0 2.055-1.062 3.849-1.957 4.996 1.226-.17 2.88-.61 4.029-1.747 1.501-1.485 1.803-3.806 1.858-4.94zm-7.57-3.1c-.772.842-2.218 2.7-2.218 4.791S8.227 10.445 9 11.289c.773-.844 2.218-2.701 2.218-4.792 0-2.091-1.445-3.948-2.218-4.792z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export default LotusIcon;
