import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import GenericTitleWithToolTip from '../GenericTitleWithToolTip';
import GenericErrorMessage from '../GenericErrorMessage';

import {
  MainContainer,
  StyledTextField,
} from './style'

const GenericTextField = ({
  title = "",
  toolTipText = null,
  toolTipProps,
  ToolTipIcon,
  isRequired,
  className, // to pass styled component className to mainContainer
  value,     // formik props
  onChange,  // formik props
  readOnly = false,
  updateOnBlur = true,
  variant = "outlined",
  size = "small",
  ...rest    // the rest of the props will be given to TextField component
}) => {
  const [currentValue, setCurrentValue] = useState();

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleOnChange = (event) => {
    if (!readOnly) {
      if (updateOnBlur) {
        setCurrentValue(event.target.value);
      }
      else {
        onChange(event);
      }
    }
  }

  const handelOnBlur = (event) => {
    if (!readOnly) {
      onChange(event);
    }
  }

  return (
    <MainContainer className={`${className ? className : ''} genericTextField`}>
      <GenericTitleWithToolTip
        title={title}
        toolTipText={toolTipText}
        ToolTipIcon={ToolTipIcon}
        toolTipProps={toolTipProps}
        isRequired={isRequired}
        className={'textFieldTitle'}
      />
      <StyledTextField
        {...rest}
        variant={variant}
        size={size}
        className={'textFieldInput'}
        value={currentValue}
        onChange={handleOnChange}
        onBlur={handelOnBlur}
        InputProps={{
          readOnly: Boolean(readOnly),
        }}
      />
      {rest.id && <GenericErrorMessage name={rest.id} />}
    </MainContainer>
  )
}

GenericTextField.propTypes = {
  title: PropTypes.string,
  toolTipText: PropTypes.any,
  isRequired: PropTypes.bool,
  className: PropTypes.string
}

export default GenericTextField

// The provided className of individual tag can be used to be styled when wrapped by styled component
// For the styling of material ui component please refer to the style.js