import styled from "styled-components";
import {
  NavLink,
} from "react-router-dom";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { COLORS } from '../../../../../constants/design'
import EmailIcon from '@material-ui/icons/Email';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import breakpoints from "../../../../../theme-style/breakpoints";


export const ArrowIconWrapper = styled.span`
  max-width: 7px;
  max-height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 auto;
  svg path{
    transition: all .2s ease-out;
  }
`;

export const SkillsDomainsItemTitle = styled.strong`
  font-weight: 600;
  font-size: 14px;
  color: #5A5A5A;
  transition: all .2s ease-out;
`;

export const SkillsDomainsItemText = styled.span`
  margin: 4px 0 0 0;
  font-size: 12px;
  color: #BBBBBB;
  line-height: 14px;
  font-weight: 600;
`;

export const SkillsDomainsItemContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

export const SkillsDomainsItemCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  min-width: 50px;
  height: 50px;
  min-height: 50px;
  border-radius: 100px;
  background-color: #397dd2;
  font-size: 24px;
  color: #FFFFFF;
  font-weight: 700;
  margin-right: 15px;
`;

export const DesktopMessageIconWrapper = styled.span`
  max-width: 15px;
  max-height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 2px 0;
`;

export const SkillsDomainsItem = styled(NavLink)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  background-color: #FFFFFF;
  height: auto;
  cursor: pointer;
  border-bottom: 1px solid #F4F4F4;
  transition: all .2s ease-out;
  text-decoration: none;
  list-style: none;
  padding: 10px 15px 10px 10px;
  @media ${breakpoints.mobileXL} {
    padding: 15px 20px 15px 15px;
  }
  &:last-child{
    border-bottom: 0;
    border-radius: 0 0 6px 6px;
  }
  &:hover{
    background-color: #FAFAFA;
    ${ArrowIconWrapper}{
      svg{
        path{
          fill: #397dd2;
          stroke: #397dd2;
        }
      }
    }
  }
`;

export const SkillsDomainsList = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 0;
  margin: 0;
`;

export const SkillsDomainsHeadTitle = styled.strong`
  font-size: 16px;
  color: #4A90E2;
  font-weight: 600;
  text-align: left;
`;

export const SkillsDomainsHeadCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4A90E2;
  border-radius: 100px;
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;
  margin-right: 25px;
`;

export const SkillsDomainsHead = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 25px;
  margin: 0;
  background-color: #FFFFFF;
`;

export const SkillsDomains = styled.div`
  border-radius: 6px;
  overflow: hidden;
  margin: 0 0 15px 0;
  background-color: ${COLORS.WHITE};

  @media ${breakpoints.mobileXL} {
    margin: 0 0 20px 0;
  }
`;


export const StyledAccordion = styled(Accordion)`
  && {
    padding: 0;

    @media ${breakpoints.mobileXL} {
      padding: 0px 0px 0px 10px;
    }

    &.MuiPaper-elevation1 {
      box-shadow: unset;
    }
    &.MuiAccordion-root {
      background-color: ${COLORS.WHITE};
      color: ${COLORS.DARK_GREY};
      ::before {
        background-color: unset;
      }
    }
    &.MuiAccordion-rounded{
      border-radius: 6px;
    }
    .MuiAccordionSummary-root{
      @media ${breakpoints.mobileXL} {
        padding: 0px 10px 0px 15px;
      }
    }
    .MuiAccordionSummary-expandIcon .MuiSvgIcon-root {
      color: ${COLORS.DARK_GREY};
    }
    .MuiAccordionDetails-root {
      flex-direction: column;
      padding: 8px 15px 10px;

      @media ${breakpoints.mobileXL} {
        padding: 8px 16px 16px;
      }
    }
  } 
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
&& {
  .MuiAccordionSummary-content {
    display: flex;
    align-items: center;
    >div:not(:last-child) {
      margin-right: 12px;
    };
  }
}
`;

export const CircleDiv = styled.div`
  background-color: ${COLORS.WHITE};
  border-radius: 50%;
  display: flex;
  height: 25px;
  width: 25px;
  justify-content: center;
  align-items: center;
  color: ${COLORS.LIGHT_GREY_4};
`;

export const StyledAccordionTitle = styled.div`
  font-size: 16px;
  color: #4A90E2;
  font-weight: 600;
  text-align: left;
  margin-left: 10px;
`;

export const StyledUserInfoContainer = styled.div`
  font-size: 0.75rem;
  >div:not(:last-child) {
    margin-bottom: 5px;
  };
  padding: 0px 5px;
  .genericTitleWithIcon {
    >div:not(:last-child) {
      margin-right: 20px;
    };
  }
`;

export const ContainerItemText = styled.div`
  margin-top: 10px;
`;

export const IconAndText = styled.div`
  font-size: 13px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`

export const ConciergerieDescription = styled.div`
  font-size: 0.9rem;
  font-weight: 500;
  margin-top: 10px;
  width: 100%;
`;

export const ConciergerieDescriptionSpan = styled.span`
  overflow-wrap: break-word;
`

export const StyledEmailIcon = styled(EmailIcon)`
`;

export const StyledPhoneIcon = styled(LocalPhoneIcon)`
`