import axios from 'axios';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';

import { CONTENT_STATUS } from '../constants/contentConstants';

export const editGoodDealService = async (endpoint, userToken, formData) => {
  const newForm = new FormData();
  let sitesList = {};
  formData.sites.map((site, index) => {
    sitesList[index] = JSON.stringify(site.value);
    return site.value;
  })

  newForm.append('title', formData?.title);
  newForm.append('content', formData?.content);
  newForm.append('companySite', JSON.stringify(sitesList));
  newForm.append('teaser', formData?.title);
  newForm.append('dealValue', formData?.dealValue);
  newForm.append('link', formData?.link);
  newForm.append('description', formData?.description);
  if (formData.status === CONTENT_STATUS.NOT_PUBLISHED) {
    newForm.append('published', false);
    newForm.append('archived', false);
  }
  if (formData.status === CONTENT_STATUS.ARCHIVED) {
    newForm.append('published', false);
    newForm.append('archived', true);
  }
  if (formData.status === CONTENT_STATUS.PUBLISHED) {
    newForm.append('published', true);
    newForm.append('archived', false);
  }
  if (typeof formData?.chooseFile !== 'string') newForm.append('bannerFile', formData?.chooseFile);

  const headers = {};
  if (userToken) {
    headers.Authorization = `Bearer ${userToken}`;
  }

  return axios.request({
    method: 'post',
    url: endpoint,
    data: newForm,
    headers: headers
  });
}

export const deleteGoodDealService = async (endpoint, userToken) => {
  const headers = {};
  if (userToken) {
    headers.Authorization = `Bearer ${userToken}`;
  }

  return axios.request({
    method: 'delete',
    url: endpoint,
    headers: headers
  });
}