import styled from "styled-components";
import RadioGroup from '@material-ui/core/RadioGroup';
import { Link } from "react-router-dom";

export const BackButton = styled(Link)`
  &&{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 10px 0 0;
    padding: 14px;
    text-align: center;
    font-weight: 700;
    border: none;
    text-decoration: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    height: 60px;
    background-color: #eaeaea;
    box-sizing: border-box;
    color: #696969;
    text-transform: uppercase;
  }
`;

export const ProfileIconWrapper = styled.span`
  position: absolute;
  top: 9px;
  right: -16px;
  padding: 0px;
  max-width: 14px;
  max-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  svg path{
    transition: all .2s ease-out;
  }
`;

export const ActionBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 0 0;
`;

export const RadioGroupCustom = styled(RadioGroup)`
  &&{
    padding: 0 20px 15px 20px;
    display: flex;
    flex-direction: row;
    .MuiFormControlLabel-root{
      position: relative;
      &:hover{
        .MuiRadio-root{
          color: #40B176;
        }
        .MuiTypography-root{
          span{
            svg path{
              fill: #40B176;
              stroke: #40B176;
            }
          }
        }
      }
      .MuiRadio-root{
        color: #E5E5E5;
        padding: 5px 5px;
        margin: 0 5px;
        transition: all .2s ease-out;
        &.Mui-checked{
          color: #40B176;
          & + .MuiTypography-root{
            span{
              svg path{
                fill: #40B176;
                stroke: #40B176;
              }
            }
          }
        }
        .MuiIconButton-label{
          input{

          }
          div{
            svg{

            }
          }
        }
      }
      .MuiTypography-root{
        color: #5A5A5A;
        font-weight: 600;
        font-size: 13px;
        line-height: 9px;
        text-align: left;
        margin: 0;
      }
    }
  }
`;

export const RadioBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 0 30px 0;
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    label{
      font-size: 15px;
      font-weight: 500;
      color: #838383;
    }
  }
`;

export const ErrorText = styled.p`
  display: flex;
  width: 100%;
  font-size: 11px;
  font-weight: 500;
  font-style: italic;
  color: #ff4f4f;
  text-align: left;
  padding: 8px 0 0 0;
`;

export const Text = styled.p`
  display: block;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 600;
  color: #838383;
  text-align: center;
  font-weight: 400;
  &:last-child{
    margin: 0;
  }
`;

export const BubbleText = styled.div`
  position: relative;
  padding: 15px;
  margin: 0 0 15px 0;
  border-radius: 5px 10px 10px 10px;
  background-color: #FFFFFF;
  box-shadow: 1px 1px 3px 1px rgb(0 0 0 / 20%);
`;

export const Container = styled.div`
  
`;