import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Modal from "react-responsive-modal";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import LaddaButton, { SLIDE_UP } from 'react-ladda';
import 'react-responsive-modal/styles.css';
import GenericCheckbox from '../../../../components/molecules/GenericCheckbox';

// Store
import {
  isUserRegisterOnError,
  isUserRegisterOnLoading,
  isUserRegisterOnSuccess,
} from '../../../../store/authentication/selectors';
import {
  register,
} from '../../../../store/authentication/actions';

// Style
import {
	ModalCustom,
	Title,
	Text,
} from './style';
import {
	LinkRgpdWrapper,
} from '../../style';

// Components
import { FieldText } from '../../../../components/molecules/Field';
// Icons
import { IconUser } from '../../../../helpers/Icon';

const RegisterForm = () => {
  const query = new URLSearchParams(window.location.search);
  const emailParam = query.get('email');
  const firstnameParam = query.get('firstname');
  const lastnameParam = query.get('lastname');
  const acceptPtdcpParam = query.get('acceptPtdcp');
  const dispatch = useDispatch();
  const [email, setEmail] = useState(emailParam || '');
  const [firstname, setFirstname] = useState(firstnameParam || '');
  const [lastname, setLastname] = useState(lastnameParam || '');
  const registerDate = moment().format("YYYY-MM-DD HH:mm:ss");
  const [acceptPtdcp, setAcceptPtdcp] = useState(acceptPtdcpParam || registerDate);
  const [checkedCGU, setCheckedCGU] = React.useState(false);
  const [showModalUserCreated, setShowModalUserCreated] = useState(false);
  const ADMIN_BASE_URL = process.env.REACT_APP_ADMIN_BASE_URL;
  const LinkRgpdFiles = "https://" + ADMIN_BASE_URL + "/build/files/cyconia/politique-de-traitement-de-donnees.pdf";

  const {
    userRegisterOnError,
    userRegisterOnLoad,
    userRegisterOnSuccess,
  } = useSelector(
    state => ({
      userRegisterOnError: isUserRegisterOnError(state),
      userRegisterOnLoad: isUserRegisterOnLoading(state),
      userRegisterOnSuccess: isUserRegisterOnSuccess(state),
    }),
    shallowEqual);

  const onSubmitRegister = e => {
    e.preventDefault();

    if (!!email?.length && !!firstname?.length && !!lastname?.length && !!acceptPtdcp?.length ) {
      dispatch(register(email, firstname, lastname, acceptPtdcp));
    } else {
      console.log('ERROR SUBMIT REGISTER!!!');
    }
  };

  const onInputChange = e => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    if (name === 'email') {
      setEmail(value);
    } else if (name === 'firstname') {
      setFirstname(value);
    } else if (name === 'lastname') {
      setLastname(value);
    } else if (name === 'acceptPtdcp') {
      setAcceptPtdcp(registerDate);
      setCheckedCGU(e.target.checked);
    }
  };

  // const showModalNewUser = () => {
  //   setShowModalUserCreated(!showModalUserCreated);
  // }

  useEffect(() => {
    if (userRegisterOnError) {
      switch (userRegisterOnError.message) {
        case "User already used.":
          toast.error("Cette adresse e-mail est déjà utilisée.", {
            position: toast.POSITION.TOP_RIGHT,
            className: "custom-toast custom-toast-error",
            bodyClassName: "custom-toast__body custom-toast__body-success",
            progressClassName: "custom-toast__progress"
          });
          break;
        case "User mail is not valid.":
          toast.error("Cette adresse n’est pas autorisée. Veuillez utiliser votre adresse mail professionnelle.", {
            position: toast.POSITION.TOP_RIGHT,
            className: "custom-toast custom-toast-error",
            bodyClassName: "custom-toast__body custom-toast__body-success",
            progressClassName: "custom-toast__progress"
          });
          break;
        default:
          toast.error("Une erreur est survenue ! Veuillez réessayer plus tard. Si le problème persiste, contactez le support. ", {
            position: toast.POSITION.TOP_RIGHT,
            className: "custom-toast custom-toast-error",
            bodyClassName: "custom-toast__body custom-toast__body-success",
            progressClassName: "custom-toast__progress"
          });
      }
    }
  }, [userRegisterOnError]);

  useEffect(() => {
    if (userRegisterOnSuccess) {
      setShowModalUserCreated(true);
    }
  }, [userRegisterOnSuccess])

  return (
    <>
      {/* <ToastContainer
        autoClose={8000}
        className="custom-toast-container custom-toast-container__success"
      /> */}
      <form name="form" onSubmit={onSubmitRegister}>
        <FieldText
          name="email"
          label="Votre e-mail professionnel"
          value={email}
          onChange={onInputChange}
          alertMessage="Votre email est requis"
          required={true}
          className="dual-theme"
          icon={IconUser}
          type="email"
        />
        <FieldText
          name="firstname"
          label="Prénom"
          value={firstname}
          onChange={onInputChange}
          alertMessage="Votre prénom est requis"
          required={true}
          className="dual-theme"
          icon={IconUser}
          type="text"
        />
        <FieldText
          name="lastname"
          label="Nom"
          value={lastname}
          onChange={onInputChange}
          alertMessage="Votre nom est requis"
          required={true}
          className="dual-theme"
          icon={IconUser}
          type="text"
        />

        <GenericCheckbox
          name="acceptPtdcp"
          className="generic-checkbox-cgu"
          posTop={true}
          title={"En m'inscrivant et en utilisant Cyconia, j'accepte le traitement des données à caractère personnel me concernant conformément à la Politique de protection des données de Zen&Bien. Je pourrai retirer mon consentement à tout moment en supprimant mon compte Cyconia. Pour toute question relative à la protection de mes données et pour exercer mes droits, je peux contacter le Délégué à la Protection des Données (DPO) de Zen&Bien par email à l'adresse julien@droit-ntic.com"}
          checked={checkedCGU}
          onChange={(e) => onInputChange(e)}
        />

        <LinkRgpdWrapper>
          <a target="_blank" href={LinkRgpdFiles} rel="noreferrer">
            Politique de traitement des données
          </a>
        </LinkRgpdWrapper>

        <LaddaButton
            className="a-button a-button-big a-button-purple"
            loading={userRegisterOnLoad}
            data-color="#eee"
            data-style={SLIDE_UP}
            data-spinner-size={30}
            data-spinner-color="#fff"
            data-spinner-lines={12}
            disabled={!checkedCGU}
        >
            S'inscrire
        </LaddaButton>
        {/*registering &&
            <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        */}
      </form>

      <ModalCustom
        aria-labelledby="simple-dialog" 
        open={showModalUserCreated}
        onClose={() => setShowModalUserCreated(false)}
      >
          <div>
            <Title>
                Votre compte a bien été créé !
            </Title>
            <Text>
                Nous avons envoyé un message à l'adresse email pour vous permettre de confirmer votre compte.
            </Text>
            <Text>
                Vous n'avez pas reçu l'e-mail ? <br />
                Pensez à regarder dans vos SPAM. <br />
                Contactez notre <a href="mailto:contact@cyconia.io">Assistance Cyconia</a>
            </Text>
          </div>
      </ModalCustom>
    </>
  );
};

export default RegisterForm;
