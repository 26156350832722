import _ from 'lodash';

export const getWeatherData = state => state.adminFront.weather.data;
export const getLastUntreatedFeedbackData = state => state.adminFront.lastUntreatedFeedback.data;
export const getUntreatedFeedbackStatsData = state => state.adminFront.untreatedFeedbackStats.data;
export const getActiveInfoElementData = state => state.adminFront.activeInfoElement.data;
export const getInactiveInfoElementData = state => state.adminFront.inactiveInfoElement.data;

// collaboratorsList
export const getCollaboratorsListData = state => state.adminFront.collaboratorsList.data;
export const getCollaboratorsListIsLoading = state => state.adminFront.collaboratorsList.get.loading;
export const getCollaboratorsListSuccess = state => state.adminFront.collaboratorsList.get.success;

export const getCollaboratorsRulesData = state => state.adminFront.collaboratorsRules.data;
export const getUntreatedFeedbackData = state => state.adminFront.untreatedFeedback.data;
export const getUntreatedFbBySkillData = state => state.adminFront.untreatedFbBySkill.data;
export const getUntreatedFbBySkillStatsData = state => state.adminFront.untreatedFbBySkillStats.data;
export const getSkillsDomainsListData = state => state.adminFront.skillsDomainsList.data;
export const getFeebackByInfoElData = state => state.adminFront.feebackByInfoEl.data;
export const getTreatedFeedbackData = state => state.adminFront.treatedFeedback.data;
export const getTreatedFeedbackStatsData = state => state.adminFront.treatedFeedbackStats.data;
export const getTreatedFbBySkillStatsData = state => state.adminFront.treatedFbBySkillStats.data;
export const getPositiveBotFeedbackData = state => state.adminFront.positiveBotFeedback.data;
export const isUpdateNegativeBotFeedbackOnSuccess = state => state.adminFront.negativeBotFeedback.update.success;
export const isInformationListLoading = state => state.adminFront.informationList.get.loading;
export const getInformationListData = state => state.adminFront.informationList.data;
export const getElementInformationListData = state => {
  const _data = state.adminFront.elementInformationList.data;
  return _data ? _data[0] : null;
};
export const getFeedsStatInfoData = state => state.adminFront.feedsStatInfo.data;
export const getFeedsListBySiteData = state => state.adminFront.feedsListBySite.data;
export const getElementInformationResponseByIdData = state => state.adminFront.elementInformationResponseById.data;
export const getElementInformationByCompanyData = state => state.adminFront.elementInformationByCompany.data;
export const isActiveElementInformation = state => state.adminFront.updateEiActivation.isActive;
export const updateElementInformationResponseData = state => state.adminFront.updateEiResponse.data;
export const updateProcessedFeedbackData = state => state.adminFront.updateProcessedFeed.data;
export const setInformationElementTitleData = state => state.adminFront.setInformationElementTitle.data;

export const isUpdateElementInformationResponseonError = state => state.adminFront.updateEiResponse.update.error;
export const isUpdateNegativeBotFeedbackOnError = state => state.adminFront.negativeBotFeedback.update.error;

export const isAllUpdateElementInformationOnSuccess = state => state.adminFront.elementInformationList.updateAll.success;

export const getUpdatedResponseId = state => state.adminFront.elementInformationList.updatedResponseId;

export const getSkillsDomainsListDataByCode = (state, codes) => {
  const skillsDomainsList = state.adminFront.skillsDomainsList.data;
  if (skillsDomainsList) return skillsDomainsList.filter(skill => codes.includes(skill.code));
  return null;
}

export const getActiveInfoElementDataByCode = (state, codes) => {
  const activeInfoElement = state.adminFront.activeInfoElement.data;;
  if (activeInfoElement) return _.pickBy(activeInfoElement, (info) => codes.includes(info.title));
  return null;
}

export const getInactiveInfoElementDataByCode = (state, codes) => {
  const inactiveInfoElement = state.adminFront.inactiveInfoElement.data;
  if (inactiveInfoElement) return inactiveInfoElement.filter(info => codes.includes(info.expertiseArea));
  return null;
};

// SpecificCase
export const getSpecificCaseData = state => state.adminFront.specificCase.data;
export const getSpecificCaseIsLoading = state => state.adminFront.specificCase.get.loading;