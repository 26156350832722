import styled from "styled-components";
import Dialog from '@material-ui/core/Dialog';
import { Form } from 'formik';

import { COLORS } from "../../../../constants/design";
import { size } from "../../../../theme-style/breakpoints";

import GenericTitleWithToolTip from "../../../../components/molecules/GenericTitleWithToolTip";
import GenericTitleWithIcon from "../../../../components/molecules/GenericTitleWithIcon";
import GenericMaterialMenu from "../../../../components/molecules/GenericMaterialMenu";
import GenericDrawerValidationButtons from "../../../../components/molecules/GenericDrawerValidationButtons";

export const CustomDialog = styled(Dialog)`
  && {
    .MuiDialog-container {
      .MuiDialog-paper {
        &.MuiPaper-rounded {
          width: 100%;
          /* min-height: 70vh; */
          max-height: 100%;
        }
        &.MuiDialog-paperWidthSm {
          max-width: 700px;
        }
      }
      @media (max-width: ${size.mobileXL}) {
        .MuiDialog-paper {
          &.MuiDialog-paperScrollPaper {
            max-height: 100%;
          }
          &.MuiDialog-paper {
            margin: 0;
          }
          &.MuiPaper-rounded {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .genericLoader {
    height: 700px;
  }
`;

export const DialogContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 25px;
  background-color: white;
  border-radius: 6px;
  color: ${COLORS.DARK_GREY};
  font-size: 0.9rem;
  /* >div:not(:last-child) {
    margin-bottom: 15px;
  }; */
`;

export const StyledCloseContainer = styled.div`
  position: absolute;
  z-index: 9999;
  color: ${COLORS.DARK_GREY};
  background-color: ${COLORS.LIGHT_GREY_1};
  border-radius: 50%;
  padding: 1px;
  display: flex;
  opacity: 0.8;
  top: 20px;
  right: 20px;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;

export const StyledTitle = styled(GenericTitleWithToolTip)`
  font-weight: 700;
  font-size: 1.1rem;
  margin-bottom: 15px;
  .textTooltip {
    color: ${COLORS.GREY_1};
  }
`;

export const StyledInputTitle = styled(GenericTitleWithToolTip)`
  margin-bottom: 0px !important;
`;

export const StyledForm = styled(Form)`
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: normal;
  box-sizing: border-box;
  /* align-items: center; */
  /* padding: 30px 30px 30px 30px; */
  .textTitle {
    font-weight: 700;
    font-size: 0.9rem;
    color: #5A5A5A;
    margin: 14px 5px 14px 0px;
  }
  .textTooltip {
    color: ${COLORS.GREY_1};
  }
  .textFieldInput {
    input {
      font-size: 13px;
    }
  }
  >div:not(:last-child) {
    margin-bottom: 15px;
  };
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const StyledGenericMaterialMenu = styled(GenericMaterialMenu)`
  .textFieldTitle {
    margin-bottom: 10px;
  }
`;

export const StyledExpandableHeader = styled.div`
  /* border: 1px solid black; */
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const StyledExpandableTitle = styled(GenericTitleWithToolTip)`
  /* font-weight: 700;
  font-size: 1.1rem; */
  /* margin-bottom: 15px; */
`;

export const StyledExpandableIcon = styled(StyledCloseContainer)`
  position: relative;
  top: 0;
  right: 0;
`;

export const StyledExpandableContainer = styled.div`
  border: 1px solid ${COLORS.LIGHT_GREY_4};
  border-radius: 5px;
  padding: ${props => props.isVisible ? '15px 20px' : '0px'};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: ${props => props.isVisible ? '15px' : '0px !important'};
  height: ${props => props.isVisible ? '300px' : '0px'};
  transform: ${props => props.isVisible ? 'scaleY(1)' : 'scaleY(0)'};
  opacity: ${props => props.isVisible ? '1' : '0'};
  transform-origin: top;
  overflow: auto; 
  transition: all 0.3s ease-in-out;
  >div:not(:last-child) {
    margin-bottom: 15px;
  };
`;

export const StyledOptionExpandableContainer = styled(StyledExpandableContainer)`
  height: ${props => props.isVisible ? '220px' : '0px'};
`;

export const StyledValidationButtons = styled(GenericDrawerValidationButtons)`
/* border: 1px solid red; */
  .customButton {
    width: 45%;
  }
`;

export const StyledTitleWithIcon = styled(GenericTitleWithIcon)`
  /* text-transform: ${props => props.longText ? "" : "capitalize"}; */
  /* align-self: flex-start; */
  font-weight: 700;
  margin-top: 10px;
  .gtwi-titleContainer {
    margin-left: 10px;
    max-width: 300px;
  }
`;

export const StyledSummaryContainer = styled.div`
  /* border: 1px solid black; */
  /* margin: 10px 0px; */
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  .textTitle {
    font-weight: 700;
    font-size: 0.9rem;
    color: #5A5A5A;
    margin: 14px 5px 14px 0px;
  }
  >div:not(:last-child) {
    margin-bottom: 15px;
  };
  ${StyledValidationButtons} {
    /* border: 1px solid black; */
    margin-top: 30px;
  }
`;

export const StyledInfoHeader = styled.div`
  font-weight: 700;
  /* margin-right: 20px; */
  margin-bottom: 30px !important;
  font-size: 0.9rem;
  align-self: center;
`;

export const StyledInfoGroup = styled.div`
  /* border: 1px solid black; */
  width: 100%;
  display: flex;
`;

export const StyledInfoTitle = styled.div`
  /* border: 1px solid blue; */
  font-weight: 700;
  margin-right: 20px;
  width: 30%;
  font-size: 0.9rem;
`;

export const StyledInfoText = styled.div`
  /* border: 1px solid red; */
  width: 60%;
  font-size: 0.9rem;
  word-wrap: break-word;
`;

export const StyledOptionsContainer = styled.div`
  border: 1px solid ${COLORS.LIGHT_GREY_4};
  border-radius: 5px;
  padding: 10px;
  >div:not(:last-child) {
    margin-bottom: 5px;
  };
  ${StyledInfoTitle} {
    margin-right: 10px;
  }
  /* display: flex; */
`;

export const StyledWarningDiv = styled.div`
  font-weight: 700;
  font-size: 0.9rem;
  color: ${COLORS.RED};
  text-align: center;
`;