import React from "react";

const SeeIcon = ({
  color = "#2B2B2B",
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon seeIcon"
      width={width}
      height={height}
      viewBox="0 0 15 9"
      fill="none"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
          <path
            d="M14.8934818,4.1915 C14.7594822,4.0205 11.56299,0 7.5,0 C3.43700998,0 0.240517834,4.0205 0.106518163,4.1915 C-0.0355060544,4.37264765 -0.0355060544,4.62735235 0.106518163,4.8085 C0.240517834,4.9795 3.43700998,9 7.5,9 C11.56299,9 14.7594822,4.9795 14.8934818,4.8085 C15.0355061,4.62735235 15.0355061,4.37264765 14.8934818,4.1915 Z M7.50000614,7 C6.11929152,7 5.00000614,5.88071187 5.00000614,4.5 C5.00000614,3.11928813 6.11929152,2 7.50000614,2 C8.88070848,2 9.99999386,3.11928813 9.99999386,4.5 C9.9983408,5.88002658 8.88002319,6.99834694 7.50000614,7 Z" 
            id="seeIcon"
            fill={color}
            fillRule="nonzero"
          >
          </path>
      </g>
    </svg>
  );
};

export default SeeIcon;
