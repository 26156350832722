import { compareAsc } from 'date-fns';
import { CONTENT_STATUS } from '../../../constants/contentConstants';

export const getContentStatus = (content) => {
  if (content.archived === true) return CONTENT_STATUS.ARCHIVED;
  else if (content.published) return CONTENT_STATUS.PUBLISHED;
  else if (content.planned === true) {
    const plannedDate = content.planned_for ? new Date(content.planned_for) : new Date(content.plannedFor);
    if (content.planned === true && compareAsc(plannedDate, new Date()) === 1) {
      return CONTENT_STATUS.PLANNED;
    }
  }
  return CONTENT_STATUS.NOT_PUBLISHED;
}