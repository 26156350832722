import styled from "styled-components";
import IconButton from '@material-ui/core/IconButton';

export const StyledPanel = styled.div`
  flex: 1;
  padding: 0 1rem;
  min-height: 300px;
  >:not(:last-child) {
    margin-bottom: 10px;
  };
`;

export const PanelHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PanelTitle = styled.div`
  font-weight: 600;
  font-size: 1.1rem;
  align-items: center;
  .MuiButtonBase-root {
    margin: 0px 10px;
  }
`;

export const IconContainer = styled.div`
  >:not(:last-child) {
    margin-right: 20px;
  };
`;

export const TableIcon = styled(IconButton)`
  &&{
    height: fit-content;
    .MuiSvgIcon-root {
      font-size: 1.2rem;
    }
  }
`;