import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const HeadWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 10px;
`;

export const Table = styled.div`

`;
