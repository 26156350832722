import React from 'react';

// Layout
import GenericLayout from '../../layouts/genericLayout';

import ContentManagement from '../../../ContentManagement';

const ContentManagementContainer = () => {
  return (
    <GenericLayout
      id="contentManagementLayout"
      className="contentManagementLayout"
    >
      <ContentManagement/>
    </GenericLayout>
  );
}

export default ContentManagementContainer;
