import styled from "styled-components";
import TextField from '@material-ui/core/TextField';

export const TextFieldCustom = styled(TextField)`
  &&{
    &.MuiFormControl-root{
      width: 100%;
      padding: 0;
      background-color: #ffffff;
      overflow: hidden;
      border-bottom: 1px solid #F4F4F4;
      // &:first-child{
      //   border-radius: 6px 6px 0 0;
      // }
      // &:last-child{
      //   border-bottom: 0;
      //   border-radius: 0 0 6px 6px;
      // }
      &:hover{
        .MuiFormLabel-root{
          color: #357ED3;
        }
      }
      .MuiFormLabel-root{
        width: 100%;
        padding: 15px 25px 10px 20px;
        box-sizing: border-box;
        font-weight: 500;
        font-size: 11px;
        transform: none;
        color: #838383;
        transition: all .2s ease-out;
        &.Mui-focused{
          color: #357ED3;
        }
      }
      .MuiInputBase-root{
        background-color: transparent;
        padding: 0;
        &:before{
          display: none;
        }
        &:after{
          display: none;
        }
        .MuiInputBase-input{
          width: 100%;
          color: #5A5A5A;
          font-weight: 600;
          font-size: 13px;
          line-height: 9px;
          text-align: left;
          margin: 0;
          padding: 45px 25px 25px 20px;
          box-sizing: border-box;
          height: 80px;
          background-color: #ffffff;
          transition: all .2s ease-out;
          &:hover,
          &:focus{
            background-color: #FAFAFA;
            &.MuiFilledInput-inputAdornedStart{
              & + span{
                svg path{
                  fill: #357ED3;
                }
              }
            }
            &.MuiFilledInput-inputAdornedEnd{
              & + span{
                svg path{
                  fill: #357ED3;
                  stoke: #357ED3;
                }
              }
            }
          }
        }
      }
    }
  }
`;
