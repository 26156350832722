export const DEFAULT_LANDING = 'dashboard';

export const NAV_BAR_KEYS = {
  DASHBOARD: 'DASHBOARD',
  CONCIERGE: 'CONCIERGE',
  SKILL: 'SKILL',
  ACCOUNT: 'ACCOUNT',
  CONSOLE: 'CONSOLE'
};

export const BLOCK_KEYS = {
  WEATHER: 'WEATHER',
  SERVICE: 'SERVICE',
  TRANSPORT: 'TRANSPORT',
  NEWS: 'NEWS',
  MY_CONCIERGE: 'MY_CONCIERGE',
  MY_ORDER: 'MY_ORDER',
  BORNE: 'BORNE',
  CONCIERGE_SERVICES: 'CONCIERGE_SERVICES',
  SKILL_CATALOG: 'SKILL_CATALOG',
  SKILL_RH: 'SKILL_RH',
  SKILL_SG: 'SKILL_SG',
  ACCOUNT_INFO: 'ACCOUNT_INFO',
  ACCOUNT_PROFILE: 'ACCOUNT_PROFILE',
  ACCOUNT_TRAVEL: 'ACCOUNT_TRAVEL',
  ACCOUNT_DISCONNECT: 'ACCOUNT_DISCONNECT',
}

export const NAV_BAR_ROUTES = {
  [NAV_BAR_KEYS.DASHBOARD]: {
    route: 'dashboard',
    label: 'Tableau de bord',
  },
  [NAV_BAR_KEYS.CONCIERGE]: {
    route: 'conciergerie',
    label: 'Ma conciergerie',
  },
  [NAV_BAR_KEYS.SKILL]: {
    route: 'competences',
    label: 'Compétences',
  },
  [NAV_BAR_KEYS.ACCOUNT]: {
    route: 'compte',
    label: 'Mon compte',
  },
  [NAV_BAR_KEYS.CONSOLE]: {
    route: 'console',
    label: `Ma console (condition d'affichage en fonction des permission de l'utilisateur courant)`,
  }
};

export const SIDE_BAR_BLOCKS = {
  [BLOCK_KEYS.WEATHER]: {
    label: 'Meteo et Infos collaborateur',
  },
  [BLOCK_KEYS.SERVICE]: {
    label: 'Mes services (Needs room booking flag to be active)',
  },
  [BLOCK_KEYS.TRANSPORT]: {
    label: 'Mes trajets',
  },
  [BLOCK_KEYS.NEWS]: {
    label: 'Mon actualité',
  },
  [BLOCK_KEYS.MY_CONCIERGE]: {
    label: 'Ma conciergerie (Only for service v2)',
  },
  [BLOCK_KEYS.MY_ORDER]: {
    label: 'Mes commandes',
  },
  [BLOCK_KEYS.BORNE]: {
    label: 'Ma borne (Only if environment have borne)',
  },
  [BLOCK_KEYS.CONCIERGE_SERVICES]: {
    label: 'Mes services de conciergerie',
  },
  [BLOCK_KEYS.SKILL_CATALOG]: {
    label: 'Mon catalogue de compétences',
  },
  [BLOCK_KEYS.SKILL_RH]: {
    label: 'Ressources Humaines (The KB must exist as well)',
  },
  [BLOCK_KEYS.SKILL_SG]: {
    label: 'Services Généraux (The KB must exist as well)',
  },
  [BLOCK_KEYS.ACCOUNT_INFO]: {
    label: 'Mes informations',
  },
  [BLOCK_KEYS.ACCOUNT_PROFILE]: {
    label: 'Mon profil',
  },
  [BLOCK_KEYS.ACCOUNT_TRAVEL]: {
    label: 'Mes trajets',
  },
  [BLOCK_KEYS.ACCOUNT_DISCONNECT]: {
    label: 'Déconnexion',
  },
};

export const NAV_BAR_BLOCKS_LIST = {
  [NAV_BAR_KEYS.DASHBOARD]: [
    BLOCK_KEYS.WEATHER,
    BLOCK_KEYS.SERVICE,
    BLOCK_KEYS.TRANSPORT,
    BLOCK_KEYS.NEWS,
    BLOCK_KEYS.ACCOUNT_DISCONNECT
  ],
  [NAV_BAR_KEYS.CONCIERGE]: [
    BLOCK_KEYS.WEATHER,
    BLOCK_KEYS.MY_CONCIERGE,
    BLOCK_KEYS.MY_ORDER,
    BLOCK_KEYS.BORNE,
    BLOCK_KEYS.CONCIERGE_SERVICES,
    BLOCK_KEYS.NEWS,
    BLOCK_KEYS.ACCOUNT_DISCONNECT
  ],
  [NAV_BAR_KEYS.SKILL]: [
    BLOCK_KEYS.SKILL_CATALOG,
    BLOCK_KEYS.SKILL_RH,
    BLOCK_KEYS.SKILL_SG,
  ],
  [NAV_BAR_KEYS.ACCOUNT]: [
    BLOCK_KEYS.WEATHER,
    BLOCK_KEYS.ACCOUNT_INFO,
    BLOCK_KEYS.ACCOUNT_PROFILE,
    BLOCK_KEYS.ACCOUNT_TRAVEL,
    BLOCK_KEYS.ACCOUNT_DISCONNECT
  ],
  [NAV_BAR_KEYS.CONSOLE]: [
    BLOCK_KEYS.WEATHER,
    BLOCK_KEYS.ACCOUNT_DISCONNECT
  ],
};