import React, { useState, useEffect } from 'react';
import { Formik, Field } from 'formik';

import GenericTextField from '../../../../../../components/molecules/GenericTextField';
import GenericMultipleSelect from '../../../../../../components/molecules/GenericMultipleSelect';
import GenericDrawerValidationButtons from '../../../../../../components/molecules/GenericDrawerValidationButtons';

import { toaster } from '../../../../../../helpers/toaster';

import {
  StyledAccordion,
  StyledForm,
  InputGroup
} from './style';

const TicketFilter = ({
  servicesData,
  currentSites,
  initFilterConditions,
  setFilterConditions,
}) => {
  const [servicesList, setServicesList] = useState([]);

  useEffect(() => {
    if (servicesData != null) {
      const newList = servicesData.map((service) => {
        return { label: service.name, value: service.id }
      })
      setServicesList(newList)
    }
  }, [servicesData]);

  const handleResetForm = (props) => {
    props.resetForm();
    setFilterConditions(initFilterConditions);
  }

  const handleSubmit = (values) => {
    if (currentSites && currentSites.length !== 0) {
      setFilterConditions(values);
    }
    else {
      toaster('Sélectionnez un site, puis réessayez', 'error');
    }
  }

  return (
    <StyledAccordion
      headerComponent={"Recherche"}
      detailComponent={
        <Formik
          initialValues={initFilterConditions}
          setFieldValue
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {props => (
            <StyledForm>
              <InputGroup>
                <GenericMultipleSelect
                  id="services"
                  name="services"
                  onChange={(e, value) => props.setFieldValue('services', value)}
                  value={props.values.services}
                  list={servicesList}
                  fildName="services"
                  title="Services"
                />
                <Field
                  id="collaborator"
                  name="collaborator"
                  title="Collaborateur"
                  placeholder="Nom du collaborateur"
                  variant="outlined"
                  size="small"
                  as={GenericTextField}
                />
                <Field
                  id="email"
                  name="email"
                  title="Email"
                  placeholder="Email du collaborateur"
                  variant="outlined"
                  size="small"
                  as={GenericTextField}
                />
              </InputGroup>
              <GenericDrawerValidationButtons
                leftButonText={'Réinitialiser'}
                RightButtonText={'Chercher'}
                onclickLefttButton={() => handleResetForm(props)}
                submitable={false}
              />
            </StyledForm>
          )}
        </Formik>
      }
    />
  );
};

export default TicketFilter;