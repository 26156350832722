import styled from "styled-components";
import { COLORS } from '../../../constants/design';

export const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #5A5A5A;
  && {
    .MuiFormGroup-root {
      margin-top: 5px;
    }
    .MuiFormControlLabel-root {
      span {
        font-size: 0.9rem;
      }
    }
    .MuiSvgIcon-root {
      font-size: 1.2rem;
    }
    .MuiCheckbox-colorSecondary.Mui-checked {
      color: ${COLORS.PRIMARY_BLUE};
    }
  }
`;
