import styled from "styled-components";
import InputField from '../../../components/molecules/inputField';


export const ListSearch = styled.div`
  position: absolute;
  top: 66px;
  z-index: 999;
  left: 0;
  width: 100%;
  background-color: #fafafa;
  border-radius: 6px;
  box-shadow: 0 2px 10px 0 rgb(0, 0, 0, 0.2);
  & > div{
    padding: 14px 20px;
    font-size: 13px;
    opacity: 1;
    color: #5A5A5A;
    cursor: pointer;
    &:hover{
      background-color: #E9E9E9;
    }
    span{
      opacity: 1;
      font-size: 13px;
      color: #5A5A5A;
    }
  }
`;

export const SearchIconWrapper = styled.span`
  position: absolute;
  right: 0;
  top: 43px;
  padding: 0px 21px 0px 0px;
  max-width: 14px;
  max-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  svg path{
    transition: all .2s ease-out;
  }
`;

export const InputFieldSearch = styled(InputField)`
  &&{
    &.MuiFormControl-root{
      .MuiInputBase-root{
        .MuiInputBase-input{
          padding: 45px 60px 25px 20px;
        }
      }
    }
  }
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
`;