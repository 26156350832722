import React, { useState, useEffect } from "react";
import {
  Route,
  Redirect,
  useRouteMatch,
  useHistory
} from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";

import { isUserTokenValid } from '../store/authentication/selectors';
import { getCompanyData } from '../store/company/selectors';

// Redirect to dashboard if trying to access page while connected 
// For instance --- Login redirected to dashboard

const notAllowedRedirectUrls = ['/logout', '/logout/'];

export const RedirectAuthRoute = ({
  component: Component,
  componentProps,
  ...rest
}) => {
  const isConciergeRoute = useRouteMatch("/espace-conciergerie");
  const history = useHistory();
  const {
    isTokenValid,
    companyData,
  } = useSelector(
    state => ({
      isTokenValid: isUserTokenValid(state),
      companyData: getCompanyData(state),
    }),
    shallowEqual
  );

  const handleTokenInvalid = (props) => {
    if (!isConciergeRoute && companyData?.getGround) {
      return (
        <Redirect
          to={{
            pathname: '/getground/restricted',
            state: { from: props.location }
          }}
        />
      )
    }
    else {
      return (
        <Component {...props} {...componentProps} />
      )
    }
  }

  // console.log('history', history.location.state);

  const redirectToTargetUrl = (props) => {
    let targetUrl = '/landing';
    // console.log('props', props.location.state);
    if (props.location.state) {
      const prevState = props.location.state;
      // console.log('prevState', prevState);
      // console.log('allowed', !notAllowedRedirectUrls.includes(prevState.from.pathname));
      if (!notAllowedRedirectUrls.includes(prevState.from.pathname)) {
        targetUrl = prevState.from.pathname;
      }
    }
    return (
      <Redirect
        to={{
          pathname: targetUrl,
          state: { from: props.location }
        }}
      />
    )
  }

  return (
    <Route
      {...rest}
      render={props =>
        !isTokenValid ?
          handleTokenInvalid(props)
          :
          redirectToTargetUrl(props)
      }
    />
  )
};
