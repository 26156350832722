import styled from "styled-components";
import DrawerCpt from '../../../../components/organisms/drawerCpt';
import { COLORS } from '../../../../../../constants/design';

import GenericButton from "../../../../../../components/molecules/GenericButton";

export const DrawerCptCustom = styled(DrawerCpt)`
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 830px;
  max-height: 1700px;
  position: relative;
  box-sizing: border-box;
`;

export const ContentContainer = styled.div`
  padding: 20px;
  >:not(:last-child) {
    margin-bottom: 20px;
  };
`;

export const SelectionContainer = styled.div`
  /* border: 1px solid black; */
  display: flex;
  >:not(:last-child) {
    margin-right: 10px;
  };
`;

export const StyledButton = styled(GenericButton)`
  && {
    &.MuiButton-root {
      width: 220px;
      background-color: ${props => props.disabled ? COLORS.LIGHT_GREY_2 : COLORS.PRIMARY_BLUE};
    }
  }
`;

export const SelectedContainer = styled.div`
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const SiteAndServicesTableContainer = styled.div`
  /* border: 1px solid black; */
  .genericTable {
    height: 70vh;
    .MuiButton-root {
      width: 150px;
    }
  }
  >:not(:last-child) {
    margin-bottom: 20px;
  };
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: row;
  .textTitle {
    font-weight: 600;
    font-size: 0.9rem;
  }
  .genericMaterialMenu {
    .textFieldTitle {
      margin-bottom: 10px;
    }
    width: 50%;
  }
  .genericTextField {
    width: 50%;
    input {
      height: 22px;
    }
  }
  >:not(:last-child) {
    margin-right: 15px;
  };
`;

export const StyledNameCell = styled.div`
  /* border: 1px solid black; */
  min-width: 320px;
`;

export const StyledActiveStatus = styled.span`
  color: ${props => props.active ? COLORS.GREEN : COLORS.RED_3};
`;