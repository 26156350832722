import styled from "styled-components";
import { Form } from 'formik';


import { COLORS } from '../../../constants/design';

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  padding: 10px 0px;
  >div:not(:last-child) {
    margin-bottom: 15px;
  };
  .textTitle {
    font-weight: 600;
    font-size: 0.85rem;
    color: #5A5A5A;
  }
  .textTooltip {
    color: ${COLORS.GREY_1};
  }
  .textFieldInput {
    input {
      font-size: 0.85rem;
      font-family: avenir;
      height: 20px;
    }
    .MuiInputBase-input {
      font-size: 0.85rem;
    }
  }
  .genericTextArea {
    .textAreaInput {
      font-size: 0.85rem;
      font-family: avenir;
    }
  }
  .genericMultipleSelect  {
    width: 100%;
  }
  .genericTextField {
    width: 100%;
  }
  .genericMaterialMenu {
    width: 100%;
    .menuInput {
      font-size: 0.85rem;
      font-family: avenir;
    }
  }
  .genericValidationButtons {
    justify-content: flex-end;
    margin-top: 20px;
    >button:not(:last-child) {
      margin-left: 20px;
    };
    button {
      width: 50%;
    };
    span {
      font-size: 0.85rem !important;
    }
  }
`;