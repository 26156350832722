import React from 'react';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PersonIcon from '@material-ui/icons/Person';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import HomeIcon from '@material-ui/icons/Home';
import LocationCityIcon from '@material-ui/icons/LocationCity';

import GenericTitleWithIcon from '../../../../../../../../../../components/molecules/GenericTitleWithIcon';


import {
  StyledAccordion,
  StyledAccordionSummary,
  CircleDiv,
  StyledAccordionTitle,
  StyledUserInfoContainer,
} from './style';

const TicketUserInfo = ({ userData }) => {
  return (
    <StyledAccordion>
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <CircleDiv><PersonIcon style={{ fontSize: '1rem' }} /></CircleDiv>
        <StyledAccordionTitle>{`${userData.firstname || ''} ${userData.lastname || ''}`}</StyledAccordionTitle>
      </StyledAccordionSummary>
      <AccordionDetails>
        <StyledUserInfoContainer>
          <GenericTitleWithIcon title={userData.email || '-'} Icon={<EmailIcon style={{ fontSize: '1rem' }} />}></GenericTitleWithIcon>
          {userData?.phone && (<GenericTitleWithIcon title={userData.phone} Icon={<PhoneIcon style={{ fontSize: '1rem' }} />}></GenericTitleWithIcon>)}
          <GenericTitleWithIcon title={userData.address || '-'} Icon={<HomeIcon style={{ fontSize: '1rem' }} />}></GenericTitleWithIcon>
          <GenericTitleWithIcon title={userData.office_address || '-'} Icon={<LocationCityIcon style={{ fontSize: '1rem' }} />}></GenericTitleWithIcon>
        </StyledUserInfoContainer>
      </AccordionDetails>
    </StyledAccordion>
  )
}

export default TicketUserInfo;