import React from "react";
// import endpoint from '../../Legacy/helpers/api';
// import { authHeader } from '../../Legacy/helpers/auth-header';
import { connect } from 'react-redux';
import endpoint from "../../../../../helpers/api";
import { authHeader } from "../../../../../helpers/auth-header";
import LaddaButton, { L, SLIDE_UP } from 'react-ladda';

class StepRecap extends React.Component {
    constructor(props) {
        super(props);
        this.closeModal = this.closeModal.bind(this);
        this.goToForm = this.goToForm.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            typeForm: props.typeForm,
            requestData: props.requestData,
            message: "",
            loadingForm: false
        }
    }

    closeModal() {
        this.props.closeModal();
        this.props.firstStep();
    }

    goToForm() {
        this.props.firstStep();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        this.setState({
            requestData: nextProps.requestData,
            typeForm: nextProps.typeForm,
            apiAction: nextProps.apiAction
        }, () => {
            let message;
            let beneficiary = this.state.requestData.beneficiary === "me" ? "toi" : `${this.state.requestData.beneficiaryFirstname} ${this.state.requestData.beneficiaryLastname}`

            switch (this.state.typeForm) {

                case 'CollaboratorsBundle/Form/Medoucine/MedoucineType':
                    message = (
                        <p>
                            J'ai pris note que tu souhaites réservé un massage au
                            <em> {this.state.requestData.address}</em>{/* ${address} */}
                                pour le
                            <em> 27/12/2018</em>{/* ${date} */} <br />
                                Cette prestation est pour {beneficiary}
                        </p>
                    )

                    break;

                default:
                    if (this.state.requestData.action === "askHelp" || this.state.requestData.action === "askSmalltalkReply" || this.state.apiAction === 'bot_negative_feedback') {
                        message = (
                            <React.Fragment>
                                <div>
                                    <h3 className="chatbot-a-title-recap">Vous confirmez ces informations ?</h3>
                                    {
                                        Object.keys(this.state.requestData).map((item, key) => {
                                            // Check if field has to be displayed

                                            let isValid = (item !== "serviceId" &&
                                                item !== "partnerId" &&
                                                item !== "service" &&
                                                item !== "headerText" &&
                                                item !== "action" &&
                                                item !== "botReply" && item !== "userMessage" && item !== "moderation" && (!item.includes("section")));


                                            if (isValid) {
                                                let data = this.props.formSchema.properties[item];
                                                let index = 0;
                                                let label = null;
                                                let title = null;
                                                if (data !== undefined && data.enum) {
                                                    index = data.enum.findIndex((label) => {
                                                        return label === this.state.requestData[item];
                                                    });
                                                    label = data.enumNames[index];
                                                    title = data.title
                                                } else {
                                                    if (data !== null && data !== undefined) {
                                                        label = this.state.requestData[item]
                                                        title = data.title
                                                    }
                                                }
                                                return (
                                                    <div className="chatbot-m-recap-block">
                                                        <strong>{title === "companyResponseElement" ?
                                                            "L'identifiant de la réponse est :"
                                                            : title} </strong>
                                                        <br /> {(label !== undefined && label !== "") ? label : "Non précisé"}
                                                    </div>
                                                )
                                            }

                                        })
                                    }
                                </div>
                            </React.Fragment>
                        );
                    }
                    else {
                        message = (
                            <React.Fragment>
                                <div>
                                    <h3 className="chatbot-a-title-recap">Votre demande :</h3>
                                    {
                                        Object.keys(this.state.requestData).map((item, key) => {
                                            // Check if field has to be displayed

                                            let isValid = (item !== "serviceId" &&
                                                item !== "partnerId" &&
                                                item !== "service" &&
                                                item !== "headerText" &&
                                                (!item.includes("section"))) &&
                                                item !== "collaboratorId";


                                            if (isValid) {
                                                let data = this.props.formSchema.properties[item];
                                                let index = 0;
                                                let label = null;
                                                let title = null;
                                                if (data !== undefined && data.enum) {
                                                    index = data.enum.findIndex((label) => {
                                                        return label === this.state.requestData[item];
                                                    });
                                                    label = data.enumNames[index];
                                                    title = data.title
                                                } else {
                                                    if (data !== null && data !== undefined) {
                                                        label = this.state.requestData[item]
                                                        title = data.title
                                                    }
                                                }
                                                return (
                                                    <div className="chatbot-m-recap-block">
                                                        <strong>{title === "companyResponseElement" ?
                                                            "L'identifiant de la réponse est :"
                                                            : title} </strong>
                                                        <br /> {(label !== undefined && label !== "") ? label : "Non précisé"}
                                                    </div>
                                                )
                                            }

                                        })
                                    }
                                </div>
                            </React.Fragment>
                        );
                    }
                    break;
            }
            this.setState({ message });
        });
    }

    handleSubmit() {
        this.setState({
            loadingForm: true
        })
        let formData = new FormData();


        let url = endpoint.addRequestService;


        const { requestData } = this.state;
        
        // formData.append("data", JSON.stringify(requestData));

        if (this.state.apiAction === "bot_negative_feedback") {
            url = endpoint.sendNegativeBotFeedback;
            let info = {
                action: requestData.action,
                anonymous: requestData.anonymous,
                botReply: requestData.botReply,
                comment: requestData.comment,
                companyResponseElement: requestData.companyResponseElement,
                // informationElementId: requestData.informationElementId,
                responseStatus: requestData.responseStatus,
                userMessage: requestData.userMessage,
            }

            formData.append("data", JSON.stringify(info));

        }
        else if (this.state.apiAction === "bot_positive_feedback") {
            url = endpoint.sendPositiveBotFeedback;
            url = endpoint.sendNegativeBotFeedback;
            let info = {
                action: requestData.action,
                anonymous: requestData.anonymous,
                botReply: requestData.botReply,
                comment: requestData.comment,
                companyResponseElement: requestData.companyResponseElement,
                // informationElementId: requestData.informationElementId,
                responseStatus: requestData.responseStatus,
                userMessage: requestData.userMessage,
            }

            formData.append("data", JSON.stringify(info));
        }
        else {
          if (this.props.companyInfo.serviceVersion === 2) {
            requestData.ticket = true;
          }
          if (this.props.author) {
            requestData.author = this.props.author.id;
          }
          // console.log('requestData', requestData);
          formData.append("data", JSON.stringify(requestData));
        }

        let reqOptions = {
            method: "POST",
            headers: authHeader(),
            body: formData
        }


        fetch(url, reqOptions)
            .then(response => {
                this.props.setStatusRequest(response.status);
                if (!response.ok) {
                    this.props.goToStep(3);
                    this.setState({
                        loadingForm: false
                    })
                    return Promise.reject(response.statusText);
                }

                return response.json();
            })
            .then(data => {
                let action = this.state.apiAction;
                // this.props.resetFormData();
                this.props.goToStep(3); // Step confirmation
                this.props.setRequestSent();
                this.setState({
                    loadingForm: false,
                    apiAction: action
                })
            })


    }

    render() {



        return (
            <div className="">
                <div className="chatbot-m-card">
                    {this.state.message}
                </div>
                <div className="modal-form-controls">
                    <button
                        type="submit"
                        onClick={this.goToForm}
                        className="chatbot-a-button chatbot-a-button-darkblue">
                        Je corrige
                    </button>
                    <LaddaButton
                        type="submit"
                        className="chatbot-a-button chatbot-a-button-green"
                        // className="chatbot-a-button chatbot-a-button-red"
                        loading={this.state.loadingForm}
                        data-style={SLIDE_UP}
                        data-spinner-size={30}
                        data-spinner-color="#fff"
                        data-spinner-lines={12}
                        onClick={this.handleSubmit}
                    >
                        Je confirme
                    </LaddaButton>
                    {/* <button
                        type="submit"
                        onClick={this.handleSubmit}
                        className="chatbot-a-button chatbot-a-button-green">
                        Je valide
                    </button> */}
                </div>


            </div>
        )
    }
}

const mapStateToProps = (state) =>{
  const { data } = state.company;
  return {
    companyInfo: data
  };
}

export default connect(mapStateToProps)(StepRecap);