import styled from "styled-components";

import { COLORS } from "../../../../../../../constants/design";
import GenericAccordion from "../../../../../../../components/molecules/GenericAccordion";
import GenericButton from "../../../../../../../components/molecules/GenericButton";

export const MainContainer = styled.div`
  .textTitle {
    font-weight: 700;
    font-size: 0.9rem;
    color: ${COLORS.DARK_GREY};
  }
`;

export const StyledAccordion = styled(GenericAccordion)`
  && {
    .MuiAccordion-root {
      border: 1px solid ${COLORS.LIGHT_GREY_2};
    }
  }
`;

export const StyledTitle = styled.div`
  font-weight: 600;
  font-size: 1rem;
  align-items: center;
`;

export const DetailContainer = styled.div`
  font-size: 0.9rem;
  >div:not(:last-child) {
    margin-bottom: 15px;
  };
`;

export const TemplateListContainer = styled.div`
  width: 100%;
  max-width: 700px;
  >div:not(:last-child) {
    margin-bottom: 10px;
  };
`;

export const BarContainer = styled.div`
  color: ${props => props.active ? COLORS.DARK_GREY : COLORS.LIGHT_GREY_4};
  >div:not(:last-child) {
    margin-bottom: 10px;
  };
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  .genericCheckBox {
    width: 80%;
  }
`;

export const SelectButtonContainer = styled.div`
  display: flex;
  >:not(:last-child) {
    margin-right: 10px;
  };
`;

export const SelectAllButton = styled(GenericButton)`
  && {
    &.MuiButton-root {
      padding: 15px 0px;
      min-width: 100px;
      height: 0px;
      font-size: 0.8rem;
    }
    &.removeAll {
      background-color: ${COLORS.LIGHT_GREY_3};
      color: ${COLORS.DARK_GREY};
      :hover {
        background-color: ${COLORS.LIGHT_GREY_4};
      }
    }
  }
`;

export const ModuleContainer = styled.div`
  padding: 0px 30px;
  .genericCheckBox {
    .textTitle {
      font-weight: 500;
      color: ${props => props.active ? COLORS.DARK_GREY : COLORS.LIGHT_GREY_4};
    }
  }
  >div:not(:last-child) {
    margin-bottom: 5px;
  };
`;