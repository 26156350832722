import React, { useEffect, useState } from 'react';
import {
  shallowEqual,
  useDispatch,
  useSelector,
} from "react-redux";
import {
  useRouteMatch,
} from "react-router-dom";
import moment from 'moment';
import _ from 'lodash';

import ArrowRightIcon from '../../atoms/icons/arrowRight';
import useTheme from "../../../../../theme-style/useThemeCustomHook";

import Loader from '../../../../../components/molecules/GenericLoader';

import {
  getServices,
} from '../../../../../store/concierge/actions';

import {
  getServicesOrdersList,
} from '../../../../../store/sidebar/actions';

import {
  getCompanyData
} from '../../../../../store/company/selectors';

import {
  getConciergeServicesData,
  getConciergeServicesLoading,
} from '../../../../../store/concierge/selectors';

import {
  getServicesOrdersListData,
  getServicesOrdersListIsLoading,
} from '../../../../../store/sidebar/selectors';

import {
  MainContainer,
  StyledTitleWithToolTip,
  ServicesWrapper,
  ArrowIconWrapper,
  ServicesPrestationsTitle,
  ServicesPrestations,
  ServicesPrestationsTop,
  ServicesPrestationsCircle,
  ServicesCircle,
  ServicesPrestationsMiddle,
  ServicesPrestationsMiddleItem,
  ServicesPrestationsMiddleItemText,
  ServicesPrestationsMiddleItemDate,
  ServicesOnlyCircle,
  ServicesWithoutPrestations,
  ServicesWithoutPrestationsTop,
  ServicesItemContent,
  ServicesItemTitle,
  ServicesItemText,
} from './style';

const RequestModule = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const [serviceTickets, setServiceTickets] = useState(null);

  const {
    serviceData,
    serviceIsLoading,
    servicesOrdersList,
    servicesOrdersListIsLoading,
    companyData,
  } = useSelector(
    state => ({
      serviceData: getConciergeServicesData(state),
      serviceIsLoading: getConciergeServicesLoading(state),
      servicesOrdersList: getServicesOrdersListData(state),
      servicesOrdersListIsLoading: getServicesOrdersListIsLoading(state),
      companyData: getCompanyData(state),
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!serviceData) {
      dispatch(getServices());
    }
    dispatch(getServicesOrdersList(1));
  }, []);

  useEffect(() => {
    if (serviceData && companyData && servicesOrdersList) {
      if (companyData.serviceVersion === 1) setServiceTickets(servicesOrdersList);
      else {
        const formatedTickets = _.cloneDeep(servicesOrdersList);
        _.map(formatedTickets.items, (ticket) => {
          const service = _.find(serviceData, (service) => service.id == ticket.service_id);
          ticket.service = service?.name;
        });
        setServiceTickets(formatedTickets);
      }
    }
  }, [companyData, serviceData, servicesOrdersList]);

  return (
    <MainContainer>
      <StyledTitleWithToolTip
        title={"Mes commandes"}
        toolTipText={""}
      />
      {servicesOrdersListIsLoading || serviceIsLoading ?
        <Loader />
        :
        <ServicesWrapper>
          {!!serviceTickets?.total_count ?
            <ServicesPrestations to={`${path}/services/prestations`}>
              <ServicesPrestationsTop>
                <ServicesPrestationsCircle>
                  <ServicesCircle className="borneItemCircle-blue" />
                  <ServicesCircle className="borneItemCircle-green" />
                  <ServicesCircle className="borneItemCircle-orange" />
                </ServicesPrestationsCircle>
                <ServicesPrestationsTitle>
                  Mes commandes en cours
                </ServicesPrestationsTitle>
                <ArrowIconWrapper>
                  <ArrowRightIcon color={theme.colors.grey4} />
                </ArrowIconWrapper>
              </ServicesPrestationsTop>

              <ServicesPrestationsMiddle>

                {serviceTickets && serviceTickets.items.slice(0, 2).map((order, idx) => (
                  <ServicesPrestationsMiddleItem key={idx}>
                    <ServicesOnlyCircle className="borneItemOnlyCircle-green" />
                    <ServicesPrestationsMiddleItemText>
                      {order.service}
                    </ServicesPrestationsMiddleItemText>
                    <ServicesPrestationsMiddleItemDate>
                      Le {moment(order.created_at).format("DD/MM/YYYY")}
                    </ServicesPrestationsMiddleItemDate>
                  </ServicesPrestationsMiddleItem>
                ))}
              </ServicesPrestationsMiddle>
            </ServicesPrestations>
            :
            <ServicesWithoutPrestations>
              <ServicesWithoutPrestationsTop>
                <ServicesPrestationsCircle>
                  <ServicesCircle className="borneItemCircle-blue" />
                  <ServicesCircle className="borneItemCircle-green" />
                  <ServicesCircle className="borneItemCircle-orange" />
                </ServicesPrestationsCircle>
                <ServicesItemContent>
                  <ServicesItemTitle>
                    Aucune prestation pour le moment
                  </ServicesItemTitle>
                  <ServicesItemText>
                    Vous n’avez jamais utilisé les services de conciergerie des partenaires Cyconia ? Essayer dès maintenant en faisant votre première demande
                    <span role="img" aria-label="emoji-smile">🙂</span>
                  </ServicesItemText>
                </ServicesItemContent>
              </ServicesWithoutPrestationsTop>
            </ServicesWithoutPrestations>
          }
        </ServicesWrapper>
      }
    </MainContainer>
  );
};

export default RequestModule;