import React from 'react'

import {
  CustomDialog,
  DialogContainer,
  Wrapper,
  Title,
  Text,
  LogOutButton,
  LogOutButtonText
} from './style'

function LogoutModal({ open, setOpenDialog }) {
  return (
    <CustomDialog
      open={open}
      onClose={() => setOpenDialog(!open)}
    >
      <DialogContainer>
        <Wrapper>
          <Title>déconnexion</Title>
          <Text>
            Êtes-vous sûr de vouloir vous déconnecter de Cyconia ?
          </Text>
          <LogOutButton to="/logout" >
            <LogOutButtonText>
              déconnecter mon compte
            </LogOutButtonText>
          </LogOutButton>
        </Wrapper>

      </DialogContainer>
    </CustomDialog >
  )
}

export default LogoutModal
