import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import Select from "react-select";
import LaddaButton, { SLIDE_UP } from 'react-ladda';
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import * as moment from 'moment/min/moment-with-locales';
import _ from 'lodash';


import endpoint from '../../../../helpers/api';
import { authHeader } from '../../../../helpers/auth-header';

import CGUModal from '../../../../components/organisms/CGUModal';
import Loader from '../../../../components/molecules/GenericLoader';

// Store
import {
  saveOnBoardingCgu,
  resetOnBoarding,
  getCorporation,
} from '../../../../store/onboarding/actions';

import { getSubDomain } from '../../../../store/config/selectors';

import {
  isOnBoardingStepCGUOnSuccess,
  isOnBoardingStepCGUOnLoad,
  getCorporationData,
  isCorporationOnLoad,
} from '../../../../store/onboarding/selectors';

import {
  getCompanyData
} from '../../../../store/company/selectors';

import {
  getUser,
} from '../../../../store/collaborator/selectors';

// Style
import {
  MainContainer,
  Container,
  BubbleText,
  Text,
  ErrorText,
  SelectCompany,
  LinkInfoCGU,
  AcceptCGU,
  StyledEmailLink
} from './style';

const StepCGU = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [companySites, setCompanySites] = useState([]);
  const [corporationList, setCorporationList] = useState([]);
  const [company, setCompany] = useState(null);
  const [selectedCorporation, setSelectedCorporation] = useState(null);
  const [isListLoading, setIsListLoading] = useState(true);
  const [isCGUModalOpen, setIsCGUModalOpen] = useState(false);
  const [acceptAgreement, setAcceptAgreement] = useState(false);
  const [errorAgreement, setErrorAgreement] = useState(false);
  const [errorSelectEntreprise, setErrorSelectEntreprise] = useState(false);
  const [errorSelectCorporation, setErrorSelectCorporation] = useState(false);

  const {
    userData,
    saveOnSuccess,
    saveOnLoad,
    subDomain,
    corporationData,
    corporationIsLoading,
    companyData,
  } = useSelector(
    state => ({
      userData: getUser(state),
      saveOnSuccess: isOnBoardingStepCGUOnSuccess(state),
      saveOnLoad: isOnBoardingStepCGUOnLoad(state),
      subDomain: getSubDomain(state),
      corporationData: getCorporationData(state),
      corporationIsLoading: isCorporationOnLoad(state),
      companyData: getCompanyData(state),
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(resetOnBoarding());
    dispatch(getCorporation());
    const url = endpoint.getCompanySites;

    const reqOptions = {
      header: authHeader(),
      method: "GET"
    };

    fetch(url, reqOptions)
      .then((response) => {
        if (!response.ok) {
          return Promise.reject(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        let company = [];
        data.forEach(item => {
          let newObj = {}
          newObj["value"] = item.id;
          newObj["label"] = item.name;
          company.push(newObj);
        });

        setCompanySites(company);
        setIsListLoading(false);
      })
  }, []);

  useEffect(() => {
    if (corporationData && company) {
      const newList = _.compact(_.map(corporationData, (data) => {
        if (data.status === 'activated' && data.site?.id === company) {
          const userDomain = userData.email.split('@').pop();
          if (data.email_domains.includes(userDomain)) {
            return ({
              value: data.id,
              label: data.name
            });
          }
        }
      }));
      setCorporationList(newList);
    }
  }, [company, corporationData]);

  useEffect(() => {
    if (saveOnSuccess) {
      history.push("/onboarding/1");
    }
  }, [saveOnSuccess]);

  useEffect(() => {
    if (userData && userData.company_site) {
      setCompany(userData.company_site.id);
    }
  }, [userData]);

  const handleSelectChangeCompany = (e) => {
    setErrorSelectEntreprise(false);
    setSelectedCorporation(null);
    setCompany(e.value)
  }

  const handleCorporationChange = (e) => {
    setErrorSelectCorporation(false);
    setSelectedCorporation(e);
  }

  const openCGUModal = () => {
    setIsCGUModalOpen(true)
  }
  const closeCGUModal = () => {
    setIsCGUModalOpen(false)
  }

  const onChangeAgreement = () => {
    if (!acceptAgreement) {
      setErrorAgreement(false);
    }
    setAcceptAgreement(!acceptAgreement);
  };

  const sendEmail = () => {
    const { firstname, lastname } = userData;
    const subject = encodeURIComponent(`${subDomain} - ${firstname} ${lastname} rencontre un problème d’inscription`);
    const targetEmail = `onvousaimeonvousaide@cyconia.io`;

    window.open(`mailto:${targetEmail}?subject=${subject}`, '_blank');
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const date = moment();
    let isError = false;

    if (!acceptAgreement || company === null) {
      if (!company) {
        setErrorSelectEntreprise(true);
        isError = true;
      }
      if (acceptAgreement === false) {
        setErrorAgreement(true);
        isError = true;
      }
    }

    if (corporationData && corporationData.length !== 0 && selectedCorporation === null) {
      setErrorSelectCorporation(true);
      isError = true;
    }

    if (isError) return;

    dispatch(saveOnBoardingCgu(date.format("YYYY-MM-DD HH:mm:ss"), company, selectedCorporation?.value || null));
  };

  return (
    <MainContainer>
      {(isListLoading || corporationIsLoading) ?
        <Loader />
        :
        <>
          <Container>
            <form onSubmit={(e) => handleSubmit(e)} >
              <BubbleText>
                <Text>
                  Faisons connaissance !
                  J'ai 5 questions pour mieux vous servir et pour seulement 20 secondes de votre temps
                </Text>
              </BubbleText>
              <BubbleText>
                <Text>
                  Où se situe votre bureau ?
                </Text>
              </BubbleText>

              <SelectCompany>
                <Select
                  options={companySites}
                  isLoading={isListLoading}
                  placeholder="Sélectionner un site"
                  isSearchable={false}
                  onChange={handleSelectChangeCompany}
                  name="Site"
                  defaultValue={_.find(companySites, (site) => site.value === company)}
                /*defaultValue={defaultValue}*/
                />
                {errorSelectEntreprise &&
                  <ErrorText>
                    Veuillez sélectionner le site
                  </ErrorText>
                }
              </SelectCompany>

              {company && corporationData && corporationData.length !== 0 &&
                <SelectCompany>
                  <Select
                    options={corporationList}
                    isLoading={corporationIsLoading}
                    placeholder="Sélectionner une entreprise"
                    isSearchable={false}
                    value={selectedCorporation}
                    onChange={handleCorporationChange}
                    name="Corporation"
                    noOptionsMessage={() => `Pas d'entreprise disponible. Contactez-nous en cliquant sur "Je rencontre un problème".`}
                  />
                  {errorSelectCorporation &&
                    <ErrorText>
                      Veuillez sélectionner l'entreprise
                    </ErrorText>
                  }
                </SelectCompany>
              }


              <LinkInfoCGU onClick={openCGUModal}>
                Lire les conditions générales d'utilisation
              </LinkInfoCGU>

              <AcceptCGU>
                <label>
                  <span>J'ai lu et j'accepte les conditions générales d'utilisation</span>
                  <input
                    type="checkbox"
                    className="m-input__checkbox"
                    name="agreement"
                    value={acceptAgreement}
                    onChange={onChangeAgreement}
                  />
                </label>
                {errorAgreement &&
                  <ErrorText>
                    Veuillez accepter les conditions générales d'utilisation
                  </ErrorText>
                }
              </AcceptCGU>

              <StyledEmailLink onClick={sendEmail}>
                {`Je rencontre un problème`}
              </StyledEmailLink>

              <LaddaButton
                type="submit"
                className="ladda-button"
                loading={saveOnLoad}
                // data-style={SLIDE_UP}
                data-spinner-size={30}
                data-spinner-color="#fff"
                data-spinner-lines={12}
              >
                Continuons
              </LaddaButton>
            </form>
          </Container>

          {isCGUModalOpen &&
            <CGUModal
              onClose={closeCGUModal}
              subDomain={subDomain}
            />
          }
        </>
      }
    </MainContainer>
  );
}

export default StepCGU;