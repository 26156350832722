import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import _ from 'lodash';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import GradeIcon from '@material-ui/icons/Grade';
import ScheduleIcon from '@material-ui/icons/Schedule';
import PersonIcon from '@material-ui/icons/Person';
import ReorderIcon from '@material-ui/icons/Reorder';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { format, isSameDay } from 'date-fns';
import fr from 'date-fns/locale/fr';

import GenericTitleWithIcon from '../../../../../components/molecules/GenericTitleWithIcon';
import GenericWarningModal from '../../../../../components/molecules/GenericWarningModal';

import {
  StyledPopper,
  PopUpContainer,
  TitleContainer,
  Title,
  StyledIconButton,
  TagContainer,
  StyledTag,
  ContentContainer,
  StyledTitleWithIcon,
  ButtonContainer,
  StyledButton,
} from './style'

const CalendarObjectModal = ({
  onClose,
  onEdit,
  onDelete,
  data,
  anchorEl
}) => {
  const [openCount, setOpenCount] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setOpenCount(1);
    }, [100]);
  }, []);

  const displayRange = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const startTime = format(startDate, "H:mm", { locale: fr });
    const endTime = format(endDate, "H:mm", { locale: fr });
    const startDateString = format(startDate, "EEEE, dd MMM", { locale: fr });
    const endDateString = format(endDate, "EEEE, dd MMM", { locale: fr });

    const rangeDisplay = isSameDay(startDate, endDate) ?
      `${startDateString} ${startTime} - ${endTime}` :
      `${startDateString} ${startTime} - ${endDateString} ${endTime}`

    return rangeDisplay
  }



  // console.log('anchorEl', anchorEl);

  // console.log('data', data);

  // console.log('openCount', openCount);

  const handleClickAway = (props) => {
    const acceptedTarget = [
      'MuiSvgIcon-root',
      'rbc-event-content',
      'rbc-event-label',
      'acceptableCalenderObject'
    ]
    // console.log('test', props)
    const intersect = _.intersectionWith(acceptedTarget, props.target.classList, _.isEqual);
    // console.log('props.target.classList', props.target.classList);
    if (intersect.length === 0) onClose();
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <StyledPopper
        open={true}
        // open={true}
        // key={anchorEl}
        anchorEl={anchorEl}
        opencount={openCount}
        placement={'right'}
      // keepMounted
      // strategy="fixed"
      // disablePortal
      >
        {data &&
          <PopUpContainer>
            <TitleContainer>
              <Title>{data.title}</Title>
              <StyledIconButton onClick={onClose}>
                <CloseIcon />
              </StyledIconButton>
            </TitleContainer>
            <TagContainer>
              {!!data.resource.type &&
                <StyledTag type={data.resource.type}>
                  <GenericTitleWithIcon
                    title={data.resource.type === 'event' ? "Événement" : "Tâche"}
                    Icon={data.resource.type === 'event' ?
                      <GradeIcon style={{ fontSize: '0.8rem' }} /> :
                      <FormatListBulletedIcon style={{ fontSize: '0.8rem' }} />}
                  />
                </StyledTag>
              }
              <StyledTag>
                Associé à CYC-1234
              </StyledTag>
            </TagContainer>
            <ContentContainer onClick={() => onEdit(data)}>
              <StyledTitleWithIcon
                title={displayRange(data.start, data.end)}
                Icon={<ScheduleIcon style={{ fontSize: '0.8rem' }} />}
              />
              <StyledTitleWithIcon
                title={data.resource.creator}
                Icon={<PersonIcon style={{ fontSize: '0.8rem' }} />}
              />
              {data.resource.note && (
                <StyledTitleWithIcon
                  title={data.resource.note}
                  Icon={<ReorderIcon style={{ fontSize: '0.8rem' }} />}
                  longText
                />
              )}
            </ContentContainer>
            <ButtonContainer>
              <StyledButton
                startIcon={<EditIcon style={{ fontSize: '0.8rem' }} />}
                onClick={() => onEdit(data)}
              >
                Éditer l'évênement
              </StyledButton>
              <StyledButton
                startIcon={<DeleteIcon style={{ fontSize: '0.8rem' }} />}
                onClick={() => setOpenDelete(true)}
              >
                Supprimer l'évênement
              </StyledButton>
            </ButtonContainer>
            <GenericWarningModal
              open={openDelete}
              setOpenDialog={() => setOpenDelete(!openDelete)}
              title={"Attention"}
              text={"Êtes-vous sur de vouloir annuler cet élément?"}
              leftButtonText={"Non"}
              rightButtonText={"Oui"}
              leftButtonFunction={() => setOpenDelete(!openDelete)}
              rightButtonFunction={() => onDelete(data.id)}
            />
          </PopUpContainer>
        }
      </StyledPopper>
    </ClickAwayListener >
  )
}

CalendarObjectModal.propTypes = {
  onClose: PropTypes.func,
  onEdit: PropTypes.func,
  data: PropTypes.object
}

export default CalendarObjectModal