import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const IconContainer = styled.div`
  height: 14px;
  width: 14px;
  display: flex;
  align-items: center;
  margin-right: 5px;
`;

export const Title = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px;
`;