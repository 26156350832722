import styled from 'styled-components';

export const MainContainer = styled.div`
  width: 100%;
  .react-horizontal-scrolling-menu--wrapper {
    align-items: center;
    position: relative;
    scrollbar-width: none;
    
  }
  .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
  }
  .react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    >div:not(:last-child) {
      margin-right: 10px;
    };
    padding: 0px 2px;
  }
`;
