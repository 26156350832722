import React, { useEffect, useState } from 'react';
import * as Yup from "yup";
import _ from 'lodash';
import { useParams } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { useDispatch } from 'react-redux';
import { Formik, Form, useFormikContext } from 'formik';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import DialogContent from "@material-ui/core/DialogContent";

import { jsonSafeParse } from '../../../../../../helpers/jsonSafeParse';

import { FORM_SOURCE } from '../../../../../../constants/serviceConstants';

//Import components
import GenericAdminFrontHeader from '../../../../../../components/molecules/GenericAdminFrontHeader';
import GenericAnimatedTextBubble from '../../../../../../components/molecules/GenericAnimatedTextBubble';
import GenericDrawerValidationButtons from '../../../../../../components/molecules/GenericDrawerValidationButtons';
import GenericRadioButton from '../../../../../../components/molecules/GenericRadioButton';
import GenericMaterialMenu from "../../../../../../components/molecules/GenericMaterialMenu";
import GenericTitleWithToolTip from '../../../../../../components/molecules/GenericTitleWithToolTip';
import GenericSwitch from '../../../../../../components/molecules/GenericSwitch';
import GenericLoader from '../../../../../../components/molecules/GenericLoader';
import UrlButtonForm from '../../../../../../components/organisms/UrlButtonForm';
import GenericWarningModal from '../../../../../../components/molecules/GenericWarningModal';

import ContentBar from './ContentBar';
import CustomContentEditor from './CustomContentEditor';
import ConciergeCustomContent from './ConciergeCustomContent';

import {
  getConciergeSitesData,
  getConciergeServicesData,
  getConciergeServicesActifsData,
  getInternalServiceFormData,
  getConciergeServicesActifsLoading
} from '../../../../../../store/concierge/selectors';

import {
  getSpecificCaseData
} from '../../../../../../store/adminFront/selectors';

import { editServiceActif } from '../../../../../../store/concierge/actions';


//Import style
import {
  Container,
  DrawerCptCustom,
  Wrapper,
  DrawerWrapper,
  InfoContainer,
  InfoTextContainer,
  InfoTextTitle,
  ContentBarContainer,
  ContentContainer,
  FormContainer,
  ContentWrapper,
  StyledAccordion,
  ContentEditContainer,
  ContentEditBlocker,
  StyledTooltip,
  SitesSelectorBox,
  StyledGenericMaterialMenu,
  SwitchContainer,
  StyledDialog,
} from "./style"

const contentSourceChoices = [
  {
    label: "Contenu template (issu de cyconia)",
    value: "template",
  },
  {
    label: "Contenu personnalisé",
    value: "custom",
  },
];

const formSourceChoices = [
  {
    label: "Formulaire interne cyconia",
    value: FORM_SOURCE.INTERNAL,
  },
  {
    label: "Formulaire externe",
    value: FORM_SOURCE.EXTERNAL,
  },
  {
    label: "Pas de formulaire",
    value: FORM_SOURCE.NONE,
  },
];

const tabList = {
  clientContent: 'PEC Chatbot',
  conciergeContent: 'PEC Concierge',
}

const ConciergeEditDrawer = ({ visible, closeFunction }) => {
  const dispatch = useDispatch();
  const { id, siteId, serviceId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [siteInfo, setSiteInfo] = useState(null);
  const [serviceInfo, setServiceInfo] = useState(null);
  const [selectedServiceActif, setSelectedServiceActif] = useState(null);
  const [isTemplateSource, setIsTemplateSource] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [openDelete, setopenDelete] = useState(false);
  const [listRef, setListRef] = useState(null);
  const [internalServiceFormList, setInternalServiceFormList] = useState(null)
  const [updatedServicesList, setUpdatedServiceList] = useState(null);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [openExternalFormModal, setOpenExternalFormModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(tabList.clientContent);

  const {
    sitesData,
    servicesData,
    servicesActifData,
    internalServiceFormData,
    serviceActifIsLoading,
    specificCaseData
  } = useSelector(
    state => ({
      sitesData: getConciergeSitesData(state),
      servicesData: getConciergeServicesData(state),
      servicesActifData: getConciergeServicesActifsData(state),
      internalServiceFormData: getInternalServiceFormData(state),
      serviceActifIsLoading: getConciergeServicesActifsLoading(state),
      specificCaseData: getSpecificCaseData(state),
    }),
    shallowEqual
  );

  useEffect(() => {
    if (selectedServiceId && servicesActifData) {
      const selected = servicesActifData.find((el) => String(el.id) === String(selectedServiceId));
      if (selected) setSelectedServiceActif(selected)
      if (selected && selected.content_source === 'template')
        setIsTemplateSource(true);
      else
        setIsTemplateSource(false);
    }
  }, [selectedServiceId, servicesActifData])

  useEffect(() => {
    if (servicesActifData) {
      const newList = _.compact(_.map(servicesActifData, (item, key) => {
        if (String(item.service) === String(serviceId) && item.active === true && String(item.company_site.id) !== String(siteId)) return { label: item.company_site.name, value: item.id }
      }))
      newList.length > 0 ?
        setUpdatedServiceList([{ label: "Appliquer une fiche existante de service ?", value: null }, ...newList])
        :
        setUpdatedServiceList([{ label: "Pas de fiche de service existante pour le moment !", value: null }])
    }
  }, [servicesActifData])

  useEffect(() => {
    if (internalServiceFormData) {
      const bufferList = _.cloneDeep(internalServiceFormData);
      let newList = _.orderBy(
        _.map(bufferList, (form) => ({ value: form.id, label: form.title })),
        ['value'],
        ['asc']
      );
      const defaultForm = _.find(newList, (item) => item.label === 'Formulaire par défaut');
      if (defaultForm) {
        newList = [
          defaultForm,
          ...newList.filter((item) => item.label !== 'Formulaire par défaut')
        ];
      }
      setInternalServiceFormList(_.compact(newList));
    }
  }, [internalServiceFormData])

  useEffect(() => {
    if (listRef !== null) {
      const diff = _.xor(listRef, servicesActifData);
      if (diff.length !== 0) closeFunction();
    }
    setListRef(servicesActifData);
  }, [servicesActifData])

  useEffect(() => {
    if (isLoading && siteInfo && serviceInfo &&
      internalServiceFormList && updatedServicesList) {
      setIsLoading(false);
    }
  }, [siteInfo, serviceInfo, internalServiceFormList, updatedServicesList])

  useEffect(() => {
    if (sitesData && servicesData && servicesActifData) {
      const serviceActif = servicesActifData.find(item => String(item.id) === id);
      const site = _.find(sitesData, (data) => String(data.id) === siteId);
      const service = _.find(servicesData, (data) => String(data.id) === serviceId);
      setSiteInfo(site);
      setServiceInfo(service);
      setSelectedServiceActif(serviceActif);
      if (serviceActif && serviceActif.content_source === 'template') {
        setIsTemplateSource(true);
      }
    }
  }, [sitesData, servicesData, servicesActifData])

  const newContentActuSchema = Yup.object().shape({
    serviceName: Yup.string()
      .required('Ce champs doit être complété'),
    illustrationPhrase: Yup.string()
      .required('Ce champs doit être complété'),
    contentShortDescription: Yup.string()
      .required('Ce champs doit être complété'),
  })

  const emptySchema = Yup.object().shape({})

  const getFormInitvalue = () => {
    const contentCustom = selectedServiceActif ? jsonSafeParse(selectedServiceActif.content_custom) : null;
    const contentConcierge = selectedServiceActif ? jsonSafeParse(selectedServiceActif.content_concierge) : null;
    const externalUrl = selectedServiceActif ? jsonSafeParse(selectedServiceActif.form_external_url) : null;
    let internalUrl = '';
    if (selectedServiceActif && selectedServiceActif.form_cyconia_id && selectedServiceActif.form_cyconia_id !== '0') {
      internalUrl = parseInt(selectedServiceActif?.form_cyconia_id) || null;
    }
    else if (internalServiceFormList && internalServiceFormList[0]) {
      internalUrl = internalServiceFormList?.[0].value;
    }

    return {
      service: serviceId,
      site: siteId,
      active: selectedServiceActif?.active || false,
      contentSource: selectedServiceActif?.content_source || "custom",
      imageFile: selectedServiceActif?.content_custom_image || null,
      contentCustomIconFile: selectedServiceActif?.content_custom_icon || null,
      serviceName: contentCustom?.serviceName || "",
      illustrationPhrase: contentCustom?.illustrationPhrase || "",
      contentShortDescription: contentCustom?.description || "",
      formSource: selectedServiceActif?.form_source || FORM_SOURCE.INTERNAL,
      messageElements: contentCustom?.messageElements || [],
      conciergeContentmessageElements: contentConcierge?.messageElements || [],
      internalUrl: internalUrl || null,
      externalUrl: externalUrl ||
      {
        url: "https://",
        title: "",
        target: "_blank",
        urlParams: [],
      }
    }
  }

  const renderFormChoice = (props) => {
    if (props.values.formSource === FORM_SOURCE.INTERNAL) {
      return (
        <GenericMaterialMenu
          list={internalServiceFormList}
          initialValue={props.values.internalUrl}
          onChange={(value) => props.setFieldValue('internalUrl', value)}
        />
      )
    }
    else if (props.values.formSource === FORM_SOURCE.EXTERNAL) {
      return (
        <ContentWrapper>
          <UrlButtonForm
            form={props.values.externalUrl}
            handleFormChange={(value, key) => handleUrlFormChange(props, value, key)}
          />
          <StyledTooltip title={'Ouvrir dans un modale'}>
            <OpenInNewIcon onClick={() => setOpenExternalFormModal(true)} style={{ fontSize: 18 }} />
          </StyledTooltip>
        </ContentWrapper>
      )
    }
  }

  const handleUrlFormChange = (props, value, key) => {
    const newExternalUrl = _.clone(props.values.externalUrl);

    newExternalUrl[key] = value;
    props.setFieldValue('externalUrl', newExternalUrl);
  }

  const handleClose = () => {
    if (isDirty) setopenDelete(true)
    else closeFunction()
  }

  const handleSubmit = (values) => {
    // console.log("all values =", values);
    dispatch(editServiceActif({ id: id, formData: values }));
  }

  const handleSourceSwitch = (event, props) => {
    props.setFieldValue('contentSource', event.target.value);
    setIsTemplateSource(event.target.value === 'template');
  }

  const CheckDirty = () => {
    const { dirty } = useFormikContext();

    useEffect(() => {
      setIsDirty(dirty)
    }, [dirty]);
    return null;
  };

  return (
    <Container>
      <DrawerCptCustom
        onOpen={visible}
        visible={visible}
        closeFunction={handleClose}
        className="serviceActifEditDrawer" >
        <DrawerWrapper>
          <Formik
            initialValues={getFormInitvalue()}
            enableReinitialize
            setFieldValue
            validationSchema={isTemplateSource ? emptySchema : newContentActuSchema}
            onSubmit={handleSubmit}
          >
            {(props) => {
              return (
                <Wrapper>
                  <GenericAdminFrontHeader
                    headerTitle={`Fiche service ${serviceInfo?.name}`}
                    headerDesc={'Editez les informations concernant votre service'}
                    RightSideComponent={
                      <SwitchContainer >
                        <GenericSwitch
                          title={props.values.active ? 'Activé' : 'Désactivé'}
                          checked={props.values.active}
                          onChange={() => props.setFieldValue('active', !props.values.active)}
                        />
                        {!props.values.active &&
                          <GenericAnimatedTextBubble
                            isIndicatorVisible={true}
                            arrowPosition={{
                              direction: 'top',
                              right: '15px',
                              top: '-6px'
                            }}
                            boxPosition={{
                              right: '20px'
                            }}
                            animationData={{ min: '-5px', max: '-17px', direction: 'vertical' }}
                          >
                            Cliquez ici pour activer <span role={'img'} aria-label={'emoji'}>☝️</span>
                          </GenericAnimatedTextBubble>
                        }
                      </SwitchContainer >
                    }
                  />
                  {(isLoading || serviceActifIsLoading) &&
                    <GenericLoader />
                  }
                  {!isLoading && !serviceActifIsLoading &&
                    <>
                      <InfoContainer>
                        <InfoTextContainer><InfoTextTitle>Site: </InfoTextTitle>{siteInfo?.name}</InfoTextContainer>
                        <InfoTextContainer><InfoTextTitle>Service Id: </InfoTextTitle>{serviceInfo?.id}</InfoTextContainer>
                      </InfoContainer>
                      <ContentBarContainer>
                        <ContentBar
                          list={tabList}
                          selectedTab={selectedTab}
                          onChange={(cat) => setSelectedTab(cat)}
                        />
                      </ContentBarContainer>
                      <Form>
                        <CheckDirty />
                        <FormContainer>
                          {selectedTab === tabList.clientContent &&
                            <ContentContainer>
                              <SitesSelectorBox>
                                <StyledGenericMaterialMenu
                                  list={updatedServicesList}
                                  onChange={(value) => setSelectedServiceId(value)}
                                />
                              </SitesSelectorBox>
                              <GenericRadioButton
                                row
                                title="Descriptif du service"
                                toolTipText="Vous pouvez utiliser des descriptifs pré-rédigés et non modifiables issus de Cyconia ou bien créer vos descriptifs"
                                value={props.values.contentSource}
                                choices={contentSourceChoices}
                                onChange={(e) => handleSourceSwitch(e, props)}
                              />
                              <StyledAccordion
                                headerComponent={
                                  <GenericTitleWithToolTip
                                    title={"Personnaliser le service"}
                                    toolTipText={"Vous pouvez présenter votre service avec une fiche standard ou créer votre propre présentation spécifique"}
                                  />
                                }
                                detailComponent={
                                  <ContentEditContainer>
                                    {isTemplateSource &&
                                      <ContentEditBlocker>
                                        {'Contenu non modifiable'}
                                      </ContentEditBlocker>
                                    }
                                    <CustomContentEditor
                                      formikProps={props}
                                      selectedServiceActif={selectedServiceActif}
                                    />
                                  </ContentEditContainer>
                                }
                              />
                              <GenericRadioButton
                                row
                                title="Choix du formulaire"
                                toolTipText="Vous pouvez utiliser un template pré-existant issu de Cyconia ou  bien un de vos formulaires personnalisés"
                                value={props.values.formSource}
                                choices={formSourceChoices}
                                onChange={(e) => props.setFieldValue('formSource', e.target.value)}
                              />
                              {renderFormChoice(props)}
                            </ContentContainer>
                          }
                          {selectedTab === tabList.conciergeContent &&
                            <ContentContainer>
                              <ConciergeCustomContent
                                formikProps={props}
                                specificCaseData={specificCaseData}
                                currentSiteId={siteId}
                              />
                            </ContentContainer>
                          }
                          <GenericDrawerValidationButtons
                            leftButonText={'Annuler'}
                            RightButtonText={'Sauvegarder'}
                            onclickLefttButton={handleClose}
                            submitable={true}
                          />
                          {openExternalFormModal &&
                            <StyledDialog open={true} onClose={() => setOpenExternalFormModal(false)}>
                              <DialogContent>
                                <UrlButtonForm
                                  form={props.values.externalUrl}
                                  handleFormChange={(value, key) => handleUrlFormChange(props, value, key)}
                                />
                              </DialogContent>
                            </StyledDialog>
                          }
                        </FormContainer>
                      </Form>
                    </>
                  }
                </Wrapper>
              )
            }}
          </Formik>
        </DrawerWrapper>
      </DrawerCptCustom>
      <GenericWarningModal
        open={openDelete}
        setOpenDialog={() => setopenDelete(!openDelete)}
        title={"Attention"}
        text={"Êtes-vous sur de vouloir annuler votre saisie?"}
        leftButtonText={"Non"}
        rightButtonText={"Oui"}
        leftButtonFunction={() => setopenDelete(!openDelete)}
        rightButtonFunction={closeFunction}
      />
    </Container>
  )
}

export default ConciergeEditDrawer