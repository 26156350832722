import * as a from "./actions";
import _ from 'lodash';

const initGetValue = {
  loading: false,
  success: false,
  error: null,
}

const modifyGet = (type, error = null) => {
  return {
    loading: type === 'request' ? true : false,
    success: type === 'success' ? true : false,
    error: type === 'error' ? error : null,
  }
}

const INITIAL_STATE = {
  collaboratorList: {
    data: null,
    get: initGetValue,
  },
  collaboratorRoles: {
    data: null,
    get: initGetValue,
  },
  selectedCollaborator: {
    data: null,
    get: initGetValue,
  },
  editCollaborator: {
    get: initGetValue,
  },
  switchCollaboratorSite: {
    get: initGetValue,
  }
};

export function userManagement(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case a.GET_COLLABORATOR_LIST.REQUEST:
      return {
        ...state,
        collaboratorList: {
          data: null,
          get: modifyGet('request')
        },
      };
    case a.GET_COLLABORATOR_LIST.SUCCESS:
      return {
        ...state,
        collaboratorList: {
          data: action.data,
          get: modifyGet('success')
        },
      };
    case a.GET_COLLABORATOR_LIST.FAILURE:
      return {
        ...state,
        collaboratorList: {
          data: null,
          get: modifyGet('error', action.error)
        },
      };
    case a.GET_COLLABORATOR_ROLES.REQUEST:
      return {
        ...state,
        collaboratorRoles: {
          data: null,
          get: modifyGet('request')
        },
      };
    case a.GET_COLLABORATOR_ROLES.SUCCESS:
      return {
        ...state,
        collaboratorRoles: {
          data: action.data,
          get: modifyGet('success')
        },
      };
    case a.GET_COLLABORATOR_ROLES.FAILURE:
      return {
        ...state,
        collaboratorRoles: {
          data: null,
          get: modifyGet('error', action.error)
        },
      };
    case a.GET_SELECTED_COLLABORATOR.REQUEST:
      return {
        ...state,
        selectedCollaborator: {
          data: null,
          get: modifyGet('request')
        },
      };
    case a.GET_SELECTED_COLLABORATOR.SUCCESS:
      return {
        ...state,
        selectedCollaborator: {
          data: action.data,
          get: modifyGet('success')
        },
      };
    case a.GET_SELECTED_COLLABORATOR.FAILURE:
      return {
        ...state,
        selectedCollaborator: {
          data: null,
          get: modifyGet('error', action.error)
        },
      };
    case a.EDIT_COLLABORATOR.REQUEST:
      return {
        ...state,
        editCollaborator: {
          get: modifyGet('request')
        },
      };
    case a.EDIT_COLLABORATOR.SUCCESS:
      return {
        ...state,
        selectedCollaborator: {
          ...state.selectedCollaborator,
          data: action.data,
        },
        editCollaborator: {
          get: modifyGet('success')
        },
      };
    case a.EDIT_COLLABORATOR.FAILURE:
      return {
        ...state,
        editCollaborator: {
          get: modifyGet('error', action.error)
        },
      };
    case a.EDIT_COLLABORATOR.RESET:
      return {
        ...state,
        editCollaborator: {
          get: initGetValue
        },
      };
    case a.SWITCH_COLLABORATOR_SITE.REQUEST:
      return {
        ...state,
        switchCollaboratorSite: {
          get: modifyGet('request')
        },
      };
    case a.SWITCH_COLLABORATOR_SITE.SUCCESS:
      return {
        ...state,
        switchCollaboratorSite: {
          get: modifyGet('success')
        },
      };
    case a.SWITCH_COLLABORATOR_SITE.FAILURE:
      return {
        ...state,
        switchCollaboratorSite: {
          get: modifyGet('error', action.error)
        },
      };
    default:
      return state;
  }
}

export default userManagement;