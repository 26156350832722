import React from 'react'

// Import Material UI components
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

//Import Icon
import CroosedElement from '../../../../../../components/atoms/Icon/croosedElement'

// Import style
import { ChipCustom } from './style'

const theme = createMuiTheme({
    overrides: {
        MuiChip: {
            root: {
                borderRadius: 4,
                backgroundColor: '#F1F1F1'
            }
        }
    }
});


const CrossElementIcon = () => {
    return (
        <ThemeProvider theme={theme}>
            <ChipCustom size="small" label="Élement partagé" icon={<CroosedElement color={'#C5C5C5'} width='17' height="9" />} />
        </ThemeProvider>
    )
}

export default CrossElementIcon;

