export const GET_USER = {
  REQUEST: 'COLLABORATOR/GET_USER/REQUEST',
  SUCCESS: 'COLLABORATOR/GET_USER/SUCCESS',
  FAILURE: 'COLLABORATOR/GET_USER/FAILURE',
};

export const GET_COLLABORATEUR = {
  REQUEST: 'COLLABORATOR/GET_COLLABORATEUR/REQUEST',
  SUCCESS: 'COLLABORATOR/GET_COLLABORATEUR/SUCCESS',
  FAILURE: 'COLLABORATOR/GET_COLLABORATEUR/FAILURE',
};

export const SAVE_COLLABORATEUR_TRANSPORTS = {
  REQUEST: 'COLLABORATOR/SAVE_TRANSPORTS/REQUEST',
  SUCCESS: 'COLLABORATOR/SAVE_TRANSPORTS/SUCCESS',
  FAILURE: 'COLLABORATOR/SAVE_TRANSPORTS/FAILURE',
  RESET: 'COLLABORATOR/SAVE_TRANSPORTS/RESET',
};

export const SAVE_COLLABORATEUR_PROFILE = {
  REQUEST: 'COLLABORATOR/SAVE_PROFILE/REQUEST',
  SUCCESS: 'COLLABORATOR/SAVE_PROFILE/SUCCESS',
  FAILURE: 'COLLABORATOR/SAVE_PROFILE/FAILURE',
  RESET: 'COLLABORATOR/SAVE_PROFILE/RESET',
};

export const SAVE_COLLABORATEUR_INFORMATIONS = {
  REQUEST: 'COLLABORATOR/SAVE_INFORMATIONS/REQUEST',
  SUCCESS: 'COLLABORATOR/SAVE_INFORMATIONS/SUCCESS',
  FAILURE: 'COLLABORATOR/SAVE_INFORMATIONS/FAILURE',
  RESET: 'COLLABORATOR/SAVE_INFORMATIONS/RESET',
};

export const UPDATE_LOCAL_USER_INFOS = {
  REQUEST: 'COLLABORATOR/UPDATE_LOCAL_USER_INFOS/REQUEST',
  SUCCESS: 'COLLABORATOR/UPDATE_LOCAL_USER_INFOS/SUCCESS',
};

export const CLEAR_USER_DATA = 'COLLABORATOR/CLEAR_DATA';

/**
 * UPDATE USER LOCAL INFOS
 */
 export const updateUserLocalInfos = (user) => ({
  type: UPDATE_LOCAL_USER_INFOS.REQUEST,
  user,
});

export const updateUserLocalInfosSuccess = (user) => ({
  type: UPDATE_LOCAL_USER_INFOS.SUCCESS,
  user,
});


/**
 * USER
 */
export const getUser = () => ({
  type: GET_USER.REQUEST,
});

export const getUserSuccess = (user) => ({
  type: GET_USER.SUCCESS,
  user,
});

export const getUserError = (error) => ({
  type: GET_USER.FAILURE,
  error,
});

/**
 * COLLABORATOR
 */
 export const getCollaborator = (id) => ({
  type: GET_COLLABORATEUR.REQUEST,
  id,
});

export const getCollaboratorSuccess = (collaborator) => ({
  type: GET_COLLABORATEUR.SUCCESS,
  collaborator,
});

export const getCollaboratorError = (error) => ({
  type: GET_COLLABORATEUR.FAILURE,
  error,
});

// Clear Data
export const clearData = () => ({
  type: CLEAR_USER_DATA,
});

/**
 * Save transports
 */
 export const saveUserTransports = (
  address, lat, lng, officeAddress,
  officeLat, officeLng, transport,
) => ({
  type: SAVE_COLLABORATEUR_TRANSPORTS.REQUEST,
  address,
  lat,
  lng,
  officeAddress,
  officeLat,
  officeLng,
  transport,
});

export const saveUserTransportsSuccess = (data) => ({
  type: SAVE_COLLABORATEUR_TRANSPORTS.SUCCESS,
  data,
});

export const saveUserTransportsError = (error) => ({
  type: SAVE_COLLABORATEUR_TRANSPORTS.FAILURE,
  error,
});

export const resetSaveUserTransports = () => ({
  type: SAVE_COLLABORATEUR_TRANSPORTS.RESET,
});

/**
 * Save profile
 */
 export const saveUserProfile = (gender, age) => ({
  type: SAVE_COLLABORATEUR_PROFILE.REQUEST,
  gender,
  age,
});

export const saveUserProfileSuccess = (data) => ({
  type: SAVE_COLLABORATEUR_PROFILE.SUCCESS,
  data,
});

export const saveUserProfileError = (error) => ({
  type: SAVE_COLLABORATEUR_PROFILE.FAILURE,
  error,
});

export const resetSaveUserProfile = () => ({
  type: SAVE_COLLABORATEUR_PROFILE.RESET,
});

/**
 * Save informations
 */
 export const saveUserInformations = ({firstname, lastname, email, currentPassword, newPassword, repeatNewPassword}) => ({
  type: SAVE_COLLABORATEUR_INFORMATIONS.REQUEST,
  firstname,
  lastname,
  email,
  currentPassword,
  newPassword,
  repeatNewPassword,
});

export const saveUserInformationsSuccess = (data) => ({
  type: SAVE_COLLABORATEUR_INFORMATIONS.SUCCESS,
  data,
});

export const saveUserInformationsError = (error) => ({
  type: SAVE_COLLABORATEUR_INFORMATIONS.FAILURE,
  error,
});

export const resetSaveUserInformations = () => ({
  type: SAVE_COLLABORATEUR_INFORMATIONS.RESET,
});
