// Import configs
import colors from "./colors";
import breakpoints from "./breakpoints";
import layouts from "./layouts";
import components from "./components";

const theme = {
  colors,
  breakpoints,
  layouts,
  components: components(colors),
};

export default theme;
