import { select, call, put } from 'redux-saga/effects';
import axios from 'axios';
import {
  getInternalServiceFormSuccess,
  getInternalServiceFormError,
  getInternalServiceFormSchemaSuccess,
  getInternalServiceFormSchemaError
} from '../actions';
import endpoint from '../../../helpers/api';
import apiCall from '../../apiCall';
import * as authenticationSelectors from '../../authentication/selectors';

export const getInternalServiceFormSchemaService = (endpoint, userToken, formData) => {
  const newForm = new FormData();
  newForm.append('formId', formData.formId);
  newForm.append('defaultValues', formData.defaultValues);

  const headers = {};
  if (userToken) {
    headers.Authorization = `Bearer ${userToken}`;
  }

  return axios.request({
    method: 'post',
    url: endpoint,
    data: newForm,
    headers: headers
  })
}

export function* getInternalServiceFormHandler() {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const calendarObjects = yield call(apiCall, endpoint.getInternalServiceForm, userToken);
    yield put(getInternalServiceFormSuccess(calendarObjects));
  } catch (err) {
    yield put(getInternalServiceFormError(err));
  }
}

export function* getInternalServiceFormSchemaHandler(action) {
  const formData = action.data;
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const response = yield call(getInternalServiceFormSchemaService, endpoint.getInternalServiceFormSchema, userToken, formData);
    if (response) {
      response.data.requiredList = response.data.required; // the required data become lost, maybe because it is a reserved keyword...
    }
    yield put(getInternalServiceFormSchemaSuccess(response.data));
  } catch (err) {
    yield put(getInternalServiceFormSchemaError(err));
  }
}