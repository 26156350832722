import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from "styled-components";
import { ThemeProvider as MUIThemeProvider } from "@material-ui/styles";
import { I18nextProvider } from "react-i18next";

import createStore from './app/store/configureStore';
import App from './app/App';
import i18nProvider from "./app/i18n-provider"; // initialized i18next instance
import reportWebVitals from './reportWebVitals';

import { PersistGate } from 'redux-persist/es/integration/react'
import { persistStore } from 'redux-persist'

// Reset
import MainDefaultStyle from './app/theme-style/main-default-style';

// Import style
import theme from "./app/theme-style";
import muiTheme from "./app/theme-style/mui-theme";

const { store } = createStore();

const persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <PersistGate persistor={persistor}>
      <Provider store={store}>
        <I18nextProvider i18n={i18nProvider}>
          <ThemeProvider theme={theme}>
            <MUIThemeProvider theme={muiTheme}>
              <MainDefaultStyle />
              <App />
            </MUIThemeProvider>
          </ThemeProvider>
        </I18nextProvider>
      </Provider>
    </PersistGate>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
