import axios from 'axios';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';

export const saveTicketRequestService = (endpoint, userToken, formData) => {
  const newForm = new FormData();
  newForm.append('version', formData.version);
  newForm.append('type', formData.type);
  newForm.append('status', formData.status);
  newForm.append('source', formData.source);
  newForm.append('companySite', parseInt(formData.company_site.id));
  newForm.append('companyId', parseInt(formData.company_id));
  newForm.append('serviceId', parseInt(formData.service_id));
  newForm.append('collaborator', parseInt(formData.collaborator.id));
  newForm.append('fullSubmission', formData.full_submission);
  newForm.append('humanReadableSubmission', formData.human_readable_submission);
  newForm.append('missionSheet', formData.mission_sheet);
  newForm.append('providerSheet', formData.provider_sheet);
  if (formData.author) {
    newForm.append('author', parseInt(formData.author.id));
  }

  // console.log('newForm', ...newForm);
  // console.log('formData', formData);

  const headers = {};
  if (userToken) {
    headers.Authorization = `Bearer ${userToken}`;
  }

  return axios.request({
    method: 'post',
    url: endpoint,
    data: newForm,
    headers: headers
  })
}