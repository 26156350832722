import React from "react";

import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useHistory
} from "react-router-dom";

import ConciergeEditDrawer from "./components/ConciergeEditDrawer";
import ServiceActifEditDrawer from "./components/ServiceActifEditDrawer";

const Routes = () => {
  const history = useHistory();
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/conceirgeEdit/:siteId/:id?`} render={() => (
        <ConciergeEditDrawer
          visible
          closeFunction={() => history.push(`${path}`)}
        />
      )} />
      <Route exact path={`${path}/serviceActifEdit/:siteId/:serviceId/:id?`} render={() => (
        <ServiceActifEditDrawer
          visible
          closeFunction={() => history.push(`${path}`)}
        />
      )} />
      <Redirect to={`${path}`} />
    </Switch>
  );
}

export default Routes;