const layouts = {
  headerApp: {
    height: 60,
  },
  headerProduct: {
    height: 120,
    heightFixed: 80,
  },
  headerKitchen: {
    height: 80,
    heightFixed: 80,
  },
  mainMenu: {
    width: 240,
  },
  structureMenu: {
    width: 170,
  },
};

export default layouts;
