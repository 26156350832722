import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';

import {
  Container,
  WrapperContent,
  Title,
  Text,
} from "./style";

const GenericAdminFrontHeader = ({
  headerTitle = '',
  headerDesc = '',
  headerDescToolTip = '',
  RightSideComponent = null,
  className, // to pass styled component className to mainContainer
  ...rest
}) => {

  return (
    <Container className={`${className ? className : ''} genericAdminFrontHeader`}>
      <WrapperContent className={'genericHeaderWrapper'}>
        <Title>
          {headerTitle}
        </Title>
        <Tooltip title={headerDescToolTip} >
          <Text>
            {headerDesc}
          </Text>
        </Tooltip>
      </WrapperContent>
      {RightSideComponent}
    </Container>
  );
}

GenericAdminFrontHeader.propTypes = {
  headerTitle: PropTypes.string,
  headerDesc: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  headerDescToolTip: PropTypes.string,
  RightSideComponent: PropTypes.any
}

export default GenericAdminFrontHeader;

// USAGE //
// Give the intended title and description for the props
// Pass a rendered component to RightSideComponent props to render your button on the right side of the headerDesc
// example usage:
// <GenericAdminFrontHeader
//   headerTitle={'Example Title'}
//   headerDesc={'Example Description'}
//   RightSideComponent={
//     <Button onClick={() => {}} >
//       Example button title
//     </Button>
//   }
// />
