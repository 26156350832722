import { toast } from 'react-toastify';

export const toaster = (message, type) => {
  if (type === 'success') {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
      className: "custom-toast custom-toast-success",
      bodyClassName: "custom-toast__body custom-toast__body-success",
      progressClassName: "custom-toast__progress"
    });
  }
  else if (type === 'error') {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
      className: "custom-toast custom-toast-error",
      bodyClassName: "custom-toast__body custom-toast__body-success",
      progressClassName: "custom-toast__progress"
    })
  }
  else if (type === 'warn') {
    toast.warn(message, {
      position: toast.POSITION.TOP_RIGHT,
      className: "custom-toast",
      bodyClassName: "custom-toast__body custom-toast__body-success",
      progressClassName: "custom-toast__progress"
    })
  }
}

