import React from 'react'
import PropTypes from 'prop-types';

import Loader from '../GenericLoader';

import {
  CustomDialog,
  DialogContainer,
  Wrapper,
  Title,
  Text,
  ButtonList,
  CustomButton,
  CustomButtonText,
  TextContainer
} from './style'

const GenericWarningModal = ({
  open,
  setOpenDialog,
  title,
  text,
  leftButtonText,
  rightButtonText,
  leftButtonFunction,
  rightButtonFunction,
  isLoading = false,
}) => {

  return (
    <CustomDialog
      open={open}
      onClose={() => setOpenDialog(!open)}
    >
      <DialogContainer>
        {isLoading ?
          <Loader />
          :
          <Wrapper>
            <Title>{title}</Title>
            <TextContainer>
              <Text>
                {text}
              </Text>
            </TextContainer>
            <ButtonList>
              <CustomButton onClick={() => leftButtonFunction()} left>
                <CustomButtonText left>
                  {leftButtonText}
                </CustomButtonText>
              </CustomButton>
              < CustomButton onClick={() => rightButtonFunction()} >
                <CustomButtonText>
                  {rightButtonText}
                </CustomButtonText>
              </CustomButton>
            </ButtonList>
          </Wrapper>
        }
      </DialogContainer>
    </CustomDialog >
  )
}

GenericWarningModal.propTypes = {
  open: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  title: PropTypes.string,
  text: PropTypes.string,
  leftButtonText: PropTypes.string,
  rightButtonText: PropTypes.string,
  leftButtonFunction: PropTypes.func,
  rightButtonFunction: PropTypes.func,
  isLoading: PropTypes.bool,
}

export default GenericWarningModal