import React from 'react'
import PropTypes from 'prop-types';
import { ThemeProvider, createTheme, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility';

import GenericTitleWithToolTip from '../GenericTitleWithToolTip';
import GenericErrorMessage from '../GenericErrorMessage';

import noPreview from '../../../assets/images/noPreviewAvailable.png';


//Import style
import {
  ChooseFileContainer,
  ChooseFileDiv,
  ChooseFileInput,
  CustomButon,
  ChoosedFileSpan,
  ChoosedFileContainer,
  ChooseFileButtonSpan,
  DeleteIcon,
  PreviewContainer,
  ImagePreviewContainer
} from './style'

const theme = createTheme({
  palette: {
    primary: {
      main: "#F7F7F7"
    }
  }
});

const useStyles = makeStyles((theme) => ({
  noMaxWidth: {
    maxWidth: 'none !important',
  },
}));

const GenericFileChooser = ({
  title,
  toolTipText = null,
  toolTipButton = '',
  buttonText,
  isRequired,
  className,
  currentFile = null,
  shouldShowImagePreview = false,
  selectedFileName,
  ...rest
}) => {
  const classes = useStyles();
  const { onChange } = rest;

  const handleOnChange = (event) => {
    if (event.target.files) {
      onChange(event.target.files[0]);
    }
    else {
      onChange(null);
    }
  }

  const getImage = (file) => {
    if (file) {
      if (typeof file !== 'string') {
        try {
          return URL.createObjectURL(file);
        }
        catch (e) {
          return noPreview;
        }
      }
      else return file;
    }
  }

  return (
    <ChooseFileContainer className={`${className ? className : ''} genericFileChooser`}>
      <GenericTitleWithToolTip
        title={title}
        toolTipText={toolTipText}
        isRequired={isRequired}
        className={'fileChooserTitle'}
      />
      <ChooseFileDiv>
        <ThemeProvider theme={theme}>
          <Tooltip className={'fileChooserButtonTooltip'} title={toolTipButton}>
            <CustomButon variant="contained" color='primary' component="label">
              <ChooseFileButtonSpan>{buttonText}</ChooseFileButtonSpan>
              <ChooseFileInput {...rest} onChange={handleOnChange} type="file" />
            </CustomButon>
          </Tooltip >
        </ThemeProvider>
        {selectedFileName &&
          <ChoosedFileContainer>
            <ChoosedFileSpan primary>
              {selectedFileName}
            </ChoosedFileSpan>
            <DeleteIcon onClick={handleOnChange} />
          </ChoosedFileContainer>
        }
        {shouldShowImagePreview &&
          <PreviewContainer>
            <Tooltip
              className={'fileChooserPreviewTooltip'}
              title={<ImagePreviewContainer image={getImage(currentFile)} />}
              classes={{ tooltip: classes.noMaxWidth }}
            >
              <VisibilityIcon className={'fileChooserPreviewIcon'} style={{ fontSize: 18 }} />
            </Tooltip>
          </PreviewContainer>
        }
      </ChooseFileDiv>
      {rest.id && <GenericErrorMessage name={rest.id} />}
    </ChooseFileContainer>
  )
}

GenericFileChooser.propTypes = {
  title: PropTypes.string,
  toolTipText: PropTypes.string,
  buttonText: PropTypes.string,
  isRequired: PropTypes.bool,
  className: PropTypes.string,
  selectedFileName: PropTypes.string,
}


export default GenericFileChooser