import React from "react";

const AdminIcon = ({
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon adminIcon"
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
    >
      <g fillRule="nonzero" fill="none">
        <path
          d="M1.955 18.195a.351.351 0 00-.03.063L.061 23.53a.352.352 0 00.448.449l5.274-1.864a.35.35 0 00.135-.088l-3.963-3.833z"
          fill="#FFC89F"
        />
        <path
          d="M19.808 11.217l-1.872-.266c-.15-.53-.36-1.04-.63-1.52l1.135-1.512a.352.352 0 00-.032-.46L16.591 5.64a.351.351 0 00-.46-.032L14.62 6.744c-.48-.27-.99-.48-1.521-.63l-.266-1.872a.351.351 0 00-.348-.302h-2.57a.351.351 0 00-.349.302l-.265 1.872c-.53.15-1.04.36-1.521.63L6.268 5.61a.352.352 0 00-.46.032L3.992 7.459a.352.352 0 00-.033.46L5.094 9.43c-.27.48-.48.99-.63 1.52l-1.872.267a.351.351 0 00-.302.348v2.57c0 .176.129.324.302.349l1.872.265c.065.23.143.46.233.685l3.237-3.238A3.34 3.34 0 0111.2 9.52a3.334 3.334 0 013.269 3.962 3.339 3.339 0 01-2.637 2.637l-3.229 3.229c.23.092.463.171.697.238l.266 1.872a.351.351 0 00.348.302h2.571a.352.352 0 00.348-.302l.266-1.872c.53-.15 1.04-.36 1.52-.63l1.512 1.136c.14.105.336.09.46-.033l1.818-1.818a.351.351 0 00.032-.46l-1.136-1.511c.27-.48.481-.99.63-1.52l1.872-.267a.351.351 0 00.302-.348v-2.57a.351.351 0 00-.301-.348z"
          fill="#007AFF"
        />
        <g fill="#005CC1">
          <path d="M18.441 7.918a.352.352 0 00-.032-.46l-1.818-1.817a.351.351 0 00-.46-.033l-.708.533 1.566 1.566-1.276 1.699c.346.57.608 1.195.77 1.86l1.453-.315c-.15-.53-.36-1.04-.63-1.52l1.135-1.512zM16.383 14.75c-.163.664-.324.975-.67 1.544l1.276 1.699-1.566 1.566.708.533c.14.105.336.09.46-.033l1.818-1.818a.351.351 0 00.032-.46l-1.135-1.511c.27-.48.48-.99.63-1.52h-1.553zM19.808 11.217l-1.221-.174v3.614l1.22-.174a.351.351 0 00.303-.348v-2.57a.352.352 0 00-.302-.348z" />
        </g>
        <g fill="#FFD039">
          <path d="M4.62 4.432l-.855-.383a1.229 1.229 0 01-.619-.62l-.383-.855a.352.352 0 00-.642 0l-.383.855a1.23 1.23 0 01-.62.62l-.855.383a.352.352 0 000 .642l.856.383c.275.124.495.344.619.62l.383.855a.352.352 0 00.642 0l.383-.856c.124-.275.344-.495.62-.619l.855-.383a.352.352 0 000-.642zM23.146 19.838l-.855-.383a1.23 1.23 0 01-.62-.62l-.383-.855a.352.352 0 00-.642 0l-.383.855a1.23 1.23 0 01-.62.62l-.855.383a.352.352 0 000 .642l.856.383c.275.123.495.343.619.62l.383.855a.352.352 0 00.642 0l.383-.856a1.23 1.23 0 01.62-.62l.855-.382a.352.352 0 000-.642zM15.73 1.839l-.76-.34a1.053 1.053 0 01-.53-.531l-.341-.76a.351.351 0 00-.642 0l-.34.76a1.053 1.053 0 01-.53.53l-.76.341a.352.352 0 000 .641l.76.341c.235.106.424.294.53.53l.34.76a.352.352 0 00.642 0l.34-.76c.106-.236.295-.424.531-.53l.76-.34a.352.352 0 000-.642zM11.2 9.52c-.224 0-.443.022-.654.065l-2.611 2.61-3.238 3.239-2.691 2.69a.353.353 0 00-.053.07L5.92 22.03l2.683-2.683 3.229-3.229 2.637-2.637A3.334 3.334 0 0011.2 9.52z" />
        </g>
        <path
          d="M14.525 12.675c0-.014-.002-.027-.003-.04a3.33 3.33 0 00-.01-.134l-.005-.037a3.351 3.351 0 00-.018-.136l-.005-.027a3.298 3.298 0 00-.027-.144l-.004-.017a3.253 3.253 0 00-.037-.151l-.002-.008a3.325 3.325 0 00-.046-.157l-.001-.002a3.327 3.327 0 00-.12-.314l-.22.232-9.217 9.217 1.11 1.074 2.683-2.683 3.229-3.229 2.637-2.637a3.332 3.332 0 00.056-.807z"
          fill="#FFA304"
        />
        <g fill="#005CC1">
          <path d="M11.264 21.76h1.221a.352.352 0 00.348-.302l.266-1.872-1.558.225-.277 1.949zM12.485 3.94h-1.221l.28 1.966 1.555.208-.266-1.872a.352.352 0 00-.348-.302z" />
        </g>
        <path
          d="M20.141 7.313L16.728 3.9a.352.352 0 00-.497 0l-2.447 2.447c.287.114.566.246.836.397L16.13 5.61a.351.351 0 01.46.032l1.818 1.818c.124.124.138.32.032.46L17.306 9.43c.149.266.28.541.392.823l2.443-2.443a.352.352 0 000-.497z"
          fill="#FFD039"
        />
        <path
          d="M17.306 9.43c.149.266.28.54.392.823l2.443-2.443a.351.351 0 000-.497L19.33 6.5l-.934.946.013.013c.124.124.138.32.032.46L17.306 9.43z"
          fill="#FFA304"
        />
        <path
          d="M.392 24a.35.35 0 00.117-.02l5.274-1.864a.35.35 0 00.135-.088l-1.111-1.075-4.382 1.549-.364 1.03A.352.352 0 00.392 24z"
          fill="#F7B081"
        />
        <path
          d="M3.502 22.922l-2.385-2.378L.06 23.531a.352.352 0 00.448.449l2.993-1.058z"
          fill="#3C5156"
        />
        <path
          d="M2.386 21.809l-1.961.693-.364 1.03a.352.352 0 00.448.448l2.993-1.058-1.116-1.113z"
          fill="#304144"
        />
        <path
          d="M21.988 5.467l-3.414-3.414a.351.351 0 00-.497 0l-2.09 2.09 3.906 3.906 2.095-2.085a.352.352 0 000-.497z"
          fill="#FF6137"
        />
        <path
          d="M18.84 6.997l1.053 1.052 2.095-2.085a.351.351 0 000-.497l-.828-.828-2.32 2.358z"
          fill="#E04F32"
        />
        <path
          d="M17.818 2.31l3.922 3.902 1.776-1.777a1.518 1.518 0 000-2.144L21.75.525a1.518 1.518 0 00-2.144 0L17.818 2.31z"
          fill="#546F7A"
        />
        <path
          d="M23.516 2.291L22.443 1.22a1.518 1.518 0 010 2.144l-1.775 1.776-2.847-2.832-.003.003 3.922 3.902 1.776-1.777a1.518 1.518 0 000-2.144z"
          fill="#475D63"
        />
      </g>
    </svg>
  );
};

export default AdminIcon;
