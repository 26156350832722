export const COLORS = {
  DARK_GREY: '#5A5A5A',
  GREEN: '#40B176',
  LIGHT_GREY_1: '#EEEEEE',
  LIGHT_GREY_2: '#DDDDDD',
  PRIMARY_BLUE: '#357ED3',
  PRIMARY_DEEP_BLUE: '#155EB3',
  RED: '#EC6B87',
  RED_2: '#CC4B67',
  RED_3: '#F44336',
  SLIGHT_GREY: '#FAFAFA',
  WHITE: '#FFFFFF',
};