import axios from 'axios';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';

import { CONTENT_STATUS } from '../constants/contentConstants';

export const editNewsContentService = async (endpoint, userToken, formData) => {
  const newForm = new FormData();
  let sitesList = {};
  formData.sites.map((site, index) => {
    sitesList[index] = JSON.stringify(site.value);
    return site.value;
  })

  newForm.append('title', formData?.title);
  newForm.append('content', formData?.content);
  newForm.append('companySite', JSON.stringify(sitesList));
  newForm.append('teaser', formData?.title);
  if (formData.status === CONTENT_STATUS.NOT_PUBLISHED) {
    newForm.append('published', false);
    newForm.append('planned', false);
    newForm.append('archived', false);
  }
  if (formData.status === CONTENT_STATUS.ARCHIVED) {
    newForm.append('published', false);
    newForm.append('planned', false);
    newForm.append('archived', true);
  }
  if (formData.status === CONTENT_STATUS.PUBLISHED) {
    newForm.append('published', true);
    newForm.append('planned', false);
    newForm.append('archived', false);
  }
  if (formData.status === CONTENT_STATUS.PLANNED) {
    const date = format(new Date(formData.publicationDate !== "" ? formData.publicationDate : new Date()), "yyyy-MM-dd HH:mm:ss", { locale: fr });
    newForm.append('published', false);
    newForm.append('planned', true);
    newForm.append('archived', false);
    newForm.append('plannedFor', date);
  }
  if (typeof formData?.chooseFile !== 'string') newForm.append('bannerFile', formData?.chooseFile);

  const headers = {};
  if (userToken) {
    headers.Authorization = `Bearer ${userToken}`;
  }

  return axios.request({
    method: 'post',
    url: endpoint,
    data: newForm,
    headers: headers
  });
}

export const deleteNewsContentService = async (endpoint, userToken) => {
  const headers = {};
  if (userToken) {
    headers.Authorization = `Bearer ${userToken}`;
  }

  return axios.request({
    method: 'delete',
    url: endpoint,
    headers: headers
  });
}