export function authHeader() {

    let token = localStorage.getItem('id_token');
    if (token !== null) {
        if (token.length !== 0) {
            return { "Authorization": "Bearer " + JSON.parse(token) };
        } else {
            return {};
        }
    }

}