import _ from 'lodash';

import {
  URL_PRESET_TYPE,
  JOT_FORM_PRESET
} from '../components/organisms/UrlButtonForm/constants';

const tokenReplacement = (params, user, companyModule, endpoint) => {
  let urlParamsString = '';

  _.map(params, (param, index) => {
    let valueString = param.value;
    const tokenArray = valueString.match(/{\w+}/g);

    _.map(tokenArray, (token) => {
      let value = '';

      if (token === '{userId}') value = user.id;
      else if (token === '{userFirstName}') value = user.firstname;
      else if (token === '{userLastName}') value = user.lastname;
      else if (token === '{userEmail}') value = user.email;
      else if (token === '{userCompanyName}') value = companyModule.name;
      else if (token === '{userCompanyId}') value = companyModule.id;
      else if (token === '{userSiteName}') value = user.company_site.name;
      else if (token === '{userSiteId}') value = user.company_site.id;
      else if (token === '{companyUrl}') value = endpoint.base_url;

      valueString = valueString.replace(token, value);
    });
    if (valueString !== '') {
      urlParamsString = `${urlParamsString}${index > 0 ? '&' : ''}${param.variable}=${valueString}`;
    }
  });

  return urlParamsString;
};

export const generateUrlParamsString = (urlObject, user, companyModule, endpoint) => {
  let urlParamsString = '';
  let urlParams = urlObject.urlParams;

  if (urlObject.preset === URL_PRESET_TYPE.JOTFORM) {
    urlParams = _.unionBy(urlObject.urlParams, JOT_FORM_PRESET, 'variable');
  }

  urlParamsString += tokenReplacement(urlParams, user, companyModule, endpoint);

  return urlParamsString;
};