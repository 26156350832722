import styled from "styled-components";
import { Form } from 'formik';

import { COLORS } from '../../../../constants/design';
import DrawerCpt from '../../../../components/organisms/drawerCpt';
import GenericDrawerFormContainer from "../../../../components/molecules/GenericDrawerFormContainer";

export const Container = styled.div`
`;

export const DrawerCptCustom = styled(DrawerCpt)`
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 700px;
  height: 100%;
  position: relative;
  box-sizing: border-box;
`;

export const StyledFormikForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const StyledFormContainer = styled(GenericDrawerFormContainer)`
  .genericMaterialMenu {
    .textFieldTitle {
      margin-bottom: 10px;
    }
  }
`;