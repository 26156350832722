import { useEffect } from "react";
import { useHistory } from 'react-router-dom';
import {
  shallowEqual,
  useDispatch,
  useSelector,
} from "react-redux";
import { logout } from '../../store/authentication/actions';
import { logoutSuccess } from '../../store/authentication/selectors';
const Logout = () => {
    const history = useHistory();
		const dispatch =  useDispatch();
		const {
			hasLogoutOnSuccess,
		} = useSelector(
			state => ({
				hasLogoutOnSuccess: logoutSuccess(state),
			}),
			shallowEqual
		);

    useEffect(() => {
			dispatch(logout());
    }, []);

		useEffect(() => {
			if (hasLogoutOnSuccess) {
				history.push('/login');
			}
    }, [hasLogoutOnSuccess]);
		return null;
};

export default Logout;
