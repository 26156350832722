import {
  all,
  put,
  takeEvery,
  call,
  // delay,
  select,
} from "redux-saga/effects";
import axios from 'axios';
import endpoint from '../../helpers/api';
import apiCall from '../apiCall';

import { toaster } from '../../helpers/toaster';

import * as authenticationSelectors from '../authentication/selectors';

import { getUser } from '../collaborator/selectors';

/** Actions / Slectors */
import * as a from './actions';

function* initCompanySaga() {
  // console.log('initCompanySaga')
  try {
    const company = yield call(apiCall, endpoint.companyInit);
    const formatedCompany = {
      ...company,
      settings: company.settings ? JSON.parse(company.settings) : null
    }
    // console.log('company', formatedCompany)
    if (formatedCompany?.code === 200) {
      yield put(a.companyInitSuccess(formatedCompany));
    } else {
      yield put(a.companyInitError(formatedCompany));
    }
  } catch (error) {
    yield put(a.companyInitError(error));
  }
}

function* getCompanyModulesSaga() {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const companyModules = yield call(apiCall, endpoint.getCompanyModules, userToken);
    yield put(a.companyGetModulesSuccess(companyModules));
  } catch (error) {
    yield put(a.companyGetModulesError(error));
  }
}

export const saveCompanySettingService = async (endpoint, formData, userToken) => {
  const newForm = new FormData();

  newForm.append('settings', JSON.stringify(formData.settings));
  newForm.append('domains', formData.companyForm.emailDomain);

  const headers = {};
  if (userToken) {
    headers.Authorization = `Bearer ${userToken}`;
  }

  return axios.request({
    method: 'post',
    url: endpoint,
    data: newForm,
    headers: headers
  })
}

function* saveCompanySettingsSaga(action) {
  const { formData } = action.data;
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const response = yield call(saveCompanySettingService, endpoint.saveCompanySetting, formData, userToken);
    yield put(a.saveCompanySettingsSuccess(response?.data));
    toaster('Company setting modified', 'success');
  } catch (error) {
    yield put(a.saveCompanySettingsError(error));
    toaster(`Une erreur s'est produite. Veuillez réessayer`, 'error');
  }
}

function* getCompanyContextsaga() {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const context = yield call(apiCall, endpoint.getCompanySiteContext, userToken);
    yield put(a.getCompanyContextSuccess(context[0]));
  } catch (error) {
    yield put(a.getCompanyContextError(error));
  }
}

export default function* companyRootSaga() {
  yield all([
    takeEvery(a.INIT.REQUEST, initCompanySaga),
    takeEvery(a.GET_MODULES.REQUEST, getCompanyModulesSaga),
    takeEvery(a.SAVE_COMPANY_SETTINGS.REQUEST, saveCompanySettingsSaga),
    takeEvery(a.GET_COMPANY_CONTEXT.REQUEST, getCompanyContextsaga),
  ]);
}
