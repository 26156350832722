import styled from "styled-components";
// import Svg, { Circle, Text, TSpan } from "react-native-svg";

export const Container = styled.div`
  .donutchart-track{
    fill: transparent;
    /* stroke: #DAE2E5; */
    /* stroke: ${props => props.strokeColorBg}; */
    /* stroke-width: ${props => props.strokeWidth || 26}; */
  }
  .donutchart-indicator {
    fill: transparent;
    /* stroke: #009688; */
    /* stroke: ${props => props.strokeColor || "#357ED3"}; */
    /* stroke-width: ${props => props.strokeWidth || 26}; */
    /* stroke-dasharray: ${props => props.strokeDasharray || "0 10000"}; */
    transition: stroke-dasharray .3s ease;
  }

  .donutchart {
    margin: 0 auto;
    border-radius: 50%;
    display: block;
  }


  .donutchart-text{
    font-family: 'Quicksand';
    font-weight: 600;
    fill: #607580;
  }
  .donutchart-text-val{
    font-size: 100%;
    /* font-size:22px; */
  }
  .donutchart-text-percent{
    font-size: 55%;
    /* font-size:14px; */
  }
  .donutchart-text-label{
    font-size: 50%;
  }
  .donutchart-text-label-outside{

  }
`;

export const ValueLabelOutside = styled.div`
  font-size: 10px;
  line-height: 10px;
  font-weight: 700;
  color: #ffffff;
  text-align: center;
  margin: 6px auto 0 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;
