import styled from "styled-components";

import { COLORS } from "../../../constants/design";

export const DialogContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 25px;
  background-color: white;
  border-radius: 6px;
  color: ${COLORS.DARK_GREY};
  font-size: 0.9rem;
  position: relative;
  
`;

export const StyledCloseContainer = styled.div`
  position: absolute;
  z-index: 9999;
  color: ${COLORS.DARK_GREY};
  background-color: ${COLORS.LIGHT_GREY_1};
  border-radius: 50%;
  padding: 1px;
  display: flex;
  opacity: 0.8;
  top: 20px;
  right: 20px;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;