import React, { useState, useEffect } from 'react';
import { shallowEqual, useSelector } from "react-redux";
import {
  useRouteMatch,
} from "react-router-dom";
import _ from 'lodash';
import { Views, dateFnsLocalizer } from 'react-big-calendar'
import { format, parse, startOfWeek, getDay } from 'date-fns';
import fr from 'date-fns/locale/fr';
import { useDispatch } from 'react-redux';
import "react-big-calendar/lib/css/react-big-calendar.css";
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import GradeIcon from '@material-ui/icons/Grade';

import {
  getConciergeServicesData,
  getConciergeCalendarObjectsData
} from '../../../../store/concierge/selectors';
import GenericLayout from '../../layouts/genericLayout';
import GenericAdminFrontHeader from '../../../../components/molecules/GenericAdminFrontHeader';
import GenericTitleWithIcon from '../../../../components/molecules/GenericTitleWithIcon';
import CalendarObjectModal from '../../components/organisms/CalandarObjectModal';
import CreateEventModal from '../../components/organisms/CreateEventModal';

import { saveCalendarObject, deleteCalendarObject } from '../../../../store/concierge/actions';
// Styles
import {
  StyledCalender,
} from "./style";

// const allViews = Object.keys(Views).map(k => Views[k])
const allViews = [
  'week', 'month', 'day', 'agenda'
]

const locales = {
  'fr': fr
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
})

const events = [
  {
    id: 0,
    title: 'Test All day',
    allDay: true,
    start: new Date(2021, 8, 25),
    end: new Date(2021, 8, 25),
    resource: {
      type: 'task',
      note: 'task 123',
      creator: 'John Doe'
    }
  },
  {
    id: 1,
    title: 'Test 12345',
    start: new Date(2021, 8, 26),
    end: new Date(2021, 8, 27),
    resource: {
      type: 'task',
      note: 'task 123sddasdas',
      creator: 'John Doe'
    }
  },
  {
    id: 2,
    title: 'Test Time',
    start: new Date('2021-09-26T09:07:00.000Z'),
    end: new Date('2021-09-26T11:10:00.000Z'),
    resource: {
      type: 'task',
      note: 'task 1dasdasdasdas23',
      creator: 'John Doe'
    }
  },
  {
    id: 3,
    title: 'Test Overlap',
    start: new Date('2021-09-25T11:00:00.000Z'),
    end: new Date('2021-09-25T12:20:00.000Z'),
    resource: {
      type: 'task',
      note: 'task 12312312333',
      creator: 'John Doe'
    }
  },
  {
    id: 4,
    title: 'Test 1',
    start: new Date('2021-09-28T11:00:00.000Z'),
    end: new Date('2021-09-28T12:20:00.000Z'),
    resource: {
      type: 'event',
      note: 'task 11123123123',
      creator: 'John Doe'
    }
  },
  {
    id: 5,
    title: 'Test 2',
    start: new Date('2021-09-29T11:00:00.000Z'),
    end: new Date('2021-09-29T12:20:00.000Z'),
    resource: {
      type: 'task',
      note: 'task 1123121adasdasd23',
      creator: 'John Doe'
    }
  },
  {
    id: 6,
    title: 'Test Now',
    start: new Date(),
    end: new Date(),
    resource: {
      type: 'event',
      note: 'task sdasd231232',
      creator: 'John Doe'
    }
  },
  {
    id: 7,
    title: 'Test MultiDay',
    start: new Date('2021-09-26T11:00:00.000Z'),
    end: new Date('2021-09-27T12:20:00.000Z'),
    resource: {
      type: 'task',
      note: 'task 431adasdasd1',
      creator: 'John Doe'
    }
  },
]


// const ColoredDateCellWrapper = ({ children }) =>
//   React.cloneElement(React.Children.only(children), {
//     style: {
//       backgroundColor: 'white',
//     },
//   })

const CustomEvent = (props) => {
  const { event } = props;

  return (
    <GenericTitleWithIcon
      title={event.title}
      Icon={event.resource.type === 'event' ?
        <GradeIcon style={{ fontSize: '0.8rem' }} /> :
        <FormatListBulletedIcon style={{ fontSize: '0.8rem' }} />}
      className={'acceptableCalenderObject'}
      iconClassName={'acceptableCalenderObject'}
      titleClassName={'acceptableCalenderObject'}
    />
  )
}

const CustomEventWrapper = (props) => {
  const { event, children } = props;
  // console.log('props', props);
  return (
    React.cloneElement(React.Children.only(children), {
      ...children.props,
      className: `${event.resource.type !== 'event' ? 'taskWrapper' : 'eventWrapper'}
      ${props.selected && event.resource.type !== 'event' && 'taskWrapperSelected'}
      ${children.props.className} acceptableCalenderObject`
    })
  )
}


const messages = {
  allDay: 'Journée',
  previous: 'Précédent',
  next: 'Suivant',
  today: 'Aujourd\'hui',
  month: 'Mois',
  week: 'Semaine',
  day: 'Jour',
  agenda: 'Tâche(s)',
  date: 'Date',
  time: 'Heure',
  event: 'Événement', // Or anything you want
  noEventsInRange: `Aucun événement prevu`,
  showMore: total => `+ ${total} événement(s) supplémentaire(s)`
}

const today = new Date();

const Dashboard = () => {
  const [createEventMode, setCreateEventMode] = useState('');
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [openDelete, setopenDelete] = useState(false);
  const [eventData, setEventData] = useState(null);
  const [calendarData, setCalendarData] = useState(null);
  const [calendarObjects, setCalendarObjects] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  // const handleClick = (event) => {
  // setAnchorEl(anchorEl ? null : event.currentTarget);
  // };

  const {
    servicesData,
    calendarObjetsData,
  } = useSelector(
    state => ({
      servicesData: getConciergeServicesData(state),
      calendarObjetsData: getConciergeCalendarObjectsData(state),
    }),
    shallowEqual
  );

  useEffect(() => {
    if (calendarObjetsData) {
      const newCalendarObjects = _.map(calendarObjetsData, (object) => {
        return {
          id: object.id,
          title: object.title,
          start: new Date(object.start),
          end: new Date(object.end),
          allDay: object.is_all_day,
          resource: {
            type: object.type,
            note: object.description,
            creator: `${object?.creator?.firstname || ""} ${object?.creator?.lastname || ""}`
          }
        }
      });
      // console.log('newCalendarObjects', newCalendarObjects);
      setCalendarObjects(newCalendarObjects);
    }
  }, [calendarObjetsData]);

  const handleEventSelect = (event, target) => {
    // console.log('handleCreateEvent event, target', event, target);

    setEventData(event);
    setAnchorEl(target.currentTarget)
  }

  const handleCreateEvent = (data, mode) => {
    setAnchorEl(null)
    setCalendarData(data);
    setCreateEventMode(mode);
  }

  const handleDelete = (id) => {
    dispatch(deleteCalendarObject(id));
    setAnchorEl(null);
  }

  const handleSubmit = (data) => {
    dispatch(saveCalendarObject(data));
    setCreateEventMode('');
  }

  return (
    <GenericLayout
      id="conciergeDashBoardLayout"
      className="conciergeDashBoardLayout"
    >
      <GenericAdminFrontHeader
        headerTitle={'Tableau de bord de mon espace conciergerie'}
        headerDesc={'Visualisez les événements à venir'}
      />
      <StyledCalender
        selectable={!anchorEl} // If popper is open, disabled calendar select event
        // popup
        events={calendarObjects}
        views={allViews}
        messages={messages}
        defaultView={"week"}
        step={15}
        timeslots={4}
        showMultiDayTimes
        culture={'fr'}
        scrollToTime={today}
        defaultDate={today}
        components={{
          // timeSlotWrapper: ColoredDateCellWrapper,
          event: CustomEvent,
          eventWrapper: CustomEventWrapper,
          // toolbar: MyToolbar,
        }}
        localizer={localizer}
        onSelectEvent={handleEventSelect}
        onSelectSlot={(data) => handleCreateEvent(data, 'add')}
      // onShowMore={(props) => console.log('onShowMore props', props)}
      />
      {!!anchorEl &&
        <CalendarObjectModal
          onClose={() => setAnchorEl(null)}
          onEdit={(data) => handleCreateEvent(data, 'edit')}
          onDelete={(id) => handleDelete(id)}
          data={eventData}
          anchorEl={anchorEl}
        />
      }

      {!!createEventMode &&
        <CreateEventModal
          // open={!!createEventMode}
          mode={createEventMode}
          onClose={() => setCreateEventMode('')}
          onSubmit={(data) => handleSubmit(data)}
          data={calendarData}
        />
      }

    </GenericLayout>
  );
}

export default Dashboard;

// Note
// if using Calender Dnd, the CustomEventWrapper needs to be modified, as DnD will add another layer of Wrapper,
// Dnd Wrapper hierachy: props.children.props.children.props.children
// Normal Wrapper hierachy; props.children.props.children
