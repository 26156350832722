import React, { useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import _ from 'lodash';

import { priceDisplay, displayDeadline } from '../../../helpers/roomBookingPriceHelper';

import {
  MainContainer,
  StyledExpandableHeader,
  StyledExpandableTitle,
  StyledExpandableIcon,
  StyledCancelExpandableContainer,
  StyledInfoContainer,
  InfoTitle,
  InfoDetails
} from './style';

const RoomBookingCancellationInfo = ({
  selectedRoom,
  expanded = true
}) => {
  const [showCancelInfo, setShowCancelInfo] = useState(expanded);

  // console.log('selectedRoom', selectedRoom);

  return (
    <MainContainer className='roomBookingCancellationInfo'>
      <StyledExpandableHeader onClick={() => setShowCancelInfo(!showCancelInfo)}>
        <StyledExpandableTitle
          title={`Conditions d’annulation`}
          toolTipText={``}
        />
        <StyledExpandableIcon>
          {showCancelInfo ?
            <ExpandLessIcon style={{ fontSize: 14 }} />
            :
            <ExpandMoreIcon style={{ fontSize: 14 }} />
          }
        </StyledExpandableIcon>
      </StyledExpandableHeader>
      <StyledCancelExpandableContainer isVisible={showCancelInfo}>
        <StyledInfoContainer>
          <InfoTitle>
            {selectedRoom.name}
          </InfoTitle>
          <InfoDetails>
            {`Délai d’annulation : ${displayDeadline(selectedRoom.cancel_deadline)}`}
          </InfoDetails>
          <InfoDetails>
            {`Frais d’annulation : ${priceDisplay(selectedRoom.cancel_fees)}`}
          </InfoDetails>
        </StyledInfoContainer>
        {_.map(selectedRoom.options, (option) => {
          return (
            <StyledInfoContainer>
              <InfoTitle>
                {option.title}
              </InfoTitle>
              <InfoDetails>
                {`Délai d’annulation : ${displayDeadline(option.cancel_deadline)}`}
              </InfoDetails>
              <InfoDetails>
                {`Frais d’annulation : ${priceDisplay(option.cancel_fees)}`}
              </InfoDetails>
            </StyledInfoContainer>
          )
        })}
        {selectedRoom.options?.length === 0 &&
          <StyledInfoContainer>
            <InfoTitle>
              {`Options`}
            </InfoTitle>
            <InfoDetails>
              {`Aucune option sélectionnée`}
            </InfoDetails>
          </StyledInfoContainer>
        }
      </StyledCancelExpandableContainer>
    </MainContainer>
  )
};

export default RoomBookingCancellationInfo;