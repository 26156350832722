export const HELPER_KEY = {
  PHRASE_1: 'PHRASE_1',
  PHRASE_2: 'PHRASE_2',
  PHRASE_3: 'PHRASE_3',
  PHRASE_4: 'PHRASE_4',
}

export const HELPER_TEXT = {
  [HELPER_KEY.PHRASE_1]: 'À quoi tu sers ?',
  [HELPER_KEY.PHRASE_2]: 'Comment contacter le service client ?',
  [HELPER_KEY.PHRASE_3]: 'Comment fonctionne l’intelligence artificielle ?',
  [HELPER_KEY.PHRASE_4]: 'Comment avoir Cyconia sur mon téléphone ',
};