import React from "react";

const EditInfoIcon = ({
  color = "#C8D1E7",
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon editInfoIcon"
      width={width}
      height={height}
      viewBox="0 0 11 11"
      fill="none"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
          <path
            d="M4.9008534,2.249244 L8.75417455,6.09799111 L4.52264692,10.3250339 C4.43941412,10.4077743 4.33181315,10.4615875 4.21569673,10.4785452 L4.21569673,10.4785452 L0.625475743,10.9939045 C0.454592843,11.0196863 0.281623634,10.9627663 0.1594243,10.8405381 C0.0372249665,10.71831 -0.0196816311,10.5453 0.00609410841,10.3743768 L0.00609410841,10.3743768 L0.521331929,6.78330932 C0.538274373,6.667162 0.592076531,6.55953231 0.674807024,6.47628676 L0.674807024,6.47628676 L4.9008534,2.249244 Z M6.31501678,0.834747195 C8.85623548,-1.70707066 12.7077478,2.13027275 10.1683379,4.68349431 L10.1683379,4.68349431 L9.52703128,5.31946961 L5.67919139,1.4707225 Z"
            id="editInfoIcon"
            fill={color}
            fillRule="nonzero"
          >
          </path>
      </g>
    </svg>
  );
};

export default EditInfoIcon;
