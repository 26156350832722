import React from 'react'

// Import style
import {
  CrossElement,
  CrossElementList,
  TitleFatherKB,
  CrossElementContainer,
  InformationBubble,
  IconAndTitle,
  TitleEIC,
  TitleHeader,
  InformationI,
  InformationISpan,
  HeaderTitleDiv
} from './style'

const CroosElementBanner = ({ eic, parent }) => {
  const bubbleContent = "Cet élément est partagé par plusieurs thématiques : Cyconia peut s’y référer comme réponse pour plusieurs questions différentes. A noter, si vous modifiez la réponse d’un élément partagé, elle sera modifiée pour toutes les thématiques à laquelle elle se rattache. "
  const EIClist = () => {
    return eic.map((item, key) => {
      if (item.subject.trim() !== parent.trim()) {
        return (
          item.enabled ? //Check if the user have the the KB
            <CrossElementContainer key={key}>
              <CrossElement>
                <IconAndTitle>
                  <TitleFatherKB>{`${item.skill} > ${item.subject}`}</TitleFatherKB>
                </IconAndTitle>
              </CrossElement>
            </CrossElementContainer >
            :
            <CrossElementContainer key={key} style={{ opacity: 0.5 }}>
              <CrossElement>
                <IconAndTitle>
                  <TitleFatherKB>{`${item.skill} > ${item.subject}`}</TitleFatherKB>
                </IconAndTitle>
              </CrossElement>
            </CrossElementContainer >
        )
      }
    })
  }
  return (
    <CrossElementList >
      <TitleHeader>
        <InformationBubble title={bubbleContent}>
          <HeaderTitleDiv style={{ display: "flex", alignItems: 'center' }}>
            <TitleEIC>Elément partagé avec :</TitleEIC>
            <InformationI><InformationISpan>i</InformationISpan></InformationI>
          </HeaderTitleDiv>
        </InformationBubble>
      </TitleHeader>
      <EIClist />
    </CrossElementList>
  )
}

export default CroosElementBanner