export const actionGenerator = (value) => ({
  REQUEST: `ROOM_BOOKING/${value}/REQUEST`,
  SUCCESS: `ROOM_BOOKING/${value}/SUCCESS`,
  FAILURE: `ROOM_BOOKING/${value}/FAILURE`,
  RESET: `ROOM_BOOKING/${value}/RESET`,
})

export const GET_USER_ROOMS = actionGenerator("GET_USER_ROOMS");
export const GET_BOOKINGS = actionGenerator("GET_BOOKINGS");
export const GET_BOOKINGS_BY_DATE = actionGenerator("GET_BOOKINGS_BY_DATE");
export const GET_BOOKINGS_BY_RANGE_DATE = actionGenerator("GET_BOOKINGS_BY_RANGE_DATE");
export const SAVE_BOOKING = actionGenerator("SAVE_BOOKING");
export const CANCEL_BOOKING = actionGenerator("CANCEL_BOOKING");

export const getUserRooms = (data) => ({
  type: GET_USER_ROOMS.REQUEST,
  data,
});

export const getUserRoomsSuccess = (data) => ({
  type: GET_USER_ROOMS.SUCCESS,
  data,
});

export const getUserRoomsError = (error) => ({
  type: GET_USER_ROOMS.FAILURE,
  error,
});

export const getBookings = (data) => ({
  type: GET_BOOKINGS.REQUEST,
  data,
});

export const getBookingsSuccess = (data) => ({
  type: GET_BOOKINGS.SUCCESS,
  data,
});

export const getBookingsError = (error) => ({
  type: GET_BOOKINGS.FAILURE,
  error,
});

export const getBookingsByDate = (data) => ({
  type: GET_BOOKINGS_BY_DATE.REQUEST,
  data,
});

export const getBookingsByDateSuccess = (data) => ({
  type: GET_BOOKINGS_BY_DATE.SUCCESS,
  data,
});

export const getBookingsByDateError = (error) => ({
  type: GET_BOOKINGS_BY_DATE.FAILURE,
  error,
});

export const getBookingsByRangeDate = (data) => ({
  type: GET_BOOKINGS_BY_RANGE_DATE.REQUEST,
  data,
});

export const getBookingsByRangeDateSuccess = (data) => ({
  type: GET_BOOKINGS_BY_RANGE_DATE.SUCCESS,
  data,
});

export const getBookingsByRangeDateError = (error) => ({
  type: GET_BOOKINGS_BY_RANGE_DATE.FAILURE,
  error,
});

export const saveBooking = (data) => ({
  type: SAVE_BOOKING.REQUEST,
  data,
});

export const saveBookingSuccess = (data) => ({
  type: SAVE_BOOKING.SUCCESS,
  data,
});

export const saveBookingError = (error) => ({
  type: SAVE_BOOKING.FAILURE,
  error,
});

export const cancelBooking = (data) => ({
  type: CANCEL_BOOKING.REQUEST,
  data,
});

export const cancelBookingSuccess = (data) => ({
  type: CANCEL_BOOKING.SUCCESS,
  data,
});

export const cancelBookingError = (error) => ({
  type: CANCEL_BOOKING.FAILURE,
  error,
});

export const cancelBookingReset = (error) => ({
  type: CANCEL_BOOKING.RESET,
  error,
});