import React from 'react';
import PropTypes from 'prop-types';

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import {
  TableCellCustom,
  TableSortLabelCustom,
  TableSortLabelContent
} from "./style";

const GenericTableHead = ({ headerList, orderBy, order, onRequestSort }) => {

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headerList.map((header) => (
          <TableCellCustom
            key={header.id}
            align={header.numeric ? 'right' : 'left'}
            padding={header.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === header.id ? order : false}
          >
            {header.disableSort ?
              header.label
              :
              <TableSortLabelCustom
                active={orderBy === header.id}
                direction={orderBy === header.id ? order : 'asc'}
                onClick={createSortHandler(header.id)}
              >
                {header.label ? header.label : 'Label'}
                {orderBy === header.id ? (
                  <TableSortLabelContent>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </TableSortLabelContent>
                ) : null}
              </TableSortLabelCustom>
            }
          </TableCellCustom>
        ))}
      </TableRow>
    </TableHead>
  )
}

GenericTableHead.propTypes = {
  headerList: PropTypes.array,
  orderBy: PropTypes.string,
  order: PropTypes.string,
  onRequestSort: PropTypes.func
}

export default GenericTableHead;