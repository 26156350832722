import styled from "styled-components";
import Button from '@material-ui/core/Button';

import { COLORS } from "../../../constants/design";

import GenericTextField from '../../molecules/GenericTextField';
import GenericRadioButton from '../../molecules/GenericRadioButton';
import GenericMaterialMenu from '../../molecules/GenericMaterialMenu';
import GenericAccordion from "../../molecules/GenericAccordion";

export const StyledForm = styled.form`
`;

export const StyledDivider = styled.div`
  margin-bottom: 25px;
`;

export const StyledTextField = styled(GenericTextField)`
  margin: 10px 0px;
`;

export const StyledRadioButton = styled(GenericRadioButton)`
  /* margin: 10px 0px; */
`;

export const StyledButton = styled(Button)`
  && {
    &.MuiButton-root {
      text-transform: none;
      color: ${COLORS.PRIMARY_DEEP_BLUE};
      width: 100%;
      font-size: 0.9rem;
      padding: 10px 0px;
      margin: 10px 0px;
    }
  }
`;

export const StyledAccordion = styled(GenericAccordion)`
  && {
    .MuiAccordion-root {
      font-size: 0.9rem;
    }
  }
`;

export const StyledAccordionHeader = styled.div`
  color: ${COLORS.PRIMARY_DEEP_BLUE};
  text-align: center;
  font-weight: 500;
  /* border: 1px solid black; */
  width: 100%;
`;

export const StyledAccordionContent = styled.div`
  display: flex;
  flex-direction: column;
  >:not(:last-child) {
    margin-bottom: 10px;
  };
`;

export const StyledMenu = styled(GenericMaterialMenu)`
  margin: 15px 0;
  .textFieldTitle {
    margin-bottom: 15px;
  }
  .textTitle {
    font-weight: 600;
    font-size: 0.85rem;
    color: #5A5A5A;
  }
  .textTooltip {
    color: ${COLORS.GREY_1};
  }
`;

export const StyledParamsHeader = styled.div`
  /* border: 1px solid black; */
  display: flex;
  padding-right: 40px;
  margin: 20px 0px;
  color: #5A5A5A;
`;

export const StyledParamsTitle = styled.div`
  /* border: 1px solid black; */
  width: 48%;
  text-align: center;
`;

export const StyledNewParamsContainer = styled.div`
  /* border: 1px solid #CCCCCC; */
  display: flex;
  width: 100%;
  /* border-radius: 10px; */
  margin: 10px 0px;
  animation: appear 0.5s ease;

  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .genericTextField {
    margin: 0px;
    .textFieldInput {
      margin: 0px 10px 0px 0px;
    }
  }
  && {
    .MuiIconButton-root {
      /* border: 1px solid black; */
      /* margin-left: 10px; */
      width: 40px;
      height: 40px;
      color: #EC6B87;
      align-self: center;
    }
  }
`;