import React from 'react';

// Layout
import GenericLayout from '../../layouts/genericLayout';

import UsersManagement from '../../../UsersManagementPage';

const UsersManagementContainer = () => {
  return (
    <GenericLayout
      id="UsersManagementLayout"
      className="UsersManagementLayout"
    >
      <UsersManagement/>
    </GenericLayout>
  );
}

export default UsersManagementContainer;
