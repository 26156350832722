import styled from "styled-components";
import Dialog from '@material-ui/core/Dialog';

export const MainContainer = styled.div`
  /* border: 1px solid black; */
  min-height: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AcceptCGU = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  &:hover{
    label{
      color: #444444;
    }
  }
  label{
    width: 100%;
    padding: 22px 10px;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: #f5f5f5;
    text-align: center;
    font-size: 15px;
    color: #838383;
    cursor: pointer;
    transition: all .2s ease-out;
    input{
      cursor: pointer;
      margin: 0 0 0 10px;
      vertical-align: bottom;
      width: 15px;
      height: 15px;
    }
  }
`;

export const LinkInfoCGU = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: blue;
  cursor: pointer;
  text-align: center;
  padding: 20px 0;
  margin: 20px 0 10px 0;
  border-radius: 8px;
  background-color: #f5f5f5;
  transition: all .2s ease-out;
  &:hover{
    text-decoration: underline;
  }
`;

export const SelectCompany = styled.div`
  padding: 0 0 20px 0;
  & > div{
    & > span + div{
      height: 50px;
    }
    & > div{
      color: #838383;
      &:first-child{
        height: 50px;
      }
    }
  }
`;

export const ErrorText = styled.p`
  display: flex;
  width: 100%;
  font-size: 11px;
  font-weight: 500;
  font-style: italic;
  color: #ff4f4f;
  text-align: left;
  padding: 5px 0 0 0;
`;

export const Text = styled.p`
  display: block;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 600;
  color: #838383;
  text-align: center;
  font-weight: 400;
  &:last-child{
    margin: 0;
  }
`;

export const BubbleText = styled.div`
  position: relative;
  padding: 15px;
  margin: 0 0 15px 0;
  border-radius: 5px 10px 10px 10px;
  background-color: #FFFFFF;
  box-shadow: 1px 1px 3px 1px rgb(0 0 0 / 20%);
`;

export const Container = styled.div`
  form{
    .ladda-button{
      margin: 20px 0 0 0;
    }
  }
`;

export const StyledEmailLink = styled(LinkInfoCGU)`
  padding: 10px;
  font-size: 0.8rem;
  margin: 10px 0px 0px 0px;
  background-color: transparent;
`;