import React, { useState, useEffect, useMemo } from 'react';
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import LaddaButton, { SLIDE_UP } from 'react-ladda';
import moment from 'moment';
import 'moment/locale/fr';
import zxcvbn from "zxcvbn";

// Store
import {
  isUserLoginOnError,
  isUserLoginOnLoading,
  isUserLoginOnSuccess,
  isSendEmailResetPasswordOnError,
  isSendEmailResetPasswordOnSuccess,
  isSendEmailResetPasswordOnLoading,
  isUserNewPasswordOnError,
} from '../../../../store/authentication/selectors';
import {
  login,
  sendEmailResetPassword,
  resetSendEmailResetPassword,
  updatePassword,
} from '../../../../store/authentication/actions';

// Style
import {
  ModalCustom,
  Title,
  Text,
  ErrorText,
  StyledAzureButton,
} from './style';
import {
	LinkRgpdWrapper,
} from '../../style';

// Components
import { FieldText, FieldPassword } from '../../../../components/molecules/Field';
// Icons
import { IconUser, IconLock } from '../../../../helpers/Icon';
import { red } from '@material-ui/core/colors';

const LoginForm = (props) => {
  const { isConcierge, companyData } = props;
  const dispatch = useDispatch();
  const PASSWORD_STRENGTH_LABELS = {
    0: "Médiocre",
    1: "Faible",
    2: "Correct",
    3: "Bon",
    4: "Exceptionnel"
  };
  const PASSWORD_MIN_LENGTH = 9;
  const {
    userLoginOnError,
    userLoginOnLoad,
    userLoginOnSuccess,
    sendEmailResetPasswordOnError,
    sendEmailResetPasswordOnSuccess,
    sendEmailResetPasswordOnLoading,
    isUpdatePasswordOnError,
  } = useSelector(
    state => ({
      userLoginOnError: isUserLoginOnError(state),
      userLoginOnLoad: isUserLoginOnLoading(state),
      userLoginOnSuccess: isUserLoginOnSuccess(state),
      sendEmailResetPasswordOnError: isSendEmailResetPasswordOnError(state),
      sendEmailResetPasswordOnSuccess: isSendEmailResetPasswordOnSuccess(state),
      sendEmailResetPasswordOnLoading: isSendEmailResetPasswordOnLoading(state),
      isUpdatePasswordOnError: isUserNewPasswordOnError(state),
    }),
    shallowEqual);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showModalForgotPassword, setShowModalForgotPassword] = useState(false);
  const [showModalNewPassword, setShowModalNewPassword] = useState(false);
  const [usernameForget, setUsernameForget] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [isRegisterForm, setIsRegisterForm] = useState(false);
  const [isResetPasswordExpired, setIsResetPasswordExpired] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(-1);
  const [passwordError, setPasswordError] = useState(null);
  const [toastLoginError, setToastLoginError] = useState(null);
  const [toastNewPasswordError, setToastNewPasswordError] = useState(null);
  const ADMIN_BASE_URL = process.env.REACT_APP_ADMIN_BASE_URL;
  const LinkRgpdFiles = "https://" + ADMIN_BASE_URL + "/build/files/cyconia/politique-de-traitement-de-donnees.pdf";

  const onSubmitLogin = e => {
    e.preventDefault();
    if (!!username?.length && !!password?.length) {
      dispatch(login(username, password));
    } else {
      console.log('ERROR SUBMIT LOGIN !!!');
    }
  };

  const onInputChange = e => {
    const {
      target: {
        name,
        value,
      },
    } = e;
    if (name === 'username') {
      setUsername(value);
    } else if (name === 'password') {
      setPassword(value);
    } else if (name === 'usernameForget') {
      setUsernameForget(value);
    } else if (name === 'newPassword') {
      checkPasswordComplexity(value, PASSWORD_MIN_LENGTH);
      setNewPassword(value);
    } else if (name === 'newPasswordConfirm') {
      setNewPasswordConfirm(value);
    }
  };

  useEffect(() => {
    const url = new URL(window.location);
    const resetToken = url.searchParams.get("token");
    const isURLRegisterForm = url.searchParams.get("register") === "true";
    const expireDate = url.searchParams.get("expire");
    let expireMoment;
    let currentDate = moment();
    if (expireDate !== null && resetToken) {
      expireMoment = moment.unix(expireDate);
      setIsResetPasswordExpired((currentDate > expireMoment));
      setIsRegisterForm(isURLRegisterForm);
      setShowModalNewPassword(true);
    } else if (!expireDate && isURLRegisterForm) {
      setShowModalNewPassword(true);
    }
  }, []);

  const sendForgetPasswordEmail = () => {
    const subject = encodeURIComponent('Récupération de mot de passe Concierge');
    const targetEmail = `onvousaimeonvousaide@cyconia.io`;
    // dont change the indent as it will affect the email body
    let body = `Bonjour,%0A%0A
Pouvez-vous m'aider à récupérer mon mot de passe ? Merci.%0A%0A
Bien cordialement,%0A`;

    window.open(`mailto:${targetEmail}?subject=${subject}&body=${body}`, '_blank');
  }

  const handleForgetPassword = () => {
    if (isConcierge) {
      sendForgetPasswordEmail();
    }
    else {
      onShowModalForgotPassword(true);
    }
  }

  const onShowModalForgotPassword = (isVisible) => {
    if (isVisible) {
      initModalForget();
    }
    setShowModalForgotPassword(isVisible);
  };

  const initModalForget = () => {
    if (usernameForget === "") {
      setUsernameForget(username);
    }
  };

  const onSendResetPasswordEmail = (e) => {
    e.preventDefault();
    if (!!usernameForget?.length) {
      dispatch(sendEmailResetPassword(usernameForget, `${window.location.origin}/login/reset`));
    }
  };

  const onRetryResetPassword = () => {
    dispatch(resetSendEmailResetPassword());
  };

  const onRetryAllRecoveryProcess = () => {
    onRetryResetPassword();
    setShowModalNewPassword(false);
    setTimeout(() => {
      setShowModalForgotPassword(true);
    }, 250);
  };

  const onSubmitNewPassword = e => {
    e.preventDefault();
    if ((!!newPassword?.length && newPassword === newPasswordConfirm) && passwordError === null) {
      const url = new URL(window.location);
      dispatch(updatePassword(newPassword, newPasswordConfirm, url.searchParams.get("token"), true));
    } else {
      if (newPassword?.length === 0 || newPasswordConfirm?.length === 0) {
        setToastNewPasswordError('Vous ne pouvez pas continuer sans remplir tous les champs.');
        return;
      }
      if (newPassword !== newPasswordConfirm) {
        setToastNewPasswordError('Les 2 mots de passe saisis ne sont pas identiques.');
        return;
      }
    }
  };

  useEffect(() => {
    if (toastNewPasswordError) {
      toast.error(toastNewPasswordError, {
        position: toast.POSITION.TOP_RIGHT,
        className: "custom-toast custom-toast-error",
        bodyClassName: "custom-toast__body custom-toast__body-success",
        progressClassName: "custom-toast__progress"
      });
      setToastNewPasswordError(null);
    }
  }, [toastNewPasswordError]);

  useEffect(() => {
    if (isUpdatePasswordOnError) {
      toast.error('Ce lien est expiré, veuillez faire une nouvelle demande de mot de passe.', {
        position: toast.POSITION.TOP_RIGHT,
        className: "custom-toast custom-toast-error",
        bodyClassName: "custom-toast__body custom-toast__body-success",
        progressClassName: "custom-toast__progress"
      });
      setToastNewPasswordError(null);
    }
  }, [isUpdatePasswordOnError]);

  const checkPasswordComplexity = (
    password, minLength, caseSensitive = true, hasNumber = true,
    hasSpecialCharacter = true) => {

    const caseRegex = /[A-Z]/;
    const caseRegex2 = /[a-z]/;
    const numberRegex = /\d/;
    const specialCharacter = /\W/;

    let error = "";
    let result;

    result = zxcvbn(password);

    if (password.length < minLength) {
      error += `Minimum ${PASSWORD_MIN_LENGTH} caractères. `
    }

    if (caseSensitive) {
      if (caseRegex.test(password) === false) {
        error += "Minimum une majuscule. ";
      }
    }
    if (caseSensitive) {
      if (caseRegex2.test(password) === false) {
        error += "Minimum une minuscule. ";
      }
    }
    if (hasNumber) {
      if (numberRegex.test(password) === false) {
        error += "Minimum un chiffre. ";
      }
    }
    if (hasSpecialCharacter) {
      if (specialCharacter.test(password) === false) {
        error += "Minimum un caractère spécial. ";
      }
    }
    setPasswordStrength(result.score);
    setPasswordError(error?.length === 0 ? null : error);
    return error?.length === 0;
  }

  useEffect(() => {
    if (userLoginOnError) {
      switch (userLoginOnError.status) {
        case 500:
          toast.error("Une erreur est survenue ! Veuillez réessayer plus tard. Si le problème persiste, contactez le support. ", {
            position: toast.POSITION.TOP_RIGHT,
            className: "custom-toast custom-toast-error",
            bodyClassName: "custom-toast__body custom-toast__body-success",
            progressClassName: "custom-toast__progress"
          });
          break;
        case 423:
          toast.error("Oups ! Votre compte semble bloqué. Si vous pensez que c'est une erreur n'hésitez pas à nous contacter au 06.19.15.32.89 ou par email au onvousaimeonvousaide@cyconia.io", {
            position: toast.POSITION.TOP_RIGHT,
            className: "custom-toast custom-toast-error",
            bodyClassName: "custom-toast__body custom-toast__body-success",
            progressClassName: "custom-toast__progress"
          });
          break;
        case 403:
          toast.error("Votre compte n'a pas été activé, si vous ne retrouvez pas votre email d'activation vous pouvez faire une récupération de mot de passe !", {
            position: toast.POSITION.TOP_RIGHT,
            className: "custom-toast custom-toast-error",
            bodyClassName: "custom-toast__body custom-toast__body-success",
            progressClassName: "custom-toast__progress"
          });
          break;
        case 401:
          toast.error("L’email ou le mot de passe saisie est incorrect", {
            position: toast.POSITION.TOP_RIGHT,
            className: "custom-toast custom-toast-error",
            bodyClassName: "custom-toast__body custom-toast__body-success",
            progressClassName: "custom-toast__progress"
          });
          break;
        default:
          toast.error("Une erreur est survenue ! Veuillez réessayer plus tard. Si le problème persiste, contactez le support. ", {
            position: toast.POSITION.TOP_RIGHT,
            className: "custom-toast custom-toast-error",
            bodyClassName: "custom-toast__body custom-toast__body-success",
            progressClassName: "custom-toast__progress"
          });
      }
    }
  }, [userLoginOnError]);

  const generateAzureSsoButton = (azureSsoData) => {
    const azureSso = JSON.parse(azureSsoData);
    // console.log('azureSsoData', azureSso);
    return (
      <StyledAzureButton
        title={azureSso.loginLinkTitle}
        onClick={() => {
          window.location = azureSso.loginUrl;
        }}
      />
    )
  }

  return (
    <>
      <form onSubmit={onSubmitLogin}>
        <FieldText
          name="username"
          label="Votre e-mail professionnel"
          value={username}
          onChange={onInputChange}
          alertMessage="Votre email est requis"
          required={true}
          className="dual-theme"
          icon={IconUser}
          type="email"
        />
        <FieldText
          type="password"
          name="password"
          label="Mot de passe"
          value={password}
          onChange={onInputChange}
          alertMessage="Votre mot de passe est requis"
          className="dual-theme"
          icon={IconLock}
        />
        <button
          type="button"
          onClick={() => handleForgetPassword()}
          className="a-link_forget btn-icon o-form_login-linkForget"
        >
          J'ai oubli&eacute; mon mot de passe
        </button>
        <LaddaButton
          className="a-button a-button-big a-button-purple"
          loading={userLoginOnLoad}
          data-color="#eee"
          data-style={SLIDE_UP}
          data-spinner-size={30}
          data-spinner-color="#fff"
          data-spinner-lines={12}
        >
          Se connecter
        </LaddaButton>
        {companyData.azureSso && generateAzureSsoButton(companyData.azureSso)}
      </form>

      <LinkRgpdWrapper>
        <hr />
        <a target="_blank" href={LinkRgpdFiles} rel="noreferrer">
          Politique de traitement des données
        </a>
      </LinkRgpdWrapper>

      <ModalCustom
        aria-labelledby="simple-dialog"
        open={showModalForgotPassword}
        onClose={() => onShowModalForgotPassword(false)}
        classNames={{ modal: "modal-disconnect", closeButton: "modal-disconnect-close_button" }}
      >
        {sendEmailResetPasswordOnSuccess ? (
          <div>
            <Title>
              E-mail envoyé !
            </Title>
            <Text>
              Nous avons envoyé un message à l'adresse {usernameForget} pour vous permettre de choisir un nouveau mot passe.
            </Text>
            <Text>
              Vous n'avez pas reçu l'e-mail ?
              <br />
              Contactez notre <a href="mailto:contact@cyconia.io">Assistance Cyconia</a>
            </Text>
            <Text>
              Ce n'est pas votre e-mail ?
            </Text>
            <button
              type="button"
              className="modal-disconnect-link"
              onClick={onRetryResetPassword}
            >
              Essayer à nouveau
            </button>
          </div>
        ) : (
          <form onSubmit={onSendResetPasswordEmail}>
            <Title>
              Mot de passe oublié
            </Title>
            <Text>
              Saisissez l’adresse adresse mail que vous nous avez communiqué pour réinitialiser votre mot de passe!
            </Text>
            <FieldText
              name="usernameForget"
              /*label="Email"*/
              type="email"
              value={usernameForget}
              onChange={onInputChange}
              alertMessage="Votre email est requis"
              required
            />
            <LaddaButton
              className="modal-disconnect-button a-button a-button-green"
              loading={sendEmailResetPasswordOnLoading}
              data-color="#eee"
              data-style={SLIDE_UP}
              data-spinner-size={30}
              data-spinner-color="#fff"
              data-spinner-lines={12}
            >
              Réinitialiser mon mot de passe
            </LaddaButton>
          </form>
        )}
      </ModalCustom>
      <ModalCustom
        aria-labelledby="simple-dialog"
        open={showModalNewPassword}
        onClose={() => onShowModalForgotPassword(false)}
        classNames={{ modal: "modal-disconnect", closeButton: "modal-disconnect-close_button" }}
      >
        {isResetPasswordExpired ? (
          <div>
            <Title>
              Récupération de mot de passe
            </Title>
            <Text>
              Ce lien de récupération a expiré ou n'est pas valide.
              <br />
              Veuillez faire une nouvelle demande de mot de passe.
            </Text>
            <button
              className="a-button a-button-red"
              type="button"
              onClick={onRetryAllRecoveryProcess}
            >
              Faire une nouvelle demande
            </button>
          </div>
        ) : (
          <form onSubmit={onSubmitNewPassword} autoComplete="off">
            <Title>
              {isRegisterForm ? "Choisissez votre mot de passe" : "Réinitialiser le mot de passe"}
            </Title>
            <Text>
              {isRegisterForm ? "Entrez un nouveau mot de passe ci-dessous." : "Entrez votre nouveau mot de passe ci-dessous."}
              <br />
              <span>
                {`Notes : Il doit contenir au minimum ${PASSWORD_MIN_LENGTH} caractères, une majuscule, une minuscule, un chiffre, et un caractère spécial.`}
              </span>
            </Text>
            <FieldPassword
              className="input-password-complexity"
              name="newPassword"
              label="Nouveau mot de passe"
              value={newPassword}
              onChange={onInputChange}
              type="password"
              passwordStrength={passwordStrength}
              passwordStrengthLabel={PASSWORD_STRENGTH_LABELS}
              autoComplete="off"
            />
            {passwordError &&
              <ErrorText>
                {passwordError}
              </ErrorText>
            }
            <FieldText
              name="newPasswordConfirm"
              label="Confirmation mot de passe"
              value={newPasswordConfirm}
              onChange={onInputChange}
              type="password"
              autoComplete="off"
            />
            <LaddaButton
              className="a-button a-button-green"
              loading={false}
              data-color="#eee"
              data-style={SLIDE_UP}
              data-spinner-size={30}
              data-spinner-color="#fff"
              data-spinner-lines={12}
            >
              {isRegisterForm ? "Valider" : "Réinitialiser mon mot de passe."}
            </LaddaButton>
          </form>
        )}
      </ModalCustom>
    </>
  );
};

export default LoginForm;
