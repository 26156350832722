import styled from "styled-components";
import ButtonCpt from '../../../../components/molecules/buttonCpt';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  position: sticky;
  z-index: 99;
  top: 0;
  background-color: #ffffff;
  height: 78px;
  padding: 20px;
  border-bottom: 1px solid #efefef;
`;

export const AllFeedbackIconWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 13px;
  height: 13px;
`;

export const CompetenceFeedbackIconWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 11px;
  height: 15px;
  margin-top: -2px;
`;

export const OldFeedbackIconWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 12px;
  height: 14px;
`;

export const SearchIconWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 12px;
  height: 14px;
`;

export const DateIconWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 12px;
  height: 14px;
`;

export const FiltreIconWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 12px;
  height: 14px;
`;

export const ButtonCptAllFeedback = styled(ButtonCpt)`
  &&{
    border-radius: 6px 0 0 6px;
    &:hover,
    &.isActive{
      background-color: #2768B3;
      svg path{
        fill: #FFFFFF;
      }
      .MuiButton-label{
        span span{
          color: #ffffff;
        }
      }
    }
    .MuiButton-label{
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 18px;
    }
  }
`;

export const ButtonCptCompetenceFeedback = styled(ButtonCpt)`
  &&{
    border-radius: 0;
    border-left: 1px solid #EDEDED;
    &:hover,
    &.isActive{
      background-color: #2768B3;
      svg path{
        fill: #FFFFFF;
      }
      .MuiButton-label{
        span span{
          color: #ffffff;
        }
      }
    }
    .MuiButton-label{
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 18px;
    }
  }
`;

export const ButtonCptOldFeedback = styled(ButtonCpt)`
  &&{
    border-radius: 0 6px 6px 0;
    border-left: 1px solid #EDEDED;
    &:hover,
    &.isActive{
      background-color: #2768B3;
      svg path{
        fill: #FFFFFF;
      }
      .MuiButton-label{
        span span{
          color: #ffffff;
        }
      }
    }
    .MuiButton-label{
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 18px;
    }
  }
`;

export const ButtonCptSearch = styled(ButtonCpt)`
  &&{
    border-radius: 6px;
    &:hover{
      svg path{
        fill: #FFFFFF;
      }
    }
  }
`;

export const ButtonCptDate = styled(ButtonCpt)`
  &&{
    border-radius: 6px;
    margin-left: 10px;
    &:hover{
      svg path{
        fill: #FFFFFF;
      }
    }
  }
`;

export const ButtonCptFiltre = styled(ButtonCpt)`
  &&{
    border-radius: 6px;
    margin-left: 10px;
    &:hover{
      svg path{
        fill: #FFFFFF;
      }
    }
  }
`;

export const WrapperContent = styled.div`
  display: flex;
`;

export const Title = styled.h1`
  margin: 0 0 8px 0;
  color: #5A5A5A;
  font-size: 25px;
  line-height: 18px;
  font-weight: 700;
  text-align: left;
`;

export const Text = styled.p`
  margin: 0;
  color: #BBBBBB;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const Toolbar = styled.div`
  display: flex;
`;
