import axios from 'axios';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';

export const saveCalendarObjectService = (endpoint, userToken, formData, userId) => {
  const startDateTime = format(new Date(`${formData.startDate.replace(/-/g, "/")} ${formData.startTime}`), "yyyy-MM-dd HH:mm:ss", { locale: fr });
  const endDateTime = format(new Date(`${formData.endDate.replace(/-/g, "/")} ${formData.endTime}`), "yyyy-MM-dd HH:mm:ss", { locale: fr });
  const newForm = new FormData();
  newForm.append('title', formData.title);
  newForm.append('isAllDay', formData.allDay.length > 0 ? 1 : 0);
  newForm.append('start', startDateTime);
  newForm.append('startTz', 'GMT+02:00');
  newForm.append('end', endDateTime);
  newForm.append('endTz', 'GMT+02:00');
  newForm.append('description', formData.note);
  newForm.append('type', formData.type);
  newForm.append('event', {});
  newForm.append('task', {});
  newForm.append('creator', userId);
  formData?.ticketId && newForm.append('ticket', Number(formData.ticketId));

  // console.log('newForm', ...newForm);
  // console.log('formData', formData);
  const headers = {};
  if (userToken) {
    headers.Authorization = `Bearer ${userToken}`;
  }

  return axios.request({
    method: 'post',
    url: endpoint,
    data: newForm,
    headers: headers
  })
}

export const deleteCalendarObjectService = async (endpoint, userToken) => {
  const headers = {};
  if (userToken) {
    headers.Authorization = `Bearer ${userToken}`;
  }

  return axios.request({
    method: 'delete',
    url: endpoint,
    headers: headers
  });
}