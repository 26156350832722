export const actionGenerator = (value) => ({
  REQUEST: `CONTEXT_SETTING/${value}/REQUEST`,
  SUCCESS: `CONTEXT_SETTING/${value}/SUCCESS`,
  FAILURE: `CONTEXT_SETTING/${value}/FAILURE`,
})

export const GET_CONTEXTS = actionGenerator('GET_CONTEXTS');
export const SAVE_CONTEXTS = actionGenerator('SAVE_CONTEXTS');

export const getContexts = () => ({
  type: GET_CONTEXTS.REQUEST,
});

export const getContextsSuccess = (data) => ({
  type: GET_CONTEXTS.SUCCESS,
  data
});

export const getContextsError = (error) => ({
  type: GET_CONTEXTS.FAILURE,
  error
});

export const saveContexts = (data) => ({
  type: SAVE_CONTEXTS.REQUEST,
  data,
});

export const saveContextsSuccess = (data) => ({
  type: SAVE_CONTEXTS.SUCCESS,
  data
});

export const saveContextsError = (error) => ({
  type: SAVE_CONTEXTS.FAILURE,
  error
});

export const actions = {
  GET_CONTEXTS,
  SAVE_CONTEXTS,
}