import styled from "styled-components";
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import GenericPaginationArrow from "../../../../components/molecules/GenericPaginationArrow";

export const Container = styled.div`
  flex: 1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const StyledButton = styled(Button)`
  && {
    &.MuiButton-root {
      color: #FFFFFF;
      font-size: 0.8rem;
      font-weight: 500;
      background-color: #357ED3;
      padding: 10px;
      transition: all ease-in .15s;
      border: transparent;
      border-radius: 5px;
      background-image: none;
      text-transform: none;
      &:hover {
        background-color: #155EB3;
      }
    }
  }
`;

export const TableButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TableIcon = styled(IconButton)`
  &&{
    height: fit-content;
    .MuiSvgIcon-root {
      font-size: 1.2rem;
    }
  }
`;

export const SitesListContainer = styled.div`
  overflow: hidden;
  white-space: nowrap;
  width: 150px;
  text-overflow: "…";
  cursor: pointer;
`;

export const EmptySitesList = styled.div`
`;

export const SettingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  min-height: 50px;
`;

export const StyledPaginationArrow = styled(GenericPaginationArrow)`
  position: absolute;
  right: 0;
  margin: 0px 10px;
  width: 20%;
`;