import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
`;

export const BarChartBg = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  background-color: ${props => props.colorBg};
  min-width: 100px;
  width: 100%;
  max-width: ${props => props.maxWidthSize + 'px' || '100%'};
  min-height: 2px;
  height: ${props => props.heightSize + 'px'};
  border-radius: 100px;
  overflow: hidden;
  transition: all .2s ease-out;
`;

export const BarChartValue = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: ${props => props.colorValue};
  width: ${props => props.valueBar + '%'};
  max-width: 100%;
  transition: all .2s ease-out;
`;

export const TextUp = styled.span`
  font-size: 10px;
  font-weight: 500;
  line-height: 10px;
  color: ${props => props.colorTextUp};
  margin: 0 0 5px 0;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TextInside = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  font-size: 8px;
  font-weight: 500;
  line-height: 10px;
  color: ${props => props.colorTextInside};
  margin: 0 5px 0 0;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TextDown = styled.span`
  font-size: 10px;
  font-weight: 500;
  line-height: 10px;
  color: ${props => props.colorTextDown};
  margin: 5px 0 0 0;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
