import React from 'react';

// Styles Theme Hooks
import useTheme from "../../../../theme-style/useThemeCustomHook";

// Icons
import NotificationIcon from '../../../../components/atoms/icons/notification';

// Import Style
import {
  Container,
  WrapperContent,
  Title,
  Text,
  // Toolbar,
  // ButtonCptNotification,
  // NotificationIconWrapper
} from './style';

const ContentHeader = (props) => {
  const {
    skillTitle,
    skillDesc,
  } = props;
  // const theme = useTheme();

  return (
    <Container>
      <WrapperContent>
        <Title>
          {skillTitle ? skillTitle : "NaN"}
        </Title>
        <Text>
          {skillDesc ?
            skillDesc.replace(/(<([^>]+)>)/ig, '') : ""}
        </Text>
      </WrapperContent>
      {/*<Toolbar>
        <ButtonCptNotification
          appearence="secondary"
          startIcon={
            <NotificationIconWrapper>
              <NotificationIcon color={theme.colors ? theme.colors.grey7 : ""} />
            </NotificationIconWrapper>
          }
        />
      </Toolbar>*/}
    </Container>
  );
}

export default ContentHeader;
