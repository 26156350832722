import React, { useState, useEffect, useLayoutEffect } from 'react';
import {
  shallowEqual,
  useDispatch,
  useSelector,
} from "react-redux";
import { useParams } from "react-router-dom";
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import {
  format,
  isValid,
  parse,
  isBefore,
  isWeekend
} from 'date-fns';
import fr from 'date-fns/locale/fr';
import CancelIcon from '@material-ui/icons/Cancel';


import BookingCalendar from './components/BookingCalendar';
import RoomInfo from './components/RoomInfo';

import { paginateArray } from '../../helpers/arrayUtils';

import {
  getUserRooms,
  getBookingsByDate,
} from '../../store/roomBooking/actions';

import {
  getCorporations,
} from '../../store/concierge/actions';

import {
  getUser
} from '../../store/collaborator/selectors';

import {
  getCorporationsData,
  getCorporationsIsLoading,
} from '../../store/concierge/selectors';

import {
  getUserRoomData,
  getUserRoomDataIsLoading,
  getBookingData,
  getBookingDataIsLoading,
} from '../../store/roomBooking/selectors';

import {
  CustomDialog,
  DialogContainer,
  StyledCloseContainer,
  StyledTitle,
  StyledTopContainer,
  StyledDayPickerContainer,
  StyledRoomInfoContainer
} from './style';

const today = new Date();

const disabledDays = [
  {
    dayOfWeek: [0, 6]
  },
  {
    before: today
  }
];

const BookingCalendarModal = ({
  onClose
}) => {
  const desktopPageSize = 4;
  const mobilePageSize = 1;
  const dispatch = useDispatch();
  const { date } = useParams();
  const [pageSize, setPageSize] = useState(desktopPageSize);
  const [selectedDay, setSelectedDay] = useState(null);
  const [currentRoomData, setCurrentRoomData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [maxPageNumber, setMaxPageNumber] = useState(1);


  const {
    userData,
    roomData,
    roomDataIsLoading,
    bookingData,
    bookingDataIsLoading,
    corporationData,
    corporationDataIsLoading,
  } = useSelector(
    state => ({
      userData: getUser(state),
      roomData: getUserRoomData(state),
      roomDataIsLoading: getUserRoomDataIsLoading(state),
      bookingData: getBookingData(state),
      bookingDataIsLoading: getBookingDataIsLoading(state),
      corporationData: getCorporationsData(state),
      corporationDataIsLoading: getCorporationsIsLoading(state),
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!roomData) {
      dispatch(getUserRooms());
    }
    if (!corporationData) {
      dispatch(getCorporations());
    }
  }, []);

  useEffect(() => {
    if (selectedDay) {
      const queryDate = format(new Date(selectedDay), "yyyy-MM-dd", { locale: fr });
      dispatch(getBookingsByDate({ date: queryDate }));
    }
  }, [selectedDay]);

  // console.log('selectedDay', selectedDay);

  useLayoutEffect(() => {
    const updateSize = () => {
      
      if (window.innerWidth <= 600) {
        setPageSize(mobilePageSize);
      }
      else {
        setPageSize(desktopPageSize);
      }
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    let dateAccepted = false;
    if (date) {
      const splittedDate = date.split('-');
      const parsedDate = parse(`${splittedDate[2]}/${splittedDate[1]}/${splittedDate[0]}`, 'P', new Date(), { locale: fr });
      const isValidDate = isValid(parsedDate);
      
      if (isValidDate) {
        const selectedDate = new Date(date);
        const isBeforeDate = isBefore(selectedDate, today);
        const isWeekendDate = isWeekend(selectedDate);

        if (!isBeforeDate && !isWeekendDate) {
          dateAccepted = true;
          setSelectedDay(selectedDate);
        }
      }
    }

    if (dateAccepted === false) {
      setSelectedDay(today);
    }
  }, [date]);

  useEffect(() => {
    if (roomData) {
      const totalPage = Math.ceil(roomData.length / pageSize);
      setMaxPageNumber(totalPage);
    }
  }, [pageSize, roomData]);

  useEffect(() => {
    if (roomData) {
      const newRoomData = paginateArray(roomData, pageSize, pageNumber);
      setCurrentRoomData(newRoomData);
    }
  }, [pageSize, roomData, pageNumber]);

  const handlePageChange = (index) => {
    const newPageNumber = pageNumber + index;
    if (newPageNumber === 0 || newPageNumber > maxPageNumber) return;
    setPageNumber(newPageNumber);
  }

  const handleDaySelect = (day) => {
    if (day) {
      setSelectedDay(day);
    }
  };

  return (
    <CustomDialog
      open={true}
      onClose={onClose}
    >
      {selectedDay &&

        <DialogContainer>
          <StyledCloseContainer onClick={onClose}>
            <CancelIcon style={{ fontSize: 30 }} />
          </StyledCloseContainer>
          <StyledTitle>{`Réserver une salle de réunion - ${userData?.company_site.name}`}</StyledTitle>
          <StyledTopContainer>
            <StyledDayPickerContainer>
              <DayPicker
                disabled={disabledDays}
                mode="single"
                locale={fr}
                selected={selectedDay}
                onSelect={handleDaySelect}
              />
            </StyledDayPickerContainer>
            <StyledRoomInfoContainer>
              <RoomInfo
                roomData={currentRoomData}
                handlePageChange={handlePageChange}
                pageNumber={pageNumber}
                maxPageNumber={maxPageNumber}
                roomDataIsLoading={roomDataIsLoading}
              />
            </StyledRoomInfoContainer>
          </StyledTopContainer>
          <BookingCalendar
            selectedDay={selectedDay}
            roomData={roomData}
            roomDataIsLoading={roomDataIsLoading}
            bookingData={bookingData}
            bookingDataIsLoading={bookingDataIsLoading}
            corporationData={corporationData}
            corporationDataIsLoading={corporationDataIsLoading}
          />
        </DialogContainer>
      }
    </CustomDialog>
  );
};

export default BookingCalendarModal;