import React from "react";

const MetroIcon = ({
  color = "#5A5A5A",
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon metroIcon"
      width={width}
      height={height}
      viewBox="0 0 16 20"
      fill="none"
    >
      <path
        d="M9.788 0c.59 0 1.111.376 1.298.935l.47 1.409h1.92c.97 0 1.758.788 1.758 1.758v10.937a1.76 1.76 0 01-1.757 1.758h-.224l1.177 2.355a.586.586 0 11-1.048.524l-.268-.535H2.12l-.268.535a.586.586 0 11-1.048-.524l1.178-2.355h-.224A1.76 1.76 0 010 15.039V4.102c0-.97.789-1.758 1.758-1.758h1.921l.47-1.41C4.335.377 4.856 0 5.446 0zm2.155 16.797H3.292l-.586 1.172h9.823l-.586-1.172zm2.12-4.688H1.171v2.93c0 .323.263.586.586.586h11.719a.587.587 0 00.585-.586v-2.93zM9.57 13.281a.586.586 0 010 1.172H5.664a.586.586 0 010-1.172zm2.735 0a.586.586 0 110 1.172.586.586 0 010-1.172zm-9.375 0a.586.586 0 110 1.172.586.586 0 010-1.172zM14.063 5.86H1.171v5.079h12.89V5.859zm-.586-2.343H1.757a.587.587 0 00-.585.586v.585h12.89v-.585a.587.587 0 00-.585-.586zM9.788 1.172H5.446a.195.195 0 00-.185.133l-.346 1.039h5.405l-.346-1.039a.195.195 0 00-.186-.133z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export default MetroIcon;
