import React from 'react';
import {
  shallowEqual,
  useSelector,
} from "react-redux";

import GenericLayout from '../../layouts/genericLayout';
import GenericAdminFrontHeader from '../../../../components/molecules/GenericAdminFrontHeader';

import Routes from './routes';
import SiteSettings from './components/SiteSettings';
import CompanySettings from './components/CompanySettings';

import {
  getConciergeSitesData,
  getConciergeSitesIsLoading
} from '../../../../store/concierge/selectors';

import {
  getCompanyData,
  getCompanyIsLoading
} from '../../../../store/company/selectors';

import {
  getContextsData,
  getContextsIsLoading
} from '../../../../store/contextSetting/selectors';

import {
  MainContainer,
  ContentContainer,
} from './style';

const Settings = () => {

  const {
    sitesData,
    sitesIsLoading,
    companyData,
    companyIsLoading,
    contextsData,
    contextsIsLoading
  } = useSelector(
    state => ({
      sitesData: getConciergeSitesData(state),
      sitesIsLoading: getConciergeSitesIsLoading(state),
      companyData: getCompanyData(state),
      companyIsLoading: getCompanyIsLoading(state),
      contextsData: getContextsData(state),
      contextsIsLoading: getContextsIsLoading(state)
    }),
    shallowEqual
  );

  return (
    <GenericLayout
      id="pageSettingLayout"
      className="pageSettingLayout"
    >
      <MainContainer>
        <GenericAdminFrontHeader
          headerTitle={'Company and site settings'}
          headerDesc={`Company and site settings`}
        />
        <ContentContainer>
          <CompanySettings
            companyData={companyData}
            companyIsLoading={companyIsLoading}
          />
          <SiteSettings
            contextsData={contextsData}
            contextsIsLoading={contextsIsLoading}
            sitesData={sitesData}
            sitesIsLoading={sitesIsLoading}
          />
        </ContentContainer>
      </MainContainer>
      <Routes />
    </GenericLayout>
  );
}

export default Settings;