export const RESET_ONBOARDING = "ONBOARDING/RESET";

export const SAVE_ONBOARDING_CGU = {
  REQUEST: 'ONBOARDING/SAVE_CGU/REQUEST',
  SUCCESS: 'ONBOARDING/SAVE_CGU/SUCCESS',
  FAILURE: 'ONBOARDING/SAVE_CGU/FAILURE',
};

export const SAVE_ONBOARDING_ONE = {
  REQUEST: 'ONBOARDING/SAVE_ONE/REQUEST',
  SUCCESS: 'ONBOARDING/SAVE_ONE/SUCCESS',
  FAILURE: 'ONBOARDING/SAVE_ONE/FAILURE',
};

export const SAVE_ONBOARDING_THREE = {
  REQUEST: 'ONBOARDING/SAVE_THREE/REQUEST',
  SUCCESS: 'ONBOARDING/SAVE_THREE/SUCCESS',
  FAILURE: 'ONBOARDING/SAVE_THREE/FAILURE',
};

export const SAVE_ONBOARDING_FOUR = {
  REQUEST: 'ONBOARDING/SAVE_FOUR/REQUEST',
  SUCCESS: 'ONBOARDING/SAVE_FOUR/SUCCESS',
  FAILURE: 'ONBOARDING/SAVE_FOUR/FAILURE',
};

export const GET_CORPORATION = {
  REQUEST: 'ONBOARDING/GET_CORPORATION/REQUEST',
  SUCCESS: 'ONBOARDING/GET_CORPORATION/SUCCESS',
  FAILURE: 'ONBOARDING/GET_CORPORATION/FAILURE',
};

/**
 * RESET
 */
export const resetOnBoarding = () => ({
  type: RESET_ONBOARDING,
});

/**
 * Save step CGU
 */
 export const saveOnBoardingCgu = (acceptTermsDate, company, corporation) => ({
  type: SAVE_ONBOARDING_CGU.REQUEST,
  acceptTermsDate,
  company,
  corporation
});

export const saveOnBoardingCguSuccess = (data) => ({
  type: SAVE_ONBOARDING_CGU.SUCCESS,
  data,
});

export const saveOnBoardingCguError = (error) => ({
  type: SAVE_ONBOARDING_CGU.FAILURE,
  error,
});

/**
 * Save step One
 */
 export const saveOnBoardingOne = ({gender, age}) => ({
  type: SAVE_ONBOARDING_ONE.REQUEST,
  gender,
  age
});

export const saveOnBoardingOneSuccess = (data) => ({
  type: SAVE_ONBOARDING_ONE.SUCCESS,
  data,
});

export const saveOnBoardingOneError = (error) => ({
  type: SAVE_ONBOARDING_ONE.FAILURE,
  error,
});

/**
 * Save step Three
 */
 export const saveOnBoardingThree = (
  address, lat, lng, officeAddress,
  officeLat, officeLng, transport,
) => ({
  type: SAVE_ONBOARDING_THREE.REQUEST,
  address,
  lat,
  lng,
  officeAddress,
  officeLat,
  officeLng,
  transport,
});

export const saveOnBoardingThreeSuccess = (data) => ({
  type: SAVE_ONBOARDING_THREE.SUCCESS,
  data,
});

export const saveOnBoardingThreeError = (error) => ({
  type: SAVE_ONBOARDING_THREE.FAILURE,
  error,
});

/**
 * Save step Four
 */
 export const saveOnBoardingFour = () => ({
  type: SAVE_ONBOARDING_FOUR.REQUEST,
});

export const saveOnBoardingFourSuccess = (data) => ({
  type: SAVE_ONBOARDING_FOUR.SUCCESS,
  data,
});

export const saveOnBoardingFourError = (error) => ({
  type: SAVE_ONBOARDING_FOUR.FAILURE,
  error,
});

export const getCorporation = () => ({
  type: GET_CORPORATION.REQUEST,
});

export const getCorporationSuccess = (data) => ({
  type: GET_CORPORATION.SUCCESS,
  data,
});

export const getCorporationError = (error) => ({
  type: GET_CORPORATION.FAILURE,
  error,
});

