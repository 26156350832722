import { takeLatest } from 'redux-saga/effects';
import { actions } from './actions';

//Import handelers
import { getCorporationsHandler } from './handler/corporationHandler';
import { getSitesHandler } from './handler/sitesHandler';
import { getConciergesHandler, editConciergeHandler } from './handler/conciergesHandler'
import { getProvidersHandler, saveProviderHandler, deleteProviderHandler } from './handler/providersHandler'
import { getServicesHandler } from './handler/servicesHandler';
import { getServiceActifsHandler, editServiceActifHandler } from './handler/serviceActifsHandler';
import { getTicketRequestsHandler, saveTicketRequestHandler } from './handler/ticketRequestsHandler';
import { saveTicketMessageHandler, deleteTicketMessageHandler } from './handler/ticketMessageHandler';
import { getCalendarObjectsHandler, saveCalendarObjectHandler, deleteCalendarObjectHandler } from './handler/calendarObjectsHandler';
import { getInternalServiceFormHandler, getInternalServiceFormSchemaHandler } from './handler/internalServiceFormHandler';


export default function* concierge() {
  yield takeLatest(actions.GET_CORPORATIONS.REQUEST, getCorporationsHandler);
  yield takeLatest(actions.GET_SITES.REQUEST, getSitesHandler);
  yield takeLatest(actions.GET_CONCIERGES.REQUEST, getConciergesHandler);
  yield takeLatest(actions.UPDATE_CONCIERGE.REQUEST, editConciergeHandler);
  yield takeLatest(actions.GET_PROVIDERS.REQUEST, getProvidersHandler);
  yield takeLatest(actions.SAVE_PROVIDER.REQUEST, saveProviderHandler);
  yield takeLatest(actions.DELETE_PROVIDER.REQUEST, deleteProviderHandler);
  yield takeLatest(actions.GET_SERVICES.REQUEST, getServicesHandler);
  yield takeLatest(actions.GET_SERVICE_ACTIFS.REQUEST, getServiceActifsHandler);
  yield takeLatest(actions.UPDATE_SERVICE_ACTIF.REQUEST, editServiceActifHandler);
  yield takeLatest(actions.GET_TICKET_REQUESTS.REQUEST, getTicketRequestsHandler);
  yield takeLatest(actions.SAVE_TICKET_REQUESTS.REQUEST, saveTicketRequestHandler);
  yield takeLatest(actions.SAVE_TICKET_MESSAGE.REQUEST, saveTicketMessageHandler);
  yield takeLatest(actions.DELETE_TICKET_MESSAGE.REQUEST, deleteTicketMessageHandler);
  yield takeLatest(actions.GET_CALENDAR_OBJECTS.REQUEST, getCalendarObjectsHandler);
  yield takeLatest(actions.SAVE_CALENDAR_OBJECT.REQUEST, saveCalendarObjectHandler);
  yield takeLatest(actions.GET_INTERNAL_SERVICE_FORM.REQUEST, getInternalServiceFormHandler);
  yield takeLatest(actions.GET_INTERNAL_SERVICE_FORM_SCHEMA.REQUEST, getInternalServiceFormSchemaHandler);
  yield takeLatest(actions.DELETE_CALENDAR_OBJECT.REQUEST, deleteCalendarObjectHandler);
}
