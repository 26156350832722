import React from 'react';
import { 
	Switch, 
	Route, 
	Redirect,
  useLocation,
  useRouteMatch,
} from "react-router-dom";

import Step0 from './components/Step0';
import Step1 from './components/Step1';
import Step3 from './components/Step3';
import Step4 from './components/Step4';
import Step5 from './components/Step5';
import StepCGU from './components/StepCGU';

const Routes = () => {
  const location = useLocation();
  const match = useRouteMatch();
	
  return (
    <Switch location={location}>
      <Route exact path={`${match.url}/`} component={Step0} />
      <Route exact path={`${match.url}/CGU`} component={StepCGU} />
      <Route exact path={`${match.url}/1`} component={Step1} />
      <Route exact path={`${match.url}/3`} component={Step3} />
      <Route exact path={`${match.url}/4`} component={Step4} />
      <Route exact path={`${match.url}/5`} component={Step5} />
      {/*Temporary removed step children informations*/}
      <Redirect exact from={`${match.url}/2`} to={`${match.url}/3`} />
    </Switch>
	);
};

export default Routes;
