import styled from "styled-components";
import breakpoints from "../../../../theme-style/breakpoints";

export const Container = styled.div`
  width: 100%;
  height: auto;
  min-height: calc(100vh - 110px);
  background-color: #357ED3;
  padding: 15px;
  box-sizing: border-box;
  >:not(:last-child) {
    margin-bottom: 1.5rem;
  };
  @media ${breakpoints.mobileXL} {
    padding: 25px;
  }
`;
