import React from 'react';

// Components
import FeedbackUntreatedTypeStats from '../components/feedbackUntreatedTypeStats';
import FeedbackUntreatedCompetenceStats from '../components/feedbackUntreatedCompetenceStats';
import TableAllFeedbackUntreated from '../components/tableAllFeedbackUntreated';

// Styles
import {
  Container,
  HeadWrapper,
  TableWrapper
} from "./style";

const AllFeedBack = (props) => {
    return (
      <Container>

        <HeadWrapper>
          <FeedbackUntreatedTypeStats />
          <FeedbackUntreatedCompetenceStats />
        </HeadWrapper>

        <TableWrapper>
          <TableAllFeedbackUntreated />
        </TableWrapper>
      </Container>
    );
}

export default AllFeedBack;
