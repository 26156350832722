import React from 'react';

// Icons
import ChatCommunicationIllustration from '../../../../../../../components/atoms/illustrations/chatCommunicationIllustration';

// Styles
import {
  Container,
  Title,
  Content,
  FeedbackTypeList,
  FeedbackTypeItem,
  FeedbackTypeIllustration,
  ChatCommunicationIllustrationWrapper
} from "./feedsBanner.style";

const FeedbackUntreatedTypeStats = ({ feedsStats, feedsList, selectedSite }) => {
  const totalFeedback = feedsStats?.total || 0;
  const totalFeedbackSiteContext = feedsStats?.company || 0;
  const totalFeedbackOtherSite = feedsStats?.companySites || 0;

  return (
    <Container>
      <Title>
        Les remarques de mes collaborateurs
      </Title>
      <Content>
        <FeedbackTypeList>
          <FeedbackTypeItem>
            <span></span>
            <strong>{totalFeedback}</strong>
            <p>non traitée(s) <strong>au total</strong></p>
          </FeedbackTypeItem>
          <FeedbackTypeItem>
            <span></span>
            <strong>{!!feedsList?.length 
            && feedsList.filter(fd => fd.company_site_id == (selectedSite?.id || null))
              .length ?
              feedsList.filter(fd => fd.company_site_id == (selectedSite?.id || null) && !fd.isProcessed)
                .length : 0}</strong>
            <p>pour <strong>{selectedSite?.name}</strong></p>
          </FeedbackTypeItem>
          {/* <FeedbackTypeItem>
            <span></span>
            <strong>{totalFeedbackOtherSite
                }</strong>
            <p>remarques pour les autres sites</p>
          </FeedbackTypeItem> */}
        </FeedbackTypeList>
        <FeedbackTypeIllustration>
          <ChatCommunicationIllustrationWrapper>
            <ChatCommunicationIllustration />
          </ChatCommunicationIllustrationWrapper>
        </FeedbackTypeIllustration>
      </Content>
    </Container>
  );
}

export default FeedbackUntreatedTypeStats;
