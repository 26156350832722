import React from "react";

const UserIcon = ({
  color = "#2B2B2B",
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon userIcon"
      width={width}
      height={height}
      viewBox="0 0 10 10"
      fill="none"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M7.36923077,5.21153846 C8.93461538,6.05769231 10,7.71538462 10,9.61538462 C10,9.82692308 9.82692308,10 9.61538462,10 L9.61538462,10 L0.384615385,10 C0.173076923,10 0,9.82692308 0,9.61538462 C0,7.71538462 1.06538462,6.05769231 2.63076923,5.21153846 C3.25,5.79615385 4.08461538,6.15384615 5,6.15384615 C5.91538462,6.15384615 6.75,5.79615385 7.36923077,5.21153846 Z M5,8.39328607e-13 C6.48461538,8.39328607e-13 7.69230769,1.20769231 7.69230769,2.69230769 C7.69230769,4.13884615 6.43923077,5.38461538 5,5.38461538 C4.41153846,5.38461538 3.86538462,5.19615385 3.42307692,4.87307692 C2.74615385,4.38461538 2.30769231,3.58846154 2.30769231,2.69230769 C2.30769231,1.20769231 3.51538462,8.39328607e-13 5,8.39328607e-13 Z" 
          id="userIcon"
          fill={color}
          fillRule="nonzero"
        >
        </path>
      </g>
    </svg>
  );
};

export default UserIcon;
