import React, {useEffect, useState} from 'react';
import {
  shallowEqual,
  useDispatch,
  useSelector,
} from "react-redux";

// Import services
// import getTreatedFbStats from '../../services/getTreatedFeedbackStats.service';
import {
  getTreatedFeedbackStatsData,
} from '../../../../../../store/adminFront/selectors';
import {
  getTreatedFeedbackStats,
} from '../../../../../../store/adminFront/actions';

// Components
import DonutChart from '../../../../components/molecules/donutChart';

// Icons
import ArchiveFolderIllustration from '../../../../components/atoms/illustrations/archiveFolderIllustration';

// Styles
import {
  Container,
  Title,
  Content,
  OldFeedbackTypeList,
  DonutChartType,
  OldFeedbackTypeIllustration,
  ArchiveFolderIllustrationWrapper
} from "./style";

const OldFeedbackTreatedTypeStats = () => {
  const dispatch = useDispatch();
  // const [untreatedFeedbackStats, setUntreatedFeedbackStats] = useState([]);

  // useEffect(() => {
  //     getTreatedFbStats().then(data => setUntreatedFeedbackStats(data));
  // }, []);

  const {
    untreatedFeedbackStats,
  } = useSelector(
    state => ({
      untreatedFeedbackStats: getTreatedFeedbackStatsData(state),
    }),
    shallowEqual
  );

  useEffect(() => {
      dispatch(getTreatedFeedbackStats());
  }, []);

  // console.log('untreatedFeedbackStats', untreatedFeedbackStats);
  const totalFeedback  = untreatedFeedbackStats?.total;
  const incompleteFeedback = untreatedFeedbackStats?.incompleteStatus;
  const wrongFeedback = untreatedFeedbackStats?.wrongStatus;
  const hsFeedback = untreatedFeedbackStats?.hsStatus;

  return (
    <Container>
      <Title>
        Ancienne(s) remarque(s) des collaborateurs
      </Title>
      <Content>

        <OldFeedbackTypeList>
          <DonutChart
            value={totalFeedback}
            globalValue={totalFeedback}
            size={56}
            strokeWidth={8}
            colorText={'#ffffff'}
            strokeColorBg={'rgba(0,0,0,0.1)'}
            strokeColorValue={'#357ED3'}
            valueLabelOutside={'au total'}
          />

          <DonutChartType
            value={incompleteFeedback}
            globalValue={totalFeedback}
            size={42}
            strokeWidth={6}
            colorText={'#ffffff'}
            strokeColorBg={'rgba(0,0,0,0.1)'}
            strokeColorValue={'#40B176'}
            valueLabelOutside={'Incomplet'}
          />
          <DonutChartType
            value={wrongFeedback}
            globalValue={totalFeedback}
            size={42}
            strokeWidth={6}
            colorText={'#ffffff'}
            strokeColorBg={'rgba(0,0,0,0.1)'}
            strokeColorValue={'#FF9F6D'}
            valueLabelOutside={'Incorrect'}
          />
          <DonutChartType
            value={hsFeedback}
            globalValue={totalFeedback}
            size={42}
            strokeWidth={6}
            colorText={'#ffffff'}
            strokeColorBg={'rgba(0,0,0,0.1)'}
            strokeColorValue={'#2B2B2B'}
            valueLabelOutside={'Hors sujet'}
          />
        </OldFeedbackTypeList>

        <OldFeedbackTypeIllustration>
          <ArchiveFolderIllustrationWrapper>
            <ArchiveFolderIllustration />
          </ArchiveFolderIllustrationWrapper>
        </OldFeedbackTypeIllustration>
      </Content>
    </Container>
  );
}

export default OldFeedbackTreatedTypeStats;
