import React, { useEffect } from 'react';
import {
  shallowEqual,
  useDispatch,
  useSelector,
} from "react-redux";
import {
  useRouteMatch,
} from "react-router-dom";
import SubjectIcon from '@material-ui/icons/Subject';

import Loader from '../../../../../components/molecules/GenericLoader';

import LotusIcon from '../../atoms/icons/lotus';
import ArrowRightIcon from '../../atoms/icons/arrowRight';
import useTheme from "../../../../../theme-style/useThemeCustomHook";

import {
  getServiceCategoryList,
} from '../../../../../store/sidebar/actions';

import {
  getServiceCategoryListData,
  getServiceCategoryListIsLoading
} from '../../../../../store/sidebar/selectors';

import {
  MainContainer,
  StyledTitleWithToolTip,
  ServicesWrapper,
  ServicesHead,
  ServicesHeadCircle,
  LotusIconWrapper,
  ServicesHeadTitle,
  ServicesList,
  ServicesItem,
  ServicesItemPicture,
  PictureBox,
  ServicesItemContent,
  ServicesItemTitle,
  ServicesItemText,
  ArrowIconWrapper,
  LoaderContainer
} from './style';

const ConciergeServiceModule = () => {
  const theme = useTheme();
  const { path } = useRouteMatch();
  const dispatch = useDispatch();

  const {
    serviceCategoryListData,
    serviceCategoryListIsLoading,
  } = useSelector(
    state => ({
      serviceCategoryListData: getServiceCategoryListData(state),
      serviceCategoryListIsLoading: getServiceCategoryListIsLoading(state),
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!serviceCategoryListData) {
      dispatch(getServiceCategoryList());
    }
  }, []);

  return (
    <MainContainer>
      <StyledTitleWithToolTip
        title={"Mes services de conciergerie"}
        toolTipText={"Retrouvez la liste des services et suivez vos commandes."}
      />
      <ServicesWrapper>
        <ServicesHead>
          <ServicesHeadCircle>
            <LotusIconWrapper>
              <LotusIcon color={theme.colors.white} />
            </LotusIconWrapper>
          </ServicesHeadCircle>
          <ServicesHeadTitle>
            Les services
          </ServicesHeadTitle>
        </ServicesHead>

        <ServicesList>
          {serviceCategoryListIsLoading ?
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
            :
            serviceCategoryListData && Object.keys(serviceCategoryListData).map((item, index) => {
              const {
                category_id,
                description,
                thumbnail,
              } = serviceCategoryListData[item];

              return (
                <ServicesItem key={index} to={`${path}/services/liste/${category_id}`}>
                  <ServicesItemPicture>
                    {thumbnail
                      ? <PictureBox style={{ backgroundImage: `url("${thumbnail}")` }} />
                      : <SubjectIcon />
                    }
                  </ServicesItemPicture>
                  <ServicesItemContent>
                    <ServicesItemTitle>
                      {item}
                    </ServicesItemTitle>
                    <ServicesItemText>
                      {description}
                    </ServicesItemText>
                  </ServicesItemContent>
                  <ArrowIconWrapper>
                    <ArrowRightIcon color={theme.colors.grey4} />
                  </ArrowIconWrapper>
                </ServicesItem>
              )
            })
          }
        </ServicesList>
      </ServicesWrapper>
    </MainContainer>
  );
};

export default ConciergeServiceModule;