import React from "react";
import { PropTypes } from 'prop-types';

const FieldText = props => {
    let required = props.required ? true : false;
    let Icon = props.icon ? props.icon : null;

    return (
        <div className={"input-group " + props.className}>
            <label htmlFor={props.name}>{props.label} {props.note ? <span className="small">{props.note}</span> : null}</label>
            <div className="input-group__input-wrapper">
                <input
                    className={props.icon ? "with-icon" : ""}
                    required={required}
                    type={props.type || "text"}
                    name={props.name}
                    value={props.value}
                    onChange={props.onChange}
                    placeholder={props.placeholder}
                    autoComplete={props.autocomplete !== undefined ? props.autocomplete : ""}
                />
                {props.icon ? <Icon /> : null}
            </div>

            {props.alert && <div className="errorMsg errorMsg__active">{props.alertMessage}</div>}
        </div>
    );
}

FieldText.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    alert: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func,
}

FieldText.defaulProps = {
    type: "text"
}

export const FieldTextLight = props => {
    let required = props.required ? true : false;
    let Icon = props.icon ? props.icon : null;
    return (
        <div className={"input-group--light " + props.className}>
            <label htmlFor={props.name}>{props.label} {props.note ? <span className="small">{props.note}</span> : null}</label>
            <div className="input-group__input-wrapper">
                <input className={props.icon ? "with-icon" : ""} required={required} type={props.type || "text"} name={props.name} value={props.value} onChange={props.onChange} placeholder={props.placeholder} autoComplete={props.autocomplete !== undefined ? props.autocomplete : ""} />
                {props.icon ? <Icon /> : null}
            </div>
            {props.alert && <div className="errorMsg errorMsg__active">{props.alertMessage}</div>}
        </div>
    );
}


class FieldPassword extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            passwordStrength: props.passwordStrength,
            passwordStrengthLabel: props.passwordStrengthLabel
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            passwordStrength: nextProps.passwordStrength,
            passwordStrengthLabel: nextProps.passwordStrengthLabel
        })
    }


    render() {
        let required = this.props.required ? true : false;
        return (
            <div className={"input-group " + this.props.className}>
                <label htmlFor={this.props.name}>{this.props.label} {this.props.note ? <span className="small">{this.props.note}</span> : null} </label>
                <input required={required} type={this.props.type || "text"} name={this.props.name} value={this.props.value} onChange={this.props.onChange} placeholder={this.props.placeholder} autoComplete={this.props.autocomplete} />
                {this.props.alert && <div className="errorMsg errorMsg__active">{this.props.alertMessage}</div>}
                {!!this.props.value?.length && (
                    <div className={this.props.className + "-meter"}>
                        <meter max="5" value={this.state.passwordStrength + 1} className="a-password-meter"></meter>
                        <p className="a-password-indicator">Votre mot de passe est : {this.state.passwordStrengthLabel[this.state.passwordStrength]}</p>
                    </div>
                )}

            </div>
        );
    }

}

FieldPassword.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    alert: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func,
}

FieldPassword.defaulProps = {
    type: "text"
}

export {
    FieldText,
    FieldPassword
};