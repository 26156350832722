import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Divider from '@material-ui/core/Divider';

import NewsContainer from '../../../../components/organisms/NewsContainer';
import bannerImage from '../../../../assets/images/news-1.png';


import {
  MainContainer,
  PreviewHeaderTitle,
  PreviewHeader,
  PreviewHeaderContent,
  PreviewHeaderContentElement,
  PreviewHeaderContentImg,
  PreviewHeaderContentFakeImg,
  PreviewHeaderContentTitle,
  PreviewHeaderContentDate,
  PreviewHeaderContentFakeDate,
  PreviewHeaderContenFakeTitle,
  PreviewBody,
} from './style';

const Preview = (props) => {
  const {
    contentTitle,
    publicationDate,
    link,
    choosenFile,
    dealValue,
    description = null,
    content = null,
  } = props
  const [newsData, setNewsData] = useState({});

  const getImage = (file) => {
    if (file) {
      if (typeof file !== 'string') {
        try {
          return URL.createObjectURL(file);
        }
        catch (e) {
          return bannerImage;
        }
      }
      else return file;
    }
  }

  useEffect(() => {
    const currentNewsData = {
      ...newsData,
      title: contentTitle,
      link: link,
      description: description,
      deal_value: dealValue,
      content: content,
    }

    setNewsData(currentNewsData);
  }, [props]);

  useEffect(() => {
    setNewsData((prev) => ({
      ...prev,
      banner: choosenFile ? getImage(choosenFile) : '',
    }));
  }, [props.choosenFile]);

  return (
    <MainContainer>
      <PreviewHeaderTitle>Aperçu vignette (tableau de bord de vos collaborateurs) </PreviewHeaderTitle>
      <PreviewHeader>

        <PreviewHeaderContent>
          <PreviewHeaderContentElement top>
            <PreviewHeaderContentFakeImg top />
            <PreviewHeaderContenFakeTitle />
            <PreviewHeaderContentFakeDate />
          </PreviewHeaderContentElement>
          <Divider style={{ background: '#F7F7F7' }} />
          <PreviewHeaderContentElement>
            {choosenFile !== null ?
              <PreviewHeaderContentImg src={getImage(choosenFile) || bannerImage} />
              :
              <PreviewHeaderContentFakeImg />
            }
            <PreviewHeaderContentTitle>{contentTitle}</PreviewHeaderContentTitle>
            {publicationDate != "" ?
              <PreviewHeaderContentDate>{moment(new Date(publicationDate)).format("DD/MM/YYYY")}</PreviewHeaderContentDate>
              :
              <PreviewHeaderContentFakeDate />
            }
          </PreviewHeaderContentElement>
          <Divider style={{ background: '#F7F7F7' }} />
          <PreviewHeaderContentElement bottom>
            <PreviewHeaderContentFakeImg bottom />
            <PreviewHeaderContenFakeTitle />
            <PreviewHeaderContentFakeDate />
          </PreviewHeaderContentElement>
        </PreviewHeaderContent>
      </PreviewHeader>
      <PreviewHeaderTitle>Aperçu de la fenêtre d’affichage (version mobile)</PreviewHeaderTitle>
      <PreviewBody>
        <NewsContainer newsData={newsData} isPreview={true} />
      </PreviewBody>
    </MainContainer>
  )
}

export default Preview;
