import React from "react";

const GearIcon = ({
  color = "#ffffff",
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon gearIcon"
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M25.422 10.544h-.594a11.307 11.307 0 00-.729-1.756l.421-.42a2.578 2.578 0 000-3.646L23.28 3.48a2.578 2.578 0 00-3.646 0l-.421.42a11.314 11.314 0 00-1.756-.728v-.594A2.58 2.58 0 0014.88 0H13.12a2.58 2.58 0 00-2.577 2.578v.594c-.605.193-1.192.437-1.756.729l-.42-.421a2.577 2.577 0 00-3.646 0L3.48 4.72a2.578 2.578 0 000 3.646l.42.421a11.305 11.305 0 00-.728 1.756h-.594A2.58 2.58 0 000 13.12v1.758a2.58 2.58 0 002.578 2.577h.594c.193.605.437 1.192.729 1.756l-.421.42a2.578 2.578 0 000 3.646L4.72 24.52a2.578 2.578 0 003.646 0l.421-.42c.564.291 1.151.535 1.756.728v.594A2.58 2.58 0 0013.12 28h1.758a2.58 2.58 0 002.577-2.578v-.594a11.304 11.304 0 001.756-.729l.42.421a2.577 2.577 0 003.646 0l1.242-1.241a2.578 2.578 0 000-3.646l-.42-.422c.291-.564.535-1.15.728-1.755h.594A2.58 2.58 0 0028 14.88V13.12a2.58 2.58 0 00-2.578-2.577zM14 20.092A6.1 6.1 0 017.908 14 6.1 6.1 0 0114 7.908 6.1 6.1 0 0120.092 14 6.1 6.1 0 0114 20.092z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export default GearIcon;
