import { select, call, put } from 'redux-saga/effects';

import endpoint from '../../../helpers/api';
import apiCall from '../../apiCall';

import {
  getUserRoomsSuccess,
  getUserRoomsError
} from '../actions';

import * as authenticationSelectors from '../../authentication/selectors';

export function* getUserRoomsHandler() {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const userRooms = yield call(apiCall, endpoint.getUserRooms, userToken);
    yield put(getUserRoomsSuccess(userRooms));
  } catch (err) {
    yield put(getUserRoomsError(err));
  }
}
