import {
  all,
  put,
  takeEvery,
  call,
  select,
  take,
} from "redux-saga/effects";
import endpoint from '../../helpers/api';
import apiCall from '../apiCall';
import { authHeader } from '../../helpers/auth-header';

/** Actions */
import * as a from './actions';
import * as collaboratorActions from "../collaborator/actions";
import * as collaboratorSelectors from "../collaborator/selectors";
import * as authenticationSelectors from '../authentication/selectors';
import { getCompanyContext } from "../company/actions";

async function postAPICall(endpoint, data) {
  return fetch(endpoint, {
    method: 'POST',
    headers: authHeader(),
    body: data
  })
  .then(res => res.json())
  .catch(err => err);
}

function* saveCGUSaga({ acceptTermsDate, company, corporation }) {
  try {
    const formData = new FormData();
    formData.append('acceptTermsDate', acceptTermsDate);
    formData.append('companySite', company);
    if (corporation !== null) {
      formData.append('corporation', corporation);
    }
    const data = yield call(postAPICall, endpoint.onboardingStep0, formData);
    yield put(getCompanyContext());
    yield put(a.saveOnBoardingCguSuccess(data));
  } catch(error) {
    yield put(a.saveOnBoardingCguError(error));
  }
}

function* saveOneSaga({ gender, age }) {
  try {
    const formData = new FormData();
    formData.append("gender", gender === "no" ? null : gender);
		formData.append("age", age === "empty" ? null : age);
    const data = yield call(postAPICall, endpoint.onboardingStep1, formData);
    if (!!data?.code) {
      yield put(a.saveOnBoardingOneError(data));  
    } else {
      yield put(collaboratorActions.updateUserLocalInfos(data));
      yield put(a.saveOnBoardingOneSuccess(data));
    }
    
    // yield delay(100);
    // yield put(a.resetSaveUserProfile());
  } catch(error) {
    yield put(a.saveOnBoardingOneError(error));
    // yield delay(100);
    // yield put(a.resetSaveUserProfile());
  }
}

function* saveThreeSaga({
  address,
  lat,
  lng,
  officeAddress,
  officeLat,
  officeLng,
  transport,
}) {
  try {
    const formData = new FormData();
    formData.append("address", address);
    formData.append("lat", lat);
    formData.append("lng", lng);
    formData.append("officeAddress", officeAddress);
    formData.append("officeLat", officeLat);
    formData.append("officeLng", officeLng);
    formData.append("transport", JSON.stringify(transport));
    const data = yield call(postAPICall, endpoint.onboardingStep3, formData);
    if (!!data?.code) {
      yield put(a.saveOnBoardingThreeError(data));  
    } else {
      yield put(collaboratorActions.updateUserLocalInfos(data));
      yield put(a.saveOnBoardingThreeSuccess(data));
    }
  } catch(error) {
    yield put(a.saveOnBoardingThreeError(error));
  }
}

function* saveFourSaga() {
  try {
    const formData = new FormData();
		formData.append("userInterest", {});
    const data = yield call(postAPICall, endpoint.onboardingStep4, formData);
    if (!!data?.code) {
      yield put(a.saveOnBoardingFourError(data));  
    } else {
      yield put(collaboratorActions.updateUserLocalInfos(data));
      yield put(a.saveOnBoardingFourSuccess(data));
    }
  } catch(error) {
    yield put(a.saveOnBoardingFourError(error));
  }
}

function* getCorporationSaga() {
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const objects = yield call(apiCall, endpoint.getAllCorporations, userToken);
    yield put(a.getCorporationSuccess(objects));
  } catch (err) {
    yield put(a.getCorporationError(err));
  }
}


export default function* onboardingRootSaga() {
  yield all([
    takeEvery(a.SAVE_ONBOARDING_CGU.REQUEST, saveCGUSaga),
    takeEvery(a.SAVE_ONBOARDING_ONE.REQUEST, saveOneSaga),
    takeEvery(a.SAVE_ONBOARDING_THREE.REQUEST, saveThreeSaga),
    takeEvery(a.SAVE_ONBOARDING_FOUR.REQUEST, saveFourSaga),
    takeEvery(a.GET_CORPORATION.REQUEST, getCorporationSaga),
  ]);
}
