import React from 'react';

import {
  MainContainer,
  StyledTitle,
  StyledValue
} from './style'

const GenericTitleWithValue = ({
  title = "",
  value = "",
  className, // to pass styled component className to mainContainer
  ...rest
}) => {
  return (
    <MainContainer className={`${className ? className : ''} genericTitleWithValue`} {...rest}>
      <StyledTitle className={`gtwv-title`}>
        {title}
      </StyledTitle>
      <StyledValue className={`gtwv-value`}>
        {value}
      </StyledValue>
    </MainContainer>
  )
}

export default GenericTitleWithValue