import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

//Import Components
import TableRequestHeader from '../TableRequestHeader';
import CardRequest from '../CardRequest';
import TicketFilter from '../TicketFilter';


import {
  ticketStatusArray,
  ticketStatusConstant
} from '../../../../../../constants/ticketConstant';

import {
  Container,
  TicketCardContainer,
  CustomDragDropContext,
  TableColumn,
  CustomColumn
} from './style'

const headerList = _.map(ticketStatusArray, (ticketStatus) => ({
  id: ticketStatus,
  label: ticketStatusConstant[ticketStatus],
  itemList: []
}));

const CurentRequestsTable = ({
  currentTicketRequests,
  currentSites,
  servicesData,
  onRequestClick
}) => {
  const [curentHeaderList, setCurentHeaderList] = useState([]);
  const initFilterConditions = {
    services: [],
    collaborator: "",
    email: "",
  };
  const [filterConditions, setFilterConditions] = useState(initFilterConditions);

  useEffect(() => {
    const newHeaderList = _.cloneDeep(headerList);
    _.map(newHeaderList, (header) => {
      _.map(currentTicketRequests, (item) => {
        if (header.id === item.status && currentSites.find((site) => site.value === item.company_site.id) && checkFilterConditions(filterConditions, item)) {
          header.itemList.push(item);
        }
      })
    })
    setCurentHeaderList(newHeaderList);
  }, [currentTicketRequests, currentSites, filterConditions]);

  const checkServiceFilter = (form, item) => {
    return !!form.services.find((value) => value.value === item.service_id)
  }

  const checkNameFilter = (form, item) => {
    const search = _.toLower(_.deburr(form.collaborator));
    const name = `${item.collaborator.firstname} ${item.collaborator.lastname}`;

    return _.toLower(_.deburr(name)).includes(search);
  }

  const checkEmailFilter = (form, item) => {
    const search = _.toLower(_.deburr(form.email));

    return _.toLower(_.deburr(item.collaborator.email)).includes(search);
  }

  const checkFilterConditions = (form, item) => {
    const conditionCheckArray = [];

    if (form.services.length !== 0)
      conditionCheckArray.push(checkServiceFilter(form, item))
    if (form.collaborator)
      conditionCheckArray.push(checkNameFilter(form, item))
    if (form.email)
      conditionCheckArray.push(checkEmailFilter(form, item))

    if (conditionCheckArray.length > 0) {
      return !conditionCheckArray.includes(false);
    }
    return true;
  }

  return (
    <Container>
      <TicketFilter
        servicesData={servicesData}
        currentSites={currentSites}
        initFilterConditions={initFilterConditions}
        setFilterConditions={(form) => setFilterConditions(form)}
      />
      <TicketCardContainer>
        <CustomDragDropContext onDragEnd={(result) => console.log('drop result =', result)}>
          {curentHeaderList.map((item, index) => {
            return (
              <Droppable droppableId={item.id} key={index} >
                {(provider, snapshot) => {
                  return (
                    <CustomColumn>
                      <TableRequestHeader title={item.label} ticketNumber={item.itemList.length} />
                      <TableColumn
                        {...provider.droppableProps}
                        ref={provider.innerRef}
                      /*   style={{
                          background: snapshot.isDraggingOver ? 'lightblue' : 'lightgrey',
                          padding: 4,
                          width: 222,
                          minHeight: 500,
                          margin: 10
                        }} */
                      >
                        {_.map(item.itemList, (value, index) => {
                          return value.status === item.id ?
                            <CardRequest data={value} key={index} servicesData={servicesData} onClick={() => onRequestClick(value.id)} />
                            :
                            null
                        })}
                      </TableColumn>
                    </CustomColumn>
                  )
                }}
              </Droppable>
            )
          })}
        </CustomDragDropContext>
      </TicketCardContainer>
    </Container >
  )
}

export default CurentRequestsTable
