import styled from "styled-components";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';

import { COLORS } from '../../../../../../../../../../constants/design'

export const StyledAccordion = styled(Accordion)`
&& {
  &.MuiPaper-elevation1 {
    box-shadow: unset;
  }
  &.MuiAccordion-root {
    background-color: ${COLORS.YELLOW_1};
    color: ${COLORS.DARK_GREY};
    ::before {
      background-color: unset;
    }
  }
  &.MuiAccordion-rounded{
    border-radius: 6px;
  }
  .MuiAccordionSummary-expandIcon .MuiSvgIcon-root {
    color: ${COLORS.DARK_GREY};
  }
  .MuiAccordionDetails-root {
    flex-direction: column;
  }
}
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
&& {
  .MuiAccordionSummary-content {
    display: flex;
    align-items: center;
    >div:not(:last-child) {
      margin-right: 12px;
    };
  }
}
`;

export const CircleDiv = styled.div`
  background-color: ${COLORS.WHITE};
  border-radius: 50%;
  display: flex;
  height: 25px;
  width: 25px;
  justify-content: center;
  align-items: center;
  color: ${COLORS.LIGHT_GREY_4};
`;

export const StyledAccordionTitle = styled.div`
  font-weight: 700;
  font-size: 0.9rem;
`;

export const StyledUserInfoContainer = styled.div`
  font-size: 0.75rem;
  >div:not(:last-child) {
    margin-bottom: 5px;
  };
  padding: 0px 5px;
  .genericTitleWithIcon {
    >div:not(:last-child) {
      margin-right: 20px;
    };
  }
`;