import { call, put, select } from 'redux-saga/effects';
import endpoint from '../../../helpers/api';
import apiCall from '../../apiCall';
import * as authenticationSelectors from '../../authentication/selectors';
import { toaster } from '../../../helpers/toaster';

import {
  editNewsContentService,
  deleteNewsContentService
} from '../../../services/newsContentService';

import {
  getNewsContentSuccess,
  getNewsContentError,
  updateNewsContentSuccess,
  updateNewsContentError,
  deleteNewsContentSuccess,
  deleteNewsContentError,
  getNewsContentByIdSuccess,
  getNewsContentByIdError,
} from '../actions';

export function* getNewsContentHandler(action) {
  const { limit = 1, pageNumber = 1 } = action.data;
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const newsList = yield call(apiCall, endpoint.getNewsContentList(limit, pageNumber), userToken);
    yield put(getNewsContentSuccess(newsList));
  } catch (error) {
    yield put(getNewsContentError(error));
  }
}

export function* updateNewsContentHandler(action) {
  const { id, formData } = action.data;
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const endPointUrl = id !== 'new' ? endpoint.editNewsContent(id) : endpoint.saveNewsContent;
    const response = yield call(editNewsContentService, endPointUrl, userToken, formData);
    yield put(updateNewsContentSuccess(response.data));
    toaster('Vos changements ont été enregistrés', 'success');
  } catch (err) {
    yield put(updateNewsContentError(err));
    toaster(`Une erreur s'est produite. Veuillez réessayer`, 'error');
  }
}

export function* deleteNewsContentHandler(action) {
  const id = action.data;
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    yield call(deleteNewsContentService, endpoint.deleteNewsContent(id), userToken);
    yield put(deleteNewsContentSuccess(id));
    toaster('Le contenu a bien été supprimé', 'success');
  } catch (err) {
    yield put(deleteNewsContentError(err));
    toaster(`Une erreur s'est produite. Veuillez réessayer`, 'error');
  }
}

export function* getNewsContentByIdHandler(action) {
  const { id } = action.data;
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const newContent = yield call(apiCall, endpoint.getNewsContentById(id), userToken);
    yield put(getNewsContentByIdSuccess(newContent));
  } catch (error) {
    yield put(getNewsContentByIdError(error));
  }
}