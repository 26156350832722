import { actions } from './actions';

const INITIAL_STATE = {
  ticketRequestSelectedSite: {
    data: null,
  },
  siteManagementSelectedSite: {
    data: null,
  },
  consoleTabSwitch: {
    data: null,
  }
}

export function persistReducers(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case actions.SAVE_TICKET_REQUEST_SELECTED_SITE_LIST.REQUEST:
      return {
        ...state,
        ticketRequestSelectedSite: {
          data: action.data,
        },
      };
    case actions.SAVE_SITE_MANAGEMENT_SELECTED_SITE_LIST.REQUEST:
      return {
        ...state,
        siteManagementSelectedSite: {
          data: action.data,
        },
      };
    case actions.SAVE_CONSOLE_TAB_SWITCH.REQUEST:
      return {
        ...state,
        consoleTabSwitch: {
          data: action.data,
        },
      };
    default:
      return state;
  }
};

export const migrations = {
  0: state => INITIAL_STATE,
  1: (previousVersionState) => ({
    persistReducers: {
      ticketRequestSelectedSite: previousVersionState.persistReducers.ticketRequestSelectedSite,
      siteManagementSelectedSite: previousVersionState.persistReducers.siteManagementSelectedSite,
      consoleTabSwitch: INITIAL_STATE.consoleTabSwitch,
      lastUpdate: new Date()
    }
  })
}

export default persistReducers;