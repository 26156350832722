import React from "react";

// Import Style
import {
  Container,
  CustomTab,
  CustomTabs,
} from './style';

const ContentBar = ({ onChange }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <Container>
      <CustomTabs
        value={value}
        onChange={handleChange}
        TabIndicatorProps={{
          style: {
            display: 'none',
          }
        }}
      >
        <CustomTab label="Tableau des réservations" />
        <CustomTab label="Tableau des options" />
      </CustomTabs>
    </Container>
  )
}

export default ContentBar