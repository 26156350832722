import React from "react";

function GoodDealLinkIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      viewBox="0 0 13 13"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#999" fillRule="nonzero" transform="translate(-1378 -1142)">
          <g transform="translate(740 134)">
            <g transform="translate(30 964)">
              <g transform="translate(0 27)">
                <path d="M614.896 21.488c.355.161.679.388.954.662a3.42 3.42 0 01.82 3.513l-.01.004a3.384 3.384 0 01-.81 1.333l-1.998 1.997a3.433 3.433 0 01-4.85 0 3.433 3.433 0 010-4.85L611 22.15c.139-.138.292-.258.451-.37a4.207 4.207 0 00.147 1.928l-1.518 1.518a1.906 1.906 0 002.695 2.694l1.997-1.998a1.906 1.906 0 000-2.694 1.141 1.141 0 010-1.616zm.888 6.793a.38.38 0 01.467.27l.197.736a.38.38 0 11-.736.197l-.197-.736a.381.381 0 01.27-.467zm2.22-1.281a.38.38 0 01.54 0l.538.539a.38.38 0 11-.538.538l-.54-.538a.38.38 0 010-.54zm-2.856-8.997a3.433 3.433 0 014.85 0 3.433 3.433 0 010 4.85l-1.993 1.991a3.301 3.301 0 01-.451.37 4.207 4.207 0 00-.147-1.927l1.513-1.513a1.906 1.906 0 00-2.695-2.694l-1.992 1.993a1.906 1.906 0 000 2.694c.447.447.447 1.17 0 1.616l-.13.13a3.42 3.42 0 01-1.768-4.181l.01-.004c.181-.542.463-.985.81-1.333zm4.53 6.494l.075.013.737.197a.38.38 0 11-.198.736l-.736-.197a.381.381 0 01.197-.736zm-11.041-2.958l.076.013.736.197a.381.381 0 11-.197.736l-.737-.197a.381.381 0 01.198-.736zm1.285-2.621a.38.38 0 01.54 0l.538.538a.38.38 0 11-.539.54l-.539-.54a.38.38 0 010-.538zm2.904-1.677a.38.38 0 01.467.27l.197.736a.38.38 0 11-.736.197l-.197-.736a.381.381 0 01.269-.467z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default GoodDealLinkIcon;