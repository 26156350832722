import styled from "styled-components";
import { COLORS } from '../../../../../../constants/design'


export const Container = styled.div`
  position: sticky;
  top: 99px;
  z-index: 5;
  width: 100%;
  height: 50px;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  &:after,
  &:before{
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 20px;
    background-color: #ffffff;
  }
  &:after{
    top: calc(100% - 10px);
  }
  &:before{
    bottom: calc(100% - 10px);
  }
`;

export const Content = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  background-color: ${COLORS.SLIGHT_GREY_2};
`;

export const Title = styled.span`
  color: ${COLORS.DARK_GREY};
  font-size: 14px;
  font-weight: 700;
`;

export const TicketNumber = styled.span`
  color: ${COLORS.DARK_GREY};
  font-size: 12px;
  font-weight: 500;
`;