import {
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

// Containers
import Dashboard from '../containers/Dashboard';
import Conciergerie from '../containers/Conciergerie';
import ConciergerieBorneLogin from '../containers/ConciergerieBorneLogin';
import ConciergerieBorneAccount from '../containers/ConciergerieBorneAccount';
import ConciergerieServicesPrestations from '../containers/ConciergerieServicesPrestations';
import ConciergerieServicesList from '../containers/ConciergerieServicesList';
import Competences from '../containers/Competences';
import CompetencesList from '../containers/CompetencesList';

import Knowledge from '../containers/CompetencesHelpers/Knowledge';
import Exchange from '../containers/CompetencesHelpers/Exchange';
import Practice from '../containers/CompetencesHelpers/Practice';

import Account from '../containers/Account';
import AccountInformations from '../containers/AccountInformations';
import AccountProfil from '../containers/AccountProfil';
import AccountTrajets from '../containers/AccountTrajets';

import Admin from "../containers/Admin";

import { ContextRoute } from '../../../routes/ContextRoute';

const RoutesApp = () => {

  return (
    <>
      <Switch>
        <ContextRoute path="/dashboard" component={Dashboard} />
        <ContextRoute exact path="/conciergerie" component={Conciergerie} />
        <ContextRoute exact path="/conciergerie/borne/ma-borne" component={ConciergerieBorneAccount} />
        <ContextRoute exact path="/conciergerie/borne/connexion" component={ConciergerieBorneLogin} />
        <ContextRoute path="/conciergerie/services/prestations/:ticketId?" component={ConciergerieServicesPrestations} />
        <ContextRoute path="/conciergerie/services/liste/:id" component={ConciergerieServicesList} />
        <ContextRoute exact path="/competences" component={Competences} />
        <ContextRoute path="/competences/aide/connaissance" component={Knowledge} />
        <ContextRoute path="/competences/aide/echange" component={Exchange} />
        <ContextRoute path="/competences/aide/pratique" component={Practice} />
        <ContextRoute exact path="/*/thematique/:id" component={CompetencesList} />
        <ContextRoute exact path="/compte" component={Account} />
        <ContextRoute path="/compte/informations" component={AccountInformations} />
        <ContextRoute path="/compte/profil" component={AccountProfil} />
        <ContextRoute path="/compte/trajets" component={AccountTrajets} />
        <ContextRoute path="/my-admin" component={Admin} />
        <Redirect from="/" to="/landing" />
      </Switch>
    </>
  );
};

export default RoutesApp;
