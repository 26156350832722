import React from "react";

// Layout
import BaseLayout from "../base";

// Import component
import SideNavbar from '../../components/organisms/sideNavbar/';

// Style
import { PageContent } from "./style";

// General editor
import SwipeableTrainingEditor from '../../containers/TrainingCyco/components/EditTraining';

const FeedsBaseLayout = ({ children, ...rest }) => {
  return (
    <BaseLayout {...rest}>
      <PageContent>
        <SideNavbar />
        {children}
      </PageContent>
      <SwipeableTrainingEditor />
    </BaseLayout>
  );
};

export default FeedsBaseLayout;
