import { call, put, select } from 'redux-saga/effects'
import {
  getSpecificCaseError,
  getSpecificCaseSuccess,
  editSpecificCaseSuccess,
  editSpecificCaseError,
  deleteSpecificCaseSuccess,
  deleteSpecificCaseError
} from '../actions';
import endpoint from '../../../helpers/api';
import apiCall from '../../apiCall';
import * as authenticationSelectors from '../../authentication/selectors';
import { editSpecificCaseService, deleteSpecificCaseService } from '../../../services/specificCaseService';
import { toaster } from '../../../helpers/toaster';

export function* getSpecificCaseHandler(action) {
  const {
    limit = 5,
    pageNumber = 1,
  } = action.data;
  const url = `${endpoint.getSpecificCaseList}?page=${pageNumber}&limit=${limit}`;

  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const specificCase = yield call(apiCall, url, userToken);
    yield put(getSpecificCaseSuccess(specificCase));
  } catch (err) {
    yield put(getSpecificCaseError(err));
  }
}

export function* editSpecificCaseHandler(action) {
  const { id, formData } = action.data;
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    const endPointUrl = id !== 'new' ? endpoint.editSpecificCase(id) : endpoint.saveSpecificCase;
    const response = yield call(editSpecificCaseService, endPointUrl, userToken, formData);
    yield put(editSpecificCaseSuccess(response.data));
    toaster('Vos changements ont été enregistrés', 'success');
  } catch (err) {
    yield put(editSpecificCaseError(err));
    toaster(`Une erreur s'est produite. Veuillez réessayer`, 'error');
  }
}

export function* deleteSpecificCaseHandler(action) {
  const id = action.data;
  try {
    const userToken = yield select(authenticationSelectors.getUserToken);
    yield call(deleteSpecificCaseService, endpoint.deleteSpecificCase(id), userToken);
    yield put(deleteSpecificCaseSuccess(id));
    toaster('Cet élément est supprimé', 'success');
  } catch (err) {
    yield put(deleteSpecificCaseError(err));
    toaster(`Une erreur s'est produite. Veuillez réessayer`, 'error');
  }
}