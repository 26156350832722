import styled from "styled-components";
import DonutChart from '../../../../components/molecules/donutChart';

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  /* min-width: 450px; */
  width: 100%;
  max-width: 52%;
  height: 100%;
  min-height: 170px;
  max-height: 21vh;
  padding: 20px;
  border-radius: 6px;
  background-color: #EC6B87;
  margin: 10px;
`;

export const Title = styled.h3`
  margin: 0 0 auto 0;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const DonutChartType = styled(DonutChart)`
  &&{
    .donutchart-text-val{

    }
    .donutchart-text-percent{
      font-size: 12px;
    }
    .donutchart-text-label{
      font-size: 12px;
    }
    .donutchart-text-label-outside{

    }
  }
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const ArchiveFolderIllustrationWrapper = styled.span`
  display: flex;
  width: auto;
  max-width: 110px;
`;

export const OldFeedbackTypeList = styled.div`
  display: flex;
  width: 65%;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0;
  padding: 0;
  & > div{
    margin: 0 30px 0 0;
    /* &:first-child{
      margin: 0 40px 0 0;
    } */
  }
`;

export const OldFeedbackTypeIllustration = styled.div`
  width: 35%;
  display: inline-flex;
  justify-content: flex-end;
`;
