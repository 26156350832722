import React from "react";
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useHistory,
  withRouter
} from "react-router-dom";

// Containers
import AllFeedBack from './allFeedback';
import CompetenceFeedBack from './competenceFeedback';
import OldFeedBack from './oldFeedback';

const Routes = () => {
  const history = useHistory();
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/all-feedback`} component={AllFeedBack} />
      <Route exact path={`${path}/competence-feedback`} component={CompetenceFeedBack} />
      <Route exact path={`${path}/old-feedback`} component={OldFeedBack} />
      <Redirect to={`${path}/all-feedback`} />
    </Switch>
  );
}

export default withRouter(Routes);
