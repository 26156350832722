import React, { useState, useEffect } from 'react'
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-withfix';
import ImageCompress from 'quill-image-compress';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import GenericTitleWithToolTip from '../../../../components/molecules/GenericTitleWithToolTip';

import {
  CommentBoxDiv,
  InputContainer,
  StyledCustomToolbar,
  StyledTooltip,
} from './style';

Quill.register('modules/ImageResize', ImageResize);
Quill.register('modules/imageCompress', ImageCompress);

const modules = {
  ImageResize: {
    modules: ['Resize', 'DisplaySize'],
  },
  imageCompress: {
    quality: 0.7,
    maxWidth: 1000,
    maxHeight: 1000,
    imageType: 'image/jpeg',
    debug: false,
    suppressErrorLogging: false,
  },
  toolbar: {
    container: [["bold", "italic", "underline"], [{ "color": [] }], ["link"], [{ list: "bullet" }, { list: "ordered" }]]
  },
};

const CommentBox = ({
  value,
  onChange,
  onOpenModal,
  isModalMode = false,
  disabled
}) => {
  const [currentValue, setCurrentValue] = useState();

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);
  
  const handleOnChange = (value) => {
    setCurrentValue(value);
  }

  const handelOnBlur = () => {
    onChange(currentValue);
  }


  return (
    <CommentBoxDiv disabled={disabled} isModalMode={isModalMode}>
      {!isModalMode &&
        <GenericTitleWithToolTip
          title={"Message de votre contenu"}
          isRequired={true}
          className={'textFieldTitle'}
        />
      }
      <InputContainer isModalMode={isModalMode} onBlur={handelOnBlur}>
        <StyledCustomToolbar>
          {!isModalMode &&
            <StyledTooltip title={'Ouvrir dans un modale'}>
              <OpenInNewIcon onClick={() => onOpenModal(true)} style={{ fontSize: 18 }} />
            </StyledTooltip>
          }
        </StyledCustomToolbar>
        <ReactQuill
          placeholder='Saisissez votre contenu ici'
          theme="snow"
          modules={modules}
          value={currentValue}
          onChange={(e) => handleOnChange(e)}
        />
      </InputContainer>
    </CommentBoxDiv >
  )
}

export default CommentBox;
