import React from 'react';

import CloseIcon from '../../atoms/icons/close';
import WashIcon from '../../atoms/icons/wash';
import WifiIcon from '../../atoms/icons/wifi';
import BorneIcon from '../../atoms/icons/borne';
import AllIcon from '../../atoms/icons/all';
import BurstModeIcon from '@material-ui/icons/BurstMode';

// Styles
import {
  SwipeableDrawerCustom,
  DrawerBorneInfoPicture,
  ButtonCptClose,
  CloseIconWrapper,
  DrawerBorneInfoContent,
  Title,
  // Subtitle,
  List,
  Item,
  AllIconWrapper,
  WifiIconWrapper,
  BorneIconWrapper,
  WashIconWrapper,
  PictureBox
} from "./style";

const DrawerBorneInfo = ({
  anchor,
  visible = false,
  closeFunction,
  openFunction
}) => {
  const image = null;

  return (
    <SwipeableDrawerCustom
      transitionDuration={200}
      anchor={anchor ? anchor : "right"}
      onOpen={(e) => null || openFunction}
      open={visible}
      onClose={closeFunction}
    >
      <DrawerBorneInfoPicture>

        {image
          ? <PictureBox style={{backgroundImage: `url("${image}")`}}/>
          : <BurstModeIcon fontSize="large"/>
        }

        <ButtonCptClose
          appearence="sidebarFrontConciergerie"
          onClick={closeFunction}
          endIcon={
            <CloseIconWrapper>
              <CloseIcon />
            </CloseIconWrapper>
          }
        />
      </DrawerBorneInfoPicture>

      <DrawerBorneInfoContent>
        <Title>
          Ma Borne - Service de conciergerie connecté
        </Title>
        <List>
          <Item className="allItem">
            <AllIconWrapper>
              <AllIcon />
            </AllIconWrapper>
            <span>Tous les services de pressing, blanchisserie, retouches, cordonnerie, réception de colis, en partenariat avec des acteurs locaux et éco-responsables, réunis en une seule borne.</span>
          </Item>
          <Item className="wifiItem">
            <WifiIconWrapper>
              <WifiIcon />
            </WifiIconWrapper>
            <span>Connectez-vous sur l'écran de votre conciergerie connectée avec votre numéro de téléphone et votre code envoyé par SMS.</span>
          </Item>
          <Item className="borneItem">
            <BorneIconWrapper>
              <BorneIcon />
            </BorneIconWrapper>
            <span>Choisissez vos services et articles, puis déposez votre linge dans le casier qui s'ouvre comme par magie.</span>
          </Item>
          <Item className="washItem">
            <WashIconWrapper>
              <WashIcon />
            </WashIconWrapper>
            <span>Vaquez à vos occupations, vous recevrez un SMS lorsque vos articles sont prêts à être récupérés.</span>
          </Item>
        </List>
      </DrawerBorneInfoContent>
    </SwipeableDrawerCustom>
  );
}

export default DrawerBorneInfo;
