import React, { useState, useEffect } from 'react';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import EditIcon from '@material-ui/icons/Edit';
import ShareIcon from '@material-ui/icons/Share';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';

import { Formik, Field } from 'formik';
import { shallowEqual, useSelector } from "react-redux";
import _ from 'lodash';

import { jsonSafeParse } from '../../../../../../../../../../helpers/jsonSafeParse';
import { ticketStatusArray, finalTicketStatus } from '../../../../../../../../../../constants/ticketConstant';

import GenericTextField from '../../../../../../../../../../components/molecules/GenericTextField';
import GenericTextArea from '../../../../../../../../../../components/molecules/GenericTextArea';
import GenericMaterialMenu from '../../../../../../../../../../components/molecules/GenericMaterialMenu';
import GoogleSuggestAndHeader from '../../../../../../../../../../components/molecules/GoogleSuggestAndHeader';

import {
  getConciergeServicesData,
} from '../../../../../../../../../../store/concierge/selectors';

import {
  StyledAccordion,
  StyledAccordionSummary,
  CircleDiv,
  StyledAccordionTitle,
  StyledForm,
  StyledMissionSummaryContainer,
  StyledSummarySection,
  StyledSummaryText,
  StyledSummaryBoldText,
  StyledSummaryButtonContainer,
  StyledButton
} from './style';

const TicketSummaryInfo = ({ ticketRequest, onTicketSave }) => {
  const [editMode, setEditMode] = useState(false);
  const [missionSheet, setMissionSheet] = useState(null);
  // const [servicesList, setServicesList] = useState([]);
  // const [summaryDisplay, setSummaryDisplay] = useState({});
  // const {
  //   servicesData,
  // } = useSelector(
  //   state => ({
  //     servicesData: getConciergeServicesData(state),
  //   }),
  //   shallowEqual
  // );

  useEffect(() => {
    if (ticketRequest) {
      setMissionSheet(jsonSafeParse(ticketRequest.mission_sheet));
    }
  }, [ticketRequest]);

  const generateInitValue = () => {
    let initValue = {}
    if (missionSheet) {
      _.map(missionSheet, (data, key) => {
        initValue = {
          ...initValue,
          [`${key}`]: data?.value || '',
        }
      })
    }

    // console.log('initValue', initValue);
    return initValue;
  }

  const shareSummary = () => {
    // const targetAddress = ticketRequest.collaborator.email;
    const subject = encodeURIComponent('Fiche de mission');
    let body = `Fiche de mission:%0A`;
    if (missionSheet) {
      _.map(missionSheet, (item, index) => {
        body = `${body}%0A${item.label} : ${item.value}`
      })
      window.open(`mailto:?subject=${subject}&body=${body}`, '_blank');
    }
  }

  const handleTicketSave = (values) => {
    onTicketSave(values, 'mission_sheet');
    setEditMode(false);
  }

  return (
    <StyledAccordion defaultExpanded>
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <CircleDiv><FlashOnIcon style={{ fontSize: '1rem' }} /></CircleDiv>
        <StyledAccordionTitle>{`Ma fiche de mission`}</StyledAccordionTitle>
      </StyledAccordionSummary>
      <AccordionDetails>
        {editMode ?
          <Formik
            initialValues={generateInitValue()}
            enableReinitialize
            setFieldValue
            // validationSchema={newContentActuSchema}
            onSubmit={handleTicketSave}
          >
            {(props) => {
              return (
                <StyledForm>
                  {_.map(missionSheet, (item, key) => {
                    const InputComponent = item.type === 'short_text' ? GenericTextField : GenericTextArea;
                    return (
                      <Field
                        key={key}
                        id={key}
                        name={key}
                        title={item.label}
                        placeholder="-"
                        variant="outlined"
                        size="small"
                        as={InputComponent}
                      />
                    )
                  })}
                  <StyledSummaryButtonContainer type="formik">
                    <StyledButton
                      onClick={() => setEditMode(false)}
                    >
                      Annuler
                    </StyledButton>
                    <StyledButton
                      type="submit"
                      onClick={() => { }}
                    >
                      Enregistrer
                    </StyledButton>
                  </StyledSummaryButtonContainer>
                </StyledForm>
              )
            }}
          </Formik>
          :
          <>
            <StyledMissionSummaryContainer>
              {_.map(missionSheet, (item, index) => {
                return (
                  <StyledSummaryText key={index}>
                    <StyledSummaryBoldText>
                      {`${item.label} : `}
                    </StyledSummaryBoldText>
                    {item.value || '-'}
                  </StyledSummaryText>
                )
              })}
            </StyledMissionSummaryContainer>
            <StyledSummaryButtonContainer>
              <StyledButton
                startIcon={<EditIcon style={{ fontSize: '0.8rem' }} />}
                onClick={() => setEditMode(true)}
                disabled={ticketRequest.status === finalTicketStatus || ticketRequest.status === ticketStatusArray[0]}
              >
                Éditer la fiche
              </StyledButton>
              <StyledButton
                startIcon={<ShareIcon style={{ fontSize: '0.8rem' }} />}
                onClick={shareSummary}
                disabled={ticketRequest.status === finalTicketStatus || ticketRequest.status === ticketStatusArray[0]}
              >
                Partager la fiche
              </StyledButton>
            </StyledSummaryButtonContainer>
          </>
        }
      </AccordionDetails>
    </StyledAccordion>
  )
}

export default TicketSummaryInfo;