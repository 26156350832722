import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const Wrapper = styled.div`
  width: 100%;
`;

export const ListSearch = styled.div`
  position: absolute;
  top: 66px;
  z-index: 999;
  left: 0;
  width: 100%;
  background-color: #fafafa;
  border-radius: 6px;
  box-shadow: 0 2px 10px 0 rgb(0, 0, 0, 0.2);
  & > div{
    padding: 14px 20px;
    font-size: 13px;
    opacity: 1;
    color: #5A5A5A;
    cursor: pointer;
    &:hover{
      background-color: #E9E9E9;
    }
    span{
      opacity: 1;
      font-size: 13px;
      color: #5A5A5A;
    }
  }
`;