import React from 'react';

// Styles
import {
  Container,
  ValueLabelOutside
} from "./style";

const DonutChart = ({
  value = 0,
  valueLabelInside = null,
  valueLabelOutside = null,
  size = 116,
  strokeWidth = 26,
  usePercent = false,
  colorText = '#2A2A2A',
  strokeColorBg = '#ffffff',
  strokeColorValue = '#357ED3',
  globalValue = 100,
  ...rest
}) => {

  const halfsize = (size * 0.5);
  const halfsizeLabel = valueLabelInside ? (size * 0.5) : (size * 0.6);
  const radius = halfsize - (strokeWidth * 0.5);
  const circumference = 2 * Math.PI * radius;
  const strokeval = ((value * circumference) / globalValue);
  const dashval = (strokeval + ' ' + circumference);

  const trackstyle = {
    strokeWidth: strokeWidth,
    stroke: strokeColorBg
  };
  const indicatorstyle = {
    strokeWidth: strokeWidth,
    strokeDasharray: dashval,
    stroke: strokeColorValue
  }
  const rotateval = 'rotate(-90 '+halfsize+','+halfsize+')';

  return (
      <Container className="donutChartCpt" {...rest}>
        <svg
          width={size}
          height={size}
          className="donutchart"
        >
          <circle
            r={radius}
            cx={halfsize}
            cy={halfsize}
            transform={rotateval}
            style={trackstyle}
            className="donutchart-track"
          />
          <circle
            r={radius}
            cx={halfsize}
            cy={halfsize}
            transform={rotateval}
            style={indicatorstyle}
            className="donutchart-indicator"
          />
          <text
            className="donutchart-text"
            x={halfsize}
            y={halfsizeLabel}
            style={{
              textAnchor:'middle',
              fill: colorText
            }}
          >
            <tspan className="donutchart-text-val">{value}</tspan>
            {usePercent && (
              <tspan className="donutchart-text-percent">%</tspan>
            )}
            {valueLabelInside && (
              <tspan
                className="donutchart-text-label"
                x={halfsize}
                y={halfsize+10}
              >
                {valueLabelInside}
              </tspan>
            )}
          </text>
        </svg>

        {valueLabelOutside && (
          <ValueLabelOutside
            className="donutchart-text-label-outside"
          >
            {valueLabelOutside}
          </ValueLabelOutside>
        )}
      </Container>
  );
}

export default DonutChart;
