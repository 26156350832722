import React, { useEffect, useState } from 'react';
import {
  shallowEqual,
  useDispatch,
  useSelector,
} from "react-redux";
import _ from "lodash";

// //Import Component
import GenericAdminFrontHeader from '../../../../../../components/molecules/GenericAdminFrontHeader';
import GenericTextField from '../../../../../../components/molecules/GenericTextField';
import GenericMaterialMenu from '../../../../../../components/molecules/GenericMaterialMenu';
import GenericTable from '../../../../../../components/molecules/GenericTable';
import Loader from '../../../../../../components/molecules/GenericLoader';

import { toaster } from '../../../../../../helpers/toaster';

import SearchUserModal from './SearchUserModal';
import CreateUserModal from './CreateUserModal';
import ServiceContentModal from './ServiceContentModal';

import {
  getUser
} from '../../../../../../store/collaborator/selectors';

import {
  getCollaboratorsList,
  getCollaboratorsListReset
} from '../../../../../../store/adminFront/actions';

import {
  getCollaboratorsListData,
  getCollaboratorsListIsLoading,
  getCollaboratorsListSuccess
} from '../../../../../../store/adminFront/selectors';

import {
  getTicketRequests,
} from '../../../../../../store/concierge/actions';

import {
  getConciergeSitesData,
  getConciergeSitesIsLoading,
  getConciergeServicesData,
  getConciergeServicesLoading,
  getConciergeServicesActifsData,
  getConciergeServicesActifsLoading,
} from '../../../../../../store/concierge/selectors';

import {
  companyGetModules
} from '../../../../../../store/company/actions';

import {
  getCompanyModules,
  getCompanyModulesIsLoading
} from '../../../../../../store/company/selectors';

import {
  createCollaboratorReset
} from '../../../../../../store/authentication/actions';

import {
  DrawerCptCustom,
  Wrapper,
  ContentContainer,
  SelectionContainer,
  StyledButton,
  SelectedContainer,
  SiteAndServicesTableContainer,
  InputGroup,
  StyledNameCell,
  StyledActiveStatus
} from './style';

const headerList = [
  { id: "service", label: "Service" },
  { id: "active", label: "Actif (oui/non)", disableSort: true },
  { id: "rowAction", label: "Action", disableSort: true },
];

const CreateNewDrawer = ({
  visible,
  closeFunction,
}) => {
  const dispatch = useDispatch();
  const [selectedUser, setSelectedUser] = useState(null);
  const [openSearchUser, setOpenSearchUser] = useState(false);
  const [openCreateUser, setOpenCreateUser] = useState(false);
  const [siteList, setSiteList] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [selectedSite, setSelectedSite] = useState(null);
  const [searchText, setSeachText] = useState("");
  const [selectedServiceActif, setSelectedServiceActif] = useState(null);
  const [shouldReloadData, setShouldReloadData] = useState(false);

  const {
    currentUser,
    sitesData,
    sitesIsLoading,
    collaboratorsList,
    collaboratorsListIsLoading,
    collaboratorsListSuccess,
    serviceData,
    serviceIsLoading,
    servicesActifsData,
    servicesActifsIsLoading,
    companyModule,
    companyModuleIsLoading,
  } = useSelector(
    state => ({
      currentUser: getUser(state),
      sitesData: getConciergeSitesData(state),
      sitesIsLoading: getConciergeSitesIsLoading(state),
      collaboratorsList: getCollaboratorsListData(state),
      collaboratorsListIsLoading: getCollaboratorsListIsLoading(state),
      collaboratorsListSuccess: getCollaboratorsListSuccess(state),
      serviceData: getConciergeServicesData(state),
      serviceIsLoading: getConciergeServicesLoading(state),
      servicesActifsData: getConciergeServicesActifsData(state),
      servicesActifsIsLoading: getConciergeServicesActifsLoading(state),
      companyModule: getCompanyModules(state),
      companyModuleIsLoading: getCompanyModulesIsLoading(state),
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!companyModule) {
      dispatch(companyGetModules());
    }
  }, []);

  useEffect(() => {
    if (sitesData) {
      const newList = [{
        value: null,
        label: '-- Tous les sites --'
      }]
      for (const site of sitesData) {
        newList.push({
          value: site.id,
          label: site.name
        });
      }
      setSiteList(newList);
    }
  }, [sitesData]);

  useEffect(() => {
    if (serviceData && servicesActifsData) {
      if (selectedSite !== null) {
        const search = _.toLower(_.deburr(searchText));
        const newItemList = _.compact(_.map(serviceData, (service) => {
          if (!_.toLower(_.deburr(service.name)).includes(search)) return;
          const servicesActif = _.find(servicesActifsData, (data) => data?.company_site?.id === selectedSite && data.service === service.id);
          return ({
            service:
              <StyledNameCell>
                {service.name}
              </StyledNameCell>,
            active:
              <StyledActiveStatus active={servicesActif?.active}>
                {servicesActif?.active ? 'activé' : 'désactivé'}
              </StyledActiveStatus>,
            rowAction:
              <StyledButton
                disabled={!servicesActif?.active}
                title={"Consulter"}
                onClick={() => handleActionClick(servicesActif?.id)}
              />
          })
        }));
        setItemList(newItemList);
      }
      else {
        setItemList([]);
      }
    }
  }, [serviceData, servicesActifsData, selectedSite, searchText, selectedUser]);

  const handleUserSearch = (keyword, companySite) => {
    dispatch(getCollaboratorsList({ limit: 100, keyword, companySite }));
  }

  const handleUserSelect = (selectedUser) => {
    const site = _.find(sitesData, (data) => data.name === selectedUser.company_site);

    setSelectedUser(selectedUser);
    setSelectedSite(site.id);
  }

  const handleSiteChange = (value) => {
    const site = _.find(sitesData, (data) => data.id === value);
    if (selectedUser && site && selectedUser.company_site !== site.name) {
      toaster('Attention, l’utilisateur sélectionné n’existe pas sur ce site', 'warn');
    }
    setSelectedSite(value);
  }

  const handleOpenSearchUser = () => {
    dispatch(getCollaboratorsListReset());
    setOpenSearchUser(true);
  }

  const handleOpenCreateUser = () => {
    dispatch(createCollaboratorReset());
    setOpenSearchUser(false);
    setOpenCreateUser(true);
  }

  const handleActionClick = (id) => {
    // if (!selectedUser) {
    //   toaster('Aucun collaborateur sélectionné', 'error');
    //   return;
    // }
    const servicesActif = _.find(servicesActifsData, (data) => data.id === id);
    if (servicesActif) {
      setSelectedServiceActif(servicesActif);
    }
  }

  const handleOnClose = () => {
    if (shouldReloadData) {
      dispatch(getTicketRequests());
    }
    closeFunction();
  }

  const generateSelectedUserInfo = () => {
    return `${selectedUser.firstname} ${selectedUser.lastname} (${selectedUser.email} - ${selectedUser.company_site})`
  }

  return (
    <DrawerCptCustom
      onOpen={visible}
      visible={visible}
      closeFunction={() => handleOnClose()}
      className="siteEditDrawer"
    >
      <Wrapper>
        <GenericAdminFrontHeader headerTitle={`Créer une demande`} />
        {sitesIsLoading || serviceIsLoading || servicesActifsIsLoading || companyModuleIsLoading ?
          <Loader />
          :
          <ContentContainer>
            <SelectionContainer>
              <StyledButton
                title={`Sélectionner un collaborateur`}
                onClick={handleOpenSearchUser}
              />
              <SelectedContainer>
                <div>
                  {`Collaborateur sélectionné :`}
                </div>
                <div>
                  {selectedUser ? generateSelectedUserInfo() : 'Aucun collaborateur sélectionné'}
                </div>
              </SelectedContainer>
            </SelectionContainer>
            <SiteAndServicesTableContainer>
              <InputGroup>
                <GenericMaterialMenu
                  title={"Rechercher dans"}
                  list={siteList}
                  initialValue={selectedSite}
                  onChange={(value) => handleSiteChange(value)}
                />
                <GenericTextField
                  variant={"outlined"}
                  size={"small"}
                  title={"Rechercher nom de service"}
                  // value={searchText}
                  autoFocus
                  updateOnBlur={false}
                  onChange={(event) => setSeachText(event.target.value)}
                />
              </InputGroup>
              <GenericTable
                headerList={headerList}
                itemList={itemList}
              />
            </SiteAndServicesTableContainer>
          </ContentContainer>
        }
        {openSearchUser &&
          <SearchUserModal
            collaboratorsList={collaboratorsList?.items}
            collaboratorsListIsLoading={collaboratorsListIsLoading}
            collaboratorsListSuccess={collaboratorsListSuccess}
            sitesData={sitesData}
            onClose={() => setOpenSearchUser(false)}
            onUserSearch={handleUserSearch}
            onUserSelect={handleUserSelect}
            handleOpenCreateUser={handleOpenCreateUser}
          />
        }
        {openCreateUser &&
          <CreateUserModal
            sitesData={sitesData}
            onClose={() => setOpenCreateUser(false)}
          />
        }
        {selectedServiceActif &&
          <ServiceContentModal
            currentUser={currentUser}
            serviceActif={selectedServiceActif}
            sitesData={sitesData}
            serviceData={serviceData}
            selectedUser={selectedUser}
            companyModule={companyModule}
            setShouldReloadData={setShouldReloadData}
            onClose={() => setSelectedServiceActif(null)}
          />
        }
      </Wrapper>
    </DrawerCptCustom>
  );
};

export default CreateNewDrawer;