import styled from "styled-components";
import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const HeadList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
`;

export const Title = styled.h3`
  margin: 0;
  color: #5A5A5A;
  font-size: 15px;
  line-height: 16px;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const Indicator = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  min-width: 18px;
  height: 18px;
  min-height: 18px;
  background-color: #EC6B87;
  border-radius: 50px;
  color: #ffffff;
  font-size: 10px;
  line-height: 18px;
  font-weight: 700;
`;

export const BodyList = styled.ul`
  margin: 0;
  padding: 0;
`;

export const BodyListItem = styled(Link)`
  margin: 0;
  padding: 0;
  list-style: none;
  border-radius: 0;
  border-bottom: 1px solid #efefef;
  cursor: pointer;
  &:hover{
    h5, span, p{
      color: #2768B3;
    }
  }
  &:last-child{
    border-bottom: 0;
  }
`;

export const HeadItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 12px 0;
  h4{
    color: #ffffff;
    font-size: 11px;
    line-height: 8px;
    font-weight: 700;
    padding: 8px 10px;
    border-radius: 4px;
    background-color: #2768B3;
    margin: 0 10px 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  h5{
    color: #5A5A5A;
    font-size: 14px;
    line-height: 15px;
    font-weight: 600;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin: 0 auto 0 0;
    transition: all .2s ease-out;
  }
  span{
    color: #999999;
    font-size: 12px;
    line-height: 10px;
    font-weight: 500;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    transition: all .2s ease-out;
  }
`;

export const BodyItem = styled.p`
  color: #5A5A5A;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  text-align: left;
  margin: 0 0 12px 0;
  transition: all .2s ease-out;
`;

export const FooterList = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
  padding: 20px 0;
`;

export const EmptyList = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #F7FBFF;
  border-radius: 6px;
  padding: 20px;
`;

export const Text = styled.p`
  margin: 0 0 20px 0;
  color: #5A5A5A;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  text-align: center;
`;
