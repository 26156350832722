import React, { useEffect } from "react";
import {
  Route,
  Redirect,
  useRouteMatch,
} from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";

import {
  isUserTokenValid,
  isUserAdmin as isUserAdminSelector,
} from '../store/authentication/selectors';
import {
  getUser
} from '../store/collaborator/actions';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const isAdminRoute = useRouteMatch("/admin");
  
  const {
    isTokenValid,
    isUserAdmin,
  } = useSelector(
    state => ({
      isTokenValid: isUserTokenValid(state),
      isUserAdmin: isUserAdminSelector(state),
    }),
    shallowEqual
  );

  useEffect(() => {
    if (isTokenValid) {
      dispatch(getUser());
    }
  }, [isTokenValid]);

  if(isAdminRoute && !isUserAdmin) {
    return (
      <Redirect
        to="/landing"
      />
    )
  } else {
    return (
      <Route
        {...rest}
        render={props =>
          isTokenValid ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}
            />
          )
        }
      />
    )
  }
};
