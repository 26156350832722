import React from "react";

const BorneIcon = ({
  color = "#5A5A5A",
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon borneIcon"
      width={width}
      height={height}
      viewBox="0 0 16 26"
      fill="none"
    >
      <path
        d="M14.548 1H2.161C1.52 1 1 1.52 1 2.161v20.904c0 .64.52 1.16 1.161 1.16h.387v.388c0 .214.174.387.387.387h1.549a.387.387 0 00.387-.387v-.387h6.968v.387c0 .214.173.387.387.387h1.548a.387.387 0 00.387-.387v-.387h.387c.642 0 1.162-.52 1.162-1.161V2.16C15.71 1.52 15.19 1 14.548 1zM1.774 7.58h6.194v5.033H1.774V7.58zm.387-5.806h5.807v5.032H1.774V2.161c0-.213.174-.387.387-.387zm2.323 21.678H2.16a.387.387 0 01-.387-.387v-9.678h6.194v10.065H4.484zm10.451-.387a.387.387 0 01-.387.387H8.742V1.774h5.806c.214 0 .387.174.387.387v20.904z"
        stroke={color}
        strokeWidth={0.5}
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export default BorneIcon;
