import React from 'react';
// import { withRouter } from 'react-router-dom';
import {
  // shallowEqual,
  // useSelector,
  useDispatch,
} from "react-redux";
import { toast } from 'react-toastify';

// Import Icons
import FlatInfoIcon from '../../../../../../../atoms/icons/flatInfo';

// Import Components

// Import style
import {
  // StyledSwitch,
  // StyledSwitchLabel,
  SwitchCustom,
  // StyledBeforeIcon,
  // StyledNextIcon,
  ElemCard,
  ElemCardHeader,
  ElemCardHeaderLeft,
  ElemCardHeaderRight,
  ElemCardContent,
  FlatInfoIconWrapper,
  PopoverCptCustom
} from './InformationElemBanner.style';

import { updateElementInformationActivation } from '../../../../../../../../store/adminFront/actions';

const InformationElementBanner = ({ data, toggled }) => {
  const dispatch = useDispatch();

  const onSwitch = () => {
    dispatch(updateElementInformationActivation(data.id));
    if (!toggled) {
      toast.success(`L'élément d'information "${data?.title}" a bien été activé !`, {
        position: toast.POSITION.TOP_RIGHT,
        className: "custom-toast custom-toast-success",
        bodyClassName: "custom-toast__body custom-toast__body-success",
        progressClassName: "custom-toast__progress"
      });
    } else {
      toast.success(`L'élément d'information "${data?.title}" a bien été désactivé !`, {
        position: toast.POSITION.TOP_RIGHT,
        className: "custom-toast custom-toast-success",
        bodyClassName: "custom-toast__body custom-toast__body-success",
        progressClassName: "custom-toast__progress"
      });
    }
  };

  return (
    <ElemCard>
      <ElemCardHeader>
        <ElemCardHeaderLeft>
          {!!data ?
            data?.title
            : "No title"}
        </ElemCardHeaderLeft>
        <ElemCardHeaderRight>
          {toggled ?
            <PopoverCptCustom
              className="popoverCpt"
              messagePopover={"Cet élément d'information est actuellement activé et utilisé par Cyconia"}
              anchorOriginVertical='center'
              anchorOriginHorizontal='left'
              transformOriginVertical='center'
              transformOriginHorizontal='right'
            >
              <span>
                <FlatInfoIconWrapper>
                  <FlatInfoIcon />
                </FlatInfoIconWrapper>
                Activé
              </span>
            </PopoverCptCustom>
            :
            <PopoverCptCustom
              className="popoverCpt"
              messagePopover={"Cet élément d'information est actuellement désactivé et non utilisé par Cyconia"}
              anchorOriginVertical='center'
              anchorOriginHorizontal='left'
              transformOriginVertical='center'
              transformOriginHorizontal='right'
            >
              <span>
                <FlatInfoIconWrapper>
                  <FlatInfoIcon />
                </FlatInfoIconWrapper>
                Désactivé
              </span>
            </PopoverCptCustom>
          }
          {/* <StyledSwitch
                        type="checkbox"
                        id="switch"
                        onChange={onSwitch}
                        checked={toggled}
                    />
                    <StyledSwitchLabel for="switch" >
                        Toggle
                    </StyledSwitchLabel> */}
          <SwitchCustom
            checked={toggled}
            onChange={onSwitch}
            color="primary"
          />
        </ElemCardHeaderRight>
      </ElemCardHeader>

      <ElemCardContent>
        {!!data ?
          data?.description
          : "No description"}
      </ElemCardContent>
    </ElemCard>
  );
}

export default InformationElementBanner;
