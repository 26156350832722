import styled from "styled-components";
import { Link } from "react-router-dom";

export const ActionBox = styled.div`
  &&{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 0 0;
  }
`;

export const BackButton = styled(Link)`
  &&{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 10px 0 0;
    padding: 14px;
    text-align: center;
    font-weight: 700;
    border: none;
    text-decoration: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    height: 60px;
    background-color: #eaeaea;
    box-sizing: border-box;
    color: #696969;
    text-transform: uppercase;
  }
`;

export const Text = styled.p`
  display: block;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 600;
  color: #838383;
  text-align: center;
  font-weight: 400;
  &:last-child{
    margin: 0;
  }
`;

export const BubbleText = styled.div`
  position: relative;
  padding: 15px;
  margin: 0 0 15px 0;
  border-radius: 5px 10px 10px 10px;
  background-color: #FFFFFF;
  box-shadow: 1px 1px 3px 1px rgb(0 0 0 / 20%);
`;

export const Container = styled.div`
  
`;