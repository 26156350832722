import React from "react";

const FlatInfoIcon = ({
  color = "#FFFFFF",
  width = "100%",
  height = "100%"
}) => {

  return (
    <svg
      className="icon flatInfoIcon"
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M6,0 C9.31208867,0.003907364 11.9960926,2.68791133 12,6 C12,9.3137085 9.3137085,12 6,12 C2.6862915,12 0,9.3137085 0,6 C0,2.6862915 2.6862915,0 6,0 Z M6,7.67272727 C5.58829682,7.67272727 5.25454545,8.00647864 5.25454545,8.41818182 C5.25454545,8.829885 5.58829682,9.16363636 6,9.16363636 C6.41170318,9.16363636 6.74545455,8.829885 6.74545455,8.41818182 C6.74545455,8.00647864 6.41170318,7.67272727 6,7.67272727 Z M6,2.8 C5.59624494,2.8 5.25454545,3.07881729 5.25454545,3.44507576 L5.25454545,3.44507576 L5.25454545,6.56060606 L5.26452926,6.66244684 L5.29311326,6.75776675 C5.39913569,7.03269258 5.6823093,7.20568182 6,7.20568182 C6.40375506,7.20568182 6.74545455,6.92686453 6.74545455,6.56060606 L6.74545455,6.56060606 L6.74545455,3.44507576 L6.73547074,3.34323498 L6.70688674,3.24791507 C6.60086431,2.97298924 6.3176907,2.8 6,2.8 Z"
          fill={color}
          fillRule="nonzero"
        >
        </path>
      </g>
    </svg>
  );
};

export default FlatInfoIcon;
