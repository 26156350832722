export const actionGenerator = (value) => ({
  REQUEST: `USER_MANAGEMENT/${value}/REQUEST`,
  SUCCESS: `USER_MANAGEMENT/${value}/SUCCESS`,
  FAILURE: `USER_MANAGEMENT/${value}/FAILURE`,
  RESET: `USER_MANAGEMENT/${value}/RESET`,
})

export const GET_COLLABORATOR_LIST = actionGenerator("GET_COLLABORATOR_LIST");
export const GET_COLLABORATOR_ROLES = actionGenerator("GET_COLLABORATOR_ROLES");
export const GET_SELECTED_COLLABORATOR = actionGenerator("GET_SELECTED_COLLABORATOR");
export const EDIT_COLLABORATOR = actionGenerator("EDIT_COLLABORATOR");
export const SWITCH_COLLABORATOR_SITE = actionGenerator("SWITCH_COLLABORATOR_SITE");

// Collaborator list
export const getCollaboratorList = (data) => ({
  type: GET_COLLABORATOR_LIST.REQUEST,
  data,
});

export const getCollaboratorListSuccess = (data) => ({
  type: GET_COLLABORATOR_LIST.SUCCESS,
  data,
});

export const getCollaboratorListError = (error) => ({
  type: GET_COLLABORATOR_LIST.FAILURE,
  error,
});

// Collaborator Roles
export const getCollaboratorRoles = (data) => ({
  type: GET_COLLABORATOR_ROLES.REQUEST,
  data,
});

export const getCollaboratorRolesSuccess = (data) => ({
  type: GET_COLLABORATOR_ROLES.SUCCESS,
  data,
});

export const getCollaboratorRolesError = (error) => ({
  type: GET_COLLABORATOR_ROLES.FAILURE,
  error,
});

// Selected Collaborator
export const getSelectedCollaborator = (data) => ({
  type: GET_SELECTED_COLLABORATOR.REQUEST,
  data,
});

export const getSelectedCollaboratorSuccess = (data) => ({
  type: GET_SELECTED_COLLABORATOR.SUCCESS,
  data,
});

export const getSelectedCollaboratorError = (error) => ({
  type: GET_SELECTED_COLLABORATOR.FAILURE,
  error,
});

// Edit Collaborator
export const editCollaborator = (data) => ({
  type: EDIT_COLLABORATOR.REQUEST,
  data,
});

export const editCollaboratorSuccess = (data) => ({
  type: EDIT_COLLABORATOR.SUCCESS,
  data,
});

export const editCollaboratorError = (error) => ({
  type: EDIT_COLLABORATOR.FAILURE,
  error,
});

export const editCollaboratorReset = (error) => ({
  type: EDIT_COLLABORATOR.RESET,
  error,
});

// switch collaborator site
export const switchCollaboratorSite = (data) => ({
  type: SWITCH_COLLABORATOR_SITE.REQUEST,
  data,
});

export const switchCollaboratorSiteSuccess = (data) => ({
  type: SWITCH_COLLABORATOR_SITE.SUCCESS,
  data,
});

export const switchCollaboratorSiteError = (error) => ({
  type: SWITCH_COLLABORATOR_SITE.FAILURE,
  error,
});