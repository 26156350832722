import Dialog from '@material-ui/core/Dialog';
import styled from "styled-components";
import { COLORS } from '../../../constants/design'


export const CustomDialog = styled(Dialog)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000 !important;
`;

export const DialogContainer = styled.div` 
  min-width: 550px;
  min-height: 300px;
  padding: 0;
  background-color: white;
  border-radius: 6px;
  color: #5a5a5a;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px;
`;

export const Title = styled.span`
  font-size: 19px;
  font-weight: 900;
  text-align: center;
  color: ${COLORS.DARK_GREY};
  margin-bottom: 40px;
  text-transform: uppercase;
`;

export const TextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Text = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${COLORS.DARK_GREY};
  text-align: center;
`;

export const ButtonList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;


export const CustomButton = styled.button`
  background-color: ${props => props.left ? COLORS.LIGHT_GREY_1 : COLORS.PRIMARY_BLUE};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  border-radius: 6px;
  cursor: pointer;
`;

export const CustomButtonText = styled.span`
  padding: 16px;
  font-size: 15px;
  font-weight: 700;
  margin: 7px 20px 7px 20px;
  color: ${props => props.left ? COLORS.DARK_GREY : COLORS.WHITE};
  text-transform: uppercase;
`;
