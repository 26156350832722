import styled from "styled-components";
import Dialog from '@material-ui/core/Dialog';
import { Form } from 'formik';

import { COLORS } from "../../../constants/design";
import { size } from '../../../theme-style/breakpoints';

import GenericTitleWithToolTip from "../../../components/molecules/GenericTitleWithToolTip";
import GenericDrawerValidationButtons from "../../../components/molecules/GenericDrawerValidationButtons";

export const CustomDialog = styled(Dialog)`
  z-index: 1600 !important;
  && {
    .MuiDialog-container {
      .MuiDialog-paper {
        &.MuiPaper-rounded {
          width: 100%;
          /* min-height: 70vh; */
          max-height: 100%;
        }
        &.MuiDialog-paperWidthSm {
          max-width: 700px;
        }
      }
      @media (max-width: ${size.mobileXL}) {
        .MuiDialog-paper {
          &.MuiDialog-paperScrollPaper {
            max-height: 100%;
          }
          &.MuiDialog-paper {
            margin: 0;
          }
          &.MuiPaper-rounded {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
`;

export const DialogContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 25px;
  background-color: white;
  border-radius: 6px;
  color: ${COLORS.DARK_GREY};
  font-size: 0.9rem;
  height: 100%;
  /* display: flex;
  flex-direction: column; */
  display: flex;
  flex-flow: column nowrap;
`;

export const StyledTitle = styled(GenericTitleWithToolTip)`
  font-weight: 700;
  font-size: 1.1rem !important;
  margin-bottom: 15px;
  .textTooltip {
    color: ${COLORS.GREY_1};
  }
`;

export const StyledWarningText = styled.div`
  color: ${COLORS.RED_4};
`;

export const StyledForm = styled(Form)`
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  /* padding: 30px 30px 30px 30px; */
  .textTitle {
    font-weight: 700;
    font-size: 0.9rem;
    color: #5A5A5A;
    margin: 14px 5px 14px 0px;
  }
  .textTooltip {
    color: ${COLORS.GREY_1};
  }
  .textFieldInput {
    input {
      font-size: 13px;
    }
  }
  >div:not(:last-child) {
    margin-bottom: 15px;
  };
`;

export const StyledValidationButtons = styled(GenericDrawerValidationButtons)`
  /* border: 1px solid red; */
  .customButton {
    width: 45%;
  }
`;

export const LoaderContainer = styled.div`
  height: 270px;
`;